import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UiFieldGroupLayout } from '@ui-kit/components/ui-field-group/ui-field-group.component';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { TextareaTypeEnum } from '../json-textarea/json-textarea.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FileUtilityHelper } from 'projects/console-adm/src/app/shared/helpers/file-utility.helper';
import { CertificateUtilityHelper } from 'projects/console-adm/src/app/shared/helpers/certificate-utility.helper';
import { ISelfSignedPems } from '@common/services/selfsigned.service';

export enum UiTypeOfFormEnum {
  DROPDOWN = 'dropdown',
  TEXT_INPUT = 'textInput',
  LONG_TEXT_INPUT = 'longTextInput',
  NUMBER_INPUT = 'numberInput',
  CHECKBOX = 'checkbox',
  OBJECT_TEXTAREA = 'objectTextarea',
  ARRAY_TEXTAREA = 'arrayTextarea',
  MULTI_SELECT = 'multiSelect',
  DATE_INPUT = 'dateTimePicker',
  FILE_UPLOAD_INPUT = 'fileUpload',
}

export interface FormInterface {
  label: ITranslatedField;
  description: ITranslatedField;
  required: boolean;
  typeOfForm: UiTypeOfFormEnum;
  controlName: string;
  group: UntypedFormGroup;
  values?: any[];
  initialValue?: { label: string; value: string | number };
}

export enum DescriptionFormatEnum {
  TOOLTIP = 'tooltip',
  LABEL = 'label'
}

@Component({
  selector: 'dynamic-form-template',
  templateUrl: './dynamic-form-template.component.html',
  styleUrls: ['./dynamic-form-template.component.scss'],
})
export class DynamicFormTemplateComponent  {
  public uiTypeOfFormEnum = UiTypeOfFormEnum;
  public textareaTypeEnum = TextareaTypeEnum;
  public descriptionFormatEnum = DescriptionFormatEnum;
  public uiFieldGroupLayout = UiFieldGroupLayout;

  @Input() formConfig: FormInterface;
  @Input() readonly = false;
  @Input() description = DescriptionFormatEnum.LABEL;

  @Output() valueSelection = new EventEmitter();

  @Output() fileUploadToggle = new EventEmitter<void>();

  @Output() onCertificateUploaded = new EventEmitter<ISelfSignedPems>();

  private fileChangeSubject = new Subject<File>();

  constructor() {
    this.fileChangeSubject
      .pipe(debounceTime(300))
      .subscribe($event => this.onFileSelection($event));
  }


  public get isInError(): boolean {
    const control = this.formConfig.group.get(this.formConfig.controlName);

    return control.invalid && control.touched;
  }

  public onValueSelection(event: { controlName: string; value: string }): void {
    this.valueSelection.emit(event);
  }


  /** show/hide Input type file  */
  toggleFileUploadVisibility(): void {
    this.fileUploadToggle.emit();
  }

  /** Handle upload file */
  async onFileSelection($file: File): Promise<void> {
    try {
      const fileContent = await FileUtilityHelper.readFile($file);
      const { privateKey, publicKey, certificate, expirationDate } = CertificateUtilityHelper.extractPemComponents(fileContent);

      this.onCertificateUploaded.emit({
        private: privateKey,
        public: publicKey,
        cert: certificate,
        expirationDate,
      });
    }
    catch (error) {
      console.error('Error reading file:', error);
    }
  }
}
