import { Component, Input } from "@angular/core";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";

@Component({
  selector: 'app-registration-update-card',
  templateUrl: './app-registration-update-card.component.html',
  styleUrls: [ './app-registration-update-card.component.scss' ],
})
export class AppRegistrationUpdateCard {
  @Input()
    active: boolean;

  constructor(public i18n: I18nService) {

  }
}
