import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { IdentityProviderTypes, List, ListSecurityGroupResponse } from "projects/@common/services/api/iam/identities/identities.api.definitions";

import { Accumulatables, Accumulate } from '@common/modules/accumulator/accumulator.store';
import { DrawerService } from "@common/modules/layout/components/drawer/services/drawer.service";
import { Notice, NoticeLevels, NoticeService } from "@common/modules/notice/notice.service";
import { Group, GroupsApi } from '@common/services/api/iam/groups/groups.api';
import { IamApi } from "@common/services/api/iam/iam.api.definitions";
import { AutocompleteTypes } from "@ui-kit/components/autocomplete/autocomplete.component";


interface ISecurityGroupAutocompleteData {
  guid: string;
  firstName: string;
  description: string;
}


@Component({
  selector: 'role-security-groups',
  templateUrl: './role-security-groups.component.html',
  styleUrls: [ './role-security-groups.component.scss' ],
})
export class RoleSecurityGroupsComponent implements OnInit {
  public associations: Partial<Group>[];

  @Input() profile: IamApi.Profile.listResponse;
  @Input() organizationId: string;

  public static readonly COMPONENT = 'profileSecurityGroups';
  public isLoading: boolean = false;
  public isEditing: boolean = false;
  public securityGroupAutocompleteType = AutocompleteTypes.USERS;

  constructor(
    private groupsApi: GroupsApi,
    private drawerService: DrawerService,
    private noticeService: NoticeService,
    private store: Store
  ) { }

  public ngOnInit(): void {
    this.getInitialAssociations();
  }

  private getInitialAssociations() {
    this.isLoading = true;
    this.groupsApi.listGroupProfileAssociations(this.profile.id)
      .then((res) => {
        this.associations = res.items;
      })
      .finally(() => this.isLoading = false);
  }

  public addItem(item: ISecurityGroupAutocompleteData): void {
    if (!item) return;

    if (this.associations.length + 1 > 100) {
      this.noticeService.notifyUser(new Notice('securityGroups.info.size.limit', NoticeLevels.ERROR));
      return;
    }

    const groupAssociation = {
      idpIdentifier: item.guid,
      name: item.firstName,
      description: item.description,
    };

    if (this.associations.find((i) => i.idpIdentifier == groupAssociation.idpIdentifier)) return;

    this.associations.push(groupAssociation);
  }

  public removeItem(group: Partial<Group>): void {
    this.associations = this.associations.filter((i) => i.idpIdentifier !== group.idpIdentifier);
  }

  public saveProfile(): void {
    this.isLoading = true;
    const groupIdpIdentifiers = this.associations.map((group) => group.idpIdentifier);
    this.groupsApi.setGroupProfileAssociations(groupIdpIdentifiers, this.profile.id)
      .then(() => {
        this.noticeService.notifyUser(new Notice('securityGroups.success.update.message.first', NoticeLevels.SUCCESS));
        this.getInitialAssociations();
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.IAM_ROLE_LIST }));
        this.toggleEditProfile();
      })
      .catch((err) => this.notifyError(err))
      .finally(() => this.isLoading = false);
  }

  private notifyError(error: any): void {
    if (error['response']?.status === 400) {
      this.noticeService.notifyUser(new Notice('securityGroups.error.404.message', NoticeLevels.ERROR));
    } else {
      this.noticeService.notifyUser(new Notice('securityGroups.error.create.500.message', NoticeLevels.ERROR));
    }
  }

  public cancel(): void {
    if (this.isEditing) {
      this.toggleEditProfile();
      this.getInitialAssociations();
    } else {
      this.drawerService.previousDrawer();
    }
  }

  public toggleEditProfile(): void {
    this.isEditing = !this.isEditing;
    this.drawerService.setEditing(this.isEditing, RoleSecurityGroupsComponent.COMPONENT);
  }

  public securityGroupAutocompleteSearchFunction = (params: { search: string; }): Promise<List<ListSecurityGroupResponse, number | string, { idpType: IdentityProviderTypes; }>> =>
    // Since the call only return security groups not associated with the profile,
    // we need to add the security groups in the pending state of the list.
    new Promise(async (resolve) => {
      this.groupsApi.listGroups({ search: params.search })
        .then((res) => {
          resolve(res);
        })
        .catch(() => {
          this.noticeService.notifyUser(new Notice('securityGroups.error.create.500.message', NoticeLevels.ERROR));
        });
    });
}
