import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UpdateStateEnum } from '../../../containers/playbook-update/playbook-update.container';

@Component({
  selector: 'app-playbook-update-action-buttons',
  templateUrl: './playbook-update-action-buttons.component.html',
  styleUrls: [ './playbook-update-action-buttons.component.scss' ],
})
export class PlaybookUpdateActionButtonsComponent implements OnInit {
  @Input() public currentState: UpdateStateEnum;

  @Output() readonly saveEvent = new EventEmitter<null>();
  @Output() readonly cancelEvent = new EventEmitter<null>();

  public statesEnum = UpdateStateEnum;

  constructor() { }

  ngOnInit(): void {
  }

  public emitSave(): void {
    this.saveEvent.emit(null);
  }

  public emitCancel(): void {
    this.cancelEvent.emit(null);
  }
}
