import { OrganizationPublicInfo } from "projects/@common/definitions/organization-public-info.definition";
import { Alert } from "projects/@common/services/api/respond/alerts/models/alert";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IObservablesPageContext } from "projects/@respond/observables/components/observable-container/observable-container.component";

export enum SimilarityItemTypeEnum {
  ALERTS = 'alerts',
  INCIDENTS = 'incidents'
};

export type SimilarityPageContext = {
  type: SimilarityItemTypeEnum;
  incident?: Incident;
  alert?: Alert;
};

export type SimilarityItem = {
  type: SimilarityItemTypeEnum;
  name: string;
  createdAt: number;
  status: string;
  shortId: string;
  id: string;
  score: number;
  canAssociate: boolean;
  sameOrganization: boolean;
  samePlaybook: boolean;
  sameUsecase: boolean;
} & OrganizationPublicInfo;

export type SimilarityTableItem = SimilarityItem & {
  typeTranslation: string;
  incident?: Incident;
  alert?: Alert;
  observables?: {
    config: IObservablesPageContext;
    totalCount: number;
    similarCount: number;
    similarTypes: string;
  }
  linkURL?: string;
  linkQueryParams?: object;
};

export enum SimilarityIndicatorEnum {
  SAME_ORGANIZATION = 'SAME_ORGANIZATION',
  SAME_USECASE = 'SAME_USECASE',
  SAME_PLAYBOOK = 'SAME_PLAYBOOK'
}

export enum SimilarityItemTypeFilterEnum {
  ALL = 'ALL',
  ALERT = 'ALERT',
  INCIDENT = 'INCIDENT'
}

export interface ISimilarityFilterEvent {
  dateFrom?: number;
  dateTo?: number;
  type?: SimilarityItemTypeEnum;
  sameOrganization?: boolean;
  sameUsecase?: boolean;
  samePlaybook?: boolean;
}

export enum SimilarityTableColumnEnum {
  SHORT_ID = 'shortId',
  NAME = 'name',
  TYPE_TRANSLATION = 'typeTranslation',
  STATUS = 'status',
  ORGANIZATION_NAME = 'organizationName',
  SCORE = 'score',
  INDICATORS = 'indicators',
  ACTIONS = 'actions'
}

export type SimilarityOnAssociateEventData = {
  alert?: Alert;
  incident?: Incident;
};
