import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { EditWithModalComponent } from 'projects/@common/components/edit-with-modal/edit-with-modal.component';

export interface IAutocompleteUser {
  id: string;
  name: string;
  idpIdentifier: string;
  o365UserId: string;
  isPlatformUser: boolean;
}

@Component({
  selector: 'analyst-adder',
  templateUrl: './analyst-adder.component.html',
  styleUrls: [ './analyst-adder.component.scss' ],
})
export class AnalystAdderComponent {
  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;

  @Input() availableAnalysts: IAutocompleteUser[];

  @Output() selectionEvent: EventEmitter<IAutocompleteUser> = new EventEmitter();

  public selectedAutocompleteItems = [];
  public selectedAnalyst: IAutocompleteUser;
  public autocompleteType: AutocompleteTypes = AutocompleteTypes.CUSTOM;

  constructor() { }

  public get autocompleteItems(): { value: string, displayValue: string }[] {
    if (!this.availableAnalysts) {
      return [];
    }
    return this.availableAnalysts.map((analyst) => ({
      value: analyst.id,
      displayValue: analyst.name,
    }));
  }

  public toggle(): void {
    this.editWithModalComponent.toggle();
  }

  public get isDropdownOpen(): boolean {
    return !!this.editWithModalComponent?.isOpen();
  }

  public handleSelection(userId: string): void {
    const analyst = this.availableAnalysts.find((analyst) => analyst.id === userId);
    if (analyst) {
      this.selectedAnalyst = analyst;
    }
  }

  public handleConfirmationEvent(isConfirmed: boolean): void {
    if (isConfirmed && this.selectedAnalyst) {
      this.selectionEvent.emit(this.selectedAnalyst);
    }
    this.editWithModalComponent.close();
  }
}
