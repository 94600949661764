<communication-business-hours
  [businessHours]="parameters.businessHours"
  [hasEditPermission]="hasEditPermission"
  (saveEvent)="saveBusinessHours($event)"
></communication-business-hours>

<communication-escalation-delays
  [businessHours]="parameters.businessHours"
  [escalationDelays]="parameters.escalationDelays"
  [hasEditPermission]="hasEditPermission"
  (saveEvent)="saveEscalationDelays($event)"
></communication-escalation-delays>