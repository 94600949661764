<ui-toolbar-filter
  toolbar
  [activatedFiltersNumber]="appliedFiltersCount || 0"
  [locale]="i18n.currentLocale"
  [showEraseAll]="appliedFiltersCount > 0"
  [total]="null"
  [areFiltersExpanded]="false"
  (onClearAllFilters)="clearFilters()"
>
  <app-date-interval
    leftSide
    [period]="period"
    [withTime]="true"
  ></app-date-interval>

  <ui-table-total-tool
    rightSide
    [locale]="i18n.currentLocale"
    [total]="isLoading ? '-' : totalDisplayed || 0"
  ></ui-table-total-tool>

  <ui-table-refresh-tool
    rightSide
    style="display: flex; margin: 0 10px;"
    [loading]="isLoading"
    (click)="handleManualRefreshClick()"
  ></ui-table-refresh-tool>

  <div
    toolbar-collapsable-element
    [elementTitle]="'common.period' | t"
  >
    <ui-table-date-tool
      [locale]="i18n.currentLocale"
      [showCustomDatetimePickers]="false"
      [minDate]="null"
      [defaultPresetOption]="null"
      [defaultCustomTimePeriod]="{dateFrom: null, dateTo: null}"
      [excludePresets]="['last_365_days']"
      (timeEmitter)="applyPeriod($event)"
      (resetDateEmitter)="applyPeriod(null)"
    >
    </ui-table-date-tool>
  </div>

  <div
    toolbar-collapsable-element
    [elementTitle]="'common.type' | t"
  >
    <ui-table-filter-tool
      [tableFilterData]="itemTypeSelectOptions"
      (select)="applyItemType($event)"
    ></ui-table-filter-tool>
  </div>

  <div
    toolbar-collapsable-element
    [elementTitle]="'common.indicator' | t"
  >
    <ui-multi-select
      [multiSelectData]="indicatorMultiSelectOptions"
      (onOptionSelect)="applyIndicators($event)"
    >
    </ui-multi-select>
  </div>

  <div
    *ngIf="observables"
    toolbar-collapsable-element
    [elementTitle]="'observables.title' | t"
  >
    <ui-multi-select
      [multiSelectData]="observableMultiSelectOptions"
      (onOptionSelect)="applyObservables($event)"
    >
    </ui-multi-select>
  </div>
</ui-toolbar-filter>