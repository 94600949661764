<div
  page
  [appPagePaths]="['mdRespond', 'histories']"
  [hasTopSection]="true"
  [previewMode]="'beta'"
  [tooltipTitle]="adminConsole ? 'histories.table.title.tooltip.admin': 'histories.table.title.tooltip.client'"
  [searchMode]="searchMode"
  [disableOrganizationSelector]="true"
  (onScrollToBottom)="loadMoreEvent()"
>


  <histories-filters
    [locale]="locale"
    toolbar
  ></histories-filters>
  <history-table
    [history]="histories"
    [canLoadMore]="false"
    [columns]="['createdAt', 'identityName', 'message', 'type', 'indicators', 'organization']"
    [isLoading]="isLoading"
    (loadMoreEvent)="loadMoreEvent()"
  ></history-table>

</div>