import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TimeUtil } from '@ui-kit/services/time-util';
import { BusinessHours, EscalationDelayByPriority, EscalationDelays, HourPeriod } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { clone } from 'projects/@common/utils/utils';

@Component({
  selector: 'communication-escalation-delays',
  templateUrl: './communication-escalation-delays.component.html',
  styleUrls: [ './communication-escalation-delays.component.scss' ],
})
export class CommunicationEscalationDelaysComponent implements OnChanges {
  @Input() businessHours: BusinessHours;
  @Input() escalationDelays: EscalationDelays;
  @Input() hasEditPermission: boolean;

  @Output() saveEvent = new EventEmitter<EscalationDelays>();

  isEditing: boolean;
  prevEscalationDelays: EscalationDelays;
  prevUseOffHours: boolean;
  nextBusinessDayValues: { reminder: string; delay: string; };
  useOffHours: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.businessHours) {
      this.initNextBusinessDayValues();
    }
  }

  public get delays(): EscalationDelayByPriority[] {
    return this.escalationDelays.delays;
  }

  public get isValid(): boolean {
    if (!this.useOffHours) {
      return this.delays.every((item) => item.businessHours.nextBusinessDay || (item.businessHours.reminder && item.businessHours.delay));
    }
    return this.delays.every((item) => (item.businessHours.nextBusinessDay || (item.businessHours.reminder && item.businessHours.delay)) &&
      (item.offHours.nextBusinessDay || (item.offHours.reminder && item.offHours.delay)));
  }

  public toggleEdit(value: boolean) {
    this.isEditing = value;
    if (this.isEditing) {
      this.prevEscalationDelays = clone(this.escalationDelays);
      this.prevUseOffHours = this.useOffHours;
    }
  }

  public save() {
    if (!this.useOffHours) {
      this.delays.forEach((item) => {
        item.offHours = null;
      });
    }
    this.saveEvent.emit(this.escalationDelays);
  }

  public cancel() {
    this.escalationDelays = clone(this.prevEscalationDelays);
    this.useOffHours = this.prevUseOffHours;
  }

  public toggleBusinessSchedule(value: boolean) {
    this.useOffHours = value;
    if (this.useOffHours) {
      this.delays.forEach((item) => {
        item.offHours = {
          reminder: null,
          delay: null,
          nextBusinessDay: item.businessHours.nextBusinessDay,
        };
      });
    }
  }

  public trackByFunc(index: number, item: any) {
    return item.priority;
  }

  public isDisabledCheckBox(index: number, type: HourPeriod): boolean {
    if (type === "businessHours") {
      // Disabled if the previous index checkebox is checked
      return index > 0 && this.delays[index - 1].businessHours.nextBusinessDay;
    } else if (type === "offHours") {
      // When same index is checked in the business hours table, the off hours checkbox should be disabled too
      // otherwise it is disabled if the previous index checkebox is checked
      return this.delays[index].businessHours.nextBusinessDay || index > 0 && this.delays[index - 1].offHours.nextBusinessDay;
    }
  }

  public setNextBusinessDaysState(escalationDelay: EscalationDelayByPriority, value: boolean, type: HourPeriod) {
    this.delays.forEach((item) => {
      // ensure no undefined object access
      if (item[type] === undefined || item[type] === null) {
        item[type] = {};
      }

      // assign values to all items with same or higher priority number
      if (item.priority === escalationDelay.priority) {
        item[type].nextBusinessDay = value;
      } else if (item.priority > escalationDelay.priority) {
        item[type].nextBusinessDay = item[type].nextBusinessDay || value;
      }

      // the right side of the table should be checked if the left side is checked
      if (type === "businessHours" && this.useOffHours && item.businessHours.nextBusinessDay) {
        item.offHours.nextBusinessDay = item.businessHours.nextBusinessDay;
      }
    });
  }

  private initNextBusinessDayValues(): void {
    const formattedMinutes = TimeUtil.padZero(this.businessHours.fromMinute);
    const hours = this.businessHours.fromHour;
    const nextHour = hours + 2;
    this.nextBusinessDayValues = {
      reminder: `${hours}:${formattedMinutes}`,
      delay: `${nextHour}:${formattedMinutes}`,
    };
  }
}
