export const networkProtocols = [
  { "value": "0", "displayValue": "HOPOPT" },
  { "value": "1", "displayValue": "ICMP" },
  { "value": "2", "displayValue": "IGMP" },
  { "value": "3", "displayValue": "GGP" },
  { "value": "4", "displayValue": "IPv4" },
  { "value": "5", "displayValue": "ST" },
  { "value": "6", "displayValue": "TCP" },
  { "value": "7", "displayValue": "CBT" },
  { "value": "8", "displayValue": "EGP" },
  { "value": "9", "displayValue": "IGP" },
  { "value": "10", "displayValue": "BBN-RCC-MON" },
  { "value": "11", "displayValue": "NVP-II" },
  { "value": "12", "displayValue": "PUP" },
  { "value": "13", "displayValue": "ARGUS" },
  { "value": "14", "displayValue": "EMCON" },
  { "value": "15", "displayValue": "XNET" },
  { "value": "16", "displayValue": "CHAOS" },
  { "value": "17", "displayValue": "UDP" },
  { "value": "18", "displayValue": "MUX" },
  { "value": "19", "displayValue": "DCN-MEAS" },
  { "value": "20", "displayValue": "HMP" },
  { "value": "21", "displayValue": "PRM" },
  { "value": "22", "displayValue": "XNS-IDP" },
  { "value": "23", "displayValue": "TRUNK-1" },
  { "value": "24", "displayValue": "TRUNK-2" },
  { "value": "25", "displayValue": "LEAF-1" },
  { "value": "26", "displayValue": "LEAF-2" },
  { "value": "27", "displayValue": "RDP" },
  { "value": "28", "displayValue": "IRTP" },
  { "value": "29", "displayValue": "ISO-TP4" },
  { "value": "30", "displayValue": "NETBLT" },
  { "value": "31", "displayValue": "MFE-NSP" },
  { "value": "32", "displayValue": "MERIT-INP" },
  { "value": "33", "displayValue": "DCCP" },
  { "value": "34", "displayValue": "3PC" },
  { "value": "35", "displayValue": "IDPR" },
  { "value": "36", "displayValue": "XTP" },
  { "value": "37", "displayValue": "DDP" },
  { "value": "38", "displayValue": "IDPR-CMTP" },
  { "value": "39", "displayValue": "TP++" },
  { "value": "40", "displayValue": "IL" },
  { "value": "41", "displayValue": "IPv6" },
  { "value": "42", "displayValue": "SDRP" },
  { "value": "43", "displayValue": "IPv6-Route" },
  { "value": "44", "displayValue": "IPv6-Frag" },
  { "value": "45", "displayValue": "IDRP" },
  { "value": "46", "displayValue": "RSVP" },
  { "value": "47", "displayValue": "GRE" },
  { "value": "48", "displayValue": "DSR" },
  { "value": "49", "displayValue": "BNA" },
  { "value": "50", "displayValue": "ESP" },
  { "value": "51", "displayValue": "AH" },
  { "value": "52", "displayValue": "I-NLSP" },
  { "value": "53", "displayValue": "SWIPE" },
  { "value": "54", "displayValue": "NARP" },
  { "value": "55", "displayValue": "MOBILE" },
  { "value": "56", "displayValue": "TLSP" },
  { "value": "57", "displayValue": "SKIP" },
  { "value": "58", "displayValue": "IPv6-ICMP" },
  { "value": "59", "displayValue": "IPv6-NoNxt" },
  { "value": "60", "displayValue": "IPv6-Opts" },
  { "value": "62", "displayValue": "CFTP" },
  { "value": "64", "displayValue": "SAT-EXPAK" },
  { "value": "65", "displayValue": "KRYPTOLAN" },
  { "value": "66", "displayValue": "RVD" },
  { "value": "67", "displayValue": "IPPC" },
  { "value": "69", "displayValue": "SAT-MON" },
  { "value": "70", "displayValue": "VISA" },
  { "value": "71", "displayValue": "IPCV" },
  { "value": "72", "displayValue": "CPNX" },
  { "value": "73", "displayValue": "CPHB" },
  { "value": "74", "displayValue": "WSN" },
  { "value": "75", "displayValue": "PVP" },
  { "value": "76", "displayValue": "BR-SAT-MON" },
  { "value": "77", "displayValue": "SUN-ND" },
  { "value": "78", "displayValue": "WB-MON" },
  { "value": "79", "displayValue": "WB-EXPAK" },
  { "value": "80", "displayValue": "ISO-IP" },
  { "value": "81", "displayValue": "VMTP" },
  { "value": "82", "displayValue": "SECURE-VMTP" },
  { "value": "83", "displayValue": "VINES" },
  { "value": "84", "displayValue": "IPTM" },
  { "value": "85", "displayValue": "NSFNET-IGP" },
  { "value": "86", "displayValue": "DGP" },
  { "value": "87", "displayValue": "TCF" },
  { "value": "88", "displayValue": "EIGRP" },
  { "value": "89", "displayValue": "OSPFIGP" },
  { "value": "90", "displayValue": "Sprite-RPC" },
  { "value": "91", "displayValue": "LARP" },
  { "value": "92", "displayValue": "MTP" },
  { "value": "93", "displayValue": "AX.25" },
  { "value": "94", "displayValue": "IPIP" },
  { "value": "95", "displayValue": "MICP" },
  { "value": "96", "displayValue": "SCC-SP" },
  { "value": "97", "displayValue": "ETHERIP" },
  { "value": "98", "displayValue": "ENCAP" },
  { "value": "100", "displayValue": "GMTP" },
  { "value": "101", "displayValue": "IFMP" },
  { "value": "102", "displayValue": "PNNI" },
  { "value": "103", "displayValue": "PIM" },
  { "value": "104", "displayValue": "ARIS" },
  { "value": "105", "displayValue": "SCPS" },
  { "value": "106", "displayValue": "QNX" },
  { "value": "107", "displayValue": "A/N" },
  { "value": "108", "displayValue": "IPComp" },
  { "value": "109", "displayValue": "SNP" },
  { "value": "110", "displayValue": "Compaq-Peer" },
  { "value": "111", "displayValue": "IPX-in-IP" },
  { "value": "112", "displayValue": "VRRP" },
  { "value": "113", "displayValue": "PGM" },
  { "value": "115", "displayValue": "L2TP" },
  { "value": "116", "displayValue": "DDX" },
  { "value": "117", "displayValue": "IATP" },
  { "value": "118", "displayValue": "STP" },
  { "value": "119", "displayValue": "SRP" },
  { "value": "120", "displayValue": "UTI" },
  { "value": "121", "displayValue": "SMP" },
  { "value": "122", "displayValue": "SM" },
  { "value": "123", "displayValue": "PTP" },
  { "value": "124", "displayValue": "ISIS" },
  { "value": "125", "displayValue": "FIRE" },
  { "value": "126", "displayValue": "CRTP" },
  { "value": "127", "displayValue": "CRUDP" },
  { "value": "128", "displayValue": "SSCOPMCE" },
  { "value": "129", "displayValue": "IPLT" },
  { "value": "130", "displayValue": "SPS" },
  { "value": "131", "displayValue": "PIPE" },
  { "value": "132", "displayValue": "SCTP" },
  { "value": "133", "displayValue": "FC" },
  { "value": "134", "displayValue": "RSVP-E2E-IGNORE" },
  { "value": "135", "displayValue": "Mobility Header" },
  { "value": "136", "displayValue": "UDPLite" },
  { "value": "137", "displayValue": "MPLS-in-IP" },
  { "value": "138", "displayValue": "manet" },
  { "value": "139", "displayValue": "HIP" },
  { "value": "140", "displayValue": "Shim6" },
  { "value": "141", "displayValue": "WESP" },
  { "value": "142", "displayValue": "ROHC" },
  { "value": "143", "displayValue": "Ethernet" },
  { "value": "144", "displayValue": "AGGFRAG" },
  { "value": "145", "displayValue": "NSH" },
].map((set) => ({ value: set.value, displayValue: `${set.displayValue} (${set.value})` }));
