import * as am4charts from "@amcharts/amcharts4/charts";
import { ColumnSeries } from '@amcharts/amcharts4/charts';
import * as am4core from "@amcharts/amcharts4/core";
import { AfterViewInit, Component, Input } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';

export enum SeverityEnum {
  none = 0,
  low = 1,
  moderate = 2,
  high = 3,
  critique = 4
}

const SEVERITY_LEVEL_WITH_COLOR = {
  [SeverityEnum.none]: "#67b7dc",
  [SeverityEnum.low]: "#00d873",
  [SeverityEnum.moderate]: "#ff7800",
  [SeverityEnum.high]: "#de3618",
  [SeverityEnum.critique]: "#9b0000",
};
@Component({
  selector: 'app-shares-by-severity',
  templateUrl: './shares-by-severity.component.html',
  styleUrls: [ './shares-by-severity.component.scss' ],
})
export class SharesBySeverityComponent implements AfterViewInit {
  @Input() public data: {
    data: Array<{ label: string; severity: SeverityEnum; value: number; }>;
    severityLegend: Record<SeverityEnum, number>;
  };

  @Input() public isLoading = true;

  private isViewInit = false;

  private chart: am4charts.XYChart;

  constructor(public i18nService: I18nService) {
    am4core.addLicense("CH259911983");
  }

  ngAfterViewInit(): void {
    this.initChart();
  }

  private initChart(): void {
    this.generateChart();
    this.setCategoryAxis();
    this.setValueAxis();
    this.setColumns();
  }

  private generateChart(): void {
    this.chart = am4core.create("severityGraph", am4charts.XYChart);
    this.chart.data = this.data?.data;

    this.chart.paddingBottom = 5;
    this.chart.paddingLeft = 0;
    this.chart.paddingRight = 10;
    this.chart.maskBullets = false;
    this.chart.paddingTop = 25;
  }

  private setCategoryAxis(): void {
    const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    categoryAxis.title.text = this.i18nService.translate('dashboard.admin.sharedFiles.severity.distribution.label.legend');
    categoryAxis.title.marginTop = 10;
    categoryAxis.title.marginBottom = 5;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 15;

    const label = categoryAxis.renderer.labels.template;
    label.fontSize = 14;
    label.truncate = true;
    categoryAxis.events.on("sizechanged", (ev) => {
      const axis = ev.target;
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      axis.renderer.labels.template.maxWidth = cellWidth;
    });

    label.tooltipText = "{category}";
    categoryAxis.tooltip.getFillFromObject = false;
    categoryAxis.tooltip.background.cornerRadius = 3;
    categoryAxis.tooltip.background.fill = am4core.color("#404C5B");
    categoryAxis.tooltip.background.strokeWidth = 0;
  }

  private setValueAxis(): void {
    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.maxPrecision = 0;
    valueAxis.min = 0;
  }

  private setColumns(): void {
    const series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "label";
    series.columns.template.width = am4core.percent(50);
    series.columns.template.maxWidth = 100;


    series.columns.template.adapter.add("fill", (fill, target) => am4core.color(SEVERITY_LEVEL_WITH_COLOR[target.dataItem.dataContext['severity'] as SeverityEnum]) || fill);

    series.columns.template.adapter.add("stroke", (fill, target) => am4core.color(SEVERITY_LEVEL_WITH_COLOR[target.dataItem.dataContext['severity'] as SeverityEnum]) || fill);

    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#404C5B");
    series.tooltip.background.strokeWidth = 0;
    series.columns.template.tooltipText = "{valueY}";

    series.columns.template.adapter.add('tooltipText', (_text, target) => {
      const amount = target.tooltipDataItem.dataContext?.['value'] || 0;
      return this.i18nService.translate('dashboard.admin.sharedFiles.severity.distribution.valueAxis.tooltip.text', { amount });
    });

    const labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.dy = -15;

    this.setLegend(series);
  }

  private setLegend(serie: ColumnSeries): void {
    const legend = new am4charts.Legend();

    legend.fontSize = 14;
    legend.itemContainers.template.togglable = false;
    legend.marginBottom = 40;
    legend.marginTop = 20;
    legend.position = "top";
    legend.contentAlign = "left";

    serie.events.on("ready", () => {
      legend.data = Object.values(SeverityEnum)
        .filter((value) => typeof value === 'number')
        .map((severity: number) => this.createLegendLabel(severity));
    });

    legend.useDefaultMarker = true;
    const markerTemplate = legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;

    const label = this.chart.createChild(am4core.Label);
    label.text = this.i18nService.translate('dashboard.admin.sharedFiles.severity.distribution.label.severity.legend');
    label.fontSize = 15;
    label.fontWeight = '600';
    label.isMeasured = false;
    label.x = 10;
    label.y = 0;

    this.chart.legend = legend;
  }

  private createLegendLabel(severity: SeverityEnum): { name: string, fill: string; } {
    const translate = this.i18nService.translate(`dashboard.admin.sharedFiles.severity.distribution.severity.${severity}`);
    const percentageText = this.data?.severityLegend[severity];

    return {
      name: `${translate} (${percentageText})`,
      fill: SEVERITY_LEVEL_WITH_COLOR[severity],
    };
  }
}
