<div
    page
    [appPagePaths]="['mdRespond', 'connectors']"
    [hasTopSection]="true"
    [tooltipTitle]="'org_connector.title.tooltip'"
    (organizationChanged)="onOrganizationChanged($event)"
>
    <ng-container *ngIf="!isVarMode || organizationEcoId" topSection>
        <div class="subscriptions-cards-container">
            <capacity-card
                logo="icon_datasource_souscrit"
                [loading]="isLoading"
                [capacityNumber]="organization?.totalSubscribedCapacity"
                [topDescription]="'detection.datasource.page.capacity.total' | t"
                [bottomDescription]="'detection.datasource.page.capacity.day' | t">
            </capacity-card>

            <capacity-card
                logo="icon_users_connector_page"
                [loading]="isLoading"
                [capacityNumber]="organization?.totalSubscribedUsers"
                [topDescription]="'detection.connector.page.user.total' | t">
            </capacity-card>

            <capacity-card
                logo="icon_total_endpoints"
                [loading]="isLoading"
                [capacityNumber]="organization?.totalSubscribedEndpoints"
                [topDescription]="'detection.connector.page.endpoint.total' | t">
            </capacity-card>

            <capacity-card
                logo="icon_server_connector_page"
                [loading]="isLoading"
                [capacityNumber]="organization?.totalSubscribedServers"
                [topDescription]="'detection.connector.page.server.total' | t">
            </capacity-card>
        </div>
    </ng-container>

    <span
        *ngIf="isVarMode && !organizationEcoId"
        class="no-org-transition"
        >
        {{ 'organizations_select.notSelected.message' | t }}
    </span>

    <inventory-layout>

        <!-- 05/09/24 Filtres à redéfinir éventuellement dû à l'intégration des nouveaux connecteurs SOAR, on cache donc la section pour l'instant... -->
        <!-- <ng-container actions *ngIf="organization">
            <ui-table-total-tool [total]="totalConnectorInstances" [locale]="i18n.currentLocale"></ui-table-total-tool>
            <ui-multi-select [multiSelectData]="categoryFiltersData" (onOptionSelect)="handleFilterOptionSelected($event, 'categories')"></ui-multi-select>
            <ui-multi-select [multiSelectData]="functionFiltersData" (onOptionSelect)="handleFilterOptionSelected($event, 'functions')"></ui-multi-select>
            <ui-multi-select [multiSelectData]="vendorFiltersData" (onOptionSelect)="handleFilterOptionSelected($event, 'vendors')"></ui-multi-select>
        </ng-container> -->

        <app-connector-inventory-tab 
            *ngIf="organization" 
            [organization]="organization"
            [connectorFilters]="filtersChanged"
            (connectorsDataEvent)="handleListData($event)">
        </app-connector-inventory-tab>
    </inventory-layout>
</div>