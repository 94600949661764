import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MultiSelectDataFactory } from '@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { TableFilterToolDataFactory } from '@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { SnackbarService } from '@common/modules/notice/implementations/snackbar/snackbar.service';
import { Notice, NoticeLevels } from '@common/modules/notice/notice.service';
import { PlaybooksApiService } from '@common/services/api/respond/playbooks/playbooks.api';
import { IPlaybookTaskTemplateRepresentation, PlaybookTaskPhases, PlaybookTaskRelation } from '@common/services/api/respond/playbooks/playbooks.definitions';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { TaskOriginEnum } from 'projects/@respond/playbooks/components/playbook-tasks-drag-and-drop.component/playbook-tasks-drag-and-drop.component';

export type PlayBookTaskTemplate = PlaybookTaskRelation | IPlaybookTaskTemplateRepresentation;

@Component({
  selector: 'incident-tasks',
  templateUrl: './incident-tasks.component.html',
  styleUrls: [ './incident-tasks.component.scss' ],
})
export class IncidentTasksComponent implements OnInit, OnDestroy {
  @Input() public incident: Incident;
  @Input() public isReadonly: boolean;
  
  public phaseFiltersSelect: MultiSelectData;
  public taskTypeFilterSelect: TableFilterData;

  public phaseFiltersEnum = PlaybookTaskPhases;
  public displayFilter: TaskOriginEnum = TaskOriginEnum.Library;

  public taskTemplates: any[] = [];

  public name: string = null;
  private phases: string[];

  private fetchingMore = false;
  private type = 'LIBRARY';
  private from = 0;
  private total = null;
  public pickedItem: PlayBookTaskTemplate;

  constructor(
    private readonly multiSelectFactory: MultiSelectDataFactory,
    private tableFilterToolDataFactory: TableFilterToolDataFactory,
    private playbooksApiService: PlaybooksApiService,
    private snackbarService: SnackbarService,
    public readonly i18n: I18nService
  ) { 
  }

  ngOnInit(): void {
    this.phaseFiltersSelect = this.multiSelectFactory.create(this.phaseFiltersEnum, [], 'detection.playbook.detail.task.library.phase.');
    this.taskTypeFilterSelect = this.tableFilterToolDataFactory.create(TaskOriginEnum, 'detection.playbook.detail.task.library.type.', this.displayFilter, true);

    this.loadTaskTemplates(false);
  }

  ngOnDestroy(): void {
  }

  public onTypeChange(event) {
    this.type = event;
    this.loadTaskTemplates(false);
  }

  public onNameChange(value: string): void {
    this.name = value;
    this.loadTaskTemplates(false);
  }

  public onPhaseChange(values: string[]): void {
    this.phases = values;
    this.loadTaskTemplates(false);
  }

  public fetchMoreTaskTemplates() {
    if (!this.fetchingMore) {
      this.fetchingMore = true
      this.loadTaskTemplates(true).finally(() => this.fetchingMore = false);
    }
  }

  public async loadTaskTemplates(append: boolean) {
    if (!append) {
      this.taskTemplates = [];
      this.from = 0;
      this.total = null;
    }

    await this.playbooksApiService.getPlaybookTaskTemplates({ custom: this.type === 'CUSTOM', from: this.from, name: this.name, phases: this.phases, language: this.i18n.currentLocale }).then((response) => {
      this.taskTemplates = [...this.taskTemplates, ...(response?.items || [])];
      this.from = this.taskTemplates.length;
      this.total = response?.total;
    }).catch((err) => {
        this.snackbarService.handleNotice(new Notice(err, NoticeLevels.ERROR));
    });
  }

  notAllowedPredicate() {
    return false;
  }

}
