import { Component, Input } from '@angular/core';
import { AlertingUsecaseListItem } from 'projects/@common/services/api/respond/usecase/usecase.definition';

@Component({
  selector: 'usecase-indicators',
  templateUrl: './usecase-indicators.component.html',
  styleUrls: [ './usecase-indicators.component.scss' ],
})
export class UsecaseIndicatorsComponent {
  @Input() public usecase: AlertingUsecaseListItem;
  @Input() public fixedGridDisplay = true;

  public readonly silencedIcon = "/assets/favicons/alerts/ico_silent_24x24.svg";
  public readonly blacklistedIcon = "/assets/favicons/alerts/icon_blacklisted_24x24.svg";
  public readonly filterIcon = "/assets/favicons/alerts/ico_filter_22x22_list.svg";
  public readonly hiddenFilterIcon = "/assets/favicons/icon_filter_hidden.svg"
  public readonly whitelistIcon = "/assets/favicons/alerts/ico_liste_exception_22x22_list.svg";
}
