import { Injectable } from '@angular/core';
import { DaysIndex, HoursIndex, WeeklyTimeSchedule } from 'projects/@common/components/weekly-time-schedule/weekly-time-schedule.component';

export type FilterPeriod = { start: { day: DaysIndex, hour: HoursIndex }, end: { day: DaysIndex, hour: HoursIndex } };

type DayFilterPeriod = { start: HoursIndex, end: HoursIndex };

@Injectable({
  providedIn: 'root',
})
export class FilterPeriodsService {
  private readonly PADDING_DAYS_NUMBER: number = 6;

  public generatePeriods(filtersSelectedTimePeriod: WeeklyTimeSchedule): FilterPeriod[] {
    if (filtersSelectedTimePeriod) {
      filtersSelectedTimePeriod.sort((timeA, timeB) => timeA.day < timeB.day ? -1 : 1);
      const periods: { start: { day: DaysIndex, hour: HoursIndex }, end: { day: DaysIndex, hour: HoursIndex } }[] = [];

      filtersSelectedTimePeriod.forEach((time, mainIndex) => {
        time.hours.sort((a, b) => a < b ? -1 : 1);

        if (time.hours.length > 0) {
          const dayPeriods = this.generatePeriodsWithinTheDay(time.hours);
          if (mainIndex > 0) {
            if (this.shouldMergePeriods(dayPeriods, periods)) {
              const lastPeriod = periods[periods.length - 1];
              const todayFirstPeriod = dayPeriods[0];
              if (this.isLastPeriodDayBeforeActual(lastPeriod, time)) {
                if (this.isSamePeriod(todayFirstPeriod, lastPeriod)) {
                  this.mergeAdjacentPeriod(periods, dayPeriods, time, todayFirstPeriod);
                }
              }
            }
          }
          dayPeriods.forEach((period) => {
            periods.push({
              start: {
                day: time.day,
                hour: period.start,
              },
              end: {
                day: time.day,
                hour: period.end,
              },
            });
          });
        }
      });
      return periods;
    }
    return [];
  }

  public filterPeriodsToWeeklyTimeSchedule(periods: FilterPeriod[]): WeeklyTimeSchedule {
    const hoursPerDayList = [ [], [], [], [], [], [], [] ];
    periods.forEach((period) => {
      if (period.start.day === period.end.day) {
        hoursPerDayList[period.start.day].push(...this.generateRange(period.start.hour, period.end.hour + 1));
      } else {
        hoursPerDayList[period.start.day].push(...this.generateRange(period.start.hour, 24));
        hoursPerDayList[period.end.day].push(...this.generateRange(0, period.end.hour + 1));
        const daysBetweenStartAndEnd =  this.generateRange(period.start.day + 1, period.end.day);
        daysBetweenStartAndEnd.forEach((index) => hoursPerDayList[index].push(...this.generateRange(0, 24)));
      }
    });

    return Array.from(Array(7).keys()).map((index) => index).map((weekdayIndex) => ({
      day: weekdayIndex,
      hours: hoursPerDayList[weekdayIndex],
    })) as WeeklyTimeSchedule;
  }

  private shouldMergePeriods(dayPeriods: DayFilterPeriod[], periods: { start: { day: DaysIndex; hour: HoursIndex; }; end: { day: DaysIndex; hour: HoursIndex; }; }[]) {
    return dayPeriods.length > 0 && periods.length > 0;
  }

  private isLastPeriodDayBeforeActual(lastPeriod: { start: { day: DaysIndex; hour: HoursIndex; }; end: { day: DaysIndex; hour: HoursIndex; }; }, time: { day: DaysIndex; hours: HoursIndex[]; }) {
    return lastPeriod.end.day + 1 === time.day;
  }

  private mergeAdjacentPeriod(periods: { start: { day: DaysIndex; hour: HoursIndex; }; end: { day: DaysIndex; hour: HoursIndex; }; }[], dayPeriods: DayFilterPeriod[], time: { day: DaysIndex; hours: HoursIndex[]; }, todayFirstPeriod: DayFilterPeriod) {
    const yesterdayLastPeriod = periods.pop();
    dayPeriods.shift();
    periods.push({
      start: yesterdayLastPeriod.start,
      end: {
        day: time.day,
        hour: todayFirstPeriod.end,
      },
    });
  }

  private isSamePeriod(todayFirstPeriod: DayFilterPeriod, lastPeriod: { start: { day: DaysIndex; hour: HoursIndex; }; end: { day: DaysIndex; hour: HoursIndex; }; }) {
    return todayFirstPeriod.start === 0 && lastPeriod.end.hour === 23;
  }

  private generatePeriodsWithinTheDay(hours: HoursIndex[]): DayFilterPeriod[] {
    let lastValue: HoursIndex;
    const result = [];
    let currentPeriod: { start: HoursIndex, end?: HoursIndex };
    hours.forEach((hour, index) => {
      if (lastValue != null) {
        if (this.isEndOfAPeriod(hour, lastValue)) {
          currentPeriod.end = lastValue;
          result.push({ ...currentPeriod });
          currentPeriod.start = hour;
          currentPeriod.end = undefined;
        }
      } else if (index === 0) {
        currentPeriod = {
          start: hour,
        };
      }
      lastValue = hour;
      if (index === hours.length - 1) {
        result.push({
          start: currentPeriod.start,
          end: currentPeriod.end ? currentPeriod.end : hour,
        });
      }
    });
    return result;
  }

  private isEndOfAPeriod(hour: HoursIndex, lastValue: HoursIndex): boolean {
    return hour - 1 !== lastValue;
  }

  private convertToNumber(value?: string): number {
    if (value != null) {
      return +value;
    }
    return -1;
  }

  private generateRange(start: number, end: number): number[] {
    return Array.from({ length: (end - start) }, (_, k) => k + start);
  }
}
