import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { EditWithModalComponent } from "projects/@common/components/edit-with-modal/edit-with-modal.component";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";

import { IncidentDifferenceService } from "../../../../../../services/incident.difference.service";
import { TabNamesEnum } from "../../../../incidents-details.component";

@Component({
  selector: 'app-zoho-link-editor',
  templateUrl: './zoho-link-editor.component.html',
  styleUrls: [ './zoho-link-editor.component.scss' ],
})
export class ZohoLinkEditorComponent implements OnInit {
  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;

  public zohoUrl: { name: string, url: string; };

  public zohoTicketUrl: string = null;

  public zohoTicketId: string = null;

  public zohoFormGroup: UntypedFormGroup;

  @Input()
  public currentValue: { url: string, id: string; };

  @Input()
  public disabled: boolean = false;

  @Output()
  public saveEvent = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private i18nService: I18nService,
    private incidentDifferenceService: IncidentDifferenceService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.zohoTicketUrl = this.currentValue.url;
    this.zohoTicketId = this.currentValue.id;
    this.apply();
  }

  public get isOpen(): boolean {
    return !!this.editWithModalComponent?.isOpen();
  }

  public open(): void {
    if (!this.disabled && !this.editWithModalComponent.isOpen()) {
      this.editWithModalComponent.open();
      this.incidentDifferenceService.handleEditingStates({
        tabNameEnum: TabNamesEnum.DETAILS,
        itemId: "zohoLink",
        hasUnsavedChange: true,
      });
    }
  }

  public close(): void {
    this.editWithModalComponent.close();
    this.incidentDifferenceService.handleEditingStates({
      tabNameEnum: TabNamesEnum.DETAILS,
      itemId: "zohoLink",
      hasUnsavedChange: false,
    });
  }

  public setZohoUrl(value: string): void {
    this.zohoTicketUrl = value;
  }

  public clearUrl(): void {
    this.zohoTicketUrl = null;
  }

  public setZohoId(value: string): void {
    this.zohoTicketId = value;
  }

  public clearId(): void {
    this.zohoTicketId = null;
  }

  public cancel(): void {
    this.zohoFormGroup.get('id').setValue(this.currentValue?.id || null);
    this.zohoFormGroup.get('url').setValue(this.currentValue?.url || null);
    this.close();
  }

  public apply(): void {
    this.zohoFormGroup.get('id').setValue(this.zohoTicketId);
    this.zohoFormGroup.get('url').setValue(this.zohoTicketUrl);
    if (this.zohoFormGroup.get('id').value && this.zohoFormGroup.get('url').value) {
      this.zohoUrl = {
        name: this.i18nService.translate('incidents.container.page.details.tab.detail.zoho.name.placeholder', { name: this.zohoFormGroup.get('id').value }),
        url: this.zohoFormGroup.get('url').value,
      };
    } else {
      this.zohoUrl = null;
    }
  }

  public handleConfirmation(value: boolean): void {
    if (value) {
      this.apply();
      this.saveEvent.emit(this.zohoFormGroup.getRawValue());
      this.close();
    } else {
      this.cancel();
    }
  }

  private initForm(): void {
    this.zohoFormGroup = this.formBuilder.group({
      url: this.currentValue?.url || null,
      id: this.currentValue?.id || null,
    });
  }
}
