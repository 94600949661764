import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { SecretStoreContainer } from './containers/secret-store.container';
import { RespondComponentsModule } from 'projects/@respond/components/respond.components.module';
import { SecretStoreRoutingModule } from './secret-store-routing.module';
import { SecretStoreDetailsComponent } from './components/secret-store-details/secret-store-details.component';
import { SecretStoreDrawerContainer } from './containers/drawer/secret-store-drawer.container';
import { SharedModule } from '../../shared/shared.module';
import { I18nModule } from '@common/modules/i18n/i18n.module';
import { FeatureSharedModule } from '@common/modules/shared/feature.shared.module';
import { UiKitModule } from '@ui-kit/uikit.module';

@NgModule({
  declarations: [ SecretStoreContainer, SecretStoreDrawerContainer, SecretStoreDetailsComponent ],
  providers: [],
  imports: [
    RespondComponentsModule,
    SecretStoreRoutingModule,
    UiKitModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxsFormPluginModule,
    FeatureSharedModule,
    SharedModule,
    I18nModule,
  ],
  exports: [],
})
export class SecretStoreModule {}
