<button
  ui-button
  class="back-button"
  color="listActionFlat"
  icon="icon_back"
  [custom-icon]="true"
  [isSvg]="true"
  icon-size="medium"
  (click)="handleClickBackButton()"
>
  {{ 'common.action.return' | t }}
</button>

<h1 class="title">
  <span>
    {{"detection.playbook.update.header.title" | t}}
  </span>
  <span class="playbook-name-and-suffix">
    {{playbook.name | translatedObject}} - {{playbook.suffix}}
  </span>
</h1>

<div class="info">
  <div class="info--organization-name">
    {{organization.name}}
  </div>
</div>

<p class="instructions">
  {{ "detection.playbook.update.header.instructions" | t}}
</p>