import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'usecase-alert-date',
  templateUrl: './usecase-alert-date.component.html',
  styleUrls: [ './usecase-alert-date.component.scss' ],
})
export class UsecaseAlertDateComponent {
  public readonly NOW = new Date();

  public alertFrom: number;

  public alertTo: number;

  public hourFrom = 0;

  public hourTo = 0;

  @Output()
  public timeEmitter = new EventEmitter<{ from: number; to: number }>();

  public onDateSelection(date: string, event: number): void {
    if (date === 'start') {
      this.alertFrom = event;
    }

    if (date === 'end') {
      this.alertTo = event;
    }
  }

  public setStartHourTime(time: number): void {
    this.hourFrom = time;
  }

  public setEndHourTime(time: number): void {
    this.hourTo = time;
  }

  public apply(): void {
    this.emitTimestamp();
  }

  private emitTimestamp(): void {
    this.timeEmitter.emit({ from: this.alertFrom + this.hourFrom, to: this.alertTo + this.hourTo });
  }
}
