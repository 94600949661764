import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { NoticeService } from "projects/@common/modules/notice/notice.service";
import { HistoriesApi } from "projects/@common/services/api/respond/histories/histories.api";
import { IPlayookHistory } from "projects/@common/services/api/respond/histories/histories.definition";
import { PlaybookHistoryRequest } from 'projects/@common/services/api/respond/histories/models/playbookHistoryReques';
import { IPlaybookDetail } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { ResponseUtils } from "projects/@common/utils/response-utils";

@Component({
  selector: 'app-playbook-history',
  templateUrl: './playbook-history.component.html',
  styleUrls: [ './playbook-history.component.scss' ],
})
export class PlaybookHistoryComponent implements OnInit, OnDestroy {
  @Input() playbook: IPlaybookDetail;

  public readonly REQUEST_LIMIT: number = 25;
  public isLoadingHistory: boolean = false;
  public isLoadMoreHistory: boolean = false;

  public playbookHistoryResponse: ResponseUtils<IPlayookHistory>;
  public playbookHistory: IPlayookHistory[];

  public readonly historicColumnsDef = [
    {
      label: '',
      field: 'icon',
      type: UiStaticTableRowType.EXPENDABLE_ICON,
      showIcon: true,
      width: '26px',
      minWidth: '26px',
      isResizable: false,
      noSorting: true,
    },
    {
      label: 'date',
      field: 'createdAt',
      width: '200px',
      minWidth: '200px',
      type: UiStaticTableRowType.DATE_AND_HOUR_AND_AGO,
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('alerts.drawer.tab.history.table.column.user'),
      field: 'identityName',
      width: '20%',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('alerts.drawer.tab.history.table.column.operation'),
      field: 'message',
      width: '80%',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      noSorting: true,
    },
  ];

  constructor(
    private readonly historyService: HistoriesApi,
    public readonly i18n: I18nService,
    public readonly noticeService: NoticeService
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.loadIncidentHistory();
  }

  private loadIncidentHistory(): void {
    const request = new PlaybookHistoryRequest({ organizationId: this.playbook.organizationId, playbookId: this.playbook.id });
    this.historyService.listPlaybookHistory(request).subscribe(
      (response) => {
        this.playbookHistoryResponse = response;
        this.playbookHistory = this.playbookHistoryResponse.getItems();
      },
      (error) => this.noticeService.error("incidents.history.fetch.error"),
      () => this.isLoadingHistory = false
    );
  }

  public loadMoreIncidentHistory(): void {
    if (!this.isLoadMoreHistory && this.canLoadMore()) {
      this.isLoadMoreHistory = true;
      const from = this.playbookHistoryResponse.getNextItem();
      const getAlertObservableRequest = new PlaybookHistoryRequest({ organizationId: this.playbook.organizationId, playbookId: this.playbook.id, from });
      this.historyService.listPlaybookHistory(getAlertObservableRequest).subscribe(
        (response) => {
          this.playbookHistoryResponse = response;
          this.playbookHistory = this.playbookHistory.concat(this.playbookHistoryResponse.getItems());
        },
        (error) => this.noticeService.error('incidents.history.fetch.error'),
        () => this.isLoadMoreHistory = false
      );
    }
  }

  public canLoadMore(): boolean {
    return this.playbookHistoryResponse.canLoadMore();
  }
}
