<div>
  <settings-edition-card
    [icon]="'icon_shield_delay_cropped.svg'"
    [title]="'settings.resources.revision.notification.frequency.title' | t"
    [canSave]="isValid"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <p>
    {{ 'settings.resources.revision.notification.frequency.description.readonly' | t }} 
    </p>
    <div class="labels">
      <div class="label-setting">
        <p
          [innerHTML]="
            'settings.resources.revision.notification.frequency.default.frequency'
              | t: { defaultDelay: revisionNotificationFrequencyForm.value.delay }
          "
        ></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editing>
  <p>{{ 'settings.resources.revision.notification.frequency.description' | t }}</p>
  <button *ngIf="revisionNotificationFrequencyForm.value.delay !== 3" class="default-values" (click)="restoreDefaults()">
    {{ 'settings.notification.frequency.default.values' | t }}
  </button>
  <form [formGroup]="revisionNotificationFrequencyForm" class="form selector" *ngIf="!isLoading; else loading">
    <ui-field-group>
      <ui-select
        [options]="delayOptions"
        name="delay"
        control="delay"
        [group]="revisionNotificationFrequencyForm"
        [label]="'settings.resources.revision.notification.frequency.delay.label' | t"
        [required]="true"
      >
      </ui-select>
    </ui-field-group>
  </form>
</ng-template>
