import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IExecuteToolboxActionsResponse } from '@common/services/api/respond/actions-toolbox/actions-toolbox.definition';
import { Incident } from '@common/services/api/respond/incidents/models/incident';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IEntityForDisplay, IEntityPayloadDisplayField } from 'projects/@common/services/api/respond/entities/entities.definition';
import { normalizeString } from 'projects/@common/utils/utils';
import { IEntityPageContext } from 'projects/@respond/components/respond-entity-with-collapse/respond-entity-with-collapse.component';

@Component({
  selector: 'incident-entities',
  templateUrl: './incident-entities.component.html',
  styleUrls: [ './incident-entities.component.scss' ],
})
export class IncidentEntitiesComponent implements OnInit {
  @Input() entities: IEntityForDisplay[];
  @Input() isLoadingEntities: boolean;
  @Input() pageContext: IEntityPageContext;
  @Input() isReadonly: boolean;
  @Input() incident: Incident;

  public filteredEntities: IEntityForDisplay[];
  public filters = { searchText: "" };

  constructor(public readonly i18n: I18nService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entities?.currentValue) {
      this.filterEntities();
    }
  }

  public trackById(index: number, entity: IEntityForDisplay) {
    return entity.id;
  }

  public createEntity() {
    throw new Error("Not implemented");
  }

  public handleSearchTextFilterChange(text: string) {
    this.filters.searchText = text;
    this.filterEntities();
  }

  private filterEntities() {
    const normalizedSearchText = this.filters.searchText ? normalizeString(this.filters.searchText) : null;
    this.filteredEntities = this.entities.filter((entity: IEntityForDisplay) => {
      if (normalizedSearchText) {
        const label = normalizeString(entity.label);
        const payload = entity.payloadDisplayFields.map((field: IEntityPayloadDisplayField) => normalizeString(field.value)).join(" ");
        const searchableString = `${label} ${payload}`;
        if (!searchableString.includes(normalizedSearchText)) {
          return false;
        }
      }
      return true;
    });
  }
}
