import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Incident } from "@common/services/api/respond/incidents/models/incident";
@Component({
  selector: 'collaboration-time',
  templateUrl: './collaboration-time.component.html',
  styleUrls: [ './collaboration-time.component.scss' ],
})
export class CollaborationTimeComponent {
  @Input() incident: Incident;

  @Output() public readonly updateCollaborationTimeMinutesEvent = new EventEmitter<number>();

  constructor() { }

  public get isToggled(): boolean {
    return !!this.incident.collaborationTime;
  }

  public get isDisabled(): boolean {
    return !!this.incident.closedAt;
  }

  public toggleCollaborationTime(event: boolean): void {
    if(event){
      this.updateCollaborationTimeMinutesEvent.emit(1);
      return;
    }
    this.updateCollaborationTimeMinutesEvent.emit(null);
  }
}
