import { Component, Input, OnInit } from '@angular/core';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';

@Component({
  selector: 'filter-connector-name',
  templateUrl: './filter-connector-name.component.html',
  styleUrls: [ './filter-connector-name.component.scss' ],
})
export class FilterConnectorNameComponent implements OnInit {
  @Input() filter: Filter;

  public connectorNames: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.connectorNames = [ ...new Set(this.filter.usecases.map((usecase) => `${usecase.datasourceType}`)) ];
  }
}
