export interface OrphanGuestUser {
  name: string;
  email: string;
  requester: string;
  invitedOn: number;
  orphanSince: number;
}

export enum GuestUserStatusEnum {
  ACTIVE = 'ACTIVE',
  PENDING_DELETION = 'PENDING_DELETION',
  TO_DELETE = 'TO_DELETE'
}

export interface IGuestUser {
  o365UserId: string;
  managerId: string;
  organizationId: string;
  status: GuestUserStatusEnum;
  weeksOfRisk: number;
  expirationDate: string;
  firstName: string;
  lastName: string;
  email: string;
}

export class GuestUser implements IGuestUser {
  public o365UserId: string;
  public managerId: string;
  public organizationId: string;
  public status: GuestUserStatusEnum;
  public weeksOfRisk: number;
  public expirationDate: string;
  public firstName: string;
  public lastName: string;
  public email: string;

  constructor(properties: IGuestUser) {
    Object.assign(this, properties);
  }

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

export class GuestAdmin {
  public id: string;
  public mail: string;
  public firstName?: string;
  public lastName?: string;
  public displayName?: string;
  public invalidLicense: boolean;

  constructor(data: any) {
    Object.assign(this, data);
  }

  public get o365UserId(): string {
    return this.id;
  }

  public get name(): string {
    return this.firstName && this.lastName
      ? `${this.firstName} ${this.lastName}`
      : this.displayName;
  }

  public get avatar(): any {
    return {
      firstName: this.name,
      type: 'Member',
    };
  }
}

