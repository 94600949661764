import { Axis, ColumnSeries, DateAxis, ValueAxis, XYChart, XYCursor } from '@amcharts/amcharts4/charts';
import { addLicense, color, create } from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IncidentStatsHistoryDto } from '@common/services/api/respond/incidents/models/getIncidentStatsHistory.definition';
import { ITimeInterval } from 'projects/@common/components/date-interval/date-interval.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { GenericDashboardTimeRangeEnum, getTimestampsFromDynamicRange } from '../../helpers/getTimestampsFromRange';

@Component({
  selector: 'incidents-timeline',
  templateUrl: './incidents-timeline.component.html',
  styleUrls: [ './incidents-timeline.component.scss' ],
})
export class IncidentsTimelineComponent implements OnInit, AfterViewInit, OnChanges {
  public readonly TIME_RANGE_ENUM = GenericDashboardTimeRangeEnum;

  @Input() public currentOrganization: IAutomationOrganization;
  public numberIncidentOpen: number = 0;
  public numberIncidentClose: number = 0;
  public currentTimeRange: GenericDashboardTimeRangeEnum = this.TIME_RANGE_ENUM.LAST_7_DAYS;
  public chartData: { timestamp: number, totalIncidentOpen: number, totalIncidentClose: number }[] = [];
  public dateRange: ITimeInterval;
  public incidentQueryParams;
  public isLoading: boolean = false;

  private readonly CHART_ABSCISSA_VALUE_KEY = 'timestamp';
  private chart: XYChart;

  constructor(
    public readonly i18n: I18nService,
    private readonly incidentsApi: IncidentsApi,
    private readonly notice: NoticeService
  ) {
    addLicense('CH259911983');
  }

  ngOnInit(): void {
    this.fetchIncidentGraphData();
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentOrganization) {
      this.currentOrganization = changes.currentOrganization.currentValue;
    }
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  public handleTimeRangeChange(event: GenericDashboardTimeRangeEnum): void {
    this.currentTimeRange = event;
    this.fetchIncidentGraphData();
  }

  private fetchIncidentGraphData(): void {
    this.handleDateRange();
    this.isLoading = true;
    this.incidentsApi.getIncidentsStatsHistory({
      params: {
        organizationId: this.currentOrganization?.ecoId,
        from: this.dateRange.dateFrom,
        to: this.dateRange.dateTo,
      },
    })
      .then((result) => {
        if (result) {
          this.chartData = result.map((item: IncidentStatsHistoryDto) => ({
            timestamp: item.timestamp,
            totalIncidentOpen: item.total.open,
            totalIncidentClose: item.total.close,
          }));
          this.chart.data = this.chartData;
          this.numberIncidentOpen = this.chartData.reduce((cnt, item) => cnt + item.totalIncidentOpen, 0);
          this.numberIncidentClose = this.chartData.reduce((cnt, item) => cnt + item.totalIncidentClose, 0);
        }
      })
      .catch(() => this.notice.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)))
      .finally(() => this.isLoading = false);
  }

  private handleDateRange(): void {
    const { fromDate, toDate } = getTimestampsFromDynamicRange(this.currentTimeRange.toString(), { timeIsUtc: true });
    this.dateRange = {
      dateFrom: fromDate,
      dateTo: toDate,
    };
  }

  private createChart(): void {
    const chart = create(`INCIDENTS_PER_DAY_GRAPH_ID`, XYChart);
    chart.data = this.chartData;
    chart.hiddenState.properties.opacity = 0;
    chart.svgContainer.htmlElement.style.height = '225px';
    this.createChartAbscissa(chart);
    this.createChartOrdinate(chart);
    this.createChartSeries(chart);
    this.chart = chart;
  }

  private createChartAbscissa(chart: XYChart): void {
    const xAxis = chart.xAxes.push(new DateAxis());
    xAxis.renderer.maxLabelPosition = 0.99;
    xAxis.timezoneOffset = 0;
    xAxis.tooltipDateFormat = "MMM d, yyyy";
    xAxis.baseInterval =  {
      timeUnit: "day",
      count: 1,
    };
    xAxis.renderer.grid.template.location = 0;
  }

  private createChartOrdinate(chart: XYChart): Axis {
    const yAxis = chart.yAxes.push(new ValueAxis());
    yAxis.min = 0;
    return yAxis;
  }

  private createChartSeries(chart: XYChart): void {
    this.createSerie(chart, 'totalIncidentOpen', '5ECC99');
    this.createSerie(chart, 'totalIncidentClose', '004C2A');
    chart.cursor = new XYCursor();
  }

  private createSerie(chart: XYChart, dataName: string, colorHex: string) {
    const columnSerie = chart.series.push(new ColumnSeries());
    columnSerie.dataFields.dateX = this.CHART_ABSCISSA_VALUE_KEY;
    columnSerie.dataFields.valueY = dataName;
    columnSerie.columns.template.tooltipText = '{valueY}';
    columnSerie.columns.template.stroke = color(`#${colorHex}`);
    columnSerie.columns.template.fill = color(`#${colorHex}`);
  }
}
