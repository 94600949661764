<ng-container *ngIf="!isLoading">
  <revision-frequency [currentSettings]="currentSettings" [isLoading]="isLoading"></revision-frequency>
  <revision-notification-frequency [currentSettings]="currentSettings" [isLoading]="isLoading"></revision-notification-frequency>
  <expiration-notification-frequency [currentSettings]="currentSettings" [isLoading]="isLoading"></expiration-notification-frequency>
  <app-guest-user-resource-add  *display="{flags: [featureFlagEnum.GUESTS_MANAGEMENT]}" [currentSettings]="currentSettings" [isLoading]="isLoading"></app-guest-user-resource-add>
</ng-container>

<ng-container *ngIf="isLoading">
  <ui-spinner size="medium"></ui-spinner>
</ng-container>
