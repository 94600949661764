import { RequestDirection } from "projects/@common/definitions/consoles.enum";

import { HistoryType } from "../../histories/histories.definition";
import { IGetIncidentAlertsRequest, ListAlertsOrderBy } from "../alerts.definitions";


export class GetIncidentAlertsRequest implements IGetIncidentAlertsRequest {
  incidentId?: string;
  organizationId: string;
  type: HistoryType;
  from?: number = 0;
  size?: number = 15;
  orderBy?: ListAlertsOrderBy = ListAlertsOrderBy.createdAt;
  direction?: RequestDirection = RequestDirection.desc;

  constructor(jsonObj: IGetIncidentAlertsRequest) {
    Object.assign(this, jsonObj);
  }

  public getQueryParams(): Partial<IGetIncidentAlertsRequest> {
    return {
      from: this.from,
      size: this.size,
      orderBy: this.orderBy,
      direction: this.direction,
    };
  }
}
