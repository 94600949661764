import { ViewportRuler } from '@angular/cdk/overlay';
import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { TeamsAuthService } from '@common/services/teams-auth.service';
import { Select, Store } from '@ngxs/store';
import { IamApiService } from 'projects/@common/services/api/iam/iam.api';
import { IdentitiesApi } from 'projects/@common/services/api/iam/identities/identities.api';
import { NotificationsApiService } from 'projects/@common/services/api/notifications/notifications-api.service';
import { LocalStorageKeysEnum, StorageService } from 'projects/@common/services/storage.service';
import { Observable, Subscription } from 'rxjs';
import { MobileService } from '../../services/mobile.service';
import { EcoSessionState, SetUserNotificationEmail, SetVarMode } from '../session/state/session.state';
import { DrawerService } from './components/drawer/services/drawer.service';
import { DrawerState } from './components/drawer/stores/drawer.store';
import { EmailInitializerComponent } from './components/email-initializer/email-initializer.component';
import { OrgTypeEnum } from './definitions/base-sidebar.service';
import { AppState, AppStatus, ResetPopover, SetAppStatus, SetExpanded } from './stores/app.state';
import { SessionService } from '../session/session.service';
import { UserActivityDialogComponent } from './components/user-activity-dialog/user-activity-dialog.component';
import { TimeUtil } from '@ui-kit/services/time-util';

export enum ConsoleType {
  SG = "sg",
  ORG = "org",
  ADM = "adm"
}

const CONSOLE_SESSION_TIMEOUT = {
  [ConsoleType.ADM]: TimeUtil.minuteDuration * 28, // 28 minutes
  [ConsoleType.ORG]: TimeUtil.minuteDuration * 8, // 8 minutes
};

const ENABLED_CONSOLE_SESSION_MONITORING: ConsoleType[] = [ ConsoleType.ADM, ConsoleType.ORG ];

@Component({
  selector: 'section.private-layout',
  templateUrl: './private.layout.html',
  styleUrls: [ './private.layout.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class PrivateLayout implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: true }) drawer: MatSidenav;

  @Select(AppState.isReady) public isReady$: Observable<boolean>;

  @Select(AppState.title) public title$: Observable<string>;

  @Select(AppState.isExpanded) public isExpanded$: Observable<boolean>;

  @Select(DrawerState.isEditing) public isEditing$: Observable<boolean>;

  @Select(DrawerState.isExpanded) public isDrawerExpanded$: Observable<boolean>;

  @Select(DrawerState.isFullWidth) public isDrawerFullWidth$: Observable<boolean>;

  private routerEventsSubscription: Subscription;

  private ruleChangeSubscription: Subscription;

  private currentOrganizationSubscription: Subscription;

  private sessionRefreshInterval = null;

  private userActivityTimeout = null;
  private userActivityWarningDialogOpened = false;

  public constructor(
    public mobileService: MobileService,
    private drawerService: DrawerService,
    private iam: IamApiService,
    private storageService: StorageService,
    private teamsAuthService: TeamsAuthService,
    private usersApiService: UsersApiService,
    private sessionService: SessionService,
    protected readonly store: Store,
    protected readonly ruler: ViewportRuler,
    protected readonly router: Router,
    protected readonly notificationsApiService: NotificationsApiService,
    protected readonly dialog: MatDialog,
    protected readonly identityApi: IdentitiesApi,
    @Inject('DISABLE_HEADER') public disableHeader: any,
    @Inject('DISABLE_SIDEBAR') public disableSidebar: any,
    @Inject('CONSOLE_TYPE') public consoleType: ConsoleType
  ) {
    this.routerEventsSubscription = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.drawerService.hideDrawer();
      }
    });
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
    this.ruleChangeSubscription?.unsubscribe();
    this.currentOrganizationSubscription?.unsubscribe();

    if (this.sessionRefreshInterval) {
      clearInterval(this.sessionRefreshInterval);
    }

    if (this.userActivityTimeout) {
      clearTimeout(this.userActivityTimeout);
    }
  }

  public async ngOnInit(): Promise<void> {
    this.mobileService.isMobile();
    this.ruleChangeSubscription = this.ruler.change().subscribe(this.handleResize.bind(this));

    const varOrg = this.store.selectSnapshot(EcoSessionState.organization).tags.includes(OrgTypeEnum.VAR);
    const result = await this.iam.describePermissions({
      queries: [
        {
          id: 'isVar',
          action: 'detect:ListOrganizations',
          resourceLabel: 'md:*::detect:organizations/all',
        },
      ],
    });

    await this.store.dispatch(new SetVarMode(result[0].shouldShow && varOrg && this.consoleType !== ConsoleType.SG)).toPromise();

    if (this.consoleType !== ConsoleType.SG) {
      try {
        const notificationIdentity = await this.notificationsApiService.getUserIdentity();

        if (!notificationIdentity.confirmed) {
          const idpIdentity = await this.identityApi.describeIdpIdentity();
          this.dialog.open(EmailInitializerComponent, {
            disableClose: true,
            width: '500px',
            panelClass: 'custom-dialog',
            data: {
              email: idpIdentity.mail,
            },
          });
        } else {
          await this.store.dispatch(new SetUserNotificationEmail(notificationIdentity.email)).toPromise();
        }
      } catch {
        // Dont Fail Here othewise the complete application won't load
      }
    }

    this.loadDefaultSidebarCollapsedState();

    await this.store.dispatch(new SetAppStatus({ status: AppStatus.Ready })).toPromise();

    if (TeamsAuthService.isInTeams) {
      this.updateUserNotificationLanguage();
    } else {
      // If the session of the console must be monitored;
      if (ENABLED_CONSOLE_SESSION_MONITORING.includes(this.consoleType)) {
        // Refreshing user's session every 30 minutes;
        this.sessionRefreshInterval = setInterval(async () => {
          // Try to refresh the idp token
          try {
            const res =  await this.iam.refreshToken();
            this.sessionService.refreshToken(res);
          } catch (error) {
            console.log(`An error occured while refreshing user's token;`);
          }
        }, TimeUtil.minuteDuration * 30); // 30 minutes

        // Detecting user inactivity
        this.setUserActivityTimeout();
      }
    }
  }

  @HostListener('window:click', [ '$event' ])
  @HostListener('window:keydown', [ '$event' ])
  @HostListener('window:wheel', [ '$event' ])
  private refreshUserActivity(event: Event) {
    // If the session of the console must be monitored
    // If we are not embedded in MS Teams
    // If the warning dialog is not displayed
    if (ENABLED_CONSOLE_SESSION_MONITORING.includes(this.consoleType) && !TeamsAuthService.isInTeams && !this.userActivityWarningDialogOpened) {
      // console.log(`User activity detected: ${event.type}`);
      clearTimeout(this.userActivityTimeout);
      this.setUserActivityTimeout();
    }
  }

  private setUserActivityTimeout(): void {
    this.userActivityTimeout = setTimeout(() => {
      this.userActivityWarningDialogOpened = true;
      this.dialog.open(UserActivityDialogComponent, {
        disableClose: true,
        width: '500px',
        panelClass: 'custom-dialog',

      }).componentInstance
        .closeEvent
        .subscribe((outcome: boolean) => {
          this.userActivityWarningDialogOpened = false;
          // if the user want to keep his session alive
          // restarting the timeout
          if (outcome) {
            this.setUserActivityTimeout();
          }
        });
    }, CONSOLE_SESSION_TIMEOUT[this.consoleType]);
  }

  // Dans l'app team, on vient synchroniser la langue du Teams du user pour avoir la bonne durant les notifications du BOT.
  private updateUserNotificationLanguage(): void {
    const userTeamLanguage = this.teamsAuthService?.locale;

    if (!userTeamLanguage) {
      return;
    }

    const currentNotificationLanguage = this.storageService.getLocal(LocalStorageKeysEnum.NotificationLanguage);

    const userGuid = this.store.selectSnapshot(EcoSessionState.userGuid);
    let userLocale: { userId: string, locale: string; };

    if (currentNotificationLanguage) {
      try {
        userLocale = JSON.parse(currentNotificationLanguage);
      } catch (e) {
        userLocale = null;
      }
    }

    if (userLocale?.userId !== userGuid || userTeamLanguage !== userLocale.locale) {
      this.usersApiService.updateUserNotificationLanguage(userTeamLanguage)
        .subscribe(() => {
          const localeObject = { userId: userGuid, locale: userTeamLanguage };
          this.storageService.setLocal(LocalStorageKeysEnum.NotificationLanguage, JSON.stringify(localeObject));
        });
    }
  }

  public handleResize(): void {
    if (this.store.selectSnapshot((state) => state.app.popover) !== null) {
      this.store.dispatch(new ResetPopover()).toPromise();
    }
  }

  public async handleToggleMenu(): Promise<void> {
    const isExpanded = !this.store.selectSnapshot((state) => state.app.expanded);
    this.store.dispatch(new SetExpanded({ expanded: isExpanded }));
    this.storageService.setSidebarCollapsedState(!isExpanded);
  }

  private loadDefaultSidebarCollapsedState(): void {
    const isSidebarCollapsed = this.storageService.getSidebarCollapsedState();
    this.store.dispatch(new SetExpanded({ expanded: !isSidebarCollapsed }));
  }
}
