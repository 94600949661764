import { RuleSet } from "angular2-query-builder";
import { FilterPeriod } from "projects/@respond/filters/filter-periods.service";
import { FilterType, IFilter, IFilterBaseRepresentation, IFilterUsecases } from "../filters.definitions";


export class Filter implements IFilter {
  id: string;
  organizationId: string;
  organizationName: string;
  organizationEcoId: string;
  usecases: IFilterUsecases[];
  name: string;
  description: string;
  createdById: string;
  createdByName: string;
  createdByOrganization: boolean;
  createdAt: number;
  conditions?: RuleSet;
  filterType: FilterType;
  periods?: FilterPeriod[];
  monitors: string[];
  hidden: boolean;

  constructor(filterType: FilterType, parameters?: IFilterBaseRepresentation) {
    let filterToAssign: IFilter;
    if (!parameters) {
      // eslint-disable-next-line no-param-reassign
      filterToAssign = {
        id: null,
        usecases: [],
        monitors: [],
        organizationId: null,
        organizationName: null,
        organizationEcoId: null,
        name: null,
        description: null,
        createdById: null,
        createdByName: null,
        createdByOrganization: null,
        createdAt: null,
        conditions: null,
        filterType: filterType,
        periods: null,
        hidden: false,
      };
    } else {
      filterToAssign = {
        ...parameters,
        filterType: filterType,
      };
    }

    Object.assign(this, filterToAssign);
  }

  public get usecaseIds(): string[] {
    return this.usecases.map((usecase) => usecase.id);
  }

  public get now(): number {
    return Date.now();
  }
}
