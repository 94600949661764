<div page [appPagePaths]="['org', 'guestUsersManagement']" [disableOrganizationSelector]="true">
  <div class="container">
    <ui-tabs [color]="uiTabsColor" [borderBelowHeader]="true" [hasNoLeftPadding]="true" [hasWhiteBackroundColor]="false">
      <div class="tab-container">
        <ui-tab *display="{flags: [featureFlagEnum.GUESTS_EXPIRATION_MANAGEMENT]}" [tabTitle]="'guest.users.management.guest-list.title' | t"
                tabImageActive="assets/favicons/icon_tab_guest_users_active.svg" tabImageInactive="assets/favicons/icon_tab_guest_users.svg">
          <ng-template *ngTemplateOutlet="guestsTable"></ng-template>
        </ui-tab>
        <ui-tab *display="{flags: [featureFlagEnum.GUESTS_MANAGEMENT]}" [tabTitle]="'guestManagers.title' | t"
                tabImageActive="assets/favicons/icon_guest_admin_active.svg" tabImageInactive="assets/favicons/icon_guest_admin_inactive.svg">
          <ng-template *ngTemplateOutlet="guestManagers"></ng-template>
        </ui-tab>
        <ui-tab *display="{flags: [featureFlagEnum.GUESTS_EXPIRATION_MANAGEMENT]}" [tabTitle]="'guest.users.management.admin-list.title' | t"
                tabImageActive="assets/favicons/icon_role_24x24_active.svg" tabImageInactive="assets/favicons/icon_role_24x24_inactive.svg">
          <ng-template *ngTemplateOutlet="guestAdmins"></ng-template>
        </ui-tab>

      </div>
    </ui-tabs>
  </div>
</div>

<ng-template #guestsTable>
  <h2>{{ 'guest.users.management.guest-list.title' | t }}</h2>
  <p>{{ 'guest.users.management.guest-list.description' | t }}</p>
  <div class="table-container">
    <app-guest-users-management #guestManagement></app-guest-users-management>
  </div>
</ng-template>

<ng-template #guestManagers>
  <h2>{{ 'guestManagers.title' | t }}</h2>
  <p>{{ 'guestManagers.h2' | t }}</p>
  <div class="table-container">
    <guest-managers></guest-managers>
  </div>
</ng-template>

<ng-template #guestAdmins>
  <h2>{{ 'guest.users.management.admin-list.title' | t }}</h2>
  <p>{{ 'guest.users.management.admin-list.description' | t }}</p>
  <div class="table-container">
    <app-guest-user-admins (guestAdminActionEvent)="guestManagement.setCurrentUserRole()"></app-guest-user-admins>
  </div>
</ng-template>
