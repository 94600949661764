import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { User } from 'projects/@common/definitions/user.definition';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import {
  NoticeLevels,
  NoticeService
} from 'projects/@common/modules/notice/notice.service';
import {
  IdentityProviderTypes,
  List,
  ListUsersResponse
} from 'projects/@common/services/api/iam/identities/identities.api.definitions';
import { UsersApiService } from 'projects/@common/services/api/sg/user/userApi.service';
import { AccountArchiverDialogComponent } from 'projects/console-org/src/app/shared/dialogs/account-archiver-dialog/account-archiver-dialog.component';

@Component({
  selector: 'organization-archiver',
  templateUrl: './organization-archiver.component.html',
  styleUrls: [ './organization-archiver.component.scss' ],
})
export class OrganizationArchiverComponent implements OnInit {
  public usersAutocomplete = AutocompleteTypes.USERS;

  public userSelected: any;

  public userSelectedCopy: any;

  public isEditing = false;

  public isLoading = false;

  public openConfirmationPopup = false;

  public warningMessages = [ { messageKey: this.i18n.translate('securityModel.archiving.warningMessage') } ];

  private dialogRef: MatDialogRef<AccountArchiverDialogComponent>;

  private noArchiverHasBeenConfirmed = false;

  constructor(
    public i18n: I18nService,
    private usersApi: UsersApiService,
    private notice: NoticeService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getArchiver();
  }

  public get saveDisabled() {
    return (
      this.userSelected?.firstName === this.userSelectedCopy?.firstName
    );
  }

  public toggleEditMode(): void {
    this.isEditing = true;
  }

  public save(): void {
    const archiverId = this.userSelected?.o365UserId || null;

    if (archiverId || this.noArchiverHasBeenConfirmed) {
      this.isLoading = true;
      this.usersApi
        .postArchiver({
          archiverId,
        })
        .then(() => {
          this.getArchiver();
          this.isLoading = false;
          this.isEditing = false;
          this.noArchiverHasBeenConfirmed = false;

          this.notice.notifyUser({
            message: archiverId
              ? `securityModel.archiving.text.addAccount`
              : `securityModel.archiving.text.removeAccount`,
            level: NoticeLevels.SUCCESS,
          });
        });
    } else {
      this.dialogRef = this.dialog.open(AccountArchiverDialogComponent, {
        maxWidth: '50%',
        panelClass: 'custom-dialog',
      });
      this.dialogRef.componentInstance.saveClicked.subscribe((result) => {
        this.userSelected = null;
        this.noArchiverHasBeenConfirmed = true;
        this.save();
      });
    }
  }

  public cancel(): void {
    this.isEditing = false;
    this.userSelected = this.userSelectedCopy;
  }

  public removeUser(): void {
    this.userSelected = null;
  }

  public addUser(user: User) {
    this.removeUser();
    this.userSelected = user;
  }

  public usersAutocompleteSearchFunction = (params: any): Promise<
  List<ListUsersResponse, number | string, { idpType: IdentityProviderTypes; }>
  > => this.usersApi.listUsersWhoCanArchive({
    limit: 25,
    fulltext: params.search,
  });

  private getArchiver(): void {
    this.usersApi
      .getArchiver()
      .then((result) => {
        // eslint-disable-next-line no-multi-assign
        this.userSelected = this.userSelectedCopy = result;
      });
  }
}
