import { ResponseUtils } from "@common/utils/response-utils";
import { FieldMap, RuleSet } from "angular2-query-builder";
import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";
import { OrganizationPublicInfo } from "projects/@common/definitions/organization-public-info.definition";
import { FilterPeriod } from "projects/@respond/filters/filter-periods.service";
import { Observable } from "rxjs";

export interface IFilterRepresentation {
  id: string;
  usecases: IFilterUsecases[];
  monitors: string[];
  organizationId: string;
  organizationName: string;
  organizationEcoId: string;
  name: string;
  description: string;
  createdById: string;
  createdByName: string;
  createdByOrganization: boolean;
  createdAt: number;
  conditions?: any;
  periods?: FilterPeriod[];
  hidden: boolean;
}

export type FilterConditionField = {
  id: string;
};

export type IDescribeFilterResponse = {
  filter: IFilterRepresentation;
  organization: OrganizationPublicInfo;
  usecases: IFilterUsecases[];
  monitors: string[];
};

export interface ICreateFilterRequest {
  organizationId: string;
  name: string,
  description: string;
  usecaseIds: string[];
  monitors: string[];
  conditions: any;
  periods?: FilterPeriod[];
  isHidden: boolean;
}

export interface IUpdateFilterRequest {
  organizationId?: string;
  id: string;
  name: string,
  description: string;
  conditions: any;
  usecaseIds: string[];
  monitors: string[];
  periods?: FilterPeriod[];
  isHidden: boolean;
}

export interface IPrevisualizeQueryRequest {
  ruleset: RuleSet;
  periods: FilterPeriod[];
  organizationId?: string;
}

export interface IDeleteFiltersRequest {
  id: string;
  organizationId?: string;
}

export interface IDeleteFiltersResponse {
  filter: IFilterRepresentation;
  success: boolean;
}

export interface IListFilterFieldsRequest {
  organizationId?: string;
}

export interface IListFiltersRequest {
  usecaseId?: string;
  organizationId?: string;
  hiddenOnly?: boolean;
}

export interface IDescribeFilterRequest {
  id: string;
  organizationId?: string;
}

export interface IFiltersListViewParams {
  isReadonly: boolean;
  showOrgSelector: boolean;
  tableColumns: Array<Object>;
  visibleOnly?: boolean;
  hiddenOnly?: boolean;
}

export interface IFilterUsecases {
  id: string,
  name: ITranslatedField,
  usecaseCatalogId: string;
  datasourceType: string;
};

export enum FiltersViewModeEnum {
  SIMPLE_LIST = 'list'
  // GROUPED_BY_USECASE = 'groupedUsecase',
}

export enum ListDirectionEnum {
  asc = 'ASC',
  desc = 'DESC'
}

export enum ListFiltersOrderBy {
  name = 'name',
  createdAt = 'createdAt'
}

export interface IListFiltersRequest {
  organizationId?: string;
  types?: string[];
  from?: number;
  size?: number;
  orderBy?: ListFiltersOrderBy;
  direction?: ListDirectionEnum;
  searchText?: string;
  visibleOnly?: boolean;
  hiddenOnly?: boolean;
}

export interface IFiltersApi {
  getFilterConditionFields(request?: IListFilterFieldsRequest): Promise<FieldMap>;

  listFilters(request?: IListFiltersRequest): Observable<ResponseUtils<IFilterRepresentation>>;

  describeFilter(request: IDescribeFilterRequest): Observable<IDescribeFilterResponse>;

  createFilter(request: ICreateFilterRequest): Observable<IDescribeFilterResponse>;

  updateFilter(request: IUpdateFilterRequest): Observable<IDescribeFilterResponse>;

  deleteFilter(request: IDeleteFiltersRequest): Promise<IDeleteFiltersResponse>;

  previsualizeQuery(request: IPrevisualizeQueryRequest): Promise<any>;
}
