<div class="collapsable-card">
  <div
    class="collapsable-card--header"
    (click)="toggleCollapse()"
  >
    <div
      class="collapsable-card--header--complete-title"
      [ngClass]="isCollapsed ? 'border-collapsed' : 'border-open'"
    >
      <ui-icon
        class="collapsable-card--header--chevron"
        modifier="on-header"
        [name]="isCollapsed ? 'chevron-right' : 'chevron-down'"
        size="medium"
      >
      </ui-icon>
      <div class="collapsable-card--header--title">
        <ng-content select="[header]"></ng-content>
      </div>
    </div>
  </div>

  <div
    class="collapsable-card--content"
    *ngIf="!isCollapsed"
  >
    <ng-content></ng-content>
  </div>
</div>