<div class="container">
    <ui-field-group>
      <ui-static
        [label]="'histories.date' | t"
        [value]="history.createdAt | dt:{locale: i18nService.currentLocale, withTime: true, withSeconds: true}"
      ></ui-static>
    </ui-field-group>

    <ui-field-group>
      <ui-static
        [label]="'histories.user' | t"
        [value]="history.identityName"
      ></ui-static>
    </ui-field-group>

    <ui-field-group>
      <ui-static
        [label]="'histories.message' | t"
        [value]="history.message | translatedObject"
      ></ui-static>
    </ui-field-group>

    <ui-field-group *ngIf="history.comment">
      <ui-static
        [label]="'histories.comment' | t"
        [value]="history.comment"
      ></ui-static>
    </ui-field-group>

    <div *ngIf="showOldNewValue()">
      <ui-field-group>
        <ui-static
          [label]="'histories.field' | t"
          [value]="history.payload.field"
        ></ui-static>
      </ui-field-group>

      <ui-field-group *ngIf="history.payload.reasons">
        <ui-static
          [label]="'histories.reasons' | t"
          [htmlValue]="history.payload.reasons"
        ></ui-static>
      </ui-field-group>

      <ui-field-group *ngIf="history.payload.contentType === 'date'">
        <ui-static
          [label]="'histories.oldValue' | t"
          [value]="history.payload.oldValue | dt:{locale: i18nService.currentLocale, withTime: true, withSeconds: true}"
        ></ui-static>
      </ui-field-group>

      <ui-field-group *ngIf="history.payload.contentType === 'date'">
        <ui-static
          [label]="'histories.newValue' | t"
          [value]="history.payload.newValue | dt:{locale: i18nService.currentLocale, withTime: true, withSeconds: true}"
        ></ui-static>
      </ui-field-group>

      <ui-field-group *ngIf="history.payload.contentType === 'text'">
        <ui-static
          [label]="'histories.oldValue' | t"
          [value]="history.payload.oldValue"
        ></ui-static>
      </ui-field-group>

      <ui-field-group *ngIf="history.payload.contentType === 'text'">
        <ui-static
          [label]="'histories.newValue' | t"
          [value]="history.payload.newValue"
        ></ui-static>
      </ui-field-group>

      <ui-field-group *ngIf="history.payload.contentType === 'i18n'">
        <ui-static
          [label]="'histories.oldValue' | t"
          [value]="history.payload.oldValue | translatedObject"
        ></ui-static>
      </ui-field-group>

      <ui-field-group *ngIf="history.payload.contentType === 'i18n'">
        <ui-static
          [label]="'histories.newValue' | t"
          [value]="history.payload.newValue | translatedObject"
        ></ui-static>
      </ui-field-group>

      <div *ngIf="history.payload.contentType === 'html'">
        <ui-static [label]="'histories.oldValue' | t"></ui-static>
        <div [innerHTML]="history.payload.oldValue"></div>
      </div>

      <div *ngIf="history.payload.contentType === 'html'">
        <ui-static [label]="'histories.newValue' | t"></ui-static>
        <div [innerHTML]="history.payload.newValue"></div>
      </div>

      <div *ngIf="history.payload.contentType === 'json'">
        <ui-static [label]="'histories.oldValue' | t"></ui-static>
        <div><pre>{{ jsonToString(history.payload.oldValue) }}</pre></div>
      </div>

      <div *ngIf="history.payload.contentType === 'json'">
        <ui-static [label]="'histories.newValue' | t"></ui-static>
        <div><pre>{{ jsonToString(history.payload.newValue) }}</pre></div>
      </div>
    </div>

    <div *ngIf="showRawPayload()">
      <ui-static [label]="'histories.payload' | t"></ui-static>
      <pre class="json-content">{{ payload() | jsonPrettier:6}}</pre>
    </div>
  </div>
