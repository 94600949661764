import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Eco } from '@common/definitions/eco';
import { Accumulatables, Accumulator, AccumulatorsState } from '@common/modules/accumulator/accumulator.store';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from '@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from '@common/modules/layout/components/drawer/services/drawer.service';
import { EcoSessionState } from '@common/modules/session/state/session.state';
import { IamApiService } from '@common/services/api/iam/iam.api';
import { SensitiveFilesApiService } from '@common/services/api/sg/files/sensitive-files-api.service';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { SecretsApiService } from '@common/services/api/tools/secrets/secrets.api';
import { ISecretIntegrations } from '@common/services/api/tools/secrets/secrets.definitions';
import { Select } from '@ngxs/store';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import {
  DlpType,
  OrganizationSensitiveFilesSettings,
} from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { SecretStoreDrawerContainer } from 'projects/console-org/src/app/tools/secret-store/containers/drawer/secret-store-drawer.container';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'dlp-activation',
  templateUrl: './dlp-activation.component.html',
  styleUrls: ['./dlp-activation.component.scss'],
})
export class DlpActivationComponent implements OnInit, OnDestroy {
  @Select(EcoSessionState.organization) public currentOrganization$: Observable<Eco.IOrganization>;
  @Select(AccumulatorsState.accumulators) private accumulators$: Observable<Accumulator[]>;

  @Input() public currentSettings: OrganizationSensitiveFilesSettings;
  @Input() public isLoading = false;

  public isActivated: boolean;
  public isEditing = false;
  public readonly engines = [
    { value: 'QoHash', displayValue: 'Qohash Qostodian' },
    { value: 'Purview', displayValue: 'Microsoft Purview' },
  ];
  public activationForm: FormGroup;
  public secretIntegrations: ISecretIntegrations[] = [];
  public hasSecretConfigured = false;
  public secrets = [];
  public isLocalLoading = false;
  public isGlobalAdmin = false;
  public purviewAppConsentUrl: string;
  public clickedUrl = true;
  private reloadSubscription: Subscription;
  private organizationSubscription: Subscription;
  private currentAccumulatorCount = 0;
  private currentOrganizationId: string;

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private noticeService: NoticeService,
    private secretsApiService: SecretsApiService,
    private sensitiveFilesApiService: SensitiveFilesApiService,
    private drawerService: DrawerService,
    private i18n: I18nService,
    private iamApiService: IamApiService,
    private route: ActivatedRoute,
    private router: Router,
    private usersApiService: UsersApiService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const gotAdminConsent = this.route.snapshot.queryParams.admin_consent === 'True';
    if (gotAdminConsent) {
      this.organizationsApiService
        .updateOrganizationSettings({
          newDlpSettings: {
            isActivated: true,
            dlpType: DlpType.Purview,
            grantedPurviewConsent: true,
          },
        })
        .then((response) => this.initPurviewPopulate(response))
        .finally(() => this.router.navigate([]));
    } else {
      this.initSecretPopulate();
    }
  }

  public get currentEngineDisplay(): string {
    return this.engines.find((engine) => this.activationForm?.value.engine === engine.value)?.displayValue;
  }

  ngOnDestroy() {
    this.reloadSubscription.unsubscribe();
    this.organizationSubscription.unsubscribe();
  }

  private initPurviewPopulate(response) {
    this.updateUIafterSettingsUpdate(response);
    this.initSecretPopulate();
  }

  private initSecretPopulate() {
    this.initSubscription();
    this.refreshSecrets();
  }

  public openSecretCreationDrawer() {
    this.drawerService.replaceCurrentDrawer(SecretStoreDrawerContainer, {
      component: DrawerPageEnum.createSecretDrawer,
      data: { title: this.i18n.translate('secrets.create.title'), templates: this.secretIntegrations },
    });
  }

  public selectEngine(engine: { value: DlpType; displayValue: string }) {
    if (engine.value === DlpType.QoHash) {
      this.activationForm?.get('secretId').setValidators([Validators.required]);
      this.activationForm?.get('secretId').updateValueAndValidity();
      this.clickedUrl = true;
    } else {
      this.clickedUrl = this.currentSettings?.grantedPurviewConsent || !this.purviewAppConsentUrl;
      this.activationForm?.get('secretId').setValidators([]);
      this.activationForm?.get('secretId').updateValueAndValidity();
    }
  }

  public cancel() {
    this.initForm(this.currentSettings);
  }

  public save(): void {
    this.isLocalLoading = true;
    this.organizationsApiService
      .updateOrganizationSettings({
        newDlpSettings: {
          isActivated: this.isActivated,
          dlpType: this.activationForm.value.engine,
          secretId: this.activationForm.value.secretId,
          grantedPurviewConsent: this.isActivated && this.activationForm.value.engine === DlpType.Purview,
        },
      })
      .then((response) => {
        // @@@ needs to be adjusted for when we have more than one
        this.updateUIafterSettingsUpdate(response);
        this.noticeService.notifyUser({
          message: 'settings.dlp.activation.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.dlp.activation.snack.error',
          level: NoticeLevels.ERROR,
        });
      })
      .finally(() => (this.isLocalLoading = false));
  }

  public openConsentUrl() {
    const url = this.purviewAppConsentUrl;
    this.clickedUrl = true;
    window.location.href = url;
  }

  public get openedUrl(): boolean {
    return this.currentSettings?.grantedPurviewConsent || this.clickedUrl;
  }

  public toggleSilencingActivation() {
    this.isActivated = !this.isActivated;
  }

  private get newlyCreatedSecret(): string {
    return this.secrets.sort((a, b) => b.createdAt - a.createdAt)[0]?.value;
  }

  private initSubscription(): void {
    this.reloadSubscription = this.accumulators$.subscribe((accumulators) => {
      const count = accumulators
        .filter((accumulator) => accumulator.accumulatable === Accumulatables.SECRETS_LIST)
        .shift()?.accumulator;
      if (count && count !== this.currentAccumulatorCount) {
        this.refreshSecrets(true);
        this.currentAccumulatorCount = count;
      }
    });
  }

  private updateUIafterSettingsUpdate(response) {
    // @@@ Needs ajustment si on en gere plus qu'un en meme temps
    if (this.isActivated && !this.currentSettings?.dlpType) {
      this.sensitiveFilesApiService.onboardOrganizationInDlp(this.currentOrganizationId, {
        type: this.activationForm.value.engine,
        secretId: this.activationForm.value.secretId,
      });
    }
    Object.assign(this.currentSettings, response);
    this.cdr.detectChanges();
    this.initForm(response);
  }

  private refreshSecrets(useNewlyCreatedSecret = false) {
    this.isLocalLoading = true;
    this.organizationSubscription = this.currentOrganization$.subscribe((currentOrganization) => {
      if (!this.currentOrganizationId || useNewlyCreatedSecret) {
        this.currentOrganizationId = currentOrganization.id;
        Promise.all([...this.qoHashApiCalls, this.purviewApiCalls])
          .then(([secretIntegrations, secrets, isGlobalAdmin, purviewAppConsentUrl]) => {
            this.secretIntegrations = [secretIntegrations.find((integration) => integration.templateName === 'QoHash')];
            this.hasSecretConfigured = secrets.total > 0;
            this.secrets = secrets.items.map((secret) => ({
              value: secret.id,
              displayValue: secret.name,
              createdAt: secret.createdAt,
            }));
            this.isGlobalAdmin = isGlobalAdmin;
            this.purviewAppConsentUrl = purviewAppConsentUrl?.consentUrl;
            this.initForm(this.currentSettings, useNewlyCreatedSecret);
          })
          .finally(() => (this.isLocalLoading = false));
      }
    });
  }

  private get qoHashApiCalls(): Promise<any>[] {
    const calls: Promise<any>[] = [
      this.secretsApiService.listSecretIntegrations(),
      this.secretsApiService.listSecrets(this.currentOrganizationId, { searchType: 'QoHash' }),
      this.usersApiService.userIsGlobalAdmin(),
    ];
    return calls;
  }

  private get purviewApiCalls(): Promise<any> {
    return this.sensitiveFilesApiService.getPurviewActivationState(`${window.location.href}?tabIndex=1`);
  }

  private initForm(currentSettings?: OrganizationSensitiveFilesSettings, useNewlyCreated = false) {
    const engine = useNewlyCreated ? this.activationForm.value.engine : currentSettings?.dlpType;
    this.clickedUrl = engine !== DlpType.Purview || !this.purviewAppConsentUrl;
    this.isActivated = !!engine;
    if (useNewlyCreated) {
      this.activationForm?.get('secretId')?.patchValue(this.newlyCreatedSecret);
    } else {
      this.activationForm = new FormGroup({
        engine: new FormControl(engine ?? null, Validators.required),
        secretId: new FormControl(
          currentSettings?.secretId ?? null,
          engine === DlpType.QoHash ? [Validators.required] : []
        ),
      });
    }
  }
}
