import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { IConnectorInstance } from 'projects/@common/services/api/detect/connectorsOrg/model/ConnectorOrg';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';

export interface ConnectorInstancePopupInput {
  connector: any;
  connectorInstances: IConnectorInstance[];
  organization: IAutomationOrganization;
}

@Component({
  selector: 'app-connector-instance-popup',
  templateUrl: './connector-instance-popup.component.html',
  styleUrls: [ './connector-instance-popup.component.scss' ],
})
export class ConnectorInstancePopupComponent implements OnInit {
  @Input()
  public readonly connectorName: string;
  @Input()
  public readonly connectorInstances: IConnectorInstance[];
  public formGroup: UntypedFormGroup;
  public connectorInstancesOptions;
  public selectedConnectorInstanceId: string;

  @Output()
  public successEvent = new EventEmitter<IConnectorInstance>();

  @Output()
  public cancelEvent = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ConnectorInstancePopupInput,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly datePipe: DTPipe,
    private readonly i18n: I18nService
  ) {
    this.connectorName = data.connector['translations']?.name;
    this.connectorInstances = data.connectorInstances.sort((a, b) => a.name?.localeCompare(b.name));
  }
  ngOnInit(): void {
    const defaultConnectorInstance = this.connectorInstances.length >= 1 ? this.connectorInstances[0] : null;
    this.formGroup = this.formBuilder.group({
      connectorInstance: this.formBuilder.control(defaultConnectorInstance, Validators.required),
    });
    this.connectorInstancesOptions = this.getConnectorInstancesOptions();
  }

  public get selectedInstanceName(): string {
    return this.formGroup.getRawValue().connectorInstanceName;
  }

  public instanceSelected(instanceIdSelected: string): void {
    this.selectedConnectorInstanceId = instanceIdSelected;
  }

  public confirmActionTrigger(): void {
    const instance = this.connectorInstances.find((instance) => instance.id === this.selectedConnectorInstanceId);
    this.successEvent.emit(instance);
  }

  public cancelActionTrigger(): void {
    this.cancelEvent.emit();
  }

  private getConnectorInstancesOptions() {
    return this.connectorInstances.map((instance) => ({
      value: instance.id,
      displayValue: instance.name ?
        instance.name : `${instance.translations.name} (${this.datePipe.transform(instance.createdAt, { locale: this.i18n.currentLocale, withTime: false })} - ${instance.id})`,
    }));
  }
}
