<ui-input-group [formGroup]="group">
  <div class="uploader-label-wrapper flex-box">
    <label
      ui-label
      *ngIf="label"
      [name]="name"
      [required]="required"
    >
    {{ label }}
  </label>
  </div>
  <div class="file-upload">
    <input
      type="file"
      (change)="onFileChange($event.target.files)"
      class="file-input"
      [accept]="accept"
    />
  </div>
</ui-input-group>
