import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";
import { OrganizationPublicInfo } from "projects/@common/definitions/organization-public-info.definition";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { WhitelistOperator } from "./models/Whitelist";

export enum WhitelistResolution {
  AUTO_CLOSE_EXPECTED = 'AUTO_CLOSE_EXPECTED', 
  AUTO_CLOSE_FALSE_POSITIVE = 'AUTO_CLOSE_FALSE_POSITIVE', 
  FINALIZE = 'FINALIZE'
}

export interface IWhitelistRepresentation {
  id: string;
  usecases: IWhitelistUsecases[];
  organizationId: string;
  organizationName: string;
  organizationEcoId: string;
  name: string;
  description: string;
  startsAt: number;
  endsAt: number;
  createdById: string;
  createdByName: string;
  createdByOrganization: boolean;
  createdAt: number;
  periods?: IWhitelistPeriod[];
  conditions?: IWhitelistCondition[];
  resolution?: WhitelistResolution;
}

export type IDescribeWhitelistResponse = {
  whitelist: IWhitelistRepresentation;
  organization: OrganizationPublicInfo;
  usecases: IWhitelistUsecases[];
};

export interface ICreateWhitelistRequest {
  organizationId: string;
  name: string,
  description: string;
  usecaseIds: string[];
  startsAt: number;
  endsAt?: number;
  periods?: IWhitelistPeriod[];
  conditions: IWhitelistCondition[];
  resolution: WhitelistResolution,
}

export interface IUpdateWhitelistRequest {
  organizationId?: string;
  id: string;
  name: string,
  description: string;
  startsAt: number;
  endsAt?: number;
  periods?: IWhitelistPeriod[];
  conditions: IWhitelistCondition[];
  usecaseIds: string[];
  resolution: WhitelistResolution,
}

export interface IDeleteWhitelistsRequest {
  id: string;
  organizationId?: string;
}

export interface IDeleteWhitelistsResponse {
  whitelist: IWhitelistRepresentation;
  success: boolean;
}

export interface IListWhitelistsRequest {
  usecaseId?: string
  organizationId?: string;
}

export interface IDescribeWhitelistRequest {
  id: string;
  organizationId?: string;
}

export interface IWhitelistsListViewParams {
  isReadonly: boolean;
  tableColumns: Array<Object>;
}

export interface IWhitelistPeriod {
  dayOfWeek: number; // Sunday = 0, Monday = 1
  fromHour: number; // 0 to 23
  toHour?: number; // 0 to 23
  fromMinute?: number; // 0 to 59
  toMinute?: number; // 0 to 59
}

export interface IWhitelistCondition {
  id: string;
  value: string;
  operator: WhitelistOperator;
  jsonPath?: string;
  ecsFields?: string[];
}

export interface IWhitelistUsecases {
  id: string,
  name: ITranslatedField,
  usecaseCatalogId: string;
  datasourceType: string;
};

export interface IWhitelistStatus {
  value: WhitelistStatusEnum;
  translationKey: string;
  icon: string;
}

export enum WhitelistStatusEnum {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED"
}

export enum WhitelistsViewModeEnum {
  SIMPLE_LIST = 'list',
  GROUPED_BY_STATUS = 'groupedStatus'
  // GROUPED_BY_USECASE = 'groupedUsecase',
}

export enum ListDirectionEnum {
  asc = 'ASC',
  desc = 'DESC'
}

export enum ListWhitelistsOrderBy {
  name = 'name',
  createdAt = 'createdAt'
}

export interface IListWhitelistsRequest {
  organizationId?: string;
  types?: string[];
  from?: number;
  size?: number;
  orderBy?: ListWhitelistsOrderBy;
  direction?: ListDirectionEnum;
  searchText?: string;
}

export enum WhitelistConditionFieldTypeEnum {
  COUNTRY = 'COUNTRY',
  PROTOCOL = 'PROTOCOL',
  IP = 'IP',
  EMAIL = 'EMAIL',
  TEXT = 'TEXT',
  JSON_PATH = 'JSON_PATH'
}

export type IWhitelistConditionField = {
  id: string;
  name: ITranslatedField;
  type: WhitelistConditionFieldTypeEnum;
  ecsFields: string[];
};

export type IGetWhitelistFieldsRequest = {
  organizationId?: string;
};

export interface IWhitelistsApi {
  listWhitelists(request?: IListWhitelistsRequest): Observable<ResponseUtils<IWhitelistRepresentation>>;

  getWhitelistConditionFields(request?: IGetWhitelistFieldsRequest): Promise<IWhitelistConditionField[]>;

  describeWhitelist(request: IDescribeWhitelistRequest): Observable<IDescribeWhitelistResponse>;

  createWhitelist(request: ICreateWhitelistRequest): Observable<IDescribeWhitelistResponse>;

  updateWhitelist(request: IUpdateWhitelistRequest): Observable<IDescribeWhitelistResponse>;

  deleteWhitelist(request: IDeleteWhitelistsRequest): Promise<IDeleteWhitelistsResponse>;

}
