<div
  page
  [appPagePaths]="['org', 'securityModel']"
  [isEditing]="isEditing"
  [isSaving]="isSaving"
  [isValid]="
    groupTemplateForm.templateForm?.valid &&
    groupTemplateForm.channelsForms?.valid &&
    groupTemplateForm.lifecycleForm?.valid &&
    areTreesValid &&
    defaultApprovingValid
    "
  [actionButtonText]="groupTemplateForm.isCreating ? 'common.action.create' : 'common.action.save'"
  [returnIcon]="!isEditing"
  returnPath="csp/templates"
  (onAction)="save()"
  (onCancel)="toggleEditMode()"
  [disableOrganizationSelector]="true"
>
  <ui-card>
    <div
      *ngIf="!groupTemplateForm.isCreating && !isEditing && !isLoading"
      class="margin--small--bottom"
    >
      <button
        icon="icon_modify"
        [custom-icon]="true"
        [isSvg]="true"
        ui-button
        color="linkFlat"
        (click)="toggleEditMode()"
      >
        {{ "common.action.edit" | t }}
      </button>
      <button
        icon="icon_copy"
        [custom-icon]="true"
        [isSvg]="true"
        ui-button
        color="linkFlat"
        (click)="duplicateForm()"
      >
        {{ "common.action.duplicate" | t }}
      </button>

      <button
        ui-button
        color="linkFlat"
        icon="icon_delete"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="medium"
        (click)="openConfirmation()"
      >
        {{ "common.action.delete" | t }}
      </button>
    </div>

    <form class="form" ui-form *ngIf="!isLoading; else loading">
      <ui-field-group>
        <ui-switch-field-static
          [isEditing]="isEditing"
          [label]="'groupTemplates.table.name' | t"
          name="name"
          control="name"
          [locale]="i18n.currentLocale"
          [value]="groupTemplate?.name"
          [required]="true"
          [group]="groupTemplateForm.templateForm"
          maxlength="50"
        >
        </ui-switch-field-static>
      </ui-field-group>

      <ui-field-group>
        <ui-switch-text-static
          [isEditing]="isEditing"
          [label]="'groupTemplates.table.description' | t"
          name="description"
          control="description"
          [value]="groupTemplate?.description"
          [required]="true"
          [group]="groupTemplateForm.templateForm"
          maxlength="200"
        >
        </ui-switch-text-static>
      </ui-field-group>

      <ui-field-group>
        <ng-container *ngIf="isEditing">
          <ui-select
            [options]="typeOptions"
            name="type"
            [group]="groupTemplateForm.templateForm"
            control="type"
            [label]="'groupTemplates.table.type' | t"
            [required]="true"
            (optionClick)="onTypeSelected($event)"
          >
          </ui-select>
          <ui-select
            [options]="stateOptions"
            name="state"
            [group]="groupTemplateForm.templateForm"
            control="state"
            [label]="'groupTemplates.table.state' | t"
            [required]="true"
          >
          </ui-select>
        </ng-container>
        <ng-container *ngIf="!isEditing">
          <ui-static
            [label]="'groupTemplates.table.type' | t"
            [value]="groupTemplate?.type | t"
          >
          </ui-static>
          <ui-static
            [label]="'groupTemplates.table.state' | t"
            [value]="'groupTemplates.state.' + groupTemplate?.state | t"
          >
          </ui-static>
        </ng-container>
      </ui-field-group>


      <ui-field-group class="lifecycle-container">
        <ui-select
          *ngIf="isEditing"
          [options]="expirationOptions"
          name="exiprationTrigger"
          control="expirationTrigger"
          [group]="groupTemplateForm.lifecycleForm"
          [label]="'groupTemplates.lifecycle.expirationType' | t"
          [required]="groupTemplateForm.isSetToExpire"
          (optionClick)="expirationTrigger = $event"
        >
        </ui-select>
        <ui-static
          *ngIf="!isEditing"
          [label]="'groupTemplates.lifecycle.expirationType' | t"
          [value]="'groupTemplates.lifecycle.expirationType.' + groupTemplate.lifecycle.expirationTrigger | t"
        >
        </ui-static>
        <ui-switch-field-static
          class="ui-select-margins"
          [isEditing]="isEditing"
          name="expirationDuration"
          [locale]="i18n.currentLocale"
          control="expirationDuration"
          [group]="groupTemplateForm.lifecycleForm"
          [label]="'groupTemplates.lifecycle.days' | t"
          [value]="groupTemplate?.lifecycle?.expirationDuration"
          [required]="groupTemplateForm.isSetToExpire"
          maxlength="4"
        >
        </ui-switch-field-static>
      </ui-field-group>
      <ui-switch-field-static
        class="ui-select-margins revision"
        [isEditing]="isEditing"
        name="certifyExpirationDuration"
        control="certifyExpirationDuration"
        [locale]="i18n.currentLocale"
        [group]="groupTemplateForm.templateForm"
        [label]="'groupTemplates.certifyExpiration.months' | t"
        [value]="groupTemplate?.certifyExpirationDuration"
        [required]="true"
        maxlength="4"
      >
      </ui-switch-field-static>

        <div class="approving-container">
          <default-approving
              [defaultApprovers]="defaultApprovers"
              [isEditing]="isEditing"
              [groupTemplateForm]="groupTemplateForm"
              (onDefaultApproverDelete)="deleteDefaultApprover($event)"
              (onClearApprovers)="defaultApprovers = []"
          >
          </default-approving>
        </div>

      <ui-collapsable-card *ngIf="!isSharepointGroup" [cardTitle]="'groupTemplates.channels.title' | t">
        <div class="group-template--section">
          <div *ngFor=" let channelForm of groupTemplateForm.channelsForms?.controls; let last = last"
            class="flex-box group-template--section--channels" [ngClass]="{ 'group-template--section--channels--border': !last || isEditing }">
            <button
              ui-button
              *ngIf="isEditing && !channelForm.value.isGeneral"
              class="group-template--section--channels--remove-icon"
              color="linkFlat"
              icon="icon_remove"
              [custom-icon]="true"
              [isSvg]="true"
              icon-size="medium"
              (click)="groupTemplateForm.removeChannel(channelForm)"
            ></button>
            <div
              class="group-template--section--channels--data"
              [ngClass]="{
                'group-template--section--channels--general':
                  isEditing && channelForm.value.isGeneral
              }"
            >
              <ui-field-group>
                <ui-switch-field-static
                  [isEditing]="isEditing && !channelForm.value.isGeneral"
                  [label]="'groupTemplates.table.name' | t"
                  name="name"
                  control="name"
                  [locale]="i18n.currentLocale"
                  [value]="channelForm.get('name').value"
                  [required]="true"
                  [group]="channelForm"
                  [forceInvalid]="groupTemplateForm.invalidChannelName"
                >
                </ui-switch-field-static>
              </ui-field-group>

              <ui-field-group>
                <ui-switch-text-static
                  [isEditing]="isEditing && !channelForm.value.isGeneral"
                  [label]="'groupTemplates.table.description' | t"
                  name="description"
                  control="description"
                  [value]="channelForm.get('description').value"
                  [group]="channelForm"
                >
                </ui-switch-text-static>
              </ui-field-group>

              <ui-field-group>
                <ui-checkbox
                  [label]="'groupTemplates.channels.private' | t"
                  [checked]="channelForm.get('isPrivate').value"
                  [isEditable]="isEditing && !channelForm.value.isGeneral"
                  controlName="isPrivate"
                  [group]="channelForm"
                ></ui-checkbox>
              </ui-field-group>
            </div>
            <div class="group-template--section--channels--folders">
              <ng-container
                *ngIf="
                  channelForm.get('folders').value.length === 0 && !isEditing
                "
              >
                <p class="no-folder">
                  {{ "groupTemplates.table.channels.noFolder" | t }}
                </p>
              </ng-container>
              <ui-tree
                [tree]="channelForm.get('folders').value"
                [locale]="locale"
                openIcon="files/icon_folder_open"
                closeIcon="files/icon_folder"
                [isEditing]="isEditing"
                (onChanges)="validateAllTrees()"
              ></ui-tree>
            </div>
          </div>

          <button
            *ngIf="isEditing"
            icon="icon_create"
            [custom-icon]="true"
            [isSvg]="true"
            ui-button
            color="linkFlat"
            (click)="groupTemplateForm.addNewChannel()"
          >
            {{ "new.drawer.channel" | t }}
          </button>
        </div>
      </ui-collapsable-card>

      <ui-collapsable-card
        [cardTitle]="
          (isSharepointGroup
            ? 'groupTemplates.folders.title'
            : 'groupTemplates.teams.folders.title'
          ) | t
        "
      >
        <div class="group-template--section">
          <ng-container *ngIf="folderTree.length === 0 && !isEditing">
            <p class="no-folder">
              {{ "groupTemplates.table.noFolder" | t }}
            </p>
          </ng-container>
          <ui-tree
            [tree]="folderTree"
            [locale]="locale"
            openIcon="files/icon_folder_open"
            closeIcon="files/icon_folder"
            [isEditing]="isEditing"
            (onChanges)="validateAllTrees()"
          ></ui-tree>
        </div>
      </ui-collapsable-card>

      <ui-collapsable-card
        *ngIf="!isSharepointGroup"
        [cardTitle]="'groupTemplates.permissions.title' | t"
      >
        <div class="flex-box group-template--section">
          <ng-template
            [ngTemplateOutlet]="permissionsSettings"
            [ngTemplateOutletContext]="{
              settingsType: 'memberSettings',
              settings: groupTemplateForm.MEMBER_SETTINGS
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="permissionsSettings"
            [ngTemplateOutletContext]="{
              settingsType: 'messagingSettings',
              settings: groupTemplateForm.MESSAGING_SETTINS
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="permissionsSettings"
            [ngTemplateOutletContext]="{
              settingsType: 'funSettings',
              settings: groupTemplateForm.FUN_SETTINGS
            }"
          ></ng-template>
        </div>
      </ui-collapsable-card>
    </form>

    <ng-template #loading>
      <ui-spinner></ui-spinner>
    </ng-template>
  </ui-card>
</div>

<ng-template
  #permissionsSettings
  let-settingsType="settingsType"
  let-settings="settings"
>
  <div>
    <div>
      {{ "groupTemplates.permissions." + settingsType + ".title" | t }}
    </div>
    <div>
      <div *ngFor="let setting of settings">
        <ui-checkbox
          [checked]="
            groupTemplateForm.templateForm
              ?.get('permissions')
              .get(settingsType)
              .get(setting).value
          "
          [isEditable]="isEditing"
          [controlName]="setting"
          [group]="
            groupTemplateForm.templateForm?.get('permissions').get(settingsType)
          "
          [label]="
            'groupTemplates.permissions.' + settingsType + '.' + setting | t
          "
        ></ui-checkbox>
      </div>
    </div></div
></ng-template>
