import { Component, Input } from '@angular/core';
import { OrganizationSettings, OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { FeatureFlagsEnum } from '@common/modules/layout/definitions/base-sidebar.service';

@Component({
  selector: 'general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent {
  @Input() public currentSettings: OrganizationSettings & OrganizationShareSettings;
  @Input() public isLoading = false;

  public featureFlagEnum = FeatureFlagsEnum;

  constructor() { }
}
