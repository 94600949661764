<div *display="{ flags: [featureFlagEnum.SHARES_MANAGEMENT] }" class="indicators-container">
  <h2>{{ 'dashboard.md-eco.pause.card.share.title' | t }}</h2>
  <div class="indicators-container--widgets-container">
    <ng-container *display="{ permissions: ['files_admin'] }">
      <div class="indicators-container--widgets-container--top-section">
        <div class="left-section">
          <admin-resources-number-with-shares *ngIf="enabledWidgets?.resourcesWithShares?.isActive" ></admin-resources-number-with-shares>
          <admin-users-shares *ngIf="enabledWidgets?.sharesByUser?.isActive"></admin-users-shares>
        </div>
        <div class="right-section">
          <shared-files-tendency
            *ngIf="enabledWidgets?.sharesTrending?.isActive"
            [isHighRiskOnly]="false"
          ></shared-files-tendency>
          <shared-files-tendency
            *ngIf="enabledWidgets?.highRiskSharesTrending?.isActive"
            [isHighRiskOnly]="true"
          ></shared-files-tendency>
        </div>
      </div>
    </ng-container>
    <div class="indicators-container--widgets-container--bottom-section">
      <admin-top10-high-risk-shares *ngIf="enabledWidgets?.top10HighRiskShares?.isActive" ></admin-top10-high-risk-shares>
    </div>
  </div>
  <app-shares-by-severity
    *ngIf="sharesBySeverityData"
    [isLoading]="isLoading"
    [data]="sharesBySeverityData"
  ></app-shares-by-severity>
</div>

<div class="indicators-container" *display="{ flags: [featureFlagEnum.RESOURCE_MANAGEMENT] }">
  <h2>{{ 'dashboard.admin.indicators.section.resource.title' | t }}</h2>
  <div class="indicators-container--widgets-container">
    <ng-container *display="{ permissions: ['files_admin'] }">
      <div class="indicators-container--widgets-container--top-section">
        <div class="left-section">
          <admin-resource-compliant-percentage></admin-resource-compliant-percentage>
        </div>
        <div class="right-section">
          <admin-resource-tendency></admin-resource-tendency>
        </div>
      </div>
    </ng-container>
    <div class="indicators-container--widgets-container--bottom-section">
      <admin-top10-resourceNoCompliant class="right-section"></admin-top10-resourceNoCompliant>
    </div>
  </div>
</div>

<div class="indicators-container">
  <h2>
    {{ 'dashboard.admin.indicators.section.engagementLevel.title' | t }}
  </h2>
  <div class="indicators-container--widgets-container--top-section">
    <div class="left-section">
      <admin-engagement-level></admin-engagement-level>
      <admin-engagement-level-tendency></admin-engagement-level-tendency>
    </div>
  </div>
</div>
