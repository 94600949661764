import { CdkConnectedOverlay, ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AppState, ResetPopover, SetPopover, UnsetPopover } from '@common/modules/layout/stores/app.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'topbar-additional-info',
  templateUrl: './topbar-additional-info.component.html',
  styleUrls: [ './topbar-additional-info.component.scss' ],
})
export class TopbarAddtionalInformation {
  @ViewChild(CdkConnectedOverlay, { static: false }) public overlay: CdkConnectedOverlay;
  @Select(AppState.isPopoverActive) public isPopoverActive$?: Observable<(name: string) => boolean>;

  public readonly name: string = TopbarAddtionalInformation.name;

  public offsetX = -20;
  public offsetY = 8;
  public positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  constructor(
    protected readonly ref: ElementRef, 
    private readonly store: Store
  ) {}

  public toggle(): void {
    this.isOpened() ? this.close() : this.open();
  }

  private open(): void {
    if (!this.isOpened()) {
      this.store.dispatch(new SetPopover({ name: this.name }));
    }
  }

  public close(): void {
    if (this.isOpened()) {
      this.store.dispatch(new ResetPopover());
    }
  }

  public isOpened(): boolean {
    return this.name === this.store.selectSnapshot((state) => state.app.popover);
  }

  @HostListener('document:click', [ '$event' ])
  public handleClickOut({ target }: Event): void {
    if (!this.isOpened()) {
      return;
    }

    if (
      this.isOpened() &&
      (this.ref.nativeElement.contains(target) || this.overlay.overlayRef.overlayElement.contains(target as Node))
    ) {
      return;
    }

    this.store.dispatch(new UnsetPopover({ name: this.name }));
  }
}
