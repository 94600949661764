<ng-container *ngIf="shouldShowAssociateButton">
  <ng-container *display="{ permissions: ['can_update_incident'] }">
    <button
      ui-button
      color="listActionFlatGray"
      icon="link"
      icon-size="small"
      [tooltip]="(isAlertPageContext ? 'respond.similarity.associate-current-alert-to-incident': 'respond.similarity.associate-alert-to-current-incident') | t"
      [disabled]="isProcessing"
      (click)="handleAssociateButtonClick(); $event.stopPropagation();"
    >
    </button>
  </ng-container>
</ng-container>