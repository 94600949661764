import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IOrgSelectorOrganization } from '@common/modules/layout/components/page/page.component';
import { AppState } from '@common/modules/layout/stores/app.state';
import { Select } from '@ngxs/store';
import { DateTimePeriod } from '@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { Eco } from 'projects/@common/definitions/eco';
import { Accumulatables, Accumulator, AccumulatorsState } from 'projects/@common/modules/accumulator/accumulator.store';
import { MultiSelectDataFactory } from "projects/@common/modules/i18n/component-wrapper/multi-select-data.factory";
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { ActionsToolboxApi } from 'projects/@common/services/api/respond/actions-toolbox/actions-toolbox.api';
import { IToolboxAction } from 'projects/@common/services/api/respond/actions-toolbox/actions-toolbox.definition';
import { mapListActionsResponse } from 'projects/@common/services/api/respond/actions-toolbox/mapActions.util';
import { AutomationOrganizationsService } from 'projects/@common/services/api/respond/automation-organization/automation-organizations.api';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { ActionTemplate, AlertingUsecaseListItem } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UsecaseCreateDrawer } from '../../containers/drawers/usecase-create-drawer/usecase-create-drawer.component';
import { UsecaseDescriptionDrawer, UsecaseDescriptionDrawerData } from '../../containers/drawers/usecase-description-drawer/usecase-description-drawer.component';
import { UsecasesTableHandler } from '../../containers/usecases-table-handler';
import { OrganizationListService } from '../../services/organizationList.service';

@Component({
  selector: 'usecase-inventory',
  templateUrl: './usecase-inventory.component.html',
  styleUrls: [ './usecase-inventory.component.scss' ],
})
export class UsecaseInventoryComponent extends UsecasesTableHandler implements OnInit {
  @Select(AppState.selectedOrganization) public selectedOrganization$: BehaviorSubject<IOrgSelectorOrganization>;
  @Select(AccumulatorsState.accumulators) private accumulators$: Observable<Accumulator[]>;

  public defaultOrganization: string;
  public organizationList: Record<string, string>;
  public organizationEcoId: string;
  public actionNames: string[];
  public actions: {
    [key: string]: ActionTemplate;
  };
  public toolboxActions: IToolboxAction[];
  public alertingModeActionNames: string[];
  public currentOrganizationName: string;
  public tableFilterData: TableFilterData;

  private accumulatorSubscription: Subscription;
  private currentAccumulatorCount: number;

  constructor(
    public readonly i18nService: I18nService,
    public readonly organizationListService: OrganizationListService,
    protected readonly drawerService: DrawerService,
    protected readonly usecasesApiService: UsecasesApiService,
    protected readonly actionsToolboxApi: ActionsToolboxApi,
    protected readonly noticeService: NoticeService,
    protected readonly translatedObjectPipe: TranslatedObjectPipe,
    protected readonly cdr: ChangeDetectorRef,
    protected staticTableDataMapper: StaticTableDataMapper,
    public automationOrganizationsService: AutomationOrganizationsService,
    multiSelectFactory: MultiSelectDataFactory
  ) {
    super(i18nService, usecasesApiService, noticeService, translatedObjectPipe, cdr, drawerService, staticTableDataMapper, multiSelectFactory);
  }

  ngOnInit(): void {
    this.selectedOrganization$.subscribe((org) => this.organizationChanged(org));
    this.fetchUsecaseActions();
    this.fetchToolboxActions();
    this.initAccumulatorSubscription();


    [ this.USECASES_COLUMN_DEF ] = this.staticTableDataMapper.translate(this.USECASES_COLUMN_DEF, []);
  }

  ngOnDestroy(): void {
    this.accumulatorSubscription?.unsubscribe();
  }

  public async organizationChanged(org): Promise<void> {
    if (org?.id) {
      await this.automationOrganizationsService.describeOrganization(org.id).then((respondOrg) => {
        this.currentOrganizationName = respondOrg.name;
        this.organizationGuid = respondOrg.id;
        this.organizationEcoId = respondOrg.ecoId;
      });
      this.handleRefresh();
    } else {
      this.organizationGuid = null;
    }
  }

  public searchFunction = async (params: any): Promise<any> => {
    const response: any = await this.automationOrganizationsService.getOrganizations(params);
    response.items = response.items.map((organization) => ({ id: organization.ecoId, name: organization.name }));
    return response;
  };

  public setAlertTimeFilter(event: DateTimePeriod | null): void {
    this.alertFrom = event?.dateFrom ?? null;
    this.alertTo = event?.dateTo ?? null;
    this.handleRefresh();
  }

  public async handleRowClick(usecase: AlertingUsecaseListItem): Promise<void> {
    this.drawerService.replaceCurrentDrawer(UsecaseDescriptionDrawer, {
      component: DrawerPageEnum.descriptionUsecaseDrawer,
      data: {
        title: `${this.translatedObjectPipe.transform(usecase.name)}`,
        usecaseId: usecase.id,
        silenced: usecase.silenced,
        actionNames: this.actionNames,
        actions: this.actions,
        toolboxActions: this.toolboxActions,
        alertingModeActionNames: this.alertingModeActionNames,
        organizationId: this.organizationGuid,
        organizationEcoId: this.organizationEcoId,
        usecaseItem: usecase,
      } as UsecaseDescriptionDrawerData,
    });
  }

  public openUsecaseCreation(): void {
    this.drawerService.replaceCurrentDrawer(UsecaseCreateDrawer, {
      component: DrawerPageEnum.createUsecaseDrawer,
      data: {
        title: this.i18nService.translate('org_usecase.create_drawer.title'),
        organizationId: this.organizationGuid,
        defaultOrganizationName: this.currentOrganizationName,
        organizationEcoId: this.organizationEcoId,
      },
    });
  }

  private initAccumulatorSubscription(): void {
    this.accumulatorSubscription = this.accumulators$.subscribe((accumulators) => {
      const count = accumulators
        .filter((accumulator) => accumulator.accumulatable === Accumulatables.LIST_REFRESHER)
        .shift()?.accumulator;
      if (count !== this.currentAccumulatorCount) {
        this.handleRefresh();
        this.currentAccumulatorCount = count;
      }
    });
  }

  private fetchUsecaseActions(): void {
    this.usecasesApiService.getUsecaseActions().then((list) => {
      this.actions = list.items.reduce((prev, curr) => {
        const key = Object.keys(curr)[0]; prev[key] = curr[key]; return prev;
      }, {});
      this.actionNames = list.items.map((action) => Object.keys(action)).map((name) => name[0]);
      const alertingModeActions = list.items.filter((action) => action[Object.keys(action)[0]]?.isAlertingModeAction);
      this.alertingModeActionNames = alertingModeActions.map((alertingModeAction) => Object.keys(alertingModeAction)[0]);

      this.loadUsecases();
    });
  }

  private fetchToolboxActions(): void {
    this.actionsToolboxApi.listActions(null).subscribe(
      (response) => {
        this.toolboxActions = mapListActionsResponse(response, this.i18nService.currentLocale as Eco.Languages);
      },
      (error) => {
        this.toolboxActions = [];
      }
    );
  }
}
