import { Component, Input } from '@angular/core';
import { TimeUtil } from '@ui-kit/services/time-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { CommunicationApi } from 'projects/@common/services/api/respond/communication/communication.api';
import { EscalationDelay, EscalationDelayByPriority, EscalationTimeChoicesEnum, EscalationTimeChoicesEnumInMilliseconds, IContactCommunicationMethod, IEscalationParameters } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { EscalationListScheduleEnum, IComputedEscalation } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { mapCommunicationMethodsTranslations } from 'projects/@respond/communication/components/communication-method/communication-method.helpers';


declare namespace Intl {
  function supportedValuesOf(input: string): string[];
  function DateTimeFormat(locale: string, options?: any): any;
}

@Component({
  selector: 'incident-escalation-table',
  templateUrl: './incident-escalation-table.component.html',
  styleUrls: [ './incident-escalation-table.component.scss' ],
})
export class IncidentEscalationTableComponent {
  @Input() escalationListSchedule: EscalationListScheduleEnum;

  @Input() incident: Incident;

  @Input() escalationParameters: IEscalationParameters;

  @Input() computedEscalationList: IComputedEscalation;

  public priorityDelayTimestamp: number;

  public nextBusinessDay: boolean;

  public isLoading = false;

  constructor(public readonly i18n: I18nService, private commnunicationApi: CommunicationApi) { }

  ngOnChanges(): void {
    this.setPriorityDelayTimestamp();
  }

  public get nextBusinessDayValues(): string {
    const formattedMinutes = TimeUtil.padZero(this.escalationParameters.businessHours.fromMinute);
    const nextHour = this.escalationParameters.businessHours.fromHour + 2;
    return `${nextHour}h${formattedMinutes}`;
  }

  public getContactCommunicationMethods(communicationMethods: IContactCommunicationMethod[]): IContactCommunicationMethod[] {
    return mapCommunicationMethodsTranslations(communicationMethods, this.i18n);
  }

  private getTimezoneAbbreviation(date: Date, timeZone) {
    return date.toLocaleTimeString('en-us', {timeZone, timeZoneName:'short'}).split(" ").pop() || '';
  }

  public getcommunicationMethodTooltipContent(communicationMethod: any): string {

    let tooltipContent = communicationMethod.properties.phoneNumber ||
                  communicationMethod.properties.email ||
                  communicationMethod.properties.note || '';

    if (communicationMethod.properties?.ext) {
      tooltipContent += `, ext. ${communicationMethod.properties.ext}`;
    }

    return tooltipContent;
  }

  public displayTimestamp(date: string): string {
    const dateFormat = new Date(date);
    const timeZone = this.escalationParameters?.businessHours?.timezone;
    const dateFormatOptions = {
      timeZone,
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const timezonedDate = Intl.DateTimeFormat(this.i18n.currentLocale, dateFormatOptions).format(dateFormat).replace(" h ", "h");
    // in French it outputs "14 h 23" so the replace formats it to "14h23"
    return `${timezonedDate} ${this.getTimezoneAbbreviation(dateFormat, timeZone)}`;
  }

  private setPriorityDelayTimestamp(): void {
    const escalationDelays: EscalationDelayByPriority = this.escalationParameters?.escalationDelays.delays[this.incident.priority - 1];

    if (escalationDelays) {
      const escalationDelay: EscalationDelay = escalationDelays?.[this.escalationListSchedule];
      const delay: EscalationTimeChoicesEnum = escalationDelay?.delay;
      this.nextBusinessDay = escalationDelay?.nextBusinessDay;
      this.priorityDelayTimestamp = EscalationTimeChoicesEnumInMilliseconds[delay];
    }
  }
}