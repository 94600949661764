import { Component, OnInit } from '@angular/core';
import {
  ChartTypeEnum,
  TendencyTimeRangesEnum
} from 'projects/@common/services/api/sg/shares/shares.definitions';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { MdEcoApiService } from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile-api';
import { IAxisFormattingSettings } from '@ui-kit/components/dynamic-chart/dynamic-chart.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { TendencyGraphFormatterService } from '../tendencyGraphFormatter.service';

@Component({
  selector: 'admin-engagement-level-tendency',
  templateUrl: './admin-engagement-level-tendency.component.html',
  styleUrls: [ './admin-engagement-level-tendency.component.scss' ],
})
export class AdminEngagementLevelTendencyComponent implements OnInit {
  public readonly TIME_RANGE_ENUM = TendencyTimeRangesEnum;

  public readonly KEY = 'dailyTimestamp';

  public readonly ENGAGEMENT_LINE = 'engagement';

  public readonly REGRESSION_LINE = 'regression';

  public readonly CHART_TYPE = ChartTypeEnum.LINE;

  public isHighRiskOnly = true;

  public isLoading = false;

  public currentTimeRange = TendencyTimeRangesEnum.LAST_7_DAYS;

  public colors: {
    engagement: string;
    regression: string;
  };

  public legend: {
    engagement: string;
    regression: string;
  };

  public axisFormattingSettings: IAxisFormattingSettings;

  public data: {
    dayAxis: number;
    engagement: number;
    regression?: number;
  }[];

  public get translationSuffix(): string {
    return this.isHighRiskOnly ? 'high' : 'total';
  }

  public locale: LanguageEnum;

  constructor(
    private readonly i18n: I18nService,
    private mdEcoApiService: MdEcoApiService
  ) {}

  public ngOnInit(): void {
    this.locale = this.i18n.currentLocale as LanguageEnum;
    this.colors = {
      engagement: '#52c5c3',
      regression: '#9575CD',
    };
    this.legend = {
      engagement: this.i18n.translate(`dashboard.admin.engagementLevel.tendency.engagedUsers`),
      regression: this.i18n.translate('dashboard.admin.engagementLevel.tendency'),
    };
    this.setAxisFormattingSettings();
    this.loadData();
  }

  private setAxisFormattingSettings(): void {
    this.axisFormattingSettings = TendencyGraphFormatterService.axisFormattingSettings(this.currentTimeRange);
  }

  private loadData(): void {
    this.isLoading = true;
    this.mdEcoApiService
      .getEngagementLevels({ gte: this.currentTimeRange })
      .then((tendency) => {
        const mappedData = [];
        for (const t of tendency) {
          mappedData.push({
            dailyTimestamp: t.createdAt,
            engagement: t.amountHigh + t.amountMedium,
          });
        }
        this.data = TendencyGraphFormatterService.addRegressionLineParamToDataSource(mappedData, this.ENGAGEMENT_LINE);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public handleTimeRange(range: TendencyTimeRangesEnum): void {
    this.currentTimeRange = range;
    this.setAxisFormattingSettings();
    this.loadData();
  }
}
