import { TimeUnit } from "@amcharts/amcharts4/core";
import { TendencyTimeRangesEnum } from "@common/services/api/sg/shares/shares.definitions";
import { RegressionUtil } from "@common/utils/regression-util";
import { IAxisFormattingSettings } from "@ui-kit/components/dynamic-chart/dynamic-chart.component";

export class TendencyGraphFormatterService {

  private static readonly REGRESSION_LINE = 'regression';

  public static axisFormattingSettings(timeRange: TendencyTimeRangesEnum): IAxisFormattingSettings {
    return {
      horizontalAxis: {
        dateFormats: this.getAxisFormatting(timeRange).dateFormats ?? {
          timeUnit: 'day',
          value: 'dd',
        },
        gridIntervals: this.getAxisFormatting(timeRange).gridIntervals,
        tooltipDateFormat: 'MMM d',
      },
    };
  }

  public static addRegressionLineParamToDataSource(dataSource: any[], dataLine: string): any[] {
    const REGRESSION_LINE = 'regression'
    const KEY = 'dailyTimestamp'

    if (!dataSource?.length){
      return [];
    }

    const regressionParams = RegressionUtil.calculateRegression(dataSource, KEY, dataLine);
    dataSource[0][REGRESSION_LINE] = regressionParams.b * dataSource[0][KEY] + regressionParams.a;
    dataSource[dataSource.length - 1][REGRESSION_LINE] =
      regressionParams.b * dataSource[dataSource.length - 1][KEY] + regressionParams.a;
    return dataSource;
  }


  private static getAxisFormatting(timeRange): {
    dateFormats?: {
      timeUnit: TimeUnit;
      value: string;
    },
    gridIntervals: {
      timeUnit: TimeUnit;
      count: number; 
    }[]
  } {
    switch(timeRange){
      case TendencyTimeRangesEnum.LAST_YEAR:
        return {
          dateFormats: {
            timeUnit: 'month',
            value: 'MMM',
          },
          gridIntervals: [
            {
              timeUnit: 'month',
              count: 2,
            }
          ]
      }
      case TendencyTimeRangesEnum.LAST_3_MONTHS:
        return {
          gridIntervals: [
            {
              timeUnit: 'week',
              count: 3,
            }
          ]
        }
      case TendencyTimeRangesEnum.PREVIOUS_MONTH:
        return {
          gridIntervals: [
            {
              timeUnit: 'day',
              count: 3,
            }
          ]
        }
      case TendencyTimeRangesEnum.LAST_7_DAYS:
        default:
        return {
          gridIntervals: [
            {
              timeUnit: 'day',
              count: 1,
            }
          ]
        }
    }
  }

}
