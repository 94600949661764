import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { NoticeService } from "projects/@common/modules/notice/notice.service";
import { HistoriesApi } from "projects/@common/services/api/respond/histories/histories.api";
import { IIncidentHistory } from "projects/@common/services/api/respond/histories/histories.definition";
import { GetIncidentHistoriesRequest } from "projects/@common/services/api/respond/histories/models/getIncidentIHistoriesRequest";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { ResponseUtils } from "projects/@common/utils/response-utils";

@Component({
  selector: 'app-incident-history-tab',
  templateUrl: './history-tab.component.html',
  styleUrls: [ './history-tab.component.scss' ],
})
export class HistoryTabIncidentComponent implements OnInit, OnDestroy {
  @Input() incident: Incident;

  public readonly REQUEST_LIMIT: number = 25;
  public isLoadingHistory: boolean = false;
  public isLoadMoreHistory: boolean = false;

  public incidentHistoryResponse: ResponseUtils<IIncidentHistory>;
  public incidentHistory: IIncidentHistory[];

  private incidentId: string;
  private organizationId: string;

  public readonly historicColumnsDef = [
    {
      label: '',
      field: 'icon',
      type: UiStaticTableRowType.EXPENDABLE_ICON,
      showIcon: true,
      width: '26px',
      minWidth: '26px',
      isResizable: false,
      noSorting: true,
    },
    {
      label: 'date',
      field: 'createdAt',
      width: '200px',
      minWidth: '200px',
      type: UiStaticTableRowType.DATE_AND_HOUR_AND_AGO,
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('alerts.drawer.tab.history.table.column.user'),
      field: 'identityName',
      width: '20%',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('alerts.drawer.tab.history.table.column.operation'),
      field: 'message',
      width: '80%',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      noSorting: true,
    },
  ];

  constructor(
    private readonly historyService: HistoriesApi,
    public readonly i18n: I18nService,
    public readonly noticeService: NoticeService
  ) { }

  ngOnDestroy(): void {
    this.incident.reloadObservable$.unsubscribe();
  }

  ngOnInit(): void {
    this.incidentId = this.incident.id;
    this.organizationId = this.incident.organizationId;
    this.loadIncidentHistory();
    this.incident.reloadObservable$.subscribe(() => this.loadIncidentHistory());
  }

  private loadIncidentHistory(): void {
    this.isLoadingHistory = true;
    const getIncidentHistoriesRequest = new GetIncidentHistoriesRequest({ incidentId: this.incidentId, organizationId: this.organizationId });
    this.historyService.listIncidentHistories(getIncidentHistoriesRequest).subscribe(
      (response) => {
        this.incidentHistoryResponse = response;
        this.incidentHistory = this.incidentHistoryResponse.getItems();
      },
      (error) => this.noticeService.error("incidents.history.fetch.error"),
      () => this.isLoadingHistory = false
    );
  }

  public loadMoreIncidentHistory(): void {
    if (!this.isLoadMoreHistory && this.canLoadMore()) {
      this.isLoadMoreHistory = true;
      const from = this.incidentHistoryResponse.getNextItem();
      const getAlertObservableRequest = new GetIncidentHistoriesRequest({ incidentId: this.incidentId, organizationId: this.organizationId, from });
      this.historyService.listIncidentHistories(getAlertObservableRequest).subscribe(
        (response) => {
          this.incidentHistoryResponse = response;
          this.incidentHistory = this.incidentHistory.concat(this.incidentHistoryResponse.getItems());
        },
        (error) => this.noticeService.error('incidents.history.fetch.error'),
        () => this.isLoadMoreHistory = false
      );
    }
  }

  public canLoadMore(): boolean {
    return this.incidentHistoryResponse.canLoadMore();
  }
}
