import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DatasourceConfigurationParameterTypes } from 'projects/@common/services/api/respond/datasources/datasources.definitions';
import { IMonitor, UsecaseCreationInterface } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { CustomValidators } from 'projects/@common/utils/validators';

export class UsecaseForm {
  public formGroup: UntypedFormGroup;

  public templateSelected = new UntypedFormGroup({ template: new UntypedFormControl('') });

  public datasourceSelected = new UntypedFormGroup({ datasource: new UntypedFormControl('') });

  public triggerConfig = new UntypedFormGroup({ config: new UntypedFormControl('') });

  constructor(protected readonly formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  public get usecaseDatasource(): UntypedFormControl {
    return this.formGroup.get('datasourceType') as UntypedFormControl;
  }

  public get parametersFormArray(): UntypedFormArray {
    return this.formGroup.get('parameters') as UntypedFormArray;
  }

  public get usecaseRequestBody(): UsecaseCreationInterface {
    if (typeof (this.formGroup.get('priority').value) === 'string') {
      this.formGroup.get('priority').setValue(null);
    }

    const bodyToReturn = this.formGroup.getRawValue();
    bodyToReturn.parameters.forEach((parameter, i) => {
      parameter.parameters.forEach((monitorParams, j) => {
        if (monitorParams.type === 'object') {
          if (monitorParams.value === '' || null) {
            bodyToReturn.parameters[i].parameters.splice(j, 1);
          } else {
            bodyToReturn.parameters[i].parameters[j].value = JSON.parse(monitorParams.value);
          }
        } else if (monitorParams.type === 'number') {
          bodyToReturn.parameters[i].parameters[j].value =
            bodyToReturn.parameters[i].parameters[j].value != null ?
              Number(bodyToReturn.parameters[i].parameters[j].value) :
              monitorParams.default;
        }
      });
    });

    return bodyToReturn;
  }

  public get isFormValid(): boolean {
    return this.formGroup.valid;
  }

  public addMonitor(monitor: IMonitor): void {
    this.parametersFormArray.push(this.getMonitorGroup(monitor));
  }

  public initForm(): void {
    this.formGroup = this.formBuilder.group({
      template: [ null, Validators.required ],
      datasourceType: [ '', Validators.required ],
      playbookId: [ null ],
      priority: [ '' ],
      parameters: this.formBuilder.array([]),
      affectedResources: [ null, [ CustomValidators.affectedResources ] ],
    });
  }

  private getParametersGroup(param: any): UntypedFormGroup {
    const group = this.formBuilder.group({
      name: param.name,
      type: param.type,
      value: [ param.default ? param.default : null ],
    });

    if (!param.default) {
      group.get('value').setValidators([ Validators.required, this.getProperValidator(param.type) ]);
    } else {
      group.get('value').setValidators(this.getProperValidator(param));
    }

    group.get('value').updateValueAndValidity();

    return group;
  }

  private getProperValidator(type: string): any {
    switch (type) {
      case DatasourceConfigurationParameterTypes.NUMBER:
        return CustomValidators.numberValidator;
      case DatasourceConfigurationParameterTypes.STRING_LIST:
        return CustomValidators.isArrayOfString;
      case DatasourceConfigurationParameterTypes.OBJECT:
        return CustomValidators.isValidJson;
      default:
        return CustomValidators.defaultValidator;
    }
  }

  public getMonitorGroup(monitor: IMonitor): UntypedFormGroup {
    const parametersForm = [];
    monitor?.parameters?.forEach((param) => parametersForm.push(this.getParametersGroup(param)));

    return this.formBuilder.group({
      monitor: monitor.name,
      parameters: this.formBuilder.array(parametersForm),
    });
  }
}
