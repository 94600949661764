import { Component, OnInit } from '@angular/core';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';

@Component({
  selector: 'app-group-templates',
  templateUrl: './group-templates.container.html',
  styleUrls: [ './group-templates.container.scss' ],
})
export class GroupTemplatesContainer implements OnInit {
  public uiTabsColor = UiTabsColor;

  constructor() { }

  ngOnInit(): void {
  }
}
