import { Component, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { AutomationOrganizationsService } from 'projects/@common/services/api/respond/automation-organization/automation-organizations.api';
import { previewModeEnum } from '../../../components/datasource-inventory/container/components/inventory-layout/inventory-layout.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IOrgSelectorOrganization } from 'projects/@common/modules/layout/components/page/page.component';
import { Filters, GetOrgConnectorUsecaseResponse } from 'projects/@common/services/api/detect/connectorsOrg/model/ConnectorOrg';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';

@Component({
  selector: 'app-connector-inventory-container',
  templateUrl: './connector-inventory-container.component.html',
  styleUrls: [ './connector-inventory-container.component.scss' ],
})
export class ConnectorInventoryContainerComponent implements OnInit {
  public isLoading: boolean = false;
  public preview = previewModeEnum.PREVIEW;
  public totalConnectorInstances: number = 0;

  public categoryFiltersData: MultiSelectData = new MultiSelectData();
  public functionFiltersData: MultiSelectData = new MultiSelectData();
  public vendorFiltersData: MultiSelectData = new MultiSelectData();

  public filtersChanged: EventEmitter<Filters> = new EventEmitter();

  public organizationEcoId: string | null = null;
  public organization: IAutomationOrganization | null = null;

  private filters: Filters;

  public isVarMode: boolean = false;

  constructor(
    public readonly i18n: I18nService,
    private readonly automationOrganizationService: AutomationOrganizationsService,
    private readonly store: Store
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  async ngOnInit(): Promise<void> {
    this.filters = {
      categories: [],
      functions: [],
      vendors: [],
    };

    if (!this.isVarMode) {
      this.loadCustomerOrganization();
    }
  }

  public handleListData(dataEvent: GetOrgConnectorUsecaseResponse): void {
    if (!this.categoryFiltersData.options.length) {
      this.categoryFiltersData = this.createFilterOptions(dataEvent.filters.categories, "category");
    }
    if (!this.functionFiltersData.options.length) {
      this.functionFiltersData = this.createFilterOptions(dataEvent.filters.functions, "function");
    }
    if (!this.vendorFiltersData.options.length) {
      this.vendorFiltersData = this.createFilterOptions(dataEvent.filters.vendors, "vendor");
    }
    this.totalConnectorInstances = dataEvent.instances.length;
  }

  public handleFilterOptionSelected(event, type: 'categories' | 'functions'| 'vendors'): void {
    this.filters[type] = event;
    this.filtersChanged.emit(this.filters);
  }

  public async onOrganizationChanged(organization: IOrgSelectorOrganization | null): Promise<void> {
    this.organizationEcoId = organization?.id || null;

    if (this.organizationEcoId) {
      this.organization = await this.automationOrganizationService.describeOrganization(this.organizationEcoId);
    } else {
      this.organization = null;
    }
  }

  public async loadCustomerOrganization(): Promise<void> {
    const orgId = this.store.selectSnapshot(EcoSessionState.organization).id;
    this.organization = await this.automationOrganizationService.describeOrganization(orgId);
  }

  private createFilterOptions(data, type: 'category' | 'function'| 'vendor'): MultiSelectData {
    const options = data.map((value) => ({
      value,
      option: value,
      translated: value.name,
      isSelected: false,
      isDefaultValue: false,
    }));
    options.unshift({
      value: this.i18n.translate(`detection.connector.page.filters.${type}.all.text`),
      option: this.i18n.translate(`detection.connector.page.filters.${type}.all.text`),
      translated: this.i18n.translate(`detection.connector.page.filters.${type}.all.text`),
      isSelected: true,
      isDefaultValue: true,
    });

    return new MultiSelectData(options);
  }
}
