<div class="attack-card">
  <div class="attack-card--content">
    <ng-container *ngIf="isTechnique">
      <div class="text-container">
        <label ui-label>{{'detection.playbook.detail.mitre.card.tactic' | t}}</label>
        <p class="info-text">{{ attack.tacticRef.attackId + ' - ' + attack.tacticRef.name }}</p>
      </div>

      <div class="text-container">
        <label ui-label>{{'detection.playbook.detail.mitre.card.technique' | t}}</label>
        <p class="info-text">{{ attack.attackId + ' - ' + attack.name }}</p>
      </div>
    </ng-container>

    <ng-container *ngIf="!isTechnique">
      <div class="text-container">
        <label ui-label>{{'detection.playbook.detail.mitre.card.tactic' | t}}</label>
        <p class="info-text">{{ attack.attackId + ' - ' + attack.name }}</p>
      </div>

      <div class="text-container">
        <label ui-label>{{'detection.playbook.detail.mitre.card.technique' | t}}</label>
        <p class="info-text">-</p>
      </div>

    </ng-container>

  </div>
  <div class="attack-card--info-section">
    <img
      class="icon-size"
      [src]="'assets/favicons/icon_info.svg'"
      alt="infoIcon"
      (click)="openMitreAttackWebsite()"
    />
  </div>
</div>