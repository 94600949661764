import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EditWithModalComponent } from 'projects/@common/components/edit-with-modal/edit-with-modal.component';

@Component({
  selector: 'confidentiality-breach',
  templateUrl: './confidentiality-breach.component.html',
  styleUrls: [ './confidentiality-breach.component.scss' ],
})
export class ConfidentialityBreachComponent implements OnInit {
  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;

  @Input() value: boolean = false; // checked or not
  @Input() disabled: boolean = false; // grayed style and not editable
  @Input() readonly: boolean = false; // not grayed style but not editable
  @Input() styleLikeInputField: boolean = false; // should component look like an input field
  @Input() showLabel: boolean = true;

  @Output() choiceEvent: EventEmitter<boolean> = new EventEmitter();

  public editable = false;

  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
    this.editable = !this.disabled && !this.readonly;
  }

  @HostListener('document:click', [ '$event' ])
  clickOut(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  public close(): void {
    this.editWithModalComponent?.close();
  }

  public open(): void {
    if (this.editable) {
      this.editWithModalComponent.open();
    }
  }

  public select(value: boolean): void {
    if (this.editable) {
      this.choiceEvent.emit(value);
      this.editWithModalComponent.close();
    }
  }
}
