import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Component, NgZone, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { SnackbarService } from "projects/@common/modules/notice/implementations/snackbar/snackbar.service";
import { Notice, NoticeLevels } from "projects/@common/modules/notice/notice.service";
import { PlaybooksApiService } from "projects/@common/services/api/respond/playbooks/playbooks.api";
import {
  IPlaybookDetail,
  IPlaybookTaskTemplateRepresentation,
  IPlaybookTemplate,
  IPlaybookTemplateTaskRelation,
  PlaybookTaskRelation
} from "projects/@common/services/api/respond/playbooks/playbooks.definitions";

import { PlaybookCreationForm } from "./playbook-creation.form";

@Component({
  selector: 'playbook-creation.container',
  templateUrl: './playbook-creation.container.html',
  styleUrls: [ './playbook-creation.container.scss' ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class PlaybookCreationContainer implements OnInit {
  public isSaving = false;

  public readonly playbookCreationForm: PlaybookCreationForm;

  public readonly backLink = '../';

  public playbookTemplate: IPlaybookTemplate;

  public organizationEcoId: string;

  public playbook: IPlaybookDetail;

  public organizationName: string;

  public playbookTemplateName: ITranslatedField;

  public mdTasks: IPlaybookTaskTemplateRepresentation[] = [];

  public isLoaded = false;

  private usecaseId: string = this.route.snapshot.queryParams.usecaseId;

  private _hasCreationStarted = false;

  private _hasCreationFinished = false;

  constructor(
    private readonly playbooksApiService: PlaybooksApiService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly i18nService: I18nService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly snackbarService: SnackbarService
  ) {
    this.playbookCreationForm = new PlaybookCreationForm(this.formBuilder);
    this.organizationEcoId = this.route.snapshot.paramMap.get('organizationEcoId');
    this.organizationName = this.router.getCurrentNavigation()?.extras.state?.organizationName; // mettre dans un service
  }

  ngOnInit(): void {
    this.playbooksApiService.getPlaybookTaskTemplates({language: this.i18nService.currentLocale})
      .then((res) => {
        this.mdTasks = res.items;
        this.setEmptyPlaybook();
        this.isLoaded = true;
      });
  }

  async navigate(playbookId: string): Promise<void> {
    if (this.organizationEcoId !== null) {
      const path = this.usecaseId ? `respond/${this.organizationEcoId}/usecases` : `respond/${this.organizationEcoId}/playbooks/${playbookId}/detail`;
      this.zone.run(() => {
        this.router.navigateByUrl(path);
      });
    } else {
      this.zone.run(() => {
        this.router.navigate([ '.' ], { relativeTo: this.route.parent });
      });
    }
  }

  public async handleCreateEvent(): Promise<void> {
    this.playbook.suffix = this.playbookCreationForm.playbookNameSuffixControl.value;
    this.playbook.extraDescription = this.playbookCreationForm.descriptionAdditionalControl.value;
    await this.savePlaybook();
  }

  public handlePlaybookPickedEvent(playbookTemplate: IPlaybookTemplate): void {
    this._hasCreationStarted = true;
    if (playbookTemplate) {
      this.playbook = this.createPlaybookFromEvent(playbookTemplate);
      this.playbookTemplateName = playbookTemplate.name;
    } else {
      this.setEmptyPlaybook();
    }
  }

  private createPlaybookFromEvent(playbookTemplate: IPlaybookTemplate): IPlaybookDetail {
    return {
      templateId: playbookTemplate.id,
      templateCatalogId: playbookTemplate.playbookTemplateId,
      version: playbookTemplate.version,
      name: playbookTemplate.name,
      description: playbookTemplate.description,
      objective: playbookTemplate.objective,
      suffix: this.playbookCreationForm.playbookNameSuffixControl.value,
      extraDescription: this.playbookCreationForm.descriptionAdditionalControl.value,
      organizationId: this.organizationEcoId,
      playbookTasks: this.mapTemplateTasksToActualTasks(playbookTemplate.playbookTasks),
      datasourceTypes: playbookTemplate.datasourceTypes,
    };
  }

  private mapTemplateTasksToActualTasks(templateTask: IPlaybookTemplateTaskRelation[]): PlaybookTaskRelation[] {
    return templateTask.map((task) => ({
      id: task.id,
      phase: task.phase,
      order: task.order,
      managed: true,
      version: task.version,
      templateId: task.templateId,
      name: task.name,
      description: task.description,
      instruction: task.instruction,
      requiredParameters: task.requiredParameters,
      createdAt: task?.createdAt,
      updatedAt: task?.updatedAt,
    }));
  }

  private setEmptyPlaybook(): void {
    this.playbook = {
      description: null,
      objective: null,
      datasourceTypes: [],
      version: 0,
      organizationId: this.organizationEcoId,
      playbookTasks: [],
    };
    this.playbookTemplateName = null;
  }

  private async savePlaybook(): Promise<void> {
    let playbookId: string;
    this.isSaving = true;

    if (this.usecaseId) {
      this.playbook.usecaseId = this.usecaseId;
    }

    await this.playbooksApiService.createPlaybook(this.playbook.organizationId, this.playbook)
      .then(async (response) => {
        playbookId = response.id;
        this.snackbarService.handleNotice(new Notice('detection.playbook.creation.save.succes', NoticeLevels.SUCCESS));
      })
      .catch((err) => {
        if (err.error.code === 'INVALID_PARAMETERS') {
          const errorMessage = this.i18nService.translate('detection.playbook.update.parameter.error', { parameters: err.error.payload.toString() });
          this.snackbarService.handleNotice(new Notice(errorMessage, NoticeLevels.ERROR));
        } else {
          this.snackbarService.handleNotice(new Notice(err, NoticeLevels.ERROR));
        }
      })
      .finally(() => {
        this.isSaving = false;
        this._hasCreationFinished = true;
        if (playbookId) {
          this.navigate(playbookId);
        }
      });
  }

  public handleCancel(): void {
    const path = `respond/${this.organizationEcoId}/playbooks`;
    this.zone.run(() => {
      this.router.navigateByUrl(path);
    });
  }

  public get hasCreationStarted(): boolean {
    return this._hasCreationStarted;
  }

  public get hasCreationFinished(): boolean {
    return this._hasCreationFinished;
  }
}
