import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { VarIncidentsApiService } from "projects/@respond/incident/api/var-incidents.api";
import { CustomerIncidentsApiService } from "projects/console-org/src/app/respond/incidents/api/customer-incidents.api";
import { Observable } from "rxjs";
import { ApiEndpointPrefixEnum } from "../../../../definitions/eco-api";
import { EcoUrlService } from "../../core/url.service";
import { IAlert } from "../alerts/alerts.definitions";
import { GetIncidentAlertsRequest } from "../alerts/models/getIncidentAlertsRequest";
import { IEntityRepresentation } from "../entities/entities.definition";
import { IDescribeIncidentTask, IncidentDescription, IncidentTaskStatus } from "../incidentTasks/incidentTasks.definitions";
import { IObservable } from "../observables/observables.definition";
import {
  CreateIncidentTimestampRequest,
  DeleteIncidentTimestampRequest,
  IAddIncidentAnalystRequest,
  IComputedEscalation,
  ICreateIncidentRequest,
  IDeleteIncidentActionRequest,
  IDeleteIncidentAnalystRequest,
  IDeleteTaskActionsRequest,
  IDeleteTaskEntitiesRequest,
  IDeleteTaskMessageRequest,
  IDeleteTaskObservablesRequest,
  IDescribeIncidentResponse,
  IExportIncidentsResponse,
  IIncident,
  IIncidentActionRepresentation,
  IIncidentApi,
  IIncidentMessage,
  IListEscalationParametersRequest,
  IListIncidentRequest,
  IPostTaskActionsRequest,
  IPostTaskEntitiesRequest,
  IPostTaskMessagesRequest,
  IPostTaskObservablesRequest,
  IRemoveAlertFromIncidentResponse,
  IUpdateIncidentActionRequest,
  IUpdateIncidentPriorityRequest,
  IUpdateIncidentRequest,
  IUpdateIncidentStatusRequest,
  IncidentSimilarityItem,
  ListIncidentSimilarItemsRequest,
  ListOwnersResponse,
  UpdateIncidentTimestampRequest
} from "./incidents.definitions";
import { IncidentStatsHistoryDto, IncidentStatsHistoryRequest } from "./models/getIncidentStatsHistory.definition";
import { Incident } from "./models/incident";
import { IncidentsStats } from "./models/incidentsStats";
import { ITranslatedField } from "@ui-kit/definitions/ITranslatedField";

@Injectable({
  providedIn: 'root',
})
export class IncidentsApi implements IIncidentApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  private varService: VarIncidentsApiService;
  private customerService: CustomerIncidentsApiService;

  constructor(
    protected store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerIncidentsApiService(http, url);
    this.varService = new VarIncidentsApiService(http, url);
  }

  public listIncidents(request: IListIncidentRequest): Observable<ResponseUtils<IIncident>> {
    if (this.isVarMode) {
      return this.varService.listIncidents(request);
    }
    return this.customerService.listIncidents(request);
  }

  public createIncident(request: ICreateIncidentRequest): Promise<IIncident> {
    if (this.isVarMode) {
      return this.varService.createIncident(request);
    }
    return this.customerService.createIncident(request);
  }

  public updateIncident(organizationId: string, request: IUpdateIncidentRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.updateIncident(organizationId, request);
    }
    return this.customerService.updateIncident(organizationId, request);
  }

  public updateIncidentEscalationList(organizationId: string, incidentId: string): Observable<void> {
    if (this.isVarMode) {
      return this.varService.updateIncidentEscalationSchedule(organizationId, incidentId);
    }
    return this.customerService.updateIncidentEscalationSchedule(organizationId, incidentId);
  }

  public getOwners(): Observable<ListOwnersResponse[]> {
    if (this.isVarMode) {
      return this.varService.getOwners();
    }
    return this.customerService.getOwners();
  }

  public getIncidentFromAlert(organizationId: string, alertId: string): Promise<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.getIncidentFromAlert(organizationId, alertId);
    }
    return this.customerService.getIncidentFromAlert(organizationId, alertId);
  }

  public getAlertsFromIncident(request: GetIncidentAlertsRequest): Observable<ResponseUtils<IAlert>> {
    if (this.isVarMode) {
      return this.varService.getAlertsFromIncident(request);
    }
    return this.customerService.getAlertsFromIncident(request);
  }

  public describeIncident(organizationId: string, incidentId: string): Observable<Incident> {
    if (this.isVarMode) {
      return this.varService.describeIncident(organizationId, incidentId);
    }
    return this.customerService.describeIncident(organizationId, incidentId);
  }

  public describeDraftIncidentEscalationSchedule(organizationId: string, incidentId: string, escalation=false): Observable<IComputedEscalation> {
    if (this.isVarMode) {
      return this.varService.describeDraftIncidentEscalationSchedule(organizationId, incidentId, escalation);
    }
    //return this.customerService.describeDraftIncidentEscalationSchedule(organizationId, incidentId, escalation);
  }

  public describeIncidentEscalationSchedule(organizationId: string, incidentId: string): Observable<IComputedEscalation> {
    if (this.isVarMode) {
      return this.varService.describeIncidentEscalationSchedule(organizationId, incidentId);
    }
    return this.customerService.describeIncidentEscalationSchedule(organizationId, incidentId);
  }

  public startConversation(organizationId: string, incidentId: string, message: string, escalation=false): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.startConversation(organizationId, incidentId, message, escalation);
    }
    //return this.customerService.startConversation(organizationId, incidentId, message, escalation);
  }

  public getIncidentsStats(organizationId?: string): Observable<IncidentsStats[]> {
    if (this.isVarMode) {
      return this.varService.getIncidentsStats(organizationId);
    }
    return this.customerService.getIncidentsStats();
  }

  public getIncidentsStatsHistory(request: IncidentStatsHistoryRequest): Promise<IncidentStatsHistoryDto[]> {
    if (this.isVarMode) {
      return this.varService.getIncidentsStatsHistory(request);
    }
    return this.customerService.getIncidentsStatsHistory(request);
  }

  public associateAlerts(organizationId: string, incidentId: string, alertIds: string[], comment?: string): Promise<IIncident> {
    if (this.isVarMode) {
      return this.varService.associateAlerts(organizationId, incidentId, alertIds, comment);
    }
    return this.customerService.associateAlerts(organizationId, incidentId, alertIds, comment);
  }

  public dissociateAlert(organizationId: string, alertId: string): Promise<IRemoveAlertFromIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.dissociateAlert(organizationId, alertId);
    }
    return this.customerService.dissociateAlert(organizationId, alertId);
  }

  public updateIncidentTimestamp(organizationId: string, request: UpdateIncidentTimestampRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.updateIncidentTimestamp(organizationId, request);
    }
    return this.customerService.updateIncidentTimestamp(organizationId, request);
  }

  public createIncidentTimestamp(organizationId: string, request: CreateIncidentTimestampRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.createIncidentTimestamp(organizationId, request);
    }
    return this.customerService.createIncidentTimestamp(organizationId, request);
  }

  public deleteIncidentTimestamp(organizationId: string, request: DeleteIncidentTimestampRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.deleteIncidentTimestamp(organizationId, request);
    }
    return this.customerService.deleteIncidentTimestamp(organizationId, request);
  }

  public updateIncidentStatus(organizationId: string, request: IUpdateIncidentStatusRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.updateIncidentStatus(organizationId, request);
    }
    return this.customerService.updateIncidentStatus(organizationId, request);
  }

  public updateIncidentPriority(organizationId: string, request: IUpdateIncidentPriorityRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.updateIncidentPriority(organizationId, request);
    }
    return this.customerService.updateIncidentPriority(organizationId, request);
  }

  public updateTaskStatus(organizationId: string, request: { incidentId: string, id: string, status: IncidentTaskStatus; }): Observable<IncidentDescription> {
    if (this.isVarMode) {
      return this.varService.updateTaskStatus(organizationId, request);
    }
    return this.customerService.updateTaskStatus(organizationId, request);
  }

  public addIncidentTask(organizationId: string, request: { playbookTaskId: string; version: number; incidentId: string; taskId: string; before: boolean;}): Observable<IncidentDescription> {
    if (this.isVarMode) {
      return this.varService.addIncidentTask(organizationId, request);
    }
    return this.customerService.addIncidentTask(organizationId, request);
  }

  public moveIncidentTask(organizationId: string, request: { sourceTaskId: string; incidentId: string; destinationTaskId: string; before: boolean;}): Observable<IncidentDescription> {
    if (this.isVarMode) {
      return this.varService.moveIncidentTask(organizationId, request);
    }
    return this.customerService.moveIncidentTask(organizationId, request);
  }
  
  public updateCustomTask(organizationId: string, request: { incidentId: string, taskId: string, name: ITranslatedField, description: ITranslatedField, instruction: ITranslatedField }): Observable<IncidentDescription> {
    if (this.isVarMode) {
      return this.varService.updateCustomTask(organizationId, request);
    }
    return this.customerService.updateCustomTask(organizationId, request);
  }
  
  public deleteIncidentTask(organizationId: string, request: { incidentId: string, taskId: string}): Observable<IncidentDescription> {
    if (this.isVarMode) {
      return this.varService.deleteIncidentTask(organizationId, request);
    }
    return this.customerService.deleteIncidentTask(organizationId, request);
  }

  public updateTask(organizationId: string, request: { incidentId: string, id: string, comment: string; }): Observable<IncidentDescription> {
    if (this.isVarMode) {
      return this.varService.updateTask(organizationId, request);
    }
    return this.customerService.updateTask(organizationId, request);
  }

  public addIncidentAnalyst(organizationId: string, request: IAddIncidentAnalystRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.addIncidentAnalyst(organizationId, request);
    }
    return this.customerService.addIncidentAnalyst(organizationId, request);
  }

  public deleteIncidentAnalyst(organizationId: string, request: IDeleteIncidentAnalystRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.deleteIncidentAnalyst(organizationId, request);
    }
    return this.customerService.deleteIncidentAnalyst(organizationId, request);
  }

  public exportIncidents(request: IListIncidentRequest): Observable<ResponseUtils<IExportIncidentsResponse>> {
    if (this.isVarMode) {
      return this.varService.exportIncidents(request);
    }
    return this.customerService.exportIncidents(request);
  }

  public listSimilarItems(organizationId: string, request: ListIncidentSimilarItemsRequest): Observable<ResponseUtils<IncidentSimilarityItem>> {
    if (this.isVarMode) {
      return this.varService.listSimilarItems(organizationId, request);
    }
    return this.customerService.listSimilarItems(organizationId, request);
  }

  public listIncidentActions(organizationId: string, incidentId: string): Observable<ResponseUtils<IIncidentActionRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listIncidentActions(organizationId, incidentId);
    }
    return this.customerService.listIncidentActions(organizationId, incidentId);
  }

  public listIncidentEntities(organizationId: string, incidentId: string): Observable<ResponseUtils<IEntityRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listIncidentEntities(organizationId, incidentId);
    }
    return this.customerService.listIncidentEntities(organizationId, incidentId);
  }

  public listIncidentObservables(organizationId: string, incidentId: string): Observable<ResponseUtils<IObservable>> {
    if (this.isVarMode) {
      return this.varService.listIncidentObservables(organizationId, incidentId);
    }
    return this.customerService.listIncidentObservables(organizationId, incidentId);
  }

  public listEscalationParameters(request: IListEscalationParametersRequest): Observable<any> {
    if (this.isVarMode) {
      return this.varService.listEscalationParameters(request);
    }
    return this.customerService.listEscalationParameters(request);
  }

  public postTaskObservables(organizationId: string, request: IPostTaskObservablesRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.postTaskObservables(organizationId, request);
    }
    return this.customerService.postTaskObservables(organizationId, request);
  }

  public postTaskEntities(organizationId: string, request: IPostTaskEntitiesRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.postTaskEntities(organizationId, request);
    }
    return this.customerService.postTaskEntities(organizationId, request);
  }

  public postTaskActions(organizationId: string, request: IPostTaskActionsRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.postTaskActions(organizationId, request);
    }
    return this.customerService.postTaskActions(organizationId, request);
  }

  public postTaskMessages(organizationId: string, request: IPostTaskMessagesRequest): Observable<IDescribeIncidentTask[]> {
    if (this.isVarMode) {
      return this.varService.postTaskMessages(organizationId, request);
    }
  }

  public deleteTaskObservables(organizationId: string, request: IDeleteTaskObservablesRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.deleteTaskObservables(organizationId, request);
    }
    return this.customerService.deleteTaskObservables(organizationId, request);
  }

  public deleteTaskActions(organizationId: string, request: IDeleteTaskActionsRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.deleteTaskActions(organizationId, request);
    }
    return this.customerService.deleteTaskActions(organizationId, request);
  }

  public deleteTaskEntities(organizationId: string, request: IDeleteTaskEntitiesRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.deleteTaskEntities(organizationId, request);
    }
    return this.customerService.deleteTaskEntities(organizationId, request);
  }

  public deleteTaskMessage(organizationId: string, request: IDeleteTaskMessageRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.deleteTaskMessage(organizationId, request);
    }
  }

  public deleteIncidentAction(organizationId: string, request: IDeleteIncidentActionRequest): Observable<IDescribeIncidentResponse> {
    if (this.isVarMode) {
      return this.varService.deleteIncidentAction(organizationId, request);
    }
    return this.customerService.deleteIncidentAction(organizationId, request);
  }

  public updateIncidentAction(organizationId: string, request: IUpdateIncidentActionRequest): Observable<void> {
    if (this.isVarMode) {
      return this.varService.updateIncidentActions(organizationId, request);
    }
  }

  public describeIncidentNotes(organizationId: string, incidentId: string, params?: any): Observable<ResponseUtils<IIncidentMessage>> {
    if (this.isVarMode) {
      return this.varService.describeIncidentNotes(organizationId, incidentId, params);
    }
  }

  public postIncidentNote(organizationId: string, incidentId: string, params?: any): Observable<IIncidentMessage[]> {
    if (this.isVarMode) {
      return this.varService.postIncidentNote(organizationId, incidentId, params);
    }
  }

  public updateIncidentNote(organizationId: string, incidentId: string, params?: any): Observable<IIncidentMessage[]> {
    if (this.isVarMode) {
      return this.varService.updateIncidentNote(organizationId, incidentId, params);
    }
  }

  public deleteIncidentNote(organizationId: string, incidentId: string, noteId: string): Observable<IIncidentMessage[]> {
    if (this.isVarMode) {
      return this.varService.deleteIncidentNote(organizationId, incidentId, noteId);
    }
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
