import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { IPlaybookDetail } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { AlertingUsecaseListItem, IUsecase } from 'projects/@common/services/api/respond/usecase/usecase.definition';

@Component({
  selector: 'usecase-summary',
  templateUrl: './usecase-summary.component.html',
  styleUrls: [ './usecase-summary.component.scss' ],
})
export class UsecaseSummaryComponent implements OnInit {
  @Input() currentUsecase: AlertingUsecaseListItem;
  @Input() currentPlaybook: IPlaybookDetail;
  @Input() usecaseTemplate: IUsecase;

  public description: string;

  public playbookNameWithSuffix: string;

  constructor(
    private translatedObjectPipe: TranslatedObjectPipe,
    public readonly i18n: I18nService
  ) { }

  ngOnInit(): void {
    this.description = this.translatedObjectPipe.transform(this.currentUsecase.description);
    this.playbookNameWithSuffix = this.currentPlaybook ? `${this.translatedObjectPipe.transform(this.currentPlaybook.name)} ${this.currentPlaybook.suffix}` : '-';
  }
}
