import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EcoSessionState } from "@common/modules/session/state/session.state";
import { Store } from "@ngxs/store";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { DashboardApi } from "projects/@common/services/api/respond/dashboard/dashboard.api";
import { IListIncidentsResolutionStatusesRequest, IListIncidentsResolutionStatusesResponse, IListPlaybooksIncidentsCountRequest, IListPlaybooksIncidentsCountResponse } from "projects/@common/services/api/respond/dashboard/dashboard.definitions";
import { UrlUtils } from "projects/@common/utils/utils";

@Injectable({
  providedIn: 'root',
})
export class OrgDashboardApi extends DashboardApi {
  constructor(private store: Store, readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listPlaybooksIncidentsCount(organizationId: string, queryParam?: IListPlaybooksIncidentsCountRequest): Promise<IRespondResponse<IListPlaybooksIncidentsCountResponse>> {
    if (this.isVarMode) {
      const url = `${super.host}/organizations/${organizationId}/reporting/playbooks/incidents?${UrlUtils.jsonToQueryParams(queryParam)}`;
      return this.http.get<IRespondResponse<IListPlaybooksIncidentsCountResponse>>(url).toPromise();
    }
    const url = `${super.host}/me/reporting/playbooks/incidents?${UrlUtils.jsonToQueryParams(queryParam)}`;
    return this.http.get<IRespondResponse<IListPlaybooksIncidentsCountResponse>>(url).toPromise();
  }

  public listIncidentsResolutionStatuses(organizationId: string, queryParam?: IListIncidentsResolutionStatusesRequest): Promise<IListIncidentsResolutionStatusesResponse> {
    if (this.isVarMode) {
      const url = `${super.host}/organizations/${organizationId}/reporting/incidents/statuses?${UrlUtils.jsonToQueryParams(queryParam)}`;
      return this.http.get<IListIncidentsResolutionStatusesResponse>(url).toPromise();
    }
    const url = `${super.host}/me/reporting/incidents/statuses?${UrlUtils.jsonToQueryParams(queryParam)}`;
    return this.http.get<IListIncidentsResolutionStatusesResponse>(url).toPromise();
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
