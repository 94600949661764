import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DatasourcesApiService } from 'projects/@common/services/api/respond/datasources/datasources.api';
import { DatasourceInventoryInfo } from 'projects/@common/services/api/respond/datasources/datasources.definitions';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { BehaviorSubject } from 'rxjs';
import { DatasourcesCategoryMapper } from '../mappers/datasourcesCategoryMapper';

export enum DatasourceStatusEnum {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export interface DatasourcesInventoryQueryParams {
  fulltext?: string;
  status?: DatasourceStatusEnum;
}

export class DatasourcesInventoryActions {
  public $datasources = new BehaviorSubject([]);

  public total = 0;

  public requestsQuery: string = null;

  public categoryActiveFilter: DatasourceStatusEnum = DatasourceStatusEnum.ALL;

  public datasourceCategoryEnum = DatasourceStatusEnum;

  public isLoading = false;

  private currentDatasources: DatasourceInventoryInfo[] = [];

  constructor(
    private organizationId: string,
    private readonly datasourcesApiService: DatasourcesApiService,
    private readonly usecasesApiService: UsecasesApiService,
    private readonly datasourcesCategoryMapper: DatasourcesCategoryMapper,
    private readonly i18n: I18nService
  ) {
  }

  public get defaultDropdownOption(): DatasourceStatusEnum {
    return DatasourceStatusEnum.ALL;
  }

  public async handleFilter(selected: DatasourceStatusEnum): Promise<void> {
    this.categoryActiveFilter = selected;
    await this.handleRefresh();
  }

  public async handleSearchQuery(value: string): Promise<void> {
    this.requestsQuery = value;

    await this.handleRefresh();
  }

  public async handleSearchClear(): Promise<void> {
    this.requestsQuery = null;
    await this.handleRefresh();
  }

  public async handleRefresh(): Promise<any> {
    this.isLoading = true;
    const datasources = await this.datasourcesApiService.getDatasourcesFamily(this.organizationId);
    const datasourceInfo = await this.usecasesApiService.getDatasourceFamilyInfo(this.organizationId);
    this.isLoading = false;

    this.currentDatasources = datasources.map((ds) => {
      const find = datasourceInfo.find((info) => info.datasourceFamily === ds.type);
      return this.datasourcesCategoryMapper.fromEntityToView(ds, find);
    });

    const filteredDatasources = this.applyFilter();

    this.total = filteredDatasources.length;
    this.$datasources.next(filteredDatasources);
  }

  private filterByName(tempDatasource: DatasourceInventoryInfo[]): DatasourceInventoryInfo[] {
    return tempDatasource.filter((ds) => {
      const title = this.i18n.translate(ds.title);
      if (title.toLowerCase().includes(this.requestsQuery.toLowerCase())) {
        return ds;
      }
    });
  }

  private filterByStatus(tempDatasource: DatasourceInventoryInfo[]): DatasourceInventoryInfo[] {
    switch (this.categoryActiveFilter) {
      case DatasourceStatusEnum.ACTIVE:
        return tempDatasource.filter((ds) => ds.state);
      case DatasourceStatusEnum.INACTIVE:
        return tempDatasource.filter((ds) => !ds.state);
      default:
        return tempDatasource;
    }
  }

  private applyFilter(): DatasourceInventoryInfo[] {
    let tempDatasource = [ ...this.currentDatasources ];

    if (this.requestsQuery) {
      tempDatasource = this.filterByName(tempDatasource);
    }
    if (this.categoryActiveFilter) {
      tempDatasource = this.filterByStatus(tempDatasource);
    }

    return tempDatasource;
  }
}
