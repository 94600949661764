import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SimilarityOnAssociateEventData } from './similarity.definitions';

@Injectable({
  providedIn: 'root',
})
export class SimilarityEventsService {
  private onAssociateSuccess = new Subject<SimilarityOnAssociateEventData>();

  public onAssociateSuccessEvent$ = this.onAssociateSuccess.asObservable();

  constructor() { }

  public emitOnAssociateSuccessEvent(data: SimilarityOnAssociateEventData) {
    this.onAssociateSuccess.next(data);
  }
}
