import { color } from '@amcharts/amcharts4/core';
import { Component, Input, OnInit } from '@angular/core';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { ITimeInterval } from 'projects/@common/components/date-interval/date-interval.component';
import { IRespondResponse } from 'projects/@common/definitions/IRespondResponse';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { DashboardApi } from 'projects/@common/services/api/respond/dashboard/dashboard.api';
import { IListPlaybooksIncidentsCountResponse } from 'projects/@common/services/api/respond/dashboard/dashboard.definitions';
import { RespondDashboardTimeRangesEnum } from '../../containers/respond-dashboard-container/respond-dashboard-container.component';
import { getTimestampsFromRange } from '../../helpers/getTimestampsFromRange';

@Component({
  selector: 'playbooks-summary',
  templateUrl: './playbooks-summary.component.html',
  styleUrls: [ './playbooks-summary.component.scss' ],
})
export class PlaybooksSummaryComponent implements OnInit {
  public readonly TIME_RANGE_ENUM = RespondDashboardTimeRangesEnum;

  private readonly COLOR_RED = '#DE3618';

  private readonly COLOR_GREEN = '#00d873';

  private readonly COLOR_GRAY = '#999';

  private readonly GREEN_COLORS = [ '#004c2a', '#006f3e', '#068d44', '#1aa969', '#5ecc99' ];

  private readonly COLOR_OTHERS = '#9eddc1';

  public isLoading = false;

  public playbooksWithIncidentsCount: any[] = [];

  public legendTemplate = '';

  public locale: LanguageEnum;

  public currentTimeRange = RespondDashboardTimeRangesEnum.CURRENT_WEEK;

  public dateRange: ITimeInterval;

  @Input()
  public activeOrganization: IAutomationOrganization;

  @Input()
  public adm: boolean = false;

  constructor(
    private dashboardService: DashboardApi,
    private readonly i18n: I18nService,
    private readonly noticeService: NoticeService,
    private readonly translatedObject: TranslatedObjectPipe
  ) { }

  ngOnInit() {
    this.locale = this.i18n.currentLocale as LanguageEnum;
    this.isLoading = true;
    this.loadData();
  }

  private loadData(): void {
    this.isLoading = true;

    const { incidentFrom, incidentTo } = getTimestampsFromRange(this.currentTimeRange);

    // We need to remove the offset between the current timezone and UTC
    const offSetFrom = incidentFrom ? new Date(incidentFrom).getTimezoneOffset() * 60000 : 0;
    const offSetTo = incidentTo ? new Date(incidentTo).getTimezoneOffset() * 60000 : 0;

    this.dateRange = { dateFrom: (incidentFrom || Date.now()) - offSetFrom, dateTo: (incidentTo || Date.now()) - offSetTo };

    this.dashboardService
      .listPlaybooksIncidentsCount(this.activeOrganization?.id, { incidentFrom: this.dateRange.dateFrom, incidentTo: this.dateRange.dateTo })
      .then((response: IRespondResponse<IListPlaybooksIncidentsCountResponse>) => {
        this.playbooksWithIncidentsCount = [];
        for (let i = 0; i < 5; i++) {
          const playbook = response.items[i];
          if (playbook?.incidentCount) {
            this.playbooksWithIncidentsCount.push({
              level: `${this.translatedObject.transform(playbook.playbook.name)} - ${playbook.playbook.suffix}`,
              count: playbook.incidentCount,
              color: color(this.GREEN_COLORS[i]),
            });
          }
        }

        const othersCount = response.items.slice(5).reduce((sum, item) => sum + item?.incidentCount, 0);
        if (othersCount) {
          this.playbooksWithIncidentsCount.push({
            level: this.i18n.translate('respond.dashboard.playbooksIncidentCount.others'),
            count: othersCount,
            color: color(this.COLOR_OTHERS),
          });
        }
      })
      .catch((error) => {
        this.noticeService.notifyUser(new Notice("alerts.container.page.notice.fetch.error", NoticeLevels.ERROR, null, error?.message));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public handleTimeRange(range: RespondDashboardTimeRangesEnum): void {
    this.currentTimeRange = range;
    this.loadData();
  }

  public get playbooksUrl(): string {
    if (this.adm) {
      return `/respond/${this.activeOrganization?.ecoId}/playbooks`;
    }
    return `/respond/playbooks`;
  }
}
