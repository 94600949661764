<div class="card">
  <div class="card--content">
    <ng-content select="[tabHeader]"></ng-content>
  </div>
  <div class="card--tabs-section">
    <ng-container *ngFor="let tab of tabs">
      <div
        class="card--tabs-section--tab-button"
        (click)="handleTabChange(tab)"
        [ngClass]="
          tab.id === currentTabSelected.id
            ? 'card--tabs-section--tab-button--active'
            : 'card--tabs-section--tab-button'"
      >
        <p>
          <span class="card--tabs-section--tab-button" [class.bold]="tab.isBold">{{translatePrefix + tab.name | t}}</span>
          <span
            *ngIf="tab.count"
            class="card--tabs-section--tab-button--tab-counter"
          >({{tab.count}})</span>
        </p>
        <img
          *ngIf="tab.icon"
          class="card--tabs-section--tab-button--tab-icon"
          [src]="tab.icon"
          [tooltip]="tab.iconTooltip ? (tab.iconTooltip | t) : null"
        />
      </div>
    </ng-container>
  </div>
</div>

<ng-content></ng-content>