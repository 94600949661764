import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SgExpendableCardComponent } from 'projects/@common/components/sg-expendable-card/sg-expendable-card.component';


@Component({
  selector: 'settings-edition-card',
  templateUrl: './settings-edition-card.component.html',
  styleUrls: [ './settings-edition-card.component.scss' ],
})
export class SettingsEditionCardComponent {
  @Input() public title: string;
  @Input() public icon: string;
  @Input() public isEditing: string;
  @Input() public canSave: boolean;
  @Input() public confirmationSave: boolean = false;
  @Input() public canEdit: boolean = true;

  @Output() toggleEditingEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() saveEvent: EventEmitter<Function> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter();

  @ViewChild('expendable') expendable: SgExpendableCardComponent;

  constructor() { }

  public toggleEditing(editing: boolean, confirm?: boolean) {
    if (editing) {
      this.expendable.open();
    }
    this.toggleEditingEvent.emit(editing);
  }

  public save(closeEditEvent: Function): void {
    this.saveEvent.emit(closeEditEvent);
  }
}
