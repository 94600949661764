import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";


export enum ListAssetsOrderBy {
  name = 'name',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  type = 'type'
}

export enum ListDirectionEnum {
  asc = 'ASC',
  desc = 'DESC'
}

export enum PropertyType {
  IP = "IP",
  EMAIL = "EMAIL",
  TEXT = "TEXT",
  SELECT = "SELECT",
  ASSET = "ASSET"
}

export enum ActifsListViewModeEnum {
  SIMPLE_LIST = 'list',
  GROUPED_BY_ASSET_TYPE = 'grouped'
}

export interface IListAssetType {
  id: string;
  type: string;
  config: IPropertyConfig;
}

export interface IListAssetsRequest {
  organizationId?: string;
  types?: string[];
  from?: number;
  size?: number;
  orderBy?: ListAssetsOrderBy;
  direction?: ListDirectionEnum;
  searchText?: string;
}

export interface IDescribeAssetsRequest {
  assetId: string;
  organizationId?: string;
}

export interface IAssetRepresentation {
  id: string;
  createdAt: number;
  updatedAt: number;
  name: string;
  note: string;
  type: string;
  properties?: Record<string, string[]>;
  typeName: string;
  organizationId: string;
  createdById: string;
  createdByName: string;
  updatedById: string;
  updatedByName: string;
  relations?: string[];
}

export type IListAssetsResponse = IRespondResponse<IAssetRepresentation>;

export interface ICreateAssetPropertyRequest {
  propertyId: string;
  values: string[];
}

export interface ICreateAssetRequest {
  name: string;
  note: string;
  type: string;
  organizationId: string;
  properties: ICreateAssetPropertyRequest[];
};

export interface IUpdateAssetRequest {
  name: string;
  note: string;
  id: string;
  organizationId: string;
  properties: ICreateAssetPropertyRequest[];
};

export interface IPropertyConfig {
  showRelations: boolean;
  name: ITranslatedField;
  properties: IProperty[];
}

export interface IProperty {
  propertyId: string;
  type: PropertyType;
  observable?: string;
  order: number;
  name: ITranslatedField;
  tooltips: ITranslatedField;
  placeholder: ITranslatedField;
  options?: { key: string; value: ITranslatedField; }[];
  linkedAsset?: { type: string; action: ITranslatedField; };
  multiple: boolean;
  required: boolean;
  informative: boolean;
  showInList: boolean;
  showRelations: boolean;
  properties: {
    propertyId: string;
    type: PropertyType;
    order: number;
    showInList: boolean;
    name: ITranslatedField;
    tooltips: ITranslatedField;
    placeholder: ITranslatedField;
    options?: { key: string; value: ITranslatedField; }[];
    linkedAsset?: { type: string; action: ITranslatedField; };
    multiple: boolean;
    required: boolean;
    informative: boolean;
  }[];
}

export interface IDescribeAssetResponse {
  asset: IAssetRepresentation;
  config: IPropertyConfig;
  properties: { propertyId: string; values: string[]; }[];
  relations: { id: string; name: string; }[];
};

export type ICreateAssetResponse = IDescribeAssetResponse;

export type IUpdateAssetResponse = IDescribeAssetResponse;

export interface IDeleteAssetsRequest {
  id: string;
  organizationId?: string;
};

export interface IDeleteAssetsResponse {
  asset: IAssetRepresentation;
  success: boolean;
};

export interface IAssetPropertyConfigRepresentation {
  id: string;
  type: string;
  config: IPropertyConfig;
}

export type IListAssetTypesResponse = IRespondResponse<IAssetPropertyConfigRepresentation>;

export enum ListActifsPageEnum {
  ACTIFS_LIST_PAGE = 'actifsListPage',
  ALERT_DETAIL_PAGE = 'alertDetailPage',
  INCIDENT_DETAIL_PAGE = 'incidentDetailPage'
}

export type ListActifsPageContext = {
  enum: ListActifsPageEnum;
  organizationId?: string;
  alertId?: string;
  incidentId?: string;
};

export interface IActifsApi {
  listAssets(request: IListAssetsRequest): Observable<ResponseUtils<IAssetRepresentation>>;
  listAlertAssets(organizationId?: string, alertId?: string): Observable<ResponseUtils<IAssetRepresentation>>
  listIncidentAssets(organizationId?: string, incidentId?: string): Observable<ResponseUtils<IAssetRepresentation>>;
  describeAsset(request: IDescribeAssetsRequest): Observable<IDescribeAssetResponse>
  listAssetTypes(organizationId?: string): Observable<ResponseUtils<IAssetPropertyConfigRepresentation>>
  createAsset(request: ICreateAssetRequest): Observable<ICreateAssetResponse>;
  updateAsset(request: IUpdateAssetRequest): Observable<IUpdateAssetResponse>;
  deleteAsset(request: IDeleteAssetsRequest): Promise<IDeleteAssetsResponse>;
}
