import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { HtmlEditorComponent } from "@ui-kit/components/html-editor/html-editor.component";
import { DisplayService } from "projects/@common/modules/display/display.service";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { Notice, NoticeLevels, NoticeService } from "projects/@common/modules/notice/notice.service";
import { ImagesUploaderService, UploadFolderEnum } from "projects/@common/services/api/respond/images/images-uploader.service";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";

import { IncidentDifferenceService } from "../../../../../../services/incident.difference.service";
import { TabNamesEnum } from "../../../../incidents-details.component";

@Component({
  selector: 'app-additional-info-section',
  templateUrl: './additional-info-section.component.html',
  styleUrls: [ './additional-info-section.component.scss' ],
})
export class AdditionalInfoSectionComponent implements OnInit {
  @ViewChild('editor') editorComponent: HtmlEditorComponent;

  @Input()
    organizationId: string;

  @Input()
    incident: Incident;

  public infoGroup = new UntypedFormGroup({ additionalInfo: new UntypedFormControl(null) });

  public isEditing = false;

  public editorEventHandlers: { [key: string]: Function };

  public initialValue: string;

  constructor(
    private readonly incidentsApi: IncidentsApi,
    private imagesUploaderService: ImagesUploaderService,
    private incidentDifferenceService: IncidentDifferenceService,
    public i18nService: I18nService,
    private readonly notice: NoticeService,
    private readonly displayService: DisplayService
  ) { }

  ngOnInit(): void {
    this.initialValue = this.incident.additionalInfo;
    this.setValue(this.initialValue);

    this.editorEventHandlers = {
      'image.beforeUpload': (images) => {
        this.imagesUploaderService
          .uploadToS3(images[0], images[0].name, UploadFolderEnum.INCIDENTS, this.incident.id, this.organizationId)
          .then((imageUrl) => {
            this.editorComponent.editor.image.insert(imageUrl, null, null, this.editorComponent.editor.image.get());
          })
          .catch((err: any) => {
            console.log(err);
          });
      },
      'contentChanged': () => this.handleHtmlContentChangeEvent(),
    };
  }

  public get infoValue(): string {
    return this.infoGroup.get('additionalInfo')?.value;
  }

  public get isInfoGroupEmpty(): boolean {
    return !this.infoValue || this.infoValue?.length === 0;
  }

  public get canEditAditionnalInfo(): boolean {
    return this.incident.isEditable && this.displayService.meetsRequirements({ permissions: [ 'can_update_incident' ] });
  }

  public get shouldShowUnsavedWarning(): boolean {
    return this.incidentDifferenceService.shouldShowUnsavedWarnings;
  }

  public openEditor(): void {
    if (this.canEditAditionnalInfo && !this.isEditing) {
      this.isEditing = true;
      this.handleInfoEditingState(false);
    }
  }

  public closeEditor(): void {
    this.isEditing = false;
    this.handleInfoEditingState(false);
  }

  public apply(): void {
    this.incident.additionalInfo = this.infoValue;
    this.initialValue = this.incident.additionalInfo;
    this.save();
    this.closeEditor();
  }

  public cancel(): void {
    this.setValue(this.initialValue);
    this.closeEditor();
  }

  public handleConfirmation(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.apply();
    } else {
      this.cancel();
    }
  }

  private save(): void {
    this.incidentsApi.updateIncident(this.organizationId, this.incident.updateRequestDto).subscribe(
      (response) => {
        this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.success'), NoticeLevels.SUCCESS));
        this.incident.reloadIncident(response.incident);
      },
      (error) => this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.error'), NoticeLevels.ERROR))
    );
  }

  private setValue(value: string) {
    this.infoGroup.get('additionalInfo').setValue(value);
  }

  private handleHtmlContentChangeEvent(): void {
    const hasUnsavedChanges = this.infoValue !== this.initialValue;
    this.handleInfoEditingState(hasUnsavedChanges);
  }

  private handleInfoEditingState(event: boolean): void {
    this.incidentDifferenceService.handleEditingStates({
      tabNameEnum: TabNamesEnum.DETAILS,
      itemId: "additionalInfo",
      hasUnsavedChange: event,
    });
  }
}
