import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '@ui-kit/services/modal.service';
import { IAssetRepresentation } from 'projects/@common/services/api/respond/actifs/actifs.definitions';
import { ActifsService } from '../../actifs.service';

export interface IActifsDeleteModalData {
  actifs: IAssetRepresentation[];
}

enum AsyncStateEnum {
  "SAVING", "READY"
};

@Component({
  selector: 'actifs-delete-modal',
  templateUrl: './actifs-delete-modal.component.html',
  styleUrls: [ './actifs-delete-modal.component.scss' ],
})
export class ActifsDeleteModalComponent implements OnInit {
  public readonly actifs: IAssetRepresentation[] = [];
  public readonly asyncStateEnum = AsyncStateEnum;
  public asyncState: AsyncStateEnum = AsyncStateEnum.READY;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: IActifsDeleteModalData,
    private readonly modalService: ModalService,
    private readonly actifsService: ActifsService
  ) {
    this.actifs = this.data.actifs;
  }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.modalService.closeDialog();
  }

  public async deleteAssets(): Promise<void> {
    this.asyncState = this.asyncStateEnum.SAVING;
    await this.actifsService.deleteAssets(this.actifs);
    this.asyncState = this.asyncStateEnum.READY;
    this.modalService.closeDialog();
  }
}
