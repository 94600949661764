import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, EventEmitter } from "@angular/core";
import { SessionService } from "@common/modules/session/session.service";
import { Store } from "@ngxs/store";
import { ModalService } from "@ui-kit/services/modal.service";
import { TimeUtil } from "@ui-kit/services/time-util";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";

@Component({
  selector: 'user-activity-dialog',
  templateUrl: './user-activity-dialog.component.html',
  styleUrls: [ './user-activity-dialog.component.scss' ],
})
export class UserActivityDialogComponent implements OnInit, AfterViewInit {
  public closeEvent = new EventEmitter<boolean>();
  public timer = TimeUtil.minuteDuration * 2;

  private countdown = null;
  constructor(
    public readonly i18n: I18nService,
    protected readonly modalService: ModalService,
    protected readonly store: Store,
    protected readonly sessionService: SessionService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    document.getElementById('closeModalButton').blur();
    this.countdown = setInterval(() => {
      this.timer -= 1000; // Minus 1 second every loop
      if (this.timer === 0) {
        clearInterval(this.countdown);
        this.logout();
      }
    }, 1000); // Every seconds
  }

  public closeModal(output: boolean): void {
    clearInterval(this.countdown);
    this.modalService.closeDialog();
    this.closeEvent.emit(output);
  }

  public logout(): void {
    this.closeModal(false);
    this.sessionService.invalidateSession();
  }
}
