import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NotificationsApiService } from "@common/services/api/notifications/notifications-api.service";
import { IEntityRepresentation } from "@common/services/api/respond/entities/entities.definition";
import { IincidentTaskMessage } from "@common/services/api/respond/incidentTasks/incidentTasks.definitions";
import { IncidentTask } from "@common/services/api/respond/incidentTasks/models/incidentTask";
import { IIncidentActionRepresentation, IIncidentMessage } from "@common/services/api/respond/incidents/incidents.definitions";
import { IObservable } from "@common/services/api/respond/observables/observables.definition";

@Injectable({
  providedIn: 'root',
})
export class IncidentMappersService {
  constructor(
    private notificationsApi: NotificationsApiService,
    private sanitizer: DomSanitizer,
  ) {}

  public mapObservablesToTaskObservables(incidentObservables: IObservable[], incidentTaskObservables: string[]): Array<IObservable & { __jsonViewString: string }> {
    return incidentObservables
      .filter((observable: IObservable) => incidentTaskObservables.includes(observable.id))
      .map((observable: IObservable) => ({
        ...observable,
        __jsonViewString: JSON.stringify({
          id: observable.id,
          type: observable.type,
          description: observable.description,
          value: observable.value,
          createdAt: observable.createdAt,
          source: observable.source,
        }),
      }));
  }

  public mapActionsToTaskActions(incidentActions: IIncidentActionRepresentation[], incidentTaskActions: string[]): IIncidentActionRepresentation[] {
    return incidentActions
      .filter((action: IIncidentActionRepresentation) => incidentTaskActions.includes(action.id))
      .map((action: IIncidentActionRepresentation) => ({ ...action, description: this.sanitizer.sanitize(SecurityContext.HTML, action.description) }));
  }

  public mapEntitiesToTaskEntities(incidentEntities: IEntityRepresentation[], incidentTaskEntities: string[]): IEntityRepresentation[] {
    return incidentEntities.filter((entity: IEntityRepresentation) => incidentTaskEntities.includes(entity.id));
  }

  public async mapMessagesToTaskMessages(incidentTask: IncidentTask, organizationId: string): Promise<Array<IincidentTaskMessage & {messageContent: string}>> {
    const out = [];
    const taskMessages = await Promise.all(incidentTask.findings.map((message) => this.notificationsApi.getIncidentMessage(organizationId, message.messageConversationId, {messageId: message.messageId}).toPromise()));

    taskMessages.forEach((taskMessage) => {
      const message = incidentTask.findings.find((message) => message.messageId === taskMessage.id);
      message['messageContent'] = taskMessage.content;
      out.push(message);
    })

    return out;
  }
}
