<ng-container *ngIf="!isFileUploadFieldsetVisible; else dynamicFormTemplate">
  <ui-field-group class="field-group">
    <!-- Send certificate button -->
    <button
      class="uploader-actions"
      (click)="toggleFileUploadVisibility()"
      color="linkFlat"
      ui-button
    >
      <ui-icon
        class="tooltip-title--icon"
        [name]="'upload'"
        [size]="'medium'"
      ></ui-icon>
      {{ formConfig.label | translatedObject }}
    </button>
    <span class="separator">ou</span>
    <button
      class="generate-actions"
      (click)="handleGenerateBtn()"
      color="linkFlat"
      ui-button
    >
      <ui-icon
        class="tooltip-title--icon"
        [name]="'certificate'"
        [size]="'medium'"
      ></ui-icon>
      {{ 'common.generate' | t }}
      <ui-spinner size="small" *ngIf="isLoading"></ui-spinner>
    </button>
  </ui-field-group>
</ng-container>

<ng-template #dynamicFormTemplate>
  <dynamic-form-template
    class="class"
    [formConfig]="formConfig"
    (fileUploadToggle)="toggleFileUploadVisibility()"
    (onCertificateUploaded)="handleCertificateUpload($event)"
  >
  </dynamic-form-template>
</ng-template>
