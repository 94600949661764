import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ModalService } from "@ui-kit/services/modal.service";
import { Eco } from "projects/@common/definitions/eco";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";

interface LanguageOption {
  language: Eco.Languages;
  selected: boolean;
}

@Component({
  selector: 'app-usecase-catalog-report-choose-language-modal',
  templateUrl: './usecase-catalog-report-choose-language-modal.component.html',
  styleUrls: [ './usecase-catalog-report-choose-language-modal.component.scss' ],
})
export class UsecaseCatalogReportChooseLanguageModalComponent implements OnInit {
  @Output()
  public readonly successEvent = new EventEmitter<Eco.Languages>();
  public selectedLanguage: Eco.Languages;
  public languageOptions: LanguageOption[];

  constructor(
    private readonly modalService: ModalService,
    public readonly i18n: I18nService
  ) {
    this.selectedLanguage = null;
    this.languageOptions = Object.keys(Eco.Languages).map((language) => ({
      language: Eco.Languages[language],
      selected: false,
    }));
  }

  ngOnInit(): void {
  }

  public async generateCatalog(): Promise<void> {
    this.successEvent.emit(this.selectedLanguage);
    this.modalService.closeDialog();
  }

  public cancel(): void {
    this.successEvent.emit(null);
    this.modalService.closeDialog();
  }

  public setLanguage(option: LanguageOption): void {
    this.languageOptions.forEach((option) => option.selected = false);
    option.selected = true;
    this.selectedLanguage = option.language;
  }
}
