import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UiKitModule } from "@ui-kit/uikit.module";
import { ComponentsModule } from "projects/@common/components/components.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { PipesModule } from "projects/@common/modules/pipes/pipes.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";

import { RespondReportsFiltersComponent } from './components/respond-reports-filters/respond-reports-filters.component';
import { RespondReportsPeriodComponent } from './components/respond-reports-period/respond-reports-period.component';
import { RespondReportsPrintButtonComponent } from './components/respond-reports-print-button/respond-reports-print-button.component';
import { RespondReportsTableComponent } from './components/respond-reports-table/respond-reports-table.component';
import { RespondReportsListComponent } from './containers/respond-reports-list/respond-reports-list.component';
import { RespondReportsPeriodCodeComponent } from "./components/respond-reports-period-code/respond-reports-period-code.component";


const components = [
  RespondReportsListComponent,
  RespondReportsTableComponent,
  RespondReportsFiltersComponent,
  RespondReportsPeriodComponent,
  RespondReportsPrintButtonComponent,
  RespondReportsPeriodCodeComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    PipesModule,
    RouterModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
  ],
})
export class RespondReportsModule { }
