import { Injectable } from '@angular/core';
import { StorageService } from '../../../storage.service';
import { TemporaryDataAbstract } from './temporary-data.abstract';

interface IGuestManager {
  displayName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  o365UserId?: string;
  date?: number;
  toHide?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GuestTemporaryDataService extends TemporaryDataAbstract {
  protected storageKey = 'guestManagers';

  constructor(protected storageService: StorageService) {
    super(storageService);
  }

  public addGuestManager(ttlGuestManager: IGuestManager): void {
    this.add(ttlGuestManager, 'o365UserId');
  }

  public removeGuestManager(guestManagereO365UserId: string[]): void {
    this.remove(guestManagereO365UserId, 'o365UserId');
  }

  public getStoredGuestManagers(upToDateManagers: any[]): IGuestManager[] {
    const fakeManagers: IGuestManager[] = this.getFilteredValue(this.storageKey);
    const originCopy = [ ...upToDateManagers ];

    fakeManagers.forEach((fakeManager, index) => {
      const elementIsPresent = originCopy.find((originManager) => fakeManager.o365UserId === originManager.id || fakeManager.o365UserId === originManager.o365UserId);
      if (!elementIsPresent && !fakeManager.toHide) {
        originCopy.push(fakeManager);
      } else if (!fakeManager.toHide) {
        this.removeGuestManager([ fakeManager.o365UserId ]);
      }
    });

    return originCopy.filter((user) => !fakeManagers.find((fakeManager) => fakeManager.o365UserId === user.id)?.toHide);
  }
}
