import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IComputedEscalation, IIncidentMessage } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { ITabsInfo } from 'projects/@common/components/custom-tabs/custom-tabs.component';

export interface IMessage {
  id: string;
  o365UserId: string;
  createdAt: number;
  senderName: string;
  text: string;
}

@Component({
  selector: 'app-chat-tab',
  templateUrl: './chat-tab.container.html',
  styleUrls: [ './chat-tab.container.scss' ],
})
export class ChatTabContainer {
  @Input() isFromAdmConsole: boolean;

  @Input() incidentConversation: IIncidentMessage[] = [];

  @Input() incidentNotes: IIncidentMessage[] = [];

  @Input() incident: Incident;

  @Input() currentTabSelected: ITabsInfo;

  @Output() computedEscalationListChange = new EventEmitter<IComputedEscalation>();

  public isExpanded: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public onExpand(isExpanded: boolean): void{
    this.isExpanded = isExpanded;
  }

  handleComputedEscalationListChange(escalationList: IComputedEscalation) {
    this.computedEscalationListChange.emit(escalationList);
  }
}
