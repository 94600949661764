import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IUsecaseDescriptionResponse } from "projects/@common/services/api/respond/usecase/usecase.definition";

interface ISimpleUsecaseData {
  name: ITranslatedField;
  description: ITranslatedField;
  shortId: string;
}

@Component({
  selector: 'app-usecase-section',
  templateUrl: './usecase-section.component.html',
  styleUrls: [ './usecase-section.component.scss' ],
})
export class UsecaseSectionComponent implements OnChanges {
  @Input() incident: Incident;
  @Input() usecases: IUsecaseDescriptionResponse[];
  @Input() organizationId: string;

  public usecasesData: ISimpleUsecaseData[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.usecases?.currentValue) {
      this.usecasesData = changes.usecases.currentValue.map((uc: IUsecaseDescriptionResponse) => ({
        name: uc.usecase.name,
        description: uc.usecase.description,
        shortId: uc?.template?.usecaseId || "-",
      }));
    }
  }
}

