import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import {
  DatasourceTableIconsComponent
} from 'projects/@common/modules/datasources/components/datasource-table-icons/datasource-table-icons.component';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import {
  DatasourceMode,
  IDatasourceTableObject
} from '../../../components/datasource-inventory-detail/components/detail-body/detail-body.component';
import {
  ProfileTableUsersCountComponent
} from '../../../components/profile-table-users-count/profile-table-users-count.component';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';

@Component({
  selector: 'detection-usecases-table',
  templateUrl: './detection-usecases-table.component.html',
  styleUrls: [ './detection-usecases-table.component.scss' ],
})
export class DetectionUsecasesTableComponent implements OnChanges, OnInit {
  @Input() public datasources: IDatasourceTableObject[] = [];
  @Input() public loading: boolean;
  public locale = LanguageEnum.FRENCH;
  public columnsDef = [
    {
      label: '',
      field: 'icon',
      showIconOnHover: true,
      type: UiStaticTableRowType.EXPENDABLE_ICON,
      noSorting: true,
      width: '45px',
      isResizable: false,
    },
    {
      label: 'detection.usecase.inventory.table.label.name',
      field: 'name',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      width: '45%',
    },
    {
      label: 'detection.usecase.inventory.table.label.mode',
      field: 'mode',
      type: UiStaticTableRowType.ENUM_WITH_ICON,
      enumPrefix: 'detection.datasource.inventory.detail.table.',
      iconName: this.getModeIconName,
      customIcon: true,
      isSvg: true,
      iconSide: 'left',
      width: '170px',
    },
    {
      label: 'detection.usecase.inventory.table.label.priority',
      field: 'priority',
      type: UiStaticTableRowType.TEXT,
      width: '100px',
    },
    {
      label: 'detection.usecase.inventory.table.label.activationDate',
      field: 'activationDate',
      type: UiStaticTableRowType.DATE,
      component: ProfileTableUsersCountComponent,
    },
    {
      label: 'detection.usecase.inventory.table.label.datasource',
      field: 'datasourcesIcon',
      firstParamData: 'datasourcesIcon',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: DatasourceTableIconsComponent,
      paramName: 'datasourceIcons',
      width: '230px',
    },
  ];

  constructor(
    private staticTableDataFactory: StaticTableDataMapper,
    private i18nService: I18nService
  ) {
  }

  ngOnInit(): void {
    this.locale = this.i18nService.currentLocale as LanguageEnum;
  }

  ngOnChanges(changes: SimpleChanges): void {
    [ this.columnsDef, this.datasources ] = this.staticTableDataFactory.translate(this.columnsDef, this.datasources);
  }

  private getModeIconName(datasource: any): string {
    return datasource.mode === DatasourceMode.SILENCE ? 'favicons/icon_green_dot_reverse' : 'favicons/icon_green_dot';
  }
}
