<div class="main-wrapper">
  <ng-container
    *ngIf="isParamsSelectionActive && currentSelection[langEnum.FRENCH]"
    [ngTemplateOutlet]="autocomplete"
    [ngTemplateOutletContext]="{lang:langEnum.FRENCH}"
  ></ng-container>
  <ng-container
    *ngIf="isParamsSelectionActive && currentSelection[langEnum.ENGLISH]"
    [ngTemplateOutlet]="autocomplete"
    [ngTemplateOutletContext]="{lang:langEnum.ENGLISH}"
  ></ng-container>
  <div class="main-wrapper--textarea-wrapper">
    <div class="flex">
      <div class="main-wrapper--icon-wrapper">
        <img
          alt="default image"
          *ngIf="textareaValues.iconName"
          class="icon"
          [class.margin-left]="textareaValues.iconName === 'icon_autres_demandes'"
          src="assets/favicons/{{textareaValues.iconName}}.svg"
        />
        <div
          *ngIf="!textareaValues.iconName"
          class="square-icon-grey"
        ></div>
      </div>
      <label
        class="label"
        ui-label
      >{{textareaValues.label | t}}</label>
    </div>
    <div class="flex">
      <div class="max-width">
        <div class="main-wrapper--textarea-wrapper--label-wrapper">
          <label
            ui-label
            [required]="isFrenchRequired"
            (click)="setSelection(langEnum.FRENCH)"
            [class.isSelected]="currentSelection.fr"
            [class.isInvalid]="textareaValues.formGroup.get(langEnum.FRENCH).invalid && textareaValues.formGroup.get(langEnum.FRENCH).touched && isFrenchRequired"
          >{{langEnum.FRENCH | t}}</label>
          <label
            ui-label
            [required]="isEnglishRequired"
            (click)="setSelection(langEnum.ENGLISH)"
            [class.isSelected]="currentSelection.en"
            [class.isInvalid]="textareaValues.formGroup.get(langEnum.ENGLISH).invalid && textareaValues.formGroup.get(langEnum.ENGLISH).touched && isEnglishRequired"
          >{{langEnum.ENGLISH | t}}</label>
        </div>
        <ng-container
          *ngIf="currentSelection.fr"
          [ngTemplateOutlet]="textarea"
          [ngTemplateOutletContext]="{lang:langEnum.FRENCH}"
        ></ng-container>
        <ng-container
          *ngIf="currentSelection.en"
          [ngTemplateOutlet]="textarea"
          [ngTemplateOutletContext]="{lang:langEnum.ENGLISH}"
        ></ng-container>
      </div>
    </div>
  </div>


  <ng-template
    #textarea
    let-lang='lang'
  >
    <div class="input-button-container">
      <div class="input-container">
        <ng-container [formGroup]="textareaValues.formGroup">
          <textarea
            [id]="uuid[lang]"
            type="textarea"
            class="main-wrapper--textarea-wrapper--textarea"
            [maxlength]="textareaValues.maximumChar"
            [formControlName]="lang"
            [ngStyle]="textAreaStyles"
            [placeholder]="textareaValues.placeholder | t"
          ></textarea>
          <p
            class="main-wrapper--textarea-wrapper--max-char"
            *ngIf="textareaValues.maximumChar"
          >{{'detection.playbook.task.specific.max-char' | t:{number: textareaValues.maximumChar} }}</p>
        </ng-container>
      </div>
      <div
        class="main-wrapper--button-wrapper"
        [ngStyle]="isParamsSelectionActive ? {'marginTop': '36px'} : null"
      >
        <button
          class="parameter-button"
          (click)="openParametersList()"
        >
          <img
            class="image"
            src="assets/favicons/icon_parametre.svg"
            rightIcon
            alt="Parameters"
          />
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template
    #autocomplete
    let-lang='lang'
  >
    <div class="autocomplete-container">
      <div class="autocomplete-input">
        <autocomplete
          [canMultiSelect]="false"
          [oneItemAtATime]="true"
          [placeholder]="'org_usecase.create_drawer.catalog_selection.placeholder' | t"
          [customValues]="autocompleteUsecaseTemplateValues"
          [(selectedItems)]="selectedUsecaseTemplate"
          [autocompleteType]="customAutocomplete"
          [maxHeight]="true"
          (onItemSelected)="onValueSelection($event, lang)"
        ></autocomplete>
      </div>
      <div class="icon_container">
        <button
          (click)="applyParam(lang)"
          class="no-style-button accept"
        >
          <img
            src="assets/favicons/icon_conforme.svg"
            alt="apply"
          >
        </button>
        <button
          (click)="closeParametersList()"
          class="no-style-button denied"
        >
          <img
            src="assets/favicons/icon_stop_sharing.svg"
            alt="decline"
          >
        </button>
      </div>
    </div>
  </ng-template>