import { FileUtilityHelper } from './file-utility.helper';
import * as forge from 'node-forge';

export interface IPemComponents {
  privateKey?: string;
  publicKey?: string;
  certificate?: string;
  expirationDate?: number;
}

export class CertificateUtilityHelper {

   /**
   * Method to extract private key, public key, and certificate from PEM content
   * @param pemContent - Full PEM string content
   * @returns IPemComponents - Extracted components (private key, public key, certificate)
   */
   static extractPemComponents(pemContent: string): IPemComponents {

    const privateKey = CertificateUtilityHelper._extractSection(pemContent, 'PRIVATE KEY');
    const publicKey = CertificateUtilityHelper._extractSection(pemContent, 'PUBLIC KEY');
    const certificate = CertificateUtilityHelper._extractSection(pemContent, 'CERTIFICATE');

    const expirationDate: number | null = CertificateUtilityHelper._getExpirationDateFromPem(certificate)?.getTime();

    return { privateKey, publicKey, certificate, expirationDate };
  }

  private static _getExpirationDateFromPem(pemCertificate: string): Date | null {
    try {
      // Convert PEM certificate to ASN.1 object
      const cert = forge.pki.certificateFromPem(pemCertificate);
      // then, extract expiration date
      return cert.validity.notAfter;
    } catch (error) {
      console.error('Error extracting expiration date:', error);
      return null;
    }
  }

   /**
   * Private method to extract a specific section
   * @param pemContent - Full PEM string content
   * @param section - Section to extract ("PRIVATE KEY", "PUBLIC KEY", "CERTIFICATE")
   * @returns string - Extracted section content or error message
   */
   private static _extractSection(pemContent: string, section: 'PRIVATE KEY' | 'PUBLIC KEY' | 'CERTIFICATE'): string {

    const startMarker = `-----BEGIN ${section}-----`;
    const endMarker = `-----END ${section}-----`;

    // Finding the start and end indices
    const startIndex = pemContent.indexOf(startMarker);
    if (startIndex === -1) {
      console.warn(`${section} start marker not found in PEM content.`);
      return '';
    }

    const endIndex = pemContent.indexOf(endMarker, startIndex);
    if (endIndex === -1) {
      console.warn(`${section} end marker not found in PEM content.`);
      return '';
    }

    return pemContent.slice(startIndex, endIndex + endMarker.length).trim();
  }

  /**
   * Check if we have a valid pem file
   * @param file: File
   */
  public static async isValidPemFile(file: File): Promise<boolean>  {

    try {
      const fileContent = await FileUtilityHelper.readFile(file);
      const pemHeaders = [
        '-----BEGIN CERTIFICATE-----',
        '-----BEGIN RSA PRIVATE KEY-----',
        '-----BEGIN PUBLIC KEY-----'
      ];

    return pemHeaders.some(header => fileContent.includes(header));

    } catch (error) {
      console.error(`Error reading file: ${error}`);
      return false;
    }

  }

}