import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";


const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/org.respond-dashboard.module').then((m) => m.OrgRespondDashboardModule),
  },
  // Not available to clients for now:
  // {
  //   path: 'reports',
  //   loadChildren: () => import('./periodic-reports/org.reports.module').then(m => m.OrgReportsModule),
  // },
  // {
  //   path: ':organizationEcoId/reports',
  //   loadChildren: () => import('./periodic-reports/org.reports.module').then(m => m.OrgReportsModule)
  // },
  {
    path: 'incidents',
    loadChildren: () => import('./incidents/org.incidents.module').then((m) => m.OrgIncidentsModule),
  },
  {
    path: ":organizationEcoId/incidents",
    loadChildren: () => import('./incidents/org.incidents.module').then((m) => m.OrgIncidentsModule),
  },
  {
    path: 'assets',
    loadChildren: () => import('./actifs/org-actifs.module').then((m) => m.OrgActifsModule),
  },
  {
    path: 'alerts',
    loadChildren: () => import('./alerts/org.alerts.module').then((m) => m.OrgAlertsModule),
  },
  {
    path: ':organizationEcoId/assets',
    loadChildren: () => import('./actifs/org-actifs.module').then((m) => m.OrgActifsModule),
  },
  {
    path: 'allowlists',
    loadChildren: () => import('./whitelists/org-whitelists.module').then((m) => m.OrgWhitelistsModule),
  },
  {
    path: ':organizationEcoId/allowlists',
    loadChildren: () => import('./whitelists/org-whitelists.module').then((m) => m.OrgWhitelistsModule),
  },
  {
    path: 'filters',
    loadChildren: () => import('./filters/org-filters.module').then((m) => m.OrgFiltersModule),
  },
  {
    path: ':organizationEcoId/filters',
    loadChildren: () => import('./filters/org-filters.module').then((m) => m.OrgFiltersModule),
  },
  {
    path: 'playbooks',
    loadChildren: () => import('./playbooks/org.playbooks.module').then((m) => m.OrgPlaybooksModule),
  },
  {
    path: ":organizationEcoId/playbooks",
    loadChildren: () => import('./playbooks/org.playbooks.module').then((m) => m.OrgPlaybooksModule),
  },
  {
    path: 'escalation',
    loadChildren: () => import('./communication/org-communication.module').then((m) => m.OrgCommunicationModule),
  },
  {
    path: ':organizationEcoId/escalation',
    loadChildren: () => import('./communication/org-communication.module').then((m) => m.OrgCommunicationModule),
  },
  {
    path: "queries",
    loadChildren: () => import('./queries/org.queries.module').then((m) => m.OrgQueriesModule),
  },
  {
    path: ":organizationEcoId/queries",
    loadChildren: () => import('./queries/org.queries.module').then((m) => m.OrgQueriesModule),
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class OrgRespondRoutingModule { }
