import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UiKitModule } from "@ui-kit/uikit.module";
import { ComponentsModule } from "projects/@common/components/components.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { PipesModule } from "projects/@common/modules/pipes/pipes.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { ConnectorsDailyIngestedDataComponent } from './components/connectors-daily-ingested-data/connectors-daily-ingested-data.component';
import { ConnectorsTotalIngestedDataComponent } from './components/connectors-total-ingested-data/connectors-total-ingested-data.component';
import { GraphConnectorsTotalIngestedDataComponent } from "./components/graphs/graph-connectors-total-ingested-data/graph-connectors-total-ingested-data.component";
import { GraphConnnectorDailyIngestedDataComponent } from "./components/graphs/graph-connnector-daily-ingested-data/graph-connnector-daily-ingested-data.component";
import { IncidentsTimelineComponent } from './components/incidents-timeline/incidents-timeline.component';
import { IncidentsHistoryComponentVar } from './components/incidents-history-var/incidents-history-var.component';
import { IncidentsOngoingCardComponentVar } from './components/incidents-ongoing-card-var/incidents-ongoing-card-var.component';
import { PlaybooksSummaryComponent } from "./components/playbooks-summary/playbooks-summary.component";
import { ResolutionStatusesComponent } from "./components/resolution-statuses/resolution-statuses.component";
import { RespondDashboardContainerComponent } from './containers/respond-dashboard-container/respond-dashboard-container.component';
import { IncidentsTimelineComponentVar } from "./components/incidents-timeline-var/incidents-timeline-var.component";


const components = [
  RespondDashboardContainerComponent,
  PlaybooksSummaryComponent,
  ResolutionStatusesComponent,
  IncidentsTimelineComponent,
  IncidentsTimelineComponentVar,
  IncidentsHistoryComponentVar,
  IncidentsOngoingCardComponentVar,
  ConnectorsDailyIngestedDataComponent,
  ConnectorsTotalIngestedDataComponent,
  GraphConnectorsTotalIngestedDataComponent,
  GraphConnnectorDailyIngestedDataComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    PipesModule,
    RouterModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
  ],
})
export class RespondDashboardModule { }
