import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@common/modules/i18n/i18n.module';
import { FeatureSharedModule } from '@common/modules/shared/feature.shared.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { UiKitModule } from '@ui-kit/uikit.module';
import { RoleDetailsComponent } from './components/role-details/role-details.component';
import { RoleMembersComponent } from './components/role-members/role-members.component';
import { RoleSecurityGroupsComponent } from './components/role-security-groups/role-security-groups.component';
import { RoleDrawerContainer } from './containers/drawer/iam-role-drawer/role-drawer.container';
import { RoleContainer } from './containers/role.container';
import { RolesRoutingModule } from './roles.routing.module';

@NgModule({
  declarations: [
    RoleContainer,
    RoleDrawerContainer,
    RoleDetailsComponent,
    RoleMembersComponent,
    RoleSecurityGroupsComponent,
  ],
  providers: [],
  imports: [
    RolesRoutingModule,
    UiKitModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxsFormPluginModule,
    FeatureSharedModule,
    I18nModule,
  ],
  exports: [],
})
export class RolesModule {
}
