<ui-warning
  *ngIf="warnings.length > 0"
  class="warning"
  [warningMessages]="warnings"
  [color]="'yellow'"
  [centerText]="false"
  [roundedBorder]="true"
  [showBulletPoint]="true"
></ui-warning>

<div class="filter-container">
  <query-builder [data]="conditions" [config]="config" [disabled]="isDisabled" [classNames]="classNames">
      <ng-container *queryButtonGroup="let ruleset; let addRule=addRule; let addRuleSet=addRuleSet; let removeRuleSet=removeRuleSet">
          <button ui-button color="linkFlat" (click)="addRule(); computeWarnings(); conditionsChange.emit(conditions);" *ngIf="!isDisabled && isAddButtonAvailable(ruleset, 'addRule')" [custom-icon]="true" [isSvg]="true" icon="icon_create" icon-size="medium">{{'filters.drawer.tabs.details.conditions.rule.text' | t}}</button>
          <button ui-button color="linkFlat" (click)="addRuleSet(); computeWarnings(); conditionsChange.emit(conditions);" *ngIf="!isDisabled && isAddButtonAvailable(ruleset, 'addRuleSet')" [custom-icon]="true" [isSvg]="true" icon="icon_create" icon-size="medium">{{'filters.drawer.tabs.details.conditions.ruleset.text' | t}}</button>
          <button ui-button color="linkFlat" (click)="removeRuleSet(); computeWarnings(); conditionsChange.emit(conditions);" *ngIf="removeRuleSet && !isDisabled && isAddButtonAvailable(ruleset, 'delete')" [custom-icon]="true" [isSvg]="true" icon="icon_delete" icon-size="medium"></button>
      </ng-container>

      <ng-container *querySwitchGroup="let ruleset">
        <div class="top-left-block">
          <mat-button-toggle-group class="radio-group" [disabled]="isDisabled">
            <mat-button-toggle class="radio-button" [checked]="ruleset.condition === 'and'" (change)="ruleset.condition = 'and'; computeWarnings()">{{ 'common.operators.and' | t }}</mat-button-toggle>
            <mat-button-toggle class="radio-button" [checked]="ruleset.condition === 'or'" (change)="ruleset.condition = 'or'; computeWarnings()">{{ 'common.operators.or' | t }}</mat-button-toggle>
            <mat-button-toggle class="radio-button" [checked]="ruleset.condition === 'not'" (change)="ruleset.condition = 'not'; computeWarnings()">{{ 'common.operators.negation' | t }}</mat-button-toggle>
          </mat-button-toggle-group>
          <div class="auto-complete-block">
            <autocomplete
              [disabled]="isDisabled"
              [ngModel]="ruleset.nestedQuery"
              (ngModelChange)="ruleset.nestedQuery= $event"
              [required]="false"
              [placeholder]="'filters.jmespath.nested.query' | t"
              [autocompleteType]="AUTOCOMPLETE_TYPE"
              [customValues]="filterArrayChoices"
              [showInInputAtInit]="true"
              [canMultiSelect]="false"
              [oneItemAtATime]="true"
              [initItems]="getSelectedSubQueryField(ruleset)"
              [isInError]="isInvalidSubQueryInput(ruleset)"
              [tooltip]="isInvalidSubQueryInput(ruleset) ? ('filters.jmespath.rule.invalid' | t): ''"
              (onItemSelected)="handleSelectedRuleSet($event?.value, ruleset)"
              (onTextValueChanged)="handleSelectedRuleSet($event, ruleset)"
            ></autocomplete>
          </div>
        </div>
      </ng-container>

      <ng-container *queryField="let rule; let fields=fields; let onChange=onChange;">
        <div class="fieldContainer" *ngIf="!isDisabled">
          <autocomplete
            [required]="true"
            [placeholder]="'common.search' | t"
            [autocompleteType]="AUTOCOMPLETE_TYPE"
            [customValues]="getOptions(fields, rule)"
            [initItems]="getSelectedField(rule, fields)"
            [showInInputAtInit]="true"
            [canMultiSelect]="false"
            [oneItemAtATime]="true"
            [isInError]="isInvalidRule(rule)"
            (onItemSelected)="setFieldValue($event?.value, rule, onChange)"
            (onTextValueChanged)="setFieldValue($event, rule, onChange)"
          ></autocomplete>
          <p *ngIf="isInvalidRule(rule)" class="warning-input">{{'filters.jmespath.rule.invalid' | t}}</p>
        </div>
        <div *ngIf="isDisabled" style="padding-right: 5px;">
          <label ui-label *ngIf="isDisabled">{{getFieldDisplayValue(fields, rule)}}</label>
        </div>
      </ng-container>

      <ng-container *queryOperator="let rule; let operators=operators">
        <div class="operatorContainer" *ngIf="!isDisabled">
          <ui-select
            [ngModel]="rule.operator"
            (ngModelChange)="rule.operator = $event; computeWarnings()"
            [required]="true"
            [options]="getOperatorOptions(operators)"
          ></ui-select>
        </div>
        <div *ngIf="isDisabled" style="padding-right: 5px;">
          <label ui-label *ngIf="isDisabled">{{rule.operator}}</label>
        </div>
      </ng-container>

      <ng-container *queryInput="let rule; let field=field; let options=options; type: 'category'">
        <div class="valueContainer" *ngIf="!isDisabled">
          <ui-select
            [(ngModel)]="rule.value"
            [required]="true"
            [options]="getOptions(options)"
          ></ui-select>
        </div>
        <div *ngIf="isDisabled" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{getOptionDisplayValue(options, rule.value)}}</label>
        </div>
      </ng-container>
      
      <ng-container *queryInput="let rule; let field=field; let options=options; type: 'boolean'">
        <div class="valueContainer" *ngIf="!isDisabled && rule?.operator !== 'exists'">
          <ui-select
            [ngModel]="rule?.value?.toString()"
            (ngModelChange)="onBooleanChange(rule, $event)"
            [required]="true"
            [options]="getBooleanOptions()"
          ></ui-select>
        </div>
        <div *ngIf="isDisabled" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{getBooleanDisplayValue(rule.value)}}</label>
        </div>
      </ng-container>
      
      <ng-container *queryInput="let rule; let field=field; type: 'country'">
        <div class="valueContainer" *ngIf="!isDisabled" style="padding-top: 8px">
          <autocomplete
            [required]="true"
            [placeholder]="'common.search' | t"
            [autocompleteType]="AUTOCOMPLETE_TYPE"
            [customValues]="countries"
            [initItems]="getSelectedCountry(rule)"
            [showInInputAtInit]="true"
            [canMultiSelect]="false"
            [oneItemAtATime]="true"
            (onItemSelected)="rule.value = $event?.value"
          ></autocomplete>
        </div>
        <div *ngIf="isDisabled" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{getCountryDisplayValue(rule.value)}}</label>
        </div>
      </ng-container>

      <ng-container *queryInput="let rule; let field=field; type: 'string'">
        <div class="valueContainer" *ngIf="!isDisabled && rule.operator !== 'exists'" style="padding-top: 8px">
          <input
            ui-input
            (valueChange)="rule.value=$event"
            [value]="rule.value"
          />
        </div>
        <div *ngIf="isDisabled && rule.operator !== 'exists'" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{rule.value}}</label>
        </div>
        <div *ngIf="!isDisabled && rule.operator === 'exists'" class="valueContainer">          
        </div>
        <div *ngIf="isDisabled && rule.operator === 'exists'" class="static-label">          
        </div>
      </ng-container>
            
      <ng-container *queryInput="let rule; let field=field; type: 'number'">
        <div class="valueContainer" *ngIf="!isDisabled" style="padding-top: 8px">
          <input
            ui-input
            uiNumberOnlyInput
            type="number"
            (valueChange)="setNumber(rule, $event)"
            [value]="rule.value"
          />
        </div>
        <div *ngIf="isDisabled" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{rule.value}}</label>
        </div>
      </ng-container>

      <ng-container *queryInput="let rule; let field=field; let options=options; type: 'enum'">
        <div class="valueContainer" *ngIf="!isDisabled && rule?.operator !== 'exists'">
          <ui-select
            [ngModel]="rule?.value?.toString()"
            (ngModelChange)="onEnumOptionChange(rule, $event)"
            [required]="true"
            [options]="getEnumOptions(rule)"
          ></ui-select>
        </div>
        <div *ngIf="isDisabled" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{rule.value}}</label>
        </div>
      </ng-container>

      <ng-container *queryInput="let rule; type: 'date'">
        <div class="valueContainer date-picker-value" *ngIf="!isDisabled && rule?.operator !== 'exists'">
          <ui-date-picker
            [defaultTimestamp]="rule?.value"
            [locale]="i18n.currentLocale"
            (onDateSelection)="onDateValueUpdate(rule, $event)"
          ></ui-date-picker>
        </div>
        <div *ngIf="isDisabled" class="static-label">
          <label  *ngIf="isDisabled">{{rule.value | date: 'yyyy-MM-dd'}}</label>
        </div>
      </ng-container>

      <ng-container *queryRemoveButton="let rule; let removeRule=removeRule">
        <div style="padding-top: 8px">
          <button ui-button color="linkFlat" (click)="removeRule(rule); computeWarnings(); conditionsChange.emit(conditions);" *ngIf="!isDisabled" [custom-icon]="true" [isSvg]="true" icon="icon_delete" icon-size="medium"></button>
        </div>
      </ng-container>

      <ng-container *queryEmptyWarning="let emptyMessage">
        <div>
          {{'filters.ruleset.empty.error' | t}}
        </div>
      </ng-container>

  </query-builder>
</div>
