import { Component, Input } from '@angular/core';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { OrganizationSettings, OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';

@Component({
  selector: 'resources-settings',
  templateUrl: './resources-settings.component.html',
  styleUrls: [ './resources-settings.component.scss' ],
})
export class ResourcesSettingsComponent {
  @Input() public currentSettings: OrganizationSettings & OrganizationShareSettings;
  @Input() public isLoading = false;

  public featureFlagEnum = FeaturesEnum;

  constructor() { }
}
