import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { ModalService } from '@ui-kit/services/modal.service';
import { TimeUtil } from '@ui-kit/services/time-util';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { CommunicationApi } from 'projects/@common/services/api/respond/communication/communication.api';
import { EscalationTimeChoicesEnum, EscalationTimeChoicesEnumLabelsForNotification, IEscalationParameters } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { IComputedEscalation, IDescribeIncidentResponse } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { HandleBarUtils } from 'projects/@common/utils/handlebars-utils';
import { Buffer } from 'buffer';


declare namespace Intl {
  function supportedValuesOf(input: string): string[];
  function DateTimeFormat(locale: string, options?: any): any;
}

@Component({
  selector: 'incident-notification-modal',
  templateUrl: './incident-notification-modal.component.html',
  styleUrls: [ './incident-notification-modal.component.scss' ],
})
export class IncidentNotificationModalComponent implements OnInit {
  @Output()
  public successEvent = new EventEmitter<IDescribeIncidentResponse>();

  public formGroup = new UntypedFormGroup({ message: new UntypedFormControl(null) });

  public isLoading = true;

  public escalationParameters: IEscalationParameters;

  public computedEscalationlist: IComputedEscalation;

  public beforeText: { [key: string]: string } = {
    "en": "before",
    "fr": "d'ici le",
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { incident: Incident, escalation: boolean },
    private readonly modalService: ModalService,
    private readonly incidentApi: IncidentsApi,
    private readonly noticeService: NoticeService,
    private readonly dtPipe: DTPipe,
    private readonly commnunicationApi: CommunicationApi,
    private readonly injector: Injector,
    
  ) {
    HandleBarUtils.registerHelpers(injector);
  }

  ngOnInit(): void {
    this.getEscalationListParameters();
    this.computeEscalationList();
  }

  public get messageValue(): string {
    return this.formGroup.get('message')?.value;
  }

  public handleConfirm(): void {
    const base64Message = Buffer.from(this.messageValue).toString('base64');

    this.incidentApi.startConversation(this.data.incident.organizationId, this.data.incident.id, base64Message, this.data.escalation).subscribe(
      (response) => {
        this.successEvent.emit(response);
        this.noticeService.notifyUser(new Notice("incidents.container.page.details.modal.sendNotification.successFeedback", NoticeLevels.SUCCESS));
        this.modalService.closeDialog();
      },
      (httpErrorResponse) => {
        this.noticeService.notifyUser(new Notice("incidents.container.page.details.modal.sendNotification.failFeedback", NoticeLevels.ERROR, null, httpErrorResponse?.error?.message));
      }
    );
  }

  public handleCancel(): void {
    this.modalService.closeDialog();
  }

  private getEscalationListParameters(): void {
    this.commnunicationApi.listEscalationParameters({ organizationId: this.data.incident.organizationId })
      .subscribe(
        (response) => {
          this.escalationParameters = response;
        },
        (error) => {

        }
      );
  }

  private computeEscalationList(): void {
    this.incidentApi.describeDraftIncidentEscalationSchedule(this.data.incident.organizationId, this.data.incident.id, this.data.escalation).subscribe((response) => {
      this.computedEscalationlist = response;
      this.setCannedMessage();
      this.isLoading = false;
    },
    (error) => {
      this.modalService.closeDialog();
      this.noticeService.notifyUser(new Notice('incidents.container.page.details.modal.error', NoticeLevels.ERROR));
    });
  }

  private setCannedMessage(): void {
    const cannedMessage = this.computedEscalationlist.cannedMessage;
    const incidentMapper = {
      ...this.data.incident,
      formattedDateCreatedAtFr: this.formatDateWithTimezone(this.data.incident.createdAt, LanguageEnum.FRENCH),
      formattedDateCreatedAtEn: this.formatDateWithTimezone(this.data.incident.createdAt, LanguageEnum.ENGLISH),
      formattedDateFirstEscalationFr: this.data.escalation ? this.setFirstEscalationMessageFragment(LanguageEnum.FRENCH) : "",
      formattedDateFirstEscalationEn: this.data.escalation ? this.setFirstEscalationMessageFragment(LanguageEnum.ENGLISH) : "",
    };

    const messageFormat = HandleBarUtils.mustacheReplace(cannedMessage, { incident: incidentMapper });

    this.formGroup.get('message').setValue(messageFormat);
  }

  private getTimezoneAbbreviation(date: Date, timeZone) {
    return date.toLocaleTimeString('en-us', {timeZone, timeZoneName:'short'}).split(" ").pop() || '';
  }

  private formatDateWithTimezone(timestamp: any, locale: string): string {
    const timeZone = this.escalationParameters.businessHours.timezone;
    const date = new Date(timestamp);
    const dateFormatOptions = {
      timeZone,
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const timezonedDate = Intl.DateTimeFormat(locale, dateFormatOptions).format(date).replace(" h ", "h");
    // in French it outputs "14 h 23" so the replace formats it to "14h23"
    return `${timezonedDate} ${this.getTimezoneAbbreviation(date, timeZone)}`;
  }

  private setFirstEscalationMessageFragment(locale: string): string {
    const isBusinessHours = !this.checkIfDateIsInOffHours(Date.now(), this.escalationParameters.businessHours);
    const delayForPriority = (this.escalationParameters.escalationDelays.useOffHours && !isBusinessHours) ? this.escalationParameters.escalationDelays.delays[this.data.incident.priority - 1].offHours : this.escalationParameters.escalationDelays.delays[this.data.incident.priority - 1].businessHours;

    if (delayForPriority.nextBusinessDay) {
      return `${this.beforeText[locale]} ${this.formatDateWithTimezone(this.computedEscalationlist.levels[1].notifyAt, locale)}`;
    }
    return EscalationTimeChoicesEnumLabelsForNotification[delayForPriority.delay]?.[locale];
  }

  private checkIfDateIsInOffHours(date: number, businessHours: any): boolean {
    const timezonedDate = new Date(date);
    timezonedDate.setTime(timezonedDate.getTime() - TimeUtil.getOffset(businessHours.timezone, timezonedDate));

    let currentlyInOffHours = [ 0, 6 ].includes(timezonedDate.getDay());
    if (!currentlyInOffHours) {
      const openingMinutes = businessHours.fromHour * 60 + businessHours.fromMinute;
      const closingMinutes = businessHours.toHour * 60 + businessHours.toMinute;
      const currentMinutes = timezonedDate.getHours() * 60 + timezonedDate.getMinutes();
      currentlyInOffHours = currentMinutes < openingMinutes || closingMinutes < currentMinutes;
    }
    return currentlyInOffHours;
  }
}
