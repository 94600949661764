import { IPlaybookTaskTemplateRepresentation, PlaybookTaskPhases, PlaybookTaskRelation } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";
import { TaskOriginEnum } from "../../../components/playbook-tasks-drag-and-drop.component/playbook-tasks-drag-and-drop.component";
import { Playbook } from "./Playbook";
import { IDragParams, IPhaseDropRegion, IPhaseDropRegionTask, IPlaybookVersionDragDrop, PhaseDropRegionKeyEnum, PlaybookVersionKeyEnum } from "./playbook-update.definitions";

interface IConstructorProps {
  key: PlaybookVersionKeyEnum;
  playbook: Playbook;
  dragParams: IDragParams;
  mdTaskTemplates: IPlaybookTaskTemplateRepresentation[];
}

export class PlaybookVersionDragDrop implements IPlaybookVersionDragDrop {
  public readonly key: PlaybookVersionKeyEnum;
  public readonly version: string;
  public readonly updatedAt: number;
  public readonly title: string;
  public readonly tooltip: string;
  public readonly dragParams: IDragParams;
  public readonly initialPlaybookTasks: PlaybookTaskRelation[];
  public readonly mdTaskTemplates: IPlaybookTaskTemplateRepresentation[];
  public phaseDropRegions: IPhaseDropRegion[];

  constructor(props: IConstructorProps) {
    this.key = props.key;
    this.version = props.playbook.getVersionString();
    this.updatedAt = props.playbook.updatedAt;
    this.title = `detection.playbook.update.playbookVersion.${this.key}.title`;
    this.tooltip = `detection.playbook.update.playbookVersion.${this.key}.tooltip`;
    this.dragParams = props.dragParams;
    this.initialPlaybookTasks = props.playbook.playbookTasks;
    this.mdTaskTemplates = props.mdTaskTemplates;
    this.phaseDropRegions = this.getPhaseDropRegions();
  }

  public togglePhase(phaseDropRegion: IPhaseDropRegion): void {
    const phase = this.findPhaseByName(phaseDropRegion.name);
    phase.isExpanded = Boolean(!phase.isExpanded);
  }

  public toggleTask(task: IPhaseDropRegionTask): void {
    const phase = this.findPhaseByName(task.phase);
    const taskInPhase = this.findTaskInPhase(task, phase);
    if (taskInPhase) {
      taskInPhase.isExpanded = !taskInPhase.isExpanded;
      if (taskInPhase.isExpanded && !phase.isExpanded) {
        this.togglePhase(phase);
      }
    }
  }

  public findPhaseByName(phaseName: PlaybookTaskPhases): IPhaseDropRegion {
    return this.phaseDropRegions.find((phaseDropRegion) => phaseDropRegion.name === phaseName);
  }

  public getTasks(): IPhaseDropRegionTask[] {
    return this.phaseDropRegions.flatMap((phase) => phase.tasks);
  }

  public getTasksInSpecificPhase(phaseName: PlaybookTaskPhases): IPhaseDropRegionTask[] {
    return this.findPhaseByName(phaseName).tasks;
  }

  private findTaskInPhase(task: IPhaseDropRegionTask, phase: IPhaseDropRegion) {
    return phase.tasks.find((t) => t.templateId === task.templateId || t.id === task.id);
  }

  private getTaskOrigin(task: IPhaseDropRegionTask): TaskOriginEnum {
    return Boolean(this.mdTaskTemplates.find((mdTask) => mdTask.id === task.id)) ? TaskOriginEnum.Library : TaskOriginEnum.Custom;
  }

  private mapInitialTasksToPhase(phaseName: PlaybookTaskPhases): IPhaseDropRegionTask[] {
    return this.initialPlaybookTasks
      .filter((task) => task.phase === phaseName)
      .sort((taskA, taskB) => taskA.order - taskB.order)
      .map((task, index) => ({
        ...task,
        order: index, // ensure the order is always set relative to it's phase and not the entire playbook tasks.
        isExpanded: false,
        isDragEnabled: true,
        origin: this.getTaskOrigin(task),
      }));
  }

  private getPhaseDropRegions(): IPhaseDropRegion[] {
    const allPhaseNames = Object.values(PlaybookTaskPhases);
    return allPhaseNames.map((phaseName) => ({
      key: `${this.key}_${phaseName}` as PhaseDropRegionKeyEnum,
      name: phaseName,
      title: `detection.playbook.detail.task.library.phase.${phaseName}.filter`,
      isExpanded: false,
      tasks: this.mapInitialTasksToPhase(phaseName),
    }));
  }
}
