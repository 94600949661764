export const ORG_APP_PAGES = {
  roles: {
    pageTitleTranslationKey: 'iam.roles.list.title',
  },
  'app-registration-upgrade': {
    pageTitleTranslationKey: 'iam.app-registration.upgrade',
  },
  mdTools: {
    secretsStore: {
      pageTitleTranslationKey: 'secrets.title',
    },
  },
  respondDashboard: {
    pageTitleTranslationKey: 'respond.dashboard.title',
  },
  reports: {
    pageTitleTranslationKey: 'respond.reports.title',
  },
  incidents: {
    pageTitleTranslationKey: 'incidents.container.page.title',
  },
  playbooks: {
    pageTitleTranslationKey: 'sidebar.navigation.respond.playbooks',
  },
  communication: {
    pageTitleTranslationKey: 'respond.communication.title',
  },
  dashboard: {
    pageTitleTranslationKey: 'sidebar.navigation.dashboard',
  },
  resourcesShares: {
    pageTitleTranslationKey: 'shares.resources',
  },
  userShares: {
    pageTitleTranslationKey: 'shares.users',
  },
  guestUsers: {
    pageTitleTranslationKey: 'guest.users.title',
  },
  guestUsersManagement: {
    pageTitleTranslationKey: 'guest.usersManagement.title',
  },
  securityModels: {
    pageTitleTranslationKey: 'securityModels.title',
  },
  securityModel: {
    pageTitleTranslationKey: 'securityModel.title',
  },
  guestManagers: {
    pageTitleTranslationKey: 'guestManagers.title',
  },
  settings: {
    pageTitleTranslationKey: 'settings.title',
  },
  actifs: {
    pageTitleTranslationKey: 'actifs.page.title',
  },
  whitelists: {
    pageTitleTranslationKey: 'whitelists.page.title',
  },
  filters: {
    pageTitleTranslationKey: 'filters.page.title',
  },
  usecases: {
    pageTitleTranslationKey: 'org_usecase.title',
  },
  datasources: {
    pageTitleTranslationKey: 'org_datasource.title',
  },
  connectors: {
    pageTitleTranslationKey: 'org_connector.title',
  },
  queries: {
    pageTitleTranslationKey: 'respond.queries.title',
  },
};
