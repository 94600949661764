<div class="top-row">
  <button
    ui-button
    color="listActionFlat"
    icon="plus"
    icon-size="medium"
    [disabled]="!canAddNewList()"
    [suffix]="null"
    (click)="addNewList()"
  >
    {{ 'respond.communication.tab.escalationList.createButton.label' | t }}
  </button>

  <div class="filters">
    <ui-table-total-tool
      [locale]="i18n.currentLocale"
      [total]="isLoading ? '-' : displayedEscalationLists?.length || 0"
    ></ui-table-total-tool>

    <ui-table-search-tool
      class="search-text"
      [placeholder]="'respond.communication.tab.contacts.search.placeholder' | t"
      [query]="filters.searchText"
      (query)="handleSearchTextChange($event)"
      (clear)="handleSearchTextChange(null)"
    ></ui-table-search-tool>

    <ui-table-refresh-tool
      class="refresher"
      rightSide
      [loading]="isLoading"
      (click)="refreshSearch()"
    ></ui-table-refresh-tool>
  </div>
</div>

<div class="escalation-lists">
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="!displayedEscalationLists.length">
      <p>{{ 'respond.communication.tab.escalationList.noList' | t }}</p>
    </ng-container>
    <ng-container *ngIf="displayedEscalationLists.length">
      <communication-escalation-list
        *ngFor="let escalationList of displayedEscalationLists; trackBy:trackById"
        [escalationList]="escalationList"
        [escalationLists]="escalationLists"
        [contacts]="contacts"
        [connectors]="connectors"
        [playbooks]="playbooks"
        [hasEditPermission]="hasEditPermission"
        [hasDeletePermission]="hasDeletePermission"
        (saveEvent)="saveList($event)"
        (deleteEvent)="deleteList($event)"
      ></communication-escalation-list>
    </ng-container>
  </ng-container>
</div>


<ng-template #loadingSpinnerTemplate>
  <ui-spinner
    class="spinner-container"
    size="medium"
    modifier="secondary"
  ></ui-spinner>
</ng-template>