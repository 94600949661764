import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

export enum UiInputType {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  Number = 'number'
}

@Component({
  selector: 'input[ui-input]',
  styleUrls: [ './ui-input.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: '',
  host: {
    class: 'ui-input',
    '[attr.id]': 'name',
    '[attr.value]': 'value || null',
    '[attr.placeholder]': 'placeholder || null',
    '[attr.aria-required]': 'required || null',
    '[attr.aria-disabled]': 'disabled || null',
  },
})
export class UiInput implements OnInit {
  @Output('valueChange') public valueChangeEmitter: EventEmitter<string> = new EventEmitter<string>();

  @HostBinding('name') @Input('name') public name: string;

  @HostBinding('type') @Input('type') public type: UiInputType = UiInputType.Text;

  @HostBinding('disabled') @Input('disabled') public disabled = false;

  @HostBinding('required') @Input('required') public required = false;

  @HostBinding('readonly') @Input('readonly') public readonly = false;

  @HostBinding('autocomplete') @Input('autocomplete') public autocomplete = "off";

  @Input('value') public value: number | string;

  @Input('placeholder') public placeholder: string;

  @HostListener('input', [ '$event.target.value' ]) 
  onInput(value) {
    this.valueChangeEmitter.emit(value);
  }

  ngOnInit(): void {
    this.forceAutocompleteOff();
  }

  private forceAutocompleteOff() {
    // Edge Browser ignores autocomplete="off" and instead uses "null"
    // Chrome and Firefox ignore autocomplete="null" and instead use "off"
    // Another problem with Edge is that when the input name="null" the autocomplete="null" will work again.
    // So this hacky fix only works when input has a name attribute which is why we assign one by default.

    // as of 2024-02-26, Ms Edge seems to respect autocomplete="off" and not "null"
    // const isEdgeBrowser = window?.navigator?.userAgent?.indexOf("Edg") > -1;
    // if (isEdgeBrowser && !this.name) {
    //   this.name = randomBytes(4).toString('hex');
    // }
    // this.autocomplete = isEdgeBrowser ? 'null' : 'off';
    this.autocomplete = 'off';
  }
}
