import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'incident-phase-menu',
  templateUrl: './incident-phase-menu.component.html',
  styleUrls: [ './incident-phase-menu.component.scss' ],
})
export class IncidentPhaseMenuComponent {
  public showOptionsMenu: boolean = false;

  @Input() public hideSetPhaseInapplicable = false;
  @Input() public hideAddCustomTask = false;

  @Output() public setPhaseInapplicableEvent = new EventEmitter<MouseEvent>();
  @Output() public addCustomTaskEvent = new EventEmitter<MouseEvent>();

  constructor() {
  }

  public toggleMenu(mouseEvent: MouseEvent): void {
    this.showOptionsMenu = !this.showOptionsMenu;
    mouseEvent?.stopPropagation();
    mouseEvent?.preventDefault();
  }

  public emitSetPhaseInapplicableEvent(mouseEvent: MouseEvent): void {
    this.setPhaseInapplicableEvent.emit(mouseEvent);
  }

  public emitAddCustomTaskEvent(mouseEvent: MouseEvent): void {
    this.addCustomTaskEvent.emit(mouseEvent);
  }

}
