import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import { Autocomplete, AutocompleteTypes } from "@ui-kit/components/autocomplete/autocomplete.component";
import { UiMultiSelect } from "@ui-kit/components/ui-multi-select/ui-multi-select.component";
import { UiTableDateToolComponent } from "@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component";
import { UiTableSearchTool } from "@ui-kit/components/ui-table-tools/ui-table-search-tool/ui-table-search-tool.component";
import { UiTableShortidSearchComponent } from "@ui-kit/components/ui-table-tools/ui-table-shortid-search/ui-table-shortid-search.component";
import { DateIntervalComponent } from "projects/@common/components/date-interval/date-interval.component";
import { Eco } from "projects/@common/definitions/eco";
import { ManagedOrganization } from "projects/@common/services/api/detect/organizations/connectors-api-organizations";
import { ListOwnersResponse } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { ConnectorsMultiselectComponent } from "projects/@respond/components/connectors-multiselect/connectors-multiselect.component";
import { IncidentFiltersService, IncidentsFilterName } from "./incident-filters.service";


@Component({
  selector: 'respond-incident-table-filters',
  templateUrl: './respond-incident-table-filters.component.html',
  styleUrls: [ './respond-incident-table-filters.component.scss' ],
})
export class RespondIncidentTableFiltersComponent implements OnInit {
  @ViewChildren(UiMultiSelect) selectors!: QueryList<UiMultiSelect>;
  @ViewChildren(UiTableSearchTool) searchSelector!: QueryList<UiTableSearchTool>;
  @ViewChild(UiTableDateToolComponent) datePicker!: UiTableDateToolComponent;
  @ViewChild(DateIntervalComponent) dateIntervalComponent!: DateIntervalComponent;
  @ViewChild(Autocomplete) autocomplete!: Autocomplete;
  @ViewChild(UiTableShortidSearchComponent) shortId!: UiTableShortidSearchComponent;
  @ViewChild(ConnectorsMultiselectComponent) connectorsMultiselect!: ConnectorsMultiselectComponent;

  @Input()
    incidentResponse: ResponseUtils<Incident>;

  @Input()
    organizations: ManagedOrganization[];

  @Input()
    owners: ListOwnersResponse[];

  @Input()
    isLoading: boolean;

  @Input()
    visibleFilters: IncidentsFilterName[];

  @Input()
  public locale: Eco.Languages;

  @Output()
  public filteringChangeEvent = new EventEmitter();

  @Output()
  public refreshEvent = new EventEmitter();

  public readonly autocompleteTypes = AutocompleteTypes;

  public readonly searchFiltersEnum = IncidentsFilterName;

  constructor(public readonly incidentFiltersService: IncidentFiltersService) { }

  ngOnInit(): void {
    this.incidentFiltersService.initializeIncidentsFilters(this.visibleFilters);
    this.incidentFiltersService.filteringChange$.subscribe(() => {
      this.dateIntervalComponent.updateFormattedTimestamp(this.incidentFiltersService.dateFilter);
      this.filteringChangeEvent.emit();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.owners?.currentValue) {
      this.incidentFiltersService.initOwnersMultiSelect(this.owners);
    }
    if (changes.organizations?.currentValue) {
      this.incidentFiltersService.initOrganizationsMultiSelect(this.organizations);
    }
  }

  public shouldShowFilter(filterName: IncidentsFilterName): boolean {
    return this.visibleFilters.includes(filterName);
  }

  public handleManualRefreshClick(): void {
    this.dateIntervalComponent.updateFormattedTimestamp(this.incidentFiltersService.dateFilter);
    this.refreshEvent.emit();
  }

  public clearAllFilters(): void {
    this.incidentFiltersService.clearAllFilters();
    this.selectors.forEach((selector) => selector.clearSelection());
    this.connectorsMultiselect?.clearConnectors();
    this.datePicker?.clearAll();
    this.autocomplete?.clearSearchQuery();
    this.searchSelector?.forEach((selector) => selector.handleClear());
  }
}
