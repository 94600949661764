<escalation-expand-card
  [title]="contact.name"
  [useEditableTitle]="true"
  [description]="null"
  [titleIconPath]="'assets/favicons/communication/icon_communication_tab_contacts.svg'"
  [preventCollapse]="isEditing"
  [isExpanded]="isExpanded"
  [startInEditingMode]="!contact.createdAt"
  [isConfirmButtonEnabled]="isEditing && isValid()"
  [confirmButtonDisabledTooltip]="'respond.communication.tab.contacts.confirmationButtons.invalid.tooltip' | t"
  [useDeleteButton]="hasDeletePermission && canDeleteContact"
  [useMenuButton]="true"
  [templateReadonly]="readonlyTemplate"
  [templateEditing]="editingTemplate"
  [showActionButtons]="hasEditPermission"
  (toggleExpandEvent)="toggleExpand($event)"
  (toggleEditEvent)="toggleEdit($event)"
  (titleChangeEvent)="handleContactNameChange($event)"
  (saveEvent)="handleSaveContact()"
  (cancelEvent)="handleEditCanceled()"
  (deleteEvent)="handleDeleteContact()"
></escalation-expand-card>

<ng-template #readonlyTemplate>
  <div class="card-content-container">
    <div
      class="job-title"
      *ngIf="contact.position"
    >
      <label>{{ 'respond.communication.tab.contacts.position.label' | t}}</label>
      <p>{{contact.position}}</p>
    </div>

    <div class="communication-methods">
      <div class="column-labels">
        <label class="left-column">{{'respond.communication.tab.contacts.communicationMethods.label' | t}}</label>
        <label class="right-column">Informations</label>
      </div>
      <div class="methods-list">
        <communication-method
          *ngFor="let method of contact.communicationMethods"
          [communicationMethod]="method"
          [isEditing]="false"
        ></communication-method>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editingTemplate>
  <div class="card-content-container">
    <div class="job-title">
      <label for="position">{{ 'respond.communication.tab.contacts.position.label' | t}}</label>
      <input
        ui-input
        id=position
        name="position"
        type="text"
        [(ngModel)]="contact.position"
      >
    </div>

    <div class="communication-methods">
      <div class="column-labels">
        <label class="left-column">{{'respond.communication.tab.contacts.communicationMethods.label' | t}}</label>
        <label class="right-column">Informations</label>
      </div>
      <div class="methods-list">
        <communication-method
          *ngFor="let method of contact.communicationMethods"
          [communicationMethod]="method"
          [alreadyUsedMethodTypes]="getContactCommunicationMethodTypes()"
          [isEditing]="true"
          [showDeleteButton]="contact.communicationMethods.length > 1"
          (deleteEvent)="removeCommucationMethod($event)"
        ></communication-method>
      </div>
      <button
        ui-button
        style="padding-left: 6px;"
        color="listActionFlat"
        icon="plus"
        icon-size="medium"
        [disabled]="!isValidCommunicationMethods() || contact.communicationMethods.length >= getMaxCommunicationMethods()"
        (click)="addCommucationMethod()"
      >
        {{'respond.communication.tab.contacts.addMethodButton.label' | t}}
      </button>
    </div>
  </div>
</ng-template>