<div class="container">
  <div class="container--actions-bar">
    <ui-table-total-tool
      [total]="ucInventoryAction.total"
      [locale]="i18nService.currentLocale"
    ></ui-table-total-tool>

    <ui-table-search-tool
      [placeholder]="'detection.usecase.inventory.table.search.placeholder'|t"
      [query]="ucInventoryAction.requestsQuery"
      (clear)="ucInventoryAction.handleSearchClear()"
      (query)="ucInventoryAction.handleSearchQuery($event)"
    ></ui-table-search-tool>

    <ui-table-filter-tool  
      [tableFilterData]="tableFilterDatasourceType" 
      (select)="ucInventoryAction.handleDatasourceTypeFilter($event)">
    </ui-table-filter-tool>

    <ui-multi-select
      [multiSelectData]="priorityFilter"
      (onOptionSelect)="ucInventoryAction.handlePriorities($event)"
    ></ui-multi-select>

    <ui-table-filter-tool
      [tableFilterData]="tableFilterData"
      (select)="ucInventoryAction.handleModesFilter($event)"
    ></ui-table-filter-tool>

    <ui-table-refresh-tool
      [loading]="false"
      (click)="ucInventoryAction.handleSearchClear()"
    ></ui-table-refresh-tool>
  </div>

  <ui-card [withShadow]="true">
    <detection-usecases-table
      [loading]="loading"
      [datasources]="datasources"
    ></detection-usecases-table>
  </ui-card>
</div>
