<div class="incident-name">
  <ui-label-input-with-modify
    #uiLabelEdit
    [value]="incident?.name"
    [hasLabel]="false"
    [maxlength]="1000"
    [locale]="i18nService.currentLocale"
    [disabled]="!isNameEditable()"
    [inputStyle]="{'font-size': '24px', 'font-weight': '700'}"
    (isEdittingEvent)="handleEditingEvent($event)"
    (onValueChange)="saveIncidentName($event)"
  >
  </ui-label-input-with-modify>

  <alert-incident-indicators
    [incident]="incident"
    [assetsCount]="assetAcount"
    [fixedGridDisplay]="false"
  ></alert-incident-indicators>
</div>

<div
  class="incident-warnings-container"
  *ngIf="showReopenedWarning || showUnsavedWarning"
>
  <ui-warning
    *ngIf="showReopenedWarning"
    class="is-reopened-warning"
    [warningMessages]="[{messageKey:'incidents.container.page.details.header.reopenedWarning' | t}]"
    [color]="'yellow'"
    [centerText]="false"
    [roundedBorder]="true"
  ></ui-warning>

  <ui-warning
    *ngIf="showUnsavedWarning"
    class="has-unsaved-changes-warning"
    [warningMessages]="[{messageKey:'incidents.container.page.details.header.unsavedChanges' | t}]"
    [color]="'yellow'"
    [centerText]="false"
    [roundedBorder]="true"
    [showIcon]="true"
  ></ui-warning>
</div>