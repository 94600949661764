import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { UiKitModule } from "@ui-kit/uikit.module";
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { SharedModule } from '../../shared/shared.module';
import { ApproversTableComponent } from './components/approvers-table/approvers-table.component';
import { GroupTemplateComponent } from './components/group-template/group-template.component';
import { GroupTemplatesTableComponent } from './components/group-templates-table/group-templates-table.component';
import { OrganizationArchiverComponent } from './components/organization-archiver/organization-archiver.component';
import { GroupTemplateContainer } from './containers/group-template/group-template.container';
import { GroupTemplatesContainer } from './containers/group-templates/group-templates.container';
import { GroupTemplatesRoutingModule } from './group-templates.routing.module';

@NgModule({
  declarations: [
    GroupTemplatesContainer,
    GroupTemplateContainer,
    GroupTemplateComponent,
    GroupTemplatesTableComponent,
    ApproversTableComponent,
    OrganizationArchiverComponent,
  ],
  imports: [
    CommonModule,
    AssistOpsModule,
    UiKitModule,
    GroupTemplatesRoutingModule,
    I18nModule,
    SharedModule,
    FeatureSharedModule,
  ],
})
export class GroupTemplatesModule {}
