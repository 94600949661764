import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Eco } from 'projects/@common/definitions/eco';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';
import { ListWhitelistsOrderBy, WhitelistsViewModeEnum } from 'projects/@common/services/api/respond/whitelists/whitelists.definitions';
import { clone } from 'projects/@common/utils/utils';
import { WhitelistsService } from '../../whitelists.service';
import { WhitelistsTableColumnEnum } from './whitelists-table.columns-config';

interface ITableGroup {
  whitelists: Whitelist[];
  name: string;
  columnsDef: any[];
  isExpanded: boolean;
  icon?: string;
}

@Component({
  selector: 'whitelists-table',
  templateUrl: './whitelists-table.component.html',
  styleUrls: [ './whitelists-table.component.scss' ],
})
export class WhitelistsTableComponent implements OnInit {
  @Input() public defaultColumnsDef: any[] = [];
  @Input() public whitelistsData: Whitelist[] = [];
  @Input() public canCreate: boolean = false;
  @Input() public canDescribe: boolean = false;
  @Input() public canDelete: boolean = false;
  @Input() public isReadonly: boolean = false;
  @Input() public disabledGrouping: boolean = false;

  @Output() public rowClickEvent = new EventEmitter<Whitelist>();
  @Output() public createEvent = new EventEmitter<void>();
  @Output() public deleteEvent = new EventEmitter<void>();

  public tableGroups: ITableGroup[] = [];

  constructor(
    private readonly whitelistsService: WhitelistsService,
    private readonly i18n: I18nService,
    private readonly translatedObject: TranslatedObjectPipe,
    private readonly staticTableDataFactory: StaticTableDataMapper,
    private readonly drawerService: DrawerService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.whitelistsData) {
      this.mapWhitelistsDataToTableGroups(this.whitelistsData);
    }
  }

  public get viewMode(): WhitelistsViewModeEnum {
    return this.whitelistsService.listViewMode;
  }

  public get isSimpleList(): boolean {
    return this.viewMode === WhitelistsViewModeEnum.SIMPLE_LIST;
  }

  public get locale(): Eco.Languages {
    return this.i18n.currentLocale as Eco.Languages;
  }

  public get isLoading(): boolean {
    return this.whitelistsService.isLoading;
  }

  public get direction(): string {
    return "asc";
  }

  public get sort(): ListWhitelistsOrderBy {
    return ListWhitelistsOrderBy.name;
  }

  public get selectedCount(): number {
    return this.whitelistsService.selectedRows?.length || null;
  }

  public get shouldHideCreateButton(): boolean {
    return this.isReadonly || !this.canCreate;
  }

  public get shouldHideDeleteButton(): boolean {
    return this.isReadonly || !this.canDelete || !this.whitelistsService.selectedRows?.length;
  }

  public onClickRow(row: Whitelist): void {
    this.rowClickEvent.emit(row);
  }

  public onClickCreate(): void {
    this.createEvent.emit();
  }

  public onClickDelete(): void {
    this.deleteEvent.emit();
  }

  public handleCheckboxEvent(isChecked: boolean, rowItem: any): void {
    if (isChecked) {
      this.whitelistsService.selectedRows.push(rowItem);
    } else {
      this.whitelistsService.selectedRows = this.whitelistsService.selectedRows.filter((item) => item.id !== rowItem.id);
    }
    this.drawerService.hideDrawer();
  }

  private mapWhitelistsDataToTableGroups(whitelistsData: Whitelist[]): void {
    if (this.viewMode === WhitelistsViewModeEnum.SIMPLE_LIST) {
      this.tableGroups = this.getTableGroupsAsSimpleList(whitelistsData);
    } else if (this.viewMode === WhitelistsViewModeEnum.GROUPED_BY_STATUS) {
      this.tableGroups = this.getTableGroupsByStatus(whitelistsData);
    }
    // else if (this.viewMode === WhitelistsViewModeEnum.GROUPED_BY_USECASE) {
    //   this.tableGroups = this.getTableGroupsByUsecase(whitelistsData);
    // }
  }

  private getTableGroupsAsSimpleList(whitelistsData: Whitelist[]): ITableGroup[] {
    const [ columns, translatedTableData ] = this.staticTableDataFactory.translate(this.defaultColumnsDef, clone(whitelistsData));
    return [ {
      columnsDef: columns,
      whitelists: translatedTableData,
      name: this.i18n.translate('common.all'),
      isExpanded: true,
    } ];
  }

  private getTableGroupsByStatus(whitelistsData: Whitelist[]): ITableGroup[] {
    const [ columns, translatedTableData ] = this.staticTableDataFactory.translate(
      this.defaultColumnsDef.filter((col) => col.field !== WhitelistsTableColumnEnum.status),
      clone(whitelistsData)
    );

    const statuses = Whitelist.getStatuses();

    const tableGroups = statuses.map((status) => ({
      columnsDef: columns,
      whitelists: translatedTableData.filter((whitelist) => whitelist.status.value === status.value),
      name: this.i18n.translate(status.translationKey),
      isExpanded: false,
      icon: status.icon,
    }));
    return tableGroups;
  }
}
