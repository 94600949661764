<div>
  <ng-container toolbar>
    <div class="inline-tools">
      <div class="inline-tools-left">
        <app-usecase-catalog-report-button
          [isWithinTableColumn]="false"
          [usecaseCategories]="filteredCategories"
        ></app-usecase-catalog-report-button>
      </div>
      <div class="inline-tools-right">
        <ui-table-total-tool
          [locale]="i18n.currentLocale"
          [total]="total"
        ></ui-table-total-tool>
        <ui-table-filter-tool
          id="catalogUsecaseDisplayFilter"
          [tableFilterData]="usecaseDisplayTableFilter"
          [titlesDifferentThanOptions]="[usecaseDisplayFilterEnum.ALL]"
          (select)="handleDisplayFilter($event)"
        ></ui-table-filter-tool>
        <ui-multi-select
          *ngIf="displayFilter === usecaseDisplayFilterEnum.PER_CONNECTOR"
          id="catalogUsecaseCategoryFilter"
          [multiSelectData]="usecaseCategoryFilterMultiSelectData"
          [titlesDifferentThanOptions]="[usecaseDatasourceFilterEnum.ALL]"
          (onOptionSelect)="handleDataSourceFilter($event)"
        ></ui-multi-select>
        <ui-table-filter-tool
          id="catalogUsecaseActiveFilter"
          [tableFilterData]="usecaseActiveTableFilter"
          [titlesDifferentThanOptions]="[usecaseActiveFilterEnum.ALL]"
          (select)="handleDataFilter($event)"
        ></ui-table-filter-tool>
        <ui-table-refresh-tool
          [loading]="isLoading"
          (click)="handleRefresh()"
        ></ui-table-refresh-tool>
      </div>
    </div>
  </ng-container>
  
  <div>
    <ui-card>
        <ng-container *ngIf="isLoading || total > 0; else emptyUsecases">
          <div
            *ngFor="let category of selectedCategoryKeys; let first = first"
            [ngClass]="{ 'space-between-tables': !first }"
          >
            <div *ngIf="getCategoryUsecases(category) as usecases">
              <ng-container *ngIf="usecases.length > 0">
                <ui-collapsable-table
                  [tableTitle]="categories.get(category).name | translatedObjectLocale :i18n.currentLocale"
                  [numberItems]="usecases.length"
                  [columnsDef]="columnsDef"
                  [dataSource]="usecases"
                  [isCollapsed]="selectedCategoryKeys.length !== 1"
                  (onRowClick)="handleRowClick($event)"
                  [canLoadMore]="false"
                  [isLoadingMore]="usecaseCatalogAction.hasMoreToLoad"
                  [sort]="sort"
                  [direction]="direction"
                  [addPaddingOnFirst]="false"
                  [locale]="i18n.currentLocale"
                ></ui-collapsable-table>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-template #emptyUsecases>
          <div class="infinite empty-row">
            <span>{{ 'ui.table-infinite.empty' | t }}</span>
          </div>
        </ng-template>

        <ui-spinner *ngIf="isLoading" size="medium"></ui-spinner>
    </ui-card>
  </div>
</div>
