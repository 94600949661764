<div class="section-layout">
  <div class="section">
    <app-summary-section
      [isEditable]="isEditable"
      [organizationId]="organizationId"
      [incident]="incident"
      [usecases]="usecases"
    ></app-summary-section>

    <escalade-list-section
      *ngIf="incident.escalationLists"
      [computedEscalationList]="computedEscalationList"
      [incident]="incident">
    </escalade-list-section>

    <affected-resources-section
      [isEditable]="isEditable"
      [isAdminConsole]="isAdminConsole"
      [organizationId]="organizationId"
      [incident]="incident"
      [usecases]="usecases"
    ></affected-resources-section>

    <actifs-section *ngIf="actifsService?.total > 0"></actifs-section>

    <whitelist-section *ngIf="whitelistsService?.total > 0"></whitelist-section>

    <app-playbook-section
      [organizationId]="organizationId"
      [incident]="incident"
    ></app-playbook-section>

    <app-usecase-section
      [organizationId]="organizationId"
      [incident]="incident"
      [usecases]="usecases"
    ></app-usecase-section>

    <app-additional-info-section
      *ngIf="isEditable || (!isEditable &&  incident?.additionalInfo?.length > 0)"
      [organizationId]="organizationId"
      [incident]="incident"
    ></app-additional-info-section>
  </div>

  <div class="section right">
    <app-metadata-section
      [incident]="incident"
      [isEditable]="isEditable"
      [organizationEcoId]="organizationId"
      [isAdminConsole]="isAdminConsole"
    ></app-metadata-section>
  </div>
</div>