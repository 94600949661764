import { Component } from '@angular/core';

@Component({
  selector: 'tooltip-confidentiality',
  templateUrl: './tooltip-confidentiality.component.html',
  styleUrls: [ './tooltip-confidentiality.component.scss' ],
})
export class TooltipConfidentialityComponent {

}
