<similarity-filters
  [isLoading]="isLoading"
  [totalDisplayed]="totalDisplayed"
  [pageContext]="pageContext"
  [defaultFilters]="defaultFilters"
  (filterEvent)="handleFilterEvent($event)"
  (manualRefreshEvent)="refreshSimilarItemsList()"
></similarity-filters>

<similarity-table
  [isLoading]="isLoading"
  [similarityTableData]="similarityTableData"
  [pageContext]="pageContext"
></similarity-table>