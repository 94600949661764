<ui-toolbar-filter
  [activatedFiltersNumber]="historiesFiltersService.nbFilterApplied"
  (onClearAllFilters)="clearAllFilters()"
  [locale]="locale"
  [showEraseAll]="historiesFiltersService.nbFilterApplied > 0"
  [total]="total || 0"
  toolbar
>
  <app-date-interval
    leftSide
    [period]="historiesFiltersService.dateFilter"
  ></app-date-interval>

  <ui-table-refresh-tool
    class="refresher"
    rightSide
    [loading]="isLoading"
    (click)="handleManualRefreshClick()"
  ></ui-table-refresh-tool>

  <div
    toolbar-collapsable-element
    [elementTitle]="'histories.container.page.filter.date.label' | t"
  >
    <ui-table-date-tool
      [attr.data-test-id]="'period_filter'"
      (resetDateEmitter)="historiesFiltersService.clearDateFilter()"
      (timeEmitter)="historiesFiltersService.applyDateFilter($event)"
      [defaultCustomTimePeriod]="historiesFiltersService.dateFilter"
      [excludePresets]="['last_180_days', 'last_365_days']"
      [locale]="locale"
    >
    </ui-table-date-tool>    
  </div>

  <div
    toolbar-collapsable-element
    [elementTitle]="'histories.container.page.filter.organization.label' | t"
  >
    <ui-multi-select
      [attr.data-test-id]="'organization_filter'"
      [multiSelectData]="historiesFiltersService.organizationValues"
      (onOptionSelect)="historiesFiltersService.applyOrganizationFilter($event)"
    >
    </ui-multi-select>
  </div>

  <div
    toolbar-collapsable-element
    [elementTitle]="'histories.container.page.filter.owner.label' | t"
  >
    <ui-multi-select
      [attr.data-test-id]="'owner_filter'"
      [multiSelectData]="historiesFiltersService.ownerValues"
      (onOptionSelect)="historiesFiltersService.applyOwnerFilter($event)"
    >
    </ui-multi-select>
  </div>

  <div
    toolbar-collapsable-element
    [elementTitle]="'histories.container.page.filter.type.label' | t"
  >
    <ui-multi-select
      [attr.data-test-id]="'type_filter'"
      [multiSelectData]="historiesFiltersService.typeValues"
      (onOptionSelect)="historiesFiltersService.applyTypeFilter($event)"
    >
    </ui-multi-select>
  </div>

  <div
    toolbar-collapsable-element
    [elementTitle]="'common.indicators' | t"
  >
    <ui-multi-select
      [attr.data-test-id]="'indicator_filter'"
      [multiSelectData]="historiesFiltersService.indicatorsValues"
      (onOptionSelect)="historiesFiltersService.applyIndicatorsFilter($event)"
    ></ui-multi-select>
  </div>

</ui-toolbar-filter>