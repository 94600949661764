<div class="row">
  <div class="resource">
    <shares-resource-avatar [size]="'small'" [resource]="resource" *ngIf="!mobileService.windowWidthSmallerThan(600)"></shares-resource-avatar>
    <p [title]="resource.name">{{resource.name}}</p>
  </div>
  <div class="owners">
    <div>
      <ui-avatars id="'avatars'" [users]="resource.owners" [size]="'tiny'" [usersToShowNumber]="3"></ui-avatars>
    </div>
    <img *ngIf="!reminderClicked && resource?.showReminderAction" (click)="notifyUser(resource.id)" [tooltip]="'dashboard.admin.top10HighRIsk.reminder.tooltip.team' | t" class="notification-button" src="assets/favicons/speaker.svg">

    <ng-container *ngIf="reminderClicked && showReminder">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
        <circle [class.path]="true" [class.circle]="true" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
        <polyline [class.path]="true" [class.circle]="true" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
      </svg>
    </ng-container>

  </div>
</div>
