import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { UiKitModule } from "@ui-kit/uikit.module";
import { AssistOpsModule } from "projects/@assist-ops/assist-ops.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { PipesModule } from 'projects/@common/modules/pipes/pipes.module';
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { SharedModule } from "projects/console-adm/src/app/shared/shared.module";
import { LabelImpactPickerComponent } from './components/card-contents/o365Labels/label-impact-picker/label-impact-picker.component';
import { LabelsInformationsTableComponent } from './components/card-contents/o365Labels/labels-informations-tables/labels-informations-table.component';
import { LabelsProtectionInformationComponent } from './components/card-contents/o365Labels/labels-protection-information/labels-protection-information.component';
import { O365LabelsComponent } from './components/card-contents/o365Labels/o365Labels.component';
import { DlpActivationComponent } from './components/dlp/card-contents/dlp-activation/dlp-activation.component';
import { DlpFrequencyComponent } from './components/dlp/card-contents/dlp-frequency/dlp-frequency.component';
import { DlpJustificationDelayComponent } from './components/dlp/card-contents/dlp-justification-delay/dlp-justification-delay.component';
import { DlpSettingsComponent } from './components/dlp/dlp-settings/dlp-settings.component';
import { DownloadsFrequencyComponent } from './components/downloads/card-contents/downloads-frequency/downloads-frequency.component';
import { DownloadsSettingsComponent } from './components/downloads/downloads-settings/downloads-settings.component';
import { ApplicationSegmentationComponent } from './components/general/card-contents/application-segmentation/application-segmentation.component';
import { GeneralSettingsComponent } from './components/general/general-settings/general-settings.component';
import { EditCardContentComponent } from './components/generics/edit-card-content/edit-card-content.component';
import { SettingsEditionCardComponent } from './components/generics/settings-edition-card/settings-edition-card.component';
import { ExpirationNotificationFrequencyComponent } from './components/ressources/card-contents/expiration-notification-frequency/expiration-notification-frequency.component';
import { GuestUserResourceAddComponent } from './components/ressources/card-contents/guest-user-resource-add/guest-user-resource-add.component';
import { RevisionFrequencyComponent } from './components/ressources/card-contents/revision-frequency/revision-frequency.component';
import { RevisionNotificationFrequencyComponent } from './components/ressources/card-contents/revision-notification-frequency/revision-notification-frequency.component';
import { ResourcesSettingsComponent } from './components/ressources/resources-settings/resources-settings.component';
import { NotificationFrequencyComponent } from './components/shares/card-contents/notification-frequency/notification-frequency.component';
import { SharesDurationToleranceComponent } from './components/shares/card-contents/shares-duration-tolerance/shares-duration-tolerance.component';
import { SharesSilencingDurationComponent } from './components/shares/card-contents/shares-silencing-duration/shares-silencing-duration.component';
import { SharesSettingsComponent } from './components/shares/shares-settings/shares-settings.component';
import { SettingsContainer } from './containers/settings.container';
import { SettingsRoutingModule } from "./settings.routing.module";
import { DeviceComplianceNotificationComponent } from './components/general/card-contents/device-compliance-notification/device-compliance-notification.component';
import { GuestSettingsComponent } from './components/general/card-contents/guest-settings/guest-settings.component';
import { GuestExpirationComponent } from './components/general/card-contents/guest-settings/guest-expiration/guest-expiration.component';
import { GuestGracePeriodComponent } from './components/general/card-contents/guest-settings/guest-grace-period/guest-grace-period.component';

@NgModule({
  declarations: [
    SharesSettingsComponent,
    ResourcesSettingsComponent,
    SettingsContainer,
    EditCardContentComponent,
    NotificationFrequencyComponent,
    RevisionFrequencyComponent,
    SettingsEditionCardComponent,
    SharesDurationToleranceComponent,
    SharesSilencingDurationComponent,
    O365LabelsComponent,
    LabelsInformationsTableComponent,
    LabelImpactPickerComponent,
    DownloadsSettingsComponent,
    DownloadsFrequencyComponent,
    ApplicationSegmentationComponent,
    GeneralSettingsComponent,
    ExpirationNotificationFrequencyComponent,
    RevisionNotificationFrequencyComponent,
    LabelsProtectionInformationComponent,
    GuestUserResourceAddComponent,
    DeviceComplianceNotificationComponent,
    GuestSettingsComponent,
    GuestExpirationComponent,
    GuestGracePeriodComponent,

    DlpSettingsComponent,
    DlpFrequencyComponent,
    DlpJustificationDelayComponent,
    DlpActivationComponent

  ],
  imports: [
    CommonModule,
    AssistOpsModule,
    UiKitModule,
    SettingsRoutingModule,
    MatRadioModule,
    I18nModule,
    SharedModule,
    FeatureSharedModule,
    ReactiveFormsModule,
    PipesModule,
  ],
})
export class SettingsModule {
}
