import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { Notice, NoticeLevels, NoticeService } from "projects/@common/modules/notice/notice.service";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";

import { ActifsService } from "projects/@respond/actifs/actifs.service";
import { Subscription } from "rxjs/internal/Subscription";
import { IncidentDifferenceService } from "../../../../services/incident.difference.service";
import { TabNamesEnum } from "../../incidents-details.component";

@Component({
  selector: 'app-incident-tab-header',
  templateUrl: './incident-tab-header.component.html',
  styleUrls: [ './incident-tab-header.component.scss' ],
})
export class IncidentTabHeaderComponent implements OnInit, OnDestroy {
  @Input()
  public incident: Incident;

  @Input()
  public organizationId: string;

  @Input()
  public isEditable: boolean;

  @Input()
  public showHeaderOrgInfo: boolean;

  public hasUnsavedChanges = false;

  private incidentDifferenceSubscription: Subscription;

  constructor(
    public readonly i18nService: I18nService,
    public readonly incidentDifferenceService: IncidentDifferenceService,
    private readonly actifsService: ActifsService,
    private readonly incidentApi: IncidentsApi,
    private readonly notice: NoticeService
  ) {
  }

  ngOnInit(): void {
    this.incidentDifferenceSubscription = this.incidentDifferenceService.stateUpdateObservable.subscribe(() => {
      this.hasUnsavedChanges = this.incidentDifferenceService.hasUnsavedChanges;
    });
  }

  ngOnDestroy(): void {
    this.incidentDifferenceSubscription?.unsubscribe();
  }

  public get showUnsavedWarning(): boolean {
    return this.incidentDifferenceService.shouldShowUnsavedWarnings && this.hasUnsavedChanges;
  }

  public get assetAcount(): number {
    return this.actifsService.total;
  }

  public isNameEditable(): boolean {
    return !this.incident?.usecaseIds?.length && this.isEditable && this.incident.isEditable;
  }

  public get showReopenedWarning(): boolean {
    return this.incident.isReopened;
  }

  public handleEditingEvent(isEditing: boolean): void {
    this.incidentDifferenceService.handleEditingStates({
      tabNameEnum: TabNamesEnum.DETAILS,
      itemId: "incidentName",
      hasUnsavedChange: isEditing,
    });
  }

  public saveIncidentName(incidentName: string): void {
    this.incident.name = incidentName;
    this.incidentApi.updateIncident(this.organizationId, this.incident.updateRequestDto).subscribe(
      (response) => {
        this.incident.reloadIncident(response.incident);
        this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.success'), NoticeLevels.SUCCESS));
      },
      (error) => this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.error'), NoticeLevels.ERROR))
    );
  }
}
