import { Component, Inject, Input, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { DisplayService } from "projects/@common/modules/display/display.service";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { NoticeService } from "projects/@common/modules/notice/notice.service";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { ProfilesApi } from "projects/@common/services/api/iam/profiles/profiles.api";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { IAddIncidentAnalystRequest, IDeleteIncidentAnalystRequest } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IAutocompleteUser } from "./analyst-adder/analyst-adder.component";

@Component({
  selector: 'app-metadata-section',
  templateUrl: './metadata-section.component.html',
  styleUrls: [ './metadata-section.component.scss' ],
})
export class MetadataSectionComponent implements OnInit {
  @Input() incident: Incident;

  @Input() isEditable: boolean;

  @Input() showAnalysts: boolean = true;

  @Input() isAdminConsole: boolean = false;

  @Input() organizationEcoId: string;

  public assignedAnalysts: IAutocompleteUser[];
  public availableAnalysts: IAutocompleteUser[];
  public isDeletingAnalyst: boolean;
  public isVarMode: boolean;

  // See comment below
  private socAnalystsProfileId = "01GA7045RXX45YZNCNDKQDM4K7";

  constructor(
    public i18nService: I18nService,
    private readonly profilesApi: ProfilesApi,
    private readonly incidentsApi: IncidentsApi,
    private readonly noticeService: NoticeService,
    private readonly displayService: DisplayService,
    private readonly store: Store,
    @Inject('CONSOLE_TYPE') public consoleType: ConsoleType
  ) { }

  ngOnInit(): void {
    // Only showing analysts if this is the root org.
    // Var organizations cannot create their own incident yet so the analyst(s) linked to the incident will always be from the root org.
    // In order allow the var organization to edit the analyst(s) linked to the incident. They will need to be the owner of the incident.
    // This only works as a work around for now. Must be changed once a var will managed his alerts.
    this.showAnalysts = this.consoleType === ConsoleType.ADM;
    if (this.showAnalysts) {
      this.fetchSocAnalysts();
    }
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  public get isAllowedToEdit(): boolean {
    return this.isEditable && this.incident.isEditable && this.displayService.meetsRequirements({ permissions: [ "can_update_incident" ] });
  }

  public handleAddAnalyst(analyst: IAutocompleteUser): void {
    if (!this.isAllowedToEdit) {
      return;
    }
    const request: IAddIncidentAnalystRequest = {
      incidentId: this.incident.id,
      userId: analyst.id,
    };
    this.incidentsApi.addIncidentAnalyst(this.incident.organizationId, request).subscribe(
      () => {
        this.assignedAnalysts.push(analyst);
        this.availableAnalysts = this.availableAnalysts.filter((a) => a.id !== analyst.id);
        this.noticeService.success("incidents.container.page.details.tab.detail.metadata.analyst.add.success");
      },
      () => this.noticeService.error("incidents.container.page.details.tab.detail.metadata.analyst.add.error")
    );
  }

  public handleDeleteAnalyst(analyst: IAutocompleteUser): void {
    if (this.isDeletingAnalyst || !this.isAllowedToEdit) {
      return;
    }
    this.isDeletingAnalyst = true;
    const request: IDeleteIncidentAnalystRequest = {
      incidentId: this.incident.id,
      userId: analyst.id,
    };
    this.incidentsApi.deleteIncidentAnalyst(this.incident.organizationId, request).subscribe(
      () => {
        this.assignedAnalysts = this.assignedAnalysts.filter((a) => a.id !== analyst.id);
        this.availableAnalysts.push(analyst);
        this.noticeService.success("incidents.container.page.details.tab.detail.metadata.analyst.delete.success");
      },
      () => this.noticeService.error("incidents.container.page.details.tab.detail.metadata.analyst.delete.error"),
      () => this.isDeletingAnalyst = false
    );
  }

  private isOwner(analyst: IAutocompleteUser): boolean {
    return this.incident.ownerId === analyst.id;
  }

  private isAnalystAssigned(analyst: IAutocompleteUser): boolean {
    return this.incident?.analysts?.some((a) => a.userId === analyst.id);
  }

  private async fetchSocAnalysts(): Promise<void> {
    const profilesResponse = await this.profilesApi.listProfileMembers({
      organizationId: this.organizationEcoId,
      profileId: this.socAnalystsProfileId,
      params: {
        idpRequired: false
      }
    });
    const analysts = profilesResponse.items.map((user) => ({
      id: user.id,
      name: user.name,
      idpIdentifier: user.idpIdentifier,
      o365UserId: user.id,
      isPlatformUser: true,
    }));
    this.assignedAnalysts = analysts.filter((analyst) => this.isAnalystAssigned(analyst));
    this.availableAnalysts = analysts.filter((analyst) => !this.isOwner(analyst) && !this.isAnalystAssigned(analyst));
  }
}
