import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-template',
  templateUrl: './group-template.container.html',
  styleUrls: [ './group-template.container.scss' ],
})
export class GroupTemplateContainer implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
