import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IPeriodicReportRepresentation, PeriodicReportsColumnEnum } from 'projects/@common/services/api/respond/periodic-reports/periodic-reports.definitions';
import { RespondReportsPeriodComponent } from '../respond-reports-period/respond-reports-period.component';
import { RespondReportsPrintButtonComponent } from '../respond-reports-print-button/respond-reports-print-button.component';
import { RespondReportsPeriodCodeComponent } from '../respond-reports-period-code/respond-reports-period-code.component';


export interface ISortData {
  orderBy?: PeriodicReportsColumnEnum;
  direction?: UiTableDirection;
}

@Component({
  selector: 'respond-reports-table',
  templateUrl: './respond-reports-table.component.html',
  styleUrls: [ './respond-reports-table.component.scss' ],
})
export class RespondReportsTableComponent implements OnInit, OnChanges {
  @Input() public reports: IPeriodicReportRepresentation[];
  @Input() public isLoading: boolean;
  @Input() public defaultSorting: ISortData;

  @Output() public sortEvent = new EventEmitter<ISortData>();
  @Output() public rowClickEvent = new EventEmitter<any>();

  public selectedRows: any;
  public columnsDef = [
    // {
    //   label: this.i18n.translate('respond.reports.column.label.name'),
    //   field: PeriodicReportsColumnEnum.NAME,
    //   type: UiStaticTableRowType.TEXT,
    //   width: '40%',
    //   isResizable: true,
    //   noSorting: false,
    // },

    {
      label: this.i18n.translate('respond.reports.column.label.type'),
      field: PeriodicReportsColumnEnum.TYPE,
      type: UiStaticTableRowType.ENUM,
      enumPrefix: 'respond.reports.type.enum.',
      // width: '30%',
      isResizable: true,
      noSorting: true,
    },
    {
      label: this.i18n.translate('respond.reports.column.label.period'),
      field: PeriodicReportsColumnEnum.PERIOD_CODE,
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: RespondReportsPeriodCodeComponent,
      paramName: 'report',
      width: '150px',
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('respond.reports.column.label.coverage'),
      field: PeriodicReportsColumnEnum.PERIOD,
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: RespondReportsPeriodComponent,
      paramName: 'report',
      // width: '30%',
      isResizable: true,
      noSorting: true,
    },
    {
      label: this.i18n.translate('respond.reports.column.label.created'),
      field: PeriodicReportsColumnEnum.CREATED,
      type: UiStaticTableRowType.DATE_AND_HOUR,
      // width: '30%',
      isResizable: true,
      noSorting: true,
    },
    {
      label: '',
      field: 'buttons',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: RespondReportsPrintButtonComponent,
      paramName: 'report',
      minWidth: '60px',
      width: '60px',
      isResizable: false,
      noSorting: true,
      showWhenHover: false,
    },
  ];

  private _sortData: ISortData;

  constructor(
    private readonly i18n: I18nService,
    private readonly staticTableDataMapper: StaticTableDataMapper
  ) { }

  ngOnInit(): void {
    this._sortData = {
      orderBy: this.defaultSorting.orderBy,
      direction: this.defaultSorting.direction,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reports) {
      this.translateTableData();
    }
  }

  public get sortData(): ISortData {
    return this._sortData;
  }

  public translateTableData(): void {
    [ this.columnsDef, this.reports ] = this.staticTableDataMapper.translate(this.columnsDef, this.reports);
  }

  public emitSortEvent(): void {
    this.sortEvent.emit(this._sortData);
  }

  public handleSort(sortData: any): void {
    this._sortData = {
      orderBy: sortData.sortColumn,
      direction: sortData.sortDirection,
    };
    this.emitSortEvent();
  }

  public onClickRow(row: any): void {
    this.rowClickEvent.emit(row);
  }

  public handleCheckboxEvent(isChecked: boolean, rowItem: any): void {
    if (isChecked) {
      this.selectedRows.push(rowItem);
    } else {
      this.selectedRows = this.selectedRows.filter((item) => item.id !== rowItem.id);
    }
  }
}
