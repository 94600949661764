import { UsecasesApiService } from "projects/@common/services/api/respond/usecase/usecase.api";
import { BehaviorSubject } from "rxjs";

import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { UiTableDirection } from "@ui-kit/components/ui-table/ui-table.component";
import { AlertingUsecaseListItem, IListAlertingUsecasesRequest, UsecaseOrderByEnum } from "@common/services/api/respond/usecase/usecase.definition";
import { ITranslatedField } from "@common/definitions/ITranslatedField";

export interface UsecasesInventoryQueryParams {
  organizationId?: string;
}

export enum UsecaseTemplateStatusFilter {
  OUTDATED = 'OUTDATED',
  VALID = 'VALID'
}

export enum UsecaseDisplayTemplateStatusFilter {
  ALL = 'ALL',
  OUTDATED = 'OUTDATED',
  VALID = 'VALID'
}

export interface IUsecaseTableObject {
  id: string;
  organizationId: string;
  name: ITranslatedField;
  templateVersion: number;
  outdated: boolean;
  createdAt: number;
  updatedAt: number;
}

export class UsecasesInventoryContainerActions {
  public $usecases: BehaviorSubject<IUsecaseTableObject[]> = new BehaviorSubject([]);
  private usecases: AlertingUsecaseListItem[] = [];

  public readonly displayUsecaseFilter: BehaviorSubject<UsecaseDisplayTemplateStatusFilter> =
    new BehaviorSubject(UsecaseDisplayTemplateStatusFilter.ALL);

  public isLoading = false;

  public total = 0;

  public outdatedTotal: number = 0;

  public selectedOrganizationId: string = null;

  public requestsQuery: string = null;

  public currentSelectedUsecase: AlertingUsecaseListItem;

  public sortOrder: UsecaseOrderByEnum = UsecaseOrderByEnum.name;

  public sortDirection: UiTableDirection = UiTableDirection.Asc;

  constructor(
    private readonly usecasesApiService: UsecasesApiService,
    private readonly i18n: I18nService
  ) { }

  public async handleSelectedOrganization(organizationId: string): Promise<void> {
    this.selectedOrganizationId = organizationId;
    await this.handleRefresh();
  }

  public async handleUsecaseSearchQuery(value: string): Promise<void> {
    this.requestsQuery = value;
    await this.handleRefresh();
  }

  public async handleUsecaseSearchClear(): Promise<void> {
    this.requestsQuery = null;
    await this.handleRefresh();
  }

  public get queryParams(): IListAlertingUsecasesRequest {
    const params = {
      organizationId: this.selectedOrganizationId,
      searchName: this.requestsQuery,
      orderBy: this.sortOrder,
      direction: this.sortDirection,
      locale: this.i18n.currentLocale,
    };
    return Object.fromEntries(Object.entries(params).filter(([ _, filter ]) => (!Array.isArray(filter) && filter != null) || (Array.isArray(filter) && filter?.length > 0)));
  }

  public async fetchUsecases(): Promise<IUsecaseTableObject[]> {
    this.$usecases.next([]);
    this.isLoading = true;

    const usecases = await this.usecasesApiService.getUsecaseList(this.selectedOrganizationId, this.queryParams);
    this.usecases = usecases.items;
    const mappedUsecases = usecases?.items?.map((value: any) => this.mapUsecaseToTable(value));

    this.total = mappedUsecases.length;
    this.outdatedTotal = mappedUsecases.filter((pb) => pb.outdated).length;
    this.$usecases.next(mappedUsecases);
    this.isLoading = false;

    return mappedUsecases;
  }

  public async handleRefresh(): Promise<void> {
    await this.fetchUsecases();
  }

  public resetUsecases(): void {
    this.$usecases.next([]);
    this.displayUsecaseFilter.next(UsecaseDisplayTemplateStatusFilter.ALL);
  }

  public setDisplayFilter(filter: UsecaseDisplayTemplateStatusFilter) {
    this.displayUsecaseFilter.next(filter);
  }

  public hasUsecaseTemplate(usecaseTemplateId: string): boolean {
    return this.usecases.some((usecase) => usecase.templateId === usecaseTemplateId);
  }

  public findUsecasesTableFromTemplate(templateId: string): IUsecaseTableObject[] {
    const usecases = this.usecases.filter((usecase) => usecase.templateId === templateId);
    return usecases.map((usecase) => this.mapUsecaseToTable(usecase));
  }

  protected mapUsecaseToTable(entity: AlertingUsecaseListItem): IUsecaseTableObject {
    return {
      id: entity.id,
      organizationId: this.selectedOrganizationId,
      name: entity.name,
      templateVersion: entity.version,
      createdAt: entity.createdAt,
      updatedAt: entity.updatedAt,
      outdated: entity.isOutdated,
    };
  }
}
