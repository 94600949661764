<div class="top-layer" *ngIf="showOptionsMenu" (click)="toggleMenu($event);"></div>
<button
  *ngIf="!hideAddCustomTask || !hideSetPhaseInapplicable"
  #menuButton
  class="options-menu-button"
  ui-button
  icon="ico_dots"
  [isSvg]="true"
  [custom-icon]="true"
  (click)="toggleMenu($event)"
></button>

<div
  #popupMenu
  class="options-menu-popup"
  [class.menu-hidden]="!showOptionsMenu"
  (click)="toggleMenu($event)"
>
  <div class="options-menu-popup-option" (click)="emitSetPhaseInapplicableEvent($event)" *ngIf="!hideSetPhaseInapplicable">
    <img src="assets/favicons/icon_non_applicable.svg" alt="Not applicable"/>
    <span>{{ 'incidents.component.tab.phase.status.setAsNotApplicable' | t }}</span>
  </div>

  <div class="options-menu-popup-option" (click)="emitAddCustomTaskEvent($event)" *ngIf="!hideAddCustomTask">
    <img src="assets/favicons/icon_template_added.svg" alt="Not applicable"/>
    <span>{{ 'incidents.component.tab.phase.status.addCustomTask' | t }}</span>
  </div>

</div>