import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";

import { IIncidentActionRepresentation, IIncidentSystemTimestamps, IIncidentTimestamp, IncidentStatus, ResolveIncidentAction } from "../incidents/incidents.definitions";
import { PlaybookTaskPhases } from "../playbooks/playbooks.definitions";
import { IObservable } from "../observables/observables.definition";
import { IEntityRepresentation } from "../entities/entities.definition";

export type IncidentDescription = {
  incident: IIncidentRepresentation;
  organization: OrganizationPublicInfo;
  tasks: IDescribeIncidentTask[];
  firstAlertTimestamp: number;
  timestamps?: IIncidentTimestamp[];
  resolveIncidentActions?: ResolveIncidentAction[];
  phaseTimestamps: IIncidentSystemTimestamps;
  reportAvailable?: boolean;
};

export interface IIncidentRepresentation {
  shortId?: string;
  name: string;
  status: IncidentStatus;
  priority: number;
  severity: number;
  organizationId: string;
  usecaseId: string;
  playbookId: string;
  summary: string;
  additionalInfo?: string;
  playbookParams: any;
  zohoTicket?: string;
  ownerId: string;
  ownerName: string;
  createdById: string;
  createdByName: string;
  containedAt?: number;
  remediatedAt?: number;
  clientNotifiedAt?: number;
  closedAt?: number;
  reportAvailable?: boolean;
}

export interface OrganizationPublicInfo {
  organizationId: string;
  organizationName: string;
  organizationEcoId: string;
}

export type IDescribeIncidentTask = {
  task: IIncidentTaskRepresentation;
  availableStatuses: IncidentTaskStatus[];
  canUpdateStatus: boolean;
  canUpdateComment: boolean;
  durationInSeconds: number;
  actions: string[];
  entities: string[];
  observables: string[];
  findings: IincidentTaskMessage[];
};

export enum IncidentTaskStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  CLOSED = 'CLOSED'
}

export interface IInProgressPeriod {
  from: number;
  to: number;
  durationInSeconds: number;
}

export enum IncidentCustomTaskType {
  USER = "USER",
  BREACH_OF_CONFIDENTIALITY = "BREACH_OF_CONFIDENTIALITY",
  PIPELINE = "PIPELINE",
  IDENTIFICATION_FINDINGS = "IDENTIFICATION_FINDINGS",
}

export enum CustomTaskAction {
  CLOSE_INCIDENT = "CLOSE_INCIDENT",
  CUSTOM_NEXT = "CUSTOM_NEXT",
  START_CONVERSATION_WITH_ESCALATION = "START_CONVERSATION_WITH_ESCALATION",
  START_CONVERSATION_WITHOUT_ESCALATION = "START_CONVERSATION_WITHOUT_ESCALATION",
}

export interface IIncidentTaskRepresentation {
  id?: string;
  incidentId?: string;
  taskId: string;
  taskVersion: number;
  hidden: boolean;
  order: number;
  phase: PlaybookTaskPhases;
  status: IncidentTaskStatus;
  previousStatus: IncidentTaskStatus;
  comment: string;
  closedAt?: number;
  inProgressPeriods?: IInProgressPeriod[];
  playbookTask?: {
    name: ITranslatedField;
    description: ITranslatedField;
    instruction: ITranslatedField;
  };
  customTask?: {
    type: IncidentCustomTaskType;
    customActions: CustomTaskAction[];
  };
}

export interface IincidentTaskMessage {
  incidentId?: string,
  id?: string,
  incidentTaskId: string,
  messageId: string,
  messageConversationId: string,
  messageSenderName: string,
  messageSentAt: number,
  createdAt?: number;
}

export interface IIncidentTaskRepresentationExtension extends IIncidentTaskRepresentation{
  taskObservables: IObservable[];
  taskActions: IIncidentActionRepresentation[];
  entities: IEntityRepresentation[];
  taskMessages: Array<IincidentTaskMessage & {messageContent: string}>
}
