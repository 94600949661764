import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '@ui-kit/services/modal.service';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';
import { FiltersService } from '../../filters.service';

export interface IFiltersDeleteModalData {
  filters: Filter[];
}

enum AsyncStateEnum {
  "SAVING", "READY"
};

@Component({
  selector: 'filters-delete-modal',
  templateUrl: './filters-delete-modal.component.html',
  styleUrls: [ './filters-delete-modal.component.scss' ],
})
export class FiltersDeleteModalComponent implements OnInit {
  public readonly filters: Filter[] = [];
  public readonly asyncStateEnum = AsyncStateEnum;
  public asyncState: AsyncStateEnum = AsyncStateEnum.READY;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: IFiltersDeleteModalData,
    private readonly modalService: ModalService,
    private readonly filtersService: FiltersService
  ) {
    this.filters = this.data.filters;
  }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.modalService.closeDialog();
  }

  public async deleteAssets(): Promise<void> {
    this.asyncState = this.asyncStateEnum.SAVING;
    await this.filtersService.deleteFilters(this.filters);
    this.asyncState = this.asyncStateEnum.READY;
    this.modalService.closeDialog();
  }
}
