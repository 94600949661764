import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DatasourceDetailsMapper } from 'projects/@common/services/api/respond/datasources/datasource.mapper';
import { IUsecaseDescriptionResponse } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { IIncidentConnector, getConnectors } from './initialize-incident-connectors';


@Component({
  selector: 'incident-connectors',
  templateUrl: './incident-connectors.component.html',
  styleUrls: [ './incident-connectors.component.scss' ],
})
export class IncidentConnectorsComponent implements OnChanges {
  @Input() public incidentUsecases: IUsecaseDescriptionResponse[];

  public connectors: IIncidentConnector[] = [];

  constructor(private readonly datasourceMapper: DatasourceDetailsMapper) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.incidentUsecases?.currentValue) {
      this.initConnectors(changes.incidentUsecases.currentValue);
    }
  }

  private initConnectors(incidentUsecases: IUsecaseDescriptionResponse[]): void {
    this.connectors = getConnectors(incidentUsecases, this.datasourceMapper);
  }
}
