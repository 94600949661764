import { Component, Input, OnInit } from '@angular/core';
import { AlertingUsecaseListItem } from '@common/services/api/respond/usecase/usecase.definition';

@Component({
  selector: 'usecase-silenced-by-table-column',
  templateUrl: './usecase-silenced-by-table-column.component.html',
  styleUrls: [ './usecase-silenced-by-table-column.component.scss' ],
})
export class UsecaseSilencedByComponent implements OnInit {
  @Input() usecase: AlertingUsecaseListItem;

  public silencedByName: string;
  public showMondataLogo: boolean;

  constructor() { }

  ngOnInit(): void {
    this.silencedByName = this.usecase.silencedByName;
    this.showMondataLogo = !!this.usecase.silencedByName;
  }
}
