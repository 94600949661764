<div class="drawer-detail--scrollable tab-menu">
  <div class="drawer-detail--content">
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium" modifier="secondary"></ui-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <div class="margin--small--top margin--small--bottom">
        <ui-field-group>
          <ui-static
            [label]="'usecases.details.name' | t "
            [value]="usecase?.template?.name | translatedObject"
          ></ui-static>
          <ui-static
            [label]="'usecases.details.id' | t "
            [value]="usecase?.template?.usecaseId"
          ></ui-static>
        </ui-field-group>

        <ui-field-group>
          <ui-static
            [label]="'usecases.details.description' | t "
            [value]="usecase?.template?.description | translatedObject"
          ></ui-static>
        </ui-field-group>

        <ui-field-group>
          <ui-static
            [label]="'usecases.details.activated' | t "
            [value]="'common.boolean.' + (usecase?.instances > 0) | t"
          ></ui-static>
          <ui-static
            [label]="'usecases.details.priority' | t "
            [value]="usecase?.template?.priority"
          ></ui-static>
        </ui-field-group>

        <ui-field-group>
          <ui-static
            [label]="'usecases.details.relatedDatasource' | t "
            [value]="usecase?.template?.datasourceTypes"
          ></ui-static>
        </ui-field-group>
      </div>
    </ng-container>
  </div>
</div>
