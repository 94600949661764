<div class="card-wrapper">
  <capacity-card
    logo="icon_usecase_souscrit"
    [loading]="loading"
    [capacityNumber]="usecaseTotalSubscribed"
    [topDescription]="'detection.usecase.inventory.page.capacity.total' | t"
    [bottomDescription]="
      'detection.usecase.inventory.page.capacity.total.subscribed' | t
    "
  >
  </capacity-card>

  <capacity-card
    logo="icon_active_usecase"
    [loading]="loading"
    [capacityNumber]="usecaseTotalQuantity"
    [topDescription]="'detection.usecase.inventory.page.capacity.total' | t"
    [bottomDescription]="
      'detection.usecase.inventory.page.capacity.total.active' | t
    "
  >
  </capacity-card>
</div>
