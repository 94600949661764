import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from '@common/modules/notice/notice.service';
import { OrganizationSettings } from '@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from '@common/services/api/sg/organizations/organizationsApi.service';
import { FormErrorUtils } from '@common/utils/formErrors-utils';
import { CustomValidators } from '@common/utils/validators';

@Component({
  selector: 'app-guest-grace-period',
  templateUrl: './guest-grace-period.component.html',
  styleUrls: ['./guest-grace-period.component.scss']
})
export class GuestGracePeriodComponent {
  @Input() public currentSettings: OrganizationSettings;
  @Input() public isLoading = false;

  public isEditing = false;
  public initialValue: number;

  public formGroup = new FormGroup({ gracePeriod: new FormControl(null, [Validators.required, CustomValidators.numberValidatorNotZero]) });
  private formErrorUtils: FormErrorUtils;

  constructor(
    private readonly i18nService: I18nService,
    private organizationsApiService: OrganizationsApiService,
    private noticeService: NoticeService,
  ) {
    this.formErrorUtils = new FormErrorUtils(this.i18nService);
  }

  ngOnInit(): void {
    this.formGroup.setValue({ gracePeriod: this.currentSettings?.guestGracePeriod });
    this.initialValue = this.currentSettings?.guestExpirationDateLimit;
  }

  public cancel(): void {
    this.formGroup.setValue({ gracePeriod: this.initialValue });
  }

  public handleSave() {
    this.save();
  }

  public get isValid(): boolean {
    return this.formGroup.valid;
  }

  public getErrorMessage(control: AbstractControl): string {
    return this.formErrorUtils.getErrorMessage(control);
  }

  public save(): void {
    this.organizationsApiService
      .updateOrganizationSettings({
        newSettings: {
          guestGracePeriod: Number(this.formGroup.get('gracePeriod').value)
        },
      })
      .then((response) => {
        this.initialValue = response.guestGracePeriod;
        this.formGroup.setValue({ gracePeriod: this.initialValue });
        this.noticeService.notifyUser({
          message: 'settings.guests.gracePeriod.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.guests.gracePeriod.error',
          level: NoticeLevels.ERROR,
        });
      });
  }

}
