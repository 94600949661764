import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IPlaybookDetail } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';

export enum PlaybookPatchValuesEnum {
  SUFFIX = 'suffix',
  EXTRA_DESCRIPTION = 'extraDescription',
  NOTES = 'notes'
}

export class PlaybookInventoryDetailForm {
  private _formGroup: UntypedFormGroup;

  constructor(private readonly _formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  public getValues(): IPlaybookDetail {
    return this._formGroup.getRawValue();
  }

  public setValue(patchValue: PlaybookPatchValuesEnum, value: string): void {
    this._formGroup.get(patchValue).setValue(value);
  }

  private initForm(): void {
    this._formGroup = this._formBuilder.group({
      suffix: null,
      extraDescription: null,
      notes: null,
    });
  }
}
