import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Eco } from '@common/definitions/eco';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from '@common/modules/notice/notice.service';
import { ActionsToolboxApi } from '@common/services/api/respond/actions-toolbox/actions-toolbox.api';
import { IExecuteToolboxActionsResponse, IListToolboxActionsResponse, IToolboxAction, IToolboxActionDropdownOption, IToolboxActionParameterUIField, IToolboxActionParameters, IToolboxActionTemplate, IToolboxParameterLabels } from '@common/services/api/respond/actions-toolbox/actions-toolbox.definition';
import { IncidentsApi } from '@common/services/api/respond/incidents/incidents.api';
import { IIncidentActionRepresentation } from '@common/services/api/respond/incidents/incidents.definitions';
import { ModalService } from '@ui-kit/services/modal.service';
import { IncidentEventsService } from '../../services/incident.events.service';
import { Incident } from '@common/services/api/respond/incidents/models/incident';

@Component({
  selector: 'app-incident-entity-actions-modal',
  templateUrl: './incident-entity-actions-modal.component.html',
  styleUrls: ['./incident-entity-actions-modal.component.scss']
})
export class IncidentEntityActionsModalComponent {  
  public actions: IToolboxAction[] = [];

  public locale: Eco.Languages;

  public isLoading = false;

  public isLaunching = false;

  private actionsToExecute: IToolboxAction[] = [];

  public incidentActions: IIncidentActionRepresentation[] = [];

  constructor(
    private readonly toolboxApi: ActionsToolboxApi,
    private readonly i18n: I18nService,
    private readonly noticeService: NoticeService,
    private readonly modalService: ModalService,
    private readonly incidentApi: IncidentsApi,
    private incidentEventsService: IncidentEventsService,
    @Inject(MAT_DIALOG_DATA) public data: {incident: Incident, entityId: string}
  ) {}

  ngOnInit(): void{
    this.locale = this.i18n.currentLocale as Eco.Languages;
    this.fetchIncidentEntitiesActions();
  }

  public get disabled(): boolean {
    return !this.actionsToExecute.length;
  }

  public onActionCheck(event: boolean, action: IToolboxAction): void{
    if(event) {
      this.actionsToExecute.push(action);
      return;
    }

    this.actionsToExecute = this.actionsToExecute.filter((element) => element.name !== action.name)
  }

  public async handleConfirm(): Promise<void> {
    const promises = [];
    this.isLaunching = true;
    this.actionsToExecute.map((action) => promises.push(this.toolboxApi.executeAction({
      incidentId: this.data.incident.id, 
      action: action.action,
      inputParameters: action.parameters.filter((input) => !!input.value).reduce((params, input) => {
        params[input.key] = input.value;
        return params;
      }, {})
    }).toPromise()));

    await Promise.all(promises)
      .then((result) => {
        this.incidentEventsService.emitActionExecutedEvent(result);
        this.noticeService.success('incident-actions.execute.success');
        this.fetchIncidentActions();
      })
      .catch(() => {
        this.noticeService.error('incident-actions.execute.error');
      })

      this.isLaunching = false;
      this.modalService.closeDialog();
  }

  public handleCancel(): void {
    this.modalService.closeDialog();
  }

  private fetchIncidentEntitiesActions(): void{
    this.isLoading = true;
    this.toolboxApi.listActions({...this.data, incidentId: this.data.incident.id}).subscribe(
      (res) => {
        this.actions = this.mapListActionsResponse(res);
        this.isLoading = false;
      },
      (error) => {
        this.noticeService.error('incident-actions.fetch.error');
      }
    )
  }

  private mapListActionsResponse(response: IListToolboxActionsResponse): IToolboxAction[] {
    return response.items.map((actionItem) => ({
      id: actionItem.action,
      name: actionItem.name,
      description: actionItem.description,
      applications: actionItem.applications,
      parameters: this.mapInputParameters(actionItem, response.parameters, response.labels),
      executionState: { isRunning: false, resultHtml: null },
      action: actionItem.action
    }));
  }

  private mapInputParameters(actionItem: IToolboxActionTemplate, parameters: IToolboxActionParameters, labels: IToolboxParameterLabels): IToolboxActionParameterUIField[] {
    return actionItem.parameterKeys
      .map<IToolboxActionParameterUIField>((key) => ({
      key,
      label: labels[key] ? labels[key][this.i18n.currentLocale] : key,
      value: parameters[key],
      isValid: false,
      isRequired: !actionItem.optionalParameters.includes(key),
      isArrayParam: actionItem.parameterArrays.includes(key),
      dropdownOptions: this.mapDropdownOptions(parameters[key], actionItem.defaultValues[key]),
    }))
      .sort((a, b) => a.key.localeCompare(b.key));
  }

  private mapDropdownOptions(parameters: string | string[], defaults: string | string[]): IToolboxActionDropdownOption[] {
    const paramsArray: string[] = this.getStringArray(parameters);
    const defaultsArray: string[] = this.getStringArray(defaults);

    const uniqueOptions: string[] = this.mergeArrays(paramsArray, defaultsArray);
    return uniqueOptions.map((option) => ({
      value: option,
      isDefault: defaultsArray.includes(option),
    }));
  }

  private getStringArray(items: string | string[]): string[] {
    if (typeof items === 'string' || typeof items === 'number') {
      return [ String(items) ];
    } else if (Array.isArray(items)) {
      return items.map((item) => String(item));
    }
    return [];
  }

  private mergeArrays(array1: string[], array2: string[]): string[] {
    return [ ...new Set([ ...array1, ...array2 ]) ];
  }

  private fetchIncidentActions(): void {
    this.incidentApi.listIncidentActions(this.data.incident.organizationId, this.data.incident.id).subscribe(
      (response) => {
        this.incidentActions = response.getItems();
        this.sortIncidentActions();
        this.incidentEventsService.emitActionsListedEvent(this.incidentActions);
      },
      () => this.noticeService.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)),
    );
  }

  private sortIncidentActions(): void {
    this.incidentActions.sort((a, b) => b.createdAt - a.createdAt);
  }
}
