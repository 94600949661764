<div
topSection
class="left-side-container"
*ngIf="!isLoading"
>
    <ui-title *ngIf="!hideTitle" [largeFont]="true" [name]="'detect.connector.instance.drawer.title.status' | t"></ui-title>
    <ng-container *ngFor="let error of errors">
        <indicator-card
            logo="{{error.icon}}"
            [name]="error.name"
            [description]="error.description"
            [createdAt]="error.createdAt">
        </indicator-card>
    </ng-container>
    <ng-container *ngIf="errors.length === 0">
        <indicator-card
            logo="icon_check_green"
            [name]="'monitoring.state.tooltip.ok.message' | t">
        </indicator-card>
    </ng-container>
</div>
