import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationFrequency } from 'projects/@common/definitions/notificationFrequency.enum';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationSensitiveFilesSettings, OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { CustomValidators } from 'projects/@common/utils/validators';

const DEFAULT_VALUES = {
  frequency: NotificationFrequency.WEEKLY,
  time: 10,
  weekDay: 5,
};

@Component({
  selector: 'dlp-frequency',
  templateUrl: './dlp-frequency.component.html',
  styleUrls: ['./dlp-frequency.component.scss'],
})
export class DlpFrequencyComponent implements OnInit {
  @Input() public currentSettings: OrganizationSensitiveFilesSettings;

  @Input() public isLoading = false;

  public isEditing = false;
  public notificationFrequencyEnum = NotificationFrequency;
  public dlpNotificationForm: FormGroup;
  public frequencyOptions = Object.values(NotificationFrequency).map((notificationFrequency) => {
    return {
      value: notificationFrequency,
      displayValue: this.i18n.translate('settings.notification.frequency.' + notificationFrequency),
    };
  });
  public dayOptions = (() => {
    const options = [];
    for (let i = 1; i < 32; i++) {
      options.push({ value: i, displayValue: i });
    }
    return options;
  })();
  public hourOptions = (() => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      options.push({ value: i, displayValue: i + ':00' });
    }
    return options;
  })();

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private formBuilder: FormBuilder,
    private i18n: I18nService,
    private noticeService: NoticeService
  ) { }

  ngOnInit() {
    this.initForm(this.currentSettings);
  }

  private initForm(currentSettings?: OrganizationSensitiveFilesSettings) {
    this.dlpNotificationForm = this.formBuilder.group({
      frequency: this.formBuilder.control(
        currentSettings?.riskNotificationFrequencyChoice ?? NotificationFrequency.WEEKLY
      ),
      time: this.formBuilder.control(currentSettings?.riskNotificationTime || 10),
      weekDay: this.formBuilder.control(
        currentSettings?.riskNotificationFrequency === 0 ? 0 : currentSettings?.riskNotificationFrequency || 5,
        [CustomValidators.isLessOrEqualThan(365, 'validFrequencyDuration')]
      ),
    });
  }

  public cancel() {
    this.initForm(this.currentSettings);
  }

  public save(): void {
    this.organizationsApiService
      .updateOrganizationSettings({
        newDlpSettings: {
          riskNotificationFrequencyChoice: this.dlpNotificationForm?.value.frequency,
          riskNotificationFrequency: +this.dlpNotificationForm?.value.weekDay,
          riskNotificationTime: +this.dlpNotificationForm?.value.time,
        },
      })
      .then((response) => {
        this.currentSettings = response;
        this.initForm(response);
        this.noticeService.notifyUser({
          message: 'settings.notification.frequency.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.notification.frequency.snack.error',
          level: NoticeLevels.ERROR,
        });
      });
  }

  public get isValid() {
    return this.dlpNotificationForm.valid;
  }

  public get isDefaultValue(): boolean {
    return JSON.stringify(this.dlpNotificationForm.getRawValue()) == JSON.stringify(DEFAULT_VALUES);
  }

  public restoreDefaults() {
    this.dlpNotificationForm.patchValue(DEFAULT_VALUES);
  }

  public selectDayOfTheWeek(dayOfTheWeek: string) {
    this.dlpNotificationForm.patchValue({ ...this.dlpNotificationForm.getRawValue(), weekDay: dayOfTheWeek });
  }

  public frequencyChanged(event: NotificationFrequency) {
    if (event === NotificationFrequency.WEEKLY && this.dlpNotificationForm.value.weekDay > 6) {
      this.dlpNotificationForm.patchValue({ ...this.dlpNotificationForm.getRawValue(), weekDay: 5 });
    }
    if (event === NotificationFrequency.MONTHLY && this.dlpNotificationForm.value.weekDay > 31) {
      this.dlpNotificationForm.patchValue({ ...this.dlpNotificationForm.getRawValue(), weekDay: 31 });
    }
  }

  public generateRange(start: number, end: number): number[] {
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  }
}
