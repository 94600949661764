import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiKitModule } from "@ui-kit/uikit.module";
import { AssistOpsModule } from "projects/@assist-ops/assist-ops.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { RespondModule } from "projects/@respond/respond.module";

import { AppRegistrationUpdateBody } from "./components/app-registration-update/components/app-registration-update-body/app-registration-update-body.component";
import { AppRegistrationUpdateCard } from "./components/app-registration-update/components/app-registration-update-card/app-registration-update-card.component";
import { AppRegistrationUpdateRedirectContainer } from "./components/app-registration-update/containers/app-registration-update-redirect/app-registration-update-redirect.container";
import { AppRegistrationUpdateContainer } from "./components/app-registration-update/containers/app-registration-update/app-registration-update.container";
import { IAMRoutingModule } from "./iam-routing.module";

@NgModule({
  imports: [
    CommonModule,
    IAMRoutingModule,
    UiKitModule,
    I18nModule,
    FeatureSharedModule,
    RespondModule,
    AssistOpsModule,
  ],
  declarations: [
    AppRegistrationUpdateBody,
    AppRegistrationUpdateContainer,
    AppRegistrationUpdateCard,
    AppRegistrationUpdateRedirectContainer,
  ],
})
export class IAMModule {
}
