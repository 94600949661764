import { formatDate } from '@angular/common';
import { Injectable } from "@angular/core";
import { RequestDirection } from "projects/@common/definitions/consoles.enum";
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IAlert, ListAlertsOrderBy } from "projects/@common/services/api/respond/alerts/alerts.definitions";
import { GetIncidentAlertsRequest } from "projects/@common/services/api/respond/alerts/models/getIncidentAlertsRequest";
import { DatasourceDetailsMapper } from 'projects/@common/services/api/respond/datasources/datasource.mapper';
import { FindingsApi } from "projects/@common/services/api/respond/finding/finding.api";
import { ListFindingsDirection, ListFindingsOrderBy } from "projects/@common/services/api/respond/finding/finding.definition";
import { Finding } from "projects/@common/services/api/respond/finding/model/finding";
import { GetAlertFindingsRequest } from "projects/@common/services/api/respond/finding/model/findingFilters";
import { HistoriesApi } from "projects/@common/services/api/respond/histories/histories.api";
import { GetIncidentHistoriesRequest } from "projects/@common/services/api/respond/histories/models/getIncidentIHistoriesRequest";
import { IDescribeIncidentTask, IIncidentTaskRepresentationExtension, IncidentTaskStatus, IincidentTaskMessage, IncidentCustomTaskType } from "projects/@common/services/api/respond/incidentTasks/incidentTasks.definitions";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { PlaybooksApiService } from "projects/@common/services/api/respond/playbooks/playbooks.api";
import { IPlaybookDetail, PlaybookTaskPhases } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { UsecasesApiService } from "projects/@common/services/api/respond/usecase/usecase.api";
import { IUsecaseDescriptionResponse } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { groupBy, sortObjectKeys } from 'projects/@common/utils/utils';
import { getConnectors } from 'projects/@respond/incident/components/incidents-details/components/details-tab/components/incident-connectors/initialize-incident-connectors';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { IncidentMappersService } from 'projects/@respond/incident/services/incidentMappers.service';
import { IObservable } from '@common/services/api/respond/observables/observables.definition';
import { IIncidentActionRepresentation } from '@common/services/api/respond/incidents/incidents.definitions';
import { IEntityRepresentation } from '@common/services/api/respond/entities/entities.definition';
import { IncidentTask } from '@common/services/api/respond/incidentTasks/models/incidentTask';

interface IPhase {
  name: string;
  tasks: IIncidentTaskRepresentationExtension[];
  count: number;
}

interface IAssociatedAlerts {
  alert: IAlert;
  findings: Finding[];
}

interface IChronology {
  date: string;
  events: { hour: string, label: string; }[];
}

@Injectable({
  providedIn: 'root',
})
export class IncidentTemplateMapper {
  constructor(
    private playbookService: PlaybooksApiService,
    private incidentApi: IncidentsApi,
    private historyApi: HistoriesApi,
    private findingsApi: FindingsApi,
    private usecaseApi: UsecasesApiService,
    private datasourceMapper: DatasourceDetailsMapper,
    private incidentService: IncidentMappersService,
  ) { }

  public async generateMapper(incidentInfo: Incident, incidentObservables: IObservable[], incidentActions: IIncidentActionRepresentation[], entities: IEntityRepresentation[], tasksMessages: (IincidentTaskMessage & {messageContent: string})[]): Promise<Record<string, any>> {
    const incident = await this.fetchIncident(incidentInfo);

    const [ associatedAlerts, history, usecases, playbook ] = await Promise.all([
      this.getAssociatedAlertsData(incident),
      this.fetchHistory(incident),
      this.fetchUsecases(incident),
      this.fetchPlaybook(incident),
    ]);

    return {
      incident,
      name: `${formatDate(Date.now(), 'yyyy-MM-dd', 'en-US')} - ${incident.organizationName} - ${incident.shortId} - ${incident.name}`,
      zohoTicket: incident.zohoTicket,
      affectedResources: incident.affectedResources,
      playbookParams: Object.entries(incident.playbookParams).map(([ name, value ]) => ({ name, value })),
      phases: this.getTasksByPhase(incident, incidentObservables, incidentActions, entities, tasksMessages),
      chronology: this.groupByChronology(incident),
      whitelistsCount: incident.whitelists?.length || null,
      associatedAlerts,
      history,
      usecases: usecases?.map((uc) => uc.usecase),
      connectors: usecases ? getConnectors(usecases, this.datasourceMapper) : null,
      playbook,
      contained: !this.isAllPhaseTaskNonApplicable(incidentInfo, PlaybookTaskPhases.CONTAINMENT) && incident.phaseTimestamps.CONTAINMENT.closedAt,
      remediated: !this.isAllPhaseTaskNonApplicable(incidentInfo, PlaybookTaskPhases.REMEDIATION) && incident.phaseTimestamps.REMEDIATION.closedAt
    };
  }

  private extractHtmlTextContent(htmlText) {
    var span = document.createElement('span');
    span.innerHTML = htmlText;
    return span.textContent || span.innerText;
  }

  private fetchIncident(incident: Incident): Promise<Incident> {
    return this.incidentApi.describeIncident(incident.organizationId, incident.id).toPromise();
  }

  private async fetchAlerts(incident: Incident): Promise<any> {
    const request = new GetIncidentAlertsRequest({
      incidentId: incident.id,
      organizationId: incident.organizationId,
      orderBy: ListAlertsOrderBy.createdAt,
      direction: RequestDirection.desc,
    });
    const response = await this.incidentApi.getAlertsFromIncident(request).toPromise();
    return response.getItems();
  }

  private async fetchAlertFindings(alert: IAlert): Promise<Finding[]> {
    const request = new GetAlertFindingsRequest({
      organizationId: alert.organizationId,
      alertId: alert.id,
      orderBy: ListFindingsOrderBy.createdAt,
      direction: ListFindingsDirection.desc,
    });
    const response = await this.findingsApi.listFindings(request).toPromise();
    return response.getItems();
  }

  private async fetchHistory(incident: Incident): Promise<any> {
    const request = new GetIncidentHistoriesRequest({
      incidentId: incident.id,
      organizationId: incident.organizationId,
      actions: [ 'PRIORITY_CHANGED' ],
    });
    const response = await this.historyApi.listIncidentHistories(request).toPromise();
    const history = response.getItems();

    return history.length > 0 ? history : null;
  }

  private async fetchUsecases(incident: Incident): Promise<IUsecaseDescriptionResponse[]> {
    if (!incident?.usecaseIds?.length) {
      return null;
    }
    const usecasePromises = incident.usecaseIds.map((usecaseId) => this.usecaseApi.getUsecase(incident.organizationId, usecaseId));
    const usecases: IUsecaseDescriptionResponse[] = usecasePromises?.length ? await Promise.all(usecasePromises) : null;
    return usecases?.length > 0 ? usecases : null;
  }

  private async fetchPlaybook(incident: Incident): Promise<IPlaybookDetail> {
    return this.playbookService.getPlaybook(incident.organizationId, incident.playbookId);
  }

  private stringifyFindingsContent(finding: Finding): string {
    const jsonObject = finding.content ? JSON.parse(finding.content) : null;
    if (!jsonObject) {
      return null;
    }
    const sortedFinding = sortObjectKeys(jsonObject);
    return JSON.stringify(sortedFinding, null, 2);
  }

  private async getAssociatedAlertsData(incident: Incident): Promise<IAssociatedAlerts[]> {
    const alerts = await this.fetchAlerts(incident);

    const associatedAlerts = [];

    for (let index = 0; index < alerts.length; index++) {
      const alert = alerts[index];
      const findingsResponse = await this.fetchAlertFindings(alert);
      const findings = findingsResponse.length > 0
        ? findingsResponse.map((finding: Finding) => ({
          ...finding,
          content: this.stringifyFindingsContent(finding),
        }))
        : null;

      associatedAlerts.push({
        alert,
        findings,
      });
    }

    return associatedAlerts.length > 0 ? associatedAlerts : null;
  }

  private getTasksByPhase(
    incident: Incident, 
    taskObservables: IObservable[], 
    incidentActions: IIncidentActionRepresentation[], 
    entities: IEntityRepresentation[],
    tasksMessages: (IincidentTaskMessage & {messageContent: string})[]
  ): IPhase[] {
    const groupedTasks: Map<string, IIncidentTaskRepresentationExtension[]> = new Map();

    incident.tasks.forEach((taskItem: IDescribeIncidentTask) => {
      const phaseName = taskItem.task.phase;
      const existingTasks = groupedTasks.get(phaseName) || [];
      if (!taskItem.task.hidden) {
        existingTasks.push({
          ...taskItem.task, 
          taskObservables: this.incidentService.mapObservablesToTaskObservables(taskObservables, taskItem.observables),
          taskActions: this.incidentService.mapActionsToTaskActions(incidentActions, taskItem.actions),
          entities: this.incidentService.mapEntitiesToTaskEntities(entities, taskItem.entities),
          taskMessages: tasksMessages.filter((taskMessage) => taskItem.findings.find((message) => message.id === taskMessage.id))
        });
        groupedTasks.set(phaseName, existingTasks);
      }
    });
    
    const phases: IPhase[] = Array.from(groupedTasks.entries()).map(([ name, tasks ]) => ({
      name,
      tasks,
      count: tasks.length || 0,
    }));
    return phases.length ? phases : null;
  }

  private isAllPhaseTaskNonApplicable(incident: Incident, phase: PlaybookTaskPhases): boolean {
    return incident.tasks
      ?.filter((task) => task.task.phase === phase)
      ?.every((task) => task.task.status === IncidentTaskStatus.NOT_APPLICABLE);
  }

  private groupByChronology(incident: Incident): IChronology[] {
    const dates = [
      { label: 'incidents.report.section.timeline.firstAlertDate', timestamp: incident.firstAlertTimestamp },
      { label: 'incidents.report.section.timeline.creationDate', timestamp: incident.createdAt },
      { label: 'incidents.report.section.timeline.startOfAnalysis', timestamp: incident.phaseTimestamps.IDENTIFICATION.startedAt },
      { label: 'incidents.report.section.timeline.dateOfConfinement', timestamp: this.isAllPhaseTaskNonApplicable(incident, PlaybookTaskPhases.CONTAINMENT) ? null : incident.phaseTimestamps.CONTAINMENT.closedAt },
      { label: 'incidents.report.section.timeline.dateOfRemediation', timestamp: this.isAllPhaseTaskNonApplicable(incident, PlaybookTaskPhases.REMEDIATION) ? null : incident.phaseTimestamps.REMEDIATION.closedAt },
    ];

    const clientNotified = incident.timestamps.find((timestamp) => timestamp.type === 'CLIENT_NOTIFIED_AT');
    if (clientNotified) {
      dates.push({
        label: 'incidents.report.section.timeline.firstContact',
        timestamp: clientNotified.timestamp,
      });
    }

    const excalationTimestamps = incident.timestamps.filter((timestamp) => timestamp.type.includes('ESCALATION_LEVEL'));
    excalationTimestamps.forEach((timestamp) => dates.push({
      label: `incidents.report.section.timeline.ESCALATION_LEVEL_${timestamp.type.charAt(timestamp.type.length - 1)}`,
      timestamp: timestamp.timestamp,
    }));

    const mappedDates = dates
      .filter((value) => value.timestamp > 0)
      .sort((date1, date2) => date1.timestamp - date2.timestamp)
      .map((value) => {
        const date = new Date(value.timestamp);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const timeString = `${hours}:${minutes}`;
        return {
          timestamp: value.timestamp,
          date: new Date(value.timestamp).toISOString().slice(0, 10), // yyyy-mm-dd used to group by same date
          hours: timeString, // hh:mm used to display time of the event
          label: value.label,
        };
      });

    const groupedBy = groupBy(mappedDates, 'date');

    const chronologyDates: IChronology[] = [];

    Object.keys(groupedBy).forEach((key) => {
      chronologyDates.push({
        date: groupedBy[key][0].timestamp,
        events: groupedBy[key].map((value) => ({
          hour: value.hours,
          label: value.label,
        })),
      });
    });

    chronologyDates.forEach((value, index) => {
      value.events.map((event, eIndex) => {
        event['icon'] = index === chronologyDates.length - 1 && eIndex === value.events.length - 1 ? 'BOTTOM' : 'TOP';
      });
    });

    return chronologyDates;
  }
}
