import { NgModule } from "@angular/core";
import { ActifsModule } from "./actifs/actifs.module";
import { RespondCommunicationModule } from "./communication/respond-communication.module";
import { RespondComponentsModule } from "./components/respond.components.module";
import { RespondDashboardModule } from "./dashboard/respond-dashboard.module";
import { FilterModule } from "./filters/filters.module";
import { HistoriesModule } from "./histories/histories.module";
import { IncidentModule } from "./incident/incident.module";
import { ObservablesModule } from "./observables/observables.module";
import { PlaybooksModule } from "./playbooks/playbooks.module";
import { RespondQueriesModule } from "./queries/queries.module";
import { RespondReportsModule } from "./reports/respond-reports.module";
import { UsecasesModule } from "./usecases/usecases.module";
import { WhitelistsModule } from "./whitelists/whitelists.module";

const components = [];

const modules = [
  RespondComponentsModule,
  IncidentModule,
  PlaybooksModule,
  HistoriesModule,
  ActifsModule,
  ObservablesModule,
  WhitelistsModule,
  FilterModule,
  RespondDashboardModule,
  RespondReportsModule,
  RespondCommunicationModule,
  UsecasesModule,
  RespondQueriesModule,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components, ...modules ],
  imports: [ ...modules ],
})
export class RespondModule { }
