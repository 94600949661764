import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from '@ui-kit/components/confirmation-modal/confirmation-modal.component';
import { ModalService } from '@ui-kit/services/modal.service';
import { IRespondResponse } from 'projects/@common/definitions/IRespondResponse';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { AutomationOrganizationsService } from 'projects/@common/services/api/respond/automation-organization/automation-organizations.api';
import { PlaybooksApiService } from 'projects/@common/services/api/respond/playbooks/playbooks.api';
import { IPlaybookDetail, IPlaybookTaskTemplateRepresentation, IPlaybookTemplate } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { PlaybookUpdateVersionComparatorComponent } from "../../components/playbook-update/playbook-update-version-comparator/playbook-update-version-comparator.component";
import { Playbook } from './models/Playbook';

export enum UpdateStateEnum {
  "LOADING",
  "ERROR",
  "READY",
  "SAVING",
  "UPDATE_SUCCESS"
}

interface IFetchAllResponse {
  organization: IAutomationOrganization,
  playbook: IPlaybookDetail,
  template: IPlaybookTemplate,
  mdTaskTemplates: IPlaybookTaskTemplateRepresentation[]
}

@Component({
  selector: 'app-playbook-update',
  templateUrl: './playbook-update.container.html',
  styleUrls: [ './playbook-update.container.scss' ],
})
export class PlaybookUpdateContainer implements OnInit {
  @ViewChild("versionComparator") versionComparator: PlaybookUpdateVersionComparatorComponent;

  public readonly stateEnum = UpdateStateEnum;
  public currentState: UpdateStateEnum;
  public errorMessage: string;

  public oldPlaybook: Playbook;
  public newPlaybook: Playbook;

  public organization: IAutomationOrganization;
  public mdTaskTemplates: IPlaybookTaskTemplateRepresentation[];

  private readonly playbookId: string;
  private readonly organizationId: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly playbooksApi: PlaybooksApiService,
    private readonly automationOrganizationApi: AutomationOrganizationsService,
    private readonly noticeService: NoticeService,
    private readonly modalService: ModalService,
    private readonly i18nService: I18nService
  ) {
    this.organizationId = this.route.snapshot.paramMap.get('organizationEcoId');
    this.playbookId = this.route.snapshot.paramMap.get('playbookId');
  }

  ngOnInit(): void {
    this.currentState = this.stateEnum.LOADING;
    this.fetchAll()
      .then((response) => {
        this.organization = response.organization;
        this.mdTaskTemplates = response.mdTaskTemplates;
        this.initPlaybookInstances(response.playbook, response.template);
        this.currentState = this.stateEnum.READY;
      })
      .catch((err) => {
        this.errorMessage = String(typeof err === "object" ? err.message : err);
        this.noticeService.notifyUser(new Notice(this.errorMessage, NoticeLevels.ERROR));
        this.currentState = this.stateEnum.ERROR;
      });
  }

  public updatePlaybook() {
    this.currentState = this.stateEnum.SAVING;

    const updatedPlaybook = this.versionComparator.getUpdatedPlaybook();

    if (!this.versionComparator.isValidNewPlaybook) {
      this.warnUserInvalidNewPlaybook().then(() => this.currentState = this.stateEnum.READY);
      return;
    }

    this.playbooksApi.updatePlaybookVersion(this.organizationId, updatedPlaybook)
      .then((playbookRes) => {
        this.noticeService.notifyUser(new Notice("detection.playbook.update.save.success.message", NoticeLevels.SUCCESS, { version: playbookRes.version }));
        this.currentState = this.stateEnum.UPDATE_SUCCESS;
        this.routerGoToPlaybookDetails();
      })
      .catch((httpErrorResponse) => {
        this.noticeService.notifyUser(new Notice(httpErrorResponse.message, NoticeLevels.ERROR));
        this.currentState = this.stateEnum.ERROR;
      });
  }

  public cancel(): void {
    this.routerGoToPlaybookDetails();
  }

  private async fetchAll(): Promise<IFetchAllResponse> {
    const [ organizationRes, playbookRes, mdTasksRes ] = await Promise.all([
      this.fetchOrganization(),
      this.fetchPlaybook(),
      this.fetchMdLibraryTaskTemplates(),
    ]);
    return {
      organization: organizationRes,
      playbook: playbookRes,
      mdTaskTemplates: mdTasksRes.items,
      template: playbookRes.templateId && await this.fetchTemplate(playbookRes.templateId),
    };
  }

  private async fetchOrganization(): Promise<IAutomationOrganization> {
    return this.automationOrganizationApi.describeOrganization(this.organizationId);
  }

  private async fetchPlaybook(): Promise<IPlaybookDetail> {
    return this.playbooksApi.getPlaybook(this.organizationId, this.playbookId);
  }

  private async fetchTemplate(templateId: string): Promise<IPlaybookTemplate> {
    return this.playbooksApi.getPlaybookTemplateById(templateId);
  }

  private async fetchMdLibraryTaskTemplates(): Promise<IRespondResponse<IPlaybookTaskTemplateRepresentation>> {
    return this.playbooksApi.getPlaybookTaskTemplates({language: this.i18nService.currentLocale});
  }

  private initPlaybookInstances(playbook: IPlaybookDetail, template: IPlaybookTemplate): void {
    this.oldPlaybook = new Playbook(playbook);

    // create a playbook instance from the old playbook and the template values
    this.newPlaybook = new Playbook({
      ...this.oldPlaybook,
      version: template.version,
      updatedAt: template.updatedAt,
      playbookTasks: template.playbookTasks.map((templateTask) => {
        // Danger: Cette relation d'identification unique par id et par phase dépend d'un fait crucial.
        // Il ne peut pas y avoir plus d'une tâche avec le même id dans une même phase du playbook.
        const matchingOriginalTask = this.oldPlaybook.playbookTasks.find((task) => task.id === templateTask.id && task.phase === templateTask.phase);
        return {
          ...templateTask,
          hidden: matchingOriginalTask?.hidden ?? false,
          ignored: matchingOriginalTask?.ignored ?? false,
          managed: matchingOriginalTask?.managed ?? true,
        };
      }),
    });
  }

  private routerGoToPlaybookDetails(): void {
    const path = `respond/${this.organization.ecoId}/playbooks/${this.playbookId}/detail`;
    this.router.navigateByUrl(path);
  }

  public async warnUserInvalidNewPlaybook(): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18nService.translate('detection.playbook.update.save.warningModal.title'),
        text: this.i18nService.translate('detection.playbook.update.save.warningModal.message'),
        confirmationText: this.i18nService.translate('common.action.close'),
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
  }
}
