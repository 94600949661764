import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { UrlUtils } from 'projects/@common/utils/utils';
import { VarUsecasesApiService } from 'projects/@respond/usecases/api/var-usecase.api';
import { CustomerUsecasesApiService } from 'projects/console-org/src/app/respond/usecases/api/customer-usecase.api';
import { IRespondResponse } from '../../../../definitions/IRespondResponse';
import { EcoUrlService } from '../../core/url.service';
import { IDatasourceUsecaseInfo } from '../datasources/datasources.definitions';
import { IFilterRepresentation } from '../filters/filters.definitions';
import {
  AlertingUsecaseListItem,
  IActionListItemResponse,
  IAlertingUsecaseTemplate,
  IDatasourceType,
  IListAlertingUsecasesRequest,
  ISystemListUsecaseTemplatesResponse,
  IUsecase,
  IUsecaseApi,
  IUsecaseDescriptionResponse,
  IUsecaseResponse,
  IUsecaseStatsResponse,
  UsecaseCreationInterface,
  UsecaseCreationResponse
} from './usecase.definition';

export enum ConditionTypeEnum {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
  RULE = 'RULE',
  OBSERVABLE = 'OBSERVABLE'
}

export enum AggregationModes {
  ALWAYS = 'always', // alert from same usecase are always merged
  CONDITIONAL = 'conditional' // alert are merged based on observable comparison
}

@Injectable({
  providedIn: 'root',
})
export class UsecasesApiService extends EcoApi implements IUsecaseApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  private customerService: CustomerUsecasesApiService;
  private varService: VarUsecasesApiService;

  constructor(
    http: HttpClient,
    url: EcoUrlService,
    protected store: Store
  ) {
    super(http, url);
    this.customerService = new CustomerUsecasesApiService(http, url);
    this.varService = new VarUsecasesApiService(http, url);
  }

  public async getOrganizationsUsecaseList(
    queryParam: IListAlertingUsecasesRequest
  ): Promise<IRespondResponse<AlertingUsecaseListItem>> {
    if (this.isVarMode) {
      return this.varService.getOrganizationsUsecaseList(queryParam);
    }
    return this.customerService.getOrganizationsUsecaseList(queryParam);
  }

  public async getUsecaseList(
    organizationId: string,
    queryParam: IListAlertingUsecasesRequest
  ): Promise<IRespondResponse<AlertingUsecaseListItem>> {
    if (this.isVarMode) {
      return this.varService.getUsecaseList(organizationId, queryParam);
    }
    return this.customerService.getUsecaseList(organizationId, queryParam);
  }

  public async createUsecase(organizationId: string, usecase: Partial<UsecaseCreationInterface>): Promise<UsecaseCreationResponse> {
    if (this.isVarMode) {
      return this.varService.createUsecase(organizationId, usecase);
    }
    return this.customerService.createUsecase(organizationId, usecase);
  }

  public async updateUsecase(organizationId: string, usecase: any): Promise<UsecaseCreationResponse> {
    if (this.isVarMode) {
      return this.varService.updateUsecase(organizationId, usecase);
    }
    return this.customerService.updateUsecase(organizationId, usecase);
  }

  public async updateOutdatedUsecase(usecase: any): Promise<IUsecase> {
    if (this.isVarMode) {
      return this.varService.updateOutdatedUsecase(usecase);
    }
    return this.customerService.updateOutdatedUsecase(usecase);
  }

  public async updateOutdatedUsecasePipeline(usecase: any): Promise<IUsecase> {
    if (this.isVarMode) {
      return this.varService.updateOutdatedUsecasePipeline(usecase);
    }
    return this.customerService.updateOutdatedUsecasePipeline(usecase);
  }

  public async updateUsecaseParameters(organizationId: string, parameters: any): Promise<UsecaseCreationResponse> {
    if (this.isVarMode) {
      return this.varService.updateUsecaseParameters(organizationId, parameters);
    }
    return this.customerService.updateUsecaseParameters(organizationId, parameters);
  }

  public listUsecaseTemplates(parameters: any): Promise<ISystemListUsecaseTemplatesResponse> {
    const url = `${super.host}/system/usecaseCatalog?${UrlUtils.jsonToQueryParams(parameters)}`;
    return this.http.get<ISystemListUsecaseTemplatesResponse>(url).toPromise();
  }

  public async getUsecaseTemplate(usecaseId: string): Promise<Array<{ name: string; description: ITranslatedField }>> {
    if (this.isVarMode) {
      return this.varService.getUsecaseTemplate(usecaseId);
    }
    return this.customerService.getUsecaseTemplate(usecaseId);
  }

  public async getUsecase(organizationId: string, usecaseId: string): Promise<IUsecaseDescriptionResponse> {
    if (this.isVarMode) {
      return this.varService.getUsecase(organizationId, usecaseId);
    }
    return this.customerService.getUsecase(organizationId, usecaseId);
  }

  public async getUsecaseActions(): Promise<IActionListItemResponse> {
    if (this.isVarMode) {
      return this.varService.getUsecaseActions();
    }
    return this.customerService.getUsecaseActions();
  }

  public async deleteUsecase(organizationId: string, usecaseId: string): Promise<{ deleted: boolean; organizationId: string; usecaseId: string }> {
    if (this.isVarMode) {
      return this.varService.deleteUsecase(organizationId, usecaseId);
    }
    return this.customerService.deleteUsecase(organizationId, usecaseId);
  }

  public async getUsecasesCatalog(params: {
    from?: number;
    size?: number;
    activated?: string;
    datasourceType?: string;
    organizationId?: string;
    orderColumn?: string;
    order?: string;
  }): Promise<IRespondResponse<IUsecaseResponse>> {
    if (this.isVarMode) {
      return this.varService.getUsecasesCatalog(params);
    }
    return this.customerService.getUsecasesCatalog(params);
  }

  public async getUsecaseCatalog(usecaseId: string): Promise<IUsecaseResponse> {
    if (this.isVarMode) {
      return this.varService.getUsecaseCatalog(usecaseId);
    }
    return this.customerService.getUsecaseCatalog(usecaseId);
  }

  public async getDatasourcesDataTypes(): Promise<IRespondResponse<IDatasourceType>> {
    if (this.isVarMode) {
      return this.varService.getDatasourcesDataTypes();
    }
    return this.customerService.getDatasourcesDataTypes();
  }

  public async getDatasourceFamilyInfo(organizationId: string): Promise<IDatasourceUsecaseInfo[]> {
    if (this.isVarMode) {
      return this.varService.getDatasourceFamilyInfo(organizationId);
    }
    return this.customerService.getDatasourceFamilyInfo(organizationId);
  }

  public async getAggregatedUsecasesList(organizationId: string, queryParam?: any): Promise<IRespondResponse<IAlertingUsecaseTemplate>> {
    if (this.isVarMode) {
      return this.varService.getAggregatedUsecasesList(organizationId, queryParam);
    }
    return this.customerService.getAggregatedUsecasesList(organizationId, queryParam);
  }

  public async getUsecaseFilters(organizationId: string, usecaseId: string): Promise<IRespondResponse<IFilterRepresentation>> {
    if (this.isVarMode) {
      return this.varService.getUsecaseFilters(organizationId, usecaseId);
    }
    return this.customerService.getUsecaseFilters(organizationId, usecaseId);
  }

  public async getUsecaseStats(organizationId: string): Promise<IUsecaseStatsResponse> {
    if (this.isVarMode) {
      return this.varService.getUsecaseStats(organizationId);
    }
    return this.customerService.getUsecaseStats(organizationId);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}

