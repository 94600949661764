import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Eco } from 'projects/@common/definitions/eco';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { ActifsListViewModeEnum, IAssetPropertyConfigRepresentation, IAssetRepresentation, ListAssetsOrderBy, ListDirectionEnum } from 'projects/@common/services/api/respond/actifs/actifs.definitions';
import { clone } from 'projects/@common/utils/utils';
import { ActifsService } from '../../actifs.service';
import { ActifTypesIconName } from '../../containers/respond-actifs-list/actifs-list-icons.enum';
import { ActifsTableColumnEnum, addDynamicColumns } from '../../containers/respond-actifs-list/actifs-list-table.config';

export interface IAssetGroup {
  assets: IAssetRepresentation[];
  assetType: string | null;
  name: string;
  columnsDef: any[];
  isExpanded: boolean;
  icon?: string;
}

@Component({
  selector: 'actifs-table',
  templateUrl: './actifs-table.component.html',
  styleUrls: [ './actifs-table.component.scss' ],
})
export class ActifsTableComponent implements OnChanges {
  @Input() public defaultColumnsDef: any[] = [];
  @Input() public assetTypes: IAssetPropertyConfigRepresentation[] = [];
  @Input() public assetsData: IAssetRepresentation[] = [];
  @Input() public canCreate: boolean = false;
  @Input() public canDescribe: boolean = false;
  @Input() public canDelete: boolean = false;
  @Input() public isReadonly: boolean = false;
  @Input() public disabledGrouping: boolean = false;

  @Output() public rowClickEvent = new EventEmitter<IAssetRepresentation>();
  @Output() public createEvent = new EventEmitter<void>();
  @Output() public deleteEvent = new EventEmitter<void>();

  public assetGroups: IAssetGroup[] = [];

  constructor(
    private readonly actifsService: ActifsService,
    private readonly i18n: I18nService,
    private readonly staticTableDataFactory: StaticTableDataMapper,
    private readonly translatedObjectPipe: TranslatedObjectPipe
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assetsData || changes.assetTypes) {
      this.mapAssetsDataToTypeGroups(this.assetsData);
    }
  }

  public get viewMode(): ActifsListViewModeEnum {
    return this.actifsService.listViewMode;
  }

  public get isSimpleList(): boolean {
    return this.viewMode === ActifsListViewModeEnum.SIMPLE_LIST;
  }

  public get locale(): Eco.Languages {
    return this.i18n.currentLocale as Eco.Languages;
  }

  public get isLoading(): boolean {
    return this.actifsService.isLoading;
  }

  public get direction(): string {
    return this.actifsService.listRequest.direction?.toLowerCase();
  }

  public get sort(): ListAssetsOrderBy {
    return this.actifsService.listRequest.orderBy;
  }

  public get selectedCount(): number {
    return this.actifsService.selectedRows?.length || null;
  }

  public get shouldHideCreateButton(): boolean {
    return this.isReadonly || !this.canCreate;
  }

  public get shouldHideDeleteButton(): boolean {
    return this.isReadonly || !this.canDelete || !this.actifsService.selectedRows?.length;
  }

  public trackByName(index: number, item: IAssetGroup): string {
    return item.name;
  }

  public onClickRow(row: IAssetRepresentation): void {
    this.rowClickEvent.emit(row);
  }

  public onClickCreate(): void {
    this.createEvent.emit();
  }

  public onClickDelete(): void {
    this.deleteEvent.emit();
  }

  public handleSort(sortData: any): void {
    this.actifsService.setSort(
      sortData.sortDirection?.toUpperCase() as ListDirectionEnum,
      sortData.sortColumn as ListAssetsOrderBy
    );
  }

  public handleCheckboxEvent(isChecked: boolean, rowItem: any): void {
    if (isChecked) {
      this.actifsService.selectedRows.push(rowItem);
    } else {
      this.actifsService.selectedRows = this.actifsService.selectedRows.filter((item) => item.id !== rowItem.id);
    }
  }

  private mapAssetsDataToTypeGroups(assetsData: IAssetRepresentation[]): void {
    if (this.viewMode === ActifsListViewModeEnum.SIMPLE_LIST) {
      this.assetGroups = this.getAssetGroupsAsSimpleList(assetsData);
    } else if (this.viewMode === ActifsListViewModeEnum.GROUPED_BY_ASSET_TYPE) {
      this.assetGroups = this.getAssetGroupsByTypes(assetsData);
    }
  }

  private getAssetGroupsAsSimpleList(assetsData: IAssetRepresentation[]): IAssetGroup[] {
    const excludedDefaultColumns = [ ActifsTableColumnEnum.relations ];

    const defaultColumns = this.defaultColumnsDef.filter((column) => !excludedDefaultColumns.includes(column.field));
    const assetsOfAllTypes = clone(assetsData);

    const [ columns, tableData ] = this.staticTableDataFactory.translate(defaultColumns, assetsOfAllTypes);

    const assetGroups = [ {
      columnsDef: columns,
      assets: tableData,
      assetType: null,
      name: this.translatedObjectPipe.transform({ fr: "Tous", en: "All" }),
      isExpanded: true,
    } ];
    return assetGroups;
  }

  private getAssetGroupsByTypes(assetsData: IAssetRepresentation[]): IAssetGroup[] {
    const assetGroups = this.assetTypes
      .map((item: IAssetPropertyConfigRepresentation) => {
        const excludedDefaultColumns = [ ActifsTableColumnEnum.type ];
        if (!item.config.showRelations) {
          excludedDefaultColumns.push(ActifsTableColumnEnum.relations);
        }

        const defaultColumns = this.defaultColumnsDef.filter((column) => !excludedDefaultColumns.includes(column.field));
        const assetsOfType = clone(assetsData.filter((asset: IAssetRepresentation) => asset.type === item.type));

        const [ columns, tableData ] = this.staticTableDataFactory.translate(defaultColumns, assetsOfType);
        addDynamicColumns(columns, item.config, this.locale);

        return {
          columnsDef: columns,
          assets: tableData,
          assetType: item.type,
          name: `${this.translatedObjectPipe.transform(item.config.name)}s`,
          isExpanded: false,
          icon: `actifs/${ActifTypesIconName[item.type] || ActifTypesIconName._UNKNOWN}_32px`,
        };
      })
      .sort((a: IAssetGroup, b: IAssetGroup) => a.name.localeCompare(b.name));
    return assetGroups;
  }
}
