import { Injectable } from '@angular/core';
import {
  IDetailHeaderData
} from 'projects/console-org/src/app/md-detection-response/components/datasource-inventory-detail/components/detail-header/detail-header.component';
import {
  DatasourceInventoryInfo,
  DatasourceMode,
  Icon,
  IDatasourceTableObject,
  IDatasourceVignette
} from './datasources.definitions';
import { DataConnectorTypes } from '@md.eco/connectors';
import { WebUiDataConnectorTypesCatalog } from '@common/services/catalogs/connectors.catalog';

const ICON_BASE_PATH = 'assets/favicons';

@Injectable({
  providedIn: 'root',
})
export class DatasourceDetailsMapper {
  public getDatasourceVignettes(entity?: any): IDatasourceVignette[] {
    const vignettes: IDatasourceVignette[] = [
      {
        title: 'detection.datasource.inventory.usecase',
        subtitle: 'detection.datasource.inventory.active',
        number: entity.totalUsecaseQuantity,
        icon: 'icon_active_usecase',
      },
    ];

    return vignettes;
  }

  public detailTableMapper(entity: any): IDatasourceTableObject {
    const datasourcesIcon = this.getDatasourceIcon(entity?.datasourceTypes || []);
    return {
      datasourcesIcon,
      name: entity.name,
      mode: entity.silenced ? DatasourceMode.SILENCE : DatasourceMode.ALERT,
      priority: entity.priority,
      activationDate: entity.createdAt,
      description: entity.description,
      datasources: datasourcesIcon.map((icon) => icon.name).join(', '),
      catalogId: entity.catalogId,
    };
  }

  public getDatasourceDetail(info: DatasourceInventoryInfo, totalUsecaseQuantity = 0): IDetailHeaderData {
    return {
      isActive: info?.state,
      vignettes: this.getDatasourceVignettes({ totalUsecaseQuantity }),
      title: info?.title,
      activationDate: info?.activatedAt,
      description: info?.description,
      lastModification: info?.updatedAt,
    };
  }

  public getDatasourceIcon(datasourceTypes: string[]): Icon[] {
    const datasourcesIcon: Icon[] = [];
    if (datasourceTypes) {
      for (const type of datasourceTypes) {
        const datasource = WebUiDataConnectorTypesCatalog[type as DataConnectorTypes];
        datasourcesIcon.push({
          icon: datasource?.icon || `${ICON_BASE_PATH}/logo_datasourceType_default.svg`, // Temporary fix for old datasource types.
          name: datasource?.name ? this.toTitleCase(datasource.name) : type,
        });
      }
    }

    return datasourcesIcon;
  }

  private toTitleCase(value: string): string {
    return value.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }
}
