import { Component, Input, OnInit } from '@angular/core';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';
import { WhitelistsService } from '../../whitelists.service';

export interface IWhitelistDrawerData {
  whitelistId: string;
  organizationId: string;
  title: string;
  isReadonly: boolean;
}

export enum WhitelistDrawerTabsEnum {
  DETAILS = 'DETAILS',
  HISTORY = 'HISTORY'
}

@Component({
  selector: 'respond-whitelist-drawer',
  templateUrl: './respond-whitelist-drawer.container.html',
  styleUrls: [ './respond-whitelist-drawer.container.scss' ],
})
export class RespondWhitelistDrawerContainer implements OnInit {
  @Input()
  public data: IWhitelistDrawerData;

  public selectedTab = WhitelistDrawerTabsEnum.HISTORY;

  constructor(
    private readonly whitelistsService: WhitelistsService,
    private readonly drawerService: DrawerService
  ) { }

  ngOnInit(): void {
    if (this.data.whitelistId) {
      this.loadWhitelist();
      this.loadHistory();
    } else {
      this.clearWhitelist();
    }
  }

  public get whitelist(): Whitelist {
    return this.whitelistsService.whitelist;
  }

  public get isLoading(): boolean {
    return this.whitelistsService.isLoadingWhitelist;
  }

  public get organizationId(): string {
    return this.data.organizationId;
  }

  public get isEditing(): boolean {
    return this.drawerService.isEditing;
  }

  public loadWhitelist(): void {
    this.whitelistsService.fetchWhitelist({ id: this.data.whitelistId, organizationId: this.data.organizationId });
  }

  public clearWhitelist(): void {
    this.whitelistsService.whitelist = null;
  }

  public loadHistory(): void {
    this.whitelistsService.fetchHistory(this.data.whitelistId, this.data.organizationId);
  }
}
