import { OrganizationSupportTypeEnum } from 'projects/@assist-ops/components/background-tasks/enums/organizationSupportType.enum';
import { NotificationFrequency } from 'projects/@common/definitions/notificationFrequency.enum';

export interface OrganizationSettings {
  supportLink: string;
  supportType: OrganizationSupportTypeEnum;
  partialSyncGroupIds: string[];
  archiveAccountId: string;
  engagementLevelsDisplayDelay: number;
  guestUserExpirationTime: number;
  guestUserNotificationFrequencyChoice: NotificationFrequency;
  guestUserNotificationFrequency: number;
  defaultRevisionFrequency: number;
  segmentationAttribute: string;
  notificationRevisionFrequency: number;
  archiveNotificationDelay: number;
  externalUserCollaboration?: boolean;
  notifyNonCompliantUsers?: boolean;
  guestExpirationDateLimit?: number;
  guestGracePeriod?: number;
}

export interface OrganizationShareSettings {
  highRiskNotificationFrequencyChoice: NotificationFrequency;
  highRiskNotificationFrequency: number;
  highRiskNotificationTime: number;
  durationMediumDayCount: number;
  durationLongDayCount: number;
  silencingOptions: number[];
  downloadedFileNotificationFrequency: number;
}

export enum DlpType {
  QoHash = 'QoHash',
  Purview = 'Purview'
}

export interface OrganizationSensitiveFilesSettings {
  ecoId: string;
  isActivated?: boolean; // true if initial sync done this will activate the cron that starts the delta sync
  secretId: string;
  dlpType: DlpType;
  initialSyncIndex?: number; // used incase initial sync is interrupted
  lastIngestedDate: number;
  minRiskThreshold: number;
  riskNotificationFrequencyChoice?: NotificationFrequency;
  riskNotificationFrequency?: number;
  riskNotificationTime?: number;
  justificationDelay?: number;
  grantedPurviewConsent?: boolean;
}

