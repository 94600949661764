import { Injectable } from '@angular/core';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { DatasourceDetailsMapper } from 'projects/@common/services/api/respond/datasources/datasource.mapper';
import {
  IPlaybookDetail,
  IPlaybookListResponse,
  IPlaybookTableObject,
  IUsecaseTableData
} from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { IAlertingUsecaseTemplate } from 'projects/@common/services/api/respond/usecase/usecase.definition';

@Injectable({
  providedIn: 'root',
})
export class PlaybooksMapper {
  constructor(private readonly datasourceDetailsMapper: DatasourceDetailsMapper) {
  }

  public playbooksTableMapper(entity: IPlaybookListResponse): IPlaybookTableObject {
    return {
      id: entity.playbook.id,
      organizationId: entity.organization?.organizationId || entity.playbook.organizationId,
      organizationEcoId: entity.organization?.organizationEcoId,
      organizationName: entity.organization?.organizationName,
      name: this.getPlaybookNameWithFallback(entity.playbook),
      usecaseCount: entity.usecaseCount,
      taskCount: entity.taskCount,
      templateVersion: entity.playbook.version,
      createdAt: entity.playbook.createdAt,
      updatedAt: entity.playbook.updatedAt,
      outdated: entity.outdated,
    };
  }

  public playbookUsecaseTableMapper(entity: IAlertingUsecaseTemplate[]): IUsecaseTableData[] {
    const table: IUsecaseTableData[] = [];
    entity.forEach((entityItem) => {
      const datasourcesIcon = this.datasourceDetailsMapper.getDatasourceIcon(entityItem.datasourceTypes);
      const currentUsecase: IUsecaseTableData = {
        datasourcesIcon,
        name: entityItem.name,
        priority: entityItem.priority,
        datasources: entityItem.datasourceTypes,
        description: entityItem.description,
      };
      table.push(currentUsecase);
    });
    return table;
  }

  private getPlaybookNameWithFallback(playbook: IPlaybookDetail): ITranslatedField {
    // Because we cannot modify a playbook's name after it has been saved,
    // we need to ensure both languages are set to avoid showing "undefined" in the UI table when name.fr or name.en is unset.
    // For example: if I created the playbook with an english browser, the name.en will be saved but the name.fr will be undefined in the DB.
    const nameFr = playbook.name?.fr;
    const nameEn = playbook.name?.en;
    return {
      fr: `${nameFr || nameEn} ${playbook.suffix}`,
      en: `${nameEn || nameFr} ${playbook.suffix}`,
    };
  }
}
