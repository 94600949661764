import { OverlayModule as CdkOverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { UiKitModule } from "@ui-kit/uikit.module";

import { DisplayModule } from "../display/display.module";
import { I18nModule } from "../i18n/i18n.module";
import { Drawer } from "./components/drawer/drawer.component";
import { DrawerState } from "./components/drawer/stores/drawer.store";
import { EmailInitializerComponent } from "./components/email-initializer/email-initializer.component";
import { ImagesBankComponent } from "./components/images-bank/images-bank.component";
import { MainContent } from "./components/main-content/main-content.component";
import { MousePopupComponent } from "./components/mouse-popup/mouse-popup.component";
import { MobileSidebarButton } from "./components/sidebar/mobile-top-sidebar/mobile-sidebar-button/mobile-sidebar-button.component";
import { MobileTopSidebar } from "./components/sidebar/mobile-top-sidebar/mobile-top-sidebar.component";
import { TopbarLogo } from "./components/sidebar/sidebar-logo/topbar-logo.component";
import { SidebarNumber } from "./components/sidebar/sidebar-number/sidebar-number.component";
import { Sidebar } from "./components/sidebar/sidebar.component";
import { TopbarOrganizations } from "./components/topbar/topbar-organizations/topbar-organizations.component";
import { TopbarToggle } from "./components/topbar/topbar-toggle/topbar-toggle.component";
import { TopbarUserPopover } from "./components/topbar/topbar-user/topbar-user-popover/topbar-user-popover.component";
import { TopbarUser } from "./components/topbar/topbar-user/topbar-user.component";
import { Topbar } from "./components/topbar/topbar.component";
import { ConsoleType, PrivateLayout } from "./private.layout";
import { AppState } from "./stores/app.state";
import { MousePopupState } from "./stores/mouse-popup.state";
import { UserActivityDialogComponent } from "./components/user-activity-dialog/user-activity-dialog.component";
import { TopbarAddtionalInformation } from "./components/topbar/topbar-additional-info/topbar-additional-info.component";


const components = [
  PrivateLayout,
  Topbar,
  TopbarToggle,
  Sidebar,
  SidebarNumber,
  TopbarLogo,
  Drawer,
  MobileTopSidebar,
  MobileSidebarButton,
  TopbarOrganizations,
  MousePopupComponent,
  MainContent,
  TopbarUserPopover,
  TopbarUser,
  TopbarAddtionalInformation,
  ImagesBankComponent,
  EmailInitializerComponent, // Used to set the user's notification email if unfound.
  UserActivityDialogComponent, 
];

@NgModule({
  imports: [
    NgxsModule.forFeature([
      AppState,
      DrawerState,
      MousePopupState,
    ]),
    BrowserModule,
    RouterModule,
    I18nModule,
    CdkOverlayModule,
    UiKitModule,
    PortalModule,
    FormsModule,
    MatSidenavModule,
    DisplayModule,
    MatDialogModule,
  ],
  declarations: [ ...components ],
  providers: [],
  exports: [ ...components ],
})
export class PrivateModule {
  static inject(config: {
    respondSidebar?: any,
    detectSidebar?: any,
    iamSideBar?: any,
    airSidebar?: any,
    pagesEnum: any,
    toolsSidebar?: any;
    disableHeader?: boolean,
    disableSidebar?: boolean,
    consoleType: ConsoleType;
  }): ModuleWithProviders<PrivateModule> {
    return {
      ngModule: PrivateModule,
      providers: [
        { provide: 'AIR_SIDEBAR', useValue: config.airSidebar },
        { provide: 'RESPOND_SIDEBAR', useValue: config.respondSidebar },
        { provide: 'DETECT_SIDEBAR', useValue: config.detectSidebar },
        { provide: 'IAM_SIDEBAR', useValue: config.iamSideBar },
        { provide: 'PAGES_ENUM', useValue: config.pagesEnum },
        { provide: 'TOOLS_SIDEBAR', useValue: config.toolsSidebar },
        { provide: 'DISABLE_HEADER', useValue: config.disableHeader },
        { provide: 'DISABLE_SIDEBAR', useValue: config.disableSidebar },
        { provide: 'CONSOLE_TYPE', useValue: config.consoleType },
      ],
    };
  }
}
