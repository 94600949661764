<widget-container class="content" [title]="'dashboard.admin.top10ResourceOwners.title' | t" [withBottomMargin]="true" [locale]="locale" [withTitleTooltip]="true">
  <div tooltip-content >
    <p>{{ 'dashboard.admin.top10ResourceOwners.tooltip' | t }} </p>
  </div>

    <div *ngIf="resources?.length && !isLoading" class="sub-content">
        <div class="sub-content--title">
            <p [ngStyle]="{'width': '315px'}">{{ 'dashboard.admin.top10ResourceOwners.subtitle.owners' | t }} </p>
            <p [ngStyle]="{'width': 'max-content'}" >{{ 'dashboard.admin.top10ResourceOwners.subtitle.amount' | t }} </p>
        </div>
        <div class="sub-content--rows">
            <ng-container *ngFor="let resource of resources">
                <admin-top10-resource-row [resource]="resource"></admin-top10-resource-row>
            </ng-container>
        </div>
    </div>
    <ui-spinner *ngIf="isLoading"></ui-spinner>
    <div
      *ngIf="!resources?.length && !isLoading"
      class=" no-content"
    >
        {{ 'dashboard.admin.noData' | t }}
    </div>
</widget-container>
