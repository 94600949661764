import { Eco } from "projects/@common/definitions/eco";

export const OrgPermissionsMapping: Eco.PermissionsMapping = {
  can_list_datasources_family: {
    action: 'respond:ListDatasourcesFamily',
    resourceLabel: 'md:::respond:datasources/all',
  },
  can_list_detect_connector_instances: {
    action: 'detect:GetConnectors',
    resourceLabel: 'md:::detect:connectors/all',
  },
  can_list_aggregated_usecases: {
    action: 'respond:ListAggregatedUsecases',
    resourceLabel: 'md:::respond:usecases/aggregated/all',
  },
  can_create_usecase: {
    action: 'respond:CreateUsecase',
    resourceLabel: 'md:::respond:usecases/all',
  },
  can_delete_usecase: {
    action: 'respond:DeleteUsecase',
    resourceLabel: 'md:::respond:usecases/all',
  },
  list_profiles: {
    action: 'iam:ListProfiles',
    resourceLabel: 'md:::iam:profiles/all',
  },
  can_list_secrets: {
    action: 'tools:ListSecrets',
    resourceLabel: 'md:::tools:secret/all',
  },
  can_create_secret: {
    action: 'tools:CreateSecret',
    resourceLabel: 'md:::tools:secret/all',
  },
  can_update_secret: {
    action: 'tools:UpdateSecret',
    resourceLabel: 'md:::tools:secret/all',
  },
  can_delete_secret: {
    action: 'tools:DeleteSecret',
    resourceLabel: 'md:::tools:secret/all',
  },
  can_create_var_secret: {
    action: 'tools:CreateSecret',
    resourceLabel: 'md:*::tools:secret/all',
  },
  can_update_var_secret: {
    action: 'tools:UpdateSecret',
    resourceLabel: 'md:*::tools:secret/all',
  },
  can_delete_var_secret: {
    action: 'tools:DeleteSecret',
    resourceLabel: 'md:*::tools:secret/all',
  },


  can_list_incidents: {
    action: 'respond:ListIncidents',
    resourceLabel: 'md:::respond:incidents/all',
  },
  can_describe_incident: {
    action: 'respond:DescribeIncident',
    resourceLabel: 'md:::respond:incidents/all',
  },
  // reactivate after the incident phases optimization
  /* can_update_incident: {
    action: 'respond:UpdateIncident',
    resourceLabel: 'md:::respond:incidents/all'
  },*/

  can_read_escalation: {
    action: "respond:ReadEscalation",
    resourceLabel: "md:::respond:escalationContactLists/all",
  },
  can_write_escalation: {
    action: "respond:WriteEscalation",
    resourceLabel: "md:::respond:escalationContactLists/all",
  },
  can_update_escalation_parameter: {
    action: "respond:UpdateEscalationParameter",
    resourceLabel: "md:::respond:escalationParameters/all",
  },
  can_delete_escalation_contact: {
    action: "respond:DeleteEscalationContact",
    resourceLabel: "md:::respond:contacts/all",
  },
  can_delete_escalation_contact_list: {
    action: "respond:DeleteEscalationContactList",
    resourceLabel: "md:::respond:escalationContactLists/all",
  },

  can_read_custom_queries: {
    action: "respond:ListCustomQuery",
    resourceLabel: "md:::respond:queries/all",
  },

  can_list_playbooks: {
    action: 'respond:ListPlaybooks',
    resourceLabel: 'md:::respond:playbooks/all',
  },
  can_describe_playbook: {
    action: 'respond:DescribePlaybook',
    resourceLabel: 'md:::respond:playbooks/all',
  },
  can_update_playbook: {
    action: 'respond:UpdatePlaybook',
    resourceLabel: 'md:::respond:playbooks/all',
  },
  files_admin: {
    action: 'sg:GetResourcesMonitoringStats',
    resourceLabel: 'md:::sg:sharedFiles/all',
  },
  list_users_with_shares: {
    action: 'sg:GetUsersWithShares',
    resourceLabel: 'md:::sg:sharedFiles/users/all',
  },
  list_resources_with_shares: {
    action: 'sg:GetResourcesWithShares',
    resourceLabel: 'md:::sg:sharedFiles/all',
  },
  create_resource: {
    action: 'sg:CreateSecurityModel',
    resourceLabel: 'md:::sg:securityModel/all',
  },
  add_guest_manager: {
    action: 'sg:ModifyGuestManagers',
    resourceLabel: 'md:::sg:guestManager/all',
  },
  list_organization_settings: {
    action: 'sg:GetOrganizationSettings',
    resourceLabel: 'md:::sg:organizations/all',
  },
  list_guest_orphans: {
    action: 'sg:ListGuestOrphans',
    resourceLabel: 'md:::sg:guestUsers/all',
  },
  can_list_assets: {
    action: 'respond:ListAssets',
    resourceLabel: 'md:::respond:assets/all',
  },
  can_describe_asset: {
    action: 'respond:DescribeAsset',
    resourceLabel: 'md:::respond:assets/all',
  },
  can_create_asset: {
    action: 'respond:CreateAsset',
    resourceLabel: 'md:::respond:assets/all',
  },
  can_update_asset: {
    action: 'respond:UpdateAsset',
    resourceLabel: 'md:::respond:assets/all',
  },
  can_delete_asset: {
    action: 'respond:DeleteAsset',
    resourceLabel: 'md:::respond:assets/all',
  },

  can_list_filters: {
    action: 'respond:ListWhitelists',
    resourceLabel: 'md:::respond:filters/all',
  },
  can_describe_filter: {
    action: 'respond:DescribeWhitelist',
    resourceLabel: 'md:::respond:filters/all',
  },
  can_create_filter: {
    action: 'respond:CreateWhitelist',
    resourceLabel: 'md:::respond:filters/all',
  },
  can_update_filter: {
    action: 'respond:UpdateWhitelist',
    resourceLabel: 'md:::respond:filters/all',
  },
  can_delete_filter: {
    action: 'respond:DeleteWhitelist',
    resourceLabel: 'md:::respond:filters/all',
  },

  can_list_whitelists: {
    action: 'respond:ListWhitelists',
    resourceLabel: 'md:::respond:whitelists/all',
  },
  can_describe_whitelist: {
    action: 'respond:DescribeWhitelist',
    resourceLabel: 'md:::respond:whitelists/all',
  },
  can_create_whitelist: {
    action: 'respond:CreateWhitelist',
    resourceLabel: 'md:::respond:whitelists/all',
  },
  can_update_whitelist: {
    action: 'respond:UpdateWhitelist',
    resourceLabel: 'md:::respond:whitelists/all',
  },
  can_delete_whitelist: {
    action: 'respond:DeleteWhitelist',
    resourceLabel: 'md:::respond:whitelists/all',
  },
  can_update_profile_members: {
    action: 'iam:UpdateProfileMembers',
    resourceLabel: 'md:::iam:profiles/all',
  },
  list_profile_members: {
    action: 'iam:ListProfileMembers',
    resourceLabel: 'md:::iam:profiles/all',
  },
  can_update_approvers: {
    action: 'sg:PutApprovers',
    resourceLabel: 'md:::sg:approvers/all',
  },
  can_update_archivers: {
    action: 'sg:PutArchivers',
    resourceLabel: 'md:::sg:archivers/all',
  },
};
