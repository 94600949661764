import { Component, OnInit, ViewChild } from '@angular/core';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { generateDocumentationLink, DOCUMENTATION_SECTION } from '@common/utils/eco.documentation-utils';
import { ResourcesSharesTableComponent } from 'projects/@assist-ops/components/resoures-shares-table/resources-shares-table.component';

@Component({
  selector: 'app-org-resources-shares',
  templateUrl: './org-resources-shares.container.html',
  styleUrls: [ './org-resources-shares.container.scss' ],
})
export class OrgResourcesSharesContainer implements OnInit {
  public documentationSection = generateDocumentationLink(DOCUMENTATION_SECTION.sg.admin.resourceShares, this.i18n.currentLocale);
  @ViewChild('table') table: ResourcesSharesTableComponent;

  constructor(public readonly i18n: I18nService) {
  }

  ngOnInit(): void {
  }

  public onScroll() {
    this.table.loadMore();
  }
}
