import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { UiMultiSelect } from '@ui-kit/components/ui-multi-select/ui-multi-select.component';
import { UiTableDateToolComponent, DateTimePeriod } from '@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { DateIntervalComponent } from 'projects/@common/components/date-interval/date-interval.component';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ISimilarityFilterEvent, SimilarityIndicatorEnum, SimilarityItemTypeEnum, SimilarityItemTypeFilterEnum, SimilarityPageContext } from '../similarity.definitions';

@Component({
  selector: 'similarity-filters',
  templateUrl: './similarity-filters.component.html',
  styleUrls: [ './similarity-filters.component.scss' ],
})
export class SimilarityFiltersComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() totalDisplayed: number;
  @Input() pageContext: SimilarityPageContext;
  @Input() defaultFilters: ISimilarityFilterEvent;

  @Output() filterEvent = new EventEmitter<ISimilarityFilterEvent>();
  @Output() manualRefreshEvent = new EventEmitter<null>();

  @ViewChildren(UiMultiSelect) multiSelectors!: QueryList<UiMultiSelect>;
  @ViewChild(UiTableDateToolComponent) datePicker!: UiTableDateToolComponent;
  @ViewChild(DateIntervalComponent) dateIntervalComponent!: DateIntervalComponent;

  public appliedFiltersCount: number;
  public period: DateTimePeriod;
  public selectedItemType: SimilarityItemTypeFilterEnum;
  public selectedIndicators: SimilarityIndicatorEnum[];

  public itemTypeSelectOptions: TableFilterData;
  public indicatorMultiSelectOptions: MultiSelectData;

  constructor(
    public readonly i18n: I18nService,
    private readonly multiSelectFactory: MultiSelectDataFactory,
    private readonly selectFactory: TableFilterToolDataFactory
  ) { }

  ngOnInit(): void {
    this.initDefaultFilters();
    this.initItemTypeSelect();
    this.initIndicatorSelect();
    this.updateAppliedFiltersCount();
  }

  public emitFilterEvent(): void {
    this.filterEvent.emit({
      dateFrom: this.period?.dateFrom ?? null,
      dateTo: this.period?.dateTo ?? null,
      type: this.convertFilterTypeEnumToQueryParam(this.selectedItemType),
      sameOrganization: this.selectedIndicators?.includes(SimilarityIndicatorEnum.SAME_ORGANIZATION) || null,
      sameUsecase: this.selectedIndicators?.includes(SimilarityIndicatorEnum.SAME_USECASE) || null,
      samePlaybook: this.selectedIndicators?.includes(SimilarityIndicatorEnum.SAME_PLAYBOOK) || null,
    });
    this.dateIntervalComponent.updateFormattedTimestamp(this.period);
    this.updateAppliedFiltersCount();
  }

  public clearFilters(): void {
    this.period = { dateFrom: null, dateTo: null };
    this.selectedItemType = null;
    this.selectedIndicators = [];
    this.datePicker.clearAll();
    this.multiSelectors.forEach((selector) => selector.clearSelection());
    this.updateAppliedFiltersCount();
  }

  public handleManualRefreshClick(): void {
    this.manualRefreshEvent.emit();
    this.dateIntervalComponent.updateFormattedTimestamp(this.period);
  }

  public applyPeriod(period: DateTimePeriod): void {
    if (period) {
      this.period = { dateFrom: period.dateFrom || null, dateTo: period.dateTo || null };
    } else {
      this.period = { dateFrom: null, dateTo: null };
    }
    this.emitFilterEvent();
  }

  public applyItemType(itemType: SimilarityItemTypeFilterEnum): void {
    this.selectedItemType = itemType ?? null;
    this.emitFilterEvent();
  }

  public applyIndicators(indicators: SimilarityIndicatorEnum[]): void {
    this.selectedIndicators = indicators ?? [];
    this.emitFilterEvent();
  }

  private initDefaultFilters(): void {
    this.period = { dateFrom: this.defaultFilters.dateTo || null, dateTo: this.defaultFilters.dateTo || null };
    this.selectedItemType = this.convertTypeQueryParamToFilterEnum(this.defaultFilters.type);
    this.selectedIndicators = [];
    if (this.defaultFilters.sameOrganization) {
      this.selectedIndicators.push(SimilarityIndicatorEnum.SAME_ORGANIZATION);
    }
    if (this.defaultFilters.sameUsecase) {
      this.selectedIndicators.push(SimilarityIndicatorEnum.SAME_USECASE);
    }
    if (this.defaultFilters.samePlaybook) {
      this.selectedIndicators.push(SimilarityIndicatorEnum.SAME_PLAYBOOK);
    }
  }

  private initItemTypeSelect(): void {
    this.itemTypeSelectOptions = this.selectFactory.create(SimilarityItemTypeFilterEnum, "respond.similarity.itemType.", this.selectedItemType ?? SimilarityItemTypeFilterEnum.ALL, true);
  }

  private initIndicatorSelect(): void {
    this.indicatorMultiSelectOptions = this.multiSelectFactory.create(SimilarityIndicatorEnum, this.selectedIndicators ?? [], "respond.similarity.indicator.");
  }

  private updateAppliedFiltersCount(): void {
    this.appliedFiltersCount = 0;
    if (this.period?.dateFrom || this.period?.dateTo) {
      this.appliedFiltersCount++;
    }
    if (this.selectedItemType && this.selectedItemType !== SimilarityItemTypeFilterEnum.ALL) {
      this.appliedFiltersCount++;
    }
    if (this.selectedIndicators?.length > 0) {
      this.appliedFiltersCount++;
    }
  }

  private convertTypeQueryParamToFilterEnum(type: SimilarityItemTypeEnum): SimilarityItemTypeFilterEnum {
    if (type === SimilarityItemTypeEnum.ALERTS) {
      return SimilarityItemTypeFilterEnum.ALERT;
    } else if (type === SimilarityItemTypeEnum.INCIDENTS) {
      return SimilarityItemTypeFilterEnum.INCIDENT;
    }
    return SimilarityItemTypeFilterEnum.ALL;
  }

  private convertFilterTypeEnumToQueryParam(type: SimilarityItemTypeFilterEnum): SimilarityItemTypeEnum {
    if (type === SimilarityItemTypeFilterEnum.ALERT) {
      return SimilarityItemTypeEnum.ALERTS;
    } else if (type === SimilarityItemTypeFilterEnum.INCIDENT) {
      return SimilarityItemTypeEnum.INCIDENTS;
    }
    return null;
  }
}
