import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TimeUtil } from "../../services/time-util";
import { parseIntSafe } from "../../services/utils";

interface IPickerValue {
  value: number; // ex: 0-23 or 0-59
  viewValue: string;
}

enum InputTypesEnum {
  HOURS = "HOURS",
  MINUTES = "MINUTES"
}

export type Time = {
  hour: number;
  minute: number;
};

@Component({
  selector: 'ui-time-picker',
  templateUrl: './ui-time-picker.component.html',
  styleUrls: [ './ui-time-picker.component.scss' ],
})
export class UiTimePicker implements OnInit {
  @Input()
  public defaultTimestamp: number; // ex: Date.now() --> 1670962504708

  @Input()
  public defaultHour: number; // ex: 0-23

  @Input()
  public defaultMinute: number; // ex: 0-59

  @Input()
  public isReadonly: boolean = false;

  @Input()
  public isDisabled: boolean = false;

  @Input()
  public shouldOutputAsHourMinuteObject: boolean = false;

  @Output()
  public timeChangedEvent = new EventEmitter<number | Time>(); // set shouldOutputAsHourMinuteObject to true to output hours and minutes

  @ViewChild("hourOptionsRef")
  public hourOptionsRef: ElementRef;

  @ViewChild("minuteOptionsRef")
  public minuteOptionsRef: ElementRef;

  public inputTypes = InputTypesEnum;

  public hour: IPickerValue;
  public minute: IPickerValue;

  public showHourOptions: boolean = false;
  public showMinuteOptions: boolean = false;

  private _hourOptions: IPickerValue[]; // 0-23
  private _minuteOptions: IPickerValue[]; // 0-59
  private _visibleSelectOptions: IPickerValue[];

  constructor() { }

  ngOnInit() {
    this.initPickerOptions();
    this.setDefaultState();
  }

  @HostListener("keydown.enter") 
  confirm() {
    this.handleInputBlur();
  }

  public get currentTimeInMilliseconds(): number {
    const hoursMs = TimeUtil.convertHoursToMilliseconds(this.hour?.value);
    const minutesMs = TimeUtil.convertMinutesToMilliseconds(this.minute?.value);
    return hoursMs + minutesMs;
  }

  public get visibleSelectOptions(): IPickerValue[] {
    return this._visibleSelectOptions;
  }

  public reset(): void {
    this.hour = null;
    this.minute = null;
  }

  public setHour(value: number): void {
    const pickerValue = this._hourOptions.find((hour) => hour.value === value);
    this.hour = pickerValue || this.createPickerValue(0);
    this.scrollVisibleOption();
  }

  public setMinute(value: number): void {
    const pickerValue = this._minuteOptions.find((minute) => minute.value === value);
    this.minute = pickerValue || this.createPickerValue(0);
    this.scrollVisibleOption();
  }

  public setStateFromHourMinute(hour: number, minute: number): void {
    this.setHour(hour);
    this.setMinute(minute);
  }

  public setStateFromTimestamp(timestamp: number): void {
    const parsed = TimeUtil.getHourMinuteFromMilliseconds(timestamp);
    this.setHour(parsed.hour);
    this.setMinute(parsed.minute);
  }

  public setDefaultState(): void {
    const timestamp = parseIntSafe(this.defaultTimestamp);
    if (timestamp) {
      this.setStateFromTimestamp(timestamp);
    } else if (typeof this.defaultHour === "number" && typeof this.defaultMinute === "number") {
      this.setStateFromHourMinute(this.defaultHour, this.defaultMinute);
    } else {
      this.reset();
    }
  }

  public setValueNow(): void {
    const timestampNow = Date.now();
    this.setStateFromTimestamp(timestampNow);
  }

  public setTimestamp(timestamp: number): void {
    this.setStateFromTimestamp(timestamp);
  }

  public handleHourOptionSelected(pickerValue: IPickerValue): void {
    this.setHour(pickerValue.value);
  }

  public handleMinuteOptionSelected(pickerValue: IPickerValue): void {
    this.setMinute(pickerValue.value);
  }

  public handleInputFocus(inputType: InputTypesEnum, event: any): void {
    if (!this.isReadonly) {
      event.target.select();
      if (inputType === InputTypesEnum.HOURS) {
        this.setVisibleSelectOptions(this._hourOptions);
        this.showHourOptions = true;
      } else if (inputType === InputTypesEnum.MINUTES) {
        this.setVisibleSelectOptions(this._minuteOptions);
        this.showMinuteOptions = true;
      }
      setTimeout(() => {
        this.scrollVisibleOption();
      }, 30);
    }
  }

  public hideSelectOptions(): void {
    this.setVisibleSelectOptions(null);
    this.showHourOptions = false;
    this.showMinuteOptions = false;
  }

  public handleInputBlur(): void {
    if (!this.isReadonly) {
      this.hideSelectOptions();
      this.emitTimeChanged();
    }
  }

  private emitTimeChanged(): void {
    if (!!this.shouldOutputAsHourMinuteObject) {
      this.timeChangedEvent.emit({ hour: this.hour.value, minute: this.minute.value });
    } else {
      this.timeChangedEvent.emit(this.currentTimeInMilliseconds);
    }
  }

  private setVisibleSelectOptions(options: IPickerValue[]): void {
    this._visibleSelectOptions = options;
  }

  private scrollVisibleOption(): void {
    let selectedOptionElem: HTMLDivElement;
    const generateSelector = (num: number) => `.time-unit--options-container--option:nth-of-type(${num})`;
    if (this.showHourOptions && this.hour) {
      selectedOptionElem = this.hourOptionsRef?.nativeElement.querySelector(generateSelector(this.hour.value + 1));
    } else if (this.showMinuteOptions && this.minute) {
      selectedOptionElem = this.minuteOptionsRef?.nativeElement.querySelector(generateSelector(this.minute.value + 1));
    }
    selectedOptionElem?.scrollIntoView({ behavior: "auto", block: "nearest" });
  }

  private createPickerValue(value: number): IPickerValue {
    return { value, viewValue: TimeUtil.padZero(value) };
  }

  private createPickerValues(arrayLength: 24 | 60): IPickerValue[] {
    return Array.from(Array(arrayLength).keys()).map((num) => this.createPickerValue(num));
  }

  private initPickerOptions(): void {
    this._hourOptions = this.createPickerValues(24);
    this._minuteOptions = this.createPickerValues(60);
  }
}
