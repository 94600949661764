import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IAssetPropertyConfigRepresentation, IAssetRepresentation } from 'projects/@common/services/api/respond/actifs/actifs.definitions';
import { ActionsToolboxApi } from 'projects/@common/services/api/respond/actions-toolbox/actions-toolbox.api';
import { IExecuteToolboxActionsResponse, IListToolboxActionsResponse, IToolboxAction, IToolboxActionDropdownOption, IToolboxActionExecutionRequest, IToolboxActionParameterUIField, IToolboxActionParameters, IToolboxActionTemplate, IToolboxParameterLabels, IncidentActionsApplicationEnum } from 'projects/@common/services/api/respond/actions-toolbox/actions-toolbox.definition';
import { IncidentTaskStatus } from 'projects/@common/services/api/respond/incidentTasks/incidentTasks.definitions';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { ActionCreatedBy, IDeleteIncidentActionRequest, IDeleteTaskActionsRequest, IDescribeIncidentResponse, IIncidentActionRepresentation, IPostTaskActionsRequest } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { IObservable, ObservableTypeEnum } from 'projects/@common/services/api/respond/observables/observables.definition';
import { getHttpErrorMessage, normalizeString } from 'projects/@common/utils/utils';
import { IObservablesPageContext, ObservablesPageEnum } from 'projects/@respond/observables/components/observable-container/observable-container.component';
import { IAddItemToTaskRequest, IAppendTaskHTMLRequest, IncidentEventsService, IRemoveItemToTaskRequest } from '../../services/incident.events.service';
import { ConfirmationModalComponent } from '@ui-kit/components/confirmation-modal/confirmation-modal.component';
import { IUiMenuOptions } from '@ui-kit/components/ui-menu-options/ui-menu-options.component';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { ModalService } from '@ui-kit/services/modal.service';
import { mapListActionsResponse } from '@common/services/api/respond/actions-toolbox/mapActions.util';
import { Eco } from '@common/definitions/eco';
import { IncidentMappersService } from '../../services/incidentMappers.service';
import { mapEntityRepresentationToDisplayEntity } from '@common/services/api/respond/entities/entities.mapper';
import { ExpendableTypeEnum } from '@ui-kit/components/ui-expendable-section/ui-expendable-section.component';
import { ActionCellService } from '../../services/action.cell.service';
import { Subscription } from 'rxjs';

interface IActionsListFilters {
  searchText: string;
  applications: IncidentActionsApplicationEnum[];
}

enum ToolboxActionsMenuEnum {
  addToActiveTask = "addToActiveTask",
  delete = "delete",
  modifyAndExecute = "modifyAndExecute"
}

@Component({
  selector: 'incident-actions',
  templateUrl: './incident-actions.component.html',
  styleUrls: [ './incident-actions.component.scss' ],
})
export class IncidentActionsComponent implements OnInit {
  @Input() public incident: Incident;
  @Input() public observables: IObservable[];
  @Input() public incidentActions: IIncidentActionRepresentation[]; // list of actions that were executed in this incident
  @Input() public assets: IAssetRepresentation[];
  @Input() public assetTypes: IAssetPropertyConfigRepresentation[];
  @Input() public isReadonly: boolean;

  @Output() public selectedActionChange = new EventEmitter<IToolboxAction>();

  private _actions: IToolboxAction[];

  public isRedFlagged: boolean = false;

  public displayedActionsList: IToolboxAction[];
  public selectedAction: IToolboxAction;
  public isLoadingActions: boolean;
  public appFilterOptions: MultiSelectData;
  public filters: IActionsListFilters;
  public configObservables: IObservablesPageContext;
  public actionObservables: IObservablesPageContext;
  public hasPermissionToExecuteAction: boolean;
  public expendableTypeEnum = ExpendableTypeEnum;
  public menuOptions: IUiMenuOptions[] = [];

  private toggleTaskFindingItemSubscription: Subscription;

  constructor(
    private incidentMappersService: IncidentMappersService,
    private readonly actionsToolboxApi: ActionsToolboxApi,
    private readonly translatedObject: TranslatedObjectPipe,
    private readonly noticeService: NoticeService,
    private readonly displayService: DisplayService,
    private readonly modalService: ModalService,
    private readonly actionCellService: ActionCellService,
    private readonly incidentsApi: IncidentsApi,
    private readonly incidentEventsService: IncidentEventsService,
    private readonly multiSelectFactory: MultiSelectDataFactory,
    private readonly sanitizer: DomSanitizer,
    public readonly i18n: I18nService
  ) { }

  ngOnInit(): void {
    this.hasPermissionToExecuteAction = this.displayService.meetsRequirements({ permissions: [ 'can_execute_toolbox_actions' ] });
    this.menuOptions = [];
    this.fetchActions();
    this.initSearchFilters();
    this.initObservablesConfig();
  }

  /** Listenner for actions added to tasks, as evidences. */
  private subscribeToToggleTaskFindingItem() {
    this.toggleTaskFindingItemSubscription = this.incidentEventsService.toggleTaskMessagesItemObservable$.subscribe((request: IAddItemToTaskRequest | IRemoveItemToTaskRequest) => {
      this.setRedFlagged();
    });
  }

  private setRedFlagged() {
    this.incidentActions = this.actionCellService.setFlaggedIncidentTasks(this.configObservables, this.incidentActions);
  }

  ngOnDestroy() {
    this.toggleTaskFindingItemSubscription?.unsubscribe();
  }

  ngOnChanges(): void {

    for (let incidentAction of this.incidentActions) {
      // Incident action observables config
      if (incidentAction.observables?.length) {
        incidentAction.observablesConfig = {
          pageEnum: ObservablesPageEnum.INCIDENT_TOOLBOX,
          observables: incidentAction.observables,
          viewParams: {
            showCreateButton: false,
            showDeleteButton: false,
            showTitle: true,
            showViewModeSelect: false,
            hideSearch: true,
          }
        }
      }

      // Process entities and get ready to display
      incidentAction.entities = incidentAction.entities?.map((entity) => mapEntityRepresentationToDisplayEntity(entity, this.i18n));
    }

  }

  public get isExecuteActionAvailable(): boolean {
    const { isRunning, resultHtml } = this.selectedAction.executionState;
    return !isRunning && !resultHtml;
  }

  public executeAction() {
    if (!this.hasPermissionToExecuteAction) {
      return;
    }
    if (!this.selectedAction.parameters.every((field) => field.isValid)) {
      this.noticeService.error("incident-actions.actionForm.execute.invalid");
      return;
    }
    this.selectedAction.executionState.isRunning = true;
    const payload: IToolboxActionExecutionRequest = this.getExecuteActionPayload();
    this.actionsToolboxApi.executeAction(payload).subscribe(
      (response: IExecuteToolboxActionsResponse) => {
        this.selectedAction.executionState.resultHtml = this.sanitizeHtml(response.text);
        this.selectedAction.executionState.entities = response.entities?.map((entity) => mapEntityRepresentationToDisplayEntity(entity, this.i18n));
        this.selectedAction.executionState.observables = response.observables;
        this.selectedAction.executionState.isRunning = false;
        this.initActionObservablesConfig();
        this.incidentEventsService.emitActionExecutedEvent([response]);
        this.noticeService.success('incident-actions.execute.success');
        console.log(this.selectedAction.executionState);
      },
      (error) => {
        this.selectedAction.executionState.resultHtml = getHttpErrorMessage(error) ?? "N/A";
        this.selectedAction.executionState.isRunning = false;
        this.noticeService.error('incident-actions.execute.error');
      }
    );


  }

  public openActionForm(action: IToolboxAction) {
    if (this.hasPermissionToExecuteAction) {
      this.setSelectedAction(action);
    }
  }

  public closeActionForm() {
    this.setSelectedAction(null);
  }

  public handleSearchTextFilterChange(value: string) {
    this.filters.searchText = value;
    this.filterActions();
  }

  public handleApplicationFilterChange(value: IncidentActionsApplicationEnum[]) {
    this.filters.applications = value;
    this.filterActions();
  }

  public copyHtmlResultToClipboard(value: string): void {
    try {
      const type = "text/html";
      const blob = new Blob([ value ], { type });
      // @ts-ignore : ClipboardItem is a native API
      const data = [ new ClipboardItem({ [type]: blob }) ];
      // @ts-ignore : clipboard.write is a native API
      navigator.clipboard.write(data);
      this.noticeService.snack("common.action.copied");
    } catch (_) { }
  }

  public copyHtmlResultToActiveTask(value: string): void {
    const taskId = this.incident.tasks.find((incidentTask) => incidentTask.task.status === IncidentTaskStatus.IN_PROGRESS)?.task?.id;
    if (!taskId) {
      this.noticeService.snack("incidents.task.no-active-task");
      return;
    }
    const request: IAppendTaskHTMLRequest = {
      html: value,
      taskId,
    };
    this.incidentEventsService.emitAppendTaskHtmlEvent(request);
  }

  public handleMenuOpened(incidentAction: IIncidentActionRepresentation): void {
    this.initMenuOptions(incidentAction);
  }

  public handleMenuOptionClicked(option: IUiMenuOptions, incidentAction: IIncidentActionRepresentation): void {
    switch (option.id) {
      case ToolboxActionsMenuEnum.addToActiveTask:
        if(!incidentAction.isRedFlagged) {
          this.addToActiveTask(incidentAction);
        }
        else
        {
          this.noticeService.snack('incidents.task.action.already.exists.error');
        }
        break;
      case ToolboxActionsMenuEnum.delete:
        this.deleteActionResult(incidentAction);
        break;
      case ToolboxActionsMenuEnum.modifyAndExecute:
        this.loadExecutedActionForModification(incidentAction);
        break;
      default:
        break;
    }
  }

  private addToActiveTask(action: IIncidentActionRepresentation): void {
    const taskId = this.incident.tasks.find((incidentTask) => incidentTask.task.status === IncidentTaskStatus.IN_PROGRESS)?.task?.id;
    if (!taskId) {
      this.noticeService.snack("incidents.task.no-active-task");
      return;
    }
    const addActionRequest: IPostTaskActionsRequest = {
      incidentId: this.incident.id,
      incidentActionId: action.id,
      incidentTaskId: taskId,
      action: "add",
    };

    this.incidentsApi.postTaskActions(this.incident.organizationId, addActionRequest).subscribe(
      (response: IDescribeIncidentResponse) => {
        this.incident.reloadIncident({
          tasks: response.tasks,
        });
        this.incidentEventsService.emitToggleTaskFindingItem(addActionRequest);
        this.noticeService.success("incidents.task.action.add.success");
      },
      (error) => {
        this.noticeService.error("incidents.task.action.add.error");
      }
    );
  }

  private async promptUserDeleteActionResult(): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18n.translate(`incident-actions.delete.confirm.title`),
        text: this.i18n.translate(`incident-actions.delete.confirm.text`),
        confirmationText: this.i18n.translate('common.boolean.true'),
        cancelText: this.i18n.translate('common.boolean.false'),
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
  }

  private async deleteActionResult(action: IIncidentActionRepresentation): Promise<void> {
    const isConfirmed = await this.promptUserDeleteActionResult();
    if (!isConfirmed) {
      return;
    }

    const deleteIncidentActionRequest: IDeleteIncidentActionRequest = {
      incidentId: this.incident.id,
      incidentActionId: action.id,
    };
    this.incidentsApi.deleteIncidentAction(this.incident.organizationId, deleteIncidentActionRequest).subscribe(
      (response: IDescribeIncidentResponse) => {
        this.incident.reloadIncident({
          tasks: response.tasks,
        });

        // delete this action from the array of results shown on top
        this.incidentActions = this.incidentActions.filter((a) => a.id !== action.id);

        const deleteTaskActionRequest: IDeleteTaskActionsRequest = {
          incidentId: this.incident.id,
          incidentTaskId: null,
          incidentActionId: action.id,
          action: "remove",
        };
        this.incidentEventsService.emitToggleTaskFindingItem(deleteTaskActionRequest);

        this.noticeService.success("incident-actions.delete.success");
      },
      (error) => {
        this.noticeService.error("incident-actions.delete.ERROR");
      }
    );
  }

  private loadExecutedActionForModification(incidentAction: IIncidentActionRepresentation): void {
    const toolboxAction: IToolboxAction = this._actions.find((a) => a.id === incidentAction.action);
    if (toolboxAction) {
      this.setSelectedAction(toolboxAction, incidentAction);
    }
  }

  private initMenuOptions(incidentAction: IIncidentActionRepresentation): void {
    this.menuOptions = [];
    if (!this.isReadonly) {
      this.menuOptions.push({
        id: ToolboxActionsMenuEnum.addToActiveTask,
        iconPath: "assets/favicons/observables/icon_flag_grey.svg",
        translation: this.i18n.translate("observables.menu.flag"),
      });
      this.menuOptions.push({
        id: ToolboxActionsMenuEnum.modifyAndExecute,
        iconPath: "assets/favicons/icon_modify_disabled.svg",
        translation: this.i18n.translate("incident-actions.menu.modifyAndExecute"),
      });
      if (incidentAction.createdBy === ActionCreatedBy.USER) {
        this.menuOptions.push({
          id: ToolboxActionsMenuEnum.delete,
          iconPath: "assets/favicons/icon_delete_gray.svg",
          translation: this.i18n.translate("common.action.delete"),
        });
      }
    }
  }

  private getExecuteActionPayload(): IToolboxActionExecutionRequest {
    if (!this.selectedAction) return null;
    return {
      action: this.selectedAction.id,
      incidentId: this.incident.id,
      inputParameters: this.selectedAction.parameters.filter((input) => !!input.value).reduce((params, input) => {
        params[input.key] = input.value;
        return params;
      }, {}),
    };
  }

  private sanitizeHtml(html: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  private filterActions() {
    const normalizedSearchText = this.filters.searchText ? normalizeString(this.filters.searchText) : null;

    this.displayedActionsList = this._actions.filter((action) => {
      if (normalizedSearchText) {
        const normalizedName = normalizeString(this.translatedObject.transform(action.name));
        if (!normalizedName.includes(normalizedSearchText)) {
          return false;
        }
      }

      if (this.filters.applications.length > 0 && !this.filters.applications.some((app) => action.applications.includes(app))) {
        return false;
      }

      return true;
    });
  }

  private initSearchFilters(): void {
    this.filters = {
      searchText: '',
      applications: [],
    };
    this.initApplicationFilterOptions();
    this.filterActions();
  }

  private initApplicationFilterOptions(actions: IToolboxAction[] = []): void {
    const applications = actions.map((action) => action.applications).flat();
    const uniqueApplications = [ ...new Set(applications) ].sort((a, b) => a.localeCompare(b));
    const options = uniqueApplications.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = currentValue;
      return accumulator;
    }, {});
    this.appFilterOptions = this.multiSelectFactory.create(options, this.filters.applications, "", false);
  }

  private initObservablesConfig() {
    this.configObservables = {
      pageEnum: ObservablesPageEnum.INCIDENT_TASKS_PAGE,
      organizationId: this.incident.organizationId,
      incident: this.incident,
      observables: this.observables,
      viewParams: {
        showCreateButton: false,
        showDeleteButton: false,
        showTitle: true,
        showViewModeSelect: false,
      },
    };
  }

  private initActionObservablesConfig() {
    this.actionObservables = {
      pageEnum: ObservablesPageEnum.INCIDENT_TOOLBOX,
      organizationId: this.incident.organizationId,
      incident: this.incident,
      observables: this.selectedAction.executionState.observables,
      viewParams: {
        showCreateButton: false,
        showDeleteButton: false,
        showTitle: false,
        showViewModeSelect: false,
        hideSearch: true,
      },
    };
  }

  private setSelectedAction(action: IToolboxAction, incidentAction?: IIncidentActionRepresentation) {
    this.selectedAction = action;
    if (!this.selectedAction) {
      return;
    }

    if (incidentAction) {

      // if incidentAction is provided, it means we are modifying an already executed action
      this.selectedAction.executionState = {
        isRunning: false,
        resultHtml: incidentAction.description,
        entities: incidentAction.payload.entityForDisplay,
        // observables: {}
      };
      this.selectedAction.parameters.forEach((param) => {
        if (param.key in incidentAction.parameters) {
          param.value = incidentAction.parameters[param.key];
          param.dropdownOptions.forEach((option) => {
            option.isDefault = option.value === param.value;
          });
        }
      });
    } else {
      this.selectedAction.executionState = {
        isRunning: false,
        resultHtml: null,
      };
    }
    this.selectedActionChange.emit(this.selectedAction);
  }

  private fetchActions() {
    this.isLoadingActions = true;
    this._actions = [];
    const params = { incidentId: this.incident.id };
    this.actionsToolboxApi.listActions(params).subscribe(
      (response) => {
        this._actions = mapListActionsResponse(response, this.i18n.currentLocale as Eco.Languages);
        this.initApplicationFilterOptions(this._actions);
        this.filterActions();
        this.isLoadingActions = false;
        this.setRedFlagged();
        this.subscribeToToggleTaskFindingItem();
      },
      (error) => {
        this.noticeService.error('incident-actions.fetch.error');
      }
    );
  }
}
