import { Component, Input, OnInit } from '@angular/core';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import {
  DatasourceTableIconsComponent
} from 'projects/@common/modules/datasources/components/datasource-table-icons/datasource-table-icons.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IUsecaseTableData } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';

@Component({
  selector: 'app-playbook-detail-usecases',
  templateUrl: './playbook-detail-usecases.component.html',
  styleUrls: [ './playbook-detail-usecases.component.scss' ],
})
export class PlaybookDetailUsecasesComponent implements OnInit {
  @Input()
  public usecases: IUsecaseTableData[];

  @Input()
  public isLoading: boolean;

  public columnsDef = [];

  constructor(
    private translateService: I18nService,
    private readonly i18n: I18nService
  ) {
  }

  ngOnInit(): void {
    this.columnsDef = [
      {
        label: '',
        field: 'icon',
        showIconOnHover: true,
        type: UiStaticTableRowType.EXPENDABLE_ICON,
        noSorting: true,
        width: '45px',
        isResizable: false,
      },
      {
        label: this.translateService.translate('users.list.columns.name.name'),
        field: 'name',
        type: UiStaticTableRowType.TRANSLATED_FIELD,
        width: '45%',
      },
      {
        label: this.translateService.translate('usecases.details.priority'),
        field: 'priority',
        type: UiStaticTableRowType.TEXT,
        width: '100px',
      },
      {
        label: this.translateService.translate('detection.playbook.detail.datasource.title'),
        field: 'datasourcesIcon',
        firstParamData: 'datasourcesIcon',
        type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
        component: DatasourceTableIconsComponent,
        paramName: 'datasourceIcons',
        width: '230px',
      },
    ];
  }

  public get locale(): string {
    return this.i18n.currentLocale;
  }
}
