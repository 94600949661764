import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-permission-grant-confirmation',
  templateUrl: './permission-grant-confirmation.component.html',
  styleUrls: ['./permission-grant-confirmation.component.scss']
})
export class PermissionGrantConfirmationComponent {

  constructor(public dialogRef: MatDialogRef<PermissionGrantConfirmationComponent>) {
  }

  public close(): void {
    this.dialogRef.close();
  }

}
