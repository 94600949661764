import { Component, Input, OnInit } from '@angular/core';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { AlertsApi } from 'projects/@common/services/api/respond/alerts/alerts.api';
import { Alert } from 'projects/@common/services/api/respond/alerts/models/alert';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { ObservablesApi } from 'projects/@common/services/api/respond/observables/observables.api';
import { IListSimilarObservablesRequest, IObservable } from 'projects/@common/services/api/respond/observables/observables.definition';
import { ResponseUtils } from 'projects/@common/utils/response-utils';
import { IObservablesPageContext, ObservablesPageEnum } from 'projects/@respond/observables/components/observable-container/observable-container.component';
import { SimilarityItemTypeEnum, SimilarityPageContext, SimilarityTableColumnEnum, SimilarityTableItem } from '../similarity.definitions';
import { SimilarityActionsComponent } from './similarity-actions/similarity-actions.component';
import { SimilarityIndicatorComponent } from './similarity-indicator/similarity-indicator.component';
import { SimilarityStatusComponent } from './similarity-status/similarity-status.component';

@Component({
  selector: 'similarity-table',
  templateUrl: './similarity-table.component.html',
  styleUrls: [ './similarity-table.component.scss' ],
})
export class SimilarityTableComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() similarityTableData: SimilarityTableItem[];
  @Input() pageContext: SimilarityPageContext;

  public similarityColumnsDef: Array<object>;
  public excludeColumns: SimilarityTableColumnEnum[] = [];
  public configObservables: IObservablesPageContext;

  constructor(
    public readonly i18n: I18nService,
    private readonly incidentsApi: IncidentsApi,
    private readonly alertsApi: AlertsApi,
    private readonly observablesApi: ObservablesApi
  ) { }

  ngOnInit(): void {
    this.initColumns();
  }

  public get isIncidentPageContext(): boolean {
    return this.pageContext.type === SimilarityItemTypeEnum.INCIDENTS;
  }

  public get isAlertPageContext(): boolean {
    return this.pageContext.type === SimilarityItemTypeEnum.ALERTS;
  }

  public isAlertItem(similarityItem: SimilarityTableItem): boolean {
    return similarityItem.type === SimilarityItemTypeEnum.ALERTS;
  }

  public isIncidentItem(similarityItem: SimilarityTableItem): boolean {
    return similarityItem.type === SimilarityItemTypeEnum.INCIDENTS;
  }

  public handleRowClick(similarityItem: SimilarityTableItem): void {
    this.loadDataOnExpand(similarityItem);
  }

  private findItemById(id: string): SimilarityTableItem {
    return this.similarityTableData.find((item) => item.id === id);
  }

  private setAlert(alert: Alert, similarityItemId: string): void {
    const similarityItem = this.findItemById(similarityItemId);
    similarityItem.alert = alert;
    similarityItem.linkURL = `/respond/alerts`;
    similarityItem.linkQueryParams = { shortId: alert.shortId };
  }

  private setIncident(incident: Incident, similarityItemId: string): void {
    const similarityItem = this.findItemById(similarityItemId);
    similarityItem.incident = incident;
    similarityItem.linkURL = `/respond/${incident.organizationEcoId}/incidents/${incident.id}/details`;
    similarityItem.linkQueryParams = null;
  }

  private setObservables(observables: IObservable[], similarityItemId: string): void {
    const similarityItem = this.findItemById(similarityItemId);
    const observablesSimilar = observables.filter((observable) => observable.similar);
    similarityItem.observables = {
      config: {
        pageEnum: ObservablesPageEnum.INCIDENT_SIMILARITY_PAGE,
        organizationId: similarityItem.organizationId,
        alert: null,
        incident: null,
        observables: observables,
        viewParams: {
          showCreateButton: false,
          showDeleteButton: false,
          showViewModeSelect: false,
          showTitle: false,
          hideSearch: true,
          isCollapsedGroupsByDefault: true, // UI performance avoid rendering when collpased
        },
      },
      totalCount: observables.length || 0,
      similarCount: observablesSimilar.length,
      similarTypes: observablesSimilar.map((observable) => observable.type).join(', '),
    };
  }

  private fetchAlert(similarityItem: SimilarityTableItem): void {
    const organizationId = similarityItem.organizationId;
    const alertId = similarityItem.id;
    this.alertsApi.getAlert({ organizationId, alertId }).subscribe((alert) => {
      this.setAlert(alert, similarityItem.id);
    });
  }

  private fetchIncident(similarityItem: SimilarityTableItem): void {
    const organizationId = similarityItem.organizationId;
    const incidentId = similarityItem.id;
    this.incidentsApi.describeIncident(organizationId, incidentId).subscribe((incident) => {
      this.setIncident(incident, similarityItem.id);
    });
  }

  private fetchObservables(similarityItem: SimilarityTableItem): void {
    if (this.isIncidentPageContext) {
      const observablesRequest: IListSimilarObservablesRequest = {
        similarItemOrganizationId: similarityItem.organizationId,
        similarItemId: similarityItem.id,
        similarItemType: similarityItem.type,
        incidentId: this.pageContext.incident.id,
      };
      this.observablesApi.listIncidentObservablesSimilarity(observablesRequest).subscribe((response: ResponseUtils<IObservable>) => {
        const observables = response.getItems();
        this.setObservables(observables, observablesRequest.similarItemId);
      });
    } else if (this.isAlertPageContext) {
      const observablesRequest: IListSimilarObservablesRequest = {
        similarItemOrganizationId: similarityItem.organizationId,
        similarItemId: similarityItem.id,
        similarItemType: similarityItem.type,
        alertId: this.pageContext.alert.id,
      };
      this.observablesApi.listAlertObservablesSimilarity(observablesRequest).subscribe((response: ResponseUtils<IObservable>) => {
        const observables = response.getItems();
        this.setObservables(observables, observablesRequest.similarItemId);
      });
    }
  }

  private loadDataOnExpand(similarityItem: SimilarityTableItem): void {
    if (this.isAlertItem(similarityItem) && similarityItem.alert === undefined) {
      this.fetchAlert(similarityItem);
    }

    if (this.isIncidentItem(similarityItem) && similarityItem.incident === undefined) {
      this.fetchIncident(similarityItem);
    }

    if (similarityItem.observables === undefined) {
      this.fetchObservables(similarityItem);
    }
  }

  private initColumns(): void {
    const allColumns = [
      {
        label: '',
        field: 'icon',
        type: UiStaticTableRowType.EXPENDABLE_ICON,
        showIcon: true,
        width: '24px',
        minWidth: '24px',
        maxWidth: '24px',
        noSorting: true,
        isResizable: false,
      },
      {
        label: this.i18n.translate('common.id'),
        field: SimilarityTableColumnEnum.SHORT_ID,
        type: UiStaticTableRowType.TEXT,
        width: '152px',
        minWidth: '152px',
        isResizable: false,
        noSorting: true,
      },
      {
        label: this.i18n.translate('common.name'),
        field: SimilarityTableColumnEnum.NAME,
        width: '38%',
        minWidth: '100px',
        type: UiStaticTableRowType.TEXT,
        noSorting: true,
        isResizable: false,
      },
      {
        label: this.i18n.translate('common.status'),
        field: SimilarityTableColumnEnum.STATUS,
        width: '92px',
        minWidth: '70px',
        type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
        component: SimilarityStatusComponent,
        paramName: 'similarityItem',
        noSorting: true,
        isResizable: false,
      },
      {
        label: this.i18n.translate('common.indicators'),
        field: SimilarityTableColumnEnum.INDICATORS,
        width: '96px',
        minWidth: '96px',
        type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
        component: SimilarityIndicatorComponent,
        paramName: 'similarityItem',
        isResizable: false,
        noSorting: true,
      },
      {
        label: this.i18n.translate('common.type'),
        field: SimilarityTableColumnEnum.TYPE_TRANSLATION,
        width: "70px",
        minWidth: "69px",
        type: UiStaticTableRowType.TEXT,
        noSorting: true,
        isResizable: false,
      },
      {
        label: this.i18n.translate('common.score'),
        field: SimilarityTableColumnEnum.SCORE,
        width: "60px",
        minWidth: '60px',
        type: UiStaticTableRowType.TEXT,
        noSorting: true,
        isResizable: false,
      },
      {
        label: this.i18n.translate('common.organization'),
        field: SimilarityTableColumnEnum.ORGANIZATION_NAME,
        minWidth: '100px',
        type: UiStaticTableRowType.TEXT,
        noSorting: true,
        isResizable: false,
      },
      {
        label: '',
        field: SimilarityTableColumnEnum.ACTIONS,
        type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
        component: SimilarityActionsComponent,
        paramName: "similarityItem",
        secondParamName: 'pageContext',
        secondParamValue: this.pageContext,
        width: "49px",
        minWidth: '49px',
        showWhenHover: false,
        noSorting: true,
        isResizable: false,
      },
    ];

    if (this.pageContext.type === SimilarityItemTypeEnum.ALERTS) {
      this.excludeColumns.push(SimilarityTableColumnEnum.TYPE_TRANSLATION);
      this.excludeColumns.push(SimilarityTableColumnEnum.ORGANIZATION_NAME);
    }

    this.similarityColumnsDef = allColumns.filter((column) => !this.excludeColumns.includes(column.field as SimilarityTableColumnEnum));
  }
}
