import { Pipe, PipeTransform } from '@angular/core';
import { TimeUtil } from '@ui-kit/services/time-util';

enum TimeFormatEnum {
  MINUTES,
  HOURS
}
@Pipe({
  name: 'timeDurationUnits',
})
export class TimeDurationUnitsPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value)) return 'N/A';
    const units = [];
    const remainingHours = Math.floor(value / TimeUtil.hourDuration);
    const remainingMinutes = Math.floor((value - (remainingHours * TimeUtil.hourDuration)) / TimeUtil.minuteDuration);
    const remainingSeconds = Math.floor(value - (remainingHours * TimeUtil.hourDuration) - (remainingMinutes * TimeUtil.minuteDuration)) / TimeUtil.secondDuration;

    if (remainingHours) {
      units.push(remainingHours.toString()?.padStart(2, '0'));
    }

    if (remainingMinutes) {
      units.push(Math.round(remainingMinutes).toString()?.padStart(2, '0'));
    }
    // Always display smallest unit
    units.push(Math.round(remainingSeconds)?.toString()?.padStart(2, '0'));
    return units.join(':');
  }
}
