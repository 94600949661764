<div
  page
  [appPagePaths]="['mdRespond', 'usecases']"
  [hasTopSection]="true"
  [tooltipTitle]="'detection.usecase.inventory.page.title'"
  [searchMode]="searchMode"
  (organizationChanged)="organizationChanged($event)"
>
  <ng-container topSection>
    <usecase-inventory-top-section
      *ngIf="organization"
      [loading]="loading"
      [usecaseTotalQuantity]="usecaseTotal"
      [usecaseTotalSubscribed]="organization?.subscribedUsecases"
    ></usecase-inventory-top-section>
  </ng-container>

  <span
        *ngIf="isVarMode && !organization"
        class="no-org-transition"
        >
        {{ 'organizations_select.notSelected.message' | t }}
  </span>

  <inventory-layout
    *ngIf="organization"
    [actionSpaced]="false"
  >
    <ng-container actions>
    </ng-container>

    <ng-container>
      <usecase-inventory-body-section
        [ucInventoryAction]="ucInventoryAction"
        [datasources]="listData"
        [loading]="ucInventoryAction?.isLoading"
        [filters]="filters"
      >
      </usecase-inventory-body-section>
    </ng-container>
  </inventory-layout>
</div>
