import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayService } from '@common/modules/display/display.service';
import { Store } from '@ngxs/store';
import { ConfirmationModalComponent } from '@ui-kit/components/confirmation-modal/confirmation-modal.component';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { UiTab } from '@ui-kit/components/ui-tabs/ui-tab.component';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { ModalService } from '@ui-kit/services/modal.service';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SearchMode } from 'projects/@common/modules/layout/components/page/page.component';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ConnectorsApiOrganizationService } from 'projects/@common/services/api/detect/organizations/connectors-api-organizations';
import { DescribeOrganizationResponse } from 'projects/@common/services/api/detect/organizations/definitions';
import { PlaybooksApiService } from 'projects/@common/services/api/respond/playbooks/playbooks.api';
import { IPlaybookTableObject, IPlaybookTemplate, PlaybookOrderByEnum } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { PlaybookInstanceSelectorPopupComponent, PlaybookInstanceSelectorPopupInput } from '../../components/playbook-instance-selector-popup/playbook-instance-selector-popup.component';
import { PlaybooksMapper } from '../../mappers/playbooks.mapper';
import { PlaybookListTabsHandlerService } from './playbook-list-tabs-handler.actions';
import { PlaybookDisplayTemplateStatusFilter, PlaybookTemplateStatusFilter, PlaybooksInventoryContainerActions } from './playbooks.actions';
import { PlaybooksCatalogContainerActions } from './playbooksCatalog.actions';

export interface IPlaybookListViewParams {
  isReadonly: boolean;
  showOutdatedCard: boolean;
  showFilterStatus: boolean;
  tableColumns: Array<Object>;
  showCatalog: boolean;
  catalogTableColumns: Array<Object>;
}

@Component({
  selector: 'respond-playbook-list',
  templateUrl: './respond-playbook-list.component.html',
  styleUrls: [ './respond-playbook-list.component.scss' ],
})
export class RespondPlaybookListComponent implements OnInit {
  @Input()
  public viewParams: IPlaybookListViewParams;

  public organizations: DescribeOrganizationResponse[] = [];

  public activeOrganization: DescribeOrganizationResponse | null = null;

  public organizationLoaded = false;

  public deleteDialogOpened = false;

  public isDeleting = false;

  public readonly pbInventoryAction: PlaybooksInventoryContainerActions;
  public readonly pbCatalogAction: PlaybooksCatalogContainerActions;
  public readonly dataFilter: MultiSelectData;
  public readonly listTabsColor: UiTabsColor = UiTabsColor.ListAction;
  public playbooksCatalog: (IPlaybookTemplate & { isOwnedByUser: boolean; })[] = [];

  public canCreatePlaybook: boolean = false;
  public canDeletePlaybook: boolean = false;
  public canUpdatePlaybook: boolean = false;
  public canDescribePlaybook: boolean = false;
  public searchMode = SearchMode.RESPOND;

  private isVarMode: boolean = false;

  constructor(
    playbooksMapper: PlaybooksMapper,
    multiSelectFactory: MultiSelectDataFactory,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    private readonly playbooksApiService: PlaybooksApiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly zone: NgZone,
    public readonly i18n: I18nService,
    private readonly displayService: DisplayService,
    private readonly modalService: ModalService,
    private readonly store: Store,
    public readonly tabsHandler: PlaybookListTabsHandlerService
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
    this.pbInventoryAction = new PlaybooksInventoryContainerActions(playbooksApiService, playbooksMapper, i18n);
    this.pbCatalogAction = new PlaybooksCatalogContainerActions(playbooksApiService);
    this.dataFilter = multiSelectFactory.create(PlaybookTemplateStatusFilter, [], 'detection.playbook.filter.');
  }

  async ngOnInit(): Promise<void> {
    this.organizationLoaded = true;
    this.canCreatePlaybook = !this.viewParams.isReadonly && this.displayService.meetsRequirements({ permissions: [ "can_create_playbook" ] });
    this.canUpdatePlaybook = !this.viewParams.isReadonly && this.displayService.meetsRequirements({ permissions: [ "can_update_playbook" ] });
    this.canDeletePlaybook = !this.viewParams.isReadonly && this.displayService.meetsRequirements({ permissions: [ "can_delete_playbook" ] });
    this.canDescribePlaybook = this.displayService.meetsRequirements({ permissions: [ "can_describe_playbook" ] });

    if (!this.isVarMode) {
      await this.handleRefresh();
    }
    await this.handlePlaybookCatalogInit();
  }

  public get showTable(): boolean {
    return !this.isVarMode || !!this.activeOrganization;
  }

  public get showOutdatedCard(): boolean {
    return this.viewParams.showOutdatedCard && !!this.activeOrganization;
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    if (org) {
      this.activeOrganization = await this.connectorsApiOrganizationService.describeOrganization(org.id);
      await this.pbInventoryAction.handleSelectedOrganization(this.activeOrganization.organization_respond_id);
    } else {
      this.pbInventoryAction.resetPlaybooks();
      this.activeOrganization = null;
    }
    await this.handlePlaybookCatalogInit();
    this.cdr.detectChanges();
  }

  public openPlaybookDetails(playbook: IPlaybookTableObject): void {
    if (this.canDescribePlaybook) {
      const path = `respond/${playbook.organizationEcoId}/playbooks/${playbook.id}/detail`;
      this.zone.run(() => {
        this.router.navigateByUrl(path, {
          state: {
            organizationName: playbook.organizationName,
          },
        });
      });
    }
  }

  public openPlaybookTemplateDetails(playbookTemplate: IPlaybookTemplate): void {
    if (this.canDescribePlaybook) {
      const path = `respond/playbooks/templates/${playbookTemplate.playbookTemplateId}/detail`;
      this.zone.run(() => {
        this.router.navigateByUrl(path, {
          state: {
            template: playbookTemplate,
          },
        });
      });
    }
  }

  public catalogTableItemClickHandler(playbookTemplate: IPlaybookTemplate & { isOwnedByUser: boolean; }) {
    if (playbookTemplate.isOwnedByUser) {
      const playbooks = this.pbInventoryAction.findPlaybooksTableFromTemplate(playbookTemplate.id);
      if (playbooks.length === 1) {
        this.openPlaybookDetails(playbooks[0]);
      } else if (playbooks.length > 1) {
        this.openPlaybookInstanceSelector(playbooks, playbookTemplate.name[this.i18n.currentLocale]);
      }
    } else {
      this.openPlaybookTemplateDetails(playbookTemplate);
    }
  }

  public openCreateDialog(): void {
    if (this.canCreatePlaybook) {
      if (this.activeOrganization !== null) {
        const path = `respond/${this.activeOrganization.organization_eco_id}/playbooks/creation`;
        this.zone.run(() => {
          this.router.navigateByUrl(path, {
            state: {
              organizationName: this.activeOrganization.name,
            },
          });
        });
      } else {
        this.zone.run(() => {
          this.router.navigate([ '.' ], { relativeTo: this.route.parent });
        });
      }
    }
  }

  public async deletePlaybook(): Promise<void> {
    if (this.canDeletePlaybook) {
      const playbookId = this.pbInventoryAction.currentSelectedPlaybook.id;
      const playbookName = this.pbInventoryAction.currentSelectedPlaybook?.name?.fr || this.pbInventoryAction.currentSelectedPlaybook?.name?.en;
      const isConfirmed = await this.promptUserDeletePlaybook(playbookName);
      if (isConfirmed) {
        this.isDeleting = true;
        try {
          await this.playbooksApiService.deletePlaybook(this.activeOrganization.organization_respond_id, playbookId);
        } finally {
          this.isDeleting = false;
          this.modalService.closeDialog();
          await this.handleRefresh();
        }
      }
    }
  }

  public displayOutdatedTemplateFilter(playbookToShow: string[]) {
    if (!playbookToShow) {
      this.pbInventoryAction.setDisplayFilter(PlaybookDisplayTemplateStatusFilter.ALL);
    }
    this.pbInventoryAction.setDisplayFilter(playbookToShow[0] as PlaybookDisplayTemplateStatusFilter);
  }

  public handleSorting(sort: PlaybookOrderByEnum, direction: UiTableDirection) {
    this.pbInventoryAction.sortOrder = sort;
    this.pbInventoryAction.sortDirection = direction;
    this.handleRefresh();
  }

  public playbookTabClick(tab: UiTab): void {
    if (tab.tabTitle === this.i18n.translate('detection.playbook.list.tab.catalog.title')) {
      this.tabsHandler.selectedTabIndex = 1;
    } else {
      this.tabsHandler.selectedTabIndex = 0;
    }
  }

  private async handleRefresh(): Promise<void> {
    this.cdr.detectChanges();
    await this.pbInventoryAction.fetchPlaybooks();
    this.pbInventoryAction.currentSelectedPlaybook = null;
    await this.pbCatalogAction.fetchPlaybooksCatalog();
  }

  private async promptUserDeletePlaybook(playbookName: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18n.translate('detection.playbook.list.delete.warning.title'),
        text: this.i18n.translate('detection.playbook.list.delete.warning', { playbook: playbookName }),
        confirmationText: this.i18n.translate('common.action.delete'),
        cancelText: this.i18n.translate('common.action.cancel'),
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
  }

  private async handlePlaybookCatalogInit(): Promise<void> {
    this.viewParams.catalogTableColumns = this.viewParams.catalogTableColumns.map((column) => {
      column['label'] = this.i18n.translate(column['label']);
      column['noSorting'] = true;
      if (column['tooltipText']) {
        column['tooltipText'] = this.i18n.translate(column['tooltipText']);
      }
      return column;
    });

    await this.pbCatalogAction.playbooksCatalog.subscribe(async (catalog) => {
      this.playbooksCatalog = await Promise.all(catalog.map((playbookTemplate) => {
        const hasTemplate = !!this.activeOrganization ? this.pbInventoryAction.hasPlaybookTemplate(playbookTemplate.id) : false;
        return {
          ...playbookTemplate,
          isOwnedByUser: hasTemplate,
        };
      }));
    });
    if (this.playbooksCatalog.length <= 0) {
      await this.pbCatalogAction.fetchPlaybooksCatalog();
    }
  }

  private openPlaybookInstanceSelector(playbooks: IPlaybookTableObject[], playbookTemplateName: string): void {
    const modalParams: PlaybookInstanceSelectorPopupInput = {
      playbooks: playbooks,
      playbookTemplateName: playbookTemplateName,
    };
    const dialogRef: MatDialogRef<PlaybookInstanceSelectorPopupComponent> = this.modalService.openDialog(PlaybookInstanceSelectorPopupComponent, modalParams, true);
    dialogRef.componentInstance.successEvent.subscribe((result) => {
      this.modalService.closeDialog();
      this.openPlaybookDetails(result);
    });
    dialogRef.componentInstance.cancelEvent.subscribe((_) => this.modalService.closeDialog());
  }
}
