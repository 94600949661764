import { HttpClient } from "@angular/common/http";
import { ITranslatedField } from "@ui-kit/definitions/ITranslatedField";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { IAlert } from "projects/@common/services/api/respond/alerts/alerts.definitions";
import { GetIncidentAlertsRequest } from "projects/@common/services/api/respond/alerts/models/getIncidentAlertsRequest";
import { IEntityRepresentation } from "projects/@common/services/api/respond/entities/entities.definition";
import { IncidentDescription, IncidentTaskStatus } from "projects/@common/services/api/respond/incidentTasks/incidentTasks.definitions";
import {
  CreateIncidentTimestampRequest,
  DeleteIncidentTimestampRequest,
  IAddIncidentAnalystRequest,
  IComputedEscalation,
  ICreateIncidentRequest,
  IDeleteIncidentActionRequest,
  IDeleteIncidentAnalystRequest,
  IDeleteTaskActionsRequest,
  IDeleteTaskEntitiesRequest,
  IDeleteTaskObservablesRequest,
  IDescribeIncidentResponse,
  IExportIncidentsResponse,
  IIncident,
  IIncidentActionRepresentation,
  IIncidentApi,
  IIncidentStats,
  IListEscalationParametersRequest,
  IListIncidentRequest,
  IPostTaskActionsRequest,
  IPostTaskEntitiesRequest,
  IPostTaskObservablesRequest,
  IRemoveAlertFromIncidentResponse,
  IUpdateIncidentPriorityRequest,
  IUpdateIncidentRequest,
  IUpdateIncidentStatusRequest,
  IncidentTaskPhases,
  ListIncidentSimilarItemsRequest,
  ListOwnersResponse,
  UpdateIncidentTimestampRequest
} from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { IncidentStatsHistoryDto, IncidentStatsHistoryRequest } from "projects/@common/services/api/respond/incidents/models/getIncidentStatsHistory.definition";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IncidentsStats } from "projects/@common/services/api/respond/incidents/models/incidentsStats";
import { IObservable } from "projects/@common/services/api/respond/observables/observables.definition";
import { Whitelist } from "projects/@common/services/api/respond/whitelists/models/Whitelist";
import { IWhitelistRepresentation } from "projects/@common/services/api/respond/whitelists/whitelists.definitions";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { UrlUtils } from "projects/@common/utils/utils";
import { SimilarityItem } from "projects/@respond/components/similarity/similarity.definitions";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class CustomerIncidentsApiService extends EcoApi implements IIncidentApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listIncidents(request: IListIncidentRequest): Observable<ResponseUtils<IIncident>> {
    const url = `${this.host}/me/incidents?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IIncident>>(url)
      .pipe(map((response) => new ResponseUtils<IIncident>(response)));
  }

  public getOwners(): Observable<ListOwnersResponse[]> {
    const url = `${this.host}/me/incidents/owners`;
    return this.http.get<ListOwnersResponse[]>(url);
  }

  public getIncidentsStats(): Observable<IncidentsStats[]> {
    const url = `${this.host}/me/incidents/stats`;
    return this.http.get<IIncidentStats[]>(url);
  }

  public getIncidentsStatsHistory(request: IncidentStatsHistoryRequest): Promise<IncidentStatsHistoryDto[]> {
    const url = `${this.host}/me/incidents/stats/history?${UrlUtils.jsonToQueryParams(request.params)}`;
    return this.http.get<IncidentStatsHistoryDto[]>(url).toPromise();
  }

  public describeIncident(organizationId: string, incidentId: string): Observable<Incident> {
    const url = `${this.host}/me/incidents/${incidentId}`;
    return this.http.get<IDescribeIncidentResponse>(url).pipe(map((response) => new Incident({
      ...response.incident,
      ...response.organization,
      tasks: response.tasks,
      firstAlertTimestamp: response.firstAlertTimestamp,
      timestamps: response.timestamps,
      phaseTimestamps: response.phaseTimestamps,
      resolveIncidentActions: response.resolveIncidentActions,
      reportAvailable: response.reportAvailable,
      whitelistedAlertCount: response.whitelistedAlertCount,
      whitelists: response?.whitelists?.map((whitelist: IWhitelistRepresentation) => new Whitelist(whitelist)) || [],
      analysts: response.analysts,
      usecaseIds: response?.usecaseIds,
    })));
  }

  public describeDraftIncidentEscalationSchedule(organizationId: string, incidentId: string, escalation = false): Observable<IComputedEscalation> {
    const url = escalation ? `${this.host}/me/incidents/${incidentId}/escalationDraft?${UrlUtils.jsonToQueryParams(escalation)}` : `${this.host}/me/incidents/${incidentId}/escalationDraft`;
    return this.http.get<IComputedEscalation>(url);
  }

  public describeIncidentEscalationSchedule(organizationId: string, incidentId: string): Observable<IComputedEscalation> {
    const url = `${this.host}/me/incidents/${incidentId}/escalation`;
    return this.http.get<IComputedEscalation>(url);
  }

  // not used and unauthorized for an org user
  public startConversation(organizationId: string, incidentId: string, content: string, escalation = false): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/me/incidents/${incidentId}/notify`;
    return this.http.post<any>(url, { incidentId, content, escalation });
  }

  public createIncident(request: ICreateIncidentRequest): Promise<IIncident> {
    throw new Error('Not implemented in console-org');
  }

  public updateIncident(organizationId: string, request: IUpdateIncidentRequest): Observable<IDescribeIncidentResponse> {
    throw new Error('Not implemented in console-org');
  }

  public getIncidentFromAlert(organizationId: string, alertId: string): Promise<IDescribeIncidentResponse> {
    throw new Error('Not implemented in console-org');
  }

  public getAlertsFromIncident(request: GetIncidentAlertsRequest): Observable<ResponseUtils<IAlert>> {
    const url = `${this.host}/me/incidents/${request.incidentId}/alerts?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`;
    return this.http.get<IRespondResponse<IAlert>>(url).pipe(map((response) => new ResponseUtils<IAlert>(response)));
  }

  public associateAlerts(organizationId: string, incidentId: string, alertIds: string[], comment?: string): Promise<IIncident> {
    throw new Error('Not implemented in console-org');
  }

  public dissociateAlert(organizationId: string, alertId: string): Promise<IRemoveAlertFromIncidentResponse> {
    throw new Error('Not implemented in console-org');
  }

  public updateIncidentTimestamp(organizationId: string, request: UpdateIncidentTimestampRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/me/incidents/${request.incidentId}/timestamps/${request.id}`;
    return this.http.put<IDescribeIncidentResponse>(url, request);
  }

  public createIncidentTimestamp(organizationId: string, request: CreateIncidentTimestampRequest): Observable<IDescribeIncidentResponse> {
    throw new Error('Not implemented in console-org');
  }

  public deleteIncidentTimestamp(organizationId: string, request: DeleteIncidentTimestampRequest): Observable<IDescribeIncidentResponse> {
    throw new Error('Not implemented in console-org');
  }

  public updateIncidentStatus(organizationId: string, request: IUpdateIncidentStatusRequest): Observable<IDescribeIncidentResponse> {
    throw new Error('Not implemented in console-org');
  }

  public updateIncidentPriority(organizationId: string, request: IUpdateIncidentPriorityRequest): Observable<IDescribeIncidentResponse> {
    throw new Error("Method not implemented.");
  }

  public addIncidentAnalyst(organizationId: string, request: IAddIncidentAnalystRequest): Observable<IDescribeIncidentResponse> {
    throw new Error("Method not implemented.");
  }

  public deleteIncidentAnalyst(organizationId: string, request: IDeleteIncidentAnalystRequest): Observable<IDescribeIncidentResponse> {
    throw new Error("Method not implemented.");
  }

  public exportIncidents(request: IListIncidentRequest): Observable<ResponseUtils<IExportIncidentsResponse>> {
    const params = UrlUtils.jsonToQueryParams(request);
    const url = `${this.host}/me/incidents/export?${params}`;
    return this.http.get<IRespondResponse<IExportIncidentsResponse>>(url)
      .pipe(map((response) => new ResponseUtils<IExportIncidentsResponse>(response)));
  }

  public listSimilarItems(organizationId: string, request: ListIncidentSimilarItemsRequest): Observable<ResponseUtils<SimilarityItem>> {
    throw new Error("Method not implemented.");
  }

  public updateIncidentPhaseStatus(organizationId: string, request: { incidentId: string, phase: IncidentTaskPhases, status: IncidentTaskStatus; organizationId: string }): Observable<IncidentDescription> {
    throw new Error("Method not implemented.");
  }

  public updateTaskStatus(organizationId: string, request: { incidentId: string, id: string, status: IncidentTaskStatus; }): Observable<IncidentDescription> {
    const url = `${this.host}/me/incidents/${request.incidentId}/tasks/${request.id}/status`;
    return this.http.put<IncidentDescription>(url, request);
  }

  public addIncidentTask(organizationId: string, request: { playbookTaskId: string; version: number; incidentId: string; taskId: string; before: boolean; }): Observable<IncidentDescription> {
    throw new Error("Method not implemented.");
  }

  public moveIncidentTask(organizationId: string, request: { sourceTaskId: string; incidentId: string; destinationTaskId: string; before: boolean; }): Observable<IncidentDescription> {
    throw new Error("Method not implemented.");
  }

  public updateCustomTask(organizationId: string, request: { incidentId: string, taskId: string, name: ITranslatedField, description: ITranslatedField, instruction: ITranslatedField }): Observable<IncidentDescription> {
    throw new Error("Method not implemented.");
  }

  public deleteIncidentTask(organizationId: string, request: { incidentId: string, taskId: string }): Observable<IncidentDescription> {
    throw new Error("Method not implemented.");
  }

  public updateTask(organizationId: string, request: { incidentId: string, id: string, comment: string; }): Observable<IncidentDescription> {
    const url = `${this.host}/me/incidents/${request.incidentId}/tasks/${request.id}`;
    return this.http.put<IncidentDescription>(url, request);
  }

  public listEscalationParameters(request: IListEscalationParametersRequest): Observable<any> {
    const url = `${this.host}/organizations/${request.organizationId}/escalation/parameters`;
    return this.http.get<any>(url);
  }

  public listIncidentActions(organizationId: string, incidentId: string): Observable<ResponseUtils<IIncidentActionRepresentation>> {
    const url = `${this.host}/me/incidents/${incidentId}/actions`;
    return this.http.get<IRespondResponse<IIncidentActionRepresentation>>(url).pipe(map((response) => new ResponseUtils<IIncidentActionRepresentation>(response)));
  }

  public listIncidentObservables(organizationId: string, incidentId: string): Observable<ResponseUtils<IObservable>> {
    const url = `${this.host}/me/incidents/${incidentId}/observables`;
    return this.http.get<IRespondResponse<IObservable>>(url).pipe(map((response) => new ResponseUtils<IObservable>(response)));
  }

  public listIncidentEntities(organizationId: string, incidentId: string): Observable<ResponseUtils<IEntityRepresentation>> {
    const url = `${this.host}/me/incidents/${incidentId}/entities`;
    return this.http.get<IRespondResponse<IEntityRepresentation>>(url).pipe(map((response) => new ResponseUtils<IEntityRepresentation>(response)));
  }

  public postTaskObservables(organizationId: string, request: IPostTaskObservablesRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/me/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/observables`;
    return this.http.post<IDescribeIncidentResponse>(url, request);
  }

  public postTaskEntities(organizationId: string, request: IPostTaskEntitiesRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/me/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/entities`;
    return this.http.post<IDescribeIncidentResponse>(url, request);
  }

  public postTaskActions(organizationId: string, request: IPostTaskActionsRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/me/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/actions`;
    return this.http.post<IDescribeIncidentResponse>(url, request);
  }

  public deleteTaskObservables(organizationId: string, request: IDeleteTaskObservablesRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/me/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/observables/${request.incidentObservableId}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public deleteTaskEntities(organizationId: string, request: IDeleteTaskEntitiesRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/me/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/entities/${request.incidentEntityId}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public deleteTaskActions(organizationId: string, request: IDeleteTaskActionsRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/me/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/actions/${request.incidentTaskId}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public deleteIncidentAction(organizationId: string, request: IDeleteIncidentActionRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/me/incidents/${request.incidentId}/actions/${request.incidentActionId}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public updateIncidentEscalationSchedule(organizationId: string, incidentId: string): Observable<void> {
    const url = `${this.host}/me/incidents/${incidentId}/escalation`;
    return this.http.put<void>(url, {});
  }
}
