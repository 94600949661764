import { Component, Input } from '@angular/core';

@Component({
  selector: 'profile-table-users-count',
  templateUrl: './profile-table-users-count.component.html',
  styleUrls: [ './profile-table-users-count.component.scss' ],
})
export class ProfileTableUsersCountComponent {
  @Input() profile: any;
}
