<div class="usecase-catalog-report-choose-language-modal">

  <ui-title
    class="usecase-catalog-report-choose-language-modal--title"
    [name]="'usecaseCatalog.report.language' | t"
  ></ui-title>

  <br>
  <span>{{ 'usecaseCatalog.report.warning' | t}}</span>

  <div class="language-selector">
    <ng-container *ngFor="let option of languageOptions; let last = last">
      <p
       [ngClass]="option.selected ? 'language-option language-option-selected': 'language-option'"
       (click)="setLanguage(option)">
        <img class="icon-lang" src="assets/favicons/icon_lang.svg">
        {{ 'common.language.' + option.language | t }}
      </p>
      <p *ngIf="!last" class="language-selector--separator"> </p>
    </ng-container>
  </div>
  

  <div class="usecase-catalog-report-choose-language-modal--buttons">
    <button
      ui-button
      color="standard"
      [disabled]="selectedLanguage === null"
      (click)="generateCatalog()"
    >
      {{ 'usecaseCatalog.report.generate' | t }}
    </button>
    <button
      ui-button
      color="secondary"
      (click)="cancel()"
    >
      {{ 'common.action.cancel' | t }}
    </button>
  </div>

</div>