import { Stages } from "@common/definitions/stages";
import { environment } from "@common/environments/environment";
import { DisplayRequirements } from "projects/@common/modules/display/display.service";
import { BaseSidebar, navigationElement, previewModeEnum } from "projects/@common/modules/layout/definitions/base-sidebar.service";
export class AdmRespondSidebar extends BaseSidebar {
  public static _showCreateAlert = false;

  private elements: navigationElement[] = [
    { name: 'MD.RESPOND' },
    {
      rootAction: true,
      name: 'respond.dashboard.title',
      icon: 'icon_admin_cyber_behavior',
      route: '/respond/dashboard',
      permissions: [ 'can_list_incidents' ],
    },
    {
      rootAction: true,
      name: 'sidebar.navigation.respond.usecases',
      icon: 'icon_menu_usecase',
      route: '/respond/usecases',
      permissions: [ 'can_list_usecases', 'can_list_usecase_templates' ],
    },
    {
      rootAction: true,
      name: 'sidebar.navigation.respond.datasources',
      icon: 'icon_admin_menu_datasources',
      route: '/respond/datasources',
      permissions: [ 'can_list_datasources' ],
    },
    {
      rootAction: true,
      name: 'sidebar.navigation.respond.playbooks',
      icon: 'sidebar/icon_admin_menu_playbook',
      route: '/respond/playbooks',
      permissions: [ 'can_list_playbooks' ],
    },
    {
      name: 'sidebar.navigation.respond.alerts.name',
      icon: 'icon_admin_menu_alerts',
      route: '/respond/alerts',
      permissions: [ 'can_list_alerts' ],
      rootAction: true,
    },
    {
      name: 'sidebar.navigation.respond.incidents.name',
      icon: 'icon_menu_incident',
      route: '/respond/incidents',
      permissions: [ 'can_list_incidents' ],
      rootAction: true,
    },
    {
      name: 'sidebar.navigation.respond.tools',
      icon: 'sidebar/icon_sidebar_tools',
      orPermissions: [ 'can_read_custom_queries', 'can_read_respond_reports', 'can_get_kpis', 'can_read_histories' ],
      rootAction: false,
      preview: previewModeEnum.BETA,
      children: this.getToolsChildren(),
    },
    {
      name: 'sidebar.navigation.respond.configs',
      icon: 'sidebar/icon_sidebar_config',
      orPermissions: [ 'can_list_assets', 'can_list_whitelists', 'can_list_filters', 'can_list_respond_organizations', 'can_read_escalation' ],
      rootAction: false,
      preview: previewModeEnum.BETA,
      children: this.getConfigsChildren(),
    },
  ];

  constructor() {
    super();
  }

  public getElements(): any[] {
    return this.elements;
  }

  public unsubscribe(): void {
  }

  public getRequirements(): DisplayRequirements {
    return {};
  }

  private getToolsChildren(): navigationElement[] {
    const out = [
      {
        name: 'respond.reports.title',
        route: '/respond/reports',
        permissions: [ 'can_read_respond_reports' ],
        rootAction: true,
      },
      {
        name: 'sidebar.navigation.respond.kpi',
        route: '/respond/kpi',
        permissions: [ 'can_get_kpis' ],
        rootAction: true,
      },  
      {
        name: 'respond.queries.title',
        route: '/respond/queries',
        permissions: [ 'can_read_custom_queries' ],
        rootAction: true,
      },
      {
        name: 'histories.page.title',
        route: '/respond/histories',
        permissions: [ 'can_read_histories' ],
        rootAction: true,
        preview: previewModeEnum.BETA,
      },
    ];
    
    if (AdmRespondSidebar._showCreateAlert) {
      console.log(`Adding 'Create alert' option in ${environment.stage} mode`);
      out.push( {
        name: 'Create alert',
        route: '/respond/alerts/create',
        permissions: [ ],
        rootAction: true,
      });
    }

    return out;
  }

  private getConfigsChildren(): navigationElement[] {
    return [
      {
        name: 'sidebar.navigation.respond.organizations',
        route: '/respond/organizations',
        permissions: [ 'can_list_respond_organizations' ],
        rootAction: true,
      },
      {
        name: 'sidebar.navigation.respond.communication',
        route: '/respond/escalation',
        permissions: [ 'can_read_escalation' ],
        rootAction: true,
      },
  
      {
        name: 'sidebar.navigation.respond.actifs',
        route: '/respond/assets',
        permissions: [ 'can_list_assets' ],
        rootAction: true,
      },
      {
        name: 'sidebar.navigation.respond.whitelists',
        route: '/respond/allowlists',
        permissions: [ 'can_list_whitelists' ],
        rootAction: true,
      },
      {
        name: 'sidebar.navigation.respond.filters',
        route: '/respond/filters',
        permissions: [ 'can_list_filters' ],
        rootAction: true,
      },
    ];
  }
}
