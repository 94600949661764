import { Injectable } from '@angular/core';
import { HistoriesApi } from '@common/services/api/respond/histories/histories.api';
import { IIncidentHistory } from '@common/services/api/respond/histories/histories.definition';
import { ResponseUtils } from '@common/utils/response-utils';
import { GetIncidentHistoriesRequest } from "projects/@common/services/api/respond/histories/models/getIncidentIHistoriesRequest";
import { BehaviorSubject, Observable } from 'rxjs';
import { HistoryIndicatorsEnum } from 'projects/@respond/histories/components/histories-filters/histories-filters.service';

export interface IncidentHistoryRequestDataType {
  incidentId: string,
  organizationId: string,
  from?: number,
  isVar?: boolean,
  isClient?: boolean,
  isSystem?: boolean,
  write?:boolean,
  actions?: string[]
}

@Injectable()
export class IncidentHistoryService {

  private _debounceTimeout: NodeJS.Timeout;
  private _indicators: string[];
  private _notificationAction: boolean;

  constructor(
    private readonly historyApi: HistoriesApi,
  ) {}

  public fetchIncidentHistories(requestData: IncidentHistoryRequestDataType): Observable<ResponseUtils<IIncidentHistory>> {
    const getIncidentHistoriesRequest = new GetIncidentHistoriesRequest(requestData);
    return this.historyApi.listIncidentHistories(getIncidentHistoriesRequest);
  }

}