import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedFilesTableComponent } from 'projects/@assist-ops/components/shared-files-table/shared-files-table.component';

@Component({
  selector: 'app-org-shared-files',
  templateUrl: './org-shared-files.container.html',
  styleUrls: [ './org-shared-files.container.scss' ],
})
export class OrgSharedFilesContainer implements OnInit {
  @ViewChild('table') table: SharedFilesTableComponent;
  public resourceName = this.route.snapshot.queryParams.name;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  public onScroll() {
    this.table.onScroll();
  }
}
