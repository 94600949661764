import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { DatasourceDetailsMapper } from 'projects/@common/services/api/respond/datasources/datasource.mapper';
import { Icon } from 'projects/@common/services/api/respond/datasources/datasources.definitions';
import { PlaybooksApiService } from 'projects/@common/services/api/respond/playbooks/playbooks.api';
import { IPlaybookDetail, IPlaybookTemplate } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import {
  PlaybookInventoryDetailForm,
  PlaybookPatchValuesEnum
} from '../../containers/respond-playbook-detail/playbook-details.form';
import { MitreAttackGenericData } from 'projects/@common/services/api/detect/mitre-attack/mitre-attack-api.definition';

@Component({
  selector: 'app-playbook-info-details',
  templateUrl: './playbook-info-details.component.html',
  styleUrls: [ './playbook-info-details.component.scss' ],
})
export class PlaybookDetailDetailsComponent implements OnInit {
  @Input()
  public playbook?: IPlaybookDetail;

  @Input()
  public playbookDetailForm: PlaybookInventoryDetailForm;

  @Input()
  public template: IPlaybookTemplate;

  @Input()
  public showTemplateInfo: boolean = true;

  @Input()
  public isReadonly: boolean = false;

  @Input() playbookAttackData: MitreAttackGenericData[] = [];

  public playbookValuesEnum = PlaybookPatchValuesEnum;

  public creationDate: string;

  public updateDate: string;

  public datasourceIcons: Icon[];

  constructor(
    private readonly datePipe: DatePipe,
    private readonly datasourceDetailsMapper: DatasourceDetailsMapper,
    private readonly playbookApiService: PlaybooksApiService,
    private readonly noticeService: NoticeService,
    public readonly i18nService: I18nService
  ) {
  }

  ngOnInit(): void {
    if (this.playbook) {
      this.setPlaybookData();
    } else {
      this.setPlaybookTemplateData();
    }
  }

  public async savePlaybookValue(patchValueEnum: PlaybookPatchValuesEnum, value: string): Promise<void> {
    if (!this.isReadonly) {
      const modifiedPlaybook = {
        ...this.playbook,
        [patchValueEnum]: value,
      };
      this.playbookApiService.updatePlaybook(this.playbook.organizationId, modifiedPlaybook)
        .then(() => {
          this.playbook[patchValueEnum] = value;
          this.noticeService.success('detection.playbook.detail.updated.success');
        })
        .catch((err) => this.noticeService.error(err));
    }
  }

  public hasTemplate(): boolean {
    return this.template != null || this.playbook?.templateId !== null;
  }

  private setPlaybookData(): void {
    this.creationDate = this.datePipe.transform(this.playbook.createdAt);
    this.updateDate = this.datePipe.transform(this.playbook.updatedAt);
    this.datasourceIcons = this.datasourceDetailsMapper.getDatasourceIcon(this.playbook.datasourceTypes);
  }

  private setPlaybookTemplateData(): void {
    this.creationDate = this.datePipe.transform(this.template.createdAt);
    this.updateDate = this.datePipe.transform(this.template.updatedAt);
    this.datasourceIcons = this.datasourceDetailsMapper.getDatasourceIcon(this.template.datasourceTypes);
  }
}
