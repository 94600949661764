<app-incident-details-base-layout [title]="title">
  <whitelists-table
    [defaultColumnsDef]="tableColumnsDef"
    [whitelistsData]="whitelistsData"
    [canDescribe]="false"
    [canCreate]="false"
    [canDelete]="false"
    [isReadonly]="true"
    [disabledGrouping]="true"
  ></whitelists-table>
</app-incident-details-base-layout>