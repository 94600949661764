import { ChangeDetectorRef, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayService } from '@common/modules/display/display.service';
import { AlertingUsecaseListItem, ISystemListUsecaseTemplateItem } from '@common/services/api/respond/usecase/usecase.definition';
import { Store } from '@ngxs/store';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { UiTab } from '@ui-kit/components/ui-tabs/ui-tab.component';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { ModalService } from '@ui-kit/services/modal.service';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SearchMode } from 'projects/@common/modules/layout/components/page/page.component';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ConnectorsApiOrganizationService } from 'projects/@common/services/api/detect/organizations/connectors-api-organizations';
import { DescribeOrganizationResponse } from 'projects/@common/services/api/detect/organizations/definitions';
import { UsecasesApiService } from "projects/@common/services/api/respond/usecase/usecase.api";
import { UsecasesCatalogContainerActions } from '../../actions/usecasesCatalog.actions';
import { UsecaseCatalogComponent } from '../../components/usecase-catalog/usecase-catalog.component';
import { UsecaseListTabsHandlerService } from './usecase-list-tabs-handler.actions';
import { IUsecaseTableObject, UsecaseTemplateStatusFilter, UsecasesInventoryContainerActions } from './usecases.actions';

export interface IUsecaseListViewParams {
  isReadonly: boolean;
  showOutdatedCard: boolean;
  showFilterStatus: boolean;
  tableColumns: Array<Object>;
  showCatalog: boolean;
  catalogTableColumns: Array<Object>;
}

@Component({
  selector: 'usecase-list',
  templateUrl: './usecase-list.container.html',
  styleUrls: [ './usecase-list.container.scss' ],
})
export class UsecaseListContainer implements OnInit {
  @Input()
  public viewParams: IUsecaseListViewParams;

  public organizations: DescribeOrganizationResponse[] = [];
  public activeOrganization: DescribeOrganizationResponse | null = null;
  public organizationLoaded = false;
  public deleteDialogOpened = false;
  public isDeleting = false;
  public usecasesCatalog: (ISystemListUsecaseTemplateItem & { isOwnedByUser: boolean; })[] = [];
  public canCreateUsecase: boolean = false;
  public canDeleteUsecase: boolean = false;
  public canUpdateUsecase: boolean = false;
  public canDescribeUsecase: boolean = false;
  public searchMode = SearchMode.RESPOND;
  public readonly usecaseInventoryAction: UsecasesInventoryContainerActions;
  public readonly usecaseCatalogAction: UsecasesCatalogContainerActions;
  public readonly dataFilter: MultiSelectData;
  public readonly listTabsColor: UiTabsColor = UiTabsColor.ListAction;

  @ViewChild(UsecaseCatalogComponent) usecasesCatalogComponent: UsecaseCatalogComponent;

  private isVarMode: boolean = false;

  constructor(
    multiSelectFactory: MultiSelectDataFactory,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    private readonly usecasesApiService: UsecasesApiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly zone: NgZone,
    public readonly i18n: I18nService,
    private readonly displayService: DisplayService,
    private readonly modalService: ModalService,
    private readonly store: Store,
    public readonly tabsHandler: UsecaseListTabsHandlerService
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
    this.usecaseInventoryAction = new UsecasesInventoryContainerActions(usecasesApiService, i18n);
    this.usecaseCatalogAction = new UsecasesCatalogContainerActions(usecasesApiService);
    this.dataFilter = multiSelectFactory.create(UsecaseTemplateStatusFilter, [], 'detection.usecase.filter.');
  }

  async ngOnInit(): Promise<void> {
    this.organizationLoaded = true;
    this.canCreateUsecase = !this.viewParams.isReadonly && this.displayService.meetsRequirements({ permissions: [ "can_create_usecase" ] });
    this.canUpdateUsecase = !this.viewParams.isReadonly && this.displayService.meetsRequirements({ permissions: [ "can_update_usecase" ] });
    this.canDeleteUsecase = !this.viewParams.isReadonly && this.displayService.meetsRequirements({ permissions: [ "can_delete_usecase" ] });
    this.canDescribeUsecase = this.displayService.meetsRequirements({ permissions: [ "can_describe_usecase" ] });

    if (!this.isVarMode) {
      await this.handleRefresh();
    }
    await this.handleUsecaseCatalogInit();
  }

  public get showTable(): boolean {
    return !this.isVarMode || !!this.activeOrganization;
  }

  public get showOutdatedCard(): boolean {
    return this.viewParams.showOutdatedCard && !!this.activeOrganization;
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    if (org) {
      this.activeOrganization = await this.connectorsApiOrganizationService.describeOrganization(org.id);
      await this.usecaseInventoryAction.handleSelectedOrganization(this.activeOrganization.organization_respond_id);
      await this.usecaseCatalogAction.handleSelectedOrganization(this.activeOrganization.organization_respond_id);
    } else {
      this.usecaseInventoryAction.resetUsecases();
      this.activeOrganization = null;
    }
    await this.handleUsecaseCatalogInit();
    this.cdr.detectChanges();
  }

  public openUsecaseDetails(usecase: IUsecaseTableObject): void {
    if (this.canDescribeUsecase) {
      const path = `respond/${this.activeOrganization}/usecases/${usecase.id}/detail`;
      this.zone.run(() => {
        this.router.navigateByUrl(path, {
          state: {
            organizationName: this.activeOrganization.name,
          },
        });
      });
    }
  }

  public openUsecaseTemplateDetails(usecaseTemplate: AlertingUsecaseListItem): void {
    if (this.canDescribeUsecase) {
      const path = `respond/usecases/templates/${usecaseTemplate.templateId}/detail`;
      this.zone.run(() => {
        this.router.navigateByUrl(path, {
          state: {
            template: usecaseTemplate,
          },
        });
      });
    }
  }

  public usecaseTabClick(tab: UiTab): void {
    if (tab.tabTitle === this.i18n.translate('detection.usecase.list.tab.catalog.title')) {
      this.tabsHandler.selectedTabIndex = 1;
      this.usecasesCatalogComponent.handleRefresh();
    } else {
      this.tabsHandler.selectedTabIndex = 0;
    }
  }

  private async handleRefresh(): Promise<void> {
    this.cdr.detectChanges();
    await this.usecaseInventoryAction.fetchUsecases();
    this.usecaseInventoryAction.currentSelectedUsecase = null;
    await this.usecaseCatalogAction.fetchUsecasesCatalog();
  }

  private async handleUsecaseCatalogInit(): Promise<void> {
    this.viewParams.catalogTableColumns = this.viewParams.catalogTableColumns.map((column) => {
      column['label'] = this.i18n.translate(column['label']);
      column['noSorting'] = true;
      if (column['tooltipText']) {
        column['tooltipText'] = this.i18n.translate(column['tooltipText']);
      }
      return column;
    });

    this.usecaseCatalogAction.usecasesCatalog.subscribe(async (catalog) => {
      this.usecasesCatalog = await Promise.all(catalog.map((usecaseTemplate) => {
        const hasTemplate = !!this.activeOrganization ? this.usecaseInventoryAction.hasUsecaseTemplate(usecaseTemplate.template.id) : false;
        return {
          ...usecaseTemplate,
          isOwnedByUser: hasTemplate,
        };
      }));
    });
    if (this.usecasesCatalog.length <= 0) {
      await this.usecaseCatalogAction.fetchUsecasesCatalog();
    }
  }
}
