<ui-toolbar-filter
  [activatedFiltersNumber]="incidentFiltersService.nbFilterApplied"
  (onClearAllFilters)="clearAllFilters()"
  [locale]="locale"
  [showEraseAll]="incidentFiltersService.nbFilterApplied > 0"
  [total]="incidentResponse?.getTotalItems() || 0"
  toolbar
>
  <app-date-interval
    leftSide
    [period]="incidentFiltersService.dateFilter"
  ></app-date-interval>

  <ui-table-refresh-tool
    class="refresher"
    rightSide
    [loading]="isLoading"
    (click)="handleManualRefreshClick()"
  ></ui-table-refresh-tool>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.date)"
    toolbar-collapsable-element
    [elementTitle]="'incidents.container.page.filter.date.label' | t"
    [showCheckbox]="true"
    [checkboxLabel]="'alerts.container.page.filter.alertDate.label.checkbox' | t"
    [checkboxTooltip]="'alerts.container.page.filter.alertDate.label.checkbox.tooltip' | t"
    [checkboxDefaultChecked]="!!incidentFiltersService?.dateFilter?.useUpdatedAtDate"
    (onCheckboxChanged)="incidentFiltersService.applyDateFilterCheckbox($event)"
  >
    <ui-table-date-tool
      [attr.data-test-id]="'period_filter'"
      (resetDateEmitter)="incidentFiltersService.clearDateFilter()"
      (timeEmitter)="incidentFiltersService.applyDateFilter($event)"
      [defaultCustomTimePeriod]="incidentFiltersService.dateFilter"
      [excludePresets]="['last_180_days', 'last_365_days']"
      [locale]="locale"
    >
    </ui-table-date-tool>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.status)"
    toolbar-collapsable-element
    [elementTitle]="'incidents.container.page.filter.status.label' | t"
  >
    <ui-multi-select
      [attr.data-test-id]="'status_filter'"
      (onOptionSelect)="incidentFiltersService.applyStatusFilter($event)"
      [multiSelectData]="incidentFiltersService.statusValues"
    ></ui-multi-select>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.indicators)"
    toolbar-collapsable-element
    [elementTitle]="'incidents.container.page.filter.indicators.label' | t"
  >
    <ui-multi-select
      [attr.data-test-id]="'indicator_filter'"
      [multiSelectData]="incidentFiltersService.indicatorsValues"
      (onOptionSelect)="incidentFiltersService.applyIndicatorsFilter($event)"
    >
    </ui-multi-select>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.priority)"
    toolbar-collapsable-element
    [elementTitle]="'incidents.container.page.filter.priority.label' | t"
  >
    <ui-multi-select
      [attr.data-test-id]="'priority_filter'"
      [multiSelectData]="incidentFiltersService.prioritiesValues"
      (onOptionSelect)="incidentFiltersService.applyPrioritiesFilter($event)"
    >
    </ui-multi-select>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.connector)"
    toolbar-collapsable-element
    [elementTitle]="'alerts.container.page.filter.connector.label' | t"
  >
    <connectors-multiselect
      [attr.data-test-id]="'connector_filter'"
      [shouldFetchConnectorsList]="true"
      [defaultSelectedConnectors]="incidentFiltersService.connectorFilter"
      (connectorSelectedEvent)="incidentFiltersService.applyConnectorsFilter($event)"
    ></connectors-multiselect>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.organization)"
    toolbar-collapsable-element
    [elementTitle]="'incidents.container.page.filter.organization.label' | t"
  >
    <ui-multi-select-searchbox
      [attr.data-test-id]="'organization_filter'"
      [multiSelectData]="incidentFiltersService.organizationValues"
      (onOptionSelect)="incidentFiltersService.applyOrgFilter($event)"
      [placeholder]="'ui.table-search-tool.placeholder'| t"
    >
    </ui-multi-select-searchbox>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.owner)"
    toolbar-collapsable-element
    [elementTitle]="'incidents.container.page.filter.owner.label' | t"
  >
    <ui-multi-select
      [attr.data-test-id]="'owner_filter'"
      [multiSelectData]="incidentFiltersService.ownerValues"
      (onOptionSelect)="incidentFiltersService.applyOwnerFilter($event)"
    >
    </ui-multi-select>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.dayTypes)"
    toolbar-collapsable-element
    [elementTitle]="'incidents.container.page.filter.dayTypes.label' | t"
  >
    <ui-multi-select
      [attr.data-test-id]="'day_type_filter'"
      (onOptionSelect)="incidentFiltersService.applyDayTypes($event)"
      [multiSelectData]="incidentFiltersService.dayTypeOptions"
    ></ui-multi-select>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.workShifts)"
    toolbar-collapsable-element
    [elementTitle]="'incidents.container.page.filter.workShift.label' | t"
  >
    <ui-multi-select
      [attr.data-test-id]="'workshift_filter'"
      (onOptionSelect)="incidentFiltersService.applyWorkShifts($event)"
      [multiSelectData]="incidentFiltersService.workShiftOptions"
    ></ui-multi-select>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.name)"
    toolbar-collapsable-element
    [elementTitle]="'incidents.container.page.filter.incident.label' | t"
  >
    <ui-table-search-tool
      [attr.data-test-id]="'name_filter'"
      [placeholder]="'incidents.container.page.filter.incident.placeholder'|t"
      [query]="incidentFiltersService.incidentFilter"
      (clear)="incidentFiltersService.clearIncidentFilter()"
      (query)="incidentFiltersService.applyIncidentFilter($event)"
    ></ui-table-search-tool>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.id)"
    toolbar-collapsable-element
    [elementTitle]="'incidents.container.page.filter.incidentId.label' | t"
  >
    <ui-table-search-tool
      [attr.data-test-id]="'incident_id_filter'"
      [placeholder]="'incidents.container.page.filter.incidentId.placeholder' | t"
      [DEBOUNCE_TIME]="500"
      [query]="incidentFiltersService.incidentIdFilter"
      (clear)="incidentFiltersService.clearIncidentIdFilter()"
      (query)="incidentFiltersService.applyIncidentIdFilter($event)"
    ></ui-table-search-tool>
  </div>

  <div
    *ngIf="shouldShowFilter(searchFiltersEnum.observable)"
    toolbar-collapsable-element
    [elementTitle]="'alerts.container.page.filter.observable.label' | t"
  >
    <ui-table-search-tool
      [attr.data-test-id]="'observablefilter'"
      [placeholder]="'alerts.container.page.filter.observable.placeholder'|t"
      [query]="incidentFiltersService.observableFilter"
      (clear)="incidentFiltersService.clearObservableFilter()"
      (query)="incidentFiltersService.applyObservableFilter($event)"
    ></ui-table-search-tool>
  </div>

</ui-toolbar-filter>