import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { TextareaTypeEnum } from '../json-textarea/json-textarea.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ISelfSignedPems } from 'projects/@common/services/selfsigned.service';
import { CertificateUtilityHelper } from '../helpers/certificate-utility.helper';
import { FileUtilityHelper } from '../helpers/file-utility.helper';
import { NoticeService } from '@common/modules/notice/notice.service';

export enum UiTypeOfFormEnum {
  DROPDOWN = 'dropdown',
  TEXT_INPUT = 'textInput',
  LONG_TEXT_INPUT = 'longTextInput',
  NUMBER_INPUT = 'numberInput',
  CHECKBOX = 'checkbox',
  OBJECT_TEXTAREA = 'objectTextarea',
  ARRAY_TEXTAREA = 'arrayTextarea',
  MULTI_SELECT = 'multiSelect',
  DATE_INPUT = 'dateTimePicker',
  FILE_UPLOAD_INPUT = 'fileUpload',
}

export interface DatasourceFormInterface {
  label: ITranslatedField;
  description: ITranslatedField;
  required: boolean;
  typeOfForm: UiTypeOfFormEnum;
  controlName: string;
  group: UntypedFormGroup;
  values?: any[];
  initialValue?: { label: string; value: string | number };
}

@Component({
  selector: 'dynamic-form-template',
  templateUrl: './dynamic-form-template.component.html',
  styleUrls: ['./dynamic-form-template.component.scss'],
})
export class DynamicFormTemplateComponent {
  public uiTypeOfFormEnum = UiTypeOfFormEnum;
  public textareaTypeEnum = TextareaTypeEnum;

  @Input() formConfig: DatasourceFormInterface;

  @Input() readonly = false;

  @Output() valueSelection = new EventEmitter();

  @Output() fileUploadToggle = new EventEmitter<void>();

  @Output() onCertificateUploaded = new EventEmitter<ISelfSignedPems>();

  private fileChangeSubject = new Subject<File>();

  constructor(private readonly noticeService: NoticeService) {
    this.fileChangeSubject
      .pipe(debounceTime(300))
      .subscribe($event => this.onFileSelection($event));
  }

  get isInError(): boolean {
    const control = this.formConfig.group.get(this.formConfig.controlName);
    return control.invalid && control.touched;
  }

  onValueSelection(event: { controlName: string; value: string }): void {
    this.valueSelection.emit(event);
  }

  /** show/hide Input type file  */
  toggleFileUploadVisibility(): void {
    this.fileUploadToggle.emit();
  }

  /** Handle upload file */
  async onFileSelection($file: File): Promise<void> {
    try {
      const fileContent = await FileUtilityHelper.readFile($file);
      const { privateKey, publicKey, certificate, expirationDate } = CertificateUtilityHelper.extractPemComponents(fileContent);

      this.onCertificateUploaded.emit({
        private: privateKey,
        public: publicKey,
        cert: certificate,
        expirationDate,
      });
    }
    catch (error) {
      const errorMessage = `Error reading file: ${error.message || error}`;
      this.noticeService.snack(errorMessage);
    }
  }

}
