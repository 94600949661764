import { addLicense } from '@amcharts/amcharts4/core';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IRespondResponse } from '@common/definitions/IRespondResponse';
import { DisplayService } from '@common/modules/display/display.service';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { ISoarConnector, SoarConnectorTypes } from '@common/services/api/respond/connectors/connectors-alerting-api.definition';
import { ConnectorsAlertingApiService } from '@common/services/api/respond/connectors/connectors-alerting-api.service';
import { UiTabs, UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ConnectorsOrgService } from 'projects/@common/services/api/detect/connectorsOrg/connectorsOrg.service';
import { ConnectorData, ConnectorInstanceDto, ConnectorOrgQueries, ConnectorTypesEnum, Family, Filters, GetOrgConnectorUsecaseResponse, ICardConnector, IConnectorInstance, Vendor } from 'projects/@common/services/api/detect/connectorsOrg/model/ConnectorOrg';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-connector-inventory-tab',
  templateUrl: './connector-inventory-tab.component.html',
  styleUrls: [ './connector-inventory-tab.component.scss' ],
})
export class ConnectorInventoryTabComponent implements OnInit, OnDestroy {
  @Input() public organization: IAutomationOrganization;
  @Input() public connectorFilters: Subject<Filters>;
  @ViewChild('tabs') public tabs: UiTabs;
  @Output() public connectorsDataEvent: EventEmitter<GetOrgConnectorUsecaseResponse> = new EventEmitter();

  public dataConnectorsGroupedByType: string[];
  public soarConnectorsGroupedByType: string[];
  public connectors: ICardConnector[] = [];
  public connectorInstances: IConnectorInstance[] = [];
  public isLoading = false;
  private filterSubscription;
  private canDisplaySoarConnectors: boolean = false;

  constructor(
    private readonly connectorsAlertingApiService: ConnectorsAlertingApiService,
    private readonly connectorOrgService: ConnectorsOrgService,
    private readonly i18n: I18nService,
    private readonly displayService: DisplayService
  ) {
    addLicense('CH259911983');
  }

  ngOnInit(): void {
    this.connectorFilters.subscribe((value) => this.fetchConnectors(value));
    this.canDisplaySoarConnectors = this.displayService.meetsRequirements({ flags: [FeaturesEnum.SOAR_CONNECTORS] });
    this.fetchConnectors();
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  public get tabColor(): string {
    return UiTabsColor.lightTabs;
  }

  public getClientConnectorInstances(connector: ICardConnector): IConnectorInstance[] {
    return this.connectorInstances.filter((instance) => instance.connectorId === connector.id);
  }

  public doesClientHaveThisConnector(connector: ICardConnector): boolean {
    return this.connectorInstances.find((instance) => instance.connectorId === connector.id) !== undefined;
  }

  private fetchConnectors(filters?: Filters): void {
    this.isLoading = true;

    const requestQueries: ConnectorOrgQueries = {
      lang: this.i18n.currentLocale,
      categories: filters ? filters.categories.map((c) => c.id) : [],
      functions: filters ? filters.functions.map((f) => f.id) : [],
      vendors: filters ? filters.vendors.map((v) => v.id) : [],
    };

    const dataConnectorsPromises = this.connectorOrgService.getConnectors(this.organization.ecoId, requestQueries);
    const soarConnectorsPromises = this.canDisplaySoarConnectors ? 
      this.connectorsAlertingApiService.listSoarConnectors(this.organization.ecoId) :
      {items: []};

    Promise.all([dataConnectorsPromises, soarConnectorsPromises]).then(([dataConnectors, soarConnectors]) => {
      const data = dataConnectors.connectors;
      const soar = soarConnectors.items;
      this.connectors = [
        ...data.map((connector) => this.connectorToBadgeMapper(connector, ConnectorTypesEnum.DATA)), 
        ...soar.map((connector) => this.connectorToBadgeMapper(connector, ConnectorTypesEnum.SOAR))
      ];

      this.connectorInstances = [
        ...dataConnectors.instances.map((connector) => this.connectorToInstanceMapper(connector, ConnectorTypesEnum.DATA)), 
        ...soar.map((connector) => this.connectorToInstanceMapper(connector, ConnectorTypesEnum.SOAR))
      ]

      this.groupConnectorsByTypes(dataConnectors.instances, soarConnectors.items);
      this.connectorsDataEvent.emit(dataConnectors);
    })
    .catch(() => {
    })
    .finally(() => {
      this.isLoading = false;
    });
  }

  public getConnector(connectorId: string): ICardConnector {
    return this.connectors.find((connector) => connectorId === connector.id);
  } 

  private connectorToBadgeMapper(connector: ConnectorData | ISoarConnector, connectorType: ConnectorTypesEnum): ICardConnector {
    return {
      ...connector,
      id: connectorType === ConnectorTypesEnum.DATA ? connector['id'] : connector['connector']?.['connector'] + '_' + connector['connector']?.['type'],
      name: connectorType === ConnectorTypesEnum.DATA ? connector['name'] : connector['connector']?.['connector'],
      type: connectorType,
      ring: connectorType === ConnectorTypesEnum.DATA ? connector['ring'] : null,
      family: connectorType === ConnectorTypesEnum.DATA ? connector['family'] : this.getSoarConnectorFamily(connector as ISoarConnector),
      vendor: connectorType === ConnectorTypesEnum.DATA ? connector['vendor'] : this.getSoarConnectorVendor(connector as ISoarConnector),
      translations: connectorType === ConnectorTypesEnum.DATA ? connector['translations'] : {
        name: `${this.i18n.translate(`detect.connector.instance.drawer.connector.type.${connector['connector']?.['connector'] + '_' + connector['connector']?.['type']}`)}`,
        description: ''
      }
    }
  }

  private connectorToInstanceMapper(connectorInstance: ConnectorInstanceDto | ISoarConnector, connectorType: ConnectorTypesEnum): IConnectorInstance {
    return {
      ...connectorInstance,
      id: connectorType === ConnectorTypesEnum.DATA ? connectorInstance['id'] : connectorInstance['connector']?.['id'],
      connectorId: connectorType === ConnectorTypesEnum.DATA ? connectorInstance['connectorId'] : connectorInstance['connector']?.['connector'] + '_' + connectorInstance['connector']?.['type'],
      name: connectorType === ConnectorTypesEnum.DATA ? connectorInstance['name'] : connectorInstance['connector']?.['name'],
      type: connectorType,
      createdAt: connectorType === ConnectorTypesEnum.DATA ? connectorInstance['createdAt'] : connectorInstance['connector']?.['createdAt'],
      updatedAt: connectorType === ConnectorTypesEnum.DATA ? connectorInstance['updatedAt'] : connectorInstance['connector']?.['updatedAt'],
      secretKeys: connectorType === ConnectorTypesEnum.DATA ? connectorInstance['secretKeys'] : (connectorInstance['connector']?.['config']?.['secretId'] ? [connectorInstance['connector']?.['config']?.['secretId']] : []),
      translations: connectorType === ConnectorTypesEnum.DATA ? connectorInstance['translations'] : {
        name: `${this.i18n.translate(`detect.connector.instance.drawer.connector.type.${connectorInstance['connector']?.['connector'] + '_' + connectorInstance['connector']?.['type']}`)}`,
        description: ''
      }
    }
  }

  private groupConnectorsByTypes(dataConnectors: ConnectorInstanceDto[], soarConnectors: ISoarConnector[]): void{
    this.dataConnectorsGroupedByType = [...new Set(dataConnectors.sort((a, b) => a.translations.name.localeCompare(b.translations.name)).map((connector) => connector.connectorId))];
    this.soarConnectorsGroupedByType = [...new Set(soarConnectors.map((connector) => connector.connector.connector + '_' + connector.connector.type))].sort((a, b) => a.localeCompare(b))
  }

  private getSoarConnectorFamily(soarConnector: ISoarConnector): Family {
    const isAws = soarConnector.connector.connector === SoarConnectorTypes.AWS;
    return {
      name: isAws ? 'AWS' : 'Microsoft 365',
      id: isAws ? '01H84X40AEDJ5CHPQ481YMP4HM' : '01H84X40AEDJ5CHPQ481YMP4HT'
    }
  }

  private getSoarConnectorVendor(soarConnector: ISoarConnector): Vendor {
    const isAws = soarConnector.connector.connector === SoarConnectorTypes.AWS;
    return {
      name: isAws ? 'AWS' : 'Microsoft',
      id: isAws ? '01H84X40AEDJ5CHPQ481YMP4HM' : '01H84X40AEDJ5CHPQ481YMP4HT'
    }
  }
}
