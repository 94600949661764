import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { PriorityTableComponent } from "projects/@common/components/priority-table/priority-table.component";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { NoticeService } from "projects/@common/modules/notice/notice.service";
import { IAlert } from "projects/@common/services/api/respond/alerts/alerts.definitions";
import { GetIncidentAlertsRequest } from "projects/@common/services/api/respond/alerts/models/getIncidentAlertsRequest";
import { FindingsApi } from "projects/@common/services/api/respond/finding/finding.api";
import { Finding } from "projects/@common/services/api/respond/finding/model/finding";
import { GetAlertFindingsRequest } from "projects/@common/services/api/respond/finding/model/findingFilters";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { SimilarityOnAssociateEventData } from "projects/@respond/components/similarity/similarity.definitions";
import { SimilarityEventsService } from "projects/@respond/components/similarity/similarity.events.service";
import { Subscription } from "rxjs";
import { AlertIncidentIndicatorsComponent } from "projects/@common/components/alert-incident-indicators/alert-incident-indicators.component";
import { DTPipe } from "@ui-kit/pipes/dt.pipe";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";

type IAlertTableData = IAlert & {
  findings?: ExtendedFinding[],
  linkURL?: string,
  linkQueryParams?: { [key: string]: any },
};

interface ExtendedFinding extends Finding {
  isCollapsed: boolean;
  createdAtBadge: {
    text: string;
    color: string;
  };
}

@Component({
  selector: 'app-alerts-tab',
  templateUrl: './alerts-tab.component.html',
  styleUrls: [ './alerts-tab.component.scss' ],
})
export class AlertsTabComponent implements OnInit, OnDestroy {
  @Input() incident: Incident;
  @Input() isAdminConsole: boolean;
  @Output() inidentFindings = new EventEmitter<Array<ResponseUtils<Finding>>>();

  public isLoadingAlert: boolean = false;
  public alertsResponse: ResponseUtils<IAlert>;
  public alertsTableData: IAlertTableData[];
  public incidentId: string;
  public organizationId: string;
  public showJsonView: boolean;

  public columnsDef = [
    {
      label: '',
      field: 'icon',
      type: UiStaticTableRowType.EXPENDABLE_ICON,
      showIcon: true,
      width: '26px',
      minWidth: '26px',
      noSorting: true,
      isResizable: false,
    },
    {
      label: this.i18n.translate('incidents.container.page.table.alerts.column.label.id'),
      field: 'shortId',
      type: UiStaticTableRowType.TEXT,
      width: '152px',
      minWidth: '152px',
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('incidents.container.page.table.alerts.column.label.name'),
      field: 'name',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      isResizable: false,
      width: '25%',
      noSorting: false,
    },
    {
      label: this.i18n.translate('incidents.container.page.table.alerts.column.label.description'),
      field: 'description',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      isResizable: false,
      noSorting: true,
      width: '45%',
    },
    {
      label: this.i18n.translate('incidents.container.page.table.alerts.column.label.findingCount'),
      field: 'findingCount',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      noSorting: true,
      width: '10%',
    },
    {
      label: this.i18n.translate('alerts.container.page.table.column.indicators'),
      field: "indicators",
      width: '110px',
      minWidth: '110px',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: AlertIncidentIndicatorsComponent,
      paramName: 'alert',
      isResizable: false,
    },
    {
      label: this.i18n.translate('incidents.container.page.table.alerts.column.label.priority'),
      field: 'priority',
      enumPrefix: 'alerts.container.page.table.column.priority.',
      width: '100px',
      minWidth: '42px',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: PriorityTableComponent,
      paramName: 'type',
      isResizable: false,
    },
    {
      label: this.i18n.translate('incidents.container.page.table.alerts.column.label.createdAt'),
      field: 'createdAt',
      type: UiStaticTableRowType.DATE_AND_HOUR,
      isResizable: false,
      width: '68px',
      minWidth: '68px',
      mediumDate: true,
      noSorting: false,
    },
    {
      label: this.i18n.translate('incidents.container.page.table.alerts.column.label.updatedAt'),
      field: 'updatedAt',
      type: UiStaticTableRowType.DATE_AND_HOUR,
      isResizable: false,
      width: '68px',
      minWidth: '68px',
      mediumDate: true,
      noSorting: false,
    },
  ];

  public formatDate = true;

  private _onAssociateSub: Subscription;

  constructor(
    public readonly i18n: I18nService,
    private readonly incidentApi: IncidentsApi,
    private readonly noticeService: NoticeService,
    private readonly findingApi: FindingsApi,
    private readonly similarityEventsService: SimilarityEventsService,
    private readonly dtPipe: DTPipe
  ) { }

  ngOnInit(): void {
    this.incidentId = this.incident.id;
    this.organizationId = this.incident.organizationId;
    this.loadAlerts();
    this._onAssociateSub = this.similarityEventsService.onAssociateSuccessEvent$.subscribe((data: SimilarityOnAssociateEventData) => {
      this.loadAlerts();
    });
  }

  ngOnDestroy(): void {
    this._onAssociateSub.unsubscribe();
  }

  get showAlertLink(): boolean {
    return this.isAdminConsole;
  }

  public canLoadMore(): boolean {
    return this.alertsResponse.canLoadMore();
  }

  public stringifyAlertWhitelist(alert: IAlertTableData): string {
    if (!alert?.whitelist) return;
    const simplifiedWhitelist = {
      name: alert.whitelist.name,
      description: alert.whitelist.description,
      conditions: alert.whitelist.conditions,
    };
    return JSON.stringify(simplifiedWhitelist);
  }

  public toggleJsonView(): void {
    this.showJsonView = !this.showJsonView;
  }

  public findingRowsTrackById(index: number, finding: Finding): string {
    return finding.id;
  }

  private async mapAlertsToTableData(alerts: IAlert[], findingsPromises?: Promise<ResponseUtils<Finding>>[]): Promise<IAlertTableData[]> {
    let alertFindingsPromises = [];

    if (findingsPromises) {
      const findings = await Promise.all(findingsPromises);
      alertFindingsPromises = alertFindingsPromises.concat(findings);
      this.inidentFindings.emit(findings);
    }

    const out = alerts.map((alert, index) => {
      if (!findingsPromises) {
        alertFindingsPromises.push(this.findingApi.listFindings(new GetAlertFindingsRequest({ alertId: alert.id, organizationId: alert.organizationId })).toPromise());
      }

      return {
        ...alert,
        findings: findingsPromises ? this.mapFindingsToTableData(alertFindingsPromises[index].getItems()) : null,
        linkURL: `/respond/alerts`,
        linkQueryParams: { shortId: alert.shortId },
      };
    });

    return findingsPromises ? out : this.mapAlertsToTableData(out, alertFindingsPromises);
  }

  private mapFindingsToTableData(findings: Finding[]): ExtendedFinding[] {
    return findings.map((finding) => ({
      ...finding,
      isCollapsed: true,
      createdAtBadge: {
        text: `${this.i18n.translate('common.date.createdAt.label')} ${this.dtPipe.transform(finding.createdAt, { withTime: true, withSeconds: true, locale: this.i18n.currentLocale })}`,
        color: 'blue',
      },
    }));
  }

  private loadAlerts(): void {
    this.isLoadingAlert = true;
    const getIncidentAlertsRequest = new GetIncidentAlertsRequest({ incidentId: this.incidentId, organizationId: this.organizationId });
    this.incidentApi.getAlertsFromIncident(getIncidentAlertsRequest).subscribe(
      async (response) => {
        this.alertsResponse = response;
        this.alertsTableData = await this.mapAlertsToTableData(this.alertsResponse.getItems());
      },
      (error) => this.noticeService.error("incidents.alerts.fetch.error"),
      () => this.isLoadingAlert = false
    );
  }

  public loadMoreAlerts(): void {
    if (!this.isLoadingAlert && this.canLoadMore()) {
      this.isLoadingAlert = true;
      const from = this.alertsResponse.getNextItem();
      const getIncidentAlertsRequest = new GetIncidentAlertsRequest({ incidentId: this.incidentId, organizationId: this.organizationId, from });
      this.incidentApi.getAlertsFromIncident(getIncidentAlertsRequest).subscribe(
        async (response) => {
          this.alertsResponse = response;
          this.alertsTableData = [...this.alertsTableData, ...(await this.mapAlertsToTableData(this.alertsResponse.getItems()))];
        },
        (error) => this.noticeService.error("incidents.alerts.fetch.error"),
        () => this.isLoadingAlert = false
      );
    }
  }
}
