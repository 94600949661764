import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationFrequency } from 'projects/@common/definitions/notificationFrequency.enum';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { CustomValidators } from 'projects/@common/utils/validators';

const DEFAULT_VALUES = {
  frequency: NotificationFrequency.WEEKLY,
  time: 10,
  weekDay: 5,
};

@Component({
  selector: 'notification-frequency',
  templateUrl: './notification-frequency.component.html',
  styleUrls: ['./notification-frequency.component.scss'],
})
export class NotificationFrequencyComponent implements OnInit {
  @Input() public currentSettings: OrganizationShareSettings;
  @Input() public isLoading = false;

  public isEditing = false;
  public notificationFrequencyEnum = NotificationFrequency;
  public sharesNotificationForm: UntypedFormGroup;
  public frequencyOptions = Object.values(NotificationFrequency).map((notificationFrequency) => ({
    value: notificationFrequency,
    displayValue: this.i18n.translate(`settings.notification.frequency.${notificationFrequency}`),
  }));
  public dayOptions = (() => {
    const options = [];
    for (let i = 1; i < 32; i++) {
      options.push({ value: i, displayValue: i });
    }
    return options;
  })();
  public hourOptions = (() => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      options.push({ value: i, displayValue: `${i}:00` });
    }
    return options;
  })();

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private formBuilder: UntypedFormBuilder,
    private i18n: I18nService,
    private noticeService: NoticeService
  ) { }

  ngOnInit() {
    this.initForm(this.currentSettings);
  }

  private initForm(currentSettings?: OrganizationShareSettings) {
    this.sharesNotificationForm = this.formBuilder.group({
      frequency: this.formBuilder.control(currentSettings?.highRiskNotificationFrequencyChoice || NotificationFrequency.WEEKLY),
      time: this.formBuilder.control(currentSettings?.highRiskNotificationTime || 10),
      weekDay: this.formBuilder.control(
        currentSettings?.highRiskNotificationFrequency === 0 ? 0 : currentSettings?.highRiskNotificationFrequency || 5,
        [CustomValidators.isLessOrEqualThan(365, 'validFrequencyDuration')]
      ),
    });
  }

  public cancel() {
    this.initForm(this.currentSettings);
  }

  public save(): void {
    this.organizationsApiService
      .updateOrganizationSettings({
        newSharesSettings: {
          highRiskNotificationFrequencyChoice: this.sharesNotificationForm?.value.frequency,
          highRiskNotificationFrequency: +this.sharesNotificationForm?.value.weekDay,
          highRiskNotificationTime: +this.sharesNotificationForm?.value.time,
        },
      })
      .then((response) => {
        this.currentSettings = response;
        this.initForm(response);
        this.noticeService.notifyUser({
          message: 'settings.notification.frequency.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.notification.frequency.snack.error',
          level: NoticeLevels.ERROR,
        });
      });
  }

  public get isValid() {
    return this.sharesNotificationForm.valid;
  }

  public get isDefaultValue(): boolean {
    return JSON.stringify(this.sharesNotificationForm.getRawValue()) == JSON.stringify(DEFAULT_VALUES);
  }

  public restoreDefaults() {
    this.sharesNotificationForm.patchValue(DEFAULT_VALUES);
  }

  public selectDayOfTheWeek(dayOfTheWeek: string) {
    this.sharesNotificationForm.patchValue({ ...this.sharesNotificationForm.getRawValue(), weekDay: dayOfTheWeek });
  }

  public frequencyChanged(event: NotificationFrequency) {
    if (event === NotificationFrequency.WEEKLY && this.sharesNotificationForm.value.weekDay > 6) {
      this.sharesNotificationForm.patchValue({ ...this.sharesNotificationForm.getRawValue(), weekDay: 5 });
    }
    if (event === NotificationFrequency.MONTHLY && this.sharesNotificationForm.value.weekDay > 31) {
      this.sharesNotificationForm.patchValue({ ...this.sharesNotificationForm.getRawValue(), weekDay: 31 });
    }
  }

  public generateRange(start: number, end: number): number[] {
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  }
}
