import { Stages } from '@common/definitions/stages';
import { environment } from '@common/environments/environment';
import { DisplayRequirements } from 'projects/@common/modules/display/display.service';
import { BaseSidebar, navigationElement, previewModeEnum, ServiceTypeEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';

export class OrgRespondSidebar extends BaseSidebar {
  public static _showCreateAlert = false;
  
  private elements: navigationElement[] = [
    { name: 'sidebar.navigation.detection' },
    {
      rootAction: true,
      name: 'respond.dashboard.title',
      icon: 'icon_admin_cyber_behavior',
      route: '/respond/dashboard',
      permissions: [ 'can_list_playbooks' ],
    },
    // {
    //   rootAction: true,
    //   name: 'respond.reports.title',
    //   icon: 'icon_admin_report',
    //   route: '/respond/reports',
    //   permissions: ['can_read_respond_reports']
    // },
    {
      name: 'sidebar.navigation.detection.datasource.name',
      icon: 'icon_menu_datasource_family',
      route: '/detection/datasources',
      preview: previewModeEnum.DEPRECATED,
      permissions: [ 'can_list_datasources_family' ],
      rootAction: true,
    },
    {
      name: 'sidebar.navigation.detection.connector.name',
      route: '/detection/connectors',
      icon: 'sidebar/icon_menu_connectors',
      permissions: [ 'can_list_detect_connector_instances' ],
      rootAction: true,
    },
    {
      name: 'sidebar.navigation.detection.usecase.name',
      icon: 'icon_menu_usecase',
      route: '/detection/usecases',
      permissions: [ 'can_list_aggregated_usecases' ],
      rootAction: true,
    },
    {
      name: 'sidebar.navigation.respond.incidents.name',
      icon: 'icon_menu_incident',
      route: '/respond/incidents',
      permissions: [ 'can_list_incidents' ],
      rootAction: true,
    },
    {
      name: 'sidebar.navigation.respond.playbooks',
      icon: 'sidebar/icon_admin_menu_playbook',
      route: '/respond/playbooks',
      permissions: [ 'can_list_playbooks' ],
      rootAction: true,
    },
    {
      name: 'respond.queries.title',
      icon: 'sidebar/icon_queries_sidebar_search',
      route: '/respond/queries',
      permissions: [ 'can_read_custom_queries' ],
      rootAction: true,
    },
    {
      name: 'sidebar.navigation.respond.configs',
      icon: 'sidebar/icon_sidebar_config',
      orPermissions: [ 'can_list_assets', 'can_list_whitelists', 'can_list_filters', 'can_read_escalation'  ],
      rootAction: false,
      children: this.getConfigsChildren(),
    },
  ];

  constructor() {
    super();

    if (OrgRespondSidebar._showCreateAlert) {
      console.log(`Adding 'Create alert' option in ${environment.stage} mode`);
      this.elements.push( {
        name: 'Create alert',
        icon: 'icon_admin_menu_alerts',
        route: '/respond/alerts/create',
        permissions: [ ],
        rootAction: true,
      });
    }
  }

  public getElements() {
    return this.elements;
  }

  public getRequirements(): DisplayRequirements {
    return {
      services: [ ServiceTypeEnum.DETECT_AND_RESPOND ],
    };
  }

  public unsubscribe() {
  }

  private getConfigsChildren(): navigationElement[] {
    return [
      {
        name: 'sidebar.navigation.respond.communication',
        route: '/respond/escalation',
        permissions: [ 'can_read_escalation' ],
        rootAction: true,
      },
      {
        name: 'sidebar.navigation.respond.actifs',
        route: '/respond/assets',
        permissions: [ 'can_list_assets' ],
        rootAction: true,
      },
      {
        name: 'sidebar.navigation.respond.whitelists',
        route: '/respond/allowlists',
        permissions: [ 'can_list_whitelists' ],
        rootAction: true,
      },
      {
        name: 'sidebar.navigation.respond.filters',
        route: '/respond/filters',
        permissions: [ 'can_list_filters' ],
        rootAction: true,
      },
    ];
  }
}
