<section class="observables-top-section">

  <div
    *ngIf="!shouldHideTitle"
    class="observables-top-section--title"
  >
    {{'observables.title' | t}}
  </div>

  <div
    *ngIf="!shouldHideCreateButton || !shouldHideDeleteButton"
    class="observables-top-section--action-buttons-container"
  >
    <button
      *ngIf="!shouldHideCreateButton"
      ui-button
      color="linkFlat"
      [disabled]="isLoadingObservables"
      [custom-icon]="true"
      [isSvg]="true"
      icon="icon_create"
      icon-size="medium"
      (click)="onClickCreate()"
    >
      {{ 'common.action.create' | t }}</button>
    <button
      *ngIf="!shouldHideDeleteButton"
      ui-button
      color="linkFlat"
      [disabled]="isLoadingObservables"
      [custom-icon]="true"
      [isSvg]="true"
      icon="icon_delete"
      icon-size="medium"
      (click)="onClickDelete()"
    >
      {{ ('common.action.delete' | t) + " (" + (selectedRows?.length || 0) +")" }}
    </button>
  </div>

  <div
    *ngIf="!shouldHideSearch || !shouldHideViewModeSelect"
    class="observables-top-section--filters-container"
  >
    <ui-table-total-tool
      [locale]="i18n.currentLocale"
      [total]="isLoadingObservables ? '-' : totalDisplayed || 0"
    ></ui-table-total-tool>

    <ui-table-search-tool
      class="search-text"
      *ngIf="!shouldHideSearch"
      [class.wide]="shouldHideTitle && !isIncidentTasksPage"
      [placeholder]="'common.search' | t"
      [query]="filters.searchText"
      (query)="handleSearchTextChange($event)"
      (clear)="handleSearchTextChange(null)"
    ></ui-table-search-tool>

    <ui-table-filter-tool
      *ngIf="!shouldHideViewModeSelect"
      class="view-mode-selector"
      [tableFilterData]="listViewModes"
      (select)="handleViewModeChange($event)"
    ></ui-table-filter-tool>
  </div>
</section>

<section class="observables-table-section">
  <ng-container *ngIf="useGrouping; else noGroupingTemplate">
    <ui-collapsable-card
      *ngFor="let group of tableGroups; trackBy: trackById"
      class="observables-table-section--table-collapse-group"
      [cardTitle]="group.name"
      [badges]="group.badge ? [group.badge] : []"
      [headerIcon]="group.icon"
      [isBigHeaderImage]="true"
      [isCollapsed]="!group.isExpanded"
      (onToggleCollapse)="group.isExpanded = !$event"
    >
      <ng-container *ngIf="group.isExpanded">
        <ui-static-table
          [sort]="defaultSort"
          [direction]="defaultSortDirection"
          [dataSource]="group.observables"
          [columnsDef]="group.columnsDef"
          [loading]="isLoadingObservables"
          [locale]="i18n.currentLocale"
          [canSelectOnlyOne]="false"
          [isSelectingOnlyOneAtATime]="false"
          [addPaddingOnFirst]="false"
          [hoverable]="true"
          (checkbox-event)="handleCheckboxEvent($event.event, $event.item)"
        >
        </ui-static-table>
      </ng-container>
    </ui-collapsable-card>
  </ng-container>

  <ng-template #noGroupingTemplate>
    <ui-static-table
      [sort]="defaultSort"
      [direction]="defaultSortDirection"
      [dataSource]="tableGroups[0].observables"
      [columnsDef]="tableGroups[0].columnsDef"
      [loading]="isLoadingObservables"
      [locale]="i18n.currentLocale"
      [canSelectOnlyOne]="false"
      [isSelectingOnlyOneAtATime]="false"
      [addPaddingOnFirst]="false"
      [hoverable]="true"
      (checkbox-event)="handleCheckboxEvent($event.event, $event.item)"
    >
    </ui-static-table>
  </ng-template>

  <ui-spinner
    *ngIf="isLoadingObservables && !tableGroups.length"
    size="medium"
  ></ui-spinner>
</section>
