import { HttpClient } from "@angular/common/http";
import { GetHistoriesRequest } from "@common/services/api/respond/histories/models/getHistoriesRequest";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { IAlertHistory, IAssetHistory, IConnectorHistory, IEscalationHistory, IFilterHistory, IGetPlaybookHistoriesRequest, IHistoriesApi, IHistory, IIncidentHistory, IIncidentTaskHistory, IListConnectorHistoryRequest, IListEscalationHistoryRequest, IListHistoryRequest, IListIncidentTaskHistoryRequest, IUsecaseHistory, IUsecaseHistoryRequest, IWhitelistHistory } from "projects/@common/services/api/respond/histories/histories.definition";
import { AssetHistoryRequest } from 'projects/@common/services/api/respond/histories/models/assetHistoryRequest';
import { GetAlertHistoriesRequest } from "projects/@common/services/api/respond/histories/models/getAlertHistoriesRequest";
import { GetIncidentHistoriesRequest } from "projects/@common/services/api/respond/histories/models/getIncidentIHistoriesRequest";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { UrlUtils } from "projects/@common/utils/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class CustomerHistoriesApi extends EcoApi implements IHistoriesApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listUsers(request: GetHistoriesRequest): Observable<{ identityName: string; identityId: string }[]> {
    throw new Error("Method not implemented.");
  }

  public listHistories(request: GetHistoriesRequest): Observable<ResponseUtils<IHistory>> {
    throw new Error("Method not implemented.");
  }

  public listAlertHistories(request: GetAlertHistoriesRequest): Observable<ResponseUtils<IAlertHistory>> {
    throw new Error("Method not implemented.");
  }

  public listIncidentHistories(request: GetIncidentHistoriesRequest): Observable<ResponseUtils<IIncidentHistory>> {
    const url = `${this.host}/me/incidents/${request.incidentId}/histories?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`;
    return this.http.get<IRespondResponse<IIncidentHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IIncidentHistory>(response)));
  }

  public listWhitelistHistory(request: IListHistoryRequest): Observable<ResponseUtils<IWhitelistHistory>> {
    const url = `${this.host}/me/whitelists/${request.whitelistId}/histories?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IWhitelistHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IWhitelistHistory>(response)));
  }

  public listFilterHistory(request: IListHistoryRequest): Observable<ResponseUtils<IFilterHistory>> {
    const url = `${this.host}/me/filters/${request.filterId}/histories?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IFilterHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IFilterHistory>(response)));
  }

  public listEcoAlertFilterHistory(request: IListHistoryRequest): Observable<ResponseUtils<IFilterHistory>> {
    const url = `${this.host}/me/ecoAlertFilters/${request.filterId}/histories?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IFilterHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IFilterHistory>(response)));
  }

  public listUsecaseHistory(request: IUsecaseHistoryRequest): Observable<ResponseUtils<IUsecaseHistory>> {
    throw new Error("Method not implemented.");
  }

  public listAssetHistory(request: AssetHistoryRequest): Observable<ResponseUtils<IAssetHistory>> {
    const url = `${this.host}/me/assets/${request.assetId}/histories?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`;
    return this.http.get<IRespondResponse<IAssetHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IAssetHistory>(response)));
  }

  public listPlaybookHistory(request: IGetPlaybookHistoriesRequest): Observable<ResponseUtils<any>> {
    throw new Error("Method not implemented.");
  }

  public listEscalationHistory(request: IListEscalationHistoryRequest): Observable<ResponseUtils<IEscalationHistory>> {
    const url = `${this.host}/me/escalation/histories?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IEscalationHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IEscalationHistory>(response)));
  }

  public listIncidentTaskHistory(request: IListIncidentTaskHistoryRequest): Observable<ResponseUtils<IIncidentTaskHistory>> {
    const url = `${this.host}/me/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/histories?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IIncidentHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IIncidentHistory>(response)));
  }

  public listDataConnectorHistory(request: IListConnectorHistoryRequest): Observable<ResponseUtils<IConnectorHistory>> {
    throw new Error("Should only be called in Var mode for now");
  }

  public listSoarConnectorHistory(request: IListConnectorHistoryRequest): Observable<ResponseUtils<IConnectorHistory>> {
    throw new Error("Should only be called in Var mode for now");
  }
}
