import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { Autocomplete, AutocompleteTypes } from "@ui-kit/components/autocomplete/autocomplete.component";
import { AutocompleteCustomValue } from "@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component";
import { ConfirmationModalComponent } from "@ui-kit/components/confirmation-modal/confirmation-modal.component";
import { ModalService } from "@ui-kit/services/modal.service";
import { Eco } from "projects/@common/definitions/eco";
import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { Notice, NoticeLevels, NoticeService } from "projects/@common/modules/notice/notice.service";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { ConnectorsApiOrganizationService } from "projects/@common/services/api/detect/organizations/connectors-api-organizations";
import { IamApiService } from "projects/@common/services/api/iam/iam.api";
import { CustomerProfilesApi } from "projects/@common/services/api/iam/profiles/customer-profiles.api";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { ICreateIncidentRequest, IIncident, ISocAnalystUser } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { PlaybooksApiService } from "projects/@common/services/api/respond/playbooks/playbooks.api";
import { IPlaybookDetail } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";
import { Observable } from "rxjs/internal/Observable";
import { Subscription } from "rxjs/internal/Subscription";


enum AsyncStateEnum {
  "LOADING", "SAVING", "READY"
}

@Component({
  templateUrl: './create-incident-modal.component.html',
  styleUrls: [ './create-incident-modal.component.scss' ],
})
export class CreateIncidentModalComponent implements OnInit, OnDestroy {
  @ViewChild('playbooksAutocomplete') playbooksAutocompleteRef: Autocomplete;

  public readonly asyncStateEnum = AsyncStateEnum;
  public readonly customAutocompleteType = AutocompleteTypes.CUSTOM;
  public organizationId: string;
  public organizationEcoId: string;
  public preferredLanguage: Eco.Languages;
  public asyncState: AsyncStateEnum = this.asyncStateEnum.LOADING;

  private form: UntypedFormGroup;

  public organizations: AutocompleteCustomValue[];
  public selectedOrganizations: AutocompleteCustomValue[] = [];

  private playbooks: IPlaybookDetail[];
  public playbookAutocompleteOptions: AutocompleteCustomValue[] = [];
  public selectedPlaybooks: AutocompleteCustomValue[] = [];

  private socAnalysts: ISocAnalystUser[];
  public analystAutocompleteOptions: AutocompleteCustomValue[] = [];
  public selectedAnalysts: AutocompleteCustomValue[] = [];

  // used to subscribe to the EcoSessionState.user Store (to get the connected user)
  @Select(EcoSessionState.user)
  private authUser$: Observable<string>;
  private authUserSubscription: Subscription;
  private authUser: any;

  private readonly customerProfileApi: CustomerProfilesApi;

  constructor(
    private readonly modalService: ModalService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly noticeService: NoticeService,
    private readonly playbooksApi: PlaybooksApiService,
    private readonly incidentsApi: IncidentsApi,
    private readonly iamApiService: IamApiService,
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly url: EcoUrlService,
    public readonly i18n: I18nService,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService
  ) {
    this.customerProfileApi = new CustomerProfilesApi(http, url);
  }

  ngOnInit(): void {
    this.asyncState = AsyncStateEnum.LOADING;

    this.authUserSubscription = this.authUser$.subscribe((value) => this.authUser = value);

    this.getOrganizationList();

    this.fetchSocAnalysts().then((socAnalysts) => {
      this.socAnalysts = socAnalysts;
      this.initSocAnalystsAutocomplete(this.socAnalysts);
      this.initFormGroup();
      this.asyncState = AsyncStateEnum.READY;
    }).catch((httpErrorResponse) => {
      this.noticeService.notifyUser(new Notice("alerts.container.page.notice.fetch.error", NoticeLevels.ERROR, null, httpErrorResponse?.message));
    });
  }

  ngOnDestroy(): void {
    this.authUserSubscription?.unsubscribe();
  }

  public get isPlaybooksAvailable(): boolean {
    return this.playbookAutocompleteOptions?.length > 0;
  }

  public get isAnalystsAvailable(): boolean {
    return this.analystAutocompleteOptions?.length > 0;
  }

  public get selectedAnalystId(): string {
    return this.selectedAnalysts[0]?.value || null;
  }

  public get selectedPlaybookId(): string {
    return this.selectedPlaybooks[0]?.value || null;
  }

  public get isSaveActionAvailable(): boolean {
    return Boolean(this.asyncState === this.asyncStateEnum.READY && this.form?.valid && this.organizationId && this.selectedAnalystId && this.selectedPlaybookId);
  }

  public get isCancelActionAvailable(): boolean {
    return this.asyncState !== this.asyncStateEnum.SAVING;
  }

  public getOrganizationList(): void {
    this.connectorsApiOrganizationService.getManagedOrganizations({ from: 0, size: 500 })
      .then((response) => this.organizations = response.items.map((org) => ({
        value: org.organization_respond_id,
        displayValue: org.name,
      })))
      .catch(() => this.noticeService.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)));
  }

  public createIncident(): void {
    this.asyncState = AsyncStateEnum.SAVING;

    const { incidentName, incidentDescription } = this.form.value;

    const apiRequestPayload: ICreateIncidentRequest = {
      alertIds: [],
      organizationId: this.organizationId,
      playbookId: this.selectedPlaybookId,
      ownerId: this.selectedAnalystId,
      name: incidentName,
      summary: incidentDescription,
    };

    this.incidentsApi.createIncident(apiRequestPayload)
      .then((response: IIncident) => {
        this.handleIncidentCreatedSuccess(response);
      })
      .catch((httpErrorResponse) => {
        this.noticeService.notifyUser(new Notice("alerts.container.page.notice.incident.error", NoticeLevels.ERROR, null, httpErrorResponse?.message));
      })
      .finally(() => {
        this.asyncState = AsyncStateEnum.READY;
      });
  }

  public cancel(): void {
    this.modalService.closeDialog();
  }

  public async handleIncidentCreatedSuccess(incident: IIncident): Promise<void> {
    this.modalService.closeDialog();
    this.noticeService.success("alerts.container.page.notice.incident.success", { id: incident.shortId });

    const shouldRedirectToIncident = await this.promptUserRedirectToIncident(incident.shortId);
    if (shouldRedirectToIncident) {
      this.router.navigate(
        [ "respond", this.organizationEcoId, "incidents", incident.id, "details" ],
        { queryParams: { tab: 'tasks' }, state: incident }
      );
    }
  }

  public async handleOrganizationChange(organization: AutocompleteCustomValue): Promise<void> {
    const organizationId = organization?.value;
    if (organizationId) {
      const organization = await this.connectorsApiOrganizationService.describeOrganization(organizationId);
      this.organizationId = organization.organization_respond_id;
      this.organizationEcoId = organization.organization_eco_id;
      const [ playbooksRes, iamOrganizationRes ] = await Promise.all([
        this.playbooksApi.getPlaybooks(this.organizationId),
        this.iamApiService.describeOrganization(this.organizationEcoId),
      ]);
      this.preferredLanguage = iamOrganizationRes.preferredLang;
      this.selectedOrganizations = [ this.organizations.find((org) => org.value === this.organizationId) ];
      this.playbooks = playbooksRes.items.map((item) => item.playbook);
      this.initSocAnalystsAutocomplete(this.socAnalysts);
      this.initPlaybooksAutocomplete(this.playbooks);
    } else {
      this.organizationId = null;
      this.organizationEcoId = null;
      this.preferredLanguage = null;
      this.playbooks = [];
      this.selectedPlaybooks = [];
      this.selectedOrganizations = [];
      this.playbookAutocompleteOptions = [];
      this.analystAutocompleteOptions = [];
      this.form.get('incidentName').reset();
      this.form.get('incidentDescription').reset();
      this.playbooksAutocompleteRef.clearSearchQuery();
    }
  }

  public get displayPlaybookError(): boolean {
    return this.organizationId && !this.isPlaybooksAvailable;
  }

  private async fetchSocAnalysts(): Promise<ISocAnalystUser[]> {
    const socAnalystsProfileId = "01GA7045RXX45YZNCNDKQDM4K7";
    const profilesResponse = await this.customerProfileApi.listProfileMembers({
      profileId: socAnalystsProfileId,
    });
    return profilesResponse.items.map((user) => ({
      id: user.id,
      displayName: user.name,
      mail: user.idpUser?.mail || user.upn,
      idpIdentifier: user.idpIdentifier,
    }));
  }

  private initFormGroup(): void {
    this.form = this.formBuilder.group({
      incidentName: this.formBuilder.control('', Validators.required),
      incidentDescription: this.formBuilder.control('', Validators.required),
    });
  }

  private async promptUserRedirectToIncident(id: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18n.translate('alerts.container.page.modal.incident.promptRedirect.title'),
        text: this.i18n.translate('alerts.container.page.modal.incident.promptRedirect.text', { id }),
        confirmationText: this.i18n.translate('alerts.container.page.modal.incident.promptRedirect.confirmationText'),
        cancelText: this.i18n.translate('alerts.container.page.modal.incident.promptRedirect.cancelText'),
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
  }

  private getTranslatedObjectValue(translatedField: ITranslatedField): string {
    const fallback = this.preferredLanguage === Eco.Languages.EN ? Eco.Languages.FR : Eco.Languages.EN;
    return translatedField ? (translatedField[this.preferredLanguage] || translatedField[fallback]) : '';
  }

  private initSocAnalystsAutocomplete(socAnalysts: ISocAnalystUser[]): void {
    this.analystAutocompleteOptions = [];
    if (socAnalysts?.length) {
      this.analystAutocompleteOptions = socAnalysts
        .map((analyst) => ({
          value: analyst.id,
          displayValue: analyst ? `${analyst.displayName} \(${analyst.mail}\)` : "",
        }))
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue));

      const connectedUserAsDefaultSocAnalyst = socAnalysts.find((analyst) => analyst.idpIdentifier === this.authUser.oid);
      if (connectedUserAsDefaultSocAnalyst) {
        const defaultAnalyst = this.analystAutocompleteOptions.find((analyst) => analyst.value === connectedUserAsDefaultSocAnalyst.id);
        this.selectedAnalysts = defaultAnalyst ? [ defaultAnalyst ] : [];
      } else {
        this.selectedAnalysts = [];
      }
    }
  }

  private initPlaybooksAutocomplete(playbooks: IPlaybookDetail[]): void {
    this.playbookAutocompleteOptions = [];
    if (playbooks?.length) {
      this.playbookAutocompleteOptions = playbooks
        .map((item) => ({
          value: item.id,
          displayValue: `(${item.templateCatalogId}) ${this.getTranslatedObjectValue(item.name)} - ${item.suffix}`,
          description: this.getTranslatedObjectValue(item.description),
        }))
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue));

      this.selectedPlaybooks = [];
    }
  }
}
