<app-incident-details-base-layout [title]="title">
  <actifs-table
    class="alert-details-actifs-table"
    [defaultColumnsDef]="tableColumnsDef"
    [assetsData]="actifs"
    [canDescribe]="false"
    [canCreate]="false"
    [canDelete]="false"
    [isReadonly]="true"
    [disabledGrouping]="true"
  ></actifs-table>
</app-incident-details-base-layout>