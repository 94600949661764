import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { SecretStoreDrawerContainer } from './drawer/secret-store-drawer.container';
import { Accumulatables, Accumulator, AccumulatorsState } from 'projects/@common/modules/accumulator/accumulator.store';
import { ISecretIntegrations } from 'projects/@common/services/api/tools/secrets/secrets.definitions';
import { SearchMode } from 'projects/@common/modules/layout/components/page/page.component';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { DrawerPageEnum } from '@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from '@common/modules/layout/components/drawer/services/drawer.service';
import { EcoSessionState } from '@common/modules/session/state/session.state';
import { SecretsApiService } from '@common/services/api/tools/secrets/secrets.api';
import { Eco } from '@common/definitions/eco';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { DisplayService } from 'projects/@common/modules/display/display.service';

@Component({
  selector: 'secret-store',
  templateUrl: './secret-store.container.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'usecases',
  },
})
export class SecretStoreContainer implements OnInit {
  public isLoading = false;
  public isLoadingMore = false;
  public total = 0;
  public secretStoreData = [];
  public secretIntegrations : ISecretIntegrations[] = [];
  public organizationTableFilter: TableFilterData;
  private readonly SECRETS_NUMBER_LIMIT = 30;
  public searchName = '';
  public searchType = '';
  public tableFilterData: TableFilterData;


  @Select(EcoSessionState.organization) public organization$: Observable<Eco.IOrganization>;
  public readonly COLUMN_DEF = [
    {
      label: this.i18n.translate('secrets.details.name'),
      field: 'name',
      type: UiStaticTableRowType.TEXT,
      width: "15%",
    },
    {
      label: this.i18n.translate('secrets.details.type'),
      field: 'type',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      width: "10%",
    },
    {
      label: this.i18n.translate('secrets.details.note'),
      field: 'note',
      type: UiStaticTableRowType.FUNCTION,
      func: this.validateEmptyNote,
      width: "35%",
    },
    {
      label: this.i18n.translate('secrets.details.createdAt'),
      field: 'createdAt',
      type: UiStaticTableRowType.DATE,
      width: "10%",
    },
    {
      label: this.i18n.translate('secrets.details.updatedAt'),
      field: 'updatedAt',
      type: UiStaticTableRowType.DATE,
      width: "10%",
    },
    {
      label: this.i18n.translate('secrets.details.expireAt'),
      field: 'expireAt',
      type: UiStaticTableRowType.DATE,
      width: "10%",
    },
    {
      label: this.i18n.translate('secrets.details.modifiedBy'),
      field: 'updatedBy',
      type: UiStaticTableRowType.AVATAR,
      avatarSize: 'small',
      width: "7%",
      noSorting: true,
    },
  ];

  public sort = 'name';
  public direction: any = UiTableDirection.Asc;

  public organizationEcoId: string | null = null;
  public searchMode = SearchMode.IAM;

  private from = 0;

  @Select(AccumulatorsState.accumulators) private accumulators$: Observable<Accumulator[]>;

  private accumulatorSubscription: Subscription;

  private currentAccumulatorCount: number;
  secretIntegrationsTableFilter: TableFilterData;

  public isVarMode: boolean = false;

  public canCreateSecret = false;

  constructor(
    private drawerService: DrawerService,
    private i18n: I18nService,
    private secretsApiService: SecretsApiService,
    private store: Store,
    private displayService: DisplayService
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  public ngOnInit() {
    this.handleNavigationFilters();
    this.handleAccumulator();
    this.loadIntegrations();

    if (!this.isVarMode) {
      this.handleRefresh();

      this.canCreateSecret = this.displayService.meetsRequirements({ permissions: [ 'can_create_secret' ] });
    } else {
      this.canCreateSecret = this.displayService.meetsRequirements({ permissions: [ 'can_create_var_secret' ] });
    }
  }

  public handleRowClick(item) {
    this.drawerService.replaceCurrentDrawer(SecretStoreDrawerContainer, {
      component: DrawerPageEnum.createSecretDrawer,
      data: { secret: item, title: this.i18n.translate('secrets.edit.title'), templates: this.secretIntegrations },
    });
  }

  public handleSorting(sort: string, direction: UiTableDirection) {
    this.sort = sort;
    this.direction = direction;
    this.handleRefresh();
  }

  public handleSearchClear() {
    this.searchName = '';
    this.handleRefresh();
  }

  public handleSearchQuery(searchName: string) {
    this.searchName = searchName;
    this.handleRefresh();
  }

  public handleDataFilter(type) {
    if (type === "all") {
      this.searchType = '';
    } else {
      this.searchType = type;
    }
    this.handleRefresh();
  }

  public create() {
    this.drawerService.replaceCurrentDrawer(SecretStoreDrawerContainer, {
      component: DrawerPageEnum.createSecretDrawer,
      data: { title: this.i18n.translate('secrets.create.title'), templates: this.secretIntegrations },
    });
  }

  public loadIntegrations() {
    this.secretsApiService.listSecretIntegrations().then((response) => {
      this.secretIntegrations = response;
      this.secretIntegrationsTableFilter = new TableFilterData((this.secretIntegrations.map((integration) => ({
        value: integration.templateName,
        translated: integration.name[this.i18n.currentLocale],
        translatedTooltip: "none",
        translatedWithTitleSuffix: "none",
        isDefault: false,
      }))).concat({ value: "all", translated: this.i18n.translate('secrets.table.filter.type.all'), translatedTooltip: "none", translatedWithTitleSuffix: "none",  isDefault: true }));
    });
  }

  public organizationChanged(org): void {
    this.organizationEcoId = org?.id;
    this.handleRefresh();
  }

  public handleRefresh(): void {
    this.secretStoreData = [];
    if (!this.isVarMode || this.organizationEcoId) {
      this.isLoading = true;
      this.from = 0;
      this.total = 0;
      this.loadSecretForOrganizationId();
    }
  }

  public validateEmptyNote(note) {
    return note === '' ? '-' : note;
  }

  public loadSecretForOrganizationId(): void {
    if (!this.isLoadingMore && (this.from === 0 || this.from < this.total)) {
      this.isLoadingMore = true;
      this.secretsApiService.listSecrets(
        this.organizationEcoId,
        {
          size: this.SECRETS_NUMBER_LIMIT,
          from: this.from,
          order: this.direction,
          searchName: this.searchName,
          searchType: this.searchType,
        }
      )
        .then((response) => {
          this.secretStoreData = this.secretStoreData.concat(response.items.map((item) => ({
            ...item,
            updatedBy: { name: item?.updatedBy },
            type: this.secretIntegrations.find((integration) => integration.templateName === item.type).name,
          })));
          this.from = response.nextItem;
          this.total = response.total;
        }).finally(() => {
          this.isLoading = false;
          this.isLoadingMore = false;
        });
    }
  }

  private handleNavigationFilters(): void {
    if (history.state?.filters?.name) {
      this.searchName = history.state?.filters?.name;
    }
  }

  private handleAccumulator() {
    this.accumulatorSubscription = this.accumulators$.subscribe((accumulators) => {
      const count = accumulators
        .filter((accumulator) => accumulator.accumulatable === Accumulatables.SECRETS_LIST)
        .shift()?.accumulator;

      if (count !== this.currentAccumulatorCount) {
        this.handleRefresh();
        this.currentAccumulatorCount = count;
      }
    });
  }
}
