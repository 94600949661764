import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UiKitModule } from "@ui-kit/uikit.module";
import { ComponentsModule } from "projects/@common/components/components.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { PipesModule } from "projects/@common/modules/pipes/pipes.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { RespondComponentsModule } from "../components/respond.components.module";
import { RespondQueriesEngineComponent } from './components/respond-queries-engine/respond-queries-engine.component';
import { RespondQueriesResultComponent } from "./components/respond-queries-result/respond-queries-result.component";
import { RespondQueriesPageComponent } from './containers/respond-queries-page/respond-queries-page.container';


const components = [
  RespondQueriesPageComponent,
  RespondQueriesEngineComponent,
  RespondQueriesResultComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    PipesModule,
    RouterModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
    RespondComponentsModule,
  ],
})
export class RespondQueriesModule { }
