<div class="table-container">
    <ui-static-table 
        [loading]="isLoading"
        [dataSource]="usecaseFilters"
        [addPaddingOnFirst]="false"
        [columnsDef]="usecaseFiltersTableColumnsDefinition"
        [locale]="i18n.currentLocale"
        (on-row-click)="navigateToFilters($event)">
    </ui-static-table>
</div>

<div class="button-container" *ngIf="lastFilterRequest && lastFilterRequest.canLoadMore()">
    <ui-table-refresh-tool class="refresher" [loading]="isLoading" [text]="'common.showMore' | t" rightSide
        (click)="fetchFilterData()"></ui-table-refresh-tool>
</div>