<div class="entity-content">
  <ng-container *ngIf="isEditing; then templateEditing; else templateReadonly"></ng-container>
</div>

<ng-template #templateReadonly>
  <div class="entity-icon-container">
    <img [src]="entity.iconSrc">
  </div>

  <div class="entity-payload-fields">
    <div
      class="payload-field"
      *ngFor="let payloadField of entity.payloadDisplayFields"
    >
      <label class="field-label">{{payloadField.translatedLabel | t}}</label>
      <span class="field-value">{{payloadField.value}}</span>
    </div>
  </div>
</ng-template>


<ng-template #templateEditing>
  entity edit
</ng-template>