import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FieldMap } from "angular2-query-builder";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { ICreateFilterRequest, IDeleteFiltersRequest, IDeleteFiltersResponse, IDescribeFilterRequest, IDescribeFilterResponse, IFilterBaseRepresentation, IFiltersApi, IListFilterFieldsRequest, IListFiltersRequest, IPrevisualizeQueryRequest, IUpdateFilterRequest } from "projects/@common/services/api/respond/filters/filters.definitions";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { UrlUtils } from "projects/@common/utils/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiEndpointPrefixEnum, EcoApi } from "@common/definitions/eco-api";

@Injectable({
  providedIn: 'root',
})
export class CustomerFiltersApi extends EcoApi implements IFiltersApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public getFilterConditionFields(request: IListFilterFieldsRequest): Promise<FieldMap> {
    const url = `${this.host}/me/filters/fields`;
    return this.http.get<FieldMap>(url).toPromise();
  }

  public listFilters(organizationId: string, request: IListFiltersRequest): Observable<ResponseUtils<IFilterBaseRepresentation>> {
    const url = `${this.host}/me/filters?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IFilterBaseRepresentation>>(url)
      .pipe(map((response) => new ResponseUtils<IFilterBaseRepresentation>(response)));
  }

  public listOrganizationsFilters(request?: IListFiltersRequest): Observable<ResponseUtils<IFilterBaseRepresentation>> {
    throw new Error('Not implemented');
  }

  public describeFilter(request: IDescribeFilterRequest): Observable<IDescribeFilterResponse> {
    const url = `${this.host}/me/filters/${request.id}`;
    return this.http.get<IDescribeFilterResponse>(url);
  }

  public createFilter(request: ICreateFilterRequest): Observable<IDescribeFilterResponse> {
    const url = `${this.host}/me/filters`;
    return this.http.post<IDescribeFilterResponse>(url, request);
  }

  public updateFilter(request: IUpdateFilterRequest): Observable<IDescribeFilterResponse> {
    const url = `${this.host}/me/filters/${request.id}`;
    return this.http.put<IDescribeFilterResponse>(url, request);
  }

  public deleteFilter(request: IDeleteFiltersRequest): Promise<IDeleteFiltersResponse> {
    const url = `${this.host}/me/filters/${request.id}`;
    return this.http.delete<IDeleteFiltersResponse>(url).toPromise();
  }

  public previsualizeQuery(request: IPrevisualizeQueryRequest): Promise<any> {
    const url = `${this.host}/me/filters/preview`;
    return this.http.post<any>(url, request).toPromise();
  }
}
