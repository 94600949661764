import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRespondResponse } from 'projects/@common/definitions/IRespondResponse';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { IDatasourceUsecaseInfo } from 'projects/@common/services/api/respond/datasources/datasources.definitions';
import { IFilterRepresentation } from 'projects/@common/services/api/respond/filters/filters.definitions';
import { AlertingUsecaseListItem, IAlertingUsecaseTemplate, IDatasourceType, IListAlertingUsecasesRequest, ISystemListUsecaseTemplatesResponse, IUsecase, IUsecaseApi, IUsecaseDescriptionResponse, IUsecaseResponse, IUsecaseStatsResponse, UsecaseCreationInterface, UsecaseCreationResponse } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { UrlUtils } from 'projects/@common/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class CustomerUsecasesApiService extends EcoApi implements IUsecaseApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  constructor(http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public getOrganizationsUsecaseList(
    queryParam: IListAlertingUsecasesRequest
  ): Promise<IRespondResponse<AlertingUsecaseListItem>> {
    throw new Error('Method not implemented.');
  }

  public getUsecase(_organizationId: string, usecaseId: string): Promise<IUsecaseDescriptionResponse> {
    const url = `${super.host}/me/usecases/${usecaseId}`;
    return this.http.get<IUsecaseDescriptionResponse>(url).toPromise();
  }

  public getUsecaseList(organizationId: string, queryParam?: IListAlertingUsecasesRequest): Promise<IRespondResponse<AlertingUsecaseListItem>> {
    const url = `${super.host}/me/usecases${this.getQueryParamsString(queryParam)}`;
    return this.http.get<IRespondResponse<AlertingUsecaseListItem>>(url).toPromise();
  }

  public async getAggregatedUsecasesList(_organizationId: string, queryParam?: any): Promise<IRespondResponse<IAlertingUsecaseTemplate>> {
    const url = `${super.host}/me/usecases/aggregated${this.getQueryParamsString(queryParam)}`;
    return this.http.get<IRespondResponse<IAlertingUsecaseTemplate>>(url).toPromise();
  }

  public getDatasourceFamilyInfo(organizationId: string): Promise<IDatasourceUsecaseInfo[]> {
    return this.http.get<IDatasourceUsecaseInfo[]>(`${super.host}/me/usecases/families`).toPromise();
  }

  public getUsecaseStats(organizationId: string): Promise<IUsecaseStatsResponse> {
    const url = `${super.host}/me/usecases/stats`;
    return this.http.get<IUsecaseStatsResponse>(url).toPromise();
  }

  public createUsecase(organizationId: string, usecase: Partial<UsecaseCreationInterface>): Promise<UsecaseCreationResponse> {
    throw new Error('Method not implemented.');
  }

  public updateUsecase(organizationId: string, usecase: any): Promise<UsecaseCreationResponse> {
    throw new Error('Method not implemented.');
  }

  public updateOutdatedUsecase(usecase: any): Promise<IUsecase> {
    throw new Error('Method not implemented.');
  }

  public updateOutdatedUsecasePipeline(usecase: any): Promise<IUsecase> {
    throw new Error('Method not implemented.');
  }

  public updateUsecaseParameters(organizationId: string, parameters: any): Promise<UsecaseCreationResponse> {
    throw new Error('Method not implemented.');
  }

  public listUsecaseTemplates(parameters: any): Promise<ISystemListUsecaseTemplatesResponse> {
    const url = `${super.host}/system/usecaseCatalog?${UrlUtils.jsonToQueryParams(parameters)}`;
    return this.http.get<ISystemListUsecaseTemplatesResponse>(url).toPromise();
  }

  public getUsecaseTemplate(usecaseId: string): Promise<{ name: string; description: ITranslatedField; }[]> {
    throw new Error('Method not implemented.');
  }

  public getUsecaseActions(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public deleteUsecase(organizationId: string, usecaseId: string): Promise<{ deleted: boolean; organizationId: string; usecaseId: string; }> {
    throw new Error('Method not implemented.');
  }

  public getUsecasesCatalog(params: { from?: number; size?: number; activated?: string; datasourceType?: string; organizationId?: string; orderColumn?: string; order?: string; }): Promise<IRespondResponse<IUsecaseResponse>> {
    throw new Error('Method not implemented.');
  }

  public getUsecaseCatalog(usecaseId: string): Promise<IUsecaseResponse> {
    throw new Error('Method not implemented.');
  }

  public getDatasourcesDataTypes(): Promise<IRespondResponse<IDatasourceType>> {
    const url = `${super.host}/me/datasources/dataTypes`;
    return this.http.get<IRespondResponse<any>>(url).toPromise();
  }

  public getUsecaseFilters(organizationId: string, usecaseId: string): Promise<IRespondResponse<IFilterRepresentation>> {
    throw new Error('Method not implemented.');
  }

  private getQueryParamsString(queryParam: any): string {
    return queryParam && Object.keys(queryParam).length > 0 ? `?${UrlUtils.jsonToQueryParams(queryParam)}` : '';
  }
}
