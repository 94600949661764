import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { ConnectorInsatancesVolumeStatsRequestParameters, ConnectorInstanceBasicDateParameters } from '../connectors/connector-api.definition';
import { ConnectorInstanceVolumeStats, ConnectorInstanceVolumeStatsHistory } from '../models/connectorInstance.model';
import { GetConnectorInstanceStatsResponse, GetConnectorInstanceVolumetryRequestParams, IConnectorsOrgApi } from './connectorsOrg.definition';
import { CustomerConnectorsOrgService } from './customer-connectorsOrg.service';
import { ConnectorInstanceVolumeEntityHistoryDto, ConnectorInstanceVolumeEntityTotalHistoryDto, ConnectorInstanceVolumeHistory, ConnectorOrgQueries, GetOrgConnectorUsecaseResponse } from './model/ConnectorOrg';
import { VarConnectorsOrgService } from './var-connectorsOrg.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectorsOrgService implements IConnectorsOrgApi {
  private customerService: CustomerConnectorsOrgService;
  private varService: VarConnectorsOrgService;

  constructor(
    protected store: Store,
    readonly http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerConnectorsOrgService(http, url);
    this.varService = new VarConnectorsOrgService(http, url);
  }

  public getConnectors(organizationId: string, requestQueries: ConnectorOrgQueries): Promise<GetOrgConnectorUsecaseResponse> {
    if (this.isVarMode) {
      return this.varService.getConnectors(organizationId, requestQueries);
    }
    return this.customerService.getConnectors(organizationId, requestQueries);
  }

  public getConnectorInstanceVolumetry(organizationId: string, instanceId: string, params: GetConnectorInstanceVolumetryRequestParams): Promise<ConnectorInstanceVolumeHistory[]> {
    if (this.isVarMode) {
      return this.varService.getConnectorInstanceVolumetry(organizationId, instanceId, params);
    }
    return this.customerService.getConnectorInstanceVolumetry(organizationId, instanceId, params);
  }

  public getConnectorInstanceStats(organizationId: string): Promise<GetConnectorInstanceStatsResponse> {
    if (this.isVarMode) {
      return this.varService.getConnectorInstanceStats(organizationId);
    }
    return this.customerService.getConnectorInstanceStats(organizationId);
  }

  public getConnectorInstanceVolumeEntityHistory(organizationId: string, instanceId: string, params: any): Promise<ConnectorInstanceVolumeEntityHistoryDto> {
    if (this.isVarMode) {
      return this.varService.getConnectorInstanceVolumeEntityHistory(organizationId, instanceId, params);
    }
    return this.customerService.getConnectorInstanceVolumeEntityHistory(organizationId, instanceId, params);
  }

  public getConnectorInstanceVolumeEntityTotalHistory(organizationId: string, instanceId: string, params: any): Promise<ConnectorInstanceVolumeEntityTotalHistoryDto> {
    if (this.isVarMode) {
      return this.varService.getConnectorInstanceVolumeEntityTotalHistory(organizationId, instanceId, params);
    }
    return this.customerService.getConnectorInstanceVolumeEntityTotalHistory(organizationId, instanceId, params);
  }

  public async getConnectorVolume(organizationId: string, requestParams: ConnectorInsatancesVolumeStatsRequestParameters): Promise<ConnectorInstanceVolumeStatsHistory[]> {
    if (this.isVarMode) {
      return this.varService.getConnectorVolume(organizationId, requestParams);
    }
    return this.customerService.getConnectorVolume(organizationId, requestParams);
  }

  public async getConnectorVolumeStats(organizationId: string, requestParams: ConnectorInsatancesVolumeStatsRequestParameters): Promise<ConnectorInstanceVolumeStats> {
    if (this.isVarMode) {
      return this.varService.getConnectorVolumeStats(organizationId, requestParams);
    }
    return this.customerService.getConnectorVolumeStats(organizationId, requestParams);
  }

  public async getConnectorTotalVolume(organizationId: string, requestParams: ConnectorInstanceBasicDateParameters): Promise<ConnectorInstanceVolumeStats> {
    if (this.isVarMode) {
      return this.varService.getConnectorTotalVolume(organizationId, requestParams);
    }
    return this.customerService.getConnectorTotalVolume(organizationId, requestParams);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
