<div
  class="collapsable-card"
  [class.is-expanded]="!isCollapsed"
>
  <div
    class="collapsable-card--header"
    (click)="toggleCollapse()"
  >
    <div
      class="collapsable-card--header--complete-title"
      [ngClass]="{
        'collapsable-card--header--space-between': spaceBetweenDynamicTitle
      }"
    >
      <div class="principal-informations">
        <ui-icon
          *ngIf="isCollapsable"
          class="collapsable-card--header--chevron"
          modifier="on-header"
          [name]="isCollapsed ? 'chevron-right' : 'chevron-down'"
          size="medium"
        ></ui-icon>

        <img
          *ngIf="headerIcon && !isAvatar"
          [ngClass]="isBigHeaderImage ? 'big' : 'small'"
          [src]="iconSrc"
          [alt]="headerIcon"
        />

        <div class="collapsable-card--header--title">
          {{ cardTitle }}
          <ng-container *ngIf="numberItems || numberItems === 0">({{ numberItems }})</ng-container>
        </div>

        <div *ngIf="tooltipText">
          <ui-tooltip [placementBottom]="tooltipPositionBottom">
            {{ tooltipText }}
          </ui-tooltip>
        </div>

        <div
          class="badges-items"
          [ngClass]="{'badge-align-item-end': badgeAlignment === 'end', 'badge-align-item-start': badgeAlignment === 'start'}"
        >
          <div
            *ngFor="let badge of badges"
            class="collapsable-card--header--badge badge-color-{{badge.color}}"
          >
            <span class="collapsable-card--header--badge--text">{{ badge.text }}</span>
          </div>
        </div>
        <img
          *ngIf="useRedFlagFeature && isRedFlagged"
          [src]="'assets/favicons/observables/icon_flag_grey.svg'"
          alt="Red Flag"
          [title]="redFlagTooltip || ''"
        >

        <div *ngIf="!!rightIcon?.name" class="icon-container">
          <div *ngIf="!!rightIcon.text"><span>{{ rightIcon.text }}</span></div>
          <img
            [ngClass]="isBigHeaderImage ? 'big' : 'small'"
            [src]="rightIcon.name"
            [tooltip]="rightIcon?.tooltip"
          />
        </div>
      </div>

      <div *ngIf="dynamicTitle && (isCollapsed || !hideDynamicTitleOnExpand)">
        <dynamic-component [dynamicComponent]="dynamicComponentParam"></dynamic-component>
      </div>
    </div>

    <div
      *ngIf="cardSecondTitle"
      class="collapsable-card--header--second-title"
    >
      {{ cardSecondTitle }}
    </div>

    <ui-menu-options
      *ngIf="withMenuOptions"
      [menuOptions]="menuOptions"
      (menuOpenedEvent)="emitMenuOpened()"
      (menuClosedEvent)="emitMenuClosed()"
      (menuOptionClickEvent)="emitMenuOptionClicked($event)"
    ></ui-menu-options>
  </div>

  <div *ngIf="!isCollapsed">
    <ng-content></ng-content>
  </div>
</div>