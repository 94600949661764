import { IIncidentStats } from "../incidents.definitions";

export class IncidentsStats implements IIncidentStats {
  public organizationId: string;
  public organizationName?: string;
  public open: number;
  public closed: number;
  public closedFalsePositive: number;
  public closedTest: number;

  constructor(jsonObj: IIncidentStats) {
    Object.assign(this, jsonObj);
  }
}
