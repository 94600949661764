import { Component } from '@angular/core';
import { SeverityEnum } from '../o365Labels.component';

@Component({
  selector: 'labels-informations-table',
  templateUrl: './labels-informations-table.component.html',
  styleUrls: [ './labels-informations-table.component.scss' ],
})
export class LabelsInformationsTableComponent {
  constructor() {}

  public get severityLevels() {
    return Object.keys(SeverityEnum).filter((severityLevel) => Number.isNaN(+severityLevel));
  }
}
