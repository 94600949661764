<div>
  <settings-edition-card
    [icon]="'icon_deactivate_notification_green_cropped.svg'"
    [title]="'settings.notification.silencing.title' | t"
    [canSave]="isValid"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div>
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div class="content-wrapper">

    <p>{{ 'settings.notification.silencing.description' | t }}</p>
    <div
      class="durations"
      *ngIf="isActivated"
    >
      <p>{{ 'settings.notification.silencing.possible.durations' | t }}</p>
      <p></p>
      <div class="duration">
        <ng-container *ngFor="let optionIndex of optionsIndex">
          <div>
            <p>
              {{
              'settings.notification.silencing.choice.label'
              | t: { formattedLabel: (optionIndex + 1) | indexSuffix }
              }}
            </p>
            <p>
              <strong>{{
                'settings.notification.silencing.choice.value.readonly'
                | t: { formattedLabel: silencingForm.value['choice' + optionIndex] }
                }}</strong>
            </p>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="durations"
      *ngIf="!isActivated"
    >
      <p [innerHTML]="'settings.notification.silencing.deactivated.description' | t"></p>
    </div>
  </div>
</ng-template>

<ng-template #editing>
  <div class="content-wrapper">
    <p class="description">{{ 'settings.notification.silencing.description' | t }}</p>
    <button
      *ngIf="isActivated && !isDefaultValues"
      class="default-values"
      (click)="restoreDefaults()"
    >
      {{ 'settings.notification.frequency.default.values' | t }}
    </button>
    <ui-toggle
      [toggled]="isActivated"
      [labelEnabled]="'settings.notification.silencing.toggle.activated' | t"
      [labelDisabled]="'settings.notification.silencing.toggle.deactivated' | t"
      (onClick)="toggleSilencingActivation()"
    ></ui-toggle>
    <ng-container *ngIf="isActivated">
      <form
        *ngIf="!isLoading; else loading"
        [formGroup]="silencingForm"
        class="form durations"
      >
        <ng-container *ngFor="let optionIndex of optionsIndex">
          <div>
            <div class="input">
              <div>
                <p>
                  {{
                  'settings.notification.silencing.choice.label'
                  | t: { formattedLabel: (optionIndex + 1) | indexSuffix }
                  }}
                </p>
                <div class="days">
                  <input
                    ui-input
                    [ngClass]="{ invalid: !silencingForm.get('choice' + optionIndex)?.valid }"
                    maxlength="3"
                    uiNumberOnlyInput
                    [formControlName]="'choice' + optionIndex"
                    [name]="'choice' + optionIndex"
                  />
                  <p>{{ 'settings.notification.silencing.days.label' | t }}</p>
                </div>
              </div>
              <ng-container *ngIf="!silencingForm.get('choice' + optionIndex).valid">
                <div class="fielderror">{{ getErrorMessage(silencingForm.get('choice' + optionIndex)) }}</div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </form>
    </ng-container>
  </div>
</ng-template>
