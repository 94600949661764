import { Component, Input } from '@angular/core';

@Component({
  selector: 'detail-layout',
  templateUrl: './detail-layout.component.html',
  styleUrls: [ './detail-layout.component.scss' ],
})
export class DetailLayoutComponent {
  @Input()
    backLink: string;
}
