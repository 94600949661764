import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Eco } from 'projects/@common/definitions/eco';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { DatasourceDetailsMapper } from 'projects/@common/services/api/respond/datasources/datasource.mapper';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { Observable } from 'rxjs';
import { DatasourceFamilyDetailService } from '../../../../services/datasource-family-detail.service';
import { IDatasourceTableObject } from '../../components/detail-body/detail-body.component';
import { IDetailHeaderData } from '../../components/detail-header/detail-header.component';
import { DatasourcesInventoryDetailActions } from './datasources-inventory-detail.actions';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';

@Component({
  selector: 'datasources-inventory-detail.container',
  templateUrl: './datasources-inventory-detail.container.component.html',
  styleUrls: [ './datasources-inventory-detail.container.component.scss' ],
})
export class DatasourcesInventoryDetailContainerComponent implements OnInit, OnDestroy {
  public datasourceDetails: IDetailHeaderData;

  public listData: IDatasourceTableObject[];

  public detailActions: DatasourcesInventoryDetailActions;

  public totalUsecases = 0;

  public isLoading = false;
  public organizationId: string;
  public multiSelectData: MultiSelectData;
  public tableFilterData: TableFilterData;
  @Select(EcoSessionState.organization) public organization$: Observable<Eco.IOrganization>;

  private userLanguage = this.i18nService?.currentLocale || Eco.Languages.FR;

  constructor(
    public i18nService: I18nService,
    private readonly usecasesApiService: UsecasesApiService,
    private readonly datasourceFamilyDetailService: DatasourceFamilyDetailService,
    private readonly datasourceDetailsMapper: DatasourceDetailsMapper,
    private readonly cdr: ChangeDetectorRef,
    private multiSelectFactory: MultiSelectDataFactory,
    private tableFilterToolDataFactory: TableFilterToolDataFactory,
    private readonly noticeService: NoticeService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.organization$.subscribe((value) => {
      this.organizationId = value.id;
      this.detailActions = new DatasourcesInventoryDetailActions(
        this.organizationId,
        this.usecasesApiService,
        this.datasourceDetailsMapper,
        this.userLanguage,
        this.noticeService
      );
      this.multiSelectData = this.multiSelectFactory.create(this.detailActions.priorityFilters, [], 'detection.datasource.inventory.table.priority.');
      this.tableFilterData = this.tableFilterToolDataFactory.create(this.detailActions.modeFilters, 'detection.datasource.inventory.detail.table.modes.', this.detailActions.modeFilters.ALL, true);
    });

    this.detailActions.setDatasourceFamily(this.datasourceFamilyDetailService.datasourceInfo.datasourceFamily);
    this.detailActions.$datasources.subscribe((usecases) => {
      this.listData = usecases;
      this.cdr.detectChanges();
    });

    await this.detailActions.handleRefresh();
    this.totalUsecases = this.detailActions.total;
    this.datasourceDetails = this.datasourceDetailsMapper.getDatasourceDetail(
      this.datasourceFamilyDetailService.datasourceInfo,
      this.totalUsecases
    );
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.detailActions.$datasources.unsubscribe();
    this.datasourceFamilyDetailService.resetValue();
  }
}
