<ui-tabs
  #tabs
  color="drawerTabs"
>
  <ui-tab [tabTitle]="'parameter.details.title' | t">
    <filter-details
      *ngIf="!isLoading; else spinner"
      [filter]="filter"
      [organizationId]="organizationId"
      [isReadonly]="data.isReadonly"
    ></filter-details>
  </ui-tab>

  <ui-tab
    *ngIf="isConsoleAdmin && (!!filter && !isEditing)"
    [tabTitle]="'alerts.drawer.tab.history.title' | t"
  >
    <filter-history
      *ngIf="!isLoading; else spinner"
      [filter]="filter"
    ></filter-history>
  </ui-tab>
</ui-tabs>


<ng-template #spinner>
  <div class="spinner">
    <ui-spinner
      size="medium"
      modifier="secondary"
    ></ui-spinner>
  </div>
</ng-template>