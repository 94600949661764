import { Component, Input } from "@angular/core";
import { IAlertingUsecaseTemplate } from "@common/services/api/respond/usecase/usecase.definition";
import { ModalService } from "@ui-kit/services/modal.service";
import { Eco } from "projects/@common/definitions/eco";
import { UsecaseCatalogTemplateMapper } from "projects/@respond/reports/templating/usecaseCatalogTemplateMapper";
import { ReportService, ReportTypesEnum } from "projects/@respond/services/report.service";
import { UsecaseCategory } from "../../mappers/usecaseCategories";
import { UsecaseCatalogReportChooseLanguageModalComponent } from "../usecase-catalog-report-choose-language-modal/usecase-catalog-report-choose-language-modal.component";

@Component({
  selector: 'app-usecase-catalog-report-button',
  templateUrl: './usecase-catalog-report-button.component.html',
  styleUrls: [ './usecase-catalog-report-button.component.scss' ],
})
export class UsecaseCatalogReportButtonComponent<T extends IAlertingUsecaseTemplate> {
  @Input() isWithinTableColumn: boolean = true;
  @Input() usecaseCategories: UsecaseCategory<T>[];

  constructor(
    private usecasesTemplateMapper: UsecaseCatalogTemplateMapper<T>,
    private reportService: ReportService,
    private readonly modalService: ModalService
  ) {
  }

  public async askForLanguageAndOpenReport(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    const modal = this.modalService.openDialog(UsecaseCatalogReportChooseLanguageModalComponent);
    const subscription = modal.componentInstance.successEvent.subscribe((language) => {
      if (language) {
        this.goToReport(language);
      }
      subscription.unsubscribe();
    });
  }

  private goToReport(language: Eco.Languages) {
    const reportWindow = window.open('', Math.random().toString(), `width=${screen.width},height=${screen.height},resizable=1`);

    this.usecasesTemplateMapper.generateMapper(language, this.usecaseCategories).then((reportTemplate) => {
      this.reportService.generateReport(ReportTypesEnum.usecaseCatalog, reportTemplate, reportWindow, language);
    });
  }
}
