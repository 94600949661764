import { DynamicQueryTimeRangeEnum } from "@ui-kit/components/widget-container/widget-container.component";
import { RespondDashboardTimeRangesEnum } from "../containers/respond-dashboard-container/respond-dashboard-container.component";
import { DateTime } from 'luxon';

export enum GenericDashboardTimeRangeEnum {
  LAST_7_DAYS = DynamicQueryTimeRangeEnum.LAST_7_DAYS,
  LAST_30_DAYS = DynamicQueryTimeRangeEnum.LAST_30_DAYS,
  LAST_90_DAYS = DynamicQueryTimeRangeEnum.LAST_90_DAYS,
  PREVIOUS_WEEK = DynamicQueryTimeRangeEnum.PREVIOUS_WEEK,
  PREVIOUS_MONTH = DynamicQueryTimeRangeEnum.PREVIOUS_MONTH
}

export function getTimestampsFromDynamicRange(currentTimeRange: string, opts?: { timeIsUtc?: boolean, fullDaysOnly?: boolean }): { fromDate: number, toDate: number } {
  const currentDate : DateTime = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
  let fromDate: DateTime = null;
  let toDate: DateTime = currentDate;
  switch (currentTimeRange) {
    case DynamicQueryTimeRangeEnum.LAST_24_HOURS:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.plus({ days: -1 });
      fromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      break;
    case DynamicQueryTimeRangeEnum.LAST_72_HOURS:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.plus({ days: -3 });
      fromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      break;
    case DynamicQueryTimeRangeEnum.LAST_4_WEEKS:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.plus({ days: -(4 * 7) });
      fromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      break;
    case DynamicQueryTimeRangeEnum.LAST_52_WEEKS:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.plus({ days: -(52 * 7) });
      fromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      break;
    case DynamicQueryTimeRangeEnum.LAST_7_DAYS:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.plus({ days: -7 });
      fromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      break;
    case DynamicQueryTimeRangeEnum.LAST_30_DAYS:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.plus({ days: -30 });
      fromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      break;
    case DynamicQueryTimeRangeEnum.LAST_90_DAYS:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.plus({ days: -90 });
      fromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      break;
    case DynamicQueryTimeRangeEnum.CURRENT_WEEK:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.plus({ days: - currentDate.weekday });
      fromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      break;
    case DynamicQueryTimeRangeEnum.PREVIOUS_WEEK:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.plus({ days: - (currentDate.weekday + 7) });
      fromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      toDate = fromDate.set({ day: fromDate.day + 7 });
      toDate = toDate.set({ day: toDate.day - 1, hour: 23, minute: 59, second: 59, millisecond: 999 });
      break;
    case DynamicQueryTimeRangeEnum.CURRENT_MONTH:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
      break;
    case DynamicQueryTimeRangeEnum.PREVIOUS_MONTH:
      fromDate = opts?.timeIsUtc ? DateTime.utc() : DateTime.now();
      fromDate = fromDate.plus({ months: -1 });
      fromDate = fromDate.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
      toDate = fromDate.plus({ months: 1 });
      toDate = toDate.set({ day: toDate.day - 1, hour: 23, minute: 59, second: 59, millisecond: 999 });
      break;
    default:
      break;
  }
  if (opts?.fullDaysOnly) {
    toDate = toDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }
  return { fromDate: fromDate?.toMillis(), toDate: toDate?.toMillis() };
}

export function getTimestampsFromRange(currentTimeRange: RespondDashboardTimeRangesEnum): { incidentFrom: number, incidentTo: number } {
  const currentDate = new Date();
  let fromDate: Date = null;
  let toDate: Date = null;
  switch (currentTimeRange) {
    case RespondDashboardTimeRangesEnum.CURRENT_WEEK:
      fromDate = new Date();
      fromDate.setDate(currentDate.getDate() - currentDate.getDay());
      fromDate.setHours(0, 0, 0, 0);
      break;
    case RespondDashboardTimeRangesEnum.PREVIOUS_WEEK:
      fromDate = new Date();
      fromDate.setDate(currentDate.getDate() - currentDate.getDay() - 7);
      fromDate.setHours(0, 0, 0, 0);
      toDate = new Date(fromDate);
      toDate.setDate(fromDate.getDate() + 6);
      toDate.setHours(23, 59, 59);
      break;
    case RespondDashboardTimeRangesEnum.CURRENT_MONTH:
      fromDate = new Date();
      fromDate.setDate(1);
      fromDate.setHours(0, 0, 0, 0);
      break;
    case RespondDashboardTimeRangesEnum.PREVIOUS_MONTH:
      fromDate = new Date();
      fromDate.setDate(1);
      fromDate.setMonth(fromDate.getMonth() - 1);
      fromDate.setHours(0, 0, 0, 0);
      toDate = new Date(fromDate);
      toDate.setMonth(fromDate.getMonth() + 1);
      break;
    default:
      break;
  }
  return { incidentFrom: fromDate?.getTime(), incidentTo: toDate?.getTime() };
}
