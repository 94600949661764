import { RequestDirection } from "projects/@common/definitions/consoles.enum";

import { HistoryType, IGetAlertHistoriesRequest, IGetHistoriesRequest, ListHistoryOrderBy } from "../histories.definition";

export class GetHistoriesRequest implements IGetHistoriesRequest {
  organizationId: string;
  owners: string;
  organizationIds: string;
  types: string;
  isVar: boolean;
  isClient: boolean;
  isSystem: boolean;
  write: boolean;
  dateFrom?: number;
  dateTo?: number;
  from?: number = 0;
  size?: number = 15;
  orderBy?: ListHistoryOrderBy = ListHistoryOrderBy.createdAt;
  direction?: RequestDirection = RequestDirection.desc;

  constructor(jsonObj: Partial<IGetHistoriesRequest>) {
    Object.assign(this, jsonObj);
  }

  public getQueryParams(): Partial<IGetHistoriesRequest> {
    return {
      organizationId: this.organizationId,
      owners: this.owners,
      types: this.types,
      organizationIds: this.organizationIds,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      isVar: this.isVar,
      isClient: this.isClient,
      isSystem: this.isSystem,
      write: this.write,
      from: this.from,
      size: this.size,
      orderBy: this.orderBy,
      direction: this.direction,
    };
  }
}
