<div class="drawer-detail--scrollable visible tab-menu">
  <div class="drawer-detail--content">
    <ng-container *ngIf="!isLoadingConditionFields; else spinner;">
      <div class="top-buttons-container">
        <ng-container *ngIf="!isEditing && !isReadonly && !isCreateMode">
          <button
            *display="{ permissions: ['can_update_whitelist'] }"
            ui-button
            icon="icon_modify"
            [custom-icon]="true"
            [isSvg]="true"
            icon-size="medium"
            color="listActionFlat"
            (click)="toggleEditing(true)"
          >
            {{ 'common.action.edit' | t }}
          </button>
        </ng-container>
      </div>

      <!-- Main content using the readonly or editing ng-templates -->
      <ng-container *ngIf="isCreateMode || isEditing; then whitelistEditingTemplate; else whitelistReadonlyTemplate;">
      </ng-container>

    </ng-container>
  </div>
</div>



<!-- Editable -->
<ng-template #whitelistEditingTemplate>
  <div class="whitelist-properties">
    <ui-title [name]="'common.properties' | t"></ui-title>
    <div class="margin--small--top margin--small--bottom">

      <div class="margin--small--bottom">
        <ui-text-field
          name="name"
          control="name"
          maxlength="250"
          [rowsNumber]="1"
          [label]="'whitelists.drawer.tabs.details.properties.name' | t"
          [placeholder]=""
          [group]="form"
          [description]="'detection.playbook.task.specific.max-char' | t:{number: 250}"
          [required]="true"
          [locale]="i18n.currentLocale"
        >
        </ui-text-field>
      </div>

      <div class="margin--small--bottom">
        <ui-text-field
          name="description"
          control="description"
          maxlength="1000"
          [label]="'whitelists.drawer.tabs.details.properties.description' | t"
          [placeholder]=""
          [group]="form"
          [description]="'detection.playbook.task.specific.max-char' | t:{number: 1000}"
          [required]="true"
        >
        </ui-text-field>
      </div>

      <div class="margin--bottom">
        <autocomplete
          *ngIf="alertingUsecasesList && usecaseAutocompleteOptions"
          [required]="true"
          [label]="'whitelists.drawer.tabs.details.properties.usecase' | t"
          [placeholder]="'common.search' | t"
          [autocompleteType]="customAutocompleteType"
          [customValues]="usecaseAutocompleteOptions"
          [selectedItems]="autocompleteSelectedUsecases"
          [initItems]="autocompleteSelectedUsecases"
          [showInInputAtInit]="false"
          [canMultiSelect]="true"
          [oneItemAtATime]="false"
          [preserveSearchQuery]="true"
          (onItemSelected)="handleUsecaseSelectEvent($event)"
        ></autocomplete>
        <div
          *ngFor="let usecase of selectedUsecasesToDisplay"
          class="usecase-item-badge"
        >
          <span>{{usecase.displayValue}}</span>
          <ui-icon
            name="close-circle"
            modifier="gray"
            [tooltip]="'common.action.delete'| t"
            (click)="handleUnselectUsecase(usecase.value)"
          ></ui-icon>
        </div>
      </div>

      <div class="margin--small--bottom">
        <ui-select
          [options]="resolutions"
          [ngModel]="whitelist?.resolution"
          (ngModelChange)="handleResolutionChange($event)"
          [label]="'whitelists.drawer.tabs.details.resolution' | t"
          [required]="true"
        ></ui-select>
      </div>

    </div>
  </div>

  <div class="whitelist-period margin--top">
    <ui-title [name]="'whitelists.drawer.tabs.details.period.title' | t"></ui-title>
    <div class="margin--small--top margin--small--bottom">
      <div class="margin--small--bottom">
        <ui-datetime-picker
          [label]="'whitelists.drawer.tabs.details.period.startsAt' | t"
          [defaultTimestamp]="currentWhitelist?.startsAt"
          [showNowButton]="currentWhitelist?.usecaseIds?.length > 0"
          [showResetButton]="currentWhitelist?.startsAt > 0"
          [locale]="i18n.currentLocale"
          [required]="true"
          [isDisabled]="!currentWhitelist?.usecaseIds?.length > 0"
          [minDate]="null"
          (timestampChangedEvent)="handleStartsAtChanged($event)"
        ></ui-datetime-picker>
      </div>
      <div class="margin--small--bottom">
        <ui-datetime-picker
          [label]="'whitelists.drawer.tabs.details.period.endsAt' | t"
          [defaultTimestamp]="currentWhitelist?.endsAt"
          [showNowButton]="false"
          [showResetButton]="currentWhitelist?.endsAt > 0"
          [locale]="i18n.currentLocale"
          [uiTooltip]="'whitelists.drawer.tabs.details.period.endsAt.tooltip' | t"
          [isDisabled]="!currentWhitelist?.usecaseIds?.length > 0 || !validStartsAt"
          [minDate]="minEndDate"
          (timestampChangedEvent)="handleEndsAtChanged($event)"
        ></ui-datetime-picker>
      </div>
      <div class="margin--small--bottom">
        <ui-toggle
          [toggled]="showDateRange"
          [labelEnabled]="'whitelists.drawer.tabs.details.period.dateRange.switch' | t"
          [labelDisabled]="'whitelists.drawer.tabs.details.period.dateRange.switch' | t"
          [disabledWhenUntoggled]="false"
          [switchRightSide]="true"
          [boldLabel]="false"
          [ignoreLabelClicks]="true"
          [disabled]="!currentWhitelist.startsAt"
          (onClick)="toggleShowDateRange($event)"
        ></ui-toggle>
        <whitelist-weekly-schedule
          *ngIf="showDateRange"
          [whitelist]="currentWhitelist"
          [isDisabled]="isReadonly"
          (weeklyScheduleChange)="handleWeeklyScheduleChanged($event)"
        ></whitelist-weekly-schedule>
      </div>
    </div>
  </div>

  <div class="whitelist-conditions margin--top">
    <ui-title
      [name]="'whitelists.drawer.tabs.details.conditions.title' | t"
      [uiTooltipMessage]="'whitelists.drawer.tabs.details.conditions.title.tooltip' | t"
    ></ui-title>
    <whitelist-conditions
      *ngIf="conditionFields?.length > 0"
      [whitelist]="currentWhitelist"
      [isDisabled]="isReadonly"
      [conditionFields]="conditionFields"
      (conditionsChange)="handleConditionsChanged($event)"
    ></whitelist-conditions>
  </div>



  <ui-form-actions
    layout="centered"
    class="visible"
  >
    <ng-container>
      <button
        ui-button
        color="standard"
        [disabled]="!isValidData"
        (click)="handleSave()"
      >
        {{ 'common.action.save' | t }}
      </button>
      <button
        ui-button
        color="secondary"
        (click)="cancel()"
      >
        {{ 'common.action.cancel' | t }}
      </button>
    </ng-container>
  </ui-form-actions>
</ng-template>


<!-- Readonly -->
<ng-template #whitelistReadonlyTemplate>
  <div class="whitelist-properties margin--top">
    <ui-title [name]="'common.properties' | t"></ui-title>
    <div class="margin--small--top margin--small--bottom">
      <div class="margin--small--bottom">
        <ui-static
          [label]="'whitelists.drawer.tabs.details.properties.name' | t"
          [value]="whitelist.name"
        ></ui-static>
      </div>

      <div class="margin--small--bottom">
        <label ui-label>{{'whitelists.drawer.tabs.details.properties.description' | t}}</label>
        <p class="readonly-description-text">{{whitelist.description || '-'}}</p>
      </div>

      <div class="margin--small--bottom">
        <label ui-label>{{'whitelists.drawer.tabs.details.properties.usecase' | t}}</label>
        <ui-static
          *ngFor="let usecase of whitelist.usecases"
          [value]="getUsecaseDisplayName(usecase.usecaseCatalogId, usecase.name, usecase.datasourceType)"
        ></ui-static>
      </div>

      <div class="margin--small--bottom">
        <label ui-label>{{'whitelists.drawer.tabs.details.properties.status' | t}}</label>
        <whitelist-status [whitelist]="whitelist"></whitelist-status>
      </div>

      <div class="margin--small--bottom">
        <label ui-label>{{'whitelists.drawer.tabs.details.properties.createdBy' | t}}</label>
        <whitelist-created-by [whitelist]="whitelist"></whitelist-created-by>
      </div>

      <div class="margin--small--bottom">
        <ui-static
          [label]="'whitelists.drawer.tabs.details.properties.createdAt' | t"
          [value]="whitelist.createdAt | dt:{withTime:true, locale:i18n.currentLocale}"
        ></ui-static>
      </div>

      <div class="margin--small--bottom">
        <ui-static
          [label]="'whitelists.drawer.tabs.details.resolution' | t"
          [value]="'whitelists.drawer.tabs.details.resolution.' + whitelist?.resolution | t"
        ></ui-static>
      </div>
    </div>
  </div>

  <div class="whitelist-period margin--top">
    <ui-title [name]="'whitelists.drawer.tabs.details.period.title' | t"></ui-title>
    <div class="margin--small--top margin--small--bottom">
      <div class="margin--small--bottom">
        <ui-static
          [label]="'whitelists.drawer.tabs.details.period.startsAt' | t"
          [value]="whitelist.startsAt | dt:{withTime:true, locale:i18n.currentLocale}"
        ></ui-static>
      </div>
      <div class="margin--small--bottom">
        <ui-static
          [label]="'whitelists.drawer.tabs.details.period.endsAt' | t"
          [value]="whitelist.endsAt ? (whitelist.endsAt | dt:{withTime:true, locale:i18n.currentLocale}) : '-'"
        ></ui-static>
      </div>
      <div
        class="margin--small--bottom"
        *ngIf="whitelist.periods.length > 0"
      >
        <label ui-label>{{'whitelists.drawer.tabs.details.period.dateRange.label' | t}}</label>
        <ng-container *ngFor="let period of whitelist.periods">
          <ui-static [value]="getPeriodLabel(period)"></ui-static>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="whitelist-conditions margin--top">
    <ui-title
      [name]="'whitelists.drawer.tabs.details.conditions.title' | t"
      [uiTooltipMessage]="'whitelists.drawer.tabs.details.conditions.title.tooltip' | t"
    ></ui-title>
    <div class="margin--small--top margin--small--bottom">
      <div
        *ngFor="let condition of groupedReadonlyConditions"
        class="margin--small--bottom"
      >
        <div class="flex-box center-horizontal">
          <label ui-label>{{getConditionLabel(condition)}}</label>
          <ui-tooltip [isSmall]="false">{{getConditionDescription(condition)}}</ui-tooltip>
        </div>
        <ui-static
          *ngFor="let value of condition.values"
          value="{{'whitelists.drawer.tabs.details.conditions.operators.' + value.operator | t}}  &quot;{{value.value}}&quot;"
        ></ui-static>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #spinner>
  <ui-spinner
    class="spinner"
    size="medium"
    modifier="secondary"
  ></ui-spinner>
</ng-template>