<div>
    <h2>{{'detection.connector.page.select.instance.modal.title' | t }}</h2>
    <p>{{'detection.connector.page.select.instance.modal.description' | t: {name: connectorName} }}</p>
    <ui-select
        [options]="connectorInstancesOptions"
        name="connectorInstance"
        control="connectorInstance"
        [group]="formGroup"
        [placeholder]="'detection.connector.page.select.instance.modal.input.placeholder' | t"
        [label]="'detection.connector.page.select.instance.modal.input.label' | t"
        [required]="true"
        (optionClick)="instanceSelected($event)">
    </ui-select>

    <div class="actions">
        <button ui-button
            color="standard"
            [disabled]="!!!selectedConnectorInstanceId"
            (click)="confirmActionTrigger()">
            {{ 'detection.connector.page.popup.action.confirm.button.text' | t }}
        </button>
        <button ui-button
            color="secondary"
            (click)="cancelActionTrigger()">
            {{ 'common.action.cancel' | t }}
        </button>
    </div>
</div>