import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEcoAlertConditionFilter } from '@common/services/api/respond/filters/ecoAlertFilter.definition';
import { Field, FieldMap, Option, Rule } from 'angular2-query-builder';
import * as isoCountries from 'i18n-iso-countries';
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';
import { EcoAlertRuleSet } from './ecoAlert-filter-condition.definition';

isoCountries.registerLocale(fr);
isoCountries.registerLocale(en);

@Component({
  selector: 'ecoAlert-filter-conditions',
  templateUrl: './ecoAlert-filter-conditions.component.html',
})
export class EcoAlertFilterConditionsComponent implements OnInit {
  @Input() public filter: Filter;
  @Input() public conditionFields: IEcoAlertConditionFilter[];
  @Input() public isDisabled: boolean;
  public transformedConditionFields: FieldMap;

  @Output() public filterConditionsChange: EventEmitter<EcoAlertRuleSet> = new EventEmitter();

  ngOnInit(): void {
    if (!this.filter.conditions || JSON.stringify(this.filter.conditions) === JSON.stringify({})) {
      this.filter.conditions = { condition: 'and', rules: [] };
    } else {
      this.filter.conditions = this.handleFilterNestedCondition(this.filter.conditions);
    }
    if (this.conditionFields) {
      this.transformedConditionFields = this.transformConditionFields();
    }
  }

  private handleFilterNestedCondition(condition: EcoAlertRuleSet): EcoAlertRuleSet {
    let nestedQuery: string | null = condition.nestedQuery;
    for (const rule of condition.rules) {
      if ((rule as EcoAlertRuleSet)?.nestedQuery) {
        nestedQuery = rule['nestedQuery'];
      }
      if ((rule as Rule)?.field) {
        if (nestedQuery && !((rule as Rule).field.includes(nestedQuery))) {
          rule['field'] = `${nestedQuery}.${rule['field']}`;
        }
      }
    }
    return condition;
  }

  private transformConditionFields(): FieldMap {
    const mapping: FieldMap = {};
    Object.keys(this.conditionFields).forEach((key: string) => {
      mapping[key] = this.transformConditionFieldValue(this.conditionFields[key]);
    });
    return mapping;
  }

  private transformConditionFieldValue(element: IEcoAlertConditionFilter): Field {
    if (element.type === 'enum' && element.enumValues) {
      const options: Option[] = element.enumValues.map((value: string) => ({
        name: value,
        value: value,
      }));
      return {
        name: element.name,
        type: element.type,
        nullable: element.nullable,
        operators: element.operators,
        options: options,
      };
    }

    return {
      name: element.name,
      type: element.type,
      nullable: element.nullable,
      operators: element.operators,
    };
  }
}
