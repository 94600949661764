import { Component, Input, OnInit } from '@angular/core';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { SeverityEnum } from '../../components/shares-by-severity/shares-by-severity.component';

@Component({
  selector: 'app-indicators-tab',
  templateUrl: './indicators-tab.component.html',
  styleUrls: [ './indicators-tab.component.scss' ],
})
export class IndicatorsTabComponent implements OnInit {
  @Input() enabledWidgets: any;
  public sharesBySeverityData: {
    data: Array<{ label: string; severity: SeverityEnum; value: number; }>;
    severityLegend: Record<SeverityEnum, string>;
  };
  public isLoading = true;

  public featureFlagEnum = FeaturesEnum;

  constructor(private readonly sharesApiService: SharesApiService, private readonly noticeService: NoticeService) {
  }

  ngOnInit(): void {
    this.sharesApiService.getSharesBySeverityLevel()
      .then((response) => {
        this.sharesBySeverityData = response;
      })
      .catch(() => {
        this.noticeService.notifyUser(new Notice("dashboard.admin.sharedFiles.severity.distribution.500.error", NoticeLevels.ERROR));
      })
      .finally(() => this.isLoading = false);
  }
}
