import { Component, OnInit } from '@angular/core';
import { ExternalLinkService } from '@common/services/external-links.service';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SessionService } from 'projects/@common/modules/session/session.service';
import { GraphToolkitService } from 'projects/@common/services/graph-toolkit.service';

@Component({
  selector: 'org-root',
  templateUrl: './org.app.component.html',
})
export class OrgComponent implements OnInit {
  constructor(
    private i18n: I18nService,
    private session: SessionService,
    private display: DisplayService,
    private graphToolkitService: GraphToolkitService,
    private externalLinksService: ExternalLinkService
  ) {}

  async ngOnInit() {
    await this.i18n.initialize();
    await this.session.loadBrowserSessionIfNeeded();
    await this.display.loadDataFromBrowserIfNeeded();
    await this.graphToolkitService.activateGraphToolkit();
  }
}
