import { Injectable } from '@angular/core';
import { RequestDirection } from 'projects/@common/definitions/consoles.enum';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { HistoriesApi } from 'projects/@common/services/api/respond/histories/histories.api';
import { HistoryType, IListHistoryRequest, IWhitelistHistory, ListHistoryOrderBy } from 'projects/@common/services/api/respond/histories/histories.definition';
import { Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';
import { WhitelistsApi } from 'projects/@common/services/api/respond/whitelists/whitelists.api';
import { ICreateWhitelistRequest, IDescribeWhitelistRequest, IDescribeWhitelistResponse, IListWhitelistsRequest, IUpdateWhitelistRequest, IWhitelistRepresentation, WhitelistsViewModeEnum } from 'projects/@common/services/api/respond/whitelists/whitelists.definitions';
import { ResponseUtils } from 'projects/@common/utils/response-utils';
import { normalizeString } from 'projects/@common/utils/utils';

export enum ListWhitelistPageEnum {
  WHITELIST_LIST_PAGE = 'WHITELIST_LIST_PAGE',
  INCIDENT_DETAIL_PAGE = 'INCIDENT_DETAIL_PAGE'
}

export type ListWhitelistsPageContext = {
  enum: ListWhitelistPageEnum;
  organizationId?: string;
  incidentId?: string;
};

@Injectable({
  providedIn: 'root',
})
export class WhitelistsService {
  public whitelistsData: IWhitelistRepresentation[];
  public total: number = 0;
  public isLoading: boolean = false;
  public selectedRows: Array<IWhitelistRepresentation>;

  public whitelist: Whitelist;
  public isLoadingWhitelist: boolean = false;

  public whitelistHistory: IWhitelistHistory[];
  public isLoadingHistory: boolean = false;
  public isLoadingMoreHistory: boolean = false;
  public canLoadMoreHistory: boolean = false;
  public historySize: number = 10;

  private _whitelistsServerData: IWhitelistRepresentation[];
  private _listViewMode: WhitelistsViewModeEnum;
  private _debounceTimeout: NodeJS.Timeout;
  private _pageContext: ListWhitelistsPageContext;
  private _searchText: any;

  constructor(
    private readonly whitelistsApi: WhitelistsApi,
    private readonly noticeService: NoticeService,
    private readonly historyService: HistoriesApi,
    private readonly translatedObject: TranslatedObjectPipe
  ) {
    this.setDefaultSettings();
  }

  public get organizationId(): string {
    return this._pageContext.organizationId;
  }

  public get listViewMode(): WhitelistsViewModeEnum {
    return this._listViewMode;
  }

  public get searchText(): string {
    return this._searchText;
  }

  public setDefaultSettings(): void {
    this.isLoading = false;
    this.whitelistsData = [];
    this.total = 0;
    this.selectedRows = [];
    this._whitelistsServerData = [];
    this._listViewMode = WhitelistsViewModeEnum.GROUPED_BY_STATUS;
    this._pageContext = {
      enum: ListWhitelistPageEnum.WHITELIST_LIST_PAGE,
      organizationId: null,
      incidentId: null,
    };
    this._searchText = null;
  }

  public setPageContext(value: ListWhitelistsPageContext): void {
    this._pageContext = value;
  }

  public setOrganizationId(id: string) {
    this._pageContext.organizationId = id;
  }

  public setListViewMode(mode: WhitelistsViewModeEnum) {
    this._listViewMode = mode;
    this.initWhitelistsData();
  }

  public fetchWhitelists(): void {
    clearTimeout(this._debounceTimeout);
    this.isLoading = true;
    this.total = 0;
    const request: IListWhitelistsRequest = {
      organizationId: this._pageContext.organizationId,
    };
    this.whitelistsApi.listWhitelists(request).subscribe(
      (response: ResponseUtils<IWhitelistRepresentation>) => {
        this.setWhitelistData(response.getItems());
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("whitelists.fetch.list.error", NoticeLevels.ERROR));
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  public createWhitelist(whitelist: Whitelist): void {
    const request: ICreateWhitelistRequest = {
      organizationId: whitelist.organizationId,
      name: whitelist.name,
      description: whitelist.description,
      usecaseIds: whitelist.usecaseIds,
      startsAt: whitelist.startsAt,
      endsAt: whitelist.endsAt,
      periods: whitelist.periods,
      conditions: whitelist.conditions,
      resolution: whitelist.resolution,
    };
    this.whitelistsApi.createWhitelist(request).subscribe(
      (response: IDescribeWhitelistResponse) => {
        this.whitelist = this.getWhitelistInstanceFromDescribeResponse(response);
        this.noticeService.notifyUser(new Notice("whitelists.create.success", NoticeLevels.SUCCESS));
        this.fetchWhitelists();
        this.fetchHistory(this.whitelist.id, this.whitelist.organizationId);
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("whitelists.create.error", NoticeLevels.ERROR));
      }
    );
  }

  public updateWhitelist(whitelist: Whitelist): void {
    const request: IUpdateWhitelistRequest = {
      organizationId: whitelist.organizationId,
      id: whitelist.id,
      name: whitelist.name,
      description: whitelist.description,
      usecaseIds: whitelist.usecaseIds,
      startsAt: whitelist.startsAt,
      endsAt: whitelist.endsAt,
      periods: whitelist.periods,
      conditions: whitelist.conditions,
      resolution: whitelist.resolution,
    };
    this.whitelistsApi.updateWhitelist(request).subscribe(
      (response: IDescribeWhitelistResponse) => {
        this.whitelist = this.getWhitelistInstanceFromDescribeResponse(response);
        this.noticeService.notifyUser(new Notice("whitelists.update.success", NoticeLevels.SUCCESS));
        this.fetchWhitelists();
        this.fetchHistory(this.whitelist.id, this.whitelist.organizationId);
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("whitelists.update.error", NoticeLevels.ERROR));
      }
    );
  }

  public fetchWhitelist(request: IDescribeWhitelistRequest): void {
    this.isLoadingWhitelist = true;
    this.whitelistsApi.describeWhitelist(request).subscribe(
      (response: IDescribeWhitelistResponse) => {
        this.whitelist = this.getWhitelistInstanceFromDescribeResponse(response);
        this.isLoadingWhitelist = false;
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("whitelists.fetch.describe.error", NoticeLevels.ERROR));
        this.isLoadingWhitelist = false;
      }
    );
  }

  public async deleteWhitelists(whitelists: Whitelist[]): Promise<void> {
    const promises = whitelists.map((whitelist: Whitelist) => this.whitelistsApi.deleteWhitelist({
      organizationId: whitelist.organizationId,
      id: whitelist.id,
    }));
    try {
      await Promise.all(promises);
      this.noticeService.notifyUser(new Notice("whitelists.delete.success", NoticeLevels.SUCCESS));
    } catch (error) {
      this.noticeService.notifyUser(new Notice("whitelists.delete.error", NoticeLevels.ERROR));
    } finally {
      this.fetchWhitelists();
    }
  }

  public fetchHistory(whitelistId: string, organizationId: string): void {
    this.isLoadingHistory = true;
    const request: IListHistoryRequest = {
      type: HistoryType.WHITELIST,
      whitelistId: whitelistId,
      organizationId: organizationId,
      orderBy: ListHistoryOrderBy.createdAt,
      direction: RequestDirection.desc,
      size: this.historySize,
    };
    this.historyService.listWhitelistHistory(request).subscribe(
      (response) => {
        this.whitelistHistory = response.getItems();
        this.canLoadMoreHistory = response.canLoadMore();
        this.isLoadingHistory = false;
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("whitelists.fetch.history.error", NoticeLevels.ERROR));
        this.isLoadingHistory = false;
      }
    );
  }

  public loadMoreHistory(): void {
    this.isLoadingMoreHistory = true;
    const request: IListHistoryRequest = {
      type: HistoryType.WHITELIST,
      whitelistId: this.whitelist.id,
      organizationId: this.whitelist.organizationId,
      orderBy: ListHistoryOrderBy.createdAt,
      direction: RequestDirection.desc,
      from: this.whitelistHistory.length,
      size: this.historySize,
    };
    this.historyService.listWhitelistHistory(request).subscribe(
      (response) => {
        this.whitelistHistory = [ ...this.whitelistHistory, ...response.getItems() ];
        this.canLoadMoreHistory = response.canLoadMore();
        this.isLoadingMoreHistory = false;
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("whitelists.fetch.history.error", NoticeLevels.ERROR));
        this.isLoadingMoreHistory = false;
      }
    );
  }

  public handleDebouncedFetch(): void {
    clearTimeout(this._debounceTimeout);
    this._debounceTimeout = setTimeout(() => this.fetchWhitelists(), 300);
  }

  public setSearchText(searchText: string): void {
    this._searchText = normalizeString(searchText);
    this.initWhitelistsData();
  }

  public setWhitelistData(whitelistsData: IWhitelistRepresentation[]): void {
    this._whitelistsServerData = whitelistsData || [];
    this.initWhitelistsData();
    this.isLoading = false;
  }

  private initWhitelistsData() {
    this.whitelistsData = this._whitelistsServerData.map((whitelist: IWhitelistRepresentation) => new Whitelist(whitelist));
    this.filterBySearchText();
    this.total = this.whitelistsData.length;
    this.selectedRows = [];
  }

  private filterBySearchText(): void {
    if (this._searchText) {
      this.whitelistsData = this.whitelistsData.filter((whitelist) => {
        const searchString = normalizeString([ whitelist.name, whitelist.createdByName ].join(' '));
        return searchString.includes(this._searchText);
      });
    }
  }

  private getWhitelistInstanceFromDescribeResponse(response: IDescribeWhitelistResponse): Whitelist {
    return new Whitelist({
      ...response.whitelist,
      ...response.organization,
      usecases: response.usecases,
    });
  }
}
