import { Injectable } from '@angular/core';
import { RequestDirection } from 'projects/@common/definitions/consoles.enum';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { FiltersApi } from 'projects/@common/services/api/respond/filters/filters.api';
import { FiltersViewModeEnum, ICreateFilterRequest, IDescribeFilterRequest, IDescribeFilterResponse, IFilterRepresentation, IListFiltersRequest, IUpdateFilterRequest } from 'projects/@common/services/api/respond/filters/filters.definitions';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';
import { HistoriesApi } from 'projects/@common/services/api/respond/histories/histories.api';
import { HistoryType, IFilterHistory, IListHistoryRequest, ListHistoryOrderBy } from 'projects/@common/services/api/respond/histories/histories.definition';
import { ResponseUtils } from 'projects/@common/utils/response-utils';
import { normalizeString } from 'projects/@common/utils/utils';

export enum ListFilterPageEnum {
  FILTER_LIST_PAGE = 'FILTER_LIST_PAGE',
  INCIDENT_DETAIL_PAGE = 'INCIDENT_DETAIL_PAGE'
}

export type ListFiltersPageContext = {
  enum: ListFilterPageEnum;
  organizationId?: string;
  incidentId?: string;
};

export type FilterAttributeSearch = 'createdById' | 'organizationId';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  public filtersData: IFilterRepresentation[];
  public total: number = 0;
  public isLoading: boolean = false;
  public selectedRows: Array<IFilterRepresentation>;

  public filter: Filter;
  public isLoadingFilter: boolean = false;

  public filterHistory: IFilterHistory[];
  public isLoadingHistory: boolean = false;
  public isLoadingMoreHistory: boolean = false;
  public canLoadMoreHistory: boolean = false;
  public historySize: number = 10;
  public hiddenFiltersOnly: boolean = false;
  public visibleFiltersOnly: boolean = false;

  private _filtersServerData: IFilterRepresentation[];
  private _listViewMode: FiltersViewModeEnum;
  private _debounceTimeout: NodeJS.Timeout;
  private _pageContext: ListFiltersPageContext;
  private _searchText: any;

  constructor(
    private readonly filtersApi: FiltersApi,
    private readonly noticeService: NoticeService,
    private readonly historyService: HistoriesApi,
    private readonly translatedObject: TranslatedObjectPipe
  ) {
    this.setDefaultSettings();
  }

  public get organizationId(): string {
    return this._pageContext.organizationId;
  }

  public get listViewMode(): FiltersViewModeEnum {
    return this._listViewMode;
  }

  public get searchText(): string {
    return this._searchText;
  }

  public setDefaultSettings(): void {
    this.isLoading = false;
    this.filtersData = [];
    this.total = 0;
    this.selectedRows = [];
    this._filtersServerData = [];
    this._listViewMode = FiltersViewModeEnum.SIMPLE_LIST;
    this._pageContext = {
      enum: ListFilterPageEnum.FILTER_LIST_PAGE,
      organizationId: null,
      incidentId: null,
    };
    this._searchText = null;
    this.visibleFiltersOnly = false;
    this.hiddenFiltersOnly = false;
  }

  public setPageContext(value: ListFiltersPageContext): void {
    this._pageContext = value;
  }

  public setOrganizationId(id: string) {
    this._pageContext.organizationId = id;
  }

  public setListViewMode(mode: FiltersViewModeEnum) {
    this._listViewMode = mode;
    this.initFiltersData();
  }

  public fetchFilters() {
    clearTimeout(this._debounceTimeout);
    this.isLoading = true;
    this.total = 0;
    const request: IListFiltersRequest = {
      organizationId: this._pageContext.organizationId,
    };
    if (this.visibleFiltersOnly) {
      request.visibleOnly = true;
    }
    if (this.hiddenFiltersOnly) {
      request.hiddenOnly = true;
    }
    const observable = this.filtersApi.listFilters(request);
    observable.subscribe(
      (response: ResponseUtils<IFilterRepresentation>) => {
        this.setFilterData(response.getItems());
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("filters.fetch.list.error", NoticeLevels.ERROR));
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
    return observable;
  }

  public createFilter(filter: Filter): void {
    const request: ICreateFilterRequest = {
      organizationId: filter.organizationId,
      name: filter.name,
      description: filter.description,
      usecaseIds: filter.usecaseIds,
      conditions: filter.conditions,
      monitors: filter.monitors,
      periods: filter.periods,
      isHidden: filter.hidden,
    };
    this.filtersApi.createFilter(request).subscribe(
      (response: IDescribeFilterResponse) => {
        this.filter = this.getFilterInstanceFromDescribeResponse(response);
        this.noticeService.notifyUser(new Notice("filters.create.success", NoticeLevels.SUCCESS));
        this.fetchFilters();
        this.fetchHistory(this.filter.id, this.filter.organizationId);
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("filters.create.error", NoticeLevels.ERROR));
      }
    );
  }

  public updateFilter(filter: Filter): void {
    const request: IUpdateFilterRequest = {
      organizationId: filter.organizationId,
      id: filter.id,
      name: filter.name,
      description: filter.description,
      usecaseIds: filter.usecaseIds,
      conditions: filter.conditions,
      periods: filter.periods,
      monitors: filter.monitors,
      isHidden: filter.hidden,
    };
    this.filtersApi.updateFilter(request).subscribe(
      (response: IDescribeFilterResponse) => {
        this.filter = this.getFilterInstanceFromDescribeResponse(response);
        this.noticeService.notifyUser(new Notice("filters.update.success", NoticeLevels.SUCCESS));
        this.fetchFilters();
        this.fetchHistory(this.filter.id, this.filter.organizationId);
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("filters.update.error", NoticeLevels.ERROR));
      }
    );
  }

  public fetchFilter(request: IDescribeFilterRequest): void {
    this.isLoadingFilter = true;
    this.filtersApi.describeFilter(request).subscribe(
      (response: IDescribeFilterResponse) => {
        this.filter = this.getFilterInstanceFromDescribeResponse(response);
        this.isLoadingFilter = false;
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("filters.fetch.describe.error", NoticeLevels.ERROR));
        this.isLoadingFilter = false;
      }
    );
  }

  public async deleteFilters(filters: Filter[]): Promise<void> {
    const promises = filters.map((filter: Filter) => this.filtersApi.deleteFilter({
      organizationId: filter.organizationId,
      id: filter.id,
    }));
    try {
      await Promise.all(promises);
      this.noticeService.notifyUser(new Notice("filters.delete.success", NoticeLevels.SUCCESS));
    } catch (error) {
      this.noticeService.notifyUser(new Notice("filters.delete.error", NoticeLevels.ERROR));
    } finally {
      this.fetchFilters();
    }
  }

  public fetchHistory(filterId: string, organizationId: string): void {
    this.isLoadingHistory = true;
    const request: IListHistoryRequest = {
      type: HistoryType.FILTER,
      filterId: filterId,
      organizationId: organizationId,
      orderBy: ListHistoryOrderBy.createdAt,
      direction: RequestDirection.desc,
      size: this.historySize,
    };
    this.historyService.listFilterHistory(request).subscribe(
      (response) => {
        this.filterHistory = response.getItems();
        this.canLoadMoreHistory = response.canLoadMore();
        this.isLoadingHistory = false;
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("filters.fetch.history.error", NoticeLevels.ERROR));
        this.isLoadingHistory = false;
      }
    );
  }

  public loadMoreHistory(): void {
    this.isLoadingMoreHistory = true;
    const request: IListHistoryRequest = {
      type: HistoryType.FILTER,
      filterId: this.filter.id,
      organizationId: this.filter.organizationId,
      orderBy: ListHistoryOrderBy.createdAt,
      direction: RequestDirection.desc,
      from: this.filterHistory.length,
      size: this.historySize,
    };
    this.historyService.listFilterHistory(request).subscribe(
      (response) => {
        this.filterHistory = [ ...this.filterHistory, ...response.getItems() ];
        this.canLoadMoreHistory = response.canLoadMore();
        this.isLoadingMoreHistory = false;
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("filters.fetch.history.error", NoticeLevels.ERROR));
        this.isLoadingMoreHistory = false;
      }
    );
  }

  public handleDebouncedFetch(): void {
    clearTimeout(this._debounceTimeout);
    this._debounceTimeout = setTimeout(() => this.fetchFilters(), 300);
  }

  public setSearchText(searchText: string): void {
    this._searchText = normalizeString(searchText);
    this.initFiltersData();
  }

  public setFilterData(filtersData: IFilterRepresentation[]): void {
    this._filtersServerData = filtersData || [];
    this.initFiltersData();
    this.isLoading = false;
  }

  public filtersByAttribute(attributeName: FilterAttributeSearch, values: string[]) {
    this.filtersData = this._filtersServerData.map((filter: IFilterRepresentation) => new Filter(filter));
    if (values && values.length > 0) {
      this.filtersData = this.filtersData.filter((filter: IFilterRepresentation) => values.includes(filter[attributeName]));
    }
    this.total = this.filtersData.length;
    this.selectedRows = [];
  }

  private initFiltersData() {
    this.filtersData = this._filtersServerData.map((filter: IFilterRepresentation) => new Filter(filter));
    this.filterBySearchText();
    this.total = this.filtersData.length;
    this.selectedRows = [];
  }

  private filterBySearchText(): void {
    if (this._searchText) {
      this.filtersData = this.filtersData.filter((filter) => {
        const searchString = normalizeString([ filter.name, filter.createdByName ].join(' '));
        return searchString.includes(this._searchText);
      });
    }
  }

  private getFilterInstanceFromDescribeResponse(response: IDescribeFilterResponse): Filter {
    return new Filter({
      ...response.filter,
      ...response.organization,
      usecases: response.usecases,
      monitors: response.monitors,
    });
  }
}
