import { HttpClient } from "@angular/common/http";
import { FieldMap } from "angular2-query-builder";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IListFilterFieldsRequest } from "../filters/filters.definitions";
import { ICreateCustomQueryRequest, IDeleteCustomQueryRequest, IDeleteCustomQueryResponse, IDescribeQueryRequest, IDescribeQueryResponse, IExecuteDefaultQueryRequest, IExecuteOrganizationQueryRequest, IExecuteQueryResponse, IGetBasicQueryRequest, IGetBasicQueryResponse, IListQueriesRequest, IQueriesApi, IQueryItem, IUpdateCustomQueryRequest } from "./queries.definitions";
import { UrlUtils } from "@common/utils/utils";


export class CustomerQueriesApi extends EcoApi implements IQueriesApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  constructor(http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listQueries(request: IListQueriesRequest): Observable<ResponseUtils<IQueryItem>> {
    const path = `${this.host}/me/queries`;
    return this.http.get<IRespondResponse<IQueryItem>>(path).pipe(map((response) => new ResponseUtils<IQueryItem>(response)));
  }

  public describeQuery(request: IDescribeQueryRequest): Observable<IDescribeQueryResponse> {
    const path = `${this.host}/me/queries/type/${request.type}/${request.customQueryId}`;
    return this.http.get<IDescribeQueryResponse>(path);
  }

  public createCustomQuery(request: ICreateCustomQueryRequest): Observable<IDescribeQueryResponse> {
    const path = `${this.host}/me/queries/type/ORGANIZATION`;
    return this.http.post<IDescribeQueryResponse>(path, request);
  }

  public updateCustomQuery(request: IUpdateCustomQueryRequest): Observable<IDescribeQueryResponse> {
    const path = `${this.host}/me/queries/type/ORGANIZATION/${request.id}`;
    return this.http.put<IDescribeQueryResponse>(path, request);
  }

  public deleteCustomQuery(request: IDeleteCustomQueryRequest): Observable<IDeleteCustomQueryResponse> {
    const path = `${this.host}/me/queries/type/ORGANIZATION/${request.id}`;
    return this.http.delete<IDeleteCustomQueryResponse>(path);
  }

  public getBasicQueryUsecase(request: IGetBasicQueryRequest): Observable<IGetBasicQueryResponse> {
    const path = `${this.host}/me/queries/type/DEFAULT/${request.customQueryId}/query?${UrlUtils.jsonToQueryParams(request.parameters)}`;
    return this.http.get<IGetBasicQueryResponse>(path);
  }

  public getAdvancedQueryAggregationFields(request: IListFilterFieldsRequest): Observable<FieldMap> {
    const url = `${this.host}/me/filters/fields`;
    return this.http.get<FieldMap>(url);
  }

  public executeDefaultQueryUsecase(request: IExecuteDefaultQueryRequest): Observable<IExecuteQueryResponse> {
    const path = `${this.host}/me/queries/type/DEFAULT/${request.customQueryId}/execute`;
    return this.http.post<IExecuteQueryResponse>(path, request);
  }

  public executeOrganizationQueryUsecase(request: IExecuteOrganizationQueryRequest): Observable<IExecuteQueryResponse> {
    const path = `${this.host}/me/queries/type/ORGANIZATION/execute`;
    return this.http.post<IExecuteQueryResponse>(path, request);
  }
}
