<div
  page
  [appPagePaths]="['mdRespond', 'datasources']"
  [hasTopSection]="true"
  [tooltipTitle]="'detection.datasource.title' | t"
  [disableOrganizationSelector]="true"
  [previewMode]="'deprecated'"
>
  <ng-container topSection>
    <capacity-card
      logo="icon_datasource_souscrit"
      [loading]="loading"
      [capacityNumber]="organization?.totalSubscribedCapacity"
      [topDescription]="'detection.datasource.page.capacity.total' | t"
      [bottomDescription]="'detection.datasource.page.capacity.day' | t"
    >
    </capacity-card>
  </ng-container>
  <inventory-layout [previewMode]="preview" *ngIf="datasources">
    <ng-container actions>
      <ui-table-total-tool
        [total]="datasourcesInventoryActions.total"
        [locale]="i18n.currentLocale"
      ></ui-table-total-tool>
      <ui-table-search-tool
        [placeholder]="'detection.datasource.inventory.table.search.placeholder' | t"
        [query]="datasourcesInventoryActions.requestsQuery"
        (clear)="datasourcesInventoryActions.handleSearchClear()"
        (query)="datasourcesInventoryActions.handleSearchQuery($event)"
      ></ui-table-search-tool>
      <ui-table-filter-tool
        class="ui-table-filter-tool"
        [tableFilterData]="tableFilterData"
        (select)="datasourcesInventoryActions.handleFilter($event)"
        [titlesDifferentThanOptions]="[datasourcesInventoryActions.datasourceCategoryEnum.ALL]"
      ></ui-table-filter-tool>
      <ui-table-refresh-tool
        [loading]="datasourcesInventoryActions.isLoading"
        (click)="datasourcesInventoryActions.handleRefresh()"
      ></ui-table-refresh-tool>
    </ng-container>

    <div class="card-container" *ngIf="!datasourcesInventoryActions.isLoading">
      <ng-container *ngFor="let item of datasources">
        <div
          class="card-container--wrapper"
          *ngIf="datasources.length > 0"
          (click)="navigate(item)"
        >
          <datasource-card [data]="item"></datasource-card>
        </div>
      </ng-container>
      <p class="card-container--no-content" *ngIf="!loading && datasources.length == 0 && !datasourcesInventoryActions.isLoading">
        {{ 'detection.datasource.no-result' | t }}
      </p>
    </div>
    <ui-spinner *ngIf="datasourcesInventoryActions.isLoading" size="medium"></ui-spinner>
  </inventory-layout>
</div>
