<div class="collapsable-library" [ngStyle]="isDraggable ? {cursor: 'pointer'}: { cursor: 'default'}">
  <div
    [ngClass]="
      isCollapsed
        ? 'collapsable-library--header'
        : 'collapsable-library--header-open'
    "
    (click)="toggleCollapse(); $event.stopPropagation();"
  >
    <div class="collapsable-library--header-top">
      <div class="collapsable-library--header-top-left">
        <div class="collapsable-library--header-top-left-icon"></div>
        <div class="collapsable-library--header-top-left-title">
          {{ playbookTemplate.name | translatedObject }}
          <div class="collapsable-library--header-top-left-subtitle">
            {{ playbookTemplate.playbookTemplateId }}
          </div>
          <div class="collapsable-library--header-top-tags">
            <div
              *ngFor="
                let datasourceType of playbookTemplate.datasourceTypes;
                let i = index;
                first as isFirst
              "
              [class.first]="isFirst"
            >
              <div class="tags">{{ datasourceType | titlecase }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapsable-library--header-top-chevron">
        <ui-icon
          *ngIf="isCollapsable"
          class="collapsable-library--header--chevron"
          modifier="on-header"
          [name]="isCollapsed ? 'chevron-right' : 'chevron-down'"
          size="medium"
        ></ui-icon>
      </div>
    </div>
  </div>
  <div *ngIf="!isCollapsed" class="collapsable-library--content">
    {{ playbookTemplate.description | translatedObject }}
  </div>
</div>
