<div
  *ngFor="let period of weeklySchedule;"
  class="day-timerange-container"
  [class.invalid]="(period.end.hour || period.end.minute) && !isValidPeriod(period)"
>
  <ui-select-single
    [value]="period.dayOfWeek"
    [initialValue]="period.dayOfWeek ? period.dayOfWeek : null"
    [displayInitialValue]="true"
    [options]="selectWeekdays"
    [showOptions]="false"
    [readonly]="isDisabled"
    placeholder=""
    displayValueKey="displayValue"
    (valueChange)="handleDayOfWeekChanged(period, $event)"
  ></ui-select-single>

  <ui-time-picker
    class="time-picker"
    [isReadonly]="isPeriodTimeInputsEnabled(period)"
    [isDisabled]="isPeriodTimeInputsEnabled(period)"
    [defaultHour]="period.start.hour"
    [defaultMinute]="period.start.minute"
    [shouldOutputAsHourMinuteObject]="true"
    (timeChangedEvent)="handleTimeChangeEvent(period, 'start', $event)"
  ></ui-time-picker>

  <span>{{'common.to' | t}}</span>

  <ui-time-picker
    class="time-picker"
    [isReadonly]="isPeriodTimeInputsEnabled(period)"
    [isDisabled]="isPeriodTimeInputsEnabled(period)"
    [defaultHour]="period.end.hour"
    [defaultMinute]="period.end.minute"
    [shouldOutputAsHourMinuteObject]="true"
    (timeChangedEvent)="handleTimeChangeEvent(period, 'end', $event)"
  ></ui-time-picker>

  <button
    *ngIf="weeklySchedule.length > 1"
    ui-button
    class="day-timerange-delete-button"
    color="drawerCloseFlat"
    icon="delete"
    [tooltip]="'common.action.delete'| t"
    (click)="deleteWeeklyScheduleInput(period);"
  >
  </button>
</div>

<div
  *ngIf="!isDisabled && isValidWeeklySchedule"
  class="add-weekly-schedule-container"
  (click)="addWeeklyScheduleInput()"
>
  <img src="assets/favicons/icon_add_circle.svg">
  <ui-static [value]="'whitelists.drawer.tabs.details.period.dateRange.add' | t"></ui-static>
</div>