import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationFrequency } from 'projects/@common/definitions/notificationFrequency.enum';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { FormErrorUtils } from 'projects/@common/utils/formErrors-utils';
import { CustomValidators } from 'projects/@common/utils/validators';

const DEFAULT_VALUES = {
  choice0: 30,
  choice1: 60,
  choice2: 90,
};
@Component({
  selector: 'shares-silencing-duration',
  templateUrl: './shares-silencing-duration.component.html',
  styleUrls: [ './shares-silencing-duration.component.scss' ],
})
export class SharesSilencingDurationComponent implements OnInit {
  @Input() public currentSettings: OrganizationShareSettings;
  @Input() public isLoading = false;

  public isEditing = false;
  public silencingForm: UntypedFormGroup;

  public isActivated = true;
  private formErrorUtils: FormErrorUtils;

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private formBuilder: UntypedFormBuilder,
    private i18n: I18nService,
    private noticeService: NoticeService
  ) {
    this.formErrorUtils = new FormErrorUtils(this.i18n);
  }

  ngOnInit() {
    this.initForm(this.currentSettings);
  }

  public get choice0Control(): AbstractControl {
    return this.silencingForm.get('choice0');
  }

  public get choice1Control(): AbstractControl {
    return this.silencingForm.get('choice1');
  }

  public get choice2Control(): AbstractControl {
    return this.silencingForm.get('choice2');
  }

  public get isDefaultValues(): boolean {
    return JSON.stringify(this.silencingForm.getRawValue()) == JSON.stringify(DEFAULT_VALUES);
  }

  public getErrorMessage(control: AbstractControl) {
    return this.formErrorUtils.getErrorMessage(control);
  }

  private initForm(currentSettings?: OrganizationShareSettings) {
    const validators = [
      Validators.required,
      CustomValidators.numberValidatorNotZero,
      CustomValidators.isLessOrEqualThan(365, 'validSilenceChoice'),
    ];
    this.silencingForm = this.formBuilder.group({
      choice0: this.formBuilder.control(currentSettings?.silencingOptions?.[0] || 30),
      choice1: this.formBuilder.control(currentSettings?.silencingOptions?.[1] || 60),
      choice2: this.formBuilder.control(currentSettings?.silencingOptions?.[2] || 90),
    });

    this.isActivated = currentSettings?.silencingOptions?.length > 0;

    this.choice1Control.setValidators([ ...validators, CustomValidators.isGreaterThan(this.choice0Control) ]);
    this.choice1Control.updateValueAndValidity();
    this.choice0Control.valueChanges.subscribe(() => {
      this.choice1Control.markAsDirty();
      this.choice1Control.updateValueAndValidity();
    });

    this.choice2Control.setValidators([ ...validators, CustomValidators.isGreaterThan(this.choice1Control) ]);
    this.choice2Control.updateValueAndValidity();
    this.choice1Control.valueChanges.subscribe(() => {
      this.choice2Control.markAsDirty();
      this.choice2Control.updateValueAndValidity();
    });
  }

  public cancel() {
    this.initForm(this.currentSettings);
  }

  public get optionsIndex() {
    return [ 0, 1, 2 ];
  }

  public save(): void {
    this.organizationsApiService
      .updateOrganizationSettings({
        newSharesSettings: {
          silencingOptions: this.isActivated ? Object.values(this.silencingForm.value).map((value) => +value) : null,
        },
      })
      .then((response) => {
        this.currentSettings = response;
        this.initForm(response);
        this.noticeService.notifyUser({
          message: 'settings.notification.silencing.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.notification.silencing.snack.error',
          level: NoticeLevels.ERROR,
        });
      });
  }

  public get isValid() {
    return this.silencingForm.valid;
  }

  public restoreDefaults() {
    this.silencingForm.patchValue(DEFAULT_VALUES);
  }

  public selectDayOfTheWeek(dayOfTheWeek: string) {
    this.silencingForm.patchValue({ ...this.silencingForm.getRawValue(), weekDay: dayOfTheWeek });
  }

  public frequencyChanged(event: NotificationFrequency) {
    if (event === NotificationFrequency.WEEKLY && this.silencingForm.value.weekDay > 6) {
      this.silencingForm.patchValue({ ...this.silencingForm.getRawValue(), weekDay: 5 });
    }
    if (event === NotificationFrequency.MONTHLY && this.silencingForm.value.weekDay > 31) {
      this.silencingForm.patchValue({ ...this.silencingForm.getRawValue(), weekDay: 31 });
    }
  }

  public toggleSilencingActivation() {
    this.isActivated = !this.isActivated;
  }
}
