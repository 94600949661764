<ui-card [class.no-padding]="!!disabledGrouping">
  <div class="top-action-buttons-container">
    <button
      *ngIf="!shouldHideCreateButton"
      ui-button
      color="linkFlat"
      [disabled]="isLoading"
      [custom-icon]="true"
      [isSvg]="true"
      icon="icon_create"
      icon-size="medium"
      (click)="onClickCreate()"
    >
      {{ 'common.action.create' | t }}</button>
    <button
      *ngIf="!shouldHideDeleteButton"
      ui-button
      color="linkFlat"
      [disabled]="isLoading"
      [custom-icon]="true"
      [isSvg]="true"
      icon="icon_delete"
      icon-size="medium"
      (click)="onClickDelete()"
    >
      {{ ('common.action.delete' | t) + " (" + selectedCount +")" }}
    </button>
  </div>

  <ng-container *ngIf="!disabledGrouping">
    <ui-collapsable-card
      *ngFor="let assetGroup of assetGroups; trackBy: trackByName"
      class="asset-group"
      [cardTitle]="assetGroup.name"
      [headerIcon]="assetGroup.icon"
      [isBigHeaderImage]="true"
      [numberItems]="assetGroup.assets.length"
      [isCollapsed]="!assetGroup.isExpanded"
    >
      <ui-static-table
        [dataSource]="assetGroup.assets"
        [columnsDef]="assetGroup.columnsDef"
        [loading]="isLoading"
        [isCustomSorting]="isSimpleList"
        [sort]="isSimpleList ? sort : 'name'"
        [direction]="isSimpleList ? direction : 'asc'"
        [locale]="locale"
        [canSelectOnlyOne]="false"
        [isSelectingOnlyOneAtATime]="false"
        [addPaddingOnFirst]="false"
        [hoverable]="true"
        (handleCustomSort)="handleSort($event)"
        (on-row-click)="onClickRow($event)"
        (checkbox-event)="handleCheckboxEvent($event.event, $event.item)"
      >
      </ui-static-table>
    </ui-collapsable-card>
  </ng-container>

  <ng-container *ngIf="disabledGrouping">
    <ui-static-table
      [dataSource]="assetGroups[0].assets"
      [columnsDef]="assetGroups[0].columnsDef"
      [loading]="isLoading"
      [isCustomSorting]="false"
      [sort]="'name'"
      [direction]="'asc'"
      [locale]="locale"
      [canSelectOnlyOne]="false"
      [isSelectingOnlyOneAtATime]="false"
      [addPaddingOnFirst]="false"
      [hoverable]="true"
      (handleCustomSort)="handleSort($event)"
      (on-row-click)="onClickRow($event)"
      (checkbox-event)="handleCheckboxEvent($event.event, $event.item)"
    >
    </ui-static-table>
  </ng-container>

  <ui-spinner
    *ngIf="isLoading && !assetGroups.length"
    size="medium"
  ></ui-spinner>

</ui-card>