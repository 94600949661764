import { Injectable } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { ActifsApi } from 'projects/@common/services/api/respond/actifs/actifs.api';
import { IAssetPropertyConfigRepresentation, IAssetRepresentation, IListAssetsRequest, ListActifsPageContext, ListActifsPageEnum, ListAssetsOrderBy, ListDirectionEnum, ActifsListViewModeEnum } from 'projects/@common/services/api/respond/actifs/actifs.definitions';
import { ResponseUtils } from 'projects/@common/utils/response-utils';

@Injectable({
  providedIn: 'root',
})
export class ActifsService {
  public assetTypes: IAssetPropertyConfigRepresentation[];
  public assetsData: IAssetRepresentation[];
  public total: number;
  public isLoading: boolean;
  public isAssetTypesLoading: boolean;
  public selectedRows: Array<any>;

  private _pageContext: ListActifsPageContext;
  private _listViewMode: ActifsListViewModeEnum;
  private _listRequest: IListAssetsRequest;
  private _debounceTimeout: NodeJS.Timeout;

  constructor(
    private readonly actifsApi: ActifsApi,
    private readonly noticeService: NoticeService,
    private readonly i18n: I18nService
  ) {
    this.setDefaultSettings();
  }

  public get pageContext(): ListActifsPageContext {
    return this._pageContext;
  }

  public get organizationId(): string {
    return this._pageContext.organizationId;
  }

  public get listViewMode(): ActifsListViewModeEnum {
    return this._listViewMode;
  }

  public get listRequest(): IListAssetsRequest {
    return this._listRequest;
  }

  public setDefaultSettings(): void {
    this.isLoading = true;
    this.isAssetTypesLoading = true;
    this.assetTypes = [];
    this.assetsData = [];
    this.total = 0;
    this.selectedRows = [];
    this._listViewMode = ActifsListViewModeEnum.GROUPED_BY_ASSET_TYPE;
    this.setPageContext({
      enum: ListActifsPageEnum.ACTIFS_LIST_PAGE,
      organizationId: null,
      alertId: null,
      incidentId: null,
    });
    this._listRequest = {
      organizationId: null,
      types: null,
      orderBy: ListAssetsOrderBy.name,
      direction: ListDirectionEnum.asc,
      from: null,
      size: 500,
      searchText: null,
    };
  }

  public fetchAssetTypes() {
    this.isAssetTypesLoading = true;
    this.actifsApi.listAssetTypes(this.pageContext.organizationId).subscribe(
      (response) => {
        this.assetTypes = response.getItems();
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("actifs.fetch.assetTypes.error", NoticeLevels.ERROR));
      },
      () => {
        this.isAssetTypesLoading = false;
      }
    );
  }

  public fetchAssets(): void {
    clearTimeout(this._debounceTimeout);
    this.isLoading = true;
    this.total = 0;

    let contextualFetcher: Function;
    switch (this.pageContext.enum) {
      case ListActifsPageEnum.ALERT_DETAIL_PAGE:
        contextualFetcher = () => this.actifsApi.listAlertAssets(this.pageContext.organizationId, this.pageContext.alertId);
        break;
      case ListActifsPageEnum.INCIDENT_DETAIL_PAGE:
        contextualFetcher = () => this.actifsApi.listIncidentAssets(this.pageContext.organizationId, this.pageContext.incidentId);
        break;
      default:
        contextualFetcher = () => this.actifsApi.listAssets(this.listRequest);
    }

    contextualFetcher().subscribe(
      (response: ResponseUtils<IAssetRepresentation>) => {
        this.assetsData = response.getItems();
        this.total = response.getTotalItems();
        this.selectedRows = [];
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("actifs.fetch.assets.error", NoticeLevels.ERROR));
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  public async deleteAssets(assets: IAssetRepresentation[]): Promise<any> {
    const results = [];
    for (const asset of assets) {
      try {
        await this.actifsApi.deleteAsset({ id: asset.id, organizationId: asset.organizationId });
        results.push({ asset, error: null });
      } catch (error) {
        results.push({ asset, error });
      }
    }

    if (results.length > 0) {
      const detailedMessage = results
        .map(({ asset, error }) => {
          const resultTranslation = this.i18n.translate(`actifs.modal.delete.notice.${error ? 'error' : 'success'}`);
          const codeTranslation = this.i18n.translate(`actifs.modal.delete.notice.error.${error?.error?.code}`, null, "");
          const errorMessage = codeTranslation || "";
          return `${resultTranslation}: ${asset.typeName[this.i18n.currentLocale]} "${asset.name}" ${error ? errorMessage : ""}`;
        })
        .join("\n");

      const isAllSuccess = results.every(({ error }) => error === null);
      const isAllErrors = results.every(({ error }) => error !== null);
      if (isAllSuccess) {
        this.noticeService.notifyUser(new Notice("actifs.modal.delete.success", NoticeLevels.SUCCESS));
      } else if (isAllErrors) {
        this.noticeService.notifyUser(new Notice("actifs.modal.delete.error", NoticeLevels.ERROR, null, detailedMessage));
      } else {
        this.noticeService.notifyUser(new Notice("actifs.modal.delete.done", NoticeLevels.INFO, null, detailedMessage));
      }

      this.fetchAssets();
    }
  }

  public handleDebouncedFetch(): void {
    clearTimeout(this._debounceTimeout);
    this._debounceTimeout = setTimeout(() => this.fetchAssets(), 300);
  }

  public setPageContext(value: ListActifsPageContext): void {
    this._pageContext = value;
  }

  public setOrganizationId(organizationId: string): void {
    this._listRequest.organizationId = organizationId;
    this.setPageContext({
      ...this._pageContext,
      organizationId,
    });
  }

  public setListViewMode(listViewMode: ActifsListViewModeEnum): void {
    this._listViewMode = listViewMode;
    this._listRequest.types = null;
    this.handleDebouncedFetch();
  }

  public setSort(direction: ListDirectionEnum, orderBy: ListAssetsOrderBy): void {
    this._listRequest.direction = direction;
    this._listRequest.orderBy = orderBy;
    this.handleDebouncedFetch();
  }

  public setSearchText(searchText: string): void {
    this._listRequest.searchText = searchText;
    this.handleDebouncedFetch();
  }

  public setTypes(types: Array<string>): void {
    this._listRequest.types = types;
    this.handleDebouncedFetch();
  }
}
