<ui-warning
  *ngIf="warnings.length > 0"
  class="warning"
  [warningMessages]="warnings"
  [color]="'yellow'"
  [centerText]="false"
  [roundedBorder]="true"
  [showBulletPoint]="true"
></ui-warning>

<div class="filter-container">
  <query-builder [data]='data' [config]='config' [disabled]="isDisabled" [classNames]="classNames">
      <ng-container *queryButtonGroup="let ruleset; let addRule=addRule; let addRuleSet=addRuleSet; let removeRuleSet=removeRuleSet">
          <button ui-button color="linkFlat" (click)="addRule(); computeWarnings(); conditionsChange.emit(conditions);" *ngIf="!isDisabled && isAddButtonAvailable(ruleset, 'addRule')" [custom-icon]="true" [isSvg]="true" icon="icon_create" icon-size="medium">Rule</button>
          <button ui-button color="linkFlat" (click)="addRuleSet(); computeWarnings(); conditionsChange.emit(conditions);" *ngIf="!isDisabled && isAddButtonAvailable(ruleset, 'addRuleSet')" [custom-icon]="true" [isSvg]="true" icon="icon_create" icon-size="medium">Ruleset</button>
          <button ui-button color="linkFlat" (click)="removeRuleSet(); computeWarnings(); conditionsChange.emit(conditions);" *ngIf="removeRuleSet && !isDisabled && isAddButtonAvailable(ruleset, 'delete')" [custom-icon]="true" [isSvg]="true" icon="icon_delete" icon-size="medium"></button>
      </ng-container>

      <ng-container *querySwitchGroup="let ruleset">
        <mat-button-toggle-group class="radio-group" [disabled]="isDisabled">
          <mat-button-toggle class="radio-button" [checked]="ruleset.condition === 'and'" (change)="ruleset.condition = 'and'; computeWarnings()">And</mat-button-toggle>
          <mat-button-toggle class="radio-button" [checked]="ruleset.condition === 'or'" (change)="ruleset.condition = 'or'; computeWarnings()">Or</mat-button-toggle>
          <mat-button-toggle class="radio-button" [checked]="ruleset.condition === 'not'" (change)="ruleset.condition = 'not'; computeWarnings()">Not</mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>

      <ng-container *queryField="let rule; let fields=fields; let onChange=onChange">
        <div class="fieldContainer" *ngIf="!isDisabled">
          <autocomplete
            [required]="true"
            [placeholder]="'common.search' | t"
            [autocompleteType]="customAutocompleteType"
            [customValues]="getOptions(fields)"
            [initItems]="getSelectedField(rule, fields)"
            [showInInputAtInit]="true"
            [canMultiSelect]="false"
            [oneItemAtATime]="true"
            (onItemSelected)="setFieldValue($event, rule, onChange)"
          ></autocomplete>
        </div>
        <div *ngIf="isDisabled" style="padding-right: 5px;">
          <label ui-label *ngIf="isDisabled">{{getFieldDisplayValue(fields, rule.field)}}</label>
        </div>
      </ng-container>

      <ng-container *queryOperator="let rule; let operators=operators">
        <div class="operatorContainer" *ngIf="!isDisabled">
          <ui-select
            [ngModel]="rule.operator"
            (ngModelChange)="rule.operator = $event; computeWarnings()"
            [required]="true"
            [options]="getOperatorOptions(operators)"
          ></ui-select>
        </div>
        <div *ngIf="isDisabled" style="padding-right: 5px;">
          <label ui-label *ngIf="isDisabled">{{rule.operator}}</label>
        </div>
      </ng-container>

      <ng-container *queryInput="let rule; let field=field; let options=options; type: 'category'">
        <div class="valueContainer" *ngIf="!isDisabled">
          <ui-select
            [(ngModel)]="rule.value"
            [required]="true"
            [options]="getOptions(options)"
          ></ui-select>
        </div>
        <div *ngIf="isDisabled" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{getOptionDisplayValue(options, rule.value)}}</label>
        </div>
      </ng-container>
      
      <ng-container *queryInput="let rule; let field=field; let options=options; type: 'boolean'">
        <div class="valueContainer" *ngIf="!isDisabled">
          <ui-select
            [ngModel]="rule?.value.toString()"
            (ngModelChange)="onBooleanChange(rule, $event)"
            [required]="true"
            [options]="getBooleanOptions()"
          ></ui-select>
        </div>
        <div *ngIf="isDisabled" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{getBooleanDisplayValue(rule.value)}}</label>
        </div>
      </ng-container>
      
      <ng-container *queryInput="let rule; let field=field; type: 'country'">
        <div class="valueContainer" *ngIf="!isDisabled" style="padding-top: 8px">
          <autocomplete
            [required]="true"
            [placeholder]="'common.search' | t"
            [autocompleteType]="customAutocompleteType"
            [customValues]="countries"
            [initItems]="getSelectedCountry(rule)"
            [showInInputAtInit]="true"
            [canMultiSelect]="false"
            [oneItemAtATime]="true"
            (onItemSelected)="rule.value = $event?.value"
          ></autocomplete>
        </div>
        <div *ngIf="isDisabled" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{getCountryDisplayValue(rule.value)}}</label>
        </div>
      </ng-container>

      <ng-container *queryInput="let rule; let field=field; type: 'string'">
        <div class="valueContainer" *ngIf="!isDisabled && rule.operator !== 'exists'" style="padding-top: 8px">
          <input
            ui-input
            (valueChange)="rule.value=$event"
            [value]="rule.value"
          />
        </div>
        <div *ngIf="isDisabled && rule.operator !== 'exists'" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{rule.value}}</label>
        </div>
        <div *ngIf="!isDisabled && rule.operator === 'exists'" class="valueContainer">          
        </div>
        <div *ngIf="isDisabled && rule.operator === 'exists'" class="static-label">          
        </div>
      </ng-container>
            
      <ng-container *queryInput="let rule; let field=field; type: 'number'">
        <div class="valueContainer" *ngIf="!isDisabled" style="padding-top: 8px">
          <input
            ui-input
            uiNumberOnlyInput
            type="number"
            (valueChange)="setNumber(rule, $event)"
            [value]="rule.value"
          />
        </div>
        <div *ngIf="isDisabled" class="static-label">
          <label ui-static--value *ngIf="isDisabled">{{rule.value}}</label>
        </div>
      </ng-container>

      <ng-container *queryRemoveButton="let rule; let removeRule=removeRule">
        <div style="padding-top: 8px">
          <button ui-button color="linkFlat" (click)="removeRule(rule); computeWarnings(); conditionsChange.emit(conditions);" *ngIf="!isDisabled" [custom-icon]="true" [isSvg]="true" icon="icon_delete" icon-size="medium"></button>
        </div>
      </ng-container>

      <ng-container *queryEmptyWarning="let emptyMessage">
        <div>
          {{'filters.ruleset.empty.error' | t}}
        </div>
      </ng-container>

  </query-builder>
</div>
