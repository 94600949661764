<app-incident-details-base-layout [title]="'incidents.container.page.details.tab.detail.additional.title' | t">
  <div
    class="editor-container"
    [ngClass]="{
      'pointer':!isEditing,
      'disabled':!canEditAditionnalInfo
    }"
    [tooltip]="!isEditing ? ('common.action.edit'| t) : ''"
    (click)="openEditor()"
  >
    <span *ngIf="isInfoGroupEmpty && !isEditing">-</span>
    <html-editor
      #editor
      class="editor"
      controlName="additionalInfo"
      [group]="infoGroup"
      [htmlData]="infoValue"
      [initialValue]="initialValue"
      [showUnsavedWarning]="shouldShowUnsavedWarning"
      [locale]="i18nService.currentLocale"
      [isEditing]="isEditing"
      [editorEventHandlers]="editorEventHandlers"
    ></html-editor>
    <img
      *ngIf="!isEditing"
      alt="edit-icon"
      class="edit"
      src="assets/favicons/icon_modify_disabled.svg"
    />
  </div>

  <ui-confirmations-buttons
    *ngIf="isEditing"
    [marginTop]="true"
    (confirmationEvent)="handleConfirmation($event)"
  ></ui-confirmations-buttons>
</app-incident-details-base-layout>