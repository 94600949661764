<div
  class="badge"
  *ngIf="!!incidentStatus"
  [style.background-color]="backgroundColorHex"
>
  <p
    class="badge--text"
    [style.color]="textColorHex"
  >
    {{textTranlationKey | t}}
  </p>
</div>