import { DisplayRequirements } from 'projects/@common/modules/display/display.service';
import { BaseSidebar, ServiceTypeEnum, navigationElement } from 'projects/@common/modules/layout/definitions/base-sidebar.service';

export class OrgDetectSidebar extends BaseSidebar {
  private elements: navigationElement[] = [
  ];

  constructor () {
    super();
  }

  public getElements() {
    return this.elements;
  }

  public unsubscribe() {
  }

  public getRequirements(): DisplayRequirements {
    return {
      services: [ ServiceTypeEnum.DETECT_AND_RESPOND ],
    };
  }
}
