import { ISystemListUsecaseTemplateItem, ISystemListUsecaseTemplatesRequest, ISystemListUsecaseTemplatesResponse } from "@common/services/api/respond/usecase/usecase.definition";
import { UsecasesApiService } from "projects/@common/services/api/respond/usecase/usecase.api";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

export class UsecasesCatalogContainerActions {
  public usecasesCatalog: BehaviorSubject<ISystemListUsecaseTemplateItem[]> = new BehaviorSubject([]);
  public isLoading = false;
  public total = 0;
  public hasMoreToLoad: boolean = false;
  public filterCatalogByName: string = null;

  private selectedOrganizationId: string;
  private catalog: ISystemListUsecaseTemplateItem[];
  private previousRequestResponse: ISystemListUsecaseTemplatesResponse;

  constructor(private readonly usecasesApiService: UsecasesApiService) { }

  public async handleSelectedOrganization(organizationId: string): Promise<void> {
    this.selectedOrganizationId = organizationId;
    await this.handleRefresh();
  }

  public get queryParams(): ISystemListUsecaseTemplatesRequest {
    const params: ISystemListUsecaseTemplatesRequest = {
      from: this.previousRequestResponse ? this.previousRequestResponse.nextItem : 0,
      size: 150,
      displayAttacks: true,
    };
    if (this.selectedOrganizationId) {
      params.organizationId = this.selectedOrganizationId;
    }
    return Object.fromEntries(Object.entries(params).filter(([ _, filter ]) => (!Array.isArray(filter) && filter != null) || (Array.isArray(filter) && filter?.length > 0)));
  }

  public async handleUsecaseSearchQuery(value: string, currentLocal: string): Promise<void> {
    if (value) {
      this.filterCatalogByName = value.trim();
      const filteredUsecases = this.catalog.filter((data) => {
        if (data.template.name[currentLocal]) {
          return data.template.name[currentLocal].toLowerCase().includes(this.filterCatalogByName.toLowerCase());
        }
        return true;
      });
      this.total = filteredUsecases.length;
      this.usecasesCatalog.next(filteredUsecases);
    } else {
      this.handleUsecaseSearchClear();
    }
  }

  public async handleUsecaseSearchClear(): Promise<void> {
    this.filterCatalogByName = null;
    this.total = this.catalog.length;
    this.usecasesCatalog.next(this.catalog);
  }

  public async fetchUsecasesCatalog(loadMore: boolean = false): Promise<ISystemListUsecaseTemplateItem[]> {
    if (!this.isLoading) {
      this.isLoading = true;
      const params = this.queryParams;
      if (!loadMore) {
        params.from = 0;
      }

      try {
        const response = await this.usecasesApiService.listUsecaseTemplates(params);
        if (loadMore) {
          this.catalog = this.catalog.concat(response.items);
        } else {
          this.catalog = response.items;
        }
        this.total = this.catalog.length;
        this.hasMoreToLoad = response.nextItem < response.total;
        this.previousRequestResponse = response;

        this.isLoading = false;
        this.handleUsecaseSearchClear();
      } catch (error) {
        this.isLoading = false;
        throw error;
      }
    }

    return this.catalog;
  }

  public async handleRefresh(): Promise<void> {
    await this.fetchUsecasesCatalog();
  }
}
