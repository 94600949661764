<div class="drawer-detail--scrollable visible">
  <div class="drawer-detail--content">
    <div
      class="horizontal-div"
      *ngIf="!noMonitorAvailable && withMonitorParam && !isEditing"
    >
      <div>
        <button
          ui-button
          icon="icon_modify"
          [custom-icon]="true"
          [isSvg]="true"
          icon-size="medium"
          color="listActionFlat"
          (click)="toggleAction()"
        >
          {{ 'common.action.edit' | t }}
        </button>

        <button ui-button color="linkFlat"
          *ngIf="!isEditing" 
          [disabled]="!isCurrentUsecaseOutdated()" 
          [custom-icon]="true"
          [isSvg]="true"
          icon="icon_in_progress" icon-size="medium" (click)="updateOutdatedUsecase()">
          {{ 'datasources.table.action.update' | t }}
        </button>
      </div>
    </div>

    <div class="monitor-title">
      <div class="margin--small--top">
        <ui-title [name]="('org_usecase.description_drawer.tab.monitors.subtitle' | t) + ' (' + (monitorFormTemplateList.length || 0) + ')'"></ui-title>
        <label
          *ngIf="noMonitorAvailable"
          ui-label
        > {{ 'org_usecase.create_drawer.usecase_monitor.cannot_be_updated' | t }}
        </label>
      </div>
    </div>

    <ng-container *ngFor="let monitor of monitorFormTemplateList; let i = index">
      <div class="horizontal-div">
          <div
            class="horizontal-div"
            (click)="monitor.isExpanded = !monitor.isExpanded"
          >
            <ui-icon
              modifier="on-header"
              [name]="!monitor.isExpanded ? 'chevron-right' : 'chevron-down'"
              size="medium"
              style="cursor:pointer;"
            ></ui-icon>
            <strong>{{ monitor.name }}</strong>
            <ui-tooltip width="400px">{{ monitor.description | translatedObject }}</ui-tooltip>
          </div>

          <div
            class="horizontal-div"
            *display="{ permissions: ['can_update_usecase'] }"
          >
            <div class="horizontal-div margin--right">
              <ui-toggle
                class="toggle-mode"
                #alertingModeToggle
                [toggled]="monitor.silenced"
                [class.toggled]="monitor.silenced"
                [labelEnabled]="'org_usecase.details_drawer.monitors_tab.silenced.enabled' | t"
                [labelDisabled]="'org_usecase.details_drawer.monitors_tab.silenced.disabled' | t"
                [disabledWhenUntoggled]="false"
                [manualToggle]="true"
                (onClick)="updateMonitorSilencedState(i, $event)"
              ></ui-toggle>
              <ui-tooltip>
                {{ "org_usecase.details_drawer.monitors_tab.silenced.tooltip" | t }}
              </ui-tooltip>
            </div>
            <div class="horizontal-div margin--left">
              <ui-toggle
                class="toggle-mode"
                #blacklistToggle
                [toggled]="monitor.blacklisted"
                [class.toggled]="monitor.blacklisted"
                [labelEnabled]="'org_usecase.details_drawer.monitors_tab.blacklist.enabled' | t"
                [labelDisabled]="'org_usecase.details_drawer.monitors_tab.blacklist.disabled' | t"
                [disabledWhenUntoggled]="false"
                [manualToggle]="true"
                (onClick)="updateMonitorBlacklistState(i, $event)"
              ></ui-toggle>
              <ui-tooltip>
                {{ "org_usecase.details_drawer.monitors_tab.blacklist.tooltip" | t }}
              </ui-tooltip>
            </div>
          </div>

        </div>
        <div
          class="monitor-form"
          [class.visible]="monitor.isExpanded"
        >
          <ng-container *ngIf="monitor.parameters?.length > 0; else noParamsTemplate;">
            <ng-container *ngFor="let parameter of monitor.parameters; let i = index">
              <dynamic-form-template
                [readonly]="!isEditing"
                [formConfig]="parameter"
              ></dynamic-form-template>
            </ng-container>
          </ng-container>
          <ng-template #noParamsTemplate>
            <ui-static [value]="'org_usecase.details_drawer.monitors_tab.monitor.noParams'|t"></ui-static>
          </ng-template>
      </div>
    </ng-container>
  </div>
</div>

<ui-form-actions
  *ngIf="isEditing && !noMonitorAvailable"
  layout="centered"
  class="visible"
>
  <ng-container>
    <button
      [disabled]="!usecaseUpdateForm.isMonitorFormValid"
      ui-button
      color="standard"
      (click)="save()"
    >
      {{ 'common.action.save' | t }}
    </button>
    <button
      ui-button
      color="secondary"
      (click)="cancel()"
    >
      {{ 'common.action.cancel' | t }}
    </button>
  </ng-container>
</ui-form-actions>

<ui-dialog *ngIf="isEditingAlertingMode"
    [headtitle]="'org_usecase.details_drawer.monitors_tab.dialog.silenced.title' | t"
    [actionButton]="'common.action.confirm' | t"
    [cancelButton]="'common.action.cancel' | t"
    (onButtonClick)="onEditingAlertingModeDialogClick($event)"
    [actionButtonDisabledCondition]="!usecaseUpdateForm.alertingModeComment"
>
  <label ui-label [required]="true">{{ 'org_usecase.details_drawer.monitors_tab.dialog.silenced.label' | t }}</label>
  <textarea
    class="textarea-reason"
    (input)='usecaseUpdateForm.setAlertingModeComment($event.target.value)'
  ></textarea>
  <br>
</ui-dialog>

<ui-dialog *ngIf="isEditingBlacklist"
    [headtitle]="'org_usecase.details_drawer.monitors_tab.dialog.blocked.title' | t"
    [actionButton]="'common.action.confirm' | t"
    [cancelButton]="'common.action.cancel' | t"
    (onButtonClick)="onEditingBlacklistedDialogClick($event)"
    [actionButtonDisabledCondition]="!usecaseUpdateForm.blacklistedComment"
>
  <label ui-label [required]="true">{{ 'org_usecase.details_drawer.monitors_tab.dialog.silenced.label' | t }}</label>
  <textarea
    class="textarea-reason"
    (input)='usecaseUpdateForm.setBlacklistedComment($event.target.value)'
  ></textarea>
  <br>
</ui-dialog>