<div class="drawer-detail--scrollable visible tab-menu">
  <div class="drawer-detail--content">
    <ng-container *display="{ permissions: ['can_update_profile_members'] }">
      <button
        *ngIf="!isEditing"
        ui-button
        color="listActionFlat"
        class="margin--small--bottom"
        icon="icon_manage"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="medium"
        (click)="toggleEditProfile()"
      >
        {{ 'common.manage' | t }}
      </button>
    </ng-container>
    <div class="iam-role-details margin--small--top margin--small--bottom">
      <div
        *ngIf="isEditing"
        class="flex-box align-bottom add-user-autocomplete autocomplete-spacing"
      >
        <autocomplete
          #userAutocomplete
          [searchFunction]="usersAutocompleteSearchFunction"
          [label]="'profiles.add.users' | t"
          [placeholder]="'autocomplete.add.user.placeholder' | t"
          name="autocomplete"
          control="autocomplete"
          [autocompleteType]="usersAutocompleteType"
          [(selectedItems)]="profileUsers"
          [canMultiSelect]="true"
          [itemsToIgnore]="profileUsers"
          expandHeight="max-content"
        >
        </autocomplete>
      </div>
      <users-table
        [isLoading]="isLoading"
        [(users)]="profileUsers"
        [prevUsers]="prevUsers"
        [showBadges]="true"
        [isEditing]="isEditing"
        [showRowSeparator]="true"
        [canDeleteOwner]="true"
        (onUserDelete)="removeUserFromProfile($event)"
      ></users-table>

      <button
        *ngIf="isMoreUsersToLoad"
        ui-button
        class="load-more-button"
        (click)="loadProfileMembers()"
      >{{'common.action.loadMore' | t}}</button>
    </div>
  </div>
</div>

<ui-form-actions
  layout="centered"
  class="visible"
>

  <ng-container *ngIf="!isLoading && isEditing">
    <button
      ui-button
      color="standard"
      (click)="saveProfile()"
    >
      {{ 'common.action.save' | t }}
    </button>
    <button
      ui-button
      color="secondary"
      (click)="cancel()"
    >
      {{ 'common.action.cancel' | t }}
    </button>
  </ng-container>
</ui-form-actions>