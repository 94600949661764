import { HttpClient } from "@angular/common/http";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { IFindingsApi } from "projects/@common/services/api/respond/finding/finding.definition";
import { Finding } from "projects/@common/services/api/respond/finding/model/finding";
import { GetAlertFindingsRequest } from "projects/@common/services/api/respond/finding/model/findingFilters";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { UrlUtils } from "projects/@common/utils/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class CustomerFindingsApi extends EcoApi implements IFindingsApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listFindings(request: GetAlertFindingsRequest): Observable<ResponseUtils<Finding>> {
    const url = `${this.host}/me/alerts/${request.alertId}/findings?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`;
    return this.http.get<IRespondResponse<Finding>>(url)
      .pipe(map((response) => new ResponseUtils<Finding>(response)));
  }
}
