import { PieChart, PieSeries } from '@amcharts/amcharts4/charts';
import { Percent, addLicense, color, create } from '@amcharts/amcharts4/core';
import { SliceGrouper } from '@amcharts/amcharts4/plugins/sliceGrouper';
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { randomBytes } from 'crypto';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';

export interface GraphConnectorTotalData {
  category: string;
  value: number;
}

@Component({
  selector: 'graph-connectors-total-ingested-data',
  templateUrl: './graph-connectors-total-ingested-data.component.html',
  styleUrls: [ './graph-connectors-total-ingested-data.component.scss' ],
})
export class GraphConnectorsTotalIngestedDataComponent implements AfterViewInit, OnDestroy, OnChanges {
  private readonly COLORS = [ "#004C2A", "#006F3E", "#068D44", "#1AA969", "#5ECC99", "#9EDDC1", "#004C41", "#006F5F", "#068D6D", "#1AA994" ];

  @Input()
  public chartData: GraphConnectorTotalData[] = [];

  @Input()
  public chartId: string = randomBytes(20).toString('hex');

  @Input()
  private grouperThreshold: number = 2.5;

  @Input()
  private groupClickBehavior: 'none' | 'break' | 'zoom' = 'zoom';

  // When set this will overwrite the grouperThreshold property.
  // Will try to only display the top x biggest elements only
  @Input()
  private displayNumberOfBiggestElements: number = 0;

  private chart: PieChart;
  private isFirstChartInitialization: boolean = true;

  constructor(private readonly i18n: I18nService) {
    addLicense('CH259911983');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData && !changes?.chartData?.isFirstChange()) {
      if (this.chart) {
        this.chart.invalidateData();
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.chart) {
      this.createChart();
    }
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  private createChart(): void {
    const chart = create(`${this.chartId}_CONNECTORS_DASHBOARD_TOTAL_INGESTED_ID`, PieChart);
    chart.numberFormatter.numberFormat = "#.0b";
    chart.innerRadius = new Percent(50);
    chart.colors.list = this.COLORS.map((colorHexValue) => color(colorHexValue));
    chart.responsive.enabled = true;
    const series = chart.series.push(new PieSeries());
    series.slices.template.states.getKey("active").properties.shiftRadius = 0;

    series.colors.list = this.COLORS.map((colorHexValue) => color(colorHexValue));
    series.data = this.chartData;
    series.dataFields.value = "value";
    series.dataFields.category = "category";
    series.labels.template.tooltipText = "[bold]{category}[/] {value}";
    series.labels.template.hideOversized = true;
    series.labels.template.valign = "bottom";
    series.labels.template.wrap = true;
    series.labels.template.maxWidth = 100;
    series.labels.template.radius = new Percent(5);
    series.labels.template.adapter.add("text", (text, target) => {
      if (target.dataItem && target.tooltipDataItem.getValue('value', 'percent') <= 0.5) {
        return "";
      }
      return text;
    });
    series.labels.template.hideOversized = true;
    series.labels.template.padding(1, 0, 1, 0);
    series.alignLabels = !(window.innerWidth < 1420);
    this.handleLabelsLevelsToBeVisible(series);
    window.addEventListener("resize", () => this.handleChartSize(series)); // on screen resize
    series.events.on("datavalidated", (event) => this.handleLabelsLevelsToBeVisible(event.target));
    if (this.shouldGroup()) {
      const grouper = series.plugins.push(new SliceGrouper());
      grouper.groupName = this.i18n.translate('respond.dashboard.playbooksIncidentCount.others');
      grouper.threshold = this.grouperThreshold;
      grouper.clickBehavior = this.groupClickBehavior;
      grouper.groupProperties.fill = color("#000000"); // Set the fill color for grouped slices to black
      grouper.groupProperties.stroke = color("#000000"); // Set the stroke color for grouped slices to black
    }
    this.isFirstChartInitialization = false;
    this.chart = chart;
  }

  private shouldGroup():boolean {
    const sumData = this.chartData.reduce((sum, current) => sum + current.value, 0);
    if (this.displayNumberOfBiggestElements > 0) {
      this.chartData.sort((a, b) => b.value - a.value);
      const sliceEndNumber = this.displayNumberOfBiggestElements + 1 <= this.chartData.length ? this.displayNumberOfBiggestElements + 1 : this.chartData.length;
      const biggestElements = this.chartData.slice(0, sliceEndNumber);
      this.grouperThreshold = (biggestElements[biggestElements.length - 1].value / sumData) * 100;
    }
    const inGrouperSection = this.chartData.filter((data) => ((data.value / sumData) * 100) < this.grouperThreshold);
    return inGrouperSection.length >= 2;
  }

  private handleChartSize(series: PieSeries): void {
    if (!this.isFirstChartInitialization) {
      const chartContainer = document.getElementById(`${this.chartId}_CONNECTORS_DASHBOARD_TOTAL_INGESTED_ID`);
      if (chartContainer) {
        if (chartContainer.clientWidth <= 640) {
          series.alignLabels = false;
        } else {
          series.alignLabels = true;
        }
      }
      this.handleLabelsLevelsToBeVisible(series);
    }
  }

  private handleLabelsLevelsToBeVisible(series: PieSeries) {
    if (!series.alignLabels) {
      series.labels.template.radius = new Percent(5); // Set a default radius for labels
      series.labels.template.adapter.add("radius", (radius, target) => {
        if (target.dataItem && target.dataItem.index && target.dataItem.index % 2 === 0) {
          return new Percent(12); // Set a different radius for even indexed labels
        }
        return radius;
      });
      series.labels.template.scale = 0.8;
      series.chart.invalidateLabels();
    } else {
      series.labels.template.scale = 1;
      series.chart.invalidateLabels();
    }
  }
}
