<detail-layout [backLink]="'../../'">
    <div class="connector-container" topCard>
        <div class="connector-details-section">
            <div class="connector-details-title">
                <img [src]="connectorMappingHelper.getConnectorIcon(connector.id)">
                <h2>{{ connector.translations.name }}</h2>
            </div>
            <p>{{ connector.translations.description }}</p>
            <div class="connector-informations" *ngIf="instance">
                <div class="connector-informations-items">
                    <div class="connector-informations-title-section">
                        <p>{{'detection.connector.instance.page.billing.title' | t}}</p>
                        <ui-tooltip [isSmall]="true" [data]="billingTooltipDescription"></ui-tooltip>
                    </div>
                    <p>{{ connector.billingMethod.name }}</p>
                </div>
    
                <div class="connector-informations-items">
                    <div class="connector-informations-title-section">
                        <p>{{'detection.connector.instance.page.category.title' | t}}</p>
                        <ui-tooltip [isSmall]="true" [data]="i18n.translate('detection.connector.instance.page.category.tooltip.description')"></ui-tooltip>
                    </div>
                    <p>{{ connector.category.name }}</p>
                </div>
    
                <div class="connector-informations-items">
                    <div class="connector-informations-title-section">
                        <p>{{'detection.connector.instance.page.function.title' | t}}</p>
                        <ui-tooltip [isSmall]="true" [data]="i18n.translate('detection.connector.instance.page.function.tooltip.description')"></ui-tooltip>
                    </div>
                    <p>{{ functionsToDisplay }}</p>
                </div>
    
                <div class="connector-informations-items">
                    <div class="connector-informations-title-section">
                        <p>{{'detection.connector.instance.page.vendor.title' | t}}</p>
                        <ui-tooltip [isSmall]="true" [data]="i18n.translate('detection.connector.instance.page.vendor.tooltip.description')"></ui-tooltip>
                    </div>
                    <p>{{ connector.vendor.name }}</p>
                </div>
            </div>
        </div>
        <div class="connector-family-section">
            <div class="tooltip">
                <img [src]="connectorMappingHelper.getConnectorFamilyIcon(connector.family.id)">
                <div class="tooltip-text" [innerHTML]="connector.family.name"></div>
            </div>
        </div>
    </div>

    <div class="connector-instance-card" *ngIf="instance" bottomCard>
        <h2>{{ 'detect.connector.instance.details.card.overview.title' | t }}</h2>
        <div class="connector-instance-data-items-container">
            <div class="connector-instance-data-items-top-left">
                <p class="connector-instance-data-items-label">{{ 'datasources.tab.info.createdAt' | t }}</p>
                <p class="connector-instance-data-items-value">{{ instance.createdAt | dt:{locale: i18n.currentLocale} }}</p>
            </div>
            <div class="connector-instance-data-items-top-right">
                <p class="connector-instance-data-items-label">{{ 'datasources.tab.info.updatedAt' | t }}</p>
                <p class="connector-instance-data-items-value">{{ instance.updatedAt | dt:{locale: i18n.currentLocale}  }}</p>
            </div>
            <div class="connector-instance-data-items-bottom-left">
                <p class="connector-instance-data-items-label">{{ 'sidebar.navigation.tools.secrets.title' | t }}</p>
                <div class="connector-instance-data-items-value-secret">
                    <div *ngFor="let secret of secretKeys">
                        <div class="connector-instance-data-items-value-secret-link" (click)="redirectToSecretsPage(secret)">
                            {{ secret }}
                            <img src="assets/favicons/icon_open_external.svg">
                        </div>
                    </div>
                    <ng-container *ngIf="instance.secretKeys.length === 0">
                        {{ 'detection.connector.instance.page.info.secret.without.text' | t }}
                    </ng-container>
                </div>
            </div>
            <div class="connector-instance-data-items-bottom-right">
                <p class="connector-instance-data-items-label">{{ 'detection.playbook.list.label.usecases' | t }}</p>
                <div class="connector-instance-data-items-value-link" (click)="redirectToUsecasesPage()">
                    {{ 'detection.connector.instance.page.info.usecase.link.text' | t }}
                    <img src="assets/favicons/icon_open_external.svg">
                </div>
            </div>
        </div>
        <div class="connector-instance-volumetry" *ngIf="instance && instance?.volumetryExcluded != 1">
            <app-connector-instance-volumetry-chart-section [organization]="organization" [connectorInstanceId]="instance.id"></app-connector-instance-volumetry-chart-section>
        </div>
    </div>

    <app-connector-instance-graphs-volume-section *ngIf="instance" [connectorInstance]="instance" bottomCard></app-connector-instance-graphs-volume-section>

    <div class="without-connector-instance" *ngIf="!instance" bottomCard>
        <h2>{{'detection.connector.instance.page.not.found.title' | t}}</h2>
        <p>{{'detection.connector.instance.page.not.found.description' | t}}</p>
        <a class="without-connector-instance-link" [href]="contactLink">{{'detection.connector.instance.page.not.found.link.text' | t}}</a>
    </div>
</detail-layout>
