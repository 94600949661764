import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";

import { Store } from "@ngxs/store";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { VarHistoriesApi } from "projects/console-adm/src/app/respond/histories/api/var-histories.api";
import { CustomerHistoriesApi } from "projects/console-org/src/app/respond/histories/api/customer-histories.api";
import { EcoUrlService } from "../../core/url.service";
import { IAlertHistory, IConnectorHistory, IEscalationHistory, IFilterHistory, IGetPlaybookHistoriesRequest, IHistoriesApi, IHistory, IIncidentHistory, IIncidentTaskHistory, IListConnectorHistoryRequest, IListEscalationHistoryRequest, IListHistoryRequest, IListIncidentTaskHistoryRequest, IUsecaseHistory, IUsecaseHistoryRequest, IWhitelistHistory } from "./histories.definition";
import { GetAlertHistoriesRequest } from "./models/getAlertHistoriesRequest";
import { GetHistoriesRequest } from "./models/getHistoriesRequest";
import { GetIncidentHistoriesRequest } from "./models/getIncidentIHistoriesRequest";

@Injectable({
  providedIn: 'root',
})
export class HistoriesApi implements IHistoriesApi {
  private customerService: CustomerHistoriesApi;
  private varService: VarHistoriesApi;

  constructor(
    protected store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerHistoriesApi(http, url);
    this.varService = new VarHistoriesApi(http, url);
  }

  public listUsers(request: GetHistoriesRequest): Observable<{ identityName: string; identityId: string }[]> {
    if (this.isVarMode) {
      return this.varService.listUsers(request);
    }
    return this.customerService.listUsers(request);
  }

  public listHistories(request: GetHistoriesRequest): Observable<ResponseUtils<IHistory>> {
    if (this.isVarMode) {
      return this.varService.listHistories(request);
    }
    return this.customerService.listHistories(request);
  }

  public listAlertHistories(request: GetAlertHistoriesRequest): Observable<ResponseUtils<IAlertHistory>> {
    if (this.isVarMode) {
      return this.varService.listAlertHistories(request);
    }
    return this.customerService.listAlertHistories(request);
  }

  public listIncidentHistories(request: GetIncidentHistoriesRequest): Observable<ResponseUtils<IIncidentHistory>> {
    if (this.isVarMode) {
      return this.varService.listIncidentHistories(request);
    }
    return this.customerService.listIncidentHistories(request);
  }

  public listWhitelistHistory(request: IListHistoryRequest): Observable<ResponseUtils<IWhitelistHistory>> {
    if (this.isVarMode) {
      return this.varService.listWhitelistHistory(request);
    }
    return this.customerService.listWhitelistHistory(request);
  }

  public listFilterHistory(request: IListHistoryRequest): Observable<ResponseUtils<IFilterHistory>> {
    if (this.isVarMode) {
      return this.varService.listFilterHistory(request);
    }
    return this.customerService.listFilterHistory(request);
  }

  public listEcoAlertFilterHistory(request: IListHistoryRequest): Observable<ResponseUtils<IFilterHistory>> {
    if (this.isVarMode) {
      return this.varService.listEcoAlertFilterHistory(request);
    }
    return this.customerService.listEcoAlertFilterHistory(request);
  }

  public listUsecaseHistory(request: IUsecaseHistoryRequest): Observable<ResponseUtils<IUsecaseHistory>> {
    if (this.isVarMode) {
      return this.varService.listUsecaseHistory(request);
    }
    return this.customerService.listUsecaseHistory(request);
  }

  public listAssetHistory(request: any): Observable<ResponseUtils<any>> {
    if (this.isVarMode) {
      return this.varService.listAssetHistory(request);
    }
    return this.customerService.listAssetHistory(request);
  }

  public listPlaybookHistory(request: IGetPlaybookHistoriesRequest): Observable<ResponseUtils<any>> {
    if (this.isVarMode) {
      return this.varService.listPlaybookHistory(request);
    }
    return this.customerService.listPlaybookHistory(request);
  }

  public listEscalationHistory(request: IListEscalationHistoryRequest): Observable<ResponseUtils<IEscalationHistory>> {
    if (this.isVarMode) {
      return this.varService.listEscalationHistory(request);
    }
    return this.customerService.listEscalationHistory(request);
  }

  public listIncidentTaskHistory(request: IListIncidentTaskHistoryRequest): Observable<ResponseUtils<IIncidentTaskHistory>> {
    if (this.isVarMode) {
      return this.varService.listIncidentTaskHistory(request);
    }
    return this.customerService.listIncidentTaskHistory(request);
  }

  public listDataConnectorHistory(request: IListConnectorHistoryRequest): Observable<ResponseUtils<IConnectorHistory>> {
    if (this.isVarMode) {
      return this.varService.listDataConnectorHistory(request);
    }
    return this.customerService.listDataConnectorHistory(request);
  }
  
  public listSoarConnectorHistory(request: IListConnectorHistoryRequest): Observable<ResponseUtils<IConnectorHistory>> {
    if (this.isVarMode) {
      return this.varService.listSoarConnectorHistory(request);
    }
    return this.customerService.listSoarConnectorHistory(request);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
