import { Component, Input } from "@angular/core";
import { ModalService } from "@ui-kit/services/modal.service";
import { Eco } from "projects/@common/definitions/eco";
import { PlaybooksCatalogTemplateMapper } from "projects/@respond/reports/templating/playbooksCatalogTemplateMapper";
import { ReportService, ReportTypesEnum } from "projects/@respond/services/report.service";
import { PlaybookCatalogReportChooseLanguageModalComponent } from "../playbook-catalog-report-choose-language-modal/playbook-catalog-report-choose-language-modal.component";

@Component({
  selector: 'app-playbook-catalog-report-button',
  templateUrl: './playbook-catalog-report-button.component.html',
  styleUrls: [ './playbook-catalog-report-button.component.scss' ],
})
export class PlaybookCatalogReportButtonComponent {
  @Input()
    isWithinTableColumn: boolean = true;

  constructor(
    private playbooksTemplateMapper: PlaybooksCatalogTemplateMapper,
    private reportService: ReportService,
    private readonly modalService: ModalService
  ) {
  }

  public async askForLanguageAndOpenReport(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    const modal = this.modalService.openDialog(PlaybookCatalogReportChooseLanguageModalComponent);
    const subscription = modal.componentInstance.successEvent.subscribe((language) => {
      if (language) {
        this.goToReport(language);
      }
      subscription.unsubscribe();
    });
  }

  private goToReport(language: Eco.Languages) {
    const reportWindow = window.open('', Math.random().toString(), `width=${screen.width},height=${screen.height},resizable=1`);

    this.playbooksTemplateMapper.generateMapper(language).then((reportTemplate) => {
      this.reportService.generateReport(ReportTypesEnum.playbookCatalog, reportTemplate, reportWindow, language);
    });
  }
}
