import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EcoSessionState } from "@common/modules/session/state/session.state";
import { Store } from "@ngxs/store";
import { FieldMap } from "angular2-query-builder";
import { VarEcoAlertFiltersApi } from "projects/console-adm/src/app/respond/filters/api/var-ecoAlert-filter.api";
import { CustomerEcoAlertFiltersApi } from "projects/console-org/src/app/respond/filters/api/customer-ecoAlert-filter.api";
import { EcoUrlService } from "../../core/url.service";
import { IEcoAlertFiltersApi } from "./ecoAlertFilter.definition";
import { ICreateFilterRequest, IDeleteFiltersRequest, IDeleteFiltersResponse, IDescribeFilterRequest, IDescribeFilterResponse, IFilterBaseRepresentation, IListFilterFieldsRequest, IListFiltersRequest, IUpdateFilterRequest } from "./filters.definitions";
import { Observable } from "rxjs";
import { ResponseUtils } from "@common/utils/response-utils";

@Injectable({
  providedIn: 'root',
})
export class EcoAlertFiltersApi implements IEcoAlertFiltersApi {
  private customerService: CustomerEcoAlertFiltersApi;
  private varService: VarEcoAlertFiltersApi;

  constructor(
    protected store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerEcoAlertFiltersApi(http, url);
    this.varService = new VarEcoAlertFiltersApi(http, url);
  }

  public getFilterConditionFields(request?: IListFilterFieldsRequest): Promise<FieldMap> {
    if (this.isVarMode) {
      return this.varService.getFilterConditionFields(request);
    }
    return this.customerService.getFilterConditionFields(request);
  }

  public describeFilter(request: IDescribeFilterRequest): Observable<IDescribeFilterResponse> {
    if (this.isVarMode) {
      return this.varService.describeFilter(request);
    }
    return this.customerService.describeFilter(request);
  }

  public listFilters(organizationId: string, request?: IListFiltersRequest): Observable<ResponseUtils<IFilterBaseRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listFilters(organizationId, request);
    }
    return this.customerService.listFilters(organizationId, request);
  }

  public listOrganizationsFilters(request?: IListFiltersRequest): Observable<ResponseUtils<IFilterBaseRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listOrganizationsFilters(request);
    }
    return this.customerService.listOrganizationsFilters(request);
  }

  public createFilter(request: ICreateFilterRequest): Observable<IDescribeFilterResponse> {
    if (this.isVarMode) {
      return this.varService.createFilter(request);
    }
    return this.customerService.createFilter(request);
  }

  public updateFilter(request: IUpdateFilterRequest): Observable<IDescribeFilterResponse> {
    if (this.isVarMode) {
      return this.varService.updateFilter(request);
    }
    return this.customerService.updateFilter(request);
  }

  public deleteFilter(request: IDeleteFiltersRequest): Promise<IDeleteFiltersResponse> {
    if (this.isVarMode) {
      return this.varService.deleteFilter(request);
    }
    return this.customerService.deleteFilter(request);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
