
  <div
    class="section-container"
    *ngIf="playbookHistoryResponse"
  >
    <ui-static-table
      [loading]="isLoadingHistory"
      [dataSource]="playbookHistory"
      [addPaddingOnFirst]="false"
      [columnsDef]="historicColumnsDef"
      [expendedTemplate]="history"
      [locale]="i18n.currentLocale"
    >
    </ui-static-table>
    <div
      class="button-container"
      *ngIf="canLoadMore()"
    >
      <ui-table-refresh-tool
        class="refresher"
        [loading]="isLoadMoreHistory"
        [text]="'common.showMore' | t"
        rightSide
        (click)="loadMoreIncidentHistory()"
      ></ui-table-refresh-tool>
    </div>
  </div>

  <ui-spinner
    *ngIf="false"
    size="medium"
    modifier="secondary"
  ></ui-spinner>

  <ng-template
    #history
    let-playbookHistory="row"
    let-isExpanded="isSelect"
  >
    <app-history-detail *ngIf="isExpanded" [history]="playbookHistory"></app-history-detail>
  </ng-template>
