import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IAssetRepresentation } from 'projects/@common/services/api/respond/actifs/actifs.definitions';

@Component({
  selector: 'actifs-relations-cell',
  templateUrl: './actifs-relations-cell.component.html',
})
export class ActifsRelationsCellComponent implements OnInit {
  @Input() public asset: IAssetRepresentation;

  public relations: string[] = [];
  public relationsCount: string;
  public iconPath: string;
  public tooltipHTML: string;

  constructor(private readonly i18n: I18nService) { }

  ngOnInit(): void {
    this.relations = this.asset.relations.sort((a, b) => a.localeCompare(b));

    this.relationsCount = this.relations.length > 0 ? this.relations.length.toString() : "-";
    this.iconPath = this.relations.length > 0 ? 'favicons/icon_link' : null;

    this.tooltipHTML = `
      <div style='font-size:13px; font-weight:600; color:#999; margin-bottom:0.25rem;'>
        ${this.i18n.translate('actifs.list.table.column.tooltipTitle')}
      </div>
      <div style='font-size:14px;'>
        ${this.relations.map((relation) => `<div>${relation}</div>`).join('')}
      </div>
    `;
  }
}
