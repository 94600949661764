export enum ConnectorFamilies {
  AWS = "aws",
  LOCAL_INFRA = "local-infra",
  CYBERSECURITY_INFRA = "cybersecurity-infra",
  MICROSOFT_365 = "microsoft-365",
  MICROSOFT_AZURE = "microsoft-azure",
  EDR = "edr",
  INFORMATICA = "informatica",
  GOOGLE = "google"
}
