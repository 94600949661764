<escalation-expand-card
  [title]="escalationList.name"
  [useEditableTitle]="!isTypeDefault"
  [description]="null"
  [titleIconPath]="'assets/favicons/communication/icon_communication_tab_list.svg'"
  [extraHeaderIcons]="headerIcons"
  [preventCollapse]="isEditing"
  [isExpanded]="isExpanded"
  [startInEditingMode]="!escalationList.createdAt"
  [isConfirmButtonEnabled]="isEditing && isValid"
  [confirmButtonDisabledTooltip]="'respond.communication.tab.escalationList.confirmationButtons.invalid.tooltip' | t"
  [useDeleteButton]="hasDeletePermission && !isTypeDefault"
  [useMenuButton]="true"
  [showActionButtons]="hasEditPermission"
  [templateReadonly]="readonlyTemplate"
  [templateEditing]="editingTemplate"
  (toggleExpandEvent)="toggleExpand($event)"
  (toggleEditEvent)="toggleEdit($event)"
  (titleChangeEvent)="setEscalationListName($event)"
  (saveEvent)="handleSave()"
  (cancelEvent)="handleEditCanceled()"
  (deleteEvent)="handleDelete()"
></escalation-expand-card>

<ng-template #readonlyTemplate>
  <div class="card-content-container">

    <div class="row-with-two-columns">
      <div class="label-value-container max-width">
        <label>{{ 'respond.communication.tab.escalationList.owner.label' | t}}</label>
        <p>{{selectedOwnerContactAutocompleteOption?.displayValue}}</p>
      </div>

      <div class="label-value-container">
        <label>{{ 'respond.communication.tab.escalationList.period.label' | t}}</label>
        <p>{{selectedPeriod.displayValue}}</p>
      </div>
    </div>

    <div
      class="escalation-list-conditions row-with-two-columns"
      *ngIf="!isTypeDefault"
    >
      <div class="label-value-container max-width">
        <label>{{ 'respond.communication.tab.escalationList.connectors.label' | t}}</label>
        <ng-container *ngIf="selectedConnectorsAutocompleteOptions?.length > 0; else noConnectorsReadonlyTemplate">
          <p
            class="connector-readonly-item"
            *ngFor="let connector of selectedConnectorsAutocompleteOptions"
          >
            {{connector.displayValue}}
            <img
              class="datasource-type-icon"
              [src]="connector.image"
            />
          </p>
        </ng-container>
        <ng-template #noConnectorsReadonlyTemplate>
          <p>-</p>
        </ng-template>
      </div>

      <div class="label-value-container max-width">
        <label>{{ 'respond.communication.tab.escalationList.playbooks.label' | t}}</label>
        <ng-container *ngIf="selectedPlaybookAutocompleteOptions?.length > 0; else noPlaybooksReadonlyTemplate">
          <p *ngFor="let playbook of selectedPlaybookAutocompleteOptions">{{playbook.displayValue}}</p>
        </ng-container>
        <ng-template #noPlaybooksReadonlyTemplate>
          <p>-</p>
        </ng-template>
      </div>
    </div>

    <div class="escalation-levels">
      <div class="column-labels">
        <label class="levels-column">{{'respond.communication.tab.escalationList.level.label' | t}}</label>
        <label class="contacts-column">Contact</label>
        <label class="methods-column">{{'respond.communication.tab.escalationList.methods.label' | t}}</label>
        <label *ngIf="showPrioritiesMatrix" class="priorities-applications-column readonly-column"></label>
        <label *ngIf="showPrioritiesMatrix"
          class="priorities-permissions-column priorities-permissions-p1-column">{{'respond.communication.tab.escalationList.priorities.P1.label'
          | t}}</label>
        <label *ngIf="showPrioritiesMatrix"
          class="priorities-permissions-column priorities-permissions-p2-column">{{'respond.communication.tab.escalationList.priorities.P2.label'
          | t}}</label>
        <label *ngIf="showPrioritiesMatrix"
          class="priorities-permissions-column priorities-permissions-p3-column">{{'respond.communication.tab.escalationList.priorities.P3.label'
          | t}}</label>
        <label *ngIf="showPrioritiesMatrix"
          class="priorities-permissions-column priorities-permissions-p4-column">{{'respond.communication.tab.escalationList.priorities.P4.label'
          | t}}</label>

      </div>
      <div class="levels-list">
        <communication-escalation-level
          *ngFor="let escalationLevel of escalationList.escalationLevels"
          [escalationLevel]="escalationLevel"
          [contacts]="contacts"
          [isDefaultList]="isTypeDefault"
          [showPrioritiesMatrix]="showPrioritiesMatrix"
          [isEditing]="false"
        ></communication-escalation-level>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editingTemplate>
  <div class="card-content-container">

    <div class="row-with-two-columns">
      <div class="label-value-container max-width">
        <label>{{ 'respond.communication.tab.escalationList.owner.label' | t}}</label>
        <autocomplete
          class="escalation-list-owner-select"
          [required]="true"
          [placeholder]="'respond.communication.tab.escalationList.owner.placeholder' | t"
          [autocompleteType]="customAutocompleteType"
          [customValues]="ownerContactAutocompleteOptions"
          [selectedItems]="selectedOwnerContactAutocompleteOption ? [selectedOwnerContactAutocompleteOption] : []"
          [initItems]="selectedOwnerContactAutocompleteOption ? [selectedOwnerContactAutocompleteOption] : []"
          [showInInputAtInit]="true"
          [canMultiSelect]="false"
          [oneItemAtATime]="true"
          (onItemSelected)="handleOwnerSelected($event)"
        ></autocomplete>
      </div>

      <div class="label-value-container max-width">
        <label>{{ 'respond.communication.tab.escalationList.period.label' | t}}</label>
        <ui-select
          ngDefaultControl
          class="escalation-list-period-select"
          [name]="'listPeriod'"
          [placeholder]="'respond.communication.tab.escalationList.typeEnum.placeholder' | t"
          [required]="true"
          [disabled]="availablePeriodSelectOptions.length === 1"
          [options]="availablePeriodSelectOptions"
          [ngModel]="selectedPeriod.value"
          (ngModelChange)="handlePeriodSelected($event)"
        >
        </ui-select>
      </div>
    </div>

    <div
      class="escalation-list-conditions row-with-two-columns"
      *ngIf="!isTypeDefault"
    >
      <div class="label-value-container max-width">
        <label>{{ 'respond.communication.tab.escalationList.connectors.label' | t}}</label>
        <autocomplete
          class="escalation-list-connector-select"
          [required]="true"
          [placeholder]="'respond.communication.tab.escalationList.connectors.placeholder' | t"
          [autocompleteType]="customAutocompleteType"
          [customValues]="connectorsAutocompleteOptions"
          [selectedItems]="selectedConnectorsAutocompleteOptions"
          [initItems]="selectedConnectorsAutocompleteOptions"
          [showInInputAtInit]="false"
          [canMultiSelect]="true"
          [oneItemAtATime]="false"
          [preserveSearchQuery]="true"
          (onItemSelected)="handleConnectorSelected($event)"
        ></autocomplete>
        <div
          *ngFor="let connector of selectedConnectorsAutocompleteOptions"
          class="item-badge"
        >
          <span class="connector-name-and-icon">
            {{connector.displayValue}}
            <img
              class="datasource-type-icon"
              [src]="connector.image"
            /></span>
          <ui-icon
            name="close-circle"
            modifier="gray"
            [tooltip]="'common.action.delete'| t"
            (click)="handleDeleteConnector(connector.value)"
          ></ui-icon>
        </div>
      </div>

      <div class="label-value-container max-width">
        <label>{{ 'respond.communication.tab.escalationList.playbooks.label' | t}}</label>
        <autocomplete
          class="escalation-list-playbook-select"
          [required]="true"
          [placeholder]="'respond.communication.tab.escalationList.playbooks.placeholder' | t"
          [autocompleteType]="customAutocompleteType"
          [customValues]="playbookAutocompleteOptions"
          [selectedItems]="selectedPlaybookAutocompleteOptions"
          [initItems]="selectedPlaybookAutocompleteOptions"
          [showInInputAtInit]="false"
          [canMultiSelect]="true"
          [oneItemAtATime]="false"
          [preserveSearchQuery]="true"
          (onItemSelected)="handlePlaybookSelected($event)"
        ></autocomplete>
        <div
          *ngFor="let playbook of selectedPlaybookAutocompleteOptions"
          class="item-badge"
        >
          <span>{{playbook.displayValue}}</span>
          <ui-icon
            name="close-circle"
            modifier="gray"
            [tooltip]="'common.action.delete'| t"
            (click)="handleDeletePlaybook(playbook.value)"
          ></ui-icon>
        </div>
      </div>
    </div>

    <div class="escalation-levels">
      <div class="column-labels">
        <label class="levels-column">{{'respond.communication.tab.escalationList.level.label' | t}}</label>
        <label class="contacts-column">Contact</label>
        <label class="methods-column">{{'respond.communication.tab.escalationList.methods.label' | t}}</label>
        <label *ngIf="showPrioritiesMatrix" class="priorities-applications-column">{{'respond.communication.tab.escalationList.priorities.application.label' | t}}</label>
        <label *ngIf="showPrioritiesMatrix"
          class="priorities-permissions-column priorities-permissions-p1-column">{{'respond.communication.tab.escalationList.priorities.P1.label'
          | t}}</label>
        <label *ngIf="showPrioritiesMatrix"
          class="priorities-permissions-column priorities-permissions-p2-column">{{'respond.communication.tab.escalationList.priorities.P2.label'
          | t}}</label>
        <label *ngIf="showPrioritiesMatrix"
          class="priorities-permissions-column priorities-permissions-p3-column">{{'respond.communication.tab.escalationList.priorities.P3.label'
          | t}}</label>
        <label *ngIf="showPrioritiesMatrix"
          class="priorities-permissions-column priorities-permissions-p4-column">{{'respond.communication.tab.escalationList.priorities.P4.label'
          | t}}</label>
      </div>
      <div class="levels-list">
        <communication-escalation-level
          *ngFor="let escalationLevel of escalationList.escalationLevels; let isLast = last;"
          [escalationLevel]="escalationLevel"
          [contacts]="contacts"
          [isEditing]="true"
          [isDefaultList]="isTypeDefault"
          [showPrioritiesMatrix]="showPrioritiesMatrix"
          [canDeleteEscalationLevel]="escalationList.escalationLevels.length > 1 && isLast"
          (deleteEvent)="removeEscalationLevel($event)"
        ></communication-escalation-level>
      </div>
      <button
        ui-button
        color="listActionFlat"
        icon="plus"
        icon-size="medium"
        [disabled]="!isValidEscalationLevels"
        (click)="addEscalationLevel()"
      >
        {{ 'respond.communication.tab.escalationList.addEscalationLevel' | t }}
      </button>
    </div>
  </div>

</ng-template>