import { Component, OnInit, ViewChild } from '@angular/core';
import { GuestUsersTableComponent } from '../../components/guest-users-table/guest-users-table.component';

@Component({
  selector: 'org-guest-users',
  templateUrl: './guest-users.container.html',
  styleUrls: ['./guest-users.container.scss'],
})
export class GuestUsersContainer implements OnInit {
  @ViewChild('guestUsersTable') public guestUsersTable: GuestUsersTableComponent;
  constructor() { }

  ngOnInit(): void {
  }

  public onScrollToBottom(): void {
    this.guestUsersTable.onScrollToBottom();
  }
}
