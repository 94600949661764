<div class="card-content">
  <settings-edition-card
    [icon]="'icon_download_warning_cropped.svg'"
    [title]="'settings.downloads.card.title' | t"
    [canSave]="isValid"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <p>
      {{ 'settings.downloads.card.description.read' | t }}
    </p>
    <div class="labels">
      <div class="label-settings">
        <ng-container *ngIf="isActivated">
          <p
            [innerHTML]="
              'settings.downloads.card.label.activated' | t: { interval: downloadsFrequencyForm.value.frequency }
            "
          ></p>
        </ng-container>
        <ng-container *ngIf="!isActivated">
          <p [innerHTML]="'settings.downloads.card.label.deactivated' | t"></p>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editing>
  <p class="edit-description">{{ 'settings.downloads.card.description.edit' | t }}</p>
  <ui-toggle
    [toggled]="isActivated"
    [labelEnabled]="'settings.notification.silencing.toggle.activated' | t"
    [labelDisabled]="'settings.notification.silencing.toggle.deactivated' | t"
    (onClick)="toggleSilencingActivation()"
  ></ui-toggle>
  <button
    *ngIf="downloadsFrequencyForm.value.frequency !== 1 && isActivated"
    class="default-values"
    (click)="restoreDefaults()"
  >
    {{ 'settings.notification.frequency.default.values' | t }}
  </button>
  <div class="selector labels">
    <ng-container *ngIf="isActivated">
      <form [formGroup]="downloadsFrequencyForm" class="form with-default" *ngIf="!isLoading; else loading">
        <ui-field-group class="multi-select">
          <ui-select
            [options]="frequencyOptions"
            name="frequency"
            control="frequency"
            [group]="downloadsFrequencyForm"
            [label]="'settings.notification.frequency.label.frequency' | t"
            [required]="true"
          >
          </ui-select>
        </ui-field-group>
      </form>
    </ng-container>
  </div>
</ng-template>
