import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-task-expensable-container',
  templateUrl: './task-expensable-container.component.html',
  styleUrls: [ './task-expensable-container.component.scss' ],
})
export class TaskExpensableContainerComponent implements OnInit {
  private _isCollapsed = true;

  constructor() { }

  ngOnInit(): void {
  }

  public get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  public toggleCollapse(): void {
    this._isCollapsed = !this._isCollapsed;
  }
}
