import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UiDatetimePickerComponent } from '@ui-kit/components/ui-datetime-picker/ui-datetime-picker.component';
import { Eco } from 'projects/@common/definitions/eco';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IncidentDifferenceService } from '../../../../../../services/incident.difference.service';
import { TabNamesEnum } from '../../../../incidents-details.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IncidentHistoryTabFiltersService } from '../../../history-tab/components/incident-history-tab-filters/incident-history-tab-filters.service';
import { filter } from 'rxjs/operators';

const dayDurationMilliseconds = 24 /* hours*/ * 60 /* minutes*/ * 60 /* seconds*/ * 1000 /* milliseconds*/;

@Component({
  selector: 'chronology-timestamp',
  templateUrl: './chronology-timestamp.component.html',
  styleUrls: [ './chronology-timestamp.component.scss' ],
})
export class ChronologyTimestampComponent implements OnInit {
  @Input() public id: string = "";

  @Input() public type: string = "";

  @Input() public incidentId: string = "";

  @Input() public editable: boolean = false;

  @Input() public deletable: boolean = false;

  @Input() public locale: Eco.Languages;

  @Input() public isEditing: boolean = false;

  @Input() public showHistoryLink: boolean = false;

  @Input() public hideEditButtons: boolean = false;

  @Input() public readonly defaultTimestamp: number = null;

  @Input() public readonly minDate: Date;

  @Output() public toggleEditEvent = new EventEmitter<boolean>();

  @Output() public deleteEvent = new EventEmitter<void>();

  @Output() public updateEvent = new EventEmitter<number>();

  @Output() public toggleFilterStateChanged = new EventEmitter<boolean>();

  @ViewChild("datetimePicker") public datetimePicker: UiDatetimePickerComponent;

  public currentTimestamp: number;

  private prevTimestamp: number;

  constructor(
    public i18nService: I18nService,
    private readonly incidentDifferenceService: IncidentDifferenceService,
    private readonly incidentHistoryTabFiltersService : IncidentHistoryTabFiltersService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentTimestamp = typeof this.defaultTimestamp === "number"
      ? this.defaultTimestamp
      : null;

    this.prevTimestamp = this.currentTimestamp;
  }

  public get maxDate(): Date {
    return new Date();
  }

  public get labelTranslationKey(): string {
    return `incidents.container.page.details.tab.chronology.timestamp.label.${this.type}`;
  }

  public reset(): void {
    this.currentTimestamp = null;
    this.prevTimestamp = null;
    this.datetimePicker.reset();
  }

  public get isValid(): boolean {
    return this.currentTimestamp > dayDurationMilliseconds;
  }

  public get hasChanged(): boolean {
    return this.currentTimestamp !== this.prevTimestamp;
  }

  public handleTimestampChangeEvent(timestamp: number): void {
    this.currentTimestamp = timestamp;
    this.incidentDifferenceService.handleEditingStates({
      tabNameEnum: TabNamesEnum.CHRONOLOGY,
      itemId: TabNamesEnum.CHRONOLOGY,
      hasUnsavedChange: this.hasChanged,
    });
  }

  public handleEditConfirmationEvent(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.emitTimestampChangedEvent();
      this.prevTimestamp = this.currentTimestamp;
    } else {
      this.datetimePicker.setDefaultState();
      this.currentTimestamp = this.prevTimestamp;
    }
    this.setIsEditing(false);
  }

  public setIsEditing(newState: boolean): void {
    if (this.editable) {
      this.toggleEditEvent.emit(newState);
    }
  }

  public handleDeleteEvent(event: any): void {
    if (this.deletable) {
      this.deleteEvent.emit();
    }
  }

  public setToggleFilterStateChange(value: boolean): void {
    this.toggleFilterStateChanged.emit(value);  // Emit the toggle state
  }

  private emitTimestampChangedEvent(): void {
    this.updateEvent.emit(this.currentTimestamp);
  }

  private handleViewHistoryEvent(): void {

    const queryParams = { ... this.activatedRoute.snapshot.queryParams };

    if (queryParams['tab']) {
      queryParams['tab'] = 'history';
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });

    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      this.incidentHistoryTabFiltersService.applyNotificationActionsFilter(true);
      this.setToggleFilterStateChange(true);  // Emit the toggle filter state
    });
  }
}
