import { DatePipe } from "@angular/common";
import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { AllSelectorCustomValue } from "@ui-kit/components/autocomplete/organization-autocomplete/organization-autocomplete.component";
import { saveAs } from 'file-saver';
import { Eco } from "projects/@common/definitions/eco";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { SearchMode } from "projects/@common/modules/layout/components/page/page.component";
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { ClearSelectedOrganization } from "projects/@common/modules/layout/stores/app.state";
import { Notice, NoticeLevels, NoticeService } from "projects/@common/modules/notice/notice.service";
import { ConnectorsApiOrganizationService, ManagedOrganization } from "projects/@common/services/api/detect/organizations/connectors-api-organizations";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { IIncident, IListIncidentRequest, ListOwnersResponse } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { GetIncidentsSortElements, getDefaultIncidentsDateFrom, getDefaultIncidentsOrderBy } from "projects/@common/services/api/respond/incidents/models/getIncidentsRequest";
import { IncidentsStats } from "projects/@common/services/api/respond/incidents/models/incidentsStats";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Subscription } from "rxjs/internal/Subscription";
import { IncidentFiltersService, IncidentsFilterName } from "../respond-incident-table-filters/incident-filters.service";

export interface IIncidentListViewParams {
  visibleFilters: Array<IncidentsFilterName>;
  tableColumns: Array<Object>;
  canCreateIncident: boolean;
  canDescribeIncident: boolean;
}

@Component({
  selector: 'respond-incident-list',
  templateUrl: './respond-incident-list.component.html',
  styleUrls: [ './respond-incident-list.component.scss' ],
  providers: [ IncidentFiltersService ],
})
export class RespondIncidentListComponent implements OnInit, OnDestroy {
  @Input() public viewParams: IIncidentListViewParams;

  public isOrganizationLoading = true;
  public organizationsList: ManagedOrganization[];
  public isIncidentsLoading = true;
  public incidentsTableData: IIncident[] = [];
  public incidentResponse: ResponseUtils<IIncident>;
  public incidentsStats: IncidentsStats;
  public isOwnersLoading: boolean = false;
  public ownersList: ListOwnersResponse[] = [];
  public locale: Eco.Languages;
  public searchMode = SearchMode.RESPOND;

  public allSelectorCustomValue: AllSelectorCustomValue = null;
  public isAdmConsole = false;
  public isExporting = false;

  private _queryParams: IListIncidentRequest;
  private _queryParamsSubscription: Subscription;
  private _debounceTimeout: NodeJS.Timeout;
  private _debounceTime = 300;

  constructor(
    private readonly incidentsApi: IncidentsApi,
    private readonly i18nService: I18nService,
    private readonly notice: NoticeService,
    private readonly incidentFiltersService: IncidentFiltersService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    private readonly store: Store,
    private readonly router: Router,
    @Inject('CONSOLE_TYPE') public consoleType: ConsoleType,
    private readonly datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.isAdmConsole = this.consoleType === ConsoleType.ADM;
    this.store.dispatch(new ClearSelectedOrganization());
    if (this.isAdmConsole) {
      this.getOrganizationList();
    }

    this.fetchOwners();
    this.fetchIncidentsStats();

    this.allSelectorCustomValue = {
      value: "*",
      displayValue: this.i18nService.translate('incidents.container.page.selector.all'),
    };

    this.locale = this.i18nService.currentLocale as Eco.Languages;

    setTimeout(() => {
      let params = this.activatedRoute.snapshot.queryParams;
      if (!params.dateFrom) {
        params = {
          ...params, 
          dateFrom: getDefaultIncidentsDateFrom()
        };
      }
      this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params, replaceUrl: true }).then(() => {
        this._queryParamsSubscription = this.activatedRoute.queryParams.subscribe((params: IListIncidentRequest) => {
          this._queryParams = {
            ...params,
            size: 50,
            language: this.locale,
            orderBy: params?.orderBy || getDefaultIncidentsOrderBy(),
            dateFrom: params?.dateFrom || 0,
          };
          this.fetchIncidents();
        });
      });
    }, 100);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearSelectedOrganization());
    this._debounceTimeout && clearTimeout(this._debounceTimeout);
    this._queryParamsSubscription?.unsubscribe();
  }

  public get queryParams(): IListIncidentRequest {
    return this._queryParams;
  }

  public getOrganizationList(): void {
    this.isOrganizationLoading = true;
    this.connectorsApiOrganizationService.getManagedOrganizations({ from: 0, size: 1000 })
      .then((response) => this.organizationsList = response.items)
      .catch(() => this.notice.notifyUser(new Notice(this.i18nService.translate('common.error.retry'), NoticeLevels.ERROR)))
      .finally(() => this.isOrganizationLoading = false);
  }

  public fetchOwners(): void {
    if (this.viewParams.visibleFilters.includes(IncidentsFilterName.owner)) {
      this.isOwnersLoading = true;
      this.incidentsApi.getOwners().subscribe(
        (response) => this.ownersList = response,
        (error) => this.notice.notifyUser(new Notice(this.i18nService.translate('common.error.retry'), NoticeLevels.ERROR)),
        () => {
          this.isOwnersLoading = false;
        }
      );
    }
  }

  public fetchIncidents(): void {
    this.isIncidentsLoading = true;
    this.incidentsTableData = [];
    this._queryParams.from = 0;
    this.incidentsApi.listIncidents(this._queryParams).subscribe(
      (response) => {
        this.incidentResponse = response;
        this.incidentsTableData = this.incidentResponse.getItems();
      },
      (error) => this.notice.notifyUser(new Notice(this.i18nService.translate('common.error.retry'), NoticeLevels.ERROR)),
      () => {
        this.isIncidentsLoading = false;
      }
    );
  }

  public handleLoadMore(): void {
    if (this.incidentResponse?.canLoadMore() && !this.isIncidentsLoading) {
      this.isIncidentsLoading = true;
      this._queryParams.from = this.incidentResponse.getNextItem();
      this.incidentsApi.listIncidents(this._queryParams).subscribe(
        (response) => {
          this.incidentResponse = response;
          this.incidentsTableData = this.incidentsTableData.concat(this.incidentResponse.getItems());
        },
        (error) => this.notice.notifyUser(new Notice(this.i18nService.translate('common.error.retry'), NoticeLevels.ERROR)),
        () => {
          this.isIncidentsLoading = false;
        }
      );
    }
  }

  public fetchIncidentsStats(): void {
    this.incidentsStats = null;
    this.incidentsApi.getIncidentsStats().subscribe(
      (response) => {
        this.incidentsStats = response.reduce((acc, curr) => new IncidentsStats({
          open: acc.open + curr.open,
          closed: acc.closed + curr.closed,
          closedFalsePositive: acc.closedFalsePositive + curr.closedFalsePositive,
          closedTest: acc.closedTest + curr.closedTest,
          organizationId: null,
        }), new IncidentsStats({ open: 0, closed: 0, closedFalsePositive: 0, closedTest: 0, organizationId: null }));
      },
      (_error) => this.notice.notifyUser(new Notice(this.i18nService.translate('common.error.retry'), NoticeLevels.ERROR))
    );
  }

  public handleDebouncedRefresh(forceManualRefresh: boolean = false): void {
    clearTimeout(this._debounceTimeout);
    this._debounceTimeout = setTimeout(() => {
      if (forceManualRefresh) {
        this.manualRefresh();
        return;
      }
      this.incidentFiltersService.applyFiltersInQueryParams();
      this.fetchIncidentsStats();
    }, this._debounceTime);
  }

  public handleSortingChange(sortElements: GetIncidentsSortElements): void {
    this.incidentFiltersService.tableSorting = sortElements;
    this.handleDebouncedRefresh();
  }

  public async handleOrganizationChanged(org: { id: string; }): Promise<void> {
    if (org && org.id !== '*') {
      const selectedOrganization = await this.connectorsApiOrganizationService.describeOrganization(org.id);
      this.incidentFiltersService.applyOrgFilter([ selectedOrganization.organization_respond_id ]);
    } else {
      this.incidentFiltersService.applyOrgFilter([]);
    }
  }

  public exportCsv(): void {
    this.isExporting = true;

    const request = {
      ...this._queryParams,
      from: 0,
      size: 500,
    };
    this.incidentsApi.exportIncidents(request).subscribe(
      (response) => {
        const incidentItems = response.getItems();
        const firstItem = incidentItems[0];

        const incidentColumns = [ "shortId", "name", "zohoTicket", "organizationName", "status", "closeStatus", "priority", "severity", "ownerName" ];
        const incidentTimestampColumns = [ "createdAt", "updatedAt", "indexedAt", "closedAt", "lastClosedAt", "lastManualClosedAt", "manualClosedAt" ];
        const excludeFields = [ "playbookParams", "additionalInfo", "summary" ];
        incidentColumns.push(...Object.keys(firstItem.incident).filter((key) => !incidentColumns.includes(key) && !excludeFields.includes(key)));

        let usecaseColumns = [ "id", "templateId", "usecaseCatalogId", "datasourceType", "affectedResources" ];
        const timestampColumns = Object.values(firstItem.timestamps).map((timestamp) => timestamp.type) as string[];
        const historyTimestampColumns = Object.values(firstItem.historyTimestamps).map((timestamp) => timestamp.action) as string[];
        const phaseTimestampColumns = Object.keys(firstItem.phaseTimestamps) as string[];
        const firstLevelColumns = [ "alertCount", "assetCount", "firstAlertTimestamp", "whitelistedAlertCount" ];

        let columns = [ ...incidentColumns, ...usecaseColumns, ...timestampColumns, ...historyTimestampColumns, ...phaseTimestampColumns, ...firstLevelColumns ];

        const csv = incidentItems.map((item) => columns.map((field) => {
          if (incidentColumns.includes(field)) {
            if (incidentTimestampColumns.includes(field)) {
              return this.datePipe.transform(item.incident[field], 'yyyy-MM-ddTH:mm:ss');
            }
            return item.incident[field];
          }
          if (usecaseColumns.includes(field)) {
            return item.usecases?.map((usecase) => usecase[field])?.join("; ");
          }
          if (timestampColumns.includes(field)) {
            return this.datePipe.transform(item.timestamps?.find((timestamp) => timestamp?.type === field)?.timestamp, 'yyyy-MM-ddTH:mm:ss');
          }
          if (historyTimestampColumns.includes(field)) {
            return this.datePipe.transform(item.historyTimestamps?.find((timestamp) => timestamp?.action === field)?.timestamp, 'yyyy-MM-ddTH:mm:ss');
          }
          if (phaseTimestampColumns.includes(field)) {
            return this.datePipe.transform(item.phaseTimestamps[field]?.closedAt, 'yyyy-MM-ddTH:mm:ss');
          }
          if (usecaseColumns.includes(field)) {
            return item.usecases?.map((usecase) => usecase[field])?.join("; ");
          }
          return item[field];
        }).join(','));

        // rename the usecase columns and regenerate the columns list
        usecaseColumns = [ "usecaseId", "templateId", "usecaseCatalogId", "datasourceType", "affectedResourcesTemplate" ];
        columns = [ ...incidentColumns, ...usecaseColumns, ...timestampColumns, ...historyTimestampColumns, ...phaseTimestampColumns, ...firstLevelColumns ];
        csv.unshift(columns.join(','));
        const csvArray = csv.join('\r\n');

        const blob = new Blob([ csvArray ], { type: 'text/csv' });
        saveAs(blob, "incidents.export.csv");

        this.isExporting = false;
      },
      (error) => {
        this.isExporting = false;
        this.notice.error("incidents.export.error");
      }
    );
  }

  private manualRefresh(): void {
    this.fetchIncidents();
    this.fetchIncidentsStats();
  }
}
