<div class="drawer-detail--scrollable visible">
  <div class="drawer-detail--content">
    <div class="button-container">
      <button
        *ngIf="!isEditing && !isCreate && canUpdateSecret"
        ui-button
        icon="icon_modify"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="medium"
        color="listActionFlat"
        (click)="setEditing()"
      >
        {{ 'common.action.edit' | t }}
      </button>
      <button
        *ngIf="!isEditing && !isCreate && canDeleteSecret"
        ui-button
        icon="icon_delete"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="medium"
        color="listActionFlat"
        (click)="openDeleteDialog()"
      >
        {{ 'common.action.delete' | t }}
      </button>
    </div>
    <ui-title [name]="'secrets.details.properties' | t"></ui-title>
    <ui-field-group layout="vertical" class="field-group">

      <ui-switch-field-static
        *ngIf="isCreate"
        class="static-container"
        [isEditing]="isCreate"
        [label]="'secrets.details.name' | t"
        name="Name"
        control="name"
        [group]="formGroup"
        [required]="true"
        maxlength="60"
        value="{{ data?.secret?.name }}"
        [tooltipText]="'secrets.details.name.tooltip' | t"
      ></ui-switch-field-static>
      <ui-static *ngIf="!isCreate"
        [label]="'secrets.details.name' | t"
        [value]="data?.secret?.name"
      ></ui-static>
      <label ui-label class="under-label" *ngIf="!isInvalidSecretName && isCreate">
        {{'secrets.details.name.limit' | t}}
      </label>
    </ui-field-group>
    <ui-field-group>
      <ui-select
        *ngIf="isCreate && !hasSingleOption"
        [options]="typeEnum"
        name="type"
        control="type"
        [placeholder]="'datasources.tab.creation.dropdown.implementation.placeholder' | t"
        [group]="implementation"
        (optionClick)="buildSecretForm($event)"
        [label]="'secrets.details.type' | t"
        [required]="true"
      ></ui-select>
      <ui-static
        *ngIf="!isCreate || hasSingleOption"
        label="{{'secrets.details.type' | t}}"
        [value]="currentTemplateUsed?.name | translatedObject"
      ></ui-static>
    </ui-field-group>
    <ui-field-group layout="vertical" class="field-group">
      <ui-static *ngIf="!isCreate && !isEditing && !getExpiration"
        [label]="'secrets.details.expiration' | t"
        [value]="'secrets.details.expiration.none' | t"
      ></ui-static>
      <ui-datetime-picker
        #datetimePicker
        *ngIf="isCreate || isEditing || getExpiration"
        [label]="'secrets.details.expiration' | t"
        [withHours]="false"
        [isReadonly]="!isEditing"
        [showNowButton]="false"
        [showResetButton]="true"
        [required]="false"
        [minDate]="minDate"
        [defaultTimestamp]="getExpiration"
        (timestampChangedEvent)="setExpiration($event)"
      ></ui-datetime-picker>
    </ui-field-group>
    <ui-field-group  layout="vertical" class="field-group">
      <ui-switch-text-static
        [isEditing]="isEditing"
        class="static-container"
        [label]="'secrets.details.note' | t"
        name="Note"
        control="note"
        [group]="formGroup"
        [required]="false"
        maxlength="255"
        value="{{ data?.secret?.note }}"
      ></ui-switch-text-static>
      <label ui-label class="under-label" *ngIf="isEditing">
        {{'secrets.details.note.limit' | t}}
      </label>
    </ui-field-group>

    <ui-title [name]="'datasources.tab.info.configuration' | t">
      <ui-checkbox
        *ngIf="isEditing && !isCreate"
        [label]="'common.action.edit' | t"
        name="editSecretValues"
        controlName="editSecretValues"
        [group]="formGroup"
        [checked]="false"
        [isEditable]="true"
      ></ui-checkbox>
    </ui-title>
    <div class="margin--small--top margin--small--bottom">
      <ng-container *ngFor="let parameter of parameters">
        <ui-field-group *ngIf="(!isEditing || !isEditingSecretValues) && !isCreate">
          <ui-static
            [allowLineBreak]="true"
            [label]="parameter.label | translatedObject"
            [value]="parameter.values || '***' "
          ></ui-static>
        </ui-field-group>
      </ng-container>

      <ng-container
        *ngFor="let parameter of parameters; let i = index"
      >

        <ui-field-group *ngIf="(isEditing && isEditingSecretValues) || isCreate">
          <!-- ByPass for the FILE_UPLOAD_INPUT -->
          <ng-container *ngIf="parameter.typeOfForm === uiTypeOfFormEnum.FILE_UPLOAD_INPUT; else dynamicFormTemplate">
           <secret-store-upload-or-generate
              [class]="dynamic-vars"
              [formConfig]="parameter"
              [description]="descriptionFormat"
              [currentTemplate]="currentTemplateUsed"
              (onGeneratedCertificate)="onCertificateGenerated($event)"
            ></secret-store-upload-or-generate>
          </ng-container>

          <ng-template #dynamicFormTemplate>
            <dynamic-form-template
              class="dynamic-vars"
              [locale]="i18n.currentLocale"
              [formConfig]="parameter"
              [description]="descriptionFormat"
            ></dynamic-form-template>
          </ng-template>

        </ui-field-group>

      </ng-container>
    </div>
  </div>

  <ui-form-actions
    *ngIf="isEditing"
    layout="centered"
    class="visible"
  >
    <ng-container>
      <button
        ui-button
        color="standard"
        [disabled]="!isValid"
        (click)="isCreate ? create(): save()"
      >
        {{ 'common.action.save' | t }}
      </button>
      <button
        ui-button
        color="secondary"
        (click)="isCreate ? onCancelCreate() : onCancel()"
      >
        {{ 'common.action.cancel' | t }}
      </button>
    </ng-container>
  </ui-form-actions>
</div>

<ui-dialog
  *ngIf="showDeleteDialog"
  [headtitle]="'secrets.table.action.delete.dialog.title' | t"
  [actionButton]="'common.action.delete' | t"
  [cancelButton]="'common.action.cancel' | t"
  [message]="secretMessage"
  (onButtonClick)="deleteSecret($event)"
  [isLoading]="isDeletingInProgress"
>
</ui-dialog>
