import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UiKitModule } from "@ui-kit/uikit.module";
import { ComponentsModule } from "projects/@common/components/components.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { PipesModule } from "projects/@common/modules/pipes/pipes.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { ObservableActionsCellComponent } from './components/observable-actions-cell/observable-actions-cell.component';
import { ObservableContainerComponent } from "./components/observable-container/observable-container.component";
import { ObservableIndicatorsCellComponent } from './components/observable-indicators-cell/observable-indicators-cell.component';
import { ObservableValueCellComponent } from './components/observable-value-cell/observable-value-cell.component';


const components = [
  ObservableContainerComponent,
  ObservableValueCellComponent,
  ObservableIndicatorsCellComponent,
  ObservableActionsCellComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    PipesModule,
    RouterModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
  ],
})
export class ObservablesModule { }
