<div
  class="severity-not-editing-view"
  [ngClass]="{'disabled':disabled}"
  [style]="'width: 30px;'"
  [tooltip]="disabled ? null : ('common.action.edit'| t)"
  (click)="openPicker()"
>
  <alerting-badge [type]="priority"></alerting-badge>
  <img
    *ngIf="!disabled"
    alt="edit-icon"
    class="edit-icon"
    src="assets/favicons/icon_modify_disabled.svg"
  />
</div>

<div
  *ngIf="showDropdown"
  class="severity-editing-view"
>
  <div class="editing-view-top-row">
    <ui-select
      #priorityDropdown
      name="priorityInput"
      control="priorityInput"
      [customTemplate]="true"
      [options]="priorities"
      [group]="formGroup"
      [required]="true"
      [label]="'incidents.container.page.details.tab.detail.summary.priority' | t"
    >
      <div
        *ngFor="let priority of priorities"
        class="option"
        [ngClass]="{ 'selected-option': currentPriorityValue === priority.value}"
        (click)="handlePriorityChange(priority.value)"
      >
        <alerting-badge
          class="option-priority-badge"
          [type]="priority.value"
        ></alerting-badge>
        <span>
          {{priority.displayValue}}
        </span>
      </div>
    </ui-select>
    <organization-preferred-language
      class="preferered-language"
      [organizationEcoId]="organizationEcoId"
    ></organization-preferred-language>
  </div>

  <div>
    <ui-text-field
      name="commentInput"
      control="commentInput"
      [label]="'incidents.container.page.details.tab.detail.summary.priority.comment' | t"
      [placeholder]=""
      [group]="formGroup"
      [required]="true"
      maxlength="1000"
    ></ui-text-field>
  </div>

  <ui-confirmations-buttons
    [isConfirmEnabled]="isFormValid"
    (confirmationEvent)="handleConfirmation($event)"
  ></ui-confirmations-buttons>

</div>

<ui-click-out-handler
  *ngIf="showDropdown"
  (clickEvent)="handleConfirmation(null)"
></ui-click-out-handler>