export enum SoarConnectorTypes {
  MICROSOFT_365 = 'MICROSOFT_365',
  AWS = 'AWS'
}

export enum SoarConnectorActionType {
  RESPONDER = 'RESPONDER',
  ANALYZER = 'ANALYZER'
}

export enum SoarConnectorTypesEnum {
  MICROSOFT_365_RESPONDER = 'MICROSOFT_365_RESPONDER',
  MICROSOFT_365_ANALYZER = 'MICROSOFT_365_ANALYZER',
  AWS_RESPONDER = 'AWS_RESPONDER',
  AWS_ANALYZER = 'AWS_ANALYZER',
}

export interface IConnector {
  connector: SoarConnectorTypes;
  type: SoarConnectorActionType;
  createdAt: number;
  createdById: string;
  createdByName: string;
  default: boolean;
  id: string;
  name: string;
  organizationId: string;
  config: ISoarConnectorConfig;
  status: SoarConnectorStatus;
  updatedAt: number;
  metadata?: any;
}

export type IDescribeSoarConnectorResponse = {
  soarConnector: IConnector;
  organization: OrganizationPublicInfo;
  permissionDefinition: {[key:string]: {type: PermissionTypes}};
};

export interface OrganizationPublicInfo {
  organizationId: string;
  organizationName: string;
  organizationEcoId: string;
}

export interface IConnectorAction {
  enabledByPermission: boolean;
  id: string;
  lastExecutionStatus: string;
  lastExecutionTimestamp: number;
  name: string;
  soarConnectorInstanceId: string;
  status: SoarConnectorActionStatus;
}

export interface ISoarConnector {
  actions: IConnectorAction[];
  connector: IConnector;
}

export interface ISoarConnectorConfig {
  policyId?: string;
  secretId?: string;
  accountIds?: string[];
}

export interface ISoarConnectorPayload {
  connector: SoarConnectorTypesEnum;
  name: string;
  organizationId: string;
  secretId: string;
  default?: boolean;
  id?: boolean;
}

export interface ISoarConnectorAction {
  id?: string;
  soarConnectorInstanceId: string;
  name: string;
  accountId: string;
  type: SoarConnectorActionType;
  status: SoarConnectorActionStatus;
  enabledByPermissions: string[];
  action: IActionRepresentation;
  createdAt: number;
  updatedAt: number;
}

export interface IActionRepresentation {
  applications: string[];
  descriptionI18n: {fr: string, en: string};
  apiName: string;
  nameI18n: {fr: string, en: string};
  permissions: string[] | string[][];
  type: ActionTypes;
  additionalPermissions: string[];
}

export interface IDataConnectorAction {
  id?: string;
  dataConnectorInstanceId: string;
  name: string;
  accountId: string;
  status: DataConnectorGrantedStatus;
  permission: {type: PermissionTypes};
  unknownReason: UnknownReason,
  createdAt: number;
  updatedAt: number;
}

export interface IDataConnectorStatuses {
  dataConnectorInstanceId: string;
  organizationId: string;
  name: string;
  accountId: string;
  status: DataConnectorGrantedStatus;
}

export enum UnknownReason {
  NO_FILE_IN_DRIVE = 'NO_FILE_IN_DRIVE',
  MISSING_PARENT_PERMISSION = 'MISSING_PARENT_PERMISSION',
}

export type IUpdateSoarConnectorResponse = IDescribeSoarConnectorResponse;

export enum PermissionTypes {
  READ = 'READ',
  WRITE = 'WRITE'
}

export enum DataConnectorGrantedStatus {
  GRANTED = 'GRANTED',
  MISSING = 'MISSING',
  UNKNOWN = 'UNKNOWN'
}

export enum ActionTypes {
  RESPONDER = 'RESPONDER',
  ANALYZER = 'ANALYZER'
}

export enum SoarConnectorStatus {
  CONNECTED = 'CONNECTED',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN'
}

export enum SoarConnectorActionStatus {
  FULLY_ENABLED = 'FULLY_ENABLED',
  PARTIALLY_ENABLED = 'PARTIALLY_ENABLED',
  MISSING_PERMISSION = 'MISSING_PERMISSION',
  UNKNOWN = 'UNKNOWN'
}