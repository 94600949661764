import { TimeUtil } from "@ui-kit/services/time-util";
import { RequestDirection } from "projects/@common/definitions/consoles.enum";
import { IDaysTypeEnum, IWorkShiftEnum } from "../../kpi/kpi.definitions";

import { IListIncidentRequest, IListIncidentsFilters, IncidentFilterIndicatorsEnum, IncidentFilterStatusEnum, ListIncidentsOrderByEnum } from "../incidents.definitions";

export interface GetIncidentsSortElements {
  orderBy?: ListIncidentsOrderByEnum;
  direction: RequestDirection;
}

export function getDefaultIncidentsOrderBy(): ListIncidentsOrderByEnum {
  return ListIncidentsOrderByEnum.orderUpdatedAtStatus;
}

export function getDefaultIncidentsDateFrom(): number {
  return TimeUtil.getTimestampFromNowAndDays(7).dateFrom;
}

export class GetIncidentsRequest implements IListIncidentsFilters {
  language?: string;
  incidentId?: string;
  ownerIds?: string[];
  connectors?: string[];
  organizationIds?: string[];
  usecaseId?: string;
  incidentName?: string;
  shortId?: string;
  priorities?: number[];
  severities?: number[];
  dateFrom?: number = getDefaultIncidentsDateFrom();
  dateTo?: number;
  useUpdatedAtDate?: boolean;
  statuses?: IncidentFilterStatusEnum[];
  indicators?: IncidentFilterIndicatorsEnum[];
  dayTypes?: IDaysTypeEnum[];
  workShifts?: IWorkShiftEnum[];
  observable?: string;
  from?: number = 0;
  size?: number = 50;
  sort?: GetIncidentsSortElements = {
    direction: RequestDirection.desc,
    orderBy: getDefaultIncidentsOrderBy(),
  };

  constructor(jsonObj: IListIncidentsFilters) {
    Object.assign(this, jsonObj);
  }

  public get direction(): string {
    return this.sort?.direction?.toUpperCase();
  }

  public get orderBy(): string {
    return this.sort?.orderBy === 'organizationName' ? ListIncidentsOrderByEnum.organization : this.sort?.orderBy;
  }

  public getQueryParams(): IListIncidentRequest {
    const withAsset = this.indicators?.includes(IncidentFilterIndicatorsEnum.CRITICAL_ASSET) || null;
    const withWhitelistedAlert = this.indicators?.includes(IncidentFilterIndicatorsEnum.WHITELISTED_ALERT) || null;
    const breachOfConfidentiality = this.indicators?.includes(IncidentFilterIndicatorsEnum.CONFIDENTIALITY_BREACH) || null;
    return {
      language: this.fallbackToNull(this.language),
      incidentId: this.fallbackToNull(this.incidentId),
      incidentName: this.fallbackToNull(this.incidentName),
      organizationIds: this.fallbackToNull(this.organizationIds),
      usecaseIds: this.usecaseId ? [ this.usecaseId ] : null,
      shortId: this.fallbackToNull(this.shortId),
      priorities: this.fallbackToNull(this.priorities),
      severities: this.fallbackToNull(this.severities),
      dayTypes: this.fallbackToNull(this.dayTypes),
      workShifts: this.fallbackToNull(this.workShifts),
      dateFrom: this.fallbackToNull(this.dateFrom),
      dateTo: this.fallbackToNull(this.dateTo),
      useUpdatedAtDate: this.useUpdatedAtDate ? this.useUpdatedAtDate : null,
      statuses: this.fallbackToNull(this.statuses),
      from: this.fallbackToNull(this.from),
      size: this.fallbackToNull(this.size),
      ownerIds: this.fallbackToNull(this.ownerIds),
      connectors: this.fallbackToNull(this.connectors),
      withAsset: this.fallbackToNull(withAsset),
      breachOfConfidentiality: this.fallbackToNull(breachOfConfidentiality),
      withWhitelistedAlert: this.fallbackToNull(withWhitelistedAlert),
      orderBy: this.fallbackToNull(this.orderBy),
      direction: this.fallbackToNull(this.direction),
      observable: this.fallbackToNull(this.observable),
    };
  }

  public setSort(value: GetIncidentsSortElements): void {
    this.sort = value;
  }

  private fallbackToNull(value: any): any {
    return value ?? null;
  }
}
