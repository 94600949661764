<div class="vignette-container">
  <div class="vignette-container--header">
    <p class="vignette-container--header--title">
      {{ vignetteData.title | t }}
    </p>
    <p class="vignette-container--header--state">
      {{ vignetteData.subtitle | t }}
    </p>
  </div>

  <div class="vignette-container--content">
    <img
      class="vignette-container--content--logo"
      [src]="'assets/favicons/' + vignetteData.icon + '.svg'"
      alt=""
    />
    <p class="vignette-container--content--number">{{ vignetteData.number }}</p>
  </div>
</div>
