<div
  page
  [appPagePaths]="['org', 'securityModels']"
  [disableOrganizationSelector]="true"
>
  <div class="container">
    <ui-tabs
      [color]="uiTabsColor.lightTabs"
      [borderBelowHeader]="true"
      [hasNoLeftPadding]="true"
      [hasWhiteBackroundColor]="false"
    >
      <div class="tab-container">
        <ui-tab
          [tabTitle]="'securityModel.tab.models' | t"
          tabImageActive="assets/favicons/icon_copy_active.svg"
          tabImageInactive="assets/favicons/icon_copy_inactive.svg"
        >
          <ng-template *ngTemplateOutlet="modelsTemplate"></ng-template>
        </ui-tab>
        <ui-tab
          [tabTitle]="'securityModel.tab.approvers' | t"
          tabImageActive="assets/favicons/icon_role_24x24_active.svg"
          tabImageInactive="assets/favicons/icon_role_24x24_inactive.svg"
        >
          <ng-template *ngTemplateOutlet="approversTemplate"></ng-template>
        </ui-tab>
        <ui-tab
          [tabTitle]="'securityModel.tab.archive' | t"
          tabImageActive="assets/favicons/icon_archive_active.svg"
          tabImageInactive="assets/favicons/icon_archive.svg"
        >
          <ng-template *ngTemplateOutlet="archiveTemplate"></ng-template>
        </ui-tab>
      </div>
    </ui-tabs>
  </div>
</div>

<ng-template #modelsTemplate>
  <h2>{{ 'securityModel.models.text.title' | t }}</h2>
  <p>{{ 'securityModel.models.text.description' | t }}</p>
  <div class="table-container">
    <group-templates-table></group-templates-table>
  </div>
</ng-template>

<ng-template #approversTemplate>
  <h2>{{ 'securityModel.approvers.text.title' | t }}</h2>
  <p>{{ 'securityModel.approvers.text.description1' | t }}</p>
  <p>{{ 'securityModel.approvers.text.description2' | t }}</p>
  <div class="table-container">
    <approvers-table></approvers-table>
  </div>
</ng-template>

<ng-template #archiveTemplate>
  <h2>{{ 'securityModel.archiving.text.title' | t }}</h2>
  <p>{{ 'securityModel.archiving.text.description1' | t }}</p>
  <ul>
    <li>{{ 'securityModel.archiving.text.description2' | t }}</li>
    <li>{{ 'securityModel.archiving.text.description3' | t }}</li>
  </ul>
  <p>{{ 'securityModel.archiving.text.description4' | t }}</p>
  <div class="table-container">
    <organization-archiver></organization-archiver>
  </div>
</ng-template>
