import { CategoryAxis, ColumnSeries, LabelBullet, ValueAxis, XYChart } from '@amcharts/amcharts4/charts';
import { Scrollbar, addLicense, color, create } from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ITimeInterval } from 'projects/@common/components/date-interval/date-interval.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { GenericDashboardTimeRangeEnum, getTimestampsFromDynamicRange } from '../../helpers/getTimestampsFromRange';

@Component({
  selector: 'incidents-history-var',
  templateUrl: './incidents-history-var.component.html',
  styleUrls: [ './incidents-history-var.component.scss' ],
})
export class IncidentsHistoryComponentVar implements OnInit, AfterViewInit {
  public readonly TIME_RANGE_ENUM = GenericDashboardTimeRangeEnum;
  private readonly COLORS = [ "#004C2A", "#006F3E", "#068D44", "#1AA969", "#5ECC99", "#9EDDC1", "#004C41", "#006F5F", "#068D6D", "#1AA994" ];
  private readonly maximumItemToDisplay: number = 15;

  @Input() public currentOrganization: IAutomationOrganization | null;
  public currentTimeRange: GenericDashboardTimeRangeEnum = this.TIME_RANGE_ENUM.LAST_7_DAYS;
  public chartData: { organizationName: string, totalIncidentOpen: number, totalIncidentClosed: number }[] = [];
  public dateRange: ITimeInterval;
  public incidentQueryParams;
  public isLoading: boolean = false;

  private chart: XYChart;
  private organizationInData: string[] = [];

  constructor(
    public readonly i18n: I18nService,
    private readonly incidentsApi: IncidentsApi
  ) {
    addLicense('CH259911983');
  }

  ngOnInit(): void {
    this.fetchIncidentGraphData();
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  public handleTimeRangeChange(event: GenericDashboardTimeRangeEnum): void {
    this.currentTimeRange = event;
    this.fetchIncidentGraphData();
  }

  public calculateChartHeight(): string {
    const vhMaxHeight = 75;
    const minChartHeight = 25; // Minimum chart height to ensure visibility
    const itemHeight = 12; // Height per item in vh

    // Calculate the height based on the number of items
    const chartHeight = Math.max(minChartHeight, this.chartData.length * itemHeight);
    return `${Math.min(chartHeight, vhMaxHeight)}vh`;
  }

  private fetchIncidentGraphData(): void {
    this.handleDateRange();
    this.isLoading = true;
    this.incidentsApi.getIncidentsStatsHistory({
      params: {
        organizationId: this.currentOrganization?.ecoId,
        from: this.dateRange.dateFrom,
        to: this.dateRange.dateTo,
      },
    })
      .then((data) => {
        if (data) {
          const groupedDataByOrganization = data.reduce((acc, curr) => {
            if (!acc[curr.organizationName]) {
              acc[curr.organizationName] = [];
            }
            acc[curr.organizationName].push(curr);
            return acc;
          }, {});

          this.organizationInData = Object.keys(groupedDataByOrganization);
          const mappedData = [];
          this.organizationInData.forEach((organization) => {
            const data = groupedDataByOrganization[organization];
            const mapping = {
              organizationName: organization,
              totalIncidentOpen: data.reduce((cnt, item) => cnt + item.total.open, 0),
              totalIncidentClosed: data.reduce((cnt, item) => cnt + item.total.close, 0),
            };
            if (mapping.totalIncidentOpen > 0 || mapping.totalIncidentClosed > 0) {
              mappedData.push(mapping);
            }
          });
          this.chartData = mappedData;
          this.updateChartData();
        }
      })
      .finally(() => this.isLoading = false);
  }

  private handleDateRange(): void {
    const { fromDate, toDate } = getTimestampsFromDynamicRange(this.currentTimeRange.toString(), { timeIsUtc: true });
    this.dateRange = {
      dateFrom: fromDate,
      dateTo: toDate,
    };
  }

  private createChart(): void {
    const chart = create(`INCIDENTS_HISTORY_VAR_GRAPH_ID`, XYChart);
    chart.data = this.chartData;
    chart.colors.list = this.COLORS.map((colorHexValue) => color(colorHexValue));

    const categoryAxis = chart.yAxes.push(new CategoryAxis());
    categoryAxis.dataFields.category = "organizationName";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minGridDistance = 2;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.align = 'center';
    categoryAxis.renderer.labels.template.textAlign = 'end';
    categoryAxis.renderer.labels.template.width = 160;

    const valueAxis = chart.xAxes.push(new ValueAxis());
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;

    // Incident ouverts
    const openSeries = chart.series.push(new ColumnSeries());
    openSeries.dataFields.categoryY = "organizationName";
    openSeries.dataFields.valueX = "totalIncidentOpen";
    openSeries.columns.template.strokeOpacity = 0;
    openSeries.columns.template.column.cornerRadiusBottomRight = 3;
    openSeries.columns.template.column.cornerRadiusTopRight = 3;
    openSeries.showOnInit = false;
    openSeries.columns.template.column.fill = color('#5ECC99');

    // Change series colors base on the colors list
    openSeries.columns.template.adapter.add("fill", (_, target) => {
      if (target.dataItem) {
        const colorIndex = this.organizationInData.findIndex((organizationName) => organizationName === target.dataItem.dataContext['organizationName']);
        return chart.colors.getIndex(colorIndex ? colorIndex : 0);
      }
    });
    categoryAxis.sortBySeries = openSeries;

    const openlabelBullet = openSeries.bullets.push(new LabelBullet());
    openlabelBullet.label.horizontalCenter = "left";
    openlabelBullet.label.truncate = false;
    openlabelBullet.label.dx = 5;
    openlabelBullet.label.text = `{values.valueX.workingValue} ${this.i18n.translate('respond.dashboard.incident.open')}`;

    // incident fermés
    const closeSeries = chart.series.push(new ColumnSeries());
    closeSeries.dataFields.categoryY = "organizationName";
    closeSeries.dataFields.valueX = "totalIncidentClosed";
    closeSeries.tooltipText = "{valueX}";
    closeSeries.columns.template.strokeOpacity = 0;
    closeSeries.columns.template.column.cornerRadiusBottomRight = 3;
    closeSeries.columns.template.column.cornerRadiusTopRight = 3;
    closeSeries.showOnInit = false;
    closeSeries.columns.template.column.fill = color('#004C2A');

    // Change series colors base on the colors list
    closeSeries.columns.template.adapter.add("fill", (_, target) => {
      if (target.dataItem) {
        const colorIndex = this.organizationInData.findIndex((organizationName) => organizationName === target.dataItem.dataContext['organizationName']);
        return chart.colors.getIndex(colorIndex ? colorIndex : 0).lighten(-0.25);
      }
    });

    const closelabelBullet = closeSeries.bullets.push(new LabelBullet());
    closelabelBullet.label.horizontalCenter = "left";
    closelabelBullet.label.truncate = false;
    closelabelBullet.label.dx = 5;
    closelabelBullet.label.text = `{values.valueX.workingValue} ${this.i18n.translate('respond.dashboard.incident.closed')}`;

    this.addScrollbarLogic(chart);

    this.chart = chart;
  }

  private updateChartData() {
    if (this.chart) {
      this.chart.dispose();
      this.createChart();
    }
  }

  private addScrollbarLogic(chart: XYChart): void {
    chart.events.on("datavalidated", () => {
      chart.scrollbarY = null;
      if (chart.data.length > this.maximumItemToDisplay) {
        chart.zoomOutButton.disabled = true;
        chart.mouseWheelBehavior = 'panY';
        chart.scrollbarY = new Scrollbar();
        chart.scrollbarY.orientation = "vertical";
        chart.scrollbarY.startGrip.icon.disabled = true;
        chart.scrollbarY.endGrip.icon.disabled = true;
        chart.scrollbarY.startGrip.background.disabled = true;
        chart.scrollbarY.endGrip.background.disabled = true;
        chart.scrollbarY.startGrip.draggable = false;
        chart.scrollbarY.endGrip.draggable = false;
        chart.scrollbarY.showSystemTooltip = false;
        chart.scrollbarY.thumb.showSystemTooltip = false;
        chart.scrollbarY.startGrip.showSystemTooltip = false;
        chart.scrollbarY.endGrip.showSystemTooltip = false;
        const categoryAxis = chart.yAxes.getIndex(0) as CategoryAxis;
        categoryAxis.zoomToIndexes(0, this.maximumItemToDisplay);
      }
    });
  }
}
