import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { DynamicForm, DynamicFormField, DynamicFormFieldType } from "@common/components/dynamic-form";
import { Accumulate, Accumulatables } from "@common/modules/accumulator/accumulator.store";
import { TranslatedObjectPipe } from "@common/modules/i18n/translatedObject.pipe";
import { DrawerService } from "@common/modules/layout/components/drawer/services/drawer.service";
import { DrawerState, SetDrawerIsEditing } from "@common/modules/layout/components/drawer/stores/drawer.store";
import { NoticeService, Notice, NoticeLevels } from "@common/modules/notice/notice.service";
import { IPlaybookDetail } from "@common/services/api/respond/playbooks/playbooks.definitions";
import { UsecasesApiService } from "@common/services/api/respond/usecase/usecase.api";
import { IUsecase, IAction, ActionTemplate, ActionConfigurationTemplate } from "@common/services/api/respond/usecase/usecase.definition";
import { Store } from "@ngxs/store";
import { AutocompleteTypes } from "@ui-kit/components/autocomplete/autocomplete.component";
import { AutocompleteCustomValue } from "@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component";
import { interval } from "rxjs";
import { debounce } from "rxjs/operators";
import { UsecaseDescriptionDrawerData } from "../../containers/drawers/usecase-description-drawer/usecase-description-drawer.component";
import { UsecaseUpdateForm } from "../usecase-details/usecase-update-form";
import { I18nService } from "@common/modules/i18n/i18n.service";
import { v4 as uuid } from 'uuid';
import { CustomValidators } from "@common/utils/validators";

export interface IAutocompleteActions extends AutocompleteCustomValue {
  applications?: string[];
  isLegacyAction: boolean;
}
export interface AppliedAction {
  generate_id: string;
  name: string;
  translatedName?: string;
  skipConditionDisabled?: boolean;
  inputsDisabled?: boolean;
  preconditionDisabled?: boolean;
  postconditionDisabled?: boolean;
  outputsDisabled?: boolean;
  skipConditionOriginal?: string;
  form?: DynamicForm;
  isLegacyAction?: boolean;
}

export interface ILibraryAppliedActionTemplate {
  [ToggleConditionEnum.INPUT_CONDITION]?: DynamicFormField;
  [ToggleConditionEnum.PRE_CONDITION]?: DynamicFormField;
  [ToggleConditionEnum.POST_CONDITION]?: DynamicFormField;
  [ToggleConditionEnum.OUTPUT_CONDITION]?: DynamicFormField;
}

const actionConfigTypeToDynamicFormFieldType = {
  "number": DynamicFormFieldType.NUMBER_INPUT,
  "string": DynamicFormFieldType.TEXT_INPUT,
  "boolean": DynamicFormFieldType.CHECKBOX,
  "string_list": DynamicFormFieldType.ARRAY_TEXTAREA,
  "object": DynamicFormFieldType.OBJECT_TEXTAREA,
};

export enum ToggleConditionEnum {
  SKIP_CONDITION = 'skipCondition',
  OUTPUT_CONDITION = 'outputs',
  INPUT_CONDITION = 'inputs',
  PRE_CONDITION = 'precondition',
  POST_CONDITION = 'postcondition'
}
@Component({
  selector: 'usecase-actions',
  templateUrl: './usecase-actions.component.html',
  styleUrls: [ './usecase-actions.component.scss' ],
})
export class UsecaseActionsComponent implements OnInit {
  @Input() currentUsecase: IUsecase;
  @Input() currentPlaybook: IPlaybookDetail;
  @Input() data: UsecaseDescriptionDrawerData;
  @Input() usecaseUpdateForm: UsecaseUpdateForm;

  public isEditing = false;
  public appliedActions: AppliedAction[] = [];
  public customAutocompleteType = AutocompleteTypes.CUSTOM;
  public legacyActionAutocompleteOptions: IAutocompleteActions[] = [];
  public actionAutocompleteOptions: IAutocompleteActions[] = [];
  public selectedActions: IAutocompleteActions[] = [];
  public toggleConditionEnum = ToggleConditionEnum;
  private actionFormGroup: FormGroup;
  private toolboxActionsMapped: {
    [key: string]: ActionTemplate;
  } = {};
  private appliedActionLibraryTemplate: ILibraryAppliedActionTemplate = {};

  constructor(
    public readonly translatedObjectPipe: TranslatedObjectPipe,
    public readonly i18n: I18nService,
    private readonly store: Store,
    private readonly formBuilder: FormBuilder,
    private usecasesApiService: UsecasesApiService,
    private noticeService: NoticeService,
    private drawerService: DrawerService
  ) { }

  ngOnInit(): void {
    this.initAppliedActionTemplate();
    this.initActionAutocompleteOptions();

    this.actionFormGroup = this.formBuilder.group({});
    this.actionFormGroup.valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(() => {
        this.updateUsecasePipelineFormValue();
      });
    this.loadData();
    this.store.select(DrawerState.isEditing).subscribe((isEditing) => {
      this.isEditing = isEditing;
    });
  }


  public get legacyActionsToIgnore(): AutocompleteCustomValue[] {
    return this.legacyActionAutocompleteOptions.filter((item) => this.appliedActions.some((e) => e.translatedName == item.displayValue));
  }

  public get actionsToIgnore(): AutocompleteCustomValue[] {
    return this.actionAutocompleteOptions.filter((item) => this.appliedActions.some((e) => e.translatedName == item.displayValue));
  }

  public get legacyActionSelectedItem(): IAutocompleteActions[] {
    return this.selectedActions.filter((action) => action?.isLegacyAction);
  }

  public get newActionSelectedItem(): IAutocompleteActions[] {
    return this.selectedActions.filter((action) => !action?.isLegacyAction);
  }

  public get isValid(): boolean {
    return !this.appliedActions.some((action) => action.form.formGroup.status === "INVALID");
  }

  public save(): void {
    this.updateUsecase();
    this.drawerService.previousDrawer();
  }

  public cancelAction(): void {
    this.toggleAction();
    this.appliedActions = [];
    this.loadData();
  }

  public toggleAction(): void {
    this.isEditing = !this.isEditing;

    this.store.dispatch(new SetDrawerIsEditing({
      isEditing: this.isEditing,
      tabName: UsecaseActionsComponent.name,
    }));
  }

  public applyActions(): void {
    this.selectedActions.map((selectedAction) => this.addAction(selectedAction.isLegacyAction ? {
      name: selectedAction.value,
      config: null,
    } : {
      action: selectedAction.value,
    }, selectedAction.isLegacyAction));

    this.selectedActions = [];
  }

  public actionUpIconClicked(id: number): void {
    this.move(this.appliedActions, id, id - 1);
  }

  public actionDownIconClicked(id: number): void {
    this.move(this.appliedActions, id, id + 1);
  }

  public deleteActionIconClicked(id: number): void {
    this.appliedActions.splice(id, 1);
    this.updateUsecasePipelineFormValue();
  }

  public getActionControlName(action: AppliedAction): string {
    return action.generate_id;
  }

  public toggleLegacyCondition(event: AppliedAction, condition: ToggleConditionEnum): void {
    event[`${condition}Disabled`] = !event[`${condition}Disabled`];
    if (event[`${condition}Disabled`] === false) {
      const actionTemplate = event.isLegacyAction ?
        this.data.actions[event.name]?.configurationTemplate :
        this.toolboxActionsMapped[event.name]?.configurationTemplate;

      if (actionTemplate[condition]) {
        event.form?.fields.unshift({
          value: event.isLegacyAction ? (event.skipConditionOriginal || JSON.stringify({ "type": "...", "config": {} }, null, 4)) : JSON.stringify(actionTemplate[condition].object),
          description: actionTemplate[condition].description,
          fieldName: condition,
          required: actionTemplate[condition].required,
          placeholder: { [this.i18n.currentLocale]: actionTemplate[condition].placeholder || " " },
          type: actionConfigTypeToDynamicFormFieldType[actionTemplate[condition].type],
          label: actionTemplate[condition].label,
        });
      }
    } else {
      event.form.fields = event.form?.fields.filter((value) => value.fieldName !== condition);
    }
    event.form = { ...event.form };
  }

  public toggleLibraryCondition(event: AppliedAction, condition: ToggleConditionEnum): void {
    event[`${condition}Disabled`] = !event[`${condition}Disabled`];
    if (event[`${condition}Disabled`] === false) {
      if (this.appliedActionLibraryTemplate[condition]) {
        event.form?.fields.unshift({
          value: this.appliedActionLibraryTemplate[condition].object,
          fieldName: condition,
          required: this.appliedActionLibraryTemplate[condition].required,
          type: this.appliedActionLibraryTemplate[condition].type,
          label: null,
          placeholder: { [this.i18n.currentLocale]: this.appliedActionLibraryTemplate[condition].placeholder || " " },
        });
      }
    } else {
      event.form.fields = event.form?.fields.filter((value) => value.fieldName !== condition);
    }

    const form = { ...event.form };
    this.buildFormGroup(form, event.generate_id);
  }

  public updateOutdatedUsecase(): void {
    this.usecasesApiService
      .updateOutdatedUsecase(this.usecaseUpdateForm.usecaseJson)
      .then((response) => {
        this.currentUsecase = response;
        this.noticeService.notifyUser(new Notice('org_usecase.description_drawer.tab.monitors.updated_success', NoticeLevels.SUCCESS));
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
      })
      .catch((error) => {
        const message = Array.isArray(error.response.data.message)
          ? `${error.response.data.error}: ${error.response.data.message[0]}`
          : error.response.data.message;
        this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
      })
      .finally(() => {
        if (this.drawerService.component) {
          this.drawerService.component.ngOnInit();
        }
      });
  }

  public isCurrentUsecaseOutdated(): boolean {
    return this.currentUsecase.isOutdated;
  }

  public handleSelectedActionEvent(event: IAutocompleteActions): void {
    if (event) {
      this.selectedActions.push(event);
    }
  }

  public getLibraryActionField(fields: DynamicFormField[], condition: ToggleConditionEnum): DynamicFormField {
    return fields?.filter((field) => field?.fieldName === condition)[0];
  }

  private buildFormGroup(form: DynamicForm, name: string): void {
    form.formGroup.removeControl(name);
    const newForm = this.formBuilder.group({});

    for (const field of form.fields) {
      newForm.addControl(field.fieldName, new FormControl(field.value, [ Validators.required, CustomValidators.isValidJson ]));
    }

    form.formGroup.addControl(name, newForm);
  }

  private updateUsecase(): void {
    const updatedData: any = this.usecaseUpdateForm.usecaseJson;
    // If the silenced property did not change, we remove it from the updatedData since it is not required;
    if (updatedData.silenced === this.currentUsecase.silenced) {
      delete updatedData.silenced;
      delete updatedData.silencedComment;
    }

    this.usecasesApiService
      .updateUsecase(this.currentUsecase.organizationId, updatedData)
      .then(() => {
        this.noticeService.notifyUser(new Notice('org_usecase.description_drawer.tab.monitors.updated_success', NoticeLevels.SUCCESS));
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
      })
      .catch((error) => {
        const message = Array.isArray(error.response.data.message)
          ? `${error.response.data.error}: ${error.response.data.message[0]}`
          : error.response.data.message;
        this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
      });
  }

  private async loadData(): Promise<void> {
    this.currentUsecase.pipeline.forEach((action: IAction) => {
      const isLegacyAction = this.data.actions.hasOwnProperty(action.name);
      this.addAction(action, isLegacyAction);
    });
  }

  private updateUsecasePipelineFormValue() {
    this.usecaseUpdateForm.actionsFormArray.clear();
    this.appliedActions.map((value) => {
      const actionControl = this.usecaseUpdateForm.getActionControl(this.getFormActionConfig(value));
      this.usecaseUpdateForm.actionsFormArray.push(actionControl);
    });
  }

  private getFormActionConfig(action: AppliedAction): IAction {
    const template = action.isLegacyAction ? this.data.actions[action.name]?.configurationTemplate : this.appliedActionLibraryTemplate;
    const rawConfig = this.actionFormGroup.get(action.generate_id)?.value;

    if (!rawConfig) {
      return {
        name: action.isLegacyAction ? action.name : null,
        config: undefined,
        action: action.isLegacyAction ? null : action.name,
        inputs: null,
        outputs: null,
        precondition: null,
        postcondition: null,
      };
    }

    const configKeys = Object.keys(template);
    const config = {};
    for (const configKey of configKeys) {
      if (!(configKey in rawConfig)) {
        continue;
      }
      if (template[configKey].type === "object") {
        if (rawConfig[configKey]?.trim().length > 0 || template[configKey].required) {
          config[configKey] = rawConfig[configKey];
        }
      } else if (template[configKey].type === "string_list") {
        if (rawConfig[configKey]?.length > 0 || template[configKey].required) {
          config[configKey] = rawConfig[configKey];
        }
      } else if (template[configKey].type === "string") {
        if (rawConfig[configKey]?.trim().length > 0 || template[configKey].required) {
          config[configKey] = rawConfig[configKey];
        }
      } else {
        config[configKey] = rawConfig[configKey];
      }
    }


    if (action.isLegacyAction) {
      return {
        name: action.isLegacyAction ? action.name : null,
        config,
      };
    }

    return {
      action: action.name,
      ...config,
    };
  }

  private addAction(action: IAction, isLegacyAction: boolean) {
    const legacyActionTemplate: ActionTemplate = this.data.actions[action.name];
    const appliedAction: AppliedAction = isLegacyAction ?
      this.createAppliedLegacyAction(action, legacyActionTemplate?.configurationTemplate) :
      this.createAppliedNewAction(action, this.appliedActionLibraryTemplate);

    this.appliedActions.push(appliedAction);
  }

  private createAppliedLegacyAction(action: IAction, formActionTemplate?: ActionConfigurationTemplate<any>): AppliedAction {
    const appliedAction: AppliedAction = {
      generate_id: `${action.name}-${uuid()}`,
      name: action.name,
      translatedName: this.findAutocompleteActionByName(action.name)?.displayValue,
      skipConditionDisabled: true,
      skipConditionOriginal: "",
      form: null,
      isLegacyAction: true,
    };

    if (formActionTemplate) {
      const filteredFormFields: DynamicFormField[] = Object.keys(formActionTemplate).map((element) => ({
        value: this.getConfigValue(element, action.config),
        description: formActionTemplate[element].description,
        fieldName: element,
        required: formActionTemplate[element].required,
        placeholder: { [this.i18n.currentLocale]: formActionTemplate[element].placeholder || " " },
        type: actionConfigTypeToDynamicFormFieldType[formActionTemplate[element].type],
        label: formActionTemplate[element].label,
        options: formActionTemplate[element]?.options,

      })).filter((field) => field.fieldName !== ToggleConditionEnum.SKIP_CONDITION || field.value !== "");

      appliedAction.skipConditionDisabled = filteredFormFields.length !== Object.keys(formActionTemplate).length;
      appliedAction.skipConditionOriginal = this.getConfigValue(ToggleConditionEnum.SKIP_CONDITION, action.config);
      appliedAction.form = {
        formGroup: this.actionFormGroup,
        fields: filteredFormFields,
      };
    }

    return appliedAction;
  }

  private createAppliedNewAction(action: IAction, formActionTemplate: ILibraryAppliedActionTemplate): AppliedAction {
    const formFields: DynamicFormField[] = [];
    const appliedAction: AppliedAction = {
      generate_id: `${action.action}-${uuid()}`,
      name: action.action,
      translatedName: this.findAutocompleteActionByName(action.action)?.displayValue,
      form: null,
      inputsDisabled: !action.inputs,
      preconditionDisabled: !action.precondition,
      postconditionDisabled: !action.postcondition,
      outputsDisabled: !action.outputs,
      isLegacyAction: false,
    };

    if (Object.keys(action).length > 1) {
      Object.keys(action).slice(1).map((element) => formFields.push({
        value: JSON.stringify(action[element]),
        fieldName: element,
        required: action[element].required,
        type: 'objectTextarea',
        label: null,
        placeholder: { [this.i18n.currentLocale]: action[element].placeholder || " " },
      }));
    }

    appliedAction.form = {
      formGroup: this.actionFormGroup,
      fields: formFields,
    };

    this.buildFormGroup(appliedAction.form, appliedAction.generate_id);

    return appliedAction;
  }

  private findAutocompleteActionByName(name: string): AutocompleteCustomValue {
    return this.legacyActionAutocompleteOptions.find((option) => option.value === name) || this.actionAutocompleteOptions.find((option) => option.value === name);
  }

  private getConfigValue(element: string, config: any): string {
    if (!config || config[element] == null) {
      return "";
    }

    const value = config[element];
    if (typeof (value) === "object") {
      return JSON.stringify(value);
    }
    return value;
  }

  private move(array: any[], from: number, to: number): void {
    array.splice(to, 0, array.splice(from, 1)[0]);
    this.updateUsecasePipelineFormValue();
  }

  private initActionAutocompleteOptions(): void {
    this.legacyActionAutocompleteOptions = [];
    this.actionAutocompleteOptions = [];

    const legacyActions = Object.keys(this.data.actions).map((value) => ({
      value,
      displayValue: this.translatedObjectPipe.transform(this.data.actions[value].name),
      description: this.translatedObjectPipe.transform(this.data.actions[value].description),
      isLegacyAction: true,
    }));

    const toolboxActions = this.data.toolboxActions.map((value) => ({
      value: value.id,
      displayValue: this.translatedObjectPipe.transform(value.name),
      applications: value.applications,
      isLegacyAction: false,
    }));

    this.legacyActionAutocompleteOptions = legacyActions;
    this.actionAutocompleteOptions = toolboxActions;

    this.legacyActionAutocompleteOptions.sort((a, b) => a.displayValue.localeCompare(b.displayValue));
    this.actionAutocompleteOptions.sort((a, b) => a.displayValue.localeCompare(b.displayValue));
  }

  private initAppliedActionTemplate(): void {
    const out = {};

    out[ToggleConditionEnum.INPUT_CONDITION] =  {
      required: false,
      type: 'objectTextarea',
      placeholder: `ex: ${JSON.stringify({
        userEmail: {
          observable: "",
        },
      })}`,
      object: null,
    };

    out[ToggleConditionEnum.PRE_CONDITION] =  {
      required: false,
      type: 'objectTextarea',
      placeholder: `ex: ${JSON.stringify({
        observable: "",
        in: {},
      })}`,
      object: null,
    };

    out[ToggleConditionEnum.POST_CONDITION] =  {
      required: false,
      type: 'objectTextarea',
      placeholder: `ex: ${JSON.stringify({
        observable: "",
        in: {},
      })}`,
      object: null,
    };

    out[ToggleConditionEnum.OUTPUT_CONDITION] =  {
      required: false,
      type: 'objectTextarea',
      placeholder: `ex: ${JSON.stringify({
        user: {
          observable: "",
        },
        userEmail: {
          observable: "",
        },
      })}`,
      object: null,
    };

    this.appliedActionLibraryTemplate = out;
  }
}
