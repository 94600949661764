import { FieldMap, Rule, RuleSet } from "angular2-query-builder";
import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { IListFilterFieldsRequest } from "../filters/filters.definitions";


export interface IQueriesApi {
  listQueries(request: IListQueriesRequest): Observable<ResponseUtils<IQueryItem>>;
  describeQuery(request: IDescribeQueryRequest): Observable<IDescribeQueryResponse>;

  createCustomQuery(request: ICreateCustomQueryRequest): Observable<IDescribeQueryResponse>;
  updateCustomQuery(request: IUpdateCustomQueryRequest): Observable<IDescribeQueryResponse>;
  deleteCustomQuery(request: IDeleteCustomQueryRequest): Observable<IDeleteCustomQueryResponse>;

  executeDefaultQueryUsecase(request: IExecuteDefaultQueryRequest): Observable<IExecuteQueryResponse>;
  executeOrganizationQueryUsecase(request: IExecuteOrganizationQueryRequest): Observable<IExecuteQueryResponse>;

  getBasicQueryUsecase(request: IGetBasicQueryRequest): Observable<IGetBasicQueryResponse>;
  getAdvancedQueryAggregationFields(request: IListFilterFieldsRequest): Observable<FieldMap>;
}

export type IGetBasicQueryResponse = RuleSet;

export type IGetBasicQueryRequest = {
  customQueryId: string;
  organizationId: string;
  parameters: { [key: string]: string };
};

export type IExecuteOrganizationQueryRequest = {
  organizationId: string;
  aggregation: string;
  maxRows: number;
  conditions: RuleSet;
  dateFrom?: number;
  dateTo?: number;
};

export type IBasicQueryParameter = {
  key: string;
  value: string
};

export type IExecuteQueryResponse = {
  items: any[];
  aggregated: boolean;
  metrics: {
    queryCount: number;
    totalDuration: number;
  }
};

export type IExecuteDefaultQueryRequest = {
  customQueryId: string;
  organizationId?: string;
  parameters: IBasicQueryParameter[];
  dateFrom?: number;
  dateTo?: number;
};

export type IListQueriesRequest = {
  organizationId: string;
};

export type IDescribeQueryRequest = {
  organizationId: string;
  customQueryId: string;
  type?: QueryTypeEnum;
};

export type ICreateCustomQueryRequest = {
  organizationId: string;
  aggregation: string;
  maxRows: number;
  name: string;
  conditions: any;
};

export type IUpdateCustomQueryRequest = ICreateCustomQueryRequest & {
  id: string;
};

export type IDeleteCustomQueryRequest = {
  id: string;
  organizationId: string;
};

export type IDeleteCustomQueryResponse = {
  success: boolean;
};

export enum QueryTypeEnum {
  ORGANIZATION = 'ORGANIZATION',
  DEFAULT = 'DEFAULT'
}

export type IQueryCondition = {
  condition: string;
  rules: Array<RuleSet | Rule>;
  collapsed?: boolean;
  isChild?: boolean;
};

export type IQueryParameter = {
  key: string;
  labels: ITranslatedField;
  path?: string;
  query?: any;
};

export type IQueryItem = {
  id: string;
  organizationId?: string;
  name: ITranslatedField;
  type: QueryTypeEnum;

  aggregation?: string;
  maxRows?: number;
  conditions?: RuleSet;

  parameters?: IQueryParameter[];
};

export type IDescribeQueryParameter = {
  key: string;
  values: string[];
};

export type IDescribeQueryResponse = {
  query: IQueryItem;
  parameterValues: IDescribeQueryParameter[];
};
