<ui-card [withShadow]="true" *ngIf="!loading">
  <div class="header-title-section">
    <h2 class="header-title-section--title">
      {{ 'detection.datasource.inventory.detail.active.usecase.title' | t }}
    </h2>
    <div class="header-title-section--actions-bar">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>

  <div class="table-container">
    <detection-usecases-table
      [datasources]="datasources"
      [loading]="loading || refreshing"
    ></detection-usecases-table>
  </div>
</ui-card>

<ui-card [withShadow]="true" *ngIf="loading">
  <ui-spinner size="large"></ui-spinner>
</ui-card>

<br />
