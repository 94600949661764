<!--  [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{page, i }"  -->

<ng-container *ngIf="!isEditing; else editingTemplate">

  <div class="level-row background-gray">

    <!-- Level -->
    <div class="levels-column">
      <p>
        {{escalationLevel.level}}
      </p>
    </div>

    <!-- Contact -->
    <div class="contacts-column flex-box column gap-100">
      <p
        *ngFor="let contact of escalationLevel.contacts; trackBy:trackById;"
        class="flex-box column gap-050"
      >
        <span
          [tooltip]="contact?.position || null"
          class="width-max-content"
        >{{contact.name}}</span>
      </p>
    </div>

    <!-- Communication Methods -->
    <div class="methods-column flex-box column gap-100">
      <p *ngFor="let contact of escalationLevel.contacts; trackBy:trackById;">
        <span
          *ngFor="let communicationMethod of contact.selectedCommunicationMethods"
          class="communication-method-chip"
          [tooltip]="communicationMethod.value"
          (click)="copyCommunicationMethodToClipboard(communicationMethod.value)"
        >
          {{communicationMethod.translation}} <span *ngIf="communicationMethod.value">: </span>{{communicationMethod.value}}
        </span>
      </p>
    </div>

    <!-- Read mode -->
    <!-- Priorities -->
    <div *ngIf="showPrioritiesMatrix" class="priorities-application-column readonly-column flex-box column gap-100">
      <!-- We do not display toggle switches in readonly mode -->
    </div>

    <div *ngIf="showPrioritiesMatrix" class="priorities-permissions-column priorities-permissions-p1-column flex-box column">
      <div
        *ngFor="let contact of escalationLevel.contacts; trackBy:trackById; let isLast = last;"
        class="flex-box center-horizontal"
        [class.with-margin-bottom]="!isLast"
      >

      <mat-checkbox
        [checked]="!contact?.withPriorities || contact?.priorities.includes(1)"
        [disabled]="true"
      ></mat-checkbox>
      </div>
    </div>

    <div  *ngIf="showPrioritiesMatrix" class="priorities-permissions-column priorities-permissions-p2-column flex-box column">
      <div
        *ngFor="let contact of escalationLevel.contacts; trackBy:trackById; let isLast = last;"
        class="flex-box center-horizontal"
        [class.with-margin-bottom]="!isLast"
      >
      <mat-checkbox
        [checked]="!contact?.withPriorities || contact?.priorities.includes(2)"
        [disabled]="true"
      ></mat-checkbox>
      </div>
    </div>

    <div *ngIf="showPrioritiesMatrix" class="priorities-permissions-column priorities-permissions-p3-column flex-box column">
      <div
        *ngFor="let contact of escalationLevel.contacts; trackBy:trackById; let isLast = last;"
        class="flex-box center-horizontal"
        [class.with-margin-bottom]="!isLast"
      >
      <mat-checkbox
        [checked]="!contact?.withPriorities || contact?.priorities.includes(3)"
        [disabled]="true"
      ></mat-checkbox>
      </div>
    </div>

    <div *ngIf="showPrioritiesMatrix" class="priorities-permissions-column priorities-application-p4-column flex-box column">
      <div
        *ngFor="let contact of escalationLevel.contacts; trackBy:trackById; let isLast = last;"
        class="flex-box center-horizontal"
        [class.with-margin-bottom]="!isLast"
      >
      <mat-checkbox
        [checked]="!contact?.withPriorities || contact?.priorities.includes(4)"
        [disabled]="true"
      ></mat-checkbox>
      </div>
    </div>

  </div>
</ng-container>


<ng-template #editingTemplate>

  <div class="level-row background-gray editable">

    <!-- Level -->
    <div class="levels-column flex-box center-horizontal">
      <p>
        {{escalationLevel.level}}
      </p>
      <button
        ui-button
        class="method-icon"
        [style.visibility]="canDeleteEscalationLevel ? 'visible': 'hidden'"
        color="drawerCloseFlat"
        icon="deleteActive"
        [tooltip]="'common.action.delete' | t"
        (click)="deleteEscalationLevelClicked();"
      >
      </button>
    </div>

    <!-- Contact -->
    <div class="contacts-column flex-box column">
      <div
        *ngFor="let contact of escalationLevel.contacts; trackBy:trackById; let isLast = last;"
        class="flex-box center-horizontal"
        [class.with-margin-bottom]="!isLast"
      >
        <autocomplete
          class="escalation-list-contact-autocomplete"
          [required]="true"
          [placeholder]="'respond.communication.tab.escalationList.contactAutocomplete.placeholder' | t"
          [autocompleteType]="customAutocompleteType"
          [customValues]="availableContactAutocompleteOptions"
          [selectedItems]="contact.selectedContactAutocompleteOption ? [contact.selectedContactAutocompleteOption] : []"
          [initItems]="contact.selectedContactAutocompleteOption ? [contact.selectedContactAutocompleteOption] : []"
          [showInInputAtInit]="true"
          [canMultiSelect]="false"
          [oneItemAtATime]="true"
          (onItemSelected)="handleContactSelected(contact, $event)"
        ></autocomplete>
        <button
          ui-button
          class="contact-delete-button"
          [style.visibility]="escalationLevel.contacts.length > 1 ? 'visible': 'hidden'"
          color="drawerCloseFlat"
          icon="deleteActive"
          [tooltip]="'common.action.delete' | t"
          (click)="removeContact(contact.id);"
        >
        </button>
      </div>

      <button
        ui-button
        class="contact-add-button"
        style="padding: 0; margin-top: 0; width: max-content;"
        color="listActionFlat"
        icon="plus"
        icon-size="medium"
        [disabled]="!isValidContacts() || !availableContactAutocompleteOptions.length || escalationLevel.contacts.length >= contacts.length"
        (click)="addContact()"
      >
        {{ 'respond.communication.tab.escalationList.addContact' | t }}
      </button>
    </div>

    <!-- Communication Methods -->
    <div class="methods-column flex-box column">
      <div
        *ngFor="let contact of escalationLevel.contacts; trackBy:trackById; let isLast = last;"
        class="flex-box center-horizontal"
        [class.with-margin-bottom]="!isLast"
      >
        <ui-multi-select
          *ngIf="!!contact.communicationMethodsMultiselectOptions"
          [multiSelectData]="contact.communicationMethodsMultiselectOptions"
          (onOptionSelect)="handleContactCommunicationMethodSelected(contact, $event)"
        >
        </ui-multi-select>
      </div>
    </div>

  <!-- Priorities -->
  <div *ngIf="!isDefaultList" class="priorities-application-column flex-box column gap-100">
    <div
        *ngFor="let contact of escalationLevel.contacts; let i=index; trackBy:trackById; let isLast = last;"
        class="flex-box center-horizontal"
      >
      <ui-toggle
        *ngIf="!!contact.communicationMethodsMultiselectOptions"
        class="toggle-priorities"
        [toggled]="!!contact?.withPriorities"
        [disabledWhenUntoggled]="false"
        [disabled]="false"
        (onClick)="handlePrioritiesToggleAction(contact, $event)"
      ></ui-toggle>
    </div>
  </div>

  <div *ngIf="!isDefaultList" class="priorities-permissions-column priorities-permissions-p1-column flex-box column">
    <div
        *ngFor="let contact of escalationLevel.contacts; trackBy:trackById; let isLast = last;"
        class="flex-box center-horizontal"
        [class.with-margin-bottom]="!isLast"
      >
      <mat-checkbox
          name="priorities-permissions-1"
          *ngIf="!!contact.communicationMethodsMultiselectOptions"
          [checked]="!contact?.withPriorities || contact?.priorities.includes(1)"
          [disabled]="!contact?.withPriorities || (contact?.priorities.length === 1 && contact?.priorities.includes(1))"
          (change)="handlePrioritiesCheckbox(contact, $event)"
      ></mat-checkbox>
    </div>
  </div>

  <div *ngIf="!isDefaultList" class="priorities-permissions-column priorities-permissions-p2-column flex-box column">
    <div
    *ngFor="let contact of escalationLevel.contacts; trackBy:trackById; let isLast = last;"
    class="flex-box center-horizontal"
    [class.with-margin-bottom]="!isLast"
    >
    <mat-checkbox
        name="priorities-permissions-2"
        *ngIf="!!contact.communicationMethodsMultiselectOptions"
        [checked]="!contact?.withPriorities || contact?.priorities.includes(2)"
        [disabled]="!contact?.withPriorities || (contact?.priorities.length === 1 && contact?.priorities.includes(2))"
        (change)="handlePrioritiesCheckbox(contact, $event)"
    ></mat-checkbox>
    </div>
  </div>

  <div *ngIf="!isDefaultList" class="priorities-permissions-column priorities-permissions-p3-column flex-box column">
    <div
    *ngFor="let contact of escalationLevel.contacts; trackBy:trackById; let isLast = last;"
    class="flex-box center-horizontal"
    [class.with-margin-bottom]="!isLast"
    >
    <mat-checkbox
        name="priorities-permissions-3"
        *ngIf="!!contact.communicationMethodsMultiselectOptions"
        [checked]="!contact?.withPriorities || contact?.priorities.includes(3)"
        [disabled]="!contact?.withPriorities || (contact?.priorities.length === 1 && contact?.priorities.includes(3))"
        (change)="handlePrioritiesCheckbox(contact, $event)"
    ></mat-checkbox>
    </div>
  </div>

  <div *ngIf="!isDefaultList" class="priorities-permissions-column priorities-application-p4-column flex-box column">
    <div
    *ngFor="let contact of escalationLevel.contacts; trackBy:trackById; let isLast = last;"
    class="flex-box center-horizontal"
    [class.with-margin-bottom]="!isLast"
    >
    <mat-checkbox
        name="priorities-permissions-4"
        *ngIf="!!contact.communicationMethodsMultiselectOptions"
        [checked]="!contact?.withPriorities || contact?.priorities.includes(4)"
        [disabled]="!contact?.withPriorities || (contact?.priorities.length === 1 && contact?.priorities.includes(4))"
        class="priorities-permissions-p4"
        (change)="handlePrioritiesCheckbox(contact, $event)"
    ></mat-checkbox>
    </div>
  </div>

  </div>
</ng-template>