import { PlaybooksApiService } from "projects/@common/services/api/respond/playbooks/playbooks.api";
import { IListPlaybooksRequest, IPlaybookDetail, IPlaybookListResponse, IPlaybookTableObject, PlaybookOrderByEnum } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";
import { BehaviorSubject } from "rxjs";

import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { PlaybooksMapper } from "../../mappers/playbooks.mapper";
import { UiTableDirection } from "@ui-kit/components/ui-table/ui-table.component";

export interface PlaybooksInventoryQueryParams {
  organizationId?: string;
}

export enum PlaybookTemplateStatusFilter {
  OUTDATED = 'OUTDATED',
  VALID = 'VALID'
}

export enum PlaybookDisplayTemplateStatusFilter {
  ALL = 'ALL',
  OUTDATED = 'OUTDATED',
  VALID = 'VALID'
}

export class PlaybooksInventoryContainerActions {
  public $playbooks: BehaviorSubject<IPlaybookTableObject[]> = new BehaviorSubject([]);
  private playbooks: IPlaybookListResponse[] = [];

  public readonly displayPlaybookFilter: BehaviorSubject<PlaybookDisplayTemplateStatusFilter> =
    new BehaviorSubject(PlaybookDisplayTemplateStatusFilter.ALL);

  public isLoading = false;

  public total = 0;

  public outdatedTotal: number = 0;

  public selectedOrganizationId: string = null;

  public requestsQuery: string = null;

  public currentSelectedPlaybook: IPlaybookDetail;

  public sortOrder: PlaybookOrderByEnum = PlaybookOrderByEnum.name;

  public sortDirection: UiTableDirection = UiTableDirection.Asc;

  constructor(
    private readonly playbooksApiService: PlaybooksApiService,
    private readonly playbooksMapper: PlaybooksMapper,
    private readonly i18n: I18nService
  ) { }

  public async handleSelectedOrganization(organizationId: string): Promise<void> {
    this.selectedOrganizationId = organizationId;
    await this.handleRefresh();
  }

  public async handlePlaybookSearchQuery(value: string): Promise<void> {
    this.requestsQuery = value;
    await this.handleRefresh();
  }

  public async handlePlaybookSearchClear(): Promise<void> {
    this.requestsQuery = null;
    await this.handleRefresh();
  }

  public get queryParams(): IListPlaybooksRequest {
    const params = {
      organizationId: this.selectedOrganizationId,
      searchName: this.requestsQuery,
      orderBy: this.sortOrder === PlaybookOrderByEnum.name ? 'nameLocale' : this.sortOrder,
      direction: this.sortDirection,
      locale: this.i18n.currentLocale,
    };
    return Object.fromEntries(Object.entries(params).filter(([ _, filter ]) => (!Array.isArray(filter) && filter != null) || (Array.isArray(filter) && filter?.length > 0)));
  }

  public async fetchPlaybooks(): Promise<IPlaybookTableObject[]> {
    this.$playbooks.next([]);
    this.isLoading = true;

    const playbooks = await this.playbooksApiService.getPlaybooks(this.selectedOrganizationId, this.queryParams);
    this.playbooks = playbooks.items;
    const mappedPlaybooks = playbooks?.items?.map((value: any) => this.playbooksMapper.playbooksTableMapper(value));

    this.total = mappedPlaybooks.length;
    this.outdatedTotal = mappedPlaybooks.filter((pb) => pb.outdated).length;
    this.$playbooks.next(mappedPlaybooks);
    this.isLoading = false;

    return mappedPlaybooks;
  }

  public async handleRefresh(): Promise<void> {
    await this.fetchPlaybooks();
  }

  public resetPlaybooks(): void {
    this.$playbooks.next([]);
    this.displayPlaybookFilter.next(PlaybookDisplayTemplateStatusFilter.ALL);
  }

  public setDisplayFilter(filter: PlaybookDisplayTemplateStatusFilter) {
    this.displayPlaybookFilter.next(filter);
  }

  public hasPlaybookTemplate(playbookTemplateId: string): boolean {
    return this.playbooks.some((playbook) => playbook.playbook.templateId === playbookTemplateId);
  }

  public findPlaybooksTableFromTemplate(templateId: string): IPlaybookTableObject[] {
    const playbooks = this.playbooks.filter((pb) => pb.playbook.templateId === templateId);
    return playbooks.map((pb) =>  this.playbooksMapper.playbooksTableMapper(pb));
  }
}
