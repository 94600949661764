import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UiDurationTimePickerComponent } from "@ui-kit/components/ui-duration-time-picker/ui-duration-time-picker.component";
import { IncidentDifferenceService } from "../../../../../../services/incident.difference.service";
import { TabNamesEnum } from "../../../../incidents-details.component";

const minuteDurationInMilliseconds = 60 * 1000;

@Component({
  selector: 'collaboration-time',
  templateUrl: './collaboration-time.component.html',
  styleUrls: [ './collaboration-time.component.scss' ],
})
export class CollaborationTimeComponent implements OnInit {
  @Input() public defaultMinutes: number;

  @Input() public editable: boolean;

  @Input() public hideEditButtons: boolean;

  @Output() public readonly toggleEditEvent = new EventEmitter<boolean>();

  @Output() public readonly updateCollaborationTimeMinutesEvent = new EventEmitter<number>();

  @ViewChild("durationPicker") durationPicker: UiDurationTimePickerComponent;

  public defaultTimeMs: number;

  public currentTimeMs: number;

  public isEditing: boolean;

  constructor(private readonly incidentDifferenceService: IncidentDifferenceService) { }

  ngOnInit(): void {
    this.setDefault();
  }

  public get hasChanged(): boolean {
    return this.defaultTimeMs !== this.currentTimeMs;
  }

  public setDefault(): void {
    this.setStateFromMinutes(this.defaultMinutes);
  }

  public setStateFromMinutes(minutes: number): void {
    this.defaultTimeMs = typeof minutes === "number"
      ? this.convertMinutesToMilliseconds(minutes)
      : null;
    this.currentTimeMs = this.defaultTimeMs;
    this.durationPicker?.setDurationFromMilliseconds(this.currentTimeMs);
  }

  public handleKeydown(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.durationPicker.validateDurationTime();
      this.handleEditConfirmation(true);
    } else if (event.key === "Escape") {
      this.handleEditConfirmation(false);
    }
  }

  public handleCollaborationTimeChanged(event: number): void {
    this.currentTimeMs = event;
    this.incidentDifferenceService.handleEditingStates({
      tabNameEnum: TabNamesEnum.CHRONOLOGY,
      itemId: TabNamesEnum.CHRONOLOGY,
      hasUnsavedChange: this.hasChanged,
    });
  }

  public setIsEditing(newState: boolean): void {
    this.isEditing = newState;
    this.toggleEditEvent.emit(this.isEditing);
  }

  public handleEditConfirmation(isConfirmed: boolean): void {
    if (!isConfirmed) {
      this.setDefault();
    } else if (this.hasChanged) {
      this.defaultTimeMs = this.currentTimeMs;
      this.emitTimeChangedEvent();
    }
    this.setIsEditing(false);
  }

  private convertMinutesToMilliseconds(minutes: number): number {
    return minutes ? (minutes * minuteDurationInMilliseconds) : 0;
  }

  private convertMillisecondsToMinutes(milliseconds: number): number {
    return milliseconds ? (milliseconds / minuteDurationInMilliseconds) : 0;
  }

  private emitTimeChangedEvent(): void {
    const minutes: number = this.convertMillisecondsToMinutes(this.currentTimeMs);
    this.updateCollaborationTimeMinutesEvent.emit(minutes);
  }
}
