import { Component, Input } from "@angular/core";
import { Incident } from '@common/services/api/respond/incidents/models/incident';

@Component({
  selector: 'app-incident-alerts',
  templateUrl: './incident-alerts.component.html'
})
export class IncidentAlertsComponent {

  @Input()
    alertCount: number = 0;

  public get incidentAlertsCount(): number {
    return this.alertCount;
  }
}
