<div
  page
  [appPagePaths]="['org', 'dashboard']"
  [disableOrganizationSelector]="true"
>
  <div class="dashboard-container">
    <div class="dashboard--content">
      <ui-tabs
        [color]="uiTabsColor.lightTabs"
        [borderBelowHeader]="true"
        [hasNoLeftPadding]="true"
        [hasWhiteBackroundColor]="false"
      >
        <div class="tab-container">
          <ui-tab
            [tabTitle]="'dashboard.admin.tab.summary' | t"
            tabImageActive="assets/favicons/icon_summary_active.svg"
            tabImageInactive="assets/favicons/icon_summary_inactive.svg"
          >
            <ng-template *ngTemplateOutlet="summary"></ng-template>
          </ui-tab>
          <ui-tab
            [tabTitle]="'dashboard.admin.tab.indicator' | t"
            tabImageActive="assets/favicons/icon_indicators_active.svg"
            tabImageInactive="assets/favicons/icon_indicators_inactive.svg"
          >
            <app-indicators-tab [enabledWidgets]="enabledWidgets"></app-indicators-tab>
          </ui-tab>
        </div>
      </ui-tabs>
    </div>
  </div>
</div>

<ng-template #summary>
  <div class="summary-container">
    <ng-container *ngIf="!isLoading">
      <sg-admin-cards [userProfile]="userProfile"> </sg-admin-cards>
      <sg-eco-profile-card [cardColor]="cardColorEnum.white">
        <sg-eco-profile-row
          *ngIf="action"
          [config]="action"
          [yearsDropdownFilter]="yearsDropdownFilter"
          (onYearSelect)="handleYearSelection($event)"
        ></sg-eco-profile-row>
      </sg-eco-profile-card>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
  </div>
</ng-template>

<ng-template #noData>
  <div class="no-content">{{ 'dashboard.admin.noData' | t }}</div>
</ng-template>
