<widget-container
  [title]="'dashboard.admin.resource.tendency.title' | t"
  [withBottomMargin]="true"
  [locale]="locale"
  [withTimeRange]="true"
  [timeRanges]="TIME_RANGE_ENUM"
  [timeRangesDefault]="currentTimeRange"
  (selectedTimerange)="handleTimeRange($event)"
>
  <div *ngIf="data?.length && !isLoading">
    <div class="chart">
      <dynamic-chart
        *ngIf="data?.length && !isLoading"
        [data]="data"
        [type]="CHART_TYPE"
        [key]="KEY"
        [series]="[SHARES_LINE, 'regression']"
        [legend]="legend"
        name="engagement_level_tendency"
        [colors]="colors"
        [importantLine]="SHARES_LINE"
        [axisFormattingSettings]="axisFormattingSettings"
        chartWidth="350px"
      ></dynamic-chart>
    </div>
  </div>
  <ui-spinner *ngIf="isLoading"></ui-spinner>
  <div
    *ngIf="!data?.length && !isLoading"
    class="admin-tendency-widget--no-history"
  >
    {{ "dashboard.admin.noData" | t }}
  </div>
</widget-container>
