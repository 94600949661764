import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "@common/components/components.module";
import { I18nModule } from "@common/modules/i18n/i18n.module";
import { PipesModule } from "@common/modules/pipes/pipes.module";
import { FeatureSharedModule } from "@common/modules/shared/feature.shared.module";
import { UiKitModule } from "@ui-kit/uikit.module";
import { ConnectorsDataPermissionsComponent } from "./components/connectors-data-permissions/connectors-data-permissions.component";
import { NgModule } from "@angular/core";
import { ConnectorsSoarPermissionsComponent } from "./components/connectors-soar-permissions/connectors-soar-permissions.component";
import { ConnectorInstanceHealthIndicatorIndicatorsComponent } from "./components/connector-instance-health-indicator-indicators/connector-instance-health-indicator-indicators.component";

const components = [
  ConnectorsDataPermissionsComponent,
  ConnectorsSoarPermissionsComponent,
  ConnectorInstanceHealthIndicatorIndicatorsComponent
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    PipesModule,
    RouterModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
  ],
})
export class RespondConnectorsModule { }
