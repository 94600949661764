import { CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiDragAndDropZone, DragDropItem, DragDropCompletedEvent } from '@ui-kit/components/ui-drag-and-drop-zone/ui-drag-and-drop-zone.component';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { PlaybooksApiService } from 'projects/@common/services/api/respond/playbooks/playbooks.api';
import {
  IPlaybookDetail,
  IPlaybookTemplate
} from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { PlaybookCreationForm } from '../../containers/playbook-creation/playbook-creation.form';
import { DataConnectorTypes } from '@md.eco/connectors';

const mdPlaybookTemplatesLibraryName = 'MDPlaybookTemplates';

interface FilteringForm {
  query: string;
  datasourceType: any[];
}

@Component({
  selector: 'playbook-creation-properties',
  templateUrl: './playbook-creation-properties.component.html',
  styleUrls: [ './playbook-creation-properties.component.scss' ],
})
export class PlaybookCreationPropertiesComponent extends UiDragAndDropZone<IPlaybookTemplate> implements OnInit {
  public canCreatePlaybookWithoutTemplate = false;

  @Input() public playbookCreationForm: PlaybookCreationForm;

  @Input() public playbookTemplate: IPlaybookTemplate;

  @Input() public playbook: IPlaybookDetail;

  @Output() playbookPickedEvent = new EventEmitter<IPlaybookTemplate>();

  public datasourceTypeFilterEnum = DataConnectorTypes;

  public filtering: FilteringForm = {
    query: '',
    datasourceType: [],
  };

  public datasourceTypeFilterSelect: MultiSelectData;

  public formDropRegions: string[] = [ 'playbookTemplate' ];

  public dragDropInitialized = false;

  public isDraggable = false;

  public pickedItem: DragDropItem<IPlaybookTemplate>;

  public splitPaneOpened: boolean = true;

  public mdPlaybookTemplates: IPlaybookTemplate[] = [];

  public playbookTemplateTaskCount = 0;

  public hasEntered = false;

  public playbookTemplateId = this.route.snapshot.queryParams?.playbookTemplateId;

  constructor(
    public readonly playbooksApiService: PlaybooksApiService,
    private readonly translatedObjectPipe: TranslatedObjectPipe,
    private multiSelectFactory: MultiSelectDataFactory,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly route: ActivatedRoute
  ) {
    super();
    this.datasourceTypeFilterSelect = this.multiSelectFactory.create(
      this.datasourceTypeFilterEnum,
      [],
      'detection.playbook.detail.task.library.datasource.',
      false, true
    );
  }

  async ngOnInit(): Promise<void> {
    const mdPlaybooks = await this.playbooksApiService.getPlaybookTemplates();
    this.mdPlaybookTemplates = mdPlaybooks.items;
    this.initializeDragDrop();

    if (this.playbookTemplateId) {
      this.playbooksApiService.getPlaybookTemplateById(this.playbookTemplateId)
        .then((res) => {
          this.onDropCompleted(
            {
              fromIndex: null,
              fromRegion: mdPlaybookTemplatesLibraryName,
              toIndex: 0,
              toRegion: this.formDropRegions[0],
            },
            res
          );
        });
    }
  }

  public isPlaybookTemplateEmpty(): boolean {
    if (this.dragDropInitialized && this.dragDropRegions.playbookTemplate) {
      return this.dragDropRegions.playbookTemplate.items.length === 0;
    }
    return true;
  }

  public filteringOnChange(value: string): void {
    this.filtering.query = value;
    this.handleFilter();
  }

  public handleDataSourceFilter(value: string[]): void {
    this.filtering.datasourceType = value;
    this.handleFilter();
  }

  public onDropCompleted(event: DragDropCompletedEvent, playbookItem?: IPlaybookTemplate): void {
    if (event.fromRegion === mdPlaybookTemplatesLibraryName) {
      const movedItem = this.dragDropRegions[event.toRegion].items[event.toIndex];
      const playbookTemplateInstance = movedItem?.itemContext || playbookItem;
      this.patchPlaybookForm(playbookTemplateInstance);
      if (playbookItem) {
        this.dragDropRegions[event.toRegion].items.push({ itemContext: playbookItem, lockedPosition: false });
      }

      this.dragDropRegions[event.toRegion].items[event.toIndex].itemContext = playbookTemplateInstance;
      this.playbookTemplateTaskCount = playbookTemplateInstance.playbookTasks.length;
      this.playbookTemplate = playbookTemplateInstance;
    }

    this.playbookPickedEvent.emit(this.playbookTemplate);
    this.hasEntered = true;
    this.isDraggable = false;
    this.pickedItem = null;
  }

  public onDropCancelled(): void {
    this.pickedItem = null;
  }

  public handleCancel(): void {
    const path = `respond/playbooks`;
    this.zone.run(() => {
      this.router.navigateByUrl(path);
    });
  }

  public removeCurrentTemplate(): void {
    this.dragDropRegions.playbookTemplate.items.pop();
    this.playbookCreationForm.resetAllValue();
    this.hasEntered = false;
    this.isDraggable = true;
    this.playbookTemplate = null;
    this.playbookPickedEvent.emit(null);
  }

  public librarySetPickedItem(e: CdkDragStart<DragDropItem<IPlaybookTemplate>>): void {
    this.pickedItem = e.source.data;
  }

  public libraryClearPickedItem(): void {
    this.pickedItem = null;
  }

  public toggleSplitPane(): void {
    this.splitPaneOpened = !this.splitPaneOpened;
  }

  private mapMDPlaybookTemplatesDropItems(mdPlaybookTemplates: IPlaybookTemplate[]): DragDropItem<IPlaybookTemplate>[] {
    return mdPlaybookTemplates.map<DragDropItem<IPlaybookTemplate>>((template) => ({
      itemContext: template,
      lockedPosition: false,
    }));
  }

  private handleFilter(): void {
    const lowerCaseQuery = this.filtering.query?.toLowerCase();
    const filteredItems = this.mdPlaybookTemplates.filter((playbook) => {
      if (lowerCaseQuery) {
        const translatedLowerCaseName = this.translatedObjectPipe.transform(playbook.name)?.toLowerCase();
        const translatedLowerCaseDescription = this.translatedObjectPipe.transform(playbook.description)?.toLowerCase();
        if (!(translatedLowerCaseName.includes(lowerCaseQuery) || translatedLowerCaseDescription.includes(lowerCaseQuery))) {
          return false;
        }
      }
      if (this.filtering.datasourceType && this.filtering.datasourceType.length > 0) {
        if (!playbook.datasourceTypes.some((p) => this.filtering.datasourceType.includes(p))) {
          return false;
        }
      }
      return true;
    });

    this.dragDropRegions[mdPlaybookTemplatesLibraryName].items = this.mapMDPlaybookTemplatesDropItems(filteredItems);
  }

  private initializeDragDrop(): void {
    this.formDropRegions.forEach((region) => {
      this.dragDropRegions[region] = {
        key: region,
        dropCopiesItems: false,
        preventDoublesKey: "task.id",
        items: [],
      };
    });

    const mdPlaybookDropItems = this.mapMDPlaybookTemplatesDropItems(this.mdPlaybookTemplates);

    this.dragDropRegions[mdPlaybookTemplatesLibraryName] = {
      key: mdPlaybookTemplatesLibraryName,
      dropCopiesItems: true,
      items: mdPlaybookDropItems,
    };

    this.dropCompleted$.subscribe((event) => this.onDropCompleted(event));
    this.dropCancelled$.subscribe(() => this.onDropCancelled());
    this.dragDropInitialized = true;
    this.isDraggable = true;
  }

  private patchPlaybookForm(playbookInstance: IPlaybookTemplate): void {
    const translatedName = this.translatedObjectPipe.transform(playbookInstance.name);
    const translatedDescription = this.translatedObjectPipe.transform(playbookInstance.description);

    this.playbookCreationForm.playbookNameControl.setValue(translatedName);
    this.playbookCreationForm.descriptionControl.setValue(translatedDescription);
    this.playbookCreationForm.playbookTemplateIdControl.setValue(playbookInstance?.playbookTemplateId);
  }
}
