import { Component, Input, OnInit } from '@angular/core';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IDatasourceTableObject } from '../../../datasource-inventory-detail/components/detail-body/detail-body.component';
import { UsecaseInventoryContainerActions } from '../../usecase-inventory.container/usecase-inventory.container.actions';
import { DataConnectorTypes } from '@md.eco/connectors';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';

@Component({
  selector: 'usecase-inventory-body-section',
  templateUrl: './usecase-inventory-body-section.component.html',
  styleUrls: [ './usecase-inventory-body-section.component.scss' ],
})
export class UsecaseInventoryBodySectionComponent implements OnInit {
  @Input() public loading = false;
  @Input() public datasources: IDatasourceTableObject[] = [];
  @Input() public ucInventoryAction: UsecaseInventoryContainerActions;
  @Input() public filters?: any;

  public datasourceCategory: MultiSelectData;
  public priorityFilter: MultiSelectData;
  public tableFilterData: TableFilterData;
  public tableFilterDatasourceType: TableFilterData;

  constructor (
    private multiSelectFactory: MultiSelectDataFactory,
    private tableFilterToolDataFactory: TableFilterToolDataFactory,
    public i18nService: I18nService
  ) {
  }

  ngOnInit(): void {
    this.datasourceCategory = this.multiSelectFactory.create(this.ucInventoryAction?.dsCategoryEnum, [], 'detection.usecase.inventory.table.datasource.category.');
    this.priorityFilter = this.multiSelectFactory.create(this.ucInventoryAction?.prioritiesFilterEnum, [], 'detection.datasource.inventory.table.priority.');
    this.tableFilterData = this.tableFilterToolDataFactory.create(this.ucInventoryAction?.modeFilters, 'detection.datasource.inventory.detail.table.modes.', this.ucInventoryAction.modeFilters.ALL);
    this.initDatasourceTypeFilterData();
  }

  private initDatasourceTypeFilterData(): void {
    const datasourceOptions = Object.keys(DataConnectorTypes);
    datasourceOptions.unshift('ALL');
    const defaultTypeOption = this.filters?.type == null ? 'ALL' : Object.keys(DataConnectorTypes).find((x) => DataConnectorTypes[x] === this.filters?.type);
    this.tableFilterDatasourceType = this.tableFilterToolDataFactory.create(datasourceOptions, 'detection.datasource.inventory.detail.table.datasource.type.', defaultTypeOption);

    if (this.filters?.type) {
      this.ucInventoryAction.handleDatasourceTypeFilter(Object.keys(DataConnectorTypes).find((x) => DataConnectorTypes[x] === this.filters?.type));
    }
  }
}
