import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { EcoUrlService } from "../../core/url.service";
import { ICreateWhitelistRequest, IDeleteWhitelistsRequest, IDeleteWhitelistsResponse, IDescribeWhitelistRequest, IDescribeWhitelistResponse, IGetWhitelistFieldsRequest, IListWhitelistsRequest, IUpdateWhitelistRequest, IWhitelistConditionField, IWhitelistRepresentation, IWhitelistsApi } from "./whitelists.definitions";
import { Store } from "@ngxs/store";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { CustomerWhitelistsApi } from "projects/console-org/src/app/respond/whitelists/api/customer-whitelists.api";
import { VarWhitelistsApi } from "projects/console-adm/src/app/respond/whitelists/api/var-whitelists.api";

@Injectable({
  providedIn: 'root',
})
export class WhitelistsApi implements IWhitelistsApi {
  private customerService: CustomerWhitelistsApi;
  private varService: VarWhitelistsApi;

  constructor(
    protected store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerWhitelistsApi(http, url);
    this.varService = new VarWhitelistsApi(http, url);
  }

  public listWhitelists(request?: IListWhitelistsRequest): Observable<ResponseUtils<IWhitelistRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listWhitelists(request);
    }
    return this.customerService.listWhitelists(request);
  }

  public getWhitelistConditionFields(request?: IGetWhitelistFieldsRequest): Promise<IWhitelistConditionField[]> {
    if (this.isVarMode) {
      return this.varService.getWhitelistConditionFields(request);
    }
    return this.customerService.getWhitelistConditionFields(request);
  }

  public describeWhitelist(request: IDescribeWhitelistRequest): Observable<IDescribeWhitelistResponse> {
    if (this.isVarMode) {
      return this.varService.describeWhitelist(request);
    }
    return this.customerService.describeWhitelist(request);
  }

  public createWhitelist(request: ICreateWhitelistRequest): Observable<IDescribeWhitelistResponse> {
    if (this.isVarMode) {
      return this.varService.createWhitelist(request);
    }
    return this.customerService.createWhitelist(request);
  }

  public updateWhitelist(request: IUpdateWhitelistRequest): Observable<IDescribeWhitelistResponse> {
    if (this.isVarMode) {
      return this.varService.updateWhitelist(request);
    }
    return this.customerService.updateWhitelist(request);
  }

  public deleteWhitelist(request: IDeleteWhitelistsRequest): Promise<IDeleteWhitelistsResponse> {
    if (this.isVarMode) {
      return this.varService.deleteWhitelist(request);
    }
    return this.customerService.deleteWhitelist(request);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
