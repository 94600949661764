import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { Stages } from 'projects/@common/definitions/stages';
import { environment } from 'projects/@common/environments/environment';
import { AuthModule } from 'projects/@common/modules/auth/auth.module';
import { DatasourcesModule } from 'projects/@common/modules/datasources/datasources.module';
import { DisplayModule } from 'projects/@common/modules/display/display.module';
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { PrivateModule } from 'projects/@common/modules/layout/private.module';
import { RootSharedModule } from 'projects/@common/modules/shared/root.shared.module';
import { DashboardApi } from 'projects/@common/services/api/respond/dashboard/dashboard.api';
import { DatasourcesApiService } from 'projects/@common/services/api/respond/datasources/datasources.api';
import { OrgDetectSidebar } from '../configuration/org.detect.sidebar.service';
import { OrgIamSidebar } from '../configuration/org.iam.sidebar.service';
import { ORG_APP_PAGES } from '../configuration/org.pages.enum';
import { OrgPermissionsMapping } from '../configuration/org.permission.mapping';
import { OrgRespondSidebar } from '../configuration/org.respond.sidebar.service';
import { OrgToolsSidebar } from '../configuration/org.tools.sidebar.service';
import { OrgComponent } from './org.app.component';
import { OrgRoutingModule } from './org.app.routing.module';
import { OrgDashboardApi } from './respond/dashboard/api/org-dashboard.api';
import { OrgDatasourcesApiService } from './respond/datasources/api/org-datasources.api';
import { PermissionGrantConfirmationComponent } from './permission-grant-confirmation/permission-grant-confirmation.component';
import { UiKitModule } from '@ui-kit/uikit.module';

const ngxs = [
  NgxsReduxDevtoolsPluginModule.forRoot({
    name: 'MD.ECO.ADM',
    disabled: environment.stage === Stages.PROD,
  }),
];

@NgModule({
  declarations: [
    OrgComponent,
    PermissionGrantConfirmationComponent
  ],
  imports: [
    RootSharedModule,
    OrgRoutingModule,
    DatasourcesModule,
    UiKitModule,
    AuthModule.forRoot({
      redirectAfterLogin: '/',
    }),
    DisplayModule.forRoot({
      permissionsMapping: OrgPermissionsMapping,
    }),
    PrivateModule.inject({
      iamSideBar: new OrgIamSidebar(),
      respondSidebar: new OrgRespondSidebar(),
      detectSidebar: new OrgDetectSidebar(),
      toolsSidebar: new OrgToolsSidebar(),
      pagesEnum: ORG_APP_PAGES,
      consoleType: ConsoleType.ORG,
    }),
    ...ngxs,
  ],
  providers: [
    ...RootSharedModule.getCommonProviders(),
    { provide: DashboardApi, useClass: OrgDashboardApi },
    { provide: DatasourcesApiService, useClass: OrgDatasourcesApiService },
  ],
  bootstrap: [OrgComponent],
})
export class OrgModule { }
