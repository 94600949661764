  <img
    *ngIf="isWrite"
    class="icon-pos-1 img24px"
    [class.fixedGridDisplay]="fixedGridDisplay"
    alt="Écriture"
    [src]="writeIcon"
    [tooltip]="'histories.container.page.filter.indicators.WRITE.filter' | t"
  >

  <img
    *ngIf="isClient"
    class="icon-pos-2 img24px"
    [class.fixedGridDisplay]="fixedGridDisplay"
    alt="Opération cliente"
    [src]="clientIcon"
    [tooltip]="'histories.container.page.filter.indicators.CLIENT.filter' | t"
  >

  <img
    *ngIf="isVar"
    class="icon-pos-3 img22px"
    [class.fixedGridDisplay]="fixedGridDisplay"
    alt="Opération VAR"
    [src]="varIcon"
    [tooltip]="'histories.container.page.filter.indicators.VAR.filter' | t"
  >

  <img
    *ngIf="isSystem"
    class="icon-pos-4 img24px"
    [class.fixedGridDisplay]="fixedGridDisplay"
    alt="Opération système"
    [src]="systemIcon"
    [tooltip]="'histories.container.page.filter.indicators.SYSTEM.filter' | t"
  >