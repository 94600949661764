import { Component } from '@angular/core';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { ProfileCardColorEnum } from 'projects/@assist-ops/components/eco-profile-card/eco-profile-card.component';
import { IEcoProfileConfig } from 'projects/@assist-ops/components/eco-profile-card/eco-profile-row/eco-profile-row.component';
import { UserProfile } from 'projects/@assist-ops/components/user-info-dashboard/user-info-dashboard.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { FeatureFlagsEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';
import { MdEcoApiService } from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile-api';
import {
  GetOrganizationProfileResponse,
  IOrganizationActions,
} from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile.definition';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.container.html',
  styleUrls: ['./dashboard.container.scss'],
})
export class DashboardContainer {
  public enabledWidgets = {
    resourcesWithShares: { isActive: true },
    sharesByUser: { isActive: true },
    sharesTrending: { isActive: true },
    highRiskSharesTrending: { isActive: true },
    top10HighRiskShares: { isActive: true },
  };

  public uiTabsColor = UiTabsColor;

  public cardColorEnum = ProfileCardColorEnum;

  public action: IEcoProfileConfig = {
    title: 'eco-profile.page.tab.user-profile.proactive-section.title',
    emptyMessage: 'eco-profile.page.tab.user-profile.proactive-section.no-value',
    actions: [],
  };

  public userProfile: Partial<UserProfile> & { commitmentLevel: number };

  public yearsDropdownFilter: number[];

  public isLoading: boolean;

  constructor(
    private i18n: I18nService,
    private mdEcoApiService: MdEcoApiService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  public handleYearSelection(year: number): void {
    this.mdEcoApiService.getDashboardUserProfile({ year }).then((profile) => {
      this.setupRows(profile.organizationActions);
    });
  }

  private setupRows(organizationActions: IOrganizationActions): void {
    this.action.actions = [
      {
        name: 'dashboard.admin.row.revokedShares.title',
        description: this.i18n.translate('dashboard.admin.row.revokedShares.description', {
          number: organizationActions.revokeShares,
          numberRevokeShareLinks: organizationActions.revokeSharesLinks,
        }),
        shouldDisplay: true,
        image: 'icon_unlink_grey.svg',
        flagRequired: [FeatureFlagsEnum.SHARES_MANAGEMENT],
      },
      {
        name: 'dashboard.admin.row.reviews.title',
        description: this.i18n.translate('dashboard.admin.row.reviews.description', {
          number: organizationActions.certify,
          numberRevoke: organizationActions.revokeUsers,
        }),
        shouldDisplay: true,
        image: 'icon_certifier.svg',
        flagRequired: [FeatureFlagsEnum.RESOURCE_MANAGEMENT],
      },
      {
        name: 'dashboard.admin.row.securityModels.title',
        description: this.i18n.translate('dashboard.admin.row.securityModels.description', {
          number: organizationActions.addTemplate,
        }),
        shouldDisplay: true,
        image: 'icon_template_added.svg',
        flagRequired: [FeatureFlagsEnum.RESOURCE_MANAGEMENT],
      },
      {
        name: 'dashboard.admin.row.archived.title',
        description: this.i18n.translate('dashboard.admin.row.archived.description', {
          total: organizationActions.archive,
        }),
        shouldDisplay: true,
        image: 'icon_archive_pale_grey.svg',
        flagRequired: [FeatureFlagsEnum.RESOURCE_MANAGEMENT],
      },
    ];
  }

  private loadData(): void {
    this.isLoading = true;
    this.mdEcoApiService.getDashboardOrganizationProfile().then((orgProfile) => {
      this.yearsDropdownFilter = orgProfile.yearsDropdownFilter;
      this.userProfile = {
        userActions: {
          archive: null,
          certify: null,
          revokeShares: null,
          revokeUsers: null,
          addTemplate: null,
          removeSensitiveFile: null,
          monthlyCounts: null,
          total: orgProfile.proactiveActions,
        },
        badges: { organizationBadgesCount: orgProfile.badges, userBadges: [] },
        commitmentLevel: orgProfile.commitmentLevel,
      };

      this.mdEcoApiService
        .getDashboardUserProfile({ year: this.yearsDropdownFilter[0] })
        .then((profile) => {
          this.setupRows(profile.organizationActions);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  }
}
