<div class="topbar--left">
  <div class="above-sidenav">
    <topbar-toggle
      class="topbar--toggle"
      (toggle)="handleToggle()"
    >
    </topbar-toggle>
    <topbar-logo
      class="topbar--logo"
      *ngIf="!mobileService.isMobile()"
    ></topbar-logo>
  </div>
</div>
<div class="topbar--right">
  <ng-container *ngIf="!mobileService.isMobile()">
  </ng-container>
  <ng-container *ngIf="!(isSideBarExpanded$ | async) && hasSearchBar">
    <div class="has-search-bar">
      <button
        ui-button
        color="drawerCloseFlat"
        icon="magnify"
        icon-size="medium"
        (click)="openSearchBar()"
      ></button>
    </div>
  </ng-container>
  <topbar-user class="topbar--user"></topbar-user>
  <topbar-additional-info *ngIf="isConsoleOrg && (canListMdrReleaseNotes || canListProactiveReleaseNotes)"></topbar-additional-info>
</div>
<div *ngIf="isTopbarDisabled$ | async" class="topbar--disabled"></div>
