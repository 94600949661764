import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { UiKitModule } from "@ui-kit/uikit.module";
import { AssistOpsModule } from "projects/@assist-ops/assist-ops.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { SharedModule } from "projects/console-adm/src/app/shared/shared.module";
import { GuestUsersTableComponent } from "./components/guest-users-table/guest-users-table.component";
import { GuestManagersComponent } from './containers/guest-managers/containers/guest-managers/guest-managers.container';
import { GuestUserAdminsComponent } from './containers/guest-user-admins/guest-user-admins.component';
import { GuestUsersManagementContainerComponent } from './containers/guest-users-management-container/guest-users-management-container.component';
import { GuestUsersDeleteModalComponent } from './containers/guest-users/guest-users-management/guest-users-delete-modal/guest-users-delete-modal.component';
import { GuestUsersManagementComponent } from './containers/guest-users/guest-users-management/guest-users-management.component';
import { GuestUsersContainer } from "./containers/guest-users/guest-users.container";
import { GuestUsersRoutingModule } from './guest-users.routing.module';

@NgModule({
  declarations: [
    GuestUsersContainer,
    GuestUsersTableComponent,
    GuestUsersManagementContainerComponent,
    GuestUsersManagementComponent,
    GuestUserAdminsComponent,
    GuestUsersDeleteModalComponent,
    GuestManagersComponent
  ],
  imports: [
    CommonModule,
    AssistOpsModule,
    GuestUsersRoutingModule,
    I18nModule,
    SharedModule,
    FeatureSharedModule,
    UiKitModule,
    MatButtonToggleModule,
    FormsModule
  ],

})
export class GuestUsersModule {
}
