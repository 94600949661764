import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { BusinessHours, EscalationDelays, IEscalationParameters } from 'projects/@common/services/api/respond/communication/communication.definitions';

@Component({
  selector: 'communication-tab-settings',
  templateUrl: './communication-tab-settings.component.html',
  styleUrls: [ './communication-tab-settings.component.scss' ],
})
export class CommunicationTabSettingsComponent {
  @Input() parameters: IEscalationParameters;
  @Input() isLoading = false;
  @Input() isVarMode: boolean;

  @Output() saveParametersEvent = new EventEmitter<IEscalationParameters>();

  constructor(private readonly displayService: DisplayService) {
  }

  get hasEditPermission(): boolean {
    return this.isVarMode && this.displayService.meetsRequirements({ permissions: [ 'can_write_escalation', 'can_update_escalation_parameter' ] });
  }

  saveBusinessHours(businessHours: BusinessHours) {
    this.saveParametersEvent.emit({ businessHours });
  }

  saveEscalationDelays(escalationDelays: EscalationDelays) {
    this.saveParametersEvent.emit({ escalationDelays });
  }
}
