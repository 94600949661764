import { DatasourceDetailsMapper } from 'projects/@common/services/api/respond/datasources/datasource.mapper';
import {
  ModeFilterEnum
} from 'projects/@common/services/api/respond/datasources/datasources.definitions';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { PrioritiesFilterEnum } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { BehaviorSubject } from 'rxjs';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { ConnectorFamilies } from '@md.eco/connectors';

export class DatasourcesInventoryDetailActions {
  public $datasources = new BehaviorSubject([]);

  public total = 0;

  public requestsQuery: string = null;

  public modeCurrentFilter: ModeFilterEnum = ModeFilterEnum.ALL;

  public prioritiesFilter: PrioritiesFilterEnum[] = [];

  public modeFilters = ModeFilterEnum;

  public priorityFilters = PrioritiesFilterEnum;

  public isLoading = false;

  private datasourceFamily: ConnectorFamilies;

  constructor(
    private organizationId: string,
    private readonly usecasesApiService: UsecasesApiService,
    private readonly datasourceDetailsMapper: DatasourceDetailsMapper,
    private readonly userLanguage: string,
    private readonly noticeService: NoticeService
  ) {
  }

  public setDatasourceFamily(value: ConnectorFamilies): void {
    this.datasourceFamily = value;
  }

  public async handleModeFilter(selected: ModeFilterEnum): Promise<void> {
    this.modeCurrentFilter = selected;
    await this.handleRefresh();
  }

  public async handlePrioritiesFilter(selected: PrioritiesFilterEnum[]): Promise<void> {
    this.prioritiesFilter = selected;
    await this.handleRefresh();
  }

  public async handleSearchQuery(value: string): Promise<void> {
    this.requestsQuery = value;
    await this.handleRefresh();
  }

  public async handleSearchClear(): Promise<void> {
    this.requestsQuery = null;
    await this.handleRefresh();
  }

  public get queryParams(): any {
    let modeFilter: string;
    if (this.modeCurrentFilter === ModeFilterEnum.ALL) {
      modeFilter = null;
    }
    if (this.modeCurrentFilter === ModeFilterEnum.SILENCE) {
      modeFilter = 'true';
    }
    if (this.modeCurrentFilter !== ModeFilterEnum.SILENCE && this.modeCurrentFilter !== ModeFilterEnum.ALL) {
      modeFilter = 'false';
    }

    const params = {
      datasourceFamilies: [ this.datasourceFamily ],
      searchName: this.requestsQuery,
      userLanguage: this.requestsQuery ? this.userLanguage : null,
      silenced: modeFilter,
      priorities: this.prioritiesFilter.length > 0 ? this.prioritiesFilter.map((value) => Number(value.substr(1))) : null,
    };

    return Object.fromEntries(Object.entries(params).filter(([ _, v ]) => v != null));
  }

  public async fetchUsecases(): Promise<any[]> {
    this.$datasources.next([]);
    this.isLoading = true;
    const datasources = await this.usecasesApiService.getAggregatedUsecasesList(
      this.organizationId,
      this.queryParams
    );
    const mappedDs = datasources.items.map((value) => this.datasourceDetailsMapper.detailTableMapper(value));
    this.total = mappedDs.length;
    this.$datasources.next(mappedDs);
    this.isLoading = false;

    return mappedDs;
  }

  public async handleRefresh(): Promise<void> {
    await this.fetchUsecases()
      .catch(() => this.noticeService.notifyUser({ message: 'usecases.error.fetch.message', level: NoticeLevels.ERROR }));
  }
}
