import { Injectable } from '@angular/core';
import { MultiSelectDataFactory } from '@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { IncidentHistoryService } from '../../history-tab.service';
import { HistoryIndicatorsEnum } from 'projects/@respond/histories/components/histories-filters/histories-filters.service';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { HistoriesApi } from '@common/services/api/respond/histories/histories.api';
import { ResponseUtils } from '@common/utils/response-utils';
import { IIncidentHistory } from '@common/services/api/respond/histories/histories.definition';
import { StaticTableDataMapper } from '@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { GetIncidentHistoriesRequest } from '@common/services/api/respond/histories/models/getIncidentIHistoriesRequest';
import { IncidentEventsService } from 'projects/@respond/incident/services/incident.events.service';

export enum IncidentHistoryIndicatorsEnum {
  WRITE = 'WRITE',
  VAR = 'VAR',
  CLIENT = 'CLIENT',
  SYSTEM = 'SYSTEM',
}

export enum IncidentHistoryFilterName {
  indicators = 'indicators',
  notificationActions = 'notificationActions'
}

export interface IHistoryFiltersApplied {
  indicatorsFilter: string[],
  notificationActionsFilter: string[];
}

@Injectable()
export class IncidentHistoryTabFiltersService {

  private _debounceTimeout: NodeJS.Timeout;

  private _indicatorsFilter: string[] = null;
  private _notificationActionsFilter: string[] = [];
  private _indicatorsValues: MultiSelectData  = new MultiSelectData();

  public isLoading: boolean = false;

  private _activeFilterList = {
    [IncidentHistoryFilterName.indicators]: false,
    [IncidentHistoryFilterName.notificationActions]: false,
  };

  constructor(
    private readonly multiSelectDataFactory: MultiSelectDataFactory,
    public readonly incidentHistoryService: IncidentHistoryService,
    private incidentEventsService: IncidentEventsService,
  ){
  }

  public initializeHistoryFilters(): void {
    this.initializeMultiSelects();
  }

  private initializeMultiSelects(): void {
    this._indicatorsValues = this.multiSelectDataFactory.create(IncidentHistoryIndicatorsEnum, this.ensureArray(this._indicatorsFilter), 'histories.container.page.filter.indicators.', true);
  }

  public get indicatorsValues(): MultiSelectData {
    return this._indicatorsValues;
  }

  public applyIndicatorsFilter(value: string[]): void {
    this._indicatorsFilter = !value || value.length === 0 ? [] : value;
    this.updateActiveFilters();
    this._emitQuery();
  }

  public applyNotificationActionsFilter(value: boolean): void {
    this._notificationActionsFilter = value ? ['NOTIFICATION_SENT'] : [];
    this._emitQuery();
  }

  public get activeFilters(): string[] {
    return Object.keys(this._activeFilterList).filter(
      key => this._activeFilterList[key as IncidentHistoryFilterName]
    );
  }

  public get activeFiltersCount(): number {
    return this.activeFilters?.length ?? 0;
  }

  public get hasActiveFilters(): boolean {
    return this.activeFiltersCount > 0;
  }

  public clearAllFilters(): void {
    this._indicatorsFilter = null;
    this._notificationActionsFilter = null;
  }

  public updateActiveFilters(): void {
    this._activeFilterList = {
      ...this._activeFilterList,
      [IncidentHistoryFilterName.notificationActions]: Boolean(this._notificationActionsFilter),
      [IncidentHistoryFilterName.indicators]: this._indicatorsFilter?.length > 0,
    };
  }

  private _emitQuery(): void {

    if (this._debounceTimeout) {
      clearTimeout(this._debounceTimeout);
    }

    this._debounceTimeout = setTimeout(() => {
      this.incidentEventsService.emitHistoryFiltersEvent({
        indicatorsFilter: this._indicatorsFilter,
        notificationActionsFilter: this._notificationActionsFilter
      });
    }, 500);

  }

  private ensureArray(value: any) {
    if (!value) {
      return [];
    }
    if (value && !Array.isArray(value)) {
      return [ value ];
    }
    return value;
  }

}
