import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { JustificationConfirmationModalComponent } from '@ui-kit/components/justification-confirmation-modal/justification-confirmation-modal.component';
import { ITreeBranch, UiTree } from '@ui-kit/components/ui-tree/ui-tree.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { ModalService } from '@ui-kit/services/modal.service';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { CookDeliciousSnack, CookInedibleSnack } from 'projects/@common/modules/notice/implementations/snackbar/state/snackbar.state';
import { GroupTemplatesApiService } from 'projects/@common/services/api/sg/group-templates/group-templates-api.service';
import { GroupTemplateLifecycleEnum, GroupTemplateScopeEnum, GroupTemplateStateEnum, IGroupTemplate } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { GroupTemplateFormService } from '../../services/group-template-form.service';

@Component({
  selector: 'group-template',
  templateUrl: './group-template.component.html',
  styleUrls: [ './group-template.component.scss' ],
})
export class GroupTemplateComponent implements OnInit {
  public groupTemplate: IGroupTemplate;
  public isLoading = false;
  public isSaving = false;
  public isEditing = false;
  public folderTree: ITreeBranch[] = [];
  public isSharepointGroup = false;
  public isSharepointGroupCopy = false;
  public isGlobal = false;
  public expirationTrigger = GroupTemplateLifecycleEnum.INACTIVITY;
  public typeOptions: any[];
  public stateOptions: any[];
  public expirationOptions: any[];
  public usersAutocomplete = AutocompleteTypes.USERS;
  public areTreesValid = true;
  public displayFoldersOnly = false;
  public locale = this.i18n.currentLocale;
  public defaultApprovers: any[] = [];
  public defaultApproversCopy: any[] = [];
  private templateGuid: string;

  constructor(
    public i18n: I18nService,
    public groupTemplateForm: GroupTemplateFormService,
    private modalService: ModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private templatesService: GroupTemplatesApiService,
    private location: Location,
    private store: Store,
    private organizationsApiService: OrganizationsApiService
  ) {
    this.setSelectionsOptions();
  }

  public get defaultApprovingValid(): boolean {
    const formValue = this.groupTemplateForm.approversForm.value;
    if (formValue?.reach === GroupTemplateScopeEnum.GLOBAL.toLowerCase()) {
      return true;
    }
    return this.defaultApprovers.length > 0;
  }

  public ngOnInit(): void {
    this.isLoading = true;
    this.activatedRoute.params.subscribe((param) => {
      this.templateGuid = param.id;
      this.templateGuid ? this.fetchTemplate() : this.initNewForm();
    });

    this.groupTemplateForm.isCreating = !this.activatedRoute?.snapshot?.params?.id;
    this.initCreating();
  }

  public duplicateForm(): void {
    this.groupTemplateForm.isDuplicating = true;
    this.groupTemplateForm.isCreating = true;
    this.initCreating();
    this.groupTemplateForm.onDuplicate(this.i18n.currentLocale as LanguageEnum);
  }

  public toggleEditMode(): void {
    if (this.groupTemplateForm.isCreating && !this.groupTemplateForm.isDuplicating) {
      this.router.navigateByUrl('csp/templates');
      return;
    }

    this.groupTemplateForm.onToggleEditDuplicate();
    this.isEditing = !this.isEditing;
    if (!this.isEditing) {
      this.updateInitialState(true);
      this.groupTemplateForm.isSetToExpire = !!this.groupTemplate.lifecycle?.expirationDuration;
      this.groupTemplateForm.createForms(this.groupTemplate);
      this.folderTree = UiTree.convertToTree(this.groupTemplate.folders, 'name', 'subFolders');
    } else {
      this.updateInitialState();
    }
  }

  public save(): void {
    this.updateInitialState();
    this.groupTemplate = this.groupTemplateForm.mapGroupTemplateToSave(this.mappedDefaultApproversList(), this.folderTree);

    if (this.groupTemplate.type === GroupTypeEnum.O365OFFICETEAMS) {
      this.validateDuplicateChannelNames();
    } else {
      delete this.groupTemplate.permissions;
    }

    this.isSaving = true;
    const call = this.groupTemplateForm.isCreating
      ? this.templatesService.postEcoGroupTemplate(this.groupTemplate)
      : this.templatesService.putEcoGroupTemplate(this.templateGuid, this.groupTemplate);

    call.then((result) => {
      this.templateGuid = result.id;
      this.groupTemplateForm.isCreating = false;
      this.toggleEditMode();
      this.router.navigateByUrl(`csp/templates/groupTemplate/${result.id}`);
      this.store.dispatch(new CookDeliciousSnack('groupTemplates.success.save.message'));
    })
      .catch((error) => {
        this.store.dispatch(new CookInedibleSnack(error.status === 403 ? 'groupTemplates.error.message.missingPermissions' : 'groupTemplates.error.save.message'));
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  public onTypeSelected(type: GroupTypeEnum): void {
    this.isSharepointGroup = type === GroupTypeEnum.O365OFFICEGROUP;
  }

  public validateAllTrees(): void {
    this.areTreesValid = UiTree.isTreeValid(this.folderTree);

    for (const channelForm of this.groupTemplateForm.channelsForms.controls) {
      const tree = channelForm.get('folders').value;
      if (!UiTree.isTreeValid(tree)) {
        this.areTreesValid = false;
      }
    }
  }

  public openConfirmation(): void {
    this.modalService.openDialog(JustificationConfirmationModalComponent, {
      title: this.i18n.translate('groupTemplates.delete.confirm.title'),
      text: this.i18n.translate('groupTemplates.delete.confirm.text', { type: this.groupTemplate.type }),
      justificationLabel: this.i18n.translate('groupTemplates.delete.justification.label', { type: this.groupTemplate.type }),
      justificationConfirmationLabel: this.i18n.translate('groupTemplates.delete.justification.confirmation.label'),
      justificationConfirmationPlaceholder: this.i18n.translate('common.action.delete'),
      confirmationText: this.i18n.translate('yes'),
      confirmationFieldText: {
        fr: this.i18n.translate('common.action.delete', { locale: LanguageEnum.FRENCH }),
        en: this.i18n.translate('common.action.delete', { locale: LanguageEnum.ENGLISH }),
      },
      cancelText: this.i18n.translate('no'),
      currentLang: this.i18n.currentLocale,
      callback: (closeValue: { value: boolean; justification: string }) => {
        if (closeValue.value) {
          this.deleteTemplate(closeValue.justification);
        }
      },
    });
  }

  public async deleteTemplate(justification: string): Promise<void> {
    this.isLoading = true;
    this.templatesService
      .deleteGroupTemplate(this.templateGuid, justification)
      .then(() => {
        this.router.navigateByUrl('csp/templates');
        this.store.dispatch(new CookDeliciousSnack('groupTemplates.success.delete.message'));
      })
      .catch((error) => {
        this.store.dispatch(new CookInedibleSnack(error.status === 403 ? 'groupTemplates.error.message.missingPermissions' : 'groupTemplates.error.delete.message'));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public deleteDefaultApprover(defaultApprover: any): void {
    this.defaultApprovers = this.defaultApprovers.filter((item) => item.guid !== defaultApprover.guid);
  }

  private updateInitialState(revert = false) {
    if (revert) {
      this.defaultApprovers = JSON.parse(JSON.stringify(this.defaultApproversCopy));
      this.isSharepointGroup = JSON.parse(JSON.stringify(this.isSharepointGroupCopy));
    } else {
      this.defaultApproversCopy = JSON.parse(JSON.stringify(this.defaultApprovers));
      this.isSharepointGroupCopy = JSON.parse(JSON.stringify(this.isSharepointGroup));
    }
  }

  private initCreating() {
    if (this.groupTemplateForm.isCreating) {
      this.groupTemplateForm.isSetToExpire = true;
      this.isEditing = true;
    }
  }

  private initNewForm() {
    this.organizationsApiService
      .getOrganizationSettings()
      .then((response) => this.groupTemplateForm.templateForm.patchValue({
        ...this.groupTemplateForm.templateForm.value,
        certifyExpirationDuration: response.defaultRevisionFrequency,
      }))
      .finally(() => {
        this.isLoading = false;
      });
    this.groupTemplateForm.createForms(null);
  }

  private fetchTemplate() {
    this.templatesService.getEcoGroupTemplate(this.templateGuid).then((template) => {
      this.groupTemplate = template;
      this.displayFoldersOnly = template.type === GroupTypeEnum.O365OFFICEGROUP;
      this.isSharepointGroup = this.groupTemplate.type === GroupTypeEnum.O365OFFICEGROUP;
      this.isGlobal = template.scope === GroupTemplateScopeEnum.GLOBAL;
      this.folderTree = UiTree.convertToTree(template.folders, 'name', 'subFolders');
      this.groupTemplateForm.isSetToExpire = !!this.groupTemplate.lifecycle?.expirationDuration;
      this.groupTemplateForm.createForms(template);
      this.defaultApprovers = this.groupTemplate.defaultApprovers.map((approver) => ({
        ...approver,
        guid: approver.o365UserId,
      }));
      this.updateInitialState();
    })
      .catch(() => {
        this.store.dispatch(new CookInedibleSnack('groupTemplates.error.loading.message'));
        this.location.back();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private mappedDefaultApproversList() {
    return this.defaultApprovers.map((defaultApprover) => ({
      o365UserId: defaultApprover.guid,
      firstName: defaultApprover.firstName,
      type: defaultApprover.type,
    }));
  }

  private validateDuplicateChannelNames() {
    const names = this.groupTemplate.channels.map((channel) => channel.name);
    for (const name of names) {
      if (names.filter((other) => other === name).length > 1) {
        this.store.dispatch(new CookInedibleSnack('groupTemplates.channelsName.validation.error', {
          count: names.filter((other) => other === name).length,
          channelName: name,
        }));
        return;
      }
    }
  }

  private setSelectionsOptions(): void {
    this.expirationOptions = [
      {
        value: GroupTemplateLifecycleEnum.INACTIVITY,
        displayValue: this.i18n.translate(`groupTemplates.lifecycle.expirationType.${GroupTemplateLifecycleEnum.INACTIVITY}`),
      },
      {
        value: GroupTemplateLifecycleEnum.CREATION,
        displayValue: this.i18n.translate(`groupTemplates.lifecycle.expirationType.${GroupTemplateLifecycleEnum.CREATION}`),
      },
    ];

    this.stateOptions = [
      {
        value: GroupTemplateStateEnum.PUBLISHED,
        displayValue: this.i18n.translate(`groupTemplates.state.${GroupTemplateStateEnum.PUBLISHED}`),
      },
      {
        value: GroupTemplateStateEnum.DRAFT,
        displayValue: this.i18n.translate(`groupTemplates.state.${GroupTemplateStateEnum.DRAFT}`),
      },
    ];

    this.typeOptions = [
      {
        value: GroupTypeEnum.O365OFFICEGROUP,
        displayValue: this.i18n.translate(`${GroupTypeEnum.O365OFFICEGROUP}`),
      },
      {
        value: GroupTypeEnum.O365OFFICETEAMS,
        displayValue: this.i18n.translate(`${GroupTypeEnum.O365OFFICETEAMS}`),
      },
    ];
  }
}
