import { AlertPriorityEnum } from "../../respond/alerts/alerts.definitions";

export interface IConnectorInstance {
  id: string;
  createdAt: number;
  updatedAt: number;
  versionId: string;
  connectorId: string;
  name: string;
  customName?: string;
  ingestionMethodId: string;
  ingestionMethodConfig: string;
  remediationConfig: string;
  parsers: string;
  ingestionTopic: string;
  logTopic: string;
  organizationId: string;
  status: ConnectorInstanceBuildStatus;
  health: IHealthStatus;
  lastIngestedLog: number;
  averageLatency?: number;
  logErrorThreshold: number;
  alertPriority: AlertPriorityEnum;
  healthAlertingSchedule?: string;
  errorIndicators?: any;
  mutedFrom: number;
  mutedTo: number;
  scalingConfig: string;
}

export interface IConnectorHealth {
  error: IIndicatorStatus;
  lag: IIndicatorStatus;
  status: ConnectorHealthStatus;
  log?: IIndicatorStatus;
}

export interface IIndicatorStatus {
  mutedFrom?: number;
  mutedTo?: number;
}

export enum IConnectorIndicatorStatus {
  ERROR = "Error",
  WARNING = "Warning",
  GREEN = "Green"
}

export enum IHealthStatus {
  ERROR = "error",
  WARNING = "warning",
  GREEN = "green"
}

export enum ConnectorHealthStatus {
  ERROR = "error",
  ANOMALY = "warning",
  HEALTHY = "green"
}

export enum ConnectorInstanceBuildStatus {
  CREATING = "creating",
  UPDATING = "updating",
  DESTROYING = "destroying",
  RUNNING = "running",
  MAINTENANCE = "maintenance",
  ERROR = "error"
}

export interface ConnectorInstanceHistory {
  timestamp: number;
}

export interface ConnectorInstanceByteRate extends ConnectorInstanceHistory {
  rate: number;
}

export interface ConnectorInstanceLagHistory extends ConnectorInstanceHistory {
  lag: number;
}

export interface ConnectorInstanceVolumeStatsHistory extends ConnectorInstanceHistory {
  volume: number;
}

export interface ConnectorInstanceVolumeStats {
  average: number;
}

export enum ConnectorTimeSeriesPeriod {
  '1_MINUTE' = '1m',
  '5_MINUTES' = '5m',
  '15_MINUTES' = '15m',
  '1_HOUR' = '1h',
  '6_HOURS' = '6h',
  '1_DAY' = '1d',
  '7_DAYS' = '7d',
  '30_DAYS' = '30d'
}

export enum ConnectorCalendarInterval {
  MINUTE = "minute",
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  YEAR = "year"
}
