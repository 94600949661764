
<div class="main-container-expanded" *ngIf="isExpanded">
  <div class="notes-section-header">
    <div class="img-header-container">
      <img src="assets/favicons/icon_note.svg" />
    </div>
    <h2>{{ 'incidents.container.page.details.tab.chat.notesSection.title' | t }}</h2>
    <ui-icon
      class="toggle-expand"
      modifier="on-header"
      name="chevron-down"
      size="medium"
      (click)="toggleExpand()"
    >
    </ui-icon>
  </div>

  <div class="conversation-container">
    <ng-container *ngTemplateOutlet="loading">

    </ng-container>
    <ng-container *ngTemplateOutlet="notes">

    </ng-container>
  </div>

  <div class="bottom-section">
    <div class="editor-wrapper" [class.focused]="isTextboxFocused">
      <html-editor
        class="editor"
        controlName="message"
        [options]="froalaOptions"
        [handleImage]="false"
        [handleLink]="false"
        [isEditing]="true"
        [group]="formGroup"
        [htmlData]="messageValue"
        [placeholder]="null"
        [editorEventHandlers]="editorEventHandlers"
      >
      </html-editor>
      <div *ngIf="incidentClosed" class="disabled-editor"></div>
    </div>
    <button
      ui-button
      color="standard"
      class="send-button"
      [disabled]="!messageValue"
      (click)="sendMessage()"
    >
      {{"incidents.container.page.details.tab.chat.send" | t}}
    </button>   
  </div>
</div>

<div *ngIf="!isExpanded" class="main-container-collapsed">
  <ui-icon
    class="toggle-expand"
    modifier="on-header"
    name="chevron-right"
    size="medium"
    (click)="toggleExpand()"
  >
  </ui-icon>
  <img src="assets/favicons/icon_note.svg" />
</div>

<ng-template #notes>
  <ng-container *ngIf="!isLoading">
    <div class="messages-crop-container">
     <div class="messages-container" *ngIf="incidentNotes?.length">
        <div *ngFor="let message of incidentNotes" 
          class="note-container" 
          (mouseenter)="hoverMessage(message.id)"
          (mouseleave)="hoverMessage(null)">       
          <span class="user-name">
            <p>{{ message.sentAt | dt:{ withTime:true, locale: i18nService.currentLocale } }}</p>
            <p>&nbsp;-&nbsp;</p>
            <p>{{ message.senderName }}</p>
          </span>
          <div class="note-box">
            <div *ngIf="!isMessageEditing(message.id)" class="message-content-notes" [innerHtml]="message.content">
            
            </div>
            <div *ngIf="isMessageEditing(message.id)">
              <html-editor
                class="editor"
                [useNgModel]="true"
                [(ngModel)]="messagesEditingMode[message.id]"
                [placeholder]="null"
              >
              </html-editor>
              <ui-confirmations-buttons 
                [marginTop]="true"
                [isConfirmEnabled]="true"
                (confirmationEvent)="handleEditingConfirmation($event, message.id)">
              </ui-confirmations-buttons>
            </div>
            <div class="buttons-actions" [class.hidden]="messageHoveredId !== message.id || isMessageEditing(message.id) || !messageFromMe(message)">
              <button
                ui-button
                color="linkFlat"
                icon="icon_modify"
                [custom-icon]="true"
                [isSvg]="true"
                icon-size="medium"
                (click)="messageEditingToggle(message.id, message.content)"    
              >            
              </button>
              <button
                ui-button
                color="linkFlat"
                icon="icon_delete"
                [custom-icon]="true"
                [isSvg]="true"
                icon-size="medium"
                (click)="onDelete(message.id)"      
              >            
              </button>
            </div>  
          </div>        
        </div>
      </div>
      <div *ngIf="!incidentNotes?.length" class="no-messages-container">
        <p>
          {{ 'incidents.container.page.details.tab.chat.noMessages' | t}}
        </p>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #loading>
  <ng-container *ngIf="isLoading">
    <ui-spinner
      size="medium"
    ></ui-spinner>
  </ng-container>
</ng-template>