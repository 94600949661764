import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IPlaybookTableObject, PlaybookOrderByEnum } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { clone } from 'projects/@common/utils/utils';
import { PlaybookDisplayTemplateStatusFilter, PlaybooksInventoryContainerActions } from '../../containers/respond-playbook-list/playbooks.actions';

@Component({
  selector: 'playbooks-inventory-body-section',
  templateUrl: './playbooks-inventory-body-section.component.html',
  styleUrls: [ './playbooks-inventory-body-section.component.scss' ],
})
export class PlaybooksInventoryBodySectionComponent implements OnInit {
  @Input()
  public pbInventoryAction: PlaybooksInventoryContainerActions;

  @Input()
  public columnsDef = [];

  @Input() public sortOrder: PlaybookOrderByEnum = PlaybookOrderByEnum.name;

  @Input() public sortDirection: UiTableDirection = UiTableDirection.Asc;

  @Output() public onSorting = new EventEmitter();

  @Output() public playbookClicked = new EventEmitter<IPlaybookTableObject>();

  private playbooksTable: IPlaybookTableObject[] = [];

  public filteredPlaybooksTable: IPlaybookTableObject[] = [];

  private playbookFilter: PlaybookDisplayTemplateStatusFilter = PlaybookDisplayTemplateStatusFilter.ALL;

  constructor(
    private readonly i18n: I18nService,
    private readonly staticTableDataFactory: StaticTableDataMapper
  ) {
  }

  ngOnInit(): void {
    this.pbInventoryAction.$playbooks.subscribe((next) => {
      this.playbooksTable = next;
      this.filteredPlaybooksTable = this.playbooksTable;
    });
    this.pbInventoryAction.displayPlaybookFilter.subscribe((filter) => {
      this.playbookFilter = filter;
      if (this.playbookFilter === PlaybookDisplayTemplateStatusFilter.OUTDATED) {
        this.filteredPlaybooksTable = this.playbooksTable.filter((pb) => pb.outdated);
      } else if (this.playbookFilter === PlaybookDisplayTemplateStatusFilter.VALID) {
        this.filteredPlaybooksTable = this.playbooksTable.filter((pb) => !pb.outdated);
      } else {
        this.filteredPlaybooksTable = this.playbooksTable;
      }
    });

    this.refreshTable();
  }

  public get locale() {
    return this.i18n.currentLocale;
  }

  public refreshTable(): void {
    const incidentsClone = clone(this.playbooksTable);
    [ this.columnsDef, this.playbooksTable ] = this.staticTableDataFactory.translate(this.columnsDef, incidentsClone);
  }

  public async openPlaybookDetails(event: IPlaybookTableObject): Promise<void> {
    this.playbookClicked.emit(event);
  }

  public handleSelected(selected: any): void {
    if (selected.event) {
      this.pbInventoryAction.currentSelectedPlaybook = selected.item;
    } else {
      this.pbInventoryAction.currentSelectedPlaybook = null;
    }
  }

  public handleSort(sortData: any) {
    this.onSorting.emit(sortData);
  }
}
