import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UiKitModule } from "@ui-kit/uikit.module";
import { ComponentsModule } from "projects/@common/components/components.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { PipesModule } from "projects/@common/modules/pipes/pipes.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { ActifDrawerComponent } from "./components/actif-drawer/actif-drawer.component";

import { HistoriesModule } from '../histories/histories.module';
import { ActifDynamicComponentComponent } from "./components/actif-dynamic-component/actif-dynamic-component.component";
import { ActifsDeleteModalComponent } from "./components/actifs-delete-modal/actifs-delete-modal.component";
import { ActifsFiltersComponent } from "./components/actifs-filters/actifs-filters.component";
import { ActifstHistoryComponent } from './components/actifs-history/actifs-history.component';
import { ActifsTableComponent } from "./components/actifs-table/actifs-table.component";
import { ActifsRelationsCellComponent } from './components/actifs-table/components/actifs-relations-cell/actifs-relations-cell.component';
import { ActifsTypeCellComponent } from './components/actifs-table/components/actifs-type-cell/actifs-type-cell.component';
import { RespondActifsListContainer } from './containers/respond-actifs-list/respond-actifs-list.container';

const components = [
  RespondActifsListContainer,
  ActifsTableComponent,
  ActifsFiltersComponent,
  ActifDynamicComponentComponent,
  ActifsDeleteModalComponent,
  ActifDrawerComponent,
  ActifsTypeCellComponent,
  ActifsRelationsCellComponent,
  ActifstHistoryComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    PipesModule,
    RouterModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
    HistoriesModule,
  ],
})
export class ActifsModule { }
