<ng-container *ngIf="usecasesData?.length > 0">
  <app-incident-details-base-layout
    *ngFor="let usecase of usecasesData"
    [title]="'incidents.container.page.details.tab.detail.usecase.title' | t:{count:1}"
  >
    <div class="label-container">
      <ui-static [label]="'incidents.container.page.details.tab.detail.usecase.name' | t"></ui-static>
      <ui-static [value]="usecase?.name | translatedObject"></ui-static>
    </div>

    <div class="label-container">
      <ui-static [label]="'incidents.container.page.details.tab.detail.usecase.id' | t"></ui-static>
      <ui-static [value]="usecase.shortId || '-'"></ui-static>
    </div>

    <div class="label-container">
      <ui-static [label]="'incidents.container.page.details.tab.detail.usecase.description' | t"></ui-static>
      <ui-static [value]="usecase?.description | translatedObject"></ui-static>
    </div>
  </app-incident-details-base-layout>
</ng-container>