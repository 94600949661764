import {Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { NoticeService } from "projects/@common/modules/notice/notice.service";
import { IncidentHistoryService } from './history-tab.service';
import { IIncidentHistory } from "projects/@common/services/api/respond/histories/histories.definition";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { IHistoryFiltersApplied, IncidentHistoryTabFiltersService } from './components/incident-history-tab-filters/incident-history-tab-filters.service';
import { HistoryIndicatorsEnum } from "projects/@respond/histories/components/histories-filters/histories-filters.service";
import { Subscription } from 'rxjs';
import { IncidentEventsService } from 'projects/@respond/incident/services/incident.events.service';

@Component({
  selector: 'app-incident-history-tab',
  templateUrl: './history-tab.component.html',
  styleUrls: [ './history-tab.component.scss' ],
})
export class HistoryTabIncidentComponent implements OnInit, OnDestroy {

  @Input() incident: Incident;

  @Input() notificationActionSwitchToggled: boolean = false;

  public readonly REQUEST_LIMIT: number = 25;
  public isLoadingHistory: boolean = false;
  public isLoadMoreHistory: boolean = false;

  public incidentHistoryResponse: ResponseUtils<IIncidentHistory>;
  public incidentHistory: IIncidentHistory[] = [];

  public incidentId: string;
  public organizationId: string;

  private _historyFilteredResultsSubscription: Subscription;

  public readonly historicColumnsDef = [
    {
      label: '',
      field: 'icon',
      type: UiStaticTableRowType.EXPENDABLE_ICON,
      showIcon: true,
      width: '26px',
      minWidth: '26px',
      isResizable: false,
      noSorting: true,
    },
    {
      label: 'date',
      field: 'createdAt',
      width: '200px',
      minWidth: '200px',
      type: UiStaticTableRowType.DATE_AND_HOUR_AND_AGO,
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('alerts.drawer.tab.history.table.column.user'),
      field: 'identityName',
      width: '20%',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('alerts.drawer.tab.history.table.column.operation'),
      field: 'message',
      width: '80%',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      noSorting: true,
    },
  ];

  constructor(
    public readonly incidentHistoryService: IncidentHistoryService,
    public readonly i18n: I18nService,
    public readonly noticeService: NoticeService,
    private incidentEventsService: IncidentEventsService
  ) { }

  ngOnDestroy(): void {
    this.incident.reloadObservable$.unsubscribe();
    this._historyFilteredResultsSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.incidentId = this.incident.id;
    this.organizationId =  this.incident.organizationId;
    this.loadIncidentHistory();
    this.incident.reloadObservable$.subscribe(() => this.loadIncidentHistory());
    this._historyFilteredResultsSubscription = this.incidentEventsService.historyFiltersAppliedSubject$.subscribe((result: IHistoryFiltersApplied) => this.loadIncidentHistory(result));
  }

  private loadIncidentHistory(appliedFilters?: {indicatorsFilter: string[], notificationActionsFilter: string[]}): void {

    this.isLoadingHistory = true;

    this.incidentHistoryService.fetchIncidentHistories({
      incidentId: this.incidentId,
      organizationId: this.organizationId,
      isVar: appliedFilters?.indicatorsFilter?.includes(HistoryIndicatorsEnum.VAR),
      isClient: appliedFilters?.indicatorsFilter?.includes(HistoryIndicatorsEnum.CLIENT),
      isSystem: appliedFilters?.indicatorsFilter?.includes(HistoryIndicatorsEnum.SYSTEM),
      write: appliedFilters?.indicatorsFilter?.includes(HistoryIndicatorsEnum.WRITE),
      actions: appliedFilters?.notificationActionsFilter,
    }).subscribe(
      (response) => {
        this.incidentHistoryResponse = response;
        this.incidentHistory = this.incidentHistoryResponse.getItems();
      },
      (error) => this.noticeService.error("incidents.history.fetch.error"),
      () => this.isLoadingHistory = false
    );

  }

  public loadMoreIncidentHistory(): void {
    if (!this.isLoadMoreHistory && this.canLoadMore()) {
      this.isLoadMoreHistory = true;

      const from = this.incidentHistoryResponse.getNextItem();

      this.incidentHistoryService.fetchIncidentHistories({
        incidentId: this.incidentId,
        organizationId: this.organizationId,
        from: from }).subscribe(
          (response) => {
            this.incidentHistoryResponse = response;
            this.incidentHistory = this.incidentHistory.concat(this.incidentHistoryResponse.getItems());
          },
          (error) => this.noticeService.error('incidents.history.fetch.error'),
          () => this.isLoadMoreHistory = false
        );
    }
  }

  public get total(): number {
    return this.incidentHistoryResponse?.getTotalItems();
  }

  public get locale(): string {
    return this.i18n.currentLocale;
  }

  public canLoadMore(): boolean {
    return this.incidentHistoryResponse.canLoadMore();
  }
}
