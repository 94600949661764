import { Component, Input, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConnectorInstancePopupComponent, ConnectorInstancePopupInput } from '../connector-instance-popup/connector-instance-popup.component';
import { Router } from '@angular/router';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { ConnectorInstanceDto, ConnectorOrgDto } from 'projects/@common/services/api/detect/connectorsOrg/model/ConnectorOrg';
import { ConnectorMappingHelper } from 'projects/@common/services/api/detect/models/connectorMappingHelper';
import { ModalService } from '@ui-kit/services/modal.service';

@Component({
  selector: 'app-connector-card',
  templateUrl: './connector-card.component.html',
  styleUrls: [ './connector-card.component.scss' ],
})
export class ConnectorFamilyCardComponent {
  @Input() connector: ConnectorOrgDto;
  @Input() connectorInstances: ConnectorInstanceDto[] = [];
  @Input() displayBadgeOwned: boolean = false;
  @Input() displayBadgeAdd: boolean = false;
  @Input() organization: IAutomationOrganization;

  constructor(
    private readonly connectorMappingHelper: ConnectorMappingHelper,
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly zone: NgZone
  ) { }
  public showTooltip: boolean = false;

  public get connectorIcon(): string {
    return this.connectorMappingHelper.getConnectorIcon(this.connector.id);
  }

  public onHoverEnter() {
    this.showTooltip = true;
  }

  public onHoverLeave() {
    this.showTooltip = false;
  }

  public openConnectorDetailsPage() {
    if (this.connectorInstances.length > 1) {
      this.openInstanceSelector();
    } else if (this.connectorInstances.length === 1) {
      this.navigateToDetails(this.connectorInstances[0]);
    } else {
      this.navigateToDetails();
    }
  }

  private openInstanceSelector() {
    const modalData: ConnectorInstancePopupInput = {
      connector: this.connector,
      connectorInstances: this.connectorInstances,
      organization: this.organization,
    };
    const dialogRef: MatDialogRef<ConnectorInstancePopupComponent> = this.modalService.openDialog(ConnectorInstancePopupComponent, modalData, true);
    dialogRef.componentInstance.successEvent.subscribe((result) => {
      this.modalService.closeDialog();
      this.navigateToDetails(result);
    });
    dialogRef.componentInstance.cancelEvent.subscribe((_) => this.modalService.closeDialog());
  }

  private transformIntoUrlFormat(value: string): string {
    return value.replace(/\s+(.)/g, (_, group) => encodeURIComponent(group.toLowerCase()));
  }

  private navigateToDetails(selectedInstance?: ConnectorInstanceDto) {
    const name = this.transformIntoUrlFormat(this.connector.name);
    const path = `detection/connectors/${name}/detail`;
    this.zone.run(() => {
      this.router.navigateByUrl(path, { state: { connector: this.connector, instance: selectedInstance, organization: this.organization } });
    });
  }
}
