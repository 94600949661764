import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { MultiSelectData } from '../ui-multi-select/multi-select-data';
import { IMobileService } from '../../interfaces/IMobileService';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';

export interface IMultiSelectData {
  option: string;
  isSelected: boolean;
  translated: string;
  isDefaultValue?: boolean;
  translatedShort?: string;
}

@Component({
  selector: 'ui-multi-select-searchbox',
  templateUrl: './ui-multi-select-filter-searchbox.component.html',
  styleUrls: ['./ui-multi-select-filter-searchbox.component.scss'],
})
export class UiMultiSelectSearchbox implements OnChanges {

  @Input() public locale: LanguageEnum = LanguageEnum.FRENCH;

  @Input() public multiSelectData: MultiSelectData;

  @Input() public withIcon = true;

  @Input() public placeholder: string = '';

  @Output() public onOptionSelect: EventEmitter<string[]> = new EventEmitter();


  filteredOptions: IMultiSelectData[] = [];
  optionsForDisplay: IMultiSelectData[] = [];
  filterText: string = '';
  selectedOptions: string[] = [];
  lastCheckedOption: string | null = null;
  showOptions: boolean = false;

  public constructor (
    private readonly ref: ElementRef,
    @Inject("MobileService") public readonly mobileService: IMobileService
  ) {
  }

  @HostListener('document:click', [ '$event' ])
  clickOut(event: any) {

    const exclusionsTargets = event.target.closest('button.ui-table-search-tool--clear, .multi-select-options--option--first');

    if (exclusionsTargets) {
      return;
    }

    if (!this.ref.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  @HostListener('document:keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent) {
    if (this.showOptions) {
      event.stopPropagation();
      this.toggle();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['multiSelectData'] && this.multiSelectData) {
      this.filteredOptions = [...this.multiSelectData.options];
      this.optionsForDisplay = [...this.filteredOptions]; // Initialize the display list
      this.setDefaultOptionCheckedStatus(false); // break default login, we do not want default option checked
    }
  }

  /** Toggle menu options list */
  public toggle(): void {
    this.showOptions = !this.showOptions;
  }

  /** Function to set defaultValue option (checkbox "All") */
  private setDefaultOptionCheckedStatus(status: boolean): void {
    const defaultOption = this.filteredOptions.find(option => option.isDefaultValue);
    if (defaultOption) {
      defaultOption.isSelected = status;
    }
  }

  /** when cross button is clicked on filter text field */
  public handleFilterTextClear(): void {
    this.filterText = '';
    this.filterOptions(); // Reset to show all options
  }

  /** When an checkbox is checked in the list */
  checkOption(option: IMultiSelectData): void {

    if (option.isDefaultValue) { // When clicking on All

      this.toggleOptionsFollowingAllState(option);

    } else { // When clicking any other option...

      const optionToSelect = this.filteredOptions.find((op) => op.option === option.option);

      if(optionToSelect) {
        optionToSelect.isSelected = !option.isSelected;
      }

      this.toggleAllState();
    }

    this.onOptionSelect.emit(this.optionsSelected);

    // Refresh optionsForDisplay to reflect the new selection state
    this.filterOptions();
  }

  /** Catch selected options ids to emit in an array */
  public get optionsSelected(): string[] {
    return this.filteredOptions
      .filter((opt) => !opt.isDefaultValue)
      .filter((opt) => opt.isSelected)
      .map((opt) => opt.option);
  }

  /** Get the translated string of the default option "All", needed in template */
  public get defaultOptionTranslated(): string {
    const option = this.filteredOptions.find((option) => option.isDefaultValue);
    return option ? option.translatedShort || option.translated : '';
  }

  /** Generating a string that contains the names of the selected options, needed in template */
  public get selectedOptionsTranslated(): string {

    const filterCondition = this.isAllOptionsChecked() || this.isNoOptionsChecked()
        ? (option) => option.isDefaultValue
        : (option) => option.isSelected;

    return this.filteredOptions
        .filter(filterCondition)
        .map((option) => option.translatedShort || option.translated)
        .join(', ');
  }

  /** Toggle select All option true/false depending all options are checked or not  */
  private toggleAllState(): void {
    const defaultOption = this.getDefaultOption();
    defaultOption.isSelected = this.isAllOptionsChecked();
  }

  /** Toggle true/false each option, based on the "All" option state passed in param */
  private toggleOptionsFollowingAllState(AllOptionState: IMultiSelectData): void {
    const allOptions = [...this.filteredOptions];
    this.filteredOptions = allOptions.map(option => ({
      ...option,
      isSelected: !AllOptionState.isSelected
    }));
  }

  /** Check if no option checked */
  isNoOptionsChecked(): boolean {
    return this.getCheckedOptions().length === 0;
  }

  /** Check if all options are checked, we exclude the "All" option here */
  isAllOptionsChecked(): boolean {
    return this.getCheckedOptions().length === this.getTotalOptions().length;
  }

  /** Get the default option "All" */
  getDefaultOption(): IMultiSelectData {
    return this.filteredOptions.find(option => option.isDefaultValue);
  }

  /** get all options, including || excluding the default option "All" */
  getTotalOptions(excludeAllOption: boolean = true): IMultiSelectData[] {
    const options = [...this.filteredOptions];

    const filteredOptions = options.filter(option =>
      !excludeAllOption || !option.isDefaultValue
    );

    return filteredOptions;
  }

  /** get checked options */
  getCheckedOptions(): IMultiSelectData[] {
    const options = [...this.filteredOptions];
    return options.filter(option => option.isSelected && !option.isDefaultValue);
  }

  /** Filtering options with input text */
  filterOptions(): void {

    const term = this.filterText.toLowerCase().trim();

    // If there is no search term, show all options
    if (!term) {
      this.optionsForDisplay = [...this.filteredOptions]; // Show all options
      return;
    }

    // Filter options for display based on the translated text (case insensitive)
    this.optionsForDisplay = this.filteredOptions.filter(option =>
      option.translated.toLowerCase().includes(term)
    );
  }

  /** Clear filter selection */
  public clearSelection(): void {

    const allOptions = [...this.filteredOptions];

    this.filteredOptions = allOptions.map(option => ({
      ...option,
      isSelected: false
    }));

    // Refresh optionsForDisplay to reflect the new selection state
    this.filterOptions();
  }

}
