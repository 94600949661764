import { Injectable } from '@angular/core';
import { IObservablesPageContext } from '../components/observable-container/observable-container.component';
import { IObservable } from '@common/services/api/respond/observables/observables.definition';
import { TranslatedObjectPipe } from '@common/modules/i18n/translatedObject.pipe';

@Injectable({
  providedIn: 'root',
})
export class ObservableCellService {

  constructor(
    private readonly translatedObjectPipe: TranslatedObjectPipe
  ) { }

  getFlaggedIncidentTasks(pageContext: IObservablesPageContext, observable: IObservable): string {
    if (pageContext.incident) {
      const flaggedIncidentTasks = pageContext.incident.tasks
        .filter((incidentTask) => incidentTask.observables.includes(observable.id))
        .map((incidentTask) => this.translatedObjectPipe.transform(incidentTask.task.playbookTask.name)).join(', ');
      return flaggedIncidentTasks;
    }

    return '';
  }

  getRedFlaggedStatus(pageContext: IObservablesPageContext, observable: IObservable): boolean {
    return this.getFlaggedIncidentTasks(pageContext, observable).length > 0;
  }
}
