<ui-title
  [name]="(filters.length > 1 ? 'filters.modal.delete.title.multiple' : 'filters.modal.delete.title.single') | t"
  [bottomBorder]="false"
  [largeFont]="true"
></ui-title>

<section
  class="text-content-wrapper"
  [ngClass]="{'multiple':filters.length > 1}"
>
  <ul class="list-filters-to-delete">
    <li *ngFor="let filter of filters">
      {{filter.name}}
    </li>
  </ul>
  <div class="confirmation-message">
    {{ (filters.length > 1 ? 'filters.modal.delete.message.multiple': 'filters.modal.delete.message.single') | t }}
  </div>
</section>

<section class="buttons">
  <button
    ui-button
    color="standard"
    [disabled]="asyncState === asyncStateEnum.SAVING"
    (click)="deleteAssets()"
  >
    <ui-spinner
      *ngIf="asyncState === asyncStateEnum.SAVING"
      size="small"
    ></ui-spinner>
    {{ 'common.action.delete' | t }}
  </button>
  <button
    ui-button
    color="secondary"
    [disabled]="asyncState === asyncStateEnum.SAVING"
    (click)="cancel()"
  >
    {{ 'common.action.cancel' | t }}
  </button>
</section>