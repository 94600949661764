import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { EcoUrlService } from "../../core/url.service";
import { IPeriodicReportListRequest, IPeriodicReportResponse, IPeriodicReportsApi } from "./periodic-reports.definitions";
import { Store } from "@ngxs/store";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { VarPeriodicReportsApi } from "projects/console-adm/src/app/respond/periodic-reports/api/var-periodic-reports.api";
import { CustomerPeriodicReportsApi } from "projects/console-org/src/app/respond/periodic-reports/api/customer-periodic-reports.api";

@Injectable({
  providedIn: 'root',
})
export class PeriodicReportsApi implements IPeriodicReportsApi {
  private customerService: CustomerPeriodicReportsApi;
  private varService: VarPeriodicReportsApi;

  constructor(
    protected store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerPeriodicReportsApi(http, url);
    this.varService = new VarPeriodicReportsApi(http, url);
  }

  public listReports(request: IPeriodicReportListRequest, organizationId: string): Observable<ResponseUtils<IPeriodicReportResponse>> {
    if (this.isVarMode) {
      return this.varService.listReports(request, organizationId);
    }
    return this.customerService.listReports(request, organizationId);
  }

  public getReportDownloadUrl(reportId: string, organizationId?: string): Observable<any> {
    if (this.isVarMode) {
      return this.varService.getReportDownloadUrl(reportId, organizationId);
    }
    return this.customerService.getReportDownloadUrl(reportId, organizationId);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
