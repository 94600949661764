import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { AutocompleteCustomValue } from '@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component';
import { Time } from '@ui-kit/components/ui-time-picker/ui-time-picker.component';
import { TimeUtil } from '@ui-kit/services/time-util';
import { BusinessHours } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { clone } from 'projects/@common/utils/utils';

@Component({
  selector: 'communication-business-hours',
  templateUrl: './communication-business-hours.component.html',
  styleUrls: [ './communication-business-hours.component.scss' ],
})
export class CommunicationBusinessHoursComponent {
  @Input() businessHours: BusinessHours;
  @Input() hasEditPermission: boolean;

  @Output() saveEvent = new EventEmitter<BusinessHours>();

  public prevBusinessHours: BusinessHours;
  public isEditing = false;
  public customAutocompleteType = AutocompleteTypes.CUSTOM;
  public timezoneOptions: AutocompleteCustomValue[] = [];

  public get formattedFrom(): string {
    return this.formatTime({ hour: this.businessHours.fromHour, minute: this.businessHours.fromMinute });
  }

  public get formattedTo(): string {
    return this.formatTime({ hour: this.businessHours.toHour, minute: this.businessHours.toMinute });
  }

  public get isValidTimeRange(): boolean {
    const validHours = this.businessHours.fromHour >= 0 && this.businessHours.toHour > 0 && this.businessHours.fromHour < this.businessHours.toHour;
    const validMinutes = this.businessHours.fromMinute >= 0 && this.businessHours.toMinute >= 0;
    return validHours && validMinutes;
  }

  public get isValidTimezone(): boolean {
    return !!this.businessHours.timezone;
  }

  public toggleEdit(value: boolean) {
    this.isEditing = value;
    if (this.isEditing) {
      this.setPreviousValues();
    }
  }

  public save() {
    this.saveEvent.emit(this.businessHours);
  }

  public cancel() {
    this.resetPreviousValues();
  }

  public handleTimeChanged(time: Time, type: "from" | "to") {
    if (type === "from") {
      this.businessHours.fromHour = time.hour;
      this.businessHours.fromMinute = time.minute;
    } else if (type === "to") {
      this.businessHours.toHour = time.hour;
      this.businessHours.toMinute = time.minute;
    }
  }

  public handleTimezoneSelection(timezone: AutocompleteCustomValue) {
    this.businessHours.timezone = timezone ? timezone.value : null;
  }

  private formatTime(time: Time): string {
    return `${TimeUtil.padZero(time.hour)}:${TimeUtil.padZero(time.minute)}`;
  }

  private setPreviousValues() {
    this.prevBusinessHours = clone(this.businessHours);
  }

  private resetPreviousValues() {
    this.businessHours = clone(this.prevBusinessHours);
  }
}
