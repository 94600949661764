import { ChangeDetectorRef } from "@angular/core";
import { UsecaseIndicatorsComponent } from "../components/usecase-indicators/usecase-indicators.component";
import { MultiSelectDataFactory } from "projects/@common/modules/i18n/component-wrapper/multi-select-data.factory";
import { StaticTableDataMapper } from "projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { TranslatedObjectPipe } from "projects/@common/modules/i18n/translatedObject.pipe";
import { DrawerService } from "projects/@common/modules/layout/components/drawer/services/drawer.service";
import { NoticeService, Notice, NoticeLevels } from "projects/@common/modules/notice/notice.service";
import { UsecasesApiService } from "projects/@common/services/api/respond/usecase/usecase.api";
import { AlertingUsecaseListItem, ListDirectionEnum, UsecaseOrderByEnum } from "projects/@common/services/api/respond/usecase/usecase.definition";
import { DialogActions } from "@ui-kit/components/ui-dialog/ui-dialog.component";
import { MultiSelectData } from "@ui-kit/components/ui-multi-select/multi-select-data";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { UiStaticTable } from "@ui-kit/components/ui-static-table/ui-static-table.component";
import { UiTableDirection } from "@ui-kit/components/ui-table/ui-table.component";
import { LanguageEnum } from "@ui-kit/interfaces/ILanguage";

export interface DatasourceOptions {
  from: number;
  size: number;
  searchName: string;
  isLoadMore: boolean;
  alertFrom: number;
  alertTo: number;
}

export enum DatasourceFilters {
  CLOUDTRAIL = 'Cloudtrail',
  FORTINET = 'Fortinet'
}

export enum UsecaseIndicatorsEnum {
  WHITELIST = "WHITELIST",
  FILTER = "FILTER",
  BLACKLISTED = "BLACKLISTED",
  SILENCED = "SILENCED"
};

export class UsecasesTableHandler {
  private readonly USECASES_PER_PAGE = 50;

  public indicators: UsecaseIndicatorsEnum[] = [];

  public total: number;

  public nextOffset = 0;

  public isTableDataLoading = false;

  public tableDatasource: Array<AlertingUsecaseListItem> = [];

  public currentUsecaseSelected: any;

  public deleteUsecaseMessage: string;

  public showDeleteDialog = false;

  public isUsecaseLoading = false;

  public fullText = '';

  public alertFrom: number;

  public alertTo: number;

  private stillProcessingLastOffset = false;

  public organizationGuid: string;

  private selectedTag = '__Selected';

  public sort: string = "catalogId";

  public direction: UiTableDirection = UiTableDirection.Asc;

  public readonly indicatorValues: MultiSelectData;

  private userLanguage: LanguageEnum;

  public USECASES_COLUMN_DEF = [
    {
      label: '',
      field: 'selected',
      selected: this.selectedTag,
      type: UiStaticTableRowType.CHECKBOX,
      width: '50px',
      isResizable: false,
    },
    {
      label: 'org_usecase.table.column.name',
      field: 'name',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      width: '300px',
    },
    {
      label: 'org_usecase.table.column.silenced',
      field: 'silenced',
      type: UiStaticTableRowType.ENUM_WITH_ICON,
      enumPrefix: 'org_usecase.table.column.silenced.',
      iconName: this.getModeIcon,
      customIcon: true,
      isSvg: true,
      width: '120px',
      iconSide: 'left',
    },
    {
      label: 'org_usecase.table.column.catalog_id',
      field: 'catalogId',
      width: '150px',
      type: UiStaticTableRowType.TEXT,
    },
    {
      label: 'org_usecase.table.column.datasourceType',
      field: 'datasourceType',
      type: UiStaticTableRowType.TEXT,
      width: '150px',
    },
    {
      label: 'org_usecase.table.column.indicators',
      field: 'indicators',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: UsecaseIndicatorsComponent,
      paramName: 'usecase',
      width: '115px',
      minWidth: '115px',
      isResizable: true,
      noSorting: true,
    },
    {
      label: 'org_usecase.table.column.alertCount',
      field: 'alertCount',
      type: UiStaticTableRowType.TEXT,
      width: '150px',
    },
    {
      label: 'org_usecase.table.column.activated_at',
      field: 'createdAt',
      type: UiStaticTableRowType.DATE_AND_HOUR,
      width: '200px',
    },
    {
      label: 'org_usecase.table.column.updated_at',
      field: 'updatedAt',
      type: UiStaticTableRowType.DATE_AND_HOUR,
      width: '200px',
    },
  ];

  constructor (
    protected readonly i18nService: I18nService,
    protected readonly usecasesApiService: UsecasesApiService,
    protected readonly noticeService: NoticeService,
    protected readonly translatedObjectPipe: TranslatedObjectPipe,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly drawerService: DrawerService,
    protected staticTableDataMapper: StaticTableDataMapper,
    multiSelectFactory: MultiSelectDataFactory
  ) {
    this.indicatorValues = multiSelectFactory.create(UsecaseIndicatorsEnum, [], 'org_usecase.table.filter.indicators.', false, true);
    this.userLanguage = this.i18nService?.currentLocale === "en" ? LanguageEnum.ENGLISH : LanguageEnum.FRENCH;
  }

  public get canLoadMore(): boolean {
    return this.nextOffset < this.total;
  }


  public applyIndicatorFilter(event: UsecaseIndicatorsEnum[]): void {
    if (event === null || event.length === 0) {
      this.indicators = [];
      this.handleRefresh();
    } else {
      this.indicators = event;
      this.handleRefresh();
    }
  }

  public handleSearchQuery(fullText: string): void {
    this.fullText = fullText;
    this.handleRefresh();
  }

  public handleSearchClear(): void {
    this.fullText = '';
    this.handleRefresh();
  }

  public loadMore(): void {
    if (this.nextOffset < this.total && !this.stillProcessingLastOffset) {
      this.stillProcessingLastOffset = true;
      this.loadUsecases();
    }
  }

  public openDeleteDialog(): void {
    this.deleteUsecaseMessage = this.i18nService.translate('org_usecase.table.action.delete.dialog.message', {
      id: this.currentUsecaseSelected.id,
    });
    this.showDeleteDialog = true;
  }

  public handleSelected(selected: any): void {
    if (selected.event) {
      this.currentUsecaseSelected = selected.item;
    } else {
      this.currentUsecaseSelected = null;
    }
  }

  public loadUsecases(nested = false): void {
    if ((!nested && this.isTableDataLoading) || !this.organizationGuid) {
      return null;
    }
    this.isTableDataLoading = true;
    this.usecasesApiService
      .getUsecaseList(this.organizationGuid, {
        from: this.nextOffset,
        size: this.USECASES_PER_PAGE,
        searchName: this.fullText,
        alertFrom: this.alertFrom,
        alertTo: this.alertTo,
        indicators: this.indicators,
        orderBy: this.fullText ? UsecaseOrderByEnum.name : UsecaseOrderByEnum.catalogId,
        direction: ListDirectionEnum.asc,
        userLanguage: this.userLanguage,
      })
      .then((response) => {
        this.tableDatasource = this.tableDatasource.concat(response.items);
        this.nextOffset = response.nextItem;
        this.total = response.total;
      })
      .finally(() => {
        // the entire dataset will be loaded to fix an issue occuring with the sort filters.
        // this will happen asynchronously using the existing paginated mechanism
        if (this.total > this.nextOffset) {
          this.isTableDataLoading = true;
          this.stillProcessingLastOffset = true;
          setTimeout(() => {
            this.loadUsecases(true);
          });
        } else {
          this.isTableDataLoading = false;
          this.stillProcessingLastOffset = false;
          [ this.USECASES_COLUMN_DEF, this.tableDatasource ] = this.staticTableDataMapper.translate(this.USECASES_COLUMN_DEF, this.tableDatasource);
        }
      });
  }

  public deleteUsecase(event: DialogActions): void {
    if (event === DialogActions.ACTION) {
      this.isUsecaseLoading = true;
      this.usecasesApiService
        .deleteUsecase(this.organizationGuid, this.currentUsecaseSelected.id)
        .then(() => {
          this.noticeService.notifyUser(new Notice(`org_usecase.table.action.delete.success`, NoticeLevels.SUCCESS));
        })
        .catch(() => {
          this.noticeService.notifyUser(new Notice(`org_usecase.table.action.delete.error`, NoticeLevels.ERROR));
        })
        .finally(() => {
          this.isUsecaseLoading = false;
          this.drawerService.hideDrawer();
          this.showDeleteDialog = false;
          this.handleRefresh();
        });
    } else {
      this.showDeleteDialog = false;
    }
  }

  public handleRefresh(): void {
    this.tableDatasource = [];
    this.nextOffset = 0;
    this.total = 0;
    this.loadUsecases();
  }

  public getModeIcon(usecase: AlertingUsecaseListItem): string {
    return usecase.silenced ? 'favicons/icon_silenced' : 'favicons/icon_enabled';
  }

  public handleSorting(sort: string, direction: UiTableDirection, parent: string): void {
    if (sort === 'name') {
      // special case : sort on the translated value
      this.tableDatasource = [].concat(this.tableDatasource.sort((obj1, obj2) => {
        const str1 = this.translatedObjectPipe.transform(obj1['name']);
        const str2 = this.translatedObjectPipe.transform(obj2['name']);

        if (direction === UiTableDirection.Asc) {
          return str1.localeCompare(str2);
        }
        return str2.localeCompare(str1);
      }));
    } else {
      // apply ui-static-table's default sort function for other fields
      this.tableDatasource = [].concat(this.tableDatasource.sort((obj1, obj2) => UiStaticTable.genericSortFunction(sort, parent, direction, obj1, obj2, this.i18nService.currentLocale)));
    }

    this.sort = sort;
    this.direction = direction;
    this.cdr.detectChanges();
  }
}
