import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from '@ui-kit/components/confirmation-modal/confirmation-modal.component';
import { ModalService } from '@ui-kit/services/modal.service';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { IIncident } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { SimilarityItemTypeEnum, SimilarityPageContext, SimilarityTableItem } from '../../similarity.definitions';
import { SimilarityEventsService } from '../../similarity.events.service';

@Component({
  selector: 'similarity-actions',
  templateUrl: './similarity-actions.component.html',
  styleUrls: [ './similarity-actions.component.scss' ],
})
export class SimilarityActionsComponent implements OnInit {
  @Input() similarityItem: SimilarityTableItem;
  @Input() pageContext: SimilarityPageContext;

  public isProcessing: boolean = false;
  public hasPermissionToUpdateIncident: boolean = false;

  constructor(
    private readonly similarityEventsService: SimilarityEventsService,
    private readonly incidentApi: IncidentsApi,
    private readonly displayService: DisplayService,
    private readonly noticeService: NoticeService,
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly i18n: I18nService
  ) { }

  ngOnInit(): void {
    this.hasPermissionToUpdateIncident = this.displayService.meetsRequirements({ permissions: [ 'can_update_incident' ] });
  }

  public get shouldShowAssociateButton(): boolean {
    return this.hasPermissionToUpdateIncident && this.similarityItem.canAssociate;
  }

  public get isAlertItem(): boolean {
    return this.similarityItem.type === SimilarityItemTypeEnum.ALERTS;
  }

  public get isIncidentItem(): boolean {
    return this.similarityItem.type === SimilarityItemTypeEnum.INCIDENTS;
  }

  public get isAlertPageContext(): boolean {
    return this.pageContext.type === SimilarityItemTypeEnum.ALERTS;
  }

  public get isIncidentPageContext(): boolean {
    return this.pageContext.type === SimilarityItemTypeEnum.INCIDENTS;
  }

  public async handleAssociateButtonClick(): Promise<void> {
    const shouldContinue = !this.isProcessing && await this.promptUserAssociate();
    if (shouldContinue) {
      this.associateAlertToIncident();
    }
  }

  private async associateAlertToIncident(): Promise<void> {
    this.isProcessing = true;
    const request = {
      organizationId: this.similarityItem.organizationId,
      organizationEcoId: this.similarityItem.organizationEcoId,
      alertIds: this.isAlertPageContext ? [ this.pageContext.alert.id ] : [ this.similarityItem.id ],
      incidentId: this.isIncidentPageContext ? this.pageContext.incident.id : this.similarityItem.id,
      comment: null,
    };
    this.incidentApi.associateAlerts(request.organizationId, request.incidentId, request.alertIds, request.comment).then((response: IIncident) => {
      this.handleAssociatedSuccess(response, request.organizationEcoId);
    }).catch(() => {
      this.isProcessing = false;
      this.noticeService.error("alerts.container.page.notice.incident.associate.error", { alertsCount: 1 });
    });
  }

  private async handleAssociatedSuccess(incident: IIncident, organizationEcoId: string): Promise<void> {
    this.isProcessing = false;
    this.noticeService.success("alerts.container.page.notice.incident.associate.created.text", { alertsCount: 1, id: incident.shortId });
    if (this.isAlertPageContext) {
      const shouldRedirectToIncident = await this.promptUserRedirectToIncident(incident.shortId);
      if (shouldRedirectToIncident) {
        this.router.navigate([ "respond", organizationEcoId, "incidents", incident.id, "details" ], { queryParams: { tab: 'alerts' } });
        return;
      }
      this.similarityEventsService.emitOnAssociateSuccessEvent({ alert: this.pageContext.alert, incident: new Incident(incident) });
    } else if (this.isIncidentPageContext) {
      this.similarityEventsService.emitOnAssociateSuccessEvent({ incident: this.pageContext.incident });
    }
  }

  private async promptUserAssociate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18n.translate('alerts.container.page.modal.associate.title', { alertsCount: 1 }),
        text: this.i18n.translate(`respond.similarity.${this.similarityItem.type}.promptAssociate.text`, {
          shortId: this.similarityItem.shortId,
        }),
        confirmationText: this.i18n.translate('common.boolean.true'),
        cancelText: this.i18n.translate('common.boolean.false'),
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
  }

  private async promptUserRedirectToIncident(id: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18n.translate('alerts.container.page.modal.incident.promptRedirect.title'),
        text: this.i18n.translate('alerts.container.page.modal.incident.promptRedirect.text', { id }),
        confirmationText: this.i18n.translate('alerts.container.page.modal.incident.promptRedirect.confirmationText'),
        cancelText: this.i18n.translate('alerts.container.page.modal.incident.promptRedirect.cancelText'),
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
  }
}
