<!-- Playbook versions (old version left and new version right) -->
<section
  *ngFor="let playbookVersion of playbookVersions"
  class="playbook-version"
  [id]="playbookVersion.key"
>
  <!-- Playbook Version Header -->
  <header class="playbook-version--header">
    <h2
      class="playbook-version--title"
      [tooltip]="playbookVersion.tooltip | t"
    >
      {{ playbookVersion.title | t }}&nbsp;{{playbookVersion.version}}
    </h2>
    <span class="playbook-version--updatedAt">
      {{ 'detection.playbook.update.header.updatedAt' | t }}
      {{ playbookVersion.updatedAt | dateAgo: false | lowercase }}
    </span>
  </header>

  <!-- Phases (as drag-n-drop regions) -->
  <div
    *ngFor="let phaseDropRegion of playbookVersion.phaseDropRegions;"
    class="phase card"
    [ngClass]="{
      'is-expanded': phaseDropRegion.isExpanded
    }"
    [id]="phaseDropRegion.key"
    [attr.name]="phaseDropRegion.name"
    cdkDropList
    [cdkDropListConnectedTo]="['NEW_PLAYBOOK_' + phaseDropRegion.name]"
    [cdkDropListData]="phaseDropRegion.tasks"
    [cdkDropListSortingDisabled]="playbookVersion.dragParams.cdkDropListSortingDisabled"
    (cdkDropListDropped)="playbookVersion.dragParams.dropHandler($event)"
  >
    <!-- Phase header -->
    <header
      class="phase--header"
      (click)="togglePhases(phaseDropRegion)"
    >
      <ui-icon
        class="phase--chevron"
        modifier="on-header"
        [name]="phaseDropRegion.isExpanded ? 'chevron-down' : 'chevron-right'"
        size="medium"
      ></ui-icon>
      <strong class="phase--title">
        {{ phaseDropRegion.title | t | titlecase }}&nbsp;({{phaseDropRegion.tasks.length}})
      </strong>
      <div class="phase--status-icons">
        <ui-icon
          class="phase--deleted-icon"
          *ngIf="playbookVersion.key === 'NEW_PLAYBOOK' && hasDeletedTasksInPhase(phaseDropRegion.name)"
          modifier="gray"
          [name]="'error'"
          size="medium"
          [tooltip]="'detection.playbook.update.phase.deletedTasks.tooltip' | t"
        ></ui-icon>
        <ui-icon
          class="phase--added-icon"
          *ngIf="playbookVersion.key === 'NEW_PLAYBOOK' && hasNewTasksInPhase(phaseDropRegion.name)"
          modifier="gray"
          [name]="'error'"
          size="medium"
          [tooltip]="'detection.playbook.update.phase.addedTasks.tooltip' | t"
        ></ui-icon>
        <ui-icon
          class="phase--error-icon"
          *ngIf="playbookVersion.key === 'NEW_PLAYBOOK' && !isPhaseComplete(phaseDropRegion.name)"
          modifier="error"
          [name]="'error'"
          size="medium"
          [tooltip]="'detection.playbook.update.phase.incomplete.tooltip' | t"
        ></ui-icon>
      </div>
    </header>

    <!-- Tasks in this phase (only visible when phase is expanded) -->
    <div class="phase--tasks-container">
      <div
        *ngFor="let task of phaseDropRegion.tasks"
        class="task card"
        [ngClass]="{
          'is-expanded': task.isExpanded,
          'is-disabled': !task.isDragEnabled,
          'is-ignored': task.ignored,
          'is-hidden': task.hidden,
          'is-deleted': task.wasDeletedFromTemplate,
          'is-new': task.isNewTaskInTemplate
        }"
        cdkDrag
        [cdkDragData]="task"
        [cdkDragLockAxis]="playbookVersion.dragParams.cdkDragLockAxis"
        [cdkDragDisabled]="!task.isDragEnabled"
      >
        <!-- Task Header -->
        <header
          class="task--header"
          (click)="toggleTasks(task)"
        >
          <ui-icon
            class="task--drag-handle-icon"
            modifier="DashboardWidget"
            [name]="task.isDragEnabled ? 'dragdots' : 'chain'"
            size="medium"
          ></ui-icon>
          <img
            class="task--origin-icon"
            [src]="task.origin === 'LIBRARY' ? 'assets/favicons/icon_playbook_task_library.svg': 'assets/favicons/icon_playbook_task_custom.svg'"
            [tooltip]="(task.origin === 'LIBRARY' ? 'detection.playbook.update.task.isMdTask.tooltip' :'detection.playbook.update.task.isCustom.tooltip') | t"
          />
          <span class="task--name">
            {{task.name | translatedObject}}
          </span>
          <div class="task--status-icons">
            <ui-icon
              class="task--ignored-icon"
              *ngIf="task.ignored"
              modifier="on-header"
              [name]="'cancel'"
              size="small"
              [tooltip]="'detection.playbook.task.tooltip.actions.ignored' | t"
            ></ui-icon>
            <ui-icon
              class="task--hidden-icon"
              *ngIf="task.hidden"
              modifier="on-header"
              [name]="'hidden'"
              size="small"
              [tooltip]="'detection.playbook.task.tooltip.actions.hidden' | t"
            ></ui-icon>
            <span
              class="task--badges task--is-new-badge"
              *ngIf="task.isNewTaskInTemplate"
              [tooltip]="'detection.playbook.update.task.isNew.tooltip' | t"
            >
              {{'detection.playbook.update.task.isNew' | t}}
            </span>
            <span
              class="task--badges task--is-deleted-badge"
              *ngIf="task.wasDeletedFromTemplate"
              [tooltip]="'detection.playbook.update.task.isDeleted.tooltip' | t"
            >
              {{'detection.playbook.update.task.isDeleted' | t}}
            </span>
          </div>
          <ui-icon
            class="task--chevron"
            modifier="on-header"
            [name]="task.isExpanded ? 'chevron-down' : 'chevron-right'"
            size="medium"
          ></ui-icon>
        </header>

        <!-- Task content (only visible when task is expanded) -->
        <div class="task--infos">
          <div class="task--description">
            <img
              src="assets/favicons/icon_playbook_task_description.svg"
              alt="icon_playbook_task_description"
            />
            <p>
              {{task.description | translatedObject}}
            </p>
          </div>
          <div class="task--instruction">
            <img
              src="assets/favicons/icon_playbook_task_instruction.svg"
              alt="icon_playbook_task_instruction"
            />
            <p>
              {{task.instruction | translatedObject}}
            </p>
          </div>
        </div>

        <!-- Placeholder for the drop zone when dragging a task -->
        <div
          *cdkDragPlaceholder
          class="task--cdk-custom-drag-drop-placeholder"
        ></div>

      </div>
    </div>

  </div>

</section>