<div *ngIf="canEdit" class="buttons">
  <ng-container *ngIf="!isEditing">
    <button
      class="surrounding"
      (click)="toggleEdit(); $event.stopPropagation()"
    >
      <img src="assets/favicons/icon_modify_disabled.svg">
    </button>
  </ng-container>
  <ng-container *ngIf="isEditing">
    <button
      [ngClass]="{'disabled': !canSave}"
      [disabled]="!canSave"
      class="surrounding pale-green"
      (click)="save(); $event.stopPropagation()"
    >
      <img
        [ngStyle]="{'height': '24px'}"
        [src]="icon"
      >
    </button>
    <button
      class="surrounding pale-red"
      (click)="cancel(); $event.stopPropagation()"
    >
      <img src="assets/favicons/icon_stop_sharing.svg">
    </button>
  </ng-container>
</div>
