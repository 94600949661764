import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { AutocompleteCustomValue } from '@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component';
import { TextareaTypeEnum } from '@ui-kit/components/ui-json-textarea/ui-json-textarea.component';
import { Eco } from 'projects/@common/definitions/eco';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { MonitorParameterTypes } from 'projects/@common/definitions/monitorParameterType.enum';
import { Accumulatables, Accumulate } from 'projects/@common/modules/accumulator/accumulator.store';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { PlaybooksApiService } from 'projects/@common/services/api/respond/playbooks/playbooks.api';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { IMonitor, IUsecase, IUsecasePipeline } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { JsonUtils } from 'projects/@common/utils/json-utils';
import { UiTypeOfFormEnum } from 'projects/console-adm/src/app/shared/dynamic-form-template/dynamic-form-template.component';
import { OrganizationListService } from '../../services/organizationList.service';
import { UsecaseForm } from './usecase-form';

export interface IMonitorToDisplay {
  name: string;
  silenced: boolean;
  description: ITranslatedField;
  blacklisted: boolean;
  parameters: {
    label: ITranslatedField;
    description: ITranslatedField;
    required: boolean;
    typeOfForm: UiTypeOfFormEnum;
    controlName: string;
    group: AbstractControl;
  }[];
}

@Component({
  selector: 'usecase-creation-detail',
  templateUrl: './usecase-creation-detail.component.html',
  styleUrls: [ './usecase-creation-detail.component.scss' ],
})
export class UsecaseCreationDetail extends UsecaseForm implements OnInit {
  public customAutocomplete = AutocompleteTypes.CUSTOM;

  public organization: UntypedFormGroup = new UntypedFormGroup({ organization: new UntypedFormControl('') });

  public selectedUsecaseTemplate: Array<AutocompleteCustomValue> = [];

  public autocompleteUsecaseTemplateValues: AutocompleteCustomValue[] = [];

  public textareaTypeEnum = TextareaTypeEnum;

  public catalogList: Array<IUsecase>;

  public currentTemplate: IUsecase;

  public currentMonitors: { [key: string]: Array<IMonitor> };

  public monitorList = [];

  public noMonitorAvailable = false;

  public noTriggerConfigAvailable = false;

  public monitorToDisplay: IMonitor[];

  public selectedPlaybook: Array<AutocompleteCustomValue> = [];

  public autocompletePlaybookValues: AutocompleteCustomValue[] = [];

  public priorityOptions = [];

  public organizationId: string;

  public locale: Eco.Languages;

  public aggregationRuleConfig = new UntypedFormGroup({ config: new UntypedFormControl('') });

  @Input() public data: { title: string; organizationId: string; defaultOrganizationName: string, organizationEcoId: string };

  constructor(
    private readonly drawerService: DrawerService,
    private readonly i18n: I18nService,
    private readonly usecasesApiService: UsecasesApiService,
    private readonly playbooksApiService: PlaybooksApiService,
    public readonly organizationListService: OrganizationListService,
    private readonly store: Store,
    protected readonly formBuilder: UntypedFormBuilder,
    private noticeService: NoticeService,
    private translatedObject: TranslatedObjectPipe,
    private router: Router
  ) {
    super(formBuilder);
    this.locale = this.i18n.currentLocale as Eco.Languages;
  }

  ngOnInit(): void {
    this.loadUsecaseCatalogs();
    this.organization.get('organization').setValue(this.data.defaultOrganizationName);
    this.organizationId = this.data.organizationId;
  }

  public get organizationNames(): string[] {
    return [ this.data.defaultOrganizationName ];
  }

  public get currentUsecaseConfig(): IUsecasePipeline {
    return this.currentTemplate?.config?.find((config) => config.datasourceType === this.usecaseDatasource.value);
  }

  public get templateOptionsList(): Array<{ value: string; label: string }> {
    return this.catalogList?.map((template) => ({
      value: template.id,
      label: template.usecaseId,
    }));
  }

  public addTriggerConfig(): void {
    this.resetMonitors();

    if (this.currentUsecaseConfig) {
      this.triggerConfig
        .get('config')
        .setValue(JsonUtils.prettifyJson(JSON.stringify(this.currentUsecaseConfig.trigger)));
      this.aggregationRuleConfig
        .get('config')
        .setValue(JsonUtils.prettifyJson(JSON.stringify(this.currentUsecaseConfig.aggregationRule)));
    } else {
      this.noTriggerConfigAvailable = true;
    }

    if (this.currentMonitors && this.currentMonitors[this.usecaseDatasource.value]) {
      this.monitorToDisplay = this.currentMonitors[this.usecaseDatasource.value];
      this.buildMonitorForm();
    } else {
      this.noMonitorAvailable = true;
    }
  }

  public onValueSelection(event: { value: string; label: string }): void {
    this.resetAll();

    if (event) {
      this.currentTemplate = this.catalogList.find((template) => template.id === event.value);
      this.formGroup.get('template').setValue(event.value);
    }

    if (this.currentTemplate) {
      this.fetchUsecaseCatalog();
      this.fetchPlaybooks();
      this.getPriority();
    }
  }

  public loadUsecaseCatalogs(): void {
    this.usecasesApiService
      .getUsecasesCatalog({
        datasourceType: null,
        orderColumn: 'usecaseId',
        order: 'asc',
        size: 3000,
      })
      .then((usecasesResponse) => {
        this.catalogList = usecasesResponse.items.map((catalog) => catalog.template);
        this.autocompleteUsecaseTemplateValues = usecasesResponse.items.map((catalog) => ({
          displayValue: `${catalog.template.usecaseId}
            (${catalog.template.name[this.i18n.currentLocale || 'en'] || catalog.template.name['en']})`,
          value: catalog.template.id,
        }));
      })
      .catch(() => this.noticeService.notifyUser(new Notice('common.error.get.500.message', NoticeLevels.ERROR)));
  }

  public fetchUsecaseCatalog(): void {
    this.usecasesApiService
      .getUsecaseCatalog(this.currentTemplate.id)
      .then((response) => {
        this.currentMonitors = response.monitors;
      })
      .catch(() => this.noticeService.notifyUser(new Notice('common.error.get.500.message', NoticeLevels.ERROR)));
  }

  public fetchPlaybooks(): void {
    if (this.currentTemplate.playbookTemplateIds) {
      this.playbooksApiService
        .getPlaybooks(this.organizationId, { templateIds: this.currentTemplate.playbookTemplateIds })
        .then((response) => {
          this.autocompletePlaybookValues = response.items.map((playbook) => ({
            displayValue: `${this.translatedObject.transform(playbook.playbook.name)} - ${playbook.playbook.suffix}`,
            value: playbook.playbook.id,
          }));
        })
        .catch(() => this.noticeService.notifyUser(new Notice('common.error.get.500.message', NoticeLevels.ERROR)));
    } else {
      this.autocompletePlaybookValues = [];
    }
  }

  private getPriority(): void {
    this.formGroup.get('priority').setValue(`${this.i18n.translate("org_usecase.description_drawer.default")} (${this.currentTemplate.priority})`);
    this.priorityOptions = [ `${this.i18n.translate("org_usecase.description_drawer.default")} (${this.currentTemplate.priority})`, 1, 2, 3, 4 ];
  }


  public playbookSelection(event: { value: string; label: string }): void {
    if (event) {
      this.formGroup.get('playbookId').setValue(event.value);
    }
  }

  public save(redirectToPlaybook: boolean = false): void {
    let newUsecaseId;
    this.usecasesApiService
      .createUsecase(this.organizationId, this.usecaseRequestBody)
      .then((result) => {
        this.noticeService.notifyUser(new Notice('org_usecase.description_drawer.tab.monitors.updated_success', NoticeLevels.SUCCESS));
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
        this.drawerService.previousDrawer();
        newUsecaseId = result.id;
      })
      .catch((error) => {
        const message = Array.isArray(error.error.message)
          ? `${error.error.error}: ${error.error.message[0]}`
          : error.error.message;
        this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR, {}, `Trace = ${error.trace}`));
        redirectToPlaybook = false;
      })
      .finally(() => {
        if (redirectToPlaybook) {
          this.router.navigateByUrl(`respond/${this.data.organizationEcoId}/playbooks/creation?playbookTemplateId=${this.currentTemplate.playbookTemplateIds[0]}&usecaseId=${newUsecaseId}`);
        }
      });
  }

  public cancel(): void {
    this.drawerService.previousDrawer();
  }

  private buildMonitorForm(): void {
    this.monitorToDisplay.forEach((monitor, index) => {
      this.parametersFormArray.push(this.getMonitorGroup(monitor));
      this.monitorList.push(this.monitorFormMapper(monitor, index));
    });
  }

  private monitorFormMapper(monitor: IMonitor, i: number): IMonitorToDisplay {
    return {
      name: monitor.name,
      silenced: false,
      description: monitor.description,
      blacklisted: false,
      parameters: monitor?.parameters?.map((parameter, j) => {
        const paramFromControl = (this.parametersFormArray.at(i).get('parameters') as UntypedFormArray).at(j);

        if (parameter.default) {
          const valueToMap: any = parameter?.type === MonitorParameterTypes.OBJECT ?
            JSON.stringify(parameter.default) : parameter.default;
          paramFromControl.get('value').setValue(valueToMap);
        }

        return {
          label: { fr: parameter.name, en: parameter.name },
          description: parameter.description,
          required: !parameter.default,
          typeOfForm: this.setTypeOfForm(parameter.type),
          controlName: 'value',
          group: paramFromControl,
        };
      }),
    };
  }

  private setTypeOfForm(type: string): UiTypeOfFormEnum {
    switch (type) {
      case MonitorParameterTypes.OBJECT:
        return UiTypeOfFormEnum.OBJECT_TEXTAREA;
      case MonitorParameterTypes.STRING:
        return UiTypeOfFormEnum.TEXT_INPUT;
      case MonitorParameterTypes.NUMBER:
        return UiTypeOfFormEnum.NUMBER_INPUT;
      default:
        return null;
    }
  }

  private resetMonitors(): void {
    this.monitorToDisplay = null;
    this.monitorList = [];
    this.noMonitorAvailable = false;
    this.noTriggerConfigAvailable = false;
  }

  private resetAll(): void {
    this.currentTemplate = null;
    this.monitorToDisplay = null;
    this.monitorList = [];
    this.initForm();
  }
}
