<div>
  <settings-edition-card
    [icon]="'icon_users_widget_green_nobackground_cropped.svg'"
    [title]="'settings.resources.guest.resources.title' | t "
    [canSave]="true"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <div class="info">
      <img
        class="info-img"
        src="assets/favicons/info-icon.png"
      >
      <p
        class="hint-text"
        [innerHtml]="'settings.resources.guest.resources.description.warning' | t"
      ></p>
    </div>
    <div class="labels">
      <p [innerHTML]="'settings.resources.guest.resources.description' | t "></p>
    </div>
    <div class="labels">
      <p [innerHTML]="'settings.resources.guest.resources.state' | t:{state: externalUserCollaborationState}"></p>
    </div>
  </div>
</ng-template>

<ng-template #editing>
  <div>
    <div class="info">
      <img
        class="info-img"
        src="assets/favicons/info-icon.png"
      >
      <p
        class="hint-text"
        [innerHtml]="'settings.resources.guest.resources.description.warning' | t"
      ></p>
    </div>
    <div class="labels">
      <p [innerHTML]="'settings.resources.guest.resources.description' | t "></p>
    </div>
  </div>
  <div
    class="form selector"
    ui-form
    *ngIf="!isLoading; else loading"
  >
    <div class="labels default">
      <p [innerHTML]="'settings.resources.guest.resources.description.default' | t"></p>
    </div>
    <ui-toggle
      [toggled]="externalUserCollaborationState"
      [labelEnabled]="'settings.notification.silencing.toggle.activated' | t"
      [labelDisabled]="'settings.notification.silencing.toggle.deactivated' | t"
      (onClick)="toggleSilencingActivation()"
    ></ui-toggle>
  </div>
</ng-template>
