
export enum TimestampSources {
  SYSTEM = "SYSTEM",
  USER = "USER"
}

export enum SystemTimestampTypes {
  INCIDENT_FIRST_ALERT = "INCIDENT_FIRST_ALERT",
  INCIDENT_CREATED = "INCIDENT_CREATED",
  INCIDENT_STARTED = "INCIDENT_STARTED",
  INCIDENT_CONTAINED = "INCIDENT_CONTAINED",
  INCIDENT_REMEDIATED = "INCIDENT_REMEDIATED",
}

export enum UserTimestampTypes {
  CLIENT_NOTIFIED_AT = "CLIENT_NOTIFIED_AT",
  ESCALATION_LEVEL_2 = "ESCALATION_LEVEL_2",
  ESCALATION_LEVEL_3 = "ESCALATION_LEVEL_3",
  ESCALATION_LEVEL_4 = "ESCALATION_LEVEL_4",
}

export enum IncidentTimestampType {
  CONVERSATION_LAST_UPDATE = 'CONVERSATION_LAST_UPDATE',
  CONVERSATION_SOC_LAST_UPDATED_AT = 'CONVERSATION_SOC_LAST_UPDATED_AT',
  CONVERSATION_CLIENT_LAST_UPDATED_AT = 'CONVERSATION_CLIENT_LAST_UPDATED_AT',
  TASKS_LAST_UPDATE = 'TASKS_LAST_UPDATE',
  CONVERSATION_SOC_LAST_ACCESSED_AT = 'CONVERSATION_SOC_LAST_ACCESSED_AT',
  TASKS_SOC_LAST_ACCESSED_AT = 'TASKS_SOC_LAST_ACCESSED_AT',
  CONVERSATION_CLIENT_LAST_ACCESSED_AT = 'CONVERSATION_CLIENT_LAST_ACCESSED_AT',
  TASKS_CLIENT_LAST_ACCESSED_AT = 'TASKS_CLIENT_LAST_ACCESSED_AT',
}

export type TimestampTypes = SystemTimestampTypes | UserTimestampTypes | IncidentTimestampType;

export interface IChronologyTimestamp {
  id?: string;
  incidentId?: string;
  timestamp: number;
  editable: boolean;
  deletable: boolean;
  type: TimestampTypes;
  source: TimestampSources;
}
