import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { DrawerService } from '@common/modules/layout/components/drawer/services/drawer.service';
import { SetDrawerIsEditing } from '@common/modules/layout/components/drawer/stores/drawer.store';
import { Notice, NoticeLevels, NoticeService } from '@common/modules/notice/notice.service';
import { SecretsApiService } from '@common/services/api/tools/secrets/secrets.api';
import { Store } from '@ngxs/store';
import { DialogActions } from '@ui-kit/components/ui-dialog/ui-dialog.component';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { SecretParameterTypes } from 'projects/@common/definitions/secretParameterType.enum';
import { Accumulatables, Accumulate } from 'projects/@common/modules/accumulator/accumulator.store';
import { ISecretIntegrations } from 'projects/@common/services/api/tools/secrets/secrets.definitions';
import { UiTypeOfFormEnum } from 'projects/console-adm/src/app/shared/dynamic-form-template/dynamic-form-template.component';
import { ISecret, SecretTemplateConfig } from './secret-store-details.form';
import { DisplayService } from '@common/modules/display/display.service';
import { EcoSessionState } from '@common/modules/session/state/session.state';
import { ISelfSignedPems } from 'projects/@common/services/selfsigned.service';

export enum StyleEnum {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

export interface IParameterToDisplay {
  label: ITranslatedField;
  description: ITranslatedField;
  required: boolean;
  typeOfForm: UiTypeOfFormEnum;
  controlName: string;
  group: AbstractControl;
}

@Component({
  selector: 'secret-store-details',
  templateUrl: './secret-store-details.component.html',
  styleUrls: [ './secret-store-details.component.scss' ],
})
export class SecretStoreDetailsComponent extends SecretTemplateConfig implements OnInit {

  public static readonly COMPONENT = 'secretStoreDetailsComponent';

  public uiTypeOfFormEnum = UiTypeOfFormEnum;

  public showDeleteDialog = false;
  public isEditing = false;
  public isCreate = false;
  public parameters: IParameterToDisplay;
  public selectedTemplate: ISecretIntegrations;
  public selfSignedCertificate: ISelfSignedPems | null = null;

  public typeEnum;
  public style = StyleEnum;
  public minDate: Date;

  // public descriptionFormat = DescriptionFormatEnum.TOOLTIP;

  @Input() public data: { secret: ISecret, title: string, ecoId?: string, templates: ISecretIntegrations[]; };
  secretMessage: string;

  public isVarMode: boolean = false;

  public isLoading: boolean = false;
  public canUpdateSecret = false;
  public canDeleteSecret = false;

  constructor(
    readonly i18n: I18nService,
    readonly formBuilder: UntypedFormBuilder,
    readonly drawerService: DrawerService,
    readonly store: Store,
    readonly secretsApiService: SecretsApiService,
    readonly noticeService: NoticeService,
    private displayService: DisplayService
  ) {
    super(i18n, formBuilder, drawerService, store, secretsApiService);
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  ngOnInit(): void {
    this.formGroup.addControl("editSecretValues", new UntypedFormControl(false));
    this.formGroup.get("editSecretValues").valueChanges.subscribe((isEditing) => this.switchEditingSecretValues(isEditing));

    this.minDate = new Date(Date.now());
    this.typeEnum = this.data.templates.map((template) => template.name[this.i18n.currentLocale]);
    this.templates = this.data.templates;
    this.formGroup.patchValue({ "ecoId": this.data.ecoId });
    this.setState();

    if (this.isVarMode) {
      this.canUpdateSecret = this.displayService.meetsRequirements({ permissions: [ 'can_update_var_secret' ] });
      this.canDeleteSecret = this.displayService.meetsRequirements({ permissions: [ 'can_delete_var_secret' ] });
    } else {
      this.canUpdateSecret = this.displayService.meetsRequirements({ permissions: [ 'can_update_secret' ] });
      this.canDeleteSecret = this.displayService.meetsRequirements({ permissions: [ 'can_delete_secret' ] });
    }
  }

  public get isEditingSecretValues(): boolean {
    return this.formGroup.get('editSecretValues').value;
  }

  private switchEditingSecretValues(isEditing: boolean) {
    this.generateSecretFormParameters();
  }

  public onCancelCreate(): void {
    this.drawerService.hideDrawer();
  }

  public onCancel(): void {
    this.isEditing = false;
    this.setDrawerStatus();
  }

  public setEditing(): void {
    this.isEditing = true;
    this.setDrawerStatus();
  }

  public onCertificateGenerated(certificate: ISelfSignedPems): void {
    this.selfSignedCertificate = certificate;
    this.generateSecretFormParameters();
  }

  public async create(): Promise<void> {

    let creationFailed = false;
      this.secretsApiService.createSecret(this.data.ecoId, this.secretBody).then(() => {
        this.noticeService.notifyUser(new Notice(`secrets.success.create.message`, NoticeLevels.SUCCESS));
      })
        .catch((error) => {
          const message = Array.isArray(error.error.message)
            ? `${error.error.error}: ${error.error.message[0]}`
            : error.error.message;
          this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
          creationFailed = true;
        })
        .finally(() => {
          if (!creationFailed) {
            this.drawerService.hideDrawer();
            this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.SECRETS_LIST }));
          }
        });
  }

  public save(): void {
    const update = this.secretBody;
    if (!this.isEditingSecretValues) {
      delete update["secretValue"];
    }

    this.secretsApiService.updateSecret(this.data.ecoId, update).then(() => {
      this.noticeService.notifyUser(new Notice(`secrets.success.update.message`, NoticeLevels.SUCCESS));
    })
      .catch((error) => {
        const message = Array.isArray(error.error.message)
          ? `${error.error.error}: ${error.error.message[0]}`
          : error.error.message;
        this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
      })
      .finally(() => {
        this.drawerService.hideDrawer();
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.SECRETS_LIST }));
      });
  }

  private setDrawerStatus() {
    this.store.dispatch(new SetDrawerIsEditing({
      isEditing: this.isEditing,
      tabName: SecretStoreDetailsComponent.COMPONENT,
    }));
  }

  public openDeleteDialog(): void {
    this.secretMessage = this.i18n.translate('secrets.table.action.delete.dialog.message', {
      name: this.data?.secret?.name,
    });
    this.showDeleteDialog = true;
  }

  public deleteSecret(event: DialogActions): void {
    if (event === DialogActions.ACTION) {
      this.secretsApiService
        .deleteSecret(this.data.ecoId, this.data?.secret?.id)
        .then(() => {
          this.noticeService.notifyUser(new Notice(`secrets.success.delete.message`, NoticeLevels.SUCCESS));
        })
        .catch((error) => {
          const message = Array.isArray(error.error.message)
            ? `${error.error.error}: ${error.error.message[0]}`
            : error.error.message;
          this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
        })
        .finally(() => {
          this.drawerService.hideDrawer();
          this.showDeleteDialog = false;
          this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.SECRETS_LIST }));
        });
    } else {
      this.showDeleteDialog = false;
    }
  }

  public buildSecretForm(event?): void {
    if (event) {
      this.currentTemplateUsed = this.templates.find((object) => object.name[this.i18n.currentLocale] === event);
    } else if (this.data?.secret) {
      this.currentTemplateUsed = this.templates.find((object) => object.templateName === this.data.secret.type);
      this.formGroup.get("id").setValue(this.data.secret.id);
      this.formGroup.get("name").setValue(this.data.secret.name);
      this.formGroup.get("note").setValue(this.data.secret?.note);
    }
    this.selectedTemplate = this.currentTemplateUsed;
    this.formGroup.get("type").setValue(this.currentTemplateUsed.templateName);
    this.formGroup.get("expireAt").setValue(this.data.secret?.expireAt);
    this.generateSecretFormParameters();
  }

  public generateSecretFormParameters(): void {

    const parametersFromGroup = this.getParamGroup(this.currentTemplateUsed);

    this.parametersFormArray.clear();
    for (let i = 0; i < parametersFromGroup.length; i++) {
      this.parametersFormArray.push(parametersFromGroup.at(i));
    }

    const fetchedValues = this.data?.secret?.value ? JSON.parse(this.data?.secret?.value) : {};

    // If a certificate is present (generated or uploaded), inject data
    fetchedValues.key = this.selfSignedCertificate?.private;
    fetchedValues.caCert = this.selfSignedCertificate?.public;
    fetchedValues.cert = this.selfSignedCertificate?.cert;
    fetchedValues.expiration = this.selfSignedCertificate?.expirationDate;

    this.parameters = this.currentTemplateUsed?.input?.map((parameter, j) => {

      const paramFromControl = this.parametersFormArray.at(j);

      if (parameter.variableName in fetchedValues) {
        paramFromControl.get('value').setValue(fetchedValues[parameter.variableName]);
      }

      if (!this.isEditingSecretValues  && !this.isCreate) {
        paramFromControl.get('value').clearValidators();
        paramFromControl.get('value').updateValueAndValidity();
      }

      return {
        label: parameter.name,
        description: parameter.description,
        required: parameter.mandatory,
        typeOfForm: this.setTypeOfForm(parameter.type),
        controlName: 'value',
        group: paramFromControl,
        values: (fetchedValues[parameter.variableName] === undefined ? undefined : fetchedValues[parameter.variableName].toString()),
      };
    });
  }

  private async verifyExistingName(name): Promise<boolean> {
    try {
      await this.secretsApiService.describeSecret(this.data.ecoId, name);
      this.noticeService.notifyUser(new Notice(this.i18n.translate(`secrets.error.alreadyExist.message`, { name }), NoticeLevels.ERROR));
      return true;
    } catch (error) {
      return false;
    }
  }


  private setTypeOfForm(type: string): UiTypeOfFormEnum {

    switch (type) {
      case SecretParameterTypes.OBJECT:
        return UiTypeOfFormEnum.OBJECT_TEXTAREA;
      case SecretParameterTypes.STRING:
        return UiTypeOfFormEnum.TEXT_INPUT;
      case SecretParameterTypes.TEXT:
        return UiTypeOfFormEnum.LONG_TEXT_INPUT;
      case SecretParameterTypes.NUMBER:
        return UiTypeOfFormEnum.NUMBER_INPUT;
      case SecretParameterTypes.BOOL:
        return UiTypeOfFormEnum.CHECKBOX;
      case SecretParameterTypes.DATE:
        return UiTypeOfFormEnum.DATE_INPUT;
      case SecretParameterTypes.FILE:
        return UiTypeOfFormEnum.FILE_UPLOAD_INPUT;
      default:
        return null;
    }
  }

  private async setState() {
    if (!this.data?.secret) {
      this.isEditing = true;
      this.isCreate = true;
    } else {
      this.data.secret = await this.secretsApiService.describeSecret(this.data.ecoId, this.data.secret.id);
      this.buildSecretForm();
    }
  }
}
