import { Subject } from "rxjs";

import { OrganizationPublicInfo } from "projects/@common/definitions/organization-public-info.definition";
import { IDescribeIncidentTask, IncidentTaskStatus } from "../../incidentTasks/incidentTasks.definitions";
import { Whitelist } from "../../whitelists/models/Whitelist";
import { IIncident, IIncidentAnalyst, IIncidentEscalationList, IIncidentPriorityChange, IIncidentSystemTimestamps, IIncidentTimestamp, IUpdateIncidentRequest, IncidentCloseStatus, IncidentStatus, ResolveIncidentAction } from "../incidents.definitions";
import { Buffer } from 'buffer';

export class Incident implements IIncident {
  id: string;
  shortId: string;
  name: string;
  status: IncidentStatus;
  priority: number;
  severity: number;
  organizationId: string;
  organizationEcoId: string;
  organizationName: string;
  usecaseIds: string[];
  playbookId: string;
  summary: string;
  additionalInfo: string;
  playbookParams: any;
  zohoTicket?: string;
  zohoTicketUrl?: string;
  ownerId: string;
  ownerName: string;
  createdById: string;
  createdByName: string;
  createdAt?: number;
  updatedAt?: number;
  firstAlertTimestamp?: number;
  clientNotifiedAt?: number;
  containedAt?: number;
  remediatedAt?: number;
  closedAt?: number;
  manualClosedAt?: number;
  lastClosedAt?: number;
  lastManualClosedAt?: number;
  tasks: IDescribeIncidentTask[];
  timestamps?: IIncidentTimestamp[];
  phaseTimestamps: IIncidentSystemTimestamps;
  collaborationTime?: number;
  closeStatus?: IncidentCloseStatus;
  resolveIncidentActions?: ResolveIncidentAction[];
  breachOfConfidentiality?: boolean;
  reportAvailable?: boolean;
  analysts?: IIncidentAnalyst[];
  whitelistedAlertCount?: number;
  alertCount?: number;
  assetCount?: number;
  whitelists?: Whitelist[];
  affectedResources?: string;
  escalationLists?: IIncidentEscalationList;
  conversationId?: string;
  clientLastAccessedAt?: number;
  socLastAccessedAt?: number;
  timeToNotify?: number;
  timeToFinalize?: number;
  firstEventTimestamp: number;
  isLate: boolean;
  escalationScheduleHtml?: string;
  updatedConversationFromClient?: boolean;
  priorityHistory?: IIncidentPriorityChange[];

  reloadObservable$ = new Subject();

  constructor(parameters: IIncident & any) {
    if (parameters.usecaseId && !parameters.usecaseIds) {
      parameters.usecaseIds = [ parameters.usecaseId ];
    }

    Object.assign(this, parameters);
  }

  public get updateRequestDto(): IUpdateIncidentRequest {
    let additionalInfo: string;

    if (this.additionalInfo && this.additionalInfo.length > 0) {
      const base64data = Buffer.from(this.additionalInfo, 'utf8').toString('base64');
      additionalInfo = base64data;
    }

    return {
      id: this.id,
      name: this.name,
      status: this.status,
      additionalInfo: additionalInfo,
      severity: this.severity,
      zohoTicketUrl: this.zohoTicketUrl,
      zohoTicket: this.zohoTicket,
      summary: this.summary,
      collaborationTime: this.collaborationTime,
      breachOfConfidentiality: this.breachOfConfidentiality,
      affectedResources: this.affectedResources,
    };
  }

  public get organization(): OrganizationPublicInfo {
    return {
      organizationId: this.organizationId,
      organizationEcoId: this.organizationEcoId,
      organizationName: this.organizationName,
    };
  }

  public get collaborationTimeMs(): number {
    if (this.collaborationTime === 0) {
      return;
    }
    return this.collaborationTime * 60000;
  }

  public get priorityLabel(): string {
    return `P${this.priority}`;
  }

  public get openStatuses(): IncidentStatus[] {
    return [ IncidentStatus.OPEN, IncidentStatus.FINALIZING ];
  }

  public get isInProgress(): boolean {
    return this.openStatuses.includes(this.status);
  }

  public get isEditable(): boolean {
    return this.isInProgress;
  }

  public get isReopened(): boolean {
    return this.closeStatus !== null && this.isInProgress;
  }

  public get currentTaskInProgressId(): string {
    return this.tasks.filter((task) => task.task.status === IncidentTaskStatus.IN_PROGRESS)[0]?.task.id || null;
  }

  public reloadIncident(incident: IIncident & any): void {
    Object.assign(this, incident);
    this.emitReloadedEvent();
  }

  public emitReloadedEvent(): void {
    this.reloadObservable$.next();
  }

  public setTimestamps(timestamps: IIncidentTimestamp[]): void {
    this.timestamps = timestamps;
  }

  public setCollaborationTime(seconds: number): void {
    this.collaborationTime = seconds;
  }
}
