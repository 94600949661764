import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";

export interface ISelectIcon {
  icon: string;
  value: any;
  viewValue: string;
};

@Component({
  selector: 'ui-select-with-icon',
  templateUrl: './ui-select-with-icon.component.html',
  styleUrls: [ './ui-select-with-icon.component.scss' ],
})
export class UiSelectWithIconComponent implements OnInit, OnChanges {
  @Input() public options: ISelectIcon[];

  @Input() public disabled = false;

  @Input() public readonly = false;

  @Input() public placeholder = '';

  @Input() public defaultValue: any;

  @Input() label: string;

  @Input() public required = false;

  @Output() optionClick = new EventEmitter();

  public selectedValue: ISelectIcon;

  public showOptions = false;

  public tempOptions: ISelectIcon[];

  constructor() { }

  ngOnChanges(): void {
    this.resetTemp();
    this.selectedValue = this.options.find((value) => value.value === this.defaultValue);
    this.removeCurrentSelectedFormList();
  }

  ngOnInit(): void {
    this.resetTemp();
    this.selectedValue = this.options.find((value) => value.value === this.defaultValue);
    this.removeCurrentSelectedFormList();
  }

  public onOptionClick(option: ISelectIcon) {
    this.selectedValue = option;
    this.optionClick.emit(option.value);
    this.resetTemp();
    this.removeCurrentSelectedFormList();
    this.showOptions = false;
  }

  private resetTemp(): void {
    this.tempOptions = [ ...this.options ];
  }

  private removeCurrentSelectedFormList(): void {
    this.tempOptions.splice(this.tempOptions.findIndex((option) => option.value === this.selectedValue?.value), 1);
  }

  public toggleOptions(event) {
    if (!this.disabled) {
      this.showOptions = !this.showOptions;
    }
    event.stopPropagation();
  }
}
