<ui-label-input-with-modify
  [value]="playbook.notes"
  [placeholder]="'detection.playbook.detail.notes.placeholder' | t"
  [label]="'detection.playbook.detail.notes.tab' | t"
  emptyPlaceholder="-"
  [textareaInput]="true"
  [locale]="i18nService.currentLocale"
  [disabled]="isReadonly"
  [isEditing]="isNotesEmpty()"
  (onValueChange)="savePlaybookValue(playbookValuesEnum.NOTES, $event)"
>
</ui-label-input-with-modify>