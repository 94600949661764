<ng-container *ngIf="!isLoading">
  <dlp-activation
    #activation
    [currentSettings]="currentSettings"
    [isLoading]="isLoading"
  ></dlp-activation>
  <dlp-justification-delay
    *ngIf="activationComponent?.isActivated"
    [currentSettings]="currentSettings"
    [isLoading]="isLoading"
  ></dlp-justification-delay>
  <dlp-frequency
    *ngIf="activationComponent?.isActivated"
    [currentSettings]="currentSettings"
    [isLoading]="isLoading"
  ></dlp-frequency>
</ng-container>

<ng-container *ngIf="isLoading">
  <ui-spinner size="medium"></ui-spinner>
</ng-container>
