import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { Eco } from "projects/@common/definitions/eco";
import { Accumulatables, Accumulate } from "projects/@common/modules/accumulator/accumulator.store";
import { IdentityProviderTypes, List, ListUsersResponse } from "projects/@common/services/api/iam/identities/identities.api.definitions";
import { clone } from "projects/@common/utils/utils";

import { ProfilesApi } from "projects/@common/services/api/iam/profiles/profiles.api";
import { AutocompleteTypes } from "@ui-kit/components/autocomplete/autocomplete.component";
import { DrawerService } from "@common/modules/layout/components/drawer/services/drawer.service";
import { NoticeService, Notice, NoticeLevels } from "@common/modules/notice/notice.service";
import { IamApi } from "@common/services/api/iam/iam.api.definitions";
import { IdentitiesApi } from "@common/services/api/iam/identities/identities.api";
import { User } from "@common/definitions/user.definition";
import { I18nService } from "@common/modules/i18n/i18n.service";

interface ListApiResult {
  from: number;
  total: number;
}

@Component({
  selector: 'role-members',
  templateUrl: './role-members.component.html',
  styleUrls: [ './role-members.component.scss' ],
})
export class RoleMembersComponent implements OnInit {
  private readonly MAX_LOADING_SIZE = 25;
  private usersPaginationDetails: ListApiResult = { from: 0, total: 0 };
  private isLoadingMore: boolean = false;

  @Input() profile: IamApi.Profile.listResponse;

  @Input() organizationId: string | null = null;

  public static readonly COMPONENT = 'profileMembers';
  public profileUsers: User[] = [];
  public prevUsers: User[] = [];
  public isLoading: boolean = false;
  public isEditing: boolean = false;
  public usersAutocompleteType = AutocompleteTypes.USERS;

  constructor(
    private profilesApi: ProfilesApi,
    private readonly drawerService: DrawerService,
    private identitiesApi: IdentitiesApi,
    private noticeService: NoticeService,
    private i18nService: I18nService,
    private store: Store
  ) { }

  public ngOnInit(): void {
    this.handleRefresh();
  }

  public get isMoreUsersToLoad(): boolean {
    return this.usersPaginationDetails.from < this.usersPaginationDetails.total;
  }

  public handleRefresh(): void {
    this.loadProfileMembers();
  }

  public loadProfileMembers() {
    if (!this.isLoadingMore && (this.usersPaginationDetails?.from === 0 || this.isMoreUsersToLoad)) {
      this.isLoadingMore = true;
      this.isLoading = true;

      this.profilesApi
        .listProfileMembers({
          organizationId: this.organizationId,
          profileId: this.profile.id,
          params: {
            from: this.usersPaginationDetails.from,
            size: this.MAX_LOADING_SIZE,
            idpRequired: false
          },
        })
        .then((members) => {
          const profileMembers = this.mapProfileMembers(members);
          this.profileUsers = [ ...this.removeDuplicate(profileMembers) ];
          this.prevUsers = clone(this.profileUsers);
        })
        .finally(() => {
          this.isLoading = false;
          this.isLoadingMore = false;
        });
    }
  }

  private removeDuplicate(users: any[]): any[] {
    const profileMap = new Map();
    this.profileUsers = this.profileUsers.concat(users);
    this.profileUsers.map((user) => {
      profileMap.set(user.guid, user);
    });

    const noDuplicates = Array.from(profileMap, (entry) => entry[1]);

    return noDuplicates;
  }

  private mapProfileMembers(members: Eco.List<IamApi.Profile.listMemberResponse>): User[] {
    return members.items.map((profileUser: IamApi.Profile.listMemberResponse) => {
      this.usersPaginationDetails = {
        from: members.nextItem,
        total: members.total,
      };
      return {
        guid: profileUser.idpIdentifier,
        mail: this.getUserEmail(profileUser),
        firstName: profileUser.name,
        lastName: null,
        o365UserId: profileUser.idpIdentifier,
        officeUser: Boolean(profileUser.idpIdentifier),
      };
    });
  }

  public saveProfile(): void {
    this.isLoading = true;
    this.toggleEditProfile(); // needs to be called before the async profilesApi promise returns otherwise Angular does not render the tab names as expected
    this.profilesApi
      .updateProfileMembers({
        organizationId: this.organizationId,
        profileId: this.profile.id,
        body: {
          membersFromIdp: this.profileUsers.map((user) => user.guid),
        },
      })
      .then(() => {
        this.noticeService.notifyUser(new Notice('profiles.users.success.update.message.first', NoticeLevels.SUCCESS));
        this.handleRefresh();
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.IAM_ROLE_LIST }));
      })
      .catch((error) => {
        if (error['response']?.status === 400) {
          this.noticeService.notifyUser(new Notice('profiles.users.error.404.message', NoticeLevels.ERROR));
        } else {
          this.noticeService.notifyUser(new Notice('profiles.users.error.create.500.message', NoticeLevels.ERROR));
        }
      })
      .finally(() => {
        this.prevUsers = clone(this.profileUsers);
        this.isLoading = false;
      });
  }

  public removeUserFromProfile(user: User): void {
    if (this.profileUsers?.includes(user)) {
      const indexToRemove = this.profileUsers.indexOf(user, 0);
      if (indexToRemove > -1) {
        this.profileUsers.splice(indexToRemove, 1);
      }
    }
  }

  public cancel(): void {
    if (this.isEditing) {
      this.profileUsers = clone(this.prevUsers);
      this.toggleEditProfile();
      this.handleRefresh();
    } else {
      this.drawerService.previousDrawer();
    }
  }

  public toggleEditProfile(): void {
    this.isEditing = !this.isEditing;
    this.drawerService.setEditing(this.isEditing, RoleMembersComponent.COMPONENT);
  }

  public usersAutocompleteSearchFunction = (params: any): Promise<List<ListUsersResponse, number | string, { idpType: IdentityProviderTypes; }>> => this.identitiesApi.listUsers(this.organizationId, params);

  public notifyUserAdded(user: User): void {
    if (user?.firstName) {
      const translatedNoticeMessage = this.i18nService.translate('roles.users.added', { name: user.firstName });
      this.noticeService.notifyUser(new Notice(`${translatedNoticeMessage}`, NoticeLevels.INFO));
    }
  }

  private getUserEmail(user: IamApi.Profile.listMemberResponse): string {
    if (user?.idpUser?.mail) {
      return user.idpUser.mail;
    }
    if (user?.idpUser?.userPrincipalName) {
      return user.idpUser.userPrincipalName;
    }
    if (user?.idpUser?.upn) {
      return user.idpUser.upn;
    }
    return "";
  }
}
