export enum S3_PREFIX_FLAGS {
  skip_dots_replace = 1,
  skip_start_of_string = 2,
  skip_end_of_string = 4,
  skip_prefix_delimiter = 8,
  skip_parenthesis = 16
}

export const IMPOSSIBLE_REGEX = "^\\b$";

export class S3Utility {
  public static patternToS3Prefix(pattern: string, flags?: S3_PREFIX_FLAGS) {
    if (!pattern) {
      return "";
    }

    // We only add parenthesis if the string starts and finishes with those flags
    let addParenthesis = false;
    let result = pattern;
    if (!this.hasFlag(S3_PREFIX_FLAGS.skip_dots_replace, flags)) {
      result = this.escapeDots(pattern);
    }

    if (!this.hasFlag(S3_PREFIX_FLAGS.skip_start_of_string, flags) && !result.includes("^")) {
      result = `^${result}`;
      addParenthesis = true;
    } else if ((result.match(/\^/g) || []).length === 1 && result[0] === "^") {
      addParenthesis = true;
    }

    if (!this.hasFlag(S3_PREFIX_FLAGS.skip_end_of_string, flags) && !result.includes("$")) {
      result = `${result}$`;
    } else if ((result.match(/\$/g) || []).length > 1 || result[result.length - 1] !== "$") {
      addParenthesis = false;
    }

    /**
     * We're not skipping, the start & end flags are appropriate, safe check
     * and the regex is not already contained between parenthesis
     */
    if (
      !this.hasFlag(S3_PREFIX_FLAGS.skip_parenthesis, flags) &&
      addParenthesis &&
      result.length >= 2 &&
      !(result[1] === "(" && result[result.length - 2] === ")")
    ) {
      result = result.replace("^", "^(");
      result = result.replace("$", ")$");
    }

    if (!this.hasFlag(S3_PREFIX_FLAGS.skip_prefix_delimiter, flags)) {
      if (!result.includes("/")) {
        if (result.includes("$")) {
          result = result.replaceAll("$", "\\/$");
        } else {
          result = `${result}\\/`;
        }
      } else if (!result.includes("\\/")) {
        result = result.replaceAll("/", "\\/");
      }
    }

    return result;
  }

  public static escapeDots(pattern: string) {
    if (!pattern) {
      return "";
    }

    return pattern.replaceAll(/\./g, "\\.");
  }

  private static hasFlag(flag: S3_PREFIX_FLAGS, value?: S3_PREFIX_FLAGS) {
    return value && ((value & flag) === flag);
  }
}
