export class FileUtilityHelper {

  static getFileExtension(file: File): string {
    const fileName = file.name;
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }

  static readFile(file: File): Promise<string> {

    return new Promise((resolve, reject) => {

      const reader = new FileReader();

      reader.onload = (event) => {
        const fileContent = event.target?.result as string;
        resolve(fileContent);
      };

      // Handle errors during file reading
      reader.onerror = (error) => {
        reject(error);
      };

        // Handle file reading abort
      reader.onabort = () => {
        reject(new Error("File reading was aborted."));
      };

      try {
        reader.readAsText(file);
      } catch (error) {
        reject(error);
      }

    });

  }

}
