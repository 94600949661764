<app-incident-details-base-layout [title]="'incidents.container.page.details.tab.chronology.title' | t">

  <collaboration-time
    #collaborationTimeRef
    [incident]="incident"
    (updateCollaborationTimeMinutesEvent)="updateCollaborationTime($event)"
  ></collaboration-time>

  <chronology-timestamp
    *ngFor="let timestamp of timestamps;"
    #timestampComponentRef
    [id]="timestamp.id"
    [type]="timestamp.type"
    [incidentId]="timestamp.incidentId"
    [defaultTimestamp]="timestamp.timestamp"
    [locale]="locale"
    [editable]="timestamp.editable && canEditChronology"
    [deletable]="(timestamp.deletable && timestamp.timestamp && canEditChronology)"
    [showHistoryLink]="timestamp.type === 'CLIENT_NOTIFIED_AT'"
    [isEditing]="currentEditingId === timestamp.type"
    [hideEditButtons]="(currentEditingId && currentEditingId !== timestamp.type)"
    [minDate]="minTimestampDate"
    (toggleEditEvent)="setEditingState($event, timestamp.type)"
    (toggleFilterStateChanged)="onToggleFilterStateChanged($event)"
    (deleteEvent)="promptConfirmDeleteTimestamp(timestamp)"
    (updateEvent)="saveTimestamp($event, timestamp)"
  ></chronology-timestamp>

</app-incident-details-base-layout>
