import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'labels-impact-picker',
  templateUrl: './label-impact-picker.component.html',
  styleUrls: [ './label-impact-picker.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelImpactPickerComponent {
  @Input() public labelForm: UntypedFormGroup;

  @Input() public impactLevelOptions;

  constructor() { }

  public get iconClass() {
    switch (this.labelForm.value.severityLevel) {
      case 0:
        return 'gray';
      case 1:
        return 'green';
      case 2:
        return 'yellow';
      case 3:
        return 'orange';
      case 4:
        return 'red';
    }
  }
}
