import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Eco } from 'projects/@common/definitions/eco';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { AutomationOrganizationsService } from 'projects/@common/services/api/respond/automation-organization/automation-organizations.api';
import { DatasourceDetailsMapper } from 'projects/@common/services/api/respond/datasources/datasource.mapper';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { Observable } from 'rxjs';
import { IDatasourceTableObject } from '../../datasource-inventory-detail/components/detail-body/detail-body.component';
import { UsecaseInventoryContainerActions } from './usecase-inventory.container.actions';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { previewModeEnum } from '../../datasource-inventory/container/components/inventory-layout/inventory-layout.component';
import { SearchMode } from 'projects/@common/modules/layout/components/page/page.component';
import { ConnectorsApiOrganizationService } from 'projects/@common/services/api/detect/organizations/connectors-api-organizations';
import { I18nService } from '@common/modules/i18n/i18n.service';

@Component({
  selector: 'usecase-inventory-container',
  templateUrl: './usecase-inventory.container.component.html',
  styleUrls: [ './usecase-inventory.container.component.scss' ],
})
export class UsecaseInventoryContainerComponent implements OnInit, OnDestroy {
  public listData: IDatasourceTableObject[];

  public organization: IAutomationOrganization;

  public usecaseTotal = 0;

  public loading = false;

  public previewModeEnum = previewModeEnum;

  public ucInventoryAction: UsecaseInventoryContainerActions;
  @Select(EcoSessionState.organization) public organization$: Observable<Eco.IOrganization>;

  private userLocale = this.i18nService?.currentLocale || Eco.Languages.FR;

  public searchMode = SearchMode.RESPOND;

  public isVarMode: boolean = false;

  public filters: any = null;

  constructor(
    public i18nService: I18nService,
    private readonly usecasesApiService: UsecasesApiService,
    private readonly datasourceDetailsMapper: DatasourceDetailsMapper,
    private readonly organizationService: AutomationOrganizationsService,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly noticeService: NoticeService,
    private readonly store: Store
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  async ngOnInit(): Promise<void> {
    this.filters = history.state.filters || null;

    // If not var mode, loading org from session
    if (!this.isVarMode) {
      this.organization$.subscribe((value) => {
        this.organizationChanged(value);
      });
    }
  }

  ngOnDestroy(): void {
    this.ucInventoryAction?.$datasources?.unsubscribe();
  }

  private async refresh(organizationId: string): Promise<void> {
    this.ucInventoryAction = new UsecaseInventoryContainerActions(
      organizationId,
      this.usecasesApiService,
      this.datasourceDetailsMapper,
      this.userLocale,
      this.noticeService
    );
    this.ucInventoryAction?.$datasources?.subscribe((usecases) => {
      this.listData = usecases;
      this.cdr.detectChanges();
    });

    await this.ucInventoryAction.handleRefresh();
    this.usecaseTotal = this.ucInventoryAction.total;
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    if (org) {
      this.loading = true;
      const describedOrg = await this.connectorsApiOrganizationService.describeOrganization(org.id);
      this.organization = await this.organizationService.describeOrganization(describedOrg.organization_respond_id);
      await this.refresh(this.organization.id);
      this.loading = false;
    } else {
      this.organization = null;
    }
  }
}
