<div
  page
  [appPagePaths]="['mdRespond', 'reports']"
  [hasTopSection]="false"
  [previewMode]=""
  [searchMode]="searchMode"
  (organizationChanged)="organizationChanged($event)"
  (onScrollToBottom)="loadMoreReports()"
>
  <span
    *ngIf="showEmptyPageToSelectOrganization"
    class="no-org-transition"
  >{{ 'organizations_select.notSelected.message' | t }}</span>

  <respond-reports-filters
    *ngIf="!showEmptyPageToSelectOrganization;"
    toolbar
    [isLoading]="isLoading"
    [total]="reports ? reports.length : 0"
    [defaultFilters]="defaultFilters"
    (filterEvent)="handleFilterEvent($event)"
  ></respond-reports-filters>

  <respond-reports-table
    *ngIf="!showEmptyPageToSelectOrganization;"
    [isLoading]="isLoading"
    [reports]="reports"
    [defaultSorting]="defaultSorting"
    (sortEvent)="handleTableSortEvent($event)"
  ></respond-reports-table>
</div>