import { Component, Input } from '@angular/core';

export enum previewModeEnum {
  BETA = 'beta',
  PREVIEW = 'preview',
  EXPERIMENTAL = 'experimental',
  DEPRECATED = 'deprecated'
}

@Component({
  selector: 'inventory-layout',
  templateUrl: './inventory-layout.component.html',
  styleUrls: [ './inventory-layout.component.scss' ],
})
export class InventoryLayoutComponent {
  @Input()
    title = '';

  @Input()
    actionSpaced = true;

  @Input()
    previewMode? : previewModeEnum;
}
