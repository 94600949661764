import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { ApiEndpointPrefixEnum } from "projects/@common/definitions/eco-api";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { EcoUrlService } from "../../core/url.service";
import { BusinessHours, EscalationDelays, ICommunicationApi, ICommunicationContact, ICreateContactRequest, ICreateEscalationListRequest, IDeleteContactRequest, IDeleteEscalationListRequest, IDescribeEscalationListRequest, IEscalationList, IEscalationParameters, IListContactsRequest, IListEscalationListsRequest, IListEscalationParametersRequest, IUpdateBusinessHoursRequest, IUpdateContactRequest, IUpdateEscalationDelaysRequest, IUpdateEscalationListRequest } from "./communication.definitions";
import { CustomerCommunicationApiService } from "./customer-communication.api";
import { VarCommunicationApiService } from "./var-communication.api";


@Injectable({
  providedIn: 'root',
})
export class CommunicationApi implements ICommunicationApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  private varService: VarCommunicationApiService;
  private customerService: CustomerCommunicationApiService;

  constructor(
    protected store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.varService = new VarCommunicationApiService(http, url);
    this.customerService = new CustomerCommunicationApiService(http, url);
  }


  listContacts(request: IListContactsRequest): Observable<ResponseUtils<ICommunicationContact>> {
    if (this.isVarMode) {
      return this.varService.listContacts(request);
    }
    return this.customerService.listContacts(request);
  }
  createContact(request: ICreateContactRequest): Observable<ICommunicationContact> {
    if (this.isVarMode) {
      return this.varService.createContact(request);
    }
    return this.customerService.createContact(request);
  }
  updateContact(request: IUpdateContactRequest): Observable<ICommunicationContact> {
    if (this.isVarMode) {
      return this.varService.updateContact(request);
    }
    return this.customerService.updateContact(request);
  }
  deleteContact(request: IDeleteContactRequest): Observable<ICommunicationContact> {
    if (this.isVarMode) {
      return this.varService.deleteContact(request);
    }
    return this.customerService.deleteContact(request);
  }


  listEscalationLists(request: IListEscalationListsRequest): Observable<ResponseUtils<IEscalationList>> {
    if (this.isVarMode) {
      return this.varService.listEscalationLists(request);
    }
    return this.customerService.listEscalationLists(request);
  }

  describeEscalationList(request: IDescribeEscalationListRequest): Observable<IEscalationList> {
    if (this.isVarMode) {
      return this.varService.describeEscalationList(request);
    }
    return this.customerService.describeEscalationList(request);
  }

  createEscalationList(request: ICreateEscalationListRequest): Observable<IEscalationList> {
    if (this.isVarMode) {
      return this.varService.createEscalationList(request);
    }
    return this.customerService.createEscalationList(request);
  }

  updateEscalationList(request: IUpdateEscalationListRequest): Observable<IEscalationList> {
    if (this.isVarMode) {
      return this.varService.updateEscalationList(request);
    }
    return this.customerService.updateEscalationList(request);
  }

  deleteEscalationList(request: IDeleteEscalationListRequest): Observable<IEscalationList> {
    if (this.isVarMode) {
      return this.varService.deleteEscalationList(request);
    }
    return this.customerService.deleteEscalationList(request);
  }


  listEscalationParameters(request: IListEscalationParametersRequest): Observable<IEscalationParameters> {
    if (this.isVarMode) {
      return this.varService.listEscalationParameters(request);
    }
    return this.customerService.listEscalationParameters(request);
  }

  updateEscalationBusinessHours(request: IUpdateBusinessHoursRequest): Observable<BusinessHours> {
    if (this.isVarMode) {
      return this.varService.updateEscalationBusinessHours(request);
    }
    return this.customerService.updateEscalationBusinessHours(request);
  }

  updateEscalationDelays(request: IUpdateEscalationDelaysRequest): Observable<EscalationDelays> {
    if (this.isVarMode) {
      return this.varService.updateEscalationDelays(request);
    }
    return this.customerService.updateEscalationDelays(request);
  }


  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
