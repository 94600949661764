import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DisplayService } from "projects/@common/modules/display/display.service";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { SnackbarService } from "projects/@common/modules/notice/implementations/snackbar/snackbar.service";
import { Notice, NoticeLevels } from "projects/@common/modules/notice/notice.service";
import { PlaybooksApiService } from "projects/@common/services/api/respond/playbooks/playbooks.api";
import { IPlaybookDetail, IPlaybookTaskTemplateRepresentation, IPlaybookTemplate, IUsecaseTableData } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";
import { UsecasesApiService } from "projects/@common/services/api/respond/usecase/usecase.api";
import { clone, isEqual } from "projects/@common/utils/utils";

import { MitreAttackGenericData } from "projects/@common/services/api/detect/mitre-attack/mitre-attack-api.definition";
import { IAlertingUsecaseTemplate } from "projects/@common/services/api/respond/usecase/usecase.definition";
import { PlaybookTabsEnum, PlaybookTabService } from "../../components/playbook-details-tab-header/playbook-tab.service";
import { IPlaybookTaskTemplatesParams } from "../../components/playbook-tasks-drag-and-drop.component/playbook-tasks-drag-and-drop.component";
import { PlaybooksMapper } from "../../mappers/playbooks.mapper";
import { PlaybookInventoryDetailForm } from "./playbook-details.form";

interface IFetchAllResponse {
  playbook: IPlaybookDetail;
  taskTemplates: IPlaybookTaskTemplateRepresentation[];
  usecases: IAlertingUsecaseTemplate[];
  template: IPlaybookTemplate;
}

export interface IPlaybookDetailViewParams {
  isReadonly: boolean,
  showHeaderOrgInfo: boolean,
  showTaskInstructions: boolean,
  showTemplateInfo: boolean,
  showMdTasksLibrary: boolean,
  tabsToHide: Array<PlaybookTabsEnum>,
  isSystemAPICall: boolean,
}

@Component({
  selector: 'respond-playbook-detail',
  templateUrl: './respond-playbook-detail.component.html',
  styleUrls: [ './respond-playbook-detail.component.scss' ],
})
export class RespondPlaybookDetailComponent implements OnInit, OnDestroy {
  @Input()
  public viewParams: IPlaybookDetailViewParams;

  public readonly playbookId?: string;

  public readonly organizationEcoId?: string;

  public readonly backLink;

  public playbook: IPlaybookDetail;

  public isSaving = false;

  public playbookTabsEnum = PlaybookTabsEnum;

  public playbookDetailForm: PlaybookInventoryDetailForm;

  public playbookUsecases: IUsecaseTableData[] = [];

  public mdTasks: IPlaybookTaskTemplateRepresentation[];

  public template: IPlaybookTemplate;

  public isOutdated: boolean = false;

  public areTaskLoading: boolean = false;

  public mitreAttackData: MitreAttackGenericData[] = [];

  private _initialPlaybookTasks: IPlaybookDetail;
  private readonly routeTemplateKeyword: string = 'templates';
  private playbookTemplateId: string;
  private playbookUsecasesRaw: IAlertingUsecaseTemplate[] = [];

  private _params: IPlaybookTaskTemplatesParams;

  private _from: number;

  private _canLoadMore: boolean;

  constructor(
    readonly formBuilder: UntypedFormBuilder,
    public readonly playbookTabService: PlaybookTabService,
    public readonly usecasesApiService: UsecasesApiService,
    public readonly playbooksApiService: PlaybooksApiService,
    private readonly playbooksMapper: PlaybooksMapper,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackbarService: SnackbarService,
    private readonly i18nService: I18nService,
    private readonly displayService: DisplayService
  ) {
    this.playbookDetailForm = new PlaybookInventoryDetailForm(formBuilder);

    if (this.isPlaybookTemplateRoute) {
      this.backLink = '../../../';
      this.playbookTemplateId = this.route.snapshot.paramMap.get('playbookTemplateId');
      const playbookTemplate = this.router.getCurrentNavigation()?.extras?.state?.template;
      if (playbookTemplate) {
        this.template = playbookTemplate;
      }
    } else {
      this.backLink = '../../';
      this.organizationEcoId = this.route.snapshot.paramMap.get('organizationEcoId');
      this.playbookId = this.route.snapshot.paramMap.get('playbookId');
    }


  }

  async ngOnInit(): Promise<void> {
    if (!this.canDescribePlaybook) {
      await this.router.navigate([ '.' ], { relativeTo: this.route.parent });
    }

    this.playbookTabService.initSelectedTabFromURL();
    if (this.isPlaybookTemplateRoute) {
      this.viewParams.tabsToHide = [ ...new Set(this.viewParams.tabsToHide.concat([ PlaybookTabsEnum.NOTES, PlaybookTabsEnum.HISTORY ])) ];
    }
    if (this.isPlaybookTemplateRoute && !this.template) {
      await this.initPlaybookTemplate();
      await this.fetchUsecaseTemplateInformation();
    } else if (this.isPlaybookTemplateRoute && this.template && this.playbookUsecases.length <= 0) {
      if (!this.template.attacks) {
        await this.initPlaybookTemplate();
      }
      await this.fetchUsecaseTemplateInformation();
    } else if (this.playbookId) {
      await this.getData();
    }
  }

  private async initPlaybookTemplate() {
    this.template = await this.playbooksApiService.getPlaybookTemplateById(this.playbookTemplateId, { displayAttacks: true });
    this.mitreAttackData = this.template.attacks ? this.template.attacks : [];
  }

  ngOnDestroy(): void {
    this.playbook = null;
    this.playbookTabService.resetTabs();
  }

  public get canDescribePlaybook(): boolean {
    return this.displayService.meetsRequirements({ permissions: [ "can_describe_playbook" ] });
  }

  public get canUpdatePlaybook(): boolean {
    return !this.viewParams.isReadonly && this.displayService.meetsRequirements({ permissions: [ "can_update_playbook" ] });
  }

  public get canUpdatePlaybookVersion(): boolean {
    return !this.viewParams.isReadonly && this.displayService.meetsRequirements({ permissions: [ "can_update_playbook_version" ] });
  }

  private async fetchAll(): Promise<IFetchAllResponse> {
    const [ playbookRes, usecaseRes, taskTemplatesRes ] = await Promise.all([
      this.playbooksApiService.getPlaybook(this.organizationEcoId, this.playbookId, { displayAttacks: true }),
      this.usecasesApiService.getAggregatedUsecasesList(this.organizationEcoId, { playbook: this.playbookId }),
      this.viewParams.showMdTasksLibrary && this.playbooksApiService.getPlaybookTaskTemplates({language: this.i18nService.currentLocale}),
    ]);

    this._canLoadMore = taskTemplatesRes?.nextItem < taskTemplatesRes?.total;
    this._from = taskTemplatesRes?.nextItem;

    return {
      playbook: playbookRes,
      taskTemplates: taskTemplatesRes?.items || [],
      usecases: usecaseRes.items,
      template: playbookRes?.templateId && this.viewParams.showTemplateInfo && await this.playbooksApiService.getPlaybookTemplateById(playbookRes.templateId),
    };
  }

  public async update(): Promise<void> {
    if (this.canUpdatePlaybook) {
      this.isSaving = true;
      this.playbooksApiService.updatePlaybook(this.playbook.organizationEcoId, this.playbook)
        .then(() => {
          this.clonePlaybook(this.playbook);
          this.snackbarService.handleNotice(new Notice(this.i18nService.translate('detection.playbook.detail.updated.success'), NoticeLevels.SUCCESS));
        })
        .catch((err) => {
          if (err.error.code === 'INVALID_PARAMETERS') {
            const errorMessage = this.i18nService.translate('detection.playbook.update.parameter.error', { parameters: err.error.payload.toString() });
            this.snackbarService.handleNotice(new Notice(errorMessage, NoticeLevels.ERROR));
          } else {
            this.snackbarService.handleNotice(new Notice(err, NoticeLevels.ERROR));
          }
        })
        .finally(() => this.isSaving = false);
    }
  }

  public navigateToPlaybookVersionUpdate(): void {
    if (this.canUpdatePlaybookVersion) {
      const path = `respond/${this.organizationEcoId}/playbooks/${this.playbook.id}/update`;
      this.router.navigateByUrl(path);
    }
  }

  public hasTaskChanged(): boolean {
    return !isEqual(this._initialPlaybookTasks, this.playbook);
  }

  public onTypeFilterChange(params: IPlaybookTaskTemplatesParams): void {
    this._params = params;
    this._from = 0;
    this.playbooksApiService.getPlaybookTaskTemplates({
      name: this._params.name,
      phases: this._params.phases,
      custom: this._params.custom,
      language: this.i18nService.currentLocale,
      playbookId: this._params.hideAddedTask ? this.playbookId : null,
      from: this._from
    }).then((response) => {
      this.mdTasks = response?.items || [];
      this._from = response?.nextItem;
      this._canLoadMore = response?.nextItem < response?.total;
    }).catch((err) => {
        this.snackbarService.handleNotice(new Notice(err, NoticeLevels.ERROR));
    });
  }

  public handleLoadMore(): void {
    if (this._canLoadMore && !this.areTaskLoading) {
      this.areTaskLoading = true;
      this.playbooksApiService.getPlaybookTaskTemplates({
        name: this._params.name,
        phases: this._params.phases,
        custom: this._params.custom,
        language: this.i18nService.currentLocale,
        playbookId: this._params.hideAddedTask ? this.playbookId : null,
        from: this._from
      }).then((response) => {
        this.mdTasks = this.mdTasks.concat(response?.items);
        this._from = response?.nextItem;
        this._canLoadMore = response?.nextItem < response?.total;
      }).catch((err) => {
        this.snackbarService.handleNotice(new Notice(err, NoticeLevels.ERROR));
      }).finally(() => {
        this.areTaskLoading = false;
      });
    }
  }

  private get isPlaybookTemplateRoute(): boolean {
    return this.router.url.includes(this.routeTemplateKeyword);
  }

  private clonePlaybook(playbook: IPlaybookDetail): void {
    this._initialPlaybookTasks = clone(playbook);
  }

  private async getData(): Promise<void> {
    await this.fetchAll()
      .then((response: IFetchAllResponse) => {
        this.playbook = response.playbook;
        this.mitreAttackData = this.playbook.attacks ? this.playbook.attacks : [];
        this.mdTasks = response.taskTemplates;
        this.playbookUsecasesRaw = response.usecases;
        this.playbookUsecases = this.playbooksMapper.playbookUsecaseTableMapper(response.usecases);
        this.template = response.template;

        this.clonePlaybook(this.playbook);
        this.isOutdated = this.template && this.template.version > this.playbook.version;
      })
      .catch((err) => {
        this.snackbarService.handleNotice(new Notice(err, NoticeLevels.ERROR));
      });
  }

  private async fetchUsecaseTemplateInformation() {
    const params = {
      playbookTemplateId: this.template.playbookTemplateId,
    };
    await this.usecasesApiService.listUsecaseTemplates(params)
      .then((result) => {
        this.playbookUsecasesRaw = result.items.map((i) => i.template);
        this.playbookUsecases = this.playbooksMapper.playbookUsecaseTableMapper(this.playbookUsecasesRaw);
      })
      .catch((err) => this.snackbarService.handleNotice(new Notice(err, NoticeLevels.ERROR)));
  }
}
