import { Eco } from 'projects/@common/definitions/eco';
import * as isoCountries from 'i18n-iso-countries';
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";

isoCountries.registerLocale(fr);
isoCountries.registerLocale(en);

export function getCountryAutocompleteOptions(locale: Eco.Languages) {
  const countries = isoCountries.getNames(locale, { select: "alias" });
  return Object.entries(countries)
    .map(([ key, value ]) => ({ value: key, displayValue: value }))
    .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
}
