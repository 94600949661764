import { Component, Input } from '@angular/core';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from '@common/modules/notice/notice.service';
import { IincidentTaskMessage } from '@common/services/api/respond/incidentTasks/incidentTasks.definitions';
import { IncidentsApi } from '@common/services/api/respond/incidents/incidents.api';
import { IIncidentMessage, IncidentStatus } from '@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from '@common/services/api/respond/incidents/models/incident';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-chat-message-menu',
  templateUrl: './chat-message-menu.component.html',
  styleUrls: ['./chat-message-menu.component.scss']
})
export class ChatMessageMenuComponent {
  public showOptionsMenu: boolean = false;

  @Input() incident: Incident;

  @Input() message: IIncidentMessage;

  @Input() isFromAdmConsole: boolean;

  constructor(
    private readonly incidentsApi: IncidentsApi,
    private readonly notice: NoticeService,
    private readonly i18nService: I18nService,
  ) {
  }

  public get isDisabled(): boolean {
    return !this.incident.currentTaskInProgressId || (this.incident.status !== IncidentStatus.OPEN && this.incident.status !== IncidentStatus.FINALIZING);
  }

  public toggleMenu(mouseEvent: MouseEvent): void {
    this.showOptionsMenu = !this.showOptionsMenu;
    mouseEvent?.stopPropagation();
    mouseEvent?.preventDefault();
  }

  public addToTaskEvidence(): void {
    const req: IincidentTaskMessage = {
      incidentId: this.incident.id,
      incidentTaskId: this.incident.currentTaskInProgressId,
      messageId: this.message.id,
      messageConversationId: this.message.conversationId,
      messageSenderName: this.message.senderName,
      messageSentAt: this.message.sentAt
    }

    this.incidentsApi.postTaskMessages(this.incident.organizationId, req)
      .subscribe(
        (response) => {
          this.incident.reloadIncident({tasks: response});
          this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.chat.messageToEvidenceTask.success'), NoticeLevels.SUCCESS))
        },
        (error) => {
          this.notice.notifyUser(new Notice(this.i18nService.translate('common.error.retry'), NoticeLevels.ERROR))
        }
      );
  }
}
