import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UiKitModule } from "@ui-kit/uikit.module";
import { ComponentsModule } from "projects/@common/components/components.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { PipesModule } from "projects/@common/modules/pipes/pipes.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";

import { RespondFilterDrawerContainer } from "./containers/respond-filter-drawer/respond-filter-drawer.container";
import { RespondFiltersContainer } from "./containers/respond-filter/respond-filters.container";

import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { RespondComponentsModule } from "../components/respond.components.module";
import { HistoriesModule } from "../histories/histories.module";
import { FilterConditionsComponent } from './components/filter-conditions/filter-conditions.component';
import { FilterConnectorNameComponent } from './components/filter-connector-name/filter-connector-name.component';
import { FilterCreatedByComponent } from './components/filter-created-by/filter-created-by.component';
import { FilterDetailsComponent } from './components/filter-details/filter-details.component';
import { FilterHistoryComponent } from './components/filter-history/filter-history.component';
import { FilterUsecaseNameComponent } from './components/filter-usecase-name/filter-usecase-name.component';
import { FiltersDeleteModalComponent } from "./components/filters-delete-modal/filters-delete-modal.component";
import { FiltersFiltersComponent } from "./components/filters-filters/filters-filters.component";
import { FiltersTableComponent } from './components/filters-table/filters-table.component';


const components = [
  RespondFiltersContainer,
  RespondFilterDrawerContainer,
  FiltersFiltersComponent,
  FiltersTableComponent,
  FilterCreatedByComponent,
  FilterHistoryComponent,
  FiltersDeleteModalComponent,
  FilterDetailsComponent,
  FilterConditionsComponent,
  FilterUsecaseNameComponent,
  FilterConnectorNameComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    PipesModule,
    RouterModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
    HistoriesModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    RespondComponentsModule,
  ],
})
export class FilterModule { }
