<label>{{"incidents.container.page.details.tab.chronology.collaborationTime" | t}}</label>

<div class="time-container">
  <span *ngIf="currentTimeMs === null && !isEditing; else pickerView">-</span>
  <ng-template #pickerView>
    <ui-duration-time-picker
      #durationPicker
      [defaultDurationMs]="defaultTimeMs"
      [isReadonly]="!editable"
      [isDisabled]="editable && !isEditing"
      (millisecondsChangedEvent)="handleCollaborationTimeChanged($event)"
      (keydown)="handleKeydown($event)"
    ></ui-duration-time-picker>
  </ng-template>
</div>

<div
  class="action-buttons"
  *ngIf="editable"
>
  <div *ngIf="!isEditing && !hideEditButtons">
    <button
      ui-button
      class="action-buttons--reset"
      color="drawerCloseFlat"
      icon="icon_modify_disabled"
      [custom-icon]="true"
      [isSvg]="true"
      icon-size="small"
      [tooltip]="'common.action.edit'| t"
      (click)="setIsEditing(true);"
    ></button>
  </div>
  <ui-confirmations-buttons
    *ngIf="isEditing"
    (confirmationEvent)="handleEditConfirmation($event)"
  ></ui-confirmations-buttons>
</div>
