<div class="container" [class.isExpanded]="isExpanded">
  <chat-conversations
    [isFromAdmConsole]="isFromAdmConsole"
    [incidentConversation]="incidentConversation"
    [incident]="incident"
    [currentTabSelected]="currentTabSelected"
    (expand)="onExpand($event)"
    (computedEscalationListChange)="handleComputedEscalationListChange($event)"
  ></chat-conversations>

  <chat-notes
    *ngIf="isFromAdmConsole"
    [incidentNotes]="incidentNotes"
    [incident]="incident"
    [currentTabSelected]="currentTabSelected"
  ></chat-notes>
</div>
