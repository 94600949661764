<div>
  <settings-edition-card
    [icon]="'icon_notification_active_cropped.svg'"
    [title]="'settings.resources.revision.frequency.title' | t"
    [canSave]="isValid"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <p>
      {{ 'settings.resources.revision.frequency.description.readonly' | t }}
    </p>
    <div class="labels">
      <div class="label-setting">
        <p
          [innerHTML]="
            'settings.resources.revision.frequency.default.frequency'
              | t: { defaultFrequency: revisionFrequencyForm.value.frequency }
          "
        ></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editing>
  <p>{{ 'settings.resources.revision.frequency.description' | t }}</p>
  <button *ngIf="revisionFrequencyForm.value.frequency !== 6" class="default-values" (click)="restoreDefaults()">
    {{ 'settings.notification.frequency.default.values' | t }}
  </button>
  <form [formGroup]="revisionFrequencyForm" class="form selector" ui-form *ngIf="!isLoading; else loading">
    <ui-field-group>
      <ui-select
        [options]="frequencyOptions"
        name="frequency"
        control="frequency"
        [group]="revisionFrequencyForm"
        [label]="'settings.notification.frequency.label.frequency' | t"
        [required]="true"
      >
      </ui-select>
    </ui-field-group>
  </form>
</ng-template>
