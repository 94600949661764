import { Component, OnInit } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { Router } from '@angular/router';
import { ConnectorInstanceDto, ConnectorOrgDto } from 'projects/@common/services/api/detect/connectorsOrg/model/ConnectorOrg';
import { ConnectorMappingHelper } from 'projects/@common/services/api/detect/models/connectorMappingHelper';
import { SecretsApiService } from '@common/services/api/tools/secrets/secrets.api';
import { DisplayService } from 'projects/@common/modules/display/display.service';

@Component({
  selector: 'app-connector-details-page',
  templateUrl: './connector-details-page.component.html',
  styleUrls: [ './connector-details-page.component.scss' ],
})
export class ConnectorDetailsPageComponent implements OnInit {
  public connector: ConnectorOrgDto;
  public instance?: ConnectorInstanceDto;
  public organization: IAutomationOrganization;
  public billingTooltipDescription: string = '';
  public secretKeys: string[] = [];

  constructor(
    public readonly connectorMappingHelper: ConnectorMappingHelper,
    public readonly secretsApiService: SecretsApiService,
    public readonly i18n: I18nService,
    private readonly displayService: DisplayService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.connector = history.state.connector;
    this.instance = history.state.instance;
    this.organization = history.state.organization;
    await this.fetchSecret();
    this.initBilllingTooltipDescription();
  }

  async fetchSecret(): Promise<void> {
    if (this.displayService.meetsRequirements({ permissions: [ 'can_list_secrets' ] })) {
      this.secretKeys = (await Promise.all(this.instance.secretKeys.map((sk) => this.secretsApiService.describeSecret(this.organization.ecoId, sk)))).map((secret) => secret.name);
    } else {
      this.secretKeys = this.instance.secretKeys || []
    }
  }

  public get functionsToDisplay(): string {
    return this.arrayValueToString(this.connector.functions.map((f) => f.name));
  }

  public get contactLink(): string {
    if (this.i18n.currentLocale === 'en') {
      return 'https://mondata.ai/en/contact-us/';
    }
    return 'https://mondata.ai/contactez-nous/';
  }

  public redirectToSecretsPage(secretName: string): void {
    let state = undefined;
    if (this.instance.secretKeys.length > 0) {
      state = {
        state: {
          filters: {
            name: secretName,
          },
        },
      };
    }
    this.router.navigate([ 'tools/secrets' ], state);
  }

  public redirectToUsecasesPage(): void {
    this.router.navigate([ 'detection/usecases' ], {
      state: {
        filters: {
          type: this.connectorMappingHelper.getDatasourceKeyTypeFromConnector(this.connector.id),
        },
      },
    });
  }

  private initBilllingTooltipDescription(): void {
    this.billingTooltipDescription =
      `${this.i18n.translate('detection.connector.instance.page.billing.tooltip.description')}
    ${this.connector.billingMethod.id === '01H84X40AEDJ5CHPQ481YMP4HJ'
    ? this.i18n.translate('detection.connector.instance.page.billing.log.description') :
    this.i18n.translate('detection.connector.instance.page.billing.subscription.description')}`;
  }

  private arrayValueToString(arrayValues: string[]): string {
    return arrayValues.join(', ');
  }
}
