import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EscalationContactListType, ICommunicationContact as ICommunicationContactDetail, IConnectorEscalation, IEscalationLevelForDisplay, IEscalationList, IEsclationListContact, PriorityEnum } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { IPlaybookDetail } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { normalizeString } from 'projects/@common/utils/utils';
import { mapCommunicationMethodsTranslations } from '../../components/communication-method/communication-method.helpers';

@Component({
  selector: 'communication-tab-escalation-lists',
  templateUrl: './communication-tab-escalation-lists.component.html',
  styleUrls: [ './communication-tab-escalation-lists.component.scss' ],
})
export class CommunicationTabEscalationListsComponent implements OnChanges {
  @Input() escalationLists: IEscalationList[];
  @Input() contacts: ICommunicationContactDetail[];
  @Input() playbooks: IPlaybookDetail[];
  @Input() connectors: IConnectorEscalation[];
  @Input() isLoading: boolean;
  @Input() isVarMode: boolean;

  @Output() saveListEvent = new EventEmitter<IEscalationList>();
  @Output() deleteListEvent = new EventEmitter<IEscalationList>();
  @Output() refreshListsEvent = new EventEmitter<void>();

  displayedEscalationLists: IEscalationList[];
  filters = {
    searchText: '',
  };

  constructor(
    public readonly i18n: I18nService,
    public readonly notice: NoticeService,
    public readonly displayService: DisplayService
  ) { }

  get hasEditPermission(): boolean {
    return this.displayService.meetsRequirements({ permissions: [ 'can_write_escalation' ] });
  }

  get hasDeletePermission(): boolean {
    return this.displayService.meetsRequirements({ permissions: [ 'can_delete_escalation_contact_list' ] });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isLoading) {
      this.mapEscalationListsForDisplay();
      this.filterEscalationLists();
    }
  }

  trackById(index: number, item: IEscalationList) {
    return item.id;
  }

  saveList(escalationList: IEscalationList) {
    console.log("result");
    console.log(escalationList);
    this.saveListEvent.emit(escalationList);
  }

  deleteList(escalationList: IEscalationList) {
    this.deleteListEvent.emit(escalationList);
  }

  canAddNewList(): boolean {
    return !this.isLoading && !this.escalationLists.some((list) => !list.createdAt);
  }

  addNewList() {
    if (this.canAddNewList()) {
      this.escalationLists.unshift({
        id: null,
        name: "",
        type: null,
        organizationId: null,
        ownerContactId: null,
        createdAt: null,
        escalationLevels: [],
        communicationMethods: [],
      });
      this.filterEscalationLists();
    }
  }

  refreshSearch() {
    this.refreshListsEvent.emit();
  }

  handleSearchTextChange(value: string) {
    this.filters.searchText = value;
    this.filterEscalationLists();
  }

  private mapEscalationListsForDisplay() {

    this.escalationLists.forEach((list: IEscalationList) => {
      list.escalationLevels.forEach((level: IEscalationLevelForDisplay) => {
        level.contacts.forEach((contact: IEsclationListContact) => {

          const contactDetails: ICommunicationContactDetail = this.contacts.find((c) => c.id === contact.id);

          let contactCopy = {...contact};

          if (contactDetails)
          {
            contactCopy.name = contactDetails.name;
            contactCopy.position = contactDetails.position;
            contactCopy.selectedCommunicationMethods = contactDetails.communicationMethods.filter((method) => !!contact.selectedCommunicationMethods.find((selectedMethod) => selectedMethod.id === method.id));
            contactCopy.selectedCommunicationMethods = mapCommunicationMethodsTranslations(contact.selectedCommunicationMethods, this.i18n);

            // Sync priorities data with the one we got from backend
            contactCopy.priorities = contactCopy.selectedCommunicationMethods[0].priorities ? this.mapSelectedCommunicationMethodsPriorities(contactCopy.selectedCommunicationMethods[0].priorities) : [];
            contactCopy.withPriorities = contactCopy.selectedCommunicationMethods[0].withPriorities ? true : false;
          }

          // Update contact object with new mapped data
          Object.assign(contact, contactCopy);

        });
      });

      list.escalationLevels.sort((a, b) => a.level - b.level);
    });
  }

  // If we have existing data from DB, they come from backend as object {1:true, 2:false, ...} , we need to convert to number[].
  private mapSelectedCommunicationMethodsPriorities(priorities: { [key in PriorityEnum]: boolean }): number[]
  {
    return Object.keys(priorities)
    .filter(key => priorities[key]) // Filter keys when value is true
    .map(Number); // convert string keys to numbers
  }

  private filterEscalationLists() {
    const priority = {
      "NEW": 1,
      [EscalationContactListType.DEFAULT]: 2,
      [EscalationContactListType.DEFAULT_OFFHOURS]: 3,
      "OTHER": 4,
    };

    const getSortOrderPriority = (escalationList: IEscalationList) => priority[escalationList.type] || !escalationList.id ? priority.NEW : priority.OTHER;

    const normalizedSearchText = normalizeString(this.filters.searchText);
    this.displayedEscalationLists = this.escalationLists
      .filter((list) => !list.id || normalizeString(list.name).includes(normalizedSearchText))
      .sort((listA, listB) => {
        const sortPriorityA = getSortOrderPriority(listA);
        const sortPriorityB = getSortOrderPriority(listB);
        if (sortPriorityA !== sortPriorityB) {
          return sortPriorityA - sortPriorityB;
        }
        return listA.name.localeCompare(listB.name);
      });
  }
}
