import { Component, Input } from '@angular/core';
import { IDatasourceVignette } from 'projects/@common/services/api/respond/datasources/datasources.definitions';

@Component({
  selector: 'detail-vignette',
  templateUrl: './detail-vignette.component.html',
  styleUrls: [ './detail-vignette.component.scss' ],
})
export class DetailVignetteComponent {
  @Input()
    vignetteData: IDatasourceVignette;
}
