import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { UiKitModule } from "@ui-kit/uikit.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { SharedModule } from "projects/console-adm/src/app/shared/shared.module";
import { HomeComponent } from "./components/home.component";
import { HomeRoutingModule } from "./home.routing.module";

@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    HomeRoutingModule,
    I18nModule,
    CommonModule,
    SharedModule,
    UiKitModule,
    FeatureSharedModule,
  ],

})
export class HomeModule {
}
