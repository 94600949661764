<div class="container" [class.padding]="isFromConsoleAdm">
  <section class="button-section">
    <button
      ui-button
      icon="icon_sync"
      icon-size="medium"
      color="listActionFlat"
      class="test-button"
      [loading]="isTestingPermission"
      [custom-icon]="true"
      [isSvg]="true"
      (click)="testPermissions()"
    >
      {{  'detect.connector.instance.drawer.tab.permissions.button' | t  }}
    </button>
  </section>
  
  <ui-title *ngIf="isFromConsoleAdm" [largeFont]="true" [name]="'detect.connector.instance.drawer.tab.permissions' | t"></ui-title>
  <h2 *ngIf="!isFromConsoleAdm">{{ 'detect.connector.instance.drawer.tab.permissions' | t }}</h2>
  <ui-warning
    *ngIf="hasActionsWithInvalidPermissions"
    [centerText]="false"
    [warningMessages]="warningMessages"
    [color]="warningColors.YELLOW"
    [showIcon]="true"
  ></ui-warning>

  <section class="actions-section">
    <ng-container *ngIf="connectorType === SoarConnectorTypes.MICROSOFT_365">
      <ng-container *ngIf="soarConnectorActions?.length">
        <div class="actions-list">
          <div *ngFor="let action of soarConnectorActions">
            <ng-container *ngTemplateOutlet="actionsTemplate; context: { action }"></ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!soarConnectorActions?.length">
        <p *ngIf="status === 'CONNECTED'">{{ 'detect.connector.instance.drawer.tab.permissions.noPermissions' | t }}</p>
        <p *ngIf="status === 'ERROR'">{{ 'detect.connector.instance.drawer.tab.permissions.noPermissionsError' | t }}</p>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="connectorType === SoarConnectorTypes.AWS">
      <ng-container *ngIf="(actionsGroupedByAccount | keyvalue).length">
        <div *ngFor="let account of actionsGroupedByAccount | keyvalue">
          <ui-title class="aws-account-name" [name]="account.key"></ui-title>
          <div class="actions-list">
            <div *ngFor="let action of account.value">
              <ng-container *ngTemplateOutlet="actionsTemplate; context: { action }"></ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!(actionsGroupedByAccount | keyvalue).length">
        <p *ngIf="status === 'CONNECTED'">{{ 'detect.connector.instance.drawer.tab.permissions.noPermissions' | t }}</p>
        <p *ngIf="status === 'ERROR'">{{ 'detect.connector.instance.drawer.tab.permissions.noPermissionsError' | t }}</p>
      </ng-container>
    </ng-container>
  </section>
</div>

<ng-template
  #detailsTemplate
  let-permissionDetails="row"
  let-isExpanded="isSelect"
>
  <ui-static class="permissions"
    *ngIf="permissionDetails.validPermissions"
    [label]="'detect.connector.instance.drawer.tab.permissions.validPermissions' | t"
    [value]="permissionDetails.validPermissions"
  ></ui-static>

  <ui-static class="permissions"
    *ngIf="permissionDetails.effectivePermission"
    [label]="'detect.connector.instance.drawer.tab.permissions.effectivePermissions' | t"
    [value]="permissionDetails.effectivePermission"
  ></ui-static>
</ng-template>

<ng-template
  #actionsTemplate
  let-action="action"
>
  <ui-collapsable-card
    [cardTitle]="action.action.nameI18n | translatedObject"
    [rightIcon]="getActionIcon(action)"
    [headerIcon]="'icon_connector_permission'"
    [isBigHeaderImage]="false"
    [isCollapsed]="true"
    >
    <ui-static-table 
      sort="name"
      direction="asc"
      [locale]="i18nService.currentLocale"
      [addPaddingOnFirst]="false"
      [dataSource]="action.dataSource"
      [columnsDef]="columnsDef"
      [expendedTemplate]="detailsTemplate"
    >    
    </ui-static-table>
  </ui-collapsable-card>
</ng-template>
