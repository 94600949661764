import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FeatureFlagsEnum } from '@common/modules/layout/definitions/base-sidebar.service';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { ConfirmationModalComponent } from '@ui-kit/components/confirmation-modal/confirmation-modal.component';
import { PresetOptionValuesEnum, DateTimePeriod } from '@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component';
import { ModalService } from '@ui-kit/services/modal.service';
import { TimeUtil } from '@ui-kit/services/time-util';
import { FieldMap } from 'angular2-query-builder';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { IBasicQueryParameter, ICreateCustomQueryRequest, IDeleteCustomQueryRequest, IDescribeQueryParameter, IDescribeQueryResponse, IExecuteDefaultQueryRequest, IExecuteOrganizationQueryRequest, IQueryItem, IQueryParameter, IUpdateCustomQueryRequest, QueryTypeEnum } from 'projects/@common/services/api/respond/queries/queries.definitions';
import { QueryBuilderComponent } from 'projects/@respond/components/query-builder/query-builder.component';

type ISelectOption = {
  value: string;
  displayValue: string;
  icon?: string;
};

type BasicQueryParameterInputField = {
  key: string;
  value: string;
  labels: ITranslatedField;
  dropdownOptions: string[];
};

enum MenuOptionIdsEnum {
  reset = "reset",
  advancedMode = "advancedMode",
  save = "save",
  saveAsNew = "saveAsNew",
  delete = "delete"
}

type MenuOption = {
  id: MenuOptionIdsEnum;
  translationKey: string;
  iconPath?: string;
};

@Component({
  selector: 'respond-queries-engine',
  templateUrl: './respond-queries-engine.component.html',
  styleUrls: [ './respond-queries-engine.component.scss' ],
})
export class RespondQueriesEngineComponent implements OnInit {
  @Input() isVarMode: boolean;

  @Input() isLoadingQueriesList: boolean;
  @Input() queriesList: IQueryItem[];

  @Input() isLoadingQueryDetails: boolean;
  @Input() queryDetails: IDescribeQueryResponse;

  @Input() isExecutingQuery: boolean;

  @Input() aggregationFields: FieldMap;

  @Output() querySelectionEvent = new EventEmitter<IQueryItem>();
  @Output() executeDefaultQueryEvent = new EventEmitter<IExecuteDefaultQueryRequest>();
  @Output() executeOrganizationQueryEvent = new EventEmitter<IExecuteOrganizationQueryRequest>();
  @Output() convertQueryToAdvancedModeEvent = new EventEmitter<IBasicQueryParameter[]>();
  @Output() createAdvancedQueryEvent = new EventEmitter<ICreateCustomQueryRequest>();
  @Output() updateAdvancedQueryEvent = new EventEmitter<IUpdateCustomQueryRequest>();
  @Output() deleteAdvancedQueryEvent = new EventEmitter<IDeleteCustomQueryRequest>();

  @ViewChild("queryBuilderComponentRef") queryBuilderComponentRef: QueryBuilderComponent;

  public querySelectOptions: ISelectOption[] = [];
  public selectedQueryIdValue: string = null;

  public defaultPeriodPreset: PresetOptionValuesEnum = PresetOptionValuesEnum.last_30_days;
  public selectedPeriod: DateTimePeriod;

  public basicQueryParameterInputs: BasicQueryParameterInputField[] = [];

  public showOptionsMenu: boolean;
  public menuOptions: MenuOption[] = [];
  
  public featureFlagEnum = FeatureFlagsEnum;

  public customAutocompleteType = AutocompleteTypes.CUSTOM;
  public advancedQueryAggregationAutocompleteOptions: ISelectOption[] = [];
  public selectedAggregationValue: string;

  public maxRowsOptions: ISelectOption[] = [];
  public selectedMaxRowsValue: string;

  public queryName: string;
  public resetQueryId: string;

  constructor(
    public readonly i18n: I18nService,
    private readonly translatedObjectPipe: TranslatedObjectPipe,
    private readonly modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.selectedPeriod = TimeUtil.getTimestampFromNowAndDays(30);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.queriesList?.currentValue) {
      this.initQueriesSelectOptions();
      if (!this.isLoadingQueryDetails && !this.queryDetails && this.selectedQueryIdValue) {
        setTimeout(() => {
          // automatically fetch the selected query details on the next tick
          this.handleQuerySelection(this.selectedQueryIdValue);
        }, 10);
      }
    }

    if (changes.queryDetails?.currentValue) {
      this.initBasicQueryParameterInputs(changes.queryDetails.currentValue);
      this.queryName = this.translatedObjectPipe.transform(changes.queryDetails.currentValue.query.name);
      this.resetQueryId = changes.queryDetails.currentValue.query.id ?? this.resetQueryId;

      if (changes.queryDetails.currentValue.query.type === QueryTypeEnum.ORGANIZATION) {
        this.initAggregationAutocompleteOptions(this.aggregationFields);
        this.initMaxRowsSelectOptions();
      }
    }

    if (changes.aggregationFields?.currentValue) {
      this.initAggregationAutocompleteOptions(changes.aggregationFields.currentValue);
    }
  }

  public get isExecuteButtonEnabled(): boolean {
    return !this.isExecutingQuery && !this.isLoadingQueryDetails && !this.isLoadingQueriesList && this.isValidQuery;
  }

  public get isValidQuery(): boolean {
    if (this.selectedQueryIdValue) {
      if (this.isAdvancedQueryMode && this.queryBuilderComponentRef) {
        return this.queryBuilderComponentRef.getIsValid();
      }
      return this.basicQueryParameterInputs?.length > -1 && this.basicQueryParameterInputs?.every((param: BasicQueryParameterInputField) => !!param.value);
    }
    return false;
  }

  public get isAdvancedQueryMode(): boolean {
    return this.queryDetails?.query?.type === QueryTypeEnum.ORGANIZATION;
  }

  public get isUnsavedAdvancedQuery(): boolean {
    return this.isAdvancedQueryMode && !this.queryDetails.query.id;
  }

  public handleResetQuery() {
    this.handleQuerySelection(this.resetQueryId);
  }

  public trackByKey(index: number, item: BasicQueryParameterInputField) {
    return item.key;
  }

  public handleQuerySelection(queryId: string) {
    this.selectedQueryIdValue = queryId || null;
    if (this.selectedQueryIdValue) {
      const queryItem = this.queriesList.find((query: IQueryItem) => query.id === this.selectedQueryIdValue);
      this.querySelectionEvent.emit(queryItem);
    }
  }

  public handlePeriodSelection(period: DateTimePeriod) {
    this.selectedPeriod = period;
  }

  public executeQuery() {
    if (this.isAdvancedQueryMode) {
      const request: IExecuteOrganizationQueryRequest = {
        organizationId: this.queryDetails?.query?.organizationId || null,
        aggregation: this.selectedAggregationValue || null,
        maxRows: this.selectedMaxRowsValue ? Number(this.selectedMaxRowsValue) : null,
        conditions: this.queryDetails?.query?.conditions || null,
        dateFrom: this.selectedPeriod?.dateFrom || null,
        dateTo: this.selectedPeriod?.dateTo || null,
      };
      this.executeOrganizationQueryEvent.emit(request);
    } else {
      const request: IExecuteDefaultQueryRequest = {
        customQueryId: this.queryDetails.query.id,
        parameters: this.basicQueryParameterInputs.map((parameter) => ({ key: parameter.key, value: parameter.value })),
        dateFrom: this.selectedPeriod?.dateFrom || null,
        dateTo: this.selectedPeriod?.dateTo || null,
      };
      this.executeDefaultQueryEvent.emit(request);
    }
  }

  public toggleOptionsMenu() {
    this.showOptionsMenu = !this.showOptionsMenu;
    if (this.showOptionsMenu) {
      this.initMenuOptions();
    }
  }

  public handleMenuOptionClicked(option: MenuOption) {
    if (option.id === MenuOptionIdsEnum.advancedMode) {
      this.convertQueryToAdvancedMode();
    } else if (option.id === MenuOptionIdsEnum.reset) {
      this.handleResetQuery();
    } else if (option.id === MenuOptionIdsEnum.save) {
      this.saveAdvancedQuery(false);
    } else if (option.id === MenuOptionIdsEnum.saveAsNew) {
      this.saveAdvancedQuery(true);
    } else if (option.id === MenuOptionIdsEnum.delete) {
      this.deleteAdvancedQuery();
    }
  }

  public getAggregationDefaultAutocompleteValue(): ISelectOption[] {
    const option = this.advancedQueryAggregationAutocompleteOptions.find((option: ISelectOption) => option.value === this.selectedAggregationValue);
    return option ? [ option ] : [];
  }

  private convertQueryToAdvancedMode() {
    const basicQueryParameters = this.basicQueryParameterInputs.map((parameter) => ({ key: parameter.key, value: parameter.value }));
    this.convertQueryToAdvancedModeEvent.emit(basicQueryParameters);
  }

  private saveAdvancedQuery(forceSaveAsNew: boolean = false) {
    const shouldCreateNew = forceSaveAsNew || this.isUnsavedAdvancedQuery;
    if (shouldCreateNew) {
      const createRequest: ICreateCustomQueryRequest = {
        name: this.queryName,
        organizationId: null,
        conditions: this.queryDetails.query.conditions,
        aggregation: this.selectedAggregationValue || null,
        maxRows: Number(this.selectedMaxRowsValue),
      };
      this.createAdvancedQueryEvent.emit(createRequest);
    } else {
      const updateRequest: IUpdateCustomQueryRequest = {
        id: this.queryDetails.query.id,
        name: this.queryName,
        organizationId: this.queryDetails.query.organizationId,
        conditions: this.queryDetails.query.conditions,
        aggregation: this.selectedAggregationValue || null,
        maxRows: Number(this.selectedMaxRowsValue),
      };
      this.updateAdvancedQueryEvent.emit(updateRequest);
    }
  }

  private async deleteAdvancedQuery() {
    const confirmation = await this.promptConfirmationDelete(this.queryName);
    if (confirmation) {
      const deleteRequest: IDeleteCustomQueryRequest = {
        id: this.queryDetails.query.id,
        organizationId: this.queryDetails.query.organizationId,
      };
      this.deleteAdvancedQueryEvent.emit(deleteRequest);
      this.selectedQueryIdValue = null;
      this.resetQueryId = null;
    }
  }

  private initQueriesSelectOptions() {
    this.querySelectOptions = this.queriesList
      .map((query: IQueryItem) => ({
        value: query.id,
        displayValue: this.translatedObjectPipe.transform(query.name),
        icon: `assets/favicons/queries/${query.type === QueryTypeEnum.DEFAULT ? 'icon_query_type_DEFAULT.png' : 'icon_query_type_ORGANIZATION.svg'}`,
      }))
      .sort((a: ISelectOption, b: ISelectOption) => a.displayValue.localeCompare(b.displayValue));

    this.selectedQueryIdValue = this.selectedQueryIdValue
      ? this.selectedQueryIdValue
      : this.querySelectOptions[0]?.value;
  }

  private initBasicQueryParameterInputs(queryDetails: IDescribeQueryResponse): void {
    if (!queryDetails?.query?.parameters) {
      this.basicQueryParameterInputs = [];
      return;
    }
    this.basicQueryParameterInputs = queryDetails.query.parameters.map((param: IQueryParameter) => ({
      key: param.key,
      value: param.query,
      labels: param.labels,
      dropdownOptions: queryDetails.parameterValues.find((paramValue: IDescribeQueryParameter) => paramValue.key === param.key)?.values ?? [],
    }));
  }

  private initMenuOptions(): void {
    console.log('initMenuOptions');

    this.menuOptions = [
      {
        id: MenuOptionIdsEnum.reset,
        iconPath: "assets/favicons/queries/icon_queries_reset.svg",
        translationKey: 'common.reset',
      },
    ];

    // dynamically add options
    if (this.selectedQueryIdValue) {
      if (!this.isAdvancedQueryMode) {
        this.menuOptions.push({
          id: MenuOptionIdsEnum.advancedMode,
          iconPath: "assets/favicons/queries/icon_queries_config.svg",
          translationKey: 'respond.queries.optionsmenu.advancedMode',
        });
      } else {
        this.menuOptions.push({
          id: MenuOptionIdsEnum.save,
          iconPath: "assets/favicons/queries/icon_queries_save.svg",
          translationKey: 'common.action.save',
        });
        if (!this.isUnsavedAdvancedQuery) {
          this.menuOptions.push(
            {
              id: MenuOptionIdsEnum.saveAsNew,
              iconPath: "assets/favicons/queries/icon_queries_saveAs.svg",
              translationKey: 'respond.queries.optionsmenu.saveAsNew',
            },
            {
              id: MenuOptionIdsEnum.delete,
              iconPath: "assets/favicons/icon_delete_gray.svg",
              translationKey: 'common.action.delete',
            }
          );
        }
      }
    }

    // Remove saving options when not valid
    if (this.isAdvancedQueryMode && (!this.isValidQuery || !this.queryName?.trim())) {
      this.menuOptions = this.menuOptions.filter((option: MenuOption) => option.id !== MenuOptionIdsEnum.save && option.id !== MenuOptionIdsEnum.saveAsNew);
    }
  }

  private initAggregationAutocompleteOptions(aggregationFields: FieldMap): void {
    if (!aggregationFields) {
      this.advancedQueryAggregationAutocompleteOptions = [];
      return;
    }
    const sortedConditionFields = Object.keys(aggregationFields).sort();
    this.advancedQueryAggregationAutocompleteOptions = sortedConditionFields.map((condition: string) => ({
      value: condition,
      displayValue: condition,
    }));

    this.selectedAggregationValue = this.queryDetails?.query?.aggregation || null;
  }

  private initMaxRowsSelectOptions() {
    this.maxRowsOptions = [ "10", "20", "50", "100", "500", "1000" ].map((rows: string) => ({
      value: rows,
      displayValue: rows,
    }));

    this.selectedMaxRowsValue = this.queryDetails?.query?.maxRows?.toString() || this.maxRowsOptions[0]?.value;
  }

  private async promptConfirmationDelete(requestName: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18n.translate('respond.queries.modal.deleteQuery.title'),
        text: this.i18n.translate('respond.queries.modal.deleteQuery.text', { requestName }),
        confirmationText: this.i18n.translate('common.action.confirm'),
        cancelText: this.i18n.translate('common.action.cancel'),
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
  }
}
