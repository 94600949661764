import { Component, Input, OnInit } from "@angular/core";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";

@Component({
  selector: 'app-history-detail',
  templateUrl: './history-detail.component.html',
  styleUrls: [ './history-detail.component.scss' ],
})
export class HistoryDetailComponent implements OnInit {
  @Input() history: any;

  constructor(public i18nService: I18nService) {
  }

  ngOnInit(): void {
  }

  showRawPayload(): boolean {
    return this.history.payload && !this.showOldNewValue() && JSON.stringify(this.history.payload) !== JSON.stringify({});
  }

  showOldNewValue(): boolean {
    return this.history.payload?.contentType && this.history.payload?.field;
  }

  payload(): string {
    return JSON.stringify(this.history.payload);
  }

  jsonToString(obj: any): string {
    return JSON.stringify(obj, undefined, 2);
  }
}
