import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IPlaybookDetail } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";

export class PlaybookCreationForm {
  private _formGroup: UntypedFormGroup;

  constructor(private readonly _formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  public getValues(): IPlaybookDetail {
    return this._formGroup.getRawValue();
  }

  public get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }

  public get isValid(): boolean {
    return this._formGroup.valid;
  }

  public get playbookNameControl(): UntypedFormControl {
    return this._formGroup.get('playbookName') as UntypedFormControl;
  }

  public get playbookNameSuffixControl(): UntypedFormControl {
    return this._formGroup.get('playbookNameSuffix') as UntypedFormControl;
  }

  public get descriptionControl(): UntypedFormControl {
    return this._formGroup.get('description') as UntypedFormControl;
  }

  public get descriptionAdditionalControl(): UntypedFormControl {
    return this._formGroup.get('descriptionAdditional') as UntypedFormControl;
  }

  public get playbookTemplateIdControl(): UntypedFormControl {
    return this._formGroup.get('playbookTemplateId') as UntypedFormControl;
  }

  public resetAllValue(): void {
    this._formGroup.reset();
  }

  private initForm(): void {
    this._formGroup = this._formBuilder.group({
      playbookName: [ '', Validators.required ],
      playbookNameSuffix: [ '', Validators.required ],
      description: [ '', Validators.required ],
      descriptionAdditional: [ '', Validators.nullValidator ],
      playbookTemplateId: [ '', Validators.required ],
    });
  }
}
