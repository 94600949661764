<ui-collapsable-card
  class="entity-expand-card"
  [cardTitle]="entity.label"
  [badges]="badges"
  [isCollapsed]="!entity.isExpanded"
  [useRedFlagFeature]="true"
  [redFlagTooltip]="'respond.entities.redflag.tooltip' | t: { tasks: redFlagTooltipTaskNames }"
  [isRedFlagged]="isRedFlagged"
  [withMenuOptions]="!isReadonly"
  [redFlagTooltipTaskNames]="redFlagTooltipTaskNames"
  [menuOptions]="menuOptions"
  (onToggleCollapse)="entity.isExpanded = !$event"
  (menuOptionClickEvent)="handleMenuOptionClicked($event)"
  (menuOpenedEvent)="handleMenuOptionOpened()"
>
  <div
    *ngIf="entity.isExpanded"
    class="entity-content-padding"
  >
    <respond-entity [entity]="entity"></respond-entity>
  </div>
</ui-collapsable-card>