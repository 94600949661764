<ui-card>
  <div class="top-section">
    <div class="buttons-container">
      <app-edit-with-modal #editor>
        <div class="tooltip">
          <button
            *display="{ permissions: ['can_update_approvers'] }"
            ui-button
            color="linkFlat"
            class="margin--small--bottom"
            icon="icon_create"
            [custom-icon]="true"
            [isSvg]="true"
            icon-size="medium"
            (click)="openPopup()"
          >
            {{ 'common.action.add' | t }}
          </button>
          <ui-tooltip>
            <p [ngStyle]="{'font-weight': '600'}"> {{ 'securityModel.approversPopup.tooltip.whoCan' | t }}</p>
            {{ 'securityModel.approversPopup.tooltip.whoCan2' | t }}
            <ul>
              <li>{{ 'securityModel.approversPopup.tooltip.roles.GLOBAL_ADMIN' | t }}</li>
              <li>{{ 'securityModel.approversPopup.tooltip.roles.EXCHANGE_ADMINISTRATOR ' | t }}</li>
              <li>{{ 'securityModel.approversPopup.tooltip.roles.DIRECTORY_WRITER ' | t }}</li>
              <li>{{ 'securityModel.approversPopup.tooltip.roles.SHAREPOINT_ADMINISTRATOR ' | t }}</li>
              <li>{{ 'securityModel.approversPopup.tooltip.roles.TEAMS_SERVICE_ADMINISTRATOR ' | t }}</li>
              <li>{{ 'securityModel.approversPopup.tooltip.roles.USER_ADMINISTRATOR ' | t }}</li>
            </ul>
            {{ 'securityModel.approversPopup.tooltip.group.note' | t }}
          </ui-tooltip>
        </div>
        <div content>
          <div class="autocomplete">
            <h3>{{ 'securityModel.approversPopup.title' | t }}</h3>
            <hr />
            <autocomplete
              [oneItemAtATime]="true"
              [canMultiSelect]="false"
              [placeholder]="'guestManagers.search.placeholder' | t"
              [autocompleteType]="usersAutocomplete"
              [searchOnlyUserResources]="false"
              (onItemSelected)="onItemSelected($event)"
              [searchFunction]="usersAutocompleteSearchFunction"
              name="userAutocomplete"
              [required]="true"
              [locale]="i18n.currentLocale"
            ></autocomplete>
            <div class="continueButton">
              <button
                [disabled]="!approverToAdd"
                class="button-spacing"
                ui-button
                color="standard"
                (click)="addApprover()"
              >
                {{ 'common.action.confirm' | t }}
              </button>
              <button class="button-spacing" ui-button color="linkFlat" (click)="closePopup()">
                {{ 'common.action.cancel' | t }}
              </button>
            </div>
          </div>
        </div>
      </app-edit-with-modal>
      <button
        *ngIf="usersSelected.length"
        ui-button
        color="linkFlat"
        class="margin--small--bottom"
        icon="icon_delete"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="medium"
        (click)="removeApprovers()"
      >
        {{ 'common.action.delete' | t }}
      </button>
    </div>

    <div class="flex-box center-horizontal group-templates--button-space">
      <ui-table-total-tool *ngIf="!isLoading" [total]="total"></ui-table-total-tool>
      <ui-table-search-tool
        [query]="fulltext"
        [placeholder]="'ui.table-search-tool.placeholder' | t"
        (clear)="handleSearchClear()"
        (query)="handleSearchQuery($event)"
      ></ui-table-search-tool>
      <ui-table-refresh-tool [loading]="isLoading" (click)="handleRefresh()"></ui-table-refresh-tool>
    </div>
  </div>

  <div *ngIf="attribute" class="warning">
    <ui-warning
      color="yellow"
      [centerText]="false"
      [roundedBorder]="true"
      [warningMessages]="[{ messageKey: 'groupTemplates.details.defaultApproving.single.approver' | t }]"
    ></ui-warning>
  </div>

  <ui-static-table
    *ngIf="columnsDef?.length"
    [loading]="isLoading"
    [direction]="direction"
    [dataSource]="dataSource"
    [columnsDef]="columnsDef"
    [locale]="i18n.currentLocale"
    [addPaddingOnFirst]="false"
    (checkbox-event)="handleTemplateSelection($event.event, $event.item.user[0].o365UserId)"
  ></ui-static-table>
</ui-card>
