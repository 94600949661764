import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'detail-layout',
  templateUrl: './detail-layout.component.html',
  styleUrls: [ './detail-layout.component.scss' ],
})
export class DetailLayoutComponent {
  @Input()
    backLink: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  public async navigateBack(): Promise<void> {
    await this.router.navigate([ this.backLink ], { relativeTo: this.route });
    this.removeQueryParams();
  }

  private removeQueryParams(): void {
    const currentUrl = this.router.url.split('?')[0];
    this.router.navigateByUrl(currentUrl, { replaceUrl: true });
  }
}
