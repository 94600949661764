import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { CommunicationMethodTypeEnum, IContactCommunicationMethod, IMethodEmail, IMethodPhone, IMethodSMS } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { CustomValidators } from 'projects/@common/utils/validators';
import { getCommunicationMethodIconSrc, getCommunicationMethodOptions, getCommunicationMethodReadonlyValue, MethodSelectionOption } from './communication-method.helpers';


@Component({
  selector: 'communication-method',
  templateUrl: './communication-method.component.html',
  styleUrls: [ './communication-method.component.scss' ],
})
export class CommunicationMethodComponent implements OnInit {
  @Input() communicationMethod: IContactCommunicationMethod;
  @Input() alreadyUsedMethodTypes: CommunicationMethodTypeEnum[];
  @Input() isEditing: boolean;
  @Input() showDeleteButton: boolean;

  @Output() deleteEvent = new EventEmitter<IContactCommunicationMethod>();

  iconSrc: string;
  readonlyValue: string;
  methodsSelectOptions: MethodSelectionOption[];
  methodsEnum = CommunicationMethodTypeEnum;

  constructor(public readonly i18n: I18nService) { }

  ngOnInit(): void {
    this.iconSrc = this.getIconSrc(this.communicationMethod.type);
    this.readonlyValue = this.getReadonlyValue(this.communicationMethod);
    this.methodsSelectOptions = this.getMethodOptions();
  }

  isValid(): boolean {
    if (this.communicationMethod.type === CommunicationMethodTypeEnum.EMAIL) {
      const props = this.communicationMethod.properties as IMethodEmail;
      return this.isValidEmail(props.email);
    } else if (this.communicationMethod.type === CommunicationMethodTypeEnum.OPSGENIE) {
      // const props = this.communicationMethod.properties as IMethodOpsGenie;
      return true;
    } else if (this.communicationMethod.type === CommunicationMethodTypeEnum.OTHER) {
      // const props = this.communicationMethod.properties as IMethodOther;
      return true;
    } else if (this.communicationMethod.type === CommunicationMethodTypeEnum.SMS) {
      const props = this.communicationMethod.properties as IMethodSMS;
      return this.isValidPhoneNumber(props.phoneNumber);
    } else if (this.communicationMethod.type === CommunicationMethodTypeEnum.PHONE) {
      const props = this.communicationMethod.properties as IMethodPhone;
      return this.isValidPhoneNumber(props.phoneNumber) && this.isValidPhoneExtension(props.ext);
    }

    return false;
  }

  isValidEmail(email: string): boolean {
    return email?.length > 0 && Validators.email(new UntypedFormControl(email)) === null;
  }

  isValidPhoneNumber(phoneNumber: string): boolean {
    return phoneNumber?.length > 0 && CustomValidators.validatePhoneNumber(new UntypedFormControl(phoneNumber)) === null;
  }

  isValidPhoneExtension(phoneExtension: string): boolean {
    return CustomValidators.numberValidator(new UntypedFormControl(phoneExtension)) === null;
  }

  handleOptionSelected(option: CommunicationMethodTypeEnum): void {
    this.iconSrc = this.getIconSrc(option);
  }

  handleDeleteClicked(): void {
    this.deleteEvent.emit(this.communicationMethod);
  }

  getIconSrc(type: CommunicationMethodTypeEnum): string {
    return getCommunicationMethodIconSrc(type);
  }

  getReadonlyValue(communicationMethod: IContactCommunicationMethod): string {
    return getCommunicationMethodReadonlyValue(communicationMethod, this.i18n);
  }

  getMethodOptions(): MethodSelectionOption[] {
    const allOptions = getCommunicationMethodOptions(this.i18n);
    // allow to select the current method type or any other type that is not already used
    return allOptions.filter((o) => o.value === this.communicationMethod.type || !this.alreadyUsedMethodTypes?.includes(o.value));
  }
}
