import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Options } from '@ui-kit/components/ui-select/ui-select.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { EscalationTimeChoicesEnum } from 'projects/@common/services/api/respond/communication/communication.definitions';

@Component({
  selector: 'escalation-time-picker',
  templateUrl: './escalation-time-picker.component.html',
  styleUrls: [ './escalation-time-picker.component.scss' ],
})
export class CommunicationEscalationTimePickerComponent implements OnInit {
  @Input() timeChoice: EscalationTimeChoicesEnum;
  @Input() isReadonly: boolean;
  @Input() type: 'reminder' | 'delay';

  @Output() timeChoiceChange = new EventEmitter<EscalationTimeChoicesEnum>();

  form = new UntypedFormGroup({});
  timeSelectOptions: Options;

  constructor(
    private readonly i18n: I18nService,
    private readonly formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.initTimeSelectOptions();
    this.initForm();
  }

  public handleChange(): void {
    this.timeChoice = this.form.value.timeSelectControl as EscalationTimeChoicesEnum;
    this.emitTimeChoiceChange();
  }

  public getTranslation(choiceEnum: EscalationTimeChoicesEnum): string {
    return choiceEnum ? this.i18n.translate(`respond.communication.tab.settings.escalation.table.timeselect.${choiceEnum}.${this.type}`) : '-';
  }

  private initTimeSelectOptions(): void {
    this.timeSelectOptions = Object.values(EscalationTimeChoicesEnum).map((choiceEnum) => ({
      value: choiceEnum,
      displayValue: this.getTranslation(choiceEnum),
    }));
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      timeSelectControl: [ this.timeChoice, Validators.required ],
    });
  }

  private emitTimeChoiceChange(): void {
    this.timeChoiceChange.emit(this.timeChoice);
  }
}
