<div
  class="toolbox-content"
  [class.isToolboxExpanded]="isToolboxExpanded"
>
  <ui-tabs
    #toolboxTabs
    class="toolbox-tabs"
    [color]="uiTabsColor"
    [borderBelowHeader]="false"
    [hasNoLeftPadding]="true"
    [hasSmallHeader]="false"
    (tabClicked)="handleTabClicked($event)"
  >
    <ui-tab
      [tabName]="'observables'"
      [tabTitle]="'incident-toolbox.tabs.observables.title' | t"
      tabImageActive="assets/favicons/toolbox-incidents/icon_observables_active.svg"
      tabImageInactive="assets/favicons/toolbox-incidents/icon_observables.svg"
    >
      <ng-template *ngTemplateOutlet="observablesTemplate"></ng-template>
    </ui-tab>

    <ui-tab
      [tabName]="'entities'"
      [tabTitle]="'incident-toolbox.tabs.entities.title' | t"
      tabImageActive="assets/favicons/toolbox-incidents/icon_entities_active.svg"
      tabImageInactive="assets/favicons/toolbox-incidents/icon_entities.svg"
    >
      <ng-template *ngTemplateOutlet="entitiesTemplate"></ng-template>
    </ui-tab>

    <ui-tab
      *display="{ permissions: ['can_list_toolbox_actions'] }"
      [tabName]="'actions'"
      [tabTitle]="'incident-toolbox.tabs.actions.title' | t"
      tabImageActive="assets/favicons/toolbox-incidents/icon_actions_active.svg"
      tabImageInactive="assets/favicons/toolbox-incidents/icon_actions.svg"
    >
      <ng-template *ngTemplateOutlet="actionsTemplate"></ng-template>
    </ui-tab>
    
    <ui-tab
      *ngIf="isTaskTabVisible()"
      [tabName]="'tasks'"
      [tabTitle]="'incident-toolbox.tabs.tasks.title' | t"
      tabImageActive="assets/favicons/toolbox-incidents/icon_tasks_active.svg"
      tabImageInactive="assets/favicons/toolbox-incidents/icon_tasks.svg"
    >
      <ng-template *ngTemplateOutlet="tasksTemplate"></ng-template>
    </ui-tab>
  </ui-tabs>
</div>


<!-- this widget is floating with an absolute position to avoid style conflicts with the main content of the toolbox -->
<div
  class="toolbox-expand-widget"
  [class.isToolboxExpanded]="isToolboxExpanded"
  (click)="toggleExpanded()"
>
  <div class="toolbox-expand-buttons">
    <img
      class="toolbox-expand-chevron"
      [src]="'assets/favicons/' + (isToolboxExpanded ? 'icon_chevron_close.svg' : 'icon_chevron_close_left.svg')"
      alt="Chevron"
      [tooltip]="(isToolboxExpanded ? 'common.collapse' : 'common.expand' ) | t"
    >
    <ng-container *ngIf="!isToolboxExpanded">
      <div class="toolbox-expand-icons-when-collapsed">
        <img
          [src]="'assets/favicons/toolbox-incidents/icon_observables' + (selectedTab === tabsEnum.observables ? '_active' : '') + '.svg'"
          alt="Observables"
          [tooltip]="'incident-toolbox.tabs.observables.title' | t"
          (mousedown)="handleTabClicked({tabName: tabsEnum.observables})"
        >
        <img
          [src]="'assets/favicons/toolbox-incidents/icon_entities' + (selectedTab === tabsEnum.entities ? '_active' : '') + '.svg'"
          alt="Entity"
          [tooltip]="'incident-toolbox.tabs.entities.title' | t"
          (mousedown)="handleTabClicked({tabName: tabsEnum.entities})"
        >
        <img
          [src]="'assets/favicons/toolbox-incidents/icon_actions' + (selectedTab === tabsEnum.actions ? '_active' : '') + '.svg'"
          alt="Action"
          [tooltip]="'incident-toolbox.tabs.actions.title' | t"
          (mousedown)="handleTabClicked({tabName: tabsEnum.actions})"
        >
        <img
          *ngIf="isTaskTabVisible()"
          [src]="'assets/favicons/toolbox-incidents/icon_tasks' + (selectedTab === tabsEnum.tasks ? '_active' : '') + '.svg'"
          alt="Task"
          [tooltip]="'incident-toolbox.tabs.tasks.title' | t"
          (mousedown)="handleTabClicked({tabName: tabsEnum.tasks})"
        >
      </div>
      <!-- stretch remaining available height and width to handle the tooltip where there are no icons -->
      <div
        class="collapsed-stretch-zone-for-tooltip"
        [tooltip]="'common.expand' | t"
      ></div>
    </ng-container>
  </div>
</div>


<ng-template #observablesTemplate>
  <observable-container
    class="toolbox-observables"
    [pageContext]="configObservables"
    [assets]="assets"
    [assetTypes]="assetTypes"
    [isReadonly]="isReadonlyMode"
  ></observable-container>
</ng-template>

<ng-template #entitiesTemplate>
  <incident-entities
    [entities]="entities"
    [incident]="incident"
    [isLoadingEntities]="isLoadingEntities"
    [pageContext]="configEntities"
    [isReadonly]="isReadonlyMode"
  ></incident-entities>
</ng-template>

<ng-template #actionsTemplate>
  <incident-actions
    class="toolbox-actions"
    [incident]="incident"
    [observables]="observables"
    [incidentActions]="incidentActions"
    [assets]="assets"
    [assetTypes]="assetTypes"
    [isReadonly]="isReadonlyMode"
  ></incident-actions>
</ng-template>

<ng-template #tasksTemplate>
  <incident-tasks
    class="toolbox-tasks"
    [incident]="incident"
    [isReadonly]="isReadonlyMode"
  ></incident-tasks>
</ng-template>