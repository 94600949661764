import { HttpClient } from "@angular/common/http";
import { ITranslatedField } from "@ui-kit/definitions/ITranslatedField";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { IAlert } from "projects/@common/services/api/respond/alerts/alerts.definitions";
import { GetIncidentAlertsRequest } from "projects/@common/services/api/respond/alerts/models/getIncidentAlertsRequest";
import { IEntityRepresentation } from 'projects/@common/services/api/respond/entities/entities.definition';
import { IDescribeIncidentTask, IncidentDescription, IncidentTaskStatus } from "projects/@common/services/api/respond/incidentTasks/incidentTasks.definitions";
import { CreateIncidentTimestampRequest, 
  DeleteIncidentTimestampRequest, 
  IAddIncidentAnalystRequest, 
  IComputedEscalation, 
  ICreateIncidentRequest, 
  IDeleteIncidentActionRequest, 
  IDeleteIncidentAnalystRequest, 
  IDeleteTaskActionsRequest, 
  IDeleteTaskEntitiesRequest, 
  IDeleteTaskMessageRequest, 
  IDeleteTaskObservablesRequest, 
  IDescribeIncidentResponse, 
  IExportIncidentsResponse, 
  IIncident, 
  IIncidentActionRepresentation, 
  IIncidentApi, 
  IIncidentMessage, 
  IIncidentStats, 
  IListEscalationParametersRequest, 
  IListIncidentRequest, 
  IPostTaskActionsRequest, 
  IPostTaskEntitiesRequest, 
  IPostTaskMessagesRequest, 
  IPostTaskObservablesRequest, 
  IRemoveAlertFromIncidentResponse, 
  IUpdateIncidentActionRequest, 
  IUpdateIncidentPriorityRequest, 
  IUpdateIncidentRequest, 
  IUpdateIncidentStatusRequest, 
  IncidentTaskPhases, 
  ListIncidentSimilarItemsRequest, 
  ListOwnersResponse, 
  UpdateIncidentTimestampRequest 
} from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { IncidentStatsHistoryDto, IncidentStatsHistoryRequest } from "projects/@common/services/api/respond/incidents/models/getIncidentStatsHistory.definition";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IncidentsStats } from "projects/@common/services/api/respond/incidents/models/incidentsStats";
import { IObservable } from "projects/@common/services/api/respond/observables/observables.definition";
import { Whitelist } from "projects/@common/services/api/respond/whitelists/models/Whitelist";
import { IWhitelistRepresentation } from "projects/@common/services/api/respond/whitelists/whitelists.definitions";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { UrlUtils } from "projects/@common/utils/utils";
import { SimilarityItem } from "projects/@respond/components/similarity/similarity.definitions";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class VarIncidentsApiService extends EcoApi implements IIncidentApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listIncidents(request: IListIncidentRequest): Observable<ResponseUtils<IIncident>> {
    const url = `${this.host}/incidents?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IIncident>>(url)
      .pipe(map((response) => new ResponseUtils<IIncident>(response)));
  }

  public createIncident(request: ICreateIncidentRequest): Promise<IIncident> {
    const url = `${this.host}/organizations/${request.organizationId}/incidents`;
    return this.http.post<IIncident>(url, request).toPromise();
  }

  public updateIncident(organizationId: string, request: IUpdateIncidentRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.id}`;
    return this.http.put<IDescribeIncidentResponse>(url, request);
  }

  public getOwners(): Observable<ListOwnersResponse[]> {
    const url = `${this.host}/incidents/owners`;
    return this.http.get<ListOwnersResponse[]>(url);
  }

  public getIncidentFromAlert(organizationId: string, alertId: string): Promise<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/alerts/${alertId}/incident`;
    return this.http.get<IDescribeIncidentResponse>(url).toPromise();
  }

  public getAlertsFromIncident(request: GetIncidentAlertsRequest): Observable<ResponseUtils<IAlert>> {
    const url = `${this.host}/organizations/${request.organizationId}/incidents/${request.incidentId}/alerts?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`;
    return this.http.get<IRespondResponse<IAlert>>(url)
      .pipe(map((response) => new ResponseUtils<IAlert>(response)));
  }

  public describeIncident(organizationId: string, incidentId: string): Observable<Incident> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}`;
    return this.http.get<IDescribeIncidentResponse>(url).pipe(map((response) => new Incident({
      ...response.incident,
      ...response.organization,
      tasks: response.tasks,
      firstAlertTimestamp: response.firstAlertTimestamp,
      timestamps: response.timestamps,
      phaseTimestamps: response.phaseTimestamps,
      resolveIncidentActions: response.resolveIncidentActions,
      reportAvailable: response.reportAvailable,
      whitelistedAlertCount: response.whitelistedAlertCount,
      whitelists: response?.whitelists?.map((whitelist: IWhitelistRepresentation) => new Whitelist(whitelist)) || [],
      analysts: response.analysts,
      usecaseIds: response?.usecaseIds,
      escalationScheduleHtml: response.escalationScheduleHtml,

    })));
  }

  public describeDraftIncidentEscalationSchedule(organizationId: string, incidentId: string, escalation=false): Observable<IComputedEscalation> {
    const url = escalation ? `${this.host}/organizations/${organizationId}/incidents/${incidentId}/escalationDraft?${UrlUtils.jsonToQueryParams({ escalation })}` : `${this.host}/organizations/${organizationId}/incidents/${incidentId}/escalationDraft`;
    return this.http.get<IComputedEscalation>(url);
  }

  public describeIncidentEscalationSchedule(organizationId: string, incidentId: string): Observable<IComputedEscalation> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/escalation`;
    return this.http.get<IComputedEscalation>(url);
  }

  public startConversation(organizationId: string, incidentId: string, content: string, escalation=false): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/notify`;
    return this.http.post<any>(url, { incidentId, content, escalation });
  }

  public associateAlerts(organizationId: string, incidentId: string, alertIds: string[], comment?: string): Promise<IIncident> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/alerts`;
    return this.http.post<IIncident>(url, { alertIds, comment }).toPromise();
  }

  public dissociateAlert(organizationId: string, alertId: string): Promise<IRemoveAlertFromIncidentResponse> {
    const url: string = `${this.host}/organizations/${organizationId}/alerts/${alertId}/incident`;
    return this.http.delete<IRemoveAlertFromIncidentResponse>(url).toPromise();
  }

  public updateIncidentTimestamp(organizationId: string, request: UpdateIncidentTimestampRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/timestamps/${request.id}`;
    return this.http.put<IDescribeIncidentResponse>(url, request);
  }

  public createIncidentTimestamp(organizationId: string, request: CreateIncidentTimestampRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/timestamps`;
    return this.http.post<IDescribeIncidentResponse>(url, request);
  }

  public deleteIncidentTimestamp(organizationId: string, request: DeleteIncidentTimestampRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/timestamps/${request.id}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public getIncidentsStats(organizationId?: string): Observable<IncidentsStats[]> {
    const url = organizationId ? `${this.host}/incidents/stats?${UrlUtils.jsonToQueryParams({ organizationId })}` : `${this.host}/incidents/stats`;
    return this.http.get<IIncidentStats[]>(url);
  }

  public getIncidentsStatsHistory(request: IncidentStatsHistoryRequest): Promise<IncidentStatsHistoryDto[]> {
    const url = `${this.host}/incidents/stats/history?${UrlUtils.jsonToQueryParams(request.params)}`;
    return this.http.get<IncidentStatsHistoryDto[]>(url).toPromise();
  }

  public updateIncidentStatus(organizationId: string, request: IUpdateIncidentStatusRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.id}/status`;
    return this.http.put<IDescribeIncidentResponse>(url, request);
  }

  public updateIncidentPriority(organizationId: string, request: IUpdateIncidentPriorityRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.id}/priority`;
    return this.http.put<IDescribeIncidentResponse>(url, request);
  }

  public addIncidentAnalyst(organizationId: string, request: IAddIncidentAnalystRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/analysts`;
    return this.http.put<IDescribeIncidentResponse>(url, request);
  }

  public deleteIncidentAnalyst(organizationId: string, request: IDeleteIncidentAnalystRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/analysts/${request.userId}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public exportIncidents(request: IListIncidentRequest): Observable<ResponseUtils<IExportIncidentsResponse>> {
    const params = UrlUtils.jsonToQueryParams(request);
    const url = `${this.host}/incidents/export?${params}`;
    return this.http.get<IRespondResponse<IExportIncidentsResponse>>(url)
      .pipe(map((response) => new ResponseUtils<IExportIncidentsResponse>(response)));
  }

  public listSimilarItems(organizationId: string, request: ListIncidentSimilarItemsRequest): Observable<ResponseUtils<SimilarityItem>> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/similarities?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<SimilarityItem>>(url)
      .pipe(map((response) => new ResponseUtils<SimilarityItem>(response)));
  }

  public updateIncidentPhaseStatus(organizationId: string, request: { incidentId: string, phase: IncidentTaskPhases, status: IncidentTaskStatus; organizationId: string }): Observable<IncidentDescription> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/phases/${request.phase}/status`;
    return this.http.post<IncidentDescription>(url, request);
  }

  public updateTaskStatus(organizationId: string, request: { incidentId: string, id: string, status: IncidentTaskStatus; }): Observable<IncidentDescription> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.id}/status`;
    return this.http.put<IncidentDescription>(url, request);
  }

  public addIncidentTask(organizationId: string, request: { playbookTaskId: string; version: number; incidentId: string; taskId: string; before: boolean;}): Observable<IncidentDescription> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks`;
    return this.http.post<IncidentDescription>(url, request);
  }
  
  public moveIncidentTask(organizationId: string, request: { sourceTaskId: string; incidentId: string; destinationTaskId: string; before: boolean;}): Observable<IncidentDescription> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.sourceTaskId}/move`;
    return this.http.post<IncidentDescription>(url, request);
  }

  public updateCustomTask(organizationId: string, request: { incidentId: string, taskId: string, name: ITranslatedField, description: ITranslatedField, instruction: ITranslatedField }): Observable<IncidentDescription> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.taskId}/update`;
    return this.http.post<IncidentDescription>(url, request);
  }

  public deleteIncidentTask(organizationId: string, request: { incidentId: string, taskId: string}): Observable<IncidentDescription> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.taskId}`;
    return this.http.delete<IncidentDescription>(url);
  }

  public updateTask(organizationId: string, request: { incidentId: string, id: string, comment: string; }): Observable<IncidentDescription> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.id}`;
    return this.http.put<IncidentDescription>(url, request);
  }

  public listEscalationParameters(request: IListEscalationParametersRequest): Observable<any> {
    const url = `${this.host}/organizations/${request.organizationId}/escalation/parameters`;
    return this.http.get<any>(url);
  }

  public listIncidentActions(organizationId: string, incidentId: string): Observable<ResponseUtils<IIncidentActionRepresentation>> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/actions`;
    return this.http.get<IRespondResponse<IIncidentActionRepresentation>>(url).pipe(map((response) => new ResponseUtils<IIncidentActionRepresentation>(response)));
  }

  public listIncidentObservables(organizationId: string, incidentId: string): Observable<ResponseUtils<IObservable>> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/observables`;
    return this.http.get<IRespondResponse<IObservable>>(url).pipe(map((response) => new ResponseUtils<IObservable>(response)));
  }

  public listIncidentEntities(organizationId: string, incidentId: string): Observable<ResponseUtils<IEntityRepresentation>> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/entities`;
    return this.http.get<IRespondResponse<IEntityRepresentation>>(url).pipe(map((response) => new ResponseUtils<IEntityRepresentation>(response)));
  }

  public postTaskObservables(organizationId: string, request: IPostTaskObservablesRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/observables`;
    return this.http.post<IDescribeIncidentResponse>(url, request);
  }

  public postTaskEntities(organizationId: string, request: IPostTaskEntitiesRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/entities`;
    return this.http.post<IDescribeIncidentResponse>(url, request);
  }

  public postTaskActions(organizationId: string, request: IPostTaskActionsRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/actions`;
    return this.http.post<IDescribeIncidentResponse>(url, request);
  }

  public postTaskMessages(organizationId: string, request: IPostTaskMessagesRequest): Observable<IDescribeIncidentTask[]> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/findings`;
    return this.http.post<IDescribeIncidentTask[]>(url, request);
  }

  public updateIncidentActions(organizationId: string, request: IUpdateIncidentActionRequest): Observable<void> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/actions`;
    return this.http.put<void>(url, request);
  }

  public deleteTaskObservables(organizationId: string, request: IDeleteTaskObservablesRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/observables/${request.incidentObservableId}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public deleteTaskEntities(organizationId: string, request: IDeleteTaskEntitiesRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/entities/${request.incidentEntityId}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public deleteTaskMessage(organizationId: string, request: IDeleteTaskMessageRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/messages/${request.incidentMessageId}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public deleteTaskActions(organizationId: string, request: IDeleteTaskActionsRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/actions/${request.incidentActionId}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public deleteIncidentAction(organizationId: string, request: IDeleteIncidentActionRequest): Observable<IDescribeIncidentResponse> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${request.incidentId}/actions/${request.incidentActionId}`;
    return this.http.delete<IDescribeIncidentResponse>(url);
  }

  public describeIncidentNotes(organizationId: string, incidentId: string, params?: any): Observable<ResponseUtils<IIncidentMessage>> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/notes?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IRespondResponse<IIncidentMessage>>(url).pipe(map((response) => new ResponseUtils<IIncidentMessage>(response)));
  }

  public postIncidentNote(organizationId: string, incidentId: string, params?: any): Observable<IIncidentMessage[]> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/notes`;
    return this.http.post<IIncidentMessage[]>(url, params);
  }

  public updateIncidentNote(organizationId: string, incidentId: string, params?: any): Observable<IIncidentMessage[]> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/notes`;
    return this.http.put<IIncidentMessage[]>(url, params);
  }

  public deleteIncidentNote(organizationId: string, incidentId: string, noteId: string): Observable<IIncidentMessage[]> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/notes/${noteId}`;
    return this.http.delete<IIncidentMessage[]>(url);
  }

  public updateIncidentEscalationSchedule(organizationId: string, incidentId: string): Observable<void> {
    const url = `${this.host}/organizations/${organizationId}/incidents/${incidentId}/escalation`;
    return this.http.put<void>(url, {});
  }
}
