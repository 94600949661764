import { Component, Input, OnInit } from '@angular/core';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';

@Component({
  selector: 'whitelist-usecase-name',
  templateUrl: './whitelist-usecase-name.component.html',
  styleUrls: [ './whitelist-usecase-name.component.scss' ],
})
export class WhitelistUsecaseNameComponent implements OnInit {
  @Input() whitelist: Whitelist;

  public usecaseNames: string[] = [];

  constructor(private translatedObject: TranslatedObjectPipe) { }

  ngOnInit(): void {
    this.usecaseNames = this.whitelist.usecases?.map((usecase) => `${this.translatedObject.transform(usecase.name)}`.trim());
  }
}
