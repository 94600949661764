import { NgModule } from '@angular/core';
import { SharesRoutingModule } from './shares.routing.module';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { LastAccessedFormatPipe } from 'projects/@assist-ops/pipes/lastAccessedFormat.pipe';
import { ExpositionTranslatePipe } from 'projects/@assist-ops/pipes/expositionTranslate.pipe';
import { OrgSharedFilesContainer } from './containers/org-shared-files/org-shared-files.container';
import { OrgResourcesSharesContainer } from './containers/org-resources-shares/org-resources-shares.container';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { DirectivesModule } from '@common/modules/directives/directives.module';

@NgModule({
  declarations: [
    OrgSharedFilesContainer,
    OrgResourcesSharesContainer,
  ],
  providers: [
    LastAccessedFormatPipe,
    ExpositionTranslatePipe,
  ],
  exports: [],
  imports: [
    SharesRoutingModule,
    AssistOpsModule,
    ...FeatureSharedModule.commonImports(),
    DirectivesModule,
  ],
})
export class SharesModule { }
