<copy-value
  [valueToCopy]="observable.displayValue"
  [title]="observable.displayValue"
>
  <div class="value-cell-container">
    <a
      *ngIf="showAsClickableLink"
      class="url-link"
      target="_blank"
      rel="noopener"
      [href]="observable.displayValue"
      (click)="$event.stopPropagation();"
    >{{observable.displayValue}}</a>

    <span
      *ngIf="!showAsClickableLink"
      class="cell-text"
    >{{observable.displayValue}}</span>
  </div>

</copy-value>