<widget-container
  [title]="'respond.dashboard.incident.var.chart.history.title' | t"
  [locale]="i18n.currentLocale"
  [withBottomMargin]="true"
  [withTimeRange]="true"
  [timeRanges]="TIME_RANGE_ENUM"
  [timeRangesDefault]="currentTimeRange"
  (selectedTimerange)="handleTimeRangeChange($event)">
  <div class="content-container" [style.display]="isLoading ? 'none': 'flex'">
    <app-date-interval class="date-interval-section"
      [period]="dateRange"
      [isGMT]="true"
      dateFormatEnum="shortWithUTC"
    ></app-date-interval>
  
    <!-- Graph -->
    <div class="chart-container">
      <div [style.height]="calculateChartHeight()" [attr.id]="'INCIDENTS_HISTORY_VAR_GRAPH_ID'" [hidden]="chartData.length === 0"></div>
      <div *ngIf="chartData.length === 0" class="graph-without-data-container">
        {{ 'dashboard.admin.noData' | t }}
      </div>
  
      <div class="graph--footer" *ngIf="chartData.length > 0">
        <p class="chart-description">{{ 'respond.dashboard.incident.chart.description' | t}}</p>
        <a routerLink="/respond/incidents" [queryParams]="dateRange" class="chart-link" *display="{ permissions: ['can_list_incidents'] }">
          {{ 'respond.dashboard.incident.var.chart.link' | t }}
        </a>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isLoading">
    <ui-spinner size="medium" modifier="secondary"></ui-spinner>
  </ng-container>
</widget-container>