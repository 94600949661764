<div page [appPagePaths]="['mdRespond', 'dashboard']" [hasTopSection]="true" [searchMode]="searchMode" [withPageHeader]="false"
  (organizationChanged)="organizationChanged($event)" [disableOrganizationSelector]="!isVarMode" [allSelectorCustomValue]="orgSelectorAllValue">

  <h1 class="respond-dashboard-title">{{ 'respond.dashboard.title' | t }}</h1>
  <ui-tabs [color]="uiTabsColor" [borderBelowHeader]="true" [hasWhiteBackroundColor]="false" [buttonContainerHasWhiteBackroundColor]="true" [buttonContainerHasBottomMargin]="true" *ngIf="!isAllOrganizationSelected">
    <ui-tab [tabName]="'incidents'" [tabTitle]="'respond.dashboard.incident.tab.title' | t">
      <ng-template *ngTemplateOutlet="tabUserIncidents"></ng-template>
    </ui-tab>
    <ui-tab [tabName]="'other'" [tabTitle]="'respond.dashboard.data.tab.title' | t">
      <ng-template *ngTemplateOutlet="tabUserData"></ng-template>
    </ui-tab>
  </ui-tabs>

  <div *ngIf="isAllOrganizationSelected" class="var-dashboard-container">
    <incidents-ongoing-card-var
      [currentOrganization]="activeOrganization">
    </incidents-ongoing-card-var>
    
    <incidents-history-var
      [currentOrganization]="activeOrganization">
    </incidents-history-var>

    <incidents-timeline-var
      [currentOrganization]="activeOrganization">
    </incidents-timeline-var>
  </div>

  <div topSection class="top-cards-container" *ngIf="!showEmptyPageToSelectOrganization">
    <capacity-card
      [loading]="!incidentsStats"
      numberColor="#47c1bf"
      [capacityNumber]="incidentsStats?.open || 0"
      [topDescription]="'respond.dashboard.badge.incident.description.top' | t"
      [bottomDescription]="'respond.dashboard.badge.incident.description.bottom' | t"
    ></capacity-card>
    <capacity-card
      [loading]="playbookActivated == null"
      numberColor="#47c1bf"
      [capacityNumber]="playbookActivated"
      [topDescription]="'respond.dashboard.badge.playbook.description.top' | t"
      [bottomDescription]="'respond.dashboard.badge.playbook.description.bottom' | t"
    ></capacity-card>
    <capacity-card
      [loading]="usecaseActivated == null"
      numberColor="#47c1bf"
      [capacityNumber]="usecaseActivated"
      [topDescription]="'respond.dashboard.badge.usecase.description.top' | t"
      [bottomDescription]="'respond.dashboard.badge.usecase.description.bottom' | t"
    ></capacity-card>
    <capacity-card
      [loading]="connectorActivated == null"
      numberColor="#47c1bf"
      [capacityNumber]="connectorActivated"
      [topDescription]="'respond.dashboard.badge.connector.description.top' | t"
      [bottomDescription]="'respond.dashboard.badge.connector.description.bottom' | t"
    ></capacity-card>
  </div>


  <ng-template #tabUserIncidents>
    <ng-container *ngIf="!showEmptyPageToSelectOrganization; else noOrganizationSelectedMessage;">
      <incidents-timeline
        [currentOrganization]="activeOrganization">
      </incidents-timeline>

      <div class="indicators-container">
        <div class="widgets-container">
          <div class="left-section">
            <playbooks-summary class="left-widget" [adm]="isAdmConsole" [activeOrganization]="activeOrganization">
            </playbooks-summary>
          </div>
          <div class="right-section">
            <resolution-statuses class="right-widget" [adm]="isAdmConsole" [activeOrganization]="activeOrganization">
            </resolution-statuses>
          </div>
        </div>
      </div>
    </ng-container>

  </ng-template>
  
  <ng-template #noOrganizationSelectedMessage>
    <span class="no-org-transition">{{ 'organizations_select.notSelected.message' | t }}</span>
  </ng-template>

  <ng-template #tabUserData>
    <ng-container *ngIf="!showEmptyPageToSelectOrganization; else noOrganizationSelectedMessage;">
      <connectors-daily-ingested-data [activeOrganization]="activeOrganization"></connectors-daily-ingested-data>
      <connectors-total-ingested-data [activeOrganization]="activeOrganization" class="container-graph-connectors-total"></connectors-total-ingested-data>
    </ng-container>
  </ng-template>
</div>