<widget-container
  [title]="'dashboard.admin.engagementLevel.tendency.title' | t"
  [withBottomMargin]="true"
  [locale]="locale"
  [withTimeRange]="true"
  [timeRanges]="TIME_RANGE_ENUM"
  [timeRangesDefault]="currentTimeRange"
  [withTitleTooltip]="true"
  (selectedTimerange)="handleTimeRange($event)"
>

  <div tooltip-content class="tooltip">
    <p>{{ 'dashboard.admin.engagementLevel.tendency.tooltip' | t }} </p>
  </div>

  <div *ngIf="data?.length && !isLoading">
    <div class="chart">
      <dynamic-chart
        *ngIf="data?.length && !isLoading"
        [data]="data"
        [type]="CHART_TYPE"
        [key]="KEY"
        [series]="[ENGAGEMENT_LINE, REGRESSION_LINE]"
        [legend]="legend"
        name="admin_enagement_level_tendency"
        [colors]="colors"
        [importantLine]="ENGAGEMENT_LINE"
        [axisFormattingSettings]="axisFormattingSettings"
        chartWidth="350px"
      ></dynamic-chart>
    </div>
  </div>
  <ui-spinner *ngIf="isLoading"></ui-spinner>
  <div
    *ngIf="!data?.length && !isLoading"
    class="admin-tendency-widget--no-history"
  >
    {{ "dashboard.admin.noData" | t }}
  </div>
</widget-container>
