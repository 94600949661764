import { AlertIncidentIndicatorsComponent } from "@common/components/alert-incident-indicators/alert-incident-indicators.component";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { IncidentStatusComponent } from "projects/@common/components/incident-status/incident-status.component";
import { IncidentTableZohoLinkComponent } from "projects/@common/components/incident-table-zoho-link/incident-table-zoho-link.component";
import { PriorityTableComponent } from "projects/@common/components/priority-table/priority-table.component";
import { SeverityBadgeComponent } from "projects/@common/components/severity-badge/severity-badge.component";
import { IncidentReportButtonComponent } from "../../incident-report-button/incident-report-button.component";

export enum IncidentsTableColumnsEnum {
  SHORT_ID = 'shortId',
  NAME = 'name',
  STATUS = 'status',
  INDICATORS = 'indicators',
  ZOHOLINK = 'zoholink',
  PRIORITY = 'priority',
  SEVERITY = 'severity',
  ALERT_COUNT = 'alertCount',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  ORGANIZATION = 'organizationName',
  OWNER = 'ownerName',
  INCIDENT = 'incident'
}

const defaultTableColumns = [
  {
    label: 'incidents.container.page.table.column.id',
    field: IncidentsTableColumnsEnum.SHORT_ID,
    type: UiStaticTableRowType.TEXT,
    width: '152px',
    minWidth: '152px',
    isResizable: false,
  },
  {
    label: 'incidents.container.page.table.column.name',
    field: IncidentsTableColumnsEnum.NAME,
    isTruncated: false,
    width: "22%",
    minWidth: '110px',
    type: UiStaticTableRowType.TEXT,
    isResizable: false,
  },
  {
    label: 'incidents.container.page.table.column.status',
    field: IncidentsTableColumnsEnum.STATUS,
    type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
    component: IncidentStatusComponent,
    paramName: 'data',
    width: '98px',
    minWidth: '98px',
    isResizable: false,
  },
  {
    label: 'incidents.container.page.table.column.indicators',
    field: IncidentsTableColumnsEnum.INDICATORS,
    type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
    component: AlertIncidentIndicatorsComponent,
    paramName: 'incident',
    width: '90px',
    minWidth: '80px',
    isResizable: false,
    noSorting: true,
  },
  {
    label: 'incidents.container.page.table.column.priority.long',
    field: IncidentsTableColumnsEnum.PRIORITY,
    enumPrefix: 'alerts.container.page.table.column.priority.',
    type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
    component: PriorityTableComponent,
    paramName: 'type',
    width: '50px',
    minWidth: '50px',
    isResizable: false,
  },
  {
    label: 'incidents.container.page.table.column.severity',
    field: IncidentsTableColumnsEnum.SEVERITY,
    type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
    component: SeverityBadgeComponent,
    paramName: 'data',
    width: '75px',
    isResizable: false,
  },
  {
    label: 'incidents.container.page.table.column.alerts',
    field: IncidentsTableColumnsEnum.ALERT_COUNT,
    noSorting: true,
    width: '75px',
    type: UiStaticTableRowType.TEXT,
    isResizable: false,
  },
  {
    label: 'incidents.container.page.table.column.date',
    field: IncidentsTableColumnsEnum.CREATED_AT,
    width: '68px',
    minWidth: '68px',
    type: UiStaticTableRowType.DATE_AND_HOUR,
    mediumDate: true,
    isResizable: false,
  },
  {
    label: 'incidents.container.page.table.column.dateUpdated',
    field: IncidentsTableColumnsEnum.UPDATED_AT,
    width: '68px',
    minWidth: '68px',
    noSorting: false,
    type: UiStaticTableRowType.DATE_AND_HOUR,
    mediumDate: true,
    isResizable: false,
  },
  {
    label: 'incidents.container.page.table.column.organization',
    field: IncidentsTableColumnsEnum.ORGANIZATION,
    type: UiStaticTableRowType.TEXT,
    width: '150px',
    minWidth: '100px',
    isResizable: false,
  },
  {
    label: 'incidents.container.page.table.column.owner',
    field: IncidentsTableColumnsEnum.OWNER,
    type: UiStaticTableRowType.TEXT,
    isTruncated: false,
    width: '165px',
    minWidth: '81px',
    noSorting: true,
    isResizable: false,
  },
  {
    label: 'incidents.container.page.table.column.zoholink',
    field: IncidentsTableColumnsEnum.ZOHOLINK,
    type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
    component: IncidentTableZohoLinkComponent,
    paramName: 'incident',
    width: '90px',
    minWidth: '80px',
    isResizable: false,
    noSorting: true,
  },
  {
    label: '',
    field: IncidentsTableColumnsEnum.INCIDENT,
    type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
    component: IncidentReportButtonComponent,
    paramName: 'incidentInfo',
    minWidth: '40px',
    width: '40px',
    showWhenHover: false,
    noSorting: true,
    isResizable: false,
  },
];


export function getIncidentsTableColumns(hiddenColumns: IncidentsTableColumnsEnum[] = [], isAdminView?: boolean): Array<Object> {
  return defaultTableColumns
    .filter((column) => !hiddenColumns.includes(column.field))
    .map((column) => {
      if (isAdminView && column.field === IncidentsTableColumnsEnum.PRIORITY) {
        column.label = 'incidents.container.page.table.column.priority.short';
      }
      return column;
    });
}
