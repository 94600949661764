import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationFrequency } from 'projects/@common/definitions/notificationFrequency.enum';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationSensitiveFilesSettings, OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { CustomValidators } from 'projects/@common/utils/validators';

@Component({
  selector: 'dlp-justification-delay',
  templateUrl: './dlp-justification-delay.component.html',
  styleUrls: ['./dlp-justification-delay.component.scss'],
})
export class DlpJustificationDelayComponent implements OnInit {
  @Input() public currentSettings: OrganizationSensitiveFilesSettings;
  @Input() public isLoading = false;
  public delays = [30, 60, 90, -1]; // -1 means custom value entered by user ;

  public isEditing = false;
  public delayForm: FormGroup;

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private formBuilder: FormBuilder,
    private i18n: I18nService,
    private noticeService: NoticeService
  ) {}

  ngOnInit() {
    this.initForm(this.currentSettings);
  }

  private initForm(currentSettings?: OrganizationSensitiveFilesSettings) {
    const isStandardValue = [30, 60, 90].includes(currentSettings?.justificationDelay);
    this.delayForm = this.formBuilder.group({
      days: isStandardValue ? currentSettings?.justificationDelay : -1,
      custom: !isStandardValue ? currentSettings?.justificationDelay : null
    });
    this.delayForm.get('days').valueChanges.subscribe((value) => {
      if (value === -1) {
        this.delayForm.controls['custom'].setValidators([
          CustomValidators.isLessOrEqualThan(365, 'validJustificationDelayLess'),
          Validators.required,
          CustomValidators.isGreaterThanValue(0, 'validJustificationDelayMore')
        ]);
      } else {
        this.delayForm.controls['custom'].setValidators([]);
      }
      this.delayForm.controls['custom'].updateValueAndValidity();
    });
  }

  public cancel() {
    this.initForm(this.currentSettings);
  }

  public save(): void {
    const justificationDelay =
      this.delayForm.value.days === -1 ? +this.delayForm.value.custom : this.delayForm.value.days;
    this.organizationsApiService
      .updateOrganizationSettings({ newDlpSettings: { justificationDelay } })
      .then((response) => {
        this.currentSettings = response;
        this.initForm(response);
        this.noticeService.notifyUser({
          message: 'settings.downloads.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.downloads.snack.error',
          level: NoticeLevels.ERROR,
        });
      });
  }

  public get errorMessage(): string {
    try {
      const errors = Object.entries(this.delayForm.get('custom').errors);
      return errors?.length ? `form.validation.${errors[0][0]}` : null;
    } catch {}
  }

  public get isValid() {
    return this.delayForm.valid;
  }

  public restoreDefaults() {
    this.delayForm.patchValue({
      frequency: 1,
    });
  }
}
