import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { IRespondResponse } from '../../../../definitions/IRespondResponse';
import { EcoUrlService } from '../../core/url.service';
import { CustomerPlaybooksApiService } from './customer-playbooks.api';
import { IGetPlaybookQuery, IGetPlaybookTemplateQuery, IListPlaybooksRequest, IListPlaybooksTemplateQuery, IPlaybookDetail, IPlaybookListResponse, IPlaybookTaskTemplateRepresentation, IPlaybookTemplate, IPlaybooksApi, getPlaybookTaskTemplatesRequest } from './playbooks.definitions';
import { VarPlaybooksApiService } from './var-playbooks.api';

@Injectable({
  providedIn: 'root',
})
export class PlaybooksApiService implements IPlaybooksApi {
  private customerService: CustomerPlaybooksApiService;
  private varService: VarPlaybooksApiService;

  constructor(
    protected store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerPlaybooksApiService(http, url);
    this.varService = new VarPlaybooksApiService(http, url);
  }

  public getPlaybookTemplateById(templateId: string, queryParams?: IGetPlaybookTemplateQuery): Promise<IPlaybookTemplate> {
    if (this.isVarMode) {
      return this.varService.getPlaybookTemplateById(templateId, queryParams);
    }
    return this.customerService.getPlaybookTemplateById(templateId, queryParams);
  }

  public getPlaybookTemplates(queryParams?: IListPlaybooksTemplateQuery): Promise<IRespondResponse<IPlaybookTemplate>> {
    if (this.isVarMode) {
      return this.varService.getPlaybookTemplates(queryParams);
    }
    return this.customerService.getPlaybookTemplates(queryParams);
  }

  public getPlaybookTaskTemplates(params?: getPlaybookTaskTemplatesRequest): Promise<IRespondResponse<IPlaybookTaskTemplateRepresentation>> {
    if (this.isVarMode) {
      return this.varService.getPlaybookTaskTemplates(params);
    }
    return this.customerService.getPlaybookTaskTemplates(params);
  }

  public getPlaybooks(organizationId: string, queryParam?: IListPlaybooksRequest): Promise<IRespondResponse<IPlaybookListResponse>> {
    if (this.isVarMode) {
      return this.varService.getPlaybooks(organizationId, queryParam);
    }
    return this.customerService.getPlaybooks(organizationId, queryParam);
  }

  public getPlaybook(organizationId: string, playbookId: string, queryParams?: IGetPlaybookQuery): Promise<IPlaybookDetail> {
    if (this.isVarMode) {
      return this.varService.getPlaybook(organizationId, playbookId, queryParams);
    }
    return this.customerService.getPlaybook(organizationId, playbookId, queryParams);
  }

  public createPlaybook(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail> {
    if (this.isVarMode) {
      return this.varService.createPlaybook(organizationId, playbook);
    }
    return this.customerService.createPlaybook(organizationId, playbook);
  }

  public updatePlaybook(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail> {
    if (this.isVarMode) {
      return this.varService.updatePlaybook(organizationId, playbook);
    }
    return this.customerService.updatePlaybook(organizationId, playbook);
  }

  public deletePlaybook(organizationId: string, playbookId: string): Promise<any> {
    if (this.isVarMode) {
      return this.varService.deletePlaybook(organizationId, playbookId);
    }
    return this.customerService.deletePlaybook(organizationId, playbookId);
  }

  public updatePlaybookVersion(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail> {
    if (this.isVarMode) {
      return this.varService.updatePlaybookVersion(organizationId, playbook);
    }
    return this.customerService.updatePlaybookVersion(organizationId, playbook);
  }

  public getPlaybookStats(organizationId: string) {
    if (this.isVarMode) {
      return this.varService.getPlaybookStats(organizationId);
    }
    return this.customerService.getPlaybookStats(organizationId);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
