<ng-container [ngSwitch]="formConfig?.typeOfForm">
  <ng-container *ngSwitchCase="uiTypeOfFormEnum.TEXT_INPUT">
    <ng-container *ngTemplateOutlet="input; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.LONG_TEXT_INPUT">
    <ng-container *ngTemplateOutlet="stdTextarea; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.NUMBER_INPUT">
    <ng-container *ngTemplateOutlet="input; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.OBJECT_TEXTAREA">
    <ng-container *ngTemplateOutlet="textarea; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.ARRAY_TEXTAREA">
    <ng-container *ngTemplateOutlet="textarea; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.CHECKBOX">
    <ng-container *ngTemplateOutlet="checkbox; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.FILE_UPLOAD_INPUT">
    <ng-container *ngTemplateOutlet="fileUpload; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.DROPDOWN">
    <ng-container *ngTemplateOutlet="dropdown; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.MULTI_SELECT">
    <ng-container
      *ngTemplateOutlet="multiSelect; context: { config: formConfig }"
    >
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.DATE_INPUT">
    <ng-container
      *ngTemplateOutlet="dateTimePicker; context: { config: formConfig }"
    >
    </ng-container>
  </ng-container>

  <ng-template #fileUpload let-config="config">
    <ui-field-group class="uploader-field-group" layout="vertical">
      <!-- Cancel button and hide uploader -->
      <button
        [title]="'common.action.cancel' | t"
        class="drawer--buttons--close"
        ui-button
        color="drawerCloseFlat"
        icon="close"
        icon-size="medium"
        (click)="toggleFileUploadVisibility()"
      ></button>
      <!-- Input File, accepting only .pem or .crt files -->
      <ui-uploader
        [label]="config.label | translatedObject"
        [required]="config.required"
        [group]="config.group"
        [accept]="'.pem,.crt'"
        (fileChange)="fileChangeSubject.next($event)"
      ></ui-uploader>
    </ui-field-group>
  </ng-template>

  <ng-template #dateTimePicker let-config="config">
    <ui-field-group layout="vertical">
      <ui-datetime-picker
        #datetimePicker
        [required]="config.required"
        [defaultTimestamp]="config.values"
        [label]="config.label | translatedObject"
        [withHours]="false"
        [isReadonly]="false"
        [showNowButton]="false"
        [showResetButton]="true"
        [minDate]="minDate"
      ></ui-datetime-picker>
    </ui-field-group>
  </ng-template>

  <ng-template #input let-config="config">
    <ui-field-group layout="vertical">
      <ui-field
        [required]="config.required"
        [label]="config.label | translatedObject"
        [maxlength]="
          config.typeOfForm === uiTypeOfFormEnum.NUMBER_INPUT ? 6 : null
        "
        [type]="
          config.typeOfForm === uiTypeOfFormEnum.NUMBER_INPUT
            ? 'number'
            : 'text'
        "
        [name]="config.controlName"
        [readonly]="readonly"
        [control]="config.controlName"
        [group]="config.group"
      >
      </ui-field>
      <label ui-label *ngIf="config.description">{{
        config.description | translatedObject
        }}</label>
    </ui-field-group>
  </ng-template>

  <ng-template #stdTextarea let-config="config">
    <ui-field-group layout="vertical">
      <ui-switch-text-static
        [isEditing]="true"
        class="static-container"
        [label]="config.label | translatedObject"
        name="config.controlName"
        control="{{ config.controlName }}"
        [group]="config.group"
        [rowsNumber]="8"
        [required]="config.required"
        maxlength="2550"
        value="{{ config.values }}"
      ></ui-switch-text-static>
      <label ui-label
             *ngIf="config.description">{{
          config.description | translatedObject
        }}</label>
    </ui-field-group>
  </ng-template>

  <ng-template #textarea let-config="config">
    <ui-field-group layout="vertical">
      <json-textarea
        [type]="
          config.typeOfForm === uiTypeOfFormEnum.OBJECT_TEXTAREA
            ? textareaTypeEnum.JSON
            : textareaTypeEnum.ARRAY
        "
        [controlName]="config.controlName"
        [formGroup]="config.group"
        [readonly]="readonly"
        [label]="config.label | translatedObject"
        [description]="config.description | translatedObject"
        [required]="config.required"
        [prettifyButton]="!readonly"
      >
      </json-textarea>
    </ui-field-group>
  </ng-template>

  <ng-template #checkbox let-config="config">
    <ui-field-group layout="vertical">
      <ui-checkbox
        [label]="config.label | translatedObject"
        [isEditable]="true"
        [controlName]="config.controlName"
        [group]="config.group"
      >
      </ui-checkbox>
      <label ui-label>{{ config.description | translatedObject }}</label>
    </ui-field-group>
  </ng-template>

  <ng-template #dropdown let-config="config">
    <ui-field-group layout="vertical">
      <select-with-label-value
        [label]="config.label | translatedObject"
        [description]="config.description"
        [name]="config.controlName"
        [required]="config.required"
        [controlName]="config.controlName"
        [group]="config.group"
        [values]="config.values"
        (onSelect)="onValueSelection($event)"
      >
      </select-with-label-value>
    </ui-field-group>
  </ng-template>

  <ng-template #multiSelect let-config="config">
    <ui-field-group layout="vertical">
      <dynamic-multi-simple-select
        [selectableValues]="config.values"
        [title]="config.label"
        [formGroup]="config.group"
        [controlName]="config.controlName"
      ></dynamic-multi-simple-select>
    </ui-field-group>
  </ng-template>
</ng-container>
