<ui-table-total-tool
  [locale]="locale"
  [total]="actifsService.isLoading ? '-' : total"
></ui-table-total-tool>

<ui-table-search-tool
  class="search-text"
  [placeholder]="'ui.table-search-tool.placeholder' | t"
  [query]="actifsService.listRequest.searchText"
  (query)="handleSearchTextChange($event)"
  (clear)="handleSearchTextChange(null)"
></ui-table-search-tool>

<ui-multi-select
  *ngIf="viewMode === listViewModeEnum.SIMPLE_LIST"
  [multiSelectData]="typesMultiSelect"
  (onOptionSelect)="handleTypesFilterChange($event)"
>
</ui-multi-select>

<ui-table-filter-tool
  class="view-mode-selector"
  [tableFilterData]="listViewModes"
  (select)="handleViewModeChange($event)"
></ui-table-filter-tool>

<ui-table-refresh-tool
  [loading]="actifsService.isLoading"
  (click)="handleRefreshClicked()"
></ui-table-refresh-tool>