<app-incident-details-base-layout
  [title]="'incidents.container.page.details.tab.detail.affectedResources.title' | t"
  [uiTooltip]="sectionTooltip"
>
  <div
    class="affected-resources-edit-container"
    [ngClass]="{
      'pointer':!isEditing,
      'disabled':!canEditAffectedResources,
      'shouldShowUnsavedWarning':shouldShowUnsavedWarning
    }"
    [tooltip]="isEditing ? null : 'common.action.edit' | t"
    (click)="!isEditing && setEditing(true)"
  >
    <div class="flex-box column">
      <label
        *ngIf="isEditing"
        ui-label
      >
        <span>{{'incidents.container.page.details.tab.detail.affectedResources.template'|t}}&nbsp;:</span>
        <span>{{affectedResourcesTemplate}}</span>
      </label>
      <div class="flex-box space-between">
        <usecase-affected-resources
          [formGroup]="formGroup"
          [controlName]="'affectedResources'"
          [value]="currentIncidentAffectedResources"
          [locale]="i18n.currentLocale"
          [isEditing]="isEditing"
          [showLabel]="false"
        ></usecase-affected-resources>
        <img
          *ngIf="!isEditing"
          alt="edit-icon"
          class="edit-icon"
          src="assets/favicons/icon_modify_disabled.svg"
        />
      </div>
      <p
        *ngIf="shouldShowUnsavedWarning"
        class="confirm-change"
      >{{ "common.action.confirm.change" | t }}</p>
    </div>
  </div>
  <ui-confirmations-buttons
    *ngIf="isEditing"
    [marginTop]="false"
    [isConfirmEnabled]="isValid"
    (confirmationEvent)="handleEditConfirmation($event)"
  ></ui-confirmations-buttons>
</app-incident-details-base-layout>