import { HttpClient } from '@angular/common/http';
import { IRespondResponse } from 'projects/@common/definitions/IRespondResponse';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { IDatasourceUsecaseInfo } from 'projects/@common/services/api/respond/datasources/datasources.definitions';
import { IFilterBaseRepresentation } from 'projects/@common/services/api/respond/filters/filters.definitions';
import { AlertingUsecaseListItem, IAlertingUsecaseTemplate, IDatasourceType, IListAlertingUsecasesRequest, ISystemListUsecaseTemplatesResponse, IUsecase, IUsecaseApi, IUsecaseDescriptionResponse, IUsecaseResponse, IUsecaseStatsResponse, UsecaseCreationInterface, UsecaseCreationResponse } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { UrlUtils } from 'projects/@common/utils/utils';

export class VarUsecasesApiService extends EcoApi implements IUsecaseApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  constructor(http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public getOrganizationsUsecaseList(
    queryParam: IListAlertingUsecasesRequest
  ): Promise<IRespondResponse<AlertingUsecaseListItem>> {
    const url = `${super.host}/organizations/usecases?${UrlUtils.jsonToQueryParams(queryParam)}`;
    return this.http.get<IRespondResponse<AlertingUsecaseListItem>>(url).toPromise();
  }

  public getUsecaseList(
    organizationId: string,
    queryParam: IListAlertingUsecasesRequest
  ): Promise<IRespondResponse<AlertingUsecaseListItem>> {
    const url = `${super.host}/organizations/${organizationId}/usecases?${UrlUtils.jsonToQueryParams(queryParam)}`;
    return this.http.get<IRespondResponse<AlertingUsecaseListItem>>(url).toPromise();
  }

  public createUsecase(
    organizationId: string,
    usecase: Partial<UsecaseCreationInterface>
  ): Promise<UsecaseCreationResponse> {
    const url = `${super.host}/organizations/${organizationId}/usecases`;
    return this.http.post<UsecaseCreationResponse>(url, usecase).toPromise();
  }

  public updateUsecase(organizationId: string, usecase: any): Promise<UsecaseCreationResponse> {
    const url = `${this.host}/organizations/${organizationId}/usecases/${usecase.id}`;
    return this.http.put<UsecaseCreationResponse>(url, usecase).toPromise();
  }

  public updateOutdatedUsecase(usecase: any): Promise<IUsecase> {
    const url = `${super.host}/system/usecases/outdated/${usecase.id}/update`;
    return this.http.put<IUsecase>(url, {}).toPromise();
  }

  public updateOutdatedUsecasePipeline(usecase: any): Promise<IUsecase> {
    const url = `${super.host}/system/usecases/outdated/pipeline/${usecase.id}/update`;
    return this.http.put<IUsecase>(url, {}).toPromise();
  }

  public updateUsecaseParameters(organizationId: string, parameters: any): Promise<UsecaseCreationResponse> {
    const url = `${super.host}/organizations/${organizationId}/usecases/${parameters.id}/parameters`;
    return this.http.put<UsecaseCreationResponse>(url, parameters).toPromise();
  }

  public listUsecaseTemplates(parameters: any): Promise<ISystemListUsecaseTemplatesResponse> {
    const url = `${super.host}/system/usecaseCatalog?${UrlUtils.jsonToQueryParams(parameters)}`;
    return this.http.get<ISystemListUsecaseTemplatesResponse>(url).toPromise();
  }

  public getUsecaseTemplate(usecaseId: string): Promise<Array<{ name: string; description: ITranslatedField; }>> {
    const url = `${super.host}/system/usecaseCatalog/${usecaseId}`;
    return this.http.get<Array<{ name: string; description: ITranslatedField; }>>(url).toPromise();
  }

  public getUsecase(organizationId: string, usecaseId: string): Promise<IUsecaseDescriptionResponse> {
    const url = `${this.host}/organizations/${organizationId}/usecases/${usecaseId}`;
    return this.http.get<IUsecaseDescriptionResponse>(url).toPromise();
  }

  public getUsecaseActions(): Promise<any> {
    const url = `${super.host}/actions`;
    return this.http.get<any>(url).toPromise();
  }

  public deleteUsecase(
    organizationId: string,
    usecaseId: string
  ): Promise<{ deleted: boolean; organizationId: string; usecaseId: string; }> {
    const url = `${super.host}/organizations/${organizationId}/usecases/${usecaseId}`;
    return this.http.delete<{ deleted: boolean; organizationId: string; usecaseId: string; }>(url).toPromise();
  }

  public getUsecasesCatalog(params: {
    from?: number;
    size?: number;
    activated?: string;
    datasourceType?: string;
    organizationId?: string;
    orderColumn?: string;
    order?: string;
  } = {}): Promise<IRespondResponse<IUsecaseResponse>> {
    const url = `${super.host}/system/usecasecatalog?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IRespondResponse<IUsecaseResponse>>(url).toPromise();
  }

  public getUsecaseCatalog(usecaseId: string): Promise<IUsecaseResponse> {
    const url = `${super.host}/system/usecasecatalog/${usecaseId}`;
    return this.http.get<IUsecaseResponse>(url).toPromise();
  }

  public async getDatasourceFamilyInfo(organizationId: string): Promise<IDatasourceUsecaseInfo[]> {
    return this.http.get<IDatasourceUsecaseInfo[]>(`${super.host}/organizations/${organizationId}/usecases/families`).toPromise();
  }

  public async getAggregatedUsecasesList(organizationId: string, queryParam?: any): Promise<IRespondResponse<IAlertingUsecaseTemplate>> {
    const url = `${super.host}/organizations/${organizationId}/usecases/aggregated${this.getQueryParamsString(queryParam)}`;
    return this.http.get<IRespondResponse<IAlertingUsecaseTemplate>>(url).toPromise();
  }

  public getUsecaseFilters(organizationId: string, usecaseId: string): Promise<IRespondResponse<IFilterBaseRepresentation>> {
    const url = `${super.host}/system/organizations/${organizationId}/usecases/${usecaseId}/filters`;
    return this.http.get<IRespondResponse<IFilterBaseRepresentation>>(url).toPromise();
  }

  public getUsecaseStats(organizationId: string): Promise<IUsecaseStatsResponse> {
    const url = `${super.host}/organizations/${organizationId}/usecases/stats`;
    return this.http.get<IUsecaseStatsResponse>(url).toPromise();
  }

  private getQueryParamsString(queryParam: any): string {
    return queryParam && Object.keys(queryParam).length > 0 ? `?${UrlUtils.jsonToQueryParams(queryParam)}` : '';
  }

  getDatasourcesDataTypes(): Promise<IRespondResponse<IDatasourceType>> {
    const url = `${this.host}/datasources/dataTypes`;
    return this.http.get<IRespondResponse<IDatasourceType>>(url).toPromise();
  }
}
