import { CdkDragDrop, CdkDropList, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { IPlaybookTaskTemplateRepresentation, PlaybookTaskPhases } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { clone } from 'projects/@common/utils/utils';
import { Playbook } from '../../../containers/playbook-update/models/Playbook';
import { PlaybookVersionDragDrop } from '../../../containers/playbook-update/models/PlaybookVersionDragDrop';
import { IPhaseDropRegion, IPhaseDropRegionTask, PlaybookVersionKeyEnum } from '../../../containers/playbook-update/models/playbook-update.definitions';

@Component({
  selector: 'app-playbook-update-version-comparator',
  templateUrl: './playbook-update-version-comparator.component.html',
  styleUrls: [ './playbook-update-version-comparator.component.scss' ],
})
export class PlaybookUpdateVersionComparatorComponent implements OnInit {
  @Input() private readonly oldPlaybook: Playbook;
  @Input() private readonly newPlaybook: Playbook;

  @Input() private readonly mdTaskTemplates: IPlaybookTaskTemplateRepresentation[];

  public oldPlaybookVersion: PlaybookVersionDragDrop;
  public newPlaybookVersion: PlaybookVersionDragDrop;

  public playbookVersions: PlaybookVersionDragDrop[];
  public isValidNewPlaybook: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.initPlaybookVersions(this.oldPlaybook, this.newPlaybook);
    this.setTasksConditions();
    this.validateNewPlaybookVersion();
  }

  public getUpdatedPlaybook(): Playbook {
    this.validateNewPlaybookVersion();
    return new Playbook({
      ...this.newPlaybook,
      playbookTasks: this.newPlaybookVersion.getTasks(),
    });
  }

  public togglePhases(clickedPhase: IPhaseDropRegion): void {
    this.oldPlaybookVersion.togglePhase(clickedPhase);
    this.newPlaybookVersion.togglePhase(clickedPhase);
    this.setPhaseElementsHeight(clickedPhase.name);
  }

  public toggleTasks(clickedTask: IPhaseDropRegionTask): void {
    this.oldPlaybookVersion.toggleTask(clickedTask);
    this.newPlaybookVersion.toggleTask(clickedTask);
    this.setPhaseElementsHeight(clickedTask.phase);
  }

  public isPhaseComplete(phaseName: PlaybookTaskPhases): boolean {
    const oldTasks = this.oldPlaybookVersion.getTasksInSpecificPhase(phaseName);
    const newTasks = this.newPlaybookVersion.getTasksInSpecificPhase(phaseName);
    return oldTasks.filter((task) => !task.managed).every((task) => this.isTaskIncluded(task, newTasks));
  }

  public hasNewTasksInPhase(phaseName: PlaybookTaskPhases): boolean {
    const newTasks = this.newPlaybookVersion.getTasksInSpecificPhase(phaseName);
    return newTasks.some((task) => task.isNewTaskInTemplate);
  }

  public hasDeletedTasksInPhase(phaseName: PlaybookTaskPhases): boolean {
    const oldTasks = this.oldPlaybookVersion.getTasksInSpecificPhase(phaseName);
    return oldTasks.some((task) => task.wasDeletedFromTemplate);
  }

  private initPlaybookVersions(oldPlaybook: Playbook, newPlaybook: Playbook): void {
    this.oldPlaybookVersion = new PlaybookVersionDragDrop({
      key: PlaybookVersionKeyEnum.OLD_PLAYBOOK,
      playbook: oldPlaybook,
      mdTaskTemplates: this.mdTaskTemplates,
      dragParams: {
        cdkDropListSortingDisabled: true,
        cdkDragLockAxis: null,
        dropHandler: () => null,
      },
    });

    this.newPlaybookVersion = new PlaybookVersionDragDrop({
      key: PlaybookVersionKeyEnum.NEW_PLAYBOOK,
      playbook: newPlaybook,
      mdTaskTemplates: this.mdTaskTemplates,
      dragParams: {
        cdkDropListSortingDisabled: false,
        cdkDragLockAxis: "y",
        dropHandler: this.handleDropEventInNewPlaybook.bind(this),
      },
    });

    this.playbookVersions = [
      this.oldPlaybookVersion,
      this.newPlaybookVersion,
    ];
  }

  private isTaskIncluded(task: IPhaseDropRegionTask, tasks: IPhaseDropRegionTask[]): boolean {
    return Boolean(tasks.find((t) => t.id === task.id && t.phase === task.phase));
  }

  private hasIncompletePhases(): boolean {
    return this.newPlaybookVersion.phaseDropRegions.some((phase) => !this.isPhaseComplete(phase.name));
  }

  private validateNewPlaybookVersion(): void {
    this.isValidNewPlaybook = !this.hasIncompletePhases();
  }

  private setTasksConditions(): void {
    const allPhaseNames = Object.values(PlaybookTaskPhases);
    allPhaseNames.forEach((phaseName) => {
      const oldTasks = this.oldPlaybookVersion.getTasksInSpecificPhase(phaseName);
      const updatedTasks = this.newPlaybookVersion.getTasksInSpecificPhase(phaseName);
      oldTasks.forEach((task) => {
        const includedInNewVersion = this.isTaskIncluded(task, updatedTasks);
        task.isDragEnabled = !task.managed && !includedInNewVersion;
        task.wasDeletedFromTemplate = task.managed && !includedInNewVersion;
        task.isNewTaskInTemplate = false;
      });
      updatedTasks.forEach((task) => {
        const includedInOldVersion = this.isTaskIncluded(task, oldTasks);
        task.isDragEnabled = !task.managed;
        task.wasDeletedFromTemplate = false;
        task.isNewTaskInTemplate = task.managed && !includedInOldVersion;
      });
    });
  }

  private setPhaseElementsHeight(phaseName: PlaybookTaskPhases): void {
    setTimeout(() => {
      let biggestHeight = 0;
      const phaseElements = Array.from(document.querySelectorAll(`[name='${phaseName}']`));

      // find which element height is bigger than the other
      phaseElements.forEach((phaseElement: HTMLElement) => {
        phaseElement.style.minHeight = "unset";
        const thisPhaseHeight = phaseElement.clientHeight;
        biggestHeight = thisPhaseHeight > biggestHeight ? thisPhaseHeight : biggestHeight;
      });

      // apply the same height style on both phase elements
      phaseElements.forEach((phaseElement: HTMLElement) => {
        phaseElement.style.minHeight = `${biggestHeight}px`;
      });
    }, 10);
    // set timeout to ensure the DOM has properly rendered the phase elements before getting their client height
  }

  private setTasksOrder(tasks: IPhaseDropRegionTask[]): void {
    tasks.forEach((task, index) => task.order = index);
  }

  private onDropCompleted(cdkDropList: CdkDropList): void {
    const tasks = cdkDropList.data;
    this.setTasksOrder(tasks);
    this.setTasksConditions();
    this.validateNewPlaybookVersion();
  }

  private handleDropEventInNewPlaybook(event: CdkDragDrop<any>): void {
    const droppedTask = event.item.data;

    const dragRegion = event.previousContainer;
    const dropRegion = event.container;

    const dragFromId = dragRegion.id;
    const dropToId = dropRegion.id;

    const isDroppedFromOldPlaybook = Boolean(dragFromId.startsWith("OLD_PLAYBOOK") && dropToId.startsWith("NEW_PLAYBOOK"));
    const isMovedWithinNewPlaybook = Boolean(dragFromId === dropToId);

    if (isDroppedFromOldPlaybook) {
      const taskAlreadyExists = Boolean(dropRegion.data.find((task) => task.id === droppedTask.id));
      if (!taskAlreadyExists) {
        copyArrayItem(
          clone(dragRegion.data), // if we don't clone it, the same object reference is used in both playbooks which breaks the synced task expand toggle
          dropRegion.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    } else if (isMovedWithinNewPlaybook) {
      moveItemInArray(dropRegion.data, event.previousIndex, event.currentIndex);
    } else {
      return;
    }

    this.onDropCompleted(dropRegion);
  }
}
