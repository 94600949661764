import { Component, Input, OnInit } from '@angular/core';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { IMultiSelectData } from '@ui-kit/components/ui-multi-select/ui-multi-select.component';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { Eco } from 'projects/@common/definitions/eco';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { IAssetPropertyConfigRepresentation, ActifsListViewModeEnum } from 'projects/@common/services/api/respond/actifs/actifs.definitions';
import { normalizeString } from 'projects/@common/utils/utils';
import { ActifsService } from '../../actifs.service';

@Component({
  selector: 'actifs-filters',
  templateUrl: './actifs-filters.component.html',
  styleUrls: [ './actifs-filters.component.scss' ],
})
export class ActifsFiltersComponent implements OnInit {
  @Input() public total: number = 0;

  public locale: Eco.Languages;
  public listViewModeEnum = ActifsListViewModeEnum;
  public typesMultiSelect: MultiSelectData;
  public listViewModes: TableFilterData;

  constructor(
    public readonly actifsService: ActifsService,
    private readonly i18n: I18nService,
    private readonly translatedObjectPipe: TranslatedObjectPipe,
    private readonly tableFilterToolDataFactory: TableFilterToolDataFactory
  ) { }

  ngOnInit(): void {
    this.locale = this.i18n.currentLocale as Eco.Languages;
    this.initTypesSelect();
    this.initViewModeSelect();
  }

  public get viewMode(): ActifsListViewModeEnum {
    return this.actifsService.listViewMode;
  }

  public handleRefreshClicked(): void {
    this.actifsService.handleDebouncedFetch();
  }

  public handleSearchTextChange(searchText: string): void {
    this.actifsService.setSearchText(normalizeString(searchText));
  }

  public handleTypesFilterChange(types: Array<string>): void {
    this.actifsService.setTypes(types);
  }

  public handleViewModeChange(viewMode: ActifsListViewModeEnum): void {
    this.initTypesSelect();
    this.actifsService.setListViewMode(viewMode);
  }

  private initTypesSelect(): void {
    const choices: Array<IMultiSelectData> = [];
    const translatePrefix = "actifs.list.filter.assetTypes.";

    const defaultValue = 'ALL';
    choices.push({
      option: null,
      isDefaultValue: true,
      isSelected: !choices.some((choice) => choice.isSelected),
      translated: this.i18n.translate(`${translatePrefix}${defaultValue}.filter`),
      translatedShort: this.i18n.translate(`${translatePrefix}${defaultValue}.filter.translatedShort`, null, ""),
    });

    choices.push(...this.actifsService.assetTypes.map((assetType: IAssetPropertyConfigRepresentation) => ({
      option: assetType.type,
      isDefaultValue: false,
      isSelected: !!this.actifsService.listRequest.types?.includes(assetType.type),
      translated: this.translatedObjectPipe.transform(assetType.config.name),
      translatedShort: this.i18n.translate(`actifs.list.filter.assetTypes.${assetType.type}.filter.translatedShort`, null, ""),
    })));

    this.typesMultiSelect = new MultiSelectData(choices);
  }

  private initViewModeSelect(): void {
    this.listViewModes = this.tableFilterToolDataFactory.create(ActifsListViewModeEnum, 'actifs.list.listViewModes.', this.viewMode, true);
  }
}
