import { Component, Input, OnInit } from "@angular/core";

export type PermissionBadge = {
  color: string; 
  label: string;
  tooltip?: string;
}

@Component({
    selector: 'permission-badge',
    templateUrl: './permission-badge.component.html',
    styleUrls: [ './permission-badge.component.scss' ],
  })
  export class PermissionBadgeComponent implements OnInit {
    @Input() public value: PermissionBadge;

    constructor() { }
  
    ngOnInit(): void { 
    }
  }