<detail-layout [backLink]="backLink">
  <ng-container extraNavButtons>
    <button
      *ngIf="canUpdatePlaybookVersion && isOutdated"
      ui-button
      color="lightGreyFlat"
      icon="icon_sync"
      [custom-icon]="true"
      [isSvg]="true"
      icon-size="small"
      icon-margin="0 1px 0 0"
      [tooltip]="'detection.playbook.detail.updatePlaybookVersion.tooltip' | t"
      (click)="navigateToPlaybookVersionUpdate()"
    >
      {{ 'detection.playbook.detail.updatePlaybookVersion' | t }}
    </button>
  </ng-container>

  <playbook-detail-tab-header
    *ngIf="playbook || template"
    [tabsToHide]="viewParams.tabsToHide"
    [showHeaderOrgInfo]="viewParams.showHeaderOrgInfo"
    [isReadonly]="!canUpdatePlaybook"
    [playbook]="playbook"
    [template]="template"
    [playbookDetailForm]="playbookDetailForm"
    [organizationName]="playbook?.organizationName"
    topCard
  ></playbook-detail-tab-header>

  <ng-container
    bottomCard
    [ngSwitch]="playbookTabService.currentTabSelected"
  >
    <ui-card *ngIf="(playbook || template) && playbookTabService.currentTabSelected !== playbookTabsEnum.TASK">
      <app-playbook-info-details
        *ngSwitchCase="playbookTabsEnum.DETAILS"
        [playbook]="playbook"
        [playbookDetailForm]="playbookDetailForm"
        [template]="template"
        [showTemplateInfo]="viewParams.showTemplateInfo"
        [isReadonly]="!canUpdatePlaybook"
        [playbookAttackData]="mitreAttackData"
      >
      </app-playbook-info-details>

      <app-playbook-detail-notes
        *ngSwitchCase="playbookTabsEnum.NOTES"
        [playbookDetailForm]="playbookDetailForm"
        [playbook]="playbook"
        [isReadonly]="!canUpdatePlaybook"
      >
      </app-playbook-detail-notes>

      <app-playbook-detail-usecases
        *ngSwitchCase="playbookTabsEnum.USECASES"
        [usecases]="playbookUsecases"
      >
      </app-playbook-detail-usecases>
      <app-playbook-history
        *ngSwitchCase="playbookTabsEnum.HISTORY"
        [playbook]="playbook"
      >
      </app-playbook-history>
    </ui-card>
    <ng-container *ngIf="playbook && playbookTabService.currentTabSelected === playbookTabsEnum.TASK">
      <playbook-tasks-drag-and-drop
        *ngSwitchCase="playbookTabsEnum.TASK"
        [mdTasks]="mdTasks"
        [playbook]="playbook"
        [organizationId]="organizationEcoId"
        [showMdTasksLibrary]="viewParams.showMdTasksLibrary"
        [isReadonly]="!canUpdatePlaybook"
        [showTaskInstructions]="viewParams.showTaskInstructions"
        [isSystemAPICall]="viewParams.isSystemAPICall"
        (onTypeFilterChange)="onTypeFilterChange($event)"
        (handleLoadMoreEmitter)="handleLoadMore()"
      >
        <div
          *ngIf="canUpdatePlaybook"
          ngProjectAs="footer"
        >
          <ui-card
            [withShadow]="false"
            [withPadding]="true"
            [isHoverable]="false"
            [hasLargeBorder]="false"
          >
            <div class="button-save-container">
              <button
                ui-button
                color="standard"
                (click)="update()"
                [disabled]="!hasTaskChanged() || isSaving"
              >
                {{ 'common.action.save' | t }}
              </button>
            </div>
          </ui-card>
        </div>
      </playbook-tasks-drag-and-drop>
    </ng-container>
  </ng-container>
</detail-layout>