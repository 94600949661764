import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Select, Store } from '@ngxs/store';
import { ConfirmationModalComponent } from '@ui-kit/components/confirmation-modal/confirmation-modal.component';
import { HtmlEditorComponent } from '@ui-kit/components/html-editor/html-editor.component';
import { ModalService } from '@ui-kit/services/modal.service';
import { ITabsInfo } from 'projects/@common/components/custom-tabs/custom-tabs.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SnackbarService } from 'projects/@common/modules/notice/implementations/snackbar/snackbar.service';
import { Notice, NoticeLevels } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { NotificationsApiService } from 'projects/@common/services/api/notifications/notifications-api.service';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { IComputedEscalation, IIncidentMessage, IncidentStatus } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { HandleBarUtils } from 'projects/@common/utils/handlebars-utils';
import { Observable } from 'rxjs';
import { TabNamesEnum } from '../../../incidents-details.component';
import { Buffer } from 'buffer';
import { IncidentTimestampType, SystemTimestampTypes, UserTimestampTypes } from '../../chronology-tab/definitions/chronologyTimestamp.definitions';

export interface IMessage {
  id: string;
  content: string;
  conversationId: string;
  senderName: string;
  senderId: string;
  metadata: any;
  sentAt: number;
}
@Component({
  selector: 'chat-conversations',
  templateUrl: './chat-conversations.component.html',
  styleUrls: [ './styles/chat-conversations.component.scss' ],
})
export class ChatConversationsComponent implements OnInit, OnDestroy {
  @ViewChild('editor') editorComponent: HtmlEditorComponent;

  @Input() isFromAdmConsole: boolean;

  @Input() incidentConversation: IIncidentMessage[] = [];

  @Input() incident: Incident;

  @Input() currentTabSelected: ITabsInfo;

  @Output() public expand: EventEmitter<boolean> = new EventEmitter();

  @Output() computedEscalationListChange = new EventEmitter<IComputedEscalation>();

  public formGroup = new UntypedFormGroup({ message: new UntypedFormControl(null) });

  public currentUser: any;

  public showStopEscalationButton = false;

  public isLoading = true;

  public editorEventHandlers: { [key: string]: Function; };

  public conversationInterval: NodeJS.Timeout;

  public froalaOptions = {charCounterCount: false};

  public isTextboxFocused = false;

  private from: number = 0;

  private size: number = 1000; // À changer éventuellement pour mettre le load event sur le scrolldown

  private isExpanded = false;

  private lastMessageSeen: IMessage = null;

  public selectedImageSrc: string = '';

  public isModalOpen: boolean = false;

  @Select(EcoSessionState.user) public currentUser$: Observable<string>;

  constructor(
    public i18nService: I18nService,
    private readonly notifsApi: NotificationsApiService,
    private readonly incidentsApi: IncidentsApi,
    private readonly snackbarService: SnackbarService,
    private readonly modalService: ModalService,
    protected store: Store,
    readonly injector: Injector
  ) {
    HandleBarUtils.registerHelpers(injector);
  }

  ngOnDestroy(): void {
    clearInterval(this.conversationInterval);
    this.conversationInterval = undefined;
  }

  ngOnInit(): void {
    this.fetchConversationNewMessages();
    this.describeIncidentEscalationList();
    this.currentUser$.subscribe((currentUser) => this.currentUser = currentUser);

    this.editorEventHandlers = {
      'image.beforeUpload': (images) => {
        this.notifsApi
          .uploadToS3(images[0], images[0].name, this.incident.conversationId, this.incident.organizationEcoId)
          .then((imageUrl) => {
            this.editorComponent.editor.image.insert(imageUrl, null, null, this.editorComponent.editor.image.get());
          })
          .catch((err: any) => {
            console.log(err);
          });
      },
      'contentChanged': () => this.handleHtmlContentChangeEvent(),
      'focus': () => this.handleFroalaFocus(),
      'blur': () => this.handleFroalaFocus(),
    };
  }

  ngOnChanges(): void {
    if (this.incidentConversation) {
      this.from = this.incidentConversation?.length;
      this.isLoading = false;
      this.applyInnerHtmlStyles();
      this.setLastMessageSeen();
    }

    if (this.currentTabSelected.name === TabNamesEnum.CONVERSATION) {
      this.scrollToLastMessage();
    }
  }

  public get messageValue(): string {
    return this.formGroup.get('message')?.value;
  }

  public get incidentClosed(): boolean {
    return this.incident.status === IncidentStatus.CLOSED;
  }

  public get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }

  public async stopEscalation(): Promise<void> {
    const completeEscalation = await this.stopEscalationConfirmationPopup();

    if (!completeEscalation) return;

    this.incidentsApi.updateIncidentEscalationList(
      this.incident.organizationEcoId,
      this.incident.id
    )
      .subscribe(
        (response) => {
          this.snackbarService.handleNotice(new Notice(this.i18nService.translate('incidents.container.page.details.tab.chat.chatSection.stopEscalation.success'), NoticeLevels.SUCCESS));
          this.showStopEscalationButton = false;
        },
        (error) => {
          this.snackbarService.handleNotice(new Notice(this.i18nService.translate('incidents.container.page.details.tab.chat.chatSection.stopEscalation.error'), NoticeLevels.ERROR));
        }
      );
  }

  public isMessageRightAlignment(message: IIncidentMessage): boolean {
    return (message.metadata.actionType === 'basic' && !this.isVarMode) || (message.metadata.actionType !== 'basic' && this.isVarMode);
  }

  public messageSeen(message: IMessage): boolean{
    return message.id === this.lastMessageSeen?.id;
  }

  public sendMessage(): void {
    const base64Message = Buffer.from(this.messageValue).toString('base64');
    this.formGroup.get('message').setValue(null);

    this.notifsApi.postIncidentMessage(
      this.incident.organizationEcoId,
      this.incident.conversationId,
      { from: this.from, size: this.size, content: base64Message, fromAdmin: this.isFromAdmConsole }
    )
      .subscribe((response) => {
        this.incidentConversation = [ ...this.incidentConversation, ...response ];
        this.from = this.incidentConversation.length;
        this.applyInnerHtmlStyles();
        this.updateIncidentTimestamp();
      });
  }

  private setLastMessageSeen(): void{
    const timestampType = this.isVarMode ? IncidentTimestampType.CONVERSATION_CLIENT_LAST_ACCESSED_AT : IncidentTimestampType.CONVERSATION_SOC_LAST_ACCESSED_AT;
    const timestamp = this.incident.timestamps.find((timestamp) => timestamp.type === timestampType).timestamp;
    const myMessages = this.incidentConversation.filter((message) => this.isMessageRightAlignment(message));
    const messagesSeen = myMessages.filter((message) => message.sentAt < timestamp);

    if(messagesSeen.length) {
      this.lastMessageSeen = messagesSeen[messagesSeen.length - 1];
    }
  }

  private handleHtmlContentChangeEvent(): void {
    this.isTextboxFocused = true;
  }

  public toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
    this.expand.emit(this.isExpanded);
  }

  private describeIncidentEscalationList(): void {
    this.incidentsApi.describeIncidentEscalationSchedule(
      this.incident.organizationEcoId,
      this.incident.id
    )
      .subscribe((response) => {

        this.computedEscalationListChange.emit(response);

        if (response?.status !== 'COMPLETED') {
          this.showStopEscalationButton = true;
        }
      });
  }

  private async stopEscalationConfirmationPopup(): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18nService.translate('incidents.container.page.details.tab.chat.popup.stopEscalation.title'),
        text: this.i18nService.translate('incidents.container.page.details.tab.chat.popup.stopEscalation.text'),
        confirmationText: this.i18nService.translate('incidents.container.page.details.tab.chat.popup.stopEscalation.confirmation'),
        cancelText: this.i18nService.translate('incidents.container.page.details.tab.chat.popup.delete.cancel'),
        currentLang: this.i18nService.currentLocale,
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
  }

  private applyInnerHtmlStyles(): void {
    setTimeout(() => {
      Array.from(document.getElementsByClassName('message-content-conversation')).map((element) => {
        for (let i = 0; i < element.children.length; i++) {
          const htmlElementChild = element.children[i] as HTMLElement;

          htmlElementChild.style.marginTop = "0";
          htmlElementChild.style.fontSize = "14px";

          if (i === element.children.length - 1) {
            htmlElementChild.style.marginBottom = "0";
          }

          // Find any <img> tags inside the <p> elements
          const imgElements = htmlElementChild.getElementsByTagName('img');
          if (imgElements.length > 0) {
            Array.from(imgElements).forEach((img) => {

              // Apply inline styles
              img.style.maxWidth = "100%";
              img.style.height = "auto";

              // Wrap image in a <a></a> tag
              const anchor = document.createElement('a');
              anchor.style.cursor = 'pointer'; // My <a> tag doesn't have an href attribute, we have to define cursor type manually
              img.parentNode?.replaceChild(anchor, img);
              anchor.appendChild(img);
            });
          }
        }
      });
      this.scrollToLastMessage();
    }, 1);
  }

  /** When message area clicked, grab the target element */
  public onClickMessageContentArea($event): void {
    const target = $event.target as HTMLElement;

    // Check if the clicked element is an image
    if (target.tagName === 'IMG') {
      const imageUrl = target.getAttribute('src');

      if (imageUrl) {
        this.openConversationContentModal(imageUrl);
      }
    }
  }

   /** Close modal */
   closeConversationContentModal(): void {
    this.isModalOpen = false;
  }

  /** Open modal */
  openConversationContentModal(imageSrc: string): void {
    this.selectedImageSrc = imageSrc;
    this.isModalOpen = true;
  }

  private scrollToLastMessage(): void {
    document.getElementsByClassName('message-container')[this.incidentConversation?.length - 1]?.scrollIntoView();
  }

  private fetchConversationNewMessages(): void {
    if (!this.conversationInterval) {
      this.conversationInterval = setInterval(() => {
        if (this.incident.conversationId) {
          this.notifsApi.describeIncidentConversation(
            this.incident.organizationEcoId,
            this.incident.conversationId,
            { from: this.from, size: this.size, fromAdmin: this.isFromAdmConsole }
          )
            .subscribe((response) => {
              const messages = response.getItems();

              if (messages.length) {
                this.incidentConversation = [ ...this.incidentConversation, ...messages ];
                this.from = this.incidentConversation.length;
                this.applyInnerHtmlStyles();
              }
            });
        }
      }, 30000);
    }
  }

  private updateIncidentTimestamp(): void {

    const context = this.isVarMode ? IncidentTimestampType.CONVERSATION_SOC_LAST_UPDATED_AT : IncidentTimestampType.CONVERSATION_CLIENT_LAST_UPDATED_AT;
    const incidentTimestamp = this.incident.timestamps.find((timestamp) => timestamp.type === context);

    try
    {
      this.incidentsApi.updateIncidentTimestamp(this.incident.organizationId, {
        incidentId: this.incident.id,
        id: incidentTimestamp.id,
        timestamp: Date.now(),
        incidentTimestamp
      }).subscribe(
        (response) => {

        },
        // (error) => this.noticeService.notifyUser(new Notice(error, NoticeLevels.ERROR))
      );
    }
    catch(e)
    {
      console.log(e);
    }

  }

  private handleFroalaFocus(): void{
    this.isTextboxFocused = !this.isTextboxFocused;
  }
}
