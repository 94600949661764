import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { MobileService } from '@common/services/mobile.service';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';

@Component({
  selector: 'introduction-slide',
  templateUrl: './introduction-slide.component.html',
  styleUrls: [ './introduction-slide.component.scss' ],
})
export class IntroductionSlideComponent implements OnInit {
  @Input() isNewClient = false;
  @Input() isRestrictedUser = false;

  constructor(public mobileService: MobileService, private i18n: I18nService) {}

  ngOnInit(): void {}

  get localizedLink() {
    return this.i18n.currentLocale == LanguageEnum.ENGLISH ? "https://mondata.ai/en/contact-us/" : 'https://mondata.ai/contactez-nous/';
  }
}
