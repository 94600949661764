<button
  ui-button
  [tooltip]="'respond.reports.download' | t"
  color="listActionFlatGray"
  icon="icon_download_report"
  [custom-icon]="true"
  [isSvg]="true"
  icon-size="small"
  icon-margin="0 1px 0 0"
  [disabled]="isDisabled"
  (click)="printReport();"
></button>