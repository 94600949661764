import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AllSelectorCustomValue } from '@ui-kit/components/autocomplete/organization-autocomplete/organization-autocomplete.component';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { DynamicQueryTimeRangeEnum } from '@ui-kit/components/widget-container/widget-container.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SearchMode } from 'projects/@common/modules/layout/components/page/page.component';
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ConnectorsOrgService } from 'projects/@common/services/api/detect/connectorsOrg/connectorsOrg.service';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { AutomationOrganizationsService } from 'projects/@common/services/api/respond/automation-organization/automation-organizations.api';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { IncidentsStats } from 'projects/@common/services/api/respond/incidents/models/incidentsStats';
import { PlaybooksApiService } from 'projects/@common/services/api/respond/playbooks/playbooks.api';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';

export enum RespondDashboardTimeRangesEnum {
  CURRENT_WEEK = DynamicQueryTimeRangeEnum.CURRENT_WEEK,
  PREVIOUS_WEEK = DynamicQueryTimeRangeEnum.PREVIOUS_WEEK,
  CURRENT_MONTH = DynamicQueryTimeRangeEnum.CURRENT_MONTH,
  PREVIOUS_MONTH = DynamicQueryTimeRangeEnum.PREVIOUS_MONTH
};

@Component({
  selector: 'respond-dashboard-container',
  templateUrl: './respond-dashboard-container.component.html',
  styleUrls: [ './respond-dashboard-container.component.scss' ],
})
export class RespondDashboardContainerComponent implements OnInit {
  public readonly uiTabsColor: UiTabsColor = UiTabsColor.lightTabs;
  public activeOrganization: IAutomationOrganization | null = null;
  public searchMode = SearchMode.RESPOND;
  public incidentsStats: IncidentsStats;
  public playbookActivated: number;
  public usecaseActivated: number;
  public connectorActivated: number;
  public isVarMode = false;
  public orgSelectorAllValue: AllSelectorCustomValue | null = null;
  public isAllOrganizationSelected: boolean = false;

  constructor(
    private readonly automationOrganizationsService: AutomationOrganizationsService,
    private readonly cdr: ChangeDetectorRef,
    @Inject('CONSOLE_TYPE') private readonly consoleType: ConsoleType,
    private readonly store: Store,
    private readonly incidentsApi: IncidentsApi,
    private readonly playbooksApi: PlaybooksApiService,
    private readonly i18nService: I18nService,
    private readonly notice: NoticeService,
    private readonly usecasesApi: UsecasesApiService,
    private readonly connectorsOrgService: ConnectorsOrgService
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  async ngOnInit(): Promise<void> {
    if (!this.isVarMode) {
      await this.organizationChanged({
        id: this.store.selectSnapshot(EcoSessionState.organization).id,
      });
      this.isAllOrganizationSelected = false;
      this.fetchBadgesStats();
    } else {
      this.orgSelectorAllValue = {
        value: 'all',
        displayValue: this.i18nService.translate('incidents.container.page.selector.all'),
      };
      this.isAllOrganizationSelected = true;
    }
  }
  public get showEmptyPageToSelectOrganization(): boolean {
    return !this.activeOrganization;
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    if (org) {
      if (org.id === 'all') {
        this.isAllOrganizationSelected = true;
      } else {
        this.isAllOrganizationSelected = false;
        this.activeOrganization = await this.automationOrganizationsService.describeOrganization(org.id);
      }
    } else {
      this.activeOrganization = null;
    }
    this.fetchBadgesStats();
    this.cdr.detectChanges();
  }

  public fetchBadgesStats(): void {
    if (this.activeOrganization?.ecoId) {
      this.incidentsStats = null;
      this.incidentsApi.getIncidentsStats(this.activeOrganization?.ecoId).subscribe(
        (response) => {
          this.incidentsStats = response.reduce((acc, curr) => new IncidentsStats({
            open: acc.open + curr.open,
            closed: acc.closed + curr.closed,
            closedFalsePositive: acc.closedFalsePositive + curr.closedFalsePositive,
            closedTest: acc.closedTest + curr.closedTest,
            organizationId: null,
          }), new IncidentsStats({ open: 0, closed: 0, closedFalsePositive: 0, closedTest: 0, organizationId: null }));
        },
        (_) => this.notice.notifyUser(new Notice(this.i18nService.translate('common.error.retry'), NoticeLevels.ERROR))
      );

      this.playbooksApi.getPlaybookStats(this.activeOrganization?.ecoId).then((result) => {
        this.playbookActivated = result?.active || 0;
      })
        .catch(() => {
          this.playbookActivated = 0;
        });

      this.usecasesApi.getUsecaseStats(this.activeOrganization?.ecoId).then((result) => {
        this.usecaseActivated = result?.count || 0;
      })
        .catch(() => {
          this.usecaseActivated = 0;
        });

      this.connectorsOrgService.getConnectorInstanceStats(this.activeOrganization?.ecoId).then((result) => {
        this.connectorActivated = result?.count || 0;
      })
        .catch(() => {
          this.connectorActivated = 0;
        });
    }
  }

  public get closeIncidentCounter(): number {
    return this.incidentsStats.closed + this.incidentsStats.closedFalsePositive + this.incidentsStats.closedTest;
  }
}
