import { ConnectorFamilies, DataConnectorTypes } from "../enums";
import { SiemConnectorTypes } from "../enums/siemConnectorTypes.enum";
import { SoarConnectorTypes, SoarConnectorTypesEnum } from "../enums/soarConnectorTypes.enum";

// Each APIs that will depends on this catalog can inherit from it if they need to add more specific details.
// E.g In connectors-api, if we want to add a threshold specific for each connector types. We could inherit from the catalog contents to create a new one.
/*
    [ConnectorTypes.ATP]: {
        ...defaultAtpConfig,
        myNewThreshold: 10
    },
*/

export interface IConnectorTypesCatalog {
  id: string;
  name: string;
  family: ConnectorFamilies;
}

export const DataConnectorTypesCatalog: { [id in DataConnectorTypes]: IConnectorTypesCatalog } = {
  [DataConnectorTypes.AWS_WAF]: {
    id: '01GR7C9848CT2RYN38W4ZDPS2H',
    name: "AWS WAF",
    family: ConnectorFamilies.AWS,
  },
  [DataConnectorTypes.AZURE_ACTIVITY_LOGS]: {
    id: '01GR7CBGEH5B6CXDK2Q8MVNGEA',
    name: "MS Azure Activity logs",
    family: ConnectorFamilies.MICROSOFT_AZURE,
  },
  [DataConnectorTypes.AZURE_SENTINEL]: {
    id: '01GYFSJWHAW50GE7678HRM1QJ5',
    name: "Microsoft Sentinel",
    family: ConnectorFamilies.MICROSOFT_AZURE,
  },
  [DataConnectorTypes.BIT_DEFENDER]: {
    id: '01GR7CB7RE06ZYSQPEMFYVK21R',
    name: "BitDefender",
    family: ConnectorFamilies.EDR,
  },
  [DataConnectorTypes.CISCO_FMC]: {
    id: '01GR7CAWH13MMQC7CK57380XDM',
    name: "Cisco FMC",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.CISCO_FTD]: {
    id: '01GR7CB045M0XD8NRKAGPYRCBM',
    name: "Cisco FTD",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.CISCO_UMBRELLA]: {
    id: '01GR7C9RVPCH8HWM1405H638DF',
    name: "Cisco Umbrella",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.CLOUDTRAIL]: {
    id: '01GR7C83SSDA8FSRWWVFQ4SX91',
    name: "CloudTrail",
    family: ConnectorFamilies.AWS,
  },
  [DataConnectorTypes.CORTEX_XDR]: {
    id: '01GX4BW1SDCP9BS3FCCANABS0S',
    name: "PaloAlto Cortex XDR",
    family: ConnectorFamilies.EDR,
  },
  [DataConnectorTypes.DATABRICKS]: {
    id: '01GR7C88QETC0991M06DYAV11Z',
    name: "Databricks",
    family: ConnectorFamilies.AWS,
  },
  [DataConnectorTypes.FORTINET]: {
    id: '01GR7CAQF6VNEAK87J964P16JA',
    name: "Fortinet",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.FORTIWEB]: {
    id: '01H3D1XQW88DVYHTN345M8YN4F',
    name: "FortiWeb",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.GUARDDUTY]: {
    id: '01GR7C67GXMYAT6BPP68TVGZ5M',
    name: "GuardDuty",
    family: ConnectorFamilies.AWS,
  },
  [DataConnectorTypes.INFORMATICA]: {
    id: '01GR7CA2NZK8TJDFVH8A0NBSPS',
    name: "Informatica",
    family: ConnectorFamilies.INFORMATICA,
  },
  [DataConnectorTypes.JUNIPER_SRX]: {
    id: '01GR7CABQA4VTQ9TFT92TVM88S',
    name: "Juniper SRX",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.JUNOS_OS]: {
    id: '01GR7CBCBKFHCT4X51Z5DFFEXK',
    name: "Junos OS",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.AWS_LINUX]: {
    id: '01GR7C9GETTDFT5CKDSJXWAXHX',
    name: "Linux on AWS",
    family: ConnectorFamilies.AWS,
  },
  [DataConnectorTypes.LINUX]: {
    id: '01J82K2YVZHKRHTX1ZGKNHFKQQ',
    name: "Linux",
    family: ConnectorFamilies.LOCAL_INFRA,
  },
  [DataConnectorTypes.MICROSOFT_365_DEFENDER]: {
    id: '01H0MWJHG4FBN5AXYVTHW9H41M',
    name: "Microsoft 365 Defender",
    family: ConnectorFamilies.MICROSOFT_365,
  },
  [DataConnectorTypes.OFFICE]: {
    id: '01GR7C8Z88Z2438QD02RDH406B',
    name: "Microsoft Activity Log",
    family: ConnectorFamilies.MICROSOFT_365,
  },
  [DataConnectorTypes.OPENVAS]: {
    id: '01GR7C8NG009EY6H7QMNZ8GJ4X',
    name: "GVM OpenVAS",
    family: ConnectorFamilies.EDR,
  },
  [DataConnectorTypes.PALOALTO]: {
    id: '01GR7CA6SH86BFT92PP5AMAEFS',
    name: "Palo Alto",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.PULSE_SECURE_VPN]: {
    id: '01GR7CAG5HSZ2FM5TCZV8GN4ZY',
    name: "Pulse Secure VPN",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.SECURITY_HUB]: {
    id: '01GR7C9Y84EQV8QYVG8GGTW1D6',
    name: "AWS SecurityHub",
    family: ConnectorFamilies.AWS,
  },
  [DataConnectorTypes.SENTINELONE_SINGULARITY]: {
    id: '01H2XQZQ1Q43GKBDPW3ZR8HB1A',
    name: "SentinelOne",
    family: ConnectorFamilies.EDR,
  },
  [DataConnectorTypes.ENTRA]: {
    id: '01GR7C9D03R95KNVRDN3RZX8E2',
    name: "Microsoft Entra ID",
    family: ConnectorFamilies.MICROSOFT_365,
  },
  [DataConnectorTypes.SNOWFLAKE]: {
    id: '01GR7C8D12ZAKS07CQ3MTG48TZ',
    name: "Snowflake",
    family: ConnectorFamilies.AWS,
  },
  [DataConnectorTypes.V_CENTER]: {
    id: '01GR7CB44VV0491DJRARNJ2HXD',
    name: "VCenter",
    family: ConnectorFamilies.LOCAL_INFRA,
  },
  [DataConnectorTypes.WATCH_GUARD_FIREBOX]: {
    id: '01HGZXEM67QJY1TB6ACRECTBXT',
    name: "WatchGuard Firebox",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.WINDOWS_EVENT_LOGS]: {
    id: '01GR7C8H0PB8CAC0E8JAW4XMKS',
    name: "Windows Event Logs",
    family: ConnectorFamilies.LOCAL_INFRA,
  },
  [DataConnectorTypes.OPA]: {
    id: '01HQ7RXG2YXH6TPVA096E9V3QZ',
    name: "Open Policy Agent",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.TIQ_AUTH]: {
    id: '01HQ7SGFV87TN0W7153NHXF2GK',
    name: "TIQ Authentication",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.FORTI_SIEM]: {
    id: '01HRZ06VZ82RCB5C56HJ60SC8T',
    name: "FortiSIEM",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.GENERIC_SYSLOG]: {
    id: '01HKZ1TKZFTDSA10WVFBM50NNS',
    name: "Generic Syslog",
    family: ConnectorFamilies.LOCAL_INFRA,
  },
  [DataConnectorTypes.OPA_CONFIG]: {
    id: '01HQWBBNH5AK78GCKPHADB5CC8',
    name: 'Open Policy Agent Configuration',
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.LDAP_CONFIG]: {
    id: '01HQWM10T3VQA8RGZY3H7GBS02',
    name: 'LDAP Groups Configuration',
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.TIQ_ACTIVITY_LOG]: {
    id: '01HRE03NB1HG5HQN3EPE7ZKQJ8',
    name: 'TIQ Activity Logs',
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.GINGER_ACTIVITY_LOG]: {
    id: '01HRE0PSH8HYTPQ77SCPKWSS1N',
    name: 'Ginger Activity Logs',
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.KNOWBILITY_ACTIVITY_LOG]: {
    id: '01HRZYW2E289SHH44K92ZXJWS7',
    name: "Knowbility Activity Logs",
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.TIQ_PERMIT_USER_LIST]: {
    id: '01HTKH757RQXA9W7K8709BPEQ1',
    name: 'TIQ PermIT User List',
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.TIQ_EXTERNAL_USER_LIST]: {
    id: '01HTKHAQYZC6QFY19T81E56AMP',
    name: 'TIQ External User List',
    family: ConnectorFamilies.CYBERSECURITY_INFRA,
  },
  [DataConnectorTypes.GOOGLE_WORKSPACE_ALERT]: {
    id: '01J2HPG83W82F1KA15CPJB9RTF',
    name: 'Google Workspace Alert',
    family: ConnectorFamilies.GOOGLE,
  },
};

export const SoarConnectorTypesCatalog: { [id in SoarConnectorTypesEnum]: IConnectorTypesCatalog } = {
  [SoarConnectorTypesEnum.MICROSOFT_365_RESPONDER]: {
    id: SoarConnectorTypesEnum.MICROSOFT_365_RESPONDER,
    name: "MS 365 Responder",
    family: ConnectorFamilies.MICROSOFT_365,
  },
  [SoarConnectorTypesEnum.MICROSOFT_365_ANALYZER]: {
    id: SoarConnectorTypesEnum.MICROSOFT_365_ANALYZER,
    name: "MS 365 Analyzer",
    family: ConnectorFamilies.MICROSOFT_365,
  },
  [SoarConnectorTypesEnum.AWS_RESPONDER]: {
    id: SoarConnectorTypesEnum.AWS_RESPONDER,
    name: "AWS Responder",
    family: ConnectorFamilies.AWS,
  },
  [SoarConnectorTypesEnum.AWS_ANALYZER]: {
    id: SoarConnectorTypesEnum.AWS_ANALYZER,
    name: "AWS Analyzer",
    family: ConnectorFamilies.AWS,
  },
};

export const SiemConnectorTypesCatalog: { [id in SiemConnectorTypes]: IConnectorTypesCatalog } = {};
