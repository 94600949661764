import { NgModule } from '@angular/core';
import { DatasourceTableIconsComponent } from './components/datasource-table-icons/datasource-table-icons.component';
import { CommonModule } from '@angular/common';
import { UiKitModule } from '@ui-kit/uikit.module';

const components = [
  DatasourceTableIconsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UiKitModule,
  ],
  declarations: [ ...components ],
  providers: [],
  exports: [ ...components ],
})
export class DatasourcesModule {
}
