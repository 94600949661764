import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IdpApi } from 'projects/@common/services/api/iam/idp/idp.api';

@Component({
  selector: 'app-registration-update-redirect.container',
  templateUrl: './app-registration-update-redirect.container.html',
  styleUrls: [ './app-registration-update-redirect.container.scss' ],
})
export class AppRegistrationUpdateRedirectContainer implements OnInit {
  public adminConsent: boolean;
  public tenantId: string;

  private appToActivate;

  constructor (
    private route: ActivatedRoute,
    private idpApi: IdpApi,
    private notice: NoticeService,
    private router: Router
  ) {
  }

  public ngOnInit() {
    this.adminConsent = Boolean(this.route.snapshot.queryParamMap.get('admin_consent'));
    this.tenantId = this.route.snapshot.queryParamMap.get('tenant');

    if (this.adminConsent && this.tenantId) {
      this.requestIdpUpdate();
    }
  }

  private requestIdpUpdate() {
    this.appToActivate = this.fetchAppToActivateFromSessionStorage();
    this.idpApi.updateIdp({ application: this.appToActivate })
      .catch(() => {
        this.notice.notifyUser(new Notice('The system was unable to update the IDP', NoticeLevels.ERROR));
      })
      .finally(() => {
        this.removeAppToActivateFromLocalStorage();
        this.router.navigate([ "iam", "app-registration-update" ]);
      });
  }

  private fetchAppToActivateFromSessionStorage(): string {
    return localStorage.getItem('appToActivate');
  }

  private removeAppToActivateFromLocalStorage() {
    localStorage.removeItem('appToActivate');
  }
}
