import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { IPlaybookDetail } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';

@Component({
  selector: 'app-playbook-update-header',
  templateUrl: './playbook-update-header.component.html',
  styleUrls: [ './playbook-update-header.component.scss' ],
})
export class PlaybookUpdateHeaderComponent {
  @Input() public playbook: IPlaybookDetail;
  @Input() public organization: IAutomationOrganization;

  @Output() public browserGoBackEvent = new EventEmitter<void>();

  constructor() { }

  public handleClickBackButton(): void {
    this.browserGoBackEvent.emit();
  }
}
