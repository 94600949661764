import {Injectable } from '@angular/core';
import * as selfsigned from 'selfsigned';

export interface ISelfSignedPems {
  private: string;
  public?: string;
  cert: string;
  fingerprint?: string;
  expirationDate?: number;
}

export interface ICertificateParams {
  keySize?: number,
  days?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SelfsignedService {

  /** Fallback default params for certificate generator */
  private _certificateGeneratorOptions: any = {
    keySize: 2048, // Default value in dependancy is 1024
    days: 365 // Default value in dependancy
  };

  public generate(params: ICertificateParams = {}): ISelfSignedPems {

    params = Object.assign({}, this._certificateGeneratorOptions, params);

    const certificate = selfsigned.generate(null, params);

    // Calculate the expiration date, including nb days in params.
    const expirationDate = new Date().setDate(new Date().getDate() + params.days);

    return {
      ...certificate,
      expirationDate: expirationDate
    };
  }
}
