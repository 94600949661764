<div class="collapsable-library">
  <div
    [ngClass]="
      isCollapsed
        ? 'collapsable-library--header'
        : 'collapsable-library--header-open'
    "
    (click)="toggleCollapse(); $event.stopPropagation();"
  >
    <div class="collapsable-library--header-top">
      <div class="collapsable-library--header-top-left">
        <div class="drag-handle-container"><img src="assets/favicons/toolbox-incidents/icon_drag.svg" class="drag-handle"></div>
        <div class="collapsable-library--header-top-left-icon">
          <img *ngIf="icon" [src]="taskIconSrc" />
        </div>
        <div class="collapsable-library--header-top-left-title">
          {{ playbooksTable.name | translatedObject }}
          <div class="collapsable-library--header-top-tags">
            <div
              *ngFor="let tag of tags; let i = index; first as isFirst"
              [class.first]="isFirst"
            >
              <div class="tags">{{ 'incidents.report.section.task.phase.' + tag | t }}</div>
            </div>
          </div>
          <div
            class="collapsable-library--header-bot--date"
            *ngIf="!isCollapsed && updateDate"
          >
            {{
              'detection.playbook.detail.task.update' | t: { date: updateDate }
            }}
          </div>
        </div>
      </div>
      <div class="collapsable-library--header-top-chevron">
        <ui-icon
          *ngIf="isCollapsable"
          class="collapsable-library--header--chevron"
          modifier="on-header"
          [name]="isCollapsed ? 'chevron-right' : 'chevron-down'"
          size="medium"
        ></ui-icon>
      </div>
    </div>
  </div>
  <div *ngIf="!isCollapsed" class="collapsable-library--content" (click)="$event.stopPropagation();">
    {{ playbooksTable.description | translatedObject }}
  </div>
</div>
