import { Injectable } from '@angular/core';
import { DatasourceInventoryInfo } from 'projects/@common/services/api/respond/datasources/datasources.definitions';

@Injectable({
  providedIn: 'root',
})
export class DatasourceFamilyDetailService {
  private currentDatasourceInventoryInfo: DatasourceInventoryInfo = null;

  public set datasourceInfo(info: DatasourceInventoryInfo) {
    this.currentDatasourceInventoryInfo = info;
  }

  public get datasourceInfo(): DatasourceInventoryInfo {
    return this.currentDatasourceInventoryInfo;
  }

  public canNavigateToDetail(): boolean {
    return this.currentDatasourceInventoryInfo !== null;
  }

  public resetValue(): void {
    this.currentDatasourceInventoryInfo = null;
  }
}
