import { HttpClient } from "@angular/common/http";
import { GetHistoriesRequest } from "@common/services/api/respond/histories/models/getHistoriesRequest";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { IAlertHistory, IAssetHistory, IConnectorHistory, IEscalationHistory, IFilterHistory, IGetPlaybookHistoriesRequest, IHistoriesApi, IHistory, IIncidentHistory, IIncidentTaskHistory, IListConnectorHistoryRequest, IListEscalationHistoryRequest, IListHistoryRequest, IListIncidentTaskHistoryRequest, IUsecaseHistory, IUsecaseHistoryRequest, IWhitelistHistory } from "projects/@common/services/api/respond/histories/histories.definition";
import { AssetHistoryRequest } from 'projects/@common/services/api/respond/histories/models/assetHistoryRequest';
import { GetAlertHistoriesRequest } from "projects/@common/services/api/respond/histories/models/getAlertHistoriesRequest";
import { GetIncidentHistoriesRequest } from "projects/@common/services/api/respond/histories/models/getIncidentIHistoriesRequest";
import { PlaybookHistoryRequest } from 'projects/@common/services/api/respond/histories/models/playbookHistoryReques';
import { UsecaseHistoryRequest } from "projects/@common/services/api/respond/histories/models/usecaseHistoryRequest";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { UrlUtils } from "projects/@common/utils/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class VarHistoriesApi extends EcoApi implements IHistoriesApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;
  private baseUrl: string;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
    this.baseUrl = url.baseUrl;
  }

  public listUsers(request: GetHistoriesRequest): Observable<{ identityName: string; identityId: string }[]> {
    const url = `${this.host}/histories/users?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`;
    return this.http.get<{ identityName: string; identityId: string }[]>(url);
  }

  public listHistories(request: GetHistoriesRequest): Observable<ResponseUtils<IHistory>> {
    const url = `${this.host}/histories?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`;
    return this.http.get<IRespondResponse<IHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IHistory>(response)));
  }

  public listAlertHistories(request: GetAlertHistoriesRequest): Observable<ResponseUtils<IAlertHistory>> {
    const url = `${this.host}/organizations/${request.organizationId}/alerts/${request.alertId}/histories?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`;
    return this.http.get<IRespondResponse<IHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IHistory>(response)));
  }

  public listIncidentHistories(request: GetIncidentHistoriesRequest): Observable<ResponseUtils<IIncidentHistory>> {
    const url = `${this.host}/organizations/${request.organizationId}/incidents/${request.incidentId}/histories?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`;
    return this.http.get<IRespondResponse<IIncidentHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IIncidentHistory>(response)));
  }

  public listWhitelistHistory(request: IListHistoryRequest): Observable<ResponseUtils<IWhitelistHistory>> {
    const url = `${this.host}/organizations/${request.organizationId}/whitelists/${request.whitelistId}/histories?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IWhitelistHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IWhitelistHistory>(response)));
  }

  public listFilterHistory(request: IListHistoryRequest): Observable<ResponseUtils<IFilterHistory>> {
    const url = `${this.host}/organizations/${request.organizationId}/filters/${request.filterId}/histories?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IFilterHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IFilterHistory>(response)));
  }

  public listUsecaseHistory(request: IUsecaseHistoryRequest): Observable<ResponseUtils<IUsecaseHistory>> {
    const objRequest = new UsecaseHistoryRequest(request);
    const url = `${this.host}/organizations/${request.organizationId}/usecases/${request.usecaseId}/histories?${UrlUtils.jsonToQueryParams(objRequest.getQueryParams())}`;
    return this.http.get<IRespondResponse<IUsecaseHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IUsecaseHistory>(response)));
  }

  public listAssetHistory(request: AssetHistoryRequest): Observable<ResponseUtils<IAssetHistory>> {
    const url = `${this.host}/organizations/${request.organizationId}/assets/${request.assetId}/histories?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`;
    return this.http.get<IRespondResponse<IAssetHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IAssetHistory>(response)));
  }

  public listPlaybookHistory(request: IGetPlaybookHistoriesRequest): Observable<ResponseUtils<any>> {
    const objRequest = new PlaybookHistoryRequest(request);
    const url = `${this.host}/organizations/${request.organizationId}/playbooks/${request.playbookId}/histories?${UrlUtils.jsonToQueryParams(objRequest.getQueryParams())}`;
    return this.http.get<IRespondResponse<any>>(url)
      .pipe(map((response) => new ResponseUtils<any>(response)));
  }

  public listEscalationHistory(request: IListEscalationHistoryRequest): Observable<ResponseUtils<IEscalationHistory>> {
    const url = `${this.host}/organizations/${request.organizationId}/escalation/histories?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IEscalationHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IEscalationHistory>(response)));
  }

  public listIncidentTaskHistory(request: IListIncidentTaskHistoryRequest): Observable<ResponseUtils<IIncidentTaskHistory>> {
    const url = `${this.host}/organizations/${request.organizationId}/incidents/${request.incidentId}/tasks/${request.incidentTaskId}/histories?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IIncidentHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IIncidentHistory>(response)));
  }

  public listConnectorHistory(request: IListConnectorHistoryRequest): Observable<ResponseUtils<IConnectorHistory>> {
    const url = `${this.baseUrl}${ApiEndpointPrefixEnum.detect_connectors}/organizations/${request.organizationId}/connectors/instances/${request.connectorInstanceId}/histories?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IConnectorHistory>>(url)
      .pipe(map((response) => new ResponseUtils<IConnectorHistory>(response)));
  }
}
