<ui-card>
  <div class="flex-box space-between margin--bottom">

    <div class="button-container">
      <button *ngIf="hasUserToDelete && isUserGuestAdmin" ui-button color="linkFlat" icon="icon_delete" [custom-icon]="true" [isSvg]="true" icon-size="medium"
              (click)="openGuestUsersModal()">
        {{ "guest.users.management.guest-list.delete-guests" | t }}
      </button>

      <button ui-button color="linkFlat" [loading]="isCsvLoading" icon="icon_download_report" [custom-icon]="true" [isSvg]="true" icon-size="medium"
              (click)="fetchOrphanGuests()">
        {{ "guest.users.management.guest-list.download-csv" | t }}
      </button>
    </div>

    <div class="flex-box center-horizontal flex-end guest-users--button-space">
      <ui-table-total-tool [total]="total"></ui-table-total-tool>
      <ui-table-search-tool class="search-tool" [query]="fulltext" (clear)="clearTextSearch()" (query)="handleTextSearch($event)"
                            [placeholder]="'report-publicFiles.fulltext.placeholder' | t"></ui-table-search-tool>
      <ui-table-refresh-tool [loading]="isLoading" (click)="handleRefresh()"></ui-table-refresh-tool>
    </div>
  </div>
  <div class="table-sections">
    <section *ngFor="let table of tables; let first = first">
      <ui-collapsable-table [tableTitle]="table.name | t" [numberItems]="table.total" [locale]="i18n.currentLocale" [columnsDef]="columnsDef"
                            [dataSource]="table.dataSource" [isCollapsed]="false" [canLoadMore]="table.canLoadMore" [isLoading]="table.isLoading"
                            [isLoadingMore]="table.isLoadingMore" [isCustomSorting]="true" (handleCustomSort)="table.handleSorting($event.sortColumn)"
                            [sort]="table.orderBy" [direction]="table.direction" [addPaddingOnFirst]="false"
                            (onLoadMore)="table.fetchGuestUsers(true)">
      </ui-collapsable-table>
    </section>
  </div>
</ui-card>
