import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FieldMap } from "angular2-query-builder";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { EcoUrlService } from "../../core/url.service";
import { ICreateFilterRequest, IDeleteFiltersRequest, IDeleteFiltersResponse, IDescribeFilterRequest, IDescribeFilterResponse, IListFiltersRequest, IUpdateFilterRequest, IFilterBaseRepresentation, IListFilterFieldsRequest, IPrevisualizeQueryRequest } from "./filters.definitions";
import { IFiltersApi } from "./filters.definitions";
import { CustomerFiltersApi } from "projects/console-org/src/app/respond/filters/api/customer-filter.api";
import { VarFiltersApi } from "projects/console-adm/src/app/respond/filters/api/var-filter.api";
import { Store } from "@ngxs/store";
import { EcoSessionState } from "@common/modules/session/state/session.state";

@Injectable({
  providedIn: 'root',
})
export class FiltersApi implements IFiltersApi {
  private customerService: CustomerFiltersApi;
  private varService: VarFiltersApi;

  constructor(
    protected store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerFiltersApi(http, url);
    this.varService = new VarFiltersApi(http, url);
  }

  public getFilterConditionFields(request?: IListFilterFieldsRequest): Promise<FieldMap> {
    if (this.isVarMode) {
      return this.varService.getFilterConditionFields(request);
    }
    return this.customerService.getFilterConditionFields(request);
  }

  public listFilters(organizationId: string, request?: IListFiltersRequest): Observable<ResponseUtils<IFilterBaseRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listFilters(organizationId, request);
    }
    return this.customerService.listFilters(organizationId, request);
  }

  public listOrganizationsFilters(request?: IListFiltersRequest): Observable<ResponseUtils<IFilterBaseRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listOrganizationsFilters(request);
    }
    return this.customerService.listOrganizationsFilters(request);
  }

  public describeFilter(request: IDescribeFilterRequest): Observable<IDescribeFilterResponse> {
    if (this.isVarMode) {
      return this.varService.describeFilter(request);
    }
    return this.customerService.describeFilter(request);
  }

  public createFilter(request: ICreateFilterRequest): Observable<IDescribeFilterResponse> {
    if (this.isVarMode) {
      return this.varService.createFilter(request);
    }
    return this.customerService.createFilter(request);
  }

  public updateFilter(request: IUpdateFilterRequest): Observable<IDescribeFilterResponse> {
    if (this.isVarMode) {
      return this.varService.updateFilter(request);
    }
    return this.customerService.updateFilter(request);
  }

  public deleteFilter(request: IDeleteFiltersRequest): Promise<IDeleteFiltersResponse> {
    if (this.isVarMode) {
      return this.varService.deleteFilter(request);
    }
    return this.customerService.deleteFilter(request);
  }

  public previsualizeQuery(request: IPrevisualizeQueryRequest): Promise<any> {
    if (this.isVarMode) {
      return this.varService.previsualizeQuery(request);
    }
    return this.customerService.previsualizeQuery(request);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
