import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { NoticeService } from '@common/modules/notice/notice.service';
import { OrganizationsApiService } from '@common/services/api/sg/organizations/organizationsApi.service';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { DateUtils } from '@common/utils/date-utils';
import { ResponseUtils } from '@common/utils/response-utils';
import { GuestUser, GuestUserStatusEnum, IGuestUser } from '../../../../services/guest-users.definition';

@Component({
  selector: 'app-guest-users-delete-modal',
  templateUrl: './guest-users-delete-modal.component.html',
  styleUrls: ['./guest-users-delete-modal.component.scss']
})
export class GuestUsersDeleteModalComponent implements OnInit {
  public total = 0;
  public fulltext = null;
  public isLoading = false;
  public dataSource: GuestUserRevocations[] = [];
  public tempDataSource: GuestUserRevocations[] = [];
  public guestUsersResponse: ResponseUtils<GuestUser>;

  public minExpirationDate = DateUtils.tomorrowDate();
  public maxExpirationDate: Date;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GuestUsersDeleteModalComponent>,
    public i18n: I18nService,
    private usersApiService: UsersApiService,
    private notice: NoticeService,
    private organizationsApiService: OrganizationsApiService,
  ) { }

  ngOnInit(): void {
    this.organizationsApiService.getOrganizationSettings()
      .then(response => {
        const date = new Date();
        date.setDate(date.getDate() + response.guestExpirationDateLimit);
        this.maxExpirationDate = date;
      });

    this.fetchUsersToDelete();
  }

  public get isValid(): boolean {
    return this.tempDataSource.every(user => user.isValid);
  }

  public get toRemoveAmount(): number {
    return this.tempDataSource.filter(user => user.toBeRemoved).length;
  }

  public get toKeepAmount(): number {
    return this.tempDataSource.filter(user => !user.toBeRemoved).length;
  }

  public onUserSearch(text: string): void {
    this.fulltext = text;
    if (text) {
      const filterData = [...this.tempDataSource];
      this.dataSource = filterData.filter(user => {
        return user.fullName.includes(text) || user.email.includes(text);
      });
    } else {
      this.resetDataSource();
    }
  }

  public resetDataSource(): void {
    this.fulltext = '';
    this.dataSource = this.tempDataSource;
  }

  public fetchUsersToDelete(): void {
    const requestParams = {
      fulltext: this.fulltext,
      from: 0,
      status: GuestUserStatusEnum.TO_DELETE
    };

    this.isLoading = true;
    this.usersApiService.listGuestUsers(requestParams)
      .subscribe(
        res => {
          this.guestUsersResponse = res;
          this.dataSource = this.guestUsersResponse.getItems().map(user => new GuestUserRevocations(user));
          this.tempDataSource = [...this.dataSource];
          this.total = this.guestUsersResponse.getTotalItems();
        },
        err => {
          this.notice.error(this.i18n.translate('guest.users.management.admin-list.500.error'));
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  public save() {
    this.isLoading = true;

    const usersToHandle = {
      userIdsToDelete: this.tempDataSource
        .filter(user => user.toBeRemoved)
        .map(user => user.o365UserId),

      usersToExtend: this.tempDataSource
        .filter(user => !user.toBeRemoved)
        .map(user => ({
          o365UserId: user.o365UserId,
          expirationDate: user.expirationForm.get('expirationDate').value
        }))
    };

    this.usersApiService.deleteGuestUsers(usersToHandle)
      .subscribe(
        response => {
          this.notice.success(this.i18n.translate('guest.users.management.delete.modal.action.delete.success'));
        },
        err => {
          this.notice.error(this.i18n.translate('guest.users.management.delete.modal.action.delete.error'));
        },
        () => {
          this.dialogRef.close();
          setTimeout(() => {
            this.data.reloadEvent();
          }, 1000);
          this.isLoading = false;
        });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

}

export class GuestUserRevocations extends GuestUser {
  public toBeRemoved: boolean = true;

  public expirationForm = new FormGroup({ expirationDate: new FormControl(null) });

  constructor(guestUser: IGuestUser) {
    super(guestUser);
  }

  public get isValid(): boolean {
    return this.expirationForm.valid;
  }

  public switchState(isDeleteState: boolean) {
    if (isDeleteState) {
      this.expirationForm.get('expirationDate').clearValidators();
      this.expirationForm.get('expirationDate').setValue(null);
    } else {
      this.expirationForm.get('expirationDate').addValidators(Validators.required);
    }
    this.expirationForm.get('expirationDate').updateValueAndValidity();
  }

}
