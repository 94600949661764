<div>
  <settings-edition-card
    [icon]="'icon_label_cropped.svg'"
    [title]="'settings.labels.card.title' | t"
    [canSave]="isValid"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading && showLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading || !showLoading">
      <div>
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <p class="description">{{ 'settings.labels.card.description' | t }}</p>
  <labels-protection-information [hasProtection]="hasProtection"></labels-protection-information>
  <div
    class="durations"
    *ngIf="isActivated"
  >
    <ng-container *ngTemplateOutlet="labelTable"> </ng-container>
  </div>
  <div
    class="durations"
    *ngIf="!isActivated"
  >
    <p [innerHTML]="'settings.labels.card.description.deactivated' | t"></p>
  </div>
</ng-template>

<ng-template #editing>
  <p class="description">{{ 'settings.labels.card.description' | t }}</p>
  <labels-protection-information [hasProtection]="hasProtection"></labels-protection-information>
  <div class="description">
    <ui-toggle
      [toggled]="isActivated"
      [labelEnabled]="'settings.notification.silencing.toggle.activated' | t"
      [labelDisabled]="'settings.notification.silencing.toggle.deactivated' | t"
      (onClick)="toggleSilencingActivation()"
    ></ui-toggle>
  </div>
  <ng-container *ngIf="isActivated">
    <labels-informations-table></labels-informations-table>
    <ng-container *ngTemplateOutlet="labelTable"> </ng-container>
  </ng-container>
</ng-template>

<ng-template #labelTable>
  <form
    [formGroup]="form"
    class="form durations"
  >

    <div
      class="empty-table"
      *ngIf="!isLoadingLabels && !initialLabels?.length "
    >
      <p>{{ 'settings.labels.card.empty.table' | t }}</p>
    </div>

    <div
      class="table-section"
      *ngIf="!isLoadingLabels; else loading"
    >
      <table>
        <ng-container
          *ngIf="labelsFormArray && initialLabels?.length"
          formArrayName="labelsFormArray"
        >
          <tr>
            <th
              (click)="sortLabels()"
              class="sortableColumn"
            >
              <div class="content">
                {{ 'settings.labels.card.labels.table.label' | t }}
                <div
                  class="ui-header-sort--indicator"
                  [ngSwitch]="direction"
                >
                  <ui-icon
                    class="ui-header-sort--icon ui-header-sort--icon_up"
                    name="arrow-asc"
                    modifier="on-header"
                    *ngSwitchCase="'asc'"
                  ></ui-icon>
                  <ui-icon
                    class="ui-header-sort--icon ui-header-sort--icon_down"
                    name="arrow-desc"
                    modifier="on-header"
                    *ngSwitchCase="'desc'"
                  ></ui-icon>
                </div>
              </div>
            </th>
            <th
              class="severity-column"
              colspan="2"
            >
              <div class="content">
                {{ 'settings.labels.card.labels.table.impact' | t }}
              </div>
            </th>
          </tr>
          <ng-container *ngFor="let label of labelsFormArray.controls">
            <tr>
              <td>
                <div class="icon">
                {{ label.value.name }}
                  <img
                    style="width:24px"
                    *ngIf="label.value.hasProtection"
                    src="assets/favicons/icon_shield_lock.svg"
                    [tooltip]="'settings.labels.card.labels.hasProtection' | t"
                  />
                </div>
              </td>
              <td>
                <div
                  *ngIf="!isEditing"
                  class="icon"
                >
                  <img
                    [ngStyle]="{ width: '10px' }"
                    *ngIf="getSeverityLevelOfLabel(label.value)?.icon"
                    [src]="getSeverityLevelOfLabel(label.value)?.icon"
                  />
                  {{ getSeverityLevelOfLabel(label.value)?.displayValue }}
                </div>
                <labels-impact-picker
                  *ngIf="isEditing"
                  [impactLevelOptions]="impactLevelOptions"
                  [labelForm]="label"
                ></labels-impact-picker>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </div>
  </form>
</ng-template>

<ng-template #loading>
  <div class="spinner">
    <ui-spinner size="medium"></ui-spinner>
  </div>
</ng-template>
