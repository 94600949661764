import { Component, Input } from "@angular/core";
import { IEntityRepresentation } from "@common/services/api/respond/entities/entities.definition";
import { IincidentTaskMessage } from "@common/services/api/respond/incidentTasks/incidentTasks.definitions";
import { IObservable } from "@common/services/api/respond/observables/observables.definition";
import { Select } from '@ngxs/store';
import { Eco } from "projects/@common/definitions/eco";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { IamApiService } from "projects/@common/services/api/iam/iam.api";
import { IIncidentActionRepresentation, IncidentCloseStatus } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IncidentTemplateMapper } from "projects/@respond/reports/templating/incidentTemplateMapper";
import { ReportService, ReportTypesEnum } from "projects/@respond/services/report.service";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-incident-report-button',
  templateUrl: './incident-report-button.component.html',
  styleUrls: [ './incident-report-button.component.scss' ],
})
export class IncidentReportButtonComponent {
  @Input()
    incidentInfo: Incident;

  @Input()
    isWithinTableColumn: boolean = true;

  @Input() 
    public observables: IObservable[] = [];

  @Input() 
    public incidentActions: IIncidentActionRepresentation[] = [];

  @Input() 
    public entities: IEntityRepresentation[] = [];

  @Input() 
    public tasksMessages: (IincidentTaskMessage & {messageContent: string})[] = [];

  @Select(EcoSessionState.organization) public organization$: Observable<Eco.IOrganization>;

  public loggedInOrganization: Eco.IOrganization = null;

  constructor(
    private i18n: I18nService,
    private iamApiService: IamApiService,
    private incidentTemplateMapper: IncidentTemplateMapper,
    private reportService: ReportService
  ) {}

  public get isDisabled(): boolean {
    return !this.incidentInfo.reportAvailable;
  }

  public get disabledTooltip(): string {
    if (this.isDisabled) {
      if (this.incidentInfo.closeStatus === IncidentCloseStatus.CLOSED_FALSE_POSITIVE || this.incidentInfo.closeStatus === IncidentCloseStatus.CLOSED_TEST) {
        return this.i18n.translate('incidents.container.page.details.nav.printReport.button.tooltip.falsePositive');
      }
      return this.i18n.translate('incidents.container.page.details.nav.printReport.button.tooltip');
    }
    return null;
  }

  public ngOnInit() {
    this.organization$.subscribe((organization) => this.loggedInOrganization = organization);
  }

  public async goToReport(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    if (!this.isDisabled) {
      const reportWindow = window.open('', Math.random().toString(), `width=${screen.width},height=${screen.height},resizable=1`);

      const preferredLang: Eco.Languages = await this.clientPreferredLanguage();

      this.incidentTemplateMapper.generateMapper(this.incidentInfo, this.observables, this.incidentActions, this.entities, this.tasksMessages).then((reportTemplate) => {
        this.reportService.generateReport(ReportTypesEnum.incident, reportTemplate, reportWindow, preferredLang);
      });
    }
  }

  private async clientPreferredLanguage(): Promise<Eco.Languages> {
    let preferredLang: Eco.Languages;

    if (this.loggedInOrganization.id === this.incidentInfo.organizationEcoId) {
      preferredLang = this.loggedInOrganization.preferredLang || (await this.iamApiService.describeOrganization(this.incidentInfo.organizationEcoId))?.preferredLang;
    } else {
      preferredLang = (await this.iamApiService.describeOrganization(this.incidentInfo.organizationEcoId))?.preferredLang;
    }

    return preferredLang || this.i18n.currentLocale as Eco.Languages;
  }
}
