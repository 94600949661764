import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { CommunicationMethodTypeEnum, ICommunicationContact, IContactCommunicationMethod, IEscalationList } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { clone } from 'projects/@common/utils/utils';
import { CommunicationMethodComponent } from '../communication-method/communication-method.component';

@Component({
  selector: 'communication-contact',
  templateUrl: './communication-contact.component.html',
  styleUrls: [ './communication-contact.component.scss' ],
})
export class CommunicationContactComponent implements OnChanges {
  @Input() contact: ICommunicationContact;
  @Input() escalationLists: IEscalationList[];
  @Input() hasEditPermission: boolean;
  @Input() hasDeletePermission: boolean;

  @Output() saveContactEvent = new EventEmitter<void>();
  @Output() deleteContactEvent = new EventEmitter<void>();

  @ViewChildren(CommunicationMethodComponent) communicationMethods: QueryList<CommunicationMethodComponent>;

  isEditing: boolean;
  isExpanded: boolean;
  canDeleteContact: boolean;

  private prevContact: ICommunicationContact;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.canDeleteContact = !this.escalationLists?.some((list) => list.ownerContactId === this.contact.id || list.escalationLevels.some((level) => level.contacts.some((contact) => contact.id === this.contact.id)));
  }

  getContactCommunicationMethodTypes(): string[] {
    return this.contact.communicationMethods.map((method) => method.type);
  }

  getMaxCommunicationMethods(): number {
    return Object.keys(CommunicationMethodTypeEnum).length;
  }

  isValid(): boolean {
    return this.isValidName() && this.isValidCommunicationMethods();
  }

  toggleExpand(value: boolean) {
    this.isExpanded = value;
  }

  toggleEdit(value: boolean) {
    this.isEditing = value;
    if (this.isEditing) {
      this.prevContact = clone(this.contact);
      this.toggleExpand(true);
    }
  }

  handleContactNameChange(value: string) {
    this.contact.name = value;
  }

  handleDeleteContact() {
    this.deleteContactEvent.emit();
  }

  handleSaveContact() {
    if (this.isValid()) {
      this.saveContactEvent.emit();
    }
  }

  handleEditCanceled() {
    if (!this.contact.createdAt) {
      this.handleDeleteContact();
      return;
    }
    this.contact = this.prevContact;
  }

  addCommucationMethod() {
    if (this.isValidCommunicationMethods()) {
      this.contact.communicationMethods.push({
        id: null,
        type: null,
        properties: {},
        contactId: this.contact.id,
      });
    }
  }

  removeCommucationMethod(communicationMethod: IContactCommunicationMethod) {
    if (this.contact.communicationMethods.length > 1) {
      this.contact.communicationMethods = this.contact.communicationMethods.filter((method) => method !== communicationMethod);
    }
  }

  private isValidName(): boolean {
    return this.contact.name?.length > 0;
  }

  private isValidCommunicationMethods(): boolean {
    return this.communicationMethods?.toArray().every((method) => method.isValid());
  }
}
