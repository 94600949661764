import { HttpClient } from '@angular/common/http';
import { IRespondResponse } from 'projects/@common/definitions/IRespondResponse';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { IGetPlaybookQuery, IGetPlaybookStats, IGetPlaybookTemplateQuery, IListPlaybooksRequest, IListPlaybooksTemplateQuery, IPlaybookDetail, IPlaybookListResponse, IPlaybookTaskTemplateRepresentation, IPlaybookTemplate, IPlaybooksApi, getPlaybookTaskTemplatesRequest } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { UrlUtils } from 'projects/@common/utils/utils';

export class CustomerPlaybooksApiService extends EcoApi implements IPlaybooksApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;
  private readonly playbookTemplates = 'playbookTemplates';

  constructor(http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public getPlaybook(organizationId: string, playbookId: string, queryParams?: IGetPlaybookQuery): Promise<IPlaybookDetail> {
    const url = `${super.host}/me/playbooks/${playbookId}?${UrlUtils.jsonToQueryParams(queryParams)}`;
    return this.http.get<IPlaybookDetail>(url).toPromise();
  }

  public getPlaybookTemplateById(templateId: string, queryParams?: IGetPlaybookTemplateQuery): Promise<IPlaybookTemplate> {
    const url = `${super.host}/${this.playbookTemplates}/${templateId}?${UrlUtils.jsonToQueryParams(queryParams)}`;
    return this.http.get<IPlaybookTemplate>(url).toPromise();
  }

  public getPlaybookTemplates(queryParams?: IListPlaybooksTemplateQuery): Promise<IRespondResponse<IPlaybookTemplate>> {
    const url = `${super.host}/playbookTemplates?${UrlUtils.jsonToQueryParams(queryParams)}`;
    return this.http.get<IRespondResponse<IPlaybookTemplate>>(url).toPromise();
  }

  public getPlaybookTaskTemplates(_params: getPlaybookTaskTemplatesRequest): Promise<IRespondResponse<IPlaybookTaskTemplateRepresentation>> {
    throw new Error('Method not implemented.');
  }

  public getPlaybooks(organizationId: string, queryParam?: IListPlaybooksRequest): Promise<IRespondResponse<IPlaybookListResponse>> {
    const url = `${super.host}/me/playbooks?${UrlUtils.jsonToQueryParams(queryParam)}`;
    return this.http.get<IRespondResponse<IPlaybookListResponse>>(url).toPromise();
  }

  public getPlaybookStats(organizationId: string): Promise<IGetPlaybookStats> {
    const url = `${super.host}/me/playbooks/stats`;
    return this.http.get<IGetPlaybookStats>(url).toPromise();
  }

  public createPlaybook(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail> {
    throw new Error('Method not implemented.');
  }

  public updatePlaybook(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail> {
    throw new Error('Method not implemented.');
  }

  public deletePlaybook(organizationId: string, playbookId: string): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public updatePlaybookVersion(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail> {
    throw new Error('Method not implemented.');
  }
}
