import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { PlaybooksApiService } from "projects/@common/services/api/respond/playbooks/playbooks.api";
import { IListPlaybooksTemplateQuery, IPlaybookTemplate } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";


export class PlaybooksCatalogContainerActions {
  public playbooksCatalog: BehaviorSubject<IPlaybookTemplate[]> = new BehaviorSubject([]);
  private catalog: IPlaybookTemplate[];

  private previousRequestResponse: IRespondResponse<IPlaybookTemplate>;

  public isLoading = false;

  public total = 0;

  public hasMoreToLoad: boolean = false;

  public filterCatalogByName: string = null;

  constructor(private readonly playbooksApiService: PlaybooksApiService) { }

  public get queryParams(): IListPlaybooksTemplateQuery {
    const params = {
      from: this.previousRequestResponse ? this.previousRequestResponse.nextItem : 0,
      size: 150,
    };
    return Object.fromEntries(Object.entries(params).filter(([ _, filter ]) => (!Array.isArray(filter) && filter != null) || (Array.isArray(filter) && filter?.length > 0)));
  }

  public async handlePlaybookSearchQuery(value: string, currentLocal: string): Promise<void> {
    if (value) {
      this.filterCatalogByName = value.trim();
      const filteredPlaybooks = this.catalog.filter((data) => {
        if (data.name[currentLocal]) {
          return data.name[currentLocal].toLowerCase().includes(this.filterCatalogByName.toLowerCase());
        }
        return true;
      });
      this.total = filteredPlaybooks.length;
      this.playbooksCatalog.next(filteredPlaybooks);
    } else {
      this.handlePlaybookSearchClear();
    }
  }

  public async handlePlaybookSearchClear(): Promise<void> {
    this.filterCatalogByName = null;
    this.total = this.catalog.length;
    this.playbooksCatalog.next(this.catalog);
  }

  public async fetchPlaybooksCatalog(loadMore: boolean = false): Promise<IPlaybookTemplate[]> {
    if (!this.isLoading) {
      this.isLoading = true;
      const params = this.queryParams;
      if (!loadMore) {
        params.from = 0;
      }
      await this.playbooksApiService.getPlaybookTemplates(params)
        .then((response) => {
          if (loadMore) {
            this.catalog = this.catalog.concat(response.items);
          } else {
            this.catalog = response.items;
          }
          this.total = this.catalog.length;
          this.hasMoreToLoad = response.nextItem < response.total;
          this.previousRequestResponse = response;
          this.handlePlaybookSearchClear();
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    return this.catalog;
  }

  public async handleRefresh(): Promise<void> {
    await this.fetchPlaybooksCatalog();
  }
}
