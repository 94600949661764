<ui-tabs
  #tabs
  color="drawerTabs"
>
  <ui-tab [tabTitle]="'parameter.details.title' | t">
    <whitelist-details
      *ngIf="!isLoading; else spinner"
      [whitelist]="whitelist"
      [organizationId]="organizationId"
    ></whitelist-details>
  </ui-tab>

  <ui-tab
    *ngIf="!!whitelist && !isEditing"
    [tabTitle]="'alerts.drawer.tab.history.title' | t"
  >
    <whitelist-history
      *ngIf="!isLoading; else spinner"
      [whitelist]="whitelist"
    ></whitelist-history>
  </ui-tab>
</ui-tabs>


<ng-template #spinner>
  <div class="spinner">
    <ui-spinner
      size="medium"
      modifier="secondary"
    ></ui-spinner>
  </div>
</ng-template>