import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { AbstractDrawer } from 'projects/@common/modules/layout/components/drawer/AbstractDrawer';

@Component({
  selector: 'usecase-create-drawer',
  templateUrl: './usecase-create-drawer.component.html',
  styleUrls: [ './usecase-create-drawer.component.scss' ],
})
export class UsecaseCreateDrawer extends AbstractDrawer {
  @Input() public data: any;

  constructor(readonly store: Store) {
    super(store);
  }
}
