import { CategoryAxis, ColumnSeries, LabelBullet, ValueAxis, XYChart } from '@amcharts/amcharts4/charts';
import { Scrollbar, addLicense, color, create } from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ITimeInterval } from 'projects/@common/components/date-interval/date-interval.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';

@Component({
  selector: 'incidents-ongoing-card-var',
  templateUrl: './incidents-ongoing-card-var.component.html',
  styleUrls: [ './incidents-ongoing-card-var.component.scss' ],
})
export class IncidentsOngoingCardComponentVar implements OnInit, AfterViewInit {
  private readonly COLORS = [ "#004C2A", "#006F3E", "#068D44", "#1AA969", "#5ECC99", "#9EDDC1", "#004C41", "#006F5F", "#068D6D", "#1AA994" ];
  private readonly GREEN_COLORS = '#0c8551';

  @Input() public currentOrganization: IAutomationOrganization;
  public chartData: { organizationName: string, incdentsOnGoing: number }[] = [];
  public incidentQueryParams;
  public isLoading: boolean = false;

  private chart: XYChart;
  private readonly maximumItemToDisplay: number = 12;

  constructor(
    public readonly i18n: I18nService,
    private readonly incidentsApi: IncidentsApi
  ) {
    addLicense('CH259911983');
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.incidentsApi.getIncidentsStats().toPromise()
      .then((data) => {
        if (data) {
          this.chartData = data.map((item) => ({
            organizationName: item.organizationName,
            incdentsOnGoing: item.open,
          }));
          if (this.chart) {
            this.chart.data = this.chartData;
          }
        }
      })
      .finally(() => this.isLoading = false);
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  public calculateChartHeight(): string {
    const vhMaxHeight = 40;
    const minChartHeight = 25; // Minimum chart height to ensure visibility
    const itemHeight = 12; // Height per item in vh

    const chartHeight = Math.max(minChartHeight, this.chartData.length * itemHeight);
    return `${Math.min(chartHeight, vhMaxHeight)}vh`;
  }

  private createChart(): void {
    const chart = create(`INCIDENTS_ONGOING_VAR_GRAPH_ID`, XYChart);
    chart.data = this.chartData;
    chart.colors.list = this.COLORS.map((colorHexValue) => color(colorHexValue));

    const categoryAxis = chart.yAxes.push(new CategoryAxis());
    categoryAxis.dataFields.category = "organizationName";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.labels.template.truncate = true;
    categoryAxis.renderer.labels.template.align = 'right';
    categoryAxis.renderer.labels.template.textAlign = 'end';
    categoryAxis.renderer.labels.template.width = 160;

    const valueAxis = chart.xAxes.push(new ValueAxis());
    valueAxis.min = 0;
    valueAxis.keepSelection = true;
    valueAxis.extraMax = 0.1;

    const series = chart.series.push(new ColumnSeries());
    series.dataFields.categoryY = "organizationName";
    series.dataFields.valueX = "incdentsOnGoing";
    series.tooltipText = "{valueX}";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusBottomRight = 3;
    series.columns.template.column.cornerRadiusTopRight = 3;
    series.columns.template.column.fill = color(this.GREEN_COLORS);
    categoryAxis.sortBySeries = series;

    // Change series colors base on the colors list
    series.columns.template.adapter.add("fill", (_, target) => {
      if (target.dataItem) {
        const colorIndex = this.chartData.findIndex((data) => data.organizationName === target.dataItem.dataContext['organizationName']);
        return chart.colors.getIndex(colorIndex ? colorIndex : 0);
      }
    });

    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.horizontalCenter = "left";
    labelBullet.label.truncate = false;
    labelBullet.label.dx = 5;
    labelBullet.label.text = `{values.valueX.workingValue}`;

    this.addScrollbarLogic(chart);

    this.chart = chart;
  }

  private addScrollbarLogic(chart: XYChart): void {
    chart.events.on("datavalidated", () => {
      chart.scrollbarY = null;
      if (chart.data.length > this.maximumItemToDisplay) {
        chart.zoomOutButton.disabled = true;
        chart.mouseWheelBehavior = 'panY';
        chart.scrollbarY = new Scrollbar();
        chart.scrollbarY.orientation = "vertical";
        chart.scrollbarY.startGrip.icon.disabled = true;
        chart.scrollbarY.endGrip.icon.disabled = true;
        chart.scrollbarY.startGrip.background.disabled = true;
        chart.scrollbarY.endGrip.background.disabled = true;
        chart.scrollbarY.startGrip.draggable = false;
        chart.scrollbarY.endGrip.draggable = false;
        chart.scrollbarY.showSystemTooltip = false;
        chart.scrollbarY.thumb.showSystemTooltip = false;
        chart.scrollbarY.startGrip.showSystemTooltip = false;
        chart.scrollbarY.endGrip.showSystemTooltip = false;
        const categoryAxis = chart.yAxes.getIndex(0) as CategoryAxis;
        categoryAxis.zoomToIndexes(0, this.maximumItemToDisplay);
      }
    });
  }
}
