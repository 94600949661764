import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { Eco } from 'projects/@common/definitions/eco';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { AlertingUsecaseListItem } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { IGroupedConditions, Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';
import { WhitelistsApi } from 'projects/@common/services/api/respond/whitelists/whitelists.api';
import { IWhitelistCondition, IWhitelistConditionField, IWhitelistPeriod, WhitelistResolution } from 'projects/@common/services/api/respond/whitelists/whitelists.definitions';
import { clone } from 'projects/@common/utils/utils';
import { WhitelistsService } from '../../whitelists.service';
import { getWhitelistConditionDescription } from '../whitelist-conditions/getWhitelistConditionDescription';
import { WhitelistConditionsComponent } from '../whitelist-conditions/whitelist-conditions.component';
import { WhitelistWeeklyScheduleComponent } from '../whitelist-weekly-schedule/whitelist-weekly-schedule.component';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { AutocompleteCustomValue } from '@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component';
import { TimeUtil } from '@ui-kit/services/time-util';


@Component({
  selector: 'whitelist-details',
  templateUrl: './whitelist-details.component.html',
  styleUrls: [ './whitelist-details.component.scss' ],
})
export class WhitelistDetailsComponent implements OnInit, OnChanges {
  @Input() public whitelist: Whitelist;
  @Input() public isReadonly: boolean;
  @Input() public organizationId: string;

  @ViewChild(WhitelistConditionsComponent) public whitelistConditions: WhitelistConditionsComponent;
  @ViewChild(WhitelistWeeklyScheduleComponent) public whitelistWeeklyScheduleComponent: WhitelistWeeklyScheduleComponent;

  public currentWhitelist: Whitelist;

  public isLoadingConditionFields: boolean;
  public form: UntypedFormGroup;
  public isEditing: boolean = false;
  public showDateRange: boolean = false;
  public daysOfWeek = [ 0, 1, 2, 3, 4, 5, 6 ];
  public conditionFields: IWhitelistConditionField[];
  public groupedReadonlyConditions: IGroupedConditions[] = [];

  public alertingUsecasesList: AlertingUsecaseListItem[];
  public usecaseAutocompleteOptions: AutocompleteCustomValue[];
  public autocompleteSelectedUsecases: AutocompleteCustomValue[] = [];
  public customAutocompleteType = AutocompleteTypes.CUSTOM;
  public selectedUsecasesToDisplay: AutocompleteCustomValue[] = [];
  public resolution = WhitelistResolution.FINALIZE;

  public resolutions = [
    { value: WhitelistResolution.FINALIZE, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.resolution.${WhitelistResolution.FINALIZE}`) },
    { value: WhitelistResolution.AUTO_CLOSE_EXPECTED, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.resolution.${WhitelistResolution.AUTO_CLOSE_EXPECTED}`) },
    { value: WhitelistResolution.AUTO_CLOSE_FALSE_POSITIVE, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.resolution.${WhitelistResolution.AUTO_CLOSE_FALSE_POSITIVE}`) },
  ];

  constructor(
    public readonly i18n: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly usecasesApi: UsecasesApiService,
    private readonly drawerService: DrawerService,
    private readonly translatedObjectPipe: TranslatedObjectPipe,
    private readonly whitelistsApi: WhitelistsApi,
    private readonly whitelistsService: WhitelistsService
  ) { }

  ngOnInit(): void {
    this.initCurrentWhitelist();
    this.fetchWhitelistConditionFields();
    if (this.isCreateMode) {
      this.toggleEditing(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.whitelist?.currentValue) {
      this.groupedReadonlyConditions = this.whitelist.getReadonlyConditionGroups(this.i18n.currentLocale as Eco.Languages);
    }
  }

  public get whitelistId(): string {
    return this.currentWhitelist?.id || this.whitelist?.id;
  }

  public get isCreateMode(): boolean {
    return !this.whitelistId;
  }

  public get minEndDate(): Date {
    return this.currentWhitelist?.startsAt ? new Date(this.currentWhitelist.startsAt) : null;
  }

  public get validStartsAt(): boolean {
    return this.currentWhitelist?.startsAt ? this.currentWhitelist.startsAt > TimeUtil.dayDuration : false;
  }

  public get validUsecaseIds(): boolean {
    return this.currentWhitelist?.usecases?.length > 0 && this.currentWhitelist.usecases.every((usecase) => Boolean(this.usecaseAutocompleteOptions?.find((option) => option.value === usecase.id)));
  }

  public get validPeriods(): boolean {
    return this.showDateRange ? this.whitelistWeeklyScheduleComponent?.isValidWeeklySchedule : true;
  }

  public get validConditions(): boolean {
    return this.currentWhitelist?.conditions?.length > 0 && this.whitelistConditions?.isValidConditions;
  }

  public get isValidData(): boolean {
    return !!this.form?.valid && this.validUsecaseIds && this.validStartsAt && this.validPeriods && this.validConditions;
  }

  public getConditionLabel(condition: IWhitelistCondition): string {
    const name = this.conditionFields.find((field) => field.id === condition.id)?.name;
    return this.translatedObjectPipe.transform(name);
  }

  public getConditionDescription(condition: IWhitelistCondition): string {
    return getWhitelistConditionDescription(condition, this.conditionFields, this.i18n);
  }

  public getPeriodLabel(period: IWhitelistPeriod): string {
    const dayOfWeek = this.i18n.translate(`common.weekdays.${period.dayOfWeek}`);
    const from = `${period.fromHour}:${TimeUtil.padZero(period.fromMinute)}`;
    const to = `${period.toHour}:${TimeUtil.padZero(period.toMinute)}`;
    const separator = this.i18n.translate('common.to');
    return `${dayOfWeek} ${from} ${separator} ${to}`;
  }

  public toggleEditing(value: boolean): void {
    this.isEditing = value;
    if (this.isEditing) {
      this.initCurrentWhitelist();
      this.initUsecaseAutocomplete();
      this.createForm();
      if (this.currentWhitelist?.periods?.length) {
        this.toggleShowDateRange(true);
      }
      // if (this.currentWhitelist?.autoClose) {
      //   this.toggleAutoClose(true);
      // }
    }
    this.drawerService.setEditing(this.isEditing, 'WhitelistDetailsComponent');
  }

  public toggleShowDateRange(event: boolean): void {
    this.showDateRange = event;
    if (!this.showDateRange) {
      this.currentWhitelist.periods = [];
    }
  }

  // public toggleAutoClose(event: boolean): void {
  //   this.currentWhitelist.autoClose = event;
  //   this.autoClose = event;
  // }

  public handleSave(): void {
    if (this.isValidData) {
      this.toggleEditing(false);
      this.currentWhitelist.name = this.form.get('name').value;
      this.currentWhitelist.description = this.form.get('description').value;
      if (this.isCreateMode) {
        this.whitelistsService.createWhitelist(this.currentWhitelist);
      } else {
        this.currentWhitelist.id = this.whitelistId;
        this.whitelistsService.updateWhitelist(this.currentWhitelist);
      }
    }
  }

  public cancel(): void {
    if (this.isCreateMode) {
      this.drawerService.hideDrawer();
    } else {
      this.toggleEditing(false);
      this.currentWhitelist = this.whitelist ? clone(this.whitelist) : new Whitelist();
      this.setDisplayedUsecases();
    }
  }

  public handleWeeklyScheduleChanged(event: IWhitelistPeriod[]): void {
    this.currentWhitelist.periods = event;
  }

  public handleUsecaseSelectEvent(event: AutocompleteCustomValue): void {
    if (event?.value) {
      const usecase: AlertingUsecaseListItem = this.alertingUsecasesList.find((usecase) => usecase.id === event.value);
      if (usecase && !this.currentWhitelist.usecaseIds.includes(usecase.id)) {
        this.currentWhitelist.usecases.push({
          id: usecase.id,
          name: usecase.name,
          usecaseCatalogId: usecase.catalogId,
          datasourceType: usecase.datasourceType,
        });
        this.setDisplayedUsecases();
      }
    }
  }

  public handleUnselectUsecase(usecaseId: string): void {
    this.currentWhitelist.usecases = this.currentWhitelist.usecases.filter((usecase) => usecase.id !== usecaseId);
    this.autocompleteSelectedUsecases = this.autocompleteSelectedUsecases.filter((usecase) => usecase.value !== usecaseId);
    this.setDisplayedUsecases();
  }

  public handleStartsAtChanged(event: number): void {
    this.currentWhitelist.startsAt = event;
  }

  public handleEndsAtChanged(event: number): void {
    this.currentWhitelist.endsAt = event;
  }

  public handleConditionsChanged(event: IWhitelistCondition[]): void {
    this.currentWhitelist.conditions = event;
  }
  
  public handleResolutionChange(value: any): void {
    this.currentWhitelist.resolution = value;
  }

  public getUsecaseDisplayName(usecaseCatalogId: string, name: ITranslatedField, datasourceType: string): string {
    return `${usecaseCatalogId} (${this.translatedObjectPipe.transform(name)}) [${datasourceType}]`;
  }

  private initCurrentWhitelist(): void {
    this.currentWhitelist = this.whitelist ? clone(this.whitelist) : new Whitelist();
    this.currentWhitelist.organizationId = this.organizationId;
    this.setDisplayedUsecases();
  }

  private setDisplayedUsecases(): void {
    this.selectedUsecasesToDisplay = [];
    if (this?.currentWhitelist?.usecases?.length) {
      this.selectedUsecasesToDisplay = this.currentWhitelist.usecases
        .map((usecase) => ({
          value: usecase.id,
          displayValue: this.getUsecaseDisplayName(usecase.usecaseCatalogId, usecase.name, usecase.datasourceType),
        }))
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
    }
  }

  private safeString(value: string): string {
    return value && typeof value === 'string' ? value : '';
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      name: [ this.safeString(this.whitelist?.name), [ Validators.required, Validators.maxLength(250) ] ],
      description: [ this.safeString(this.whitelist?.description), [ Validators.required, Validators.maxLength(1000) ] ],
    });
  }

  private async initUsecaseAutocomplete(): Promise<void> {
    if (!this.alertingUsecasesList) {
      this.alertingUsecasesList = await this.fetchUsecases();
    }
    this.usecaseAutocompleteOptions = this.alertingUsecasesList
      .map((usecase: AlertingUsecaseListItem) => ({
        value: usecase.id,
        displayValue: this.getUsecaseDisplayName(usecase.catalogId, usecase.name, usecase.datasourceType),
      }))
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue));

    this.autocompleteSelectedUsecases = this.usecaseAutocompleteOptions.filter((option) => this.currentWhitelist?.usecases?.find((usecase) => usecase.id === option.value));
  }

  private fetchWhitelistConditionFields(): void {
    this.isLoadingConditionFields = true;
    this.whitelistsApi.getWhitelistConditionFields({ organizationId: this.organizationId || null })
      .then((response) => {
        this.conditionFields = response;
        this.isLoadingConditionFields = false;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoadingConditionFields = false;
      });
  }

  private async fetchUsecases(): Promise<AlertingUsecaseListItem[]> {
    try {
      const response = await this.usecasesApi.getUsecaseList(this.organizationId || null, { from: 0, size: 500 });
      const usecases = response.items;
      return usecases;
    } catch (error) {
      return [];
    }
  }
}
