import { Component, Input } from '@angular/core';
import { AbstractDrawer } from '@common/modules/layout/components/drawer/AbstractDrawer';
import { DrawerState } from '@common/modules/layout/components/drawer/stores/drawer.store';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RoleDetailsComponent } from '../../../components/role-details/role-details.component';
import { RoleMembersComponent } from '../../../components/role-members/role-members.component';
import { RoleSecurityGroupsComponent } from '../../../components/role-security-groups/role-security-groups.component';

@Component({
  selector: 'role-drawer',
  templateUrl: './role-drawer.container.html',
})
export class RoleDrawerContainer extends AbstractDrawer {
  @Select(DrawerState.isEditing) public isEditing$: Observable<boolean>;

  @Select(DrawerState.tabName) public tabName$: Observable<string>;

  @Input() public data: any;

  public profileDetailsTab = RoleDetailsComponent.COMPONENT;
  public membersComponent = RoleMembersComponent.COMPONENT;
  public securityGroupsComponent = RoleSecurityGroupsComponent.COMPONENT;
  constructor(private readonly store: Store) {
    super(store);
  }
}
