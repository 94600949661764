import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";
import { GetAlertFindingsRequest } from "./model/findingFilters";
import { Observable } from "rxjs";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Finding } from "./model/finding";

export interface IFinding {
  id: string;
  createdAt?: number;
  monitorId: string;
  monitorName: string;
  organizationId: string;
  content: string;
  monitorDescription?: ITranslatedField;
}

export enum ListFindingsOrderBy {
  createdAt='createdAt'
}

export enum ListFindingsDirection {
  asc = 'ASC',
  desc = 'DESC'
}

export interface IListFindingsRequest {
  organizationId: string;
  alertId?: string;
  from?: number;
  size?: number;
  orderBy?: ListFindingsOrderBy;
  direction?: ListFindingsDirection;
}

export interface IFindingsApi {
  listFindings(request: GetAlertFindingsRequest): Observable<ResponseUtils<Finding>>
}
