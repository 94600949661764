import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EcoUrlService } from '../../core/url.service';

@Injectable({
  providedIn: 'root',
})
export class MeApi {
  private readonly prefix = '/iam/me';
  private readonly host: string;

  constructor (
    private http: HttpClient,
    url: EcoUrlService
  ) {
    this.host = url.baseUrl + this.prefix;
  }

  public async describeMyOrganization(): Promise<any> {
    const url = `${this.host}/organization`;
    return this.http.get(url).toPromise();
  }
}
