<div class="slide-container">
  <ng-container *ngIf="!isRestrictedUser; else restrictedUser">
    <h2>{{ 'dashboard.dialog.carousel.page1.title' | t }}</h2>
    <div class="content-wrapper">
      <section class="left-section">
        <h3>{{ 'dashboard.dialog.carousel.page1.second-title1' | t }}</h3>
        <p>{{ 'dashboard.dialog.carousel.page1.text1' | t }}</p>
        <h3>{{ 'dashboard.dialog.carousel.page1.second-title2' | t }}</h3>
        <p>{{ 'dashboard.dialog.carousel.page1.text2' | t }}</p>
        <h3>{{ 'dashboard.dialog.carousel.page1.second-title3' | t }}</h3>
        <p>{{ 'dashboard.dialog.carousel.page1.text3' | t }}</p>
        <ng-container *ngIf="isNewClient">
          <p class="contact-us">
            <a style="font-weight: bold" [href]="localizedLink">{{ 'dashboard.dialog.carousel.page6.contact' | t }}</a>
            {{ 'dashboard.dialog.carousel.page6.contact.2' | t }}
          </p>
        </ng-container>
      </section>
      <section class="right-section" *ngIf="!mobileService.isMobile(800)">
        <img src="assets/favicons/icon_illustration_fusee.svg" />
      </section>
    </div>
  </ng-container>
</div>

<ng-template #restrictedUser>
  <div class="restricted-access">
    <h3 class="restricted-title">{{ 'dashboard.dialog.carousel.page1.second-title4' | t }}</h3>
    <p>{{ 'dashboard.dialog.carousel.page1.text4' | t }}</p>
    <img src="assets/favicons/icon_illustration_fusee.svg" />
  </div>
</ng-template>
