import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalService } from '@ui-kit/services/modal.service';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { SearchMode } from 'projects/@common/modules/layout/components/page/page.component';
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ConnectorsApiOrganizationService } from 'projects/@common/services/api/detect/organizations/connectors-api-organizations';
import { DescribeOrganizationResponse } from 'projects/@common/services/api/detect/organizations/definitions';
import { Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';
import { IWhitelistRepresentation, IWhitelistsListViewParams } from 'projects/@common/services/api/respond/whitelists/whitelists.definitions';
import { IWhitelistsDeleteModalData, WhitelistsDeleteModalComponent } from '../../components/whitelists-delete-modal/whitelists-delete-modal.component';
import { WhitelistsService } from '../../whitelists.service';
import { IWhitelistDrawerData, RespondWhitelistDrawerContainer } from '../respond-whitelist-drawer/respond-whitelist-drawer.container';

@Component({
  selector: 'respond-whitelists',
  templateUrl: './respond-whitelists.container.html',
  styleUrls: [ './respond-whitelists.container.scss' ],
})
export class RespondWhitelistsContainer implements OnInit {
  @Input() public viewParams: IWhitelistsListViewParams;

  public activeOrganization: DescribeOrganizationResponse;

  public canList: boolean = false;
  public canCreate: boolean = false;
  public canDelete: boolean = false;
  public canUpdate: boolean = false;
  public canDescribe: boolean = false;
  public searchMode = SearchMode.RESPOND;

  private isVarMode: boolean = false;

  constructor(
    private readonly whitelistsService: WhitelistsService,
    private readonly i18n: I18nService,
    private readonly displayService: DisplayService,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    private readonly modalService: ModalService,
    private readonly store: Store,
    private drawerService: DrawerService,
    @Inject('CONSOLE_TYPE') public consoleType: ConsoleType
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  ngOnInit(): void {
    this.whitelistsService.setDefaultSettings();
    this.canList = this.displayService.meetsRequirements({ permissions: [ 'can_list_whitelists' ] });
    this.canCreate = this.displayService.meetsRequirements({ permissions: [ 'can_create_whitelist' ] });
    this.canDelete = this.displayService.meetsRequirements({ permissions: [ 'can_delete_whitelist' ] });
    this.canUpdate = this.displayService.meetsRequirements({ permissions: [ 'can_update_whitelist' ] });
    this.canDescribe = this.displayService.meetsRequirements({ permissions: [ 'can_describe_whitelist' ] });
    if (this.canList && !this.showEmptyPageToSelectOrganization) {
      // immediate fetch for consoles that do not have an organization selector
      this.fetchWhitelists();
    }
  }

  public get adminConsole(): boolean {
    return this.consoleType === ConsoleType.ADM || this.consoleType === ConsoleType.ORG;
  }

  public get whitelistsData(): Array<IWhitelistRepresentation> {
    return this.whitelistsService.whitelistsData;
  }

  public get defaultTableColumns(): Array<object> {
    return this.viewParams.tableColumns;
  }

  public get isReadonly(): boolean {
    return this.viewParams.isReadonly;
  }

  public get showEmptyPageToSelectOrganization(): boolean {
    return this.isVarMode && !this.activeOrganization;
  }

  public getOrganizationId(whitelist?: IWhitelistRepresentation): string {
    if (whitelist) {
      return whitelist.organizationId; // existing whitelist
    }
    if (this.activeOrganization) {
      return this.activeOrganization.organization_respond_id; // using organization selector
    }
    return this.store.selectSnapshot(EcoSessionState.organization).id; // logged in user's organization
  }

  public fetchWhitelists(): void {
    this.whitelistsService.fetchWhitelists();
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    this.drawerService.hideDrawer();
    if (org) {
      this.activeOrganization = await this.connectorsApiOrganizationService.describeOrganization(org.id);
      this.whitelistsService.setOrganizationId(this.activeOrganization.organization_respond_id);
      this.fetchWhitelists();
    } else {
      this.activeOrganization = null;
    }
  }

  public openWhitelistDrawer(whitelist: IWhitelistRepresentation | null): void {
    const drawerInputData: IWhitelistDrawerData = {
      title: whitelist ? whitelist.name : this.i18n.translate(`whitelists.drawer.title.create`),
      whitelistId: whitelist ? whitelist.id : null,
      organizationId: this.getOrganizationId(whitelist),
      isReadonly: !this.canUpdate || this.viewParams.isReadonly,
    };
    this.drawerService.replaceCurrentDrawer(RespondWhitelistDrawerContainer, {
      component: DrawerPageEnum.whitelistDrawer,
      data: drawerInputData,
    });
  }

  public openDeleteModal(): void {
    const dialogData: IWhitelistsDeleteModalData = {
      whitelists: this.whitelistsService.selectedRows.map((selectedRow) => new Whitelist(selectedRow)),
    };
    this.modalService.openDialog(WhitelistsDeleteModalComponent, dialogData);
  }
}
