import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationSensitiveFilesSettings, OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';

@Component({
  selector: 'dlp-activation',
  templateUrl: './dlp-activation.component.html',
  styleUrls: ['./dlp-activation.component.scss'],
})
export class DlpActivationComponent implements OnInit {
  @Input() public currentSettings: OrganizationSensitiveFilesSettings;
  @Input() public isLoading = false;

  public isEditing = false;
  public isActivated = false;

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private noticeService: NoticeService
  ) {}

  ngOnInit() {
    this.initForm(this.currentSettings);
  }

  private initForm(currentSettings?: OrganizationSensitiveFilesSettings) {
    this.isActivated = !!currentSettings?.isActivated;
  }

  public get activationIsDefined(): boolean {
    return "isActivated" in this.currentSettings && ![undefined, null].includes(this.isActivated)
  }

  public cancel() {
    this.initForm(this.currentSettings);
  }

  public save(): void {
    this.organizationsApiService
      .updateOrganizationSettings({
        newDlpSettings: {
          isActivated: this.isActivated
        },
      })
      .then((response) => {
        this.currentSettings = response;
        this.initForm(response);
        this.noticeService.notifyUser({
          message: 'settings.dlp.activation.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.dlp.activation.snack.error',
          level: NoticeLevels.ERROR,
        });
      });
  }


  public toggleSilencingActivation() {
    this.isActivated = !this.isActivated;
  }
}
