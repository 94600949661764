import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { IEntityForDisplay, IEntityRepresentation } from "projects/@common/services/api/respond/entities/entities.definition";
import { EntityTypeEnum } from "@md.eco/actions/enums";


export function mapEntityRepresentationToDisplayEntity(entity: IEntityRepresentation, i18n: I18nService): IEntityForDisplay {
  const entityForDisplay: IEntityForDisplay = {
    ...entity,
    label: entity.key,
    iconSrc: getIconSrc(entity.type),
    typeTranslationKey: getTypeTranslationKey(entity.type),
    payloadDisplayFields: [],
  };

  const translateField = (field: string): string => {
    const translationKey = getFieldTranslationKey(field);
    return i18n.translate(translationKey);
  };

  switch (entity.type) {
    case EntityTypeEnum.INSTANCE:
      if (entity.payload.instanceType) {
        const newIconSrc = getIconSrc(`${entity.type}_${entity.payload.instanceType.split(".")[0]}`);
        if (newIconSrc) entityForDisplay.iconSrc = newIconSrc;
      }
      // let's roll into the next cases
    case EntityTypeEnum.ADDRESS:
    case EntityTypeEnum.DATABASE_INSTANCE:
    case EntityTypeEnum.FILE:
    case EntityTypeEnum.NETWORK_INTERFACE:
    case EntityTypeEnum.SECURITY_GROUP:
    case EntityTypeEnum.MAIL:
    case EntityTypeEnum.GROUP:
      for (const key of [ ...new Set(Object.keys(entity.payload)) ]) {
        console.log(key);
        if (entity.payload[key] !== undefined) {
          entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey(key), value: parseValuesToString([ entity.payload[key] ]) });
        }
      }
      break;
    case EntityTypeEnum.USER:
      entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("id"), value: parseValuesToString([ entity.payload.id ]) });
      if (entity.payload.department) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("department"), value: parseValuesToString([ entity.payload.department ]) });
      if (entity.payload.jobTitle) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("jobTitle"), value: parseValuesToString([ entity.payload.department ]) });
      if (entity.payload.phoneNumber) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("phoneNumber"), value: parseValuesToString([ entity.payload.phoneNumber?.business, entity.payload.phoneNumber?.mobile ]) });
      if (entity.payload.location) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("location"), value: parseValuesToString([ entity.payload.location ]) });

      entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("email"), value: parseValuesToString([ entity.payload.mail, entity.payload.proxyAddresses ]) });

      if (entity.payload.enabled !== undefined) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("enabled"), value: translateField(`enabled.${entity.payload.enabled}`) });

      if (entity.payload.permitId) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("permitId"), value: parseValuesToString([ entity.payload.permitId ]) });
      if (entity.payload.officeAddress) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("officeAddress"), value: parseValuesToString([ entity.payload.officeAddress ]) });
      if (entity.payload.roles) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("roles"), value: parseValuesToString([ entity.payload.roles ]) });

      if (entity.payload.goqNda !== undefined) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("goqNda"), value: translateField(`goq.${entity.payload.goqNda}`) });
      if (entity.payload.goqSq !== undefined) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("goqSq"), value: translateField(`goq.${entity.payload.goqSq}`) });
      if (entity.payload.goqSpvm !== undefined) entityForDisplay.payloadDisplayFields.push({ translatedLabel: getFieldTranslationKey("goqSpvm"), value: translateField(`goq.${entity.payload.goqSpvm}`) });
      break;
    default:
      break;
  }

  return entityForDisplay;
}


function getIconSrc(entityType: string): string {
  return `assets/favicons/entities/icon_${entityType}.svg`;
}

function getTypeTranslationKey(entityType: string): string {
  return `respond.entities.type.${entityType}`;
}

function getFieldTranslationKey(field: string): string {
  return `respond.entities.field.${field}`;
}

function parseValuesToString(values: Array<string | string[]>): string {
  const flattenedValues: string[] = values
    .map((value) => {
      if (typeof value === 'string' && value !== '') {
        return value;
      } else if (Array.isArray(value) && value.length > 0) {
        return value.join(", ");
      }
      return undefined;
    })
    .filter((value) => value !== undefined);

  return flattenedValues.join(", ") || '-';
}
