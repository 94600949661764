import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";
import { RequestDirection } from "projects/@common/definitions/consoles.enum";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { GetAlertHistoriesRequest } from "./models/getAlertHistoriesRequest";
import { GetIncidentHistoriesRequest } from "./models/getIncidentIHistoriesRequest";
import { GetHistoriesRequest } from "./models/getHistoriesRequest";

export interface IListHistoryRequest {
  incidentId?: string;
  alertId?: string;
  filterId?: string;
  whitelistId?: string;
  organizationId?: string;
  incidentTaskId?: string;
  type?: HistoryType;
  from?: number;
  size?: number;
  orderBy?: ListHistoryOrderBy;
  direction?: RequestDirection;
}
export interface IGetHistoriesRequest {
  organizationId: string;
  organizationIds: string;
  type?: HistoryType;
  dateFrom?: number;
  dateTo?: number;
  owners?: string;
  types?: string;
  isVar?: boolean;
  isClient?: boolean;
  isSystem?: boolean;
  write?: boolean;
  from?: number;
  size?: number;
  orderBy?: ListHistoryOrderBy;
  direction?: RequestDirection;
}

export interface IGetAlertHistoriesRequest {
  alertId: string;
  organizationId: string;
  dateFrom?: number;
  dateTo?: number;
  from?: number;
  size?: number;
  orderBy?: ListHistoryOrderBy;
  direction?: RequestDirection;
}

export interface IGetPlaybookHistoriesRequest {
  playbookId: string;
  organizationId: string;
  from?: number;
  size?: number;
  orderBy?: ListHistoryOrderBy;
  direction?: RequestDirection;
}

export interface IGetIncidentHistoriesRequest {
  incidentId?: string;
  organizationId?: string;
  from?: number;
  size?: number;
  orderBy?: ListHistoryOrderBy;
  direction?: RequestDirection;
  actions?: Array<string>;
}

export interface IHistoryFilterRequest {
  from?: number;
  size?: number;
  orderBy?: ListHistoryOrderBy;
  direction?: RequestDirection;
}

export interface IUsecaseHistoryRequest {
  usecaseId: string;
  organizationId: string;
  from?: number;
  size?: number;
  orderBy?: ListHistoryOrderBy;
  direction?: RequestDirection;
}

export interface IAssetHistory {
  assetId: string;
  organizationId: string;
  from?: number;
  size?: number;
  orderBy?: ListHistoryOrderBy;
  direction?: RequestDirection;
}
export interface IAlertHistories {
  id?: string;
  identityId?: string;
  identityName: string;
  organizationId: string;
  message: ITranslatedField;
  type: HistoryType;
  createdAt?: number;
  action: AlertHistoryAction;
  comment: any;
  payload?: string;
}

export interface IHistory {
  id?: string;
  identityId?: string;
  identityName: string;
  organizationId: string;
  message: ITranslatedField;
  type: HistoryType;
  isVar: boolean;
  isSystem: boolean;
  write: boolean;
  createdAt?: number;
  payload?: any;
}

export interface IListEscalationHistoryRequest {
  organizationId?: string;
  from?: number;
  size?: number;
  orderBy?: ListHistoryOrderBy;
  direction?: RequestDirection;
  types?: string[];
}

export interface IListIncidentTaskHistoryRequest {
  incidentId: string;
  organizationId?: string;
  incidentTaskId: string;
  from?: number;
  size?: number;
  orderBy?: ListHistoryOrderBy;
  direction?: RequestDirection;
}

export interface IListConnectorHistoryRequest {
  connectorInstanceId: string;
  organizationId?: string;
  from?: number;
  size?: number;
}

export interface IAlertHistory extends IHistory { }

export interface IIncidentTaskHistory extends IHistory { }

export interface IIncidentHistory extends IHistory { }

export interface IWhitelistHistory extends IHistory { }

export interface IFilterHistory extends IHistory { }

export interface IUsecaseHistory extends IHistory { }

export interface IPlayookHistory extends IHistory { }

export interface IEscalationHistory extends IHistory { }

export interface IConnectorHistory extends IHistory { }

export enum AlertHistoryAction {
  CREATED = 'CREATED',
  ASSIGNED = 'ASSIGNED',
  IGNORED = 'IGNORED',
  SILENCED = 'SILENCED',
  VIEWED = 'VIEWED',
  UPDATED = 'UPDATED'
}

export enum HistoryType {
  ALERT = 'ALERT',
  ALERT_ACTION_LOG = 'ALERT_ACTION_LOG',
  USECASE = 'USECASE',
  INCIDENT = 'INCIDENT',
  INCIDENT_TASK = 'INCIDENT_TASK',
  WHITELIST = 'WHITELIST',
  FILTER = 'FILTER',
  DATA_CONNECTOR = 'DATA_CONNECTOR'
}

export enum ListHistoryOrderBy {
  createdAt = 'createdAt'
}


export interface IHistoriesApi {

  listHistories(request: GetHistoriesRequest): Observable<ResponseUtils<IHistory>>

  listAlertHistories(request: GetAlertHistoriesRequest): Observable<ResponseUtils<IAlertHistory>>

  listIncidentHistories(request: GetIncidentHistoriesRequest): Observable<ResponseUtils<IIncidentHistory>>

  listWhitelistHistory(request: IListHistoryRequest): Observable<ResponseUtils<IWhitelistHistory>>

  listFilterHistory(request: IListHistoryRequest): Observable<ResponseUtils<IFilterHistory>>

  listUsecaseHistory(request: IUsecaseHistoryRequest): Observable<ResponseUtils<IUsecaseHistory>>

  listAssetHistory(request: any): Observable<ResponseUtils<any>>

  listPlaybookHistory(request: IGetPlaybookHistoriesRequest): Observable<ResponseUtils<any>>

  listEscalationHistory(request: IListEscalationHistoryRequest): Observable<ResponseUtils<IEscalationHistory>>

  listIncidentTaskHistory(request: IListIncidentTaskHistoryRequest): Observable<ResponseUtils<IIncidentTaskHistory>>

  listConnectorHistory(request: IListConnectorHistoryRequest): Observable<ResponseUtils<IConnectorHistory>>
}

