import { Component, Input, OnInit } from '@angular/core';
import { IamApi } from '@common/services/api/iam/iam.api.definitions';

@Component({
  selector: 'role-details',
  templateUrl: './role-details.component.html',
  styleUrls: [ './role-details.component.scss' ],
})
export class RoleDetailsComponent implements OnInit {
  @Input() profile: IamApi.Profile.listResponse;

  public static readonly COMPONENT = 'roleDetails';

  constructor() {
  }

  ngOnInit(): void {
  }
}
