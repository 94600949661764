import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { UiKitModule } from '@ui-kit/uikit.module';
import { ComponentsModule } from 'projects/@common/components/components.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { SharedModule } from 'projects/console-adm/src/app/shared/shared.module';
import { WhitelistsModule } from '../whitelists/whitelists.module';
import { UsecaseActionsComponent } from './components/usecase-actions/usecase-actions.component';
import { UsecaseAlertDateComponent } from './components/usecase-alert-date/usecase-alert-date.component';
import { UsecaseCatalogReportButtonComponent } from './components/usecase-catalog-report-button/usecase-catalog-report-button.component';
import { UsecaseCatalogReportChooseLanguageModalComponent } from './components/usecase-catalog-report-choose-language-modal/usecase-catalog-report-choose-language-modal.component';
import { UsecaseCatalogComponent } from './components/usecase-catalog/usecase-catalog.component';
import { UsecaseCreationDetail } from './components/usecase-creation-detail/usecase-creation-detail.component';
import { UsecaseDetailsComponent } from './components/usecase-details/usecase-details.component';
import { UsecaseFiltersListComponent } from './components/usecase-filters-list/usecase-filters-list.component';
import { UsecaseHistoryComponent } from './components/usecase-history/usecase-history.component';
import { UsecaseIndicatorsComponent } from './components/usecase-indicators/usecase-indicators.component';
import { UsecaseInventoryComponent } from './components/usecase-inventory/usecase-inventory.component';
import { UsecaseMonitorsComponent } from './components/usecase-monitors/usecase-monitors.component';
import { UsecaseSummaryComponent } from './components/usecase-summary/usecase-summary.component';
import { UsecaseTemplateDetailsComponent } from './components/usecase-template-details/usecase-details.component';
import { UsecaseWhitelistComponent } from './components/usecase-whitelist/usecase-whitelist.component';
import { UsecaseCatalogDrawerContainer } from './containers/drawers/usecase-catalog-drawer/usecase-catalog-drawer.container';
import { UsecaseCreateDrawer } from './containers/drawers/usecase-create-drawer/usecase-create-drawer.component';
import { UsecaseDescriptionDrawer } from './containers/drawers/usecase-description-drawer/usecase-description-drawer.component';
import { UsecaseListContainer } from './containers/usecase-list/usecase-list.container';
import { HistoriesModule } from '../histories/histories.module';
import { UsecaseSilencedByComponent } from './components/usecase-silenced-by-table-column/usecase-silenced-by-table-column.component';

const components = [
  UsecaseSummaryComponent,
  UsecaseDetailsComponent,
  UsecaseMonitorsComponent,
  UsecaseAlertDateComponent,
  UsecaseActionsComponent,
  UsecaseIndicatorsComponent,
  UsecaseFiltersListComponent,
  UsecaseHistoryComponent,
  UsecaseWhitelistComponent,
  UsecaseListContainer,
  UsecaseCatalogComponent,
  UsecaseCatalogReportButtonComponent,
  UsecaseCatalogReportChooseLanguageModalComponent,
  UsecaseTemplateDetailsComponent,
  UsecaseInventoryComponent,
  UsecaseSilencedByComponent
];

@NgModule({
  declarations: [
    ...components,
    UsecaseCreateDrawer,
    UsecaseCreationDetail,
    UsecaseDescriptionDrawer,
    UsecaseCatalogDrawerContainer,
  ],
  imports: [
    CommonModule,
    UiKitModule,
    ReactiveFormsModule,
    FormsModule,
    NgxsFormPluginModule,
    I18nModule,
    FeatureSharedModule,
    SharedModule,
    ComponentsModule,
    WhitelistsModule,
    HistoriesModule,
  ],
  exports: [
    ...components,
  ],
})
export class UsecasesModule { }
