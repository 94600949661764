import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';
import { IWhitelistPeriod } from 'projects/@common/services/api/respond/whitelists/whitelists.definitions';

export interface IWeeklySchedulePeriod {
  dayOfWeek: { value: number, displayValue: string };
  start: {
    hour: number;
    minute: number;
  };
  end: {
    hour: number;
    minute: number;
  };
}

interface ISelectOption {
  value: number;
  displayValue: string;
}

@Component({
  selector: 'whitelist-weekly-schedule',
  templateUrl: './whitelist-weekly-schedule.component.html',
  styleUrls: [ './whitelist-weekly-schedule.component.scss' ],
})
export class WhitelistWeeklyScheduleComponent implements OnInit {
  @Input() public whitelist: Whitelist;
  @Input() public isDisabled: boolean = false;

  @Output() public weeklyScheduleChange: EventEmitter<IWhitelistPeriod[]> = new EventEmitter<IWhitelistPeriod[]>();

  public weeklySchedule: IWeeklySchedulePeriod[] = [];

  public selectWeekdays: ISelectOption[];

  constructor(private readonly i18n: I18nService) { }

  ngOnInit(): void {
    this.initSelectWeekdays();
    this.initWeeklySchedule();
  }

  public get newWeeklySchedule(): IWeeklySchedulePeriod {
    return {
      dayOfWeek: null,
      start: {
        hour: 0,
        minute: 0,
      },
      end: {
        hour: 0,
        minute: 0,
      },
    };
  }

  public get isValidWeeklySchedule(): boolean {
    return this.weeklySchedule.every((period) => this.isValidPeriod(period));
  }

  public isValidPeriod(period: IWeeklySchedulePeriod): boolean {
    if (!period.dayOfWeek) return false;
    const getSafeNumber = (value: number) => +(value || 0).toFixed(2);
    const getNumericalValue = (value: { hour: number, minute: number }) => getSafeNumber(value.hour) + getSafeNumber(value.minute / 60);
    const numericalStart = getNumericalValue(period.start);
    const numericalEnd = getNumericalValue(period.end);
    const validRange = numericalStart < numericalEnd;
    return validRange;
  }

  public isPeriodTimeInputsEnabled(period: IWeeklySchedulePeriod): boolean {
    return (period.dayOfWeek as any) >= 0 && !this.isDisabled;
  }

  public addWeeklyScheduleInput(): void {
    this.weeklySchedule.push(this.newWeeklySchedule);
  }

  public deleteWeeklyScheduleInput(period: IWeeklySchedulePeriod): void {
    this.weeklySchedule = this.weeklySchedule.filter((weeklySchedule) => weeklySchedule !== period);
    this.emitWeeklyScheduleChange();
  }

  public handleDayOfWeekChanged(period: IWeeklySchedulePeriod, dayOfWeek: ISelectOption): void {
    period.dayOfWeek = dayOfWeek;
    this.emitWeeklyScheduleChange();
  }

  public handleTimeChangeEvent(period: IWeeklySchedulePeriod, type: "start" | "end", time: { hour: number, minute: number }): void {
    period[type] = time;
    this.emitWeeklyScheduleChange();
  }

  public emitWeeklyScheduleChange(): void {
    this.weeklyScheduleChange.emit(this.weeklySchedule.map((period) => ({
      dayOfWeek: period.dayOfWeek.value,
      fromHour: period.start.hour,
      fromMinute: period.start.minute,
      toHour: period.end.hour,
      toMinute: period.end.minute,
    })));
  }

  public getPeriodByDay(day: number): IWeeklySchedulePeriod {
    let periodMap: IWeeklySchedulePeriod;
    periodMap.dayOfWeek = this.getDayOfWeekSelectOption(day);

    const period: IWhitelistPeriod = this.whitelist.getPeriodByDay(day);
    periodMap.start = {
      hour: period ? period.fromHour : 0,
      minute: period ? period.fromMinute : 0,
    };
    periodMap.end = {
      hour: period ? period.toHour : 0,
      minute: period ? period.toMinute : 0,
    };

    return periodMap;
  }

  public getDayOfWeekSelectOption(day: number): ISelectOption {
    return {
      value: day,
      displayValue: this.i18n.translate(`common.weekdays.${day}`),
    };
  }

  private initSelectWeekdays(): void {
    this.selectWeekdays = [ 0, 1, 2, 3, 4, 5, 6 ].map((day) => this.getDayOfWeekSelectOption(day));
  }

  private initWeeklySchedule(): void {
    const activePeriods = this.whitelist?.periods?.filter((period) => period.fromHour !== 0 || period.fromMinute !== 0 || period.toHour !== 0 || period.toMinute !== 0);
    if (!activePeriods?.length) {
      this.weeklySchedule = [
        this.newWeeklySchedule,
      ];
      return;
    }
    this.weeklySchedule = activePeriods.map((period) => ({
      dayOfWeek: this.getDayOfWeekSelectOption(period.dayOfWeek),
      start: {
        hour: period.fromHour,
        minute: period.fromMinute,
      },
      end: {
        hour: period.toHour,
        minute: period.toMinute,
      },
    }));
  }
}
