import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UiDialog } from '@ui-kit/components/ui-dialog/ui-dialog.component';

@Component({
  selector: 'account-archiver-dialog',
  templateUrl: './account-archiver-dialog.component.html',
  styleUrls: [ './account-archiver-dialog.component.scss' ],
})
export class AccountArchiverDialogComponent implements OnInit {
  @Output() saveClicked = new EventEmitter<void>();

  constructor(private dialogRef: MatDialogRef<UiDialog>) { }

  ngOnInit(): void {

  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.saveClicked.emit();
    this.dialogRef.close();
  }
}
