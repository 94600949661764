import { CdkDragEnter, CdkDragStart } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { MultiSelectDataFactory } from "projects/@common/modules/i18n/component-wrapper/multi-select-data.factory";
import { TranslatedObjectPipe } from "projects/@common/modules/i18n/translatedObject.pipe";
import { IPlaybookDetail, IPlaybookTaskTemplateRepresentation, PlaybookTaskPhases, PlaybookTaskRelation } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";
import { ParametersApiService } from "projects/@common/services/api/tools/parameters/parameters.api";
import { IParameterStore } from "projects/@common/services/api/tools/parameters/parameters.definitions";
import { HandleBarUtils } from "projects/@common/utils/handlebars-utils";
import { Subject, Subscription, combineLatest } from "rxjs";

import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { FormsFactory } from "../../forms/formsFactory";
import { SpecificTaskForm } from "../../forms/specific-task.form";
import { SpecificTaskComponent } from "../specific-task/specific-task.component";
import { UiDragAndDropZone, DragDropItem, DragDropCompletedEvent } from "@ui-kit/components/ui-drag-and-drop-zone/ui-drag-and-drop-zone.component";
import { MultiSelectData } from "@ui-kit/components/ui-multi-select/multi-select-data";
import { TableFilterData } from "@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data";
import { UiTableDirection } from "@ui-kit/components/ui-table/ui-table.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

export interface IPlaybookTaskTemplatesParams {
  custom: boolean;
  name: string;
  phases: string[];
  hideAddedTask: boolean;
}

export enum TaskOriginEnum {
  Library = 'LIBRARY',
  Custom = 'CUSTOM'
}

interface OriginDefinedTask<T> {
  origin: TaskOriginEnum;
  task: T;
}

const mdTaskLibraryName = 'MDTasks';

export type PlayBookTaskTemplate = PlaybookTaskRelation | IPlaybookTaskTemplateRepresentation;

@Component({
  selector: 'playbook-tasks-drag-and-drop',
  templateUrl: './playbook-tasks-drag-and-drop.component.html',
  styleUrls: [ './playbook-tasks-drag-and-drop.component.scss' ],
})
export class PlaybookTasksDragAndDropComponent extends UiDragAndDropZone<OriginDefinedTask<PlayBookTaskTemplate>> implements OnInit, OnDestroy, OnChanges {
  @Input()
    mdTasks: IPlaybookTaskTemplateRepresentation[] = [];

  @Input()
    showMdTasksLibrary: boolean = true;

  @Input()
    showTaskInstructions: boolean = true;

  @Input()
    isReadonly: boolean = false;

  @Input()
    organizationId: string;

  @Input()
    isSystemAPICall: boolean = false;

  @Output()
    onTypeFilterChange: EventEmitter<IPlaybookTaskTemplatesParams> = new EventEmitter();

  @Output()
    handleLoadMoreEmitter: EventEmitter<void> = new EventEmitter();

  public taskOriginEnum = TaskOriginEnum;

  public paramsList: IParameterStore[] = [];

  public isParamsLoaded = false;

  public specificTaskForm: SpecificTaskForm;

  public phaseFiltersEnum = PlaybookTaskPhases;

  public formDropRegions: PlaybookTaskPhases[] = Object.values(this.phaseFiltersEnum);

  public phaseFiltersSelect: MultiSelectData;

  public taskTypeFilterSelect: TableFilterData;

  public displayFilter: TaskOriginEnum = TaskOriginEnum.Library;

  public dragDropInitialized = false;

  private _playbookDetail: IPlaybookDetail = null;

  public pickedItem: DragDropItem<PlayBookTaskTemplate>;

  public regionCardCollapsed: { [key: string]: boolean } = {};

  public splitPaneOpened: boolean = true;

  public name: string = null;
  public phases: string[];
  public type = TaskOriginEnum.Library;
  public hideAddedTask = false;

  public tasksChanged$ = new Subject();

  public filteringObservablesSubscription$: Subscription;

  public filterDisabled = false;

  constructor(
    private dialog: MatDialog,
    private readonly translatedObjectPipe: TranslatedObjectPipe,
    private readonly formsFactory: FormsFactory,
    private readonly parametersApiService: ParametersApiService,
    private readonly multiSelectFactory: MultiSelectDataFactory,
    private tableFilterToolDataFactory: TableFilterToolDataFactory
  ) {
    super();
    this.specificTaskForm = this.formsFactory.getSpecificTaskForm();
  }

  @Input()
  public get playbook(): IPlaybookDetail {
    return this._playbookDetail;
  }

  public set playbook(playbook: IPlaybookDetail) {
    this._playbookDetail = playbook;
    this.initializeItemContext();
  }

  public get libraryLabel(): string {
    return this.splitPaneOpened ? 'detection.playbook.detail.task.library' : 'detection.playbook.detail.task.library.closed';
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.initializeDragDrop();
    this.initializeItemContext();
  }

  async ngOnInit(): Promise<void> {
    this.initializeDragDrop();
    this.initializeItemContext();
    this.phaseFiltersSelect = this.multiSelectFactory.create(this.phaseFiltersEnum, [], 'detection.playbook.detail.task.library.phase.');
    this.tasksChanged$.subscribe(() => this.entityUpdate());
    this.getOrganizationTaskParams();
    this.taskTypeFilterSelect = this.tableFilterToolDataFactory.create(this.taskOriginEnum, 'detection.playbook.detail.task.library.type.', this.displayFilter, true);
  }

  ngOnDestroy(): void {
    this.filteringObservablesSubscription$?.unsubscribe();
  }

  public onTypeChange(event) {
    this.type = event;
    this.onTypeFilterChange.emit(this.getCurrentFilters());
  }

  public onNameChange(value: string): void {
    this.name = value;
    this.onTypeFilterChange.emit(this.getCurrentFilters());
  }

  public onPhaseChange(values: string[]): void {
    this.phases = values;
    this.onTypeFilterChange.emit(this.getCurrentFilters());
  }

  public onHideAddedTask(checked: boolean): void {
    this.hideAddedTask = checked;
    this.onTypeFilterChange.emit(this.getCurrentFilters());
  }

  public handleLoadMore(): void {
    this.handleLoadMoreEmitter.emit();
  }

  private updateParametersLastAccessedAt(): void {
    const playbookTasksText = this.playbook?.playbookTasks.map((task) => `${task.name?.en} ${task.description?.en} ${task.instruction?.en}`).join(',');
    const usedParams = [ ...new Set(HandleBarUtils.mustacheFinder(playbookTasksText)) ];
    const paramsToUpdateLastAccessedAtValue = this.paramsList
      .filter((param) => usedParams.some((p) => p.endsWith(param.name)))
      .map((param) => ({ ecoId: param.ecoId, name: param.name }));
    if (paramsToUpdateLastAccessedAtValue.length > 0) {
      this.parametersApiService.describeParameters(paramsToUpdateLastAccessedAtValue, this.isSystemAPICall);
    }
  }

  public librarySetPickedItem(e: CdkDragStart<DragDropItem<PlayBookTaskTemplate>>): void {
    this.pickedItem = e.source.data;
  }

  public libraryClearPickedItem(): void {
    this.pickedItem = null;
  }

  public toggleRegionCollapse(region: string): void {
    this.regionCardCollapsed[region] = !this.regionCardCollapsed[region];
  }

  public toggleSplitPane(): void {
    this.splitPaneOpened = !this.splitPaneOpened;
  }

  public toggleOpenOnRegionEntered(event: CdkDragEnter<PlayBookTaskTemplate>): void {
    const region = event.container.id;

    if (this.regionCardCollapsed[region]) {
      this.regionCardCollapsed[region] = false;
    }
  }

  public deleteEventHandler($taskItemDeletedEvent: any): void {
    const removedTaskIndex = this.dragDropRegions[$taskItemDeletedEvent.region].items.findIndex((object) => object.itemContext.task === $taskItemDeletedEvent.item);
    if (removedTaskIndex !== -1) {
      this.dragDropRegions[$taskItemDeletedEvent.region].items.splice(removedTaskIndex, 1);
    }
    this.tasksChanged$.next();
  }

  public ignoreEventHandler($taskItemIgnoredEvent: any): void {
    const ignoredTaskIndex = this.dragDropRegions[$taskItemIgnoredEvent.region].items.findIndex((object) => object.itemContext.task === $taskItemIgnoredEvent.item);
    const task = this.dragDropRegions[$taskItemIgnoredEvent.region].items[ignoredTaskIndex].itemContext
      .task as PlaybookTaskRelation;
    task.ignored = !task.ignored;
    this.tasksChanged$.next();
  }

  public hideEventHandler($taskItemHiddenEvent: any): void {
    const hiddenTaskIndex = this.dragDropRegions[$taskItemHiddenEvent.region].items.findIndex((object) => object.itemContext.task === $taskItemHiddenEvent.item);
    const task = this.dragDropRegions[$taskItemHiddenEvent.region].items[hiddenTaskIndex].itemContext
      .task as PlaybookTaskRelation;
    task.hidden = !task.hidden;
    this.tasksChanged$.next();
  }

  public openCustomTaskModal(region: PlaybookTaskPhases, value: any, index?: number): void {

    const self = this;

    this.dialog.open(SpecificTaskComponent, {
      width: 'max-content',
      height: 'max-content',
      maxWidth: '90vw',
      maxHeight: '90vh',
      hasBackdrop: true,
      backdropClass: 'adm-modal-backdrop-class',
      panelClass: 'modal-service-dialog-modal-scroll',
      disableClose: false,
      data: {
        specificTaskForm: this.specificTaskForm,
        organizationId: this.organizationId,
        paramsList: this.paramsList.map((param) => ({
          value: param.value,
          displayValue: param.name,
        })),
        value,
        callback: (task: PlaybookTaskRelation) => {
          self.createCustomTask(region, task, index);
        },
      },
    });

  }

  private initializeDragDrop(): void {
    this.formDropRegions.forEach((playbookTaskSection) => {
      this.dragDropRegions[playbookTaskSection] = {
        key: playbookTaskSection,
        dropCopiesItems: false,
        preventDoublesKey: "task.id",
        items: [],
      };
      this.regionCardCollapsed[playbookTaskSection] = true;
    });

    const mdTaskDropItems = this.mapMdTaskDropItems(this.mdTasks);
    this.dragDropRegions[mdTaskLibraryName] = {
      key: mdTaskLibraryName,
      dropCopiesItems: true,
      items: mdTaskDropItems,
    };
    this.dropCompleted$.subscribe((event) => this.onDropCompleted(event));
    this.dropCancelled$.subscribe(() => this.onDropCancelled());
    this.dragDropInitialized = true;
  }

  private initializeItemContext(): void {
    if (this.dragDropInitialized) {
      if (this.playbook) {
        this.formDropRegions.forEach((region) => {
          this.dragDropRegions[region].items.splice(0);
        });
        this.playbook.playbookTasks.forEach((task: PlaybookTaskRelation) => {
          this.dragDropRegions[task.phase].items.push({
            itemContext: {
              origin: task.templateId ? TaskOriginEnum.Library : TaskOriginEnum.Custom,
              task,
            },
            lockedPosition: task.managed,
          });
          this.dragDropRegions[task.phase].items.sort((a, b) => (a.itemContext.task as PlaybookTaskRelation).order - (b.itemContext.task as PlaybookTaskRelation).order);
        });
      }
    }
  }

  private mapMdTaskDropItems(items: any[]): DragDropItem<OriginDefinedTask<PlayBookTaskTemplate>>[] {
    return items.map<DragDropItem<OriginDefinedTask<PlayBookTaskTemplate>>>((libraryTask) => ({
      itemContext: {
        origin: TaskOriginEnum.Library,
        task: libraryTask,
      },
      lockedPosition: false,
    }));
  }

  private createCustomTask(region: PlaybookTaskPhases, task: PlaybookTaskRelation, index?: number): void {
    const customTask: PlaybookTaskRelation = {
      id: task.id,
      name: task.name,
      description: task.description,
      instruction: task.instruction,
      phase: region,
      version: 0,
      order: 0,
      requiredParameters: [],
      managed: false,
      ignored: task.ignored,
      hidden: task.hidden,
      customParameters: [],
    };

    const dragDropItem: DragDropItem<OriginDefinedTask<PlayBookTaskTemplate>> = {
      itemContext: {
        origin: TaskOriginEnum.Custom,
        task: customTask,
      },
      lockedPosition: false,
    };

    if (index !== undefined) {
      this.dragDropRegions[region].items[index].itemContext.task = customTask;
    } else {
      this.dragDropRegions[region].items.push(dragDropItem);
    }
    this.tasksChanged$.next();
  }

  private convertTaskTemplate(taskTemplate: IPlaybookTaskTemplateRepresentation): PlaybookTaskRelation {
    const createdAt = Math.floor(Date.now() / 1000);
    return {
      createdAt,
      id: taskTemplate.id,
      phase: null,
      order: -1,
      managed: false,
      ignored: false,
      hidden: false,
      updatedAt: taskTemplate.updatedAt,
      templateId: taskTemplate.templateId,
      version: taskTemplate.version,
      name: taskTemplate.name,
      description: taskTemplate.description,
      instruction: taskTemplate.instruction,
      requiredParameters: taskTemplate.requiredParameters,
      customParameters: [],
    };
  }

  private onDropCompleted(event: DragDropCompletedEvent): void {
    if (event.fromRegion === mdTaskLibraryName) {
      const dropRegion = this.dragDropRegions[event.toRegion];
      const movedItem = dropRegion.items[event.toIndex];
      const taskTemplate: OriginDefinedTask<IPlaybookTaskTemplateRepresentation> =
        movedItem.itemContext as OriginDefinedTask<IPlaybookTaskTemplateRepresentation>;
      const taskInstance = this.convertTaskTemplate(taskTemplate.task);

      this.dragDropRegions[event.toRegion].items[event.toIndex].itemContext = {
        origin: taskTemplate.origin,
        task: taskInstance,
      };
    }

    this.pickedItem = null;
    this.tasksChanged$.next();
  }

  private onDropCancelled(): void {
    this.pickedItem = null;
  }

  private entityUpdate(): void {
    this.playbook.playbookTasks = this.formDropRegions
      .map((phase) => this.dragDropRegions[phase].items
        .map((task, order) => ({
          ...task.itemContext.task as PlaybookTaskRelation,
          phase,
          order,
        }))).flat();
  }

  private getOrganizationTaskParams(): void {
    const request = {
      ecoId: this.organizationId,
      from: 0,
      size: 1000,
      order: UiTableDirection.Asc,
    };
    this.parametersApiService.listParameters(request, this.isSystemAPICall)
      .then((value) => {
        this.paramsList = value.items;
        this.updateParametersLastAccessedAt();
      })
      .finally(() => {
        this.isParamsLoaded = true;
      });
  }

  private getCurrentFilters(): IPlaybookTaskTemplatesParams {
    return {
      name: this.name,
      custom: this.type === TaskOriginEnum.Custom,
      phases: this.phases,
      hideAddedTask: this.hideAddedTask};
  }
}
