import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DetectionConnectorGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const componentDataState = this.router.getCurrentNavigation().extras.state;

    if (componentDataState) {
      if (componentDataState.connector != null && componentDataState.organization != null) {
        return true;
      }
    }
    this.router.navigate([ 'detection/connectors' ]);
    return false;
  }
}
