import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { AutocompleteCustomValue } from '@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component';
import { RuleSet } from 'angular2-query-builder';
import { WeeklyTimeSchedule } from 'projects/@common/components/weekly-time-schedule/weekly-time-schedule.component';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { FiltersApi } from 'projects/@common/services/api/respond/filters/filters.api';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { AlertingUsecaseListItem } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { clone } from 'projects/@common/utils/utils';
import { FilterPeriod, FilterPeriodsService } from '../../filter-periods.service';
import { FiltersService } from '../../filters.service';
import { FilterConditionsComponent } from '../filter-conditions/filter-conditions.component';
import { ConsoleType } from '@common/modules/layout/private.layout';
import { FilterType } from '@common/services/api/respond/filters/filters.definitions';

type UsecaseMonitors = {
  [key: string]: {
    monitors: { id: string; name: string }[]
  }
};

@Component({
  selector: 'filter-details',
  templateUrl: './filter-details.component.html',
  styleUrls: [ './filter-details.component.scss' ],
})
export class FilterDetailsComponent implements OnInit {
  @Input() public filter: Filter;
  @Input() public isReadonly: boolean;
  @Input() public organizationId: string;

  @Output() public saveEvent: EventEmitter<void> = new EventEmitter();

  @ViewChild(FilterConditionsComponent) public filterConditions: FilterConditionsComponent;

  public currentFilter: Filter;

  public isLoadingConditionFields: boolean;
  public isMonitorLoaded = false;
  public form: UntypedFormGroup;
  public isEditing: boolean = false;
  public showDateRange: boolean = false;
  public daysOfWeek = [ 0, 1, 2, 3, 4, 5, 6 ];
  public conditionFields: any;
  public displayConditionsAndPeriodWarning: boolean = false;

  public isShowQuery = false;
  public query = null;

  public alertingUsecasesList: AlertingUsecaseListItem[];
  public usecaseAutocompleteOptions: AutocompleteCustomValue[];
  public autocompleteSelectedUsecases: AutocompleteCustomValue[] = [];
  public customAutocompleteType = AutocompleteTypes.CUSTOM;
  public selectedUsecasesToDisplay: AutocompleteCustomValue[] = [];

  public usecaseMonitors: UsecaseMonitors = {};
  public filtersSelectedTimePeriod: WeeklyTimeSchedule;
  public isConsoleAdmin: boolean = true;

  constructor(
    public readonly i18n: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly usecasesApi: UsecasesApiService,
    private readonly drawerService: DrawerService,
    private readonly translatedObjectPipe: TranslatedObjectPipe,
    private readonly filtersApi: FiltersApi,
    private readonly filtersService: FiltersService,
    private readonly adminUsecasesApiService: UsecasesApiService,
    private readonly filterPeriodsService: FilterPeriodsService,
    @Inject('CONSOLE_TYPE') private readonly consoleType: ConsoleType
  ) { }

  ngOnInit(): void {
    this.isConsoleAdmin = this.consoleType === ConsoleType.ADM;
    this.initCurrentFilter();
    this.fetchFilterConditionFields();
    this.fetchInitialUsecaseMonitors();
    this.setDisplayConditionAndPeriodWarning();
    if (this.isCreateMode) {
      this.toggleEditing(true);
    }
  }

  public getActiveTemplate() {
    if (this.isShowQuery) return "queryPreview";
    if (this.isCreateMode || this.isEditing) return "filterEditing";
    return "filterReadonly";
  }

  public get filterId(): string {
    return this.currentFilter?.id || this.filter?.id;
  }

  public get isCreateMode(): boolean {
    return !this.filterId;
  }

  public get validUsecaseIds(): boolean {
    return this.currentFilter?.usecases?.length > 0 && this.currentFilter.usecases.every((usecase) => Boolean(this.usecaseAutocompleteOptions?.find((option) => option.value === usecase.id)));
  }

  public get validConditions(): boolean {
    if (this.currentFilter.conditions?.rules?.length === 0) {
      return true;
    }
    return this.currentFilter.conditions && this.isValidRuleset(this.currentFilter.conditions);
  }

  public get areConditionsEmpty(): boolean {
    return this.currentFilter.conditions?.rules?.length === 0;
  }

  public isMonitorAvailable(usecaseId: string): boolean {
    return this.usecaseMonitors[usecaseId]?.monitors?.length > 0;
  }

  public previsualize() {
    this.filtersApi.previsualizeQuery({
      ruleset: this.currentFilter.conditions,
      periods: this.currentFilter.periods || [],
      organizationId: this.currentFilter.organizationId,
    }).then((query) => {
      this.isShowQuery = true;
      this.query = JSON.stringify({
        query: {
          bool: {
            must: "##MONITOR QUERY##",
            filter: [ query ],
          },
        },
      }, undefined, 2).replace(/\\n/g, '\n');
    });
  }

  onCheckClick($event, monitor) {
    if ($event.checked) {
      this.currentFilter.monitors.push(monitor.id);
    } else {
      this.currentFilter.monitors = this.currentFilter.monitors.filter((item) => item !== monitor.id);
    }
  }

  onCheckClickHiddenFilter(event) {
    this.currentFilter.hidden = event.checked;
  }

  private isValidRuleset(condition: RuleSet): boolean {
    if (!condition.rules?.length) {
      return false;
    }
    if (condition.condition === 'not' && condition.rules?.length > 1) {
      return false;
    }
    for (const rule of condition.rules) {
      if (rule['operator'] && [ null, undefined, "" ].includes(rule['value']) && rule['operator'] !== 'exists') {
        return false;
      }
      if (rule['operator'] && rule['invalid'] === true) {
        return false;
      }
      if (rule['condition']) {
        if (!this.isValidRuleset(rule as RuleSet)) {
          return false;
        }
      }
    }
    return true;
  }

  public get isValidData(): boolean {
    for (const usecase of this.currentFilter.usecaseIds) {
      if (this.isUsecaseError(usecase)) {
        return false;
      }
    }

    return !!this.form?.valid && this.validUsecaseIds && this.validConditions;
  }

  public toggleEditing(value: boolean): void {
    this.isEditing = value;
    if (this.isEditing) {
      this.initCurrentFilter();
      this.initUsecaseAutocomplete();
      this.createForm();
    }
  }

  public handleSave(): void {
    if (this.isValidData) {
      this.toggleEditing(false);
      this.currentFilter.name = this.form.get('name').value;
      this.currentFilter.description = this.form.get('description').value;
      this.handleFiltersTimePeriodWhenSavingForm();
      if (this.isCreateMode) {
        this.filtersService.createFilter(this.currentFilter);
      } else {
        this.currentFilter.id = this.filterId;
        this.filtersService.updateFilter(this.currentFilter);
      }
      this.saveEvent.emit();
    }
  }

  public cancel(): void {
    if (this.isCreateMode) {
      this.drawerService.hideDrawer();
    } else {
      this.toggleEditing(false);
      this.initCurrentFilter();
    }
  }

  public handleUsecaseSelectEvent(event: AutocompleteCustomValue): void {
    if (event?.value) {
      const usecase: AlertingUsecaseListItem = this.alertingUsecasesList.find((usecase) => usecase.id === event.value);
      if (usecase && !this.currentFilter.usecaseIds.includes(usecase.id)) {
        this.currentFilter.usecases.push({
          id: usecase.id,
          name: usecase.name,
          usecaseCatalogId: usecase.catalogId,
          datasourceType: usecase.datasourceType,
        });
        this.setDisplayedUsecases();
        this.fetchUsecaseMonitors(usecase.id).then(() => {
          this.usecaseMonitors[usecase.id].monitors.forEach((monitor) => {
            this.currentFilter.monitors.push(monitor.id);
          });
        });
      }
    }
  }

  public handleUnselectUsecase(usecaseId: string): void {
    this.currentFilter.usecases = this.currentFilter.usecases.filter((usecase) => usecase.id !== usecaseId);
    this.autocompleteSelectedUsecases = this.autocompleteSelectedUsecases.filter((usecase) => usecase.value !== usecaseId);
    this.setDisplayedUsecases();

    this.currentFilter.monitors = this.currentFilter.monitors.filter((monitorId) => !this.usecaseMonitors[usecaseId]?.monitors?.some((monitor) => monitor.id === monitorId));
  }

  public isUsecaseError(usecaseId: any): boolean {
    const monitors = this.usecaseMonitors[usecaseId]?.monitors.map((monitor) => monitor.id) || [];

    if (!monitors.length) {
      return false;
    }

    return !monitors.some((id) => {
      if (this.currentFilter.monitors) {
        return this.currentFilter?.monitors.includes(id);
      }
      return false;
    });
  }

  public timePeriodChanges(event: WeeklyTimeSchedule): void {
    this.filtersSelectedTimePeriod = event;
    this.handleFiltersTimePeriodWhenSavingForm();
    this.setDisplayConditionAndPeriodWarning();
  }

  public filterConditionChanges(event: RuleSet): void {
    this.setDisplayConditionAndPeriodWarning();
  }

  private isConditionsAndPeriodsSet(conditions: RuleSet, periods: FilterPeriod[]): boolean {
    return conditions?.rules?.length > 0 && periods?.length > 0;
  }

  private async fetchUsecaseMonitors(id: string) {
    const usecase = await this.adminUsecasesApiService.getUsecase(this.currentFilter.organizationId, id);

    this.usecaseMonitors[id] = {
      monitors: usecase.monitors.map((monitor) => ({
        id: monitor.id,
        name: monitor.name,
        description: monitor.description,
      })),
    };
  }

  private initCurrentFilter(): void {
    this.currentFilter = this.filter ? clone(this.filter) : new Filter(FilterType.ECS);
    this.currentFilter.organizationId = this.organizationId;
    if (this.currentFilter.periods) {
      this.filtersSelectedTimePeriod = this.filterPeriodsService.filterPeriodsToWeeklyTimeSchedule(this.currentFilter.periods);
    }
    this.setDisplayedUsecases();
  }

  private setDisplayedUsecases(): void {
    this.selectedUsecasesToDisplay = [];
    if (this?.currentFilter?.usecases?.length) {
      this.selectedUsecasesToDisplay = this.currentFilter.usecases
        .map((usecase) => ({
          value: usecase.id,
          displayValue: this.getUsecaseDisplayName(usecase.usecaseCatalogId, usecase.name, usecase.datasourceType),
        }))
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
    }
  }

  private safeString(value: string): string {
    return value && typeof value === 'string' ? value : '';
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      name: [ this.safeString(this.currentFilter?.name), Validators.required ],
      description: [ this.safeString(this.currentFilter?.description), Validators.required ],
    });
  }

  private getUsecaseDisplayName(usecaseCatalogId: string, name: ITranslatedField, datasourceType: string): string {
    return `${usecaseCatalogId} (${this.translatedObjectPipe.transform(name)}) [${datasourceType}]`;
  }

  private async initUsecaseAutocomplete(): Promise<void> {
    if (!this.alertingUsecasesList) {
      this.alertingUsecasesList = await this.fetchUsecases();
    }
    this.usecaseAutocompleteOptions = this.alertingUsecasesList
      .map((usecase: AlertingUsecaseListItem) => ({
        value: usecase.id,
        displayValue: this.getUsecaseDisplayName(usecase.catalogId, usecase.name, usecase.datasourceType),
      }))
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue));

    this.autocompleteSelectedUsecases = this.usecaseAutocompleteOptions.filter((option) => this.currentFilter?.usecases?.find((usecase) => usecase.id === option.value));
  }

  private async fetchUsecases(): Promise<AlertingUsecaseListItem[]> {
    try {
      const response = await this.usecasesApi.getUsecaseList(this.organizationId || null, { from: 0, size: 500 });
      const usecases = response.items;
      return usecases;
    } catch (error) {
      return [];
    }
  }

  private fetchFilterConditionFields(): void {
    this.isLoadingConditionFields = true;
    this.filtersApi.getFilterConditionFields({ organizationId: this.organizationId })
      .then((response) => {
        this.conditionFields = response;
        this.isLoadingConditionFields = false;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoadingConditionFields = false;
      });
  }

  private async fetchInitialUsecaseMonitors() {
    for (const usecase of this.currentFilter.usecases) {
      await this.fetchUsecaseMonitors(usecase.id);
    }

    this.isMonitorLoaded = true;
  }

  private handleFiltersTimePeriodWhenSavingForm(): void {
    const periods = this.filterPeriodsService.generatePeriods(this.filtersSelectedTimePeriod);
    if (periods.length > 0) {
      this.currentFilter.periods = periods;
    } else {
      this.currentFilter.periods = null;
    }
  }

  private setDisplayConditionAndPeriodWarning(): void {
    this.displayConditionsAndPeriodWarning = this.isConditionsAndPeriodsSet(this.currentFilter.conditions, this.currentFilter.periods);
  }
}
