<div class="ui-header-sort" [ngClass]="columnDef.textAlign">
  <span class="ui-header-sort--content">
    <ng-content select="static-table-header-content"></ng-content>
  </span>
  <div class="ui-header-sort--indicator" [ngSwitch]="direction">
    <ui-icon
      class="ui-header-sort--icon ui-header-sort--icon_up"
      name="arrow-asc"
      modifier="on-header"
      *ngSwitchCase="'asc'"
    ></ui-icon>
    <ui-icon
      class="ui-header-sort--icon ui-header-sort--icon_down"
      name="arrow-desc"
      modifier="on-header"
      *ngSwitchCase="'desc'"
    ></ui-icon>
  </div>
</div>
