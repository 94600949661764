import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StaticTableDataMapper } from '@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { ResponseUtils } from '@common/utils/response-utils';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { TimeUtil } from '@ui-kit/services/time-util';
import { GuestUser, GuestUserStatusEnum } from '../../../services/guest-users.definition';
import { GuestUsersDeleteModalComponent } from './guest-users-delete-modal/guest-users-delete-modal.component';
import { NoticeService } from '@common/modules/notice/notice.service';

@Component({
  selector: 'app-guest-users-management',
  templateUrl: './guest-users-management.component.html',
  styleUrls: ['./guest-users-management.component.scss'],
})
export class GuestUsersManagementComponent implements OnInit {
  public hasUserToDelete = false;

  public fulltext: string = null;

  public isCsvLoading: boolean = false;

  public isUserGuestAdmin: boolean = false;

  public csvColumnLabels = [
    this.i18n.translate('guest.users.table.column.email'),
    this.i18n.translate('guest.users.table.column.name'),
    this.i18n.translate('guest.users.table.column.requester'),
    this.i18n.translate('guest.users.table.column.invitedOn'),
    this.i18n.translate('guest.users.table.column.orphanSince'),
  ];

  public columnsDef = [
    {
      label: this.i18n.translate('guest.users.management.table.column.name'),
      field: 'fullName',
      type: UiStaticTableRowType.TEXT,
      width: '250px',
      isTruncated: true,
    },
    {
      label: this.i18n.translate('guest.users.management.table.column.email'),
      field: 'email',
      type: UiStaticTableRowType.TEXT,
      width: '275px',
      isTruncated: true,
    },
    {
      label: this.i18n.translate('guest.users.management.table.column.manager'),
      field: 'managerEmail',
      type: UiStaticTableRowType.TEXT,
      width: '275px',
      isTruncated: true,
      noSorting: true,
    },
    {
      label: this.i18n.translate('guest.users.management.table.column.invitedOn'),
      field: 'createdAt',
      type: UiStaticTableRowType.PIPED_FIELD,
      pipe: DTPipe,
      pipeParams: { locale: this.i18n.currentLocale },
      width: '175px',
    },
    {
      label: this.i18n.translate('guest.users.management.table.column.expireOn'),
      field: 'expirationDate',
      type: UiStaticTableRowType.DATE_AND_HOUR_AND_UNTIL,
      pipeParams: { locale: this.i18n.currentLocale },
      timeOnly: true,
      noSeconds: true,
      width: '215px',
      getColumnCustomCss: (row: any) => {
        const daysDifference = TimeUtil.getNumberOfDaysBetweenTwoDates(Date.now(), row.expirationDate, false);
        if (daysDifference === 0 && row.status === GuestUserStatusEnum.ACTIVE) return { color: '#ff7800' };
        if (daysDifference < 0 || row.status !== GuestUserStatusEnum.ACTIVE) return { color: '#de3618' };
      },
    },
    {
      label: this.i18n.translate('guest.users.management.table.column.associatedResources'),
      field: 'resourcesAmount',
      type: UiStaticTableRowType.TEXT,
      width: '215px',
      timeOnly: true,
      noSeconds: true,
      noSorting: true,
    },
  ];

  private guestUserTableManagementFactory: GuestUserTableManagementFactory;

  public activeGuestsTable: GuestUserTableManagement;
  public pendingGuestsTable: GuestUserTableManagement;
  public toDeleteGuestsTable: GuestUserTableManagement;

  public tables: GuestUserTableManagement[];

  constructor(
    public i18n: I18nService,
    public dialog: MatDialog,
    private usersApiService: UsersApiService,
    staticTableDataMapper: StaticTableDataMapper,
    private dtPipe: DTPipe,
    private notice: NoticeService,
  ) {
    this.guestUserTableManagementFactory = new GuestUserTableManagementFactory(usersApiService, staticTableDataMapper, this.columnsDef);
  }

  ngOnInit(): void {
    this.activeGuestsTable = this.guestUserTableManagementFactory.getActiveGuestUsersTable();
    this.pendingGuestsTable = this.guestUserTableManagementFactory.getPendingGuestUsersTable();
    this.toDeleteGuestsTable = this.guestUserTableManagementFactory.getToDeleteGuestUsersTable();

    this.tables = [this.toDeleteGuestsTable, this.pendingGuestsTable, this.activeGuestsTable];
    this.setUserToDelete();
    this.setCurrentUserRole();
  }

  public get isLoading(): boolean {
    return this.tables.every((table) => table.isLoading);
  }

  public get total(): number {
    if (this.isLoading) {
      return 0;
    }

    return this.tables.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
  }

  public setUserToDelete(): void {
    this.usersApiService.listGuestUsers({ status: GuestUserStatusEnum.TO_DELETE, from: 0, limit: 1 })
      .subscribe(res => this.hasUserToDelete = res.getTotalItems() > 0);
  }

  public setCurrentUserRole(): void {
    this.usersApiService.getCurrentUserRole()
      .subscribe(response => this.isUserGuestAdmin = response);
  }

  public handleRefresh(): void {
    this.tables.forEach(table => table.handleRefresh());
    this.setUserToDelete();
  }

  public handleTextSearch(query: string): void {
    this.fulltext = query;
    this.tables.forEach((table) => table.handleTextSearch(query));
  }

  public clearTextSearch(): void {
    this.fulltext = '';
    this.tables.forEach((table) => table.clearTextSearch());
  }

  public openGuestUsersModal(): void {
    this.dialog.open(GuestUsersDeleteModalComponent, {
      data: {
        reloadEvent: this.handleRefresh.bind(this),
      },
      minHeight: '40%',
      maxWidth: '50%',
      panelClass: 'custom-dialog'
    });
  }

  public fetchOrphanGuests(): void {
    this.isCsvLoading = true;
    const data = this.usersApiService.getOrphanGuestCsvData().subscribe(
      res => this.generateCSVReport(res.items),
      err => {
        this.notice.error(this.i18n.translate('guest.users.management.admin-list.500.error'));
        this.isCsvLoading = false;
      },
      () => this.isCsvLoading = false
    );
  }

  public async generateCSVReport(data: any[]): Promise<void> {
    let csvContent = '\uFEFF';

    for (const column of this.csvColumnLabels) {
      csvContent += `${column.toUpperCase()},`;
    }
    csvContent.substring;
    csvContent = csvContent.substr(0, csvContent.length - 1);
    csvContent += '\n';

    data.forEach((item) => {
      // Les guillemets sont importants, https://datatracker.ietf.org/doc/html/rfc4180, point 2.6
      csvContent += `"${item.email}","${item.name}","${item.requester || 'N/A'}","${this.dtPipe.transform(item.invitedOn, { withTime: false, locale: this.i18n.currentLocale })}","${this.dtPipe.transform(item.orphanSince, { withTime: false, locale: this.i18n.currentLocale })}"\n`;
    });

    // Specify encoding directly in Blob options
    const blob = new Blob([csvContent], { type: 'text/csv; charset=utf-8;' });
    blob.text().then((response) => {
      const url = URL.createObjectURL(blob); // No need to create another Blob here

      const downloadLink = document.createElement('a');
      downloadLink.href = url;

      // Set the charset to UTF-8 in the Content-Disposition header
      downloadLink.download = `${this.dtPipe.transform(Date.now(), { withTime: false, locale: this.i18n.currentLocale })} - ${this.i18n.translate('guest.users.table.download.report.name')}.csv`;

      downloadLink.click();

      URL.revokeObjectURL(url);
      downloadLink.remove();
    });
  }
}

class GuestUserTableManagement {
  public name: string;
  public dataSource: GuestUser[] = [];
  public guestUsersResponse: ResponseUtils<GuestUser>;

  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;

  public fulltext: string = null;
  public total: number = 0;
  public from: number = 0;
  public direction: string = 'asc';
  public orderBy: string;
  public sort: string;

  constructor(
    public tableName: string,
    private status: GuestUserStatusEnum,
    public columnsDef: any[],
    private usersApiService: UsersApiService,
    private staticTableDataMapper: StaticTableDataMapper
  ) {
    this.name = tableName;
    this.fetchGuestUsers();
  }

  public get canLoadMore(): boolean {
    return (this.guestUsersResponse?.canLoadMore() ?? false) && !this.isLoading;
  }

  public handleRefresh(): void {
    this.total = 0;
    this.from = 0;
    this.dataSource = [];
    this.fetchGuestUsers();
  }

  public handleTextSearch(query: string): void {
    this.total = 0;
    this.fulltext = query;
    this.dataSource = [];
    this.fetchGuestUsers();
  }

  public handleSorting(orderBy: string): void {
    this.orderBy = orderBy;
    if (this.direction === 'asc') {
      this.direction = 'desc';
    } else {
      this.direction = 'asc';
    }
    this.dataSource = [];
    this.fetchGuestUsers();
  }

  public clearTextSearch(): void {
    this.total = 0;
    this.fulltext = null;
    this.fetchGuestUsers();
  }

  public fetchGuestUsers(loadMore: boolean = false): void {
    const setLoading = (loadingState: boolean) => {
      loadMore ? this.isLoadingMore = loadingState : this.isLoading = loadingState;
    };

    const requestParams = {
      fulltext: this.fulltext,
      from: loadMore ? this.guestUsersResponse.getNextItem() : this.from,
      limit: 25,
      direction: this.direction,
      orderBy: this.orderBy,
      status: this.status,
    };

    setLoading(true);
    this.usersApiService.listGuestUsers(requestParams)
      .subscribe(
        (res) => {
          this.guestUsersResponse = res;
          if (loadMore) {
            this.dataSource = this.dataSource.concat(this.guestUsersResponse.getItems());
          } else {
            this.dataSource = this.guestUsersResponse.getItems();
            this.total = this.guestUsersResponse.getTotalItems();
          }
        },
        err => {
          setLoading(false);
        },
        () => {
          [ this.columnsDef, this.dataSource ] = this.staticTableDataMapper.translate(this.columnsDef, this.dataSource);
          setLoading(false);
        }
      );
  }
}

class GuestUserTableManagementFactory {
  constructor(
    private usersApiService: UsersApiService,
    private staticTableDataMapper: StaticTableDataMapper,
    private columnsDef: any[]
  ) { }

  public getActiveGuestUsersTable(): GuestUserTableManagement {
    return new GuestUserTableManagement('guest.users.management.guest-list.active-title', GuestUserStatusEnum.ACTIVE, this.columnsDef, this.usersApiService, this.staticTableDataMapper);
  }

  public getPendingGuestUsersTable(): GuestUserTableManagement {
    return new GuestUserTableManagement('guest.users.management.guest-list.pending-title', GuestUserStatusEnum.PENDING_DELETION, this.columnsDef, this.usersApiService, this.staticTableDataMapper);
  }

  public getToDeleteGuestUsersTable(): GuestUserTableManagement {
    return new GuestUserTableManagement('guest.users.management.guest-list.toRemove-title', GuestUserStatusEnum.TO_DELETE, this.columnsDef, this.usersApiService, this.staticTableDataMapper);
  }
}
