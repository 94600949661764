<app-incident-details-base-layout>
  <div class="history-top-section--filters-container">
    <incident-history-tab-filters
    [organizationId]= "organizationId"
    [isNotificationActionSwitchToggled] = "notificationActionSwitchToggled"
    [locale]="locale"
    [total]="total"
    toolbar
    ></incident-history-tab-filters>
  </div>
  <div
    class="section-container"
    *ngIf="incidentHistoryResponse"
  >
    <ui-static-table
      [loading]="isLoadingHistory"
      [dataSource]="incidentHistory"
      [addPaddingOnFirst]="false"
      [columnsDef]="historicColumnsDef"
      [expendedTemplate]="history"
      [locale]="i18n.currentLocale"
    >
    </ui-static-table>
    <div
      class="button-container"
      *ngIf="canLoadMore()"
    >
      <ui-table-refresh-tool
        class="refresher"
        [loading]="isLoadMoreHistory"
        [text]="'common.showMore' | t"
        rightSide
        (click)="loadMoreIncidentHistory()"
      ></ui-table-refresh-tool>
    </div>
  </div>

  <ui-spinner
    *ngIf="!incidentHistoryResponse"
    size="medium"
    modifier="secondary"
  ></ui-spinner>

  <ng-template
    #history
    let-incidentHistory="row"
    let-isExpanded="isSelect"
  >
    <app-history-detail
      *ngIf="isExpanded"
      [history]="incidentHistory"
    ></app-history-detail>
  </ng-template>
</app-incident-details-base-layout>