import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { TemplateStatesFilterEnum } from 'projects/@assist-ops/definitions/templateStatesFilter.enum';
import { Eco } from 'projects/@common/definitions/eco';
import {
  ApiEndpointPrefixEnum,
  EcoApi
} from 'projects/@common/definitions/eco-api';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { UrlUtils } from '../../../../utils/utils';
import { EcoUrlService } from '../../core/url.service';
import { IGroupTemplate } from './group-templates.definitions';
import IFetchResult = Eco.IFetchResult;

@Injectable({
  providedIn: 'root',
})
export class GroupTemplatesApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public getEcoGroupTemplate(templateGuid: string): Promise<IGroupTemplate> {
    return this.http
      .get<any>(`${this.host}/me/securityModels/${templateGuid}`, this.getFlagParams({ flags: [ FeaturesEnum.RESOURCE_MANAGEMENT ] }))
      .toPromise();
  }

  public getEcoGroupTemplates(params: {
    direction: string;
    sort: string;
    from: number;
    limit: number;
    fulltext?: string;
    type?: GroupTypeEnum;
    state?: TemplateStatesFilterEnum;
    splitGlobalAndSegmented?: boolean;
  }): Promise<IFetchResult<IGroupTemplate> & { total: { globalTotal: number, segmentTotal: number; }, userAttributeValue: string, hasContextualisation: boolean; }> {
    return this.http
      .get<any>(`${this.host}/me/securityModels?${UrlUtils.jsonToQueryParams(params)}`, this.getFlagParams({ flags: [ FeaturesEnum.RESOURCE_MANAGEMENT ] }))
      .toPromise();
  }

  public postEcoGroupTemplate(groupTemplate: IGroupTemplate): Promise<IGroupTemplate> {
    const url = `${this.host}/me/securityModels`;
    return this.http.post<any>(url, groupTemplate, this.getFlagParams({ flags: [ FeaturesEnum.RESOURCE_MANAGEMENT ] })).toPromise<any>();
  }

  public putEcoGroupTemplate(
    templateGuid: string,
    groupTemplate: IGroupTemplate
  ): Promise<IGroupTemplate> {
    const url = `${this.host}/me/securityModels/${templateGuid}`;
    return this.http.put<any>(url, groupTemplate, this.getFlagParams({ flags: [ FeaturesEnum.RESOURCE_MANAGEMENT ] })).toPromise<any>();
  }

  public deleteGroupTemplate(
    templateGuid: string,
    justification: string
  ): Promise<IFetchResult<IGroupTemplate>> {
    const url = `${this.host}/me/securityModels/${templateGuid}/delete`;
    return this.http
      .patch<any>(url, { disableJustification: justification }, this.getFlagParams({ flags: [ FeaturesEnum.RESOURCE_MANAGEMENT ] }))
      .toPromise();
  }
}
