import { Component, Input, OnInit } from '@angular/core';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';
import { FiltersService } from '../../filters.service';

@Component({
  selector: 'filter-history',
  templateUrl: './filter-history.component.html',
  styleUrls: [ './filter-history.component.scss' ],
})
export class FilterHistoryComponent implements OnInit {
  @Input() filter: Filter;

  public tableColumnsDef = [
    {
      label: '',
      field: 'icon',
      showIconOnHover: true,
      type: UiStaticTableRowType.EXPENDABLE_ICON,
      width: '10px',
      minWidth: '10px',
      isResizable: false,
      noSorting: true,
    },
    {
      label: 'date',
      field: 'createdAt',
      width: '200px',
      minWidth: '200px',
      type: UiStaticTableRowType.DATE_AND_HOUR_AND_AGO,
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('alerts.drawer.tab.history.table.column.user'),
      field: 'identityName',
      width: '200px',
      minWidth: '200px',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('alerts.drawer.tab.history.table.column.operation'),
      field: 'message',
      width: '100%',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      noSorting: true,
    },
  ];

  constructor(
    public readonly i18n: I18nService,
    private readonly staticTableDataFactory: StaticTableDataMapper,
    private readonly filtersService: FiltersService
  ) { }

  ngOnInit(): void {
  }

  public get historyTableData(): Array<object> {
    return this.filtersService.filterHistory;
  }

  public get isLoading(): boolean {
    return this.filtersService.isLoadingHistory;
  }

  public get isLoadingMore(): boolean {
    return this.filtersService.isLoadingMoreHistory;
  }

  public get canLoadMore(): boolean {
    return this.filtersService.canLoadMoreHistory;
  }

  public loadMoreHistory(): void {
    this.filtersService.loadMoreHistory();
  }
}
