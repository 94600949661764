<div
  page
  [appPagePaths]="['org', 'guestUsers']"
  (onScrollToBottom)="onScrollToBottom()"
  [disableOrganizationSelector]="true"
>
    <div class="container">
      <h3>{{ 'guest.users.dsecription.text' | t }} </h3>
        <guest-users-table #guestUsersTable></guest-users-table>
    </div>
</div>
  
  
