import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatasourceFamilyDetailService } from '../../../../services/datasource-family-detail.service';
import { DatasourceInventoryInfo } from 'projects/@common/services/api/respond/datasources/datasources.definitions';
import { DatasourcesCategoryMapper } from '../mappers/datasourcesCategoryMapper';
import { DatasourcesInventoryActions } from './datasources-inventory.actions';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { previewModeEnum } from '../../../datasource-inventory/container/components/inventory-layout/inventory-layout.component';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { IAutomationOrganization } from '@common/services/api/respond/automation-organization/automation-organization.definitions';
import { AutomationOrganizationsService } from '@common/services/api/respond/automation-organization/automation-organizations.api';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { Eco } from '@common/definitions/eco';
import { TableFilterToolDataFactory } from '@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { EcoSessionState } from '@common/modules/session/state/session.state';
import { DatasourcesApiService } from '@common/services/api/respond/datasources/datasources.api';
import { UsecasesApiService } from '@common/services/api/respond/usecase/usecase.api';

@Component({
  selector: 'datasources-inventory-container',
  templateUrl: './datasources-inventory.container.component.html',
  styleUrls: [ './datasources-inventory.container.component.scss' ],
})
export class DatasourcesInventoryContainerComponent implements OnInit, OnDestroy {
  public datasources: DatasourceInventoryInfo[] = [];

  public organization: IAutomationOrganization;
  public organizationId: string;
  public loading = false;

  public datasourcesInventoryActions: DatasourcesInventoryActions;
  public tableFilterData: TableFilterData;

  public preview: previewModeEnum;

  @Select(EcoSessionState.organization) public organization$: Observable<Eco.IOrganization>;

  constructor(
    public readonly i18n: I18nService,
    private readonly datasourcesApiService: DatasourcesApiService,
    private readonly usecasesApiService: UsecasesApiService,
    private readonly datasourcesCategoryMapper: DatasourcesCategoryMapper,
    private readonly organizationService: AutomationOrganizationsService,
    private readonly router: Router,
    private readonly datasourceFamilyDetailService: DatasourceFamilyDetailService,
    private readonly cdr: ChangeDetectorRef,
    private readonly zone: NgZone,
    private tableFilterToolDataFactory: TableFilterToolDataFactory
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.organization$.subscribe((value) => {
      this.organizationId = value.id;
      this.datasourcesInventoryActions = new DatasourcesInventoryActions(
        this.organizationId,
        this.datasourcesApiService,
        this.usecasesApiService,
        this.datasourcesCategoryMapper,
        this.i18n
      );
    });

    this.loading = true;
    try {
      this.organization = await this.organizationService.describeOrganization(this.organizationId);
      await this.datasourcesInventoryActions.handleRefresh();
    } finally {
      this.loading = false;
    }

    this.datasourcesInventoryActions.$datasources.subscribe((datasources) => {
      this.datasources = datasources;
      this.cdr.detectChanges();
    });

    this.tableFilterData = this.tableFilterToolDataFactory.create(this.datasourcesInventoryActions.datasourceCategoryEnum, 'detection.datasource.inventory.table.', this.datasourcesInventoryActions.defaultDropdownOption, true);
  }

  ngOnDestroy(): void {
    this.datasourcesInventoryActions.$datasources.unsubscribe();
  }

  public async navigate(item: DatasourceInventoryInfo): Promise<void> {
    this.datasourceFamilyDetailService.datasourceInfo = item;
    const name = this.transformIntoTitleCase(this.i18n.translate(item.title));
    const path = `detection/datasources/${name}/detail`;
    this.zone.run(() => {
      this.router.navigateByUrl(path);
    });
  }

  private transformIntoTitleCase(value: string): string {
    return value.replace(/\s+(.)/g, (match, group) => group.toUpperCase());
  }
}
