import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { AutocompleteCustomValue } from '@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component';
import { TextareaTypeEnum } from '@ui-kit/components/ui-json-textarea/ui-json-textarea.component';
import { Accumulatables, Accumulate } from 'projects/@common/modules/accumulator/accumulator.store';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { DrawerState, SetDrawerIsEditing } from 'projects/@common/modules/layout/components/drawer/stores/drawer.store';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { PlaybooksApiService } from 'projects/@common/services/api/respond/playbooks/playbooks.api';
import { IPlaybookDetail, IPlaybookTemplate } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { AlertingUsecaseListItem, IUsecase } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { UsecaseDescriptionDrawer } from '../../containers/drawers/usecase-description-drawer/usecase-description-drawer.component';
import { UsecaseUpdateForm } from './usecase-update-form';
import { UiToggle } from '@ui-kit/components/ui-toggle/ui-toggle.component';
import { DataConnectorTypes } from '@md.eco/connectors';
import { PriorityEnum } from '@common/services/api/respond/communication/communication.definitions';

@Component({
  selector: 'usecase-details',
  templateUrl: './usecase-details.component.html',
  styleUrls: [ './usecase-details.component.scss' ],
})
export class UsecaseDetailsComponent implements OnInit, AfterViewInit {
  public static readonly COMPONENT = 'usecaseDetailsComponent';
  public readonly locale: string;
  public action: UntypedFormGroup = new UntypedFormGroup({ action: new UntypedFormControl('') });
  public textareaTypeEnum = TextareaTypeEnum;
  public isEditing = false;
  public isEditingAlertingMode = false;
  public organizationId: string;
  public selectedPlaybook: Array<AutocompleteCustomValue> = [];
  public initialPlaybook: Array<AutocompleteCustomValue> = [];
  public autocompletePlaybookValues: AutocompleteCustomValue[] = [];
  public playbookNameWithSuffix: string;
  public customAutocomplete = AutocompleteTypes.CUSTOM;
  public priorityOptions: any[] = [];
  private playbookTemplateIds: string[];
  private playbookTemplates: IPlaybookTemplate[];
  private associatedLabel = {
    en: "Associated |",
    fr: "Associé |",
  };
  private recommendedLabel = {
    en: "Recommended |",
    fr: "Recommandé |",
  };

  @ViewChild('alertingModeToggle') private alertingModeToggleComponent: UiToggle;

  @Input() currentUsecase: IUsecase;
  @Input() usecaseTemplate: IUsecase;
  @Input() currentPlaybook: IPlaybookDetail;
  @Input() usecaseUpdateForm: UsecaseUpdateForm;
  @Input() public data: { title: string; usecaseItem: AlertingUsecaseListItem; organizationId: string; actions: any[]; alertingModeActionNames: string[]; organizationEcoId: string };

  constructor(
    public readonly i18nService: I18nService,
    private drawerService: DrawerService,
    private usecasesApiService: UsecasesApiService,
    private readonly playbooksApiService: PlaybooksApiService,
    private store: Store,
    private noticeService: NoticeService,
    private router: Router
  ) {
    this.locale = this.i18nService.currentLocale;
  }

  ngOnInit(): void {
    this.priorityOptions.push({
      value: null,
      displayValue: `${this.i18nService.translate("org_usecase.description_drawer.default")} (${this.usecaseTemplate.priority})`,
    });

    for (const key of Object.keys(PriorityEnum).filter((key) => isNaN(Number(key)))) {
      this.priorityOptions.push({
        value: PriorityEnum[key],
        displayValue: key,
      });
    }
  }

  ngAfterViewInit(): void {
    this.initializeData();
    this.fetchPlaybooks();
    this.store.select(DrawerState.isEditing).subscribe((isEditing) => {
      this.isEditing = isEditing;
    });
  }

  save(redirectToPlaybook: boolean = false): void {
    this.updateUsecases(redirectToPlaybook);
    this.drawerService.previousDrawer();
  }

  cancel(): void {
    this.toggleAction();
    this.initializeData();
  }

  public toggleAction(): void {
    this.isEditing = !this.isEditing;

    this.store.dispatch(new SetDrawerIsEditing({
      isEditing: this.isEditing,
      tabName: UsecaseDetailsComponent.COMPONENT,
    }));
  }

  public isAlerting(): boolean {
    return !this.usecaseUpdateForm.alertingMode;
  }

  public isOutdated(): boolean {
    return this.currentUsecase.isOutdated;
  }

  public isPipelineOutdated(): boolean {
    return this.currentUsecase.isPipelineOutdated;
  }

  public updateOutdatedUsecase(): void {
    this.usecasesApiService
      .updateOutdatedUsecase(this.usecaseUpdateForm.usecaseJson)
      .then((response) => {
        this.currentUsecase = response;
        this.noticeService.notifyUser(new Notice('org_usecase.description_drawer.tab.monitors.updated_success', NoticeLevels.SUCCESS));
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
      })
      .catch((error) => {
        const message = Array.isArray(error.response.data.message)
          ? `${error.response.data.error}: ${error.response.data.message[0]}`
          : error.response.data.message;
        this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
      })
      .finally(() => {
        if (this.drawerService.component) {
          this.drawerService.component.ngOnInit();
        }
      });
  }

  public updateOutdatedUsecasePipeline(): void {
    this.usecasesApiService
      .updateOutdatedUsecasePipeline(this.usecaseUpdateForm.usecaseJson)
      .then((response) => {
        this.currentUsecase = response;
        this.drawerService.replaceCurrentDrawer(UsecaseDescriptionDrawer, {
          component: DrawerPageEnum.descriptionUsecaseDrawer,
          data: {
            title: response.name[this.locale] || response.name['en'],
            usecaseId: response.id,
            silenced: response.silenced,
            actions: response.pipeline,
            alertingModeActionNames: this.data.alertingModeActionNames,
          },
        });
        this.noticeService.notifyUser(new Notice('org_usecase.description_drawer.tab.usecase_pipeline.updated_success', NoticeLevels.SUCCESS));
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
      })
      .catch((error) => {
        const message = Array.isArray(error.response.data.message)
          ? `${error.response.data.error}: ${error.response.data.message[0]}`
          : error.response.data.message;
        this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
      });
  }

  public toggleMode(): void {
    if (this.isAlerting()) {
      // Open dialog to set the comment
      this.isEditingAlertingMode = true;
    } else {
      // Call the api directly
      this.usecaseUpdateForm.toggleAlertingMode();
      this.editAlertingMode();
    }
  }

  public async onEditingAlertingModeDialogClick(event: 'action'|'cancel'): Promise<void> {
    if (event === 'action') {
      this.usecaseUpdateForm.toggleAlertingMode();
      await this.editAlertingMode();
    }

    this.isEditingAlertingMode = false;
    this.usecaseUpdateForm.clearAlertingModeComment();
  }

  private async editAlertingMode(): Promise<void> {
    try {
      if (this.isAlerting()) {
        const actionNames = this.usecaseUpdateForm.actionsControls.map((control) => control.value.name);
        this.data.alertingModeActionNames.forEach((alertingModeActionName) => {
          if (!actionNames.includes(alertingModeActionName)) {
            this.usecaseUpdateForm.actionsFormArray.push(this.usecaseUpdateForm.getActionControl({ name: alertingModeActionName, config: null }));
          }
        });
      }
      // Changing the toggle status manually
      this.alertingModeToggleComponent.toggleState(this.isAlerting());
      await this.usecasesApiService.updateUsecase(this.organizationId, this.usecaseUpdateForm.usecaseJson);
      this.noticeService.notifyUser(new Notice('org_usecase.description_drawer.tab.usecase_mode.updated_success', NoticeLevels.SUCCESS));
      this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
    } catch (error) {
      const message = Array.isArray(error.response.data.message)
        ? `${error.response.data.error}: ${error.response.data.message[0]}`
        : error.response.data.message;
      this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
    }
  }

  public async fetchPlaybooks(): Promise<void> {
    this.playbookTemplateIds = this.currentPlaybook ? [ this.currentPlaybook?.templateId ] : (await this.usecasesApiService.getUsecaseCatalog(this.currentUsecase.templateId)).template.playbookTemplateIds;
    if (this.playbookTemplateIds) {
      try {
        const everyPlaybookResponse = await this.playbooksApiService.getPlaybooks(this.organizationId);
        const playbooksToPrioritizeResponse = await this.playbooksApiService.getPlaybooks(this.organizationId, { templateIds: this.playbookTemplateIds });

        const everyPlaybook = everyPlaybookResponse.items.map((item) => ({
          displayValue: `${item.playbook.name[this.locale] || item.playbook.name['en']} ${item.playbook.suffix}`,
          value: item.playbook.id,
        }));
        let playbooksToPrioritize = playbooksToPrioritizeResponse.items.map((item) => ({
          displayValue: `${this.recommendedLabel[this.locale]} ${item.playbook.name[this.locale] || item.playbook.name['en']} ${item.playbook.suffix}`,
          value: item.playbook.id,
        }));

        const playbooksNotPrioritized = everyPlaybook.filter((item) => !playbooksToPrioritize.find((pb) => [ item.value, this.currentPlaybook ].includes(pb.value)));

        if (this.currentPlaybook) {
          const recommended = playbooksToPrioritize.find((pb) => pb.value === this.currentPlaybook.id);
          const extra = `${this.associatedLabel[this.locale]} ${recommended ? this.recommendedLabel[this.locale] : ""}`;
          playbooksToPrioritize = [ {
            displayValue: `${extra} ${this.currentPlaybook.name[this.locale] || this.currentPlaybook.name['en']} ${this.currentPlaybook.suffix}`,
            value: this.currentPlaybook.id,
          }, ...playbooksToPrioritize.filter((pb) => pb.value !== this.currentPlaybook.id) ];
        }

        this.autocompletePlaybookValues = [ ...playbooksToPrioritize, ...playbooksNotPrioritized ];
      } catch {
        this.noticeService.notifyUser(new Notice('common.error.get.500.message', NoticeLevels.ERROR));
      }

      this.playbooksApiService.getPlaybookTemplates({ ids: this.playbookTemplateIds })
        .then((response) => {
          this.playbookTemplates = response.items;
        }).catch(() => this.noticeService.notifyUser(new Notice('common.error.get.500.message', NoticeLevels.ERROR)));
    } else {
      this.playbooksApiService
        .getPlaybooks(this.organizationId)
        .then((response) => {
          this.autocompletePlaybookValues = response.items.map((item) => ({
            displayValue: `${item.playbook.name[this.locale] || item.playbook.name['en']} ${item.playbook.suffix}`,
            value: item.playbook.id,
          }));
        })
        .catch(() => this.noticeService.notifyUser(new Notice('common.error.get.500.message', NoticeLevels.ERROR)));
    }
  }


  public playbookSelection(event: { value: string; label: string }): void {
    if (event) {
      this.usecaseUpdateForm.setPlaybookId(event.value);
    }
  }

  private initializeData() {
    this.organizationId = this.data.organizationId;
    this.usecaseUpdateForm.setAlertingMode(this.currentUsecase.silenced);
    this.alertingModeToggleComponent.toggleState(!this.currentUsecase.silenced);
    this.usecaseUpdateForm.setUsecasePriority(this.currentUsecase?.priority);
    this.usecaseUpdateForm.usecaseFormGroup.patchValue({ affectedResources: this.currentUsecase.affectedResources });
    this.playbookNameWithSuffix = this.currentPlaybook ? `${this.currentPlaybook.name[this.locale] || this.currentPlaybook.name['en']} ${this.currentPlaybook.suffix}` : '-';
    if (this.currentPlaybook) {
      this.initialPlaybook.push({
        value: this.currentPlaybook.id,
        displayValue: this.playbookNameWithSuffix,
      });
    };
  }


  private updateUsecases(redirectToPlaybook: boolean): void {
    const updatedData: any = this.usecaseUpdateForm.usecaseJson;

    // If the silenced property did not change, we remove it from the updateData since it is not required;
    if (updatedData.silenced === this.currentUsecase.silenced) {
      delete updatedData.silenced;
      delete updatedData.silencedComment;
    }

    if(this.currentUsecase.playbookId !== updatedData.playbookId){
      updatedData.pipeline = this.currentUsecase.pipeline;
    }

    this.usecasesApiService
      .updateUsecase(this.organizationId, updatedData)
      .then(() => {
        this.noticeService.notifyUser(new Notice('org_usecase.description_drawer.tab.monitors.updated_success', NoticeLevels.SUCCESS));
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
      })
      .catch((error) => {
        const message = Array.isArray(error.response.data.message)
          ? `${error.response.data.error}: ${error.response.data.message[0]}`
          : error.response.data.message;
        this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
      })
      .finally(() => {
        if (redirectToPlaybook) {
          // filter playbookTemplates related to the usecase template by datasource type to find the first correct one
          const playbookTemplateToCreate = this.playbookTemplates.find((pbt) => pbt.datasourceTypes.includes(this.currentUsecase?.datasourceType as DataConnectorTypes));
          this.router.navigateByUrl(`respond/${this.data.organizationEcoId}/playbooks/creation?playbookTemplateId=${playbookTemplateToCreate}&usecaseId=${this.currentUsecase.id}`);
        }
      });
  }
}
