<app-incident-details-base-layout [title]="'incidents.container.page.details.tab.detail.summary.title' | t">
  <div class="summary-section">
    <short-id
      [shortId]="incident?.shortId"
      [label]="'incidents.container.page.details.tab.detail.summary.id' | t"
    ></short-id>

    <div class="flex-box column space-between">
      <label ui-label>{{'incidents.container.page.details.tab.detail.summary.priority' | t}}</label>
      <incident-priority-picker
        [priority]="incident?.priority"
        [organizationEcoId]="incident?.organizationEcoId"
        [disabled]="!canEditPriority"
        (priorityChangeEvent)="savePriority($event)"
      ></incident-priority-picker>
    </div>

    <div
      *ngIf="isEditable"
      class="flex-box column space-between"
    >
      <label
        ui-label
        [required]="canEditSeverity"
      >{{'incidents.container.page.details.tab.detail.summary.severity' | t}}</label>
      <app-severity-picker
        [disabled]="!canEditSeverity"
        [value]="incident.severity"
        (choiceEvent)="saveSeverity($event)"
      ></app-severity-picker>
    </div>

    <div class="flex-box column space-between">
      <label ui-label>{{'incidents.container.page.details.tab.detail.summary.status' | t}}</label>
      <app-incident-status
        [wrapper]="false"
        [data]="incident"
        [context]="'details'"
      ></app-incident-status>
    </div>

    <confidentiality-breach
      [showLabel]="true"
      [styleLikeInputField]="false"
      [disabled]="!canEditConfidentialityBreach"
      [value]="incident.breachOfConfidentiality"
      (choiceEvent)="saveConfidentialityBreach($event)"
    ></confidentiality-breach>

    <div class="flex-box column space-between">
      <label ui-label>{{'incidents.container.page.details.tab.detail.summary.connectors' | t}}</label>
      <incident-connectors
        *ngIf="usecases?.length; else noConnectorsTemplate"
        [incidentUsecases]="usecases"
      ></incident-connectors>
      <ng-template #noConnectorsTemplate>-</ng-template>
    </div>

  </div>

  <div class="description-section">
    <label
      ui-label
      [required]="canEditSummary"
    >{{'incidents.container.page.details.tab.detail.summary.summary' | t}}</label>
    <ui-label-input-with-modify
      [attr.data-test-id]="'summary_edit'"
      emptyPlaceholder="-"
      [value]="incident.summary"
      [maxlength]="20000"
      [textareaInput]="true"
      [locale]="i18nService.currentLocale"
      [disabled]="!canEditSummary"
      [showUnsavedWarning]="shouldShowUnsavedWarning"
      (onValueChange)="saveSummary($event)"
      (isEdittingEvent)="handleSummaryEditingState(false)"
      (hasPendingChangeEvent)="handleSummaryEditingState($event)"
    ></ui-label-input-with-modify>
  </div>

  <ng-container *ngIf="incident.zohoTicketUrl || canEditZohoLink">
    <hr class="separator">
    <app-zoho-link-editor
      [disabled]="!canEditZohoLink"
      [currentValue]="{url: incident.zohoTicketUrl, id: incident.zohoTicket}"
      (saveEvent)="saveZohoTicket($event)"
    ></app-zoho-link-editor>
  </ng-container>

</app-incident-details-base-layout>