import { Component, Inject, OnInit } from '@angular/core';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { Eco } from 'projects/@common/definitions/eco';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { FiltersViewModeEnum } from 'projects/@common/services/api/respond/filters/filters.definitions';
import { FiltersService } from '../../filters.service';
import { ConsoleType } from '@common/modules/layout/private.layout';

export enum FilterVisibilitySelectionEnum {
  ALL = 'all',
  HIDDEN = 'hidden'
}

@Component({
  selector: 'filters-filters',
  templateUrl: './filters-filters.component.html',
  styleUrls: [ './filters-filters.component.scss' ],
})
export class FiltersFiltersComponent implements OnInit {
  public locale: Eco.Languages;
  public listViewModeEnum = FiltersViewModeEnum;
  public listViewModes: TableFilterData;
  public filterVisibility: TableFilterData;
  public isConsoleAdmin: boolean = false;

  constructor(
    public readonly filtersService: FiltersService,
    private readonly i18n: I18nService,
    private readonly tableFilterToolDataFactory: TableFilterToolDataFactory,
    @Inject('CONSOLE_TYPE') private readonly consoleType: ConsoleType
  ) { }

  ngOnInit(): void {
    this.locale = this.i18n.currentLocale as Eco.Languages;
    this.listViewModes = this.tableFilterToolDataFactory.create(FiltersViewModeEnum, 'filters.table.listViewModes.', this.viewMode, true);
    this.filterVisibility = this.tableFilterToolDataFactory.create(FilterVisibilitySelectionEnum, 'filters.table.visibility.search.mode.', FilterVisibilitySelectionEnum.ALL);
    this.isConsoleAdmin = this.consoleType === ConsoleType.ADM;
  }

  public get viewMode(): FiltersViewModeEnum {
    return this.filtersService.listViewMode;
  }

  public get searchText(): string {
    return this.filtersService.searchText;
  }

  public get isLoading(): boolean {
    return this.filtersService.isLoading;
  }

  public get total(): number {
    return this.filtersService.total;
  }

  public handleRefreshClicked(): void {
    this.filtersService.handleDebouncedFetch();
  }

  public handleSearchTextChange(searchText: string): void {
    this.filtersService.setSearchText(searchText);
  }

  public handleViewModeChange(viewMode: FiltersViewModeEnum): void {
    this.filtersService.setListViewMode(viewMode);
  }

  public handleVisibilitySelection(event: FilterVisibilitySelectionEnum): void {
    this.filtersService.hiddenFiltersOnly = event === FilterVisibilitySelectionEnum.HIDDEN;
    this.filtersService.fetchFilters();
  }
}
