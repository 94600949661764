<div class="drawer-detail--scrollable visible tab-menu">
  <ui-card>
    <ui-static-table
      [loading]="isLoading"
      [dataSource]="historyTableData"
      [addPaddingOnFirst]="false"
      [columnsDef]="tableColumnsDef"
      [expendedTemplate]="history"
      [locale]="i18n.currentLocale"
    >
    </ui-static-table>
  </ui-card>
  <div
    class="button-container"
    *ngIf="canLoadMore"
  >
    <ui-table-refresh-tool
      class="refresher"
      [loading]="isLoadingMore"
      [text]="'common.showMore' | t"
      rightSide
      (click)="loadMoreHistory()"
    ></ui-table-refresh-tool>
  </div>
</div>


<!-- Template used in the expanded table row when -->
<ng-template
  #history
  let-history="row"
  let-isExpanded="isSelect"
>
  <app-history-detail
    *ngIf="isExpanded"
    [history]="history"
  ></app-history-detail>
</ng-template>