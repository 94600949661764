import { Component, Input } from '@angular/core';
import { IHistory } from '@common/services/api/respond/histories/histories.definition';

@Component({
  selector: 'histories-indicators',
  templateUrl: './histories-indicators.component.html',
  styleUrls: [ './histories-indicators.component.scss' ],
})
export class HistoriesIndicatorsComponent {
  @Input() public history: IHistory;
  @Input() fixedGridDisplay: boolean = true;

  public writeIcon = "/assets/favicons/histories/write.svg";
  public varIcon = "/assets/favicons/histories/var.svg";
  public systemIcon = "/assets/favicons/histories/system.svg";
  public clientIcon = "/assets/favicons/histories/client.svg";

  public get isWrite(): boolean {
    return !!this.history?.write;
  }

  public get isClient(): boolean {
    return !this.isVar && !this.isSystem;
  }

  public get isVar(): boolean {
    return !!this.history?.isVar;
  }

  public get isSystem(): boolean {
    return !!this.history?.isSystem;
  }
}
