import { Component, Input } from '@angular/core';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IDatasourceVignette } from 'projects/@common/services/api/respond/datasources/datasources.definitions';

export interface IDetailHeaderData {
  title: string;
  description: string;
  activationDate: number;
  lastModification: number;
  isActive: boolean;
  vignettes: IDatasourceVignette[];
}

@Component({
  selector: 'detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: [ './detail-header.component.scss' ],
  providers: [ DTPipe ],
})
export class DetailHeaderComponent {
  @Input()
    data: IDetailHeaderData;

  @Input()
    isLoading: boolean;

  constructor(private datePipe: DTPipe, private i18n: I18nService) {}

  public getDateFormat(date: number): { date: string } {
    return { date: this.datePipe.transform(date, { withTime: false, locale: this.i18n.currentLocale }) };
  }
}
