<div class="button-bar-container"
  (click)="toggle()"
  cdk-overlay-origin
  #origin="cdkOverlayOrigin">
  <img src="assets/favicons/verticals_dots_icon.svg" [ngClass]="{'button-bar-container--activated': isOpened()}"/>
</div>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  [cdkConnectedOverlayOffsetY]="offsetY"
  [cdkConnectedOverlayOffsetX]="offsetX"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="(isPopoverActive$ | async)(name)"
  (detach)="close()"
>
  <div class="popover">
    <details open (click)="false">
      <summary disabled>
        <span class="release-note-title-adjuster">{{ 'release.notes.page.title' | t}}</span>
      </summary>
      <span class="separator"></span>
      <div class="releaseNotes-links">
        <a routerLink="/releases/proactive" (click)="close()" *display="{ services: ['proactiveCybersecurity'] }">{{ 'release.notes.proactive.title' | t }}</a>
        <a routerLink="/releases/mdr" (click)="close()" *display="{ services: ['detectAndRespond'] }">{{ 'release.notes.mdr.title' | t }}</a>
      </div>
    </details>
  </div>
</ng-template>