import { Component, Input, OnInit } from "@angular/core";
import { IAssetPropertyConfigRepresentation, IAssetRepresentation } from "projects/@common/services/api/respond/actifs/actifs.definitions";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IObservable } from "projects/@common/services/api/respond/observables/observables.definition";
import { IObservablesPageContext, ObservablesPageEnum } from "projects/@respond/observables/components/observable-container/observable-container.component";

@Component({
  selector: 'app-observable-tab',
  templateUrl: './observable-tab.component.html',
  styleUrls: [ './observable-tab.component.scss' ],
})
export class ObservableTabComponent implements OnInit {
  @Input()
  public incident: Incident;

  @Input()
  public observables: IObservable[];

  @Input()
  public assets: IAssetRepresentation[] = [];

  @Input()
  public assetTypes: IAssetPropertyConfigRepresentation[] = [];

  public pageContext: IObservablesPageContext;

  constructor() { }

  ngOnInit(): void {
    this.pageContext = {
      pageEnum: ObservablesPageEnum.INCIDENT_OBSERVABLES_PAGE,
      organizationId: this.incident.organizationId,
      incident: this.incident,
      observables: this.observables,
      viewParams: {
        showCreateButton: false,
        showDeleteButton: false,
        showTitle: false,
        showViewModeSelect: true,
      },
    };
  }
}
