import { RequestDirection } from "projects/@common/definitions/consoles.enum";

export interface IObservable {
  id: string;
  type: ObservableTypeEnum;
  value: any;
  displayValue?: string;
  source: ObservableSourceEnum;
  signature?: string;
  createdAt: number;
  description?: string;
  alertIds?: string[];
  alertAssetId?: string;
  assetId?: string;
  parentId?: string;
  assetType?: string;
  similar?: boolean;
}

export type IListObservablesRequest = {
  organizationId?: string;
  alertId?: string;
  incidentId?: string;
  findingId?: string;
};

export type IListSimilarObservablesRequest = IListObservablesRequest & {
  similarItemOrganizationId: string;
  similarItemId: string;
  similarItemType: SimilarityItemTypeEnum;
};

export interface IAlertObservableRequest {
  alertId: string;
  organizationId: string;
  source?: ObservableSourceEnum;
  from?: number;
  size?: number;
  orderBy?: ListObservablesOrderBy;
  direction?: RequestDirection;
}

export interface IIncidentObservableRequest {
  incidentId?: string;
  organizationId?: string;
  source?: ObservableSourceEnum;
  from?: number;
  size?: number;
  orderBy?: ListObservablesOrderBy;
  direction?: RequestDirection;
}

export enum ListObservablesOrderBy {
  name = 'name',
  createdAt = 'createdAt'
}

export enum ObservableSourceEnum {
  FINDING = 'FINDING',
  ACTION = "ACTION",
  ALERT = "ALERT",
  INCIDENT = "INCIDENT",
  ENRICHMENT = "ENRICHMENT"
}

export enum ObservableTypeEnum {
  ACTION_PERFORMED_BY = "action-performed-by",
  ALERT_CATEGORY = "alert-category",
  ATP_CATEGORY = "atp-category",
  ATP_DETECTION_SOURCE = "atp-detection-source",
  ATP_INVESTIGATION_STATE = "atp-investigation-state",
  ATP_THREAT_FAMILY_NAME = "atp-threat-family-name",
  ATP_TITLE = "atp-title",
  ATTACK_SUBTYPE = "attack-subtype",
  ATTACK_TYPE = "attack-type",
  AUTOMATED_ACTION_PERFORMED = "automated-action-performed",
  AUTONOMOUS_SYSTEM = "autonomous-system",
  AWS_ACCESS_KEY_ID = "aws-access-key-id",
  AWS_MASTER_ACCOUNT_ID = "aws-master-account-id",
  AWS_ACCOUNT_ID = "aws-account-id",
  AWS_ACCOUNT_NAME = "aws-account-name",
  AWS_AMI_LAUNCHPERMISSION = "aws-ami-launchpermission",
  AWS_API = "aws-api",
  AWS_BACKUP_VAULT = "aws-backup-vault",
  AWS_CLUSTER_ID = "aws-cluster-id",
  AWS_EC2_ID = "aws-ec2-id",
  AWS_EC2_AMI_ID = "aws-ec2-ami-id",
  AWS_EC2_PERMISSION = "aws-ec2-permission",
  AWS_EC2_SNAPSHOT_ID = "aws-ec2-snapshot-id",
  AWS_LOAD_BALANCER_ARN = "aws-load-balancer-arn",
  AWS_LOAD_BALANCER_ID = "aws-load-balancer-id",
  AWS_DATABASE_ARN = "aws-database-arn",
  AWS_DATABASE_ID = "aws-database-id",
  AWS_LATEST_RESTORE_TIME = "aws-latest-restore-time",
  AWS_PARAMETER_GROUP_NAME = "aws-parameter-group-name",
  AWS_PRINCIPAL_ID = "aws-principal-id",
  AWS_PRODUCT_ARN = "aws-product-arn",
  AWS_REPORT_PLAN = "aws-report-plan",
  AWS_RESOURCE_ARN = "aws-resource-arn",
  AWS_ROLES = "aws-roles",
  AWS_SECURITY_GROUP_ID = "aws-security-group-id",
  AWS_SECURITY_GROUP_NAME = "aws-security-group-name",
  AWS_SNAPSHOT_ID = "aws-snapshot-id",
  AWS_SUBNET_ID = "aws-subnet-id",
  AWS_SUBSCRIPTION_ID = "aws-subscription-id",
  AWS_VPC_ID = "aws-vpc-id",
  AWS_VPC_SG = "aws-vpc-security-group",
  AWS_WAF_RULE_NAME = "aws-waf-rule-name",
  AWS_WAF_NAME = "aws-waf-name",
  AZR_ACCOUNT_ID = "azr-account-id",
  AZR_APPLICATION_NAME = "azr-application-name",
  AZR_BLOB_IS_PUBLIC = "azr-blob-is-public",
  AZR_BLOB_NAME = "azr-blob-name",
  AZR_CORRELATION_ID = "azr-correlation-id",
  AZR_NETWORK_INTERFACE = "azr-network-interface",
  AZR_PUBLIC_IP_ADDR = "azr-public-ip-address",
  AZR_STORAGE_ACCOUNT = "azr-storage-account",
  AZR_SUBSCRIPTION_NAME = "azr-subscription",
  AZR_VIRTUAL_MACHINE = "azr-virtual-machine",
  AZR_VM_ENCRYPTION_ENABLED = "azr-vm-encryption-enabled",
  AZR_WEB_APP_HTTPS_ENABLED = "azr-web-app-https-enabled",
  COUNTRY = "country",
  DATABASE = "database",
  DATABASE_INSTANCE = "database-instance",
  DATE_TIME = "date-time",
  DELEGATE_NAME = "delegate-name",
  DELEGATED_MAILBOX = "delegated-mailbox",
  DESCRIPTION = "description",
  DESTINATION_IP = "destination-ip",
  DESTINATION_PORT = "destination-port",
  DESTINATION_USER_EMAIL = "destination-user-email",
  DESTINATION_USER_NAME = "destination-user-name",
  DEVICE_TYPE = "device-type",
  DOMAIN = "domain",
  ENDPOINT = "endpoint",
  EVENT_CODE = "event-code",
  EVENT_COUNT = "event-count",
  EVENT_ID = "event-id",
  FAILED_ATTEMPTS = "failed-attempts",
  FILE = "file",
  FILE_PATH = "file-path",
  FILENAME = "filename",
  FINDING_ID = "finding-id",
  FQDN = "fqdn",
  GROUP = "group",
  GROUP_DOMAIN = "group-domain",
  GROUP_ID = "group-id",
  GROUP_NAME = "group-name",
  GUARDDUTY_FINDING = "guardduty-finding",
  HASH = "hash",
  HOST_ID = "host-id",
  HOSTNAME = "hostname",
  HTTP_METHOD = "http-method",
  INCIDENT_ID = "incident-id",
  INCIDENT_ID_SECONDARY = "incident-id-secondary",
  INSTANCE = "instance",
  INTERFACE_ID = "interface-id",
  INTERFACE_NAME = "interface-name",
  IP = "ip",
  KEY_NAME = "key-name",
  LOG_ID = "log-id",
  LOG_TYPE = "log-type",
  LOGICAL_PARTITION = "logical-partition",
  MAIL = "mail",
  MAIL_SUBJECT = "mail-subject",
  MITRE_TACTIC_ID = "mitre-tactic-id",
  MITRE_TECHNIQUE_ID = "mitre-technique-id",
  NETWORK_INTERFACE = "network-interface",
  O365_ALERT_ID = "office365-alert-id",
  O365_ALERT_NAME = "office365-alert-name",
  ORGANIZATION_ID = "organization-id",
  ORGANIZATION_NAME = "organization-name",
  OS = "operating-system",
  OTHER = "other",
  OUTCOME = "outcome",
  OWNER = "owner",
  POLICY = "policy",
  POLICY_ID = "policy-id",
  PORT_COUNT = "port-count",
  PROCESS_NAME = "process-name",
  READER_ENDPOINT = "reader-endpoint",
  REASON = "reason",
  REGEXP = "regexp",
  REGISTRY = "registry",
  RESOURCE_ROLE = "resource-role",
  RULE_SCORE = "rule-score",
  S3_BUCKET_ENCRYPTED = "s3-bucket-encrypted",
  S3_BUCKET_HAS_TAG = "s3-bucket-has-tag",
  S3_BUCKET_NAME = "s3-bucket-name",
  S3_BUCKET_EXISTS = "s3-bucket-exists",
  S3_BUCKET_PRIVATE = "s3-bucket-private",
  S3_FILE_HAS_ONE_VERSION = "s3-file-has-one-version",
  S3_PUBLIC_BLOCK_ACCESS_ENABLED = "s3-public-block-access-enabled",
  SECURITY_GROUP = "security-group",
  SERVICE_NAME = "service-name",
  SEVERITY = "severity",
  SG_EXPOSES_NETWORK_INTERFACES = "sg-exposes-network-interfaces",
  SG_EXPOSES_DATABASES = "sg-exposes-databases",
  SG_NETWORK_INTERFACES = "sg-network-interfaces",
  SG_PUBLIC_INGRESS = "sg-public-ingress",
  SG_PUBLIC_INGRESS_REVOKED = "sg-public-ingress-revoked",
  SG_PUBLIC_INGRESS_RULE_IDS = "sg-public-ingress-rule-ids",
  SG_PUBLIC_INGRESS_RULES = "sg-public-ingress-rules",
  ENI_PUBLIC_INGRESS = "eni-public-ingress",
  SIGNING_CERTIFICATES = "signing-certificates",
  SNOWFLAKE_EVENT = "snowflake-event",
  SOURCE_IP = "source-ip",
  SOURCE_PORT = "source-port",
  SOURCE_USER_DOMAIN = "source-user-domain",
  SOURCE_USER_EMAIL = "source-user-email",
  SOURCE_USER_ID = "source-user-id",
  SOURCE_USER_NAME = "source-user-name",
  STATUS = "status",
  STORAGE_PRIVATE = "is-storage-private",
  SUCCESSFUL_ATTEMPTS = "successful-attempts",
  SYN_PORTS = "syn-ports",
  TARGET_USER_DOMAIN = "target-user-domain",
  TARGET_USER_ID = "target-user-id",
  TARGET_USER_NAME = "target-user-name",
  TCP_PORTS = "tcp-ports",
  TECHNOLOGY = "technology",
  THREAT_NAME = "threat-name",
  TIMESTAMP = "timestamp",
  TYPE = "type",
  TYPE_COUNT = "type-count",
  UDP_PORTS = "udp-ports",
  URI_PATH = "uri-path",
  URL = "url",
  URL_SAFE = "url-safe",
  USER = "user",
  USER_AGENT = "user-agent",
  USER_COUNT = "user-count",
  USER_EMAIL = "user-email",
  USER_ID = "user-id",
  USER_ROLE = "user-role",
  USERNAME = "username",
  VULNERABILITY_CATEGORY = "vulnerability-category"
}

enum SimilarityItemTypeEnum {
  ALERTS = 'alerts',
  INCIDENTS = 'incidents'
};
