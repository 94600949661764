import { HttpClient } from '@angular/common/http';
import { IRespondResponse } from 'projects/@common/definitions/IRespondResponse';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { IGetPlaybookQuery, IGetPlaybookStats, IGetPlaybookTemplateQuery, IListPlaybooksRequest, IListPlaybooksTemplateQuery, IPlaybookDetail, IPlaybookListResponse, IPlaybookTaskTemplateRepresentation, IPlaybookTemplate, IPlaybooksApi, getPlaybookTaskTemplatesRequest } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { UrlUtils } from 'projects/@common/utils/utils';

export class VarPlaybooksApiService extends EcoApi implements IPlaybooksApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  private readonly playbooks = 'playbooks';
  private readonly playbookTemplates = 'playbookTemplates';
  private readonly playbookTaskTemplates = 'playbookTaskTemplates';
  private readonly organizations = 'organizations';

  constructor(http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public getPlaybookTemplateById(templateId: string, queryParams?: IGetPlaybookTemplateQuery): Promise<IPlaybookTemplate> {
    const url = `${super.host}/${this.playbookTemplates}/${templateId}?${UrlUtils.jsonToQueryParams(queryParams)}`;
    return this.http.get<IPlaybookTemplate>(url).toPromise();
  }

  public getPlaybookTemplates(queryParams?: IListPlaybooksTemplateQuery): Promise<IRespondResponse<IPlaybookTemplate>> {
    const url = `${super.host}/${this.playbookTemplates}?${UrlUtils.jsonToQueryParams({ ...queryParams, size: queryParams?.size || 250 })}`;
    return this.http.get<IRespondResponse<IPlaybookTemplate>>(url).toPromise();
  }

  public async getPlaybookTaskTemplates(params?: getPlaybookTaskTemplatesRequest): Promise<IRespondResponse<IPlaybookTaskTemplateRepresentation>> {
    const url = `${super.host}/${this.playbookTaskTemplates}?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IRespondResponse<IPlaybookTaskTemplateRepresentation>>(url).toPromise();
  }

  public getPlaybooks(organizationId: string, queryParam?: IListPlaybooksRequest): Promise<IRespondResponse<IPlaybookListResponse>> {
    const url = `${super.host}/${this.organizations}/${organizationId}/${this.playbooks}?${UrlUtils.jsonToQueryParams(queryParam)}`;
    return this.http.get<IRespondResponse<IPlaybookListResponse>>(url).toPromise();
  }

  public getPlaybook(organizationId: string, playbookId: string, queryParams?: IGetPlaybookQuery): Promise<IPlaybookDetail> {
    const url = `${super.host}/${this.organizations}/${organizationId}/${this.playbooks}/${playbookId}?${UrlUtils.jsonToQueryParams(queryParams)}`;
    return this.http.get<IPlaybookDetail>(url).toPromise();
  }

  public getPlaybookStats(organizationId: string): Promise<IGetPlaybookStats> {
    const url = `${super.host}/${this.organizations}/${organizationId}/${this.playbooks}/stats`;
    return this.http.get<IGetPlaybookStats>(url).toPromise();
  }

  public createPlaybook(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail> {
    const url = `${super.host}/${this.organizations}/${organizationId}/${this.playbooks}/`;
    return this.http.post<IPlaybookDetail>(url, playbook).toPromise();
  }

  public updatePlaybook(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail> {
    const url = `${super.host}/${this.organizations}/${organizationId}/${this.playbooks}/${playbook.id}`;
    return this.http.put<IPlaybookDetail>(url, playbook).toPromise();
  }

  public deletePlaybook(organizationId: string, playbookId: string): Promise<any> {
    const url = `${super.host}/${this.organizations}/${organizationId}/${this.playbooks}/${playbookId}`;
    return this.http.delete(url).toPromise();
  }

  public updatePlaybookVersion(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail> {
    const url = `${super.host}/${this.organizations}/${organizationId}/${this.playbooks}/${playbook.id}/version`;
    const payload = {
      id: playbook.id,
      playbookTasks: playbook.playbookTasks,
    };

    return this.http.put<IPlaybookDetail>(url, payload).toPromise();
  }
}
