<div class="content-wrapper">
  <div class="content-wrapper--title-wrapper">
    <div class="content-wrapper--title-wrapper--square-icon"></div>
    <p class="content-wrapper--title-wrapper--title">{{ (data.title || 'detection.playbook.task.specific.title') | t}}</p>
  </div>

  <ng-container *ngFor="let taskInput of taskInputArray">
    <app-specific-task-textarea
      [requiredLanguage]="requiredLanguage"
      [autocompleteUsecaseTemplateValues]="paramsList"
      [textareaValues]="taskInput"
    >
    </app-specific-task-textarea>
    <div class="separator"></div>
  </ng-container>

  <div class="content-wrapper--checkbox-option">
    <ui-checkbox
      [group]="specificTaskForm.taskForm"
      controlName="hidden"
      isEditable="true"
      [label]="'detection.playbook.task.specific.hide' | t"
    ></ui-checkbox>

    <ui-checkbox
      *ngIf="!data.cantIgnore"
      [group]="specificTaskForm.taskForm"
      controlName="ignored"
      isEditable="true"
      [label]="'detection.playbook.task.specific.ignore' | t"
    ></ui-checkbox>
  </div>
  <div class="specific-task-actions-container">
    <button
      class="action-buttons"
      ui-button
      type="submit"
      (click)="save()"
      [disabled]="!specificTaskForm.isFormValid"
      color="standard"
    >{{ (data.save || 'common.action.add') | t }}
    </button>
    <button
      ui-button
      color="linkFlat"
      (click)="modalService.closeDialog()"
    >{{'common.action.cancel' | t}}</button>
  </div>
</div>
