<div class="top-row">
  <button
    ui-button
    color="listActionFlat"
    icon="plus"
    icon-size="medium"
    [disabled]="!canAddNewContact()"
    [suffix]="null"
    (click)="addNewContact()"
  >
    {{ 'respond.communication.tab.contacts.createButton.label' | t }}
  </button>

  <div class="filters">
    <ui-table-total-tool
      [locale]="i18n.currentLocale"
      [total]="isLoading ? '-' : displayedContactsList?.length || 0"
    ></ui-table-total-tool>

    <ui-table-search-tool
      class="search-text"
      [placeholder]="'respond.communication.tab.contacts.search.placeholder' | t"
      [query]="filters.searchText"
      (query)="handleSearchTextChange($event)"
      (clear)="handleSearchTextChange(null)"
    ></ui-table-search-tool>

    <ui-table-refresh-tool
      class="refresher"
      rightSide
      [loading]="isLoading"
      (click)="refreshSearch()"
    ></ui-table-refresh-tool>
  </div>
</div>

<div class="contacts">
  <ng-container *ngIf="!isLoading">
    <communication-contact
      *ngFor="let contact of displayedContactsList; trackBy:trackById"
      [contact]="contact"
      [escalationLists]="escalationLists"
      [hasEditPermission]="hasEditPermission"
      [hasDeletePermission]="hasDeletePermission"
      (saveContactEvent)="saveContact(contact)"
      (deleteContactEvent)="deleteContact(contact)"
    ></communication-contact>
  </ng-container>
</div>


<ng-template #loadingSpinnerTemplate>
  <ui-spinner
    class="spinner-container"
    size="medium"
    modifier="secondary"
  ></ui-spinner>
</ng-template>