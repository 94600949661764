<div class="card-content">
  <settings-edition-card
    icon="Icon_timer_bold_cropped.svg"
    [title]="'settings.dlp.justification.title' | t"
    [canSave]="isValid"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <p>
      {{ 'settings.dlp.justification.description' | t }}
    </p>
    <div class="labels">
      <div class="label-settings">
        <p [innerHTML]="'settings.dlp.justification.days' | t: { amount: delayForm?.value.days === -1 ? +this.delayForm.value.custom : this.delayForm.value.days  }"></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editing>
  <p class="edit-description">
    {{ 'settings.dlp.justification.description.edit' | t }}
  </p>
  <div class="buttonsAndStuff" [formGroup]="delayForm">
    <mat-radio-group formControlName="days" class="radio-buttons">
      <mat-radio-button *ngFor="let delay of delays; let last = last" [value]="delay">
        <div *ngIf="!last" class="flex">
          <span>{{ 'settings.dlp.justification.days.option' | t: { amount: delay } }}</span>
        </div>
        <div *ngIf="last" class="input">
          <div class="days">
            <input
              [ngClass]="{'error-input': errorMessage}"
              [disabled]="delayForm.value.days !== -1"
              ui-input
              maxlength="3"
              uiNumberOnlyInput
              formControlName="custom"
              name="custom"
            />
            <p>{{ 'settings.notification.silencing.days.label' | t }}</p>
          </div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <ng-container *ngIf="errorMessage">
    <p class="error-text">{{ errorMessage | t }}</p>
  </ng-container>

</ng-template>
