import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IUiMenuOptions } from '../ui-menu-options/ui-menu-options.component';

export interface ICollapsableBadge {
  text: string;
  color: CollapsableBadgeColor;
}

export enum CollapsableBadgeColor {
  ORANGE = 'orange',
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  GRAY = 'gray',
  YELLOW = 'yellow'
}

export enum CollapsableBadgeAlignment {
  START = 'start',
  END = 'end'
}

@Component({
  selector: 'ui-collapsable-card',
  templateUrl: './ui-collapsable-card.component.html',
  styleUrls: [ './ui-collapsable-card.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-collapsable-card',
  },
})
export class UiCollapsableCard implements OnInit, OnChanges {
  @Input() public cardTitle: string;

  @Input() public cardSecondTitle: string;

  @Input() public headerIcon: string;

  @Input() public rightIcon: {name: string, tooltip?: string; text?: string};

  @Input() public isHeaderIconPng = false;

  @Input() public isHeaderIconFullpath = false;

  @Input() public avatarOfficeId: string;

  @Input() public isAvatar = false;

  @Input() public numberItems: number;

  @Input() public isCollapsable = true;

  @Input() public isCollapsed = true;

  @Input() public useRedFlagFeature: boolean = false;

  @Input() public isRedFlagged: boolean = false;

  @Input() public redFlagTooltipTaskNames?: string;

  @Input() public redFlagTooltip?: string;

  @Input() public isBigHeaderImage = false;

  @Input() public hideDynamicTitleOnExpand = false;

  @Input() public dynamicTitle: any;

  @Input() public spaceBetweenDynamicTitle = true;

  @Input() public item: any;

  @Input() public badges: ICollapsableBadge[] = [];

  @Input() public badgeAlignment: CollapsableBadgeAlignment = CollapsableBadgeAlignment.START;

  @Input() public tooltipText: string;

  @Input() public tooltipPositionBottom: boolean = false;

  @Input() public withMenuOptions: boolean = false;

  @Input() public menuOptions: IUiMenuOptions[];

  @Output() public onToggleCollapse = new EventEmitter<boolean>();

  @Output() public menuOptionClickEvent = new EventEmitter<IUiMenuOptions>();

  @Output() public menuOpenedEvent = new EventEmitter<void>();

  @Output() public menuClosedEvent = new EventEmitter<void>();

  public dynamicComponentParam: any;

  public ngOnInit() {
    if (this.isCollapsed) {
      this.isCollapsed = this.isCollapsable;
    }
  }

  public ngOnChanges() {
    this.getComponent();
  }

  public get iconSrc(): string {
    if (this.isHeaderIconFullpath) {
      return this.headerIcon;
    }
    return `assets/favicons/${this.headerIcon}${this.isHeaderIconPng ? '.png' : '.svg'}`;
  }

  public toggleCollapse() {
    if (this.isCollapsable) {
      this.isCollapsed = !this.isCollapsed;
      this.onToggleCollapse.emit(this.isCollapsed);
    }
  }

  public getComponent() {
    if (this.dynamicTitle) {
      const dynamicComponentParam = {
        component: this.dynamicTitle.component,
        data: {},
      };
      dynamicComponentParam.data[this.dynamicTitle.paramName] = this.dynamicTitle.item;
      this.dynamicComponentParam = dynamicComponentParam;
    }
  }

  public emitMenuOptionClicked(option: IUiMenuOptions) {
    this.menuOptionClickEvent.emit(option);
  }

  public emitMenuOpened(): void {
    this.menuOpenedEvent.emit();
  }

  public emitMenuClosed(): void {
    this.menuClosedEvent.emit();
  }
}
