import { ITranslatedField } from '@common/definitions/ITranslatedField';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { IAlertingUsecaseTemplate, IDatasourceType } from '@common/services/api/respond/usecase/usecase.definition';
import { Eco } from 'projects/@common/definitions/eco';
import { DataConnectorTypes } from '@md.eco/connectors';;

export interface UsecaseCategory<T extends IAlertingUsecaseTemplate> {
  type: UsecaseCategoryType;
  name: ITranslatedField;
  usecases: T[];
}

export enum UsecaseNonConnectorCategoryEnum {
  ALL_CONNECTORS = 'ALL_CONNECTORS',
  GENERIC = 'GENERIC',
  FIREWALL = 'FIREWALL',
  OTHER = 'OTHER',
  MCAS = 'MICROSOFT_CLOUD_APP_SECURITY'
}

export type UsecaseCategoryType = UsecaseNonConnectorCategoryEnum | DataConnectorTypes;

const nonConnectorCategoryMappings: {
  reg?: RegExp;
  type: UsecaseNonConnectorCategoryEnum;
  order?: number;
}[] = [
  {
    type: UsecaseNonConnectorCategoryEnum.ALL_CONNECTORS,
    order: 1,
  },
  {
    reg: /^GEN-UC-\d{4}$/,
    type: UsecaseNonConnectorCategoryEnum.GENERIC,
    order: 2,
  },
  {
    reg: /^FW-UC-\d{4}$/,
    type: UsecaseNonConnectorCategoryEnum.FIREWALL,
    order: 3,
  },
  {
    reg: /^MCAS-UC-\d{4}$/,
    type: UsecaseNonConnectorCategoryEnum.MCAS,
  },
  {
    type: UsecaseNonConnectorCategoryEnum.OTHER,
    order: -1,
  },
];

export class UsecaseCategories<T extends IAlertingUsecaseTemplate> {
  private categories: Map<UsecaseCategoryType, UsecaseCategory<T>> = new Map<UsecaseCategoryType, UsecaseCategory<T>>();
  private categoryKeys: UsecaseCategoryType[] = [];

  public constructor(
    private i18n: I18nService,
    public language?: Eco.Languages
  ) { }

  public get keys(): UsecaseCategoryType[] {
    return this.categoryKeys;
  }

  public get(key: UsecaseCategoryType): UsecaseCategory<T> {
    return this.categories.get(key);
  }

  public setCategories(DataConnectorTypes: IDatasourceType[]) {
    Object.values(UsecaseNonConnectorCategoryEnum).forEach((category) => {
      this.categories.set(category, {
        type: category,
        name: Object.values(Eco.Languages)
          .map((language) => [ language, this.i18n.translateWithLanguage(`usecaseCatalog.table.${category}.filter`, language) ])
          .reduce((prev, curr) => {
            prev[curr[0]] = curr[1];
            return prev;
          }, {}),
        usecases: [],
      });
    });

    DataConnectorTypes.forEach((connectorType) => {
      this.categories.set(connectorType.dataType, {
        type: connectorType.dataType,
        name: {
          en: connectorType.name,
          fr: connectorType.name,
        },
        usecases: [],
      });
    });

    this.setCategoryKeys();
  }

  public setUsecases(usecases: T[]) {
    if (this.categories.size === 0) {
      throw new Error("Must set categories before setting usecases");
    }
    this.clearCategoryUsecases();

    for (const usecase of usecases) {
      this.categories.get(UsecaseNonConnectorCategoryEnum.ALL_CONNECTORS).usecases.push(usecase);

      let assigned = false;
      for (const nonConnectorCategoryMapping of nonConnectorCategoryMappings) {
        if (nonConnectorCategoryMapping.reg?.test(usecase.shortId)) {
          this.categories.get(nonConnectorCategoryMapping.type).usecases.push(usecase);
          assigned = true;
          break;
        }
      }

      if (!assigned) {
        if (usecase.datasourceTypes.length === 1 && this.categories.has(usecase.datasourceTypes[0])) {
          this.categories.get(usecase.datasourceTypes[0]).usecases.push(usecase);
        } else {
          this.categories.get(UsecaseNonConnectorCategoryEnum.OTHER).usecases.push(usecase);
        }
      }
    }
  }

  private setCategoryKeys() {
    this.categoryKeys = Array
      .from(this.categories.keys())
      .sort((a, b) => {
        if (a === b) {
          return 0;
        }

        const orderA = nonConnectorCategoryMappings.find((m) => m.type === a)?.order;
        const orderB = nonConnectorCategoryMappings.find((m) => m.type === b)?.order;
        if (orderA || orderB) {
          if (orderA === -1) {
            return 1;
          }
          if (orderB === -1) {
            return -1;
          }
          if (orderA && !orderB) {
            return -1;
          }
          if (!orderA && orderB) {
            return 1;
          }
          return orderA - orderB;
        }

        const categoryA = this.categories.get(a).name[this.language || this.i18n.currentLocale];
        const categoryB = this.categories.get(b).name[this.language || this.i18n.currentLocale];
        return categoryA.localeCompare(categoryB);
      });
  }

  private clearCategoryUsecases() {
    for (const category of this.categories.keys()) {
      this.categories.get(category).usecases = [];
    }
  }
}
