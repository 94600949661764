<ng-container *ngIf="!isLoading">
  <downloads-frequency
    [currentSettings]="currentSettings"
    [isLoading]="isLoading"
  ></downloads-frequency>
</ng-container>

<ng-container *ngIf="isLoading">
  <ui-spinner size="medium"></ui-spinner>
</ng-container>
