import { Component, ViewChild } from '@angular/core';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { GuestUsersManagementComponent } from '../guest-users/guest-users-management/guest-users-management.component';
import { FeatureFlagsEnum } from '@common/modules/layout/definitions/base-sidebar.service';

@Component({
  selector: 'app-guest-users-management-container',
  templateUrl: './guest-users-management-container.component.html',
  styleUrls: ['./guest-users-management-container.component.scss']
})
export class GuestUsersManagementContainerComponent {
  @ViewChild('guestManagement') guestManagement: GuestUsersManagementComponent;

  public uiTabsColor: UiTabsColor = UiTabsColor.lightTabs;
  public featureFlagEnum = FeatureFlagsEnum;
}
