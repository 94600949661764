
  <div class="conversation-section-header">
    <div>
      <div class="img-header-container">
        <img src="assets/favicons/icon_conversation.svg" />
      </div>
      <h2>{{ 'incidents.container.page.details.tab.chat.chatSection.title' | t }}</h2>
    </div>
    <div>
      <button
        *ngIf="showStopEscalationButton"
        ui-button
        color="secondary"
        (click)="stopEscalation()"
      >
        {{ (isVarMode ? "incidents.container.page.details.tab.chat.chatSection.stopEscalation" : "incidents.container.page.details.tab.chat.chatSection.accuseReception") | t}}
      </button>
      <button
        ui-button
        color="dropdown"
        icon="expand"
        icon-size="medium"
        (click)="toggleExpanded()"
      ></button>
    </div>
  </div>

  <div class="conversation-container">
    <ng-container *ngTemplateOutlet="loading">

    </ng-container>
    <ng-container *ngTemplateOutlet="conversation">

    </ng-container>
  </div>

  <div class="bottom-section">
    <div class="editor-wrapper" [class.focused]="isTextboxFocused">
      <html-editor
        #editor
        class="editor"
        controlName="message"
        [attr.data-test-id]="'send_message'"
        [options]="froalaOptions"
        [isEditing]="true"
        [group]="formGroup"
        [htmlData]="messageValue"
        [placeholder]="null"
        [editorEventHandlers]="editorEventHandlers">
      </html-editor>
      <div *ngIf="incidentClosed" class="disabled-editor"></div>
    </div>
    
    <button
      ui-button
      class="send-button"
      color="listActionFlat"
      icon="send"
      icon-size="small"
      id="send-message"
      [disabled]="!messageValue"
      (click)="sendMessage()"
    >
      {{"incidents.container.page.details.tab.chat.send" | t}}
    </button>
    
  </div>


<ng-template #conversation>
  <ng-container *ngIf="!isLoading">
    <div class="messages-crop-container conversation">
     <div class="messages-container">
        <div *ngFor="let message of incidentConversation" class="message" [class.message-right-alignment]="isMessageRightAlignment(message)">
          <div class="message-container">
            <span class="user-name">
              <p>{{ message.sentAt | dt:{ withTime:true, locale: i18nService.currentLocale } }}</p>
              <p>&nbsp;-&nbsp;</p>
              <p>{{ message.senderName }}</p>
            </span>
            <div class="message-box">  
              <div class="message">
                <div class="message-content-conversation" [innerHTML]="message.content | safeHtml">
                
                </div>
                <div *ngFor="let file of message.files"> 
                  <a href="{{file.url}}" target="_blank">{{file.name}}</a>
                </div>
              </div>
              <app-chat-message-menu 
                class="menu"
                [incident]="incident" 
                [message]="message"
                [isFromAdmConsole]="isFromAdmConsole">
              </app-chat-message-menu>
            </div>
            <div *ngIf="messageSeen(message)" class="message-seen">
              <p>{{ 'incidents.container.page.details.tab.chat.messageSeen' | t }} {{ isVarMode ? 'client' : 'Mondata' }}</p>
              <img src="assets/favicons/icon_check.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #loading>
  <ng-container *ngIf="isLoading">
    <ui-spinner
      size="medium"
    ></ui-spinner>
  </ng-container>
</ng-template>