<div class="card-container">

  <header class="card-header">
    <h2 class="card-header-title">{{"respond.queries.result.title" | t:{items: resultTableData?.length || "0"} }}</h2>
    <span [tooltip]="'respond.queries.result.exportCsv.tooltip' | t">
      <button
        ui-button
        color="linkFlat"
        [disabled]="isExecutingQuery || !queryResult || !resultTableData?.length"
        [loading]="false"
        (click)="exportCsv()"
        icon="download"
        icon-size="small"
        icon-margin="0 2px 0 0"
      >{{"common.download" | t}}</button>
    </span>
  </header>
  <ng-container *ngIf="!isExecutingQuery; else loadingSpinner">
    <div
      class="query-result-content"
      *ngIf="queryResult && resultTableData; else noQueryResultYet"
    >
      <table
        *ngIf="!isAggregatedQuery"
        class="query-result-custom-table"
      >
        <!-- Title columns -->
        <thead>
          <tr>
            <th class="chevron-cell"></th>
            <th class="main-cell-bold no-padding-left">DATE</th>
            <th class="source-cell">SOURCE</th>
            <th class="copy-cell"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="resultTableData?.length > 0; else executedQueryResultEmptyTemplate">
            <ng-container *ngFor="let result of resultTableData">
              <!-- Main row info -->
              <tr
                class="basic-info border-top hover-style small-padding-bottom"
                [class.is-expanded]="result.isRowExpanded"
                [class.is-mouse-over]="result.isMouseOver"
                (mouseenter)="result.isMouseOver = true"
                (mouseleave)="result.isMouseOver = false"
              >
                <td
                  class="chevron-cell"
                  (click)="handleRowClick(result)"
                >
                  <ui-icon
                    class="'chevron-arrow-icon'"
                    modifier="on-header"
                    [name]="result.isRowExpanded ? 'chevron-down' : 'chevron-right'"
                    size="medium"
                  ></ui-icon>
                </td>
                <td
                  class="main-cell-bold no-padding-left"
                  (click)="handleRowClick(result)"
                >
                  {{result.formattedDate}}
                </td>
                <td
                  class="source-cell"
                  (click)="handleRowClick(result)"
                >
                  {{result.source}}
                </td>
                <td class="copy-cell">
                  <button
                    ui-button
                    color="listActionFlatGray"
                    icon="copy"
                    icon-size="small"
                    [tooltip]="'respond.queries.result.copy.tooltip' | t"
                    [disabled]="false"
                    (click)="copyFullJSON(result, $event)"
                  >
                  </button>
                </td>
              </tr>

              <!-- Message and/or dynamic props -->
              <tr
                class="summary-message no-padding-top light-grey"
                [class.is-expanded]="result.isRowExpanded"
                [class.is-mouse-over]="result.isMouseOver"
                (mouseenter)="result.isMouseOver = true"
                (mouseleave)="result.isMouseOver = false"
              >
                <td (click)="handleRowClick(result)"></td>
                <td
                  class="no-padding-left"
                  colspan="3"
                  (click)="handleRowClick(result)"
                >
                  <div
                    *ngIf="result.message"
                    class="query-result-dynamic-prop-dark-font"
                    style="margin-bottom: 0.5rem;"
                  >
                    {{result.message}}
                  </div>
                  <div *ngFor="let dynamicProp of result.dynamicProps">
                    <span>{{dynamicProp.key}}:&nbsp;</span>
                    <span class="query-result-dynamic-prop-dark-font">{{dynamicProp.value}}</span>
                  </div>
                </td>
              </tr>

              <!-- Complete log -->
              <tr
                class="complete-json-log"
                [class.is-expanded]="result.isRowExpanded"
                *ngIf="result.isRowExpanded"
              >
                <td colspan="4">
                  <json-table
                    *ngIf="!showJson"
                    [jsonString]="result.resultJSON"
                  ></json-table>
                  <json-sorted-view
                    *ngIf="showJson"
                    [jsonString]="result.resultJSON"
                  ></json-sorted-view>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>

      <table
        *ngIf="isAggregatedQuery"
        class="query-result-custom-table"
      >
        <!-- Title columns -->
        <thead>
          <tr>
            <th class="chevron-cell"></th>
            <th
              class="aggregated-value-cell main-cell-bold no-padding-left"
              [tooltip]="queryDetails.query.aggregation"
            >
              {{"respond.queries.result.columns.aggregatedValue" | t}}
            </th>
            <th class="aggregated-count-cell">
              {{"respond.queries.result.columns.aggregatedCount" | t}}
            </th>
            <th class="copy-cell"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="resultTableData?.length > 0; else executedQueryResultEmptyTemplate">
            <ng-container *ngFor="let result of resultTableData">
              <!-- Main row info -->
              <tr
                class="basic-info border-top hover-style small-padding-bottom"
                [class.is-expanded]="result.isRowExpanded"
                [class.is-mouse-over]="result.isMouseOver"
                (mouseenter)="result.isMouseOver = true"
                (mouseleave)="result.isMouseOver = false"
              >
                <td
                  class="chevron-cell"
                  (click)="handleRowClick(result)"
                >
                  <ui-icon
                    class="'chevron-arrow-icon'"
                    modifier="on-header"
                    [name]="result.isRowExpanded ? 'chevron-down' : 'chevron-right'"
                    size="medium"
                  ></ui-icon>
                </td>
                <td
                  class="aggregated-value-cell main-cell-bold no-padding-left"
                  (click)="handleRowClick(result)"
                >
                  {{result.aggregatedValue}}
                </td>
                <td
                  class="aggregated-count-cell"
                  (click)="handleRowClick(result)"
                >
                  {{result.aggregatedCount}}
                </td>
                <td class="copy-cell">
                  <button
                    ui-button
                    color="listActionFlatGray"
                    icon="copy"
                    icon-size="small"
                    [tooltip]="'respond.queries.result.copy.tooltip' | t"
                    [disabled]="false"
                    (click)="copyFullJSON(result, $event)"
                  >
                  </button>
                </td>
              </tr>

              <!-- Message and/or dynamic props -->
              <tr
                class="summary-message no-padding-top light-grey"
                [class.is-expanded]="result.isRowExpanded"
                [class.is-mouse-over]="result.isMouseOver"
                (mouseenter)="result.isMouseOver = true"
                (mouseleave)="result.isMouseOver = false"
              >
                <td (click)="handleRowClick(result)"></td>
                <td
                  class="no-padding-left"
                  colspan="3"
                  (click)="handleRowClick(result)"
                >
                  <ng-container *ngIf="result.isRowExpanded">
                    <div
                      class="query-result-dynamic-prop-dark-font"
                      style="margin-bottom: 0.5rem;"
                    >
                      {{"respond.queries.result.fullLog" | t:{date: result.formattedDate} }}
                    </div>
                    <div *ngFor="let dynamicProp of result.dynamicProps">
                      <span>{{dynamicProp.key}}:&nbsp;</span>
                      <span class="query-result-dynamic-prop-dark-font">{{dynamicProp.value}}</span>
                    </div>
                  </ng-container>
                </td>
              </tr>

              <!-- Complete log -->
              <tr
                class="complete-json-log"
                [class.is-expanded]="result.isRowExpanded"
                *ngIf="result.isRowExpanded"
              >
                <td colspan="4">
                  <json-table
                    *ngIf="!showJson"
                    [jsonString]="result.resultJSON"
                  ></json-table>
                  <json-sorted-view
                    *ngIf="showJson"
                    [jsonString]="result.resultJSON"
                  ></json-sorted-view>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>

</div>





<ng-template #noQueryResultYet>
  <div class="no-query-result-available">
    <p>{{"respond.queries.result.noQueryResult" | t}}</p>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"
      />
    </svg>
  </div>
</ng-template>





<ng-template #executedQueryResultEmptyTemplate>
  <tr>
    <td colspan="4">{{"common.nomatch" | t}}</td>
  </tr>
</ng-template>





<ng-template #loadingSpinner>
  <ui-spinner size="small"></ui-spinner>
</ng-template>