import { color } from '@amcharts/amcharts4/core';
import { Component, Input, OnInit } from '@angular/core';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { ITimeInterval } from 'projects/@common/components/date-interval/date-interval.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { DashboardApi } from 'projects/@common/services/api/respond/dashboard/dashboard.api';
import { IListIncidentsResolutionStatusesResponse } from 'projects/@common/services/api/respond/dashboard/dashboard.definitions';
import { RespondDashboardTimeRangesEnum } from '../../containers/respond-dashboard-container/respond-dashboard-container.component';
import { getTimestampsFromRange } from '../../helpers/getTimestampsFromRange';

@Component({
  selector: 'resolution-statuses',
  templateUrl: './resolution-statuses.component.html',
  styleUrls: [ './resolution-statuses.component.scss' ],
})
export class ResolutionStatusesComponent implements OnInit {
  public readonly TIME_RANGE_ENUM = RespondDashboardTimeRangesEnum;

  private readonly COLOR_RED = '#DE3618';

  private readonly COLOR_ORANGE = '#FF7800';

  private readonly COLOR_GREEN = '#00d873';

  private readonly COLOR_GRAY = '#999';

  public isLoading = false;

  public resolutionStatuses: any[] = [];

  public legendTemplate = '';

  public locale: LanguageEnum;

  public currentTimeRange = RespondDashboardTimeRangesEnum.CURRENT_WEEK;

  public dateRange: ITimeInterval;

  @Input()
  public activeOrganization: IAutomationOrganization;

  @Input()
  public adm: boolean = false;

  constructor(
    private dashboardService: DashboardApi,
    private readonly i18n: I18nService,
    private readonly noticeService: NoticeService
  ) { }

  ngOnInit() {
    this.locale = this.i18n.currentLocale as LanguageEnum;
    this.loadData();
  }

  private loadData(): void {
    this.isLoading = true;

    const { incidentFrom, incidentTo } = getTimestampsFromRange(this.currentTimeRange);

    // We need to remove the offset between the current timezone and UTC
    const offSetFrom = incidentFrom ? new Date(incidentFrom).getTimezoneOffset() * 60000 : 0;
    const offSetTo = incidentTo ? new Date(incidentTo).getTimezoneOffset() * 60000 : 0;

    this.dateRange = { dateFrom: (incidentFrom || Date.now()) - offSetFrom, dateTo: (incidentTo || Date.now()) - offSetTo };

    this.dashboardService
      .listIncidentsResolutionStatuses(this.activeOrganization?.id, { incidentFrom: this.dateRange.dateFrom, incidentTo: this.dateRange.dateTo })
      .then((response: IListIncidentsResolutionStatusesResponse) => {
        this.resolutionStatuses = [
          {
            level: this.i18n.translate("incidents.container.page.filter.status.CLOSED.filter"),
            count: response.closed,
            color: color(this.COLOR_RED),
          },
          {
            level: this.i18n.translate("incidents.container.page.filter.status.CLOSED_EXPECTED.filter"),
            count: response.closedExpected,
            color: color(this.COLOR_ORANGE),
          },
          {
            level: this.i18n.translate("incidents.container.page.filter.status.CLOSED_FALSE_POSITIVE.filter"),
            count: response.closedFalsePositive,
            color: color(this.COLOR_GREEN),
          },
        ].filter((status) => status.count);
      })
      .catch((error) => {
        this.noticeService.notifyUser(new Notice("alerts.container.page.notice.fetch.error", NoticeLevels.ERROR, null, error?.message));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public get urlQueryParams(): object {
    const { incidentFrom, incidentTo } = getTimestampsFromRange(this.currentTimeRange);
    const queryParams = {
      dateFrom: incidentFrom,
      dateTo: incidentTo,
      organizationIds: this.activeOrganization?.id,
    };
    if (this.adm) {
      return queryParams;
    }
    delete queryParams.organizationIds;
    return queryParams;
  }

  public handleTimeRange(range: RespondDashboardTimeRangesEnum): void {
    this.currentTimeRange = range;
    this.loadData();
  }
}
