import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { UiKitModule } from "@ui-kit/uikit.module";
import { AssistOpsModule } from "projects/@assist-ops/assist-ops.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { SharedModule } from '../../shared/shared.module';
import { AdminEngagementLevelTendencyComponent } from './components/admin-engagement-level-tendency/admin-engagement-level-tendency.component';
import { AdminEngagementLevelComponent } from './components/admin-engagement-level/admin-engagement-level.component';
import { AdminResourceCompliantPercentageComponent } from "./components/admin-resource-compliance-percentage/admin-resource-compliant-percentage.component";
import { AdminResourceTendencyComponent } from "./components/admin-resource-tendency/admin-resource-tendency.component";
import { AdminTop10ResourceRowComponent } from "./components/admin-top10-resourceNoCompliant/admin-top10-resource-row/admin-top10-resource-row.component";
import { AdminTop10ResourceNoCompliant } from "./components/admin-top10-resourceNoCompliant/admin-top10-resourceNoCompliant.component";
import { DashboardContainer } from "./components/dashboard.container";
import { SharedFilesTendency } from "./components/shared-files-tendency/shared-files-tendency.component";
import { SharesBySeverityComponent } from './components/shares-by-severity/shares-by-severity.component';
import { DashboardRoutingModule } from "./dashboard.routing.module";
import { IndicatorsTabComponent } from './tabs/indocators-tab/indicators-tab.component';

@NgModule({
  declarations: [
    DashboardContainer,
    SharedFilesTendency,
    AdminResourceCompliantPercentageComponent,
    AdminResourceTendencyComponent,
    AdminTop10ResourceNoCompliant,
    AdminTop10ResourceRowComponent,
    AdminEngagementLevelComponent,
    AdminEngagementLevelTendencyComponent,
    SharesBySeverityComponent,
    IndicatorsTabComponent,
  ],
  imports: [
    AssistOpsModule,
    DashboardRoutingModule,
    I18nModule,
    CommonModule,
    SharedModule,
    UiKitModule,
    FeatureSharedModule,
    AssistOpsModule,
  ],
})
export class DashboardModule { }
