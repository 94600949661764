<ui-card [class.no-padding]="false">
  <ui-static-table
    *ngIf="!isLoading && reports; else loadingSpinner"
    [dataSource]="reports"
    [columnsDef]="columnsDef"
    [loading]="isLoading"
    [isCustomSorting]="true"
    [sort]="sortData.orderBy"
    [direction]="sortData.direction"
    [locale]="i18n.currentLocale"
    [canSelectOnlyOne]="false"
    [isSelectingOnlyOneAtATime]="false"
    [addPaddingOnFirst]="false"
    [hoverable]="true"
    (handleCustomSort)="handleSort($event)"
    (on-row-click)="onClickRow($event)"
    (checkbox-event)="handleCheckboxEvent($event.event, $event.item)"
  >
  </ui-static-table>

  <ng-template #loadingSpinner>
    <ui-spinner size="medium"></ui-spinner>
  </ng-template>
</ui-card>