import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DisplayService } from '@common/modules/display/display.service';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { UserTypeEnum } from '@ui-kit/components/autocomplete/user-autocomplete/user-autocomplete.component';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { EditWithModalComponent } from 'projects/@common/components/edit-with-modal/edit-with-modal.component';
import { User } from 'projects/@common/definitions/user.definition';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import {
  IdentityProviderTypes,
  List,
  ListUsersResponse
} from 'projects/@common/services/api/iam/identities/identities.api.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { UsersApiService } from 'projects/@common/services/api/sg/user/userApi.service';

export interface IApproversTableRow {
  id: string;
  displayName?: string;
  mail?: string;
  user?: { o365UserId: string; }[];
  group?: { o365GroupId: string; }[];
  attribute?: string;
}

@Component({
  selector: 'approvers-table',
  templateUrl: './approvers-table.component.html',
  styleUrls: [ './approvers-table.component.scss' ],
})
export class ApproversTableComponent implements OnInit {
  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;
  public isLoading = false;
  public direction = 'asc';
  public usersSelected: string[] = [];
  public approverToAdd: IApproversTableRow;
  public fulltext: string = null;
  public usersAutocomplete = AutocompleteTypes.USERS;
  public total: number;
  public dataSource: IApproversTableRow[] = [];
  public attribute = null;
  public columnsDef: any[];
  private readonly SELECTED_TAG = '__Selected';

  constructor(
    public i18n: I18nService,
    private displayService: DisplayService,
    private notice: NoticeService,
    private usersApi: UsersApiService,
    private staticTableDataMapper: StaticTableDataMapper,
    private eRef: ElementRef,
    private organizationApiService: OrganizationsApiService
  ) { }

  @HostListener('document:click', [ '$event' ])
  onOutsideClick(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closePopup();
    }
  }

  ngOnInit(): void {
    this.organizationApiService.getOrganizationSettings().then((settings) => {
      this.setupColumnsDef(settings);
      this.getResourceApprovers();
    });
  }

  public handleTemplateSelection(isChecked: boolean, o365UserId: string): void {
    if (isChecked) {
      this.usersSelected.push(o365UserId);
    } else {
      this.usersSelected = this.usersSelected.filter((user) => user !== o365UserId);
    }
  }

  public openPopup(): void {
    this.editWithModalComponent.toggle();
  }

  public closePopup(): void {
    this.editWithModalComponent.close();
  }

  public onItemSelected(approver: User): void {
    this.approverToAdd = {
      id: approver.o365UserId,
      displayName: approver.firstName,
      mail: approver.mail,
      user: approver.type !== UserTypeEnum.SECURITY_GROUP ? [ { o365UserId: approver.o365UserId } ] : null,
      group: approver.type === UserTypeEnum.SECURITY_GROUP ? [ { o365GroupId: approver.o365UserId } ] : null,
    };
  }

  public removeApprovers(): void {
    this.usersApi
      .removeResourceApprovers({
        approversToRemoveIds: this.usersSelected,
      })
      .then(() => {
        this.getResourceApprovers();
        this.notice.notifyUser({
          message: `securityModel.approversTable.removeUser`,
          level: NoticeLevels.SUCCESS,
        });
        this.usersSelected = [];
      })
      .catch();
  }

  public usersAutocompleteSearchFunction = (params: any): Promise<List<ListUsersResponse, number | string, { idpType: IdentityProviderTypes; }>> => this.usersApi.listUsersCreateGroup({
    limit: 25,
    fulltext: params.search,
  });

  public addApprover(): void {
    this.usersApi
      .postResourceApprover({
        groupId: this.approverToAdd.group ? this.approverToAdd.group[0].o365GroupId : null,
        userId: this.approverToAdd.user ? this.approverToAdd.user[0].o365UserId : null,
      })
      .then(() => {
        this.getResourceApprovers();
        this.notice.notifyUser({
          message: `securityModel.approversTable.${this.approverToAdd.group ? 'addGroup' : 'addUser'}`,
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch()
      .finally(() => {
        this.closePopup();
      });
  }

  public handleRefresh(): void {
    this.getResourceApprovers();
  }

  public handleSearchQuery(fulltext: string): void {
    this.fulltext = fulltext;
    this.handleRefresh();
  }

  public handleSearchClear(): void {
    this.fulltext = null;
    this.handleRefresh();
  }

  private getResourceApprovers(): void {
    this.isLoading = true;
    this.dataSource = [];
    this.usersApi
      .listResourceApprovers({ limit: 999, fulltext: this.fulltext })
      .then((result) => {
        this.dataSource = this.toApproversTableRow(result.items);
        this.total = result.items.length || 0;
        this.translateTable();
      })
      .catch()
      .finally(() => {
        this.isLoading = false;
      });
  }

  private translateTable(): void {
    [ this.columnsDef, this.dataSource ] = this.staticTableDataMapper.translate(this.columnsDef, this.dataSource);
  }

  private setupColumnsDef(settings: any): void {
    this.attribute = settings.segmentationAttribute?.replace(/([A-Z])/g, ' $1'); // accountEnabled => account Enabled
    this.columnsDef = [
      {
        label: '',
        field: 'user',
        type: UiStaticTableRowType.AVATARS,
        withUser: true,
        width: '60px',
      },
      {
        label: 'securityModel.approversTable.column.name',
        field: 'userName',
        type: UiStaticTableRowType.TEXT,
        width: this.attribute ? '150px' : null,
      },
      {
        label: 'securityModel.approversTable.column.email',
        field: 'mail',
        type: UiStaticTableRowType.TEXT,
        isTruncated: true,
        width: this.attribute ? '50%' : '70%',
      },
    ];
    if (this.attribute) {
      this.columnsDef.push({
        label: this.attribute,
        field: 'attribute',
        type: UiStaticTableRowType.TEXT,
        isTruncated: true,
        noSorting: true,
      });
    }

    const displayCheckBox = this.displayService.meetsRequirements({ permissions: [ 'can_update_approvers' ] });
    if (displayCheckBox) {
      this.columnsDef.unshift({
        label: '',
        field: 'selected',
        selected: this.SELECTED_TAG,
        type: UiStaticTableRowType.CHECKBOX,
        width: '35px',
        isResizable: false,
      });
    }
    this.translateTable();
  }

  private toApproversTableRow(result: any): IApproversTableRow[] {
    return result.map((approver) => ({
      userName: approver.displayName,
      mail: approver.mail,
      user: [ { o365UserId: approver.id, type: approver.type } ],
      id: approver.id,
      attribute: approver.attribute,
    }));
  }
}
