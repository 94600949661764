<ui-static-table
  [dataSource]="similarityTableData"
  [columnsDef]="similarityColumnsDef"
  [loading]="isLoading"
  [locale]="i18n.currentLocale"
  [canSelectOnlyOne]="true"
  [isSelectingOnlyOneAtATime]="true"
  [addPaddingOnFirst]="false"
  [hoverable]="true"
  [addPaddingOnFirst]="false"
  [expendedTemplate]="expandedRowTemplate"
  [emitClickEventOnExpandedRow]="true"
  (on-row-click)="handleRowClick($event)"
>
</ui-static-table>

<!-- Expanded Component -->
<ng-template
  #expandedRowTemplate
  let-similarityItem="row"
  let-isExpanded="isSelect"
>
  <div
    *ngIf="isExpanded"
    class="similarityItem-row-template-container"
  >
    <ng-container *ngIf="isAlertItem(similarityItem)">
      <ui-field-group
        *ngIf="!!similarityItem?.alert; else loadingSpinner"
        [layout]="'vertical'"
        [hasMarginBottom]="false"
      >
        <strong class="expand-title">
          {{'respond.similarity.expanded-row.title' | t:{itemType: similarityItem.type, score: similarityItem.score} }}
        </strong>
        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <short-id
            class="width-100"
            [shortId]="similarityItem.alert.shortId"
            [label]="'alert.detail.Id.label' | t"
            [linkURL]="similarityItem.linkURL"
            [linkQueryParams]="similarityItem.linkQueryParams"
            [linkOpenExternal]="true"
          ></short-id>

          <ui-static
            [label]="'alert.detail.name.label' | t"
            [value]="similarityItem.alert.name | translatedObject"
          ></ui-static>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <div class="flex-box column space-between width-100">
            <label ui-label>{{'incidents.container.page.details.tab.detail.summary.priority' | t}}</label>
            <alerting-badge [type]="similarityItem.alert.priority"></alerting-badge>
          </div>
          <div class="flex-box column space-between width-100">
            <label ui-label>{{'incidents.container.page.details.tab.detail.summary.status' | t}}</label>
            <app-alert-status [alert]="similarityItem.alert"></app-alert-status>
          </div>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <ui-static
            [label]="'common.organization' | t"
            [value]="similarityItem.alert.organizationName"
          ></ui-static>

          <ui-static
            [label]="'common.date.createdAt.label' | t"
            [value]="similarityItem.alert.createdAt | dt:{withTime:true, withSeconds:true, locale:i18n.currentLocale}"
          ></ui-static>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <short-id
            class="width-100"
            [shortId]="similarityItem.alert.templateId"
            [label]="'alert.detail.usecaseShortId.label' | t"
          ></short-id>

          <ui-static
            [label]="'alert.detail.connector.label' | t"
            [value]="similarityItem.alert.connector || '-'"
          >
          </ui-static>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <ui-static
            [label]="'common.description' | t"
            [value]="similarityItem.alert.description | translatedObject"
          ></ui-static>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="false"
        >
          <div class="flex-box column space-between width-100">
            <ui-static
              [label]="'respond.similarity.expanded-row.observables' | t:{similar:similarityItem?.observables?.similarCount || 0, total:similarityItem?.observables?.totalCount || 0}"
              [value]="similarityItem?.observables?.similarTypes || '-'"
            ></ui-static>
            <observable-container
              *ngIf="similarityItem?.observables?.config; else loadingSpinner;"
              [pageContext]="similarityItem.observables.config"
            ></observable-container>
          </div>
        </ui-field-group>
      </ui-field-group>
    </ng-container>

    <ng-container *ngIf="isIncidentItem(similarityItem)">
      <ui-field-group
        *ngIf="!!similarityItem?.incident; else loadingSpinner"
        [layout]="'vertical'"
        [hasMarginBottom]="false"
      >
        <strong class="expand-title">
          {{'respond.similarity.expanded-row.title' | t:{itemType: similarityItem.type, score: similarityItem.score} }}
        </strong>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <short-id
            class="width-100"
            [shortId]="similarityItem.incident.shortId"
            [label]="'incidents.container.page.details.tab.detail.summary.id' | t"
            [linkURL]="similarityItem.linkURL"
            [linkQueryParams]="similarityItem.linkQueryParams"
            [linkOpenExternal]="true"
          ></short-id>

          <ui-static
            [label]="'alerts.container.page.modal.incident.name' | t"
            [value]="similarityItem.incident.name"
          ></ui-static>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <div class="flex-box column space-between width-100">
            <label ui-label>{{'incidents.container.page.details.tab.detail.summary.priority' | t}}</label>
            <alerting-badge [type]="similarityItem.incident.priority"></alerting-badge>
          </div>
          <div class="flex-box column space-between width-100">
            <label ui-label>{{'incidents.container.page.details.tab.detail.summary.status' | t}}</label>
            <app-incident-status
              [wrapper]="false"
              [data]="similarityItem.incident"
              [context]="'details'"
            ></app-incident-status>
          </div>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <ui-static
            [label]="'common.organization' | t"
            [value]="similarityItem.incident.organizationName"
          ></ui-static>

          <ui-static
            [label]="'common.date.createdAt.label' | t"
            [value]="similarityItem.incident.createdAt | dt:{withTime:true,withSeconds:true,locale:i18n.currentLocale}"
          ></ui-static>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <div class="flex-box column space-between width-100">
            <label
              ui-label
              [required]="false"
            >{{'incidents.container.page.details.tab.detail.summary.severity' | t}}</label>
            <severity-badge
              class="severity-badge"
              [noMargin]="true"
              [data]="{severity: similarityItem.incident.severity}"
            ></severity-badge>
          </div>

          <ui-static
            [label]="'respond.similarity.expanded-row.confidentiality' | t"
            [value]="similarityItem.incident.breachOfConfidentiality ? ('common.boolean.true' | t) : ('common.boolean.false' | t)"
          ></ui-static>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <ui-static
            [label]="'incidents.container.page.details.tab.detail.affectedResources.title' | t"
            [value]="similarityItem.incident.affectedResources || '-'"
          ></ui-static>
        </ui-field-group>

        <ui-field-group
          *ngIf="similarityItem.incident.justification"
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <ui-static
            [label]="'incidents.container.page.details.tab.detail.closeJustification' | t"
            [value]="similarityItem.incident.justification || '-'"
          ></ui-static>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="true"
        >
          <ui-static
            [label]="'incidents.container.page.details.tab.detail.summary.summary' | t"
            [value]="similarityItem.incident.summary || '-'"
          ></ui-static>
        </ui-field-group>

        <ui-field-group
          [layout]="'inline'"
          [hasMarginBottom]="false"
        >
          <div class="flex-box column space-between width-100">
            <ui-static
              [label]="'respond.similarity.expanded-row.observables' | t:{similar:similarityItem?.observables?.similarCount || 0, total:similarityItem?.observables?.totalCount || 0}"
              [value]="similarityItem?.observables?.similarTypes || '-'"
            ></ui-static>
            <observable-container
              *ngIf="similarityItem?.observables?.config; else loadingSpinner;"
              [pageContext]="similarityItem.observables.config"
            ></observable-container>
          </div>
        </ui-field-group>
      </ui-field-group>
    </ng-container>

  </div>
</ng-template>

<ng-template #loadingSpinner>
  <ui-spinner size="medium"></ui-spinner>
</ng-template>