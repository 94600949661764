<div class="top-layer" *ngIf="showOptions" (click)="toggleOptions($event);"></div>
<div
  class="ui-select ui-select-margins"
  [ngClass]="{'pointer': !disabled,'default-cursor': disabled}"
  [ngStyle]="{backgroundColor: disabled ? 'transparent' : 'white'}"
>
  <label
    ui-label
    *ngIf="label"
    [required]="required"
  >{{ label }}</label>
  <div
    class="ui-select--input-container"
    [ngClass]="{'border' : !disabled, 'no-border': disabled}"
    (click)="toggleOptions($event); $event.stopPropagation()"
  >
    <div class="image-wrapper">
      <img
        *ngIf="selectedValue?.icon"
        [src]="'assets/favicons/' + selectedValue?.icon + '.svg'"
        [alt]="selectedValue?.icon"
      >
    </div>

    <input
      ui-input
      [ngClass]="{'pointer': !disabled,'default-cursor': disabled}"
      [class.disabled]="disabled"
      [class.pointer]="disabled"
      [value]="selectedValue?.viewValue"
      autocomplete="off"
      [placeholder]="placeholder"
      [readonly]="readonly"
      (keydown)="(false)"
    />

    <ui-icon
      [class.invisible]="disabled"
      [name]="showOptions ? 'chevron-up' : 'chevron-down'"
      modifier="list-action"
      size="medium"
    ></ui-icon>
  </div>

  <div
    *ngIf="showOptions"
    class="ui-select--options-container"
  >
    <ng-container *ngFor="let option of tempOptions; let i = index">
      <div class="icon-text-wrapper">
        <div
          (click)="onOptionClick(option); $event.stopPropagation()"
          class="option"
          [id]="'option-' + i"
        >
          <div class="image-wrapper">
            <img
              *ngIf="option?.icon"
              [src]="'assets/favicons/' + option?.icon + '.svg'"
              [alt]="option?.icon"
            >
          </div>
          <p>{{ option.viewValue }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>