  <ui-card>
    <div class="flex-box space-between wrap margin--bottom">
      <div class="flex-box center-horizontal guest-users--button-space">
          <button
            ui-button
            color="linkFlat"
            class="margin--small--bottom"
            icon="icon_download_report"
            [custom-icon]="true"
            [isSvg]="true"
            icon-size="medium"
            [loading]="isLoading"
            [disabled]="!orphanGuestUsers?.length"
            (click)="getDataForReport()"

          >
            {{ 'guest.users.table.download.report' | t }}
          </button>
      </div>

      <div class="flex-box center-horizontal guest-users--button-space">
        <ui-table-total-tool
          *ngIf="!isLoading"
          [total]="total"
        ></ui-table-total-tool>
        <ui-table-refresh-tool
          [loading]="isLoading"
          (click)="handleRefresh()"
        ></ui-table-refresh-tool>
      </div>
    </div>

    <ui-static-table
      [loading]="isLoading"
      [sort]="sort"
      [direction]="direction"
      [dataSource]="orphanGuestUsers"
      [columnsDef]="columnsDefTranslated"
      [isCustomSorting]="true"
      [addPaddingOnFirst]="false"
      (handleCustomSort)="handleSorting($event.sortColumn, $event.sortDirection)"
      [locale]="i18n.currentLocale"
    ></ui-static-table>
  </ui-card>


