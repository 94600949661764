<base-dialog
  [title]="'securityModel.archiving.popup.title' | t"
  [hasStepper]="false"
  (onCancel)="close()"
  >
  <div class="dialog-content">
    <p>{{ 'securityModel.archiving.popup.text1' | t }}</p>
    <p>{{ 'securityModel.archiving.popup.text2' | t }}</p>
    <div class="buttons-container">
      <button
        ui-button
        color="standard"
        (click)="save()"
      >
        {{ 'securityModel.archiving.popup.confirmationButton' | t }}
      </button>
      <button
        ui-button
        color="secondary"
        (click)="close()"
      >
        {{ "common.action.cancel" | t }}
      </button>
    </div>
  </div>


</base-dialog>