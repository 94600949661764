<div class="card-content">
  <settings-edition-card
    [icon]="'icon_notification_active_cropped.svg'"
    [title]="'settings.devices.compliance.notification.title' | t"
    (saveEvent)="handleSave()"
    [canSave]="true"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <p class="description">
      {{ 'settings.devices.compliance.notification.description' | t }}
    </p>
    <p
      class="attribute"
      [innerHTML]="'settings.devices.compliance.notification.' + (isActivated ? 'activated' : 'deactivated') | t"
    ></p>
  </div>
</ng-template>

<ng-template #editing>
  <p class="description">{{ 'settings.devices.compliance.notification.description' | t }}</p>
  <div
    class="autocomplete"
    *ngIf="!isLoading; else loading"
  >
    <ui-toggle
      [toggled]="isActivated"
      [labelEnabled]="'settings.notification.silencing.toggle.activated' | t"
      [labelDisabled]="'settings.notification.silencing.toggle.deactivated' | t"
      (onClick)="toggleActivation()"
    ></ui-toggle>
  </div>
</ng-template>
