import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { EditWithModalComponent } from '@common/components/edit-with-modal/edit-with-modal.component';
import { StaticTableDataMapper } from '@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from '@common/modules/notice/notice.service';
import { OrganizationsApiService } from '@common/services/api/sg/organizations/organizationsApi.service';
import { GuestTemporaryDataService } from '@common/services/api/sg/temporary-data/guest-temporary-data.service';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { Autocomplete, AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { UserTypeEnum } from '@ui-kit/components/autocomplete/user-autocomplete/user-autocomplete.component';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { GuestManagerFilter } from '../../enums/guestManagerFilter.enum';

@Component({
  selector: 'guest-managers',
  templateUrl: './guest-managers.container.html',
  styleUrls: ['./guest-managers.container.scss'],
})
export class GuestManagersComponent implements OnInit {
  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;
  @ViewChild('userAutocomplete') userAutocomplete: Autocomplete;

  @Input() events: Observable<void>;

  public usersAutocomplete = AutocompleteTypes.USERS;
  public eventsSubscrption: Subscription;

  private readonly SELECTED_TAG = '__Selected';

  private readonly LIMIT = 25;
  public managers: any[] = [];
  public selectedManagerO365Id: string;
  public isLoading = false;
  public fulltext: string = null;
  public total: number;
  public templateStatesFilter: any;
  public userFilter = GuestManagerFilter.AVAILABLE;
  public direction = 'asc';
  public sort = 'displayName';
  public newGuestManagers: any[] = [];
  public guestManagerToAdd: any;
  public isButtonLoading = false;
  public isDeleting = false;
  private from: string = null;
  private payload = { o365SecurityGroupId: null, o365UserId: null };
  public columnsDef;
  public columsDefTranslated: any;

  public attribute: string;

  constructor(
    private eRef: ElementRef,
    private readonly usersApi: UsersApiService,
    private readonly notice: NoticeService,
    private staticTableDataMapper: StaticTableDataMapper,
    private temporaryDataService: GuestTemporaryDataService,
    public i18n: I18nService,
    private organizationApiService: OrganizationsApiService
  ) { }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKey(event: KeyboardEvent): void {
    this.close();
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  public close(): void {
    this.userAutocomplete.clearSearchQuery();
    this.newGuestManagers = [];
    this.guestManagerToAdd = null;
    this.editWithModalComponent.close();
  }

  public ngOnInit(): void {
    this.organizationApiService.getOrganizationSettings().then((settings) => {
      this.attribute = settings.segmentationAttribute?.replace(/([A-Z])/g, ' $1');
      this.columnsDef = [
        {
          label: '',
          field: 'selected',
          selected: this.SELECTED_TAG,
          type: UiStaticTableRowType.CHECKBOX,
          width: '50px',
          isResizable: false,
        },
        {
          label: '',
          field: 'avatar',
          type: UiStaticTableRowType.AVATAR,
          withUser: true,
          width: '40px',
          noSorting: true,
          isResizable: false,
        },
        {
          label: 'groupTemplates.table.name',
          field: 'displayName',
          type: UiStaticTableRowType.TEXT,
          width: '200px',
        },
        {
          label: 'email',
          field: 'mail',
          type: UiStaticTableRowType.TEXT,
          noSorting: true,
          isTruncated: true,
        },
      ];
      if (this.attribute) {
        this.columnsDef.push({
          label: this.attribute,
          field: 'attribute',
          type: UiStaticTableRowType.TEXT,
          isTruncated: true,
          noSorting: true,
        });
      }
    });
    this.handleRefresh();
    this.eventsSubscrption = this.events.subscribe(() => this.onScrollToBottom());
  }

  private loadGuestManagers(): void {
    this.isLoading = true;
    this.usersApi
      .listGuestManagers({
        direction: this.direction,
        fulltext: this.fulltext,
        sort: this.sort,
        limit: this.LIMIT,
        from: this.from,
        filter: GuestManagerFilter.CURRENT_GUEST_MANAGERS,
      })
      .then((managers) => {
        this.managers = managers.items;
        this.managers = this.temporaryDataService.getStoredGuestManagers(this.managers);

        this.managers = this.managerMapper(this.managers);
        this.from = managers.nextToken;
        this.total = this.managers.length || 0;
      })
      .catch(() => {
        this.notice.notifyUser({ message: 'guestManagers.confirmation.notification.error', level: NoticeLevels.ERROR });
      })
      .finally(() => {
        this.isLoading = false;
        [this.columsDefTranslated, this.managers] = this.staticTableDataMapper.translate(
          this.columnsDef,
          this.managers
        );
      });
  }

  public handleRefresh(): void {
    this.from = '';
    this.selectedManagerO365Id = null;
    this.managers = [];
    this.loadGuestManagers();
  }

  public handleSearchQuery(fulltext: string): void {
    this.fulltext = fulltext;
    this.handleRefresh();
  }

  public handleSearchClear(): void {
    this.fulltext = null;
    this.handleRefresh();
  }

  public handleSorting(sort: string, direction: string): void {
    this.sort = sort;
    this.direction = direction;
    this.handleRefresh();
  }

  public handleTemplateSelection(isChecked: boolean, manager: any): void {
    if (isChecked) {
      this.selectedManagerO365Id = manager.id || manager.o365UserId;
    } else {
      this.selectedManagerO365Id = null;
    }
  }

  public onItemSelected(manager: any): void {
    this.guestManagerToAdd = manager;
  }

  public addGuestUserManager(): void {
    this.payload = { o365SecurityGroupId: null, o365UserId: null };
    this.payload[this.guestManagerToAdd.type === UserTypeEnum.SECURITY_GROUP ? 'o365SecurityGroupId' : 'o365UserId'] =
      this.guestManagerToAdd.o365UserId;

    this.usersApi
      .addGuestManager(this.payload)
      .then((response) => {
        this.temporaryDataService.addGuestManager({
          displayName: this.guestManagerToAdd.firstName,
          firstName: this.guestManagerToAdd.firstName.split(' ')[0],
          lastName: this.guestManagerToAdd.firstName.split(' ')[1],
          email: this.guestManagerToAdd.mail,
          o365UserId: this.guestManagerToAdd.o365UserId,
          date: Date.now(),
        });
        this.notice.notifyUser({ message: 'guestManagers.confirmation.notification.add', level: NoticeLevels.SUCCESS });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.notice.notifyUser({
            message: 'guestManagers.error.notification.add.missing.role',
            level: NoticeLevels.ERROR,
          });
        } else {
          this.notice.notifyUser({ message: 'guestManagers.error.notification.add', level: NoticeLevels.ERROR });
        }
      })
      .finally(() => {
        this.close();
        this.handleRefresh();
      });
  }

  public onScrollToBottom(): void {
    if (!this.isLoading && this.from?.length > 0) {
      this.loadGuestManagers();
    }
  }

  public deleteManager(): void {
    if (!this.isDeleting) {
      this.isDeleting = true;
      this.usersApi
        .removeGuestManager(this.selectedManagerO365Id)
        .then((response) => {
          this.isDeleting = false;
          this.temporaryDataService.addGuestManager({
            o365UserId: this.selectedManagerO365Id,
            toHide: true,
            date: Date.now(),
          });
          this.notice.notifyUser({
            message: 'guestManagers.confirmation.notification.remove',
            level: NoticeLevels.SUCCESS,
          });
        })
        .catch(() => this.notice.notifyUser({ message: 'guestManagers.error.notification.remove', level: NoticeLevels.ERROR }))
        .finally(() => {
          this.isDeleting = false;
          this.handleRefresh();
        });
    }
  }

  public usersAutocompleteSearchFunction = (params: any): Promise<any> => {
    return this.usersApi.listGuestManagers({ filter: params.filter, limit: 25, fulltext: params.search });
  };

  private managerMapper(managers: any[]): any[] {
    return managers.map((manager) => ({
      ...manager,
      mail: manager.mail || '-',
      avatar: { firstName: manager.givenName || manager.displayName, lastName: manager.surname, type: manager.type },
    }));
  }

  public removeGuestManagerLocal(guestManager: any): void {
    this.newGuestManagers = this.newGuestManagers.filter((item) => item.o365UserId !== guestManager.o365UserId);
  }

  public toggle(): void {
    this.editWithModalComponent.toggle();
  }

  public onScrollSubject: Subject<void> = new Subject<void>();
}
