import { Component, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { UiTabs } from "@ui-kit/components/ui-tabs/ui-tabs.component";
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { AbstractDrawer } from "projects/@common/modules/layout/components/drawer/AbstractDrawer";
import { DrawerState } from "projects/@common/modules/layout/components/drawer/stores/drawer.store";
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { ActifsApi } from "projects/@common/services/api/respond/actifs/actifs.api";
import { IAssetPropertyConfigRepresentation, IDescribeAssetResponse, IListAssetType } from "projects/@common/services/api/respond/actifs/actifs.definitions";
import { AlertsApi } from 'projects/@common/services/api/respond/alerts/alerts.api';
import { Alert } from 'projects/@common/services/api/respond/alerts/models/alert';
import { FindingsApi } from 'projects/@common/services/api/respond/finding/finding.api';
import { HistoriesApi } from 'projects/@common/services/api/respond/histories/histories.api';
import { MobileService } from "projects/@common/services/mobile.service";
import { getHttpErrorMessage } from 'projects/@common/utils/utils';
import { AlertFindingRequest } from 'projects/console-adm/src/app/respond/alerts/containers/alert-drawer/sections/alert-findings/alert-finding.request';
import { Observable } from "rxjs";
import { ActifsService } from '../../actifs.service';
import { AlertHistoryRequests } from '../actifs-history/actifs-history.requests';

@Component({
  selector: 'app-actif-drawer',
  templateUrl: './actif-drawer.component.html',
  styleUrls: [ './actif-drawer.component.scss' ],
})
export class ActifDrawerComponent extends AbstractDrawer implements OnChanges, OnInit {
  @Input() public data: {
    title: string;
    organizationId: string;
    assetId: string;
    types: IAssetPropertyConfigRepresentation[],
    isReadonly: boolean;
  } = null;

  @Select(DrawerState.isEditing) public isEditing$: Observable<boolean>;

  @Select(DrawerState.tabName) public tabName$: Observable<string>;

  @ViewChild('tabs', { static: true }) private tabs: UiTabs;

  public assetTypes: IListAssetType[];

  public currentAsset: IDescribeAssetResponse;

  public isLoading = true;

  public alert: Alert;

  public alertHistoryRequests: AlertHistoryRequests;

  public alertFindingRequests: AlertFindingRequest;

  constructor (
    protected readonly store: Store,
    private readonly actifsApi: ActifsApi,
    private readonly noticeService: NoticeService,
    public readonly mobileService: MobileService,
    private readonly actifsService: ActifsService,
    private readonly historiesApi: HistoriesApi,
    private readonly i18nService: I18nService,
    private readonly alertApi: AlertsApi,
    private readonly findingApi: FindingsApi
  ) {
    super(store);
  }

  ngOnInit(): void {
    if (this.data.assetId) {
      this.fetchCurrentAsset();
    } else {
      this.isLoading = false;
    }

    this.fetchAssetsTypes();
  }

  private fetchAssetsTypes(): void {
    this.assetTypes = this.data.types;
  }

  public fetchCurrentAsset(): void {
    this.actifsApi.describeAsset({ assetId: this.data.assetId, organizationId: this.data.organizationId }).subscribe(
      (response) => {
        this.currentAsset = response;
      },
      (error) => {
        this.noticeService.notifyUser(new Notice("actifs.fetch.assets.error", NoticeLevels.ERROR, getHttpErrorMessage(error)));
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  public ngOnChanges() {
    super.ngOnChanges();
  }

  public setState(state: any): void {
    this.tabs.selectTabIndex(state.tabIndex);
  }

  public getState(): any {
    return {
      tabIndex: this.tabs.getSelectedTabIndex(),
    };
  }
}
