import { Component, Input, OnInit } from '@angular/core';
import { ChartTypeEnum, TendencyTimeRangesEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { RegressionUtil } from 'projects/@common/utils/regression-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IAxisFormattingSettings } from '@ui-kit/components/dynamic-chart/dynamic-chart.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { TimeUnit } from '@amcharts/amcharts4/core';
import { TendencyGraphFormatterService } from '../tendencyGraphFormatter.service';

@Component({
  selector: 'shared-files-tendency',
  templateUrl: './shared-files-tendency.component.html',
  styleUrls: [ './shared-files-tendency.component.scss' ],
})

export class SharedFilesTendency implements OnInit {
  public readonly TIME_RANGE_ENUM = TendencyTimeRangesEnum;

  public readonly KEY = 'dailyTimestamp';

  public readonly SHARES_LINE = 'shares';

  public readonly REGRESSION_LINE = 'regression';

  public readonly CHART_TYPE = ChartTypeEnum.LINE;

  @Input() public isHighRiskOnly = false;

  public isLoading = false;

  public currentTimeRange = TendencyTimeRangesEnum.LAST_7_DAYS;

  public colors: {
    shares: string;
    regression: string;
  };

  public legend: {
    shares: string;
    regression: string;
  };

  public axisFormattingSettings: IAxisFormattingSettings;

  public data: {
    dayAxis: number;
    shares: number;
    regression?: number;
  }[];

  public get translationSuffix(): string {
    return this.isHighRiskOnly ? 'high' : 'total';
  }

  public locale: LanguageEnum;

  constructor (private readonly sharedFilesService: SharesApiService, private readonly i18n: I18nService) { }

  public ngOnInit(): void {
    this.locale = this.i18n.currentLocale as LanguageEnum;
    this.colors = {
      shares: this.isHighRiskOnly ? '#ea5455' : '#52c5c3',
      regression: '#9575CD',
    };
    this.legend = {
      shares: this.i18n.translate(`dashboard.admin.sharedFiles.tendency.legend.shares.${this.translationSuffix}`),
      regression: this.i18n.translate('dashboard.admin.sharedFiles.tendency.legend.regression'),
    };
    this.setAxisFormattingSettings();
    this.loadData();
  }

  private setAxisFormattingSettings(): void {
    this.axisFormattingSettings = TendencyGraphFormatterService.axisFormattingSettings(this.currentTimeRange);
  }

  private loadData(): void {
    this.isLoading = true;
    this.sharedFilesService
      .getSharedFilesTendency({ gte: this.currentTimeRange })
      .then((tendency) => {
        const mappedData = [];
        for (const t of tendency) {
          mappedData.push({
            dailyTimestamp: t.dailyTimestamp,
            shares: this.isHighRiskOnly ? t.highCount : t.totalCount,
          });
        }
        this.data = TendencyGraphFormatterService.addRegressionLineParamToDataSource(mappedData, this.SHARES_LINE);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public handleTimeRange(range: TendencyTimeRangesEnum): void {
    this.currentTimeRange = range;
    this.setAxisFormattingSettings();
    this.loadData();
  }
}
