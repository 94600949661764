<form
  *ngIf="!isReadonly; else readonlyTemplate"
  class="time-picker-form"
  [formGroup]="form"
>
  <ui-select
    class="time-value"
    name="timeSelectControl"
    control="timeSelectControl"
    [group]="form"
    [label]=""
    [disabled]="false"
    [required]="true"
    [options]="timeSelectOptions"
    (optionClick)="handleChange()"
  >
  </ui-select>
</form>

<ng-template #readonlyTemplate>
  {{getTranslation(timeChoice)}}
</ng-template>