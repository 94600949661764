<ng-container *ngIf="!isLoading">
  <dlp-activation #activation [currentSettings]="currentSettings" [isLoading]="isLoading"></dlp-activation>
  <ng-container *ngIf="currentSettings?.dlpType">
    <dlp-justification-delay [currentSettings]="currentSettings" [isLoading]="isLoading"></dlp-justification-delay>
    <dlp-frequency [currentSettings]="currentSettings" [isLoading]="isLoading"></dlp-frequency>
  </ng-container>

</ng-container>

<ng-container *ngIf="isLoading">
  <ui-spinner size="medium"></ui-spinner>
</ng-container>
