<ui-title
  class="title"
  [name]="'respond.entities.menu.launchSOAR' | t"
>
</ui-title>

<div class="content">
  <ng-container *ngIf="!isLoading; else spinner">
    <p>{{ 'respond.entities.modal.incidentEntityActions.description' | t }}</p>
    <div *ngFor="let action of actions">
      <ui-checkbox
        [label]="action.name[locale]"
        [isEditable]="true"
        (onCheck)="onActionCheck($event, action)"
      ></ui-checkbox>
    </div>
  </ng-container>

  <ng-template #spinner>
    <div class="spinner">
      <ui-spinner size="medium"></ui-spinner>
    </div>
  </ng-template>

  <div class="buttons">
    <button
      ui-button
      color="standard"
      [disabled]="disabled"
      (click)="handleConfirm()"
    >
      {{ 'respond.entities.modal.incidentEntityActions.button.launch' | t }}
    </button>

    <button
      ui-button
      (click)="handleCancel()"
    >
      {{'common.action.cancel' | t}}
    </button>
  </div>

</div>
