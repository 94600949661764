import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filters-hidden-column-indicator',
  templateUrl: './filters-hidden-column-indicator.component.html',
  styleUrls: ['./filters-hidden-column-indicator.component.scss']
})
export class FiltersHiddenColumnIndicatorComponent {
  public readonly hiddenFilterIcon = '/assets/favicons/icon_eye_off.svg';
  
  @Input() public filter;
}
