import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { EditWithModalComponent } from "projects/@common/components/edit-with-modal/edit-with-modal.component";

@Component({
  selector: 'app-severity-picker',
  templateUrl: './severity-picker.component.html',
  styleUrls: [ './severity-picker.component.scss' ],
})
export class SeverityPickerComponent implements OnInit {
  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;

  public severitiesList = [ 1, 2, 3, 4, 5 ];

  @Input() value: number;
  @Input() disabled: boolean = false;
  @Input() styleLikeInputField: boolean = false;

  @Input() readonly = false;

  @Output() choiceEvent: EventEmitter<number> = new EventEmitter();

  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
  }

  @HostListener('document:click', [ '$event' ])
  clickOut(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.editWithModalComponent?.close();
    }
  }

  public open(): void {
    if (!this.disabled) {
      this.editWithModalComponent.open();
    }
  }

  public select(value: number): void {
    if (!this.readonly) {
      this.choiceEvent.emit(value);
      this.editWithModalComponent.close();
    }
  }
}
