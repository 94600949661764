<label
  ui-label
  class="timestamp-label ui-label_disabled"
>
  {{labelTranslationKey | t}}
</label>

<span
  class="date-string"
  *ngIf="!editable; else datetimePickerTemplate"
>
  {{currentTimestamp ? (currentTimestamp | dt: {locale: i18nService.currentLocale, withTime: true}) : "-"}}
</span>
<ng-template #datetimePickerTemplate>
  <ui-datetime-picker
    #datetimePicker
    [defaultTimestamp]="prevTimestamp"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [isDisabled]="!isEditing"
    [showNowButton]="true"
    [showResetButton]="false"
    (timestampChangedEvent)="handleTimestampChangeEvent($event)"
  ></ui-datetime-picker>
</ng-template>

<div
  class="action-buttons"
  *ngIf="editable"
>
  <div *ngIf="!isEditing && !hideEditButtons">
    <button
      ui-button
      class="action-buttons--edit"
      color="drawerCloseFlat"
      icon="icon_modify_disabled"
      [custom-icon]="true"
      [isSvg]="true"
      icon-size="small"
      [tooltip]="'common.action.edit'| t"
      (click)="setIsEditing(true);"
    ></button>
    <button
      *ngIf="deletable"
      ui-button
      class="action-buttons--delete"
      color="drawerCloseFlat"
      icon="delete"
      [tooltip]="'common.action.delete'| t"
      (click)="handleDeleteEvent($event);"
    >
    </button>
  </div>
  <button
      ui-button
      [isSvg]="true"
      *ngIf="showHistoryLink"
      class="action-buttons--history"
      color="drawerCloseFlat"
      [tooltip]="'common.action.history.view'| t"
      (click)="handleViewHistoryEvent();"
    >
      <ui-icon
        class="tooltip-title--icon"
        [name]="'view_history'"
        [size]="'medium'"
      ></ui-icon>

    </button>
  <ui-confirmations-buttons
    *ngIf="isEditing"
    [isConfirmEnabled]="isValid"
    [notEnabledTooltip]="!isValid ? ('incidents.container.page.details.tab.chronology.timestamp.confirmBtnNotEnabled.tooltip' | t) : ''"
    (confirmationEvent)="handleEditConfirmationEvent($event)"
  ></ui-confirmations-buttons>
</div>
