<div class="detail-layout">
  <nav class="detail-layout--nav">
    <div
      class="detail-layout--back-wrapper"
      *ngIf="backLink"
    >
        <button
          ui-button
          color="lightGreyFlat"
          icon="icon_back"
          [custom-icon]="true"
          [isSvg]="true"
          icon-size="medium"
          (click)="navigateBack()"
        >
          {{ 'common.action.return' | t }}
        </button>
    </div>

    <div class="detail-layout--extra-nav-buttons">
      <ng-content select="[extraNavButtons]"></ng-content>
    </div>
  </nav>

  <div class="detail-layout--card">
    <ng-content select="[topCard]"></ng-content>
  </div>

  <div class="detail-layout--card">
    <ng-content select="[bottomCard]"></ng-content>
  </div>
</div>