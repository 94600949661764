import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { AutocompleteTypes } from "@ui-kit/components/autocomplete/autocomplete.component";
import { AutocompleteCustomValue } from "@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component";
import { LanguageEnum } from "@ui-kit/interfaces/ILanguage";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import * as uuid from "uuid";


export interface ITextareaSpecificTaskParams {
  label?: string;
  formGroup?: UntypedFormGroup;
  maximumChar?: number;
  isRequired?: boolean;
  textareaStyleParams?: {
    height: number;
    resizable: boolean;
  };
  placeholder?: string;
  iconName?: string;
}

interface ITextareaSpecificTaskStylesParams {
  height: string;
  resize: string;
}

@Component({
  selector: 'app-specific-task-textarea',
  templateUrl: './specific-task-textarea.component.html',
  styleUrls: [ './specific-task-textarea.component.scss' ],
})
export class SpecificTaskTextareaComponent implements OnInit {
  @Input()
    textareaValues: ITextareaSpecificTaskParams;

  @Input()
    autocompleteUsecaseTemplateValues: AutocompleteCustomValue[] = [];

  @Input()
    requiredLanguage: LanguageEnum;

  public uuid = {
    fr: uuid.v4(),
    en: uuid.v4(),
  };

  public customAutocomplete = AutocompleteTypes.CUSTOM;
  public isParamsSelectionActive = false;
  public selectedUsecaseTemplate: AutocompleteCustomValue[] = [];
  public textAreaStyles: ITextareaSpecificTaskStylesParams;

  private currentParam = {
    fr: null,
    en: null,
  };
  public currentSelection = {
    fr: true,
    en: false,
  };

  public langEnum = LanguageEnum;

  constructor(public i18nService: I18nService) {
  }

  ngOnInit(): void {
    this.textAreaStyles = {
      height: `${this.textareaValues.textareaStyleParams.height}px`,
      resize: this.textareaValues.textareaStyleParams.resizable ? 'vertical' : 'none',
    };

    if (this.requiredLanguage === LanguageEnum.ENGLISH) {
      this.currentSelection = {fr: false, en: true};
    }
  }

  public get isFrenchRequired(): boolean {
    return !this.requiredLanguage || this.requiredLanguage  === LanguageEnum.FRENCH;
  }

  public get isEnglishRequired(): boolean {
    return !this.requiredLanguage || this.requiredLanguage  === LanguageEnum.ENGLISH;
  }

  public setSelection(lang: LanguageEnum): void {
    if (!this.currentSelection[lang]) {
      this.closeParametersList();
    }
    for (const key in this.currentSelection) {
      this.currentSelection[key] = false;
    }
    this.currentSelection[lang] = true;
  }

  public onValueSelection(event: { value: string; displayValue: string; }, lang: string): void {
    this.currentParam[lang] = event?.displayValue || null;
  }

  public openParametersList(): void {
    this.isParamsSelectionActive = true;
  }

  public closeParametersList(): void {
    this.isParamsSelectionActive = false;
  }

  public applyParam(lang: string): void {
    if (this.currentParam[lang]) {
      document.getElementById(this.uuid[lang]).focus();
      const index = (document.getElementById(this.uuid[lang]) as any).selectionStart;
      const oldValue: string = this.textareaValues.formGroup.get(lang).value || '';
      const newValue = `${oldValue.slice(0, index)}{{parameter.${this.currentParam[lang]}}}${oldValue.slice(index)}`;
      this.textareaValues.formGroup.get(lang).setValue(newValue);
    }
    this.closeParametersList();
  }
}
