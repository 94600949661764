import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ITreeBranch, UiTree } from '@ui-kit/components/ui-tree/ui-tree.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { GroupTemplateLifecycleEnum, GroupTemplateScopeEnum, GroupTemplateStateEnum, IGroupTemplate, IGroupTemplateFolder } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { CustomValidators } from 'projects/@common/utils/validators';

@Injectable({
  providedIn: 'root',
})
export class GroupTemplateFormService {
  public readonly MEMBER_SETTINGS = [
    'allowCreateUpdateChannels',
    'allowDeleteChannels',
    'allowGuestCreateUpdateChannels',
    'allowGuestDeleteChannels',
    'allowAddRemoveApps',
    'allowCreateUpdateRemoveTabs',
    'allowCreateUpdateRemoveConnectors',
  ];

  public readonly MESSAGING_SETTINS = [
    'allowUserEditMessages',
    'allowUserDeleteMessages',
    'allowOwnerDeleteMessages',
    'allowTeamMentions',
    'allowChannelMentions',
  ];

  public readonly FUN_SETTINGS = ['allowGiphy', 'allowStickersAndMemes', 'allowCustomMemes'];

  public channelsForms: UntypedFormArray;
  public templateForm: UntypedFormGroup;
  public lifecycleForm: UntypedFormGroup;
  public approversForm: UntypedFormGroup;

  public expirationOptions = [GroupTemplateLifecycleEnum.INACTIVITY, GroupTemplateLifecycleEnum.CREATION];

  public isCreating: boolean;
  public isDuplicating: boolean;
  public isSetToExpire = true;
  public invalidGroupNameDetail = false;
  public invalidChannelName = false;

  constructor(private readonly store: Store) {
  }

  public createForms(template?: IGroupTemplate): void {
    this.templateForm = new UntypedFormGroup({
      id: new UntypedFormControl(template ? template.id : null),
      name: new UntypedFormControl(template ? template.name : null, Validators.required),
      description: new UntypedFormControl(template ? template.description : null, Validators.required),
      type: new UntypedFormControl(template ? template.type : GroupTypeEnum.O365OFFICETEAMS, Validators.required),
      state: new UntypedFormControl(template ? template.state : GroupTemplateStateEnum.PUBLISHED, Validators.required),
      scope: new UntypedFormControl(GroupTemplateScopeEnum.ORGANIZATIONAL),
      certifyExpirationDuration: new UntypedFormControl(template?.certifyExpirationDuration || 6, [
        Validators.required,
        CustomValidators.numberValidatorNotZero,
      ]),
      permissions: new UntypedFormGroup({
        memberSettings: this.fillPermissionsFormGroup(template, 'memberSettings', this.MEMBER_SETTINGS),
        messagingSettings: this.fillPermissionsFormGroup(template, 'messagingSettings', this.MESSAGING_SETTINS),
        funSettings: this.fillPermissionsFormGroup(template, 'funSettings', this.FUN_SETTINGS),
      }),
    });
    this.approversForm = new UntypedFormGroup({
      reach: new UntypedFormControl(template ? template.reach : null, [Validators.required]),
      attribute: new UntypedFormControl(
        template?.attribute ? [{ value: template.attribute, displayValue: template.attribute }] : [],
        [Validators.required]
      ),
    });

    this.createChannelsForms(template);
    this.createLifecycleForm(template);
  }

  public onDuplicate(language: LanguageEnum) {
    const name = language === LanguageEnum.FRENCH ? `Copie de ${this.templateForm.value.name}` : `Copy of ${this.templateForm.value.name}`;
    this.templateForm.patchValue({ id: null, name, state: GroupTemplateStateEnum.DRAFT });
  }

  public onToggleEditDuplicate() {
    if (this.isCreating && this.isDuplicating) {
      this.isCreating = false;
      this.isDuplicating = false;
    }
  }

  public removeChannel(channelForm: AbstractControl): void {
    const index = this.channelsForms.controls.indexOf(channelForm);
    if (index > -1) {
      this.channelsForms.removeAt(index);
    }
  }

  public addNewChannel(): void {
    this.channelsForms.push(new UntypedFormGroup({
      name: new UntypedFormControl('', [this.checkChannelNameValidation, Validators.required]),
      description: new UntypedFormControl(),
      isPrivate: new UntypedFormControl(false, Validators.required),
      folders: new UntypedFormControl([]),
    }));
  }

  public createLifecycleForm(template: IGroupTemplate): void {
    this.lifecycleForm = new UntypedFormGroup({
      expirationTrigger: new UntypedFormControl(
        template?.lifecycle ? template.lifecycle.expirationTrigger : this.expirationOptions[0],
        this.isSetToExpire ? Validators.required : null
      ),
      expirationDuration: new UntypedFormControl(
        template?.lifecycle ? template.lifecycle.expirationDuration : null,
        this.isSetToExpire
          ? [this.validateExpirationDuration, CustomValidators.numberValidatorNotZero, Validators.required]
          : null
      ),
    });
  }

  public validateExpirationDuration = (control: AbstractControl) => {
    if (control.value && control.value.length > 0) {
      // eslint-disable-next-line no-useless-escape
      const validRegex = new RegExp(/^[0-9]*$/g);
      if (validRegex.test(control.value)) {
        return null;
      }
      return { invalidExpirationDate: true };
    }
    return null;
  };

  public checkChannelNameValidation = (control: AbstractControl) => {
    if (control.value && control.value.length > 0) {
      // eslint-disable-next-line no-useless-escape
      const invalidRegex = new RegExp(/^(\.|_)|[~#%&*\{\}+/\\:<>?|'",]|[\x00-\x1F]|[\x80-\x9F]|(\.\.)|(\.)$/);
      const disallowedName = [
        'forms',
        'CON',
        'CONIN$',
        'CONOUT$',
        'PRN',
        'AUX',
        'NUL',
        'COM1',
        'COM2',
        'COM3',
        'COM4',
        'COM5',
        'COM6',
        'COM7',
        'COM8',
        'COM9',
        'LPT1',
        'LPT2',
        'LPT3',
        'LPT4',
        'LPT5',
        'LPT6',
        'LPT7',
        'LPT8',
        'LPT9',
        'desktop.ini',
        '_vti_',
      ];
      if (invalidRegex.test(control.value) || disallowedName.includes(control.value)) {
        this.invalidChannelName = true;
        return { invalidChannelName: true };
      }
      this.invalidChannelName = false;
    }
    return null;
  };

  public mapGroupTemplateToSave(defaultApprovers: any, folderTree: ITreeBranch[]) {
    return {
      ...this.templateForm.value,
      reach: this.approversForm.value.reach,
      attribute: this.approversForm.value?.attribute?.[0]?.value,
      certifyExpirationDuration: +this.templateForm.value.certifyExpirationDuration,
      folders: this.treeToFolders(folderTree),
      defaultApprovers: defaultApprovers.length > 0 ? defaultApprovers : [],
      channels: this.templateForm.value.type === GroupTypeEnum.O365OFFICETEAMS ? this.mappedChannels() : null,
      lifecycle: this.isSetToExpire ? this.mappedLifeCycle() : null,
    };
  }

  private createChannelsForms(template: IGroupTemplate): void {
    const generalNames = ['Général', 'General'];
    this.channelsForms = new UntypedFormArray(template?.channels
      ? template.channels.map((channel) => new UntypedFormGroup({
        name: new UntypedFormControl(channel.name, [this.checkChannelNameValidation, Validators.required]),
        description: new UntypedFormControl(channel.description),
        isPrivate: new UntypedFormControl(channel.isPrivate, Validators.required),
        folders: new UntypedFormControl(UiTree.convertToTree(channel.folders, 'name', 'subFolders')),
        isGeneral: new UntypedFormControl(generalNames.includes(channel.name)),
      }))
      : [
        new UntypedFormGroup({
          name: new UntypedFormControl(
            this.store.selectSnapshot<string>((state) => state.i18n.locale) === LanguageEnum.FRENCH
              ? generalNames[0]
              : generalNames[1],
            Validators.required
          ),
          description: new UntypedFormControl(),
          isPrivate: new UntypedFormControl(false, Validators.required),
          folders: new UntypedFormControl([]),
          isGeneral: new UntypedFormControl(true),
        }),
      ]);
  }

  private mappedLifeCycle() {
    return {
      expirationTrigger: this.lifecycleForm.controls['expirationTrigger'].value,
      expirationDuration: this.lifecycleForm.controls['expirationDuration'].value,
    };
  }

  private mappedChannels() {
    return this.channelsForms.controls.map((channelForm) => ({
      name: channelForm.get('name').value,
      description: channelForm.get('description').value,
      isPrivate: channelForm.get('isPrivate').value,
      folders: this.treeToFolders(channelForm.get('folders').value),
    }));
  }

  private treeToFolders(tree: ITreeBranch[]): IGroupTemplateFolder[] {
    const folders: IGroupTemplateFolder[] = [];
    if (tree?.length) {
      for (const branch of tree) {
        folders.push({
          name: branch.name.trim().replace(/\s+/g, ' '),
          subFolders: this.treeToFolders(branch.branches),
        });
      }
    }
    return folders;
  }


  private fillPermissionsFormGroup(template: IGroupTemplate, permissionsType: string, settings: string[]): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});
    for (const setting of settings) {
      formGroup.addControl(setting, new UntypedFormControl(template?.permissions ? template.permissions[permissionsType][setting] : this.getPermissionsDefaultValue(setting, permissionsType), Validators.required));
    }
    return formGroup;
  }

  private getPermissionsDefaultValue(setting: string, permissionsType: string): boolean {
    if (permissionsType === 'memberSettings') {
      return setting === 'allowCreateUpdateChannels';
    }

    return true;
  }
}
