import { Component, Input } from '@angular/core';
import { IncidentStatus } from 'projects/@common/services/api/respond/incidents/incidents.definitions';

export enum UiStatusBadgeColorVariant {
  GREEN = 'green',
  PURPLE = 'purple',
  GRAY = 'gray'
}

@Component({
  selector: 'incident-status-badge',
  templateUrl: './incident-status-badge.component.html',
  styleUrls: [ './incident-status-badge.component.scss' ],
})
export class IncidentStatusBadgeComponent {
  @Input() incidentStatus: IncidentStatus;

  constructor() { }

  public get textTranlationKey(): string {
    return `incidents.container.page.filter.status.${this.incidentStatus}.filter`;
  }

  public get badgeColorVariant(): UiStatusBadgeColorVariant {
    switch (this.incidentStatus) {
      case IncidentStatus.OPEN:
        return UiStatusBadgeColorVariant.GREEN;
      case IncidentStatus.FINALIZING:
        return UiStatusBadgeColorVariant.PURPLE;
      default:
        return UiStatusBadgeColorVariant.GRAY;
    }
  }

  public get textColorHex(): string {
    switch (this.badgeColorVariant) {
      case UiStatusBadgeColorVariant.GREEN:
        return "#1d9973";
      case UiStatusBadgeColorVariant.PURPLE:
        return "#9475c8";
      case UiStatusBadgeColorVariant.GRAY:
        return "#757575";
    }
  }

  public get backgroundColorHex(): string {
    switch (this.badgeColorVariant) {
      case UiStatusBadgeColorVariant.GREEN:
        return "#cdf7e4";
      case UiStatusBadgeColorVariant.PURPLE:
        return "#eae3f4";
      case UiStatusBadgeColorVariant.GRAY:
        return "#e5e5e5";
    }
  }
}
