<ui-carousel
  [locale]="locale"
  [learnMoreFinish]="data?.isNewClient || data?.isRestrictedUser"
  (closeDialogEmitter)="closeDialog()"
  *ngIf="!mobileService.isMobile(800)"
>
  <div #slide>
    <introduction-slide [isRestrictedUser]="data?.isRestrictedUser"></introduction-slide>
  </div>
  <ng-container  *ngIf="!data?.isRestrictedUser">
    <div #slide>
      <generic-description-slide></generic-description-slide>
    </div>
    <div #slide>
      <quick-actions-description-slide></quick-actions-description-slide>
    </div>
    <div #slide>
      <eco-breaktime-description-slide></eco-breaktime-description-slide>
    </div>
    <div #slide>
      <eco-profile-description-slide></eco-profile-description-slide>
    </div>
    <div #slide>
      <summary-description-slide [isNewClient]="data?.isNewClient"></summary-description-slide>
    </div>
  </ng-container>
</ui-carousel>

<introduction-slide
  *ngIf="mobileService.isMobile(800)"
  [isNewClient]="data?.isNewClient"
  [isRestrictedUser]="data?.isRestrictedUser"
></introduction-slide>
