<div class="connector-card" (click)="openConnectorDetailsPage()"
[tooltip]="displayBadgeOwned ? ('detection.connector.page.tooltip.subcribed'|t) : (displayBadgeAdd ? ('detection.connector.page.tooltip.tosubcribe'|t) : null)"         
(mouseenter)="onHoverEnter()"
(mouseleave)="onHoverLeave()">
<!-- <div 
*ngIf="connector.ring === 'PreviewRelease'" 
class="ribbon">
<span>{{('connector.table.field.ring.' +  connector.ring )| t}}</span>
</div> -->
    <div class="card-badge">
        <img *ngIf="displayBadgeOwned" class="card-badge-items" src="assets/favicons/icon_check.svg">
        <div class="card-badge-items" *ngIf="!displayBadgeOwned && connectorInstances.length > 1">
            <span>{{ connectorInstances.length }}</span>
        </div>
    </div>
    <img class="card-image" [src]="connectorIcon">
    <div class="card-lower-section">
        <p>{{ connector.translations.name }}</p>
    </div>
</div>