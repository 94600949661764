<ui-title
  class="title"
  [name]="'incidents.container.page.details.modal.reopenIncident.title' | t"
>
  <organization-preferred-language
    *ngIf="incident?.organizationEcoId"
    [userLocale]="i18n.currentLocale"
    [organizationEcoId]="incident?.organizationEcoId"
  ></organization-preferred-language>
</ui-title>

<div class="user-inputs">

  <ui-text-field
    name="comment"
    control="comment"
    maxlength="5000"
    rowsNumber="3"
    [label]="'incidents.container.page.details.modal.reopenIncident.comment.label' | t"
    [placeholder]="'incidents.container.page.details.modal.reopenIncident.comment.placeholder' | t"
    [group]="form"
    [required]="true"
    [disabled]="false"
    [readonly]="false"
  ></ui-text-field>

</div>

<div class="buttons">
  <button
    ui-button
    color="standard"
    [disabled]="!isSaveActionAvailable"
    (click)="handleConfirm()"
  >
    <ui-spinner
      *ngIf="asyncState === asyncStateEnum.SAVING"
      size="small"
    ></ui-spinner>
    {{"incidents.container.page.details.modal.reopenIncident.confirmButton" | t}}
  </button>

  <button
    ui-button
    [disabled]="!isCancelActionAvailable"
    (click)="handleCancel()"
  >
    {{'common.action.cancel' | t}}
  </button>
</div>