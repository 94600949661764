import { Component, Inject, Input, OnInit } from '@angular/core';
import { DataConnectorTypes } from '@md.eco/connectors';
import { TableScrollableColumnsContent } from '@ui-kit/components/ui-table-scrollable-body/ui-table-scrollable-body.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { ConnectorMappingHelper } from 'projects/@common/services/api/detect/models/connectorMappingHelper';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { DashboardFileUnitHelper } from '../../helpers/dashboardFileUnitHelper';
import { GenericDashboardTimeRangeEnum, getTimestampsFromDynamicRange } from '../../helpers/getTimestampsFromRange';
import { ConnectorsOrgService } from '@common/services/api/detect/connectorsOrg/connectorsOrg.service';

@Component({
  selector: 'connectors-total-ingested-data',
  templateUrl: './connectors-total-ingested-data.component.html',
  styleUrls: [ './connectors-total-ingested-data.component.scss' ],
})
export class ConnectorsTotalIngestedDataComponent implements OnInit {
  public readonly TIME_RANGE_ENUM = GenericDashboardTimeRangeEnum;

  @Input()
  public activeOrganization: IAutomationOrganization;
  public currentTimeRange: GenericDashboardTimeRangeEnum = GenericDashboardTimeRangeEnum.LAST_7_DAYS;
  public chartData = [];
  public totalUsedCapacity: number = 0;
  public totalUsedCapacityUnit: string = 'GB';
  public isLoading: boolean = false;
  public routerLink: string;
  public routingPermissions: string[] = [];
  public tableData: TableScrollableColumnsContent[] = [];

  constructor(
    public readonly i18n: I18nService,
    public readonly dashboardFileUnitHelper: DashboardFileUnitHelper,
    private readonly connectorsOrgService: ConnectorsOrgService,
    private readonly connectorMappingHelper: ConnectorMappingHelper,
    @Inject('CONSOLE_TYPE') private readonly consoleType: ConsoleType
  ) { }

  ngOnInit(): void {
    this.routerLink = this.consoleType === ConsoleType.ADM ? '/detect/connectors' : '/detection/connectors';
    this.routingPermissions = this.consoleType === ConsoleType.ADM ? [ 'can_list_data_connectors' ] : [ 'can_list_detect_connector_instances' ];
    this.fetchAndSetChartData();
  }

  public handleTimeRangeChange(event: GenericDashboardTimeRangeEnum): void {
    this.currentTimeRange = event;
    this.fetchAndSetChartData();
  }

  private fetchAndSetChartData(): void {
    const { fromDate, toDate } = getTimestampsFromDynamicRange(this.currentTimeRange.toString(), { timeIsUtc: true });
    this.isLoading = true;
    this.connectorsOrgService.getConnectorTotalVolume(this.activeOrganization.id, {
      from: fromDate,
      to: toDate,
    })
      .then((data) => {
        this.chartData = Object.keys(data['connectors']).map((connectorId) => {
          const connectorType = this.connectorMappingHelper.getDatasourceKeyTypeFromConnector(connectorId);
          const connectorName = Object.keys(DataConnectorTypes).find((x) => DataConnectorTypes[x] === connectorType);
          return {
            category: this.i18n.translate(`detection.datasource.inventory.detail.table.datasource.type.${connectorName}.filter`),
            value: data['connectors'][connectorId],
          };
        }).sort((a, b) => b.value - a.value);
        const totalCapacity = this.chartData.reduce((sum, currentData) => sum + currentData.value, 0);
        const { result, typeToTranslate } = this.dashboardFileUnitHelper.getFileUnitValues(totalCapacity);
        this.totalUsedCapacity = result;
        this.totalUsedCapacityUnit = this.i18n.translate(typeToTranslate);
        this.setTableData();
      })
      .finally(() => this.isLoading = false);
  }

  private setTableData(): void {
    if (this.chartData && this.chartData.length > 0) {
      const dataCategories: string[] = this.chartData.map((data) => data.category);
      const dataValues: string[] = this.chartData.map((data) => this.dashboardFileUnitHelper.formatValueWithBinaryUnit(data.value));
      this.tableData = [ {
        headerName: this.i18n.translate('org_connector.title'),
        content: dataCategories,
        footerName: this.i18n.translate('detection.connector.page.all.connectors.collapsable.title'),
      }, {
        headerName: this.i18n.translate('respond.dashboard.data.ingest.title'),
        content: dataValues,
        footerName: `${this.totalUsedCapacity} ${this.totalUsedCapacityUnit}`,
      } ];
    } else {
      this.tableData = [];
    }
  }
}
