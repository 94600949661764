import { Component, Input, OnInit } from '@angular/core';
import { IPlaybookTemplate } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';

@Component({
  selector: 'playbook-library-card',
  templateUrl: './playbook-library-card.component.html',
  styleUrls: [ './playbook-library-card.component.scss' ],
})
export class PlaybookLibraryCardComponent implements OnInit {
  @Input() public playbookTemplate: IPlaybookTemplate;

  @Input() public isCollapsable = true;

  @Input() public isCollapsed = true;

  @Input() public isDraggable = true;

  public toggleCollapse(): void {
    if (this.isCollapsable) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  ngOnInit(): void {
    if (this.isCollapsed) {
      this.isCollapsed = this.isCollapsable;
    }
  }
}
