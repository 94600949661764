import { Component, Input } from '@angular/core';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';

@Component({
  selector: 'short-id',
  templateUrl: './short-id.component.html',
  styleUrls: [ './short-id.component.scss' ],
})
export class ShortIdComponent {
  @Input() shortId: string;
  @Input() label: string;
  @Input() linkURL: string;
  @Input() linkQueryParams: object;
  @Input() linkTooltip: string;
  @Input() linkOpenExternal: boolean;

  constructor(private readonly notice: NoticeService) { }

  public copyShortIdToClipboard(): void {
    navigator.clipboard.writeText(this.shortId);
    this.notice.snack('common.action.copied');
  }
}
