<div class="create-incident-modal">

  <ui-title
    class="create-incident-modal--title"
    [name]="'alerts.container.page.modal.incident.title.noalerts' | t"
  >
    <ui-preferred-language-tag
      *ngIf="asyncState !== asyncStateEnum.LOADING && preferredLanguage"
      class="create-incident-modal--preferredLanguage"
      [locale]="i18n.currentLocale"
      [preferedLang]="preferredLanguage"
    ></ui-preferred-language-tag>
  </ui-title>

  <div
    *ngIf="asyncState === asyncStateEnum.LOADING; else readyView"
    class="create-incident-modal--loading"
  >
    <strong>{{"common.state.loading" | t}}</strong>
    <ui-spinner></ui-spinner>
  </div>

  <ng-template #readyView>
    <form class="create-incident-modal--form">
      <div>
        <label
          ui-label
          class="margin--small--top ui-label_required"
        >{{'common.organization'|t}}</label>
        <autocomplete
          name="autocomplete"
          [canMultiSelect]="false"
          [oneItemAtATime]="true"
          [label]="showLabel ? ('common.organization' | t) : null"
          [placeholder]="'common.search' | t"
          [initItems]="selectedOrganizations"
          [showInInputAtInit]="true"
          [customValues]="organizations"
          [(selectedItems)]="selectedOrganizations"
          [autocompleteType]="customAutocompleteType"
          (onItemSelected)="handleOrganizationChange($event)"
          (isSearchCleared)="handleOrganizationChange(null)"
        ></autocomplete>
      </div>

      <ui-field
        style="margin:0;"
        name="incidentName"
        control="incidentName"
        maxlength="120"
        [label]="'alerts.container.page.modal.incident.name' | t"
        [placeholder]="'alerts.container.page.modal.incident.name.placeholder' | t"
        [group]="form"
        [required]="true"
        [disabled]="!organizationId || !isPlaybooksAvailable"
      ></ui-field>

      <ui-text-field
        style="margin:0;"
        name="incidentDescription"
        control="incidentDescription"
        maxlength="1000"
        rowsNumber="4"
        [label]="'alerts.container.page.modal.incident.description' | t"
        [placeholder]="'incidents.container.page.create.description.placeholder' | t"
        [group]="form"
        [required]="true"
        [disabled]="!organizationId || !isPlaybooksAvailable"
        [readonly]="!organizationId || !isPlaybooksAvailable"
      ></ui-text-field>

      <div>
        <autocomplete
          autocomplete="off"
          [label]="'alerts.container.page.modal.incident.analyst' | t"
          [placeholder]="'alerts.container.page.modal.incident.analyst.placeholder' | t"
          [required]="true"
          [disabled]="!organizationId || !isAnalystsAvailable || !isPlaybooksAvailable"
          [placeholder]=""
          [autocompleteType]="customAutocompleteType"
          [customValues]="analystAutocompleteOptions"
          [selectedItems]="selectedAnalysts"
          [initItems]="selectedAnalysts"
          [showInInputAtInit]="true"
          [canMultiSelect]="false"
          [oneItemAtATime]="true"
          (onItemSelected)="selectedAnalysts = $event ? [$event] : []"
        ></autocomplete>
        <span
          *ngIf="!isAnalystsAvailable && organizationId"
          class="errorMessage"
        >{{'alerts.container.page.modal.incident.analyst.errorMessage' | t}}</span>
      </div>

      <div>
        <autocomplete
          #playbooksAutocomplete
          autocomplete="off"
          [label]="'alerts.container.page.modal.incident.playbook' | t"
          [placeholder]="'alerts.container.page.modal.incident.playbook.placeholder' | t"
          [required]="true"
          [disabled]="!organizationId || !isPlaybooksAvailable || !isAnalystsAvailable"
          [placeholder]=""
          [autocompleteType]="customAutocompleteType"
          [customValues]="playbookAutocompleteOptions"
          [selectedItems]="selectedPlaybooks"
          [initItems]="selectedPlaybooks"
          [showInInputAtInit]="true"
          [canMultiSelect]="false"
          [oneItemAtATime]="true"
          (onItemSelected)="selectedPlaybooks = $event ? [$event] : []"
        ></autocomplete>
        <span
          *ngIf="!isPlaybooksAvailable && organizationId"
          class="errorMessage"
        >{{'alerts.container.page.modal.incident.playbook.required' | t}}</span>
      </div>
    </form>
  </ng-template>

  <div class="create-incident-modal--buttons">
    <button
      ui-button
      color="standard"
      [disabled]="!isSaveActionAvailable"
      (click)="createIncident()"
    >
      <ui-spinner
        *ngIf="asyncState === asyncStateEnum.SAVING"
        size="small"
      ></ui-spinner>
      {{ 'common.action.create' | t }}
    </button>
    <button
      ui-button
      color="secondary"
      [disabled]="!isCancelActionAvailable"
      (click)="cancel()"
    >{{ 'common.action.cancel' | t }}</button>
  </div>
</div>