<div class="drawer-detail--scrollable visible tab-menu">

  <div class="drawer-detail--content">
    <div class="button-container">
      <ng-container *ngIf="!isEditing && currentAsset && !isReadonly">
        <button
          *display="{ permissions: ['can_update_asset'] }"
          ui-button
          icon="icon_modify"
          [custom-icon]="true"
          [isSvg]="true"
          icon-size="medium"
          color="listActionFlat"
          (click)="setEditing()"
        >
          {{ 'common.action.edit' | t }}
        </button>
      </ng-container>
    </div>
    <ui-title [name]="'common.properties' | t"></ui-title>
    <div class="margin--small--top margin--small--bottom">

      <div class="input-wrapper">
        <ui-select
          *ngIf="isEditing && !isReadonly"
          [disabled]="currentAsset"
          [label]="'actifs.drawer.type.label' | t"
          name="type"
          control="type"
          [required]="true"
          [group]="form"
          [options]="typeSelectOptions"
          (optionClick)="addTemplate($event)"
        >
        </ui-select>
        <ng-container
          *ngIf="!isEditing || isReadonly"
          [ngTemplateOutlet]="readonlyTemplate"
          [ngTemplateOutletContext]="{config: {label: 'actifs.drawer.type.label' | t, value: currentAsset.config.name | translatedObject}}"
        >
        </ng-container>
      </div>

      <div class="input-wrapper name-input">
        <ui-field
          *ngIf="isEditing && !isReadonly"
          [errorMessage]="errorUtils.getErrorMessage(form.get('name'))"
          [required]="true"
          [label]="'actifs.drawer.name.label' | t"
          [maxlength]="256"
          type="text"
          name="name"
          control="name"
          [description]="'detection.playbook.task.specific.max-char' | t:{number : 250}"
          [placeholder]="'actifs.drawer.name.placeholder' | t"
          [group]="form"
        >
        </ui-field>
        <ng-container
          *ngIf="!isEditing || isReadonly"
          [ngTemplateOutlet]="readonlyTemplate"
          [ngTemplateOutletContext]="{config: {label: 'actifs.drawer.name.label' | t, value: form.get('name').value}}"
        >
        </ng-container>
      </div>

      <div class="input-wrapper">
        <ui-text-field
          *ngIf="isEditing && !isReadonly"
          name="note"
          control="note"
          maxlength="1024"
          [label]="'actifs.drawer.note.label' | t"
          [placeholder]="'actifs.drawer.note.placeholder' | t"
          [group]="form"
          [description]="'detection.playbook.task.specific.max-char' | t:{number : 1000}"
          [required]="false"
        >
        </ui-text-field>
        <ng-container
          *ngIf="!isEditing || isReadonly"
          [ngTemplateOutlet]="readonlyTemplate"
          [ngTemplateOutletContext]="{config: {label: 'actifs.drawer.note.label' | t, value: form.get('note').value}}"
        >
        </ng-container>
      </div>

      <div *ngIf="!isEditing || isReadonly">
        <div class="flex-box two-columns margin--small--bottom">
          <ng-container
            [ngTemplateOutlet]="readonlyTemplate"
            [ngTemplateOutletContext]="{config: {label: 'actifs.drawer.createdAt.label' | t, value: currentAsset.asset.createdAt | dt:{locale: i8nService.currentLocale, withTime:true} }}"
          >
          </ng-container>
          <ng-container
            [ngTemplateOutlet]="readonlyTemplate"
            [ngTemplateOutletContext]="{config: {label: 'actifs.drawer.updatedAt.label' | t, value: currentAsset.asset.updatedAt | dt:{locale: i8nService.currentLocale, withTime:true} }}"
          >
          </ng-container>
        </div>
        <div class="flex-box two-columns">
          <ng-container
            [ngTemplateOutlet]="readonlyTemplate"
            [ngTemplateOutletContext]="{config: {label: 'actifs.drawer.createdByName.label' | t, value: currentAsset.asset.createdByName}}"
          >
          </ng-container>
          <ng-container
            [ngTemplateOutlet]="readonlyTemplate"
            [ngTemplateOutletContext]="{config: {label: 'actifs.drawer.updatedByName.label' | t, value: currentAsset.asset.updatedByName}}"
          >
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div
    class="drawer-detail--content"
    *ngIf="isTemplateAvailable"
  >
    <ui-title [name]="'actifs.drawer.identification.label' | t"></ui-title>
    <div class="margin--small--top margin--small--bottom">
      <ng-container *ngFor="let field of fieldValues">
        <div class="forms-wrapper">
          <ng-container *ngFor="let control of form.get('properties').get(field.key).controls; let i = index">
            <div class="add-cntainer">
              <ui-dynamic-form-field
                *ngIf="isEditing && !isReadonly"
                [errorMessage]="errorUtils.getErrorMessage(control)"
                class="field"
                [locale]="i8nService.currentLocale"
                [formGroup]="field.form"
                [attr.data-test-id]="'input_' + field.key"
                [field]="getFormField(field.key, i)"
              ></ui-dynamic-form-field>
              <div *ngIf="!isEditing || isReadonly">
                <ng-container
                  [ngTemplateOutlet]="readonlyTemplate"
                  [ngTemplateOutletContext]="{config: {label: getFormField(field.key, i)?.label 
                                ? (getFormField(field.key, i)?.label | translatedObject) 
                                : null, value:getReadonlyValue(getFormField(field.key, i), control.value), tooltip: getFormField(field.key, i).tooltip  }}"
                >
                </ng-container>
              </div>
              <button
                *ngIf="showDelete(form.get('properties').get(field.key), field, i) && isEditing"
                ui-button
                icon="icon_trash"
                [custom-icon]="true"
                [isSvg]="true"
                [color]="uiButtonColor"
                class="table-action-button"
                [class.margin-normal]="i === 0 && control.errors === null && !control.dirty || i === 0 && control.errors === null && control.dirty"
                [class.margin-title-initial]="i === 0 && control.errors && !control.dirty"
                [class.margin-error-no-label]="i > 0 && control.errors && control.dirty"
                [class.margin-error]="i > 0 && control.errors && control.dirty"
                icon-size="small"
                (click)="remove(field.fieldValue.id, i, field)"
              >
              </button>
            </div>
          </ng-container>
          <div
            class="add-container"
            (click)="add(field.fieldValue.id)"
            *ngIf="field.fieldValue.multiple && isEditing && !isReadonly"
          >
            <img src="assets/favicons/icon_add_circle.svg">
            <span>{{'common.action.add' | t}}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ui-form-actions
    *ngIf="isEditing && !isReadonly"
    layout="centered"
    class="visible"
  >
    <ng-container>
      <button
        ui-button
        color="standard"
        [disabled]="!isValid || !isTemplateAvailable"
        (click)="handleSave()"
      >
        {{ 'common.action.save' | t }}
      </button>
      <button
        ui-button
        *ngIf="isEditing && currentAsset"
        color="secondary"
        (click)="cancel()"
      >
        {{ 'common.action.cancel' | t }}
      </button>
    </ng-container>
  </ui-form-actions>
</div>

<ng-template
  #readonlyTemplate
  let-config='config'
>
  <ui-field-group>
    <ui-static
      [label]="config?.label"
      [value]="config.value || '-'"
    >
      <ui-tooltip *ngIf="isTooltipValid(config.tooltip)">{{config.tooltip | translatedObjectLocale}}</ui-tooltip>
    </ui-static>
  </ui-field-group>
</ng-template>