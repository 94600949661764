import { Component, Input } from '@angular/core';
import { SimilarityTableItem } from '../../similarity.definitions';

@Component({
  selector: 'similarity-indicator',
  templateUrl: './similarity-indicator.component.html',
  styleUrls: [ './similarity-indicator.component.scss' ],
})
export class SimilarityIndicatorComponent {
  @Input() similarityItem: SimilarityTableItem;
  @Input() fixedGridDisplay: boolean = true;

  public get isSameUsecase(): boolean {
    return !!this.similarityItem.sameUsecase;
  }

  public get isSameOrganization(): boolean {
    return !!this.similarityItem.sameOrganization;
  }

  public get isSamePlaybook(): boolean {
    return !!this.similarityItem.samePlaybook;
  }
}
