import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { DatasourceConfigurationParameterTypes } from 'projects/@common/services/api/respond/datasources/datasources.definitions';
import { SecretsApiService } from 'projects/@common/services/api/tools/secrets/secrets.api';
import { ISecretIntegrations } from 'projects/@common/services/api/tools/secrets/secrets.definitions';
import { CustomValidators } from 'projects/@common/utils/validators';

export interface ISecret {
  ecoId: string;
  id: string;
  name: string;
  secretValue?: string;
  value?: string
  type: string;
  note?: string;
  version?: string;
  createdAt?: number;
  updatedAt?: number;
  lastAccessedAt?: number;
  expireAt?: number;
}

export interface ConfigurationBaseTemplate {
  label: ITranslatedField;
  type: any;
  required: boolean;
  default?: any;
  values?: { label: ITranslatedField; value: any }[];
}

export interface SecretVariableTemplate {
  variableName: string,
  name: ITranslatedField;
  mandatory: boolean;
  type: string;
}

export class SecretTemplateConfig {
  constructor(
    readonly i18n: I18nService,
    readonly formBuilder: UntypedFormBuilder,
    readonly drawerService: DrawerService,
    readonly store: Store,
    readonly secretsApiService: SecretsApiService
  ) {
    this.initFormGroup();
  }

  public formGroup: UntypedFormGroup;
  public text: UntypedFormGroup;
  public templates: ISecretIntegrations[];
  public currentTemplateUsed: any;

  public validSecretName = true;
  public secretNameErrorMessage: string = null;

  public implementation: UntypedFormGroup = new UntypedFormGroup({ type: new UntypedFormControl('') });


  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [ '' ],
      ecoId: [ '' ],
      name: [ '' ],
      note: [ '' ],
      expireAt: [ 0 ],
      type: [ '', Validators.required ],
      parameters: this.formBuilder.array([]),
    });
  }

  public get parametersFormArray(): UntypedFormArray {
    return this.formGroup.get('parameters') as UntypedFormArray;
  }

  public get isValid(): boolean {
    return this.formGroup.valid;
  }

  public get getExpiration(): number {
    return this.formGroup.get('expireAt').value;
  }

  public setExpiration(date: number) {
    this.formGroup.get('expireAt').setValue(date);
  }

  public get secretBody(): ISecret {

    const formValues = this.formGroup.value;

    return {
      id: formValues.id,
      note: formValues.note,
      ecoId: formValues.ecoId,
      name: formValues.name,
      expireAt: formValues.expireAt || 0,
      secretValue: JSON.stringify(
        formValues.parameters
          .filter(param => param.type !== 'file')  // Exclude type 'file', not needed in backend.
          .reduce((next, param) => {
            const { name } = param;
            return { ...next, [name]: param.value };
          }, {})
      ),
      type: formValues.type,
    };
  }

  get isInvalidSecretName(): boolean {
    return !this.validSecretName;
  }

  get invalidSecretNameMessage(): string {
    return this.secretNameErrorMessage;
  }


  public checkSecretNameValidation = (control: AbstractControl) => {
    this.secretNameErrorMessage = null;
    if (control.value && control.value.length > 0) {
      this.validSecretName = /^[0-9a-zA-Z\-_]*$/.test(control.value);
      this.secretNameErrorMessage = this.validSecretName ? null : this.i18n.translate('form.validation.invalidSecretName');
      return null;
    }
  };

  private getParametersGroup(param: any): UntypedFormGroup {
    const group = this.formBuilder.group({
      name: param.variableName,
      type: param.type,
      value: [ param.default === undefined ? undefined : param.default ],
    });

    if (!param.default && (param.type !== DatasourceConfigurationParameterTypes.FILE || (param.type === DatasourceConfigurationParameterTypes.FILE && param.mandatory === true))) {
      group.get('value').setValidators([ Validators.required, this.getProperValidator(param.type) ]);
    } else {
      group.get('value').setValidators(this.getProperValidator(param));
    }

    group.get('value').updateValueAndValidity();

    return group;
  }

  private getProperValidator(type: string): any {
    switch (type) {
      case DatasourceConfigurationParameterTypes.NUMBER:
        return CustomValidators.numberValidator;
      case DatasourceConfigurationParameterTypes.STRING_LIST:
        return CustomValidators.isArrayOfString;
      case DatasourceConfigurationParameterTypes.OBJECT:
        return CustomValidators.isValidJson;
      default:
        return CustomValidators.defaultValidator;
    }
  }

  public getParamGroup(secretTemplate: any): UntypedFormArray {
    const parametersForm = [];
    secretTemplate?.input?.forEach((param) => parametersForm.push(this.getParametersGroup(param)));

    return this.formBuilder.array(parametersForm);
  }
}
