import { Eco } from "projects/@common/definitions/eco";
import { IWhitelistCondition, IWhitelistPeriod, IWhitelistRepresentation, IWhitelistStatus, IWhitelistUsecases, WhitelistResolution, WhitelistStatusEnum } from "../whitelists.definitions";
import { getCountryAutocompleteOptions } from "./countries";
import { networkProtocols } from "./network-protocols";

export enum WhitelistOperator {
  EQ = "EQ",
  EQ_INSENSITIVE = "EQ_INSENSITIVE",
  BEGIN_WITH = "BEGIN_WITH",
  BEGIN_WITH_INSENSITIVE = "BEGIN_WITH_INSENSITIVE",
  END_WITH = "END_WITH",
  END_WITH_INSENSITIVE = "END_WITH_INSENSITIVE",
  CONTAINS = "CONTAINS",
  CONTAINS_INSENSITIVE = "CONTAINS_INSENSITIVE",
  CIDR = "CIDR",
  CIDR_EXCLUDE = "CIDR_EXCLUDE"
}

export interface IGroupedConditions {
  id: string;
  values: {value: string; operator: WhitelistOperator}[];
}

export class Whitelist implements IWhitelistRepresentation {
  id: string;
  organizationId: string;
  organizationName: string;
  organizationEcoId: string;
  usecases: IWhitelistUsecases[];
  name: string;
  description: string;
  startsAt: number;
  endsAt: number;
  createdById: string;
  createdByName: string;
  createdByOrganization: boolean;
  createdAt: number;
  periods?: IWhitelistPeriod[];
  conditions?: IWhitelistCondition[];
  resolution?: WhitelistResolution;

  constructor(parameters?: IWhitelistRepresentation) {
    if (!parameters) {
      parameters = {
        id: null,
        usecases: [],
        organizationId: null,
        organizationName: null,
        organizationEcoId: null,
        name: null,
        description: null,
        startsAt: null,
        endsAt: null,
        createdById: null,
        createdByName: null,
        createdByOrganization: null,
        createdAt: null,
        periods: [],
        conditions: [],
        resolution: WhitelistResolution.FINALIZE,
      };
    }

    Object.assign(this, parameters);
  }

  public get usecaseIds(): string[] {
    return this.usecases.map((usecase) => usecase.id);
  }

  public get now(): number {
    return Date.now();
  }

  public get isActive(): boolean {
    return this.startsAt < this.now && !this.isExpired;
  }

  public get isExpired(): boolean {
    return !!this.endsAt && this.endsAt < this.now;
  }

  public get isWhitelistPending(): boolean {
    return this.startsAt > this.now;
  }

  public get status(): IWhitelistStatus {
    if (this.isActive) {
      return Whitelist.findWhitelistStatusByEnum(WhitelistStatusEnum.ACTIVE);
    }
    if (this.isWhitelistPending) {
      return Whitelist.findWhitelistStatusByEnum(WhitelistStatusEnum.PENDING);
    } else if (this.isExpired) {
      return Whitelist.findWhitelistStatusByEnum(WhitelistStatusEnum.EXPIRED);
    }
  }

  public static getStatuses() {
    return [
      {
        value: WhitelistStatusEnum.ACTIVE,
        icon: 'icon_in_progress',
        translationKey: 'whitelists.table.column.status.enum.ACTIVE',
      },
      {
        value: WhitelistStatusEnum.PENDING,
        icon: 'icon_timer',
        translationKey: 'whitelists.table.column.status.enum.PENDING',
      },
      {
        value: WhitelistStatusEnum.EXPIRED,
        icon: 'icon_non_applicable',
        translationKey: 'whitelists.table.column.status.enum.EXPIRED',
      },
    ];
  }

  public static findWhitelistStatusByEnum(statusEnumValue: WhitelistStatusEnum): IWhitelistStatus {
    return Whitelist.getStatuses().find((status) => status.value === statusEnumValue);
  }

  public getReadonlyConditionGroups(locale: Eco.Languages): IGroupedConditions[] {
    const countries = getCountryAutocompleteOptions(locale as Eco.Languages);

    return this.conditions.reduce((accumulator: IGroupedConditions[], currentValue: IWhitelistCondition) => {
      let displayValue: string;
      switch (currentValue.id) {
        case 'json-path':
          displayValue = JSON.stringify({
            [currentValue.jsonPath]: currentValue.value
          });
          break;
        case 'source-country':
          displayValue = `${countries.find((country) => country.value === currentValue.value).displayValue} (${currentValue.value})`;
          break;
        case 'network-protocol':
          displayValue = networkProtocols.find((protocol) => protocol.value === currentValue.value).displayValue;
          break;
        default:
          displayValue = currentValue.value;
      }

      const foundIndex = accumulator.findIndex((item) => item.id === currentValue.id);
      if (foundIndex !== -1) {
        accumulator[foundIndex].values.push(this.getFormatedValue(displayValue, currentValue.operator));
      } else {
        accumulator.push({
          id: currentValue.id,
          values: [ this.getFormatedValue(displayValue, currentValue.operator) ],
        });
      }
      return accumulator;
    }, []);
  }

  public getPeriodByDay(day: number): IWhitelistPeriod {
    return this.periods?.find((period) => period.dayOfWeek === day);
  }

  private getFormatedValue(displayValue: string, operator: WhitelistOperator): {value: string; operator: WhitelistOperator} {
    return {
      value: displayValue,
      operator: operator || WhitelistOperator.EQ
    };
  }
}
