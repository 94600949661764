import { Component, EventEmitter, Input, Output} from '@angular/core';
import { SecretsApiService } from '@common/services/api/tools/secrets/secrets.api';
import { ISecretIntegrations } from '@common/services/api/tools/secrets/secrets.definitions';
import { ISelfSignedPems, SelfsignedService } from '@common/services/selfsigned.service';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { DatasourceFormInterface } from 'projects/console-adm/src/app/shared/dynamic-form-template/dynamic-form-template.component';

@Component({
  selector: 'secret-store-upload-or-generate',
  templateUrl: './secret-store-upload-or-generate.component.html',
  styleUrls: [ './secret-store-upload-or-generate.component.scss' ]
})
export class SecretStoreUploadOrGenerateComponent {

  constructor(
    readonly secretsApiService: SecretsApiService,
    readonly selfsignedService: SelfsignedService
  ) {}

  @Input() formConfig: DatasourceFormInterface;

  @Input() public locale: string = LanguageEnum.FRENCH;

  @Input() public class: string = '';

  @Input() public currentTemplate: ISecretIntegrations;

  @Output() onGeneratedCertificate = new EventEmitter<ISelfSignedPems>();

  public isFileUploadFieldsetVisible: boolean = false;

  public isLoading: boolean = false;

  async handleGenerateBtn(): Promise<void> {

    this.isLoading = true;

    try {

      await new Promise((resolve, reject) => {
        setTimeout(resolve, 500);
      });

      /** @see: https://www.npmjs.com/package/selfsigned for documentation about selfsigned certificate generation */
      const certificate:ISelfSignedPems = this.selfsignedService.generate();
      this.onGeneratedCertificate.emit(certificate);

    }
    finally {
      this.isLoading = false;
    }

  }

  handleCertificateUpload(uploadedCertificateData:ISelfSignedPems) {
    this.onGeneratedCertificate.emit(uploadedCertificateData);
  }

  toggleFileUploadVisibility() {
    this.isFileUploadFieldsetVisible = !this.isFileUploadFieldsetVisible;
  }

}
