import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Select } from '@ngxs/store';
import { ConfirmationModalComponent } from '@ui-kit/components/confirmation-modal/confirmation-modal.component';
import { ModalService } from '@ui-kit/services/modal.service';
import { ITabsInfo } from 'projects/@common/components/custom-tabs/custom-tabs.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SnackbarService } from 'projects/@common/modules/notice/implementations/snackbar/snackbar.service';
import { Notice, NoticeLevels } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { IIncidentMessage, IncidentStatus } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { HandleBarUtils } from 'projects/@common/utils/handlebars-utils';
import { Observable } from 'rxjs';
import { TabNamesEnum } from '../../../incidents-details.component';
import { Buffer } from 'buffer';

@Component({
  selector: 'chat-notes',
  templateUrl: './chat-notes.component.html',
  styleUrls: [ './styles/chat-notes.component.scss' ],
})
export class ChatNotesComponent implements OnInit, OnDestroy {
  @Input() incidentNotes: IIncidentMessage[] = [];

  @Input() incident: Incident;

  @Input() currentTabSelected: ITabsInfo;

  public formGroup = new UntypedFormGroup({ message: new UntypedFormControl(null) });

  public currentUser: any;

  public isLoading = true;

  public messageHoveredId: string;

  public messagesEditingMode: { [key: string]: string; } = {};

  public isExpanded = false;

  public editorEventHandlers: { [key: string]: Function; };

  public isTextboxFocused = false;

  public noteInterval: NodeJS.Timeout;

  public froalaOptions = {charCounterCount: false};

  private from: number = 0;

  private size: number = 1000; // À changer éventuellement pour mettre le load event sur le scrolldown

  public selectedImageSrc: string = '';

  public isModalOpen: boolean = false;

  @Select(EcoSessionState.user) public currentUser$: Observable<string>;

  constructor(
    public i18nService: I18nService,
    private readonly incidentsApi: IncidentsApi,
    private readonly modalService: ModalService,
    private readonly snackbarService: SnackbarService,
    private readonly injector: Injector
  ) {
    HandleBarUtils.registerHelpers(injector);
  }
  ngOnDestroy(): void {
    clearInterval(this.noteInterval);
    this.noteInterval = undefined;
  }

  ngOnInit(): void {
    this.fetchNotes();
    this.currentUser$.subscribe((currentUser) => this.currentUser = currentUser);
    this.editorEventHandlers = {
      'image.beforeUpload': () => null,
      'contentChanged': () => null,
      'focus': () => this.handleFroalaFocus(),
      'blur': () => this.handleFroalaFocus(),
    };

  }

  ngOnChanges(): void {
    this.from = this.incidentNotes?.length || 0;
    this.isLoading = false;

    if (this.incidentNotes) {
      this.applyInnerHtmlStyles();
    } else {
      this.incidentNotes = [];
    }

    if (this.currentTabSelected.name === TabNamesEnum.CONVERSATION) {
      this.scrollToLastMessage();
    }
  }

  public get messageValue(): string {
    return this.formGroup.get('message')?.value;
  }

  public get incidentClosed(): boolean {
    return this.incident.status === IncidentStatus.CLOSED;
  }

  public sendMessage(): void {
    const base64Message = Buffer.from(this.messageValue).toString('base64');
    this.formGroup.get('message').setValue(null);

    this.incidentsApi.postIncidentNote(
      this.incident.organizationId,
      this.incident.id,
      { from: this.from, size: this.size, content: base64Message }
    )
      .subscribe((response) => {
        this.incidentNotes = [ ...this.incidentNotes, ...response ];
        this.from = this.incidentNotes.length;
        this.applyInnerHtmlStyles();
      });
  }

  public hoverMessage(messageId?: string) {
    this.messageHoveredId = messageId;
  }

  public messageEditingToggle(messageId: string, messageContent: string): void {
    this.messagesEditingMode[messageId] = messageContent;
  }

  public isMessageEditing(messageId: string): boolean {
    return Object.keys(this.messagesEditingMode).includes(messageId);
  }

  public messageFromMe(message: IIncidentMessage): boolean {
    return message.senderName === this.currentUser.name;
  }

  public handleEditingConfirmation(event: boolean, noteId: string): void {
    if (event) {
      const content = Buffer.from(this.messagesEditingMode[noteId]).toString('base64');
      this.incidentsApi.updateIncidentNote(
        this.incident.organizationId,
        this.incident.id,
        { content, noteId }
      )
        .subscribe(
          (response) => {
            this.snackbarService.handleNotice(new Notice(this.i18nService.translate('incidents.container.page.details.tab.chat.update.success'), NoticeLevels.SUCCESS));

            this.incidentNotes = this.incidentNotes.map((note) => (note.id === noteId ? { ...note, content: this.messagesEditingMode[noteId] } : note));
            this.applyInnerHtmlStyles(false);
            delete this.messagesEditingMode[noteId];
          },
          (error) => {
            this.snackbarService.handleNotice(new Notice(this.i18nService.translate('incidents.container.page.details.tab.chat.update.error'), NoticeLevels.ERROR));
          }
        );
    } else {
      delete this.messagesEditingMode[noteId];
      this.applyInnerHtmlStyles(false);
    }
  }

  public async onDelete(noteId: string): Promise<void> {
    const noteRemoved = await this.deleteMessageConfirmationPopup();

    if (noteRemoved) {
      this.incidentsApi.deleteIncidentNote(
        this.incident.organizationId,
        this.incident.id,
        noteId
      )
        .subscribe(
          (response) => {
            this.snackbarService.handleNotice(new Notice(this.i18nService.translate('incidents.container.page.details.tab.chat.delete.success'), NoticeLevels.SUCCESS));

            delete this.messagesEditingMode[noteId];
            this.incidentNotes = this.incidentNotes.filter((note) => note.id !== noteId);
            this.from = this.incidentNotes.length;
            this.applyInnerHtmlStyles(false);
          },
          (error) => {
            this.snackbarService.handleNotice(new Notice(this.i18nService.translate('incidents.container.page.details.tab.chat.delete.error'), NoticeLevels.ERROR));
          }
        );
    }
  }

  public toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) this.applyInnerHtmlStyles();
  }

  private async deleteMessageConfirmationPopup(): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18nService.translate('incidents.container.page.details.tab.chat.popup.delete.title'),
        text: this.i18nService.translate('incidents.container.page.details.tab.chat.popup.delete.text'),
        confirmationText: this.i18nService.translate('incidents.container.page.details.tab.chat.popup.delete.confirmation'),
        cancelText: this.i18nService.translate('incidents.container.page.details.tab.chat.popup.delete.cancel'),
        currentLang: this.i18nService.currentLocale,
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
  }

  private applyInnerHtmlStyles(scrollToLastMessage: boolean = true): void {
    setTimeout(() => {
      Array.from(document.getElementsByClassName('message-content-notes')).map((element) => {
        for (let i = 0; i < element.children.length; i++) {
          const htmlElementChild = element.children[i] as HTMLElement;

          htmlElementChild.style.marginTop = "0";
          htmlElementChild.style.fontSize = "14px";

          if (i === element.children.length - 1) {
            htmlElementChild.style.marginBottom = "0";
          }

           // Find any <img> tags inside the <p> elements
          const imgElements = htmlElementChild.getElementsByTagName('img');
          if (imgElements.length > 0) {
            Array.from(imgElements).forEach((img) => {

              // Apply inline styles
              img.style.maxWidth = "100%";
              img.style.height = "auto";

              // Wrap image in a <a></a> tag
              const anchor = document.createElement('a');
              anchor.style.cursor = 'pointer'; // My <a> tag doesn't have an href attribute, we have to define cursor type manually
              img.parentNode?.replaceChild(anchor, img);
              anchor.appendChild(img);
            });
          }
        }
      });
      if (scrollToLastMessage) this.scrollToLastMessage();
    }, 1);
  }

  /** When message area clicked, grab the target element */
  public onClickMessageContentArea($event): void {
    const target = $event.target as HTMLElement;

    // Check if the clicked element is an image
    if (target.tagName === 'IMG') {
        const imageUrl = target.getAttribute('src');

        if (imageUrl) {
          this.openNoteContentModal(imageUrl);
        }
    }
  }

  /** Close modal */
  closeNoteContentModal() {
    this.isModalOpen = false;
  }

  /** Open modal */
  openNoteContentModal(imageSrc: string): void {
    this.isModalOpen = true;
    this.selectedImageSrc = imageSrc;
  }

  private scrollToLastMessage(): void {
    document.getElementsByClassName('note-container')[this.incidentNotes?.length - 1]?.scrollIntoView();
  }

  private fetchNotes(): void {
    if (!this.noteInterval) {
      this.noteInterval = setInterval(() => {
        if (this.incident.conversationId) {
          this.incidentsApi.describeIncidentNotes(
            this.incident.organizationId,
            this.incident.id,
            { from: this.from, size: this.size }
          )
            .subscribe((response) => {
              const messages = response.getItems();

              if (messages.length) {
                this.incidentNotes = [ ...this.incidentNotes, ...messages ];
                this.applyInnerHtmlStyles();
              }
            });
        }
      }, 30000);
    }
  }

  private handleFroalaFocus(): void{
    this.isTextboxFocused = !this.isTextboxFocused;
  }
}
