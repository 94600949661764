import { Component, Input, OnInit } from '@angular/core';
import { SimilarityItemTypeEnum, SimilarityTableItem } from '../../similarity.definitions';

@Component({
  selector: 'similarity-status',
  templateUrl: './similarity-status.component.html',
  styleUrls: [ './similarity-status.component.scss' ],
})
export class SimilarityStatusComponent implements OnInit {
  @Input() similarityItem: SimilarityTableItem;

  constructor() { }

  ngOnInit(): void { }

  public get isAlert(): boolean {
    return this.similarityItem.type === SimilarityItemTypeEnum.ALERTS;
  }

  public get isIncident(): boolean {
    return this.similarityItem.type === SimilarityItemTypeEnum.INCIDENTS;
  }

  public get statusData(): { status: string } {
    return { status: this.similarityItem.status };
  }
}
