import { Injectable } from '@angular/core';
import { TranslatedObjectPipe } from '@common/modules/i18n/translatedObject.pipe';
import { IIncidentActionRepresentation } from '@common/services/api/respond/incidents/incidents.definitions';
import { IObservablesPageContext } from 'projects/@respond/observables/components/observable-container/observable-container.component';

export interface IFlaggedTasksResult {
  actionId: string;
  flaggedIncidentTasks: string;
};

@Injectable({
  providedIn: 'root',
})
export class ActionCellService {

  constructor(
    private readonly translatedObjectPipe: TranslatedObjectPipe
  ) { }

  setFlaggedIncidentTasks(pageContext: IObservablesPageContext, actions: IIncidentActionRepresentation[]): IIncidentActionRepresentation[] {

    let actionsCopy = [...actions];

    if (pageContext.incident) {
      for(const action of actionsCopy) {
        const flaggedIncidentTasks = pageContext.incident.tasks
        .filter((incidentTask) => {
          return incidentTask.actions.includes(action.id);
        })
        .map((incidentTask) => this.translatedObjectPipe.transform(incidentTask.task.playbookTask.name)).join(', ');

        action.isRedFlagged = flaggedIncidentTasks.length > 0; // set flag status
        action.redFlagTooltipTaskNames = flaggedIncidentTasks; // set tooltip title
      }
    }

    return actionsCopy;
  }

}
