import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UiKitModule } from "@ui-kit/uikit.module";
import { ComponentsModule } from "projects/@common/components/components.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { PipesModule } from "projects/@common/modules/pipes/pipes.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";

import { RespondWhitelistDrawerContainer } from "./containers/respond-whitelist-drawer/respond-whitelist-drawer.container";
import { RespondWhitelistsContainer } from "./containers/respond-whitelists/respond-whitelists.container";

import { HistoriesModule } from "../histories/histories.module";
import { WhitelistConditionsComponent } from './components/whitelist-conditions/whitelist-conditions.component';
import { WhitelistConnectorNameComponent } from './components/whitelist-connector-name/whitelist-connector-name.component';
import { WhitelistCreatedByComponent } from './components/whitelist-created-by/whitelist-created-by.component';
import { WhitelistDetailsComponent } from './components/whitelist-details/whitelist-details.component';
import { WhitelistHistoryComponent } from './components/whitelist-history/whitelist-history.component';
import { WhitelistStatusComponent } from './components/whitelist-status/whitelist-status.component';
import { WhitelistUsecaseNameComponent } from './components/whitelist-usecase-name/whitelist-usecase-name.component';
import { WhitelistWeeklyScheduleComponent } from './components/whitelist-weekly-schedule/whitelist-weekly-schedule.component';
import { WhitelistsDeleteModalComponent } from "./components/whitelists-delete-modal/whitelists-delete-modal.component";
import { WhitelistsFiltersComponent } from "./components/whitelists-filters/whitelists-filters.component";
import { WhitelistsTableComponent } from './components/whitelists-table/whitelists-table.component';


const components = [
  RespondWhitelistsContainer,
  RespondWhitelistDrawerContainer,
  WhitelistsFiltersComponent,
  WhitelistsTableComponent,
  WhitelistStatusComponent,
  WhitelistCreatedByComponent,
  WhitelistHistoryComponent,
  WhitelistsDeleteModalComponent,
  WhitelistDetailsComponent,
  WhitelistWeeklyScheduleComponent,
  WhitelistConditionsComponent,
  WhitelistUsecaseNameComponent,
  WhitelistConnectorNameComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    PipesModule,
    RouterModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
    HistoriesModule,
  ],
})
export class WhitelistsModule { }
