<div class="slide-container">
    <h2>{{ 'dashboard.dialog.carousel.page2.title' | t }}</h2>
    <h3>{{ 'dashboard.dialog.carousel.page2.second-title' | t }}</h3>
    <p>{{ 'dashboard.dialog.carousel.page2.text1' | t }}</p>
    <div class="bottom-section">
      <div class="left-section">
        <div class="txt-description-wrapper top">
          <p class="bold">{{ 'dashboard.dialog.carousel.page2.text2' | t }}</p>
          <p>{{ 'dashboard.dialog.carousel.page2.text3' | t }}</p>
        </div>
        <div class="txt-description-wrapper bottom">
          <p class="bold">{{ 'dashboard.dialog.carousel.page2.text4' | t }}</p>
          <p>{{ 'dashboard.dialog.carousel.page2.text5' | t }}</p>
        </div>
      </div>
      <div class="middle-section">
        <div class="img-container">
          <img src="assets/favicons/icon_illustration_dashboard.svg" />
          <div class="stroke-1"></div>
          <div class="stroke-2"></div>
          <div class="stroke-3"></div>
          <div class="stroke-4"></div>
        </div>
      </div>
      <div class="right-section">
        <div class="txt-description-wrapper top">
          <p class="bold">{{ 'dashboard.dialog.carousel.page2.text6' | t }}</p>
          <p>{{ 'dashboard.dialog.carousel.page2.text7' | t }}</p>
        </div>
        <div class="txt-description-wrapper bottom">
          <p class="bold">{{ 'dashboard.dialog.carousel.page2.text8' | t }}</p>
          <p>{{ 'dashboard.dialog.carousel.page2.text9' | t }}</p>
        </div>
      </div>
    </div>
</div>
