import { HttpClient } from "@angular/common/http";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EcoUrlService } from "../../core/url.service";
import { BusinessHours, EscalationDelays, ICommunicationApi, ICommunicationContact, ICreateContactRequest, ICreateEscalationListRequest, IDeleteContactRequest, IDeleteEscalationListRequest, IDescribeEscalationListRequest, IEscalationList, IEscalationParameters, IListContactsRequest, IListEscalationListsRequest, IListEscalationParametersRequest, IUpdateBusinessHoursRequest, IUpdateContactRequest, IUpdateEscalationDelaysRequest, IUpdateEscalationListRequest } from "./communication.definitions";


export class CustomerCommunicationApiService extends EcoApi implements ICommunicationApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  listContacts(request: IListContactsRequest): Observable<ResponseUtils<ICommunicationContact>> {
    const url = `${this.host}/me/escalation/contacts`;
    return this.http.get<IRespondResponse<ICommunicationContact>>(url).pipe(map((response) => new ResponseUtils<ICommunicationContact>(response)));
  }

  createContact(request: ICreateContactRequest): Observable<ICommunicationContact> {
    const url = `${this.host}/me/escalation/contacts`;
    return this.http.post<ICommunicationContact>(url, request);
  }

  updateContact(request: IUpdateContactRequest): Observable<ICommunicationContact> {
    const url = `${this.host}/me/escalation/contacts/${request.id}`;
    return this.http.put<ICommunicationContact>(url, request);
  }

  deleteContact(request: IDeleteContactRequest): Observable<ICommunicationContact> {
    const url = `${this.host}/me/escalation/contacts/${request.contactId}`;
    return this.http.delete<ICommunicationContact>(url);
  }


  listEscalationLists(request: IListEscalationListsRequest): Observable<ResponseUtils<IEscalationList>> {
    const url = `${this.host}/me/escalation/escalationContactLists`;
    return this.http.get<IRespondResponse<IEscalationList>>(url).pipe(map((response) => new ResponseUtils<IEscalationList>(response)));
  }

  describeEscalationList(request: IDescribeEscalationListRequest): Observable<IEscalationList> {
    const url = `${this.host}/me/escalation/escalationContactLists/${request.escalationContactListId}`;
    return this.http.get<IEscalationList>(url);
  }

  createEscalationList(request: ICreateEscalationListRequest): Observable<IEscalationList> {
    const url = `${this.host}/me/escalation/escalationContactLists`;
    return this.http.post<IEscalationList>(url, request);
  }

  updateEscalationList(request: IUpdateEscalationListRequest): Observable<IEscalationList> {
    const url = `${this.host}/me/escalation/escalationContactLists/${request.id}`;
    return this.http.put<IEscalationList>(url, request);
  }

  deleteEscalationList(request: IDeleteEscalationListRequest): Observable<IEscalationList> {
    const url = `${this.host}/me/escalation/escalationContactLists/${request.escalationContactListId}`;
    return this.http.delete<IEscalationList>(url);
  }


  listEscalationParameters(request: IListEscalationParametersRequest): Observable<IEscalationParameters> {
    const url = `${this.host}/me/escalation/parameters`;
    return this.http.get<IEscalationParameters>(url);
  }

  updateEscalationBusinessHours(request: IUpdateBusinessHoursRequest): Observable<BusinessHours> {
    const url = `${this.host}/me/escalation/parameters/businessHours`;
    return this.http.put<BusinessHours>(url, request);
  }

  updateEscalationDelays(request: IUpdateEscalationDelaysRequest): Observable<EscalationDelays> {
    const url = `${this.host}/me/escalation/parameters/escalationDelays`;
    return this.http.put<EscalationDelays>(url, request);
  }
}
