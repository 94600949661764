<widget-container
  [title]="'respond.dashboard.resolutionStatuses.title' | t"
  [locale]="locale"
  [withBottomMargin]="true"
  [withTimeRange]="true"
  [timeRanges]="TIME_RANGE_ENUM"
  [timeRangesDefault]="currentTimeRange"
  (selectedTimerange)="handleTimeRange($event)"
>
  <div class="flex-box column center-horizontal">
    <app-date-interval [period]="dateRange" dateFormatEnum="shortWithUTC" [isGMT]="true"></app-date-interval>

    <div *ngIf="resolutionStatuses?.length && !isLoading">
      <div>
        <dynamic-chart
          [locale]="i18n.currentLocale"
          [data]="resolutionStatuses"
          name="resolutionStatuses"
          type="DonutChart"
          key="level"
          strokeColor="#FFF"
          strokeWidth="4"
          [showTicksAndLabels]="true"
          [series]="['count']"
          [legend]="false"
          pieChartHeight="300px"
          pieChartWidth="570px"
          pieChartRadius="120"
          pieChartInnerRadius="40"
          chartWidth="100%"
        ></dynamic-chart>
      </div>
      <div class="resolution-statuses--content--text">
        {{ 'respond.dashboard.resolutionStatuses.text' | t }}
      </div>
      <div class="resolution-statuses--content--action">
        <a
          routerLink="/respond/incidents"
          [queryParams]="urlQueryParams"
        >{{ 'respond.dashboard.'+( adm ? 'adm':'org') +'.resolutionStatuses.action' | t }}</a>
      </div>
    </div>

    <div
      *ngIf="!resolutionStatuses?.length && !isLoading"
      class="no-history"
    >{{ 'dashboard.admin.noData' | t }}</div>

  </div>

  <ui-spinner *ngIf="isLoading"></ui-spinner>
</widget-container>