import { Injectable } from '@angular/core';
import { WebUiConnectorFamiliesCatalog } from '@common/services/catalogs/connectorFamilies.catalog';
import { ConnectorFamilies, DataConnectorTypes } from '@md.eco/connectors';
import { WebUiDataConnectorTypesCatalog } from '@common/services/catalogs/connectors.catalog';

@Injectable({
  providedIn: 'root',
})
export class ConnectorMappingHelper {
  constructor() {}

  public getConnectorIcon(connectorId: string): string {
    // Do we need null check here ? I have no clue. There was before but I'm confused
    const catalogEntry = Object.keys(WebUiDataConnectorTypesCatalog).find((element: DataConnectorTypes) => WebUiDataConnectorTypesCatalog[element].id === connectorId);
    return WebUiDataConnectorTypesCatalog[catalogEntry as DataConnectorTypes].icon;
  }

  public getConnectorFamilyIcon(connectorFamilyId: string): string {
    // Do we need null check here ? I have no clue. There was before but I'm confused
    const catalogEntry = Object.keys(WebUiConnectorFamiliesCatalog).find((element: ConnectorFamilies) => WebUiConnectorFamiliesCatalog[element].id === connectorFamilyId);
    return WebUiConnectorFamiliesCatalog[catalogEntry as ConnectorFamilies].icon;
  }

  public getDatasourceKeyTypeFromConnector(connectorId: string): string {
    // Do we need null check here ? I have no clue. There was before but I'm confused
    return Object.keys(WebUiDataConnectorTypesCatalog).find((element: DataConnectorTypes) => WebUiDataConnectorTypesCatalog[element].id === connectorId);
  }
}
