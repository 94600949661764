import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppRegistrationInfo } from '../../containers/app-registration-update/app-registration-update.container';
@Component({
  selector: 'app-registration-update-body',
  templateUrl: './app-registration-update-body.component.html',
  styleUrls: [ './app-registration-update-body.component.scss' ],
})
export class AppRegistrationUpdateBody {
  @Input() loading: boolean;

  @Input() apps: AppRegistrationInfo;

  @Output() appClick: EventEmitter<AppRegistrationInfo> = new EventEmitter();

  public onAppClick(app: AppRegistrationInfo) {
    if (!app.value.active) {
      this.appClick.emit(app);
    }
  }
}
