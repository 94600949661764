import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { Whitelist, WhitelistOperator } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';
import { IWhitelistCondition, IWhitelistConditionField, WhitelistConditionFieldTypeEnum } from 'projects/@common/services/api/respond/whitelists/whitelists.definitions';

import * as isoCountries from 'i18n-iso-countries';
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";
import { Eco } from 'projects/@common/definitions/eco';
import { getWhitelistConditionDescription } from './getWhitelistConditionDescription';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { AutocompleteCustomValue } from '@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component';
import { getCountryAutocompleteOptions } from '@common/services/api/respond/whitelists/models/countries';
import { networkProtocols } from '@common/services/api/respond/whitelists/models/network-protocols';
import { ComponentsModule } from '@common/components/components.module';
isoCountries.registerLocale(fr);
isoCountries.registerLocale(en);

interface IDynamicCondition {
  id: string,
  type: WhitelistConditionFieldTypeEnum,
  value: string,
  jsonPath?: string,
  operator: WhitelistOperator,
  description?: string,
  countrySelected?: AutocompleteCustomValue[],
  protocolSelected?: AutocompleteCustomValue[],
  typeSelected?: AutocompleteCustomValue[],
}

@Component({
  selector: 'whitelist-conditions',
  templateUrl: './whitelist-conditions.component.html',
  styleUrls: [ './whitelist-conditions.component.scss' ],
})
export class WhitelistConditionsComponent implements OnInit {
  @Input() public whitelist: Whitelist;
  @Input() public conditionFields: IWhitelistConditionField[];
  @Input() public isDisabled: boolean;

  @Output() public conditionsChange: EventEmitter<IWhitelistCondition[]> = new EventEmitter();

  public currentConditions: IDynamicCondition[];
  public typeEnum = WhitelistConditionFieldTypeEnum;
  public customAutocompleteType = AutocompleteTypes.CUSTOM;

  public typeAutocompleteOptions: AutocompleteCustomValue[];
  public countryAutocompleteOptions: AutocompleteCustomValue[];
  public protocolAutocompleteOptions: AutocompleteCustomValue[] = networkProtocols;

  public operators = [
    { value: WhitelistOperator.EQ, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.conditions.operators.${WhitelistOperator.EQ}`) },
    { value: WhitelistOperator.EQ_INSENSITIVE, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.conditions.operators.${WhitelistOperator.EQ_INSENSITIVE}`) },
    { value: WhitelistOperator.BEGIN_WITH, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.conditions.operators.${WhitelistOperator.BEGIN_WITH}`) },
    { value: WhitelistOperator.BEGIN_WITH_INSENSITIVE, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.conditions.operators.${WhitelistOperator.BEGIN_WITH_INSENSITIVE}`) },
    { value: WhitelistOperator.END_WITH, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.conditions.operators.${WhitelistOperator.END_WITH}`) },
    { value: WhitelistOperator.END_WITH_INSENSITIVE, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.conditions.operators.${WhitelistOperator.END_WITH_INSENSITIVE}`) },
    { value: WhitelistOperator.CONTAINS, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.conditions.operators.${WhitelistOperator.CONTAINS}`) },
    { value: WhitelistOperator.CONTAINS_INSENSITIVE, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.conditions.operators.${WhitelistOperator.CONTAINS_INSENSITIVE}`) },
    { value: WhitelistOperator.CIDR, displayValue: this.i18n.translate(`whitelists.drawer.tabs.details.conditions.operators.${WhitelistOperator.CIDR}`) },
  ];

  constructor(
    private readonly translatedObjectPipe: TranslatedObjectPipe,
    private readonly i18n: I18nService
  ) { }

  ngOnInit(): void {
    this.initSelectConditionOptions();
    this.initSelectCountryOptions();
    this.initCurrentWhitelistConditions();
  }

  public get isValidConditions(): boolean {
    return this.currentConditions.every((condition) => {
      switch (condition.type) {
        case WhitelistConditionFieldTypeEnum.JSON_PATH:
          return !!condition.jsonPath && !!condition.value;
        default:
          return !!condition.value;
      }
    });
  }

  public emitConditionsChange(): void {
    const cleanConditions = this.currentConditions.map((condition) => {
      const newObj: any = {
        id: condition.id,
        operator: condition.operator,
        value: condition.value,
      };
      if (condition.jsonPath !== null) {
        newObj.jsonPath = condition.jsonPath;
      }
      return newObj;
    });
    console.log(cleanConditions);
    this.conditionsChange.emit(cleanConditions);
  }

  public addConditionInput(): void {
    this.currentConditions.push({
      id: null,
      type: null,
      value: null,
      operator: WhitelistOperator.EQ,
      description: this.i18n.translate('whitelists.drawer.tabs.details.conditions.ecsFields.tooltip.pleaseselect'),
      protocolSelected: [],
      countrySelected: [],
      typeSelected: [],
    });
  }

  public deleteConditionInput(condition: IDynamicCondition): void {
    this.currentConditions = this.currentConditions.filter((c) => c !== condition);
    this.emitConditionsChange();
  }

  public handleSelectedConditionChange(condition: IDynamicCondition, option: AutocompleteCustomValue): void {
    condition.id = option?.value || null;
    condition.description = getWhitelistConditionDescription(condition, this.conditionFields, this.i18n);
    condition.type = this.getConditionType(condition);
    condition.value = null;
    condition.jsonPath = null;
  }

  public handleAutocompleteValueChange(condition: IDynamicCondition, option: AutocompleteCustomValue): void {
    condition.value = option?.value || null;
    this.emitConditionsChange();
  }

  public handleConditionValueChange(condition: IDynamicCondition, value: string): void {
    condition.value = value;
    this.emitConditionsChange();
  }

  public handleConditionJsonPathChange(condition: IDynamicCondition, jsonPath: string): void {
    condition.jsonPath = jsonPath;
    this.emitConditionsChange();
  }

  public handleConditionChange(condition: IDynamicCondition, operator: WhitelistOperator): void {
    condition.operator = operator;
    this.emitConditionsChange();
  }

  private findConditionField(condition: IWhitelistCondition): IWhitelistConditionField {
    return this.conditionFields.find((field) => field.id === condition.id);
  }

  private getConditionType(condition: IWhitelistCondition): WhitelistConditionFieldTypeEnum {
    return this.findConditionField(condition)?.type;
  }

  private initSelectCountryOptions(): void {
    this.countryAutocompleteOptions = getCountryAutocompleteOptions(this.i18n.currentLocale as Eco.Languages);
  }

  private initSelectConditionOptions(): void {
    this.typeAutocompleteOptions = this.conditionFields
      .map((field) => ({
        value: field.id,
        displayValue: this.translatedObjectPipe.transform(field.name),
        description: field?.ecsFields?.length > 0
          ? `[${field.ecsFields?.join(', ')}]`
          : this.i18n.translate("common.custom"),
      }))
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
  }

  private initCurrentWhitelistConditions(): void {
    this.currentConditions = this.whitelist.conditions.map((condition) => {
      const mappedCondition: IDynamicCondition = {
        id: condition.id,
        type: this.getConditionType(condition),
        description: getWhitelistConditionDescription(condition, this.conditionFields, this.i18n),
        typeSelected: [],
        countrySelected: [],
        protocolSelected: [],
        operator: condition.operator,
        jsonPath: condition.jsonPath,
        value: condition.value,
      };

      mappedCondition.typeSelected.push(this.typeAutocompleteOptions.find((c) => c.value === mappedCondition.id));

      switch (mappedCondition.type) {
        case WhitelistConditionFieldTypeEnum.COUNTRY:
          mappedCondition.countrySelected.push(this.countryAutocompleteOptions.find((c) => c.value === mappedCondition.value));
          break;
        case WhitelistConditionFieldTypeEnum.PROTOCOL:
          mappedCondition.protocolSelected.push(this.protocolAutocompleteOptions.find((c) => c.value === mappedCondition.value));
          break;
      }

      return mappedCondition;
    });
  }
}
