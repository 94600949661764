import { color } from '@amcharts/amcharts4/core';
import { Component, OnInit } from '@angular/core';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { MdEcoApiService } from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile-api';

@Component({
  selector: 'admin-engagement-level',
  templateUrl: './admin-engagement-level.component.html',
  styleUrls: [ './admin-engagement-level.component.scss' ],
})
export class AdminEngagementLevelComponent implements OnInit {
  private readonly COLOR_RED = '#DE3618';

  private readonly COLOR_GREEN = '#00d873';

  private readonly COLOR_GRAY = '#999';

  private readonly COLOR_ORANGE = '#ff7800';

  public stats: any;

  public isLoading = false;

  public engagementLevels: any[] = [];

  public legendTemplate = '';

  public locale: LanguageEnum;

  constructor(
    private readonly i18n: I18nService,
    private mdEcoApiService: MdEcoApiService
  ) { }

  ngOnInit() {
    this.locale = this.i18n.currentLocale as LanguageEnum;
    this.isLoading = true;
    this.mdEcoApiService
      .getEngagementLevels()
      .then((orgStats) => {
        this.stats = orgStats;
        this.formatData();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private formatData() {
    this.engagementLevels = [];
    if (this.stats[0]) {
      this.legendTemplate = `{name}: {value} ${this.i18n.translate('common.users')} [{color2}]({value.percent}%)[/]`;

      this.engagementLevels.push({
        level: this.i18n.translate('dashboard.admin.engagementLevel.high'),
        count: this.stats[0]?.amountHigh,
        color: color(this.COLOR_GREEN),
        color2: color(this.COLOR_GRAY),
      });

      this.engagementLevels.push({
        level: this.i18n.translate('dashboard.admin.engagementLevel.moderate'),
        count: this.stats[0]?.amountMedium,
        color: color(this.COLOR_ORANGE),
        color2: color(this.COLOR_GRAY),
      });

      this.engagementLevels.push({
        level: this.i18n.translate('dashboard.admin.engagementLevel.low'),
        count: this.stats[0]?.amountLow,
        color: color(this.COLOR_RED),
        color2: color(this.COLOR_GRAY),
      });

      this.engagementLevels.push({
        level: this.i18n.translate('dashboard.admin.engagementLevel.else'),
        count: this.stats[0]?.amountElse,
        color: color(this.COLOR_GRAY),
        color2: color(this.COLOR_GRAY),
      });
    }
  }
}
