<base-dialog class="min-width" [title]="'guest.users.management.delete.modal.title'  | t"
             [description]="'guest.users.management.delete.modal.secondTitle' | t" (onCancel)="dialogRef.close()">
  <ng-template *ngTemplateOutlet="guestUsersList"></ng-template>
</base-dialog>

<ng-template #guestUsersList>
  <div class="title">
    <p>{{ 'guest.users.management.delete.modal.description' | t}}</p>
  </div>
  <div class="toolbar">
    <ui-table-total-tool [total]="dataSource.length"></ui-table-total-tool>
    <ui-table-search-tool class="search" [query]="fulltext" [placeholder]="'ui.table-search-tool.placeholder'| t" (clear)="resetDataSource()"
                          (query)="onUserSearch($event)"></ui-table-search-tool>
  </div>
  <div class="table-container">
    <table class="guest-table">
      <tr>
        <th class="guest-table-header">
          <div>{{ 'users.title' | t }} ({{total}})</div>
        </th>
        <th class="guest-table-header"></th>
      </tr>

      <ng-container *ngIf="!isLoading else spinner">
        <ng-container *ngIf="dataSource.length > 0; else emptyList">
          <ng-container *ngFor="let user of dataSource; let last = last">
            <tr class="table-element">
              <td class="row-element" [class.no-border]="last">
                <div class="avatar">
                  <ui-avatar size="small" [user]="user"></ui-avatar>
                </div>
                <div class="name">
                  <span>{{user.fullName}}</span>
                  <span class="email elipsis">{{user.email}}</span>
                </div>
                <div class="datePicker">
                  <ui-date-picker *ngIf="!user.toBeRemoved" class="date-picker" [minDate]="minExpirationDate" [maxDate]="maxExpirationDate"
                                  [withClearButton]="false" [group]="user.expirationForm" [locale]="i18n.currentLocale" control="expirationDate"
                                  [placeholder]="'guest.users.management.delete.modal.action.keep.date.placeholder' | t">
                  </ui-date-picker>
                </div>
              </td>
              <td class="toggle-container" [class.no-border]="last">
                <mat-button-toggle-group [(ngModel)]="user.toBeRemoved" class="toggle-group">
                  <mat-button-toggle [value]="false" (change)="user.switchState(false)" [class.toggle-confirm]="!user.toBeRemoved">{{ 'guest.users.management.delete.modal.action.keep' |
                    t}}</mat-button-toggle>
                  <mat-button-toggle [value]="true" (change)="user.switchState(true)" [class.toggle-revoke]="user.toBeRemoved">{{ 'guest.users.management.delete.modal.action.delete' |
                    t}}</mat-button-toggle>
                </mat-button-toggle-group>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>

    </table>
  </div>
  <div class="stepper">
    <div class="left-side">
      <span class="confirm-text">{{ 'guest.users.management.delete.modal.action.keep.confirm' | t }}: <span
              class="confirm-number">{{toKeepAmount}}</span></span>
      <span class="revoke-text">{{ 'guest.users.management.delete.modal.action.delete.confirm' | t }}: <span
              class="revoke-number">{{toRemoveAmount}}</span></span>
    </div>
    <button ui-button [disabled]="!isValid" [loading]="isLoading" color="standard" (click)="save()">
      {{ 'certify.save' | t }}
    </button>
    <div class="cancel-button">
      <button ui-button color="secondary" (click)="cancel()">
        {{ 'common.action.cancel' | t }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #emptyList>
  <p class="empty">{{ 'guest.users.management.delete.modal.empty' | t }} </p>
</ng-template>

<ng-template #spinner>
  <div class="spinner-container">
    <ui-spinner size="medium" modifier="secondary"></ui-spinner>
  </div>
</ng-template>
