import { Component, Input } from '@angular/core';
import { UsecasesApiService } from '@common/services/api/respond/usecase/usecase.api';
import { IUsecaseResponse } from '@common/services/api/respond/usecase/usecase.definition';

@Component({
  selector: 'usecase-template-details',
  templateUrl: './usecase-details.component.html',
})
export class UsecaseTemplateDetailsComponent {
  @Input() public usecaseId: string;

  public usecase: IUsecaseResponse;

  public isLoading = true;

  constructor(private readonly usecasesApiService: UsecasesApiService) {}

  public ngOnChanges() {
    this.loadUsecase();
  }

  private loadUsecase() {
    this.isLoading = true;

    this.usecasesApiService
      .getUsecaseCatalog(this.usecaseId)
      .then((usecase) => (this.usecase = usecase))
      .finally(() => (this.isLoading = false));
  }
}
