<div>
  <ng-container toolbar>
    <div class="inline-tools">
      <ui-table-total-tool
        [total]="total"
        [locale]="i18nService.currentLocale"
      ></ui-table-total-tool>

      <ui-table-date-tool
        (timeEmitter)="setAlertTimeFilter($event)"
        [locale]="i18nService.currentLocale"
        [defaultTriggerButtonLabel]="'usecase.drawer.tab.trigger.date_filter' | t"
        [excludePresets]="['last_180_days', 'last_365_days']"
      ></ui-table-date-tool>

      <ui-multi-select
        (onOptionSelect)="applyIndicatorFilter($event)"
        [multiSelectData]="indicatorValues"
      ></ui-multi-select>

      <ui-table-search-tool
        [query]="fullText"
        [placeholder]="'ui.table-search-tool.placeholder' | t"
        (clear)="handleSearchClear()"
        (query)="handleSearchQuery($event)"
      ></ui-table-search-tool>
      <ui-table-refresh-tool
        [loading]="isTableDataLoading"
        (click)="handleRefresh()"
      ></ui-table-refresh-tool>
    </div>
  </ng-container>

  <ui-card>
    <button
      ui-button
      *display="{ permissions: ['can_create_usecase'] }"
      color="linkFlat"
      icon="plus"
      icon-size="small"
      (click)="openUsecaseCreation()"
    >
      {{ 'datasources.table.action.create' | t }}
    </button>

    <button
      ui-button
      *display="{ permissions: ['can_delete_usecase'] }"
      color="linkFlat"
      [disabled]="!currentUsecaseSelected"
      [custom-icon]="true"
      [isSvg]="true"
      icon="icon_delete"
      icon-size="medium"
      (click)="openDeleteDialog()"
    >
      {{ 'datasources.table.action.delete' | t }}
    </button>

    <ui-static-table
      [loading]="isTableDataLoading"
      [addPaddingOnFirst]="false"
      [dataSource]="tableDatasource"
      [canSelectOnlyOne]="true"
      (on-row-click)="handleRowClick($event)"
      [columnsDef]="USECASES_COLUMN_DEF"
      (checkbox-event)="handleSelected($event)"
      [isCustomSorting]="true"
      [sort]="sort"
      [direction]="direction"
      [locale]="i18nService.currentLocale"
      (handleCustomSort)="
        handleSorting(
          $event.sortColumn,
          $event.sortDirection,
          $event.sortParent
        )
      "
    >
    </ui-static-table>
  </ui-card>
</div>

<ui-dialog
  *ngIf="showDeleteDialog"
  [headtitle]="'org_usecase.table.action.delete.dialog.title' | t"
  [actionButton]="'datasources.table.action.delete' | t"
  [message]="deleteUsecaseMessage"
  [cancelButton]="'common.action.cancel' | t"
  (onButtonClick)="deleteUsecase($event)"
  [isLoading]="isUsecaseLoading"
></ui-dialog>
