import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEntityRepresentation } from '@common/services/api/respond/entities/entities.definition';
import { IincidentTaskMessage } from '@common/services/api/respond/incidentTasks/incidentTasks.definitions';
import { IObservable } from '@common/services/api/respond/observables/observables.definition';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IIncidentActionRepresentation, IncidentStatus } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { IUsecaseDescriptionResponse } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { ActifsService } from 'projects/@respond/actifs/actifs.service';

@Component({
  selector: 'app-incident-detail-navbar',
  templateUrl: './incident-detail-navbar.component.html',
  styleUrls: [ './incident-detail-navbar.component.scss' ],
})
export class IncidentsNavbarComponent {
  @Input()
  public incident: Incident;

  @Input()
  public usecases: IUsecaseDescriptionResponse[];

  @Input()
  public showHeaderOrgInfo: boolean = true;

  @Input()
  public isAdminConsole: boolean;

  @Input() 
  public observables: IObservable[] = [];

  @Input() 
  public incidentActions: IIncidentActionRepresentation[] = [];

  @Input() 
  public tasksMessages: (IincidentTaskMessage & {messageContent: string})[] = [];

  @Input() 
  public entities: IEntityRepresentation[] = [];

  @Output()
  public closeIncidentEvent = new EventEmitter<void>();

  @Output()
  public reopenIncidentEvent = new EventEmitter<void>();

  @Output()
  public sendNotificationEvent = new EventEmitter<void>();

  public backLink = '../../';
  public queryParamsHandling = 'preserve';

  constructor(
    private readonly displayService: DisplayService,
    private readonly actifsService: ActifsService,
    private readonly notice: NoticeService
  ) { }

  public get isIncidentStatusOpen(): boolean {
    return this.incident && [ IncidentStatus.OPEN, IncidentStatus.FINALIZING ].includes(this.incident.status);
  }

  public get showCloseIncidentButton(): boolean {
    return this.isIncidentStatusOpen && this.displayService.meetsRequirements({ permissions: [ 'can_update_incident' ] });
  }

  public get showReopenIncidentButton(): boolean {
    return this.incident && !this.isIncidentStatusOpen && this.displayService.meetsRequirements({ permissions: [ 'can_reopen_incident' ] });
  }

  public get sendNotificationButton(): boolean {
    return !this.incident?.conversationId && this.isAdminConsole && this.incident?.status === IncidentStatus.OPEN;
  }

  public get assetAcount(): number {
    return this.actifsService.total;
  }

  public emitCloseIncidentEvent(): void {
    this.closeIncidentEvent.emit();
  }

  public emitReopenIncidentEvent(): void {
    this.reopenIncidentEvent.emit();
  }

  public emitSendNotificationEvent(): void {
    this.sendNotificationEvent.emit();
  }
}
