import { Component, Inject, Input, OnInit } from '@angular/core';
import { ConsoleType } from '@common/modules/layout/private.layout';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';
import { FiltersService } from '../../filters.service';
import { FilterType } from '@common/services/api/respond/filters/filters.definitions';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { Store } from "@ngxs/store";
import { AbstractDrawer } from "projects/@common/modules/layout/components/drawer/AbstractDrawer";
import { Options } from '@ui-kit/components/ui-select/ui-select.component';

export interface IFilterDrawerData {
  filterId: string;
  organizationId: string;
  title: string;
  isReadonly: boolean;
  filterType?: FilterType;
}

export enum FilterDrawerTabsEnum {
  DETAILS = 'DETAILS',
  HISTORY = 'HISTORY'
}

@Component({
  selector: 'respond-filter-drawer',
  templateUrl: './respond-filter-drawer.container.html',
  styleUrls: [ './respond-filter-drawer.container.scss' ],
})
export class RespondFilterDrawerContainer extends AbstractDrawer implements OnInit {
  public readonly filterTypeEnum = FilterType;

  @Input()
  public data: IFilterDrawerData;

  public selectedTab = FilterDrawerTabsEnum.DETAILS;
  public isConsoleAdmin: boolean = true;
  public isCreatingFilter: boolean = false;

  public typeEnum: Options;

  constructor(
    private readonly filtersService: FiltersService,
    private readonly drawerService: DrawerService,
    @Inject('CONSOLE_TYPE') private readonly consoleType: ConsoleType,
    private readonly i18n: I18nService,
    private readonly store: Store
  ) {
    super(store);
    const filterChoices = [];
    for (const value of Object.values(FilterType)) {
      filterChoices.push({
        value: `${value}`,
        displayValue: this.i18n.translate(`filter.type.choice.${value}`),
        description: this.i18n.translate(`filter.type.choice.${value}.description`),
      });
    }
    this.typeEnum = filterChoices;
  }

  ngOnInit(): void {
    this.isConsoleAdmin = this.consoleType === ConsoleType.ADM;
    this.isCreatingFilter = !this.data.filterId;
    if (this.data.filterId) {
      this.loadFilter();
      if (this.isConsoleAdmin) {
        this.loadHistory();
      }
    } else {
      this.drawerService.setEditing(true, 'FilterDrawerComponent');
      this.clearFilter();
    }
  }

  public get filter(): Filter {
    return this.filtersService.filter;
  }

  public get isLoading(): boolean {
    return this.filtersService.isLoading;
  }

  public get organizationId(): string {
    return this.data.organizationId;
  }

  public get isEditing(): boolean {
    return this.drawerService.isEditing;
  }

  public loadFilter(): void {
    this.filtersService.fetchFilter({ id: this.data.filterId, organizationId: this.data.organizationId, filterType: this.data.filterType });
    if (this.data.isReadonly && !this.filtersService.filter?.id) {
      this.drawerService.hideDrawer();
    }
  }

  public clearFilter(): void {
    this.filtersService.filter = null;
  }

  public loadHistory(): void {
    this.filtersService.fetchHistory(this.data.filterId, this.data.organizationId, this.data.filterType);
  }

  public handleCreationFilterTypeChoice(value: string): void {
    if (value) {
      this.data.filterType = value as FilterType;
    }
  }

  public handleSavedFilter() {
    this.isCreatingFilter = false;
    this.drawerService.hideDrawer();
  }
}
