import { Component, Input, OnChanges } from "@angular/core";
import { ExpendableTypeEnum } from "@ui-kit/components/ui-expendable-section/ui-expendable-section.component";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { I18nService } from '@common/modules/i18n/i18n.service';
import { PermissionBadge, PermissionBadgeComponent } from "../permission-badge/permission-badge.component";
import { Notice, NoticeLevels, NoticeService } from "@common/modules/notice/notice.service";
import { DataConnectorGrantedStatus, ISoarConnector, SoarConnectorActionStatus, SoarConnectorStatus, SoarConnectorTypes } from "@common/services/api/respond/connectors/connectors-alerting-api.definition";
import { ConnectorsAlertingApiService } from "@common/services/api/respond/connectors/connectors-alerting-api.service";
import { Incident } from "@common/services/api/respond/incidents/models/incident";
import { IObservable, ObservableTypeEnum } from "@common/services/api/respond/observables/observables.definition";
import { ConnectorInstanceApiService } from "@common/services/api/detect/connectorInstances/connectorInstances-api.service";
import { IConnectorInstance, IHealthStatus } from '@common/services/api/detect/models/connectorInstance.model';
import { Finding } from "@common/services/api/respond/finding/model/finding";
import { trigger } from "@angular/animations";


export type SoarConnectorRow = {
  name: string;
  account: string;
  badge: PermissionBadge,
  actions: {
    name: string;
    badge: PermissionBadge,
    date: number;
  }[];
}

export type DataConnectorRow = {
  name: string;
  badge: PermissionBadge,
  health: PermissionBadge,
  data: IConnectorInstance,
  actions: {
    name: string;
    badge: PermissionBadge,
    date: number;
  }[];
  readonly: boolean;
}

@Component({
    selector: 'preparation-section',
    templateUrl: './preparation-section.component.html',
    styleUrls: [ './preparation-section.component.scss' ],
  })
  export class PreparationSectionComponent implements OnChanges {
    @Input() public disabled = false;
    @Input() public incident: Incident;
    @Input() public observables: IObservable[];
    @Input() public findings: Finding[];
    
    public expendableTypeEnum = ExpendableTypeEnum;
    public soarConnectors: SoarConnectorRow[] = null;
    public dataConnectors: DataConnectorRow[] = null;
    public loadingCount = 0;

    public soarColumnsDef = [{
        label: this.i18n.translate('incidents.component.tab.task.preparation.table.actions'),
        field: 'name',
        type: UiStaticTableRowType.TEXT,
        width: '40%',
        isResizable: false,
      },
      {
        label: this.i18n.translate('incidents.component.tab.task.preparation.table.status'),
        field: 'badge',
        firstParamData: 'badge',
        width: '35%',
        isResizable: false,
        type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
        component: PermissionBadgeComponent,
        paramName: 'value',
      },
      {
        label: this.i18n.translate('incidents.component.tab.task.preparation.table.dates'),
        field: 'date',
        type: UiStaticTableRowType.DATE_AND_HOUR,
        width: '25%',
        isResizable: false,
      },
    ];

    public dataColumnsDef = [{
        label: this.i18n.translate('incidents.component.tab.task.preparation.table.actions'),
        field: 'name',
        type: UiStaticTableRowType.TEXT,
        isResizable: false,
        width: '40%'
      },
      {
        label: this.i18n.translate('incidents.component.tab.task.preparation.table.status'),
        field: 'badge',
        firstParamData: 'badge',
        width: '35%',
        isResizable: false,
        type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
        component: PermissionBadgeComponent,
        paramName: 'value',
      },
      {
        label: this.i18n.translate('incidents.component.tab.task.preparation.table.dates'),
        field: 'date',
        type: UiStaticTableRowType.DATE_AND_HOUR,
        width: '25%',
        isResizable: false,
      },
    ];
  
    constructor(
      private readonly i18n: I18nService, 
      private readonly connectorsAlertingApiService: ConnectorsAlertingApiService,
      private readonly connectorInstanceApiService: ConnectorInstanceApiService,
      private readonly noticeService: NoticeService
    ) {
    }

    ngOnChanges(): void {
      if (this.observables && this.incident) {
        this.loadSoarConnectorData();
        this.loadDataConnectorData();
      }
    }

    public get loading(): boolean {
      return this.loadingCount > 0;
    }

    public getConnectorCount(): number {
      return this.soarConnectors && this.dataConnectors ? this.soarConnectors.length + this.dataConnectors.length : null;
    }

    private async loadSoarConnectorData() {
      this.soarConnectors = [];
      this.loadingCount++;
      try {
        const data = await this.connectorsAlertingApiService.listSoarConnectors(this.incident.organizationId);
        await this.setSoarConnectors(data.items);
      } catch (e) {
        console.log(e);
        this.noticeService.notifyUser(new Notice("connector.table.notice.error.fetch", NoticeLevels.ERROR));
      } finally {
        this.loadingCount--;
      }
    }

    private async loadDataConnectorData() {
      this.dataConnectors = [];
      this.loadingCount++;
      try {
        const connectorIds = this.findings.map((finding) => JSON.parse(finding.content)?.eco?.connector_id);
        const data = await this.connectorInstanceApiService.listDataConnectorsInstance(this.incident.organizationId,  { fulltext: '' }).toPromise();
        await this.setDataConnectors(data.filter((connector) => connectorIds.includes(connector.id)));
      } catch (e) {
        console.log(e);
        this.noticeService.notifyUser(new Notice("connector.table.notice.error.fetch", NoticeLevels.ERROR));
      } finally {
        this.loadingCount--;
      }
    }

    private async setDataConnectors(connectors: IConnectorInstance[]) {
      const actions = await Promise.all(connectors.map((connector) => 
        this.connectorsAlertingApiService.describeDataConnectorActions(
          this.incident.organizationId, 
          connector.id)
      ));

      if (connectors.length === 0) {
        this.dataConnectors.push({
          name: this.i18n.translate("incidents.component.tab.task.preparation.header.notFound"),
          health: null,
          badge: null,
          data: null,
          actions: [],
          readonly: true,
        });
      }

      for (const [index, connector] of connectors.entries()) {
        const connectorActions = actions[index].items;

        let tableLabel = this.i18n.translate("incidents.component.tab.task.preparation.permission.MISSING_PERMISSION");
        let badge = {
          color: 'red',
          label: this.i18n.translate("incidents.component.tab.task.preparation.header.MISSING_PERMISSION"),
          tooltip: this.i18n.translate("incidents.component.tab.task.preparation.header.MISSING_PERMISSION.tooltip")
        };

        if (connectorActions.every((action) => action.status === DataConnectorGrantedStatus.GRANTED)) {
          badge = {
            color: 'green',
            label: this.i18n.translate("incidents.component.tab.task.preparation.header.FULLY_ENABLED"),
            tooltip: this.i18n.translate("incidents.component.tab.task.preparation.header.FULLY_ENABLED.tooltip")
          };
          tableLabel = this.i18n.translate("incidents.component.tab.task.preparation.permission.FULLY_ENABLED");
        }

        this.dataConnectors.push({
          name: connector.name,
          health: {
            color: connector.health != IHealthStatus.GREEN ? 'red' : 'green',
            label: this.i18n.translate('connector.instance.drawer.tab.health.title'),
            tooltip: this.getDataTooltip(connector)
          },
          badge: badge,
          data: connector,
          readonly: false,
          actions: connectorActions.length > 0 ? [{
              name: this.i18n.translate(`incidents.component.tab.task.preparation.read`),
              badge: {
                color: badge.color,
                tooltip: badge.tooltip,
                label: tableLabel
              },
              date: connectorActions[0].updatedAt || null
          }] : []
        });
      };

      this.dataConnectors = this.dataConnectors.sort((c1, c2) => c1.name.localeCompare(c2.name));
    }

    private async setSoarConnectors(connectors: ISoarConnector[]) {
      const accountIds: string[] = this.observables.filter((observable) => observable.type === ObservableTypeEnum.AWS_ACCOUNT_ID).map((observable) => observable.value);

      const actions = await Promise.all(connectors.map((connector) => 
        this.connectorsAlertingApiService.describeSoarConnectorActions(
          this.incident.organizationId, 
          connector.connector.id, 
          connector.connector.connector === SoarConnectorTypes.AWS ? accountIds : null)
      ));

      for (const [index, connector] of connectors.entries()) {
        const isAWSConnector = connector.connector.connector === SoarConnectorTypes.AWS;
        const accounts = (isAWSConnector && accountIds.length) ? accountIds : [null];
        const connectorActions = actions[index];

        for (const account of accounts) {
          let showAction = true;

          let badge = {
            color: 'orange',
            label: this.i18n.translate('incidents.component.tab.task.preparation.header.PARTIALLY_ENABLED'),
            tooltip: this.i18n.translate("incidents.component.tab.task.preparation.header.PARTIALLY_ENABLED.tooltip"),
          };

          const accountActions = connectorActions.items.filter((action) => !isAWSConnector || action.accountId === account);
          
          if (accountActions.every((action) => action.status === SoarConnectorActionStatus.MISSING_PERMISSION) || connectorActions.items.length === 0) {
            badge = {
              color: 'red',
              label: this.i18n.translate("incidents.component.tab.task.preparation.header.MISSING_PERMISSION"),
              tooltip: this.i18n.translate("incidents.component.tab.task.preparation.header.MISSING_PERMISSION.tooltip")
            };
          } else if (accountActions.every((action) => action.status === SoarConnectorActionStatus.FULLY_ENABLED || action.status === SoarConnectorActionStatus.PARTIALLY_ENABLED)) {
            badge = {
              color: 'green',
              label: this.i18n.translate("incidents.component.tab.task.preparation.header.FULLY_ENABLED"),
              tooltip: this.i18n.translate("incidents.component.tab.task.preparation.header.FULLY_ENABLED.tooltip")
            };
          }

          if (connector.connector.status === SoarConnectorStatus.ERROR) {
            badge = {
              color: 'red',
              label: this.i18n.translate("incidents.component.tab.task.preparation.header.ERROR"),
              tooltip: this.i18n.translate("incidents.component.tab.task.preparation.header.ERROR.tooltip")
            };
            showAction = false;
          }

          if (isAWSConnector && !accountIds?.length) {
            badge = {
              color: 'red',
              label: this.i18n.translate("incidents.component.tab.task.preparation.header.NO_AWS_ACCOUNT"),
              tooltip: this.i18n.translate("incidents.component.tab.task.preparation.header.NO_AWS_ACCOUNT.tooltip")
            };
            showAction = false;
          }
        
          this.soarConnectors.push({
            name: `${this.i18n.translate(`detect.connector.instance.drawer.connector.type.${connector.connector.connector + '_' + connector.connector.type}`)} (${connector.connector.name})`,
            account: account ? `${this.i18n.translate(`incidents.component.tab.task.preparation.accountId`)}: ${account}` : null,
            badge: badge,
            actions: accountActions
              .filter(() => showAction)              
              .map((action) => ({
                name: action.action.nameI18n[this.i18n.currentLocale],
                badge: {
                  color: action.status === SoarConnectorActionStatus.MISSING_PERMISSION ? 'red' : 'green',
                  label: this.i18n.translate(`incidents.component.tab.task.preparation.permission.${action.status}`),
                  tooltip: this.i18n.translate(`incidents.component.tab.task.preparation.permission.${action.status}.tooltip`),                },
                date: action.updatedAt
              }))
              .sort((a1,a2) => a1.name.localeCompare(a2.name))
          });
        }
      }

      this.soarConnectors = this.soarConnectors.sort((c1, c2) => c1.name.localeCompare(c2.name));
    }

    public getDataTooltip(dataConnectorInstance: IConnectorInstance): string {
      let errorsTooltip = ''
      if (dataConnectorInstance.health != IHealthStatus.GREEN) {
        for (const errorIndicator of dataConnectorInstance?.errorIndicators) {
          errorsTooltip += errorIndicator.name + '<br>';
        }
      } else {
        errorsTooltip += this.i18n.translate(`incidents.component.tab.task.preparation.healty`);
      }
      return errorsTooltip;
    }
  }