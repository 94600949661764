<div class="drawer-detail--scrollable visible">
  <div
    class="drawer-detail--content"
    *ngIf="autocompleteUsecaseTemplateValues.length > 0; else notLoaded"
  >
    <ui-field-group>
      <autocomplete
        [required]="true"
        [label]="'org_usecase.create_drawer.catalog_selection' | t"
        [placeholder]="'org_usecase.create_drawer.catalog_selection.placeholder' | t"
        [autocompleteType]="customAutocomplete"
        [customValues]="autocompleteUsecaseTemplateValues"
        [selectedItems]="selectedUsecaseTemplate"
        [canMultiSelect]="false"
        [oneItemAtATime]="true"
        [searchLimit]="3000"
        (onItemSelected)="onValueSelection($event)"
      ></autocomplete>
    </ui-field-group>

    <ui-field-group *ngIf="currentTemplate?.datasourceTypes">
      <ui-select
        [options]="currentTemplate?.datasourceTypes"
        name="datasourceType"
        control="datasourceType"
        [placeholder]="'org_usecase.create_drawer.datasource_selection.placeholder' | t"
        [group]="formGroup"
        (optionClick)="addTriggerConfig()"
        [label]="'org_usecase.create_drawer.datasource_selection' | t"
      >
      </ui-select>
    </ui-field-group>

    <ui-field-group *ngIf="usecaseDatasource?.value">
      <ui-static
        label="name"
        [value]="currentTemplate.name | translatedObject"
      ></ui-static>
    </ui-field-group>

    <ui-field-group *ngIf="usecaseDatasource?.value">
      <ui-static
        label="description"
        [value]="currentTemplate.description | translatedObject"
      ></ui-static>
    </ui-field-group>

    <ui-field-group *ngIf="usecaseDatasource?.value">
      <autocomplete
        [required]="false"
        [disabled]="!autocompletePlaybookValues?.length"
        [label]="'org_usecase.create_drawer.playbook_selection' | t"
        [placeholder]="(!autocompletePlaybookValues?.length ? 'org_usecase.create_drawer.playbook_selection.disabled.placeholder' : 'org_usecase.create_drawer.playbook_selection.placeholder') | t"
        [autocompleteType]="customAutocomplete"
        [customValues]="autocompletePlaybookValues"
        [selectedItems]="selectedPlaybook"
        [canMultiSelect]="false"
        [oneItemAtATime]="true"
        (onItemSelected)="playbookSelection($event)"
      ></autocomplete>
    </ui-field-group>

    <ui-field-group>
      <ui-select
        *ngIf="usecaseDatasource?.value"
        [options]="priorityOptions"
        control="priority"
        [group]="formGroup"
        [label]="'org_usecase.table.column.priority' | t"
      ></ui-select>
    </ui-field-group>

    <usecase-affected-resources
      *ngIf="usecaseDatasource?.value"
      [formGroup]="formGroup"
      [controlName]="'affectedResources'"
      [value]="currentTemplate.affectedResources"
      [locale]="locale"
      [isEditing]="true"
    ></usecase-affected-resources>

    <ui-title
      *ngIf="usecaseDatasource?.value"
      class="static-title"
      [name]="'org_usecase.create_drawer.configuration' | t"
    ></ui-title>

    <label
      ui-label
      *ngIf="noTriggerConfigAvailable"
    >
      {{ 'org_usecase.create_drawer.usecase_configuration.not_available' | t }}
    </label>

    <ui-field-group *ngIf="usecaseDatasource?.value">
      <json-textarea
        class="json-textarea"
        *ngIf="usecaseDatasource?.value && !noTriggerConfigAvailable"
        [type]="textareaTypeEnum.JSON"
        controlName="config"
        [formGroup]="aggregationRuleConfig"
        [readonly]="true"
        [prettifyButton]="false"
        [label]="'aggregationRule'"
      >
      </json-textarea>
    </ui-field-group>

    <ui-field-group *ngIf="usecaseDatasource?.value && !noTriggerConfigAvailable">
      <ui-static
        label="datasourceType"
        [value]="currentUsecaseConfig?.datasourceType"
      ></ui-static>
    </ui-field-group>

    <ui-field-group *ngIf="usecaseDatasource?.value && !noTriggerConfigAvailable">
      <ui-static
        label="pipeline"
        [value]="currentUsecaseConfig?.pipeline"
      ></ui-static>
    </ui-field-group>

    <ui-field-group *ngIf="usecaseDatasource?.value">
      <json-textarea
        class="json-textarea"
        *ngIf="usecaseDatasource?.value && !noTriggerConfigAvailable"
        [type]="textareaTypeEnum.JSON"
        controlName="config"
        [formGroup]="triggerConfig"
        [readonly]="true"
        [prettifyButton]="false"
        [label]="'trigger'"
      >
      </json-textarea>
    </ui-field-group>

    <ui-title
      *ngIf="usecaseDatasource?.value"
      class="monitor-title"
      [name]="'org_usecase.description_drawer.tab.monitors.title' | t"
    ></ui-title>

    <ui-field-group *ngIf="usecaseDatasource?.value">
      <label
        ui-label
        *ngIf="noMonitorAvailable"
      >
        {{
        'org_usecase.create_drawer.usecase_monitor.not_available' | t
        }}</label>
    </ui-field-group>

    <ng-container *ngIf="!noMonitorAvailable && usecaseDatasource?.value">
      <ng-container *ngFor="let monitor of monitorList; let i = index">
        <div class="monitor-title">
          <label ui-label>{{ monitor.name }}</label>

          <ng-container *ngFor="let parameter of monitor.parameters; let i = index">
            <ui-field-group>
              <dynamic-form-template
                class="json-textarea"
                [locale]="i18n.currentLocale"
                [formConfig]="parameter"
              ></dynamic-form-template>
            </ui-field-group>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ui-form-actions
  layout="centered"
  class="visible"
>
  <ng-container>
    <button
      *ngIf="!autocompletePlaybookValues?.length && isFormValid"
      class="add-playbook-button"
      ui-button
      color="standard"
      (click)="save(true)">
        {{ 'org_usecase.create_drawer.create_playbook_button.redirect' | t }}
    </button>
    <button
      [disabled]="!isFormValid"
      ui-button
      color="standard"
      (click)="save()"
    >
      {{ 'common.action.save' | t }}
    </button>
    <button
      ui-button
      color="secondary"
      (click)="cancel()"
    >
      {{ 'common.action.cancel' | t }}
    </button>
  </ng-container>
</ui-form-actions>

<ng-template #notLoaded>
  <ui-spinner
    class="spinner"
    size="medium"
    modifier="secondary"
  ></ui-spinner>
</ng-template>

<ng-template #noTemplate>
  {{ 'datasources.tab.creation.configuration.template.placeholder' | t }}
</ng-template>