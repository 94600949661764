import { ConnectorFamilies, ConnectorFamiliesCatalog, IConnectorTypesCatalog } from '@md.eco/connectors';
import { merge } from 'lodash';

const ICON_BASE_PATH = 'assets/favicons';

interface IWebUiConnectorFamily {
  icon: string; // Set to null to apply a default value
};

const tempImplementationCatalog: { [id in ConnectorFamilies]: IWebUiConnectorFamily } = {
  [ConnectorFamilies.AWS]: {
    icon: `${ICON_BASE_PATH}/datasource_icon_aws.svg`,
  },
  [ConnectorFamilies.LOCAL_INFRA]: {
    icon: `${ICON_BASE_PATH}/datasource_icon_local-infra.svg`,
  },
  [ConnectorFamilies.CYBERSECURITY_INFRA]: {
    icon: `${ICON_BASE_PATH}/datasource_icon_cybersecurity-infra.svg`,
  },
  [ConnectorFamilies.MICROSOFT_365]: {
    icon: `${ICON_BASE_PATH}/datasource_icon_microsoft-365.svg`,
  },
  [ConnectorFamilies.MICROSOFT_AZURE]: {
    icon: `${ICON_BASE_PATH}/datasource_icon_microsoft-azure.svg`,
  },
  [ConnectorFamilies.EDR]: {
    icon: `${ICON_BASE_PATH}/datasource_icon_edr.svg`,
  },
  [ConnectorFamilies.INFORMATICA]: {
    icon: `${ICON_BASE_PATH}/datasource_icon_aws.svg`,
  },
  [ConnectorFamilies.GOOGLE]: {
    icon: `${ICON_BASE_PATH}/datasource_icon_google.svg`,
  },
};

// Merging webui catalog with the default catalog.
for (const key of Object.keys(tempImplementationCatalog)) {
  tempImplementationCatalog[key] = merge(tempImplementationCatalog[key], ConnectorFamiliesCatalog[key]);

  // If the icon is set to null in the catalog, we set it to the default value
  if (!tempImplementationCatalog[key as ConnectorFamilies].icon) {
    tempImplementationCatalog[key as ConnectorFamilies].icon = `${ICON_BASE_PATH}/logo_datasourceFamily_none.svg`;
  }
}

export type WebUiConnectorFamiliesCatalogTemplate = { [id in ConnectorFamilies]: IConnectorTypesCatalog & IWebUiConnectorFamily };

export const WebUiConnectorFamiliesCatalog: WebUiConnectorFamiliesCatalogTemplate = tempImplementationCatalog as WebUiConnectorFamiliesCatalogTemplate;

