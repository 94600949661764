import { Component, Input, OnInit } from '@angular/core';
import { DateTimePeriod } from '@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component';
import { IPeriodicReportRepresentation } from 'projects/@common/services/api/respond/periodic-reports/periodic-reports.definitions';

@Component({
  selector: 'respond-reports-period',
  templateUrl:  './respond-reports-period.component.html',
  styleUrls: [ './respond-reports-period.component.scss' ],
})
export class RespondReportsPeriodComponent implements OnInit {
  @Input() public report: IPeriodicReportRepresentation;

  public period: DateTimePeriod;

  constructor() {}

  ngOnInit(): void {
    this.period = {
      dateFrom: this.report.dateFrom,
      dateTo: this.report.dateTo,
    };
  }
}
