import { AutocompleteCustomValue } from "@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component";
import { MultiSelectData } from "@ui-kit/components/ui-multi-select/multi-select-data";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { DataConnectorTypes } from '@md.eco/connectors';

export interface ICommunicationApi {
  listContacts(request: IListContactsRequest): Observable<ResponseUtils<ICommunicationContact>>;
  createContact(request: ICreateContactRequest): Observable<ICommunicationContact>;
  updateContact(request: IUpdateContactRequest): Observable<ICommunicationContact>;
  deleteContact(request: IDeleteContactRequest): Observable<ICommunicationContact>;

  listEscalationLists(request: IListEscalationListsRequest): Observable<ResponseUtils<IEscalationList>>;
  describeEscalationList(request: IDescribeEscalationListRequest): Observable<IEscalationList>;
  createEscalationList(request: ICreateEscalationListRequest): Observable<IEscalationList>;
  updateEscalationList(request: IUpdateEscalationListRequest): Observable<IEscalationList>;
  deleteEscalationList(request: IDeleteEscalationListRequest): Observable<IEscalationList>;

  listEscalationParameters(request: IListEscalationParametersRequest): Observable<IEscalationParameters>;
  updateEscalationBusinessHours(request: IUpdateBusinessHoursRequest): Observable<BusinessHours>;
  updateEscalationDelays(request: IUpdateEscalationDelaysRequest): Observable<EscalationDelays>;
}

export type IDescribeEscalationListRequest = {
  organizationId: string;
  escalationContactListId: string;
};

export type IUpdateEscalationDelaysRequest = {
  id: string;
  organizationId: string;
  useOffHours: boolean;
  delays: EscalationDelayByPriority[];
};

export type IUpdateBusinessHoursRequest = {
  id: string;
  organizationId: string;
  fromHour: number;
  fromMinute: number;
  toHour: number;
  toMinute: number;
  timezone: string;
};

export type IListEscalationParametersRequest = {
  organizationId: string;
};

export type IEscalationParameters = {
  businessHours?: BusinessHours;
  escalationDelays?: EscalationDelays;
};

export type IListEscalationListsRequest = {
  organizationId: string;
};

export type IDeleteEscalationListRequest = {
  organizationId: string;
  escalationContactListId: string;
};

export type ICreateEscalationListRequest = {
  organizationId: string;
  name: string;
  type: EscalationContactListType;
  ownerContactId: string;
  datasourceTypes?: any[];
  playbookIds?: string[];
  communicationMethods: EscalationListCommunicationMethodRequest[];
};

export type IUpdateEscalationListRequest = ICreateEscalationListRequest & {
  id: string;
};

export type EscalationListCommunicationMethodRequest = {
  level: number;
  contactCommunicationMethodIds: string[];
  withPriorities: boolean;
  priorities: any;
};

export type IListContactsRequest = {
  organizationId: string;
};

export type ICreateContactRequest = {
  organizationId: string;
  name: string;
  position: string;
  communicationMethods: IContactCommunicationMethod[];
};

export type IUpdateContactRequest = ICreateContactRequest & {
  id: string;
};

export type IDeleteContactRequest = {
  organizationId: string;
  contactId: string;
};

export type HourPeriod = 'businessHours' | 'offHours';

export enum EscalationPeriodEnum {
  ANYTIME = "ANYTIME",
  BUSINESS_HOURS = "BUSINESS_HOURS",
  OFF_HOURS = "OFF_HOURS"
}

export type BusinessHours = {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  fromHour: number;
  fromMinute: number;
  toHour: number;
  toMinute: number;
  timezone: string;
};

export enum PriorityEnum {
  "P1" = 1,
  "P2" = 2,
  "P3" = 3,
  "P4" = 4
}

export enum EscalationTimeChoicesEnum {
  NONE = "NONE",
  MINUTES_5 = "MINUTES_5",
  MINUTES_10 = "MINUTES_10",
  MINUTES_15 = "MINUTES_15",
  MINUTES_30 = "MINUTES_30",
  MINUTES_45 = "MINUTES_45",
  HOURS_1 = "HOURS_1",
  HOURS_2 = "HOURS_2",
  HOURS_3 = "HOURS_3",
  HOURS_4 = "HOURS_4",
  HOURS_5 = "HOURS_5",
  HOURS_6 = "HOURS_6",
  HOURS_8 = "HOURS_8",
  HOURS_10 = "HOURS_10",
  HOURS_12 = "HOURS_12"
};

export enum EscalationTimeChoicesEnumInMilliseconds {
  NONE = null,
  MINUTES_5 = 5 * 60 * 1000,
  MINUTES_10 = 10 * 60 * 1000,
  MINUTES_15 = 15 * 60 * 1000,
  MINUTES_30 = 30 * 60 * 1000,
  MINUTES_45 = 45 * 60 * 1000,
  HOURS_1 = 1 * 60 * 60 * 1000,
  HOURS_2 = 2 * 60 * 60 * 1000,
  HOURS_3 = 3 * 60 * 60 * 1000,
  HOURS_4 = 4 * 60 * 60 * 1000,
  HOURS_5 = 5 * 60 * 60 * 1000,
  HOURS_6 = 6 * 60 * 60 * 1000,
  HOURS_8 = 8 * 60 * 60 * 1000,
  HOURS_10 = 10 * 60 * 60 * 1000,
  HOURS_12 = 12 * 60 * 60 * 1000
};

export const EscalationTimeChoicesEnumLabelsForNotification = {
  [EscalationTimeChoicesEnum.MINUTES_5]: {
    fr: `d'ici les 5 prochaines minutes`,
    en: `within the next 5 minutes`,
  },
  [EscalationTimeChoicesEnum.MINUTES_10]: {
    fr: `d'ici les 10 prochaines minutes`,
    en: `within the next 10 minutes`,
  },
  [EscalationTimeChoicesEnum.MINUTES_15]: {
    fr: `d'ici les 15 prochaines minutes`,
    en: `within the next 15 minutes`,
  },
  [EscalationTimeChoicesEnum.MINUTES_30]: {
    fr: `d'ici les 30 prochaines minutes`,
    en: `within the next 30 minutes`,
  },
  [EscalationTimeChoicesEnum.MINUTES_45]: {
    fr: `d'ici les 45 prochaines minutes`,
    en: `within the next 45 minutes`,
  },
  [EscalationTimeChoicesEnum.HOURS_1]: {
    fr: `d'ici la prochaine heure`,
    en: `within the next hour`,
  },
  [EscalationTimeChoicesEnum.HOURS_2]: {
    fr: `d'ici les 2 prochaines heures`,
    en: `within the next 2 hours`,
  },
  [EscalationTimeChoicesEnum.HOURS_3]: {
    fr: `d'ici les 3 prochaines heures`,
    en: `within the next 3 hours`,
  },
  [EscalationTimeChoicesEnum.HOURS_4]: {
    fr: `d'ici les 4 prochaines heures`,
    en: `within the next 4 hours`,
  },
  [EscalationTimeChoicesEnum.HOURS_5]: {
    fr: `d'ici les 5 prochaines heures`,
    en: `within the next 5 hours`,
  },
  [EscalationTimeChoicesEnum.HOURS_6]: {
    fr: `d'ici les 6 prochaines heures`,
    en: `within the next 6 hours`,
  },
  [EscalationTimeChoicesEnum.HOURS_8]: {
    fr: `d'ici les 8 prochaines heures`,
    en: `within the next 8 hours`,
  },
  [EscalationTimeChoicesEnum.HOURS_10]: {
    fr: `d'ici les 10 prochaines heures`,
    en: `within the next 10 hours`,
  },
  [EscalationTimeChoicesEnum.HOURS_12]: {
    fr: `d'ici les 12 prochaines heures`,
    en: `within the next 12 hours`,
  },
};

export type EscalationDelay = {
  reminder?: EscalationTimeChoicesEnum;
  delay?: EscalationTimeChoicesEnum;
  nextBusinessDay?: boolean;
};

export type EscalationDelayByPriority = {
  priority: PriorityEnum, // 1, 2, 3, 4
  businessHours: EscalationDelay,
  offHours?: EscalationDelay,
};

export type EscalationDelays = {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  useOffHours: boolean;
  delays: EscalationDelayByPriority[];
};

export enum CommunicationMethodTypeEnum {
  EMAIL = 'EMAIL',
  OPSGENIE = 'OPSGENIE',
  OTHER = 'OTHER',
  SMS = 'SMS',
  PHONE = 'PHONE'
}

export interface IMethodEmail {
  email?: string;
}

export interface IMethodOpsGenie {}

export interface IMethodOther {
  note?: string;
}

export interface IMethodSMS {
  phoneNumber?: string;
}

export interface IMethodPhone {
  phoneNumber?: string;
  ext?: string;
}

export type CommunicationMethodProperties =
  | IMethodEmail
  | IMethodOpsGenie
  | IMethodOther
  | IMethodSMS
  | IMethodPhone;

export interface IContactCommunicationMethod {
  id?: string;
  contactId?: string;
  level?: number;
  type: CommunicationMethodTypeEnum;
  properties: CommunicationMethodProperties;

  // UI only
  translation?: string;
  value?: string;
  withPriorities?: boolean;
  priorities?: { [key in PriorityEnum]: boolean };
}

export interface ICommunicationContact {
  id?: string;
  name: string;
  organizationId: string;
  position?: string;
  createdAt?: number;
  updatedAt?: number;
  deletedAt?: number;
  communicationMethods: IContactCommunicationMethod[];
}

export enum EscalationConditionTypeEnum {
  CONNECTOR = "connector",
  PLAYBOOK = "playbook"
}

export interface IEscalationCondition {
  type: EscalationConditionTypeEnum;
  connectorId?: string;
  playbookId?: string;
}

export interface IEsclationListContact {
  id: string;
  name?: string;
  position?: string;
  selectedCommunicationMethods?: IContactCommunicationMethod[];
  communicationMethodsMultiselectOptions?: MultiSelectData;
  selectedContactAutocompleteOption?: AutocompleteCustomValue;
  withPriorities?: boolean;
  priorities?: number[];
}

export interface IEscalationLevelForDisplay {
  level: number;
  contacts: IEsclationListContact[];
}

export enum EscalationContactListType {
  DEFAULT = 'DEFAULT',
  DEFAULT_OFFHOURS = 'DEFAULT_OFFHOURS',
  CUSTOM_ANYTIME = 'CUSTOM_ANYTIME',
  CUSTOM_BUSINESS_HOURS = 'CUSTOM_BUSINESS_HOURS',
  CUSTOM_OFFHOURS = 'CUSTOM_OFFHOURS'
}

export interface IEscalationList {
  id?: string;
  name: string;
  type: EscalationContactListType;
  organizationId: string;
  createdAt?: number;
  updatedAt?: number;
  deletedAt?: number;
  ownerContactId?: string;
  datasourceTypes?: DataConnectorTypes[];
  playbookIds?: string[];
  communicationMethods?: IContactCommunicationMethod[];
  escalationLevels?: IEscalationLevelForDisplay[];
}

export interface IConnectorEscalation {
  connectorId: string;
  displayName: string;
  icon: string;
  datasourceType: DataConnectorTypes;
}
