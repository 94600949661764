import { Component, Inject, OnInit } from '@angular/core';
import { IHistory } from '@common/services/api/respond/histories/histories.definition';
import { Store } from '@ngxs/store';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { SearchMode } from 'projects/@common/modules/layout/components/page/page.component';
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ConnectorsApiOrganizationService } from 'projects/@common/services/api/detect/organizations/connectors-api-organizations';
import { HistoriesService } from '../../histories.service';

@Component({
  selector: 'histories-page',
  templateUrl: './histories.container.html',
  styleUrls: [ './histories.container.scss' ],
})
export class HistoriesContainer implements OnInit {
  public canList: boolean = false;
  public canDescribe: boolean = false;
  public searchMode = SearchMode.RESPOND;

  constructor(
    private readonly i18n: I18nService,
    private readonly displayService: DisplayService,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    private readonly historiesService: HistoriesService,
    private drawerService: DrawerService,
    @Inject('CONSOLE_TYPE') public consoleType: ConsoleType
  ) {
  }

  ngOnInit(): void {
    this.canList = this.displayService.meetsRequirements({ permissions: [ 'can_list_whitelists' ] });
    this.canDescribe = this.displayService.meetsRequirements({ permissions: [ 'can_describe_whitelist' ] });
    this.historiesService.setDefaultSettings();
  }

  public loadMoreEvent() {
    if (!this.historiesService.isLoading) {
      this.historiesService.loadMoreHistory();
    }
  }

  public get locale(): string {
    return this.i18n.currentLocale;
  }

  public get histories(): IHistory[] {
    return this.historiesService.historiesData;
  }

  public get isLoading(): boolean {
    return this.historiesService.isLoading;
  }
  
  public get canLoadMore(): boolean {
    return this.historiesService.canLoadMoreHistory;
  }
  
  public get adminConsole(): boolean {
    return this.consoleType === ConsoleType.ADM || this.consoleType === ConsoleType.ORG;
  }

  public get defaultTableColumns(): Array<object> {
    return [];
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    this.drawerService.hideDrawer();
    if (org) {
      const organization = await this.connectorsApiOrganizationService.describeOrganization(org.id);
      this.historiesService.setOrganizationId(organization.organization_respond_id);
      this.historiesService.fetchHistory();      
    } else {
      this.historiesService.setOrganizationId(null);
      this.historiesService.historiesData = [];
    }
  }
}
