<ui-tabs #tabs color="drawerTabs" *ngIf="!isLoading && !waitForPlaybook; else notLoaded" id="usecaseDescriptionTabs">
  <ui-tab [tabTitle]="'org_usecase.description_drawer.tab.details.title' | t" [isDisabled]="disabledTabs">
    <usecase-details
      [currentUsecase]="currentUsecase"
      [usecaseTemplate]="usecaseTemplate"
      [usecaseUpdateForm]="usecaseUpdateForm"
      [currentPlaybook]="currentPlaybook"
      [data]="data"
    ></usecase-details>
  </ui-tab>

  <ui-tab [tabTitle]="'org_usecase.description_drawer.tab.actions.title' | t" [isDisabled]="disabledTabs">
    <usecase-actions
      [currentUsecase]="currentUsecase"
      [usecaseUpdateForm]="usecaseUpdateForm"
      [currentPlaybook]="currentPlaybook"
      [data]="data"
    ></usecase-actions>
  </ui-tab>

  <ui-tab
    *ngIf="usecaseMonitors.length > 0"
    [tabTitle]="'org_usecase.description_drawer.tab.monitors.title' | t"
    [isDisabled]="disabledTabs">
    <usecase-monitors
      [currentUsecase]="currentUsecase"
      [usecaseMonitors]="usecaseMonitors"
      [usecaseUpdateForm]="usecaseUpdateForm"
    ></usecase-monitors>
  </ui-tab>

  <ui-tab [tabTitle]="'usecase.drawer.tab.filters.title' | t" *ngIf="currentUsecase" [isDisabled]="disabledTabs">
    <app-usecase-filters-list [usecase]="currentUsecase"></app-usecase-filters-list>
  </ui-tab>

  <ui-tab [tabTitle]="'usecase.drawer.tab.whitelist.title' | t" *ngIf="currentUsecase" [isDisabled]="disabledTabs">
    <app-usecase-whitelist [usecase]="currentUsecase"></app-usecase-whitelist>
  </ui-tab>

  <ui-tab [tabTitle]="'usecase.drawer.tab.history.title' | t" *ngIf="currentUsecase" [isDisabled]="disabledTabs">
    <app-usecase-history [usecase]="currentUsecase"></app-usecase-history>
  </ui-tab>
</ui-tabs>

<ng-template #notLoaded>
  <ui-spinner class="spinner" size="medium" modifier="secondary"></ui-spinner>
</ng-template>
