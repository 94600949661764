<div
  *ngFor="let condition of currentConditions; let i = index"
  class="whitelist-condition margin--small--bottom"
>
  <div class="whitelist-condition-content">
    <div class="whitelist-condition-firstline">
      <div class="whitelist-condition-select-container">
        <div class="flex-box">
          <label
            ui-label
            [required]="true"
          >{{'whitelists.drawer.tabs.details.conditions.selectType.label' | t}}</label>
          <ui-tooltip [isSmall]="false">
            {{condition.description}}
          </ui-tooltip>
        </div>
        <autocomplete
          class="whitelist-condition-select"
          [required]="true"
          [placeholder]="'whitelists.drawer.tabs.details.conditions.selectType' | t"
          [autocompleteType]="customAutocompleteType"
          [customValues]="typeAutocompleteOptions"
          [selectedItems]="condition.typeSelected"
          [initItems]="condition.typeSelected"
          [showInInputAtInit]="true"
          [canMultiSelect]="false"
          [oneItemAtATime]="true"
          [showSearchIcon]="true"
          (onItemSelected)="handleSelectedConditionChange(condition, $event)"
        ></autocomplete>
      </div>

      <div class="whitelist-condition-select-container operator">
        <ui-select
          [options]="operators"
          [ngModel]="condition.operator"
          (ngModelChange)="handleConditionChange(condition, $event)"
          [label]="'whitelists.drawer.tabs.details.conditions.operator' | t"
        ></ui-select>
      </div>

    </div>
    <div class="whitelist-condition-secondline" *ngIf="condition.type !== typeEnum.JSON_PATH">
      <div
        [ngSwitch]="condition.type"
        class="whitelist-condition-input-main-container"
      >
        <label
          ui-label
          [required]="true"
        >{{"common.value" |t}}</label>

        <div class="whitelist-condition-input">
          <autocomplete
            *ngSwitchCase="typeEnum.COUNTRY"
            [required]="true"
            [placeholder]="'common.search' | t"
            [autocompleteType]="customAutocompleteType"
            [customValues]="countryAutocompleteOptions"
            [selectedItems]="condition.countrySelected"
            [initItems]="condition.countrySelected"
            [showInInputAtInit]="true"
            [canMultiSelect]="false"
            [oneItemAtATime]="true"
            (onItemSelected)="handleAutocompleteValueChange(condition, $event)"
          ></autocomplete>

          <autocomplete
            *ngSwitchCase="typeEnum.PROTOCOL"
            [required]="true"
            [placeholder]="'common.search' | t"
            [autocompleteType]="customAutocompleteType"
            [customValues]="protocolAutocompleteOptions"
            [selectedItems]="condition.protocolSelected"
            [initItems]="condition.protocolSelected"
            [showInInputAtInit]="true"
            [canMultiSelect]="false"
            [oneItemAtATime]="true"
            (onItemSelected)="handleAutocompleteValueChange(condition, $event)"
          ></autocomplete>

          <input
            *ngSwitchDefault
            ui-input
            type="text"
            [placeholder]="condition.type ? ('common.value'|t) : ''"
            [value]="condition.value"
            (input)="handleConditionValueChange(condition, $event.target.value)"
          >
        </div>
      </div>
    </div>
    <div class="whitelist-condition-thirdline" *ngIf="condition.type === typeEnum.JSON_PATH">
      <div class="whitelist-condition-select-container json-path">
        <div class="flex-box">
          <label
            ui-label
            [required]="true"
          >{{'whitelists.drawer.tabs.details.conditions.jsonPath' | t}}</label>
        </div>
        <input
          ui-input
          type="text"
          [placeholder]="'whitelists.drawer.tabs.details.conditions.jsonPath'|t"
          [value]="condition.jsonPath"
          (input)="handleConditionJsonPathChange(condition, $event.target.value)"
        >
      </div>
      <div class="whitelist-condition-select-container">
        <div class="flex-box">
            <label
            ui-label
            [required]="true"
          >{{"common.value" |t}}</label>
        </div>
        <input
          ui-input
          type="text"
          [placeholder]="'common.value'|t"
          [value]="condition.value"
          (input)="handleConditionValueChange(condition, $event.target.value)"
        >
      </div>
    </div>
  </div>
  <div class="whitelist-condition-trash">
    <button
      *ngIf="currentConditions.length > 1"
      ui-button
      class="day-timerange-delete-button"
      color="drawerCloseFlat"
      icon="close"
      [tooltip]="'common.action.delete'| t"
      (click)="deleteConditionInput(condition);"
    >
    </button>
  </div>
</div>

<div
  *ngIf="!isDisabled && isValidConditions"
  class="add-condition-container"
  (click)="addConditionInput()"
>
  <img src="assets/favicons/icon_add_circle.svg">
  <ui-static [value]="'whitelists.drawer.tabs.details.conditions.addCondition' | t"></ui-static>
</div>