import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DatasourcesInventoryContainerComponent } from './components/datasource-inventory/container/datasources-inventory.container/datasources-inventory.container.component';
import { DatasourcesInventoryDetailContainerComponent } from './components/datasource-inventory-detail/container/datasources-inventory-detail.container/datasources-inventory-detail.container.component';
import { DetectionGuardGuard } from './guards/detection-guard.guard';
import { DatasourceFamilyDetailService } from './services/datasource-family-detail.service';
import { OrgUsecaseContainer } from './org-usecases.container';
import { ConnectorInventoryContainerComponent } from './connectors/container/connector-inventory-container/connector-inventory-container.component';
import { ConnectorDetailsPageComponent } from './connectors/components/connector-details-page/connector-details-page.component';
import { DetectionConnectorGuard } from './guards/detection-connector-guard';

const routes: Routes = [
  {
    path: 'datasources',
    children: [
      {
        path: '',
        component: DatasourcesInventoryContainerComponent,
      },
      {
        path: ':id/detail',
        component: DatasourcesInventoryDetailContainerComponent,
        canActivate: [ DetectionGuardGuard ],
      },
    ],
  },
  {
    path: 'connectors',
    children: [
      {
        path: '',
        component: ConnectorInventoryContainerComponent,
      },
      {
        path: ':name/detail',
        component: ConnectorDetailsPageComponent,
        canActivate: [ DetectionConnectorGuard ],
      },
    ],
  },
  {
    path: ':organizationEcoId/connectors',
    children: [
      {
        path: '',
        component: ConnectorInventoryContainerComponent,
      },
      {
        path: ':name/detail',
        component: ConnectorDetailsPageComponent,
        canActivate: [ DetectionConnectorGuard ],
      },
    ],
  },
  {
    path: 'usecases',
    children: [
      {
        path: '',
        component: OrgUsecaseContainer,
      },
    ],
  },
  {
    path: ':organizationEcoId/usecases',
    children: [
      {
        path: '',
        component: OrgUsecaseContainer,
      },
    ],
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  providers: [ DetectionGuardGuard, DatasourceFamilyDetailService ],
  exports: [ RouterModule ],
})
export class MdDetectionResponseRoutingModule {}
