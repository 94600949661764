<widget-container
  [title]="'dashboard.admin.resource.percentage.title' | t"
  [withBottomMargin]="true"
  [locale]="locale"
>
  <div *ngIf="resourcesNumberPercentageNoCompliant?.length && !isLoading">
    <div>
      <dynamic-chart
        [locale]="i18n.currentLocale"
        [legend]="true"
        [data]="resourcesNumberPercentageNoCompliant"
        name="resourcePercentage"
        type="DonutChart"
        key="level"
        [series]="['count']"
        [innerSeriesLabel]="{ count: 1 }"
        pieChartHeight="120px"
        pieChartRadius="30"
        pieChartInnerRadius="70"
        chartWidth="100%"
        [legendTemplate]="legendTemplate"
      ></dynamic-chart>
    </div>
  </div>
  <ui-spinner *ngIf="isLoading"></ui-spinner>
  <div
    *ngIf="!resourcesNumberPercentageNoCompliant?.length && !isLoading"
    class="admin-tendency-widget--no-history"
  >
    {{ 'dashboard.admin.noData' | t }}
  </div>
</widget-container>
