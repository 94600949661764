export enum DataConnectorTypes {
  AWS_WAF = 'awsWaf',
  AZURE_ACTIVITY_LOGS = 'azureActivityLogs',
  AZURE_SENTINEL = 'azureSentinel',
  BIT_DEFENDER = 'bitDefender',
  CISCO_FMC = 'CiscoFMC',
  CISCO_FTD = 'CiscoFTD',
  CISCO_UMBRELLA = 'CiscoUmbrella',
  CLOUDTRAIL = 'cloudtrail',
  CORTEX_XDR = 'cortexXDR',
  DATABRICKS = 'databricks',
  FORTINET = 'Fortinet',
  FORTIWEB = 'FortiWeb',
  GUARDDUTY = 'guardduty',
  INFORMATICA = 'informatica',
  JUNIPER_SRX = 'juniperSRX',
  JUNOS_OS = 'JunosOs',
  LINUX = 'linux',
  MICROSOFT_365_DEFENDER = 'microsoft365Defender',
  OFFICE = 'MicrosoftActivityLog',
  OPENVAS = 'openvas',
  PALOALTO = 'PaloAlto',
  PULSE_SECURE_VPN = 'PulseSecureVPN',
  SECURITY_HUB = 'securityHub',
  SENTINELONE_SINGULARITY = "SentineloneSingularity",
  ENTRA = 'MicrosoftEntraId',
  SNOWFLAKE = 'snowflake',
  V_CENTER = 'vCenter',
  WATCH_GUARD_FIREBOX = 'watchGuardFirebox',
  WINDOWS_EVENT_LOGS = 'WindowsEventLogs',
  OPA = 'OpenPolicyAgent',
  TIQ_AUTH = 'TIQAuthentication',
  FORTI_SIEM = 'fortiSiem',
  WATCHGUARD_FIREBOX = 'watchGuardFirebox',
  GENERIC_SYSLOG = 'GenericSyslog',
  OPA_CONFIG = 'OpenPolicyAgentConfiguration',
  LDAP_CONFIG = 'LDAPGroupConfiguration',
  TIQ_ACTIVITY_LOG = "TIQActivityLogs",
  GINGER_ACTIVITY_LOG = "GingerActivityLogs",
  KNOWBILITY_ACTIVITY_LOG = "KnowbilityActivityLogs",
  TIQ_PERMIT_USER_LIST = "TIQPermITUserList",
  TIQ_EXTERNAL_USER_LIST = "TIQExternalUserList",
  GOOGLE_WORKSPACE_ALERT = "GoogleWorkspaceAlert"
}

