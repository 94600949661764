<div 
  page
  [appPagePaths]="['org', 'app-registration-upgrade']"
  [disableOrganizationSelector]="true"
>
  <ui-card *ngIf="true">
    <app-registration-update-body
                                  [loading]="loading"
                                  [apps]="apps"
                                  (appClick)="displayPrompt($event)"
                                  body>

    </app-registration-update-body>

  </ui-card>
</div>

<ui-dialog
           *ngIf="showActivationPrompt"
           [headtitle]="'iam.app-registration.upgrade.confirmation.prompt.title' | t"
           [actionButton]="'iam.app-registration.upgrade.confirmation.prompt.activate' | t"
           [cancelButton]="'iam.app-registration.upgrade.confirmation.prompt.cancel' | t"
           [message]="activationMessage"
           (onButtonClick)="sendActivationRequest($event)">