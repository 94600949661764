export enum EntityTypeEnum {
  DATABASE_INSTANCE = "database-instance",
  FILE = "file",
  INSTANCE = "instance",
  NETWORK_INTERFACE = "network-interface",
  SECURITY_GROUP = "security-group",
  GROUP = "group",
  MAIL = "mail",
  USER = "user",
  OTHER = "other",
  ADDRESS = "address"
}
