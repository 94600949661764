export interface ILinearFunctionParams {
  b: number;
  a: number;
}

export class RegressionUtil {
  public static calculateRegression(dataSource: any[], xParamName: string, yParamName: string): ILinearFunctionParams {
    const sumOfX = dataSource.reduce((sum, current) => sum + current[xParamName], 0);
    const sumOfY = dataSource.reduce((sum, current) => sum + current[yParamName], 0);

    const sumOfProducts =
      dataSource.length * dataSource.reduce((sum, current) => sum + current[xParamName] * current[yParamName], 0) -
      sumOfX * sumOfY;

    const sumOfSquaresX =
      dataSource.length * dataSource.reduce((sum, current) => sum + current[xParamName] ** 2, 0) - sumOfX ** 2;

    const b = sumOfProducts / sumOfSquaresX;

    const a = (sumOfY - b * sumOfX) / dataSource.length;

    return {
      b,
      a,
    };
  }
}
