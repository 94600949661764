<ui-toolbar-filter
  [locale]="locale"
  [showEraseAll]="hasActiveFilters()"
  (onClearAllFilters)="clearFilters()"
  [total]="total || 0"
  toolbar
>

  <div
      toolbar-collapsable-element
      [elementTitle]="'common.indicators' | t"
  >

      <div class="flex-box center-horizontal">

        <ui-multi-select
          [attr.data-test-id]="'indicator_filter'"
          [multiSelectData]="indicatorsValues"
          (onOptionSelect)="applyIndicatorsFilter($event)"
        ></ui-multi-select>

        <ui-toggle
            class="margin--small--left"
            [labelEnabled]="'respond.incident.history.filter.notification.action' | t"
            [labelDisabled]="'respond.incident.history.filter.notification.action' | t"
            [tooltip]="'respond.incident.history.filter.notification.action.tooltip' | t"
            [toggled]="isNotificationActionSwitchToggled"
            [disabledWhenUntoggled]="false"
            [ignoreLabelClicks]="false"
            (onClick)="applyNotificationActionsFilter($event)"
        ></ui-toggle>
      </div>

  </div>

</ui-toolbar-filter>