import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { OrganizationSensitiveFilesSettings, OrganizationSettings, OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { DlpActivationComponent } from '../card-contents/dlp-activation/dlp-activation.component';

@Component({
  selector: 'dlp-settings',
  templateUrl: './dlp-settings.component.html',
  styleUrls: ['./dlp-settings.component.scss']
})
export class DlpSettingsComponent {
  @ViewChild('activation') public activationComponent: DlpActivationComponent
  @Input() public currentSettings: OrganizationSettings & OrganizationShareSettings & OrganizationSensitiveFilesSettings;
  @Input() public isLoading = false;

  constructor() { }

}
