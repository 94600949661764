<div class="text">
  <p
    style="white-space: pre-line"
    [innerHTML]="
      (hasProtection ? 'settings.labels.card.description.2' : 'settings.labels.card.description.2.noProtection') | t
    "
  ></p>
  <collapsable-description
    *ngIf="hasProtection"
    [title]="'settings.labels.card.details.protection.title' | t"
    [icon]="'assets/favicons/icon_shield_lock.svg'"
  >
    <ul>
      <li>
        <div [innerHTML]="'settings.labels.card.details.protection.contentMarking.title' | t"></div>
        <ol>
          <li [innerHTML]="'settings.labels.card.details.protection.contentMarking.footers' | t"></li>
          <li [innerHTML]="'settings.labels.card.details.protection.contentMarking.water' | t"></li>
        </ol>
      </li>
      <li>
        <p [innerHTML]="'settings.labels.card.details.protection.encryption.title' | t"></p>
        <ol>
          <li [innerHTML]="'settings.labels.card.details.protection.encryption.manual' | t"></li>
          <li>
            <p [innerHTML]="'settings.labels.card.details.protection.encryption.automatic' | t"></p>
          </li>
        </ol>
      </li>
    </ul>
  </collapsable-description>
  <p style="white-space: pre-line; padding-top: 1rem">{{ 'settings.labels.card.description.default.disabled' | t }}</p>
</div>
