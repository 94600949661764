<div class="ui-table-search-tool--input-group">
  <img *ngIf="withIcon" class="ui-table-search-tool--icon" src="{{ 'assets/favicons/icon_search.svg' }}"/>
  <input class="ui-table-search-tool--input"
         [placeholder]="placeholder | t"
         [value]="query"
         (input)="handleQuery($event)"
         [disabled]="disabled" />

  <ui-tooltip *ngIf="showTooltip" 
    class="ui-table-search-tool--tooltip"
    [placementRight]="tooltipPlacementRight">{{tooltipText}}</ui-tooltip>

  <button class="ui-table-search-tool--clear"
          type="button"
          (click)="handleClear()"
          *ngIf="hasValue">
    <ui-icon
      class="ui-table-search-tool--clear-icon"
      name="close"
      modifier="OnHighlight"
    ></ui-icon>
  </button>
</div>
