import { Eco } from "@common/definitions/eco";
import { IListToolboxActionsResponse, IToolboxAction, IToolboxActionDropdownOption, IToolboxActionParameterUIField, IToolboxActionParameters, IToolboxActionTemplate, IToolboxParameterLabels } from "./actions-toolbox.definition";

function mapListActionsResponse(response: IListToolboxActionsResponse, locale: Eco.Languages): IToolboxAction[] {
  return response.items.map(actionItem => ({
    id: actionItem.action,
    name: actionItem.name,
    description: actionItem.description,
    applications: actionItem.applications,
    parameters: mapInputParameters(actionItem, response.parameters, response.labels, locale),
    executionState: { isRunning: false, resultHtml: null }
  }));
}

function mapInputParameters(actionItem: IToolboxActionTemplate, parameters: IToolboxActionParameters, labels: IToolboxParameterLabels, locale: Eco.Languages): IToolboxActionParameterUIField[] {
  return actionItem.parameterKeys
    .map<IToolboxActionParameterUIField>(key => ({
      key,
      label: labels[key] ? labels[key][locale] : key,
      value: null,
      isValid: false,
      isRequired: !actionItem.optionalParameters.includes(key),
      isArrayParam: actionItem.parameterArrays.includes(key),
      dropdownOptions: mapDropdownOptions(parameters?.[key], actionItem.defaultValues[key]) || [],
    }))
    .sort((a, b) => a.key.localeCompare(b.key));
}

function mapDropdownOptions(parameters: string | string[], defaults: string | string[]): IToolboxActionDropdownOption[] {
  const paramsArray: string[] = getStringArray(parameters);
  const defaultsArray: string[] = getStringArray(defaults);

  const uniqueOptions: string[] = mergeArrays(paramsArray, defaultsArray);
  return uniqueOptions.map(option => ({
    value: option,
    isDefault: defaultsArray.includes(option),
  }));
}

function getStringArray(items: string | string[]): string[] {
  if (typeof items === 'string' || typeof items === 'number') {
    return [String(items)];
  } else if (Array.isArray(items)) {
    return items.map(item => String(item));
  }
  return [];
}

function mergeArrays(array1: string[], array2: string[]): string[] {
  return [...new Set([...array1, ...array2])];
}


export { mapListActionsResponse };