<div class="icon-container">
  <img
    *ngIf="usecase?.whitelistCount > 0"
    class="icon-pos-1"
    [class.fixedGridDisplay]="fixedGridDisplay"
    alt=""
    [src]="whitelistIcon"
    [tooltip]="'org_usecase.table.column.whitelist.tooltip' | t"
  >

  <img
    *ngIf="usecase?.filterCount > 0"
    class="icon-pos-2"
    [class.fixedGridDisplay]="fixedGridDisplay"
    alt=""
    [src]="filterIcon"
    [tooltip]="'org_usecase.table.column.filter.tooltip' | t"
  >

  <img *ngIf="usecase?.hiddenFilters"
    class="icon-pos-3"
    [class.fixedGridDisplay]="fixedGridDisplay"
    [src]="hiddenFilterIcon"
    [tooltip]="'org_usecase.table.column.filter.hidden.tooltip' | t"
  >

  <img
    *ngIf="usecase?.hasBlacklistedMonitor"
    class="icon-pos-4"
    [class.fixedGridDisplay]="fixedGridDisplay"
    alt=""
    [src]="blacklistedIcon"
    [tooltip]="'org_usecase.table.column.blacklisted.tooltip' | t"
  >

  <img
    *ngIf="usecase?.hasSilencedMonitor"
    class="icon-pos-5"
    [class.fixedGridDisplay]="fixedGridDisplay"
    alt=""
    [src]="silencedIcon"
    [tooltip]="'org_usecase.table.column.silenced.tooltip' | t"
  >
</div>