import { Component, Input } from '@angular/core';
import { IComputedEscalation } from '@common/services/api/respond/incidents/incidents.definitions';
import { CommunicationApi } from 'projects/@common/services/api/respond/communication/communication.api';
import { IEscalationParameters } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';

@Component({
  selector: 'escalade-list-section',
  templateUrl: './escalade-list.component.html',
  styleUrls: [ './escalade-list.component.scss' ],
})
export class EscaladeListComponent {

  @Input() incident: Incident;

  @Input() computedEscalationList: IComputedEscalation | null;

  public escalationParameters: IEscalationParameters;

  constructor(private commnunicationApi: CommunicationApi) { }

  ngOnChanges(): void {
    this.getEscalationListParameters();
  }

  private getEscalationListParameters(): void {
    this.commnunicationApi.listEscalationParameters({ organizationId: this.incident?.organizationId })
      .subscribe(
        (response) => {
          this.escalationParameters = response;
        },
        (error) => {}
      );
  }
}
