import { Component, Input, ViewChild } from '@angular/core';
import { AbstractDrawer } from '@common/modules/layout/components/drawer/AbstractDrawer';
import { Store } from '@ngxs/store';
import { UiTabs } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { ISecretIntegrations } from 'projects/@common/services/api/tools/secrets/secrets.definitions';
import { ISecret } from '../../components/secret-store-details/secret-store-details.form';

@Component({
  selector: 'secret-store-drawer',
  templateUrl: './secret-store-drawer.container.html',
})
export class SecretStoreDrawerContainer extends AbstractDrawer {
  @Input() public data: { secret: ISecret, title: string, templates: ISecretIntegrations[], ecoId: string };

  @ViewChild('tabs', { static: false }) tabs: UiTabs;

  constructor(private readonly store: Store) {
    super(store);
  }
}
