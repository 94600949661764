import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";

import { RoleDrawerContainer } from "./drawer/iam-role-drawer/role-drawer.container";
import { SearchMode } from "projects/@common/modules/layout/components/page/page.component";
import { ProfilesApi } from "projects/@common/services/api/iam/profiles/profiles.api";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { UiTableDirection } from "@ui-kit/components/ui-table/ui-table.component";
import { LanguageEnum } from "@ui-kit/interfaces/ILanguage";
import { AccumulatorsState, Accumulator, Accumulatables } from "@common/modules/accumulator/accumulator.store";
import { TranslatedObjectPipe } from "@common/modules/i18n/translatedObject.pipe";
import { DrawerPageEnum } from "@common/modules/layout/components/drawer/drawerPage.enum";
import { DrawerService } from "@common/modules/layout/components/drawer/services/drawer.service";
import { IamApi } from "@common/services/api/iam/iam.api.definitions";
import { Eco } from "@common/definitions/eco";
import { I18nService } from "@common/modules/i18n/i18n.service";

@Component({
  selector: 'role',
  templateUrl: './role.container.html',
  styleUrls: [ './role.container.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class RoleContainer implements OnInit {
  public roles: Eco.List<IamApi.Profile.listResponse>;
  public isLoading = false;
  public searchQuery = '';
  public locale = this.i18nService.currentLocale as LanguageEnum || LanguageEnum.ENGLISH;
  public tableDef = [
    {
      label: this.i18nService.translate('iam.roles.list.label.name'),
      field: 'name',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      width: '325px',
    },
    {
      label: this.i18nService.translate('iam.roles.description.label'),
      field: 'description',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
    },
    {
      label: this.i18nService.translate('iam.roles.count.label'),
      field: 'userCount',
      type: UiStaticTableRowType.TEXT,
      width: '135px',
    },
    {
      label: this.i18nService.translate('iam.roles.security-group.label'),
      field: 'securityGroupsCount',
      type: UiStaticTableRowType.TEXT,
      width: '190px',
    },
  ];
  public searchMode = SearchMode.IAM;
  public organizationId: string | null = null;
  @Select(AccumulatorsState.accumulators) private accumulators$: Observable<Accumulator[]>;

  private accumulatorSubscription: Subscription;
  private currentAccumulatorCount: number;
  private userLanguage = this.i18nService?.currentLocale || Eco.Languages.FR;

  public isVarMode: boolean = false;

  constructor(
    public i18nService: I18nService,
    private translatedObjectPipe: TranslatedObjectPipe,
    private drawerService: DrawerService,
    private profilesApi: ProfilesApi,
    private store: Store
  ) {
    // We currently do not have the applicative rights to fetch users from a tenant.
    // Disabling var mode on this component.
    // this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
    this.isVarMode = false;
  }

  ngOnInit() {
    this.handleAccumulator();

    if (!this.isVarMode) {
      this.handleRefresh();
    }
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    this.drawerService.hideDrawer();
    if (org) {
      this.organizationId = org.id;
      this.handleRefresh();
    } else {
      this.organizationId = null;
    }
  }

  public handleRefresh(): void {
    this.isLoading = true;
    if (this.roles) {
      this.roles.items = [];
    }
    this.profilesApi
      .listProfiles({
        organizationId: this.organizationId,
        params: {
          order: UiTableDirection.Asc,
          searchName: this.searchQuery,
          nameLanguage: this.searchQuery ? this.userLanguage : null,
        },
      })
      .then((data) => {
        this.roles = data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public handleRowClick(row: any): void {
    this.drawerService.replaceCurrentDrawer(RoleDrawerContainer, {
      component: DrawerPageEnum.roleDrawer,
      data: {
        title: this.translatedObjectPipe.transform(row.name),
        organizationId: this.organizationId,
        row,
      },
    });
  }

  public handleSearchClear(): void {
    this.searchQuery = null;
    this.handleRefresh();
  }

  public handleSearchQuery(searchQuery: string): void {
    this.searchQuery = searchQuery;
    this.handleRefresh();
  }

  private handleAccumulator(): void {
    this.accumulatorSubscription = this.accumulators$.subscribe((accumulators) => {
      const count = accumulators
        .filter((accumulator) => accumulator.accumulatable === Accumulatables.IAM_ROLE_LIST)
        .shift()?.accumulator;

      if (count !== this.currentAccumulatorCount) {
        this.handleRefresh();
        this.currentAccumulatorCount = count;
      }
    });
  }
}
