<ui-tooltip
  [width]="'300px'"
  [placementRight]="true"
  [placementBottom]="true"
  [isSmall]="true"
>
  <div class="tooltip-title flex-box center-horizontal">
    <ui-icon
      class="tooltip-title--icon"
      [name]="'confidentiality_breach_green'"
      [size]="'medium'"
    ></ui-icon>
    <strong class="tooltip-title--text">
      {{'incidents.container.page.details.tab.detail.summary.confidentiality.tooltip.title' |t}}
    </strong>
  </div>
  <div class="tooltip-content">
    {{ 'incidents.container.page.details.tab.detail.summary.confidentiality.tooltip.content' | t}}
  </div>
</ui-tooltip>