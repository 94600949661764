import { Component, Input, OnInit } from '@angular/core';
import { Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';

@Component({
  selector: 'whitelist-connector-name',
  templateUrl: './whitelist-connector-name.component.html',
  styleUrls: [ './whitelist-connector-name.component.scss' ],
})
export class WhitelistConnectorNameComponent implements OnInit {
  @Input() whitelist: Whitelist;

  public connectorNames: string[] = [];

  constructor() { }

  ngOnInit(): void {
    const usecases = this.whitelist.usecases || [];
    this.connectorNames = [ ...new Set(usecases.map((usecase) => `${usecase.datasourceType}`)) ];
  }
}
