import { Component, OnInit } from '@angular/core';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { TimeUtil } from '@ui-kit/services/time-util';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { UsersApiService } from 'projects/@common/services/api/sg/user/userApi.service';
import { OrphanGuestUser } from '../../services/guest-users.definition';

@Component({
  selector: 'guest-users-table',
  templateUrl: './guest-users-table.component.html',
  styleUrls: [ './guest-users-table.component.scss' ],
})
export class GuestUsersTableComponent implements OnInit {
  private readonly LIMIT = 50;

  public orphanGuestUsers: OrphanGuestUser[] = [];
  public dataForCSV: OrphanGuestUser[];

  public isLoading = false;

  public fulltext: string = null;

  public total = 0;

  private from = 0;

  private csvFrom = 0;

  public direction = 'asc';

  public sort = 'email';

  public columnsDef = [
    {
      label: 'guest.users.table.column.email',
      field: 'email',
      type: UiStaticTableRowType.TEXT,
      width: '150px',
      isTruncated: true,
    },
    {
      label: 'guest.users.table.column.name',
      field: 'name',
      enumPrefix: 'groupTemplates.type.',
      type: UiStaticTableRowType.TEXT,
      width: '125px',
      isTruncated: true,
    },
    {
      label: 'guest.users.table.column.requester',
      field: 'requester',
      type: UiStaticTableRowType.TEXT,
      width: '150px',
      isTruncated: true,
    },
    {
      label: 'guest.users.table.column.invitedOn',
      field: 'invitedOn',
      type: UiStaticTableRowType.PIPED_FIELD,
      pipe: DTPipe,
      width: '150px',
    },
    {
      label: 'guest.users.table.column.orphanSince',
      field: 'orphanSince',
      type: UiStaticTableRowType.DATE_AND_HOUR_AND_AGO,
      width: '200px',
      timeOnly: true,
      noSeconds: true,
      getColumnCustomCss:
        (row: any) => TimeUtil.getNumberOfDaysBetweenTwoDates(Date.now(), row.orphanSince) > (this.guestExpirationDuration * 7)
          ? { color: '#de3618' }
          : null,
    },
  ];

  public columnsDefTranslated: any;
  private guestExpirationDuration = 4;

  constructor(
    public i18n: I18nService,
    private usersApiService: UsersApiService,
    private staticTableDataMapper: StaticTableDataMapper,
    private dtPipe: DTPipe
  ) { }

  public ngOnInit(): void {
    this.handleRefresh();
  }

  private loadMoreTemplates(params: { from?: number, orderBy?: string, direction?: string, limit?: number; }, populateTableData = true): void {
    this.isLoading = true;
    this.usersApiService.listOrgOrphanGuestUsers(params)
      .then((response) => {
        if (populateTableData) {
          this.orphanGuestUsers = this.orphanGuestUsers.concat(response.items);
          this.total = response.total;
          this.from = response.nextItem;
          this.guestExpirationDuration = response.guestExpirationDuration;
        } else {
          this.dataForCSV = this.dataForCSV.concat(response.items);
          this.csvFrom = response.nextItem;
          this.total = response.total;
          this.generateCSVReport();
        }
      })
      .finally(() => {
        [ this.columnsDefTranslated, this.orphanGuestUsers ] = this.staticTableDataMapper.translate(this.columnsDef, this.orphanGuestUsers);
        this.isLoading = false;
      });
  }

  public handleRefresh(): void {
    this.from = 0;
    this.orphanGuestUsers = [];
    this.loadMoreTemplates({ from: this.from, orderBy: this.sort, direction: this.direction, limit: this.LIMIT });
  }


  public handleTypeFilter(type: any): void {
    this.handleRefresh();
  }

  public handleSorting(sort: string, direction: string): void {
    this.sort = sort;
    this.direction = direction;
    this.handleRefresh();
  }

  public onScrollToBottom(): void {
    if (!this.isLoading && this.orphanGuestUsers.length < this.total) {
      this.loadMoreTemplates({ from: this.from, orderBy: this.sort, direction: this.direction, limit: this.LIMIT });
    }
  }

  public getDataForReport(): void {
    this.csvFrom = 0;
    this.dataForCSV = [];
    this.loadMoreTemplates({ from: this.csvFrom, orderBy: 'orphanSince', direction: UiTableDirection.Desc }, false);
  }

  public generateCSVReport(): void {
    let csvContent = '\uFEFF';

    for (const column of this.columnsDefTranslated) {
      csvContent += `${column.label.toUpperCase()},`;
    }
    csvContent = csvContent.substr(0, csvContent.length - 1);
    csvContent += '\n';

    this.dataForCSV.forEach((item) => {
      csvContent += `${item.email},${item.name},${item.requester || 'N/A'},${item.invitedOn},${item.orphanSince}\n`;
    });

    // Specify encoding directly in Blob options
    const blob = new Blob([ csvContent ], { type: 'text/csv; charset=utf-8;' });
    blob.text().then((response) => {
      const url = URL.createObjectURL(blob); // No need to create another Blob here

      const downloadLink = document.createElement('a');
      downloadLink.href = url;

      // Set the charset to UTF-8 in the Content-Disposition header
      downloadLink.download = `${this.dtPipe.transform(Date.now(), { withTime: false, locale: this.i18n.currentLocale })} - ${this.i18n.translate('guest.users.table.download.report.name')}.csv`;

      downloadLink.click();

      URL.revokeObjectURL(url);
      downloadLink.remove();
    });
  }
}
