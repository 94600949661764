<div class="card-container">
  <div class="card">
    <div class="flex-box title-container">
      <h2
        class="title"
        *ngIf="title"
      >{{title}}</h2>
      <ui-tooltip
        *ngIf="uiTooltip"
        style="margin-left: 2px;"
        [isSmall]="true"
      >{{uiTooltip}}</ui-tooltip>
    </div>
    <ng-content></ng-content>
  </div>
</div>