import { DateTimePeriod } from "@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component";
import { DataConnectorTypes } from '@md.eco/connectors';

export enum IDaysTypeEnum {
  WEEKDAYS = 'WEEKDAYS',
  HOLIDAYS = 'HOLIDAYS',
  WEEKEND = 'WEEKEND'
}

export enum IWorkShiftEnum {
  DAY = 'DAY',
  EVENING = 'EVENING',
  NIGHT = 'NIGHT'
}

export enum IProrityEnum {
  P1 = '1',
  P2 = '2',
  P3 = '3',
  P4 = '4'
}

export interface IKpiFilters {
  period: DateTimePeriod;
  dayTypes: IDaysTypeEnum[];
  workShifts: IWorkShiftEnum[];
  priorities: IProrityEnum[];
  connectors: DataConnectorTypes[];
  users: string[];
  organizations: string[];
}

export interface IKpiQueryParams {
  dateFrom?: number;
  dateTo?: number;
  dayTypes?: IDaysTypeEnum[];
  workShifts?: IWorkShiftEnum[];
  priorities?: IProrityEnum[];
  connectors?: string[];
  ownerIds?: string[];
  organizationIds?: string[];
}

export interface IKpiTimeItem {
  id: string;
  shortId: string;
  organizationId: string
}

export interface IKpiTime {
  avg: number; // milliseconds
  median: number; // milliseconds
  min: number; // milliseconds
  max: number; // milliseconds
  minItem: IKpiTimeItem;
  maxItem: IKpiTimeItem;
}

export interface IKpiItemValue {
  date: string;
  alerts: number;
  incidents: number;
  weekend: boolean;
  weekday: boolean;
  holiday: boolean;
}

export interface IGetKpisResponse {
  assignation: IKpiTime;
  notification: IKpiTime;
  finalization: IKpiTime;
  totals: {
    alerts: number;
    incidents: number;
    closed: number;
    closedExpected: number;
    closedFalsePositive: number;
    activeUsecaseAtStart: number;
    activeUsecaseAtEnd: number;
  },
  timezone: string;
};

export interface IGetKpisHistogramResponse {
  items: IKpiItemValue[];
  timezone: string;
};

export type IGetKpisTopsValueResponse = {
  id: string;
  value: string;
  count: number;
};

export type IGetKpisTopsResponse = {
  incidentPerOrganization: IGetKpisTopsValueResponse[];
  incidentPerOwner: IGetKpisTopsValueResponse[];
  openIncidentPerOrganization: IGetKpisTopsValueResponse[];
  openIncidentPerOwner: IGetKpisTopsValueResponse[];
  timezone: string;
};
