import { Component, Input, OnInit } from '@angular/core';
import { IToolboxActionDropdownOption, IToolboxActionParameterUIField } from 'projects/@common/services/api/respond/actions-toolbox/actions-toolbox.definition';
import { randomId } from 'projects/@common/utils/utils';

interface IArrayItem {
  id: string;
  value: string;
}

@Component({
  selector: 'incident-action-input',
  templateUrl: './incident-action-input.component.html',
  styleUrls: [ './incident-action-input.component.scss' ],
})
export class IncidentActionInputComponent implements OnInit {
  @Input() actionParameter: IToolboxActionParameterUIField;
  @Input() isExecuteActionAvailable: boolean;

  public dropdownOptions: string[];
  public arrayItems: IArrayItem[] = [];

  constructor() { }

  ngOnInit(): void {
    if (this.actionParameter.dropdownOptions.length > 0 && this.actionParameter.isArrayParam) {
      this.actionParameter.dropdownOptions.forEach((option: IToolboxActionDropdownOption) => {
        this.addValue(option.value);
      });
    } else if (this.actionParameter.dropdownOptions.length === 1 && !this.actionParameter.isArrayParam) {
      this.addValue(this.actionParameter.dropdownOptions[0].value);
    } else if (this.actionParameter.dropdownOptions.length > 1) {
      const defaultOption = this.actionParameter.dropdownOptions.find((option: IToolboxActionDropdownOption) => option.isDefault);
      const value = defaultOption?.value ?? this.actionParameter.value as string ?? null;
      this.addValue(value);
    } else {
      this.addValue(null);
    }

    this.dropdownOptions = this.actionParameter.dropdownOptions.map((option: IToolboxActionDropdownOption) => option.value);
  }

  public get dropdownOptionsToIgnore(): string[] {
    return this.actionParameter.isArrayParam ? this.arrayItems.map((item) => item.value) : [];
  }

  public handleInputChange(value: string, arrayItem: IArrayItem): void {
    arrayItem.value = value;
    this.setActionParameterValue();
  }

  public addValue(defaultValue: string): void {
    this.arrayItems.push({
      id: randomId(),
      value: defaultValue ?? null,
    });
    this.setActionParameterValue();
  }

  public deleteValue(arrayItem: IArrayItem): void {
    const index = this.arrayItems.findIndex((i: IArrayItem) => i.id === arrayItem.id);
    this.arrayItems.splice(index, 1);
    this.setActionParameterValue();
  }

  public trackByFn(index: number, arrayItem: IArrayItem) {
    return arrayItem.id;
  }

  private cleanValue(value: string): string {
    return value ? value.trim() : null;
  }

  private setActionParameterValue(): void {
    this.actionParameter.value = this.actionParameter.isArrayParam
      ? this.arrayItems.map((item: IArrayItem) => this.cleanValue(item.value))
      : this.cleanValue(this.arrayItems[0].value);

    this.setActionParameterValidity();
  }

  private setActionParameterValidity(): void {
    if (Array.isArray(this.actionParameter.value)) {
      this.actionParameter.isValid = this.actionParameter.isRequired
        ? this.actionParameter.value.every((value: string) => !!value)
        : true;
    } else {
      this.actionParameter.isValid = this.actionParameter.isRequired
        ? !!this.actionParameter.value
        : true;
    }
  }
}
