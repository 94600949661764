<div class="escalation-table" *ngIf="!isLoading">
  <div class="title-container">
    <img [src]="'assets/favicons/communication/icon_communication_tab_list.svg'">
    <h3>{{ computedEscalationList?.title || incident.escalationLists[escalationListSchedule]?.name }}</h3>
  </div>
  <table>
    <thead>
      <tr>
        <td class="cell levels-column">{{'respond.communication.tab.escalationList.level.label' | t}}</td>
        <td class="cell contacts-column">Contact</td>
        <td class="cell channels-column">{{'respond.communication.tab.settings.escalation.table.channels' | t}}</td>
        <td class="cell delays-column">{{'respond.communication.tab.settings.escalation.table.delay' | t}}</td>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="computedEscalationList">
        <ng-container *ngFor="let escalationLevel of computedEscalationList.levels; let i=index">
          <tr>
            <td class="cell">
              <p>{{ escalationLevel.level }}</p>
            </td>
            <td class="cell">
              <p *ngFor="let contact of escalationLevel?.contacts" class="contact">
                {{ contact.name }}
              </p>
            </td>
            <td class="cell">
              <ng-container *ngFor="let contact of escalationLevel?.contacts">
                <div class="labels-container">
                  <ng-container *ngFor="let communicationMethod of contact.selectedCommunicationMethods">
                    <div class="label mail" [tooltip]="getcommunicationMethodTooltipContent(communicationMethod)">
                      <span class="value">{{ getContactCommunicationMethods([communicationMethod])[0].translation }}
                        <span *ngIf="communicationMethod.properties.phoneNumber || communicationMethod.properties.email  || communicationMethod.properties.note">:</span>
                        {{ communicationMethod.properties.phoneNumber || communicationMethod.properties.email || communicationMethod.properties.note }}
                        <span *ngIf="communicationMethod.properties?.ext">ext.: {{ communicationMethod.properties.ext }}</span>
                      </span>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </td>
            <td class="cell">
              <p>
                {{ displayTimestamp(escalationLevel.notifyAt) }}
              </p>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!computedEscalationList">
        <ng-container *ngFor="let escalationLevel of incident.escalationLists[escalationListSchedule].escalationLevels; let i=index">
          <tr>
            <td class="cell">
              <p>{{ escalationLevel.level }}</p>
            </td>
            <td class="cell">
              <p *ngFor="let contact of escalationLevel?.contacts" class="contact">
                {{ contact.name }}
              </p>
            </td>
            <td class="cell">
              <ng-container *ngFor="let contact of escalationLevel?.contacts">
                <div class="labels-container">
                  <ng-container *ngFor="let communicationMethod of contact.selectedCommunicationMethods">
                    <div class="label mail" [tooltip]="getcommunicationMethodTooltipContent(communicationMethod)">
                      <span class="value">{{ getContactCommunicationMethods([communicationMethod])[0].translation }}
                        <span *ngIf="communicationMethod.properties.phoneNumber || communicationMethod.properties.email  || communicationMethod.properties.note">:</span>
                        {{ communicationMethod.properties.phoneNumber || communicationMethod.properties.email || communicationMethod.properties.note }}
                        <span *ngIf="communicationMethod.properties?.ext">, ext. {{ communicationMethod.properties.ext }}</span>
                      </span>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </td>
            <td *ngIf="nextBusinessDay" class="cell">
              <p>{{ 'incidents.container.page.details.tab.detail.summary.escalationList.delaysNextdays' | t:{ time: nextBusinessDayValues } }}</p>
            </td>
            <td *ngIf="!nextBusinessDay" class="cell">
              <p>
                {{ 'incidents.container.page.details.tab.detail.summary.escalationList.delays' | t:
                { number: priorityDelayTimestamp * escalationLevel.level | timeDuration:{ prefix: false, language: i18n.currentLocale} } }}
              </p>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-container *ngIf="isLoading">
  <ui-spinner size="medium" modifier="secondary"></ui-spinner>
</ng-container>
