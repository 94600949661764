import { HttpClient } from '@angular/common/http';
import { EcoUrlService } from '../../core/url.service';
import { Eco } from '../../../../definitions/eco';
import { UrlUtils } from '../../../../utils/utils';
import { ISecret, ISecretApi } from './secrets.definitions';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';

export class CustomerSecretsApiService extends EcoApi implements ISecretApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.tools;

  constructor(http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listSecrets(organizationEcoId: string, params: { from?: number; size?: number; order?: string; searchName?: string; searchType?: string; }): Promise<Eco.List<ISecret>> {
    const query = UrlUtils.jsonToQueryParams({
      from: params.from,
      size: params.size,
      order: params.order,
      searchName: params.searchName,
      searchType: params.searchType,
    });
    return this.http.get<Eco.List<ISecret>>(`${this.host}/secrets?${query}`).toPromise();
  }

  public describeSecret(organizationEcoId: string, secret: string): Promise<ISecret> {
    return this.http.get<ISecret>(`${this.host}/secrets/${secret}`).toPromise();
  }

  public updateSecret(organizationEcoId: string, secret: any): Promise<ISecret> {
    return this.http.put<ISecret>(`${this.host}/secrets/${secret.id}`, secret).toPromise();
  }


  public deleteSecret(organizationEcoId: string, secret: string): Promise<any> {
    return this.http.delete<any>(`${this.host}/secrets/${secret}`).toPromise();
  }

  public createSecret(organizationEcoId: string, secret: any): Promise<ISecret> {
    return this.http.post<ISecret>(`${this.host}/secrets`, secret).toPromise();
  }
}
