import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { CommunicationMethodTypeEnum, ICommunicationContact, IEscalationList } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { normalizeString } from 'projects/@common/utils/utils';

@Component({
  selector: 'communication-tab-contacts',
  templateUrl: './communication-tab-contacts.component.html',
  styleUrls: [ './communication-tab-contacts.component.scss' ],
})
export class CommunicationTabContactsComponent implements OnChanges {
  @Input() contacts: ICommunicationContact[];
  @Input() escalationLists: IEscalationList[];
  @Input() isLoading: boolean;
  @Input() isVarMode: boolean;

  @Output() saveContactEvent = new EventEmitter<ICommunicationContact>();
  @Output() deleteContactEvent = new EventEmitter<ICommunicationContact>();
  @Output() refreshContactsEvent = new EventEmitter<void>();

  displayedContactsList: ICommunicationContact[];

  filters = {
    searchText: '',
  };

  constructor(
    public readonly i18n: I18nService,
    public readonly notice: NoticeService,
    public readonly displayService: DisplayService
  ) { }

  get hasEditPermission(): boolean {
    return this.displayService.meetsRequirements({ permissions: [ 'can_write_escalation' ] });
  }

  get hasDeletePermission(): boolean {
    return this.displayService.meetsRequirements({ permissions: [ 'can_delete_escalation_contact' ] });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contacts) {
      this.filterDisplayedContacts();
    }
  }

  trackById(index: number, item: ICommunicationContact) {
    return item.id;
  }

  saveContact(contact: ICommunicationContact) {
    this.saveContactEvent.emit(contact);
  }

  deleteContact(contact: ICommunicationContact) {
    this.deleteContactEvent.emit(contact);
  }

  canAddNewContact(): boolean {
    return !this.isLoading && !this.contacts.some((contact) => !contact.id);
  }

  addNewContact() {
    if (this.canAddNewContact()) {
      this.contacts.unshift({
        id: null,
        organizationId: null,
        name: null,
        position: "",
        communicationMethods: [ { type: CommunicationMethodTypeEnum.EMAIL, id: null, contactId: null, properties: { email: null } } ],
      });
      this.filterDisplayedContacts();
    }
  }

  refreshSearch() {
    this.refreshContactsEvent.emit();
  }

  handleSearchTextChange(value: string) {
    this.filters.searchText = value;
    this.filterDisplayedContacts();
  }

  filterDisplayedContacts() {
    const priority = {
      "NEW": 0,
      "OTHER": 1,
    };

    const normalizedSearchText = normalizeString(this.filters.searchText);
    this.displayedContactsList = this.contacts
      .filter((contact) => !contact.id || normalizeString(contact.name).includes(normalizedSearchText))
      .sort((a, b) => {
        const priorityA = !a.id ? priority.NEW : priority.OTHER;
        const priorityB = !b.id ? priority.NEW : priority.OTHER;
        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        }
        return a.name.localeCompare(b.name);
      });
  }
}
