import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { DateTimePeriod } from '@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component';
import { RequestDirection } from 'projects/@common/definitions/consoles.enum';
import { MultiSelectDataFactory } from "projects/@common/modules/i18n/component-wrapper/multi-select-data.factory";
import { ManagedOrganization } from 'projects/@common/services/api/detect/organizations/connectors-api-organizations';
import { IncidentFilterIndicatorsEnum, IncidentFilterStatusEnum, IncidentPriorityEnum, IncidentPriorityEnumDisplay, ListOwnersResponse } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { GetIncidentsRequest, GetIncidentsSortElements, getDefaultIncidentsDateFrom, getDefaultIncidentsOrderBy } from "projects/@common/services/api/respond/incidents/models/getIncidentsRequest";
import { IDaysTypeEnum, IWorkShiftEnum } from 'projects/@common/services/api/respond/kpi/kpi.definitions';
import { mapArrayToObject, pruneObject } from 'projects/@common/utils/utils';
import { Subject } from "rxjs";

export interface ITimeInterval {
  dateFrom?: number;
  dateTo?: number;
  useUpdatedAtDate?: boolean;
}

export enum IncidentsFilterName {
  name = 'name',
  id = 'id',
  priority = 'priority',
  indicators = 'indicators',
  status = 'status',
  date = 'date',
  organization = 'organization',
  owner = 'owner',
  workShifts = 'workShifts',
  dayTypes = 'dayTypes',
  connector = 'connector',
  observable = "observable"
}

@Injectable()
export class IncidentFiltersService {
  public ownersList: ListOwnersResponse[] = [];
  public organizationsList: ManagedOrganization[] = [];
  public tableSorting: GetIncidentsSortElements;

  private _prioritiesValues: MultiSelectData;
  private _indicatorsValues: MultiSelectData;
  private _statusValues: MultiSelectData;
  private _ownerValues: MultiSelectData;
  private _organizationValues: MultiSelectData;
  private _dayTypeOptions: MultiSelectData;
  private _workShiftOptions: MultiSelectData;

  private _incidentNameFilter: string = null;
  private _incidentIdFilter: string = null;
  private _observableFilter: string = null;
  private _prioritiesFilter: IncidentPriorityEnum[] = null;
  private _indicatorsFilter: IncidentFilterIndicatorsEnum[] = null;
  private _statusFilter: IncidentFilterStatusEnum[] = null;
  private _dateFilter: ITimeInterval = null;
  private _organizationFilter: string[] = null;
  private _ownerFilter: string[] = null;
  private _connectorFilter: string[] = null;
  private _dayTypesFilter: IDaysTypeEnum[] = null;
  private _workShiftsFilter: IWorkShiftEnum[] = null;

  private _visibleFilters: IncidentsFilterName[] = null;

  private _activeFilterList = {
    [IncidentsFilterName.id]: false,
    [IncidentsFilterName.name]: false,
    [IncidentsFilterName.priority]: false,
    [IncidentsFilterName.indicators]: false,
    [IncidentsFilterName.status]: false,
    [IncidentsFilterName.date]: false,
    [IncidentsFilterName.owner]: false,
    [IncidentsFilterName.workShifts]: false,
    [IncidentsFilterName.dayTypes]: false,
    [IncidentsFilterName.connector]: false,
    [IncidentsFilterName.observable]: false,
    [IncidentsFilterName.organization]: false,
  };

  private _filteringChange$ = new Subject<GetIncidentsRequest>();

  constructor(
    private readonly multiSelectFactory: MultiSelectDataFactory,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) { }

  public initializeIncidentsFilters(visibleFilters: IncidentsFilterName[]): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams) {
      this._statusFilter = queryParams.statuses ? queryParams.statuses : null;
      this._prioritiesFilter = queryParams.priorities ? queryParams.priorities : null;
      this._incidentNameFilter = queryParams.incidentName ? queryParams.incidentName : null;
      this._incidentIdFilter = queryParams.shortId ? queryParams.shortId : null;
      this._observableFilter = queryParams.observable ? queryParams.observable : null;
      this._organizationFilter = queryParams.organizationIds ? queryParams.organizationIds : null;
      this._ownerFilter = queryParams.ownerIds ? queryParams.ownerIds : null;
      this._connectorFilter = queryParams.connectors ? queryParams.connectors : null;
      this._dayTypesFilter = queryParams.dayTypes ? queryParams.dayTypes : null;
      this._workShiftsFilter = queryParams.workShifts ? queryParams.workShifts : null;
      this._dateFilter = {
        dateFrom: queryParams.dateFrom ? queryParams.dateFrom : getDefaultIncidentsDateFrom(),
        dateTo: queryParams.dateTo ? queryParams.dateTo : null,
        useUpdatedAtDate: queryParams.useUpdatedAtDate ? queryParams.useUpdatedAtDate : null,
      };
      this.tableSorting = {
        orderBy: queryParams.orderBy || getDefaultIncidentsOrderBy(),
        direction: queryParams.direction || RequestDirection.desc,
      };

      this._indicatorsFilter = [];
      if (queryParams.withWhitelistedAlert) {
        this._indicatorsFilter.push(IncidentFilterIndicatorsEnum.WHITELISTED_ALERT);
      }
      if (queryParams.withAsset) {
        this._indicatorsFilter.push(IncidentFilterIndicatorsEnum.CRITICAL_ASSET);
      }
      if (queryParams.breachOfConfidentiality) {
        this._indicatorsFilter.push(IncidentFilterIndicatorsEnum.CONFIDENTIALITY_BREACH);
      }

      this._visibleFilters = visibleFilters;
      this.updateActiveFilters();
    }

    this.ownersList = null; // async loaded
    this.organizationsList = null; // async loaded
    this.initializeMultiSelects();
  }

  public get filteringChange$(): Subject<GetIncidentsRequest> {
    return this._filteringChange$;
  }

  // values
  public get dayTypeOptions(): MultiSelectData {
    return this._dayTypeOptions;
  }

  public get workShiftOptions(): MultiSelectData {
    return this._workShiftOptions;
  }

  public get prioritiesValues(): MultiSelectData {
    return this._prioritiesValues;
  }

  public get indicatorsValues(): MultiSelectData {
    return this._indicatorsValues;
  }

  public get statusValues(): MultiSelectData {
    return this._statusValues;
  }

  public get ownerValues(): MultiSelectData {
    return this._ownerValues;
  }

  public get organizationValues(): MultiSelectData {
    return this._organizationValues;
  }

  public get incidentFilter(): string {
    return this._incidentNameFilter;
  }

  public get incidentIdFilter(): string {
    return this._incidentIdFilter;
  }

  public get dateFilter(): ITimeInterval {
    return this._dateFilter;
  }

  public get connectorFilter(): string[] {
    return this._connectorFilter;
  }

  public get observableFilter(): string {
    return this._observableFilter;
  }


  // Filter applying

  public applyStatusFilter(event: IncidentFilterStatusEnum[]): void {
    if (event === null || event.length === 0) {
      this.clearStatusFilter();
    } else {
      this._statusFilter = event;
      this.emitNewQueries();
    }
  }

  public applyIndicatorsFilter(event: IncidentFilterIndicatorsEnum[]): void {
    if (event === null || event.length === 0) {
      this.clearIndicatorsFilter();
    } else {
      this._indicatorsFilter = event;
      this.emitNewQueries();
    }
  }

  public applyPrioritiesFilter(event: IncidentPriorityEnum[]): void {
    if (event === null || event.length === 0) {
      this.clearPriorityFilter();
    } else {
      this._prioritiesFilter = event;
      this.emitNewQueries();
    }
  }

  public applyIncidentFilter(event: string): void {
    if (event === null || event === '') {
      this.clearIncidentFilter();
    } else {
      this._incidentNameFilter = event.toUpperCase();
      this.emitNewQueries();
    }
  }

  public applyIncidentIdFilter(event: string): void {
    if (event === null) {
      this.clearIncidentIdFilter();
    } else {
      this._incidentIdFilter = event;
      this.emitNewQueries();
    }
  }

  public applyObservableFilter(event: string): void {
    if (event === null || event === '') {
      this.clearObservableFilter();
    } else {
      this._observableFilter = event;
      this.emitNewQueries();
    }
  }

  public applyOrgFilter(event: string[]): void {
    if (event === null || event.length === 0) {
      this.clearOrgFilter();
    } else {
      this._organizationFilter = event;
      this.emitNewQueries();
    }
  }

  public applyOwnerFilter(event: string[]): void {
    if (event === null || event.length === 0) {
      this.clearOwnerFilter();
    } else {
      this._ownerFilter = event;
      this.emitNewQueries();
    }
  }

  public applyConnectorsFilter(event: string[]): void {
    if (event === null || event.length === 0) {
      this.clearConnectorsFilter();
    } else {
      this._connectorFilter = event;
      this.emitNewQueries();
    }
  }

  public applyDateFilter(event: DateTimePeriod): void {
    this._dateFilter = {
      ...this._dateFilter,
      dateFrom: event?.dateFrom || null,
      dateTo: event?.dateTo || null,
    };
    this.emitNewQueries();
  }

  public applyDateFilterCheckbox(event: boolean): void {
    this._dateFilter = {
      ...this._dateFilter,
      useUpdatedAtDate: event,
    };
    this.emitNewQueries();
  }

  public applyDayTypes(event: IDaysTypeEnum[], emitEvent = true): void {
    if (event === null || event.length === 0) {
      this.clearDayTypesFilter();
    } else {
      this._dayTypesFilter = event;
      this.emitNewQueries();
    }
  }

  public applyWorkShifts(event: IWorkShiftEnum[], emitEvent = true): void {
    if (event === null || event.length === 0) {
      this.clearWorkShiftsFilter();
    } else {
      this._workShiftsFilter = event;
      this.emitNewQueries();
    }
  }

  // Filter reset
  public clearDayTypesFilter(): void {
    this._dayTypesFilter = null;
    this.emitNewQueries();
  }

  public clearWorkShiftsFilter(): void {
    this._workShiftsFilter = null;
    this.emitNewQueries();
  }

  public clearStatusFilter(): void {
    this._statusFilter = null;
    this.emitNewQueries();
  }

  public clearIndicatorsFilter(): void {
    this._indicatorsFilter = null;
    this.emitNewQueries();
  }

  public clearConnectorsFilter(): void {
    this._connectorFilter = null;
    this.emitNewQueries();
  }

  public clearIncidentFilter(): void {
    this._incidentNameFilter = null;
    this.emitNewQueries();
  }

  public clearIncidentIdFilter(): void {
    this._incidentIdFilter = null;
    this.emitNewQueries();
  }

  public clearObservableFilter(): void {
    this._observableFilter = null;
    this.emitNewQueries();
  }

  public clearPriorityFilter(): void {
    this._prioritiesFilter = null;
    this.emitNewQueries();
  }

  public clearDateFilter(): void {
    this._dateFilter = null;
    this.emitNewQueries();
  }

  public clearOrgFilter(): void {
    this._organizationFilter = null;
    this.emitNewQueries();
  }

  public clearOwnerFilter(): void {
    this._ownerFilter = null;
    this.emitNewQueries();
  }

  public getActiveFiltersRequest(): GetIncidentsRequest {
    const request = new GetIncidentsRequest({
      statuses: this._statusFilter,
      workShifts: this._workShiftsFilter,
      dayTypes: this._dayTypesFilter,
      indicators: this._indicatorsFilter,
      priorities: this._prioritiesFilter,
      incidentName: this._incidentNameFilter,
      shortId: this._incidentIdFilter,
      ownerIds: this._ownerFilter,
      dateFrom: this._dateFilter?.dateFrom,
      dateTo: this._dateFilter?.dateTo,
      useUpdatedAtDate: this._dateFilter?.useUpdatedAtDate,
      organizationIds: this._organizationFilter,
      connectors: this._connectorFilter,
      observable: this._observableFilter,
    });
    if (this.tableSorting) {
      request.setSort(this.tableSorting);
    }
    return request;
  }

  public emitNewQueries(): void {
    this.filteringChange$.next(this.getActiveFiltersRequest());
    this.updateActiveFilters();
  }

  public get nbFilterApplied(): number {
    return Object.values(this._activeFilterList).filter((value) => value).length;
  }

  public clearAllFilters(): void {
    this._statusFilter = null;
    this._indicatorsFilter = null;
    this._incidentIdFilter = null;
    this._prioritiesFilter = null;
    this._dateFilter = null;
    this._incidentNameFilter = null;
    this._organizationFilter = null;
    this._ownerFilter = null;
    this._dayTypesFilter = null;
    this._workShiftsFilter = null;
    this._connectorFilter = null;
    this._observableFilter = null;
    this.updateActiveFilters();
    this.applyFiltersInQueryParams();
  }

  public applyFiltersInQueryParams(): void {
    const request: GetIncidentsRequest = this.getActiveFiltersRequest();
    const params = pruneObject(request.getQueryParams());
    // the incident-list component is subscribed to activatedRoute.queryParams and will fetch the data when the params change
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params, replaceUrl: true });
  }

  public initOwnersMultiSelect(owners: ListOwnersResponse[]): void {
    this._ownerValues = this.multiSelectFactory.create(mapArrayToObject(owners, 'ownerName', 'ownerId'), this.ensureArray(this._ownerFilter), "", true);
  }

  public initOrganizationsMultiSelect(organizations: ManagedOrganization[]): void {
    this._organizationValues = this.multiSelectFactory.create(mapArrayToObject(organizations, 'name', 'organization_respond_id'), this.ensureArray(this._organizationFilter), "", true);
  }

  private initializeMultiSelects(): void {
    this._dayTypeOptions = this.multiSelectFactory.create(IDaysTypeEnum, this.ensureArray(this._dayTypesFilter) || [], 'incidents.container.page.filter.dayTypes.');
    this._workShiftOptions = this.multiSelectFactory.create(IWorkShiftEnum, this.ensureArray(this._workShiftsFilter) || [], 'incidents.container.page.filter.workShift.');
    this._statusValues = this.multiSelectFactory.create(IncidentFilterStatusEnum, this.ensureArray(this._statusFilter), 'incidents.container.page.filter.status.');
    this._indicatorsValues = this.multiSelectFactory.create(IncidentFilterIndicatorsEnum, this.ensureArray(this._indicatorsFilter), 'incidents.container.page.filter.indicators.', false, true);
    this._prioritiesValues = this.multiSelectFactory.create(IncidentPriorityEnumDisplay, this.ensureArray(this._prioritiesFilter), 'incidents.container.page.filter.priority.');
    this.initOwnersMultiSelect(this.ownersList);
    this.initOrganizationsMultiSelect(this.organizationsList);
  }

  private ensureArray(value: any) {
    if (!value) {
      return [];
    }
    if (value && !Array.isArray(value)) {
      return [ value ];
    }
    return value;
  }

  private updateActiveFilters(): void {
    this._activeFilterList = {
      [IncidentsFilterName.id]: Boolean(this._incidentIdFilter && this._visibleFilters.includes(IncidentsFilterName.id)),
      [IncidentsFilterName.name]: Boolean(this._incidentNameFilter && this._visibleFilters.includes(IncidentsFilterName.name)),
      [IncidentsFilterName.priority]: Boolean(this._prioritiesFilter && this._visibleFilters.includes(IncidentsFilterName.priority)),
      [IncidentsFilterName.indicators]: Boolean(this._indicatorsFilter?.length && this._visibleFilters.includes(IncidentsFilterName.indicators)),
      [IncidentsFilterName.status]: Boolean(this._statusFilter && this._visibleFilters.includes(IncidentsFilterName.status)),
      [IncidentsFilterName.date]: Boolean((this._dateFilter?.dateFrom || this._dateFilter?.dateTo) && this._visibleFilters.includes(IncidentsFilterName.date)),
      [IncidentsFilterName.owner]: Boolean(this._ownerFilter?.length && this._visibleFilters.includes(IncidentsFilterName.owner)),
      [IncidentsFilterName.dayTypes]: Boolean(this._dayTypesFilter && this._visibleFilters.includes(IncidentsFilterName.dayTypes)),
      [IncidentsFilterName.workShifts]: Boolean(this._workShiftsFilter && this._visibleFilters.includes(IncidentsFilterName.workShifts)),
      [IncidentsFilterName.connector]: Boolean(this._connectorFilter && this._visibleFilters.includes(IncidentsFilterName.connector)),
      [IncidentsFilterName.organization]: Boolean(this._organizationFilter?.length && this._visibleFilters.includes(IncidentsFilterName.organization)),
      [IncidentsFilterName.observable]: Boolean(this._observableFilter && this._visibleFilters.includes(IncidentsFilterName.observable)),
    };
  }
}
