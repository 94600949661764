<app-incident-details-base-layout>
  <h2 class="title">{{ 'incidents.container.page.details.tab.detail.metadata.title' | t}}</h2>

  <!-- Created At -->
  <div class="margin-bottom">
    <label ui-label>{{'incidents.container.page.details.tab.detail.metadata.createdAt' | t}}</label>
    <span>{{incident.createdAt | dt: {locale: i18nService.currentLocale, withTime: true, withSeconds: true} }}</span>
  </div>

  <!-- Created By -->
  <div *ngIf="isVarMode" class="margin-bottom">
    <label ui-label>{{'incidents.container.page.details.tab.detail.metadata.createdBy' | t}}</label>
    <span>{{incident.createdByName}}</span>
  </div>

  <!-- Closed At -->
  <div
    class="margin-bottom"
    *ngIf="incident?.closedAt"
  >
    <label ui-label>{{'incidents.container.page.details.tab.detail.metadata.closeDate' | t}}</label>
    <span>{{incident.closedAt | dt: {locale: i18nService.currentLocale, withTime: true, withSeconds: true} }}</span>
  </div>

  <!-- Closed At Manual (Optional override of automatic closed date) -->
  <div
    class="margin-bottom"
    *ngIf="incident?.closedAt && incident?.manualClosedAt"
  >
    <label ui-label>{{'incidents.container.page.details.tab.detail.metadata.manualClosedAt' | t}}</label>
    <span>{{incident.manualClosedAt | dt: {locale: i18nService.currentLocale, withTime: true, withSeconds: true} }}</span>
  </div>

  <!-- Collaboration Time (Time waited for client actions out of our control) -->
  <div
    class="margin-bottom"
    *ngIf="incident?.collaborationTimeMs"
  >
    <label ui-label>{{'incidents.container.page.details.tab.detail.metadata.client.waitingTime' | t}}</label>
    <span>{{incident.collaborationTimeMs | timeDuration }}</span>
  </div>

  <!-- Time To Finalize (Resolution time set when incident becomes in Finalizing status) -->
  <div
    class="margin-bottom"
    *ngIf="incident?.timeToFinalize"
    [tooltip]="'incidents.container.page.details.tab.detail.metadata.resolutionTime.tooltip' | t"
  >
    <label ui-label>{{'incidents.container.page.details.tab.detail.metadata.resolutionTime' | t}}</label>
    <span>{{incident.timeToFinalize | timeDuration }}</span>
  </div>

  <!-- Owner of this incident -->
  <div *ngIf="isVarMode">
    <label ui-label>{{'incidents.container.page.details.tab.detail.metadata.owner' | t}}</label>
    <span>{{incident?.ownerName}}</span>
  </div>

  <!-- List of Analysts working on the incident -->
  <div class="assigned-analysts margin-bottom">
    <ng-container *ngIf="!!assignedAnalysts?.length">
      <label
        ui-label
        class="margin-top"
      >{{'incidents.container.page.details.tab.detail.metadata.analyst' | t}}</label>
      <div
        class="analyst"
        *ngFor="let assignedAnalyst of assignedAnalysts"
      >
        <ui-avatar
          class="analyst--avatar"
          [user]="assignedAnalyst"
          size="tiny"
        ></ui-avatar>
        <p class="analyst--name">{{assignedAnalyst.name}}</p>
        <ui-icon
          *ngIf="isAllowedToEdit"
          class="analyst--delete"
          name="close-circle"
          modifier="gray"
          [tooltip]="'common.action.delete'| t"
          (click)="handleDeleteAnalyst(assignedAnalyst)"
        >
        </ui-icon>
      </div>
    </ng-container>
    <analyst-adder
      *ngIf="isAllowedToEdit"
      [availableAnalysts]="availableAnalysts"
      (selectionEvent)="handleAddAnalyst($event)"
    ></analyst-adder>
  </div>


</app-incident-details-base-layout>