import { color } from '@amcharts/amcharts4/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import {
  IOrganizationResourceTendency
} from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'admin-resource-compliant-percentage',
  templateUrl: './admin-resource-compliant-percentage.component.html',
  styleUrls: [ './admin-resource-compliant-percentage.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class AdminResourceCompliantPercentageComponent implements OnInit {
  private readonly COLOR_RED = '#DE3618';

  private readonly COLOR_GREEN = '#00d873';

  private readonly COLOR_GRAY = '#999';

  public stats: IOrganizationResourceTendency[];

  public isLoading = false;

  public resourcesNumberPercentageNoCompliant: any[] = [];

  public legendTemplate = '';

  public locale: LanguageEnum;

  constructor (private groupsApiService: GroupsApiService, private readonly i18n: I18nService, private router: Router) { }

  ngOnInit() {
    this.locale = this.i18n.currentLocale as LanguageEnum;
    this.isLoading = true;
    this.groupsApiService
      .listOrgResourceTendency()
      .then((orgStats) => {
        this.stats = orgStats.organizationResources;
        this.formatData();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private formatData() {
    this.resourcesNumberPercentageNoCompliant = [];
    if (this.stats.length) {
      this.legendTemplate = `{name}: {value} ${this.i18n.translate('common.resources')} [{color2}]({value.percent})%[/]`;

      this.resourcesNumberPercentageNoCompliant.push({
        level: this.i18n.translate('dashboard.admin.resource.percentage.legend.nonCompliant'),
        count: this.stats[0]?.atRisk,
        color: color(this.COLOR_RED),
        color2: color(this.COLOR_GRAY),
      });

      this.resourcesNumberPercentageNoCompliant.push({
        level: this.i18n.translate('dashboard.admin.resource.percentage.legend.compliant'),
        count: this.stats[0]?.total,
        color: color(this.COLOR_GREEN),
        color2: color(this.COLOR_GRAY),
      });
    }
  }
}
