<div
  page
  [appPagePaths]="['user', 'mdAssistOps', 'resourceShares']"
  [useMobileHeader]="true"
  [returnIcon]="true"
  [specialPageName]="'shares.resourceShares' | t: { resource: resourceName }"
  scrollToBottom
  (onScrollToBottom)="onScroll()"
  [disableOrganizationSelector]="true"
>
  <shared-files-table #table [isFromConsoleOrg]="true"></shared-files-table>
</div>
