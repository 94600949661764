  <div
  class="ui-table-filter-tool--trigger multi-select-trigger"
  (click)="toggle()">
  <button
    ui-button
    class="ui-table-filter-tool--button filter-button"
    color="linkBordedRegular"
    modifier="gray"
    custom-icon="true"
    isSvg="true"
    icon="icon_filter"
    [disabled]="disabled"
    [ngStyle]="{'min-width': !mobileService.isMobile() ? '150px' : null}"
    [wide]="!mobileService.isMobile()">
    {{ !mobileService.isMobile() ? multiSelectData?.selectedOptionsTranslated  : null }}
  </button>
</div>

<div
  class="multi-select-options"
  [ngClass]="{ 'multi-select-options--left-orientation': leftOrientation }"
  *ngIf="showOptions"
  uiAccessibleChoiceListDirective
  [choices]="multiSelectData.options"
  (choiceSelectedEmitter)="checkOption($event.choice)"
>
  <div
    *ngFor="let option of multiSelectData.options; let first = first; let last = last"
    class="multi-select-options--option accessible-choice"

    [ngClass]="{
      'multi-select-options--option--first': first,
      'multi-select-options--option--last': last
    }"
    (click)="checkOption(option)">
    <div class="multi-select-options--option--checkbox">
      <ui-checkbox
        [isEditable]="true"
        [checked]="option.isSelected"></ui-checkbox>
    </div>

    {{ option.translated }}
  </div>
</div>

<ng-template #mobile>
  <div
    *ngFor="let option of multiSelectData.options"
    class="multi-select-options--mobile"
    (click)="checkOption(option)">
    <ui-checkbox
      [checked]="option.isSelected"
      [isEditable]="true"></ui-checkbox>

    {{ option.translated }}
  </div>
</ng-template>
