import { Component, Input } from '@angular/core';
import { OrganizationSettings, OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';

@Component({
  selector: 'shares-settings',
  templateUrl: './shares-settings.component.html',
  styleUrls: [ './shares-settings.component.scss' ],
})
export class SharesSettingsComponent {
  @Input() public currentSettings: OrganizationSettings & OrganizationShareSettings;
  @Input() public isLoading = false;

  constructor() { }
}
