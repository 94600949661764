import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MobileService } from '@common/services/mobile.service';
import { ModalService } from '@ui-kit/services/modal.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { StorageService } from 'projects/@common/services/storage.service';

@Component({
  selector: 'carousel-dialog',
  templateUrl: './carousel-dialog.component.html',
  styleUrls: ['./carousel-dialog.component.scss'],
})
export class CarouselDialogComponent implements OnInit {
  public locale = this.i18n.currentLocale;
  public index = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { isNewClient: boolean; isRestrictedUser?: boolean },
    private i18n: I18nService,
    private modalService: ModalService,
    private storage: StorageService,
    public mobileService: MobileService
  ) {}

  ngOnInit(): void {
    this.storage.setLocal('carouselOpen', 'false'); // Reset to ensure it always starts from the first slide
  }

  public closeDialog(): void {
    this.storage.setLocal('carouselOpen', 'true');
    this.modalService.closeDialog();
  }
}
