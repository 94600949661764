<ng-container *ngIf="currentState === stateEnum.LOADING; else readyView">
  <strong>{{"common.state.loading" | t}}</strong>
  <ui-spinner></ui-spinner>
</ng-container>


<ng-template #readyView>
  <app-playbook-update-header
    [playbook]="oldPlaybook"
    [organization]="organization"
    (browserGoBackEvent)="cancel()"
  ></app-playbook-update-header>

  <app-playbook-update-version-comparator
    #versionComparator
    [oldPlaybook]="oldPlaybook"
    [newPlaybook]="newPlaybook"
    [mdTaskTemplates]="mdTaskTemplates"
  ></app-playbook-update-version-comparator>

  <app-playbook-update-action-buttons
    [currentState]="currentState"
    (saveEvent)="updatePlaybook()"
    (cancelEvent)="cancel()"
  ></app-playbook-update-action-buttons>
</ng-template>


<ng-container *ngIf="currentState === stateEnum.ERROR">
  <p>{{"common.error.retry" | t}}&nbsp;:</p>
  <pre>{{errorMessage}}</pre>
</ng-container>