<div class="card-content">
  <settings-edition-card
    icon="icon_pause_file_green.svg"
    [title]="'settings.dlp.activation.title' | t"
    [canSave]="!isActivated || (activationForm?.valid && openedUrl)"
    [canEdit]="true"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading || isLocalLoading">
      <ui-spinner class="spinner" size="medium"></ui-spinner>
    </ng-container>

    <ng-container *ngIf="!(isLoading || isLocalLoading)">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <p [innerHTML]="'settings.dlp.activation.description' | t"></p>
    <div class="labels">
      <div>
        <p
          [innerHTML]="
            'settings.dlp.activation.state' | t: { state: isActivated, engine: currentEngineDisplay }
          "
        ></p>
        <p
          *ngIf="activationForm?.value.engine === 'QoHash'"
          [innerHTML]="'settings.dlp.activation.qohash.currentSecret' | t: { secret: currentSettings.secretId }"
        ></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editing>
  <p class="edit-description" [innerHTML]="'settings.dlp.activation.description' | t"></p>
  <ui-toggle
    [toggled]="isActivated"
    [labelEnabled]="'settings.notification.silencing.toggle.activated' | t"
    [labelDisabled]="'settings.notification.silencing.toggle.deactivated' | t"
    (onClick)="toggleSilencingActivation()"
  ></ui-toggle>

  <div *ngIf="isActivated" class="engine-picker" [formGroup]="activationForm">
    <div>
      <label ui-label [required]="true">{{ 'settings.dlp.engine.label' | t }}</label>
      <mat-radio-group formControlName="engine">
        <mat-radio-button *ngFor="let engine of engines" (click)="selectEngine(engine)" [value]="engine.value">
          <div>{{ engine.displayValue }}</div>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="activationForm?.value.engine === 'QoHash'">
      <div class="engine-picker--text">
        <label ui-label [required]="true">{{ 'settings.dlp.qohash.secret.label' | t }}</label>
        <ui-tooltip> {{ 'settings.dlp.qohash.secret.tooltip' | t }} </ui-tooltip>
      </div>

      <div class="qo-hash" [ngClass]="{ 'qo-hash--spaced': hasSecretConfigured }">
        <div>
          <ui-select
            *ngIf="hasSecretConfigured"
            [options]="secrets"
            [group]="activationForm"
            name="secret"
            control="secretId"
          >
          </ui-select>
        </div>

        <button ui-button color="standard" (click)="openSecretCreationDrawer()">
          {{ 'settings.dlp.qohash.secret.create' | t }}
        </button>
      </div>
    </div>

    <ng-container *ngIf="activationForm?.value.engine === 'Purview'">
      <ng-container *ngIf="currentSettings.grantedPurviewConsent || !purviewAppConsentUrl">
        <p>{{ 'settings.dlp.purview.already.granted' | t }}</p>
      </ng-container>
      <ng-container *ngIf="!currentSettings.grantedPurviewConsent && purviewAppConsentUrl">
        <p> {{ 'settings.dlp.purview.why.grant.text.1' | t }} </p>
        <p> {{ 'settings.dlp.purview.why.grant.text.2' | t }} </p>
        <div class="engine-picker--text">
          <button
            [title]="'settings.dlp.purview.globaladmin.is' + (!isGlobalAdmin ? '.not' : '') | t"
            [disabled]="!isGlobalAdmin"
            ui-button
            color="standard"
            (click)="openConsentUrl()"
          >
            {{ 'settings.dlp.purview.button.signup' | t }}
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
