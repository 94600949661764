<app-edit-with-modal
  #editor
  [noMargin]="false"
  [leftOrientation]="true"
>

  <div
    class="add-button-container"
    (click)="toggle()"
  >
    <img
      alt="edit-icon"
      class="pointer"
      src="assets/favicons/icon_add_circle.svg"
    />
    <span class="add-text">{{'incidents.container.page.details.tab.detail.metadata.analyst.add' | t}}</span>
  </div>

  <div
    class="dropdown-analyst-container"
    content
  >
    <autocomplete
      *ngIf="isDropdownOpen"
      class="analyst-autocomplete"
      [canMultiSelect]="false"
      [oneItemAtATime]="true"
      [placeholder]="'incidents.container.page.details.tab.detail.metadata.analyst.add.placeholder' | t"
      name="autocomplete"
      [customValues]="autocompleteItems"
      [autocompleteType]="autocompleteType"
      (onItemSelected)="handleSelection($event?.value)"
    ></autocomplete>

    <ui-confirmations-buttons
      class="analyst-confirmation-buttons"
      (confirmationEvent)="handleConfirmationEvent($event)"
    ></ui-confirmations-buttons>

  </div>

  <ui-click-out-handler
    *ngIf="isDropdownOpen"
    (clickEvent)="handleConfirmationEvent(null)"
  ></ui-click-out-handler>
</app-edit-with-modal>