import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";

import { Store } from "@ngxs/store";
import { FieldMap } from "angular2-query-builder";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { ApiEndpointPrefixEnum } from "../../../../definitions/eco-api";
import { EcoUrlService } from "../../core/url.service";
import { IListFilterFieldsRequest } from "../filters/filters.definitions";
import { CustomerQueriesApi } from "./customer-queries.api";
import { ICreateCustomQueryRequest, IDeleteCustomQueryRequest, IDeleteCustomQueryResponse, IDescribeQueryRequest, IDescribeQueryResponse, IExecuteDefaultQueryRequest, IExecuteOrganizationQueryRequest, IExecuteQueryResponse, IGetBasicQueryRequest, IGetBasicQueryResponse, IListQueriesRequest, IQueriesApi, IQueryItem, IUpdateCustomQueryRequest } from "./queries.definitions";
import { VarQueriesApi } from "./var-queries.api";

@Injectable({
  providedIn: 'root',
})
export class QueriesApi implements IQueriesApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  private customerService: CustomerQueriesApi;
  private varService: VarQueriesApi;

  constructor(
    http: HttpClient,
    url: EcoUrlService,
    protected store: Store
  ) {
    this.customerService = new CustomerQueriesApi(http, url);
    this.varService = new VarQueriesApi(http, url);
  }

  public listQueries(request: IListQueriesRequest): Observable<ResponseUtils<IQueryItem>> {
    if (this.isVarMode) {
      return this.varService.listQueries(request);
    }
    return this.customerService.listQueries(request);
  }

  public describeQuery(request: IDescribeQueryRequest): Observable<IDescribeQueryResponse> {
    if (this.isVarMode) {
      return this.varService.describeQuery(request);
    }
    return this.customerService.describeQuery(request);
  }


  public createCustomQuery(request: ICreateCustomQueryRequest): Observable<IDescribeQueryResponse> {
    if (this.isVarMode) {
      return this.varService.createCustomQuery(request);
    }
    return this.customerService.createCustomQuery(request);
  }

  public updateCustomQuery(request: IUpdateCustomQueryRequest): Observable<IDescribeQueryResponse> {
    if (this.isVarMode) {
      return this.varService.updateCustomQuery(request);
    }
    return this.customerService.updateCustomQuery(request);
  }

  public deleteCustomQuery(request: IDeleteCustomQueryRequest): Observable<IDeleteCustomQueryResponse> {
    if (this.isVarMode) {
      return this.varService.deleteCustomQuery(request);
    }
    return this.customerService.deleteCustomQuery(request);
  }

  public getBasicQueryUsecase(request: IGetBasicQueryRequest): Observable<IGetBasicQueryResponse> {
    // get the rule set to transform the basic query into an advanced query
    if (this.isVarMode) {
      return this.varService.getBasicQueryUsecase(request);
    }
    return this.customerService.getBasicQueryUsecase(request);
  }

  public getAdvancedQueryAggregationFields(request: IListFilterFieldsRequest): Observable<FieldMap> {
    if (this.isVarMode) {
      return this.varService.getAdvancedQueryAggregationFields(request);
    }
    return this.customerService.getAdvancedQueryAggregationFields(request);
  }

  public executeDefaultQueryUsecase(request: IExecuteDefaultQueryRequest): Observable<IExecuteQueryResponse> {
    // execute a default query (preset query by Mondata)
    if (this.isVarMode) {
      return this.varService.executeDefaultQueryUsecase(request);
    }
    return this.customerService.executeDefaultQueryUsecase(request);
  }

  public executeOrganizationQueryUsecase(request: IExecuteOrganizationQueryRequest): Observable<IExecuteQueryResponse> {
    // execute a custom organization query (customized in advanced mode and saved by the user)
    if (this.isVarMode) {
      return this.varService.executeOrganizationQueryUsecase(request);
    }
    return this.customerService.executeOrganizationQueryUsecase(request);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
