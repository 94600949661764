import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IncidentTaskStatus } from 'projects/@common/services/api/respond/incidentTasks/incidentTasks.definitions';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { IDescribeIncidentResponse, IPostTaskObservablesRequest } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { IObservable } from 'projects/@common/services/api/respond/observables/observables.definition';
import { IncidentEventsService } from 'projects/@respond/incident/services/incident.events.service';
import { IObservablesPageContext } from '../observable-container/observable-container.component';
import { IUiMenuOptions } from '@ui-kit/components/ui-menu-options/ui-menu-options.component';
import { TimeUtil } from '@ui-kit/services/time-util';
import { ObservableCellService } from '../../services/observable.cell.service';

enum ObservablesMenuEnum {
  addToActiveTask = "addToActiveTask",
  copy = "copy",
  search_in_incidents = "search_in_incidents",
  search_in_alerts = "search_in_alerts",
  edit = "edit",
  delete = "delete"
}

enum ObservablesSearchInMode {
  search_in_incidents = "search_in_incidents",
  search_in_alerts = "search_in_alerts"
}

@Component({
  selector: 'observable-actions-cell',
  templateUrl: './observable-actions-cell.component.html',
  styleUrls: [ './observable-actions-cell.component.scss' ],
})
export class ObservableActionsCellComponent implements OnInit {
  @Input() observable: IObservable;
  @Input() pageContext: IObservablesPageContext;

  menuOptions: IUiMenuOptions[] = [];
  isConsoleAdmin: boolean;

  constructor(
    private readonly i18n: I18nService,
    private readonly noticeService: NoticeService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly incidentsApi: IncidentsApi,
    private observableCellService: ObservableCellService,
    @Optional() private readonly incidentEventService: IncidentEventsService,
    @Inject('CONSOLE_TYPE') public consoleType: ConsoleType
  ) {
    this.isConsoleAdmin = this.consoleType === ConsoleType.ADM;
  }

  ngOnInit(): void {
    this.menuOptions = [];
  }

  public get useRedFlagFeature(): boolean {
    return this.pageContext.viewParams.useRedFlagFeature;
  }

  public get isReadonly(): boolean {
    return !this.pageContext.incident?.["isEditable"];
  }

  public getRedFlaggedStatus(): boolean {
    return this.observableCellService.getRedFlaggedStatus(this.pageContext, this.observable);
  }

  public handleMenuOpened(): void {
    this.initMenuOptions();
  }

  public handleMenuOptionClicked(option: IUiMenuOptions): void {
    switch (option.id) {
      case ObservablesMenuEnum.addToActiveTask:
        if(!this.getRedFlaggedStatus()) {
          this.flagObservable();
        }
        else
        {
          this.noticeService.snack("incidents.task.observable.already.exists.error");
        }
        break;
      case ObservablesMenuEnum.copy:
        this.copyToClipboard();
        break;
      case ObservablesMenuEnum.search_in_incidents:
      case ObservablesMenuEnum.search_in_alerts:
        // These options are handled in the html template because they are regular URL links
        break;
      default:
        break;
    }
  }

  private flagObservable(): void {
    if (this.pageContext.incident) {
      const taskId = this.pageContext.incident.tasks.find((incidentTask) => incidentTask.task.status === IncidentTaskStatus.IN_PROGRESS)?.task?.id;
      if (!taskId) {
        this.noticeService.snack("incidents.task.no-active-task");
        return;
      }
      const request: IPostTaskObservablesRequest = {
        incidentId: this.pageContext.incident.id,
        incidentObservableId: this.observable.id,
        incidentTaskId: taskId,
        action: "add",
      };

      this.incidentsApi.postTaskObservables(this.pageContext.incident.organizationId, request).subscribe(
        (response: IDescribeIncidentResponse) => {
          (this.pageContext.incident as Incident).reloadIncident({
            tasks: response.tasks,
          });
          this.incidentEventService.emitToggleTaskFindingItem(request);
          this.noticeService.success("incidents.task.observable.add.success");
        },
        (error) => {
          this.noticeService.error("incidents.task.observable.add.error");
        }
      );
    }
  }

  private copyToClipboard(): void {
    try {
      let value = this.observable.value;
      if (typeof value === "object") {
        // you cannot copy an object into clipboard or it would be [object object]
        value = JSON.stringify(value, null, 0);
      }
      navigator.clipboard.writeText(value);
      this.noticeService.snack("common.action.copied");
    } catch (_) { }
  }

  private getUrlQueryParams(searchInMode: ObservablesSearchInMode): { [key: string]: string } {
    // to search observables we need to define the url query params for the routerLink
    const observableQuery = {
      observable: this.observable.value,
      dateFrom: null,
    };

    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams?.dateFrom) {
      observableQuery.dateFrom = queryParams.dateFrom;
    } else if (searchInMode === ObservablesSearchInMode.search_in_alerts && !this.pageContext.alert) {
      // if searching alert's observables from another page than alerts, default to # days to ensure results
      observableQuery.dateFrom = TimeUtil.getTimestampFromNowAndDays(7).dateFrom;
    }

    return observableQuery;
  }

  private getQueryParamsHandling(searchInMode: ObservablesSearchInMode): "merge" | "preserve" | "" {
    if (searchInMode === ObservablesSearchInMode.search_in_alerts && this.pageContext.alert) {
      // if searching alert's observables from alerts page, merge the query params
      return "merge";
    } else if (searchInMode === ObservablesSearchInMode.search_in_alerts && !this.pageContext.alert) {
      // if searching alert's observables from another page than alerts do not merge the query params
      return "";
    } else if (searchInMode === ObservablesSearchInMode.search_in_incidents && this.pageContext.incident) {
      // if searching incident's observables from incident page, merge the query params
      return "merge";
    } else if (searchInMode === ObservablesSearchInMode.search_in_incidents && !this.pageContext.incident) {
      // if searching incident's observables from another page than incidents do not merge the query params
      return "";
    }
    return null;
  }

  private initMenuOptions(): void {
    this.menuOptions = [
      {
        id: ObservablesMenuEnum.search_in_incidents,
        iconPath: "assets/favicons/observables/icon_menu_incident.svg",
        translation: this.i18n.translate("observables.menu.search.incidents"),
        routerLink: "/respond/incidents",
        queryParams: this.getUrlQueryParams(ObservablesSearchInMode.search_in_incidents),
        queryParamsHandling: this.getQueryParamsHandling(ObservablesSearchInMode.search_in_incidents),
      },
      {
        id: ObservablesMenuEnum.copy,
        iconPath: "assets/favicons/icon_material_copy.svg",
        translation: this.i18n.translate("observables.menu.copy"),
      },
    ];

    if (this.isConsoleAdmin) {
      this.menuOptions.unshift({
        id: ObservablesMenuEnum.search_in_alerts,
        iconPath: "assets/favicons/observables/icon_admin_menu_alerts.svg",
        translation: this.i18n.translate("observables.menu.search.alerts"),
        routerLink: "/respond/alerts",
        queryParams: this.getUrlQueryParams(ObservablesSearchInMode.search_in_alerts),
        queryParamsHandling: this.getQueryParamsHandling(ObservablesSearchInMode.search_in_alerts),
      });
    }

    if (this.useRedFlagFeature && !this.isReadonly) {
      this.menuOptions.unshift({
        id: ObservablesMenuEnum.addToActiveTask,
        iconPath: "assets/favicons/observables/icon_flag_grey.svg",
        // iconPath: "assets/favicons/icon_revision.svg",
        translation: this.i18n.translate("observables.menu.flag"),
      });
    }

    /*
    // phase 2: not implemented yet
    if (this.observable["isEditable"]) {
      this.menuOptions.push(...[
        {
          id: ObservablesMenuEnum.edit,
          iconPath: "assets/favicons/icon_modify_disabled.svg",
          translation: this.i18n.translate("observables.menu.edit"),
        },
        {
          id: ObservablesMenuEnum.delete,
          iconPath: "assets/favicons/icon_delete_gray.svg",
          translation: this.i18n.translate("observables.menu.delete"),
        },
      ]);
    }
    */
  }
}
