<ui-static-table
  [dataSource]="filteredPlaybooksTable"
  [columnsDef]="columnsDef"
  [isSelectingOnlyOneAtATime]="true"
  [addPaddingOnFirst]="false"
  [hoverable]="true"
  [canSelectOnlyOne]="true"
  [locale]="locale"
  [sort]="sortOrder"
  [direction]="sortDirection"
  [isCustomSorting]="true"
  [loading]="pbInventoryAction.isLoading"
  (on-row-click)="openPlaybookDetails($event)"
  (checkbox-event)="handleSelected($event)"
  (handleCustomSort)="handleSort($event)"
>
</ui-static-table>