import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersSharesContainer } from 'projects/@assist-ops/containers/users-shares/users-shares.container';
import { OrgResourcesSharesContainer } from './containers/org-resources-shares/org-resources-shares.container';
import { OrgSharedFilesContainer } from './containers/org-shared-files/org-shared-files.container';

const routes: Routes = [
  {
    path: 'users',
    component: UsersSharesContainer,
  },
  {
    path: 'resources',
    component: OrgResourcesSharesContainer,
  },
  {
    path: 'user',
    component: OrgSharedFilesContainer,
  },
  {
    path: 'resource',
    component: OrgSharedFilesContainer,
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class SharesRoutingModule {}
