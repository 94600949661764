<div
  page
  [appPagePaths]="['mdRespond', 'playbooks']"
  [hasTopSection]="true"
  [tooltipTitle]="'detection.playbook.title.tooltipTitle'"
  (organizationChanged)="organizationChanged($event)"
  [searchMode]="searchMode"
>
  <capacity-card
    topSection
    *ngIf="showOutdatedCard"
    logo="icon_menu_playbook_outdated"
    [loading]="pbInventoryAction.isLoading"
    numberColor="#DE3618"
    [capacityNumber]="pbInventoryAction?.outdatedTotal"
    [topDescription]="'detection.playbook.title' | t"
    [bottomDescription]="'detection.playbook.counter.text' | t"
  ></capacity-card>

  <ui-tabs #tabs [color]="listTabsColor" [defaultSelectedTabIndex]="tabsHandler.selectedTabIndex" (tabClicked)="playbookTabClick($event)">
    <ui-tab [tabTitle]="'detection.playbook.list.tab.customer.title' | t"> 
      <ng-container *ngIf="showTable && tabsHandler.selectedTabIndex === 0; else noOrganizationSelectedMessage;">
        <div class="inline-search-container">
          <ui-table-total-tool
            [locale]="i18n.currentLocale"
            [total]="pbInventoryAction.isLoading ? '?' : pbInventoryAction.total"
          ></ui-table-total-tool>
          <ui-table-search-tool
            [placeholder]="'ui.table-search-tool.placeholder' | t"
            [query]="pbInventoryAction.requestsQuery"
            (clear)="pbInventoryAction.handlePlaybookSearchClear()"
            (query)="pbInventoryAction.handlePlaybookSearchQuery($event)"
          ></ui-table-search-tool>
          <ui-multi-select
            *ngIf="viewParams.showFilterStatus"
            class="filterOutdatedPlaybook"
            [multiSelectData]="dataFilter"
            (onOptionSelect)="displayOutdatedTemplateFilter($event)"
          ></ui-multi-select>
          <ui-table-refresh-tool
            [loading]="pbInventoryAction.isLoading"
            (click)="handleRefresh()"
          ></ui-table-refresh-tool>
        </div>
    
        <div class="playbooks-table-container container">
          <div class="inline-justify-both-ends">
            <div class="inline-actions">
              <button
                *ngIf="canCreatePlaybook"
                ui-button
                color="linkFlat"
                [disabled]="false"
                [custom-icon]="true"
                [isSvg]="true"
                icon="icon_create"
                icon-size="medium"
                (click)="openCreateDialog()"
              >
                {{ 'datasources.table.action.create' | t }}</button>
              <button
                *ngIf="canDeletePlaybook && pbInventoryAction.currentSelectedPlaybook"
                ui-button
                color="linkFlat"
                [disabled]="false"
                [custom-icon]="true"
                [isSvg]="true"
                icon="icon_delete"
                icon-size="medium"
                (click)="deletePlaybook()"
              >
                {{ 'datasources.table.action.delete' | t }}
              </button>
            </div>
          </div>
          <playbooks-inventory-body-section
            [pbInventoryAction]="pbInventoryAction"
            [columnsDef]="viewParams.tableColumns"
            [sortOrder]="pbInventoryAction.sortOrder"
            [sortDirection]="pbInventoryAction.sortDirection"
            (onSorting)="handleSorting($event.sortColumn, $event.sortDirection)"
            (playbookClicked)="openPlaybookDetails($event)"
          ></playbooks-inventory-body-section>
        </div>
      </ng-container>
    </ui-tab>

    <ui-tab *ngIf="viewParams.showCatalog" [tabTitle]="'detection.playbook.list.tab.catalog.title' | t">
      <div 
        *ngIf="tabsHandler.selectedTabIndex === 1"
        class="inline-tools">
        <div class="inline-tools-left">
          <app-playbook-catalog-report-button
            [isWithinTableColumn]="false"
          ></app-playbook-catalog-report-button>
        </div>
        <div class="inline-tools-right">
          <ui-table-total-tool
            [locale]="i18n.currentLocale"
            [total]="pbCatalogAction.isLoading ? '?' : pbCatalogAction.total"
          ></ui-table-total-tool>
          <ui-table-search-tool
            [placeholder]="'ui.table-search-tool.placeholder' | t"
            [query]="pbCatalogAction.filterCatalogByName"
            (clear)="pbCatalogAction.handlePlaybookSearchClear()"
            (query)="pbCatalogAction.handlePlaybookSearchQuery($event, i18n.currentLocale)"
          ></ui-table-search-tool>
          <ui-table-refresh-tool
            [loading]="pbCatalogAction.isLoading"
            (click)="pbCatalogAction.handleRefresh()"
          ></ui-table-refresh-tool>
        </div>
      </div>
  
      <div class="playbooks-table-container container">
        <ui-static-table
          [dataSource]="playbooksCatalog"
          [columnsDef]="viewParams.catalogTableColumns"
          [isSelectingOnlyOneAtATime]="true"
          [addPaddingOnFirst]="false"
          [hoverable]="true"
          [canSelectOnlyOne]="true"
          [locale]="i18n.currentLocale"
          [isCustomSorting]="true"
          [loading]="pbCatalogAction.isLoading"
          (on-row-click)="catalogTableItemClickHandler($event)"
        ></ui-static-table>

        <div class="load-more-button-container" *ngIf="pbCatalogAction.hasMoreToLoad && !pbCatalogAction.isLoading">
          <button
            ui-button
            (click)="pbCatalogAction.fetchPlaybooksCatalog(true)"
          >{{'common.action.loadMore' | t}}</button>
        </div>
      </div>
    </ui-tab>
  </ui-tabs>

  <ng-template #noOrganizationSelectedMessage>
    <div class="no-org-transition">{{ 'organizations_select.notSelected.message' | t }}</div>
  </ng-template>
</div>
