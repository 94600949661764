import { HttpClient } from "@angular/common/http";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { IActifsApi, IAssetRepresentation, ICreateAssetRequest, ICreateAssetResponse, IDeleteAssetsRequest, IDeleteAssetsResponse, IDescribeAssetResponse, IDescribeAssetsRequest, IListAssetsRequest, IListAssetType, IUpdateAssetRequest, IUpdateAssetResponse } from "projects/@common/services/api/respond/actifs/actifs.definitions";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { UrlUtils } from "projects/@common/utils/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class CustomerActifsApi extends EcoApi implements IActifsApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  constructor(http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listAssets(request: IListAssetsRequest): Observable<ResponseUtils<IAssetRepresentation>> {
    const path = `${this.host}/me/assets`;
    const queryParams = UrlUtils.jsonToQueryParams(request);
    const url = queryParams ? `${path}?${queryParams}` : path;
    return this.http.get<IRespondResponse<IAssetRepresentation>>(url)
      .pipe(map((response) => new ResponseUtils<IAssetRepresentation>(response)));
  }

  public listAlertAssets(organizationId?: string, alertId?: string): Observable<ResponseUtils<IAssetRepresentation>> {
    throw new Error("Not implemented");
  }

  public listIncidentAssets(organizationId?: string, incidentId?: string): Observable<ResponseUtils<IAssetRepresentation>> {
    const url = `${this.host}/me/incidents/${incidentId}/assets`;
    return this.http.get<IRespondResponse<IAssetRepresentation>>(url)
      .pipe(map((response) => new ResponseUtils<IAssetRepresentation>(response)));
  }

  public describeAsset(request: IDescribeAssetsRequest): Observable<IDescribeAssetResponse> {
    const path = `${this.host}/me/assets/${request.assetId}`;
    return this.http.get<IDescribeAssetResponse>(path);
  }

  public listAssetTypes(organizationId: string): Observable<ResponseUtils<IListAssetType>> {
    const path = `${this.host}/me/assets/types`;
    const url = path;
    return this.http.get<IRespondResponse<IListAssetType>>(url)
      .pipe(map((response) => new ResponseUtils<IListAssetType>(response)));
  }

  public createAsset(request: ICreateAssetRequest): Observable<ICreateAssetResponse> {
    const url = `${this.host}/me/assets`;
    return this.http.post<ICreateAssetResponse>(url, request);
  }

  public updateAsset(request: IUpdateAssetRequest): Observable<IUpdateAssetResponse> {
    const url = `${this.host}/me/assets/${request.id}`;
    return this.http.put<IUpdateAssetResponse>(url, request);
  }

  public deleteAsset(request: IDeleteAssetsRequest): Promise<IDeleteAssetsResponse> {
    const url = `${this.host}/me/assets/${request.id}`;
    return this.http.delete<IDeleteAssetsResponse>(url).toPromise();
  }
}
