import { Injectable } from '@angular/core';
import { StaticTableDataMapper } from '@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { HistoriesApi } from '@common/services/api/respond/histories/histories.api';
import { GetHistoriesRequest } from '@common/services/api/respond/histories/models/getHistoriesRequest';
import { RequestDirection } from 'projects/@common/definitions/consoles.enum';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IHistory, ListHistoryOrderBy } from 'projects/@common/services/api/respond/histories/histories.definition';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { BehaviorSubject, PartialObserver, Subscription } from 'rxjs';
import { HistoriesIndicatorsComponent } from './components/histories-indicators/histories-indicators.component';
import { HistoryIndicatorsEnum } from './components/histories-filters/histories-filters.service';

export type ListHistoriesPageContext = {
  organizationId?: string;
};

@Injectable({
  providedIn: 'root',
})
export class HistoriesService {
  public historiesData: IHistory[];
  public total: number = 0;
  public isLoading: boolean = false;
  public canLoadMoreHistory: boolean = false;
  public from: number = 0;
  public historySize: number = 100;
  public organizationId: string;

  private onFilterChangeSubject = new BehaviorSubject<GetHistoriesRequest>(null);

  private _debounceTimeout: NodeJS.Timeout;

  private dateFrom: number;
  private dateTo: number;
  private owners: string[];
  private types: string[];
  private indicators: string[];
  private organizationIds: string[];
  constructor(
    private readonly historiesApi: HistoriesApi,
    private readonly noticeService: NoticeService,
    private readonly staticTableDataFactory: StaticTableDataMapper,
    private readonly i18nService: I18nService,
  ) {
    this.setDefaultSettings();
  }

  public onFilterChange(next: (value: GetHistoriesRequest) => void): Subscription {
    return this.onFilterChangeSubject.subscribe(next);
  }

  public setDefaultSettings(): void {
    this.isLoading = false;
    this.canLoadMoreHistory = false;
    this.historiesData = [];
    this.total = 0;
    this.from = 0;
  }

  public setOrganizationId(id: string) {
    this.organizationId = id;
  }

  public fetchHistory(): void {
    this.setDefaultSettings();
    this.loadMoreHistory();
  }

  public applyFilter(dateFrom: number, dateTo: number, owners: string[], types: string[], organizationIds: string[], indicators: string[]) {
    this.setDefaultSettings();
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.owners = owners;
    this.types = types;
    this.indicators = indicators;
    this.organizationIds = organizationIds;
    this.loadMoreHistory();
  }

  public loadMoreHistory(): void {
    this.isLoading = true;
    const request = new GetHistoriesRequest({
      organizationId: this.organizationId,
      orderBy: ListHistoryOrderBy.createdAt,
      direction: RequestDirection.desc,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      isVar: this.indicators?.includes(HistoryIndicatorsEnum.VAR),
      isClient: this.indicators?.includes(HistoryIndicatorsEnum.CLIENT),
      isSystem: this.indicators?.includes(HistoryIndicatorsEnum.SYSTEM),
      write: this.indicators?.includes(HistoryIndicatorsEnum.WRITE),
      owners: this.owners?.length > 0 ? this.owners.join(',') : null,
      types: this.types?.length > 0 ? this.types.join(',') : null,
      organizationIds: this.organizationIds?.length > 0 ? this.organizationIds.join(',') : null,
      from: this.from,
      size: this.historySize,
    });

    this.onFilterChangeSubject.next(request);

    this.historiesApi.listHistories(request).toPromise().then(
      (response) => {
        const [ columns, data ] = this.staticTableDataFactory.translate(this.getColumnsDefinition(), response.getItems());

        this.historiesData = [ ...this.historiesData, ...data ];
        this.canLoadMoreHistory = response.canLoadMore();
        this.total = response.getTotalItems();
        this.from = response.getNextItem();
      }).catch(() => {
        this.noticeService.notifyUser(new Notice("histories.fetch.history.error", NoticeLevels.ERROR));
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public handleDebouncedFetch(): void {
    clearTimeout(this._debounceTimeout);
    this._debounceTimeout = setTimeout(() => this.fetchHistory(), 300);
  }


  public getColumnsDefinition(): any[] {
    return [
      {
        label: '',
        field: 'icon',
        type: UiStaticTableRowType.EXPENDABLE_ICON,
        showIcon: true,
        width: '26px',
        minWidth: '26px',
        isResizable: false,
        noSorting: true,
      },
      {
        label: 'date',
        field: 'createdAt',
        width: '200px',
        minWidth: '200px',
        type: UiStaticTableRowType.DATE_AND_HOUR_AND_AGO,
        isResizable: false,
        noSorting: true,
      },
      {
        label: this.i18nService.translate('common.user'),
        field: 'identityName',
        width: '20%',
        type: UiStaticTableRowType.TEXT,
        isResizable: false,
        noSorting: true,
      },
      {
        label: this.i18nService.translate('common.organization'),
        field: 'organization',
        width: '15%',
        type: UiStaticTableRowType.TEXT,
        isResizable: false,
        noSorting: true,
      },
      {
        label: this.i18nService.translate('common.type'),
        field: 'type',
        width: '10%',
        type: UiStaticTableRowType.ENUM,
        enumPrefix: 'histories.container.page.filter.types.',
        enumSuffix: '.filter',
        isResizable: false,
        noSorting: true,
      },
      {
        label: this.i18nService.translate('common.indicators'),
        field: 'indicators',
        type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
        component: HistoriesIndicatorsComponent,
        paramName: 'history',
        width: '90px',
        minWidth: '80px',
        isResizable: false,
        noSorting: true,
      },
      {
        label: "Message",
        field: 'message',
        width: '80%',
        type: UiStaticTableRowType.TRANSLATED_FIELD,
        noSorting: true,
      },
    ];
  }
}
