import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '@ui-kit/services/modal.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { IDescribeIncidentResponse, IUpdateIncidentStatusRequest, ResolveIncidentAction } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';

enum AsyncStateEnum {
  "LOADING", "SAVING", "READY"
};

@Component({
  selector: 'app-incident-reopen-modal',
  templateUrl: './incident-reopen-modal.component.html',
  styleUrls: [ './incident-reopen-modal.component.scss' ],
})
export class IncidentReopenModalComponent implements OnInit {
  @Output()
  public successEvent = new EventEmitter<IDescribeIncidentResponse>();

  public readonly incident: Incident;

  public form: UntypedFormGroup;

  public readonly asyncStateEnum = AsyncStateEnum;
  public asyncState: AsyncStateEnum = AsyncStateEnum.READY;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { incident: Incident },
    private readonly modalService: ModalService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly i18n: I18nService,
    private readonly incidentsApi: IncidentsApi,
    private readonly noticeService: NoticeService
  ) {
    this.incident = this.data.incident;
  }

  ngOnInit(): void {
    this.initForm();
  }

  public get comment(): string {
    return this.form.get("comment").value;
  }

  public get isValidComment(): boolean {
    return this.comment?.length > 2;
  }

  public get errors(): string[] {
    const currentErrorList = [];
    if (!this.isValidComment) {
      currentErrorList.push("Commentaire invalide");
    }
    return currentErrorList;
  }

  public get isSaveActionAvailable(): boolean {
    const isFormValid = !this.errors?.length;
    return this.asyncState === this.asyncStateEnum.READY && isFormValid;
  }

  public get isCancelActionAvailable(): boolean {
    return this.asyncState !== this.asyncStateEnum.SAVING;
  }

  public handleConfirm(): void {
    this.asyncState = AsyncStateEnum.SAVING;

    const request: IUpdateIncidentStatusRequest = {
      id: this.incident.id,
      status: ResolveIncidentAction.REOPEN,
      comment: this.comment,
    };

    this.incidentsApi.updateIncidentStatus(this.incident.organizationId, request).subscribe(
      (response: IDescribeIncidentResponse) => {
        this.successEvent.emit(response);
        this.noticeService.notifyUser(new Notice("incidents.container.page.details.modal.reopenIncident.apiresult.SUCCESS", NoticeLevels.SUCCESS));
        this.modalService.closeDialog();
      },
      (httpErrorResponse) => {
        this.noticeService.notifyUser(new Notice("incidents.container.page.details.modal.reopenIncident.apiresult.ERROR", NoticeLevels.ERROR, null, httpErrorResponse?.error?.message));
        this.asyncState = AsyncStateEnum.READY;
      }
    );
  }

  public handleCancel(): void {
    this.modalService.closeDialog();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      comment: this.formBuilder.control("", Validators.required),
    });
  }
}
