import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'projects/@common/modules/session/session.service';
import { TeamsAuthService } from '../../../../services/teams-auth.service';
import { I18nService } from '../../../i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from '../../../notice/notice.service';

@Component({
  template: '<app-loader class="private-layout--loader"></app-loader>',
})
export class AuthnRedirectTeamsComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private teamsAuthService: TeamsAuthService,
    private noticeService: NoticeService,
    private i18n: I18nService,
    @Inject('REDIRECT_AFTER_LOGIN') private readonly redirectAfterLogin: string,
    @Inject('ASSERT_ORGANIZATION_TAG') private readonly assertOrganizationTag: string
  ) {
  }

  async ngOnInit() {
    const { code } = this.route.snapshot.queryParams;
    const { state } = this.route.snapshot.queryParams;
    const { jwt } = this.route.snapshot.queryParams;
    await this.login(code, state, jwt);
  }

  private async login(code: string, state: string, jwt: string) {
    const parsedState = JSON.parse(state);
    const redirectUrl = `${window.location.origin}/login/office`;
    let onboardRoute = null;
    await this.sessionService.login(
      {
        code,
        email: parsedState.email,
        jwt,
        redirectUrl,
      },
      { assertOrganizationTag: this.assertOrganizationTag }
    ).catch((error) => {
      if (error.status === 404) {
        // Onboarding tour carousel with newClient boolean true
        onboardRoute = '/onboard/contact';
      }
      if(error.status === 417){
        onboardRoute = '/onboard/restrictedAccess';
      }
      if (!TeamsAuthService.isInTeams) {
        this.noticeService.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR));
      }
    });

    this.router.navigate([onboardRoute ?? this.teamsAuthService.tabRoute ?? this.redirectAfterLogin]);
  }

  private completeFailedLogin() {
    this.router.navigate(['signin']);
  }
}

