<div
  page
  [appPagePaths]="['mdRespond', 'usecases']"
  [hasTopSection]="true"
  (organizationChanged)="organizationChanged($event)"
  [searchMode]="searchMode"
>
  <ui-tabs #tabs [color]="listTabsColor" [defaultSelectedTabIndex]="tabsHandler.selectedTabIndex" (tabClicked)="usecaseTabClick($event)">
    <ui-tab [tabTitle]="'detection.usecase.list.tab.customer.title' | t"> 
      <ng-container *ngIf="showTable; else noOrganizationSelectedMessage;">
        <usecase-inventory
          *ngIf="tabsHandler.selectedTabIndex === 0"
          [organizationGuid]="activeOrganization?.organization_respond_id"
        ></usecase-inventory>
      </ng-container>
    </ui-tab>

    <ui-tab *ngIf="viewParams.showCatalog" [tabTitle]="'detection.usecase.list.tab.catalog.title' | t">
      <usecase-catalog
        *ngIf="tabsHandler.selectedTabIndex === 1"
        [usecaseCatalogAction]="usecaseCatalogAction"
      ></usecase-catalog>
    </ui-tab>
  </ui-tabs>

  <ng-template #noOrganizationSelectedMessage>
    <div class="no-org-transition">{{ 'organizations_select.notSelected.message' | t }}</div>
  </ng-template>
</div>
