import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { randomBytes } from 'crypto';

export type Options = string[] | { value: string; displayValue: string; description?: string; icon?: string; disabled?: boolean }[];
type Option = string | { value: string; displayValue: string; description?: string; icon?: string; disabled: boolean };

@Component({
  selector: 'ui-select',
  templateUrl: './ui-select.component.html',
  styleUrls: [ './ui-select.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-select',
  },
})
export class UiSelect {
  @Input() public options: Options = [];

  @Input() public optionsPrefix = '';

  @Input() public name: string = randomBytes(4).toString('hex');

  @Input() public group?: UntypedFormGroup;

  @Input() public control: string = this.name;

  @Input() public disabled = false;

  @Input() public readonly = false;

  @Input() public placeholder = '';

  @Input() public label: string;

  @Input() public required = false;

  @Input() public errorMessage: string = '';

  @Input() public customTemplate: boolean = false;

  @Input() public biggerOptionsHeight: boolean = false;

  @Input()
    ngModel: string;

  @Output()
    ngModelChange = new EventEmitter<string>();

  @Output() optionClick = new EventEmitter();

  public showOptions = false;

  @HostListener('document:click', [ '$event' ])
  clickOut(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  @HostListener('keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent): void {
    if (this.showOptions) {
      event.stopPropagation();
      this.showOptions = false;
    }
  }

  constructor(private eRef: ElementRef) {}

  public get inputDisplayValue(): string {
    let value;
    if (this.group?.get(this.control).value === 0) {
      value = 0;
    } else {
      value = this.group?.get(this.control).value || this.ngModel || null;
    }
    const displayValue = this.getDisplayValue(value);

    return `${this.optionsPrefix}${displayValue}`;
  }

  public onOptionClick(option: Option): void {
    if (!this.isDisabledOption(option)) {
      this.toggleOptions();
      
      if (this.group) {
        this.group.get(this.control).setValue(this.getOptionValue(option));
      } else {
        this.ngModel = this.getOptionValue(option);
        this.ngModelChange.emit(this.ngModel);
      }

      this.optionClick.emit(this.getOptionValue(option));
    }
  }

  public toggleOptions(): void {
    this.showOptions = !this.showOptions;

    if (this.showOptions) {
      setTimeout(() => {
        document.querySelector('.ui-select--options-container .selected-option')?.scrollIntoView({ block: 'nearest' });
      });
    }
  }

  public getOptionValue(option: Option): string {
    if (typeof option !== 'object') {
      return option;
    }
    if (+option?.value === 0) {
      // shitty JS case where the || below returns null
      return option?.value;
    }
    return option?.value || null;
  }

  public getOptionDisplayValue(option: Option): string {
    if (typeof option !== 'object') {
      return option;
    }
    return option?.displayValue || null;
  }

  public getDisplayValue(value: string): string {
    const option = (this.options as any[]).find((item: Option) => this.getOptionValue(item) === value);
    return this.getOptionDisplayValue(option) || '';
  }

  public isSelectedOption(option: Option): boolean {
    return (
      this.getOptionValue(option) === this.group?.get(this.control).value ||
      this.getOptionValue(option) === this.ngModel
    );
  }

  public isDisabledOption(option: Option): boolean {
    return !!option[`disabled`] && !this.isSelectedOption(option);
  }
}
