import { Component, OnInit } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IWhitelistRepresentation } from 'projects/@common/services/api/respond/whitelists/whitelists.definitions';
import { WhitelistsTableColumnEnum, getWhitelistsTableColumns } from 'projects/@respond/whitelists/components/whitelists-table/whitelists-table.columns-config';
import { WhitelistsService } from 'projects/@respond/whitelists/whitelists.service';

@Component({
  selector: 'whitelist-section',
  templateUrl: './whitelist-section.component.html',
  styleUrls: [ './whitelist-section.component.scss' ],
})
export class WhitelistSectionComponent implements OnInit {
  public tableColumnsDef: Array<object>;

  constructor(
    private readonly whitelistsService: WhitelistsService,
    private readonly i18n: I18nService
  ) { }

  ngOnInit(): void {
    this.tableColumnsDef = getWhitelistsTableColumns([
      WhitelistsTableColumnEnum.selected,
      WhitelistsTableColumnEnum.status,
      WhitelistsTableColumnEnum.createdAt,
      WhitelistsTableColumnEnum.usecaseName,
    ]);
  }

  public get whitelistsData(): IWhitelistRepresentation[] {
    return this.whitelistsService.whitelistsData;
  }

  public get title(): string {
    return `${this.i18n.translate('incidents.container.page.details.tab.detail.whitelist.title')} (${this.whitelistsData?.length || 0})`;
  }
}
