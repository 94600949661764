import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SecurityContext, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { EditWithModalComponent } from "projects/@common/components/edit-with-modal/edit-with-modal.component";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { ImagesUploaderService, UploadFolderEnum } from "projects/@common/services/api/respond/images/images-uploader.service";
import { IncidentTask } from "projects/@common/services/api/respond/incidentTasks/models/incidentTask";
import { IParameterStore } from "projects/@common/services/api/tools/parameters/parameters.definitions";

import { DomSanitizer } from "@angular/platform-browser";
import { HtmlEditorComponent } from "@ui-kit/components/html-editor/html-editor.component";
import { RequestDirection } from "projects/@common/definitions/consoles.enum";
import { ConsoleType } from "projects/@common/modules/layout/private.layout";
import { NoticeLevels, NoticeService } from "projects/@common/modules/notice/notice.service";
import { IEntityRepresentation } from "projects/@common/services/api/respond/entities/entities.definition";
import { HistoriesApi } from "projects/@common/services/api/respond/histories/histories.api";
import { IIncidentTaskHistory, IListIncidentTaskHistoryRequest, ListHistoryOrderBy } from "projects/@common/services/api/respond/histories/histories.definition";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { IDeleteTaskObservablesRequest, IIncidentActionRepresentation, IIncidentMessage } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { CustomTaskAction, IincidentTaskMessage, IncidentTaskStatus } from "projects/@common/services/api/respond/incidentTasks/incidentTasks.definitions";
import { IObservable } from "projects/@common/services/api/respond/observables/observables.definition";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { IAddItemToTaskRequest, IAppendTaskHTMLRequest, IncidentEventsService, IRemoveItemToTaskRequest } from "projects/@respond/incident/services/incident.events.service";
import { Subscription } from "rxjs";
import { IncidentDifferenceService } from "../../../../../../services/incident.difference.service";
import { TabNamesEnum } from "../../../../incidents-details.component";
import { Buffer } from 'buffer';
import { IncidentMappersService } from "projects/@respond/incident/services/incidentMappers.service";
import { clone } from "@ui-kit/services/utils";
import { ExpendableTypeEnum } from "@ui-kit/components/ui-expendable-section/ui-expendable-section.component";


enum TaskSectionsEnum {
  INSTRUCTIONS = "INSTRUCTIONS",
  FINDINGS = "FINDINGS",
  HISTORY = "HISTORY"
}

enum TaskMessagesSectionsEnum {
  ACTIONS = "ACTIONS",
  OBSERVABLES = "OBSERVABLES",
  ENTITIES = "ENTITIES"
}

@Component({
  selector: 'app-task-container-content',
  templateUrl: './task-container-content.component.html',
  styleUrls: [ './task-container-content.component.scss' ],
})
export class TaskContainerContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() incident: Incident;

  @Input() incidentTask: IncidentTask;

  @Input() incidentActions: IIncidentActionRepresentation[] = [];

  @Input() entities: IEntityRepresentation[] = [];

  @Input() observables: IObservable[] = [];

  @Input() parametersValues: IParameterStore[] = [];

  @Input() autoExpandTaskInstruction = true;

  @Input() autoExpandTaskNote = true;

  @Input() showStartButton = false;

  @Input() showNextButton = false;

  @Input() customButtons: CustomTaskAction[] = [];

  @Input() showCompleteButton = false;

  @Input() disabled = false;

  @Input() isAdminConsole = false;

  @Output() saveEvent = new EventEmitter<{comment: string; callback: Function}>();

  @Output() startFirstTaskEvent = new EventEmitter<{callback: Function}>();

  @Output() nextTaskEvent = new EventEmitter<{callback: Function}>();

  @Output() completeFinalTaskEvent = new EventEmitter<{callback: Function}>();

  @Output() stopInvestigation = new EventEmitter<{callback: Function}>();

  @Output() startConversation = new EventEmitter<{escalation: boolean; callback: Function}>();

  @Output() emitTaskMessages = new EventEmitter<(IincidentTaskMessage & {messageContent: string})[]>();

  @ViewChild('htmlEditor') editorComponent: HtmlEditorComponent;

  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;

  public isConsoleAdmin: boolean;
  public expendableTypeEnum = ExpendableTypeEnum;
  public taskSectionsEnum = TaskSectionsEnum;
  public findingsSectionsEnum = TaskMessagesSectionsEnum;
  public initialValue: string;
  public isEditing = false;
  public formGroup = new UntypedFormGroup({ comment: new UntypedFormControl(null) });
  public editorEventHandlers: Record<string, Function>;

  public history: IIncidentTaskHistory[] = [];
  public historyResponse: ResponseUtils<IIncidentTaskHistory>;
  public isLoadingHistory = false;

  public taskActions: IIncidentActionRepresentation[] = [];
  public taskActionsInitialState: IIncidentActionRepresentation[] = [];
  public taskObservables: Array<IObservable & { __jsonViewString: string }> = [];
  public taskEntities: IEntityRepresentation[] = [];
  public taskMessages: (IincidentTaskMessage & {messageContent: string})[] = [];
  public forceExpandFirstElement: boolean = false;

  public periods: string[] = [];
  public currentRowsExpanded: number[] = [];
  public taskActionsJustificationEdit: string[] = [];

  private appendTaskHtmlSubscription: Subscription;
  private toggleTaskFindingItemSubscription: Subscription;

  constructor(
    public i18nService: I18nService,
    private datePipe: DatePipe,
    private historiesApi: HistoriesApi,
    private incidentsApi: IncidentsApi,
    private incidentDifferenceService: IncidentDifferenceService,
    private imagesUploaderService: ImagesUploaderService,
    private incidentEventsService: IncidentEventsService,
    private noticeService: NoticeService,
    private sanitizer: DomSanitizer,
    private incidentMappersService: IncidentMappersService,
    @Inject('CONSOLE_TYPE') private consoleType: ConsoleType
  ) {
    this.isConsoleAdmin = this.consoleType === ConsoleType.ADM;
  }

  ngOnInit(): void {
    this.setHtmlEditorValue(this.incidentTask.taskComment);
    this.initialValue = this.htmlEditorValue;
    if (this.incidentTask.taskStatus === IncidentTaskStatus.IN_PROGRESS) {
      this.openHtmlEditor();
    }

    this.editorEventHandlers = {
      'image.beforeUpload': (images) => {
        this.imagesUploaderService
          .uploadToS3(images[0], images[0].name, UploadFolderEnum.INCIDENTS, this.incident.id, this.incident.organizationEcoId)
          .then((imageUrl) => {
            this.editorComponent.editor.image.insert(imageUrl, null, null, this.editorComponent.editor.image.get());
          })
          .catch((err: any) => {
            console.log(err);
          });
      },
      'contentChanged': () => this.handleHtmlContentChangeEvent(),
    };

    this.appendTaskHtmlSubscription = this.incidentEventsService.appendTaskHtmlObservable$.subscribe((request: IAppendTaskHTMLRequest) => {
      if (this.isEditing && this.incidentTask.isInProgress && request.taskId === this.incidentTask.task.id) {
        const currentHtml = this.htmlEditorValue;
        this.setHtmlEditorValue(this.sanitizer.sanitize(SecurityContext.HTML, currentHtml ? `${currentHtml}<br>${request.html}` : request.html));
        this.noticeService.snack("incident-actions.actionForm.result.copyToTask.success");
      }
    });

    this.toggleTaskFindingItemSubscription = this.incidentEventsService.toggleTaskMessagesItemObservable$.subscribe((request: IAddItemToTaskRequest | IRemoveItemToTaskRequest) => {
      if (request.incidentTaskId === this.incidentTask.task.id || request.incidentTaskId === null) {
        if (request.hasOwnProperty("incidentObservableId")) {
          this.taskObservables = this.incidentMappersService.mapObservablesToTaskObservables(this.observables, this.incidentTask.observables);
        } else if (request.hasOwnProperty("incidentActionId")) {
          this.taskActions = this.incidentMappersService.mapActionsToTaskActions(this.incidentActions, this.incidentTask.actions);
          this.taskActionsInitialState = clone(this.taskActions);
        } else if (request.hasOwnProperty("incidentEntityId")) {
          this.taskEntities = this.incidentMappersService.mapEntitiesToTaskEntities(this.entities, this.incidentTask.entities);
        }
      }
    });

    this.periods = [];
    for (const period of this.incidentTask.task.inProgressPeriods) {
      this.periods.push(this.getTimestamp(period.from, period.to));
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.taskActions = this.incidentMappersService.mapActionsToTaskActions(this.incidentActions, this.incidentTask.actions);
    this.taskActionsInitialState = clone(this.taskActions);
    this.taskObservables = this.incidentMappersService.mapObservablesToTaskObservables(this.observables, this.incidentTask.observables);
    this.taskEntities = this.incidentMappersService.mapEntitiesToTaskEntities(this.entities, this.incidentTask.entities);
    this.taskMessages = await this.incidentMappersService.mapMessagesToTaskMessages(this.incidentTask, this.incident.organizationId);
    this.emitTaskMessages.emit(this.taskMessages);

    if (changes.showNextButton && changes.showNextButton.currentValue === true) {
      // force refresh html editor value when next button becomes visible
      this.setHtmlEditorValue(this.incidentTask.taskComment);
      this.initialValue = this.incidentTask.taskComment;
    }

    // When SOAR action form is submitted, we need to expand the new created action.
    if (changes.incidentActions) {
      if (changes.incidentActions.firstChange === false && changes.incidentActions.previousValue?.length && changes.incidentActions.currentValue?.length > changes.incidentActions.previousValue?.length) {
        this.forceExpandFirstElement = true;
      }
    }

  }

  ngOnDestroy() {
    this.appendTaskHtmlSubscription?.unsubscribe();
  }

  public get editorFormControl(): UntypedFormControl {
    return this.formGroup.get('comment') as UntypedFormControl;
  }

  public get isInfoGroupEmpty(): boolean {
    return !this.editorFormControl?.value || !this.editorFormControl?.value?.length;
  }

  public get showComment(): boolean {

    if(!this.disabled) {
      return true;
    }

    return !this.isInfoGroupEmpty || this.taskActions.length > 0 || this.taskEntities.length  > 0 || this.taskObservables.length  > 0;
  }

  public get showCustomNextButton(): boolean {
    return !!this.customButtons?.includes(CustomTaskAction.CUSTOM_NEXT);
  }

  public get showStopButton(): boolean {
    return !!this.customButtons?.includes(CustomTaskAction.CLOSE_INCIDENT);
  }

  public get showNotifyWithEscalationButton(): boolean {
    return !!this.customButtons?.includes(CustomTaskAction.START_CONVERSATION_WITH_ESCALATION) && !this.incident.conversationId;
  }

  public get showNotifyWithoutEscalationButton(): boolean {
    return !!this.customButtons?.includes(CustomTaskAction.START_CONVERSATION_WITHOUT_ESCALATION) && !this.incident.conversationId;
  }

  public get base64Comment(): string {
    let comment: string;
    if (!this.isInfoGroupEmpty) {
      const base64data = Buffer.from(this.htmlEditorValue, 'utf8').toString('base64');
      comment = base64data;
    }
    return comment;
  }

  public get shouldShowUnsavedWarning(): boolean {
    return this.incidentDifferenceService.shouldShowUnsavedWarnings;
  }

  public get hasUnsavedChanges(): boolean {
    return this.isTaskCommentChanged();
  }

  public get htmlEditorValue(): string {
    return this.editorFormControl.value;
  }

  public get expandableSections(): TaskSectionsEnum[] {
    if (this.isAdminConsole) {
      return [ TaskSectionsEnum.INSTRUCTIONS, TaskSectionsEnum.FINDINGS, TaskSectionsEnum.HISTORY ];
    }
    return [ TaskSectionsEnum.FINDINGS ];
  }

  public setHtmlEditorValue(content: string): void {
    this.editorFormControl.setValue(content);
  }

  public isTaskCommentChanged(): boolean {
    return this.initialValue !== this.htmlEditorValue && !(!this.initialValue && !this.htmlEditorValue);
  }

  public getTimestamp(from: number, to: number): string {
    const toValue = to ? this.datePipe.transform(to, 'medium', null, this.i18nService.dateCurrentLocale) : this.i18nService.translate('common.now');

    const toReturn = to
      ? `${this.i18nService.translate('common.date.interval', {
        dateFrom: this.datePipe.transform(from, 'medium', null, this.i18nService.dateCurrentLocale), dateTo: toValue,
      })}`
      : `${this.i18nService.translate('common.date.interval.onGoing', {
        dateFrom: this.datePipe.transform(from, 'medium', null, this.i18nService.dateCurrentLocale), dateTo: toValue,
      })}`;

    return toReturn;
  }

  public openHtmlEditor(): void {
    if (!this.disabled && !this.isEditing) {
      this.isEditing = true;
    }
  }

  public closeHtmlEditor(): void {
    this.isEditing = false;
  }

  public toggleDurationTimeHistoryPopup(): void {
    if (!this.disabled) {
      this.editWithModalComponent.toggle();
    }
  }

  public async updateComment() {
    this.initialValue = this.htmlEditorValue;
    await new Promise((resolve, reject) => {
      this.saveEvent.emit({comment: this.base64Comment, callback: (result: any, error: any) => {
        if (result) {
          resolve(result);
        } else {
          reject(error);
        }
      }});
    });

    this.closeHtmlEditor();
  }

  public async nextTask() {
    await new Promise((resolve, reject) => this.nextTaskEvent.emit({ callback: (result: any, error: any) => {
      if (result) {
        resolve(result);
      } else {
        reject(error);
      }
    }}));
  }

  public cancel(): void {
    this.setHtmlEditorValue(this.initialValue);
    this.closeHtmlEditor();
  }

  public async handleConfirmation(isConfirmed: boolean) {
    if (isConfirmed) {
      await this.updateComment();
    } else {
      this.cancel();
    }
    this.handleUnsavedEditingState(false);
  }

  public async onClickStartFirstTask() {
    this.openHtmlEditor();
    await new Promise((resolve, reject) => this.startFirstTaskEvent.emit({ callback: (result: any, error: any) => {
      if (result) {
        resolve(result);
      } else {
        reject(error);
      }
    }}));
  }

  public async onClickCustomNextTask() {
    await this.handleConfirmation(true);
    await this.nextTask();
  }

  public async onClickNextTask() {
    await this.handleConfirmation(true);
    await this.nextTask();
  }

  public async onClickNotify(escalation: boolean) {
    await this.handleConfirmation(true);
    await new Promise((resolve, reject) => this.startConversation.emit({ escalation, callback: (result: any, error: any) => {
      if (result) {
        resolve(result);
      } else {
        reject(error);
      }
    }}));
  }

  public async onClickCompleteFinalTask() {
    await this.handleConfirmation(true);
    await new Promise((resolve, reject) => this.completeFinalTaskEvent.emit({ callback: (result: any, error: any) => {
      if (result) {
        resolve(result);
      } else {
        reject(error);
      }
    }}));
  }

  public async onClickStopInvestigationTask() {
    await this.handleConfirmation(true);
    await new Promise((resolve, reject) => this.stopInvestigation.emit({ callback: (result: any, error: any) => {
      if (result) {
        resolve(result);
      } else {
        reject(error);
      }
    }}));
  }

  public handleHistoryManualRefresh(): void {
    this.fetchTaskHistory();
  }

  public handleHistoryExpanded(): void {
    if (!this.history?.length) {
      this.fetchTaskHistory();
    }
  }

  public handleLoadMoreHistory(): void {
    this.fetchTaskHistory(true);
  }

  public removeObservableFromTask(observable: IObservable): void {
    const removeObservableRequest: IDeleteTaskObservablesRequest = {
      incidentId: this.incident.id,
      incidentTaskId: this.incidentTask.task.id,
      incidentObservableId: observable.id,
      action: "remove",
    };
    this.incidentsApi.deleteTaskObservables(this.incident.organizationId, removeObservableRequest).subscribe((response) => {
      this.incident.reloadIncident({
        tasks: response.tasks,
      });
      this.incidentEventsService.emitToggleTaskFindingItem(removeObservableRequest);
    });
  }

  public removeActionFromTask(action: IIncidentActionRepresentation): void {
    const removeActionRequest: IRemoveItemToTaskRequest = {
      incidentId: this.incident.id,
      incidentTaskId: this.incidentTask.task.id,
      incidentActionId: action.id,
      action: "remove",
    };
    this.incidentsApi.deleteTaskActions(this.incident.organizationId, removeActionRequest).subscribe((response) => {
      this.incident.reloadIncident({
        tasks: response.tasks,
      });
      this.incidentEventsService.emitToggleTaskFindingItem(removeActionRequest);
    });
  }

  public removeEntityFromTask(entity: IEntityRepresentation): void {
    const removeEntityRequest: IRemoveItemToTaskRequest = {
      incidentId: this.incident.id,
      incidentTaskId: this.incidentTask.task.id,
      incidentEntityId: entity.id,
      action: "remove",
    };
    this.incidentsApi.deleteTaskEntities(this.incident.organizationId, removeEntityRequest).subscribe((response) => {
      this.incident.reloadIncident({
        tasks: response.tasks,
      });
      this.incidentEventsService.emitToggleTaskFindingItem(removeEntityRequest);
    });
  }

  public removeMessageFromTask(message: IincidentTaskMessage): void {
    const removeMessageRequest: IRemoveItemToTaskRequest = {
      incidentId: this.incident.id,
      incidentTaskId: this.incidentTask.task.id,
      incidentMessageId: message.id,
      action: "remove",
    };
    this.incidentsApi.deleteTaskMessage(this.incident.organizationId, removeMessageRequest).subscribe((response) => {
      this.incident.reloadIncident({
        tasks: response.tasks,
      });
      this.incidentEventsService.emitToggleTaskFindingItem(removeMessageRequest);
    });
  }

  public async onRowClick(row: any, index: number, taskAction: IIncidentActionRepresentation): Promise<void>{
    const currentTaskActionIndex = this.taskActions.indexOf(this.taskActions.find((action) => action.id === taskAction.id));

    taskAction.payload.activities[index] = {...row, isFlagged: !taskAction.payload.activities[index].isFlagged};
    this.taskActions[currentTaskActionIndex] = {...taskAction, payload: {...taskAction.payload, activities: taskAction.payload.activities}};
    this.incidentsApi.updateIncidentAction(
      this.incident.organizationId,
      {
        incidentId: this.incident.id,
        incidentAction: this.taskActions[currentTaskActionIndex]
      })
    .subscribe(
      (response) => {

      },
      (error) => {
        this.noticeService.notifyUser({message: 'incidents.task.action.update.error', level: NoticeLevels.ERROR});
        taskAction.payload.activities[index] = {...row, isFlagged: !taskAction.payload.activities[index].isFlagged};
      }
    )
  }

  public onAllRowsClick(taskAction: IIncidentActionRepresentation, event: boolean): void {
    const currentTaskActionIndex = this.taskActions.indexOf(this.taskActions.find((action) => action.id === taskAction.id));

    taskAction.payload.activities = taskAction.payload.activities.map((row) => ({...row, isFlagged: !event}));
    this.taskActions[currentTaskActionIndex] = {...taskAction, payload: {...taskAction.payload, activities: taskAction.payload.activities}};

    this.incidentsApi.updateIncidentAction(
      this.incident.organizationId,
      {
        incidentId: this.incident.id,
        incidentAction: this.taskActions[currentTaskActionIndex]
      })
      .subscribe(
        (response) => {
        },
        (error) => {
          this.noticeService.notifyUser({message: 'incidents.task.action.update.error', level: NoticeLevels.ERROR});
          taskAction.payload.activities = taskAction.payload.activities.map((row) => ({...row, isFlagged: !event}));
        }
      )
  }

  public setCurrentRowExpanded(index: number): void{
    console.log('setCurrentRowExpanded()', index);
    this.currentRowsExpanded.push(index);
    console.log(this.currentRowsExpanded);
  }

  public removeCurrentRowExpanded(index: number): void{
    console.log('removeCurrentRowExpanded()', index);
    this.currentRowsExpanded = this.currentRowsExpanded.filter((i) => i !== index);
    console.log(this.currentRowsExpanded);
  }

  public isAllRowsFlagged(taskAction: IIncidentActionRepresentation): boolean{
    return taskAction.payload.activities.every((row) => row.isFlagged);
  }

  public atLeastOneRowFlagged(taskAction: IIncidentActionRepresentation): boolean{
    if(taskAction.payload.activities){
      return taskAction.payload.activities.some((row) => row.isFlagged);
    }
    return false;
  }

  public isJustificationEditing(taskAction: IIncidentActionRepresentation): boolean{
    return this.atLeastOneRowFlagged(taskAction) && !taskAction.justification;
  }

  public handleConfirmationJustification(taskAction: IIncidentActionRepresentation, isConfirmed: boolean, index: number): void {

    if (isConfirmed) {
      this.incidentsApi.updateIncidentAction(
        this.incident.organizationId,
      {
        incidentId: this.incident.id,
        incidentAction: this.taskActions[index]
      })
      .subscribe(
        (response) => {
          this.noticeService.notifyUser({message: 'incidents.task.action.update.success', level: NoticeLevels.SUCCESS});
          this.taskActionsJustificationEdit = this.taskActionsJustificationEdit.filter((action) => action !== taskAction.id)
        },
        (error) => {
          this.noticeService.notifyUser({message: 'incidents.task.action.update.error', level: NoticeLevels.ERROR});
        }
      )
    }
    else{
      this.taskActions[index] = this.taskActionsInitialState[index];
      this.taskActionsJustificationEdit = this.taskActionsJustificationEdit.filter((action) => action !== taskAction.id)
    }
  }

  public editActionToggle(taskActionId: string): void{
    this.taskActionsJustificationEdit.push(taskActionId);
  }

  private fetchTaskHistory(isLoadMore: boolean = false): void {
    this.isLoadingHistory = true;
    const request: IListIncidentTaskHistoryRequest = {
      incidentId: this.incident.id,
      incidentTaskId: this.incidentTask.task.id,
      organizationId: this.incident.organizationId,
      orderBy: ListHistoryOrderBy.createdAt,
      direction: RequestDirection.desc,
      from: isLoadMore ? this.historyResponse.getNextItem() : 0,
      size: isLoadMore ? 20 : 10,
    };
    this.historiesApi.listIncidentTaskHistory(request).subscribe((response: ResponseUtils<IIncidentTaskHistory>) => {
      const items = response.getItems();
      this.history = isLoadMore ? [ ...this.history, ...items ] : items;
      this.historyResponse = response;
      this.isLoadingHistory = false;
    });
  }

  private handleHtmlContentChangeEvent(): void {
    this.handleUnsavedEditingState(!!this.hasUnsavedChanges);
  }

  private handleUnsavedEditingState(event: boolean): void {
    this.incidentDifferenceService.handleEditingStates({
      tabNameEnum: TabNamesEnum.TASKS,
      itemId: this.incidentTask.task.id,
      hasUnsavedChange: event,
    });
  }
}
