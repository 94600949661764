import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { IUsecaseDescriptionResponse } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { CustomValidators } from 'projects/@common/utils/validators';
import { IncidentDifferenceService } from '../../../../../../services/incident.difference.service';
import { TabNamesEnum } from '../../../../incidents-details.component';

@Component({
  selector: 'affected-resources-section',
  templateUrl: './affected-resources-section.component.html',
  styleUrls: [ './affected-resources-section.component.scss' ],
})
export class AffectedResourcesSectionComponent implements OnInit {
  @Input() isEditable: boolean;
  @Input() isAdminConsole: boolean;
  @Input() organizationId: string;
  @Input() incident: Incident;
  @Input() usecases: IUsecaseDescriptionResponse[];

  public currentIncidentAffectedResources: string;
  public formGroup: UntypedFormGroup;
  public isEditing: boolean;
  public hasEditPermission: boolean;

  constructor(
    private readonly incidentsApi: IncidentsApi,
    private incidentDifferenceService: IncidentDifferenceService,
    public i18n: I18nService,
    private readonly notice: NoticeService,
    private readonly displayService: DisplayService,
    private readonly formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.hasEditPermission = this.displayService.meetsRequirements({ permissions: [ 'can_update_incident' ] });

    this.currentIncidentAffectedResources = this.incident?.affectedResources;

    this.formGroup = this.formBuilder.group({
      affectedResources: [ this.currentIncidentAffectedResources || null, [ Validators.required, CustomValidators.affectedResources ] ],
    });
  }

  public get canEditAffectedResources(): boolean {
    return this.isEditable && this.incident.isEditable && this.hasEditPermission;
  }

  public get sectionTooltip(): string {
    return this.i18n.translate(`incidents.container.page.details.tab.detail.affectedResources.title.tooltip.${this.isAdminConsole ? 'admin' : 'org'}`);
  }

  public get affectedResourcesTemplate(): string {
    const templates: string[] = this.usecases?.filter((uc) => !!uc.usecase?.affectedResources).map((uc) => uc.usecase.affectedResources);

    if (templates?.length) {
      return templates.join(' | ');
    }

    return this.i18n.translate('incidents.container.page.details.tab.detail.affectedResources.template.none');
  }

  public get isValid(): boolean {
    return this.formGroup.valid;
  }

  public get shouldShowUnsavedWarning(): boolean {
    return this.incidentDifferenceService.shouldShowUnsavedWarnings;
  }

  public saveAffectedResources(): void {
    this.incident.affectedResources = this.formGroup.get('affectedResources').value;
    this.incidentsApi.updateIncident(this.organizationId, this.incident.updateRequestDto).subscribe(
      (response) => {
        this.incident.reloadIncident({
          ...response.incident,
          tasks: response.tasks,
          resolveIncidentActions: response.resolveIncidentActions,
        });
        this.notice.notifyUser(new Notice(this.i18n.translate('incidents.container.page.details.tab.detail.save.success'), NoticeLevels.SUCCESS));
      },
      (error) => this.notice.notifyUser(new Notice(this.i18n.translate('incidents.container.page.details.tab.detail.save.error'), NoticeLevels.ERROR))
    );
  }

  public setEditing(value: boolean): void {
    if (this.canEditAffectedResources) {
      this.isEditing = value;
      this.incidentDifferenceService.handleEditingStates({
        tabNameEnum: TabNamesEnum.DETAILS,
        itemId: "affectedResources",
        hasUnsavedChange: this.isEditing,
      });
      this.currentIncidentAffectedResources = this.incident.affectedResources;
      this.formGroup.setValue({ affectedResources: this.currentIncidentAffectedResources || null });
    }
  }

  public handleEditConfirmation(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.saveAffectedResources();
    }
    this.setEditing(false);
  }
}
