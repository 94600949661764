import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { WhitelistConnectorNameComponent } from "../whitelist-connector-name/whitelist-connector-name.component";
import { WhitelistCreatedByComponent } from "../whitelist-created-by/whitelist-created-by.component";
import { WhitelistStatusComponent } from "../whitelist-status/whitelist-status.component";
import { WhitelistUsecaseNameComponent } from "../whitelist-usecase-name/whitelist-usecase-name.component";

export enum WhitelistsTableColumnEnum {
  selected = 'selected',
  name = 'name',
  usecaseName = 'usecaseName',
  datasourceType = 'datasourceType',
  status = 'status',
  createdBy = 'createdBy',
  createdAt = 'createdAt',
  startsAt = 'startsAt',
  endsAt = 'endsAt'
}

export function getWhitelistsTableColumns(hiddenColumns: WhitelistsTableColumnEnum[] = []): Array<Object> {
  const defaultTableColumns = [
    {
      label: '',
      field: WhitelistsTableColumnEnum.selected,
      selected: "__Selected",
      type: UiStaticTableRowType.CHECKBOX,
      noSorting: true,
      isResizable: false,
      width: '50px',
    },
    {
      label: 'whitelists.table.column.name',
      field: WhitelistsTableColumnEnum.name,
      type: UiStaticTableRowType.TEXT,
      noSorting: false,
      isResizable: true,
      width: '25%',
      minWidth: "150px",
    },
    {
      label: 'whitelists.table.column.usecaseName',
      field: WhitelistsTableColumnEnum.usecaseName,
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: WhitelistUsecaseNameComponent,
      paramName: "whitelist",
      noSorting: true,
      isResizable: true,
      isTruncated: true,
      width: '22.5%',
      minWidth: "120px",
    },
    {
      label: 'whitelists.table.column.datasourceType',
      field: WhitelistsTableColumnEnum.datasourceType,
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: WhitelistConnectorNameComponent,
      paramName: "whitelist",
      noSorting: true,
      isResizable: true,
      isTruncated: true,
      width: '12%',
      minWidth: "90px",
    },
    {
      label: 'whitelists.table.column.status',
      field: WhitelistsTableColumnEnum.status,
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: WhitelistStatusComponent,
      paramName: "whitelist",
      noSorting: true,
      isResizable: true,
      minWidth: '115px',
      width: '115px',
    },
    {
      label: 'whitelists.table.column.createdBy',
      field: WhitelistsTableColumnEnum.createdBy,
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: WhitelistCreatedByComponent,
      paramName: "whitelist",
      noSorting: true,
      isResizable: true,
      minWidth: '120px',
      width: '15%',
    },
    {
      label: 'whitelists.table.column.createdAt',
      field: WhitelistsTableColumnEnum.createdAt,
      type: UiStaticTableRowType.DATE,
      noSorting: false,
      isResizable: true,
      minWidth: '132px',
      width: '132px',
    },
    {
      label: 'whitelists.table.column.startsAt',
      field: WhitelistsTableColumnEnum.startsAt,
      type: UiStaticTableRowType.DATE_AND_HOUR,
      noSorting: false,
      isResizable: true,
      minWidth: '180px',
    },
    {
      label: 'whitelists.table.column.endsAt',
      field: WhitelistsTableColumnEnum.endsAt,
      type: UiStaticTableRowType.DATE_AND_HOUR,
      noSorting: false,
      isResizable: true,
      minWidth: '180px',
    },
  ];
  return defaultTableColumns.filter((column) => !hiddenColumns?.includes(column.field));
}
