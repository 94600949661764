import { Injectable } from '@angular/core';
import {
  DatasourceInventoryInfo,
  IDatasourceFamily,
  IDatasourceUsecaseInfo
} from 'projects/@common/services/api/respond/datasources/datasources.definitions';
import { ConnectorFamilies } from '@md.eco/connectors';

@Injectable({
  providedIn: 'root',
})
export class DatasourcesCategoryMapper {
  public fromEntityToView(datasource: IDatasourceFamily, info: IDatasourceUsecaseInfo): DatasourceInventoryInfo {
    const infos = this.getEntityLogoAndTitle(datasource.type);
    return {
      datasourceFamily: datasource.type,
      title: infos.name,
      description: infos.description,
      logo: infos.logo,
      state: datasource.isActive,
      activatedAt: datasource.createdAt,
      activatedNumber: info?.usecasesCount,
      updatedAt: datasource.updatedAt || datasource.createdAt,
    };
  }

  protected getEntityLogoAndTitle(type: ConnectorFamilies): { logo: string; name: string; description: string } {
    const titlePrefix = 'detection.datasource.inventory.card.title';
    const descriptionPrefix = 'detection.datasource.inventory.card.description';
    const logoPrefix = 'datasource_icon';

    return {
      logo: `${logoPrefix}_${type}` || '',
      name: `${titlePrefix}.${type}` || '',
      description: `${descriptionPrefix}.${type}` || '',
    };
  }
}
