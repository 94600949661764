<widget-container 
  [title]="'connector.dashboard.my.total.data.section.title' | t" 
  [locale]="i18n.currentLocale"
  [withBottomMargin]="true" 
  [withTimeRange]="true" 
  [timeRanges]="TIME_RANGE_ENUM"
  [timeRangesDefault]="currentTimeRange"
  (selectedTimerange)="handleTimeRangeChange($event)">
  <div class="content-container" [style.display]="isLoading ? 'none': 'flex'" [style.height]="chartData.length > 0 ? '30rem': 'auto'">
    <div class="info-section" *ngIf="chartData.length > 0">
      <capacity-card
        class="card-info-total"
        numberColor="#47c1bf"
        [capacityNumber]="totalUsedCapacity"
        [topDescription]="'respond.dashboard.badge.tota.used.data.description.top' | t"
        [bottomDescription]="totalUsedCapacityUnit"
      ></capacity-card>
      
      <ui-table-scrollable-body [data]="tableData"></ui-table-scrollable-body>
    </div>

    <div class="chart-section" id="chart-section-connectors-total-ingested-data">
      <div class="chart-container">
        <graph-connectors-total-ingested-data *ngIf="chartData.length > 0"
          [chartData]="chartData"
        ></graph-connectors-total-ingested-data>
        <div *ngIf="chartData.length === 0" class="graph-without-data-container">
          {{ 'dashboard.admin.noData' | t }}
        </div>
      </div>      
      <div class="graph--footer" *ngIf="chartData.length > 0">
        <p>{{ 'respond.dashboard.connectors.total.data.chart.description' | t}}</p>
        <a [routerLink]="routerLink" class="chart-link" *display="{ permissions: routingPermissions }">
          {{ 'respond.dashboard.connectors.total.data.chart.link' | t }}
        </a>
      </div>

    </div>
  </div>

  <ng-container *ngIf="isLoading">
    <ui-spinner size="medium" modifier="secondary"></ui-spinner>
  </ng-container>
</widget-container>