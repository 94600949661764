export enum FeaturesEnum {
  // AIR
  AIR_MANAGEMENT = 'airManagement',
  PROMPTS_MANAGEMENT = 'promptsManagement',

  // Cybersecrity Proactive
  SHARES_MANAGEMENT = "sharesManagement",
  RESOURCE_MANAGEMENT = "resourceManagement",
  GUESTS_MANAGEMENT = "guestsManagement",
  DEVICE_MANAGEMENT = "deviceManagement",
  GUESTS_EXPIRATION_MANAGEMENT = "guestsExpirationManagement",
  DEVICE_COMPLIANCE_NOTIFICATION = "deviceComplianceNotification",
  DLP_MANAGEMENT = "dlpManagement",
  TEAM_PROFILE = "teamProfile",

  // Alerting
  ADVANCED_QUERY_BUILDER = "advancedQueryBuilder",

  // Connectors
  DATA_CONNECTORS_PERMISSIONS = "dataConnectorsPermissions",
  SOAR_CONNECTORS = "soarConnectors",
}

