import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { Alert } from 'projects/@common/services/api/respond/alerts/models/alert';
import { IEntityForDisplay } from 'projects/@common/services/api/respond/entities/entities.definition';
import { IncidentTaskStatus } from 'projects/@common/services/api/respond/incidentTasks/incidentTasks.definitions';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { IDescribeIncidentResponse, IPostTaskEntitiesRequest } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { IAddItemToTaskRequest, IncidentEventsService, IRemoveItemToTaskRequest } from 'projects/@respond/incident/services/incident.events.service';
import { CollapsableBadgeColor, ICollapsableBadge } from '@ui-kit/components/ui-collapsable-card/ui-collapsable-card.component';
import { IUiMenuOptions } from '@ui-kit/components/ui-menu-options/ui-menu-options.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IncidentEntityActionsModalComponent } from 'projects/@respond/incident/components/incident-entity-actions-modal/incident-entity-actions-modal.component';
import { EntityCellService } from '../services/entity.cell.service';
import { Subscription } from 'rxjs';

enum EntityMenuOptionsEnum {
  addToActiveTask = "addToActiveTask",
  launchActions = "launchActions"
}

export enum EntityPageEnum {
  INCIDENTS = 'incidents',
  ALERTS = 'alerts'
}

export interface IEntityPageContext {
  pageEnum: EntityPageEnum;
  organizationId: string;
  incident?: Incident;
  alert?: Alert;
}

@Component({
  selector: 'respond-entity-with-collapse',
  templateUrl: './respond-entity-with-collapse.component.html',
  styleUrls: [ './respond-entity-with-collapse.component.scss' ],
})
export class RespondEntityWithCollapseComponent implements OnChanges {
  @Input() entity: IEntityForDisplay;
  @Input() pageContext: IEntityPageContext;
  @Input() startExpanded: boolean = false;
  @Input() isReadonly: boolean = false;
  @Input() incident: Incident;

  public badges: ICollapsableBadge[] = [];
  public menuOptions: IUiMenuOptions[] = [];
  private toggleTaskFindingItemSubscription: Subscription;
  public isRedFlagged: boolean = false;
  public redFlagTooltipTaskNames: string;

  constructor(
    private readonly i18n: I18nService,
    private readonly incidentsApi: IncidentsApi,
    private readonly incidentEventsService: IncidentEventsService,
    private readonly entityCellService: EntityCellService,
    private readonly noticeService: NoticeService,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit() {
    this.entity.isExpanded = this.startExpanded;
    this.setRedFlagged();
    this.subscribeToToggleTaskFindingItem();
  }

  /** Listenner for entities added to tasks, as evidences. */
  private subscribeToToggleTaskFindingItem() {
    this.toggleTaskFindingItemSubscription = this.incidentEventsService.toggleTaskMessagesItemObservable$.subscribe((request: IAddItemToTaskRequest | IRemoveItemToTaskRequest) => {
       if (request["incidentEntityId"] === this.entity.id) {
        this.setRedFlagged();
      }
    });
  }

  private setRedFlagged() {
    const flaggedIncidentTasks = this.entityCellService.getFlaggedIncidentTasks(this.pageContext, this.entity);

    this.isRedFlagged = flaggedIncidentTasks.length > 0;

    if (this.isRedFlagged) {
      this.redFlagTooltipTaskNames = flaggedIncidentTasks;
    }


  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entity?.currentValue) {
      this.initTypeBadges();
    }
  }

  ngOnDestroy() {
    this.toggleTaskFindingItemSubscription?.unsubscribe();
  }

  public handleMenuOptionOpened() {
    this.initMenuOptions();
  }

  public handleMenuOptionClicked(option: IUiMenuOptions) {
    if (option.id === EntityMenuOptionsEnum.addToActiveTask) {
      if (this.pageContext.pageEnum === EntityPageEnum.INCIDENTS) {
        const taskId = this.pageContext.incident.tasks.find((incidentTask) => incidentTask.task.status === IncidentTaskStatus.IN_PROGRESS)?.task?.id;
        if (!taskId) {
          this.noticeService.snack("incidents.task.no-active-task");
          return;
        }

        this.isRedFlagged = this.entityCellService.getRedFlaggedStatus(this.pageContext, this.entity);

        if(!this.isRedFlagged)
        {
          const addEntityRequest: IPostTaskEntitiesRequest = {
            incidentId: this.pageContext.incident.id,
            incidentEntityId: this.entity.id,
            incidentTaskId: taskId,
          };
          this.incidentsApi.postTaskEntities(this.pageContext.organizationId, addEntityRequest).subscribe(
            (response: IDescribeIncidentResponse) => {
              this.pageContext.incident.reloadIncident({
                tasks: response.tasks,
              });
              this.incidentEventsService.emitToggleTaskFindingItem(addEntityRequest);
              this.noticeService.success('incidents.task.entity.add.success');
            },
            (error) => {
              this.noticeService.error('incidents.task.entity.add.error');
            }
          );
        }
        else
        {
          this.noticeService.snack('incidents.task.entity.already.exists.error');
        }


      } else {
        throw new Error('Not implemented');
      }
    }

    if(option.id === EntityMenuOptionsEnum.launchActions){
      this.dialog.open(IncidentEntityActionsModalComponent, {
        data: {
          incident: this.incident,
          entityId: this.entity.id,
        },
      })
    }
  }

  public initTypeBadges() {
    this.badges = [ {
      text: this.i18n.translate(this.entity.typeTranslationKey, null, this.entity.type),
      color: CollapsableBadgeColor.BLUE,
    } ];
  }

  public initMenuOptions() {
    this.menuOptions = [];
    if (!this.isReadonly) {
      this.menuOptions.push({
        id: EntityMenuOptionsEnum.addToActiveTask,
        iconPath: 'assets/favicons/observables/icon_flag_grey.svg',
        translation: this.i18n.translate('respond.entities.menu.add-to-task'),
      },
      {
        id: EntityMenuOptionsEnum.launchActions,
        iconPath: 'assets/favicons/toolbox-incidents/icon_actions.svg',
        translation: this.i18n.translate('respond.entities.menu.launchSOAR'),
      });
    }
  }
}
