<div class="drawer-detail--scrollable visible">
  <div class="drawer-detail--content">
    <div class="horizontal-div">
      <button *ngIf="!isEditing" ui-button icon="icon_modify" [custom-icon]="true" [isSvg]="true" icon-size="medium"
        color="listActionFlat" (click)="toggleAction()">
        {{ 'common.action.edit' | t}}
      </button>
      <button ui-button color="linkFlat" *ngIf="!isEditing" [disabled]="!isCurrentUsecaseOutdated()" [custom-icon]="true" [isSvg]="true"
        icon="icon_in_progress" icon-size="medium" (click)="updateOutdatedUsecase()">
        {{ 'datasources.table.action.update' | t }}
      </button>
    </div>

    <div class="margin--small--top margin--small--bottom">
      <ui-title [name]="'common.properties' | t"></ui-title>
    </div>

    <div
      class="action-creation-container"
      *ngIf="isEditing"
    >
      <autocomplete
        #legacyActionAutocomplete
        class="action-selector"
        autocomplete="off"
        [placeholder]="'org_usecase.description_drawer.tab.actions.add.legacyAction' | t"
        [required]="false"
        [disabled]="false"
        [placeholder]=""
        [autocompleteType]="customAutocompleteType"
        [customValues]="legacyActionAutocompleteOptions"
        [selectedItems]="legacyActionSelectedItem"
        [itemsToIgnore]="legacyActionsToIgnore"
        [initItems]="legacyActionSelectedItem"
        [showInInputAtInit]="false"
        [canMultiSelect]="false"
        [oneItemAtATime]="true"
        (onItemSelected)="handleSelectedActionEvent($event);"
      ></autocomplete>

      <autocomplete
        #actionAutocomplete
        class="action-selector"
        autocomplete="off"
        [placeholder]="'org_usecase.description_drawer.tab.actions.add.action' | t"
        [required]="false"
        [disabled]="false"
        [placeholder]=""
        [autocompleteType]="customAutocompleteType"
        [customValues]="actionAutocompleteOptions"
        [selectedItems]="newActionSelectedItem"
        [itemsToIgnore]="actionsToIgnore"
        [initItems]="newActionSelectedItem"
        [showInInputAtInit]="false"
        [canMultiSelect]="false"
        [oneItemAtATime]="true"
        (onItemSelected)="handleSelectedActionEvent($event);"
      ></autocomplete>
      <div>
        <button
          ui-button
          [disabled]="!selectedActions.length"
          color="linkFlat"
          class="table-action-button"
          icon="plus"
          icon-size="medium"
          (click)="applyActions(); actionAutocomplete.clearSearchQuery(); legacyActionAutocomplete.clearSearchQuery();"
        >
          {{ 'common.action.add' | t }}
        </button>
      </div>
    </div>

    <div class="action-list">
      <div class="action-container" *ngFor="let action of appliedActions; let i = index" [attr.data-index]="i">
        <div class="header">
          <label class="title">{{ action.translatedName }}</label>
          <div class="icon-list" *ngIf="isEditing">
            <button ui-button [tooltip]="'common.action.down' | t" modifier="gray" color="listActionFlatGray"
              icon="arrowDown" (click)="actionDownIconClicked(i)"></button>
            <button ui-button [tooltip]="'common.action.up' | t" modifier="gray" color="listActionFlatGray"
              icon="arrowUp" (click)="actionUpIconClicked(i)"></button>
            <button ui-button [tooltip]="'common.action.delete' | t" color="listActionFlatGray" icon="trashcan"
              (click)="deleteActionIconClicked(i)"></button>
          </div>
        </div>

        <!-- Actions legacy -->
        <ng-container *ngIf="action.isLegacyAction">
          <ui-toggle class="toggle-skip" [toggled]="action.skipConditionDisabled === false"
            [labelEnabled]="'org_usecase.description_drawer.tab.actions.skip.enable' | t"
            [labelDisabled]="'org_usecase.description_drawer.tab.actions.skip.disable' | t"
            [disabledWhenUntoggled]="true" [disabled]="!isEditing"
            (onClick)="toggleLegacyCondition(action, toggleConditionEnum.SKIP_CONDITION)">
          </ui-toggle>

          <ui-dynamic-form
            [name]="getActionControlName(action)"
            [readonly]="!isEditing"
            [formDetails]="action.form">
          </ui-dynamic-form>
        </ng-container>

        <!-- Libray actions/new actions -->
        <ng-container *ngIf="!action.isLegacyAction">

          <ui-toggle class="toggle-skip" [toggled]="!action.inputsDisabled"
            [labelEnabled]="'org_usecase.description_drawer.tab.actions.input' | t"
            [labelDisabled]="'org_usecase.description_drawer.tab.actions.input' | t"
            [disabledWhenUntoggled]="true" [disabled]="!isEditing"
            (onClick)="toggleLibraryCondition(action, toggleConditionEnum.INPUT_CONDITION)">
          </ui-toggle>
          <ui-dynamic-form-field
            *ngIf="getLibraryActionField(action.form.fields, toggleConditionEnum.INPUT_CONDITION)"
            [formGroup]="action.form.formGroup?.controls[action.generate_id]"
            [field]="getLibraryActionField(action.form.fields, toggleConditionEnum.INPUT_CONDITION)"
            [locale]="i18n.currentLocale"
            [readonly]="!isEditing"
          >
          </ui-dynamic-form-field>

          <ui-toggle class="toggle-skip" [toggled]="!action.preconditionDisabled"
            [labelEnabled]="'org_usecase.description_drawer.tab.actions.precondition' | t"
            [labelDisabled]="'org_usecase.description_drawer.tab.actions.precondition' | t"
            [disabledWhenUntoggled]="true" [disabled]="!isEditing"
            (onClick)="toggleLibraryCondition(action, toggleConditionEnum.PRE_CONDITION)">
          </ui-toggle>
          <ui-dynamic-form-field
            *ngIf="getLibraryActionField(action.form.fields, toggleConditionEnum.PRE_CONDITION)"
            [formGroup]="action.form.formGroup?.controls[action.generate_id]"
            [field]="getLibraryActionField(action.form.fields, toggleConditionEnum.PRE_CONDITION)"
            [locale]="i18n.currentLocale"
            [readonly]="!isEditing"
          >
          </ui-dynamic-form-field>

          <ui-toggle class="toggle-skip" [toggled]="!action.postconditionDisabled"
            [labelEnabled]="'org_usecase.description_drawer.tab.actions.postcondition' | t"
            [labelDisabled]="'org_usecase.description_drawer.tab.actions.postcondition' | t"
            [disabledWhenUntoggled]="true" [disabled]="!isEditing"
            (onClick)="toggleLibraryCondition(action, toggleConditionEnum.POST_CONDITION)">
          </ui-toggle>
          <ui-dynamic-form-field
            *ngIf="getLibraryActionField(action.form.fields, toggleConditionEnum.POST_CONDITION)"
            [formGroup]="action.form.formGroup?.controls[action.generate_id]"
            [field]="getLibraryActionField(action.form.fields, toggleConditionEnum.POST_CONDITION)"
            [locale]="i18n.currentLocale"
            [readonly]="!isEditing"
          >
          </ui-dynamic-form-field>

          <ui-toggle class="toggle-skip" [toggled]="!action.outputsDisabled"
            [labelEnabled]="'org_usecase.description_drawer.tab.actions.output' | t"
            [labelDisabled]="'org_usecase.description_drawer.tab.actions.output' | t"
            [disabledWhenUntoggled]="true" [disabled]="!isEditing"
            (onClick)="toggleLibraryCondition(action, toggleConditionEnum.OUTPUT_CONDITION)">
          </ui-toggle>
          <ui-dynamic-form-field
            *ngIf="getLibraryActionField(action.form.fields, toggleConditionEnum.OUTPUT_CONDITION)"
            [formGroup]="action.form.formGroup?.controls[action.generate_id]"
            [field]="getLibraryActionField(action.form.fields, toggleConditionEnum.OUTPUT_CONDITION)"
            [locale]="i18n.currentLocale"
            [readonly]="!isEditing"
          >
          </ui-dynamic-form-field>

        </ng-container>
      </div>
    </div>
  </div>
</div>

<ui-form-actions *ngIf="isEditing" layout="centered" class="visible">
  <ng-container>
    <button ui-button color="standard" [disabled]="!isValid" (click)="save()">
      {{ 'common.action.save' | t }}
    </button>
    <button ui-button color="secondary" (click)="cancelAction()">
      {{ 'common.action.cancel' | t }}
    </button>
  </ng-container>
</ui-form-actions>