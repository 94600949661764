<ui-tabs
  #tabs
  color="drawerTabs"
>
  <ui-tab
    [tabTitle]="'iam.profiles.tab.details.title' | t"
    [isShown]="!(isEditing$ | async) || (tabName$ | async) === profileDetailsTab"
  >
    <role-details [profile]="data.row"></role-details>
  </ui-tab>
  <ui-tab
    [tabTitle]="'iam.profiles.tab.members.title' | t"
    [isShown]="!(isEditing$ | async) || (tabName$ | async) === membersComponent"
  >
    <role-members [profile]="data.row" [organizationId]="data.organizationId"></role-members>
  </ui-tab>
  <ui-tab
    [tabTitle]="'iam.profiles.tab.security-group.title' | t"
    [isShown]="!(isEditing$ | async) || (tabName$ | async) === securityGroupsComponent"
  >
    <role-security-groups [profile]="data.row" [organizationId]="data.organizationId"></role-security-groups>
  </ui-tab>

</ui-tabs>