import { Component, Input, OnChanges } from "@angular/core";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { StaticTableDataMapper } from "projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";

import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { HistoriesApi } from 'projects/@common/services/api/respond/histories/histories.api';
import { AssetHistoryRequest } from 'projects/@common/services/api/respond/histories/models/assetHistoryRequest';
import { ResponseUtils } from 'projects/@common/utils/response-utils';


@Component({
  selector: 'app-actifs-history',
  templateUrl: './actifs-history.component.html',
  styleUrls: [ './actifs-history.component.scss' ],
})
export class ActifstHistoryComponent implements OnChanges {
  @Input() currentAssetId: string;
  @Input() organizationId: string;

  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public assetHistoryTableData: AssetHistoryRequest[];
  public assetObservableColumnDefinition = [
    {
      label: '',
      field: 'icon',
      type: UiStaticTableRowType.EXPENDABLE_ICON,
      showIcon: true,
      width: '26px',
      minWidth: '26px',
      isResizable: false,
      noSorting: true,
    },
    {
      label: 'date',
      field: 'createdAt',
      width: '200px',
      minWidth: '200px',
      type: UiStaticTableRowType.DATE_AND_HOUR_AND_AGO,
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('alerts.drawer.tab.history.table.column.user'),
      field: 'identityName',
      width: '200px',
      minWidth: '200px',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('alerts.drawer.tab.history.table.column.operation'),
      field: 'message',
      width: '100%',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      noSorting: true,
    },
  ];
  private assetHistoryResponse: ResponseUtils<AssetHistoryRequest>;

  constructor(
    private staticTableDataFactory: StaticTableDataMapper,
    public readonly i18n: I18nService,
    private readonly historiesApi: HistoriesApi,
    private readonly noticeService: NoticeService
  ) { }

  ngOnChanges(): void {
    this.fetchHistories();
  }

  public loadMore(): void {
    this.loadMoreHistories();
  }

  public fetchHistories(): void {
    this.isLoading = true;

    if (this.currentAssetId) {
      const assetHistoryRequest = new AssetHistoryRequest({ assetId: this.currentAssetId, organizationId: this.organizationId });
      this.historiesApi.listAssetHistory(assetHistoryRequest).subscribe(
        (response) => {
          this.assetHistoryResponse = response;
          this.assetHistoryTableData = this.assetHistoryResponse.getItems();
          this.handleTableTranslation();
        },
        (error) => this.noticeService.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)),
        () => this.isLoading = false
      );
    }
  }

  public loadMoreHistories(): void {
    if (!this.isLoadingMore) {
      this.isLoadingMore = true;
      const from = this.assetHistoryResponse.getNextItem();
      const assetHistoryRequest = new AssetHistoryRequest({ assetId: this.currentAssetId, organizationId: this.organizationId, from });
      this.historiesApi.listAssetHistory(assetHistoryRequest).subscribe(
        (response) => {
          this.assetHistoryResponse = response;
          this.assetHistoryTableData = this.assetHistoryTableData.concat(this.assetHistoryResponse.getItems());
        },
        (error) => this.noticeService.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)),
        () => this.isLoading = false
      );
    };
  }

  public get canLoadMore(): boolean {
    return this.assetHistoryResponse?.canLoadMore();
  }

  private handleTableTranslation(): void {
    const temp = JSON.parse(JSON.stringify(this.assetHistoryTableData));
    let tableData: AssetHistoryRequest[];
    [ this.assetObservableColumnDefinition, tableData ] = this.staticTableDataFactory.translate(this.assetObservableColumnDefinition, temp);
    this.assetHistoryTableData = tableData.map((item) => new AssetHistoryRequest(item));
  }
}
