<!-- 05/09/24 Cacher les tabs pour l'instant. On veut juste la vue des connecteurs client. -->

<!-- <div class="connector-cards-collapsable-container" *ngIf="!isLoading">
    <ui-tabs
        #tabs
        [color]="tabColor"
    >
        <ui-tab
            [tabTitle]="'detection.connector.page.user.connectors.collapsable.title' | t"
        >
            <div class="cards-container">
                <ng-container *ngFor="let connectorType of connectorsGroupedByType">
                    <ng-container *ngTemplateOutlet="template; context: { connector: getConnector(connectorType) }"></ng-container>
                </ng-container>
            </div>
            <ng-container *ngIf="connectors.length <= 0">
                <div class="without-user-connectors">
                    <img class="small-size"
                        [src]="'assets/favicons/icon_playbook_alert_update.svg'"
                        alt="infoIcon"/>
                    {{'detection.connector.page.all.connectors.collapsable.empty.list.text' | t}}
                </div>
            </ng-container>
        </ui-tab>
        <ui-tab
            [tabTitle]="'detection.connector.page.all.connectors.collapsable.title' | t"
            *ngIf="false"
        >
            <div class="cards-container">
                <ng-container *ngFor="let connector of connectors">
                    <app-connector-card *ngIf="connectors.length > 0" 
                        [connector]="connector"
                        [organization]="organization"
                        [connectorInstances]="getUserConnectorInstance(connector.id)"
                        [displayBadgeOwned]="doesUserHaveThisConnector(connector.id)"
                        [displayBadgeAdd]="!doesUserHaveThisConnector(connector.id)">
                    </app-connector-card>
                </ng-container>
            </div>
            <ng-container *ngIf="connectors.length <= 0">
                <div class="without-user-connectors">
                    <img class="small-size"
                        [src]="'assets/favicons/icon_playbook_alert_update.svg'"
                        alt="infoIcon"/>
                    {{'detection.connector.page.all.connectors.collapsable.empty.list.text' | t}}
                </div>
            </ng-container>
        </ui-tab>
    </ui-tabs> 
</div> -->

<div class="cards-container" *ngIf="!isLoading">
    <ng-container *ngIf="connectorInstances.length">
        <ui-title *ngIf="dataConnectorsGroupedByType.length && soarConnectorsGroupedByType.length" 
            [name]="'connector.table.dataConnectors.title' | t">
        </ui-title>
        <div *ngIf="dataConnectorsGroupedByType.length" class="cards-data">
            <div *ngFor="let connectorType of dataConnectorsGroupedByType">
                <ng-container *ngTemplateOutlet="template; context: { connector: getConnector(connectorType) }"></ng-container>
            </div>
        </div>
        <ui-title *ngIf="dataConnectorsGroupedByType.length && soarConnectorsGroupedByType.length"
            [name]="'connector.table.soarConnectors.title' | t">
        </ui-title>
        <div *ngIf="soarConnectorsGroupedByType.length" class="cards-soar">
            <div *ngFor="let connectorType of soarConnectorsGroupedByType">
                <ng-container *ngTemplateOutlet="template; context: { connector: getConnector(connectorType) }"></ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!connectorInstances.length">
        <p>{{'detection.connector.page.all.connectors.collapsable.empty.list.text' | t}}</p>
    </ng-container>
</div>

<ui-spinner *ngIf="isLoading"></ui-spinner>

<ng-template #template let-connector="connector">
    <app-connector-card
        [connector]="connector"
        [organization]="organization"
        [connectorInstances]="getClientConnectorInstances(connector)"
        [displayBadgeOwned]="false"
        [displayBadgeAdd]="false"
        >
    </app-connector-card>
</ng-template>