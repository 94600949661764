import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { OrgModule } from './app/org.app.module';
import { Stages } from 'projects/@common/definitions/stages';
import { environment } from 'projects/@common/environments/environment';

if (environment.stage == Stages.PROD) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(OrgModule)
  .catch((err) => console.error(err));
