import { Component, Input } from '@angular/core';
import { FeatureFlagsEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';
import { OrganizationSettings, OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';

@Component({
  selector: 'resources-settings',
  templateUrl: './resources-settings.component.html',
  styleUrls: [ './resources-settings.component.scss' ],
})
export class ResourcesSettingsComponent {
  @Input() public currentSettings: OrganizationSettings & OrganizationShareSettings;
  @Input() public isLoading = false;

  public featureFlagEnum = FeatureFlagsEnum;

  constructor() { }
}
