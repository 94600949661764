<div
  page
  class="queries-page"
  [appPagePaths]="['mdRespond', 'queries']"
  [searchMode]="organizationSearchMode"
  (organizationChanged)="organizationChanged($event)"
>
  <main
    class="queries-page-main-container"
    *ngIf="!showEmptyPageToSelectOrganization; else noOrgSelectedTemplate"
  >
    <respond-queries-engine
      [isVarMode]="isVarMode"
      [isLoadingQueriesList]="isLoadingQueriesList"
      [queriesList]="queriesList"
      [isLoadingQueryDetails]="isLoadingQueryDetails"
      [queryDetails]="queryDetails"
      [aggregationFields]="aggregationFields"
      [isExecutingQuery]="isExecutingQuery"
      (querySelectionEvent)="handleQuerySelectionEvent($event)"
      (executeDefaultQueryEvent)="handleExecuteDefaultQueryEvent($event)"
      (executeOrganizationQueryEvent)="handleExecuteOrganizationQueryEvent($event)"
      (convertQueryToAdvancedModeEvent)="handleConvertQueryToAdvancedModeEvent($event)"
      (createAdvancedQueryEvent)="handleCreateAdvancedQueryEvent($event)"
      (updateAdvancedQueryEvent)="handleUpdateAdvancedQueryEvent($event)"
      (deleteAdvancedQueryEvent)="handleDeleteAdvancedQueryEvent($event)"
    ></respond-queries-engine>

    <respond-queries-result
      [isExecutingQuery]="isExecutingQuery"
      [queryResult]="queryResult"
      [queryDetails]="queryDetails"
    ></respond-queries-result>
  </main>
</div>




<ng-template #noOrgSelectedTemplate>
  <span
    *ngIf="showEmptyPageToSelectOrganization"
    class="no-org-transition"
  >
    {{ 'organizations_select.notSelected.message' | t }}
  </span>
</ng-template>