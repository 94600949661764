import { ITranslatedField } from "../../../../../definitions/ITranslatedField";
import { IWhitelistRepresentation } from "../../whitelists/whitelists.definitions";
import { AlertPriorityEnum, AlertStatusEnum, IAlert } from "../alerts.definitions";

export class Alert implements IAlert {
  public count: number;
  public createdAt: number;
  public id: string;
  public name: ITranslatedField;
  public organizationId: string;
  public organizationEcoId: string;
  public organizationName: string;
  public priority: AlertPriorityEnum;
  public description: ITranslatedField;
  public shortId: string;
  public signature: any;
  public status: AlertStatusEnum;
  public updatedAt: number;
  public usecaseId: string;
  public templateId?: string;
  public connector?: string;
  public assetCount?: number;
  public whitelisted?: boolean;
  public whitelist?: IWhitelistRepresentation;
  public playbookId?: string;
  public indexedAt: number;
  public firstEventTimestamp: number;
  public isLate: boolean;

  constructor(jsonObj: IAlert) {
    Object.assign(this, jsonObj);
  }

  public get priorityLabel(): string {
    return `P${this.priority}`;
  }

  public get statusLabel(): string {
    return `alerts.container.page.table.status.${this.status}`;
  }

  public get isNewAlert(): boolean {
    return this.status === AlertStatusEnum.NEW;
  }

  public get isIgnored(): boolean {
    return this.status === AlertStatusEnum.IGNORED;
  }

  public get isAssigned(): boolean {
    return this.status === AlertStatusEnum.ASSIGNED;
  }

  public get isSilenced(): boolean {
    return this.status === AlertStatusEnum.SILENCED;
  }
}
