
import { Axis, ColumnSeries, DateAxis, ValueAxis, XYChart, XYCursor } from '@amcharts/amcharts4/charts';
import { Scrollbar, color, create } from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DateTimePeriod, PresetOptionValuesEnum } from '@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component';
import { TimeUtil } from '@ui-kit/services/time-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ConnectorsOrgService } from 'projects/@common/services/api/detect/connectorsOrg/connectorsOrg.service';
import { ConnectorInstanceVolumeHistory } from 'projects/@common/services/api/detect/connectorsOrg/model/ConnectorOrg';
import { ConnectorCalendarInterval } from 'projects/@common/services/api/detect/models/connectorInstance.model';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';


@Component({
  selector: 'app-connector-instance-volumetry-chart-section',
  templateUrl: './connector-instance-volumetry-chart-section.component.html',
  styleUrls: [ './connector-instance-volumetry-chart-section.component.scss' ],
})
export class ConnectorInstanceVolumetryChartSectionComponent implements OnInit, AfterViewInit {
  public readonly defaultSelectedPeriod = PresetOptionValuesEnum.last_30_days;
  public readonly defaultSelectedTimePeriod : DateTimePeriod = TimeUtil.getTimestampFromNowAndDays(30);
  private readonly CHART_ABSCISSA_VALUE_KEY = 'timestamp';

  @Input() organization: IAutomationOrganization;
  @Input() connectorInstanceId: string;
  public volumetryHistoryData: (ConnectorInstanceVolumeHistory & { tpvPerDay: number })[] = [];
  public chartName: string = "connector-instance-volumetry-org";
  public chartDataLoading: boolean = false;

  private chart: XYChart;
  private currentTimePeriod: DateTimePeriod = { dateFrom: Number(TimeUtil.getFirstTimestampOfTheDay((this.defaultSelectedTimePeriod).dateFrom)) };

  constructor(
    public readonly i18n: I18nService,
    private readonly connectorOrgService: ConnectorsOrgService
  ) { }

  ngOnInit(): void {
    this.fetchChartData();
  }

  ngAfterViewInit(): void {
    if (!this.chart) {
      this.createChart();
    }
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  public timePeriodChanged(period: DateTimePeriod) {
    this.currentTimePeriod = period;
    this.fetchChartData();
  }

  private fetchChartData() {
    const from = this.currentTimePeriod.dateFrom - (new Date().getTimezoneOffset() * 60000);
    const to = this.currentTimePeriod.dateTo ? this.currentTimePeriod.dateTo - (new Date().getTimezoneOffset() * 60000) : Date.now();
    const period = ConnectorCalendarInterval.DAY;

    this.chartDataLoading = true;
    this.connectorOrgService.getConnectorInstanceVolumetry(this.organization.id, this.connectorInstanceId, {
      from,
      to,
      period,
    })
      .then((volumetryData) => {
        this.volumetryHistoryData = volumetryData.map((data) => ({
          ...data,
          tpvPerDay: this.organization.tpvPerDay * 1000000000,
        }));
        if (this.volumetryHistoryData.every((item) => item.volume === 0)) {
          this.volumetryHistoryData = [];
        }
        if (this.chart) {
          this.chart.data = this.mapChartData();
        }
      })
      .finally(() => this.chartDataLoading = false);
  }

  private createChart(): void {
    const chart = create(`${this.chartName}_ID`, XYChart);
    chart.data = this.mapChartData();
    chart.mouseWheelBehavior = 'zoomX';
    chart.hiddenState.properties.opacity = 0;
    chart.scrollbarX = new Scrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    chart.svgContainer.htmlElement.style.height = '225px';
    this.createChartAbscissa(chart);
    this.createChartSeries(chart, this.createChartOrdinate(chart));
    chart.numberFormatter.numberFormat = "#.0b";
    this.chart = chart;
  }

  private mapChartData() {
    return this.volumetryHistoryData.map((item) => {
      const date = new Date(item.timestamp);
      return {
        volume: item.volume,
        tpvPerDay: item.tpvPerDay,
        timestamp: date.setMinutes(date.getMinutes() + date.getTimezoneOffset()),
      };
    });
  }

  private createChartAbscissa(chart: XYChart): void {
    const xAxis = chart.xAxes.push(new DateAxis());
    xAxis.renderer.maxLabelPosition = 0.99;
    const axisTooltip = xAxis.tooltip;
    axisTooltip.background.fill = color('#404C5B');
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
  }

  private createChartOrdinate(chart: XYChart): Axis {
    const yAxis = chart.yAxes.push(new ValueAxis());
    yAxis.min = 0;
    const valueAxisTooltip = yAxis.tooltip;
    valueAxisTooltip.background.fill = color('#404C5B');
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    return yAxis;
  }

  private createChartSeries(chart: XYChart, yAxis: Axis): void {
    const columnSerie = chart.series.push(new ColumnSeries());
    columnSerie.dataFields.dateX = this.CHART_ABSCISSA_VALUE_KEY;
    columnSerie.dataFields.valueY = 'volume';
    chart.cursor = new XYCursor();
    chart.cursor.behavior = 'panX';
    chart.cursor.yAxis = yAxis;
    columnSerie.tooltipText = '{valueY}';
    columnSerie.tooltip.getFillFromObject = false;
    columnSerie.tooltip.background.strokeWidth = 0;
    columnSerie.tooltip.background.cornerRadius = 3;
    columnSerie.tooltip.background.fill = color('#67b7dc');
  }
}
