import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRespondResponse } from '@common/definitions/IRespondResponse';
import { ResponseUtils } from '@common/utils/response-utils';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { UrlUtils } from 'projects/@common/utils/utils';
import { GuestAdmin, GuestUser } from 'projects/console-org/src/app/csp/guest-users/services/guest-users.definition';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcoUrlService } from '../../core/url.service';
import { GetRequestResponse } from '../../requests/requests.definitions';
import { IResourceApprovers } from './user.definition';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(
    readonly http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url);
  }

  public async reminderSend(resourceId: string): Promise<GetRequestResponse> {
    return this.http.get<GetRequestResponse>(`${this.host}/userNotifications/sendSharesReminder/resource/${resourceId}`, this.getFlagParams({ flags: [FeaturesEnum.TEAM_PROFILE] })).toPromise();
  }

  public userIsGlobalAdmin(): Promise<boolean> {
    return this.http.get<boolean>(`${this.host}/users/me/globalAdmin`).toPromise();
  }

  public deleteGuestUsers(data: { userIdsToDelete: string[], usersToExtend: { o365UserId: string, expirationDate: string; }[]; }): Observable<any> {
    return this.http.post(`${this.host}/guestUsers/delete`, data);
  }

  public getCurrentUserRole(): Observable<boolean> {
    const url = `${this.host}/guestUsers/isCallerAdmin`;
    return this.http.get<boolean>(url);
  }

  public listGuestUsers(params?: {
    direction?: string;
    fulltext?: string;
    orderBy?: string;
    status?: string;
    from?: number;
    limit?: number;
  }): Observable<ResponseUtils<GuestUser>> {
    const url = `${this.host}/guestUsers?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IRespondResponse<GuestUser>>(url).pipe(
      map((response) => {
        response.items = response.items.map((guest) => new GuestUser(guest));
        return new ResponseUtils<GuestUser>(response);
      })
    );
  }

  public listAvailableGuestUserAdmins(params?: { fulltext: string; }): Promise<any> {
    const url = `${this.host}/guestUsers/admin/available?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public listGuestUserAdmins(params?: {
    direction?: string;
    fulltext?: string;
    sort?: string;
    status?: string;
    from?: number;
    limit?: number;
  }): Observable<ResponseUtils<GuestAdmin>> {
    const url = `${this.host}/guestUsers/admin?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IRespondResponse<GuestAdmin>>(url).pipe(map(response => {
      response.items = response.items.map(guest => new GuestAdmin(guest));
      return new ResponseUtils<GuestAdmin>(response);
    }));
  }

  public addGuestUserAdmin(o365UserId: string): Observable<void> {
    const url = `${this.host}/guestUsers/admin`;
    return this.http.post<any>(url, { o365UserId });
  }

  public removeGuestUserAdmin(o365UserIds: string[]): Observable<void> {
    const url = `${this.host}/guestUsers/admin/remove`;
    return this.http.post<any>(url, { o365UserIds });
  }

  public inviteGuestUser(payload: {
    firstName: string;
    lastName: string;
    email: string;
    resourcesToAdd: string[];
    justification: string;
    approversO365Ids: string[];
    messageLanguage: LanguageEnum;
    expirationDate: number;
  }): Promise<string> {
    const url = `${this.host}/guestUsers`;
    return this.http.post<any>(url, payload).toPromise();
  }

  public listGuestManagers(params?: {
    direction?: string;
    fulltext?: string;
    filter?: string;
    from?: string;
    limit?: number;
    sort?: string;
    usersOnly?: boolean;
    forInvitation?: boolean;
  }): Promise<any> {
    const url = `${this.host}/guestUser/manager?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public addGuestManager(payload: { o365SecurityGroupId?: string; o365UserId?: string; }): Promise<any> {
    const url = `${this.host}/guestUser/manager`;
    return this.http.post<any>(url, payload).toPromise();
  }

  public removeGuestManager(o365UserId: string): Promise<any> {
    const url = `${this.host}/guestUser/manager/${o365UserId}`;
    return this.http.delete<any>(url).toPromise();
  }

  public listUsersCreateGroup(params?: {
    direction?: string;
    fulltext?: string;
    from?: string;
    limit?: number;
    sort?: string;
  }): Promise<any> {
    const url = `${this.host}/groups/users/create?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public listUsersReportingTo(fulltext?: string) {
    const url = `${this.host}/users/reportingTo?${UrlUtils.jsonToQueryParams({ fulltext })}`;
    return this.http.get<any>(url, this.getFlagParams({ flags: [FeaturesEnum.TEAM_PROFILE] })).toPromise();
  }

  public listResourceApprovers(params?: {
    direction?: string;
    fulltext?: string;
    from?: string;
    limit?: number;
    sort?: string;
    usersOnly?: boolean;
  }): Promise<any> {
    const url = `${this.host}/groups/approvers?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url, this.getFlagParams({ flags: [FeaturesEnum.RESOURCE_MANAGEMENT] })).toPromise();
  }

  public listUsersWhoCanArchive(params?: {
    direction?: string;
    fulltext?: string;
    from?: string;
    limit?: number;
    sort?: string;
  }): Promise<any> {
    const url = `${this.host}/archive/users?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public getArchiver(): Promise<any> {
    const url = `${this.host}/archivers`;
    return this.http.get<any>(url, this.getFlagParams({ flags: [FeaturesEnum.RESOURCE_MANAGEMENT] })).toPromise();
  }

  public postArchiver(payload: { archiverId: string; }): Promise<void> {
    const url = `${this.host}/archivers`;
    return this.http.post<void>(url, payload).toPromise();
  }

  public postResourceApprover(payload: { groupId?: string; userId?: string; }): Promise<IResourceApprovers> {
    const url = `${this.host}/groups/approvers`;
    return this.http.post<IResourceApprovers>(url, payload).toPromise();
  }

  public removeResourceApprovers(params: { approversToRemoveIds: string[]; }): Promise<void> {
    const url = `${this.host}/groups/approvers?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.delete<void>(url).toPromise();
  }

  public listOrgOrphanGuestUsers(params?: {
    from?: number;
    orderBy?: string;
    direction?: string;
    limit?: number;
  }): Promise<any> {
    const url = `${this.host}/guestUsers/orphans?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public getOrphanGuestCsvData(): Observable<any> {
    const url = `${this.host}/guestUsers/orphans`;
    return this.http.get<any>(url);
  }

  public getUsersBySegmentationAttribute(params?: { attributeValue?: string; searchQuery?: string; }) {
    const url = `${this.host}/users?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public updateUserNotificationLanguage(language: string): Observable<any> {
    const url = `${this.host}/users/setLanguage`;
    return this.http.put<any>(url, { language });
  }
}
