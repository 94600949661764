import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { SearchMode } from 'projects/@common/modules/layout/components/page/page.component';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { AutomationOrganizationsService } from 'projects/@common/services/api/respond/automation-organization/automation-organizations.api';
import { PeriodicReportsApi } from 'projects/@common/services/api/respond/periodic-reports/periodic-reports.api';
import { IPeriodicReportListRequest, IPeriodicReportRepresentation, IPeriodicReportResponse, PeriodicReportTypeEnum } from 'projects/@common/services/api/respond/periodic-reports/periodic-reports.definitions';
import { ResponseUtils } from 'projects/@common/utils/response-utils';
import { getHttpErrorMessage } from 'projects/@common/utils/utils';
import { IReportFilters } from '../../components/respond-reports-filters/respond-reports-filters.component';
import { ISortData } from '../../components/respond-reports-table/respond-reports-table.component';

@Component({
  selector: 'respond-reports-list',
  templateUrl: './respond-reports-list.component.html',
  styleUrls: [ './respond-reports-list.component.scss' ],
})
export class RespondReportsListComponent implements OnInit {
  public reports: IPeriodicReportRepresentation[];
  public isLoading: boolean;
  public defaultSort: ISortData;
  public activeOrganization: IAutomationOrganization;
  public defaultFilters: IReportFilters;
  public defaultSorting: ISortData;
  public canList: boolean;
  public searchMode = SearchMode.RESPOND;

  private _listRequest: IPeriodicReportListRequest;
  private _listResponse: ResponseUtils<IPeriodicReportResponse>;
  private _debounceTimeout: NodeJS.Timeout;

  constructor(
    private readonly automationOrganizationsService: AutomationOrganizationsService,
    private readonly periodicReportsApi: PeriodicReportsApi,
    private readonly displayService: DisplayService,
    private readonly noticeService: NoticeService,
    private readonly cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.reports = [];

    this._listRequest = {
      from: 0,
      size: 50,
    };

    this.defaultFilters = {
      name: this.listRequest.name,
      dateFrom: this.listRequest.dateFrom,
      dateTo: this.listRequest.dateTo,
      type: PeriodicReportTypeEnum.ALL,
    };

    this.defaultSorting = {
      orderBy: this.listRequest.orderBy,
      direction: this.listRequest.direction,
    };

    this.canList = this.displayService.meetsRequirements({ permissions: [ 'can_list_whitelists' ] }); // TODO: update permission
    if (this.canList && !this.showEmptyPageToSelectOrganization) {
      // immediate fetch for consoles that do not have an organization selector
      this.loadReports();
    }
  }

  public get listRequest(): IPeriodicReportListRequest {
    return this._listRequest;
  }


  public get showEmptyPageToSelectOrganization(): boolean {
    return !this.activeOrganization;
  }

  public loadReports(): void {
    this.isLoading = true;
    this.periodicReportsApi.listReports(this.listRequest, this.activeOrganization.id).subscribe(
      (response) => {
        this._listResponse = response;
        this.reports = this._listResponse.getItems().map(this.mapReport);
        this.isLoading = false;
      },
      (error) => {
        this.noticeService.error(getHttpErrorMessage(error));
        this.isLoading = false;
      }
    );
  }

  public loadMoreReports(): void {
    if (!this.isLoading && this._listResponse.canLoadMore()) {
      this.isLoading = true;
      this._listRequest.from = this._listResponse.getNextItem();
      this.periodicReportsApi.listReports(this.listRequest, this.activeOrganization?.id).subscribe(
        (response) => {
          this._listResponse = response;
          this.reports = [ ...this.reports, ...this._listResponse.getItems().map(this.mapReport) ];
          this.isLoading = false;
        },
        (error) => {
          this.noticeService.error(getHttpErrorMessage(error));
          this.isLoading = false;
        }
      );
    }
  }

  public handleFilterEvent(filterEvent: IReportFilters): void {
    this._listRequest = { ...this._listRequest, ...filterEvent };
    this.handleDebouncedFetch();
  }

  public handleTableSortEvent(sortEvent: ISortData): void {
    this._listRequest = { ...this._listRequest, ...sortEvent };
    this.handleDebouncedFetch();
  }

  public handleDebouncedFetch(): void {
    clearTimeout(this._debounceTimeout);
    this._debounceTimeout = setTimeout(() => {
      this.loadReports();
    }, 350);
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    if (org) {
      this.activeOrganization = await this.automationOrganizationsService.describeOrganization(org.id);
      this.loadReports();
    } else {
      this.activeOrganization = null;
    }
    this.cdr.detectChanges();
  }

  private mapReport(apiResponse: IPeriodicReportResponse): IPeriodicReportRepresentation {
    return {
      id: apiResponse.id,
      name: apiResponse?.period?.name,
      type: apiResponse?.period?.type,
      organizationId: apiResponse.organizationId,
      dateFrom: apiResponse?.period?.start,
      dateTo: apiResponse?.period?.end,
      createdAt: apiResponse.createdAt,
      periodCode: apiResponse.period.periodCode
    };
  }
}
