<div class="ui-select ui-select-margins">
  <div class="label-wrapper">
    <label
      ui-label
      *ngIf="label"
      [required]="required"
    >{{ label }}
    </label>
    <ng-content select="[tooltip]"></ng-content>
  </div>
  <div class="ui-select--input-container">
    <input
      ui-input
      [name]="name"
      [value]="inputDisplayValue"
      (click)="toggleOptions()"
      autocomplete="off"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [disabled]="disabled"
      (keydown)="(false)"
    />

    <ui-icon
      *ngIf="options.length > 0 && !disabled"
      [name]="showOptions ? 'chevron-up' : 'chevron-down'"
      modifier="list-action"
      size="medium"
      (click)="toggleOptions()"
    ></ui-icon>
  </div>
  <span
    *ngIf="errorMessage"
    class="errorMessage"
  >{{errorMessage}}</span>

  <div
    *ngIf="showOptions && !customTemplate"
    class="ui-select--options-container"
    [class.biggerOptionsHeight]="biggerOptionsHeight"
  >
    <ng-container *ngFor="let option of options; let i = index">
      <div
        class="option"
        [id]="'option-' + i"
        [class.selected-option]="isSelectedOption(option)"
        [class.disabled-option]="isDisabledOption(option)"
        (click)="onOptionClick(option)"
      >
      <div class="icon-and-display-value">
        <img [ngStyle]="{'width': '10px'}" *ngIf="option?.icon" [src]="option?.icon">
        {{ optionsPrefix + getOptionDisplayValue(option) }}
      </div>
        <span
          *ngIf="option?.description"
          class="option-description"
        >
        {{option.description}}
      </span>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="showOptions && customTemplate"
    class="ui-select--options-container"
  >
    <ng-content></ng-content>
  </div>
</div>
