<div class="container" [class.padding]="isFromConsoleAdm">
  <section *ngIf="dataSource?.length" class="button-section">
    <button
      ui-button
      icon="icon_sync"
      icon-size="medium"
      color="listActionFlat"
      class="test-button"
      [loading]="isTestingPermission"
      [custom-icon]="true"
      [isSvg]="true"
      (click)="testPermissions()"
    >
      {{  'detect.connector.instance.drawer.tab.permissions.button' | t  }}
    </button>
  </section>

  <ui-title *ngIf="isFromConsoleAdm" [largeFont]="true" [name]="'detect.connector.instance.drawer.tab.permissions' | t"></ui-title>
  <h2 *ngIf="!isFromConsoleAdm">{{ 'detect.connector.instance.drawer.tab.permissions' | t }}</h2>
  <ui-warning
    *ngIf="hasInvalidPermissions"
    [centerText]="false"
    [warningMessages]="warningMessages"
    [color]="warningColors.YELLOW"
    [showIcon]="true"
    [showBulletPoint]="hasErrorMessages > 1"
  ></ui-warning>

  <section class="actions-section">
    <ng-container *ngIf="dataSource?.length">
      <ui-collapsable-card
        [cardTitle]="'detect.connector.instance.drawer.tab.permissions.basePermissions' | t"
        [rightIcon]="getActionIcon()"
        [headerIcon]="'icon_connector_permission'"
        [isBigHeaderImage]="false"
        [isCollapsed]="true"
        >
        <ui-static-table
          sort="name"
          direction="asc"
          [locale]="i18nService.currentLocale"
          [addPaddingOnFirst]="false"
          [dataSource]="dataSource"
          [columnsDef]="columnsDef"
          [loading]="isLoading"
        >    
        </ui-static-table>
      </ui-collapsable-card>
    </ng-container>
    <ng-container *ngIf="!dataSource?.length">
      <p>{{ 'detect.connector.instance.drawer.tab.permissions.noPermissions' | t }}</p>
    </ng-container>
  </section>
</div>

