<div class="container" *ngIf="!dragDropInitialized && isParamsLoaded">Loading ...</div>

<div class="split-pane-container" *ngIf="dragDropInitialized"
     [ngClass]="splitPaneOpened ? 'split-pane-opened' : 'split-pane-closed'">

  <div class="split-pane split-pane-left">
    <ng-content select='header'></ng-content>
    <ng-container *ngFor="let dropRegionKey of formDropRegions">
      <div class="drop-region-container collapsable-container"
          [ngClass]="[
          regionCardCollapsed[dropRegionKey] ? 'closed' : 'opened',
          dragDropRegions[dropRegionKey].items.length > 3
            ? 'many-items'
            : 'few-items'
          ]"
      >
        <div cdkDropList
             [id]="dropRegionKey"
             [cdkDropListDisabled]="isReadonly"
             [cdkDropListData]="dragDropRegions[dropRegionKey].items"
             [cdkDropListConnectedTo]="formDropRegions"
             (cdkDropListDropped)="drop($event)"
             (cdkDropListEntered)="toggleOpenOnRegionEntered($event)">

          <div class="drop-region-header" (click)="toggleRegionCollapse(dropRegionKey)">
            <span class="collapse-toggle-chevron"></span>
            {{ 'detection.playbook.detail.task.library.phase.' + dropRegionKey + '.filter' | t | titlecase }} ({{
            dragDropRegions[dropRegionKey].items.length
            }})
          </div>

          <div class="drop-region-body">
            <button
              *ngIf="!isReadonly"
              ui-button
              color="linkFlat"
              [disabled]="false"
              [custom-icon]="true"
              [isSvg]="true"
              icon="icon_create"
              icon-size="medium"
              class="drop-region-collapsable-element"
              (click)="openCustomTaskModal(dropRegionKey, $event)">
              {{ 'detection.playbook.create.custom.task' | t }}
            </button>
            <ng-container *ngFor="let item of dragDropRegions[dropRegionKey].items; let last = last;let first = first; let i = index">
              <div cdkDrag
                    [ngStyle]="first ? {'margin-top': '10px'}: null"
                    class="task drop-region-collapsable-element"
                    [cdkDragData]="item"
                    [cdkDragDisabled]="item.lockedPosition">
                <div class="drag-placeholder" *cdkDragPlaceholder></div>
                
                <task-ui-card [item]="item.itemContext.task"
                              [isReadonly]="isReadonly"
                              [showTaskInstructions]="showTaskInstructions"
                              [region]="dropRegionKey"
                              [origin]="item.itemContext.origin"
                              [parametersValues]="paramsList"
                              (editEvent)="openCustomTaskModal(dropRegionKey, $event, i)"
                              (deleteEvent)="deleteEventHandler($event)"
                              (ignoreEvent)="ignoreEventHandler($event)"
                              (hideEvent)="hideEventHandler($event)"
                              [lockedPosition]="item.itemContext.origin === taskOriginEnum.Template">
                </task-ui-card>
                <div class="card-divider" *ngIf="!last"></div>
              </div>
            </ng-container>
            <button
              *ngIf="!isReadonly && dragDropRegions[dropRegionKey].items.length > 10"
              ui-button
              color="linkFlat"
              [disabled]="false"
              [custom-icon]="true"
              [isSvg]="true"
              icon="icon_create"
              icon-size="medium"
              class="drop-region-collapsable-element create-button"
              (click)="openCustomTaskModal(dropRegionKey, $event)">
              <p>{{ 'detection.playbook.create.custom.task' | t }}</p>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-content select='footer'></ng-content>
  </div>

  <div *ngIf="showMdTasksLibrary" class="split-pane split-pane-right" (click)="toggleSplitPane()">
    <div class="split-right-content">
      <div class="top-container">
        <div class="library-header">
          <div class="library-header-left" [ngStyle]="splitPaneOpened ? null : {'display': 'block'}">
            <img src="assets/favicons/icon_playbook_library.svg"/>
            <div class="label-icon-container">
              <span>{{ libraryLabel | t }}</span>
              <ui-icon
                [ngClass]="splitPaneOpened ? null : 'collapsable-library--header--chevron'"
                modifier="on-header"
                [name]="!splitPaneOpened ? 'chevron-right' : 'chevron-down'"
                size="medium"
              ></ui-icon>
            </div>
          </div>

        </div>
        <div class="library-filters collapses-on-pane-closed">
          <ui-table-search-tool
            [query]="name"
            (clear)="onNameChange('')"
            (query)="onNameChange($event)"
            (click)="$event.stopPropagation()"
            [placeholder]="'ui.table-search-tool.placeholder' | t"
          ></ui-table-search-tool>
          <div class="library-filters--multiselect-container">
            <ui-multi-select
              [multiSelectData]="phaseFiltersSelect"
              [disabled]="false"
              (onOptionSelect)="onPhaseChange($event)"
              (click)="$event.stopPropagation()"
              class="library-filters--multiselect-phases"
            ></ui-multi-select>
            <ui-table-filter-tool
              [tableFilterData]="taskTypeFilterSelect"
              (click)="$event.stopPropagation()"
              (select)="onTypeChange($event)"
            ></ui-table-filter-tool>
          </div>
          <div class="filter-toggle-wrapper">
            <ui-checkbox
              [isEditable]="true"
              [label]="'detection.playbook.detail.task.maskexistingtasks' | t"
              (onCheck)="onHideAddedTask($event)"
            ></ui-checkbox>
          </div>
        </div>
      </div>
      <div scrollToBottom class="list-container" (onScrollToBottom)="handleLoadMore()">
        <div cdkDropList
             id="MDTasks"
             [cdkDropListDisabled]="isReadonly"
             [cdkDropListData]="dragDropRegions['MDTasks'].items"
             [cdkDropListConnectedTo]="formDropRegions"
             [cdkDropListSortingDisabled]="true"
             [cdkDropListEnterPredicate]="noReturnPredicate"
             (cdkDropListDropped)="libraryClearPickedItem()"
             class="task-library collapses-on-pane-closed">
          <ng-container *ngFor="let item of dragDropRegions['MDTasks'].items"
                        class="library-items">
            <div cdkDrag
                 (cdkDragStarted)="librarySetPickedItem($event)"
                 [cdkDragData]="item">
              <div class="drag-placeholder" *cdkDragPlaceholder></div>
              <playbook-task-library-card class="task-card"
                                          [playbooksTable]="item.itemContext.task">
              </playbook-task-library-card>
            </div>
            <div class="item-placeholder" *ngIf="pickedItem === item">
              <playbook-task-library-card [playbooksTable]="item.itemContext.task">
              </playbook-task-library-card>
            </div>
          </ng-container>
        </div>
      </div>
    </div>




  </div>
</div>
