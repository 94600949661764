import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { I18nModule } from "@common/modules/i18n/i18n.module";
import { FeatureSharedModule } from "@common/modules/shared/feature.shared.module";
import { UiKitModule } from "@ui-kit/uikit.module";
import { PipesModule } from "projects/@common/modules/pipes/pipes.module";

import { SharedModule } from "projects/console-adm/src/app/shared/shared.module";
import { HistoryDetailComponent } from "./components/history-detail/history-detail.component";
import { HistoryTableComponent } from './components/history-table/history-table.component';
import { HistoriesContainer } from "./containers/histories/histories.container";
import { HistoriesFiltersComponent } from "./components/histories-filters/histories-filters.component";
import { FilterModule } from "../filters/filters.module";
import { HistoriesIndicatorsComponent } from "./components/histories-indicators/histories-indicators.component";

@NgModule({
  declarations: [
    HistoryDetailComponent,
    HistoryTableComponent,
    HistoriesContainer,
    HistoriesFiltersComponent,
    HistoriesIndicatorsComponent
  ],
  imports: [
    CommonModule,
    UiKitModule,
    SharedModule,
    I18nModule,
    ReactiveFormsModule,
    FeatureSharedModule,
    PipesModule,
  ],
  exports: [
    HistoryDetailComponent,
    HistoryTableComponent,
    HistoriesContainer,
  ],
})
export class HistoriesModule {
}
