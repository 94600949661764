import { Component, OnInit } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IdpApi } from 'projects/@common/services/api/iam/idp/idp.api';
import { MeApi } from 'projects/@common/services/api/iam/me/me.api';

export interface AppRegistrationInfo {
  [name: string]: {
    active: boolean;
  };
}

export enum ApplicationNames {
  ELEVATED = 'md.eco.elevated',
  BASIC = 'md.eco'
}

@Component({
  selector: 'app-registration-update-container',
  templateUrl: './app-registration-update.container.html',
  styleUrls: [ './app-registration-update.container.scss' ],
})
export class AppRegistrationUpdateContainer implements OnInit {
  public loading = true;
  public showActivationPrompt = false;
  public activationMessage = '';
  public myOrganizationDetails;
  public appToActivate = '';

  public apps: AppRegistrationInfo = {};

  constructor (
    private meApi: MeApi,
    private notice: NoticeService,
    private i18n: I18nService,
    private idpApi: IdpApi
  ) {
  }

  ngOnInit(): void {
    this.fetchMyOrganizationDetails();
  }

  public displayPrompt(app) {
    this.appToActivate = app.key;
    this.activationMessage = this.i18n.translate('iam.app-registration.upgrade.confirmation.prompt.details', {
      appName: this.i18n.translate(`iam.app-registration.upgrade.${app.key}-app.name`),
    });
    this.showActivationPrompt = true;
  }

  public sendActivationRequest(event) {
    if (event === 'action') {
      this.idpApi.getAdminConsentUrl({
        application: this.appToActivate,
        tenantId: this.myOrganizationDetails?.identityProvider?.tenantId,
        redirectUrl: `${window.location.href}/redirect`,
      })
        .then((data) => {
          this.setAppToActivateInLocalStorage(this.appToActivate);
          window.location.href = data.url;
        })
        .catch(() => {
          this.setAppToActivateInLocalStorage('');
          this.notice.notifyUser(new Notice('Could initiate the activation process properly', NoticeLevels.ERROR));
        })
        .finally(() => {
          this.appToActivate = '';
          this.showActivationPrompt = false;
        });
    } else {
      this.appToActivate = '';
      this.showActivationPrompt = false;
    }
  }

  private fetchMyOrganizationDetails(): void {
    this.meApi.describeMyOrganization()
      .then((data) => {
        this.myOrganizationDetails = data;
        this.intializeAppStates();
        this.loading = false;
      })
      .catch(() => {
        this.notice.notifyUser(new Notice('Could not fetch current organization', NoticeLevels.ERROR));
      });
  }

  private intializeAppStates(): void {
    this.apps[ApplicationNames.BASIC] = {
      active: false,
    };

    this.apps[ApplicationNames.ELEVATED] = {
      active: false,
    };

    if (this.myOrganizationDetails.identityProvider.applicationName === ApplicationNames.BASIC) {
      this.apps[ApplicationNames.BASIC].active = true;
    }

    if (this.myOrganizationDetails.identityProvider.applicationName === ApplicationNames.ELEVATED) {
      delete this.apps[ApplicationNames.BASIC];
      this.apps[ApplicationNames.ELEVATED].active = true;
    }
  }

  private setAppToActivateInLocalStorage(value: string) {
    localStorage.setItem('appToActivate', value);
  }
}
