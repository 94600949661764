<ui-title
  class="title"
  [name]="'incidents.container.page.details.modal.closeIncident.title' | t"
>
  <div class="flex-box center-horizontal">
    <div
      class="copy-incident-url flex-box center-horizontal"
      [tooltip]="'incidents.container.page.details.modal.closeIncident.copyIncidentURL.tooltip'|t"
      (click)="copyIncidentURL()"
    >
      <img
        class="copy-incident-url--icon"
        src="assets/favicons/icon_copy.svg"
        alt="icon copy"
      >
      <span class="copy-incident-url--text">{{incident.shortId}}</span>
    </div>
    <organization-preferred-language
      *ngIf="incident?.organizationEcoId"
      [userLocale]="i18n.currentLocale"
      [organizationEcoId]="incident?.organizationEcoId"
    ></organization-preferred-language>
  </div>
</ui-title>

<ui-warning
  *ngIf="hasIncompleteTasks"
  class="warning"
  [warningMessages]="[{messageKey:'incidents.container.page.details.modal.closeIncident.warning.unmetConditions' | t}]"
  [color]="'yellow'"
  [centerText]="false"
  [roundedBorder]="true"
></ui-warning>

<ui-warning
  *ngIf="isBreachOfConfidentiality && !hasSocManagerPermission"
  class="warning"
  [warningMessages]="[{messageKey:'incidents.container.page.details.modal.closeIncident.warning.breachOfConfidentiality.soclvl1'| t}]"
  [color]="'red'"
  [showIcon]="true"
  [centerText]="false"
  [roundedBorder]="true"
></ui-warning>

<ui-warning
  *ngIf="isBreachOfConfidentiality && hasSocManagerPermission"
  class="warning"
  [warningMessages]="[{messageKey:'incidents.container.page.details.modal.closeIncident.warning.breachOfConfidentiality.soclvl2'| t}]"
  [color]="'yellow'"
  [centerText]="false"
  [roundedBorder]="true"
></ui-warning>

<ui-text-field
  name="summary"
  control="summary"
  maxlength="20000"
  rowsNumber="5"
  [label]="'incidents.container.page.details.tab.detail.summary.summary' | t"
  [group]="form"
  [required]="useManualClosedAtDate"
  [disabled]="shouldPreventClosingIncident"
  [readonly]="shouldPreventClosingIncident"
></ui-text-field>

<div class="user-inputs">
  <ui-select
    class="status"
    name="incidentStatus"
    control="incidentStatus"
    [label]="'incidents.container.page.details.modal.closeIncident.status.label' | t"
    [group]="form"
    [required]="true"
    [disabled]="isBreachOfConfidentiality"
    [biggerOptionsHeight]="true"
    [options]="status.selectableOptions"
    (optionClick)="setStatusValue($event)"
  ></ui-select>

  <ng-container *ngIf="displayJustification">
    <div>
      <label
        ui-label
        [required]="form.get('incidentStatus').value === 'CLOSE_FALSE_POSITIVE'"
      >{{ closeIncidentJustificationLabel | t }}</label>
      <div class="justification-section">
        <div *ngFor="let justification of justifications.controls; let i = index" class="justification">
          <ui-select
            control="type"
            class="justification-select"
            [placeholder]="'incidents.container.page.details.modal.closeIncident.option.placeholder' | t"
            [group]="justification"
            [required]="true"
            [options]="justificationOptions"
            (optionClick)="setJustification($event, i)"
          ></ui-select>

          <div class="justification-select-details">
            <autocomplete
              *ngIf="!!setJustificationDetailList(justification?.get('type').value) && !justification?.get('value').value"
              [autocompleteType]="autocompleteTypes.CUSTOM"
              [placeholder]="'incidents.container.page.details.modal.closeIncident.option.placeholder' | t"
              [required]="true"
              [customValues]="setJustificationDetailList(justification?.get('type').value)"
              [canMultiSelect]="false"
              [showInInputAtInit]="true"
              (onItemSelected)="setJustificationDetail($event, i)"
            >
            </autocomplete>
            <ui-user-tag *ngIf="!!justification?.get('value').value"
              keyNameToDisplay="displayValue"
              [user]="justification?.get('value').value"
              [showAvatar]="false"
              (onDeleteUser)="resetJustificationDetail($event, i)"
            >
              
            </ui-user-tag>
          </div>
          <button
            ui-button
            *ngIf="!(form.get('incidentStatus').value === 'CLOSE_FALSE_POSITIVE' && i === 0)"
            class="delete-button" 
            icon="icon_delete"
            color="listActionFlat"
            custom-icon-size="medium"
            [isSvg]="true"
            [custom-icon]="true"
            (click)="removeJustification(i)">
          </button>
        </div>
      </div>
      <button
        ui-button
        class="add-button" 
        icon="icon_create"
        color="listActionFlat"
        custom-icon-size="medium"
        [isSvg]="true"
        [custom-icon]="true"
        (click)="addJustification()">
        {{ 'incidents.container.page.details.modal.closeIncident.button.add' | t }}
      </button>
    </div>
  </ng-container>

  <ui-field-group>
    <div class="severity">
      <label
        ui-label
        [required]="true"
      >{{'incidents.container.page.details.modal.closeIncident.severity.label' | t}}</label>
      <app-severity-picker
        [value]="severity.currentValue"
        [styleLikeInputField]="true"
        [disabled]="shouldPreventClosingIncident"
        (choiceEvent)="setSeverityValue($event)"
      ></app-severity-picker>
    </div>
  </ui-field-group>

  <ui-text-field
    name="comment"
    control="comment"
    maxlength="5000"
    rowsNumber="3"
    [label]="'incidents.container.page.details.modal.closeIncident.comment.label' | t"
    [group]="form"
    [required]="useManualClosedAtDate"
    [disabled]="shouldPreventClosingIncident"
    [readonly]="shouldPreventClosingIncident"
  ></ui-text-field>

  <div class="toggles-container">
    <div class="toggle-date">
      <ui-toggle
        [toggled]="useManualClosedAtDate"
        [labelEnabled]="'incidents.container.page.details.modal.closeIncident.manualCloseDate.toggle' | t"
        [labelDisabled]="'incidents.container.page.details.modal.closeIncident.manualCloseDate.toggle' | t"
        [disabledWhenUntoggled]="false"
        [switchRightSide]="false"
        [boldLabel]="false"
        [ignoreLabelClicks]="true"
        (onClick)="toggleManualCloseDate()"
      ></ui-toggle>
      <ui-tooltip [placementRight]="true">
        {{ 'incidents.container.page.details.modal.closeIncident.manualCloseDate.tooltip' | t}}
      </ui-tooltip>
    </div>
    <div class="toggle-collaboration">
      <ui-toggle
        [toggled]="useCollaborationTime"
        [labelEnabled]="'incidents.container.page.details.modal.closeIncident.collaborationTime.toggle' | t"
        [labelDisabled]="'incidents.container.page.details.modal.closeIncident.collaborationTime.toggle' | t"
        [disabledWhenUntoggled]="false"
        [switchRightSide]="false"
        [boldLabel]="false"
        [ignoreLabelClicks]="true"
        (onClick)="toggleCollaborationTime()"
      ></ui-toggle>
      <ui-tooltip [placementRight]="false">
        {{ 'incidents.container.page.details.modal.closeIncident.collaborationTime.tooltip' | t}}
      </ui-tooltip>
    </div>
  </div>

  <div
    *ngIf="useManualClosedAtDate"
    class="flex-box align-bottom"
  >
    <ui-datetime-picker
      #datetimePicker
      [defaultTimestamp]="manualClosedAt"
      [minDate]="minDate"
      [maxDate]="dateNow"
      [showNowButton]="false"
      [label]="'incidents.container.page.details.modal.closeIncident.manualCloseDate.label' | t"
      [showResetButton]="!!manualClosedAt"
      [required]="true"
      [locale]="locale"
      (timestampChangedEvent)="handleCloseDateChange($event)"
    ></ui-datetime-picker>

    <button
      *ngIf="!manualClosedAt && lastClosedAtTimestamp"
      ui-button
      color="standard"
      [tooltip]="'incidents.container.page.details.modal.closeIncident.manualCloseDate.originalDate.tooltip' | t"
      (click)="datetimePicker.setTimestamp(lastClosedAtTimestamp)"
    >{{'incidents.container.page.details.modal.closeIncident.manualCloseDate.originalDate' | t}}</button>
  </div>

  <div
    *ngIf="useCollaborationTime"
    class="flex-box align-bottom"
  >
    <div class="collaboration-time">
      <div class="flex-box">
        <label
          ui-label
          [required]="true"
        >
          {{"incidents.container.page.details.modal.closeIncident.collaborationTime.label" | t}}
        </label>
        <ui-tooltip [placementRight]="false">
          {{ 'incidents.container.page.details.modal.closeIncident.collaborationTime.tooltip' | t}}
        </ui-tooltip>
      </div>
      <ui-duration-time-picker
        #durationPicker
        [defaultDurationMs]="collaborationTime.milliseconds || 60000"
        [isDisabled]="shouldPreventClosingIncident"
        [fullWidth]="true"
        (millisecondsChangedEvent)="setCollaborationTime($event)"
      ></ui-duration-time-picker>
    </div>
  </div>
</div>

<div class="buttons">
  <button
    ui-button
    color="standard"
    [disabled]="!isSaveActionAvailable"
    (click)="handleConfirm()"
  >
    <ui-spinner
      *ngIf="asyncState === asyncStateEnum.SAVING"
      size="small"
    ></ui-spinner>
    {{"incidents.container.page.details.modal.closeIncident.confirmButton" | t}}
  </button>

  <button
    ui-button
    [disabled]="!isCancelActionAvailable"
    (click)="handleCancel()"
  >
    {{'common.action.cancel' | t}}
  </button>
</div>