import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "@common/components/components.module";
import { UiKitModule } from "@ui-kit/uikit.module";
import { QueryBuilderModule } from "angular2-query-builder";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { FeatureSharedModule } from "projects/@common/modules/shared/feature.shared.module";
import { AlertStatusComponent } from "projects/@respond/components/alert-status/alert-status.component";
import { ObservablesModule } from "../observables/observables.module";
import { ConnectorsMultiselectComponent } from './connectors-multiselect/connectors-multiselect.component';
import { QueryBuilderComponent } from "./query-builder/query-builder.component";
import { RespondEntityWithCollapseComponent } from './respond-entity-with-collapse/respond-entity-with-collapse.component';
import { RespondEntityComponent } from "./respond-entity/respond-entity.component";
import { ShortIdComponent } from "./short-id/short-id.component";
import { SimilarityFiltersComponent } from "./similarity/similarity-filters/similarity-filters.component";
import { SimilarityActionsComponent } from './similarity/similarity-table/similarity-actions/similarity-actions.component';
import { SimilarityIndicatorComponent } from './similarity/similarity-table/similarity-indicator/similarity-indicator.component';
import { SimilarityStatusComponent } from './similarity/similarity-table/similarity-status/similarity-status.component';
import { SimilarityTableComponent } from './similarity/similarity-table/similarity-table.component';
import { SimilarityContainerComponent } from "./similarity/similarity.container";
import { SecretStoreUploadOrGenerateComponent } from './secrets/secret-store-upload-or-generate/secret-store-upload-or-generate.component';
import { QueryBuilderJmespathComponent } from "./query-builder-jmespath/query-builder-jmespath.component";

const components = [
  ConnectorsMultiselectComponent,
  SecretStoreUploadOrGenerateComponent,
  SimilarityContainerComponent,
  SimilarityFiltersComponent,
  SimilarityTableComponent,
  SimilarityStatusComponent,
  SimilarityIndicatorComponent,
  SimilarityActionsComponent,
  AlertStatusComponent,
  ShortIdComponent,
  QueryBuilderComponent,
  QueryBuilderJmespathComponent,
  RespondEntityComponent,
  RespondEntityWithCollapseComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
    ObservablesModule,
    RouterModule,
    QueryBuilderModule,
    MatButtonToggleModule,
    MatCheckboxModule,
  ],
})
export class RespondComponentsModule { }
