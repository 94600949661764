import { Component, Input } from '@angular/core';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { Icon } from 'projects/@common/services/api/respond/datasources/datasources.definitions';


export enum DatasourceMode {
  SILENCE = 'silence',
  ALERT = 'alert'
}

export interface IDatasourceTableObject {
  name: ITranslatedField;
  mode: DatasourceMode;
  priority: number;
  activationDate: number;
  description: ITranslatedField;
  datasources: string;
  datasourcesIcon: Icon[];
  catalogId: string;
}

@Component({
  selector: 'detail-body',
  templateUrl: './detail-body.component.html',
  styleUrls: [ './detail-body.component.scss' ],
})
export class DetailBodyComponent {
  @Input() public datasources: IDatasourceTableObject[] = [];
  @Input() public loading = false;
  @Input() public refreshing = false;

  private getModeIconName(datasource: any): string {
    return datasource.mode === DatasourceMode.SILENCE ? 'favicons/icon_green_dot_reverse' : 'favicons/icon_green_dot';
  }
}
