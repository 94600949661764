import { Injectable } from '@angular/core';
import { DisplayRequirements } from '@common/modules/display/display.service';
import { BaseSidebar, ServiceTypeEnum, navigationElement } from '@common/modules/layout/definitions/base-sidebar.service';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';

@Injectable()
export class OrgIamSidebar extends BaseSidebar {
  private elements: navigationElement[] = [
    { name: 'sidebar.navigation.collaboration.name' },
    {
      rootAction: true,
      name: 'sidebar.navigation.dashboard',
      icon: 'icon_menu_dashboard',
      route: '/csp/dashboard',
      permissions: [ 'list_users_with_shares', 'list_resources_with_shares' ],
    },
    {
      rootAction: true,
      name: 'guest.users.sidebar',
      icon: 'sidebar/icon_sidebar_communication',
      route: '/csp/guests/management',
      permissions: ['list_guest_orphans'],
      flags: [FeaturesEnum.GUESTS_MANAGEMENT],
    },
    {
      name: 'sidebar.navigation.assistops.shares',
      icon: 'icon_admin_menu_shares',
      permissions: [ 'list_users_with_shares', 'list_resources_with_shares' ],
      flags: [ FeaturesEnum.SHARES_MANAGEMENT ],
      children: this.getSharesChildren(),
    },
    {
      name: 'sidebar.navigation.assistops.configs',
      icon: 'sidebar/icon_sidebar_config',
      permissions: [ 'create_resource' ],
      children: this.getSettingsChildren(),
    },
  ];

  constructor() {
    super();
  }

  public unsubscribe() {
  }

  public getElements(): navigationElement[] {
    return this.elements;
  }

  public getRequirements(): DisplayRequirements {
    return {
      services: [ ServiceTypeEnum.PROACTIVE_CYBERSECURITY ],
    };
  }

  private getSharesChildren(): navigationElement[] {
    return [
      {
        rootAction: true,
        name: 'sidebar.navigation.assistops.shares.users',
        route: '/shares/users',
        permissions: [],
      },
      {
        rootAction: true,
        name: 'sidebar.navigation.assistops.shares.resources',
        route: '/shares/resources',
        permissions: [],
      },
    ];
  }

  private getSettingsChildren(): navigationElement[] {
    return [
      {
        rootAction: true,
        name: 'sidebar.navigation.assistops.securityModels',
        route: '/csp/templates',
        flags: [ FeaturesEnum.RESOURCE_MANAGEMENT ],
        permissions: [ 'create_resource' ],
      },
      {
        rootAction: true,
        name: 'sidebar.navigation.assistops.subSettings',
        route: '/csp/settings',
        permissions: [ 'list_organization_settings' ],
      },
    ];
  }
}
