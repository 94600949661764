import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { FormErrorUtils } from 'projects/@common/utils/formErrors-utils';
import { CustomValidators } from 'projects/@common/utils/validators';

const DEFAULT_VALUES = {
  durationMediumDayCount: 15,
  durationLongDayCount: 60,
};

@Component({
  selector: 'app-shares-duration-tolerance',
  templateUrl: './shares-duration-tolerance.component.html',
  styleUrls: [ './shares-duration-tolerance.component.scss' ],
})
export class SharesDurationToleranceComponent implements OnInit {
  @Input() public currentSettings: OrganizationShareSettings;
  @Input() public isLoading = false;

  public isEditing = false;
  public toleranceForm: UntypedFormGroup;

  private formErrorUtils: FormErrorUtils;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly i18nService: I18nService,
    private readonly organizationsApiService: OrganizationsApiService,
    private readonly noticeService: NoticeService
  ) {
    this.formErrorUtils = new FormErrorUtils(this.i18nService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  public get durationMediumDayCountControl(): AbstractControl {
    return this.toleranceForm.get('durationMediumDayCount');
  }

  public get durationLongDayCountControl(): AbstractControl {
    return this.toleranceForm.get('durationLongDayCount');
  }

  public get isValid(): boolean {
    return this.toleranceForm.valid;
  }

  public get isDefaultValues(): boolean {
    return JSON.stringify(this.toleranceForm.getRawValue()) == JSON.stringify(DEFAULT_VALUES);
  }

  public getErrorMessage(control: AbstractControl): string {
    return this.formErrorUtils.getErrorMessage(control);
  }

  public cancel(): void {
    this.resetForm();
  }

  public restoreDefaults(): void {
    this.toleranceForm.setValue(DEFAULT_VALUES);
  }

  public save(): void {
    this.organizationsApiService.updateOrganizationSettings(this.formToSave)
      .then((response) => {
        this.currentSettings = response;
        this.toleranceForm.patchValue(response);
        this.noticeService.notifyUser({ message: 'settings.shares.duration.tolerance.snack.success', level: NoticeLevels.SUCCESS });
      }).catch((error) => {
        this.noticeService.notifyUser({ message: 'settings.notification.frequency.snack.error', level: NoticeLevels.ERROR });
      });
  }

  private initForm(): void {
    this.toleranceForm = this.fb.group({
      durationMediumDayCount: [ null, [ Validators.required, CustomValidators.numberValidator, CustomValidators.numberValidatorNotZero ] ],
      durationLongDayCount: [ null ],
    });

    this.toleranceForm.patchValue({
      durationMediumDayCount: this.currentSettings?.durationMediumDayCount || 15,
      durationLongDayCount: this.currentSettings?.durationLongDayCount || 60,
    });

    this.durationLongDayCountControl.setValidators([
      Validators.required,
      CustomValidators.numberValidator,
      CustomValidators.isGreaterThan(this.durationMediumDayCountControl),
    ]);
    this.durationLongDayCountControl.updateValueAndValidity();
    this.durationMediumDayCountControl.valueChanges.subscribe(() => {
      this.durationLongDayCountControl.markAsDirty();
      this.durationLongDayCountControl.updateValueAndValidity();
    });
  }

  private resetForm(): void {
    this.toleranceForm.patchValue(this.currentSettings);
  }

  private get formToSave(): { newSharesSettings: Partial<OrganizationShareSettings>; } {
    return {
      newSharesSettings: {
        durationMediumDayCount: +this.durationMediumDayCountControl.value,
        durationLongDayCount: +this.durationLongDayCountControl.value,
      },
    };
  }
}
