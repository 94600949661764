import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlUtils } from "projects/@common/utils/utils";
import { Observable } from "rxjs";
import { ApiEndpointPrefixEnum, EcoApi } from "../../../../definitions/eco-api";
import { EcoUrlService } from "../../core/url.service";
import { IExecuteToolboxActionsResponse, IListToolboxActionsRequest, IListToolboxActionsResponse, IToolboxActionExecutionRequest } from "./actions-toolbox.definition";

@Injectable({
  providedIn: 'root',
})
export class ActionsToolboxApi extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  constructor(http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listActions(params: IListToolboxActionsRequest): Observable<IListToolboxActionsResponse> {
    const url = `${this.host}/toolbox?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IListToolboxActionsResponse>(url);
  }

  public executeAction(params: IToolboxActionExecutionRequest): Observable<IExecuteToolboxActionsResponse> {
    const url = `${this.host}/toolbox`;
    return this.http.post<IExecuteToolboxActionsResponse>(url, params);
  }
}
