import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ITabsInfo } from "projects/@common/components/custom-tabs/custom-tabs.component";
import { ActifsListViewModeEnum, IAssetPropertyConfigRepresentation, IAssetRepresentation, ListActifsPageEnum } from "projects/@common/services/api/respond/actifs/actifs.definitions";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { IComputedEscalation, IDescribeIncidentResponse, IIncidentActionRepresentation, IIncidentMessage, IIncidentTimestamp } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { UsecasesApiService } from "projects/@common/services/api/respond/usecase/usecase.api";
import { IUsecaseDescriptionResponse } from "projects/@common/services/api/respond/usecase/usecase.definition";
import { ActifsService } from "projects/@respond/actifs/actifs.service";

import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { Notice, NoticeLevels, NoticeService } from "projects/@common/modules/notice/notice.service";
import { NotificationsApiService } from "projects/@common/services/api/notifications/notifications-api.service";
import { IExecuteToolboxActionsResponse } from "projects/@common/services/api/respond/actions-toolbox/actions-toolbox.definition";
import { IEntityRepresentation } from "projects/@common/services/api/respond/entities/entities.definition";
import { mapEntityRepresentationToDisplayEntity } from "projects/@common/services/api/respond/entities/entities.mapper";
import { IObservable } from "projects/@common/services/api/respond/observables/observables.definition";
import { WhitelistsViewModeEnum } from "projects/@common/services/api/respond/whitelists/whitelists.definitions";
import { SimilarityOnAssociateEventData } from "projects/@respond/components/similarity/similarity.definitions";
import { SimilarityEventsService } from "projects/@respond/components/similarity/similarity.events.service";
import { ListWhitelistPageEnum, WhitelistsService } from "projects/@respond/whitelists/whitelists.service";
import { Subscription } from "rxjs/internal/Subscription";
import { IncidentDifferenceService } from "../../services/incident.difference.service";
import { IncidentEventsService } from "../../services/incident.events.service";
import { IncidentCloseModalComponent } from "./components/incident-close-modal/incident-close-modal.component";
import { IncidentNotificationModalComponent } from "./components/incident-notification-modal/incident-notification-modal.component";
import { IncidentReopenModalComponent } from "./components/incident-reopen-modal/incident-reopen-modal.component";
import { TaskTabComponent } from "./components/task-tab/task-tab.component";
import { ModalService } from "@ui-kit/services/modal.service";
import { Finding } from "@common/services/api/respond/finding/model/finding";
import { ResponseUtils } from "@common/utils/response-utils";
import { IincidentTaskMessage } from "@common/services/api/respond/incidentTasks/incidentTasks.definitions";
import { EcoSessionState } from "@common/modules/session/state/session.state";
import { Store } from "@ngxs/store";
import { IncidentTimestampType } from "projects/@respond/incident/components/incidents-details/components/chronology-tab/definitions/chronologyTimestamp.definitions";


export enum TabNamesEnum {
  DETAILS = "DETAILS",
  TASKS = "TASKS",
  OBSERVABLES = "OBSERVABLES",
  ALERTS = "ALERTS",
  CONVERSATION = "CONVERSATION",
  SIMILARITY = "SIMILARITY",
  CHRONOLOGY = "CHRONOLOGY",
  HISTORY = "HISTORY"
}

export interface IIncidentDetailsViewParams {
  isEditable: boolean;
  isAdminConsole: boolean;
  tabsToHide: TabNamesEnum[];
  showHeaderOrgInfo: boolean;
}

@Component({
  selector: 'respond-incidents-details',
  templateUrl: './incidents-details.component.html',
  styleUrls: [ './incidents-details.component.scss' ],
})
export class IncidentsDetailsComponent implements OnInit, OnDestroy {
  public incident: Incident;
  public isLoadingIncident: boolean = true;

  public task: any;
  public currentTabSelected: ITabsInfo;

  public usecases: IUsecaseDescriptionResponse[] = [];
  public isLoadingUsecase: boolean = false;

  public incidentConversation: IIncidentMessage[] = [];

  public observables: IObservable[];
  public isLoadingObservables: boolean = true;

  public entities: IEntityRepresentation[] = [];
  public incidentNotes: IIncidentMessage[];
  public isLoadingEntities: boolean = true;
  public incidentFindings: Finding[];

  public incidentActions: IIncidentActionRepresentation[] = [];
  public isLoadingActions: boolean = true;
  public tasksMessages: (IincidentTaskMessage & {messageContent: string})[] = [];

  public computedEscalationList: IComputedEscalation;

  private isVarMode = !!this.store.selectSnapshot(EcoSessionState.varMode);

  public isNotificationActionsFilterEnabled: boolean = false;

  @Input()
    public tabs: ITabsInfo[];

  @Input()
    public viewParams: IIncidentDetailsViewParams = { isEditable: true, isAdminConsole: false, tabsToHide: [], showHeaderOrgInfo: true };

  @ViewChild('taskTabRef')
    public taskTabRef: TaskTabComponent;

  private incidentId: string;
  private organizationId: string;
  private incidentDifferenceSubscription: Subscription;
  private onAssociateNewAlertSubscription: Subscription;
  private actionExecutedSubscription: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly incidentApi: IncidentsApi,
    private readonly modalService: ModalService,
    private readonly usecaseApi: UsecasesApiService,
    private readonly actifsService: ActifsService,
    private readonly noticeService: NoticeService,
    private readonly notifsApi: NotificationsApiService,
    private readonly i18n: I18nService,
    private readonly whitelistsService: WhitelistsService,
    private readonly incidentDifferenceService: IncidentDifferenceService,
    private readonly incidentEventsService: IncidentEventsService,
    private readonly similarityEventsService: SimilarityEventsService,
    private readonly store: Store,
  ) { }

  ngOnInit(): void {
    this.setupTabs();
    this.incidentId = this.route.snapshot.paramMap.get('incidentId');
    this.organizationId = this.route.snapshot.paramMap.get('organizationEcoId');
    this.loadAll();

    this.incidentDifferenceService.reset();
    this.incidentDifferenceSubscription = this.incidentDifferenceService.stateUpdateObservable.subscribe(() => {
      this.handleTabWarningIcon();
    });

    this.onAssociateNewAlertSubscription = this.similarityEventsService.onAssociateSuccessEvent$.subscribe((data: SimilarityOnAssociateEventData) => {
      this.loadAll();
    });

    this.actionExecutedSubscription = this.incidentEventsService.actionExecutedObservable$.subscribe((response: IExecuteToolboxActionsResponse[]) => {

      this.incidentActions = Array.from(new Set(this.incidentActions.concat(response.map((e) => e.incidentAction))));
      this.sortIncidentActions();
      this.incident.tasks = this.incident.tasks.map((incidentTask) => {

        if (response.map((e) => e.incidentTaskId).includes(incidentTask.task.id)) {
          return {
            ...incidentTask,
            actions: Array.from(new Set(incidentTask.actions.concat(response.map((e) => e.incidentAction.id)))),
          };
        }
        return incidentTask;
      });

      // Check if we have new entities for this executed action, then merge it to the existing and avoid duplicates.
      if(response[0]?.successful && response[0]?.entities?.length){
        this.entities = Array.from(
          new Set(
            this.entities.concat(response[0]?.entities || [])
          )
        );
      }

      this.incident.emitReloadedEvent(); // for other components to be notified
    });
  }

  ngOnDestroy(): void {
    this.incidentDifferenceSubscription?.unsubscribe();
    this.onAssociateNewAlertSubscription?.unsubscribe();
    this.actionExecutedSubscription?.unsubscribe();
  }

  handleComputedEscalationListChange(computedEscalationList: IComputedEscalation) {
    this.computedEscalationList = computedEscalationList;
  }

  public get showTaskTab(): boolean {
    return !!this.tabs.find((value) => value.name === TabNamesEnum.TASKS);
  }

  public get showObservablesTab(): boolean {
    return !!this.tabs.find((value) => value.name === TabNamesEnum.OBSERVABLES);
  }

  public get showHistoryTab(): boolean {
    return !!this.tabs.find((value) => value.name === TabNamesEnum.HISTORY);
  }

  public get showAlertsTab(): boolean {
    return !!this.tabs.find((value) => value.name === TabNamesEnum.ALERTS);
  }

  public get showSimilarityTab(): boolean {
    return !!this.tabs.find((value) => value.name === TabNamesEnum.SIMILARITY);
  }

  public get assets(): IAssetRepresentation[] {
    return this.actifsService.assetsData;
  }

  public get assetTypes(): IAssetPropertyConfigRepresentation[] {
    return this.actifsService.assetTypes;
  }

  public get isLoadingAssets(): boolean {
    return this.actifsService.isLoading;
  }

  private get conversationSocLastAccessedAt(): number {
    return this.incident?.timestamps?.find((timestamp) => timestamp.type === IncidentTimestampType.CONVERSATION_SOC_LAST_ACCESSED_AT)?.timestamp || 0;
  }

  private get tasksSocLastAccessedAt(): number {
    return this.incident?.timestamps.find((timestamp) => timestamp.type === IncidentTimestampType.TASKS_SOC_LAST_ACCESSED_AT)?.timestamp || 0;
  }

  private get conversationClientLastAccessedAt(): number {
    return this.incident?.timestamps.find((timestamp) => timestamp.type === IncidentTimestampType.CONVERSATION_CLIENT_LAST_ACCESSED_AT)?.timestamp || 0;
  }

  private get tasksClientLastAccessedAt(): number {
    return this.incident?.timestamps.find((timestamp) => timestamp.type === IncidentTimestampType.TASKS_CLIENT_LAST_ACCESSED_AT)?.timestamp || 0;
  }

  private get conversationLastUpdate(): number {
    return this.incident?.timestamps.find((timestamp) => timestamp.type === IncidentTimestampType.CONVERSATION_LAST_UPDATE)?.timestamp || 0;
  }

  private get tasksLastUpdate(): number {
    return this.incident?.timestamps.find((timestamp) => timestamp.type === IncidentTimestampType.TASKS_LAST_UPDATE)?.timestamp || 0;
  }

  public handleTabChanged(tab: ITabsInfo): void {
    this.currentTabSelected = tab;
    if(tab.name === TabNamesEnum.TASKS || tab.name === TabNamesEnum.CONVERSATION){
      let incidentTimestamp: IIncidentTimestamp;

      if (tab.name === TabNamesEnum.TASKS) {
        this.taskTabRef?.focusActiveTask();
        incidentTimestamp = this.incident.timestamps.find((timestamp) => timestamp.type === (this.isVarMode ? IncidentTimestampType.TASKS_SOC_LAST_ACCESSED_AT : IncidentTimestampType.TASKS_CLIENT_LAST_ACCESSED_AT))
      }else{
        incidentTimestamp = this.incident.timestamps.find((timestamp) => timestamp.type === (this.isVarMode ? IncidentTimestampType.CONVERSATION_SOC_LAST_ACCESSED_AT : IncidentTimestampType.CONVERSATION_CLIENT_LAST_ACCESSED_AT))
      }
      
      if (incidentTimestamp) {
        const timestamp = Date.now();
        this.incidentApi.updateIncidentTimestamp(this.incident.organizationId, {
          incidentId: this.incident.id,
          timestamp,
          incidentTimestamp,
          id: incidentTimestamp.id
        }).subscribe(
          (response) => {
            this.incident.timestamps = response.timestamps;
            this.setupTabs(this.tabs.indexOf(this.currentTabSelected));
          },
          (error) => this.noticeService.notifyUser(new Notice(error, NoticeLevels.ERROR))
        )
      }
    }
  }

  public async onStopInvestigation(): Promise<void> {
    const dialogRef = this.modalService.openDialog(IncidentCloseModalComponent, { incident: this.incident, observables: this.observables, findings: this.incidentFindings }, false);
    dialogRef.componentInstance.successEvent.subscribe((response) => this.onStatusUpdateSuccess(response));
  }

  public handleCloseIncident(): void {
    // prompt user with modal
    const dialogRef = this.modalService.openDialog(IncidentCloseModalComponent, { incident: this.incident, observables: this.observables, findings: this.incidentFindings }, false);
    dialogRef.componentInstance.successEvent.subscribe((response) => this.onStatusUpdateSuccess(response));
  }

  public handleReopenIncident(): void {
    // prompt user with modal
    const dialogRef = this.modalService.openDialog(IncidentReopenModalComponent, { incident: this.incident }, false);
    dialogRef.componentInstance.successEvent.subscribe((response) => this.onStatusUpdateSuccess(response));
  }

  public handleNotificationSending(escalation = true): void {
    const dialogRef = this.modalService.openDialog(IncidentNotificationModalComponent, { incident: this.incident, escalation }, false);
    dialogRef.componentInstance.successEvent.subscribe((response) => {
      this.onStatusUpdateSuccess(response);
      this.fetchIncidentConversation(this.incident);
      this.setupTabs(this.tabs.indexOf(this.currentTabSelected));
      this.incident.reloadIncident({
        ...response.incident,
        tasks: response.tasks,
      });
    });
  }

  public setIncidentFindings(incidentFindings: ResponseUtils<Finding>[]): void{
    let findings = [];
    incidentFindings.map((finding) => findings = findings.concat(finding.getItems()));
    this.incidentFindings = findings;
  }

  public setTasksMessages(tasksMessages: (IincidentTaskMessage & {messageContent: string})[]): void{
    this.tasksMessages = tasksMessages;
  }

  private onStatusUpdateSuccess(response: IDescribeIncidentResponse): void {
    this.incident.reloadIncident({
      ...response.incident,
      timestamps: response.timestamps,
      phaseTimestamps: response.phaseTimestamps,
      tasks: response.tasks,
      resolveIncidentActions: response.resolveIncidentActions,
      reportAvailable: response.reportAvailable,
    });
  }

  private setupTabs(selectedTabIndex = 0): void {
    this.tabs = [];
    const tabs = Object.values(TabNamesEnum).map((tabName, index) => ({
      name: tabName,
      id: index + 1,
      count: null,
      icon: null,
      isBold: this.setBoldTables(tabName, index)
    }));

    if (this.incident?.conversationId) {
      this.viewParams.tabsToHide = this.viewParams.tabsToHide.filter((tab) => tab !== TabNamesEnum.CONVERSATION);
    } else {
      this.viewParams.tabsToHide.push(TabNamesEnum.CONVERSATION);
    }

    this.viewParams.tabsToHide.forEach((value) => {
      const index = tabs.findIndex((currentTab) => currentTab.name === value);
      tabs.splice(index, 1);
    });

    this.tabs = tabs;
    this.currentTabSelected = this.tabs[selectedTabIndex];
  }

  private setBoldTables(value: TabNamesEnum, index: number): boolean {
    if(value === TabNamesEnum.TASKS) {
      return (this.isVarMode ? this.tasksSocLastAccessedAt : this.tasksClientLastAccessedAt) < this.tasksLastUpdate
    }

    if(value === TabNamesEnum.CONVERSATION) {
      return (this.isVarMode ? this.conversationSocLastAccessedAt : this.conversationClientLastAccessedAt) < this.conversationLastUpdate
    }

    return false;
  }

  private handleTabWarningIcon(): void {
    this.tabs.forEach((tab) => {
      const tabHasUnsavedChanges = this.incidentDifferenceService.editingStates.some((state) => state.tabNameEnum === tab.name && state.hasUnsavedChange);
      const showWarningIcon = this.incidentDifferenceService.shouldShowUnsavedWarnings && tabHasUnsavedChanges;
      if (showWarningIcon) {
        tab.icon = this.incidentDifferenceService.iconWarning;
        tab.iconTooltip = 'common.unsavedChange';
      } else {
        tab.icon = null;
        tab.iconTooltip = null;
      }
    });
  }

  private fetchUsecases(incident: Incident): void {
    if (incident.usecaseIds && incident.usecaseIds.length > 0) {
      this.isLoadingUsecase = true;

      const usecasePromises = incident.usecaseIds.map((usecaseId) => this.usecaseApi.getUsecase(incident.organizationId, usecaseId));

      Promise.all(usecasePromises).then((responses: IUsecaseDescriptionResponse[]) => {
        this.usecases = responses;
        this.isLoadingUsecase = false;
      });
    }
  }

  private fetchActifs(incident: Incident): void {
    this.actifsService.setDefaultSettings();
    this.actifsService.setPageContext({
      enum: ListActifsPageEnum.INCIDENT_DETAIL_PAGE,
      organizationId: incident.organizationId,
      incidentId: incident.id,
    });
    this.actifsService.setListViewMode(ActifsListViewModeEnum.SIMPLE_LIST);

    this.actifsService.fetchAssetTypes();
    this.actifsService.fetchAssets();
  }

  private initWhitelists(incident: Incident): void {
    this.whitelistsService.setDefaultSettings();
    this.whitelistsService.setListViewMode(WhitelistsViewModeEnum.SIMPLE_LIST);
    this.whitelistsService.setPageContext({
      enum: ListWhitelistPageEnum.INCIDENT_DETAIL_PAGE,
      organizationId: incident.organizationId,
      incidentId: incident.id,
    });
    this.whitelistsService.setWhitelistData(this.incident?.whitelists);
  }

  private sortIncidentActions(): void {
    this.incidentActions.sort((a, b) => b.createdAt - a.createdAt);
  }

  private fetchIncidentConversation(incident: Incident): void {
    if (this.incident?.conversationId) {
      this.notifsApi.describeIncidentConversation(this.incident.organizationId, incident.conversationId, { from: 0, size: 50 }).subscribe((response) => this.incidentConversation = response.getItems());
    }
  }

  private fetchIncidentNotes(incident: Incident): void {
    if (this.incident?.conversationId && this.isVarMode) {
      this.incidentApi.describeIncidentNotes(this.incident.organizationId, incident.id, { from: 0, size: 50 }).subscribe((response) => this.incidentNotes = [ ...response.getItems() ]);
    }
  }

  private fetchIncidentActions(incident: Incident): void {
    this.isLoadingActions = true;
    this.incidentApi.listIncidentActions(incident.organizationId, incident.id).subscribe(
      (response) => {
        this.incidentActions = response.getItems();
        this.sortIncidentActions();
        this.incidentEventsService.emitActionsListedEvent(this.incidentActions);
      },
      () => this.noticeService.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)),
      () => this.isLoadingActions = false
    );
  }

  private fetchIncidentObservables(incident: Incident): void {
    this.isLoadingObservables = true;
    this.incidentApi.listIncidentObservables(incident.organizationId, incident.id).subscribe(
      (response) => this.observables = response.getItems(),
      () => this.noticeService.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)),
      () => this.isLoadingObservables = false
    );
  }

  private fetchIncidentEntities(incident: Incident): void {
    this.isLoadingEntities = true;
    this.incidentApi.listIncidentEntities(incident.organizationId, incident.id).subscribe(
      (response) => this.entities = response.getItems().map((entity) => mapEntityRepresentationToDisplayEntity(entity, this.i18n)),
      () => this.noticeService.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)),
      () => this.isLoadingEntities = false
    );
  }

  private loadAll(): void {
    this.incidentApi.describeIncident(this.organizationId, this.incidentId).subscribe((value) => {
      this.incident = value;
      this.isLoadingIncident = false;
      this.fetchActifs(this.incident);
      this.fetchUsecases(this.incident);
      this.initWhitelists(this.incident);
      this.fetchIncidentConversation(this.incident);
      this.fetchIncidentNotes(this.incident);
      this.fetchIncidentObservables(this.incident);
      this.fetchIncidentActions(this.incident);
      this.fetchIncidentEntities(this.incident);
      this.setupTabs();
    });
  }

  onToggleFilterStateChanged(event: boolean): void {
    this.isNotificationActionsFilterEnabled = event;  // Update the state
  }
}
