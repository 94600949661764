import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiKitModule } from "@ui-kit/uikit.module";

import { I18nModule } from "@common/modules/i18n/i18n.module";
import { FeatureSharedModule } from "@common/modules/shared/feature.shared.module";
import { RespondDashboardModule } from "projects/@respond/dashboard/respond-dashboard.module";
import { DetailBodyComponent } from "./components/datasource-inventory-detail/components/detail-body/detail-body.component";
import { DetailHeaderComponent } from "./components/datasource-inventory-detail/components/detail-header/detail-header.component";
import { DetailLayoutComponent } from "./components/datasource-inventory-detail/components/detail-layout/detail-layout.component";
import { DetailVignetteComponent } from "./components/datasource-inventory-detail/components/detail-vignette/detail-vignette.component";
import {
  DatasourcesInventoryDetailContainerComponent
} from "./components/datasource-inventory-detail/container/datasources-inventory-detail.container/datasources-inventory-detail.container.component";
import { DatasourceCardComponent } from "./components/datasource-inventory/container/components/datasource-card/datasource-card.component";
import { InventoryLayoutComponent } from "./components/datasource-inventory/container/components/inventory-layout/inventory-layout.component";
import { DatasourcesInventoryContainerComponent } from "./components/datasource-inventory/container/datasources-inventory.container/datasources-inventory.container.component";
import { ProfileTableUsersCountComponent } from "./components/profile-table-users-count/profile-table-users-count.component";
import { UsecaseInventoryBodySectionComponent } from "./components/usecase-inventory/components/usecase-inventory-body-section/usecase-inventory-body-section.component";
import { UsecaseInventoryTopSectionComponent } from "./components/usecase-inventory/components/usecase-inventory-top-section/usecase-inventory-top-section.component";
import { UsecaseInventoryContainerComponent } from "./components/usecase-inventory/usecase-inventory.container/usecase-inventory.container.component";
import { ConnectorFamilyCardComponent } from './connectors/components/connector-card/connector-card.component';
import { ConnectorDetailsPageComponent } from './connectors/components/connector-details-page/connector-details-page.component';
import { ConnectorInstanceGraphsVolumeSectionComponent } from "./connectors/components/connector-instance-graphs-volume-section/connector-instance-graphs-volume-section.component";
import { ConnectorInstancePopupComponent } from './connectors/components/connector-instance-popup/connector-instance-popup.component';
import { ConnectorInstanceVolumetryChartSectionComponent } from './connectors/components/connector-instance-volumetry-chart-section/connector-instance-volumetry-chart-section.component';
import { ConnectorInventoryTabComponent } from './connectors/components/connector-inventory-tab/connector-inventory-tab.component';
import { ConnectorInventoryContainerComponent } from './connectors/container/connector-inventory-container/connector-inventory-container.component';
import { MdDetectionResponseRoutingModule } from "./md-detection-response-routing.module";
import { OrgUsecaseContainer } from "./org-usecases.container";
import { DetectionUsecasesTableComponent } from "./shared/components/detection-usecases-table/detection-usecases-table.component";

@NgModule({
  imports: [
    CommonModule,
    MdDetectionResponseRoutingModule,
    UiKitModule,
    I18nModule,
    FeatureSharedModule,
    RespondDashboardModule,
  ],
  declarations: [
    DatasourcesInventoryContainerComponent,
    OrgUsecaseContainer,
    InventoryLayoutComponent,
    DatasourcesInventoryDetailContainerComponent,
    DetailLayoutComponent,
    DetailHeaderComponent,
    DetailVignetteComponent,
    DetailBodyComponent,
    UsecaseInventoryContainerComponent,
    UsecaseInventoryTopSectionComponent,
    UsecaseInventoryBodySectionComponent,
    DetectionUsecasesTableComponent,
    DatasourceCardComponent,
    ProfileTableUsersCountComponent,
    ConnectorInventoryContainerComponent,
    ConnectorInventoryTabComponent,
    ConnectorFamilyCardComponent,
    ConnectorInstancePopupComponent,
    ConnectorDetailsPageComponent,
    ConnectorInstanceVolumetryChartSectionComponent,
    ConnectorInstanceGraphsVolumeSectionComponent,
  ],
})
export class MdDetectionResponseModule {
}
