<div
  page
  [appPagePaths]="['mdRespond', 'communication']"
  class="communication-page"
  [searchMode]="organizationSearchMode"
  (organizationChanged)="organizationChanged($event)"
>
  <ui-tabs
    *ngIf="!showEmptyPageToSelectOrganization; else noOrgSelectedTemplate"
    class="communication-page--tabs"
    [color]="uiTabsColor"
    [borderBelowHeader]="true"
    [hasNoLeftPadding]="true"
    [hasWhiteBackroundColor]="false"
    [defaultSelectedTabName]="selectedTab"
    (tabClicked)="handleTabClicked($event)"
  >
    <ui-tab
      *display="{ permissions: ['can_read_escalation'] }"
      [tabName]="tabsEnum.lists"
      [tabTitle]="'respond.communication.tab.escalationList.title' | t"
      tabImageActive="assets/favicons/communication/icon_communication_tab_list.svg"
      tabImageInactive="assets/favicons/communication/icon_communication_tab_list_inactive.svg"
    >
      <ng-template *ngTemplateOutlet="tabEscalationList"></ng-template>
    </ui-tab>

    <ui-tab
      *display="{ permissions: ['can_read_escalation'] }"
      [tabName]="tabsEnum.contacts"
      [tabTitle]="'respond.communication.tab.contacts.title' | t"
      tabImageActive="assets/favicons/communication/icon_communication_tab_contacts.svg"
      tabImageInactive="assets/favicons/communication/icon_communication_tab_contacts_inactive.svg"
    >
      <ng-template *ngTemplateOutlet="tabContacts"></ng-template>
    </ui-tab>

    <ui-tab
      *display="{ permissions: ['can_read_escalation'] }"
      [tabName]="tabsEnum.settings"
      [tabTitle]="'respond.communication.tab.settings.title' | t"
      tabImageActive="assets/favicons/communication/icon_communication_tab_settings.svg"
      tabImageInactive="assets/favicons/communication/icon_communication_tab_settings_inactive.svg"
    >
      <ng-template *ngTemplateOutlet="tabSettings"></ng-template>
    </ui-tab>

    <ng-container *ngIf="showHistoryTab">
      <ui-tab
        *display="{ permissions: ['can_read_escalation'] }"
        [tabName]="tabsEnum.history"
        [tabTitle]="'respond.communication.tab.history.title' | t"
        tabImageActive="assets/favicons/communication/icon_communication_tab_history.svg"
        tabImageInactive="assets/favicons/communication/icon_communication_tab_history_inactive.svg"
      >
        <ng-template *ngTemplateOutlet="tabHistory"></ng-template>
      </ui-tab>
    </ng-container>
  </ui-tabs>
</div>


<ng-template #tabEscalationList>
  <communication-tab-escalation-lists
    [escalationLists]="escalationLists"
    [contacts]="contacts"
    [connectors]="connectors"
    [playbooks]="playbooks"
    [isLoading]="isLoadingEscalationLists || isLoadingContacts || isLoadingPlaybooks || isLoadingConnectors"
    [isVarMode]="isVarMode"
    (refreshListsEvent)="fetchEscalationLists(); fetchContacts();"
    (saveListEvent)="handleSaveEscalationList($event)"
    (deleteListEvent)="handleDeleteEscalationList($event)"
  ></communication-tab-escalation-lists>
</ng-template>


<ng-template #tabContacts>
  <communication-tab-contacts
    [contacts]="contacts"
    [escalationLists]="escalationLists"
    [isLoading]="isLoadingContacts || isLoadingEscalationLists"
    [isVarMode]="isVarMode"
    (refreshContactsEvent)="fetchContacts()"
    (saveContactEvent)="handleSaveContact($event)"
    (deleteContactEvent)="handleDeleteContact($event)"
  ></communication-tab-contacts>
</ng-template>


<ng-template #tabSettings>
  <communication-tab-settings
    [parameters]="parameters"
    [isLoading]="isLoadingParameters"
    [isVarMode]="isVarMode"
    (saveParametersEvent)="handleSaveParameters($event)"
  ></communication-tab-settings>
</ng-template>


<!-- <ng-template #tabHistory>
  <communication-tab-history
    [history]="history"
    [canLoadMore]="historyResponse?.canLoadMore()"
    [isLoading]="isLoadingHistory"
    [isVarMode]="isVarMode"
    (loadMoreEvent)="fetchHistory(true)"
  ></communication-tab-history>
</ng-template> -->


<ng-template #noOrgSelectedTemplate>
  <span
    *ngIf="showEmptyPageToSelectOrganization"
    class="no-org-transition"
  >
    {{ 'organizations_select.notSelected.message' | t }}
  </span>
</ng-template>