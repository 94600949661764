import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IPlaybookTableObject } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';

export interface PlaybookInstanceSelectorPopupInput {
  playbooks: IPlaybookTableObject[];
  playbookTemplateName: string;
}

@Component({
  selector: 'playbook-instance-selector-popup',
  templateUrl: './playbook-instance-selector-popup.component.html',
  styleUrls: [ './playbook-instance-selector-popup.component.css' ],
})
export class PlaybookInstanceSelectorPopupComponent implements OnInit {
  @Input()
  public readonly playbooks: IPlaybookTableObject[];

  @Input()
  public readonly playbookTemplateName: string;

  public formGroup: UntypedFormGroup;
  public playbookOptions;
  public selectedPlaybookId: string;
  public playbook: string;

  @Output()
  public successEvent = new EventEmitter<IPlaybookTableObject>();

  @Output()
  public cancelEvent = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: PlaybookInstanceSelectorPopupInput,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly i18n: I18nService
  ) {
    this.playbooks = data.playbooks;
    this.playbookTemplateName = data.playbookTemplateName;
  }

  ngOnInit(): void {
    const defaultPlaybook = this.playbooks.length >= 1 ? this.playbooks[0] : null;
    this.formGroup = this.formBuilder.group({
      playbook: this.formBuilder.control(defaultPlaybook, Validators.required),
    });
    this.playbookOptions = this.getPlaybooksTableOptions();
  }

  public playbookSelected(selectPlaybookId: string): void {
    this.selectedPlaybookId = selectPlaybookId;
  }

  public confirmActionTrigger(): void {
    const selectedPlaybook = this.playbooks.find((playbook) => playbook.id === this.selectedPlaybookId);
    this.successEvent.emit(selectedPlaybook);
  }

  public cancelActionTrigger(): void {
    this.cancelEvent.emit();
  }

  private getPlaybooksTableOptions() {
    return this.playbooks.map((playbook) => ({
      value: playbook.id,
      displayValue: playbook.name[this.i18n.currentLocale],
    }));
  }
}
