<div class="main-container" cdkDropListGroup>
  <div 
    class="card-container tasks-section"
    [class.tasks-section-fullwidth]="!showToolboxComponent || !isToolboxExpanded"
  >

    <!-- Preparation -->
    <preparation-section *ngIf="isAdminConsole && observables && findings" [disabled]="disabled" [incident]="incident" [observables]="observables" [findings]="findings">      
    </preparation-section>

    <!-- Phases -->
    <ng-container *ngFor="let phase of incidentTaskPhases; trackBy:trackByPhaseName">

      <ui-expendable-section
        #phaseExpandableSection
        [readonly]="disabled"
        [type]="expendableTypeEnum.BIG_WHITE"
        [forceExpanded]="phase === activePhase || disabled"  
      >
        <header
          class="phase-header"
          header
          cdkDropList
          (cdkDropListEntered)="enterDropZone(phaseExpandableSection, $event)"
          (cdkDropListExited)="exitDropZone()"
        >
          <div class="flex-box">
            <p class="header-title">{{'detection.playbook.detail.task.library.phase.' + phase.name + '.filter' | t}}
              ({{phase?.numberOfTask}})
            </p>

            <span
              *ngIf="incidentDifferenceService.shouldShowUnsavedWarnings && phase.hasUnsavedChange"
              class="header-unsaved-change-warning"
            >
              <img
                [src]="incidentDifferenceService.iconInfo"
                width="24px"
                height="24px"
              >
              <p>{{"common.unsavedChange" | t}}</p>
            </span>
          </div>

          <incident-phase-menu
            *ngIf="canEditTaskStatuses"
            [hideAddCustomTask]="!canAddCustomTask(phase)"
            [hideSetPhaseInapplicable]="!canSetPhaseInapplicable(phase)"
            (setPhaseInapplicableEvent)="setPhaseInapplicable(phase, phaseExpandableSection)"
            (addCustomTaskEvent)="addCustomTask(phase, phaseExpandableSection)"
          ></incident-phase-menu>
        </header>

        <!-- Tasks of this Phase -->
        <div 
          class="phase-tasks-container">
          <div
            [id]="task.taskId" 
            *ngFor="let task of phase.tasks; trackBy:trackByTaskId;"
            class="task-wrapper"
            cdkDropList
            [cdkDropListData]="task"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListEnterPredicate]="isAllowedToDrop" 
          >
            <div
              cdkDrag
              [cdkDragData]="task"
              [cdkDragPreviewClass]="'dragging'"
              [cdkDragDisabled]="!isDragAllowed(task)">
              <div class="drag-placeholder" *cdkDragPlaceholder style="min-height: 50px;"></div>
              <ui-expendable-section
                #taskExpandableSection
                [readonly]="disabled"
                [type]="expendableTypeEnum.MEDIUM_HEADER"
                [forceExpanded]="task === activeTask || disabled"
                style="position: relative"
              >
                <header
                  class="task-header"
                  (mouseenter)="isHeaderHoveredTaskId = task.taskId"
                  (mouseleave)="isHeaderHoveredTaskId = null"
                  header
                >
                  <div class="select-wrapper title-side" style="position: relative;">
                    <img src="assets/favicons/toolbox-incidents/icon_drag.svg" 
                          class="drag-handle" 
                          *ngIf="isDragAllowed(task)">
                    <img class="task-icon" [src]="task.icon">
                    <p>{{(task.taskName| translatedObject) | parameters:paramsList | async}}</p>
                  </div>
                  <div class="select-wrapper icon-side">
                    <ng-container *ngIf="task.actions.length || task.entities.length || task.observables.length">
                      <img [src]="atLeastOneActionFlagged(task) ? 'assets/favicons/observables/icon_flag_red.svg' : 'assets/favicons/observables/icon_flag_grey.svg'">
                    </ng-container>
                    <ui-select-with-icon
                      [disabled]="!canEditTaskStatuses || availableStatus(task).length === 1"
                      [defaultValue]="task.taskStatus"
                      [options]="availableStatus(task)"
                      (optionClick)="updateTaskStatus(task.taskId, $event, taskExpandableSection);"
                    >
                    </ui-select-with-icon>

                    <div
                      class="button-wrapper"
                      *ngIf="!disabled"
                    >
                      <img
                        src="assets/favicons/icon_hidden.svg"
                        [tooltip]="'detection.playbook.task.tooltip.actions.hidden' | t"
                        [ngClass]="{'visibility-hidden': !task.isHidden, 'visibility-visible' : task.isHidden}"
                      >
                      
                      <button
                        ui-button
                        color="drawerCloseFlat"
                        icon="icon_modify_disabled"
                        [custom-icon]="true"
                        [isSvg]="true"
                        icon-size="small"
                        class="hover-opacity-always"
                        [style.paddingRight]=20
                        [style.visibility]="isHeaderHoveredTaskId === task.taskId && isDragAllowed(task) ? 'visible' : 'hidden'"
                        (click)="editTask(task); $event.stopPropagation()"
                        [tooltip]="'detection.playbook.task.tooltip.actions.edit' | t"
                      ></button>
                      <button
                        ui-button
                        color="drawerCloseFlat"
                        icon="delete"
                        icon-size="small"
                        class="hover-opacity-always"
                        [style.visibility]="isHeaderHoveredTaskId === task.taskId && isDragAllowed(task) ? 'visible' : 'hidden'"
                        (click)="deleteTask(task); $event.stopPropagation()"
                        [tooltip]="'detection.playbook.task.tooltip.actions.delete' | t"
                      ></button>
                    </div>
                  </div>
                </header>
                <app-task-container-content
                  *ngIf="incidentActions && entities && observables && incident && task"
                  #taskContainerContent
                  [incident]="incident"
                  [incidentTask]="task"
                  [incidentActions]="incidentActions"
                  [entities]="entities"
                  [observables]="observables"
                  [parametersValues]="paramsList"
                  [autoExpandTaskInstruction]="task === activeTask"
                  [isAdminConsole]="isAdminConsole"
                  [showStartButton]="false"
                  [showNextButton]="task.isInProgress && incident.isEditable && hasNextTask && !hasCustomActions(task)"
                  [showCompleteButton]="task.isInProgress && incident.isEditable && !hasNextTask && !hasCustomActions(task)"
                  [customButtons]="getCustomButtons(task)"
                  [disabled]="!canEditTaskNotes(task)"
                  (startFirstTaskEvent)="startFirstTask(task.task.id, $event.callback)"
                  (nextTaskEvent)="startNextTask(task.task.id, $event.callback, taskExpandableSection)"
                  (completeFinalTaskEvent)="completeFinalTask(task.task.id, $event.callback)"
                  (stopInvestigation)="onStopInvestigation($event.callback)"
                  (startConversation)="onStartConversation($event.escalation, $event.callback)"
                  (saveEvent)="updateTaskComment(task.task.id, $event.comment, $event.callback)"
                  (emitTaskMessages)="setTasksMessages($event)"
                ></app-task-container-content>
              </ui-expendable-section>
            </div>
          </div>
        </div>
      </ui-expendable-section>
    </ng-container>
  </div>

  <!-- sidebar on the right -->
  <incident-toolbox
    *ngIf="showToolboxComponent"
    [incident]="incident"
    [observables]="observables"
    [incidentActions]="incidentActions"
    [entities]="entities"
    [isLoadingEntities]="isLoadingEntities"
    [assets]="assets"
    [assetTypes]="assetTypes"
    (onExpandToggle)="handleToolboxExpandToggled($event)"
    ></incident-toolbox>
</div>