<sg-expendable-card
  #expendable
  [isOpen]="true"
  [stickyColor]="true"
>
  <div
    header
    class="header-content"
  >
    <div class="title">
      <img
        class="card-icon"
        *ngIf="icon"
        [src]="'assets/favicons/' + icon"
      >
      <p>{{title}}</p>
    </div>
    <edit-card-content
      [canSave]="canSave"
      [confirmationSave]="confirmationSave"
      [canEdit]="canEdit"
      (cancelEvent)="cancelEvent.emit()"
      (saveEvent)="save($event)"
      (toggleEditingEvent)="toggleEditing($event)"
    ></edit-card-content>
  </div>
  <div content>
    <ng-content></ng-content>
  </div>
</sg-expendable-card>
