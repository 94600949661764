import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { I18nModule } from "@common/modules/i18n/i18n.module";
import { UiKitModule } from "@ui-kit/uikit.module";

import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { AccountArchiverDialogComponent } from './dialogs/account-archiver-dialog/account-archiver-dialog.component';
import { DynamicFormTemplateComponent } from "./dynamic-form-template/dynamic-form-template.component";
import { DynamicMultiSimpleSelectComponent } from "./dynamic-multi-simple-select/dynamic-multi-simple-select.component";
import { JsonTextareaComponent } from "./json-textarea/json-textarea.component";
import { OrganizationFilterAutocompleteComponent } from "./organization-filter-autocomplete/organization-filter-autocomplete.component";
import { SelectWithLabelValueComponent } from "./select-with-label-value/select-with-label-value.component";

@NgModule({
  declarations: [
    SelectWithLabelValueComponent,
    JsonTextareaComponent,
    DynamicMultiSimpleSelectComponent,
    DynamicFormTemplateComponent,
    OrganizationFilterAutocompleteComponent,
    AccountArchiverDialogComponent,
  ],
  exports: [
    SelectWithLabelValueComponent,
    JsonTextareaComponent,
    DynamicMultiSimpleSelectComponent,
    DynamicFormTemplateComponent,
    OrganizationFilterAutocompleteComponent,
  ],
  imports: [
    CommonModule,
    UiKitModule,
    I18nModule,
    ReactiveFormsModule,
    AssistOpsModule,
  ],
})
export class SharedModule {
}
