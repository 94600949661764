<ui-tabs
  #tabs
  color="drawerTabs"
>
  <ui-tab [tabTitle]="'parameter.details.title' | t">
    <!-- Mode création -->
    <ng-container *ngIf="isCreatingFilter">
      <div class="filterTypeOptions">
        <ui-title [name]="'filter.type.choice.title' | t"></ui-title>
        <div class="input-choice">
          <ui-select
          [options]="typeEnum"
          name="type"
          control="type"
          [placeholder]="'datasources.tab.creation.dropdown.implementation.placeholder' | t"
          (optionClick)="handleCreationFilterTypeChoice($event)"
          [label]="'usecase.drawer.tab.filters.details.type' | t"
          [required]="true"
          ></ui-select>
        </div>
      </div>
    </ng-container>

    <!-- Afficher les détails (Création + Descriptif) -->
    <ng-container *ngIf="data.filterType" [ngSwitch]="data.filterType">
      <ng-container *ngSwitchCase="filterTypeEnum.ECS" [ngTemplateOutlet]="filterDetails"></ng-container>
      <ng-container *ngSwitchCase="filterTypeEnum.ECO_ALERT" [ngTemplateOutlet]="ecoAlertFilterDetails"></ng-container>
    </ng-container>
  </ui-tab>

  <ui-tab
    *ngIf="isConsoleAdmin && (!!filter && !isEditing)"
    [tabTitle]="'alerts.drawer.tab.history.title' | t"
  >
    <filter-history
      *ngIf="!isLoading; else spinner"
      [filter]="filter"
    ></filter-history>
  </ui-tab>
</ui-tabs>

<!-- Templates -->
<ng-template #filterDetails>
  <filter-details
    *ngIf="!isLoading; else spinner"
    [filter]="filter"
    [organizationId]="organizationId"
    [isReadonly]="data.isReadonly"
    (saveEvent)="handleSavedFilter()"
  ></filter-details>
</ng-template>

<ng-template #ecoAlertFilterDetails>
  <filter-ecoAlert-details
    *ngIf="!isLoading; else spinner"
    [filter]="filter"
    [organizationId]="organizationId"
    [isReadonly]="data.isReadonly"
    (saveEvent)="handleSavedFilter()"
  ></filter-ecoAlert-details>
</ng-template>

<ng-template #spinner>
  <div class="spinner">
    <ui-spinner
      size="medium"
      modifier="secondary"
    ></ui-spinner>
  </div>
</ng-template>