<ui-card>
  <div class="flex-box space-between margin--bottom">
    <div class="buttons-container">
      <app-edit-with-modal #editor>
        <button *display="{ permissions: ['can_update_approvers'] }" ui-button color="linkFlat" icon="icon_create" [custom-icon]="true" [isSvg]="true"
                icon-size="medium" (click)="openPopup()">
          {{ "common.action.add" | t }}
        </button>

        <div content>
          <div class="autocomplete">
            <h3>{{ "guest.users.management.admin-list.add.title" | t }}</h3>
            <hr />
            <autocomplete #autocomplete [oneItemAtATime]="true" [canMultiSelect]="false" expandHeight="max-content"
                          [placeholder]="'guestManagers.search.placeholder' | t" [autocompleteType]="usersAutocomplete"
                          [searchOnlyUserResources]="false" (onItemSelected)="onItemSelected($event)"
                          [searchFunction]="usersAutocompleteSearchFunction" name="userAutocomplete" [required]="true" [locale]="i18n.currentLocale">
            </autocomplete>
            <div class="continueButton">
              <button [disabled]="!adminToAdd" class="button-spacing" ui-button color="standard" (click)="addGuestAdmin()">
                {{ "common.action.confirm" | t }}
              </button>
              <button class="button-spacing" ui-button color="linkFlat" (click)="closePopup()">
                {{ "common.action.cancel" | t }}
              </button>
            </div>
          </div>
        </div>
      </app-edit-with-modal>
      <button *ngIf="adminsSelected.length" ui-button color="linkFlat" icon="icon_delete" [custom-icon]="true" [isSvg]="true" icon-size="medium"
              (click)="removeGuestAdmins()">
        {{ "common.action.delete" | t }}
      </button>
    </div>

    <div class="flex-box center-horizontal group-templates--button-space">
      <ui-table-total-tool [total]="total"></ui-table-total-tool>
      <ui-table-search-tool [query]="fulltext" [placeholder]="'report-publicFiles.fulltext.placeholder' | t" (clear)="clearTextSearch()"
                            (query)="handleTextSearch($event)"></ui-table-search-tool>
      <ui-table-refresh-tool [loading]="isLoading" (click)="handleRefresh()"></ui-table-refresh-tool>
    </div>
  </div>

  <ui-static-table [loading]="isLoading" [direction]="direction" [dataSource]="dataSource" [columnsDef]="columnsDef" [locale]="i18n.currentLocale"
                   [addPaddingOnFirst]="false" (checkbox-event)="handleSelectedAdmins($event.event, $event.item)"></ui-static-table>
</ui-card>
