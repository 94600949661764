  <ui-card>
    <div class="flex-box space-between wrap margin--bottom">
      <div class="flex-box center-horizontal group-templates--button-space">
          <button
            ui-button
            color="linkFlat"
            class="margin--small--bottom"
            icon="icon_create"
            [custom-icon]="true"
            [isSvg]="true"
            icon-size="medium"
            (click)="createTemplate()"
          >
            {{ 'common.action.create' | t }}
          </button>
          <button
            *ngIf="selectedTemplateGuid"
            ui-button
            color="linkFlat"
            class="margin--small--bottom"
            icon="icon_copy"
            [custom-icon]="true"
            [isSvg]="true"
            icon-size="medium"
            (click)="createTemplate(true)"
          >
            {{ 'groupTemplates.table.button.duplicate' | t }}
          </button>
      </div>

      <div class="flex-box center-horizontal group-templates--button-space">
        <ui-table-total-tool
          *ngIf="!isLoading"
          [total]="total"
        ></ui-table-total-tool>
        <ui-table-search-tool
          [query]="fulltext"
          [placeholder]="'ui.table-search-tool.placeholder' | t"
          [tooltipText]="'ui.table-search-tool.includesDescription' | t"
          (clear)="handleSearchClear()"
          (query)="handleSearchQuery($event)"
        ></ui-table-search-tool>
        <ui-table-filter-tool
          [titlesDifferentThanOptions]="[]"
          [tableFilterData]="templateTypesFilter"
          (select)="handleTypeFilter($event)"
        ></ui-table-filter-tool>
        <ui-table-filter-tool
          [titlesDifferentThanOptions]="[]"
          [tableFilterData]="templateStatesFilter"
          (select)="handleStateFilter($event)"
        ></ui-table-filter-tool>
        <ui-table-refresh-tool
          [loading]="isLoading"
          (click)="handleRefresh()"
        ></ui-table-refresh-tool>
      </div>
    </div>

    <ui-static-table
      [loading]="isLoading"
      [sort]="sort"
      [direction]="direction"
      [dataSource]="templates"
      [columnsDef]="columsDefTranslated"
      [isCustomSorting]="true"
      [addPaddingOnFirst]="false"
      [canSelectOnlyOne]="true"
      (handleCustomSort)="handleSorting($event.sortColumn, $event.sortDirection)"
      (on-row-click)="handleRowClick($event)"
      (checkbox-event)="handleTemplateSelection($event.event, $event.item)"
      [locale]="i18n.currentLocale"
    ></ui-static-table>
  </ui-card>


