import { HttpClient } from '@angular/common/http';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { UrlUtils } from 'projects/@common/utils/utils';
import { GetConnectorInstanceStatsResponse, GetConnectorInstanceVolumetryRequestParams, IConnectorsOrgApi } from './connectorsOrg.definition';
import { ConnectorInstanceVolumeEntityHistoryDto, ConnectorInstanceVolumeEntityTotalHistoryDto, ConnectorInstanceVolumeHistory, ConnectorOrgQueries, GetOrgConnectorUsecaseResponse } from './model/ConnectorOrg';
import { ConnectorInstanceVolumeStats, ConnectorInstanceVolumeStatsHistory } from '../models/connectorInstance.model';
import { ConnectorInsatancesVolumeStatsRequestParameters, ConnectorInstanceBasicDateParameters } from '../connectors/connector-api.definition';

export class VarConnectorsOrgService extends EcoApi implements IConnectorsOrgApi {
  protected prefix = ApiEndpointPrefixEnum.detect_connectors;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public getConnectors(organizationId: string, requestQueries: ConnectorOrgQueries): Promise<GetOrgConnectorUsecaseResponse> {
    const url = `${this.host}/organizations/${organizationId}/connectors?${UrlUtils.jsonToQueryParams(requestQueries)}`;
    return this.http.get<GetOrgConnectorUsecaseResponse>(url).toPromise();
  }

  public getConnectorInstanceVolumetry(organizationId: string, instanceId: string, params: GetConnectorInstanceVolumetryRequestParams): Promise<ConnectorInstanceVolumeHistory[]> {
    const url = `${this.host}/organizations/${organizationId}/connectors/instances/${instanceId}/volume/history?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<ConnectorInstanceVolumeHistory[]>(url).toPromise();
  }

  public getConnectorInstanceVolumeEntityHistory(organizationId: string, instanceId: string, params: any): Promise<ConnectorInstanceVolumeEntityHistoryDto> {
    const url = `${this.host}/organizations/${organizationId}/connectors/instances/${instanceId}/volume/entity/history?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<ConnectorInstanceVolumeEntityHistoryDto>(url).toPromise();
  }

  public getConnectorInstanceVolumeEntityTotalHistory(organizationId: string, instanceId: string, params: any): Promise<ConnectorInstanceVolumeEntityTotalHistoryDto> {
    const url = `${this.host}/organizations/${organizationId}/connectors/instances/${instanceId}/volume/entity/total?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<ConnectorInstanceVolumeEntityTotalHistoryDto>(url).toPromise();
  }

  public getConnectorInstanceStats(organizationId: string): Promise<GetConnectorInstanceStatsResponse> {
    return this.http.get<GetConnectorInstanceStatsResponse>(`${this.host}/organizations/${organizationId}/connectors/instances/stats`).toPromise();
  }

  public async getConnectorVolume(organizationId: string, requestParams: ConnectorInsatancesVolumeStatsRequestParameters) {
    const url = `${this.host}/organizations/${organizationId}/connectors/volume/history?${UrlUtils.jsonToQueryParams(requestParams)}`;
    return this.http.get<ConnectorInstanceVolumeStatsHistory[]>(url).toPromise();
  }

  public async getConnectorVolumeStats(organizationId: string, requestParams: ConnectorInsatancesVolumeStatsRequestParameters) {
    const url = `${this.host}/organizations/${organizationId}/connectors/volume/stats?${UrlUtils.jsonToQueryParams(requestParams)}`;
    return this.http.get<ConnectorInstanceVolumeStats>(url).toPromise();
  }

  public async getConnectorTotalVolume(organizationId: string, requestParams: ConnectorInstanceBasicDateParameters) {
    const url = `${this.host}/organizations/${organizationId}/connectors/volume/total?${UrlUtils.jsonToQueryParams(requestParams)}`;
    return this.http.get<ConnectorInstanceVolumeStats>(url).toPromise();
  }
}
