export enum Consoles {
  USER = 'user',
  ORG = 'org',
  ADMIN = 'admin',
  OG = 'og'
}

export enum RequestDirection {
  asc = 'ASC',
  desc = 'DESC'
}
