import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from '../../core/url.service';
import { GetDashboardProfileResponse, GetOrganizationProfileResponse } from './md.eco-profile.definition';
import { UrlUtils } from 'projects/@common/utils/utils';
import { TendencyTimeRangesEnum } from '../shares/shares.definitions';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';

export type GetDashboardReportingProfileResponse = { data: GetDashboardProfileResponse; hasUsersReporting: number, teamName: string };

@Injectable({
  providedIn: 'root',
})
export class MdEcoApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(
    readonly http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url);
  }

  public getDashboardUserProfile(params?: { year: number }): Promise<GetDashboardProfileResponse> {
    return this.http
      .get<GetDashboardProfileResponse>(`${this.host}/profile?${UrlUtils.jsonToQueryParams(params)}`)
      .toPromise();
  }

  public getDashboardOrganizationProfile(): Promise<GetOrganizationProfileResponse> {
    return this.http.get<GetOrganizationProfileResponse>(`${this.host}/org/profile`).toPromise();
  }

  public getDashboardReportingProfile(userIds?: string[], isFirstLoad = true): Promise<GetDashboardReportingProfileResponse> {
    return this.http
      .post<GetDashboardReportingProfileResponse>(
        `${this.host}/reporting/profile`,
        { userIds , isFirstLoad },
        this.getFlagParams({ flags: [FeaturesEnum.TEAM_PROFILE] })
      )
      .toPromise();
  }

  public getEngagementLevels(params?: { gte: TendencyTimeRangesEnum }): Promise<any> {
    return this.http
      .get<any>(`${this.host}/org/engagementLevels${params ? `?${UrlUtils.jsonToQueryParams(params)}` : ''}`)
      .toPromise();
  }
}
