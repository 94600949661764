<div class="card-container">

  <header class="card-header">
    <h2 class="card-header-title">{{"respond.queries.engine.title" | t}}</h2>
    <div
      *display="{flags: [featureFlagEnum.ADVANCED_QUERY_BUILDER]}"
      class="options-menu-container"
    >
      <button
        class="options-menu-button"
        ui-button
        icon="ico_dots"
        [isSvg]="true"
        [custom-icon]="true"
        (click)="toggleOptionsMenu()"
      ></button>

      <div
        *ngIf="showOptionsMenu"
        class="options-menu-popup"
        (click)="toggleOptionsMenu()"
      >
        <div
          *ngFor="let option of menuOptions;"
          class="options-menu-popup-option"
          (click)="handleMenuOptionClicked(option)"
        >
          <img
            *ngIf="option.iconPath"
            [src]="option.iconPath"
          />
          <span>{{ option.translationKey | t }}</span>
        </div>
      </div>

      <ui-click-out-handler
        *ngIf="showOptionsMenu"
        (clickEvent)="toggleOptionsMenu()"
      ></ui-click-out-handler>
    </div>
  </header>

  <div class="query-selection">
    <ui-select
      ngDefaultControl
      [name]="'selectedQuery'"
      [label]="'respond.queries.query.autocomplete.label' | t"
      [placeholder]="'respond.queries.query.autocomplete.placeholder' | t"
      [required]="true"
      [options]="querySelectOptions"
      [(ngModel)]="selectedQueryIdValue"
      (ngModelChange)="handleQuerySelection($event)"
    >
    </ui-select>

    <div>
      <label ui-label>{{"common.period" | t}}</label>
      <ui-table-date-tool
        #uiDateToolComponent
        class="query-period-selector"
        (resetDateEmitter)="handlePeriodSelection(null)"
        (timeEmitter)="handlePeriodSelection($event)"
        [defaultPresetOption]="defaultPeriodPreset"
        [excludePresets]="['last_90_days' ,'last_180_days', 'last_365_days']"
        [maxDaysInCustomRange]="30"
        [locale]="i18n.currentLocale"
      >
      </ui-table-date-tool>
    </div>

    <button
      ui-button
      class="query-execute-button"
      color="standard"
      [disabled]="!isExecuteButtonEnabled"
      (click)="executeQuery()"
    >
      <ng-container *ngIf="isExecutingQuery">
        <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
      </ng-container>
      {{"respond.queries.execute.button.label" | t}}
    </button>
  </div>

  <div class="query-parameters-container">
    <div
      *ngIf="!isAdvancedQueryMode"
      class="basic-query-dynamic-params"
    >
      <div class="flex-box center-horizontal gap-025">
        <strong class="query-parameters-title">{{"common.parameters" | t}}</strong>
        <ui-tooltip *ngIf="basicQueryParameterInputs?.length > 0">{{"respond.queries.engine.parameters.tooltip" | t}}</ui-tooltip>
      </div>

      <ng-container *ngIf="!isLoadingQueryDetails; else loadingSpinner">
        <ng-container *ngIf="basicQueryParameterInputs?.length > 0; else queryNoParamsNeededTemplate">
          <div
            class="query-input-field"
            *ngFor="let param of basicQueryParameterInputs; trackBy:trackByKey"
          >
            <label
              ui-label
              [required]="true"
            >{{param.labels | translatedObject}}</label>

            <combobox-input
              [defaultValue]=""
              [disabled]="false"
              [dropdownOptions]="param.dropdownOptions"
              [dropdownOptionsToIgnore]="[]"
              (valueChangeEvent)="param.value = $event"
            ></combobox-input>
          </div>
        </ng-container>
      </ng-container>

    </div>

    <div
      *ngIf="isAdvancedQueryMode"
      class="advanced-query-builder-container"
    >
      <div class="advanced-query-custom-name">
        <label
          ui-label
          [required]="true"
        >{{'common.name' | t}}</label>
        <input
          type="text"
          ui-input
          [placeholder]="'respond.queries.engine.builder.query.name.placeholder' | t"
          [value]="queryName"
          (input)="queryName = $event.target.value"
        >
      </div>

      <div class="advanced-query-parameters-container">
        <strong
          class="query-parameters-title"
          style="margin-bottom: .5rem;"
        >{{"common.parameters" | t}}</strong>
        <div class="advanced-query-parameters">
          <autocomplete
            #advancedQueryAggregationAutocomplete
            [required]="false"
            [placeholder]="'respond.queries.advancedMode.aggregation.placeholder' | t"
            [label]="'respond.queries.advancedMode.aggregation.label' | t"
            [autocompleteType]="customAutocompleteType"
            [customValues]="advancedQueryAggregationAutocompleteOptions"
            [initItems]="getAggregationDefaultAutocompleteValue()"
            [showInInputAtInit]="true"
            [canMultiSelect]="false"
            [oneItemAtATime]="true"
            (onItemSelected)="selectedAggregationValue = $event?.value || null"
            (isSearchCleared)="selectedAggregationValue = null"
          ></autocomplete>
          <ui-select
            ngDefaultControl
            [name]="'selectedMaxRows'"
            [label]="'respond.queries.advancedMode.maxrows.label' | t"
            [required]="false"
            [options]="maxRowsOptions"
            [(ngModel)]="selectedMaxRowsValue"
            (ngModelChange)="selectedMaxRowsValue = $event"
          >
          </ui-select>
        </div>
      </div>

      <div class="advanced-query-builder">
        <strong class="query-parameters-title">{{"respond.queries.engine.builder.query.title" | t}}</strong>
        <query-builder-component
          #queryBuilderComponentRef
          *ngIf="!!aggregationFields; else loadingSpinner;"
          [conditions]="queryDetails.query.conditions"
          [conditionFields]="aggregationFields"
          [isDisabled]="false"
        ></query-builder-component>
      </div>

    </div>
  </div>
</div>



<ng-template #loadingSpinner>
  <ui-spinner size="small"></ui-spinner>
</ng-template>



<ng-template #queryNoParamsNeededTemplate>
  <label
    ui-label
    class="query-no-params-needed"
    style="width: max-content; cursor: default;"
  >{{"respond.queries.engine.parameters.noneRequired" | t}}</label>
</ng-template>