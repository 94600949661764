<div class="card-content">
  <settings-edition-card
    icon="icon_pause_file_green.svg"
    [title]="'settings.dlp.activation.title' | t"
    [canSave]="true"
    [canEdit]="activationIsDefined"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <p [innerHTML]="'settings.dlp.activation.description' | t"></p>
    <div class="labels">
      <div class="label-settings">
        <p [innerHTML]="'settings.dlp.activation.state' | t: { state: isActivated }"></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editing>
  <p class="edit-description" [innerHTML]="'settings.dlp.activation.description' | t"></p>
  <ui-toggle
    [toggled]="isActivated"
    [labelEnabled]="'settings.notification.silencing.toggle.activated' | t"
    [labelDisabled]="'settings.notification.silencing.toggle.deactivated' | t"
    (onClick)="toggleSilencingActivation()"
  ></ui-toggle>
</ng-template>
