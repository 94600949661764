<ui-title
  [name]="(actifs.length > 1 ? 'actifs.modal.delete.title.multiple' : 'actifs.modal.delete.title.single') | t"
  [bottomBorder]="false"
  [largeFont]="true"
></ui-title>

<section
  class="text-content-wrapper"
  [ngClass]="{'multiple':actifs.length > 1}"
>
  <ul class="list-actifs-to-delete">
    <li *ngFor="let actif of actifs">
      {{actif.typeName | translatedObject}} : {{actif.name}}
    </li>
  </ul>
  <div class="confirmation-message">
    {{ (actifs.length > 1 ? 'actifs.modal.delete.message.multiple': 'actifs.modal.delete.message.single') | t }}
  </div>
</section>

<section class="buttons">
  <button
    ui-button
    color="standard"
    [disabled]="asyncState === asyncStateEnum.SAVING"
    (click)="deleteAssets()"
  >
    <ui-spinner
      *ngIf="asyncState === asyncStateEnum.SAVING"
      size="small"
    ></ui-spinner>
    {{ 'common.action.delete' | t }}
  </button>
  <button
    ui-button
    color="secondary"
    [disabled]="asyncState === asyncStateEnum.SAVING"
    (click)="cancel()"
  >
    {{ 'common.action.cancel' | t }}
  </button>
</section>