import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ConfirmationModalComponent } from "@ui-kit/components/confirmation-modal/confirmation-modal.component";
import { ModalService } from "@ui-kit/services/modal.service";
import { Eco } from "projects/@common/definitions/eco";
import { DisplayService } from "projects/@common/modules/display/display.service";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { Notice, NoticeLevels, NoticeService } from "projects/@common/modules/notice/notice.service";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { CreateIncidentTimestampRequest, DeleteIncidentTimestampRequest, UpdateIncidentTimestampRequest } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IncidentDifferenceService } from "../../../../services/incident.difference.service";
import { TabNamesEnum } from "../../incidents-details.component";
import { CollaborationTimeComponent } from "./components/collaboration-time/collaboration-time.component";
import { ChronologyTimestampComponent } from "./components/timestamp/chronology-timestamp.component";
import { SystemTimestampTypes, TimestampSources, UserTimestampTypes } from "./definitions/chronologyTimestamp.definitions";
import { ChronologyTimestamp } from "./models/chronologyTimestamp.model";

@Component({
  selector: 'app-chronology-tab',
  templateUrl: './chronology-tab.component.html',
  styleUrls: [ './chronology-tab.component.scss' ],
})
export class ChronologyTabComponent implements OnInit, OnDestroy {
  @Input() public incident: Incident;

  public collaborationTimeMinutes: number = null;

  public timestamps: ChronologyTimestamp[] = [];

  public minTimestampDate: Date;

  public currentEditingId: string | null = null;

  public readonly locale: Eco.Languages;

  @ViewChild("collaborationTimeRef") public collaborationTimeRef: CollaborationTimeComponent;

  @ViewChildren("timestampComponentRef") public timestampComponents: QueryList<ChronologyTimestampComponent>;

  constructor(
    private readonly incidentsApi: IncidentsApi,
    private readonly i18nService: I18nService,
    private readonly noticeService: NoticeService,
    private readonly incidentDifferenceService: IncidentDifferenceService,
    private readonly modalService: ModalService,
    private readonly displayService: DisplayService
  ) {
    this.locale = this.i18nService.currentLocale as Eco.Languages;
  }

  ngOnInit(): void {
    this.initValues();

    this.incident.reloadObservable$.subscribe(() => {
      this.initValues();
    });
  }

  ngOnDestroy(): void {
    this.incident.reloadObservable$.unsubscribe();
  }

  public get canEditCollaborationTime(): boolean {
    return this.incident.isEditable && this.displayService.meetsRequirements({ permissions: [ 'can_update_incident' ] });
  }

  public get hasSocManagerPermission(): boolean {
    return this.displayService.meetsRequirements({ permissions: [ "can_reopen_incident" ] });
  }

  public get canEditChronology(): boolean {
    return this.incident.isEditable && this.displayService.meetsRequirements({ permissions: [ 'can_update_incident' ] }) && (!this.incident.isReopened || this.hasSocManagerPermission);
  }

  public initValues() {
    this.collaborationTimeMinutes = this.incident.collaborationTime;
    this.collaborationTimeRef?.setStateFromMinutes(this.collaborationTimeMinutes);

    this.timestamps = [
      ...this.getSystemDefinedTimestamps(this.incident),
      ...this.getUserDefinedTimestamps(this.incident),
    ];
    this.minTimestampDate = new Date(this.incident.createdAt);
  }

  public setEditingState(newState: boolean, currentEditingId: string): void {
    this.currentEditingId = newState === true ? currentEditingId : null;
    if (!this.currentEditingId) {
      this.incidentDifferenceService.handleEditingStates({
        tabNameEnum: TabNamesEnum.CHRONOLOGY,
        itemId: TabNamesEnum.CHRONOLOGY,
        hasUnsavedChange: false,
      });
    }
  }

  public saveTimestamp(newTimestamp: number, instance: ChronologyTimestamp): void {
    if (instance.id) {
      this.updateTimestamp(newTimestamp, instance);
    } else {
      this.createTimestamp(newTimestamp, instance);
    }
  }

  public updateCollaborationTime(minutes: number): void {
    const shoudlAllowUpdate = this.canEditCollaborationTime;
    if (shoudlAllowUpdate) {
      const initialCollaborationTime = this.incident.collaborationTime;
      this.incident.setCollaborationTime(minutes); // Update incident instance immediately because it's used by updateRequestDto
      this.incidentsApi.updateIncident(this.incident.organizationId, this.incident.updateRequestDto).subscribe(
        (response) => {
          this.incident.reloadIncident(response.incident);
          this.noticeService.notifyUser(new Notice("incidents.container.page.details.tab.chronology.collaborationTime.update.success", NoticeLevels.SUCCESS));
        },
        (error) => {
          this.incident.setCollaborationTime(initialCollaborationTime); // Revert changes on error
          this.noticeService.notifyUser(new Notice(error, NoticeLevels.ERROR));
        }
      );
    }
  }

  public async promptConfirmDeleteTimestamp(instance: ChronologyTimestamp): Promise<void> {
    const isConfirmed = await new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18nService.translate('incidents.container.page.details.tab.chronology.timestamp.prompt.title'),
        text: this.i18nService.translate('incidents.container.page.details.tab.chronology.timestamp.prompt.text'),
        confirmationText: this.i18nService.translate('incidents.container.page.details.tab.chronology.timestamp.prompt.confirmationText'),
        cancelText: this.i18nService.translate('incidents.container.page.details.tab.chronology.timestamp.prompt.cancelText'),
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
    if (isConfirmed) {
      this.deleteTimestamp(instance);
    }
  }

  private getSystemDefinedTimestamps(incident: Incident): ChronologyTimestamp[] {
    return Object.values(SystemTimestampTypes).map((type) => new ChronologyTimestamp({
      type,
      timestamp: ChronologyTimestamp.getIncidentValueFromTimestampType(incident, type),
      incidentId: incident.id,
      source: TimestampSources.SYSTEM,
      editable: false,
      deletable: false,
    }));
  }

  private getUserDefinedTimestamps(incident: Incident): ChronologyTimestamp[] {
    const defaults = Object.values(UserTimestampTypes).map((type) => new ChronologyTimestamp({
      type,
      timestamp: undefined,
      incidentId: incident.id,
      source: TimestampSources.USER,
      editable: true,
      deletable: true,
    }));

    return defaults.map((mappedTimestamp) => {
      const userTimestamp = incident.timestamps?.find((timestamp) => timestamp.type === mappedTimestamp.type);
      if (userTimestamp) {
        mappedTimestamp.id = userTimestamp.id;
        mappedTimestamp.timestamp = userTimestamp.timestamp;
      }
      return mappedTimestamp;
    });
  }

  private createTimestamp(newTimestamp: number, instance: ChronologyTimestamp): void {
    const shouldAllowCreation = this.canEditChronology && newTimestamp > 0 && !instance.id;
    if (shouldAllowCreation) {
      const request: CreateIncidentTimestampRequest = {
        incidentId: this.incident.id,
        timestamp: newTimestamp,
        type: instance.type,
      };
      this.incidentsApi.createIncidentTimestamp(this.incident.organizationId, request).subscribe(
        (response) => {
          this.incident.setTimestamps(response.timestamps);
          this.incident.reloadIncident(response.incident);
          const timestampId = this.incident.timestamps.find((timestamp) => timestamp.type === instance.type).id;
          instance.id = timestampId;
          instance.timestamp = newTimestamp;
          this.noticeService.notifyUser(new Notice("incidents.container.page.details.tab.chronology.timestamp.update.success", NoticeLevels.SUCCESS));
        },
        (error) => this.noticeService.notifyUser(new Notice(error, NoticeLevels.ERROR))
      );
    }
  }

  private updateTimestamp(newTimestamp: number, instance: ChronologyTimestamp): void {
    const showAllowUpdate = this.canEditChronology && newTimestamp > 0 && newTimestamp !== instance.timestamp;
    if (showAllowUpdate) {
      const request: UpdateIncidentTimestampRequest = {
        id: instance.id,
        incidentId: this.incident.id,
        timestamp: newTimestamp,
      };
      this.incidentsApi.updateIncidentTimestamp(this.incident.organizationId, request).subscribe(
        (response) => {
          this.incident.setTimestamps(response.timestamps);
          this.incident.reloadIncident(response.incident);
          instance.timestamp = newTimestamp;
          this.noticeService.notifyUser(new Notice("incidents.container.page.details.tab.chronology.timestamp.update.success", NoticeLevels.SUCCESS));
        },
        (error) => this.noticeService.notifyUser(new Notice(error, NoticeLevels.ERROR))
      );
    }
  }

  private deleteTimestamp(instance: ChronologyTimestamp): void {
    const shouldAllowDelete = this.canEditChronology && instance.id;
    if (shouldAllowDelete) {
      const request: DeleteIncidentTimestampRequest = {
        id: instance.id,
        incidentId: this.incident.id,
      };
      this.incidentsApi.deleteIncidentTimestamp(this.incident.organizationId, request).subscribe(
        (response) => {
          this.incident.setTimestamps(response.timestamps);
          this.incident.reloadIncident(response.incident);
          instance.id = null;
          instance.timestamp = null;
          const timestampComponent = this.timestampComponents.find((timestamp) => timestamp.type === instance.type);
          timestampComponent.reset();
          this.noticeService.notifyUser(new Notice("incidents.container.page.details.tab.chronology.timestamp.delete.success", NoticeLevels.SUCCESS));
        },
        (error) => this.noticeService.notifyUser(new Notice(error, NoticeLevels.ERROR))
      );
    }
  }
}
