<ui-table-total-tool
  [locale]="locale"
  [total]="isLoading ? '-' : total"
></ui-table-total-tool>

<ui-table-search-tool
  class="search-text"
  [placeholder]="'common.search' | t"
  [query]="searchText"
  (query)="handleSearchTextChange($event)"
  (clear)="handleSearchTextChange(null)"
></ui-table-search-tool>

<ui-table-filter-tool
  *ngIf="false"
  class="view-mode-selector"
  [tableFilterData]="listViewModes"
  (select)="handleViewModeChange($event)"
></ui-table-filter-tool>

<ui-table-filter-tool *ngIf="isConsoleAdmin"
  [tableFilterData]="filterVisibility"
  (select)="handleVisibilitySelection($event)">
</ui-table-filter-tool>

<ui-table-refresh-tool
  [loading]="filtersService.isLoading"
  (click)="handleRefreshClicked()"
></ui-table-refresh-tool>