import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';

@Component({
  selector: 'revision-notification-frequency',
  templateUrl: './revision-notification-frequency.component.html',
  styleUrls: [ './revision-notification-frequency.component.scss' ],
})
export class RevisionNotificationFrequencyComponent implements OnInit {
  @Input() public currentSettings: Pick<OrganizationSettings, 'notificationRevisionFrequency'>;
  @Input() public isLoading = false;

  public isEditing = false;
  public revisionNotificationFrequencyForm: UntypedFormGroup;
  public delayOptions = (() => {
    const options = [];
    for (let i = 1; i < 7; i++) {
      options.push({
        value: i,
        displayValue: this.i18n.translate('settings.resources.revision.notification.frequency.option', {
          weekNumber: i,
        }),
      });
    }
    return options;
  })();

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private formBuilder: UntypedFormBuilder,
    private i18n: I18nService,
    private noticeService: NoticeService
  ) {}

  ngOnInit() {
    this.initForm(this.currentSettings);
  }

  private initForm(currentSettings?: Pick<OrganizationSettings, 'notificationRevisionFrequency'>) {
    this.revisionNotificationFrequencyForm = this.formBuilder.group({
      delay: this.formBuilder.control(currentSettings?.notificationRevisionFrequency || 3),
    });
  }

  public cancel() {
    this.initForm(this.currentSettings);
  }

  public save(): void {
    this.organizationsApiService
      .updateOrganizationSettings({
        newSettings: { notificationRevisionFrequency: this.revisionNotificationFrequencyForm?.value.delay },
      })
      .then((response) => {
        this.currentSettings = response;
        this.initForm(response);
        this.noticeService.notifyUser({
          message: 'settings.notification.revision.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.notification.revision.snack.error',
          level: NoticeLevels.ERROR,
        });
      });
  }

  public get isValid() {
    return this.revisionNotificationFrequencyForm.valid;
  }

  public restoreDefaults() {
    this.revisionNotificationFrequencyForm.patchValue({
      delay: 3,
    });
  }
}
