import { UiTableDirection } from "@ui-kit/components/ui-table/ui-table.component";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";

export enum PeriodicReportTypeEnum {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  ALL = 'all'
  // CUSTOM = 'custom',
}

export enum PeriodicReportsColumnEnum {
  NAME = 'name',
  PERIOD = 'start',
  TYPE = 'type',
  CREATED = 'createdAt',
  PERIOD_CODE = 'periodCode'
}

export interface IPeriodicReportListRequest {
  name?: string;
  dateFrom?: number;
  dateTo?: number;
  type?: PeriodicReportTypeEnum;
  orderBy?: PeriodicReportsColumnEnum;
  direction?: UiTableDirection;
  from?: number;
  size?: number;
}

export interface IPeriodicReportRepresentation {
  id: string;
  name: string;
  dateFrom: number;
  dateTo: number;
  type: PeriodicReportTypeEnum;
  organizationId: string;
  organizationName?: string;
  organizationEcoId?: string;
  createdAt?: number;
  periodCode?: number;
}

export interface IPeriodicReportResponse {
  createdAt: number;
  id: string;
  organizationId: string;
  period: {
    end: number;
    id: string;
    name: string;
    start: number;
    type: PeriodicReportTypeEnum;
    periodCode?: number;
  }
}

export interface IPeriodicReportsApi {
  listReports(request: IPeriodicReportListRequest, organizationId?: string): Observable<ResponseUtils<IPeriodicReportResponse>>;

  getReportDownloadUrl(reportId: string, organizationId?: string): Observable<any>;
}
