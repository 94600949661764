<ui-static
  [label]="''"
  [value]="relationsCount"
  [iconName]="iconPath"
  [iconModifier]=""
  [iconSide]="'left'"
  [isCustomIcon]="true"
  [isSvg]="true"
  [iconSize]="'18px'"
  [tooltip]="tooltipHTML"
  [tooltipDelay]="150"
  [tooltipBackgroundWhite]="true"
  [tooltipBiggerPadding]="true"
>
</ui-static>