import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import { DrawerState } from 'projects/@common/modules/layout/components/drawer/stores/drawer.store';
import { PlaybooksApiService } from 'projects/@common/services/api/respond/playbooks/playbooks.api';
import { IPlaybookDetail } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { UsecaseUpdateForm } from '../../../components/usecase-details/usecase-update-form';
import { AbstractDrawer } from 'projects/@common/modules/layout/components/drawer/AbstractDrawer';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { AlertingUsecaseListItem, ActionTemplate, IUsecase, IMonitor } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { IToolboxAction } from '@common/services/api/respond/actions-toolbox/actions-toolbox.definition';

export interface UsecaseDescriptionDrawerData {
  title: string;
  usecaseItem: AlertingUsecaseListItem;
  organizationId: string;
  organizationEcoId: string;
  actionNames: string[];
  actions: {
    [key: string]: ActionTemplate;
  };
  toolboxActions: IToolboxAction[];
  alertingModeActionNames: string[];
};

@Component({
  selector: 'usecase-description-drawer',
  templateUrl: './usecase-description-drawer.component.html',
  styleUrls: [ './usecase-description-drawer.component.scss' ],
})
export class UsecaseDescriptionDrawer extends AbstractDrawer implements OnInit {
  public currentUsecase: IUsecase;
  public currentPlaybook: IPlaybookDetail;
  public usecaseMonitors: Array<IMonitor>;
  public usecaseUpdateForm: UsecaseUpdateForm;
  public isLoading = false;
  public waitForPlaybook = false;
  public organizationId: string;
  public usecaseTemplate: IUsecase;
  public disabledTabs: boolean = false;

  @Input() public data: UsecaseDescriptionDrawerData;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private usecasesApiService: UsecasesApiService,
    private playbookApiService: PlaybooksApiService,
    readonly store: Store
  ) {
    super(store);
    this.usecaseUpdateForm = new UsecaseUpdateForm(formBuilder);
  }

  ngOnInit(): void {
    this.organizationId = this.data.organizationId;
    this.loadCurrentUsecase();
    this.store.select(DrawerState.isEditing).subscribe((isEditing) => {
      this.disabledTabs = isEditing;
    });
  }

  private loadCurrentUsecase(): void {
    this.isLoading = true;
    this.waitForPlaybook = true;
    this.usecasesApiService
      .getUsecase(this.organizationId, this.data.usecaseItem.id)
      .then((response) => {
        this.currentUsecase = response.usecase;
        this.currentUsecase.hasBlacklistedMonitor = !!response.monitors.find((monitor) => monitor.blacklisted);
        this.currentUsecase.hasSilencedMonitor = !!response.monitors.find((monitor) => monitor.silenced);
        this.usecaseTemplate = response.template;
        this.usecaseMonitors = response.monitors;
        this.usecaseUpdateForm.setUsecaseId(this.currentUsecase.id);
        this.usecaseUpdateForm.setAlertingMode(this.currentUsecase.silenced);
        this.usecaseUpdateForm.setUsecaseAggregationRule(this.currentUsecase.aggregationRule ? this.currentUsecase.aggregationRule : undefined);
        this.waitForPlaybook = !!this.currentUsecase?.playbookId;
        this.loadPlaybook();
      })
      .finally(() => (this.isLoading = false));
  }

  private loadPlaybook(): void {
    if (this.currentUsecase?.playbookId) {
      this.playbookApiService
        .getPlaybook(this.organizationId, this.currentUsecase.playbookId)
        .then((playbook) => {
          this.currentPlaybook = playbook;
        })
        .finally(() => (this.waitForPlaybook = false));;
    }
  }
}
