import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UiKitModule } from '@ui-kit/uikit.module';
import { ComponentsModule } from 'projects/@common/components/components.module';
import { DirectivesModule } from 'projects/@common/modules/directives/directives.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { HistoriesModule } from '../histories/histories.module';
import { CommunicationBusinessHoursComponent } from './components/communication-business-hours/communication-business-hours.component';
import { CommunicationContactComponent } from './components/communication-contact/communication-contact.component';
import { CommunicationEscalationDelaysComponent } from './components/communication-escalation-delays/communication-escalation-delays.component';
import { CommunicationEscalationLevelComponent } from './components/communication-escalation-level/communication-escalation-level.component';
import { CommunicationEscalationListComponent } from './components/communication-escalation-list/communication-escalation-list.component';
import { CommunicationEscalationTimePickerComponent } from './components/communication-escalation-time-picker/escalation-time-picker.component';
import { CommunicationMethodComponent } from './components/communication-method/communication-method.component';
import { RespondCommunicationPageContainer } from './containers/communication-page/respond-communication-page.container';
import { CommunicationTabContactsComponent } from './containers/communication-tab-contacts/communication-tab-contacts.component';
import { CommunicationTabEscalationListsComponent } from './containers/communication-tab-escalation-lists/communication-tab-escalation-lists.component';
import { CommunicationTabHistoryComponent } from './containers/communication-tab-history/communication-tab-history.component';
import { CommunicationTabSettingsComponent } from './containers/communication-tab-settings/communication-tab-settings.component';


const components = [
  RespondCommunicationPageContainer,
  CommunicationTabSettingsComponent,
  CommunicationTabContactsComponent,
  CommunicationTabEscalationListsComponent,
  CommunicationTabHistoryComponent,
  CommunicationBusinessHoursComponent,
  CommunicationEscalationDelaysComponent,
  CommunicationEscalationTimePickerComponent,
  CommunicationContactComponent,
  CommunicationMethodComponent,
  CommunicationEscalationListComponent,
  CommunicationEscalationLevelComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiKitModule,
    I18nModule,
    FeatureSharedModule,
    ComponentsModule,
    MatCheckboxModule,
    DirectivesModule,
    HistoriesModule,
  ],
})
export class RespondCommunicationModule { }
