import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { UiKitModule } from '@ui-kit/uikit.module';
import { ComponentsModule } from 'projects/@common/components/components.module';
import { DatasourcesModule } from 'projects/@common/modules/datasources/datasources.module';
import { DirectivesModule } from 'projects/@common/modules/directives/directives.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { PipesModule } from 'projects/@common/modules/pipes/pipes.module';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { HistoriesModule } from '../histories/histories.module';
import { DetailLayoutComponent } from './components/detail-layout/detail-layout.component';
import { PlaybookCatalogReportButtonComponent } from './components/playbook-catalog-report-button/playbook-catalog-report-button.component';
import { PlaybookCatalogReportChooseLanguageModalComponent } from './components/playbook-catalog-report-choose-language-modal/playbook-catalog-report-choose-language-modal.component';
import { PlaybookCreationPropertiesComponent } from './components/playbook-creation-properties/playbook-creation-properties.component';
import { PlaybookLibraryCardComponent } from './components/playbook-creation-properties/playbook-library-card.component';
import { PlaybookDetailNotesComponent } from './components/playbook-detail-notes/playbook-detail-notes.component';
import { PlaybookDetailUsecasesComponent } from './components/playbook-detail-usecases/playbook-detail-usecases.component';
import { PlaybookDetailsMitreAttackCardComponent } from './components/playbook-details-mitre-attack-card/playbook-details-mitre-attack-card.component';
import { PlaybookDetailTabHeaderComponent } from './components/playbook-details-tab-header/playbook-detail-tab-header.component';
import { PlaybookHistoryComponent } from './components/playbook-history/playbook-history.component';
import { PlaybookDetailDetailsComponent } from './components/playbook-info-details/playbook-info-details.component';
import { PlaybookInstanceSelectorPopupComponent } from './components/playbook-instance-selector-popup/playbook-instance-selector-popup.component';
import { TaskUiCardComponent } from './components/playbook-tasks-drag-and-drop.component/playbook-detail-task-ui-card.component';
import { DetectionPlaybooksLibraryTaskCardComponent } from './components/playbook-tasks-drag-and-drop.component/playbook-task-library-card.component';
import { PlaybookTasksDragAndDropComponent } from './components/playbook-tasks-drag-and-drop.component/playbook-tasks-drag-and-drop.component';
import { PlaybookUpdateActionButtonsComponent } from './components/playbook-update/playbook-update-action-buttons/playbook-update-action-buttons.component';
import { PlaybookUpdateHeaderComponent } from './components/playbook-update/playbook-update-header/playbook-update-header.component';
import { PlaybookUpdateVersionComparatorComponent } from './components/playbook-update/playbook-update-version-comparator/playbook-update-version-comparator.component';
import { PlaybooksInventoryBodySectionComponent } from './components/playbooks-inventory-body-section/playbooks-inventory-body-section.component';
import { SpecificTaskTextareaComponent } from './components/specific-task/components/specific-task-textarea/specific-task-textarea.component';
import { SpecificTaskComponent } from './components/specific-task/specific-task.component';
import { PlaybookCreationContainer } from './containers/playbook-creation/playbook-creation.container';
import { PlaybookMitreAttackContainerComponent } from './containers/playbook-mitre-attack-container/playbook-mitre-attack-container.component';
import { PlaybookUpdateContainer } from './containers/playbook-update/playbook-update.container';
import { RespondPlaybookDetailComponent } from './containers/respond-playbook-detail/respond-playbook-detail.component';
import { RespondPlaybookListComponent } from './containers/respond-playbook-list/respond-playbook-list.component';

const components = [
  RespondPlaybookListComponent,
  RespondPlaybookDetailComponent,
  PlaybookCreationContainer,
  SpecificTaskComponent,
  SpecificTaskTextareaComponent,
  DetailLayoutComponent,
  DetectionPlaybooksLibraryTaskCardComponent,
  PlaybookCreationPropertiesComponent,
  PlaybookTasksDragAndDropComponent,
  PlaybookDetailDetailsComponent,
  PlaybookDetailNotesComponent,
  PlaybookDetailTabHeaderComponent,
  PlaybookDetailUsecasesComponent,
  PlaybookLibraryCardComponent,
  PlaybooksInventoryBodySectionComponent,
  TaskUiCardComponent,
  PlaybookUpdateContainer,
  PlaybookUpdateHeaderComponent,
  PlaybookUpdateVersionComparatorComponent,
  PlaybookUpdateActionButtonsComponent,
  PlaybookHistoryComponent,
  PlaybookCatalogReportButtonComponent,
  PlaybookCatalogReportChooseLanguageModalComponent,
  PlaybookInstanceSelectorPopupComponent,
];

@NgModule({
  declarations: [ ...components, PlaybookDetailsMitreAttackCardComponent, PlaybookMitreAttackContainerComponent ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    PipesModule,
    RouterModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
    HistoriesModule,
    DatasourcesModule,
    DragDropModule,
    FormsModule,
    MatStepperModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    DirectivesModule,
  ],
})
export class PlaybooksModule { }
