<header class="entity-header">
  <div class="buttons">
    <!-- <button
      ui-button
      color="linkFlat"
      icon="plus"
      icon-size="medium"
      [disabled]="false"
      (click)="createEntity()"
    >
      {{ 'common.action.create' | t }}
    </button> -->
  </div>
  <div class="filters">
    <ui-table-total-tool
      [locale]="i18n.currentLocale"
      [total]="isLoadingEntities ? '-' : filteredEntities?.length || 0"
    ></ui-table-total-tool>
    <ui-table-search-tool
      class="search-text"
      [placeholder]="'common.search' | t"
      [query]="filters.searchText"
      (query)="handleSearchTextFilterChange($event)"
      (clear)="handleSearchTextFilterChange(null)"
    ></ui-table-search-tool>
  </div>
</header>


<section class="entity-list self-contained-scrollbar">
  <span *ngIf="!filteredEntities.length" class="no-items-label">{{"common.nomatch" | t}}</span>
  <respond-entity-with-collapse
    *ngFor="let entity of filteredEntities; trackBy: trackById"
    [entity]="entity"
    [pageContext]="pageContext"
    [startExpanded]="true"
    [isReadonly]="isReadonly"
    [incident]="incident"
  ></respond-entity-with-collapse>
</section>


<ng-template #loadingTemplate>
  <span
    *ngIf="!isLoadingEntities"
    style="font-size: 14px;"
  >{{'common.nomatch' | t}}
  </span>
</ng-template>