import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DisplayGuard } from 'projects/@common/modules/guards/display.guard';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { DashboardModule } from "./dashboard/dashboard.module";
import { GroupTemplatesModule } from "./group-templates/group-templates.module";
import { GuestUsersModule } from "./guest-users/guest-users.module";
import { SettingsModule } from "./settings/settings.module";


const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => DashboardModule,
  },
  {
    path: 'templates',
    loadChildren: () => GroupTemplatesModule,
    canLoad: [ DisplayGuard ],
    data: {
      requirements: {
        flags: [ FeaturesEnum.RESOURCE_MANAGEMENT ],
      },
    },
  },
  {
    path: 'settings',
    loadChildren: () => SettingsModule,
  },
  {
    path: 'guests',
    loadChildren: () => GuestUsersModule,
    canLoad: [ DisplayGuard ],
    data: {
      requirements: {
        flags: [FeaturesEnum.GUESTS_MANAGEMENT],
      },
    },
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class OrgCspRoutingModule { }
