<ui-card [withShadow]="true" *ngIf="!isLoading">
  <div class="header-title-section">
    <h1>{{ data?.title | t }}</h1>
    <active-inactive-badge [state]="data.isActive"></active-inactive-badge>
  </div>

  <div class="description-section">
    <p class="description-section--paragraph">
      {{ data?.description | t }}
    </p>
  </div>

  <div class="datasource-timestamps-section" *ngIf="data?.isActive">
    <div class="datasource-timestamps-section--label">
      <img src="assets/favicons/icon_grey_checkbox.svg" alt="" />
      <p>
        {{
          'detection.datasource.inventory.detail.activation.date'
            | t: getDateFormat(data?.activationDate)
        }}
      </p>
    </div>
    <div class="datasource-timestamps-section--label">
      <img src="assets/favicons/icon_grey_timer.svg" alt="" />
      <p>
        {{
          'detection.datasource.inventory.detail.changes.date'
            | t: getDateFormat(data?.lastModification)
        }}
      </p>
    </div>
  </div>

  <div class="vignette-section">
    <ng-container *ngFor="let vignette of data?.vignettes">
      <detail-vignette [vignetteData]="vignette"></detail-vignette>
    </ng-container>
  </div>
</ui-card>

<ui-card [withShadow]="true" *ngIf="isLoading">
  <ui-spinner size="large"></ui-spinner>
</ui-card>
