import { ValidatorFn, Validators } from "@angular/forms";
import { DynamicFormField, DynamicFormFieldType } from "projects/@common/components/dynamic-form";
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { CustomValidators } from "projects/@common/utils/validators";
import { IProperty, IPropertyConfig, PropertyType } from "../actifs.definitions";

export interface IAssetDynamicFormField {
  order: number;
  tooltip?: ITranslatedField;
  multiple: boolean;
  informative: boolean;
  dynamicForm: DynamicFormField;
  id: string,
}

export class Actif {
  id: string;
  type: string;
  config: IPropertyConfig;

  constructor(id: string, type: string, config: IPropertyConfig) {
    this.id = id;
    this.type = type;
    this.config = config;
  }

  public getRawProperty(): IPropertyConfig {
    return this.config;
  }

  public getFormProperty(lang: string): IAssetDynamicFormField[] {
    const sortedProperties = this.sortedProperties();

    const mappedProperties = sortedProperties.map((property) => {
      const properties = this.formTypeConverter(property);

      const assetDynamicFormField: IAssetDynamicFormField = {
        id: property.propertyId,
        multiple: property.multiple,
        informative: property.informative,
        order: property.order,
        dynamicForm: {
          required: property.required,
          label: property.name,
          type: properties.type,
          placeholder: property.placeholder,
          fieldName: null,
          tooltip: property.tooltips,
          values: property.options?.map((value) => ({ value: value.key, label: value.value[lang] })),
          options: {
            customValidators: properties.validators,
          },
        },
      };

      return assetDynamicFormField;
    });

    return mappedProperties;
  }

  private sortedProperties(): IProperty[] {
    return this.config.properties.slice(0).sort((a, b) => a.order - b.order);
  }

  private formTypeConverter(config: IProperty): { validators: ValidatorFn[]; type: DynamicFormFieldType; } {
    const formProperties = { validators: null, type: null };

    switch (config.type) {
      case PropertyType.IP:
        formProperties.type = DynamicFormFieldType.TEXT_INPUT;
        formProperties.validators = [ CustomValidators.validateIpAddress ];
        break;
      case PropertyType.TEXT:
        formProperties.type = DynamicFormFieldType.TEXT_INPUT;
        formProperties.validators = [];
        break;
      case PropertyType.EMAIL:
        formProperties.type = DynamicFormFieldType.TEXT_INPUT;
        formProperties.validators = [ CustomValidators.validateEmailAddress ];
        break;
      case PropertyType.ASSET:
      case PropertyType.SELECT:
        formProperties.type = DynamicFormFieldType.DROPDOWN;
        formProperties.validators = [];
        break;
      default:
        break;
    }

    config.required ? formProperties.validators.push(Validators.required) : null;

    return formProperties;
  }
}
