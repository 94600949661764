import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-duration-time',
  templateUrl: './duration-time.component.html',
  styleUrls: [ './duration-time.component.scss' ],
})
export class DurationTimeComponent implements OnInit {
  @Input() secondes: number;

  public toDisplay: string = '';

  constructor() { }

  ngOnInit(): void {
    this.toDisplay = new Date(this.secondes * 1000).toISOString().slice(11, 19);
  }
}
