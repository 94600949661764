import { HttpClient } from '@angular/common/http';
import { Eco } from 'projects/@common/definitions/eco';
import { UrlUtils } from 'projects/@common/utils/utils';
import { EcoUrlService } from '../../core/url.service';
import { IamApi } from '../iam.api.definitions';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { IProfileApi } from './profiles.api.definition';
import { ListProfileMembersRequest, ListProfileSecurityGroupsRequest, ListProfilesRequest, UpdateProfileMembersRequest } from './profiles.api';

export class CustomerProfilesApi extends EcoApi implements IProfileApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.iam;

  constructor(
    http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url);
  }
  public async listProfileSecurityGroups(request: ListProfileSecurityGroupsRequest): Promise<Eco.List<{ groupId: string, name: string, description: string; }>> {
    const queryParams = UrlUtils.jsonToQueryParams(request.params);
    const url = `${this.host}/profiles/${request.profileId}/securityGroups?${queryParams}`;
    return this.http.get<any>(url).toPromise();
  }

  public async listProfileMembers(request: ListProfileMembersRequest): Promise<Eco.List<IamApi.Profile.listMemberResponse>> {
    const queryParams = UrlUtils.jsonToQueryParams(request.params);
    const url = `${this.host}/profiles/${request.profileId}/members?${queryParams}`;
    return this.http.get<any>(url).toPromise();
  }

  public async updateProfileMembers(request: UpdateProfileMembersRequest): Promise<void> {
    const url = `${this.host}/profiles/${request.profileId}/members`;
    return this.http.put<any>(url, request.body).toPromise();
  }

  public async listProfiles(request: ListProfilesRequest): Promise<Eco.List<IamApi.Profile.listResponse>> {
    const query = UrlUtils.jsonToQueryParams(request.params);
    const url = `${this.host}/profiles?${query}`;
    return this.http.get<Eco.List<IamApi.Profile.listResponse>>(url).toPromise();
  }
}
