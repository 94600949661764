import { Component, Input, OnInit } from '@angular/core';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';

@Component({
  selector: 'app-guest-user-resource-add',
  templateUrl: './guest-user-resource-add.component.html',
  styleUrls: [ './guest-user-resource-add.component.scss' ],
})
export class GuestUserResourceAddComponent implements OnInit {
  @Input() public currentSettings: Pick<OrganizationSettings, 'externalUserCollaboration'>;
  @Input() public isLoading = false;

  public isEditing = false;
  public externalUserCollaborationState = false;

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private noticeService: NoticeService
  ) { }

  ngOnInit(): void {
    this.initForm(this.currentSettings);
  }

  private initForm(currentSettings?: Pick<OrganizationSettings, 'externalUserCollaboration'>) {
    this.externalUserCollaborationState = currentSettings?.externalUserCollaboration;
  }

  public cancel() {
    this.initForm(this.currentSettings);
  }

  public toggleSilencingActivation(): void {
    this.externalUserCollaborationState = !this.externalUserCollaborationState;
  }

  public save(): void {
    this.organizationsApiService
      .updateOrganizationSettings({
        newSettings: { externalUserCollaboration: this.externalUserCollaborationState },
      })
      .then((response) => {
        if (response) {
          this.currentSettings = response;
          this.initForm(response);
          this.noticeService.notifyUser({
            message: 'settings.notification.expiration.notification.snack.success',
            level: NoticeLevels.SUCCESS,
          });
        }
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.notification.expiration.notification.snack.success',
          level: NoticeLevels.ERROR,
        });
      });
  }
}
