<ng-container [formGroup]="formGroup">
  <div style="display: flex">
    <ui-text-field
      [label]="label"
      [required]="required"
      [readonly]="readonly"
      [placeholder]="placeholder"
      [control]="controlName"
      [group]="formGroup"
      [jsonStyle]="true"
    >
    </ui-text-field>
    <ng-content></ng-content>
  </div>
  <label *ngIf="description" ui-label>{{ description }}</label>
  <br *ngIf="!description" />
  <button
    *ngIf="prettifyButton"
    ui-button
    (click)="validateAndPrettify(controlName)"
  >
    {{
      'datasources.tab.creation.configuration.template.input.json.prettify' | t
    }}
  </button>
</ng-container>
