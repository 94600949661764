<div class="container-card" *ngIf="displaySection">
  <div class="container-header">
    <h2>{{ 'detect.connector.instance.details.card.data.analytic.title' | t }}</h2>
  
    <ui-table-date-tool
      [locale]="i18n.currentLocale"
      [withHours]="false"
      [excludePresets]="['today', 'last_180_days', 'last_365_days']"
      [defaultPresetOption]="defaultSelectedPeriod"
      [displayDropdownToLeft]="true"
      (timeEmitter)="timePeriodChanged($event)"
    ></ui-table-date-tool>
  </div>
  
  <div class="container-data">
    <h3 class="title-center-middle">{{ 'detect.connector.instance.details.card.data.analytic.total.graph.title' | t:{type:dataType} }}</h3>
    <div class="container--connector-daily-data">
      <graph-connnector-daily-ingested-data [chartData]="lineChartData" *ngIf="lineChartData?.length > 0"></graph-connnector-daily-ingested-data>
      <p>{{ 'detect.connector.instance.details.card.data.analytic.total.graph.description' | t:{type:dataType} }}</p>
    </div>

    <hr>

    <h3 class="title-center-middle">{{ 'detect.connector.instance.details.card.data.analytic.daily.graph.title' | t:{type:dataType} }}</h3>
    <div class="container--connector-total-data" *ngIf="roundChartData?.length > 0">
      <ui-table-scrollable-body [data]="tableData" class="scrollable-total-table"></ui-table-scrollable-body>
      <div class="container--connector-total-data-graph">
        <graph-connectors-total-ingested-data 
          class="round-chart-container" 
          [chartId]="connectorInstance.id" 
          [chartData]="roundChartData"
          [groupClickBehavior]="'none'"
          [displayNumberOfBiggestElements]="5">
        </graph-connectors-total-ingested-data>
        <div class="container--connector-total-data-footer">
          <p>{{ 'detect.connector.instance.details.card.data.analytic.daily.graph.description' | t:{type:dataType} }}</p>
        </div>
      </div>
    </div>
  </div>
</div>