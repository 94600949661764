import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { ObservableActionsCellComponent } from "./components/observable-actions-cell/observable-actions-cell.component";
import { IObservablesPageContext } from "./components/observable-container/observable-container.component";
import { ObservableIndicatorsCellComponent } from "./components/observable-indicators-cell/observable-indicators-cell.component";
import { ObservableValueCellComponent } from "./components/observable-value-cell/observable-value-cell.component";

export enum ObservablesTableColumnsEnum {
  SELECTED = 'selected',
  INDICATORS = 'indicators',
  TYPE = 'type',
  VALUE = 'value',
  DESCRIPTION = 'description',
  ALERT_IDS = 'alertIds',
  ACTIONS = 'actions'
}

export function getObservablesTableColumns(columnsToInclude: ObservablesTableColumnsEnum[] = [], pageContext: IObservablesPageContext): Array<Object> {
  const defaults = [
    {
      label: '',
      field: ObservablesTableColumnsEnum.SELECTED,
      selected: "__Selected",
      type: UiStaticTableRowType.CHECKBOX,
      noSorting: true,
      isResizable: false,
      width: '0.1%',
      minWidth: '24px',
    },
    {
      label: '',
      field: ObservablesTableColumnsEnum.INDICATORS,
      width: '0.1%',
      minWidth: '28px',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: ObservableIndicatorsCellComponent,
      paramName: 'observable',
      secondParamName: 'pageContext',
      secondParamValue: pageContext,
      showWhenHover: false,
      isResizable: false,
      noSorting: true,
    },
    {
      label: 'Type',
      field: ObservablesTableColumnsEnum.TYPE,
      width: '18%',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      noSorting: false,
    },
    {
      label: 'alerts.drawer.tab.observables.table.column.value',
      field: ObservablesTableColumnsEnum.VALUE,
      width: '34%',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: ObservableValueCellComponent,
      paramName: 'observable',
      isResizable: false,
      noSorting: false,
      isTruncated: true,
    },
    {
      label: 'Description',
      field: ObservablesTableColumnsEnum.DESCRIPTION,
      width: '30%',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      noSorting: true,
    },
    {
      label: 'alerts.drawer.tab.observables.table.column.alerts',
      field: ObservablesTableColumnsEnum.ALERT_IDS,
      width: '20%',
      type: UiStaticTableRowType.FUNCTION,
      func: (alertIds: string[]) => alertIds.length ? alertIds.join(", ") : "-",
      isResizable: false,
      noSorting: false,
    },
    {
      label: '',
      field: ObservablesTableColumnsEnum.ACTIONS,
      width: '1%',
      minWidth: '48px',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: ObservableActionsCellComponent,
      paramName: 'observable',
      secondParamName: 'pageContext',
      secondParamValue: pageContext,
      showWhenHover: true,
      isResizable: false,
      noSorting: true,
    },
  ];
  return defaults.filter((column) => columnsToInclude.includes(column.field));
}
