<div class="icon-container">
  <ng-container *ngFor="let icon of iconsToDisplay; let i = index">
    <div
      class="image-wrapper"
      (mouseenter)="showTooltip(icon)"
      (mouseleave)="hideTooltip(icon)"
    >
      <img
        *ngIf="icon.icon"
        class="size"
        [src]="icon.icon"
        alt=""
      />
      <div *ngIf="!icon.icon" class="custom-name-wrapper">
        <p class="custom-name-wrapper--name">{{ icon.name.substr(0, 1) }}</p>
      </div>
      <ng-container *ngIf="icon.isHovered">
        <ng-container
          *ngTemplateOutlet="tooltip; context: { config: icon, index: i }"
        ></ng-container>
      </ng-container>
    </div>
  </ng-container>
  <span *ngIf="iconsHidden > 0">+{{ iconsHidden }}</span>
</div>

<ng-template #tooltip let-config="config">
  <div #tooltipMessage id="tooltipMessage" class="toolTipText">
    <p class="tooltip-text">{{ config.name }}</p>
  </div>
</ng-template>
