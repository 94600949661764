<app-incident-details-base-layout [title]="'respond.communication.tab.escalationList.title' | t">
  <div class="tables-container">

    <ng-container *ngIf="incident.conversationId; else escalationListsTemplate">
      <!-- Display computedEscalationList when conversationId is present -->
      <incident-escalation-table class="escalation-table"
        [incident]="incident"
        [escalationParameters]="escalationParameters"
        [escalationListSchedule]="null"
        [computedEscalationList]="computedEscalationList"
      ></incident-escalation-table>
    </ng-container>

    <!-- Fallback for when conversationId is not present -->
    <ng-template #escalationListsTemplate>
      <ng-container *ngFor="let escalationList of incident.escalationLists | keyvalue">
        <ng-container *ngIf="escalationList.value">
          <ng-container [ngSwitch]="escalationList.key">
            <p *ngSwitchCase="'anytime'" class="escalation-list">{{ 'respond.communication.tab.escalationList.ANYTIME' | t }} :</p>
            <p *ngSwitchCase="'businessHours'" class="escalation-list">{{ 'respond.communication.tab.escalationList.BUSINESS_HOURS' | t }} :</p>
            <p *ngSwitchCase="'offHours'" class="escalation-list">{{ 'respond.communication.tab.escalationList.OFF_HOURS' | t }} :</p>
          </ng-container>
          <incident-escalation-table class="escalation-table"
            [incident]="incident"
            [escalationParameters]="escalationParameters"
            [escalationListSchedule]="escalationList.key"
            [computedEscalationList]="computedEscalationList"
          ></incident-escalation-table>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>
</app-incident-details-base-layout>