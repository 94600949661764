<div class="ui-table-refresh-tool--button">
  <button
    ui-button
    [loading]="loading"
    custom-icon="true"
    isSvg="true"
    icon="icon_refresh"
    modifier="on-highlight"
    [class.available]="!loading"
    color="linkBordedRegular"
  >{{text}}</button>
</div>
