<ui-card>
  <div class="flex-box space-between margin--bottom">
    <div class="buttons-container">
      <app-edit-with-modal #editor>
        <div
          class="edit-content small-gap"
          [tooltip]="'guestManagers.popup.title' | t"
        >
          <button
            ui-button
            color="linkFlat"
            icon="icon_create"
            [custom-icon]="true"
            [isSvg]="true"
            icon-size="medium"
            (click)="toggle()"
          >
            {{ "guestManagers.add.button" | t }}
          </button>
        </div>
        <div content>
          <div class="autocomplete">
            <h3>{{ "guestManagers.popup.title" | t }}</h3>
            <hr />
            <autocomplete
              #userAutocomplete
              [oneItemAtATime]="true"
              [canMultiSelect]="false"
              [placeholder]="'guestManagers.search.placeholder' | t"
              [autocompleteType]="usersAutocomplete"
              [searchOnlyUserResources]="false"
              [(selectedItems)]="newGuestManagers"
              (onItemSelected)="onItemSelected($event)"
              [searchFunction]="usersAutocompleteSearchFunction"
              name="userAutocomplete"
              expandHeight="max-content"
              [required]="true"
              [filter]="userFilter"
              [locale]="i18n.currentLocale"
            ></autocomplete>
            <div class="continueButton">
              <button
                [disabled]="!guestManagerToAdd"
                class="button-spacing"
                ui-button
                color="standard"
                (click)="addGuestUserManager()"
              >
                {{ "common.action.confirm" | t }}
              </button>
              <button
                class="button-spacing"
                ui-button
                color="linkFlat"
                (click)="close()"
              >
                {{ "common.action.cancel" | t }}
              </button>
            </div>
          </div>
        </div>
      </app-edit-with-modal>
      <button
        *ngIf="selectedManagerO365Id"
        ui-button
        color="linkFlat"
        icon="icon_delete"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="medium"
        (click)="deleteManager()"
      >
        {{ "common.action.delete" | t }}
      </button>
    </div>

    <div class="flex-box center-horizontal group-templates--button-space">
      <ui-table-total-tool
        *ngIf="!isLoading"
        [total]="total"
      ></ui-table-total-tool>
      <ui-table-search-tool
        [query]="fulltext"
        [placeholder]="'guestManagers.search.placeholder' | t"
        (clear)="handleSearchClear()"
        (query)="handleSearchQuery($event)"
      ></ui-table-search-tool>
      <ui-table-refresh-tool
        [loading]="isLoading"
        (click)="handleRefresh()"
      ></ui-table-refresh-tool>
    </div>
  </div>
  <div *ngIf="attribute" class="warning">
    <ui-warning
      color="yellow"
      [centerText]="false"
      [roundedBorder]="true"
      [warningMessages]="[{ messageKey: 'groupTemplates.details.defaultApproving.single.approver' | t }]"
    ></ui-warning>
  </div>

  <ui-static-table
    *ngIf="columnsDef?.length"
    [loading]="isLoading"
    [sort]="sort"
    [direction]="direction"
    [dataSource]="managers"
    [columnsDef]="columsDefTranslated"
    [useEmptyMessage]="true"
    [isCustomSorting]="true"
    [addPaddingOnFirst]="false"
    [canSelectOnlyOne]="true"
    (handleCustomSort)="handleSorting($event.sortColumn, $event.sortDirection)"
    (checkbox-event)="handleTemplateSelection($event.event, $event.item)"
    [locale]="i18n.currentLocale"
  ></ui-static-table>
</ui-card>
