import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EcoUrlService } from '../../core/url.service';
import { UpdateIdpResponse } from './idp.api.definitions';

@Injectable({
  providedIn: 'root',
})
export class IdpApi {
  private readonly prefix = '/iam/idp';
  private readonly host: string;
  private readonly baseUrl: string;

  constructor (
    private http: HttpClient,
    url: EcoUrlService
  ) {
    this.baseUrl = url.baseUrl;
    this.host = url.baseUrl + this.prefix;
  }

  public async getAdminConsentUrl(requestParams: { application: string, tenantId: string, redirectUrl: string; }): Promise<{ url: string; }> {
    const url = `${this.baseUrl}/iam/adminconsent`;
    return this.http.get(url, { params: requestParams }).toPromise<any>();
  }

  public async updateIdp(requestParams: { application: string; }): Promise<UpdateIdpResponse> {
    const url = `${this.host}`;
    return this.http.put(url, requestParams).toPromise<any>();
  }
}
