import { Component, Input, OnInit } from '@angular/core';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IObservable, ObservableTypeEnum } from 'projects/@common/services/api/respond/observables/observables.definition';
import { reduceIPsToSubnets } from 'projects/@common/utils/ip.util';

@Component({
  selector: 'observable-value-cell',
  templateUrl: './observable-value-cell.component.html',
  styleUrls: [ './observable-value-cell.component.scss' ],
})
export class ObservableValueCellComponent implements OnInit {
  @Input() observable: IObservable;

  public showAsClickableLink: boolean = false;

  constructor(
    private readonly i18n: I18nService,
    private readonly dtPipe: DTPipe
  ) { }

  ngOnInit(): void {
    this.formatDisplayValue();
  }

  private formatDisplayValue(): void {
    if (this.isSafeUrl()) {
      this.observable.displayValue = this.getValidURL(this.observable.value).href;
      this.showAsClickableLink = true;
      return;
    }

    if (this.isIPsList()) {
      this.observable.displayValue = reduceIPsToSubnets(this.observable.value).join(', ');
      return;
    }

    if (this.isPortsList()) {
      if (this.observable.value.length > 10) {
        const min = Math.min(...this.observable.value);
        const max = Math.max(...this.observable.value);
        this.observable.displayValue = this.i18n.translate("observables.value.ports", { count: this.observable.value.length, min, max });
        return;
      }
      this.observable.displayValue = this.observable.value;
      return;
    }

    if (this.isTimestamp()) {
      this.observable.displayValue = this.getFormattedTimestamp(this.observable.value);
      return;
    }

    if (this.isObject()) {
      this.observable.displayValue = JSON.stringify(this.observable.value, null, 2);
      return;
    }

    this.observable.displayValue = this.observable.value;
  }


  private getValidURL(str: string): URL | null {
    let url = null;
    try {
      url = new URL(str);
    } catch (_) {
      return null;
    }
    const isValidUrl = url.protocol === "http:" || url.protocol === "https:";
    if (isValidUrl) {
      return url;
    }
    return null;
  }

  private getFormattedTimestamp(timestamp: number): string {
    if (!timestamp) return '';
    return this.dtPipe.transform(timestamp, { withTime: true, withSeconds: true, locale: this.i18n.currentLocale });
  }

  private isSafeUrl(): boolean {
    if (this.observable.type !== ObservableTypeEnum.URL_SAFE) {
      return false;
    }
    const url = this.getValidURL(this.observable.value);
    return url !== null;
  }

  private isIPsList(): boolean {
    return Array.isArray(this.observable.value) && [ "host", "ip", "source-ip", "destination-ip" ].includes(this.observable.type);
  }

  private isPortsList(): boolean {
    return Array.isArray(this.observable.value) && [ "tcp-ports", "port", "source-port", "destination-port", "port-count" ].includes(this.observable.type);
  }

  private isTimestamp(): boolean {
    return this.observable.type === "timestamp" && typeof this.observable.value === 'number';
  }

  private isObject(): boolean {
    return typeof this.observable.value === "object";
  }
}
