<div class="detail-layout">
  <div class="detail-layout--back-wrapper" *ngIf="backLink">
    <a [routerLink]="backLink">
      <button
        ui-button
        color="listActionFlat"
        class="margin--small--bottom"
        icon="icon_back"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="medium"
      >
        {{ 'common.action.return' | t }}
      </button>
    </a>
  </div>

  <div class="detail-layout--card">
    <ng-content select="[topCard]"></ng-content>
  </div>

  <div class="detail-layout--card">
    <ng-content select="[bottomCard]"></ng-content>
  </div>
</div>
