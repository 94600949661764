import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { AutocompleteCustomValue } from '@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { IMultiSelectData } from '@ui-kit/components/ui-multi-select/ui-multi-select.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { CommunicationMethodTypeEnum, ICommunicationContact, IContactCommunicationMethod, IEscalationLevelForDisplay, IEsclationListContact } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { mapCommunicationMethodsTranslations } from '../communication-method/communication-method.helpers';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'communication-escalation-level',
  templateUrl: './communication-escalation-level.component.html',
  styleUrls: [ './communication-escalation-level.component.scss' ],
})
export class CommunicationEscalationLevelComponent implements OnChanges {
  @Input() escalationLevel: IEscalationLevelForDisplay;
  @Input() contacts: ICommunicationContact[];
  @Input() isEditing: boolean;
  @Input() isDefaultList: boolean;
  @Input() showPrioritiesMatrix: boolean;
  @Input() canDeleteEscalationLevel: boolean;

  @Output() deleteEvent = new EventEmitter<IEscalationLevelForDisplay>();

  customAutocompleteType = AutocompleteTypes.CUSTOM;
  contactAutocompleteOptions: AutocompleteCustomValue[];
  availableContactAutocompleteOptions: AutocompleteCustomValue[];

  constructor(
    public readonly i18n: I18nService,
    private readonly noticeService: NoticeService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contacts || changes.escalationLevel) {
      this.initContacts();
    }
  }

  trackById(index: number, item: any): string {
    return item.id;
  }

  deleteEscalationLevelClicked() {
    if (this.canDeleteEscalationLevel) {
      this.deleteEvent.emit(this.escalationLevel);
    }
  }

  handleContactSelected(contact: IEsclationListContact, selectedContactOption: AutocompleteCustomValue) {
    if (selectedContactOption) {
      contact.id = selectedContactOption.value;
      contact.selectedContactAutocompleteOption = selectedContactOption;
      this.initContactCommunicationMethodOptions(contact);
    } else {
      contact.id = null;
      contact.selectedContactAutocompleteOption = null;
      contact.selectedCommunicationMethods = [];
      contact.communicationMethodsMultiselectOptions = null;
    }
    this.setAvailableContactSelectOptions();
  }

  handleContactCommunicationMethodSelected(contact: IEsclationListContact, selectedOptions: CommunicationMethodTypeEnum[]) {
    const contactDetails = this.getContactById(contact.id);
    contact.selectedCommunicationMethods = contactDetails.communicationMethods.filter((method) => selectedOptions.includes(method.type));
  }

  isValidContacts(): boolean {
    return this.escalationLevel.contacts.every((contact) => contact.selectedCommunicationMethods.length > 0);
  }

  addContact() {
    this.escalationLevel.contacts.push({ id: null, selectedCommunicationMethods: [], withPriorities: false, priorities: [] });
  }

  removeContact(contactId: string) {
    this.escalationLevel.contacts = this.escalationLevel.contacts.filter((contact) => contact.id !== contactId);
    this.setAvailableContactSelectOptions();
  }

  /** Handle priorities switches (toggle) */
  handlePrioritiesToggleAction(contact: IEsclationListContact, $event: boolean) {
    contact.withPriorities = $event;
    contact.priorities = $event ? [1,2,3,4] : [];
  }

  /** Handle priorities checkboxes */
  handlePrioritiesCheckbox(contact: IEsclationListContact, $event: MatCheckboxChange) {
    const element = $event.source;
    const priority = parseInt(element.name.split('-').pop());

    if (!contact.priorities) {
      contact.priorities = [];
    }

    let updatedPriorities: number[] = [...contact.priorities];

    const index = updatedPriorities.indexOf(priority, 0);

    if (element.checked) { // add to array
        if(index === -1) {
          updatedPriorities.push(priority);
        }
    } else { // remove from array
      if (index > -1) {
        updatedPriorities.splice(index, 1);
      }
    }

    contact.priorities = updatedPriorities.sort((a, b) => a - b);
  }

  copyCommunicationMethodToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    this.noticeService.snack("common.action.copied");
  }

  private getContactById(contactId: string): ICommunicationContact {
    return this.contacts.find((contact) => contact.id === contactId);
  }

  private getContactCommunicationMethods(contactId: string): IContactCommunicationMethod[] {
    const contact = this.getContactById(contactId);
    if (!contact) {
      return [];
    }
    return mapCommunicationMethodsTranslations(contact.communicationMethods, this.i18n);
  }

  private initContacts(): void {
    this.contactAutocompleteOptions = this.contacts.map((contact) => ({
      value: contact.id,
      displayValue: contact.name,
    }));
    this.setAvailableContactSelectOptions();

    this.escalationLevel.contacts.forEach((contact) => {
      contact.selectedContactAutocompleteOption = this.contactAutocompleteOptions.find((option) => option.value === contact.id);
      this.initContactCommunicationMethodOptions(contact);
    });
  }

  private setAvailableContactSelectOptions(): void {
    this.availableContactAutocompleteOptions = this.contactAutocompleteOptions.filter((option) => !this.escalationLevel.contacts.find((contact) => contact.id === option.value));
  }

  private initContactCommunicationMethodOptions(contact: IEsclationListContact): void {
    const contactCommunicationMethods = this.getContactCommunicationMethods(contact.id);
    if (!contact.selectedCommunicationMethods?.length) {
      // if no selected communication methods, set all communication methods as selected by default
      contact.selectedCommunicationMethods = contactCommunicationMethods;
    }

    const options: IMultiSelectData[] = contactCommunicationMethods.map((method) => ({
      option: method.type,
      isSelected: !!contact.selectedCommunicationMethods?.find((m) => m.id === method.id),
      translated: method.translation,
      isDefaultValue: false,
    }));

    contact.communicationMethodsMultiselectOptions = new MultiSelectData(options);
  }

}
