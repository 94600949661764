import { Directive, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { getKey } from '../../services/utils';

export interface DragDropRegion<T> {
  key: string;
  dropCopiesItems: boolean;
  preventDoublesKey?: string; // ex: task.id
  items: DragDropItem<T>[];
}

export interface DragDropItem<T> {
  itemContext: T;
  lockedPosition: boolean;
}

export interface DragDropCompletedEvent {
  fromRegion: string;
  fromIndex: number;
  toRegion: string;
  toIndex: number;
}

export interface DragDropCancelledEvent {
  fromRegion: string;
  fromIndex: number;
}

@Directive()
export abstract class UiDragAndDropZone<T> {
  @Input() dragDropRegions: { [key: string]: DragDropRegion<T> } = {};

  get allDragDropRegionKeys(): string[] {
    return Object.keys(this.dragDropRegions);
  }

  dropCompleted$: Subject<DragDropCompletedEvent> = new Subject();
  dropCancelled$: Subject<DragDropCancelledEvent> = new Subject();

  drop(event: CdkDragDrop<DragDropRegion<T>[]>) {
    const dragRegion = this.dragDropRegions[event.previousContainer.id];
    const dropRegion = this.dragDropRegions[event.container.id];

    const isSameRegion = dragRegion.key === dropRegion.key;

    let shouldRejectDropAction = false;
    if (dropRegion.dropCopiesItems) {
      // ne pas considerer les region 'copie' comme cibles valides du 'drop'.
      shouldRejectDropAction = true;
    } else if (dropRegion.preventDoublesKey && !isSameRegion) {
      // ne pas permettre d'ajouter le même item en double dans la drop region.
      const identifyDoubleItemObjectKey = `itemContext.${dropRegion.preventDoublesKey}`;
      const droppedItemId = getKey(event.item.data, identifyDoubleItemObjectKey);
      const isItemAlreadyInDropRegion = dropRegion.items.some((item) => {
        const itemId = getKey(item, identifyDoubleItemObjectKey);
        return Boolean(itemId === droppedItemId);
      });
      if (isItemAlreadyInDropRegion) {
        shouldRejectDropAction = true;
      }
    }

    if (shouldRejectDropAction) {
      this.dropCancelled$.next({
        fromRegion: event.previousContainer.id,
        fromIndex: event.previousIndex,
      });
      return false;
    }

    if (!isSameRegion) {
      if (dragRegion.dropCopiesItems) {
        copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      }
    } else {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }

    const dropCompletedEv = {
      fromRegion: event.previousContainer.id,
      fromIndex: event.previousIndex,
      toRegion: event.container.id,
      toIndex: event.currentIndex,
    };

    this.dropCompleted$.next(dropCompletedEv);
  }

  noReturnPredicate() {
    return false;
  }
}
