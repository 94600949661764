import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { Observable, Subscription } from 'rxjs';
import { PermissionGrantConfirmationComponent } from '../../permission-grant-confirmation/permission-grant-confirmation.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Select(EcoSessionState.userName) public userName$: Observable<string>;
  @Select(EcoSessionState.organizationName) public organizationName$: Observable<string>;

  private subscriptions = new Subscription();
  public userName: string;
  public organizationName: string;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private router: Router) {
  }

  private checkForRedirectParam() {
    const permissionParams = this.route.snapshot.queryParams['permissionPopup'];

    if (permissionParams) {
      const dialog = this.dialog.open(PermissionGrantConfirmationComponent, {
        disableClose: true,
        autoFocus: false
      });

      dialog.afterOpened()
        .subscribe(() => this.router.navigate(['.'], { relativeTo: this.route }));
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(this.userName$.subscribe((name) => this.userName = name));
    this.subscriptions.add(this.organizationName$.subscribe((name) => this.organizationName = name));
    this.checkForRedirectParam();
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
