<app-edit-with-modal
  #editor
  [noMargin]="true"
>
  <div
    class="container input-wrapper"
    [ngClass]="{
      'disabled':disabled, 
      'style-like-input-field':styleLikeInputField
    }"
    [tooltip]="disabled ? null : ('common.action.edit'| t)"
    (click)="open()"
  >
    <severity-badge
      class="severity-badge"
      [noMargin]="true"
      [data]="{severity: value}"
    ></severity-badge>
    <img
      *ngIf="!readonly"
      alt="edit-icon"
      class="edit"
      src="assets/favicons/icon_modify_disabled.svg"
    />
  </div>

  <div
    content
    class="form-wrapper"
  >
    <div
      class="item-wrapper"
      (click)="select(item)"
      *ngFor="let item of severitiesList"
    >
      <severity-badge [data]="{severity: item}"></severity-badge>
    </div>
  </div>
</app-edit-with-modal>