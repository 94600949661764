import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';

type LabelType = { name: string; sensitivityLabelId: string; severityLevel: SeverityEnum, hasProtection?: boolean };

export enum SeverityEnum {
  nul = 0,
  low = 1,
  moderate = 2,
  high = 3,
  critical = 4
}

@Component({
  selector: 'o365-labels',
  templateUrl: './o365Labels.component.html',
  styleUrls: [ './o365Labels.component.scss' ],
})
export class O365LabelsComponent implements OnInit {
  public impactLevelOptions = (() => {
    const options = [];
    for (const severityLevel of Object.keys(SeverityEnum)) {
      if (Number.isNaN(+severityLevel)) {
        options.push({
          value: SeverityEnum[severityLevel],
          displayValue: this.i18n.translate(`settings.labels.card.labels.picker.impact.${severityLevel}`),
          icon: this.getIconForLevel(severityLevel),
        });
      }
    }
    return options;
  })();

  public showLoading = true;
  public isLoading = false;
  public isLoadingLabels = false;

  public isEditing = false;
  public form: UntypedFormGroup;
  public labelsFormArray: UntypedFormArray;

  public isActivated = false;

  public direction: UiTableDirection = UiTableDirection.Asc;

  public initialLabels = [];

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private formBuilder: UntypedFormBuilder,
    private i18n: I18nService,
    private noticeService: NoticeService
  ) {}

  ngOnInit() {
    this.initForm();
    this.loadLabels();
  }

  public get isValid() {
    return this.labelsFormArray.controls.some((control) => control.value.severityLevel !== null);
  }

  private get newLabelsValues(): LabelType[] {
    return this.labelsFormArray.value;
  }

  public getLabelFormGroup(value: LabelType): UntypedFormGroup {
    return this.formBuilder.group({
      name: value.name,
      sensitivityLabelId: value.sensitivityLabelId,
      severityLevel: value.severityLevel,
      hasProtection: value.hasProtection,
    });
  }

  public cancel(): void {
    this.isActivated = this.initialLabels?.some((initialLabel) => initialLabel.severityLevel !== null);
    this.initForm();
  }

  public save(): void {
    this.organizationsApiService
      .updateOrganizationSensitivityLabels({
        isActivated: this.isActivated,
        labelsToUpdate: this.getLabelsToUpdate(),
      })
      .then((response) => {
        this.initialLabels = this.newLabelsValues;
        this.noticeService.notifyUser({ message: 'settings.labels.snack.success', level: NoticeLevels.SUCCESS });
      })
      .catch((error) => {
        this.noticeService.notifyUser({ message: 'settings.labels.snack.error', level: NoticeLevels.ERROR });
      });
  }

  public toggleSilencingActivation(): void {
    this.isActivated = !this.isActivated;
  }

  public sortLabels(): void {
    let temp = this.labelsFormArray.value as { name: string }[];
    if (this.direction === UiTableDirection.Asc) {
      this.direction = UiTableDirection.Desc;
      temp = temp.sort((a, b) => b.name.localeCompare(a.name));
    } else {
      temp = temp.sort((a, b) => a.name.localeCompare(b.name));
      this.direction = UiTableDirection.Asc;
    }

    this.labelsFormArray.patchValue(temp);
  }

  public getSeverityLevelOfLabel(label: LabelType): { value: number; displayValue: string; icon: string } {
    return this.impactLevelOptions.find((option) => option.value === label.severityLevel);
  }

  public get hasProtection() : boolean {
    return this.initialLabels?.some((label) => label.hasProtection);
  }

  private initForm(): void {
    this.labelsFormArray = this.formBuilder.array([]);
    if (this.initialLabels?.length) {
      for (const label of this.initialLabels) {
        this.labelsFormArray.push(this.getLabelFormGroup(label));
      }
    }

    this.form = this.formBuilder.group({
      labelsFormArray: this.labelsFormArray,
    });
  }

  private loadLabels(): void {
    this.isLoading = true;
    this.organizationsApiService
      .getOrganizationSensitivityLabels()
      .then((labels) => {
        this.initialLabels = labels;
        this.initForm();
        this.isActivated = this.initialLabels.some((initialLabel) => initialLabel.severityLevel !== null);
      })
      .finally(() => {
        this.isLoading = false;
        this.showLoading = true;
        this.isLoadingLabels = false;
      });
  }

  private getLabelsToUpdate(): Omit<LabelType, 'name'>[] {
    const changedLabels = this.newLabelsValues.filter((newLabel) => {
      const initialLabel = this.initialLabels.find((label) => label.sensitivityLabelId === newLabel.sensitivityLabelId);
      return initialLabel.severityLevel !== newLabel.severityLevel;
    });

    return changedLabels.map((changedLabel) => ({
      sensitivityLabelId: changedLabel.sensitivityLabelId,
      severityLevel: changedLabel.severityLevel,
    }));
  }

  private getIconForLevel(level: string): string {
    let icon = 'assets/favicons/';
    switch (SeverityEnum[level]) {
      case SeverityEnum.nul:
        icon += 'icon_gray_dot.svg';
        break;
      case SeverityEnum.low:
        icon += 'icon_green_dot_cropped.svg';
        break;
      case SeverityEnum.moderate:
        icon += 'icon_yellow_dot.svg';
        break;
      case SeverityEnum.high:
        icon += 'icon_orange_dot.svg';
        break;
      case SeverityEnum.critical:
        icon += 'icon_red_dot.svg';
        break;
    }
    return icon;
  }
}
