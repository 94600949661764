import { IPlaybookTaskTemplateRepresentation, PlaybookTaskPhases, PlaybookTaskRelation } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";
import { TaskOriginEnum } from "../../../components/playbook-tasks-drag-and-drop.component/playbook-tasks-drag-and-drop.component";


export enum PlaybookVersionKeyEnum {
  "OLD_PLAYBOOK" = "OLD_PLAYBOOK",
  "NEW_PLAYBOOK" = "NEW_PLAYBOOK"
};

export enum PhaseDropRegionKeyEnum {
  "OLD_PLAYBOOK_PREPARATION" = "OLD_PLAYBOOK_PREPARATION",
  "OLD_PLAYBOOK_IDENTIFICATION" = "OLD_PLAYBOOK_IDENTIFICATION",
  "OLD_PLAYBOOK_CONTAINMENT" = "OLD_PLAYBOOK_CONTAINMENT",
  "OLD_PLAYBOOK_REMEDIATION" = "OLD_PLAYBOOK_REMEDIATION",
  "OLD_PLAYBOOK_RECOVERY" = "OLD_PLAYBOOK_RECOVERY",
  "OLD_PLAYBOOK_POSTMORTEM" = "OLD_PLAYBOOK_POSTMORTEM",

  "NEW_PLAYBOOK_PREPARATION" = "NEW_PLAYBOOK_PREPARATION",
  "NEW_PLAYBOOK_IDENTIFICATION" = "NEW_PLAYBOOK_IDENTIFICATION",
  "NEW_PLAYBOOK_CONTAINMENT" = "NEW_PLAYBOOK_CONTAINMENT",
  "NEW_PLAYBOOK_REMEDIATION" = "NEW_PLAYBOOK_REMEDIATION",
  "NEW_PLAYBOOK_RECOVERY" = "NEW_PLAYBOOK_RECOVERY",
  "NEW_PLAYBOOK_POSTMORTEM" = "NEW_PLAYBOOK_POSTMORTEM"
};

export interface IPhaseDropRegionTask extends PlaybookTaskRelation {
  origin?: TaskOriginEnum;
  isExpanded?: boolean;
  isDragEnabled?: boolean;
  wasDeletedFromTemplate?: boolean;
  isNewTaskInTemplate?: boolean;
}

export interface IPhaseDropRegion {
  key: PhaseDropRegionKeyEnum;
  name: PlaybookTaskPhases;
  title: string;
  isExpanded: boolean;
  tasks: IPhaseDropRegionTask[];
}

export interface IDragParams {
  cdkDropListSortingDisabled: boolean;
  cdkDragLockAxis: "y";
  dropHandler: Function;
}

export interface IPlaybookVersionDragDrop {
  key: PlaybookVersionKeyEnum;
  version: string;
  updatedAt: number;
  title: string;
  tooltip: string;
  phaseDropRegions: IPhaseDropRegion[];
  dragParams: IDragParams;
  initialPlaybookTasks: PlaybookTaskRelation[];
  mdTaskTemplates: IPlaybookTaskTemplateRepresentation[];
}
