import { Component, OnInit } from '@angular/core';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { Eco } from 'projects/@common/definitions/eco';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { WhitelistsViewModeEnum } from 'projects/@common/services/api/respond/whitelists/whitelists.definitions';
import { WhitelistsService } from '../../whitelists.service';

@Component({
  selector: 'whitelists-filters',
  templateUrl: './whitelists-filters.component.html',
  styleUrls: [ './whitelists-filters.component.scss' ],
})
export class WhitelistsFiltersComponent implements OnInit {
  public locale: Eco.Languages;
  public listViewModeEnum = WhitelistsViewModeEnum;
  public listViewModes: TableFilterData;

  constructor(
    public readonly whitelistsService: WhitelistsService,
    private readonly i18n: I18nService,
    private readonly tableFilterToolDataFactory: TableFilterToolDataFactory
  ) { }

  ngOnInit(): void {
    this.locale = this.i18n.currentLocale as Eco.Languages;
    this.listViewModes = this.tableFilterToolDataFactory.create(WhitelistsViewModeEnum, 'whitelists.table.listViewModes.', this.viewMode, true);
  }

  public get viewMode(): WhitelistsViewModeEnum {
    return this.whitelistsService.listViewMode;
  }

  public get searchText(): string {
    return this.whitelistsService.searchText;
  }

  public get isLoading(): boolean {
    return this.whitelistsService.isLoading;
  }

  public get total(): number {
    return this.whitelistsService.total;
  }

  public handleRefreshClicked(): void {
    this.whitelistsService.handleDebouncedFetch();
  }

  public handleSearchTextChange(searchText: string): void {
    this.whitelistsService.setSearchText(searchText);
  }

  public handleViewModeChange(viewMode: WhitelistsViewModeEnum): void {
    this.whitelistsService.setListViewMode(viewMode);
  }
}
