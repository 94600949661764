import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { AlertStatusEnum, IAlert } from 'projects/@common/services/api/respond/alerts/alerts.definitions';

@Component({
  selector: 'app-alert-status',
  templateUrl: './alert-status.component.html',
  styleUrls: [ './alert-status.component.scss' ],
})
export class AlertStatusComponent implements OnInit {
  @Input() alert: IAlert;

  status: AlertStatusEnum;
  translatedStatus: string;

  constructor(private readonly i18n: I18nService) { }

  ngOnInit(): void {
    this.status = this.alert.status;
    if (this.status === AlertStatusEnum.SILENCED) {
      this.status = AlertStatusEnum.NEW; // overwrite pour les requis d'affaires (voir Jira ECO-1440)
    }
    this.translatedStatus = this.i18n.translate(`alerts.container.page.table.status.${this.status}`);
  }
}
