import { Component, OnInit } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ActifsService } from 'projects/@respond/actifs/actifs.service';
import { ActifsTableColumnEnum, getActifsTableColumns } from 'projects/@respond/actifs/containers/respond-actifs-list/actifs-list-table.config';

@Component({
  selector: 'actifs-section',
  templateUrl: './actifs-section.component.html',
  styleUrls: [ './actifs-section.component.scss' ],
})
export class ActifsSectionComponent implements OnInit {
  public tableColumnsDef: any;

  constructor(
    private readonly actifsService: ActifsService,
    private readonly i18n: I18nService
  ) { }

  ngOnInit(): void {
    this.tableColumnsDef = getActifsTableColumns([ ActifsTableColumnEnum.selected, ActifsTableColumnEnum.updatedAt, ActifsTableColumnEnum.relations ]);
  }

  public get actifs() {
    return this.actifsService.assetsData;
  }

  public get total() {
    return this.actifsService.total;
  }

  public get title() {
    return `${this.i18n.translate('incidents.container.page.details.tab.detail.actifs.title')} (${this.total})`;
  }
}
