import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { Field, FieldMap, QueryBuilderClassNames, QueryBuilderConfig, Rule, RuleSet, Option } from 'angular2-query-builder';
import * as isoCountries from 'i18n-iso-countries';
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";
import * as jmespath from 'jmespath';
import { Eco } from 'projects/@common/definitions/eco';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { EcoAlertRuleSet } from 'projects/@respond/filters/components/ecoAlert-filter-conditions/ecoAlert-filter-condition.definition';

isoCountries.registerLocale(fr);
isoCountries.registerLocale(en);

@Component({
  selector: 'query-builder-jmespath-component',
  templateUrl: './query-builder-jmespath.component.html',
  styleUrls: [ './query-builder-jmespath.component.scss' ],
})
export class QueryBuilderJmespathComponent implements OnInit {
  public readonly AUTOCOMPLETE_TYPE = AutocompleteTypes.CUSTOM;

  @Input() public conditions?: EcoAlertRuleSet;
  @Input() public conditionFields: FieldMap;
  @Input() public isDisabled: boolean;
  @Output() public conditionsChange: EventEmitter<EcoAlertRuleSet> = new EventEmitter();

  public config: QueryBuilderConfig;
  public warnings = [];

  public countries: { value: string; displayValue: string }[];
  public filterArrayChoices: { value: string; displayValue: string }[];
  private fieldsAddedDynamically: Field[] = [];

  classNames: QueryBuilderClassNames = {
    invalidRuleSet: 'empty-message',
    emptyWarning: 'empty-message',
  };

  constructor(private readonly i18n: I18nService) {}

  ngOnInit(): void {
    this.filterArrayChoices = Object.values(this.conditionFields)
      .filter((item: Field) => item.name.split('.').length > 1)
      .map((item: Field) => ({
        value: item.name.substring(0, item.name.lastIndexOf('.')),
        displayValue: item.name.substring(0, item.name.lastIndexOf('.')),
      }))
      .filter((obj, i, arr) => arr.findIndex((obj2) => (obj2.value === obj.value)) === i)
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue));

    this.config = {
      fields: this.conditionFields,
      getOperators: (_, field) => field?.operators ? field.operators : [ "prefix", "suffix", "contains", "matches", "exists", "=", "!=", ">", ">=", "<", "<=" ],
    };

    this.addCustomConditionFields(...this.findCustomConditionFields(this.conditions, []));

    const locale = this.i18n.currentLocale as Eco.Languages;
    const countries = isoCountries.getNames(locale, { select: "alias" });
    this.countries = Object.entries(countries)
      .map(([ key, value ]) => ({ value: key, displayValue: value }))
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue));

    this.computeWarnings();
  }

  isAddButtonAvailable(ruleset: RuleSet, button: string) {
    if (button === 'addRule') {
      return ruleset.condition !== 'not' || !ruleset.rules?.length;
    }
    if (button === 'addRuleSet') {
      return ruleset.condition !== 'not' || !ruleset.rules?.length;
    }
    return true;
  }

  getCountryDisplayValue(value: string): string {
    return this.countries?.find((option) => option.value === value)?.displayValue || '-';
  }

  getSelectedCountry(rule: any): any[] {
    if (rule.value) {
      return this.countries.filter((item) => item.value === rule.value);
    }
    return [];
  }

  getOperatorOptions(options: string[]): { value: string; displayValue: string }[] {
    return options.map((option) => ({ value: option, displayValue: option }));
  }

  getFieldDisplayValue(options: { name: string, value: string }[], rule: Rule): string {
    const field = options?.find((option) => option.value === rule.field);
    if (!field) {
      return '-';
    }
    const parentRuleSet = this.findParentRuleSet(this.conditions, rule);
    if (parentRuleSet && parentRuleSet.nestedQuery) {
      return field.name.replace(`${parentRuleSet.nestedQuery}.`, '');
    }
    return field.name;
  }

  getOptionDisplayValue(options: { name: string, value: string }[], value: string): string {
    return options?.find((option) => option.value === value)?.name || '-';
  }

  getBooleanDisplayValue(value: boolean | null): string {
    if (value === null || value === undefined) {
      return '-';
    }

    return value ? "True" : "False";
  }

  getSelectedField(rule: any, fields: { name: string, value: string }[]): any[] {
    const field = fields.find((item) => item.value === rule.field);
    if (!field) {
      return [];
    }
    const parentRuleSet = this.findParentRuleSet(this.conditions, rule);
    if (parentRuleSet && parentRuleSet.nestedQuery) {
      return [ { value: field.value, displayValue: field.name.replace(`${parentRuleSet.nestedQuery}.`, '') } ];
    }
    return [ { value: field.value, displayValue: field.name } ];
  }

  getOptions(fields: Field[], rule: Rule): { value: string; displayValue: string }[] {
    const parentRuleSet: EcoAlertRuleSet | null = this.findParentRuleSet(this.conditions, rule);
    let fieldsToDisplay = fields;
    if (parentRuleSet && parentRuleSet.nestedQuery) {
      fieldsToDisplay = fields.filter((item: Field) => item.name.includes(parentRuleSet.nestedQuery) && item.name !== parentRuleSet.nestedQuery);
    }
    return fieldsToDisplay.map((option) => {
      if (parentRuleSet.nestedQuery) {
        return { value: option.value, displayValue: option.name.replace(`${parentRuleSet.nestedQuery}.`, '') };
      }
      return { value: option.value, displayValue: option.name };
    });
  }

  getBooleanOptions(): { value: string; displayValue: string }[] {
    return [ { value: 'true', displayValue: 'True' }, { value: 'false', displayValue: 'False' } ];
  }

  onBooleanChange(rule: any, $event: string) {
    rule.value = $event === 'true';
    this.conditionsChange.emit(this.conditions);
  }

  setNumber(rule: any, value: string) {
    rule.value = +value;
  }

  setFieldValue(event: string, rule: any, onChange: any) {
    if (event && this.isValidJmespath(event)) {
      delete rule['invalid'];
      const itemAdded: Field = this.fieldsAddedDynamically.find((value: Field) => value.name === event);
      if (!Object.keys(this.config.fields).find((value: string) => value === event) || itemAdded) {
        if (Object.keys(this.config.fields).indexOf(event) > -1) {
          delete this.config.fields[event];
        }
        const index = this.fieldsAddedDynamically.indexOf(itemAdded);
        if (index > -1) {
          this.fieldsAddedDynamically.splice(index, 1);
        }

        this.addCustomConditionFields(event);
      }
      rule.field = event;
      onChange(event, rule);
    } else {
      rule['invalid'] = true;
    }
    this.computeWarnings();
    this.conditionsChange.emit(this.conditions);
  }

  getIsValid(): boolean {
    return this.warnings.length === 0;
  }

  computeWarnings() {
    this.warnings = [];

    this.validateRuleset(this.conditions, this.warnings);

    this.warnings = [ ...new Set(this.warnings) ];
  }

  public getSelectedSubQueryField(ruleset: EcoAlertRuleSet): { value: string, displayValue: string }[] {
    if (ruleset?.nestedQuery) {
      return [ { value: ruleset.nestedQuery, displayValue: ruleset.nestedQuery } ];
    }
    return [];
  }

  public handleSelectedRuleSet(event: string, ruleset: EcoAlertRuleSet) {
    if (event) {
      ruleset.nestedQuery = event;
    } else {
      ruleset.nestedQuery = null;
    }
    this.conditionsChange.emit(this.conditions);
  }

  public isInvalidRule(rule: any): boolean {
    return !!rule?.invalid;
  }

  public isInvalidSubQueryInput(ruleset: EcoAlertRuleSet): boolean {
    if (ruleset.nestedQuery) {
      return !this.isValidJmespath(ruleset.nestedQuery);
    }
    return false;
  }

  public getEnumOptions(rule: Rule): { value: string; displayValue: string }[] {
    if (rule.field && this.conditionFields[rule.field]) {
      const options: Option[] = this.conditionFields[rule.field]?.options;
      return options.map((option: Option) => ({
        value: option.value,
        displayValue: option.name,
      }));
    }
    return [];
  }

  public onEnumOptionChange(rule: Rule, value: string) {
    rule.value = value;
    this.conditionsChange.emit(this.conditions);
  }

  public onDateValueUpdate(rule: Rule, value: number) {
    rule.value = value;
    this.conditionsChange.emit(this.conditions);
  }

  private findParentRuleSet(root, targetRule: Rule): EcoAlertRuleSet | null {
    if (root?.rules) {
      for (const rule of root.rules) {
        if ((rule as EcoAlertRuleSet).rules !== undefined) {
          const found = this.findParentRuleSet(rule, targetRule);
          if (found) {
            return found;
          }
        } else if (rule === targetRule) {
          return root;
        }
      }
    }
    return null;
  }

  private findCustomConditionFields(root, keys: string[]): string[] {
    if (root.nestedQuery) {
      if (!this.conditionFields[root.nestedQuery]) {
        keys.push(root.nestedQuery);
      }
    }
    if (root?.field) {
      if (!this.conditionFields[root['field']]) {
        keys.push(root.field);
      }
    }
    if (root?.rules) {
      for (const rule of root.rules) {
        if ((rule as Rule).field !== undefined) {
          if (!this.conditionFields[rule['field']]) {
            keys.push(rule.field);
          }
        } else {
          return this.findCustomConditionFields(rule, keys);
        }
      }
    }
    return keys;
  }

  private addCustomConditionFields(...keys: string[]) {
    keys.forEach((key: string) => {
      const fieldToAdd: Field = {
        name: key,
        type: "string",
        nullable: true,
        operators: [ "exists", "=", "!=", "prefix", "suffix", "contains", "matches" ],
      };
      this.fieldsAddedDynamically.push(fieldToAdd);
      this.config.fields[key] = fieldToAdd;
    });
  }

  private validateRuleset(condition: RuleSet, warnings: any[]) {
    for (const rule of condition.rules) {
      if (rule['condition']) {
        this.validateRuleset(rule as RuleSet, warnings);
      }
    }
  }

  private isValidJmespath(input: string): boolean {
    if (input) {
      try {
        jmespath.search({}, input);
        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  }
}
