import { Component, Input, OnInit } from '@angular/core';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';

@Component({
  selector: 'filter-usecase-name',
  templateUrl: './filter-usecase-name.component.html',
  styleUrls: [ './filter-usecase-name.component.scss' ],
})
export class FilterUsecaseNameComponent implements OnInit {
  @Input() filter: Filter;

  public usecaseNames: string[] = [];

  constructor(private translatedObject: TranslatedObjectPipe) { }

  ngOnInit(): void {
    this.usecaseNames = this.filter.usecases?.map((usecase) => `${this.translatedObject.transform(usecase.name)}`);
  }
}
