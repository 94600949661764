import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TemplateStatesFilterEnum } from 'projects/@assist-ops/definitions/templateStatesFilter.enum';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { CookInedibleSnack } from 'projects/@common/modules/notice/implementations/snackbar/state/snackbar.state';
import { GroupTemplatesApiService } from 'projects/@common/services/api/sg/group-templates/group-templates-api.service';
import { IGroupTemplate } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';

export enum TemplateTypesFilterEnum {
  ALL = GroupTypeEnum.ALL,
  o365OfficeTeams = GroupTypeEnum.O365OFFICETEAMS,
  o365OfficeGroup = GroupTypeEnum.O365OFFICEGROUP
}
@Component({
  selector: 'group-templates-table',
  templateUrl: './group-templates-table.component.html',
  styleUrls: [ './group-templates-table.component.scss' ],
})
export class GroupTemplatesTableComponent implements OnInit {
  private readonly LIMIT = 50;

  public templates: IGroupTemplate[];

  public selectedTemplateGuid: string;

  public isLoading = false;

  public fulltext: string = null;

  public total: number;

  public templateStatesFilter: any;

  public defaultTemplateState = TemplateStatesFilterEnum.ALL;

  private templateState = null;

  public templateTypesFilter: any;

  public defaultTemplateType = TemplateTypesFilterEnum.ALL;

  private templateType = null;

  private from = 0;

  public direction = 'asc';

  public sort = 'name';

  public columnsDef = [
    {
      label: 'groupTemplates.table.name',
      field: 'name',
      type: UiStaticTableRowType.TEXT,
    },
    {
      label: 'groupTemplates.table.type',
      field: 'type',
      enumPrefix: 'groupTemplates.type.',
      type: UiStaticTableRowType.ENUM,
      width: '150px',
    },
    {
      label: 'groupTemplates.table.state',
      field: 'state',
      enumPrefix: 'groupTemplates.state.',
      type: UiStaticTableRowType.ENUM,
      width: '150px',
    },
    {
      label: 'groupTemplates.table.updatedAt',
      field: 'updatedAt',
      type: UiStaticTableRowType.PIPED_FIELD,
      pipe: DTPipe,
      pipeParams: { locale: this.i18n.currentLocale },
      width: '200px',
    },
    {
      label: 'groupTemplates.table.updatedBy',
      field: 'updatedBy',
      type: UiStaticTableRowType.AVATAR,
      withUser: true,
      width: '200px',
    },
  ];

  public columsDefTranslated: any;

  constructor(
    private readonly templatesService: GroupTemplatesApiService,
    private readonly router: Router,
    private readonly store: Store,
    private staticTableDataMapper: StaticTableDataMapper,
    private tableFilterToolDataFactory: TableFilterToolDataFactory,
    public i18n: I18nService
  ) {}

  public ngOnInit(): void {
    this.handleRefresh();

    this.templateTypesFilter = this.tableFilterToolDataFactory.create(TemplateTypesFilterEnum, '', TemplateTypesFilterEnum.ALL, true);
    this.templateStatesFilter = this.tableFilterToolDataFactory.create(TemplateStatesFilterEnum, '', TemplateStatesFilterEnum.ALL, true);
  }

  private loadMoreTemplates(): void {
    this.isLoading = true;
    this.templatesService
      .getEcoGroupTemplates({
        direction: this.direction,
        sort: this.sort,
        from: this.from,
        limit: this.LIMIT,
        fulltext: this.fulltext,
        type: this.templateType,
        state: this.templateState,
      })
      .then((result) => {
        this.templates = this.templatesMapper(result?.items);
        this.total = result.total;
        this.from = +result.nextToken;
      })
      .catch(() => {
        this.store.dispatch(new CookInedibleSnack('groupTemplates.error.loading.multiple.message'));
      })
      .finally(() => {
        this.isLoading = false;
        [ this.columsDefTranslated, this.templates ] = this.staticTableDataMapper.translate(this.columnsDef, this.templates);
      });
  }

  public handleRefresh(): void {
    this.from = 0;
    this.selectedTemplateGuid = null;
    this.templates = [];
    this.loadMoreTemplates();
  }

  public handleSearchQuery(fulltext: string): void {
    this.fulltext = fulltext;
    this.handleRefresh();
  }

  public handleSearchClear(): void {
    this.fulltext = null;
    this.handleRefresh();
  }

  public handleTypeFilter(type: TemplateTypesFilterEnum): void {
    if (type === TemplateTypesFilterEnum.ALL) {
      this.templateType = null;
    } else {
      this.templateType = type;
    }
    this.handleRefresh();
  }

  public handleStateFilter(state: TemplateStatesFilterEnum): void {
    if (state === TemplateStatesFilterEnum.ALL) {
      this.templateState = null;
    } else {
      this.templateState = state;
    }
    this.handleRefresh();
  }

  public handleSorting(sort: string, direction: string): void {
    this.sort = sort;
    this.direction = direction;
    this.handleRefresh();
  }

  public handleTemplateSelection(isChecked: boolean, template: IGroupTemplate): void {
    if (isChecked) {
      this.selectedTemplateGuid = template.id;
    } else {
      this.selectedTemplateGuid = null;
    }
  }

  public handleRowClick(template: IGroupTemplate): void {
    this.router.navigate([ `csp/templates/groupTemplate/${template.id}` ]);
  }

  public createTemplate(fromExisting = false): void {
    let url = 'csp/templates/groupTemplate/';
    if (fromExisting) {
      url += this.selectedTemplateGuid;
    }

    this.router.navigate([ url ]);
  }

  public onScrollToBottom() {
    if (!this.isLoading && this.templates.length < this.total) {
      this.loadMoreTemplates();
    }
  }

  private templatesMapper(templates: IGroupTemplate[]): IGroupTemplate[] {
    return templates.map((template) => ({
      ...template, updatedBy: {
        firstName: template.updatedBy || `Mondata ${this.i18n.translate('common.system')}`,
        lastName: '',
      },
    }));
  }
}
