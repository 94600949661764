<div class="header-container">
    <p class="connector-instance-volumetry-label">{{ 'connector.instance.drawer.health.section.volumetry.title' | t }}</p>
    <ui-table-date-tool [hidden]="chartDataLoading"
        [locale]="i18n.currentLocale"
        [withHours]="false"
        [excludePresets]="['today', 'last_180_days', 'last_365_days']"
        [defaultPresetOption]="defaultSelectedPeriod"
        [displayDropdownToLeft]="true"
        (timeEmitter)="timePeriodChanged($event)"
        (resetDateEmitter)="timePeriodChanged(defaultSelectedTimePeriod)"
    ></ui-table-date-tool>
</div>
<ui-spinner *ngIf="chartDataLoading"></ui-spinner>
<div class="graph" [attr.id]="chartName + '_ID'" [hidden]="volumetryHistoryData.length <= 0 || chartDataLoading"></div>
<ng-container *ngIf="volumetryHistoryData.length <= 0 && !chartDataLoading">
    <div class="no-chart-section">
        <img class="small-size"
            [src]="'assets/favicons/icon_playbook_alert_update.svg'"
            alt="infoIcon"
        />
        <p>{{'connector.instance.status.chart.empty.data.text' | t}}</p>
    </div>
</ng-container>