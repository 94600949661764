<app-edit-with-modal #editor>
  <div
    *ngIf="zohoUrl"
    class="edit-content big-gap"
    [ngClass]="{'disabled':disabled}"
  >
    <ui-icon
      [name]="'chain'"
      size="medium"
    ></ui-icon>
    <a
      [href]="zohoUrl.url"
      target="_blank"
    >{{zohoUrl.name}}</a>
    <img
      *ngIf="zohoUrl && !disabled"
      (click)="open()"
      alt="edit-icon"
      class="pointer edit"
      src="assets/favicons/icon_modify_disabled.svg"
      [tooltip]="disabled ? null : ('common.action.edit'| t)"
    />
  </div>

  <div
    *ngIf="!zohoUrl"
    class="edit-content small-gap"
    [ngClass]="{
      'disabled':disabled,
      'pointer': !disabled
    }"
    [tooltip]="disabled ? null : ('common.action.edit'| t)"
    (click)="open()"
  >
    <img
      alt="edit-icon"
      class="pointer"
      src="assets/favicons/icon_add_circle.svg"
    />
    <span class="add-text">{{'incidents.container.page.details.tab.detail.zoho.insert' | t}}</span>
  </div>

  <div
    content
    class="form-wrapper"
  >
    <ui-table-search-tool
      [withIcon]="false"
      [DEBOUNCE_TIME]="0"
      [placeholder]="'incidents.container.page.details.tab.detail.zoho.url.placeholder' | t"
      [query]="zohoTicketUrl"
      (clear)="clearUrl()"
      (query)="setZohoUrl($event)"
    ></ui-table-search-tool>

    <ui-table-search-tool
      [withIcon]="false"
      [DEBOUNCE_TIME]="0"
      [placeholder]="'incidents.container.page.details.tab.detail.zoho.id.placeholder' | t"
      [query]="zohoTicketId"
      (clear)="clearId()"
      (query)="setZohoId($event)"
    ></ui-table-search-tool>

    <ui-confirmations-buttons (confirmationEvent)="handleConfirmation($event)"></ui-confirmations-buttons>
  </div>

  <ui-click-out-handler
    *ngIf="isOpen"
    (clickEvent)="cancel()"
  ></ui-click-out-handler>
</app-edit-with-modal>