import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export enum PlaybookTabsEnum {
  DETAILS = 'details',
  TASK = 'tasks',
  NOTES = 'notes',
  USECASES = 'usecases',
  HISTORY = 'history'
}

@Injectable({
  providedIn: 'root',
})
export class PlaybookTabService {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  private _tabsArray = [
    PlaybookTabsEnum.DETAILS,
    PlaybookTabsEnum.TASK,
    PlaybookTabsEnum.NOTES,
    PlaybookTabsEnum.USECASES,
    PlaybookTabsEnum.HISTORY,
  ];

  private _tabsToHide: Array<PlaybookTabsEnum> = [];

  private _currentTabSelected = PlaybookTabsEnum.DETAILS;

  public get currentTabSelected(): PlaybookTabsEnum {
    return this._currentTabSelected;
  }

  public get tabsToDisplay(): Array<PlaybookTabsEnum> {
    return this._tabsArray.filter((tab) => !this._tabsToHide.includes(tab));
  }

  public setTabsToHide(tabs: Array<PlaybookTabsEnum>) {
    this._tabsToHide = tabs;
  }

  public setCurrentTab(tab: PlaybookTabsEnum): void {
    if (this.isValidTab(tab)) {
      this._currentTabSelected = tab;
      this.setTabInURL(tab);
    }
  }

  public resetTabs(): void {
    this.setCurrentTab(PlaybookTabsEnum.DETAILS);
  }

  public initSelectedTabFromURL(): void {
    const tabFromUrl = this.route.snapshot.queryParams.tab;
    if (tabFromUrl && this.isValidTab(tabFromUrl)) {
      this._currentTabSelected = tabFromUrl;
    } else {
      this.resetTabs();
    }
  }

  public setTabInURL(tab: PlaybookTabsEnum): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab },
      queryParamsHandling: 'merge',
    });
  }

  public isValidTab(tab: PlaybookTabsEnum): boolean {
    return this.tabsToDisplay.includes(tab);
  }
}
