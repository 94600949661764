<div class="top-layer" *ngIf="showOptionsMenu" (click)="toggleMenu($event);"></div>
<button
  *ngIf="isFromAdmConsole"
  class="options-menu-button"
  ui-button
  icon="ico_dots"
  [disabled]="isDisabled"
  [isSvg]="true"
  [custom-icon]="true"
  (click)="toggleMenu($event)"
></button>

<div
  class="options-menu-popup"
  [class.menu-hidden]="!showOptionsMenu"
  (click)="toggleMenu($event)"
>
  <div class="options-menu-popup-option" (click)="addToTaskEvidence()">
    <img src="assets/favicons/observables/icon_flag_grey.svg" alt=""/>
    <span>{{ 'respond.entities.menu.add-to-task' | t }}</span>
  </div>

</div>