import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DisplayGuard } from 'projects/@common/modules/guards/display.guard';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { GuestUsersManagementContainerComponent } from './containers/guest-users-management-container/guest-users-management-container.component';
import { GuestUsersContainer } from './containers/guest-users/guest-users.container';


const routes: Routes = [
  {
    path: '',
    component: GuestUsersContainer,
    canLoad: [ DisplayGuard ],
    data: {
      requirements: {
        flags: [ FeaturesEnum.GUESTS_MANAGEMENT ],
      },
    },
  },
  {
    path: 'management',
    component: GuestUsersManagementContainerComponent,
    canLoad: [ DisplayGuard ],
    data: {
      requirements: {
        flags: [ FeaturesEnum.GUESTS_MANAGEMENT ],
      },
    },
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class GuestUsersRoutingModule {
}
