import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';

export interface IEcoProfileConfig {
  title: string;
  emptyMessage: string;
  actions: IEcoProfileActions[];
  knowMoreText?: string,
}

export interface IEcoProfileActions {
  name: string;
  description: number | string;
  shouldDisplay: boolean;
  image?: string;
  tooltip?: string;
  flagRequired?: FeaturesEnum[];
}

@Component({
  selector: 'sg-eco-profile-row',
  templateUrl: './eco-profile-row.component.html',
  styleUrls: [ './eco-profile-row.component.scss' ],
})
export class EcoProfileRowComponent implements OnChanges {
  @Input() config: IEcoProfileConfig;

  @Input() yearsDropdownFilter: number[];

  @Input() secondCellWidth = null;

  @Output() onYearSelect: EventEmitter<number> = new EventEmitter();

  public defaultYearSelected: number;

  constructor() { }

  ngOnChanges(): void {
    if (this.yearsDropdownFilter) {
      this.defaultYearSelected = this.yearsDropdownFilter[0];
    }
  }

  public shouldDisplay(list: IEcoProfileActions[]): boolean {
    return list?.every((value) => !value.shouldDisplay);
  }

  public handleSelection(year: number): void {
    this.onYearSelect.emit(year);
  }
}
