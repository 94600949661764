import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { AutocompleteCustomValue } from '@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component';
import { ExtraIconHeader } from 'projects/@common/components/escalation-expand-card/escalation-expand-card.component';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { EscalationContactListType, EscalationPeriodEnum, ICommunicationContact, IConnectorEscalation, IEscalationLevelForDisplay, IEscalationList, IEsclationListContact } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { DataConnectorTypes } from '@md.eco/connectors';
import { IPlaybookDetail } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { clone } from 'projects/@common/utils/utils';
import { chainMiddleware } from '@microsoft/mgt-element';

interface SelectOption {
  value: string; displayValue: string; description?: string, icon?: string
};

@Component({
  selector: 'communication-escalation-list',
  templateUrl: './communication-escalation-list.component.html',
  styleUrls: [ './communication-escalation-list.component.scss' ],
})
export class CommunicationEscalationListComponent implements OnChanges {
  @Input() escalationList: IEscalationList;
  @Input() escalationLists: IEscalationList[];
  @Input() contacts: ICommunicationContact[];
  @Input() connectors: IConnectorEscalation[];
  @Input() playbooks: IPlaybookDetail[];
  @Input() hasEditPermission: boolean;
  @Input() hasDeletePermission: boolean;

  @Output() saveEvent = new EventEmitter<IEscalationList>();
  @Output() deleteEvent = new EventEmitter<IEscalationList>();

  headerIcons: ExtraIconHeader[];
  isEditing: boolean;
  isExpanded: boolean;
  customAutocompleteType = AutocompleteTypes.CUSTOM;

  ownerContactAutocompleteOptions: SelectOption[];
  selectedOwnerContactAutocompleteOption: SelectOption;

  playbookAutocompleteOptions: AutocompleteCustomValue[];
  selectedPlaybookAutocompleteOptions: AutocompleteCustomValue[];

  connectorsAutocompleteOptions: AutocompleteCustomValue[];
  selectedConnectorsAutocompleteOptions: AutocompleteCustomValue[];

  periodSelectOptions: SelectOption[];
  availablePeriodSelectOptions: SelectOption[];
  selectedPeriod: SelectOption;

  private prevEscalationList: IEscalationList;

  constructor(
    private readonly i18n: I18nService,
    private readonly translateObjetPipe: TranslatedObjectPipe
  ) { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.escalationList || changes.contacts) {
      this.initializeAll();
    }
  }

  get isTypeDefault(): boolean {
    return this.escalationList.type === EscalationContactListType.DEFAULT || this.escalationList.type === EscalationContactListType.DEFAULT_OFFHOURS;
  }

  get showPrioritiesMatrix(): boolean {
    return ![EscalationContactListType.DEFAULT, EscalationContactListType.DEFAULT_OFFHOURS].includes(this.escalationList.type)
      && this.escalationList.communicationMethods.some(method => method.withPriorities);
  }

  get hasDefaultOffhoursList(): boolean {
    return this.escalationLists.some((list) => list.type === EscalationContactListType.DEFAULT_OFFHOURS && !!list.createdAt);
  }

  get hasDefaultList(): boolean {
    return this.escalationLists.some((list) => list.type === EscalationContactListType.DEFAULT && !!list.createdAt);
  }

  get isValid(): boolean {
    const isValidOwner = !!this.escalationList.ownerContactId;
    const isValidName = !!this.escalationList.name;
    const isValidEscalations = this.isValidEscalationLevels;

    if (this.isTypeDefault) {
      return isValidOwner && isValidName && isValidEscalations;
    }

    const isValidEscalationPriorities = this.isValidEscalationPriorities;

    const isValidPlaybooks = this.escalationList.playbookIds?.length > 0 && this.escalationList.playbookIds.every((id) => !!id);
    const isValidConnectors = this.escalationList.datasourceTypes?.length > 0 && this.escalationList.datasourceTypes.every((type) => !!type);
    const isValidConditions = isValidConnectors || isValidPlaybooks;

    return isValidOwner && isValidName && isValidConditions && isValidEscalations && isValidEscalationPriorities;
  }

  get isValidEscalationLevels(): boolean {
    return this.escalationList?.escalationLevels?.every((level) => level.contacts.length > 0 && level.contacts.every((contact) => this.getIsValidContact(contact)));
  }

  get isValidEscalationPriorities(): boolean {
    if (this.escalationList?.escalationLevels?.every((level) => level.contacts?.every((contact) => !contact.withPriorities))) {
      return true;
    }
    const p1Valid = this.escalationList?.escalationLevels?.some((level) => level.contacts?.some((contact) => !contact.withPriorities || contact.priorities?.includes(1)));
    const p2Valid = this.escalationList?.escalationLevels?.some((level) => level.contacts?.some((contact) => !contact.withPriorities || contact.priorities?.includes(2)));
    const p3Valid = this.escalationList?.escalationLevels?.some((level) => level.contacts?.some((contact) => !contact.withPriorities || contact.priorities?.includes(3)));
    const p4Valid = this.escalationList?.escalationLevels?.some((level) => level.contacts?.some((contact) => !contact.withPriorities || contact.priorities?.includes(4)));
    return p1Valid && p2Valid && p3Valid && p4Valid;
  }

  initializeAll(): void {
    this.initEscalationListType();
    this.initOwnerSelectOptions();
    this.initPeriodSelectOptions();
    this.initPlaybooksSelectOptions();
    this.initConnectorsSelectOptions();
    this.initEscalationLevels();
    this.initHeaderIcons();
  }

  getIsValidContact(contact: IEsclationListContact): boolean {
    return !!contact.id && !!contact.selectedContactAutocompleteOption?.value && contact.selectedCommunicationMethods?.length > 0;
  }

  toggleExpand(value: boolean) {
    this.isExpanded = value;
  }

  toggleEdit(value: boolean) {
    this.isEditing = value;
    if (this.isEditing) {
      this.prevEscalationList = clone(this.escalationList);
      this.toggleExpand(true);
    }
  }

  setEscalationListName(value: string) {
    this.escalationList.name = value;
  }

  handlePeriodSelected(value: EscalationPeriodEnum) {
    this.selectedPeriod = this.availablePeriodSelectOptions.find((option) => option.value === value);
    if (!this.isTypeDefault) {
      if (value === EscalationPeriodEnum.ANYTIME) {
        this.setEscalationListType(EscalationContactListType.CUSTOM_ANYTIME);
      } else if (value === EscalationPeriodEnum.OFF_HOURS) {
        this.setEscalationListType(EscalationContactListType.CUSTOM_OFFHOURS);
      } else if (value === EscalationPeriodEnum.BUSINESS_HOURS) {
        this.setEscalationListType(EscalationContactListType.CUSTOM_BUSINESS_HOURS);
      }
    }
  }

  handleOwnerSelected(option: AutocompleteCustomValue) {
    if (option?.value) {
      this.escalationList.ownerContactId = option.value;
      this.selectedOwnerContactAutocompleteOption = option;
    } else {
      this.escalationList.ownerContactId = null;
      this.selectedOwnerContactAutocompleteOption = null;
    }
  }

  handlePlaybookSelected(option: AutocompleteCustomValue) {
    if (option?.value && !this.escalationList?.playbookIds?.includes(option.value)) {
      if (this.escalationList.playbookIds === undefined || this.escalationList.playbookIds === null) {
        this.escalationList.playbookIds = [];
      }
      this.escalationList.playbookIds.push(option.value);
    }
  }

  handleDeletePlaybook(playbookId: string) {
    this.escalationList.playbookIds = this.escalationList.playbookIds.filter((id) => id !== playbookId);
    this.selectedPlaybookAutocompleteOptions = this.selectedPlaybookAutocompleteOptions.filter((option) => option.value !== playbookId);
  }

  handleConnectorSelected(option: AutocompleteCustomValue) {
    if (option?.value && !this.escalationList?.datasourceTypes?.includes(option.value)) {
      if (this.escalationList.datasourceTypes === undefined || this.escalationList.datasourceTypes === null) {
        this.escalationList.datasourceTypes = [];
      }
      this.escalationList.datasourceTypes.push(option.value);
    }
  }

  handleDeleteConnector(datasourceType: DataConnectorTypes) {
    this.escalationList.datasourceTypes = this.escalationList.datasourceTypes.filter((type) => type !== datasourceType);
    this.selectedConnectorsAutocompleteOptions = this.selectedConnectorsAutocompleteOptions.filter((option) => option.value !== datasourceType);
  }

  handleDelete() {
    this.deleteEvent.emit(this.escalationList);
  }

  handleSave() {
    if (this.isValid) {
      this.saveEvent.emit(this.escalationList);
    }
  }

  handleEditCanceled() {
    if (!this.escalationList.createdAt) {
      this.handleDelete();
      return;
    }
    this.escalationList = this.prevEscalationList;
    this.initializeAll();
  }

  addEscalationLevel() {
    const level = this.escalationList.escalationLevels.length + 1;
    this.escalationList.escalationLevels.push({
      level,
      contacts: [ { id: null, selectedCommunicationMethods: [], withPriorities: false, priorities: [] } ]
    });
  }

  removeEscalationLevel(level: IEscalationLevelForDisplay) {
    this.escalationList.escalationLevels = this.escalationList.escalationLevels.filter((escalationLevel) => escalationLevel !== level);
  }

  private initOwnerSelectOptions(): void {
    this.ownerContactAutocompleteOptions = this.contacts.map((contact) => ({
      value: contact.id,
      displayValue: contact.name,
    }));
    this.selectedOwnerContactAutocompleteOption = this.ownerContactAutocompleteOptions.find((option) => option.value === this.escalationList.ownerContactId);
  }

  private initPeriodSelectOptions(): void {
    this.periodSelectOptions = Object.values(EscalationPeriodEnum).map((enumValue) => ({
      value: enumValue,
      displayValue: this.i18n.translate(`respond.communication.tab.escalationList.${enumValue}`),
      icon: enumValue === EscalationPeriodEnum.BUSINESS_HOURS
        ? `assets/favicons/communication/icon_communication_calendar_business_hours.svg`
        : enumValue === EscalationPeriodEnum.OFF_HOURS
          ? `assets/favicons/communication/icon_communication_calendar_off_hours.svg`
          : null,
    }));

    this.availablePeriodSelectOptions = this.periodSelectOptions.filter((option) => {
      if (this.escalationList.type === EscalationContactListType.DEFAULT) {
        return option.value === EscalationPeriodEnum.ANYTIME;
      } else if (this.escalationList.type === EscalationContactListType.DEFAULT_OFFHOURS) {
        return option.value === EscalationPeriodEnum.OFF_HOURS;
      }
      return true;
    });

    this.setPeriodFromEscalationListType();
  }

  private setPeriodFromEscalationListType(): void {
    if (this.escalationList.type === EscalationContactListType.DEFAULT) {
      this.handlePeriodSelected(EscalationPeriodEnum.ANYTIME);
    } else if (this.escalationList.type === EscalationContactListType.DEFAULT_OFFHOURS) {
      this.handlePeriodSelected(EscalationPeriodEnum.OFF_HOURS);
    } else if (this.escalationList.type === EscalationContactListType.CUSTOM_ANYTIME) {
      this.handlePeriodSelected(EscalationPeriodEnum.ANYTIME);
    } else if (this.escalationList.type === EscalationContactListType.CUSTOM_OFFHOURS) {
      this.handlePeriodSelected(EscalationPeriodEnum.OFF_HOURS);
    } else if (this.escalationList.type === EscalationContactListType.CUSTOM_BUSINESS_HOURS) {
      this.handlePeriodSelected(EscalationPeriodEnum.BUSINESS_HOURS);
    }
  }

  private setEscalationListType(value: EscalationContactListType) {
    this.escalationList.type = value;
    if (this.isTypeDefault) {
      this.setEscalationListName(this.i18n.translate(`respond.communication.tab.escalationList.typeEnum.${value}`));
    }
  }

  private initEscalationListType(): void {
    if (!this.escalationList.type) {
      if (!this.hasDefaultList) {
        this.setEscalationListType(EscalationContactListType.DEFAULT);
      } else if (!this.hasDefaultOffhoursList) {
        this.setEscalationListType(EscalationContactListType.DEFAULT_OFFHOURS);
      } else {
        this.setEscalationListType(EscalationContactListType.CUSTOM_ANYTIME);
      }
    } else if (this.isTypeDefault) {
      this.setEscalationListName(this.i18n.translate(`respond.communication.tab.escalationList.typeEnum.${this.escalationList.type}`));
    }
  }

  private initEscalationLevels(): void {
    if (!this.escalationList.escalationLevels?.length) {
      this.escalationList.escalationLevels = [];
      this.addEscalationLevel();
    }
  }

  private getTranslatedObjectValue(value: ITranslatedField): string {
    return this.translateObjetPipe.transform(value);
  }

  private getPlaybookDisplayValue(playbook: IPlaybookDetail): string {
    let displayName = `${this.getTranslatedObjectValue(playbook.name)} - ${playbook.suffix}`;
    if (playbook.templateCatalogId) {
      displayName = `(${playbook.templateCatalogId}) ${displayName}`;
    }
    return displayName;
  }

  private initPlaybooksSelectOptions(): void {
    this.playbookAutocompleteOptions = [];

    if (this.playbooks?.length) {
      this.playbookAutocompleteOptions = this.playbooks.map((playbook) => ({
        value: playbook.id,
        displayValue: this.getPlaybookDisplayValue(playbook),
        description: this.getTranslatedObjectValue(playbook.description),
        image: null,
      })).sort((a, b) => a.displayValue.localeCompare(b.displayValue));
    }

    this.selectedPlaybookAutocompleteOptions = this.playbookAutocompleteOptions.filter((option) => this.escalationList.playbookIds?.includes(option.value));
  }

  private initConnectorsSelectOptions(): void {
    this.connectorsAutocompleteOptions = [];

    if (this.connectors?.length) {
      this.connectorsAutocompleteOptions = this.connectors.map((connector) => ({
        value: connector.datasourceType,
        displayValue: connector.displayName,
        description: connector.datasourceType,
        image: connector.icon,
      }));
    }

    this.selectedConnectorsAutocompleteOptions = this.connectorsAutocompleteOptions.filter((option) => this.escalationList.datasourceTypes?.includes(option.value as DataConnectorTypes));
  }

  public initHeaderIcons(): void {
    this.headerIcons = [];

    // Business or Off Hours icon
    if (this.escalationList.type === EscalationContactListType.DEFAULT_OFFHOURS || this.escalationList.type === EscalationContactListType.CUSTOM_OFFHOURS) {
      this.headerIcons.push({
        path: "assets/favicons/communication/icon_communication_calendar_off_hours.svg",
        tooltipText: this.i18n.translate('respond.communication.tab.escalationList.OFF_HOURS'),
      });
    } else if (this.escalationList.type === EscalationContactListType.CUSTOM_BUSINESS_HOURS) {
      this.headerIcons.push({
        path: 'assets/favicons/communication/icon_communication_calendar_business_hours.svg',
        tooltipText: this.i18n.translate('respond.communication.tab.escalationList.BUSINESS_HOURS'),
      });
    }

    // Connectors icon
    if (this.escalationList.datasourceTypes?.length) {
      const connectors = this.escalationList.datasourceTypes
        .map((type) => this.connectors.find((connector) => connector.datasourceType === type))
        .filter((connector) => typeof connector?.icon === "string");

      this.headerIcons.unshift(...connectors.map((connector) => ({
        path: connector.icon,
        tooltipText: connector.displayName,
      })));
    }
  }
}
