<div class="drawer-detail--scrollable visible tab-menu">
  <div class="drawer-detail--content">
    <ng-container *ngIf="!isLoadingConditionFields; else spinner;">
      <div class="top-buttons-container">
        <ng-container *ngIf="!isEditing && !isReadonly && !isCreateMode && !isShowQuery">
          <button
            *display="{ permissions: ['can_update_filter'] }"
            ui-button
            icon="icon_modify"
            [custom-icon]="true"
            [isSvg]="true"
            icon-size="medium"
            color="listActionFlat"
            (click)="toggleEditing(true)"
          >
            {{ 'common.action.edit' | t }}
          </button>
          <button
            *ngIf="currentFilter.monitors.length > 0"
            ui-button
            icon="icon_eye"
            [custom-icon]="true"
            [isSvg]="true"
            icon-size="medium"
            color="listActionFlat"
            (click)="previsualize()"
          >
          {{ 'filters.drawer.tabs.details.previsualize' | t }}
        </button>

        </ng-container>
        <ng-container *ngIf="isShowQuery">
          <button
            ui-button
            icon="icon_back"
            [custom-icon]="true"
            [isSvg]="true"
            icon-size="medium"
            color="listActionFlat"
            (click)="isShowQuery = false"
          >
            {{ 'common.action.return' | t }}
          </button>
        </ng-container>
      </div>

      <ng-container [ngSwitch]="getActiveTemplate()">
        <ng-container *ngSwitchCase="'queryPreview'" [ngTemplateOutlet]="queryPreviewTemplate"></ng-container>
        <ng-container *ngSwitchCase="'filterEditing'" [ngTemplateOutlet]="filterEditingTemplate"></ng-container>
        <ng-container *ngSwitchCase="'filterReadonly'" [ngTemplateOutlet]="filterReadonlyTemplate"></ng-container>
      </ng-container>

    </ng-container>
  </div>
</div>

<!-- Preview-->
<ng-template #queryPreviewTemplate>
  <div style="margin-top: 30px;">
    <ui-title [name]="'filters.drawer.tabs.details.preview.title' | t" [uiTooltipMessage]="'filters.drawer.tabs.details.preview.tooltip' | t"></ui-title>
    <div class="json-content"><pre>{{query}}</pre></div>
  </div>
</ng-template>

<!-- Editable -->
<ng-template #filterEditingTemplate>
  <div class="filter-properties">
    <ui-title [name]="'common.properties' | t"></ui-title>
    <div class="margin--small--top margin--small--bottom">

      <div class="margin--small--bottom">
        <ui-text-field
          name="name"
          control="name"
          maxlength="100"
          [rowsNumber]="1"
          [label]="'filters.drawer.tabs.details.properties.name' | t"
          [placeholder]=""
          [group]="form"
          [description]="'detection.playbook.task.specific.max-char' | t:{number : 100}"
          [required]="true"
        >
        </ui-text-field>
      </div>

      <div class="margin--small--bottom">
        <ui-text-field
          name="description"
          control="description"
          maxlength="1000"
          [label]="'filters.drawer.tabs.details.properties.description' | t"
          [placeholder]=""
          [group]="form"
          [description]="'detection.playbook.task.specific.max-char' | t:{number : 1000}"
          [required]="true"
        >
        </ui-text-field>
      </div>

      <div class="margin--small--bottom" id="edit-filter-checkbox">
        <div>
          <mat-checkbox 
            [checked]="currentFilter.hidden"
            (change)="onCheckClickHiddenFilter($event)"
            [disabled]="false">
          </mat-checkbox>
          <span>{{ 'filter.edit.checkbox.text' | t }}</span>
        </div>
      </div>
    </div>

    <div class="filter-usecases">
      <ui-title [name]="'filters.drawer.tabs.details.usecase.title' | t" [uiTooltipMessage]="(isConsoleAdmin ? 'filters.drawer.tabs.details.usecase.tooltip': 'filters.drawer.tabs.details.usecase.tooltip.client') | t"></ui-title>
      <div class="margin--small--bottom" *ngIf="isMonitorLoaded" style="margin-top: 20px;">
        <autocomplete
          *ngIf="alertingUsecasesList && usecaseAutocompleteOptions"
          [required]="true"
          [placeholder]="'common.search' | t"
          [autocompleteType]="customAutocompleteType"
          [customValues]="usecaseAutocompleteOptions"
          [selectedItems]="autocompleteSelectedUsecases"
          [initItems]="autocompleteSelectedUsecases"
          [showInInputAtInit]="false"
          [canMultiSelect]="true"
          [oneItemAtATime]="false"
          [preserveSearchQuery]="true"
          (onItemSelected)="handleUsecaseSelectEvent($event)"
        ></autocomplete>
        <div
          *ngFor="let usecase of selectedUsecasesToDisplay"
          class="usecase-item-badge"
        >
          <div class="badge-content">
            <div>
              <span>{{usecase.displayValue}}</span>
              <div class="usecase-error" *ngIf="!isMonitorAvailable(usecase.value)"><span>{{ 'filters.drawer.tabs.details.error.no_monitor_available' | t }}</span></div>
            </div>
            <div class="usecase-error" *ngIf="isUsecaseError(usecase.value) && isMonitorAvailable(usecase.value)"><span>{{ 'filters.drawer.tabs.details.error.no_monitor' | t }}</span></div>
            <div *ngIf="isMonitorAvailable(usecase.value)"><label ui-label class="monitor-title">{{ 'filters.drawer.tabs.details.monitors.title' | t }}</label></div>
            <div class="monitor-row" *ngFor="let monitor of usecaseMonitors[usecase.value]?.monitors || []">
              <div><mat-checkbox 
                [checked]="currentFilter.monitors?.includes(monitor.id)"
                (change)="onCheckClick($event, monitor)"
                [disabled]="false"></mat-checkbox></div>
                <div><span style="padding-top: 2px;">{{ monitor.name }}</span></div> <div><ui-tooltip>{{ monitor.description | translatedObject }}</ui-tooltip></div>
            </div>
          </div>
          <ui-icon
            name="close-circle"
            modifier="gray"
            [tooltip]="'common.action.delete'| t"
            (click)="handleUnselectUsecase(usecase.value)"
          ></ui-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="filter-period margin--top">
    <ui-title 
      [name]="'common.period' | t"
      [uiTooltipMessage]="'filters.drawer.tabs.details.periods.tooltip' | t"
    ></ui-title>
    <div class="container-periods">
      <div class="banner banner--warning" *ngIf="displayConditionsAndPeriodWarning">
        <ul>
          <li>{{'filters.drawer.tabs.details.warning.multi.conditions' | t}}</li>
        </ul>
      </div>
      <div class="banner banner--information">
        <ul>
          <li>{{'filters.drawer.tabs.details.periods.details.information.banner.text' | t}}</li>
          <li>{{'filters.drawer.tabs.details.periods.time.zone.information.banner.text' | t}}</li>
        </ul>
      </div>
      <weekly-time-schedule [weeklyTimeSchedule]="filtersSelectedTimePeriod" (timeChange)="timePeriodChanges($event)"></weekly-time-schedule>
    </div>
  </div>

  <div class="filter-conditions margin--top">
    <ui-title
      [name]="'filters.drawer.tabs.details.conditions.title' | t"
      [uiTooltipMessage]="(isConsoleAdmin ? 'filters.drawer.tabs.details.conditions.tooltip' : 'filters.drawer.tabs.details.conditions.tooltip.client') | t"
    ></ui-title>
    <div class="banner banner--warning" *ngIf="displayConditionsAndPeriodWarning">
      <ul>
        <li>{{'filters.drawer.tabs.details.warning.multi.conditions' | t}}</li>
      </ul>
    </div>
    <div class="banner banner--information">
      <ul>
        <li>{{ 'filters.drawer.tabs.details.information.condition.usage' | t}}</li>
      </ul>
    </div>
    <filter-conditions
      *ngIf="conditionFields"
      [filter]="currentFilter"
      [isDisabled]="false"
      [conditionFields]="conditionFields"
      (filterConditionsChange)="filterConditionChanges($event)"
    ></filter-conditions>
  </div>

  <ui-form-actions
    layout="centered"
    class="visible"
  >
    <ng-container>
      <button
        ui-button
        color="standard"
        [disabled]="!isValidData"
        (click)="handleSave()"
      >
        {{ 'common.action.save' | t }}
      </button>
      <button
        ui-button
        color="secondary"
        (click)="cancel()"
      >
        {{ 'common.action.cancel' | t }}
      </button>
    </ng-container>
  </ui-form-actions>
</ng-template>


<!-- Readonly -->
<ng-template #filterReadonlyTemplate>
  <div class="filter-properties margin--top">
    <ui-title [name]="'common.properties' | t"></ui-title>
    <div class="margin--small--top margin--small--bottom">
      <div class="margin--small--bottom">
        <ui-static
          [label]="'filters.drawer.tabs.details.properties.name' | t"
          [value]="filter.name"
        ></ui-static>
      </div>

      <div class="margin--small--bottom">
        <ui-static
          [label]="'filters.drawer.tabs.details.properties.description' | t"
          [value]="filter.description || '-'"
        ></ui-static>
      </div>

      <div class="margin--small--bottom" *ngIf="isConsoleAdmin">
        <label ui-label>{{'filters.drawer.tabs.details.properties.createdBy' | t}}</label>
        <filter-created-by [filter]="filter"></filter-created-by>
      </div>

      <div class="margin--small--bottom">
        <ui-static
          [label]="'filters.drawer.tabs.details.properties.createdAt' | t"
          [value]="filter.createdAt | dt:{withTime:true, locale:i18n.currentLocale}"
        ></ui-static>
      </div>

      <div *ngIf="filter.hidden" class="container-filter-visibility margin--small--bottom" [tooltip]="'filters.table.column.hidden.tooltip' | t">
        <img [src]="'/assets/favicons/icon_eye_off.svg'">
        <span>{{ 'filters.hidden.text' | t }}</span>
      </div>
    </div>
  </div>

  <div class="filter-usecases margin--top" *ngIf="selectedUsecasesToDisplay.length > 0">
    <ui-title
      [name]="'filters.drawer.tabs.details.usecase.title' | t"
      [uiTooltipMessage]="'filters.drawer.tabs.details.usecase.tooltip' | t"
    ></ui-title>
    <div class="margin--small--bottom">
      <div *ngFor="let usecase of selectedUsecasesToDisplay">
        <div><ui-static [value]="usecase.displayValue"></ui-static></div>
        <div *ngIf="isConsoleAdmin">
          <div *ngIf="(usecaseMonitors[usecase.value]?.monitors || []).length"><label ui-label class="monitor-title">{{ 'filters.drawer.tabs.details.monitors.title' | t }}</label></div>
          <div class="monitor-row" *ngFor="let monitor of usecaseMonitors[usecase.value]?.monitors || []">
            <div><ui-static [value]="monitor.name"></ui-static></div> <div><ui-tooltip>{{ monitor.description | translatedObject }}</ui-tooltip></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="filter-period margin--top">
    <ui-title 
      [name]="'common.period' | t"
      [uiTooltipMessage]="'filters.drawer.tabs.details.periods.tooltip' | t"
    ></ui-title>
    <div *ngIf="currentFilter?.periods" class="filters-time-period-container">
      <ui-collapsable-card [cardTitle]="'filters.drawer.tabs.details.periods.read.card.collapse.title' | t" [isCollapsed]="false">
        <weekly-time-schedule [weeklyTimeSchedule]="filtersSelectedTimePeriod" [readOnly]="true"></weekly-time-schedule>
      </ui-collapsable-card>
    </div>
    <ng-container *ngIf="!currentFilter?.periods">
      <p>{{'filters.drawer.tabs.details.periods.without.text' | t}}</p>
    </ng-container>
  </div>

  <div class="filter-conditions margin--top">
    <ui-title
      [name]="'filters.drawer.tabs.details.conditions.title' | t"
      [uiTooltipMessage]="'filters.drawer.tabs.details.conditions.tooltip' | t"
    ></ui-title>
    <filter-conditions
      *ngIf="filter?.conditions?.rules?.length > 0 && conditionFields"
      [filter]="currentFilter"
      [isDisabled]="true"
      [conditionFields]="conditionFields"
    ></filter-conditions>
    <ng-container *ngIf="filter?.conditions?.rules?.length <= 0">
      <p>{{'filters.drawer.tabs.details.conditions.without.text' | t}}</p>
    </ng-container>
  </div>
</ng-template>

<ng-template #spinner>
  <ui-spinner
    class="spinner"
    size="medium"
    modifier="secondary"
  ></ui-spinner>
</ng-template>