<div
  page
  [appPagePaths]="['org', 'roles']"
  [tooltipTitle]="'roles.title.tooltipTitle'"
  [disableOrganizationSelector]="true"
  [searchMode]="searchMode"
  (organizationChanged)="organizationChanged($event)"
  >
  <span
    *ngIf="!organizationId && isVarMode"
    class="no-org-transition"
  >
    {{ 'organizations_select.notSelected.message' | t }}
  </span>
  <ng-container toolbar *ngIf="organizationId || !isVarMode">
    <ui-table-total-tool
      [total]="roles?.items?.length"
      [locale]="locale"
    ></ui-table-total-tool>
    <ui-table-search-tool
      [placeholder]="'iam.roles.list.search.placeholder' | t "
      [query]="searchQuery"
      (clear)="handleSearchClear()"
      (query)="handleSearchQuery($event)"
    >
    </ui-table-search-tool>
    <ui-table-refresh-tool
      [loading]="isLoading"
      (click)="handleRefresh()"
    ></ui-table-refresh-tool>
  </ng-container>
  <ui-card *ngIf="organizationId || !isVarMode">
    <ng-container>
      <ui-static-table
        [columnsDef]="tableDef"
        [dataSource]="roles?.items"
        (on-row-click)="handleRowClick($event)"
        [locale]="locale"
        [loading]="isLoading"
      >
      </ui-static-table>
    </ng-container>
  </ui-card>
</div>