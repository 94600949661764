import { Component, EventEmitter, Input, Output } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IHistory } from 'projects/@common/services/api/respond/histories/histories.definition';
import { HistoriesService } from '../../histories.service';

@Component({
  selector: 'history-table',
  templateUrl: './history-table.component.html',
  styleUrls: [ './history-table.component.scss' ],
})
export class HistoryTableComponent {
  @Input() history: IHistory[];
  @Input() canLoadMore: boolean;
  @Input() isLoading: boolean;
  @Input() columns: string[] = ['createdAt', 'identityName', 'message'];

  @Output() loadMoreEvent = new EventEmitter<void>();

  historyColumnsDef: any[];

  constructor(private readonly historiesService: HistoriesService, private readonly i18n: I18nService) { }

  ngOnInit() {
    this.historyColumnsDef = this.historiesService.getColumnsDefinition().filter((column) => this.columns.includes(column.field));
  }

  get locale(): string {
    return this.i18n.currentLocale;
  }

  loadMoreHistory(): void {
    this.loadMoreEvent.emit();
  }
}
