<ng-container *ngIf="!selectedAction">
  <div class="incident-actions--list self-contained-scrollbar">
    <h4 class="section-bold-title">Actions SOAR executées</h4>
    <section class="incident-actions--executed">
      <span
        *ngIf="!incidentActions.length"
        class="no-items-label"
      >{{"incident-actions.executed.none" | t}}</span>
      <ui-expendable-section
        class="expandable-result"
        *ngFor="let incidentAction of incidentActions;"
        [type]="expendableTypeEnum.COLUMN"
        [forceExpanded]="false"
        [transparentBackground]="true"
      >
        <header
          class="section-title"
          header
        >
          <div class="image-and-title">
            <div class="img-wrapper">
              <img
                src="assets/favicons/toolbox-incidents/icon_actions.svg"
                class="icon-actions"
              >
            </div>
            <div class="img-wrapper">
              <img
              *ngIf="incidentAction.isRedFlagged"
              [src]="'assets/favicons/observables/icon_flag_grey.svg'"
              alt="Red Flag"
              [title]="incidentAction.redFlagTooltipTaskNames || ''"
            >
            </div>
            <span class="title">{{incidentAction.name}}</span>
          </div>
          <div class="date-timestamp">
            <span class="title">{{incidentAction.updatedAt | date:"medium":"":i18n.dateCurrentLocale }}</span>
            <ui-menu-options
              [class.hidden]="isReadonly"
              [menuOptions]="menuOptions"
              (menuOptionClickEvent)="handleMenuOptionClicked($event, incidentAction)"
              (menuOpenedEvent)="handleMenuOpened(incidentAction)"
            ></ui-menu-options>
          </div>
        </header>
        <div class="section-content">

          <!-- Old description content (built in backend) -->
          <div class="result-sanitized-html" [innerHTML]="incidentAction.description"></div>

          <!-- Entities -->
          <div class="incident-actions--form-result-entities-wrapper" *ngIf="incidentAction.entities?.length">
            <respond-entity *ngFor="let entity of incidentAction.entities;" [entity]="entity">
            </respond-entity>
          </div>

          <!-- Observables de l'action -->
          <div class="incident-actions--form-result-observables-wrapper" *ngIf="incidentAction.observables?.length">
            <observable-container class="incident-actions--form-observables" [pageContext]="incidentAction.observablesConfig"
              [useGrouping]="false">
            </observable-container>
          </div>

        </div>
      </ui-expendable-section>
    </section>

    <!-- list available actions -->
    <h4 class="section-bold-title">Actions SOAR disponibles</h4>
    <ui-spinner
      *ngIf="isLoadingActions"
      size="small"
    ></ui-spinner>
    <ng-container *ngIf="!isReadonly">
      <div class="incident-actions--filters">
        <ui-table-total-tool
          [locale]="i18n.currentLocale"
          [total]="isLoadingActions ? '-' : displayedActionsList?.length || 0"
        ></ui-table-total-tool>
        <ui-multi-select
          class="incident-actions--app-selector"
          [multiSelectData]="appFilterOptions"
          (onOptionSelect)="handleApplicationFilterChange($event)"
        ></ui-multi-select>
        <ui-table-search-tool
          class="incident-actions--search-text"
          [placeholder]="'common.search' | t"
          [query]="filters.searchText"
          (query)="handleSearchTextFilterChange($event)"
          (clear)="handleSearchTextFilterChange(null)"
        ></ui-table-search-tool>
      </div>

      <ng-container *ngIf="displayedActionsList?.length > 0; else noActionsTemplate;">
        <div
          *ngFor="let action of displayedActionsList;"
          class="incident-actions--action-card"
          [class.click-execute-not-allowed]="!hasPermissionToExecuteAction"
          (click)="openActionForm(action)"
        >
          <div class="incident-actions--action-card--title">
            <span>{{action.name | translatedObject}}</span>
            <ui-icon
              class="toolbox-expand-chevron"
              modifier="on-header"
              [name]="'chevron-right'"
              size="medium"
            >
            </ui-icon>
          </div>
          <div class="incident-actions--action-card--app-tags-container">
            <span
              class="incident-actions--action-card--app-tag"
              *ngFor="let applicationName of action.applications;"
            >{{applicationName}}
            </span>
          </div>
        </div>
      </ng-container>
      <ng-template #noActionsTemplate>
        <span
          *ngIf="!isLoadingActions"
          style="font-size: 14px;"
        >{{'common.nomatch' | t}}
        </span>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="isReadonly">
      <span style="font-size: 14px;">{{'incident-actions.readonly.no-actions-available' | t}}
      </span>
    </ng-container>
  </div>
</ng-container>

<form
  class="incident-actions--form"
  *ngIf="selectedAction"
>
  <header class="incident-actions--form-header">
    <button
      ui-button
      color="topbar"
      icon="icon_back"
      [custom-icon]="true"
      [isSvg]="true"
      icon-size="medium"
      [tooltip]="'incident-actions.actionForm.back' | t"
      (click)="closeActionForm(null)"
    >
      {{ 'common.action.return' | t }}
    </button>

    <div class="incident-actions--form-header-title">{{selectedAction.name | translatedObject}}</div>
  </header>

  <div class="incident-actions--form-scroll-container self-contained-scrollbar">
    <div class="incident-actions--form-fields">
      <div
        *ngFor="let actionParameter of selectedAction.parameters"
        class="incident-actions--form-field"
      >
        <incident-action-input
          [actionParameter]="actionParameter"
          [isExecuteActionAvailable]="isExecuteActionAvailable"
        ></incident-action-input>
      </div>
    </div>

    <div class="incident-actions--form-buttons">
      <button
        ui-button
        color="standard"
        [disabled]="!isExecuteActionAvailable"
        (click)="executeAction()"
      >
        <ui-spinner
          *ngIf="isExecutingAction"
          size="small"
        ></ui-spinner>
        {{ 'common.action.execute' | t }}
      </button>
      <button
        ui-button
        color="secondary"
        *ngIf="!isExecutingAction && (selectedAction.executionState.text || selectedAction.executionState.resultHtml)"
        (click)="selectedAction.executionState.resultHtml = null"
      >
        {{ 'common.action.modify' | t }}
      </button>
    </div>

    <div class="incident-actions--form-result"
      *ngIf="selectedAction.executionState.text || selectedAction.executionState.resultHtml">

      <div class="incident-actions--form-result-title">
        <span>{{'incident-actions.actionForm.result.title' | t}}</span>
        <ui-tooltip [isSmall]="true">{{'incident-actions.actionForm.result.title.tooltip' | t}}</ui-tooltip>
      </div> <!-- / incident-actions--form-result-title -->

      <!-- Text -->
      <div class="incident-actions--form-result-value-container">

        <div class="incident-actions--form-result-html">

          <div class="result-sanitized-html" [innerHTML]="selectedAction.executionState.resultHtml"></div>

          <!-- Entities -->
          <div class="incident-actions--form-result-entities-wrapper" *ngIf="selectedAction.executionState.entities?.length">
            <respond-entity *ngFor="let entity of selectedAction.executionState.entities;" [entity]="entity"></respond-entity>
          </div>

        </div>

        <div class="incident-actions--form-result-buttons">
          <button
            class="incident-actions--form-result-copy-to-active-task"
            ui-button
            color="listActionFlatGray"
            [custom-icon]="true"
            [custom-icon-width]="'20px'"
            [icon]="'icon_revision'"
            [isSvg]="true"
            [tooltip]="'incident-actions.actionForm.result.copyToTask.tooltip' | t"
            [disabled]="false"
            (click)="copyHtmlResultToActiveTask(selectedAction.executionState.text || selectedAction.executionState.resultHtml)"
          ></button>
          <button
            class="incident-actions--form-result-copy"
            ui-button
            color="listActionFlatGray"
            [icon]="'copy'"
            [tooltip]="('common.action.copy' | t)"
            [disabled]="false"
            (click)="copyHtmlResultToClipboard(selectedAction.executionState.text || selectedAction.executionState.resultHtml)"
          ></button>
        </div>

      </div> <!-- / incident-actions--form-result-value-container -->

      <!-- Observables de l'action -->
      <observable-container *ngIf="selectedAction.executionState.observables?.length"
        class="incident-actions--form-observables" [pageContext]="actionObservables"></observable-container>

    </div> <!-- / incident-actions--form-result -->

  </div>
</form>

<!-- Observables des alertes -->
<observable-container class="incident-actions--form-observables" [pageContext]="configObservables" [assets]="assets"
[assetTypes]="assetTypes"></observable-container>
