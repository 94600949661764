import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { EditWithModalComponent } from '@common/components/edit-with-modal/edit-with-modal.component';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { NoticeService } from '@common/modules/notice/notice.service';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { ResponseUtils } from '@common/utils/response-utils';
import { Autocomplete, AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { GuestAdmin } from '../../services/guest-users.definition';

@Component({
  selector: 'app-guest-user-admins',
  templateUrl: './guest-user-admins.component.html',
  styleUrls: ['./guest-user-admins.component.scss']
})
export class GuestUserAdminsComponent implements OnInit {
  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;
  @ViewChild('autocomplete') autocomplete: Autocomplete;

  @Output() guestAdminActionEvent = new EventEmitter();

  public guestUserAdminsResponse: ResponseUtils<GuestAdmin>;

  public usersAutocomplete = AutocompleteTypes.USERS;
  public adminToAdd: GuestAdmin;
  public dataSource: GuestAdmin[] = [];
  public adminsSelected: string[] = [];

  public isLoading = false;
  public isLoadingMore = false;
  public direction = 'asc';
  public total: number = 0;
  public from: number = 0;
  public fulltext: string = null;
  public sort: string;

  public columnsDef = [
    {
      label: '',
      field: 'selected',
      selected: '__Selected',
      type: UiStaticTableRowType.CHECKBOX,
      width: '50px',
      isResizable: false,
    },
    {
      label: '',
      field: 'avatar',
      type: UiStaticTableRowType.AVATAR,
      withUser: true,
      width: '40px',
      noSorting: true,
      isResizable: false,
    },
    {
      label: this.i18n.translate('groupTemplates.table.name'),
      field: 'displayName',
      type: UiStaticTableRowType.TEXT,
      width: '200px',
    },
    {
      label: this.i18n.translate('email'),
      field: 'mail',
      type: UiStaticTableRowType.TEXT,
      noSorting: true,
      isTruncated: true,
    },
  ];

  constructor(
    private eRef: ElementRef,
    public i18n: I18nService,
    private notice: NoticeService,
    private usersApi: UsersApiService,
  ) { }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKey(event: KeyboardEvent): void {
    this.close();
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  ngOnInit(): void {
    this.fetchGuestUserAdmins();
  }

  public close(): void {
    this.autocomplete.clearSearchQuery();
    this.adminToAdd = null;
    this.editWithModalComponent.close();
  }

  public get canLoadMore(): boolean {
    return (this.guestUserAdminsResponse?.canLoadMore() ?? false) && !this.isLoading;
  }

  public openPopup(): void {
    this.editWithModalComponent.toggle();
  }

  public closePopup(): void {
    this.autocomplete.clearSearchQuery();
    this.adminToAdd = null;
    this.editWithModalComponent.close();
  }

  public usersAutocompleteSearchFunction = (params: { search: string; }): Promise<any> => {
    return this.usersApi.listAvailableGuestUserAdmins({
      fulltext: params.search,
    });
  };

  public onItemSelected(admin: GuestAdmin): void {
    this.adminToAdd = admin;
  }

  public addGuestAdmin(): void {
    this.usersApi.addGuestUserAdmin(this.adminToAdd.o365UserId)
      .subscribe(
        response => {
          this.fetchGuestUserAdmins();
          this.guestAdminActionEvent.emit();
          this.notice.success(this.i18n.translate('guest.users.management.admin-list.add.success'));
        },
        err => {
          this.notice.error(this.i18n.translate('guest.users.management.admin-list.add.error'));
        },
        () => this.closePopup()
      );
  }

  public removeGuestAdmins(): void {
    this.usersApi.removeGuestUserAdmin(this.adminsSelected)
      .subscribe(
        response => {
          this.dataSource = [];
          this.fetchGuestUserAdmins();
          this.guestAdminActionEvent.emit();
          this.notice.success(this.i18n.translate('guest.users.management.admin-list.revoke.success'));
          this.adminsSelected = [];
        },
        err => {
          this.notice.error(this.i18n.translate('guest.users.management.admin-list.revoke.error'));
        },
        () => this.closePopup()
      );
  }

  public handleSelectedAdmins(isChecked: boolean, guestAdmin: GuestAdmin): void {
    if (isChecked) {
      this.adminsSelected.push(guestAdmin.id);
    } else {
      this.adminsSelected = this.adminsSelected.filter(userId => userId !== guestAdmin.id);
    }
  }

  public handleRefresh(): void {
    this.total = 0;
    this.from = 0;
    this.dataSource = [];
    this.fetchGuestUserAdmins();
  }

  public handleTextSearch(query: string): void {
    this.total = 0;
    this.fulltext = query;
    this.dataSource = [];
    this.fetchGuestUserAdmins();
  }

  public handleSorting(sort: string, direction: string): void {
    this.sort = sort;
    this.direction = direction;
    this.dataSource = [];
    this.fetchGuestUserAdmins();
  }

  public clearTextSearch(): void {
    this.total = 0;
    this.fulltext = null;
    this.fetchGuestUserAdmins();
  }

  public fetchGuestUserAdmins(loadMore: boolean = false): void {
    this.adminsSelected = [];
    const setLoading = (loadingState: boolean) => {
      loadMore ? this.isLoadingMore = loadingState : this.isLoading = loadingState;
    };

    const requestParams = {
      fulltext: this.fulltext,
      from: loadMore ? this.guestUserAdminsResponse.getNextItem() : this.from,
      limit: 25,
      direction: this.direction,
      sort: this.sort,
    };

    setLoading(true);
    this.usersApi.listGuestUserAdmins(requestParams)
      .subscribe(
        res => {
          this.guestUserAdminsResponse = res;
          if (loadMore) {
            this.dataSource = this.dataSource.concat(this.guestUserAdminsResponse.getItems());
          } else {
            this.dataSource = this.guestUserAdminsResponse.getItems();
            this.total = this.guestUserAdminsResponse.getTotalItems();
          }
        },
        err => {
          this.notice.error(this.i18n.translate('guest.users.management.admin-list.500.error'));
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
  }
}

