import { Component, Input, OnInit } from '@angular/core';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { FiltersApi } from 'projects/@common/services/api/respond/filters/filters.api';
import { FilterType, IFilter, IFilterBaseRepresentation } from 'projects/@common/services/api/respond/filters/filters.definitions';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';
import { IUsecase } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { ResponseUtils } from 'projects/@common/utils/response-utils';
import { IFilterDrawerData, RespondFilterDrawerContainer } from 'projects/@respond/filters/containers/respond-filter-drawer/respond-filter-drawer.container';
import { FiltersHiddenColumnIndicatorComponent } from 'projects/console-adm/src/app/respond/filters/filters-hidden-column-indicator/filters-hidden-column-indicator.component';

@Component({
  selector: 'app-usecase-filters-list',
  templateUrl: './usecase-filters-list.component.html',
  styleUrls: [ './usecase-filters-list.component.scss' ],
})
export class UsecaseFiltersListComponent implements OnInit {
  public readonly usecaseFiltersTableColumnsDefinition;

  @Input() usecase: IUsecase;
  public usecaseFilters: Filter[] = [];
  public isLoading: boolean = false;
  public lastFilterRequest: ResponseUtils<IFilter>;

  constructor(
    public readonly i18n: I18nService,
    private readonly filterService: FiltersApi,
    private readonly drawerService: DrawerService
  ) {
    this.usecaseFiltersTableColumnsDefinition = [
      {
        label: '',
        field: 'hidden',
        type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
        component: FiltersHiddenColumnIndicatorComponent,
        paramName: 'filter',
        width: '25px',
        isResizable: false,
        noSorting: true,
      },
      {
        label: this.i18n.translate('usecase.drawer.tab.details.name'),
        field: 'name',
        type: UiStaticTableRowType.TEXT,
        isResizable: true,
      },
      {
        label: this.i18n.translate('filters.drawer.tabs.details.properties.description'),
        field: 'description',
        type: UiStaticTableRowType.TEXT,
        isResizable: true,
      },
    ];
  }

  ngOnInit(): void {
    this.fetchFilterData();
  }

  public fetchFilterData(): void {
    if (!this.isLoading) {
      const request = {
        usecaseId: this.usecase.id,
      };
      this.isLoading = true;
      this.filterService.listFilters(this.usecase.organizationId, request).toPromise()
        .then((response: ResponseUtils<IFilterBaseRepresentation>) => {
          const filters: IFilter[] = response.getItems().map((representation: IFilterBaseRepresentation) => ({
            ...representation,
            filterType: FilterType.ECS,
          }));
          const filterResponse: ResponseUtils<IFilter> = new ResponseUtils({
            items: filters,
            total: response.getTotalItems(),
            metadata: response.getMetadata(),
            nextItem: response.getNextItem(),
          });
          this.lastFilterRequest = filterResponse;
          this.usecaseFilters = this.usecaseFilters.concat(filterResponse.getItems().map((filter: IFilter) => new Filter(filter.filterType, filter)));
        })
        .finally(() => this.isLoading = false);
    }
  }

  public navigateToFilters(filter: Filter): void {
    const drawerInputData: IFilterDrawerData = {
      title: filter ? filter.name : this.i18n.translate(`filters.drawer.title.create`),
      filterId: filter ? filter.id : null,
      organizationId: filter.organizationId,
      isReadonly: false,
      filterType: filter.filterType,
    };
    this.drawerService.showDrawer(RespondFilterDrawerContainer, {
      component: DrawerPageEnum.filterDrawer,
      data: drawerInputData,
    });
  }
}
