<ui-tabs
  #tabs
  color="drawerTabs"
>
  <ui-tab [tabTitle]="'parameter.details.title' | t">
    <actif-dynamic-component
      *ngIf="!isLoading; else spinner"
      [currentAsset]="currentAsset"
      [isReadonly]="data.isReadonly"
      [assetTypes]="assetTypes"
      [organizationId]="data.organizationId"
    >
    </actif-dynamic-component>
  </ui-tab>
  <ui-tab *ngIf="data.assetId" [tabTitle]="'parameter.details.history' | t">
    <app-actifs-history
      [currentAssetId]="currentAsset?.asset?.id"
      [organizationId]="data.organizationId">
    </app-actifs-history>
  </ui-tab>
</ui-tabs>

<ng-template #spinner>
  <div class="spinner">
    <ui-spinner
      size="medium"
      modifier="secondary"
    ></ui-spinner>
  </div>
</ng-template>