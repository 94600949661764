import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiKitModule } from "@ui-kit/uikit.module";
import { I18nModule } from "projects/@common/modules/i18n/i18n.module";
import { OrgRespondRoutingModule } from "./org.respond-routing.module";


@NgModule({
  imports: [
    CommonModule,
    UiKitModule,
    I18nModule,
    OrgRespondRoutingModule,
  ],
  declarations: [
  ],
})
export class OrgRespondModule {
}
