import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DynamicFormField } from "projects/@common/components/dynamic-form";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { IDescribeAssetResponse, IListAssetType, PropertyType } from "projects/@common/services/api/respond/actifs/actifs.definitions";
import { Actif, IAssetDynamicFormField } from "projects/@common/services/api/respond/actifs/models/IActif";

export class AssetDynamicFieldHandler {
  private actif: Actif;
  public form: UntypedFormGroup;

  public assetDynamicFormFields: IAssetDynamicFormField[];

  private controlMatcher: { [key: string]: DynamicFormField; } = {};

  constructor (private formBuilder: UntypedFormBuilder, private i18nService: I18nService) { }

  public createDynamicForm(asset: IListAssetType): IAssetDynamicFormField[] {
    this.actif = new Actif(asset.id, asset.type, asset.config);
    this.assetDynamicFormFields = this.actif.getFormProperty(this.i18nService.currentLocale);

    return this.assetDynamicFormFields;
  }

  public createForm(assetToPatch?: IDescribeAssetResponse): UntypedFormGroup {
    this.form = this.formBuilder.group({});
    this.assetDynamicFormFields.forEach((field) => {
      this.form.addControl(field.id, new UntypedFormArray([]));
      assetToPatch
        ? this.pushFormAndPatch(assetToPatch, field)
        : this.pushForm(field);

      this.controlMatcher[field.id] = field.dynamicForm;
    });

    return this.form;
  }

  public getNewFieldControl(id: string): UntypedFormControl {
    return new UntypedFormControl(null, this.controlMatcher[id].options.customValidators);
  }

  private pushFormAndPatch(assetToPatch: IDescribeAssetResponse, field: IAssetDynamicFormField): void {
    const values = assetToPatch.properties.find((toFind) => toFind.propertyId === field.id).values;

    values
      .forEach((value) => {
        (this.form.get(field.id) as UntypedFormArray).push(new UntypedFormControl(value, field.dynamicForm.options.customValidators));
      });

    if (!values.length) {
      this.pushForm(field);
    }
  }

  private pushForm(field: IAssetDynamicFormField) {
    (this.form.get(field.id) as UntypedFormArray).push(new UntypedFormControl(null, field.dynamicForm.options.customValidators));
  }
}
