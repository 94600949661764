import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { CommunicationMethodTypeEnum, IContactCommunicationMethod, IMethodEmail, IMethodOther, IMethodPhone, IMethodSMS } from "projects/@common/services/api/respond/communication/communication.definitions";

export type MethodSelectionOption = {
  value: CommunicationMethodTypeEnum;
  displayValue: string;
  icon: string;
};

export function getCommunicationMethodIconSrc(type: CommunicationMethodTypeEnum): string {
  switch (type) {
    case CommunicationMethodTypeEnum.EMAIL:
      return 'assets/favicons/communication/icon_communication_email.svg';
    case CommunicationMethodTypeEnum.SMS:
      return 'assets/favicons/communication/icon_communication_sms.svg';
    case CommunicationMethodTypeEnum.PHONE:
      return 'assets/favicons/communication/icon_communication_phone.svg';
    case CommunicationMethodTypeEnum.OPSGENIE:
      return 'assets/favicons/communication/icon_communication_opsGenie.svg';
    case CommunicationMethodTypeEnum.OTHER:
      return 'assets/favicons/communication/icon_communication_other.svg';
    default:
      return "";
  }
}

export function getCommunicationMethodReadonlyValue(communicationMethod: IContactCommunicationMethod, i18n: I18nService): string {
  if (communicationMethod.type === CommunicationMethodTypeEnum.EMAIL) {
    const props = communicationMethod.properties as IMethodEmail;
    return props.email;
  } else if (communicationMethod.type === CommunicationMethodTypeEnum.OPSGENIE) {
    // const props = communicationMethod.properties as IMethodOpsGenie;
    return "";
  } else if (communicationMethod.type === CommunicationMethodTypeEnum.OTHER) {
    const props = communicationMethod.properties as IMethodOther;
    return props.note;
  } else if (communicationMethod.type === CommunicationMethodTypeEnum.SMS) {
    const props = communicationMethod.properties as IMethodSMS;
    return props.phoneNumber;
  } else if (communicationMethod.type === CommunicationMethodTypeEnum.PHONE) {
    const props = communicationMethod.properties as IMethodPhone;
    const phoneNumber = props.phoneNumber;
    const phoneExtension = props.ext;
    const translatedExtension = phoneExtension
      ? `, ${i18n.translate(`respond.communication.tab.contacts.communicationMethods.type.${communicationMethod.type}.phoneExtension`)} ${phoneExtension}`
      : "";
    return `${phoneNumber}${translatedExtension}`;
  }
  return "";
}

export function getCommunicationMethodOptions(i18n: I18nService): MethodSelectionOption[] {
  return Object.values(CommunicationMethodTypeEnum).map((methodTypeEnum) => ({
    value: methodTypeEnum,
    displayValue: getCommunicationMethodTranslation(methodTypeEnum, i18n),
    icon: getCommunicationMethodIconSrc(methodTypeEnum),
  }));
}

export function mapCommunicationMethodsTranslations(communicationMethods: IContactCommunicationMethod[], i18n: I18nService): IContactCommunicationMethod[] {
  return communicationMethods.map((method) => ({
    ...method,
    translation: getCommunicationMethodTranslation(method.type, i18n),
    value: getCommunicationMethodReadonlyValue(method, i18n),
  }));
}

function getCommunicationMethodTranslation(methodTypeEnum: CommunicationMethodTypeEnum, i18n: I18nService): string {
  return i18n.translate(`respond.communication.tab.contacts.communicationMethods.type.${methodTypeEnum}`);
}
