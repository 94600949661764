import { Component, Input, OnInit } from '@angular/core';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';

@Component({
  selector: 'admin-top10-resource-row',
  templateUrl: './admin-top10-resource-row.component.html',
  styleUrls: [ './admin-top10-resource-row.component.scss' ],
})
export class AdminTop10ResourceRowComponent implements OnInit {
  @Input() public resource;

  public locale: LanguageEnum;

  constructor () { }

  ngOnInit(): void {
  }
}
