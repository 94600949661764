<div class="section-container">
  <ui-card>
    <ui-static-table
      [loading]="isLoading"
      [dataSource]="assetHistoryTableData"
      [addPaddingOnFirst]="false"
      [columnsDef]="assetObservableColumnDefinition"
      [expendedTemplate]="history"
      [locale]="i18n.currentLocale"
    >
    </ui-static-table>
  </ui-card>
  <div
    class="button-container"
    *ngIf="canLoadMore"
  >
    <ui-table-refresh-tool
      class="refresher"
      [loading]="isLoadingMore"
      [text]="'common.showMore' | t"
      rightSide
      (click)="loadMore()"
    ></ui-table-refresh-tool>
  </div>
</div>



<ng-template
  #history
  let-assetHistory="row"
  let-isExpanded="isSelect"
>
  <app-history-detail
    *ngIf="isExpanded"
    [history]="assetHistory"
  ></app-history-detail>
</ng-template>