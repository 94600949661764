import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';

@Component({
  selector: 'admin-top10-resourceNoCompliant',
  templateUrl: './admin-top10-resourceNoCompliant.component.html',
  styleUrls: [ './admin-top10-resourceNoCompliant.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class AdminTop10ResourceNoCompliant implements OnInit {
  public resources = [];

  public isLoading = false;

  public locale: LanguageEnum;

  public columnsDef = [];

  constructor(
    private groupsApiService: GroupsApiService,
    private readonly i18n: I18nService,
    private staticTableDataMapper: StaticTableDataMapper
  ) {}

  ngOnInit(): void {
    this.locale = this.i18n.currentLocale as LanguageEnum;
    this.loadLibraries();
  }

  private loadLibraries(): void {
    this.isLoading = true;

    this.groupsApiService.listOrgTop10riskyUsers()
      .then((resources) => {
        this.resourcesMapper(resources.users);
        this.resources = this.resources.slice(0, 10);
      })
      .finally(() => {
        [ this.columnsDef, this.resources ] = this.staticTableDataMapper.translate(this.columnsDef, this.resources);
        this.isLoading = false;
      });
  }

  private resourcesMapper(resources: any): void {
    const resourcesWithStats = resources.map((item) => ({
      name: item.user.displayName,
      owner: item.user,
      amount: item.riskAmount,
    }));
    this.resources = this.resources.concat(resourcesWithStats);
  }
}
