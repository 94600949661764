import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Accumulatables, Accumulate } from 'projects/@common/modules/accumulator/accumulator.store';
import { MonitorsApiService } from 'projects/@common/services/api/respond/usecase/monitors.api';
import { Observable } from 'rxjs';
import { IMonitorToDisplay } from '../usecase-creation-detail/usecase-creation-detail.component';
import { UsecaseUpdateForm } from '../usecase-details/usecase-update-form';
import { Eco } from 'projects/@common/definitions/eco';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { DrawerState, SetDrawerIsEditing } from 'projects/@common/modules/layout/components/drawer/stores/drawer.store';
import { NoticeService, Notice, NoticeLevels } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { IMonitor, IUsecase } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { UiTypeOfFormEnum } from 'projects/console-adm/src/app/shared/dynamic-form-template/dynamic-form-template.component';
import { TextareaTypeEnum } from '@ui-kit/components/ui-json-textarea/ui-json-textarea.component';
import { UiToggle } from '@ui-kit/components/ui-toggle/ui-toggle.component';

@Component({
  selector: 'usecase-monitors',
  templateUrl: './usecase-monitors.component.html',
  styleUrls: [ './usecase-monitors.component.scss' ],
})
export class UsecaseMonitorsComponent implements OnInit {
  public static readonly COMPONENT = 'usecaseMonitorsComponent';
  public textareaTypeEnum = TextareaTypeEnum;
  public isEditing = false;
  public monitorsTemplate: IMonitor[];
  public monitorFormTemplateList = [];
  public noMonitorAvailable = false;
  public withMonitorParam: boolean;
  public organizationId: string;

  public isEditingAlertingMode = false;
  public isEditingBlacklist = false;

  private editingMonitor: IMonitor = null;
  private editingMonitorComponent: UiToggle = null;

  @ViewChildren('alertingModeToggle') private alertingModeToggleComponents: QueryList<UiToggle>;
  @ViewChildren('blacklistToggle') private blacklistToggleComponents: QueryList<UiToggle>;

  @Input() currentUsecase: IUsecase;
  @Input() usecaseMonitors: IMonitor[];
  @Input() usecaseUpdateForm: UsecaseUpdateForm;

  @Select(EcoSessionState.organization) public organization$: Observable<Eco.IOrganization>;


  constructor(
    private drawerService: DrawerService,
    private usecasesApiService: UsecasesApiService,
    private store: Store,
    private noticeService: NoticeService,
    private monitorApiService: MonitorsApiService
  ) {}
  ngOnInit(): void {
    this.organization$.subscribe((value) => this.organizationId = value.id);
    this.fetchUsecaseCatalog();
    this.store.select(DrawerState.isEditing).subscribe((isEditing) => {
      this.isEditing = isEditing;
    });
  }

  public save(): void {
    this.usecasesApiService
      .updateUsecaseParameters(this.currentUsecase.organizationId, this.usecaseUpdateForm.monitorsJson)
      .then(() => {
        this.handleUpdateSuccess("org_usecase.description_drawer.tab.monitors.updated_success");
        this.drawerService.previousDrawer();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  cancel(): void {
    this.toggleAction();
    this.monitorFormTemplateList = [];
    this.ngOnInit();
  }

  public fetchUsecaseCatalog(): void {
    if (this.currentUsecase.templateId) {
      this.usecasesApiService.getUsecaseCatalog(this.currentUsecase.templateId).then((response) => {
        if (response.monitors[this.currentUsecase.datasourceType]) {
          this.monitorsTemplate = response.monitors[this.currentUsecase.datasourceType];
          this.withMonitorParam = !!this.monitorsTemplate.find((monitor) => monitor.parameters && monitor?.parameters?.length > 0);
          this.buildMonitorForm();
          this.patchMonitor();
        } else {
          this.noMonitorAvailable = true;
        }
      });
    } else {
      this.noMonitorAvailable = true;
    }
  }

  public toggleAction(): void {
    this.isEditing = !this.isEditing;

    this.store.dispatch(new SetDrawerIsEditing({
      isEditing: this.isEditing,
      tabName: UsecaseMonitorsComponent.COMPONENT,
    }));
  }

  public async onEditingAlertingModeDialogClick(event: 'action'|'cancel'): Promise<void> {
    if (event === 'action') {
      await this.editMonitorAlertingMode(this.editingMonitor);
    }

    this.editingMonitor = null;
    this.isEditingAlertingMode = false;
    this.usecaseUpdateForm.clearAlertingModeComment();
  }

  public async onEditingBlacklistedDialogClick(event: 'action'|'cancel'): Promise<void> {
    if (event === 'action') {
      await this.editMonitorBlacklist(this.editingMonitor);
    }

    this.editingMonitor = null;
    this.isEditingBlacklist = false;
    this.usecaseUpdateForm.clearBlacklistedComment();
  }

  public updateOutdatedUsecase(): void {
    this.usecasesApiService
      .updateOutdatedUsecase(this.usecaseUpdateForm.usecaseJson)
      .then((response) => {
        this.currentUsecase = response;
        this.noticeService.notifyUser(new Notice('org_usecase.description_drawer.tab.monitors.updated_success', NoticeLevels.SUCCESS));
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
      })
      .catch((error) => {
        const message = Array.isArray(error.response.data.message)
          ? `${error.response.data.error}: ${error.response.data.message[0]}`
          : error.response.data.message;
        this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
      })
      .finally(() => {
        if (this.drawerService.component) {
          this.drawerService.component.ngOnInit();
        }
      });
  }

  public async updateMonitorBlacklistState(index): Promise<void> {
    const name = this.usecaseUpdateForm.monitorParametersFormArray.at(index).get('monitor').value;
    const find = this.usecaseMonitors.find((params) => params.name === name);

    this.editingMonitorComponent = this.blacklistToggleComponents['_results'][index];

    if (!find.blacklisted) {
      this.editingMonitor = find;
      this.isEditingBlacklist = true;
    } else {
      this.usecaseUpdateForm.monitorParametersFormArray.at(index).get('blacklisted').setValue(!find.blacklisted);
      await this.editMonitorBlacklist(find);
    }
  }

  public async updateMonitorSilencedState(index): Promise<void> {
    const name = this.usecaseUpdateForm.monitorParametersFormArray.at(index).get('monitor').value;
    const find = this.usecaseMonitors.find((params) => params.name === name);

    this.editingMonitorComponent = this.alertingModeToggleComponents['_results'][index];

    if (!find.silenced) {
      this.editingMonitor = find;
      this.isEditingAlertingMode = true;
    } else {
      this.usecaseUpdateForm.monitorParametersFormArray.at(index).get('silenced').setValue(!find.silenced);
      await this.editMonitorAlertingMode(find);
    }
  }

  private async editMonitorAlertingMode(monitor: IMonitor): Promise<void> {
    monitor.silenced = !monitor.silenced;
    try {
      // Changing the toggle status manually
      this.editingMonitorComponent.toggleState(monitor.silenced);
      this.editingMonitorComponent = null;
      await this.monitorApiService.updateMonitor(this.currentUsecase.organizationId, monitor.id, {
        silenced: monitor.silenced,
        silencedComment: this.usecaseUpdateForm.alertingModeComment,
        usecaseId: this.currentUsecase.id,
      });
      this.handleUpdateSuccess('org_usecase.details_drawer.monitors_tab.blacklist.updated_success');
    } catch (error) {
      this.handleError(error);
    }
  }

  private async editMonitorBlacklist(monitor: IMonitor): Promise<void> {
    monitor.blacklisted = !monitor.blacklisted;
    try {
      // Changing the toggle status manually
      this.editingMonitorComponent.toggleState(monitor.blacklisted);
      this.editingMonitorComponent = null;
      await this.monitorApiService.updateMonitor(this.currentUsecase.organizationId, monitor.id, {
        blacklisted: monitor.blacklisted,
        blacklistedComment: this.usecaseUpdateForm.blacklistedComment,
        usecaseId: this.currentUsecase.id,
      });
      this.handleUpdateSuccess('org_usecase.details_drawer.monitors_tab.blacklist.updated_success');
    } catch (error) {
      this.handleError(error);
    }
  }

  public isCurrentUsecaseOutdated(): boolean {
    return this.currentUsecase.isOutdated;
  }

  private monitorFormMapper(monitor: IMonitor, monitorIndex: number): IMonitorToDisplay {
    const usecaseMonitor = this.usecaseMonitors.find((params) => params.name === monitor.name);
    return {
      name: monitor.name,
      silenced: !!usecaseMonitor?.silenced,
      blacklisted: !!usecaseMonitor?.blacklisted,
      description: monitor.description,
      parameters: monitor?.parameters?.map((parameter, parameterIndex) => {
        const paramFromControl = (
          this.usecaseUpdateForm.monitorParametersFormArray.at(monitorIndex).get('parameters') as UntypedFormArray
        ).at(parameterIndex);

        if (parameter.default) {
          paramFromControl.get('value').setValue(this.setTypeOfForm(parameter.type) === UiTypeOfFormEnum.OBJECT_TEXTAREA ? JSON.stringify(parameter.default) : parameter.default);
        }

        return {
          label: { fr: parameter.name, en: parameter.name },
          description: parameter.description,
          required: !parameter.default,
          typeOfForm: this.setTypeOfForm(parameter.type),
          controlName: 'value',
          group: paramFromControl,
        };
      }),
    };
  }

  private setTypeOfForm(type: string): UiTypeOfFormEnum {
    switch (type) {
      case 'object':
        return UiTypeOfFormEnum.OBJECT_TEXTAREA;
      case 'string':
        return UiTypeOfFormEnum.TEXT_INPUT;
      case 'number':
        return UiTypeOfFormEnum.NUMBER_INPUT;
      default:
        return null;
    }
  }

  private patchMonitor(): void {
    this.usecaseUpdateForm.monitorParametersFormArray.value.forEach((element, index) => {
      const find = this.usecaseMonitors.find((params) => params.name === element.monitor);

      this.usecaseUpdateForm.monitorParametersFormArray.at(index).get('blacklisted').setValue(find.blacklisted);

      if (find && find.parameters) {
        this.usecaseUpdateForm.patchMonitorParameters(find, index);
      }
    });
  }

  private buildMonitorForm(): void {
    this.monitorsTemplate.forEach((monitorTemplate, index) => {
      this.usecaseUpdateForm.monitorParametersFormArray.push(this.usecaseUpdateForm.getMonitorControl(monitorTemplate));
      this.monitorFormTemplateList.push(this.monitorFormMapper(monitorTemplate, index));
    });
  }

  private handleError(error): void {
    const message = Array.isArray(error.response.data.message)
      ? `${error.response.data.error}: ${error.response.data.message[0]}`
      : error.response.data.message;
    this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
  }

  private handleUpdateSuccess(successTranslationKey): void {
    this.noticeService.notifyUser(new Notice(successTranslationKey, NoticeLevels.SUCCESS));
    this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
  }
}
