import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTimePeriod } from '@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component';
import { ListOwnersResponse } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { getDefaultIncidentsDateFrom } from "projects/@common/services/api/respond/incidents/models/getIncidentsRequest";
import { HistoriesService } from '../../histories.service';
import { mapArrayToObject, pruneObject } from 'projects/@common/utils/utils';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { MultiSelectDataFactory } from '@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { ManagedOrganization } from '@common/services/api/detect/organizations/connectors-api-organizations';

export interface ITimeInterval {
  dateFrom?: number;
  dateTo?: number;
  useUpdatedAtDate?: boolean;
}

export enum HistoriesFilterName {
  date = 'date',
  owners = 'owners',
  types = 'types',
  organizations = 'organizations',
  indicators = 'indicators'
}

export enum HistoryTypeEnum {
  ALERT = 'ALERT',
  INCIDENT = 'INCIDENT',
  WHITELIST = 'WHITELIST',
  ALERTING_USECASE = 'ALERTING_USECASE',
  FILTER = 'FILTER',
  PLAYBOOK = 'PLAYBOOK',
  ASSET = 'ASSET',
  CONTACT = 'CONTACT',
  ESCALATION_CONTACT_LIST = 'ESCALATION_CONTACT_LIST',
  ESCALATION_PARAMETER = 'ESCALATION_PARAMETER',
  SOAR_CONNECTOR = 'SOAR_CONNECTOR',
  DATA_CONNECTOR = 'DATA_CONNECTOR',
  SECRET = 'SECRET'
}

export enum HistoryIndicatorsEnum {
  WRITE = 'WRITE',
  VAR = 'VAR',
  CLIENT = 'CLIENT',
  SYSTEM = 'SYSTEM'
}

@Injectable()
export class HistoriesFiltersService {
  private _dateFilter: ITimeInterval = null;
  private _ownerFilter: string[] = null;
  private _typeFilter: string[] = null;
  private _indicatorsFilter: string[] = null;
  private _organizationFilter: string[] = null;
  private _ownerValues: MultiSelectData  = new MultiSelectData();
  private _typeValues: MultiSelectData  = new MultiSelectData();
  private _organizationValues: MultiSelectData  = new MultiSelectData();
  private _indicatorsValues: MultiSelectData  = new MultiSelectData();

  private _debounceTimeout: NodeJS.Timeout;

  private _activeFilterList = {
    [HistoriesFilterName.date]: false,
    [HistoriesFilterName.owners]: false,
    [HistoriesFilterName.types]: false,
    [HistoriesFilterName.organizations]: false,
    [HistoriesFilterName.indicators]: false,
  };

  constructor(
    private readonly multiSelectFactory: MultiSelectDataFactory,
    private readonly activatedRoute: ActivatedRoute,
    private readonly historiesService: HistoriesService,
    private readonly router: Router
  ) { }

  public initializeHistoriesFilters(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams) {
      this._dateFilter = {
        dateFrom: queryParams.dateFrom ? queryParams.dateFrom : getDefaultIncidentsDateFrom(),
        dateTo: queryParams.dateTo ? queryParams.dateTo : null,
        useUpdatedAtDate: queryParams.useUpdatedAtDate ? queryParams.useUpdatedAtDate : null,
      };
      this._ownerFilter = queryParams.owners ? queryParams.owners.split(',') : null;
      this._typeFilter = queryParams.types ? queryParams.types.split(',') : null;
      this._indicatorsFilter = queryParams.indicators ? queryParams.indicators.split(',') : null;
      this._organizationFilter = queryParams.organizationIds ? queryParams.organizationIds.split(',') : null;

      this.updateActiveFilters();
      this.initializeMultiSelects();
    }
  }

  public initOwnersMultiSelect(owners: ListOwnersResponse[]): void {
    this._ownerFilter = this._ownerFilter ? this._ownerFilter.filter((owner) => owners.some((item) => item.ownerId === owner)) : [];
    this._ownerValues = this.multiSelectFactory.create(mapArrayToObject(owners, 'ownerName', 'ownerId'), this.ensureArray(this._ownerFilter), "", true);
  }

  public initOrganizationsMultiSelect(organizations: ManagedOrganization[]): void {
    this._organizationValues = this.multiSelectFactory.create(mapArrayToObject(organizations, 'name', 'organization_respond_id'), this.ensureArray(this._organizationFilter), "", true);
  }

  private initializeMultiSelects(): void {
    this._typeValues = this.multiSelectFactory.create(HistoryTypeEnum, this.ensureArray(this._typeFilter), 'histories.container.page.filter.types.', true, true);
    this._indicatorsValues = this.multiSelectFactory.create(HistoryIndicatorsEnum, this.ensureArray(this._indicatorsFilter), 'histories.container.page.filter.indicators.', true, true);
  }

  // values
  public get dateFilter(): ITimeInterval {
    return this._dateFilter;
  }

  public get ownerValues(): MultiSelectData {
    return this._ownerValues;
  }

  public get typeValues(): MultiSelectData {
    return this._typeValues;
  }

  public get organizationValues(): MultiSelectData {
    return this._organizationValues;
  }

  public get indicatorsValues(): MultiSelectData {
    return this._indicatorsValues;
  }

  // Filter applying

  public applyDateFilter(event: DateTimePeriod): void {
    this._dateFilter = {
      ...this._dateFilter,
      dateFrom: event?.dateFrom || null,
      dateTo: event?.dateTo || null,
    };
    this.emitNewQueries();
  }

  public applyOwnerFilter(event: string[]): void {
    if (event === null || event.length === 0) {
      this.clearOwnerFilter();
    } else {
      this._ownerFilter = event;
      this.emitNewQueries();
    }
  }

  public applyTypeFilter(event: string[]): void {
    if (event === null || event.length === 0) {
      this.clearTypeFilter();
    } else {
      this._typeFilter = event;
      this.emitNewQueries();
    }
  }

  public applyIndicatorsFilter(event: string[]): void {
    if (event === null || event.length === 0) {
      this.clearIndicatorsFilter();
    } else {
      this._indicatorsFilter = event;
      this.emitNewQueries();
    }
  }

  public applyOrganizationFilter(event: string[]): void {
    if (event === null || event.length === 0) {
      this.clearOrganizationFilter();
    } else {
      this._organizationFilter = event;
      this.emitNewQueries();
    }
  }

  public clearDateFilter(): void {
    this._dateFilter = null;
    this.emitNewQueries();
  }

  public clearOwnerFilter(): void {
    this._ownerFilter = [];
    this.emitNewQueries();
  }

  public clearTypeFilter(): void {
    this._typeFilter = [];
    this.emitNewQueries();
  }

  public clearIndicatorsFilter(): void {
    this._indicatorsFilter = [];
    this.emitNewQueries();
  }

  public clearOrganizationFilter(): void {
    this._organizationFilter = null;
    this.emitNewQueries();
  }

  public emitNewQueries(): void {
    clearTimeout(this._debounceTimeout);
    this.updateActiveFilters();
    this.applyFiltersInQueryParams();
    this._debounceTimeout = setTimeout(() =>
      this.historiesService.applyFilter(this._dateFilter?.dateFrom, this._dateFilter?.dateTo, this._ownerFilter, this._typeFilter, this._organizationFilter, this._indicatorsFilter), 300);
  }

  public get nbFilterApplied(): number {
    return Object.values(this._activeFilterList).filter((value) => value).length;
  }

  public clearAllFilters(): void {
    this._dateFilter = null;
    this._ownerFilter = null;
    this._typeFilter = null;
    this._organizationFilter = null;
    this._indicatorsFilter = null;

    this.updateActiveFilters();
    this.applyFiltersInQueryParams();
    this.emitNewQueries();
  }

  public applyFiltersInQueryParams(): void {
    const request = {
      owners: this._ownerFilter ? this._ownerFilter.join(',') : null,
      types: this._typeFilter ? this._typeFilter.join(',') : null,
      indicators: this._indicatorsFilter ? this._indicatorsFilter.join(',') : null,
      organizationIds: this._organizationFilter ? this._organizationFilter.join(',') : null,
      dateFrom: this._dateFilter?.dateFrom,
      dateTo: this._dateFilter?.dateTo,
    };
    const params = pruneObject(request);
    // the incident-list component is subscribed to activatedRoute.queryParams and will fetch the data when the params change
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params, replaceUrl: true });
  }

  private updateActiveFilters(): void {
    this._activeFilterList = {
      [HistoriesFilterName.date]: Boolean((this._dateFilter?.dateFrom || this._dateFilter?.dateTo)),
      [HistoriesFilterName.owners]: this._ownerFilter?.length > 0,
      [HistoriesFilterName.types]: this._typeFilter?.length > 0,
      [HistoriesFilterName.organizations]: this._organizationFilter?.length > 0,
      [HistoriesFilterName.indicators]: this._indicatorsFilter?.length > 0,
    };
  }

  private ensureArray(value: any) {
    if (!value) {
      return [];
    }
    if (value && !Array.isArray(value)) {
      return [ value ];
    }
    return value;
  }
}
