<div class="drawer-detail--scrollable visible">
  <div class="drawer-detail--content">
    <div class="horizontal-div">
      <div>
        <button
          *ngIf="!isEditing"
          ui-button
          icon="icon_modify"
          [custom-icon]="true"
          [isSvg]="true"
          icon-size="medium"
          color="listActionFlat"
          (click)="toggleAction()"
        >
          {{ 'common.action.edit' | t}}
        </button>
        <button
          ui-button
          color="linkFlat"
          *ngIf="!isEditing"
          [disabled]="!isOutdated()"
          [custom-icon]="true"
          [isSvg]="true"
          icon="icon_in_progress"
          icon-size="medium"
          (click)="updateOutdatedUsecase()"
        >
          {{ 'datasources.table.action.update' | t }}
        </button>
      </div>
      <ui-toggle
        *ngIf="!isEditing"
        #alertingModeToggle
        class="toggle-mode"
        [toggled]="alertingModeToggle"
        [class.toggled]="!isEditing"
        [labelEnabled]="'usecase.activated' | t"
        [labelDisabled]="'usecase.muted' | t"
        [disabledWhenUntoggled]="false"
        (onClick)="toggleMode()"
        [manualToggle]="true"
      ></ui-toggle>
    </div>

    <div class="margin--small--top margin--small--bottom">
      <ui-title [name]="'common.properties' | t" id="details-header"></ui-title>
      <ui-field-group>
        <ui-static
          [label]="'org_usecase.table.column.catalog_id' | t"
          [value]="data.usecaseItem.catalogId || '-'"
        ></ui-static>
        <ui-static
          [label]="'org_usecase.table.column.datasourceType' | t"
          [value]="currentUsecase.datasourceType"
        ></ui-static>

        <div class="usecase-indicators">
          <label ui-label>{{ 'incidents.container.page.table.column.indicators' | t }}</label>
          <usecase-indicators
            [usecase]="data.usecaseItem"
          ></usecase-indicators>
        </div>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          [label]="'usecases.details.description' | t "
          [value]="currentUsecase?.description | translatedObject"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          [label]="'org_usecase.table.column.activated_at' | t"
          [value]="data.usecaseItem.createdAt | dt:{withTime:true,withSeconds:true,locale:i18nService.currentLocale}"
        ></ui-static>
        <ui-static
          [label]="'org_usecase.table.column.updated_at' | t"
          [value]="data.usecaseItem.updatedAt | dt:{withTime:true,withSeconds:true,locale:i18nService.currentLocale}"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          *ngIf="!isEditing"
          label="{{'org_usecase.description_drawer.tab.details.playbook' | t}}"
          [value]="playbookNameWithSuffix"
        ></ui-static>
        <autocomplete
          *ngIf="isEditing"
          [disabled]="!autocompletePlaybookValues?.length"
          [required]="false"
          [initItems]="initialPlaybook"
          [label]="'org_usecase.create_drawer.playbook_selection' | t"
          [placeholder]="(!autocompletePlaybookValues?.length ? 'org_usecase.create_drawer.playbook_selection.disabled.placeholder' : 'org_usecase.create_drawer.playbook_selection.placeholder') | t"
          [autocompleteType]="customAutocomplete"
          [customValues]="autocompletePlaybookValues"
          [selectedItems]="selectedPlaybook"
          [canMultiSelect]="false"
          [oneItemAtATime]="true"
          [showInInputAtInit]="true"
          (onItemSelected)="playbookSelection($event)"
        ></autocomplete>
      </ui-field-group>

      <ui-field-group class="usecase-priority-section">
        <div class="usecase-priority-modified-container">
          <ui-static class="usecase-priority-modified"
            *ngIf="!isEditing"
            label="{{'org_usecase.table.column.modified_priority' | t}}"
            [value]="currentUsecase.priority || '-'"
          ></ui-static>
            <ui-select class="usecase-priority-modified"
            *ngIf="isEditing"
            [options]="priorityOptions"
            control="priority"
            [group]="usecaseUpdateForm.usecaseFormGroup"
            [label]="'org_usecase.table.column.modified_priority' | t"
            ></ui-select>
        </div>

        <ui-static class="usecase-priority-default"
          [label]="'org_usecase.table.column.default_priority' | t"
          [value]="usecaseTemplate.priority || '-'"
        ></ui-static>
      </ui-field-group>

      <usecase-affected-resources
        [formGroup]="usecaseUpdateForm.usecaseFormGroup"
        [controlName]="'affectedResources'"
        [value]="currentUsecase?.affectedResources"
        [locale]="locale"
        [isEditing]="isEditing"
      ></usecase-affected-resources>

      <ui-field-group>
        <json-textarea
          class="json-textarea"
          [type]="textareaTypeEnum.JSON"
          controlName="aggregationRule"
          [readonly]="!isEditing"
          [prettifyButton]="isEditing"
          [formGroup]="usecaseUpdateForm.usecaseFormGroup"
          [label]="'org_usecase.create_drawer.aggragation_rule.title' | t"
        >
        </json-textarea>
      </ui-field-group>
    </div>
  </div>
</div>
<ui-form-actions
  *ngIf="isEditing"
  layout="centered"
  class="visible"
>
  <ng-container>
    <button
      *ngIf="!autocompletePlaybookValues?.length && playbookTemplateIds"
      class="add-playbook-button"
      ui-button
      color="standard"
      (click)="save(true)">
        {{ 'org_usecase.create_drawer.create_playbook_button.redirect' | t }}
    </button>
    <button
      ui-button
      color="standard"
      [disabled]="!usecaseUpdateForm.isUsecaseFormValid"
      (click)="save()"
    >
      {{ 'common.action.save' | t }}
    </button>
    <button
      ui-button
      color="secondary"
      (click)="toggleAction()"
    >
      {{ 'common.action.cancel' | t }}
    </button>
  </ng-container>
</ui-form-actions>

<ui-dialog *ngIf="isEditingAlertingMode"
    [headtitle]="'org_usecase.create_drawer.dialog.silenced.title' | t"
    [actionButton]="'common.action.confirm' | t"
    [cancelButton]="'common.action.cancel' | t"
    (onButtonClick)="onEditingAlertingModeDialogClick($event)"
    [actionButtonDisabledCondition]="!usecaseUpdateForm.alertingModeComment"
>
  <label ui-label [required]="true">{{ 'org_usecase.create_drawer.dialog.silenced.label' | t }}</label>
  <textarea
    class="textarea-reason"
    (input)='usecaseUpdateForm.setAlertingModeComment($event.target.value)'
  ></textarea>
  <br>
</ui-dialog>