import { ApplicationRef, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { DisplayService } from "projects/@common/modules/display/display.service";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { Notice, NoticeLevels, NoticeService } from "projects/@common/modules/notice/notice.service";
import { IIncidentActionRepresentation, IncidentStatus, IncidentTaskPhases } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IDescribeIncidentTask, IincidentTaskMessage, IncidentCustomTaskType, IncidentTaskStatus } from "projects/@common/services/api/respond/incidentTasks/incidentTasks.definitions";
import { IncidentTask } from "projects/@common/services/api/respond/incidentTasks/models/incidentTask";
import { IncidentTaskPhase } from "projects/@common/services/api/respond/incidentTasks/models/incidentTaskPhase";
import { IParameterStore } from "projects/@common/services/api/tools/parameters/parameters.definitions";

import { IAssetPropertyConfigRepresentation, IAssetRepresentation } from "projects/@common/services/api/respond/actifs/actifs.definitions";
import { IEntityRepresentation } from "projects/@common/services/api/respond/entities/entities.definition";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { IObservable } from "projects/@common/services/api/respond/observables/observables.definition";
import { Subscription } from "rxjs/internal/Subscription";
import { IncidentDifferenceService } from "../../../../services/incident.difference.service";
import { ISelectIcon } from "./components/ui-select-with-icon/ui-select-with-icon.component";
import { ConfirmationModalComponent } from "@ui-kit/components/confirmation-modal/confirmation-modal.component";
import { ModalService } from "@ui-kit/services/modal.service";
import { IncidentTaskAction } from "@common/services/api/respond/incidentTasks/models/incidentTaskAction";
import { CdkDrag, CdkDropList } from "@angular/cdk/drag-drop";
import { TaskContainerContentComponent } from "./components/task-container-content/task-container-content.component";
import { SpecificTaskComponent } from "projects/@respond/playbooks/components/specific-task/specific-task.component";
import { SpecificTaskForm } from "projects/@respond/playbooks/forms/specific-task.form";
import { FormsFactory } from "projects/@respond/playbooks/forms/formsFactory";
import { PlaybookTaskRelation } from "@common/services/api/respond/playbooks/playbooks.definitions";
import { IamApiService } from "@common/services/api/iam/iam.api";
import { Store } from "@ngxs/store";
import { EcoSessionState } from "@common/modules/session/state/session.state";
import { LanguageEnum } from "@ui-kit/interfaces/ILanguage";
import { ExpendableTypeEnum, UiExpendableSectionComponent } from "@ui-kit/components/ui-expendable-section/ui-expendable-section.component";

@Component({
  selector: 'app-task-tab',
  templateUrl: './task-tab.component.html',
  styleUrls: [ './task-tab.component.scss' ],
})
export class TaskTabComponent implements OnInit, OnDestroy {
  @Input() public incident: Incident;

  @Input() public observables: IObservable[] = [];

  @Input() public entities: IEntityRepresentation[] = [];
  
  @Input() public isLoadingEntities: boolean;

  @Input() public assets: IAssetRepresentation[] = [];

  @Input() public assetTypes: IAssetPropertyConfigRepresentation[] = [];

  @Input() public incidentActions: IIncidentActionRepresentation[] = [];

  @Input() public disabled = false;

  @Input() public showToolboxComponent = false;

  @Input() public isAdminConsole = false;

  @Output() stopInvestigation = new EventEmitter<void>();

  @Output() startConversation = new EventEmitter<boolean>();

  @Output() emitTaskMessages = new EventEmitter<(IincidentTaskMessage & {messageContent: string})[]>();

  @ViewChildren('taskContainerContent') taskContainerContents: QueryList<TaskContainerContentComponent>;

  private isVarMode = !!this.store.selectSnapshot(EcoSessionState.varMode);
  
  public specificTaskForm: SpecificTaskForm;
  public paramsList: IParameterStore[];
  public incidentTaskPhases: IncidentTaskPhase[] = [];
  public expendableTypeEnum = ExpendableTypeEnum;
  public statusValues: ISelectIcon[] = [
    { viewValue: this.i18nService.translate(`incidents.component.tab.task.status.${IncidentTaskStatus.CLOSED}`), icon: 'icon_success', value: IncidentTaskStatus.CLOSED },
    { viewValue: this.i18nService.translate(`incidents.component.tab.task.status.${IncidentTaskStatus.IN_PROGRESS}`), icon: 'icon_sync', value: IncidentTaskStatus.IN_PROGRESS },
    { viewValue: this.i18nService.translate(`incidents.component.tab.task.status.${IncidentTaskStatus.NOT_APPLICABLE}`), icon: 'icon_non_applicable', value: IncidentTaskStatus.NOT_APPLICABLE },
    { viewValue: this.i18nService.translate(`incidents.component.tab.task.status.${IncidentTaskStatus.OPEN}`), icon: 'icon_waiting', value: IncidentTaskStatus.OPEN },
  ];
  public incidentId: string;
  public organizationId: string;
  public organizationEcoId: string;
  public isToolboxExpanded: boolean = true;
  public isHeaderHoveredTaskId: string;

  private incidentDifferenceSubscription: Subscription;
  private reloadIncidentSubscription: Subscription;
  private placeholderElement;
  private placeholderStyles;
  private canEditRequirement = false;
  private preferedLanguage: LanguageEnum;
  private tasksMessages: (IincidentTaskMessage & {messageContent: string})[] = [];

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public applicationRef: ApplicationRef,
    private readonly incidentsApi: IncidentsApi,
    private readonly i18nService: I18nService,
    private readonly notice: NoticeService,
    private readonly displayService: DisplayService,
    private readonly modalService: ModalService,
    private readonly incidentDifferenceService: IncidentDifferenceService,
    private readonly iamApiService: IamApiService,
    private readonly store: Store,
    readonly formsFactory: FormsFactory,
  ) {
  }

  ngOnInit(): void {
    this.incidentId = this.incident.id;
    this.organizationId = this.incident.organizationId;
    this.organizationEcoId = this.incident.organizationEcoId;

    const observableParams = this.observables ? this.getObservablesParams(this.observables) : [];
    const playbookParams = Object.entries(this.incident.playbookParams).map(([ key, value ]) => ({ name: key, value })) as IParameterStore[];
    this.paramsList = [ ...playbookParams, ...observableParams ];

    this.createPhaseContainer();
    this.mapIncidentTasksToPhases();
    this.focusActiveTask();

    this.canEditRequirement = this.displayService.meetsRequirements({ permissions: [ 'can_update_incident' ] });

    this.reloadIncidentSubscription = this.incident.reloadObservable$.subscribe(() => {
      this.mapIncidentTasksToPhases();
    });

    this.incidentDifferenceSubscription = this.incidentDifferenceService.stateUpdateObservable.subscribe(() => {
      this.setPhasesUnsavedState();
    });

    this.fetchOrganizationPreferredLanguage();
  }

  ngOnDestroy(): void {
    this.reloadIncidentSubscription?.unsubscribe();
    this.incidentDifferenceSubscription?.unsubscribe();
  }

  private async fetchOrganizationPreferredLanguage() {
    if (this.isVarMode) {
      this.iamApiService.describeOrganization(this.incident?.organizationEcoId).then((org) => {
        this.preferedLanguage = org.preferredLang as any as LanguageEnum;
        this.specificTaskForm = this.formsFactory.getSpecificTaskForm();
        this.specificTaskForm.setRequiredLanguage(this.preferedLanguage);
      });
    } else {
      this.preferedLanguage = this.i18nService.currentLocale as LanguageEnum;
      this.specificTaskForm = this.formsFactory.getSpecificTaskForm();
      this.specificTaskForm.setRequiredLanguage(this.preferedLanguage);
    }
  }

  public getCustomButtons(task: IncidentTask) {
    if (task.isInProgress && this.incident.isEditable) {
      return task?.task?.customTask?.customActions || null;
    }
    return null;
  }

  public hasCustomActions(task: IncidentTask): boolean {
    return !!task?.task?.customTask?.customActions?.length;
  }

  public hasCustomAction(task: IncidentTask, action: string): boolean{
    return !!task?.task?.customTask?.customActions?.some((item) => item === action);
  }

  public get activePhase(): IncidentTaskPhase {
    return this.incidentTaskPhases?.find((phase) => this.findActiveTask(phase.tasks));
  }

  public get activeTask(): IncidentTask {
    return this.findActiveTask(this.activePhase?.tasks);
  }

  public get hasNextTask(): boolean {
    return Boolean(this.findNextOpenTask());
  }
  
  public get canEditTaskStatuses(): boolean {
    return this.incident.isEditable && this.canEditRequirement;
  }

  public canEditTaskNotes(task: IncidentTask): boolean {
    return this.incident.isEditable && this.canEditRequirement && !this.disabled && task.canUpdateComment;
  }

  public trackByPhaseName(index: number, item: IncidentTaskPhase): string {
    return item.name;
  }

  public trackByTaskId(index: number, item: IncidentTask): string {
    return item.taskId;
  }

  public setPhasesUnsavedState(): void {
    this.incidentTaskPhases.forEach((phase) => {
      const taskHasUnsavedChange = (incidentTask: IncidentTask) => this.incidentDifferenceService.editingStates.find((state) => state.itemId === incidentTask.task.id)?.hasUnsavedChange;
      phase.hasUnsavedChange = phase.tasks?.some(taskHasUnsavedChange);
    });
  }

  public availableStatus(task: IncidentTask): ISelectIcon[] {
    if (task === this.activeTask) {
      return this.statusValues.filter((status) => this.filterActiveAccordingToCustomActions(task).includes(status.value));
    } else {
      return this.statusValues.filter((status) => this.filterUnactiveAccordingToCustomActions(task).includes(status.value));
    }
  }

  public filterActiveAccordingToCustomActions(task: IncidentTask): IncidentTaskStatus[] {
    return task.availableStatuses.filter((item) => {
      if (item === IncidentTaskStatus.CLOSED || item === IncidentTaskStatus.NOT_APPLICABLE) {
        return !this.hasCustomActions(task);
      }
      return true;
    });
  }

  public filterUnactiveAccordingToCustomActions(task: IncidentTask): IncidentTaskStatus[] {
    return task.availableStatuses.filter((item) => {
      if (item === IncidentTaskStatus.NOT_APPLICABLE) {
        return !this.hasCustomActions(task);
      }
      return true;
    });
  }

  public createPhaseContainer(): void {
    for (const phase in IncidentTaskPhases) {
      this.incidentTaskPhases.push(new IncidentTaskPhase(phase));
    }
  }

  public canSetPhaseInapplicable(phase: IncidentTaskPhase): boolean {
    if (phase.name === IncidentTaskPhases.IDENTIFICATION || !phase.tasks[0]) {
      return false;
    }

    const firstTaskPhase = phase.tasks[0];
    if (!firstTaskPhase) {
      return false;
    }
    const previousTaskIndex = this.incident.tasks.findIndex((item) => item.task.id === firstTaskPhase.task.id) - 1;
    const previousTask = this.incident.tasks[previousTaskIndex];

    return !previousTask || [IncidentTaskStatus.CLOSED, IncidentTaskStatus.NOT_APPLICABLE].includes(previousTask.task.status);
  }

  public async setPhaseInapplicable(phase: IncidentTaskPhase, expandableSection: UiExpendableSectionComponent): Promise<void> {
    const shouldConfirm = phase.tasks?.some((task) => task.task.status !== IncidentTaskStatus.NOT_APPLICABLE && [ IncidentTaskStatus.NOT_APPLICABLE, IncidentTaskStatus.IN_PROGRESS ].includes(task.task.status));
    if (shouldConfirm) {
      const confirmed = await this.promptSetPhaseInapplicable();
      if (!confirmed) {
        return;
      }
    }
    if (!expandableSection.isCollapsed) {
      expandableSection.toggleCollapse();
    }

    // This need to be replaced by an endpoint server side to avoir 12 calls...
    for (const task of phase.tasks) {
      if (task.task.status !== IncidentTaskStatus.NOT_APPLICABLE) {
        if (!task.task.customTask?.customActions?.length) {
          await this.updateTaskStatus(task.task.id, IncidentTaskStatus.NOT_APPLICABLE, null);
        } else {
          break;
        }
      }
    }
    this.focusActiveTask();
  }

  public updateTaskStatus(id: string, status: IncidentTaskStatus, taskExpandableSection?: UiExpendableSectionComponent): Promise<void> {
    return new Promise((resolve) => {
      const request = { incidentId: this.incidentId, id, status };
      this.incidentsApi.updateTaskStatus(this.organizationId, request)
        .subscribe(
          (response) => {
            this.incident.reloadIncident({
              ...response.incident,
              tasks: response.tasks,
              phaseTimestamps: response.phaseTimestamps,
              historyTimestamps: response.historyTimestamps,
              resolveIncidentActions: response.resolveIncidentActions,
              reportAvailable: response.reportAvailable,
            });

            this.refeshTaskContainer(id, status,taskExpandableSection);
          },
          () => this.notice.notifyUser(new Notice(this.i18nService.translate('common.error.retry'), NoticeLevels.ERROR)),
          () => resolve()
        );
    });
  }

  public refeshTaskContainer(id: string, status: IncidentTaskStatus, taskExpandableSection: UiExpendableSectionComponent) {
    const container: TaskContainerContentComponent = this.taskContainerContents.toArray().find((item) => item.incidentTask.task.id === id);
    if (status === IncidentTaskStatus.IN_PROGRESS) {      
      container.openHtmlEditor();

      if (taskExpandableSection && taskExpandableSection.isCollapsed) {
        taskExpandableSection.toggleCollapse();
      }
    } else {
      container.updateComment().then(() => {
        container.closeHtmlEditor();
      });

      if (taskExpandableSection && !taskExpandableSection.isCollapsed) {
        taskExpandableSection.toggleCollapse();
      }
    }
  }

  public updateTaskComment(id: string, comment: string, callback: Function): void {
    const request = { incidentId: this.incidentId, id, comment };
    this.incidentsApi.updateTask(this.organizationId, request)
      .subscribe(
        (response) => {
          this.incident.reloadIncident({
            tasks: response.tasks,
          });
          callback && callback({});
        },
        (error) => {
          console.log(error);
          callback && callback({});
        }
      );
  }

  public async startFirstTask(clickedTaskId: string, callback: Function): Promise<void> {
    await this.updateTaskStatus(clickedTaskId, IncidentTaskStatus.IN_PROGRESS);
    callback && callback({});
  }

  public async startNextTask(clickedTaskId: string, callback: Function, taskExpandableSection: UiExpendableSectionComponent): Promise<void> {
    await this.updateTaskStatus(clickedTaskId, IncidentTaskStatus.CLOSED);
    if (!taskExpandableSection.isCollapsed) {
      taskExpandableSection.toggleCollapse();
    }
    const nextTask = this.findNextOpenTask();
    if (nextTask) {
      await this.updateTaskStatus(nextTask.taskId, IncidentTaskStatus.IN_PROGRESS);
      this.focusActiveTask();
    }

    callback && callback({});
  }

  public async completeFinalTask(clickedTaskId: string, callback: Function): Promise<void> {
    await this.updateTaskStatus(clickedTaskId, IncidentTaskStatus.CLOSED);
    this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.success'), NoticeLevels.SUCCESS));
    callback && callback({});
  }

  public async onStopInvestigation(callback: Function): Promise<void> {
    this.stopInvestigation.emit();
    callback && callback({});
  }

  public async onStartConversation(escalation: boolean, callback: Function): Promise<void> {
    this.startConversation.emit(escalation);
    callback && callback({});
  }

  public focusActiveTask(): void {
    if (this.activeTask && !this.disabled) {
      setTimeout(() => {
        const activeTaskElement: HTMLElement = document.getElementById(this.activeTask.taskId);
        if (activeTaskElement) {
          activeTaskElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
      }, 100); // let the DOM render first
    }
  }

  public handleToolboxExpandToggled(value: boolean): void {
    this.isToolboxExpanded = value;
  }

  public atLeastOneActionFlagged(task: IDescribeIncidentTask): boolean{
    const incidentActions = this.incidentActions.filter((incidentAction) => task.actions.includes(incidentAction.id));

    return incidentActions?.some((incidentAction) => incidentAction.payload?.activities?.some((row) => row.isFlagged));
  }

  public setTasksMessages(tasksMessages: (IincidentTaskMessage & {messageContent: string})[]): void{
    this.tasksMessages = this.tasksMessages.concat(tasksMessages).filter((taskMessage) => !this.tasksMessages.find((message) => message.id === taskMessage.id));
    this.emitTaskMessages.emit(this.tasksMessages);
  }

  private mapIncidentTasksToPhases(): void {
    const incidentTasks = this.incident.tasks.map((task) => new IncidentTask(task));

    this.incidentTaskPhases.forEach((phase) => {
      phase.tasks = [];
      incidentTasks.forEach((incidentTask) => {
        if (incidentTask.taskPhase === phase.name) {
          phase.setTask(incidentTask);
        }
      });
      phase.filterRemovedTasks(incidentTasks);
    });
  }

  private findActiveTask(incidentTasks: IncidentTask[]): IncidentTask {
    const activeStatuses = [ IncidentTaskStatus.IN_PROGRESS, IncidentTaskStatus.OPEN ];
    return incidentTasks?.find((incidentTask) => activeStatuses.includes(incidentTask.task.status));
  }

  private findNextOpenTask(): IncidentTask {
    return this.incidentTaskPhases.flatMap((phase) => phase.tasks).find((task) => task.task.status === IncidentTaskStatus.OPEN);
  }

  private async promptSetPhaseInapplicable(): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalService.openDialog(ConfirmationModalComponent, {
        title: this.i18nService.translate('incidents.component.tab.phase.status.setAsNotApplicable.confirm.title'),
        text: this.i18nService.translate('incidents.component.tab.phase.status.setAsNotApplicable.confirm.text'),
        confirmationText: this.i18nService.translate('incidents.component.tab.phase.status.setAsNotApplicable.confirm.confirmationText'),
        cancelText: this.i18nService.translate('incidents.component.tab.phase.status.setAsNotApplicable.confirm.cancelText'),
        callback: (closeValue: boolean) => resolve(closeValue),
      });
    });
  }

  private getObservablesParams(observables: IObservable[]): IParameterStore[] {
    const out: { name: string; values: string[] }[] = [];

    for (const observable of observables || []) {
      const name = `observables/${observable.type}`;
      let current = out.find((item) => item.name === name) as any;
      if (!current) {
        current = {
          name, values: [],
        };
        out.push(current);
      }
      if (observable.value) {
        current.values.push(observable.value);
      }
    }

    const and = this.i18nService.translate(`incidents.component.tab.task.separator`);

    return out.map((item) => ({
      name: item.name,
      value: {
        type: 'string',
        content: [ item.values.slice(0, -1).join(', '), ...item.values.slice(-1) ].join(` ${and} `),
      },
    } as any));
  }

  public canAddCustomTask(phase: IncidentTaskPhase) {
    const lastTask = phase.tasks.slice(-1).shift();
    if (!lastTask) {
      //Check the status of the next phase
      return false;
    }
    const nextTaskIndex = this.incident.tasks.findIndex((item) => item.task.id === lastTask.task.id) + 1;
    const nextTask = this.incident.tasks[nextTaskIndex];

    return !nextTask || [IncidentTaskStatus.OPEN].includes(nextTask.task.status);
  }

  async addCustomTask(phase: IncidentTaskPhase, expandableSection: UiExpendableSectionComponent): Promise<void> {
    // Get the last task of the current phase
    const lastTask = phase.tasks.slice(-1).shift();

    this.incidentsApi.addIncidentTask(this.organizationId, {
      incidentId: this.incident.id, 
      playbookTaskId: null, 
      version: null,
      taskId: lastTask.task.id,
      before: false,
    }).toPromise().then((response) => {
      this.incident.reloadIncident({
        ...response.incident,
        tasks: response.tasks,
      });
      const newTask = phase.tasks.slice(-1).shift();
      this.editTask(newTask);
    }).catch(() => {
      this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.error'), NoticeLevels.ERROR));
    });

    if (expandableSection.isCollapsed) {
      expandableSection.toggleCollapse();
    }
  }

  deleteTask(task: IncidentTask) {
    this.incidentsApi.deleteIncidentTask(this.organizationId, {
      incidentId: this.incident.id, 
      taskId: task.task.id
    }).toPromise().then((response) => {
      this.incident.reloadIncident({
        ...response.incident,
        tasks: response.tasks,
      });
    }).catch(() => {
      this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.error'), NoticeLevels.ERROR));
    });
  }

  editTask(task: IncidentTask) {    
    this.modalService.openDialog(SpecificTaskComponent, {
      specificTaskForm: this.specificTaskForm,
      organizationId: this.organizationId,
      paramsList: this.paramsList.map((param) => ({
        value: param.value,
        displayValue: param.name,
      })),
      requiredLanguage: this.preferedLanguage,
      title: 'incidents.component.tab.task.editCustomTask.title',
      save: 'common.action.save',
      cantIgnore: true,
      value: {
        name: task.task.name,
        description: task.task.description,
        instruction: task.task.instruction,
        hidden: task.task.hidden,
      },
      callback: (editedTask: PlaybookTaskRelation) => {
        const request = { 
          incidentId: this.incidentId, 
          taskId: task.task.id, 
          name: editedTask.name, 
          description: editedTask.description, 
          instruction: editedTask.instruction, 
          hidden: editedTask.hidden
        };
        this.incidentsApi.updateCustomTask(this.organizationId, request)
          .subscribe(
            (response) => {
              this.incident.reloadIncident({
                ...response.incident,
                tasks: response.tasks,
              });
            },
            () => this.notice.notifyUser(new Notice(this.i18nService.translate('common.error.retry'), NoticeLevels.ERROR))
          );
      },
    });
  }

  drop(event: any) {
    if (event.item.data.task) {
      if (event.item.data.task.id !== event.container.data.task.id) {
        const before = event.currentIndex === 0;
        this.incidentsApi.moveIncidentTask(this.organizationId, {
          incidentId: this.incident.id, 
          sourceTaskId: event.item.data.task.id, 
          destinationTaskId: event.container.data.task.id,
          before,
        }).toPromise().then((response) => {
          this.incident.reloadIncident({
            ...response.incident,
            tasks: response.tasks,
          });
        }).catch(() => {
          this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.error'), NoticeLevels.ERROR));
        });
      }
    } else {
      const task: IncidentTask = event.container.data;
      const before = event.currentIndex === 0;
      const playbookTaskId = event.item.data.id;
      const version = event.item.data.version;
      this.incidentsApi.addIncidentTask(this.organizationId, {
        incidentId: this.incident.id, 
        playbookTaskId, 
        version,
        taskId: task.task.id,
        before,
      }).toPromise().then((response) => {
        this.incident.reloadIncident({
          ...response.incident,
          tasks: response.tasks,
        });
      }).catch(() => {
        this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.error'), NoticeLevels.ERROR));
      });
    }
  }

  enterDropZone(expandableSection: UiExpendableSectionComponent, event: any) {
    if (expandableSection.isCollapsed) {
      expandableSection.toggleCollapse();
    }
    const activeTaskElement: HTMLElement = document.getElementById(event.container.element.nativeElement.id);
    if (activeTaskElement) {
      this.placeholderElement = activeTaskElement.lastElementChild;
      this.placeholderStyles = activeTaskElement.lastElementChild.getAttribute('style');
      activeTaskElement.lastElementChild.setAttribute('style','display: none');
      setTimeout(() => {
        activeTaskElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }, 100);
    }
  }

  exitDropZone() {
    this.placeholderElement.setAttribute('style',this.placeholderStyles);
  }

  isAllowedToDrop(drag: CdkDrag, drop: CdkDropList): boolean {
    const task = drop.data.task as IncidentTaskAction;
    return task.status === IncidentTaskStatus.OPEN;
  }
  
  isDragAllowed(task: IncidentTask): boolean {
    return task.task.customTask?.type === IncidentCustomTaskType.USER && task.task.status === IncidentTaskStatus.OPEN 
      && [IncidentStatus.OPEN, IncidentStatus.FINALIZING].includes(this.incident.status);
  }

  cancelDefault(event: any) {
    event.event.stopPropagation();
    event.event.preventDefault();
  }
}
