import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { RequestDirection } from 'projects/@common/definitions/consoles.enum';
import { OrganizationPublicInfo } from 'projects/@common/definitions/organization-public-info.definition';
import { MitreAttackGenericData } from '../../detect/mitre-attack/mitre-attack-api.definition';
import { DataConnectorTypes } from '@md.eco/connectors';
import { IRespondResponse } from 'projects/@common/definitions/IRespondResponse';
import { Icon } from '../datasources/datasources.definitions';

export enum PlaybookTaskPhases {
  PREPARATION = 'PREPARATION',
  IDENTIFICATION = 'IDENTIFICATION',
  CONTAINMENT = 'CONTAINMENT',
  REMEDIATION = 'REMEDIATION',
  RECOVERY = 'RECOVERY',
  POSTMORTEM = 'POSTMORTEM'
}

export interface IUsecaseTableData {
  name: ITranslatedField;
  priority: number;
  datasources: string[];
  description: ITranslatedField;
  datasourcesIcon: Icon[];
}

export interface IPlaybookTableObject extends IPlaybookAtMetadata {
  id: string;
  organizationId: string;
  organizationEcoId: string;
  organizationName: string;
  name: ITranslatedField;
  usecaseCount: number;
  templateVersion: number;
  taskCount: number;
  outdated: boolean;
}

export interface IPlaybookAtMetadata {
  createdAt: number;
  updatedAt?: number;
}

export interface IPlaybookByMetadata {
  updatedBy: string;
  createdBy: string;
}

export interface IPlaybookTemplate extends Partial<IPlaybookAtMetadata> {
  id?: string;
  name: ITranslatedField;
  description: ITranslatedField;
  objective: ITranslatedField;
  datasourceTypes: DataConnectorTypes[];
  version: number;
  playbookTasks: IPlaybookTemplateTaskRelation[];
  playbookTemplateId: string;
  attacks?: MitreAttackGenericData[];
}

export interface IPlaybookDetail extends Partial<IPlaybookAtMetadata>, Partial<IPlaybookByMetadata> {
  id?: string;
  suffix?: string;
  extraDescription?: string;
  name?: ITranslatedField;
  description: ITranslatedField;
  objective: ITranslatedField;
  datasourceTypes: DataConnectorTypes[];
  version: number;
  notes?: string;
  organizationId: string;
  organizationEcoId?: string;
  organizationName?: string;
  usecases?: number;
  usecaseId?: string;
  playbookTasks?: PlaybookTaskRelation[];
  templateId?: string;
  templateCatalogId?: string;
  attacks?: MitreAttackGenericData[];
}

export interface IPlaybookTaskCustomParameters {
  name: string;
  value: string;
}

export interface IPlaybookTaskRepresentation extends IPlaybookAtMetadata {
  id: string;
  templateId?: string;
  version: number;
  name: ITranslatedField;
  description: ITranslatedField;
  instruction: ITranslatedField;
  requiredParameters?: string[];
  phase: PlaybookTaskPhases;
  order: number;
  managed: boolean;
  ignored?: boolean;
  hidden?: boolean;
  customParameters?: IPlaybookTaskCustomParameters[];
}

export interface PlaybookTaskRelation extends Partial<IPlaybookTaskRepresentation> {
  id?: string;
  phase: PlaybookTaskPhases;
  order: number;
  managed: boolean;
  ignored?: boolean;
  hidden?: boolean;
}

export interface IPlaybookTaskTemplateRepresentation extends Required<IPlaybookAtMetadata> {
  id: string;
  version?: number;
  activeVersion?: boolean;
  templateId: string;
  recommendedPhases: PlaybookTaskPhases[];
  name: ITranslatedField;
  description: ITranslatedField;
  instruction: ITranslatedField;
  requiredParameters: string[];
}

export interface IPlaybookTemplateTaskRelation extends Partial<IPlaybookTaskTemplateRepresentation> {
  templateId: string;
  order: number;
  phase: PlaybookTaskPhases;
}

export interface IPlaybookListResponse {
  playbook: IPlaybookDetail;
  organization: OrganizationPublicInfo;
  usecaseCount: number;
  taskCount: number;
  outdated: boolean;
}

export enum PlaybookOrderByEnum {
  name = 'name',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt'
}

export interface IListPlaybooksRequest {
  organizationId?: string;
  from?: number;
  size?: number;
  searchName?: string;
  orderBy?: PlaybookOrderByEnum;
  direction?: RequestDirection;
  templateCatalogIds?: string[];
  templateIds?: string[];
}

export interface IListPlaybooksTemplateQuery {
  from?: number;
  size?: number;
  orderColumn?: PlaybookOrderByEnum;
  order?: string;
  datasourceType?: DataConnectorTypes;
  ids?: string[];
  displayAttacks?: boolean;
}

export interface IGetPlaybookQuery {
  displayAttacks?: boolean;
}

export interface IGetPlaybookTemplateQuery {
  displayAttacks?: boolean;
}

export interface IGetPlaybookStats {
  requiresUpdate: number;
  active: number;
}

export interface getPlaybookTaskTemplatesRequest {
  from?: number;
  size?: number;
  custom?: boolean;
  name?: string;
  language?: string;
  phases?: string[];
  playbookId?: string;
}

export interface IPlaybooksApi {
  getPlaybookTemplateById(templateId: string, queryParams?: IGetPlaybookTemplateQuery): Promise<IPlaybookTemplate>;

  getPlaybookTemplates(queryParams?: IListPlaybooksTemplateQuery): Promise<IRespondResponse<IPlaybookTemplate>>;

  getPlaybookTaskTemplates(queryParams?: getPlaybookTaskTemplatesRequest): Promise<IRespondResponse<IPlaybookTaskTemplateRepresentation>>;

  getPlaybooks(organizationId: string, queryParam?: IListPlaybooksRequest): Promise<IRespondResponse<IPlaybookListResponse>>;

  getPlaybook(organizationId: string, playbookId: string, queryParam?: IGetPlaybookQuery): Promise<IPlaybookDetail>;

  getPlaybookStats(organizationId: string): Promise<IGetPlaybookStats>;

  createPlaybook(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail>;

  updatePlaybook(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail>;

  deletePlaybook(organizationId: string, playbookId: string): Promise<any>;

  updatePlaybookVersion(organizationId: string, playbook: IPlaybookDetail): Promise<IPlaybookDetail>;
}
