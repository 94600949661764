import { Component, Input, OnInit } from '@angular/core';
import { TableScrollableColumnsContent } from '@ui-kit/components/ui-table-scrollable-body/ui-table-scrollable-body.component';
import { DateTimePeriod, PresetOptionValuesEnum } from '@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component';
import { TimeUtil } from '@ui-kit/services/time-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ConnectorsOrgService } from 'projects/@common/services/api/detect/connectorsOrg/connectorsOrg.service';

import { ConnectorInstanceDto, ConnectorInstanceVolumeEntityHistoryDto, ConnectorInstanceVolumeEntityTotalHistoryDto, ConnectorInstanceVolumetryEntity } from 'projects/@common/services/api/detect/connectorsOrg/model/ConnectorOrg';
import { ConnectorCalendarInterval } from 'projects/@common/services/api/detect/models/connectorInstance.model';
import { GraphConnectorTotalData } from 'projects/@respond/dashboard/components/graphs/graph-connectors-total-ingested-data/graph-connectors-total-ingested-data.component';
import { GraphConnectorDailyData } from 'projects/@respond/dashboard/components/graphs/graph-connnector-daily-ingested-data/graph-connnector-daily-ingested-data.component';
import { DashboardFileUnitHelper } from 'projects/@respond/dashboard/helpers/dashboardFileUnitHelper';


@Component({
  selector: 'app-connector-instance-graphs-volume-section',
  templateUrl: './connector-instance-graphs-volume-section.component.html',
  styleUrls: [ './connector-instance-graphs-volume-section.component.scss' ],
})
export class ConnectorInstanceGraphsVolumeSectionComponent implements OnInit {
  public readonly defaultSelectedPeriod = PresetOptionValuesEnum.last_7_days;

  @Input()
  public connectorInstance: ConnectorInstanceDto;
  public roundChartData: GraphConnectorTotalData[] = [];
  public tableData: TableScrollableColumnsContent[] = [];
  public lineChartData: GraphConnectorDailyData[] = [];
  public displaySection: boolean = false;
  public dataType: string;
  private currentTimePeriod: DateTimePeriod = { dateFrom: Number(TimeUtil.getFirstTimestampOfTheDay(TimeUtil.getTimestampFromNowAndDays(7).dateFrom)) };

  constructor(
    public readonly i18n: I18nService,
    private readonly dashboardFileUnitHelper: DashboardFileUnitHelper,
    private readonly connectorOrgService: ConnectorsOrgService
  ) { }

  ngOnInit(): void {
    this.fetchRoundChartData();
    this.fetchLineChartData();
  }

  public timePeriodChanged(period: DateTimePeriod) {
    this.currentTimePeriod = period;
    this.fetchRoundChartData();
    this.fetchLineChartData();
  }

  private fetchRoundChartData(): void {
    this.connectorOrgService.getConnectorInstanceVolumeEntityTotalHistory(this.connectorInstance.organizationId, this.connectorInstance.id, {
      from: this.currentTimePeriod.dateFrom - (new Date().getTimezoneOffset() * 60000),
      to: this.currentTimePeriod.dateTo ? this.currentTimePeriod.dateTo - (new Date().getTimezoneOffset() * 60000) : Date.now(),
      period: ConnectorCalendarInterval.DAY,
    })
      .then((data: ConnectorInstanceVolumeEntityTotalHistoryDto) => {
        if (data) {
          this.roundChartData = Object.keys(data.volume).map((entityName) => ({
            category: entityName,
            value: data.volume[entityName],
          })).sort((a, b) => b.value - a.value);
          this.mapTableDataFromRoundChartData(data.entityType);
          this.setDataType(data.entityType);
          this.displaySection = true;
        }
      });
  }

  private mapTableDataFromRoundChartData(entityType: ConnectorInstanceVolumetryEntity): void {
    if (this.roundChartData && this.roundChartData.length > 0) {
      const totalCapacity = this.roundChartData.reduce((sum, currentData) => sum + currentData.value, 0);
      const { result, typeToTranslate } = this.dashboardFileUnitHelper.getFileUnitValues(totalCapacity);
      const totalUsedCapacity = result;
      const totalUsedCapacityUnit = this.i18n.translate(typeToTranslate);
      const dataCategories: string[] = this.roundChartData.map((data) => data.category);
      const dataValues: string[] = this.roundChartData.map((data) => this.dashboardFileUnitHelper.formatValueWithBinaryUnit(data.value));
      this.tableData = [ {
        headerName: this.i18n.translate(`detect.connector.instance.details.card.data.analytic.table.header.type.${entityType}`),
        content: dataCategories,
        footerName: this.i18n.translate(`detect.connector.instance.details.card.data.analytic.table.footer.type.${entityType}`),
      }, {
        headerName: this.i18n.translate('respond.dashboard.data.ingest.title'),
        content: dataValues,
        footerName: `${totalUsedCapacity} ${totalUsedCapacityUnit}`,
      } ];
    } else {
      this.tableData = [];
    }
  }

  private fetchLineChartData(): void {
    this.connectorOrgService.getConnectorInstanceVolumeEntityHistory(this.connectorInstance.organizationId, this.connectorInstance.id, {
      from: this.currentTimePeriod.dateFrom - (new Date().getTimezoneOffset() * 60000),
      to: this.currentTimePeriod.dateTo ? this.currentTimePeriod.dateTo - (new Date().getTimezoneOffset() * 60000) : Date.now(),
      period: ConnectorCalendarInterval.DAY,
    })
      .then((data: ConnectorInstanceVolumeEntityHistoryDto) => {
        if (data) {
          this.lineChartData = data.volume.map((value) => {
            const chartValues = new Map<string, number>();
            value.entities.forEach((map) => {
              for (const key in map) {
                if (Object.prototype.hasOwnProperty.call(map, key)) {
                  chartValues[key] = map[key];
                }
              }
            });
            return {
              timestamp: value.timestamp,
              values: chartValues,
            };
          });
          this.setDataType(data.entityType);
          this.displaySection = true;
        }
      });
  }

  private setDataType(entityType: ConnectorInstanceVolumetryEntity) {
    if (!this.dataType) {
      this.dataType = this.i18n.translate(`detect.connector.instance.details.card.data.analytic.type.${entityType}`);
    }
  }
}
