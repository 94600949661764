<div class="card-content">
  <settings-edition-card
    [icon]="'icon_users_widget_green_nobackground_cropped.svg'"
    [title]="'settings.general.segmentation.title' | t"
    [canSave]="isValid"
    [confirmationSave]="true"
    (saveEvent)="handleSave($event)"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing = $event"
  >
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <p class="description">
      {{ 'settings.general.segmentation.description' | t }}
    </p>
    <p
      *ngIf="showSelectedAttribute"
      class="attribute"
      [innerHTML]="'settings.general.segmentation.label' | t: { attribute: initialValue }"
    ></p>
    <p
      *ngIf="!isActivated"
      class="attribute"
      [innerHTML]="'settings.general.segmentation.deactivated' | t"
    ></p>
  </div>
</ng-template>

<ng-template #editing>
  <p class="description">{{ 'settings.general.segmentation.description' | t }}</p>
  <div
    *ngIf="isChangingAttribute"
    class="warning-container"
  >
    <ui-warning
      [warningMessages]="warningMessage"
      [color]="warningColors.YELLOW"
      [centerText]="false"
    > </ui-warning>
  </div>
  <div
    class="autocomplete"
    *ngIf="!isLoading; else loading"
  >
    <ui-toggle
      [toggled]="isActivated"
      [labelEnabled]="'settings.notification.silencing.toggle.activated' | t"
      [labelDisabled]="'settings.notification.silencing.toggle.deactivated' | t"
      (onClick)="toggleSilencingActivation()"
    ></ui-toggle>
    <ng-container *ngIf="isActivated">
      <autocomplete
        #segmentationAutocomplete
        [placeholder]="'settings.general.segmentation.autocomplete.placeholder' | t"
        [required]="true"
        [autocompleteType]="customAutocompleteType"
        [customValues]="customValues"
        [canMultiSelect]="false"
        [oneItemAtATime]="true"
        expandHeight="max-content"
        (onItemSelected)="onItemSelected($event)"
      >
      </autocomplete>
      <p
        *ngIf="selectedAttribute"
        class="settings-content"
        [innerHTML]="'settings.general.segmentation.label' | t: { attribute: selectedAttribute }"
      ></p>
    </ng-container>
  </div>
</ng-template>
