import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TeamsAuthService } from '@common/services/teams-auth.service';
import { Store } from '@ngxs/store';
import { IamApiService } from 'projects/@common/services/api/iam/iam.api';
import { IamApi, RefreshTokenResponse } from 'projects/@common/services/api/iam/iam.api.definitions';
import { DisplayService } from '../display/display.service';
import { AppStatus, SetAppStatus } from '../layout/stores/app.state';
import { InvalidateSession, LoadSessionFromBrowser, Login, RefreshToken } from './state/session.state';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private iam: IamApiService,
    private store: Store,
    private display: DisplayService,
    private router: Router,
    private teamsAuthService: TeamsAuthService
  ) {
  }

  public async login(login: {
    email: string,
    code: string,
    redirectUrl: string,
    jwt?: string,
  }, options?: { assertOrganizationTag?: string; }) {
    const loginResponse = await this.iam.login(login);

    if (options.assertOrganizationTag) {
      if (!loginResponse.organization.tags.includes(options.assertOrganizationTag)) {
        throw new Error('Organization not authorized.');
      }
    }

    this.dispatchLogin(loginResponse);

    await this.display.loadPermissions();
    this.setAppReady();
  }

  public async signup(request: {
    code: string,
    state: string,
    idpType: 'google' | 'azuread';
  }): Promise<{ adminConsentUrl: string; }> {
    // Signup and initialize the session with the returned credentials
    const adminConsentRedirectUrl = `${window.location.origin}/adminconsent/redirect`;
    const authorizationRedirectUrl = `${window.location.origin}/signup/redirect`;

    const res = await this.iam.signup({
      authorizationRedirectUrl,
      adminConsentRedirectUrl,
      code: request.code,
      state: request.state,
      idpType: request.idpType,
    });
    this.dispatchLogin(res);
    await this.display.loadPermissions();
    this.setAppReady();
    return res;
  }

  public refreshToken(payload: RefreshTokenResponse): void {
    this.store.dispatch(new RefreshToken(payload));
  }

  public loadBrowserSessionIfNeeded() {
    this.store.dispatch(new LoadSessionFromBrowser());
  }

  public invalidateSession() {
    this.store.dispatch(new InvalidateSession());
    this.display.invalidateData();
  }

  public redirectToLogin() {
    this.router.navigate([ 'signin' ]);
  }

  private dispatchLogin(loginResponse: IamApi.Login.response) {
    const action = new Login(loginResponse);
    this.store.dispatch(action);
  }

  private setAppReady() {
    this.store.dispatch(new SetAppStatus({ status: AppStatus.Ready }));
  }
}
