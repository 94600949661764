import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { LanguageEnum } from "@ui-kit/interfaces/ILanguage";
import { pruneObject } from 'projects/@common/utils/utils';


export class SpecificTaskForm {
  private specificTaskForm: UntypedFormGroup;

  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  public get isFormValid(): boolean {
    return this.specificTaskForm.valid;
  }

  public get values(): any {
    return pruneObject(this.specificTaskForm.getRawValue());
  }

  public get taskForm(): UntypedFormGroup {
    return this.specificTaskForm;
  }

  public get nameFormGroup(): UntypedFormGroup {
    return this.specificTaskForm.get('name') as UntypedFormGroup;
  }

  public get descriptionFormGroup(): UntypedFormGroup {
    return this.specificTaskForm.get('description') as UntypedFormGroup;
  }

  public get instructionsFormGroup(): UntypedFormGroup {
    return this.specificTaskForm.get('instruction') as UntypedFormGroup;
  }

  public resetForm(): void {
    this.taskForm.reset();
  }

  public setRequiredLanguage(language: LanguageEnum) {
    this.initForm(language);
  }

  private initForm(requiredLanguage?: LanguageEnum): void {
    this.specificTaskForm = this.formBuilder.group({
      hidden: [ false ],
      ignored: [ false ],
      name: this.formBuilder.group({
        fr: !requiredLanguage || requiredLanguage === LanguageEnum.FRENCH ? [ null, [ Validators.required, Validators.maxLength(150) ] ] : [ null, [ Validators.maxLength(150) ] ],
        en: !requiredLanguage || requiredLanguage === LanguageEnum.ENGLISH ? [ null, [ Validators.required, Validators.maxLength(150) ] ] : [ null, [ Validators.maxLength(150) ] ],
      }),
      description: this.formBuilder.group({
        fr: !requiredLanguage || requiredLanguage === LanguageEnum.FRENCH ? [ null, Validators.required ] : [null],
        en: !requiredLanguage || requiredLanguage === LanguageEnum.ENGLISH ? [ null, Validators.required ] : [null],
      }),
      instruction: this.formBuilder.group({
        fr: !requiredLanguage || requiredLanguage === LanguageEnum.FRENCH ? [ null, Validators.required ] : [null],
        en: !requiredLanguage || requiredLanguage === LanguageEnum.ENGLISH ? [ null, Validators.required ] : [null],
      }),
    });
  }
}
