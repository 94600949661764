<ui-card>
  <div class="action-buttons-container">
    <div class="flex-box center-horizontal">
      <button
        *ngIf="canCreateIncident"
        ui-button
        color="linkFlat"
        (click)="createIncident()"
        icon="plus"
        icon-size="medium"
      >
        {{ 'incidents.container.page.table.action.createIncident' | t }}
      </button>
    </div>
    <div class="flex-box center-horizontal">
      <span [tooltip]="'incidents.container.page.table.action.exportIncidents.tooltip' | t">
        <button
          ui-button
          *display="['can_describe_incident']"
          color="linkFlat"
          [disabled]="isLoading"
          [loading]="isExporting"
          (click)="emitExportCsvEvent()"
          icon="download"
          icon-size="small"
          icon-margin="0 2px 0 0"
        >{{ 'incidents.container.page.table.action.exportIncidents' | t }}</button>
      </span>
    </div>
  </div>

  <ui-static-table
    [loading]="isLoading"
    [dataSource]="incidentTableData"
    [columnsDef]="incidentsColumnDefinition"
    (on-row-click)="handleRowClick($event)"
    [isCustomSorting]="true"
    [sort]="sort"
    [direction]="sortDirection"
    [addPaddingOnFirst]="false"
    [locale]="locale"
    fieldNameToCheckForBoldRow="updatedAfterLastDescribe"
    (handleCustomSort)="handleSorting($event)"
  >
  </ui-static-table>

</ui-card>