<div
  class="main-container"
  [ngClass]="{'isReadonly':isReadonly}"
>
  <ng-container *ngIf="hasTemplate()">
    <label ui-label>{{ 'detection.playbook.detail.description' | t }}</label>
    <p class="no-margin description">
      {{ playbook?.description || template.description | translatedObject }}
    </p>
  </ng-container>

  <ui-label-input-with-modify
    *ngIf="!hasTemplate() && playbook"
    [label]="'detection.playbook.detail.description' | t"
    [value]="playbook.extraDescription"
    [emptyPlaceholder]="'common.na' | t"
    [locale]="i18nService.currentLocale"
    (onValueChange)="savePlaybookValue(playbookValuesEnum.EXTRA_DESCRIPTION, $event)"
    [normalInput]="true"
    [disabled]="isReadonly"
  >
  </ui-label-input-with-modify>
  <div class="separator"></div>
  <ui-label-input-with-modify
    *ngIf="hasTemplate() && playbook"
    [locale]="i18nService.currentLocale"
    [label]="'detection.playbook.detail.description.additional' | t"
    [value]="playbook?.extraDescription"
    [emptyPlaceholder]="'common.na' | t"
    [normalInput]="true"
    [disabled]="isReadonly"
    (onValueChange)="savePlaybookValue(playbookValuesEnum.EXTRA_DESCRIPTION, $event)"
  >
  </ui-label-input-with-modify>
  <div class="separator"></div>
  <div *ngIf="playbook">
    <p class="no-margin">
      {{'detection.playbook.detail.creation' | t: {date: creationDate, by: playbook.createdBy} }}
    </p>
    <p
      class="no-margin"
      *ngIf="updateDate"
    >
      {{'detection.playbook.detail.update' | t: {date: updateDate, by: playbook.updatedBy} }}
    </p>
  </div>
  <div class="separator" *ngIf="template">
    <label ui-label>{{'detection.playbook.detail.template.objective' | t}}</label>
    <p class="no-margin">{{template.objective| translatedObject}}</p>
  </div>
  <div class="separator"></div>
  <ng-container *ngIf="hasTemplate()">
    <label ui-label>{{'detection.playbook.detail.datasource.title' | t }}</label>
    <app-datasource-table-icons [datasourceIcons]="datasourceIcons"></app-datasource-table-icons>
  </ng-container>

  <ng-container *ngIf="showTemplateInfo">
    <div class="separator"></div>
    <label ui-label>{{ 'detection.playbook.detail.template.title' | t }}</label>
    <p class="no-margin">{{ playbook?.name || template.name | translatedObject }}</p>
    <p class="no-margin">{{ playbook?.templateCatalogId || template.playbookTemplateId }}</p>
    <p class="no-margin">
      {{'detection.playbook.detail.version' | t: {version: playbook?.version || template.version } }}
    </p>
    <p class="no-margin">
      {{'detection.playbook.detail.latestVersion' | t: {version: template?.version ? template.version : "N/A"} }}
    </p>
  </ng-container>

  <div class="global-mitre-attack-container" *ngIf="playbookAttackData.length > 0">
    <label ui-label>{{'playbookCatalog.report.mitre' | t}}</label>
    <playbook-mitre-attack-container class="mitre-attack-container" 
      [attacks]="playbookAttackData">
    </playbook-mitre-attack-container>
  </div>

</div>