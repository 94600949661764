import { Injectable } from '@angular/core';
import { IExecuteToolboxActionsResponse } from 'projects/@common/services/api/respond/actions-toolbox/actions-toolbox.definition';
import { IDeleteTaskActionsRequest, IDeleteTaskEntitiesRequest, IDeleteTaskMessageRequest, IDeleteTaskObservablesRequest, IIncidentActionRepresentation, IPostTaskActionsRequest, IPostTaskEntitiesRequest, IPostTaskObservablesRequest } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Subject } from 'rxjs';

export type IAddItemToTaskRequest = IPostTaskObservablesRequest | IPostTaskActionsRequest | IPostTaskEntitiesRequest;
export type IRemoveItemToTaskRequest = IDeleteTaskObservablesRequest | IDeleteTaskActionsRequest | IDeleteTaskEntitiesRequest | IDeleteTaskMessageRequest;
export type IAppendTaskHTMLRequest = { html: string, taskId: string };

@Injectable({
  providedIn: 'root',
})
export class IncidentEventsService {
  // Subjects not accessible from outside
  private _appendTaskHtmlSubject = new Subject<IAppendTaskHTMLRequest>();
  private _toggleTaskMessagesItemSubject = new Subject<IAddItemToTaskRequest | IRemoveItemToTaskRequest>();
  private _actionExecutedSubject = new Subject<IExecuteToolboxActionsResponse[]>();
  private _actionsListedSubject = new Subject<IIncidentActionRepresentation[]>();

  // Observables you can subscribe to
  public appendTaskHtmlObservable$ = this._appendTaskHtmlSubject.asObservable();
  public toggleTaskMessagesItemObservable$ = this._toggleTaskMessagesItemSubject.asObservable();
  public actionExecutedObservable$ = this._actionExecutedSubject.asObservable();
  public actionsListedSubject$ = this._actionsListedSubject.asObservable();

  // Public Methods to emit events from anywhere in the incidents module
  public emitAppendTaskHtmlEvent(request: IAppendTaskHTMLRequest) {
    this._appendTaskHtmlSubject.next(request);
  }

  public emitToggleTaskFindingItem(request: IAddItemToTaskRequest | IRemoveItemToTaskRequest) {
    this._toggleTaskMessagesItemSubject.next(request);
  }

  public emitActionExecutedEvent(request: IExecuteToolboxActionsResponse[]) {
    this._actionExecutedSubject.next(request);
  }

  public emitActionsListedEvent(request: IIncidentActionRepresentation[]) {
    this._actionsListedSubject.next(request);
  }
}
