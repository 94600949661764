<ui-card [withShadow]="false" *ngIf="!loading">
  <div class="app-registration-update-body">
    <div class="app-registration-update-body--content">
      <div class="app-registration-update-body--title-container">
        <div class="app-registration-update-body--title">
          {{ 'iam.app-registration.upgrade.available-apps' | t }}
        </div>
        <ui-tooltip> {{ 'iam.app-registration.upgrade.call-to-action' | t }}</ui-tooltip>
      </div>

      <div class="margin--small--top margin--small--bottom app-registration-update-body--cards" *ngIf="apps">
        <ng-container *ngFor="let app of apps | keyvalue">       
          <app-registration-update-card [active]="app?.value?.active" (click)="onAppClick(app)">
            <ng-container details>
              <ui-title [name]="'iam.app-registration.upgrade.' + app.key + '-app.name' | t"></ui-title>
              <p>{{ 'iam.app-registration.upgrade.' + app.key + '-app.description' | t }}</p>
            </ng-container>
          </app-registration-update-card>
        </ng-container>
      </div>
    </div>
  </div>
</ui-card>

<ui-card [withShadow]="true" *ngIf="loading">
  <ui-spinner size="large"></ui-spinner>
</ui-card>