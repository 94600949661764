import { ISoarConnector } from "@common/services/api/respond/connectors/connectors-alerting-api.definition";
import { RingType } from "projects/@common/services/api/detect/models/connector.model";

export enum ConnectorTypesEnum {
  DATA = 'data',
  SOAR = 'soar'
}
export class Translations {
  name: string;
  description: string;
}

export class BillingMethod {
  id: string;
  name: string;
}

export class Category {
  id: string;
  name: string;
}

export class Family {
  id: string;
  name: string;
}

export class Function {
  id: string;
  name: string;
}

export class Vendor {
  id: string;
  name: string;
}

export class Filters {
  categories: Category[];
  functions: Function[];
  vendors: Vendor[];
}
export type ICardConnector = Partial<ConnectorData> & Partial<ISoarConnector> & {
  type: ConnectorTypesEnum;
}

export type IConnectorInstance = Partial<ConnectorInstanceDto> & Partial<ISoarConnector> & {
  type: ConnectorTypesEnum;
}

export class ConnectorData {
  id: string;
  name: string;
  translations: Translations;
  billingMethod: BillingMethod;
  category: Category;
  family: Family;
  functions: Function[];
  vendor: Vendor;
  ring?: RingType; // Si jamais on veut afficher le connecteur est dans quel niveau de w/e façon dans le catalogue
}

export class ConnectorInstanceDto extends ConnectorData {
  createdAt: number;
  updatedAt: number;
  connectorId: string;
  organizationId: string;
  secretKeys: string[];
  volumetryExcluded?: boolean;
}

export type GetOrgConnectorUsecaseResponse = {
  connectors: ConnectorData[];
  instances: ConnectorInstanceDto[];
  filters?: Filters;
};

export class ConnectorOrgQueries {
  lang: string;
  name?: string;
  categories?: string[];
  families?: string[];
  functions?: string[];
  vendors?: string[];
}

export interface ConnectorInstanceVolumeHistory {
  timestamp: number;
  volume: number;
}

export interface ConnectorInstanceVolumeEntityHistory {
  timestamp: number;
  entities: Map<string, number>[],
}

export enum ConnectorInstanceVolumetryEntity {
  DEVICE = 'device',
  AWS_ACCOUNT = 'aws_account',
  AZURE_SUBSCRIPTION = 'azure_subscription',
  FIREWALL = 'firewall'
}

export interface ConnectorInstanceVolumeEntityHistoryDto {
  entityType: ConnectorInstanceVolumetryEntity;
  volume: ConnectorInstanceVolumeEntityHistory[];
}

export interface ConnectorInstanceVolumeEntityTotalHistoryDto {
  entityType: ConnectorInstanceVolumetryEntity;
  volume: Map<string, number>;
}
