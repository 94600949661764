import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { AlertsApi } from 'projects/@common/services/api/respond/alerts/alerts.api';
import { ListAlertSimilarItemsRequest } from 'projects/@common/services/api/respond/alerts/alerts.definitions';
import { Alert } from 'projects/@common/services/api/respond/alerts/models/alert';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { ListIncidentSimilarItemsRequest } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { ResponseUtils } from 'projects/@common/utils/response-utils';
import { pruneObject } from 'projects/@common/utils/utils';
import { Subscription } from 'rxjs';
import { ISimilarityFilterEvent, SimilarityItem, SimilarityItemTypeEnum, SimilarityOnAssociateEventData, SimilarityPageContext, SimilarityTableItem } from './similarity.definitions';
import { SimilarityEventsService } from './similarity.events.service';
import { IObservable } from '@common/services/api/respond/observables/observables.definition';

@Component({
  selector: 'similarity',
  templateUrl: './similarity.container.html',
  styleUrls: [ './similarity.container.scss' ],
})
export class SimilarityContainerComponent implements OnInit, OnDestroy {
  @Input() incident: Incident = null;
  @Input() alert: Alert = null;
  @Input() observables: IObservable[] = null;

  public similarityTableData: SimilarityTableItem[];
  public isLoading: boolean;
  public pageContext: SimilarityPageContext;

  private _filters: ISimilarityFilterEvent;
  private _defaultFilters: ISimilarityFilterEvent = { sameOrganization: true };
  private _debounceTimeout: NodeJS.Timeout;
  private _onAssociateSub: Subscription;

  constructor(
    public readonly i18n: I18nService,
    private readonly incidentsApi: IncidentsApi,
    private readonly alertsApi: AlertsApi,
    private readonly similarityEventsService: SimilarityEventsService
  ) { }

  ngOnInit(): void {
    this.initPageContext();
    this.fetchSimilarItems();
    this._onAssociateSub = this.similarityEventsService.onAssociateSuccessEvent$.subscribe((data: SimilarityOnAssociateEventData) => {
      if (this.isIncidentPageContext) {
        this.incident.emitReloadedEvent();
        this.refreshSimilarItemsList();
      }
    });
  }

  ngOnDestroy(): void {
    this._onAssociateSub.unsubscribe();
  }

  public get defaultFilters(): ISimilarityFilterEvent {
    return this._defaultFilters;
  }

  public get totalDisplayed(): number {
    return this.similarityTableData?.length || 0;
  }

  public get isIncidentPageContext(): boolean {
    return this.pageContext.type === SimilarityItemTypeEnum.INCIDENTS;
  }

  public get isAlertPageContext(): boolean {
    return this.pageContext.type === SimilarityItemTypeEnum.ALERTS;
  }

  public handleFilterEvent(event: ISimilarityFilterEvent): void {
    this._filters = event;
    this.debouncedRefresh();
  }

  public refreshSimilarItemsList(): void {
    this.fetchSimilarItems();
  }

  private fetchSimilarItems(): void {
    if (this.isIncidentPageContext) {
      this.fetchIncidentsSimilarItems();
    } else if (this.isAlertPageContext) {
      this.fetchAlertsSimilarItems();
    }
  }

  private initPageContext(): void {
    if (this.incident) {
      this.pageContext = { type: SimilarityItemTypeEnum.INCIDENTS, incident: this.incident };
    } else if (this.alert) {
      this.pageContext = { type: SimilarityItemTypeEnum.ALERTS, alert: this.alert };
    } else {
      throw new Error('SimilarityContainerComponent: Must provide either incident or alert input');
    }
  }

  private fetchIncidentsSimilarItems(): void {
    this.isLoading = true;
    const request: ListIncidentSimilarItemsRequest = {
      ...pruneObject(this._filters ?? this.defaultFilters),
      incidentId: this.incident.id,
    };
    this.incidentsApi.listSimilarItems(this.incident.organizationId, request).subscribe(
      (response) => {
        this.similarityTableData = this.mapListResponseToTableData(response);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  private fetchAlertsSimilarItems(): void {
    this.isLoading = true;
    const request: ListAlertSimilarItemsRequest = {
      ...pruneObject(this._filters ?? this.defaultFilters),
      alertId: this.alert.id,
    };
    this.alertsApi.listSimilarItems(this.alert.organizationId, request).subscribe(
      (response) => {
        this.similarityTableData = this.mapListResponseToTableData(response);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  private debouncedRefresh(): void {
    clearTimeout(this._debounceTimeout);
    this._debounceTimeout = setTimeout(() => {
      this.fetchSimilarItems();
    }, 350);
  }

  private mapListResponseToTableData(response: ResponseUtils<SimilarityItem>): SimilarityTableItem[] {
    return response.getItems().map((similarityItem: SimilarityItem) => ({
      ...similarityItem,
      typeTranslation: similarityItem.type === SimilarityItemTypeEnum.ALERTS ? this.i18n.translate("common.alert") : "Incident",
    }));
  }
}
