import { HttpClient } from "@angular/common/http";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { IPeriodicReportListRequest, IPeriodicReportResponse, IPeriodicReportsApi } from "projects/@common/services/api/respond/periodic-reports/periodic-reports.definitions";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";

export class CustomerPeriodicReportsApi extends EcoApi implements IPeriodicReportsApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listReports(request: IPeriodicReportListRequest, organizationId: string): Observable<ResponseUtils<IPeriodicReportResponse>> {
    // request.type = request.type === PeriodicReportTypeEnum.ALL ? null : request.type;
    // const path = `${this.host}/me/reports`;
    // const queryParams = UrlUtils.jsonToQueryParams(request);
    // const url = queryParams ? `${path}?${queryParams}` : path;
    // return this.http.get<IRespondResponse<IPeriodicReportResponse>>(url)
    //   .pipe(
    //     map(response => new ResponseUtils<IPeriodicReportResponse>(response))
    //   );
    throw new Error("Method not implemented.");
  }

  public getReportDownloadUrl(reportId: string, organizationId?: string): Observable<any> {
    throw new Error("Method not implemented.");
  }
}
