import { Component, Output, EventEmitter, HostListener, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NoticeService } from '@common/modules/notice/notice.service';
import { CertificateUtilityHelper } from 'projects/console-adm/src/app/shared/helpers/certificate-utility.helper';
import { FileUtilityHelper } from 'projects/console-adm/src/app/shared/helpers/file-utility.helper';

@Component({
  selector: 'ui-uploader',
  templateUrl: './ui-uploader.component.html',
  styleUrls: ['./ui-uploader.component.scss'],
  encapsulation: ViewEncapsulation.None,  // Ensure styles are global
})
export class UiUploader {

  @Input() public group: UntypedFormGroup;

  @Input() public label: string;

  @Input() public accept: string;

  @Input() public required: boolean = false;

  @Output('fileChange') public fileChangeEmitter: EventEmitter<File> = new EventEmitter<File>();

  private debounceTimeout: any;

  private readonly handledMimeTypes: any = {
    '.crt': 'application/x-x509-ca-cert',
    '.pem': 'application/x-pem-file',
  };

  @HostListener('change', ['$event.target.files'])
  onFileChange(fileList: FileList) {

    if(!fileList.length) {
      this.noticeService.error(`No file selected. Note that only ${this.accept} extensions are allowed.`);
    }

    const file:File = fileList[0];

    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(() => { // Prevent code execution twice
      this.handleFileChange(file);
      this.group.get('value').setValue(file);  // Set the file as the value in the form control
    }, 300);

  }

  constructor(private noticeService: NoticeService) {}

  /**
   * Handle file and emit if valid
   * @param file
   */
  private handleFileChange(file: File): void {

    this._checkFileValidity(file).then((status:boolean) => {
      return status ? this.fileChangeEmitter.emit(file) : this.noticeService.error(`Invalid file type selected. Only ${this.accept} extensions are allowed.`);
    });

  }

  /**
   * Check if we have a valid file
   * Extract accepted extensions from the "accept" attribute of the input type file
   * Feel free to add more mime types -> handledMimeTypes, see above.
   * @param file
   */
  private async _checkFileValidity(file: File): Promise<boolean> {

    const fileExt: string = FileUtilityHelper.getFileExtension(file);

    // Sometimes, pem files have no mime type, we need to check with other method.
    if(file.type === '' && fileExt === 'pem') {
      return await CertificateUtilityHelper.isValidPemFile(file);
    }
    else // Classic way, with mime types.
    {
      const acceptedExtensions: string[] = this.accept.split(',').map(ext => ext.trim());
      const allowedMimeTypes: string[] = acceptedExtensions.map(ext => this.handledMimeTypes[ext]).filter(mime => mime !== undefined);

      return allowedMimeTypes.includes(file.type);
    }

  }

}
