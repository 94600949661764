import { Injectable } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { debounceTime } from "rxjs/operators";
import { TabNamesEnum } from "../components/incidents-details/incidents-details.component";

export interface IEditingState {
  tabNameEnum: TabNamesEnum;
  itemId: string;
  hasUnsavedChange: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class IncidentDifferenceService {
  private _stateUpdatedSubject = new Subject<void>();
  private _editingStates: IEditingState[] = [];
  private _shouldShowUnsavedWarnings: boolean = false;

  public iconWarning = "assets/favicons/icon_warning_rounded.svg";
  public iconInfo = "assets/favicons/icon_error_v2.svg";
  public stateUpdateObservable = this._stateUpdatedSubject.pipe(debounceTime(250));

  constructor() { }

  public get shouldShowUnsavedWarnings(): boolean {
    return this._shouldShowUnsavedWarnings;
  }

  public get editingStates(): IEditingState[] {
    return this._editingStates;
  }

  public get hasUnsavedChanges(): boolean {
    return this._editingStates.some((change) => change.hasUnsavedChange);
  }

  public canLeavePage(): boolean {
    this.setShouldShowUnsavedWarnings(true);
    return !this.hasUnsavedChanges;
  }

  public reset(): void {
    this._editingStates = [];
    this.setShouldShowUnsavedWarnings(false);
  }

  public handleEditingStates(state: IEditingState): void {
    const item = this._editingStates?.find((item) => item.itemId === state.itemId);
    if (item) {
      item.hasUnsavedChange = state.hasUnsavedChange;
    } else {
      this._editingStates.push(state);
    }
    if (!this.hasUnsavedChanges) {
      this.setShouldShowUnsavedWarnings(false);
    }
    this.emitStateUpdate();
  }

  private setShouldShowUnsavedWarnings(value: boolean): void {
    this._shouldShowUnsavedWarnings = value;
    this.emitStateUpdate();
  }

  private emitStateUpdate(): void {
    this._stateUpdatedSubject.next();
  }
}
