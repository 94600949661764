import { addLicense } from '@amcharts/amcharts4/core';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UiTabs, UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ConnectorsOrgService } from 'projects/@common/services/api/detect/connectorsOrg/connectorsOrg.service';
import { ConnectorInstanceDto, ConnectorOrgDto, ConnectorOrgQueries, Family, Filters, GetOrgConnectorUsecaseResponse } from 'projects/@common/services/api/detect/connectorsOrg/model/ConnectorOrg';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { Subject } from 'rxjs/internal/Subject';

export type ConnectorsByFamily = { connectors: ConnectorOrgDto[], userConnectors: ConnectorOrgDto[], family: Family };
export type UserConnectors = { numberOfInstances:number, connector: ConnectorOrgDto };

@Component({
  selector: 'app-connector-inventory-tab',
  templateUrl: './connector-inventory-tab.component.html',
  styleUrls: [ './connector-inventory-tab.component.scss' ],
})
export class ConnectorInventoryTabComponent implements OnInit, OnDestroy {
  @Input() public organization: IAutomationOrganization;
  @Input() public connectorFilters: Subject<Filters>;
  public connectors: ConnectorOrgDto[] = [];
  public connectorInstances: ConnectorInstanceDto[] = [];
  public isUserConnectorCollapsed: boolean = false;
  @ViewChild('tabs') public tabs: UiTabs;
  @Output() public connectorsDataEvent: EventEmitter<GetOrgConnectorUsecaseResponse> = new EventEmitter();

  private filterSubscription;

  constructor(
    private readonly connectorOrgService: ConnectorsOrgService,
    private readonly i18n: I18nService
  ) {
    addLicense('CH259911983');
  }

  ngOnInit(): void {
    this.connectorFilters.subscribe((value) => this.fetchConnectors(value));
    this.fetchConnectors();
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  public get tabColor(): string {
    return UiTabsColor.lightTabs;
  }

  public get userConnectors(): ConnectorOrgDto[] {
    const userConnectorIds: string[] = [ ...new Set(this.connectorInstances.map((instance) => instance.connectorId)) ];
    return this.connectors.filter((connector) => userConnectorIds.includes(connector.id));
  }

  public getUserConnectorInstance(connectorId: string): ConnectorInstanceDto[] {
    return this.connectorInstances.filter((instance) => instance.connectorId === connectorId);
  }

  public doesUserHaveThisConnector(connectorId: string): boolean {
    return this.connectorInstances.find((instance) => instance.connectorId === connectorId) !== undefined;
  }

  private fetchConnectors(filters?: Filters) {
    const requestQueries: ConnectorOrgQueries = {
      lang: this.i18n.currentLocale,
      categories: filters ? filters.categories.map((c) => c.id) : [],
      functions: filters ? filters.functions.map((f) => f.id) : [],
      vendors: filters ? filters.vendors.map((v) => v.id) : [],
    };
    this.connectorOrgService.getConnectors(this.organization.ecoId, requestQueries)
      .then((data) => {
        this.connectors = data.connectors;
        this.connectorInstances = data.instances;
        this.connectorsDataEvent.emit(data);
      })
      .finally(() => {
        this.isUserConnectorCollapsed = this.userConnectors.length <= 0;
      });
  }
}
