<div
  class="task"
  [ngStyle]="lockedPosition ? {cursor: 'default'} : {cursor: 'pointer'}"
>
  <div
    [ngClass]="isCollapsed ? 'task--header' : 'task--header-open'"
    class="task--header"
    (mouseenter)="isHeaderHovered = true"
    (mouseleave)="isHeaderHovered = false"
    (click)="toggleCollapse()"
  >
    <div class="task--header-left">
      <div class="task--header-icons">
        <div
          class="icon-wrapper"
          cdkDragHandle
          [style.visibility]="isReadonly ? 'hidden' : 'visible'"
        >
          <ui-icon
            class="collapsable-card--header--chevron"
            modifier="DashboardWidget"
            [name]="item.managed ? 'chain' : 'dragdots'"
            size="medium"
          ></ui-icon>
        </div>
        <div class="task--header-icons-status">
          <img [src]="taskIconSrc" />
        </div>
      </div>
      <div class="task--header-title">{{ (item.name | translatedObject) | parameters:parametersValues | async }}</div>
    </div>
    <div class="task--header-actions">
      <div>
        <button
          [id]="'actionsContainer' + iteration"
          *ngIf="taskActions.isEditable"
          ui-button
          (click)="edit(); $event.stopPropagation();"
          color="drawerCloseFlat"
          icon="icon_modify_disabled"
          [custom-icon]="true"
          [isSvg]="true"
          icon-size="small"
          class="hover-opacity-always"
          [style.visibility]="isHeaderHovered ? 'visible' : 'hidden'"
          [tooltip]="'detection.playbook.task.tooltip.actions.edit' | t"
        ></button>
        <button
          *ngIf="taskActions.isDeletable"
          ui-button
          color="drawerCloseFlat"
          icon="delete"
          icon-size="small"
          class="hover-opacity-always"
          [style.visibility]="isHeaderHovered ? 'visible' : 'hidden'"
          (click)="deleteTask(); $event.stopPropagation();"
          [tooltip]="'detection.playbook.task.tooltip.actions.delete' | t"
        ></button>
        <button
          [id]="'actionsContainer' + iteration"
          (click)="ignoreTask(); $event.stopPropagation();"
          *ngIf="taskActions.isIgnorable"
          color="drawerCloseFlat"
          icon="skip"
          icon-size="small"
          ui-button
          class="hover-opacity"
          [style.visibility]="isHeaderHovered || item.ignored ? 'visible' : 'hidden'"
          [ngClass]="
            item.ignored  ? 'action-button-enabled' : 'action-button-disabled'
          "
          [tooltip]="'detection.playbook.task.tooltip.actions.ignored' | t"
        ></button>
        <button
          *ngIf="taskActions.isHidable"
          ui-button
          color="drawerCloseFlat"
          icon="icon_hidden"
          [custom-icon]="true"
          [isSvg]="true"
          icon-size="small"
          [style.visibility]="isHeaderHovered || item.hidden ? 'visible' : 'hidden'"
          [tooltip]="'detection.playbook.task.tooltip.actions.hidden' | t"
          [ngClass]=" item.hidden ? 'action-button-enabled' : 'action-button-disabled'"
          (click)="hideTask(); $event.stopPropagation();"
        ></button>
      </div>
      <ui-icon
        *ngIf="isCollapsable"
        class="collapsable-card--header--chevron"
        modifier="on-header"
        [name]="isCollapsed ? 'chevron-right' : 'chevron-down'"
        size="medium"
      ></ui-icon>
    </div>
  </div>
  <div
    *ngIf="!isCollapsed"
    class="task--content"
  >
    <div
      *ngIf="item.description"
      class="task--description"
    >
      <div class="task--description-icon">
        <img
          [src]="'assets/favicons/icon_playbook_task_description.svg'"
          [alt]="'icon_playbook_task_description'"
        />
      </div>
      <div class="task--description-text">{{ (item.description | translatedObject) | parameters:parametersValues | async
        }}</div>
    </div>
    <div
      *ngIf="showTaskInstructions && item.instruction"
      class="task--instruction"
    >
      <div class="task--instruction-icon">
        <img
          src="assets/favicons/icon_playbook_task_instruction.svg"
          alt="icon_playbook_task_instruction"
        />
      </div>
      <div class="task--instruction-text">{{ (item.instruction | translatedObject) | parameters:parametersValues | async
        }}</div>
    </div>
    <ng-container *ngIf="item.pipeline && item.pipeline.length > 0">
      <div class="task--soar-actions">
        <img
          src="assets/favicons/icon_actions_pale.svg"
          class="icon-actions"
        >
        <div class="tasks-actions-container">
          <div *ngFor="let action of item.pipeline" class="label">
            {{ action.action}}
          </div>
        </div>
      </div>
      <div class="json-raw" *ngIf="isVarMode">
        <ui-expendable-section
          class="expandable-section"
          [type]="expendableTypeEnum.COLUMN"
          [forceExpanded]="false"
          [transparentBackground]="true"
        >
          <header
            class="section-title"
            header
            >
            <div class="title">
              <p>{{'detection.playbook.task.specific.json' | t}}</p>
            </div>
          </header>
          <div class="section-content">
            {{ getItemPipeline(item.pipeline) }}
          </div>
        </ui-expendable-section>
      </div>
    </ng-container>

  </div>
</div>
