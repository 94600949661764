import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { Eco } from 'projects/@common/definitions/eco';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IDescribeQueryResponse, IExecuteQueryResponse } from 'projects/@common/services/api/respond/queries/queries.definitions';
import { clone, normalizeString } from 'projects/@common/utils/utils';

type KeyValue = {
  key: string;
  value: string;
};

export type IResultTableData = {
  result: any;
  resultJSON: string;
  formattedDate: string;
  source: string;
  message: string;
  isRowExpanded: boolean;
  isMouseOver: boolean;
  dynamicProps?: KeyValue[];
  aggregatedValue?: string;
  aggregatedCount?: number;
};

@Component({
  selector: 'respond-queries-result',
  templateUrl: './respond-queries-result.component.html',
  styleUrls: [ './respond-queries-result.component.scss' ],
})
export class RespondQueriesResultComponent implements OnInit {
  @Input() queryResult: IExecuteQueryResponse;
  @Input() isExecutingQuery: boolean;
  @Input() queryDetails: IDescribeQueryResponse;

  public resultTableData: IResultTableData[];
  public showJson: boolean = false;

  constructor(
    public readonly i18n: I18nService,
    private readonly notice: NoticeService,
    private readonly translatedObjectPipe: TranslatedObjectPipe
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.queryResult) {
      this.resultTableData = this.mapQueryResultToTableData(changes.queryResult.currentValue);
    }
  }

  public get isAggregatedQuery(): boolean {
    return !!this.queryResult?.aggregated;
  }

  public get queryName(): ITranslatedField {
    return this.queryDetails?.query?.name;
  }

  public handleRowClick(result: IResultTableData): void {
    result.isRowExpanded = !result.isRowExpanded;
  }

  public copyFullJSON(result: IResultTableData): void {
    const formattedJSON = JSON.stringify(JSON.parse(result.resultJSON), null, 2);
    navigator.clipboard.writeText(formattedJSON);
    this.notice.snack("common.action.copied");
  }

  public exportCsv(): void {
    if (!this.resultTableData?.length) {
      return;
    }
    const tableData = clone(this.resultTableData);

    let headers: string[] = []; // column titles
    let rows: string[][] = []; // data rows
    if (this.isAggregatedQuery) {
      headers = [
        "AGGREGATION",
        ...[
          "respond.queries.result.columns.aggregatedValue",
          "respond.queries.result.columns.aggregatedCount",
          "respond.queries.result.columns.dateLatest",
        ].map((s) => normalizeString(this.i18n.translate(s)).toUpperCase()),
      ];
      rows = tableData.map((row) => [ this.queryDetails.query.aggregation, row.aggregatedValue, row.aggregatedCount, row.formattedDate ]);
    } else {
      headers = [
        "DATE", "SOURCE", "MESSAGE",
        ...tableData[0].dynamicProps.map((prop: KeyValue) => prop.key),
      ];
      rows = tableData.map((row) => {
        const newRow = [ row.formattedDate, row.source, row.message ];
        newRow.push(...tableData[0].dynamicProps.map((prop: KeyValue) => prop.value));
        return newRow;
      });
    }

    // create csv content
    const csvContent = [
      headers.join(","),
      ...rows.map((value) => value.join(",")),
    ].join("\n");

    // download the csv file
    const blob = new Blob([ csvContent ], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.visibility = 'hidden';
    link.setAttribute("href", url);
    const fileName = normalizeString(this.translatedObjectPipe.transform(this.queryDetails.query.name, this.i18n.currentLocale as Eco.Languages));
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private mapQueryResultToTableData(queryResult: IExecuteQueryResponse): IResultTableData[] {
    const items = queryResult?.items;
    if (!items) {
      return [];
    }

    if (this.isAggregatedQuery) {
      return queryResult.items.map((item: IResultTableData) => ({
        result: item,
        resultJSON: JSON.stringify(item["latest"]),
        formattedDate: this.formatTimestamp(item["latest"]['@timestamp']),
        source: item["latest"]["event"].provider,
        message: item["latest"]["message"],
        isRowExpanded: false,
        isMouseOver: false,
        aggregatedValue: item["key"],
        aggregatedCount: item["value"],
      }));
    }

    return queryResult.items.map((item: IResultTableData) => {
      const dynamicProps = item["hilites"];
      delete item["hilites"];
      return {
        result: item,
        resultJSON: JSON.stringify(item),
        formattedDate: this.formatTimestamp(item['@timestamp']),
        source: item["event"].provider,
        message: item["message"],
        isRowExpanded: false,
        isMouseOver: false,
        dynamicProps,
      };
    });
  }

  private formatTimestamp(timestamp: number): string {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }
}
