import { ValidatorFn } from "@angular/forms";
import { LanguageEnum } from "@ui-kit/interfaces/ILanguage";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { UsecaseIndicatorsEnum } from "projects/@respond/usecases/containers/usecases-table-handler";
import { ITranslatedField } from "../../../../definitions/ITranslatedField";
import { IDatasourceUsecaseInfo, ModeFilterEnum } from "../datasources/datasources.definitions";
import { DataConnectorTypes } from '@md.eco/connectors';
import { IFilterRepresentation } from "../filters/filters.definitions";
import { ObservableTypeEnum } from "../observables/observables.definition";
import { AggregationModes, ConditionTypeEnum } from "./usecase.api";
import { MitreAttackTactic, MitreAttackTechnique } from "../../detect/mitre-attack/mitre-attack-api.definition";

export interface IAggregationRule {
  mode: AggregationModes; // if and how the aggregation is handled
  ttl?: number; // time to live of an alert signature (in minutes)
  rules?: string[]; // list of observable types that should be identical to merge alerts.
}

export interface IAction {
  name?: string;
  config?: any;
  action?: string;
  inputs?: any;
  outputs?: any;
  precondition?: any;
  postcondition?: any;
}
export interface ICondition {
  type: ConditionTypeEnum;
  config: any;
}

export interface AlertingUsecaseListItem {
  id: string;
  name: ITranslatedField;
  silenced: boolean;
  silencedAt: number;
  silencedById: string;
  silencedByName: string;
  hasBlacklistedMonitor: boolean;
  hasSilencedMonitor: boolean;
  silencedTranslation?: string;
  description?: ITranslatedField;
  aggregationRule: any;
  pipeline: IAction[];
  priority?: number;
  templateId: string;
  catalogId: string;
  version: number;
  isOutdated?: boolean;
  isPipelineOutdated?: boolean;
  createdAt: number;
  updatedAt: number;
  datasourceType?: string;
  affectedResources?: string;
  whitelistCount?: number;
  filterCount?: number;
  hiddenFilters?: boolean;
}

export interface FieldMappings {
  title?: string;
  description?: string;
  priority?: string;
}

export interface IAlertingUsecaseTemplateConfig {
  datasourceType: DataConnectorTypes;
  trigger?: ICondition;
  pipeline?: IAction[];
  aggregationRule?: IAggregationRule;
}

export enum AlertingUsecaseTemplateVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export enum AlertingUsecaseTemplateType {
  MONITOR = 'MONITOR',
  SIEM = 'SIEM'
}

export interface AlertingUsecaseTemplateRoutingRule {
  forceName?: boolean;
  forceDescription?: boolean;
  forcePriority?: boolean;
  query: any;
  observables?: {
    type: ObservableTypeEnum,
    description: string,
    path: string,
  }[];
}

export interface IAlertingUsecaseTemplate {
  usecaseId: string;
  shortId?: string;
  playbookTemplateIds?: string[];
  fieldMappings?: FieldMappings;
  version?: number;
  name: { [key: string]: string };
  description: { [key: string]: string };
  tags?: { [key: string]: string };
  priority: number;
  affectedResources?: string;
  datasourceTypes: DataConnectorTypes[];
  config: IAlertingUsecaseTemplateConfig[];
  tactics?: string[];
  techniques?: string[];
  visibility?: AlertingUsecaseTemplateVisibility;
  type?: AlertingUsecaseTemplateType;
  routingOrder?: number;
  routingRules?: AlertingUsecaseTemplateRoutingRule;
  createdAt?: number;
  updatedAt?: number;
  id?: string;
}

export interface ISystemListUsecaseTemplateItem {
  instances: number;
  template: IAlertingUsecaseTemplate;
}

export type ISystemListUsecaseTemplatesResponse = IRespondResponse<ISystemListUsecaseTemplateItem  & {
  attack?: {
    tactic: MitreAttackTactic;
    techniques: MitreAttackTechnique[];
  }[] }>;

export enum UsecaseTemplateListOrderByValues {
  USECASE_ID = 'usecaseId',
  INSTANCES = 'instances'
}

export enum ListDirectionEnum {
  asc = 'asc',
  desc = 'desc'
}

export interface ISystemListUsecaseTemplatesRequest {
  from?: number;
  size?: number;
  orderColumn?: UsecaseTemplateListOrderByValues;
  order?: ListDirectionEnum;
  organizationId?: string;
  playbookTemplateId?: string;
  datasourceType?: DataConnectorTypes;
  activated?: string;
  displayTactics?: boolean;
  displayTechniques?: boolean;
  displayAttacks?: boolean;
}

export enum UsecaseOrderByEnum {
  name = 'name',
  catalogId = 'catalogId'
}

export interface IListAlertingUsecasesRequest {
  from?: number;
  alertFrom?: number;
  alertTo?: number;
  size?: number;
  searchName?: string;
  indicators?: UsecaseIndicatorsEnum[];
  orderBy?: UsecaseOrderByEnum;
  direction?: ListDirectionEnum;
  userLanguage?: LanguageEnum;
  silenced?: boolean;
}

export interface UsecaseCreationResponse {
  id?: string;
  name: string;
  organizationId: string;
  humanReadableName: string;
  description: string;
  stackId: string;
  trigger: string;
  pipeline: string;
  aggregationRule?: string;
  templateId?: string;
  createdAt?: number;
  updatedAt?: number;
}

export interface UsecaseCreationInterface {
  usecase: {
    name: string;
    humanReadableName: ITranslatedField;
    description: string;
    trigger: ICondition;
    pipeline?: IAction[];
    aggregationRule?: IAggregationRule;
    templateId: string;
  };
}

export interface IUsecasePipeline {
  trigger?: any;
  pipeline?: any;
  datasourceType?: string;
  aggregationRule?: any;
}

export interface IUpdateUsecasePipeline {
  name: string;
  config: any;
}

export interface IUpdateUsecase {
  id: string;
  usecaseId: string;
  name: ITranslatedField;
  description: ITranslatedField;
  pipeline: IUsecasePipeline[];
  priority: number;
  templateId: string;
  tags: any;
  datasourceType?: string;
  datasourceTypes?: string[];
  activated: boolean;
  config: any;
  organizationId: string;
}

export interface IUpdateUsecaseDetails {
  id: string;
  pipeline: IAction[];
}

export interface IUpdateMonitorParameters {
  monitor: string;
  parameters: any[];
}

export interface IUpdateMonitorDetails {
  id: string;
  parameters: IUpdateMonitorParameters;
}

export interface IMonitorParameters {
  name: string;
  description: ITranslatedField;
  type: string;
  default: any;
  value: any;
}

export interface IMonitor {
  name: string;
  description: ITranslatedField;
  blacklisted: boolean;
  silenced: boolean;
  implementation: string;
  config: any;
  parameters: IMonitorParameters[];
  id: string;
  createdAt: number;
  updatedAt: number;
}

export enum PrioritiesFilterEnum {
  P1 = 'p1',
  P2 = 'p2',
  P3 = 'p3',
  P4 = 'p4'
}

export interface UsecaseInventoryUsecaseQueryParams {
  fulltext?: string;
  mode?: ModeFilterEnum;
  priorities?: PrioritiesFilterEnum[];
  datasourceCategories?: DataConnectorTypes[];
}

export interface IUsecase {
  id: string;
  templateId?: string;
  hasBlacklistedMonitor: boolean;
  hasSilencedMonitor: boolean;
  usecaseId?: string;
  playbookTemplateIds?: string[];
  name: ITranslatedField;
  description: ITranslatedField;
  priority: number;
  tags: any;
  datasourceType: string;
  datasourceTypes: string[];
  datasourceCategory?: string;
  activated: boolean;
  silenced: boolean;
  config: any;
  playbookId?: string;
  organizationId?: string;
  aggregationRule?: IAggregationRule;
  isOutdated?: boolean;
  isPipelineOutdated?: boolean;
  trigger: ICondition;
  pipeline: IAction[];
  affectedResources?: string;
}

export interface IUsecaseResponse {
  instances: number;
  template: IUsecase;
  monitors: any;
}

export interface IUsecaseDescriptionResponse {
  monitors: IMonitor[];
  usecase: IUsecase;
  template?: IUsecase;
}

export interface IDatasourceType {
  name: string
  dataType: DataConnectorTypes,
}

export type ActionConfigurationTemplate<T> = {
  [key in keyof T]?: {
    label: { [lang: string]: string };
    description: { [lang: string]: string };
    type: string;
    required: boolean;
    default?: any;
    values?: { label: { [lang: string]: string }; value: any }[];
    placeholder?: string;
    options?: {
      customValidators?: ValidatorFn[];
    };
    object?: any;
  };
};

export interface ActionTemplate {
  configurationTemplate: ActionConfigurationTemplate<any>;
  name: { [lang: string]: string };
  description: { [lang: string]: string };
  isAlertingModeAction?: boolean;
}

export type IActionListItemResponse = IRespondResponse<{ [key: string]: ActionTemplate }>;

export interface IUsecaseStatsResponse {
  count: number;
}

export interface IUsecaseApi {

  getOrganizationsUsecaseList(
    queryParam: IListAlertingUsecasesRequest
  ): Promise<IRespondResponse<AlertingUsecaseListItem>>;

  getUsecaseList(
    organizationId: string,
    queryParam: IListAlertingUsecasesRequest
  ): Promise<IRespondResponse<AlertingUsecaseListItem>>;

  createUsecase(organizationId: string, usecase: Partial<UsecaseCreationInterface>): Promise<UsecaseCreationResponse>;

  updateUsecase(organizationId: string, usecase: any): Promise<UsecaseCreationResponse>;

  updateOutdatedUsecase(usecase: any): Promise<IUsecase>;

  updateOutdatedUsecasePipeline(usecase: any): Promise<IUsecase>;

  updateUsecaseParameters(organizationId: string, parameters: any): Promise<UsecaseCreationResponse>;

  getUsecaseTemplate(usecaseId: string): Promise<Array<{ name: string; description: ITranslatedField }>>;

  getUsecase(organizationId: string, usecaseId: string): Promise<IUsecaseDescriptionResponse>;

  getUsecaseActions(): Promise<IActionListItemResponse>;

  deleteUsecase(organizationId: string, usecaseId: string): Promise<{ deleted: boolean; organizationId: string; usecaseId: string }>;

  getUsecasesCatalog(
    params: {
      from?: number;
      size?: number;
      activated?: string;
      datasourceType?: string;
      organizationId?: string;
      orderColumn?: string;
      order?: string;
    }
  ): Promise<IRespondResponse<IUsecaseResponse>>;

  getUsecaseCatalog(usecaseId: string): Promise<IUsecaseResponse>;

  getDatasourceFamilyInfo(organizationId: string): Promise<IDatasourceUsecaseInfo[]>;

  getAggregatedUsecasesList(organizationId: string, queryParam?: any): Promise<IRespondResponse<IAlertingUsecaseTemplate>>;

  getUsecaseFilters(organizationId: string, usecaseId: string): Promise<IRespondResponse<IFilterRepresentation>>;

  getDatasourcesDataTypes(): Promise<IRespondResponse<IDatasourceType>>;

  getUsecaseStats(organizationId: string): Promise<IUsecaseStatsResponse>;
}
