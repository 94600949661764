import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '@ui-kit/services/modal.service';
import { Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';
import { WhitelistsService } from '../../whitelists.service';

export interface IWhitelistsDeleteModalData {
  whitelists: Whitelist[];
}

enum AsyncStateEnum {
  "SAVING", "READY"
};

@Component({
  selector: 'whitelists-delete-modal',
  templateUrl: './whitelists-delete-modal.component.html',
  styleUrls: [ './whitelists-delete-modal.component.scss' ],
})
export class WhitelistsDeleteModalComponent implements OnInit {
  public readonly whitelists: Whitelist[] = [];
  public readonly asyncStateEnum = AsyncStateEnum;
  public asyncState: AsyncStateEnum = AsyncStateEnum.READY;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: IWhitelistsDeleteModalData,
    private readonly modalService: ModalService,
    private readonly whitelistsService: WhitelistsService
  ) {
    this.whitelists = this.data.whitelists;
  }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.modalService.closeDialog();
  }

  public async deleteAssets(): Promise<void> {
    this.asyncState = this.asyncStateEnum.SAVING;
    await this.whitelistsService.deleteWhitelists(this.whitelists);
    this.asyncState = this.asyncStateEnum.READY;
    this.modalService.closeDialog();
  }
}
