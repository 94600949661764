import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { IObservable } from 'projects/@common/services/api/respond/observables/observables.definition';
import { IAddItemToTaskRequest, IRemoveItemToTaskRequest, IncidentEventsService } from 'projects/@respond/incident/services/incident.events.service';
import { Subscription } from 'rxjs';
import { IObservablesPageContext } from '../observable-container/observable-container.component';
import { ObservableCellService } from '../../services/observable.cell.service';

@Component({
  selector: 'observable-indicators-cell',
  templateUrl: './observable-indicators-cell.component.html',
  styleUrls: [ './observable-indicators-cell.component.scss' ],
})
export class ObservableIndicatorsCellComponent implements OnInit {
  @Input() observable: IObservable;
  @Input() pageContext: IObservablesPageContext;

  isSimilar: boolean = false;

  useRedFlagFeature: boolean = false;
  isRedFlagged: boolean = false;
  toggleTaskFindingItemSubscription: Subscription;
  redFlagTooltipTaskNames: string;

  constructor(
    @Optional() private readonly incidentEventsService: IncidentEventsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private observableCellService: ObservableCellService
  ) { }

  ngOnInit(): void {
    this.isSimilar = !!this.observable.similar;

    this.useRedFlagFeature = !!this.pageContext.viewParams.useRedFlagFeature;
    if (this.useRedFlagFeature) {
      this.setRedFlagged();
      this.subscribeToToggleTaskFindingItem();
    }
  }

  ngOnDestroy() {
    this.toggleTaskFindingItemSubscription?.unsubscribe();
  }

  private setRedFlagged() {
    const flaggedIncidentTasks = this.observableCellService.getFlaggedIncidentTasks(this.pageContext, this.observable);

    this.isRedFlagged = flaggedIncidentTasks.length > 0;

    if (this.isRedFlagged) {
      this.redFlagTooltipTaskNames = flaggedIncidentTasks;
    }
  }

  /** Listenner for observables added to tasks, as evidences. */
  private subscribeToToggleTaskFindingItem() {
    this.toggleTaskFindingItemSubscription = this.incidentEventsService.toggleTaskMessagesItemObservable$.subscribe((request: IAddItemToTaskRequest | IRemoveItemToTaskRequest) => {
      if (request["incidentObservableId"] === this.observable.id) {
        this.setRedFlagged();
        this.changeDetectorRef.detectChanges();
      }
    });
  }
}
