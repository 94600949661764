<ui-expendable-section
  [type]="expendableTypeEnum.BIG_WHITE"
  [readonly]="loading"
  >
  <header class="phase-header" header>
    <div class="flex-box">
      <p class="header-title" *ngIf="!loading">{{ 'incidents.component.tab.task.preparation.title' | t }} ({{ getConnectorCount() }})</p>
      <div class="header-title-container"><p class="header-title" *ngIf="loading">{{ 'incidents.component.tab.task.preparation.title' | t }}</p>
        <ui-spinner *ngIf="loading" size="small"></ui-spinner></div>
    </div>
  </header>
  <div class="phase-tasks-container" *ngIf="getConnectorCount()">
    <ui-expendable-section
      class="data-connector"
      style="padding-top: 5px;padding-bottom: 5px;"
      *ngFor="let connector of dataConnectors"
      [type]="expendableTypeEnum.MEDIUM_HEADER"
      [readonly]="connector.readonly"
    >
      <header class="task-header" header>
        <div class="flex-box">
          <p class="header-title">{{connector.name}}</p>
        </div>
        <div class="badges">
          <permission-badge *ngIf="connector.health" [value]="connector.health"></permission-badge>
          <permission-badge *ngIf="connector.badge" [value]="connector.badge"></permission-badge>
        </div>
      </header>
      <div class="phase-tasks-container">
        <ui-title [largeFont]="false" [name]="'incidents.component.tab.task.preparation.table.permissions' | t"></ui-title>
        <ui-static-table
          [loading]="loading"
          [dataSource]="connector.actions"
          [columnsDef]="dataColumnsDef"
          [isSelectingOnlyOneAtATime]="false"
          [addPaddingOnFirst]="false"
          [hoverable]="false"
        ></ui-static-table>
        <ui-title [largeFont]="false" [name]="'incidents.component.tab.task.preparation.health' | t" class="health-title"></ui-title>
        <app-connector-instance-health-indicator-indicators
          [hideTitle]="true"
          [currentConnector]="connector.data">
        </app-connector-instance-health-indicator-indicators>
      </div>
    </ui-expendable-section>

    <ui-expendable-section
      class="soar-connector"
      style="padding-top: 5px;padding-bottom: 5px;"
      *ngFor="let connector of soarConnectors"
      [type]="expendableTypeEnum.MEDIUM_HEADER"
    >
      <header class="task-header" header>
        <div class="flex-box">
          <p class="header-title">{{connector.name}}</p>
        </div>
        <div class="badges">
          <permission-badge *ngIf="connector.account" [value]="{label: connector.account, color: 'blue'}"></permission-badge>
          <permission-badge [value]="connector.badge"></permission-badge>
        </div>
      </header>
      <div class="phase-tasks-container">
        <ui-title [largeFont]="false" [name]="'incidents.component.tab.task.preparation.table.permissions' | t"></ui-title>
        <ui-static-table
          [loading]="loading"
          [dataSource]="connector.actions"
          [columnsDef]="soarColumnsDef"
          [isSelectingOnlyOneAtATime]="false"
          [addPaddingOnFirst]="false"
          [hoverable]="false"
        ></ui-static-table>
      </div>
    </ui-expendable-section>
  </div>
  <div class="phase-tasks-container" *ngIf="!getConnectorCount() && !loading">
    <div class="no-connector-found">
      {{ 'incidents.component.tab.task.preparation.noConnectorFound' | t }}
    </div>
  </div>
</ui-expendable-section>
