import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from "projects/@common/modules/notice/notice.service";
import { ConnectorInstanceApiService } from 'projects/@common/services/api/detect/connectorInstances/connectorInstances-api.service';
import { IConnectorIndicatorStatus, IConnectorInstance, IHealthStatus } from 'projects/@common/services/api/detect/models/connectorInstance.model';

@Component({
  selector: 'app-connector-instance-health-indicator-indicators',
  templateUrl: './connector-instance-health-indicator-indicators.component.html',
  styleUrls: [ './connector-instance-health-indicator-indicators.component.scss' ],
})
export class ConnectorInstanceHealthIndicatorIndicatorsComponent implements OnInit, OnChanges {
  @Input()
  public currentConnector: IConnectorInstance;

  @Input()
  public hideTitle = false;

  @Output()
  public errorHealthIndicatorChanged: EventEmitter<IConnectorInstance> = new EventEmitter();

  public errors = [];
  public isErrorsToggle: boolean;
  public isLoading = false;

  constructor(
    public readonly i18n: I18nService,
    private readonly connectorInstanceApi: ConnectorInstanceApiService,
    private readonly noticeService: NoticeService
  ) { }

  ngOnInit(): void {
    this.loadErrorListData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.currentConnector?.isFirstChange()) {
      this.errors = [];
      this.loadErrorListData();
    }
  }

  private loadErrorListData() {
    if (this.currentConnector.health != IHealthStatus.GREEN) {
      this.errors = this.currentConnector.errorIndicators;
      this.errors.map((element) => {
        element.icon = element.status === IConnectorIndicatorStatus.ERROR ? "icon_attention_red" : "icon_attention";
      });
    }
  }
}
