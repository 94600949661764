<ng-container *ngIf="!isLoading">
  <notification-frequency
    [currentSettings]="currentSettings"
    [isLoading]="isLoading"
  ></notification-frequency>

  <app-shares-duration-tolerance
    [currentSettings]="currentSettings"
    [isLoading]="isLoading"
  ></app-shares-duration-tolerance>

  <shares-silencing-duration 
    [currentSettings]="currentSettings" 
    [isLoading]="isLoading" 
   ></shares-silencing-duration>

  <o365-labels
    [currentSettings]="currentSettings" 
    [isLoading]="isLoading" 
    ></o365-labels>
</ng-container>

<ng-container *ngIf="isLoading">
  <ui-spinner size="medium"></ui-spinner>
</ng-container>
