import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Eco } from 'projects/@common/definitions/eco';
import { OrgTypeEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';
import { UrlUtils } from 'projects/@common/utils/utils';
import { EcoUrlService } from '../core/url.service';
import { IamApi, RefreshTokenResponse } from './iam.api.definitions';

@Injectable({
  providedIn: 'root',
})
export class IamApiService {
  private readonly prefix = '/iam';
  private readonly host: string;

  constructor(
    private http: HttpClient,
    url: EcoUrlService
  ) {
    this.host = url.baseUrl + this.prefix;
    // this.host = 'http://localhost:3000';
  }

  public async listAllServices(): Promise<any> {
    const url = `${this.host}/service`;
    return this.http.get(url).toPromise();
  }

  public async listOrganizationServices(organizationId: string): Promise<any> {
    const url = `${this.host}/service/${organizationId}`;
    return this.http.get(url).toPromise();
  }

  public async login(request: IamApi.Login.request): Promise<IamApi.Login.response> {
    const params = UrlUtils.jsonToQueryParams(request);
    return this.http.get<IamApi.Login.response>(`${this.host}/login?${params}`).toPromise();
  }

  public async refreshToken(): Promise<RefreshTokenResponse> {
    return this.http.get<RefreshTokenResponse>(`${this.host}/refresh`).toPromise();
  }

  public async authorizationUrl(request: IamApi.AuthorizationUrl.request): Promise<IamApi.AuthorizationUrl.response> {
    const query = UrlUtils.jsonToQueryParams(request);
    const url = `${this.host}/authorization?${query}`;
    const res = await this.http.get<IamApi.AuthorizationUrl.response>(url).toPromise();
    return res;
  }

  public async describePermissions(request: IamApi.DescribePermissions.request): Promise<IamApi.DescribePermissions.response> {
    const url = `${this.host}/permissions`;
    const res = await this.http.post<IamApi.DescribePermissions.response>(url, request).toPromise();
    return res;
  }

  public async listManagedOrganizations(params?: {
    from?: number;
    size?: number;
    searchName?: string;
    order?: string;
    includeManagerOrganization?: boolean;
    oraganizationType?: OrgTypeEnum;
  }): Promise<Eco.List<Eco.IOrganization>> {
    const query = UrlUtils.jsonToQueryParams(params);
    const url = `${this.host}/organizations?${query}`;
    const res = await this.http.get<Eco.List<Eco.IOrganization>>(url).toPromise();
    return res;
  }

  public async describeOrganization(id: IamApi.DescribeOrganization.request): Promise<IamApi.DescribeOrganization.response> {
    const url = `${this.host}/organizations/${id}`;
    const res = await this.http.get<IamApi.DescribeOrganization.response>(url).toPromise();
    return res;
  }

  public async createOrganization(form: IamApi.CreateOrganization.request) {
    const url = `${this.host}/organizations`;
    const res = await this.http.post(url, form).toPromise();
    return res;
  }

  public async updateOrganization(organizationId: string, req: IamApi.UpdateOrganization.request) {
    const url = `${this.host}/organizations/${organizationId}`;
    const res = await this.http.put(url, req).toPromise();
    return res;
  }

  public async getSignupUrl(request: IamApi.SignupUrl.request): Promise<IamApi.SignupUrl.response> {
    const res = await this.http.get<IamApi.SignupUrl.response>(`${this.host}/signup?${UrlUtils.jsonToQueryParams(request)}`).toPromise();
    return res;
  }

  public async signup(request: IamApi.Signup.request): Promise<IamApi.Signup.response> {
    const res = await this.http.post<IamApi.Signup.response>(`${this.host}/signup`, request).toPromise();
    return res;
  }

  public async updateAdminConsent(request: IamApi.UpdateAdminConsent.request): Promise<IamApi.UpdateAdminConsent.response> {
    const res = await this.http.post<IamApi.UpdateAdminConsent.response>(`${this.host}/adminconsent`, request).toPromise();
    return res;
  }

  public async listUsers(request?: IamApi.ListUsers.request): Promise<IamApi.ListUsers.response> {
    // Prevents every possible parameter appearing as undefined in the URL
    const params: any = {};

    if (request?.from) {
      params.from = request.from;
    }

    if (request?.search) {
      params.search = request.search;
    }

    if (request?.size) {
      params.size = request.size.toString();
    }

    const res = await this.http.get<IamApi.ListUsers.response>(`${this.host}/identities/users`, { params }).toPromise();

    return res;
  }

  public async updateOnboardingAdmins(id: string, form: { ecoAdminUsers: string[]; }) {
    const url = `${this.host}/organizations/${id}/onboardingAdmins`;
    return this.http.post(url, form).toPromise();
  }
}
