import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { IObservable } from 'projects/@common/services/api/respond/observables/observables.definition';
import { IAddItemToTaskRequest, IRemoveItemToTaskRequest, IncidentEventsService } from 'projects/@respond/incident/services/incident.events.service';
import { Subscription } from 'rxjs';
import { IObservablesPageContext } from '../observable-container/observable-container.component';

@Component({
  selector: 'observable-indicators-cell',
  templateUrl: './observable-indicators-cell.component.html',
  styleUrls: [ './observable-indicators-cell.component.scss' ],
})
export class ObservableIndicatorsCellComponent implements OnInit {
  @Input() observable: IObservable;
  @Input() pageContext: IObservablesPageContext;

  isSimilar: boolean = false;

  useRedFlagFeature: boolean = false;
  isRedFlagged: boolean = false;
  toggleTaskFindingItemSubscription: Subscription;
  redFlagTooltipTaskNames: string;

  constructor(
    @Optional() private readonly incidentEventsService: IncidentEventsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translatedObjectPipe: TranslatedObjectPipe
  ) { }

  ngOnInit(): void {
    this.isSimilar = !!this.observable.similar;

    this.useRedFlagFeature = !!this.pageContext.viewParams.useRedFlagFeature;
    if (this.useRedFlagFeature) {
      this.setRedFlagged();
      this.subscribeToToggleTaskFindingItem();
    }
  }

  ngOnDestroy() {
    this.toggleTaskFindingItemSubscription?.unsubscribe();
  }

  private setRedFlagged() {
    if (this.pageContext.incident) {
      const flaggedIncidentTasks = this.pageContext.incident.tasks
        .filter((incidentTask) => incidentTask.observables.includes(this.observable.id))
        .map((incidentTask) => this.translatedObjectPipe.transform(incidentTask.task.playbookTask.name)).join(', ');
      this.isRedFlagged = flaggedIncidentTasks?.length > 0;
      if (this.isRedFlagged) {
        this.redFlagTooltipTaskNames = flaggedIncidentTasks;
      }
    }
  }

  private subscribeToToggleTaskFindingItem() {
    this.toggleTaskFindingItemSubscription = this.incidentEventsService.toggleTaskMessagesItemObservable$.subscribe((request: IAddItemToTaskRequest | IRemoveItemToTaskRequest) => {
      if (request["incidentObservableId"] === this.observable.id) {
        this.setRedFlagged();
        this.changeDetectorRef.detectChanges();
      }
    });
  }
}
