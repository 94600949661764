import { Component, Input } from '@angular/core';
import { MitreAttackGenericData } from 'projects/@common/services/api/detect/mitre-attack/mitre-attack-api.definition';

@Component({
  selector: 'playbook-mitre-attack-container',
  templateUrl: './playbook-mitre-attack-container.component.html',
  styleUrls: [ './playbook-mitre-attack-container.component.scss' ],
})
export class PlaybookMitreAttackContainerComponent {
  @Input() public attacks: MitreAttackGenericData[] = [];

  constructor() { }
}
