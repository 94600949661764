import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IChronologyTimestamp, SystemTimestampTypes, TimestampSources, TimestampTypes } from "../definitions/chronologyTimestamp.definitions";
import { PlaybookTaskPhases } from "@common/services/api/respond/playbooks/playbooks.definitions";
import { IncidentTaskStatus } from "@common/services/api/respond/incidentTasks/incidentTasks.definitions";

export class ChronologyTimestamp implements IChronologyTimestamp {
  id: string;
  incidentId: string;
  type: TimestampTypes;
  timestamp: number;
  editable: boolean;
  deletable: boolean;
  source: TimestampSources;

  constructor(props: IChronologyTimestamp) {
    this.id = props.id;
    this.incidentId = props.incidentId;
    this.type = props.type;
    this.timestamp = props.timestamp;
    this.editable = props.editable;
    this.deletable = props.deletable;
    this.source = props.source;
  }

  public get isUserTimestamp(): boolean {
    return this.source === TimestampSources.USER;
  }

  public static getIncidentValueFromTimestampType(incident: Incident, type: SystemTimestampTypes): number {
    switch (type) {
      case SystemTimestampTypes.INCIDENT_FIRST_ALERT:
        return incident["firstAlertTimestamp"];
      case SystemTimestampTypes.INCIDENT_CREATED:
        return incident["createdAt"];
      case SystemTimestampTypes.INCIDENT_STARTED:
        return incident.phaseTimestamps?.IDENTIFICATION?.startedAt;
      case SystemTimestampTypes.INCIDENT_CONTAINED:
        return this.isAllPhaseTaskNonApplicable(incident, PlaybookTaskPhases.CONTAINMENT) ? null: incident.phaseTimestamps?.CONTAINMENT?.closedAt;
      case SystemTimestampTypes.INCIDENT_REMEDIATED:
        return this.isAllPhaseTaskNonApplicable(incident, PlaybookTaskPhases.REMEDIATION) ? null: incident.phaseTimestamps?.REMEDIATION?.closedAt;
    };
  }

  public static isAllPhaseTaskNonApplicable(incident: Incident, phase: PlaybookTaskPhases): boolean {
    return incident.tasks
      ?.filter((task) => task.task.phase === phase)
      ?.every((task) => task.task.status === IncidentTaskStatus.NOT_APPLICABLE);
  }
}
