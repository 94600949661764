<div
  page
  [appPagePaths]="['org', 'mdTools', 'secretsStore']"
  [tooltipTitle]="'secrets.tooltipTitle'"
  (onScrollToBottom)="loadSecretForOrganizationId()"
  (organizationChanged)="organizationChanged($event)"
  [searchMode]="searchMode"
  >
  <ng-container *ngIf="isVarMode && !organizationEcoId">
    <span class="no-org-transition"
      >{{ 'organizations_select.notSelected.message' | t }}</span
    >
  </ng-container>

  <ng-container toolbar *ngIf="!isVarMode || organizationEcoId">
    <ui-table-total-tool [total]="total"></ui-table-total-tool>
    <ui-table-search-tool
    [query]="searchName"
    [placeholder]="'ui.table-search-tool.placeholder' | t"
    (clear)="handleSearchClear()"
    (query)="handleSearchQuery($event)"
  ></ui-table-search-tool>
    <ui-table-filter-tool
    [tableFilterData]="secretIntegrationsTableFilter"
    (select)="handleDataFilter($event)"
  ></ui-table-filter-tool>
    <ui-table-refresh-tool
      [loading]="isLoading"
      (click)="handleRefresh()"
    ></ui-table-refresh-tool>
  </ng-container>

  <div *ngIf="!isVarMode || organizationEcoId">
    <ui-card class="page">
      <button
        *ngIf="canCreateSecret"
        ui-button
        color="linkFlat"
        icon="plus"
        icon-size="small"
        (click)="create()">
        {{ "secrets.create.title" | t }}
      </button>

      <div>
        <ng-container *ngIf="secretStoreData?.length > 0">
          <ui-static-table
            [emptyMessage]="'ui.table-infinite.empty' | t"
            [columnsDef]="COLUMN_DEF"
            [dataSource]="secretStoreData"
            [isCustomSorting]="true"
            [sort]="sort"
            [useEmptyMessage]="false"
            [addPaddingOnFirst]="false"
            (handleCustomSort)="handleSorting($event.sortColumn, $event.sortDirection)"
            (on-row-click)="handleRowClick($event)"
            [direction]="direction"
            [locale]="i18n.currentLocale"
          ></ui-static-table>
        </ng-container>

        <ng-container *ngIf="!isLoading && total <= 0">
          <span>{{ 'ui.table-infinite.empty' | t }}</span>
        </ng-container>
        <div class="spinner" *ngIf="isLoading || isLoadingMore">
          <ui-spinner size="medium" modifier="secondary"></ui-spinner>
        </div>
      </div>
    </ui-card>
  </div>
</div>

