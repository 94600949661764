<div
  page
  [appPagePaths]="['org', 'settings']"
  [disableOrganizationSelector]="true"
>
<div *ngIf="hasNoneOfNecessaryFeature" class="container">
  <p>{{ 'features.settings.no.features' | t }}</p>
</div>
  <div *ngIf="!hasNoneOfNecessaryFeature" class="container">
    <ui-tabs
      [color]="uiTabsColor.lightTabs"
      [borderBelowHeader]="true"
      [hasNoLeftPadding]="true"
      [hasWhiteBackroundColor]="false"
    >
      <div class="tab-container" *display="{flags: [featureFlagEnum.SHARES_MANAGEMENT]}" >
        <ui-tab
          [tabTitle]="'settings.tab.shares' | t"
          tabImageActive="assets/favicons/icon_menu_shares_tab_active_cropped.svg"
          tabImageInactive="assets/favicons/icon_menu_shares_tab_cropped.svg"
        >
          <ng-template *ngTemplateOutlet="sharesSettings"></ng-template>
        </ui-tab>
      </div>

            <div class="tab-container" *display="{flags: [featureFlagEnum.DLP_MANAGEMENT]}" >
        <ui-tab
          [tabTitle]="'settings.tab.dlp' | t"
          tabImageActive="assets/favicons/icon_pause_file_green.svg"
          tabImageInactive="assets/favicons/icon_pause_file_gray.svg"
        >
          <ng-template *ngTemplateOutlet="dlpSettings"></ng-template>
        </ui-tab>
      </div>


      <div class="tab-container" *display="{flags: [featureFlagEnum.SHARES_MANAGEMENT]}" >
        <ui-tab
          [tabTitle]="'settings.tab.downloads' | t "
          tabImageActive="assets/favicons/icon_download_tab_active_cropped.svg"
          tabImageInactive="assets/favicons/icon_download_tab_cropped.svg"
        >
          <ng-template *ngTemplateOutlet="downloadsSettings"></ng-template>
        </ui-tab>
      </div>

      <div
        class="tab-container"
        *display="{flags: [featureFlagEnum.RESOURCE_MANAGEMENT]}"
      >
        <ui-tab
          [tabTitle]="'settings.tab.resources' | t"
          tabImageActive="assets/favicons/icon_pause_ressource_green_cropped.svg"
          tabImageInactive="assets/favicons/icon_pause_ressource_gray_cropped.svg" >
          <ng-template *ngTemplateOutlet="resourcesSettingsTemplate"></ng-template>
        </ui-tab>
      </div>

      <div
        class="tab-container"
        *display="{flags: [featureFlagEnum.RESOURCE_MANAGEMENT]}"
      >
        <ui-tab
          [tabTitle]="'settings.tab.general' | t"
          tabImageActive="assets/favicons/icon_sliders_green_cropped.svg"
          tabImageInactive="assets/favicons/icon_sliders_cropped.svg"
        >
          <ng-template *ngTemplateOutlet="generalSettingsTemplate"></ng-template>
        </ui-tab>
      </div>

      <div
        class="tab-container"
        *display="{flags: [featureFlagEnum.GUESTS_EXPIRATION_MANAGEMENT]}"
      >
        <ui-tab
          [tabTitle]="'guest.usersManagement.title' | t"
          tabImageActive="assets/favicons/icon_tab_guest_users_active.svg"
          tabImageInactive="assets/favicons/icon_tab_guest_users.svg"
        >
          <ng-template *ngTemplateOutlet="guestsSettingsTemplate"></ng-template>
        </ui-tab>
      </div>
    </ui-tabs>
  </div>
</div>

<ng-template #sharesSettings>
  <div class="cards-container">
    <shares-settings
      [currentSettings]="currentSettings"
      [isLoading]="isLoading"
    ></shares-settings>
  </div>
</ng-template>

<ng-template #dlpSettings>
  <div class="cards-container">
    <dlp-settings
      [currentSettings]="currentSettings"
      [isLoading]="isLoading"
    ></dlp-settings>
  </div>
</ng-template>

<ng-template #downloadsSettings>
  <div class="cards-container">
    <downloads-settings
      [currentSettings]="currentSettings"
      [isLoading]="isLoading"
    ></downloads-settings>
  </div>
</ng-template>

<ng-template #resourcesSettingsTemplate>
  <div class="cards-container">
    <resources-settings
      [currentSettings]="currentSettings"
      [isLoading]="isLoading"
    ></resources-settings>
  </div>
</ng-template>

<ng-template #generalSettingsTemplate>
  <div class="cards-container">
    <general-settings
      [currentSettings]="currentSettings"
      [isLoading]="isLoading"
    ></general-settings>
  </div>
</ng-template>

<ng-template #guestsSettingsTemplate>
  <div class="cards-container">
    <app-guest-settings
      [currentSettings]="currentSettings"
      [isLoading]="isLoading"
    ></app-guest-settings>
  </div>
</ng-template>
