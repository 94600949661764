<widget-container
  [title]="'respond.dashboard.incident.chart.title' | t"
  [locale]="i18n.currentLocale"
  [withBottomMargin]="true"
  [withTimeRange]="true"
  [timeRanges]="TIME_RANGE_ENUM"
  [timeRangesDefault]="currentTimeRange"
  (selectedTimerange)="handleTimeRangeChange($event)">
  <div class="content-container" [style.display]="isLoading ? 'none': 'flex'">
    <app-date-interval class="date-interval-section"
      [period]="dateRange"
      [isGMT]="true"
      dateFormatEnum="shortWithUTC"
    ></app-date-interval>

    <div class="badge-container" *ngIf="chartData.length > 0">
      <capacity-card
        class="card-border"
        numberColor="#5ECC99"
        [capacityNumber]="numberIncidentOpen"
        [topDescription]="'respond.dashboard.incident.opened.description.top' | t"
        [bottomDescription]="'respond.dashboard.incident.opened.description.bottom' | t"
      ></capacity-card>
      <capacity-card
        class="card-border"
        numberColor="#004C2A"
        [capacityNumber]="numberIncidentClose"
        [topDescription]="'respond.dashboard.incident.closed.description.top' | t"
        [bottomDescription]="'respond.dashboard.incident.closed.description.bottom' | t"
      ></capacity-card>
    </div>
  
    <!-- Graph -->
    <div class="chart-container">
      <div [attr.id]="'INCIDENTS_PER_DAY_GRAPH_ID'" [hidden]="chartData.length === 0"></div>
      <div *ngIf="chartData.length === 0" class="graph-without-data-container">
        {{ 'dashboard.admin.noData' | t }}
      </div>
  
      <div class="graph--footer" *ngIf="chartData.length > 0">
        <p class="chart-description">{{ 'respond.dashboard.incident.var.chart.timeline.description' | t}}</p>
        <a routerLink="/respond/incidents" [queryParams]="dateRange" class="chart-link" *display="{ permissions: ['can_list_incidents'] }">
          {{ 'respond.dashboard.incident.var.chart.link' | t }}
        </a>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isLoading">
    <ui-spinner size="medium" modifier="secondary"></ui-spinner>
  </ng-container>
</widget-container>