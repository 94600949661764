<button
  ui-button
  color="standard"
  [disabled]="currentState === statesEnum.SAVING"
  (click)="emitSave()"
>
  <ui-spinner
    *ngIf="currentState === statesEnum.SAVING"
    size="small"
  ></ui-spinner>
  {{ 'common.action.update' | t }}
</button>

<button
  ui-button
  color="secondary"
  [disabled]="currentState === statesEnum.SAVING"
  (click)="emitCancel()"
>
  {{ 'common.action.cancel' | t }}
</button>