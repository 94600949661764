import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { AutocompleteTypes } from "@ui-kit/components/autocomplete/autocomplete.component";
import { UiTableDateToolComponent } from "@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component";
import { I18nService } from "@ui-kit/services/I18nService";
import { DateIntervalComponent } from "projects/@common/components/date-interval/date-interval.component";
import { Eco } from "projects/@common/definitions/eco";
import { HistoriesFiltersService } from "./histories-filters.service";
import { HistoriesService } from "../../histories.service";
import { Notice, NoticeLevels, NoticeService } from "@common/modules/notice/notice.service";
import { ConnectorsApiOrganizationService } from "@common/services/api/detect/organizations/connectors-api-organizations";
import { HistoriesApi } from "@common/services/api/respond/histories/histories.api";
import { GetHistoriesRequest } from "@common/services/api/respond/histories/models/getHistoriesRequest";
import { Subscription } from "rxjs";
import { UiMultiSelect } from "@ui-kit/components/ui-multi-select/ui-multi-select.component";

@Component({
  selector: 'histories-filters',
  templateUrl: './histories-filters.component.html',
  styleUrls: [ './histories-filters.component.scss' ],
  providers: [ HistoriesFiltersService ],
})
export class HistoriesFiltersComponent implements OnInit, OnDestroy {
  @ViewChildren(UiMultiSelect) selectors!: QueryList<UiMultiSelect>;
  @ViewChild(DateIntervalComponent) dateIntervalComponent!: DateIntervalComponent;
  @ViewChild(UiTableDateToolComponent) datePicker!: UiTableDateToolComponent;

  @Input()
  isLoading: boolean;

  @Input()
  public locale: Eco.Languages;

  public readonly autocompleteTypes = AutocompleteTypes;
  private subscription: Subscription;

  constructor(
    private readonly i18n: I18nService, 
    private readonly historiesApi: HistoriesApi,
    private readonly notice: NoticeService,
    public historiesFiltersService: HistoriesFiltersService, 
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    
  public historiesService: HistoriesService) { 
  }

  ngOnInit(): void {
    this.subscription = this.historiesService.onFilterChange((request: GetHistoriesRequest) => this.fetchOwners(request));
    this.historiesFiltersService.initializeHistoriesFilters();
    this.fetchOrganizations();
    this.historiesFiltersService.emitNewQueries();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get total(): number {
    return this.historiesService.total;
  }

  public handleManualRefreshClick(): void {
    this.dateIntervalComponent.updateFormattedTimestamp(this.historiesFiltersService.dateFilter);
    this.historiesFiltersService.emitNewQueries();
  }

  public clearAllFilters(): void {
    this.historiesFiltersService.clearAllFilters();
    this.datePicker?.clearAll();
    this.selectors.forEach((selector) => selector.clearSelection());
  }
  
  public fetchOwners(request: GetHistoriesRequest): void {
    if (request?.from === 0) {
      this.historiesApi.listUsers(request).subscribe(
        (response) => this.setOwners(response),
        () => this.notice.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR))
      );
    }
  }

  public fetchOrganizations(): void {
    this.connectorsApiOrganizationService.getManagedOrganizations({ from: 0, size: 1000 })
      .then((response) => this.historiesFiltersService.initOrganizationsMultiSelect(response.items))
      .catch(() => this.notice.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)));
  }

  private setOwners(owners: {identityName: string; identityId: string}[]) {
    this.historiesFiltersService.initOwnersMultiSelect(owners.map((identity) => ({ownerId: identity.identityId, ownerName: identity.identityName})));    
  }
}
