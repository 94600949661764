<div class="drawer-detail--scrollable visible tab-menu">
  <div class="drawer-detail--content">
    <ui-title [name]="'common.properties' | t"></ui-title>
    <div class="margin--small--top margin--small--bottom">
      <ui-field-group>
        <ui-static
          [label]="'iam.roles.description.label' | t"
          [value]="profile.description | translatedObject"
        ></ui-static>
      </ui-field-group>
    </div>
  </div>
</div>
