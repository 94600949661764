import { Component, Input } from '@angular/core';
import { Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';
import { IWhitelistStatus } from 'projects/@common/services/api/respond/whitelists/whitelists.definitions';

@Component({
  selector: 'whitelist-status',
  templateUrl: './whitelist-status.component.html',
  styleUrls: [ './whitelist-status.component.scss' ],
})
export class WhitelistStatusComponent {
  @Input() whitelist: Whitelist;

  public get status(): IWhitelistStatus {
    return this.whitelist.status;
  }
}
