<div class="row">
  <div class="owners">
    <ui-avatar
      [tooltip]="resource.owner.email || resource.name"
      [user]="resource.owner"
      size="tiny"
    ></ui-avatar>
    <p [tooltip]="resource.owner.email || resource.name">{{resource.name}}</p>
  </div>
  <div class="amount">
    <p>{{resource.amount}}</p>
  </div>
</div>