<div class="card-content">
  <settings-edition-card
    [icon]="'icon_notification_active_cropped.svg'"
    [title]="'settings.shares.notification.card.title' | t"
    [canSave]="isValid"
    (saveEvent)="save()"
    (cancelEvent)="cancel()"
    (toggleEditingEvent)="isEditing=$event"
  >
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <p
      [innerHTML]="'settings.shares.notification.card.description'| t: {'frequency': ('settings.notification.frequency.' + dlpNotificationForm?.value.frequency) | t}">
    </p>
    <div class="labels">
      <ng-container [ngSwitch]="dlpNotificationForm?.value.frequency">
        <ng-container *ngSwitchCase="notificationFrequencyEnum.DAILY">
          <div class="label">
            <p
              [innerHTML]=" 'settings.shares.notification.card.daily'| t: { 'time': dlpNotificationForm?.value.time }">
            </p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="notificationFrequencyEnum.WEEKLY">
          <div class="label">
            <p class="identifier">{{ ('settings.notification.frequency.label.day' | t) + ':'}}</p>
            <p><strong>{{ ('common.weekdays.' + dlpNotificationForm?.value.weekDay) | t }}</strong></p>
          </div>
          <div class="label">
            <p class="identifier">{{ ('settings.notification.frequency.label.hour' | t) + ':'}}</p>
            <p><strong>{{dlpNotificationForm?.value.time + ':00'}}</strong></p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="notificationFrequencyEnum.MONTHLY">
          <div class="label">
            <p class="identifier">{{ ('settings.notification.frequency.label.day' | t) + ':'}}</p>
            <p
              [innerHTML]="'settings.notification.frequency.label.month.sublabel' | t: {'dayInMonth': dlpNotificationForm?.value.weekDay | indexSuffix, 'time':dlpNotificationForm?.value.time }">
            </p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="notificationFrequencyEnum.X_DAYS">
          <div class="label">
            <p
              [innerHTML]="'settings.notification.frequency.label.X.sublabel' | t: {'X': dlpNotificationForm?.value.weekDay, 'time': dlpNotificationForm?.value.time}">
            </p>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #editing>
  <p>{{ 'settings.shares.notification.card.description.editing' | t }}</p>
  <button
    *ngIf="!isDefaultValue"
    class="default-values"
    (click)="restoreDefaults()"
  >{{ 'settings.notification.frequency.default.values' | t }}</button>
  <form
    class="form"
    ui-form
    *ngIf="!isLoading; else loading"
    [formGroup]="dlpNotificationForm"
  >
    <ui-field-group class="left-section">
      <ui-select
        [options]="frequencyOptions"
        name="frequency"
        control="frequency"
        [group]="dlpNotificationForm"
        [label]="'settings.notification.frequency.label.frequency' | t"
        [required]="true"
        (optionClick)="frequencyChanged($event)"
      >
      </ui-select>
    </ui-field-group>
    <div class="right-section">
      <ui-field-group class="picker">
        <ui-select
          class="hours"
          [options]="hourOptions"
          name="time"
          control="time"
          [group]="dlpNotificationForm"
          [label]="'settings.notification.frequency.label.hour' | t"
          [required]="true"
        >
        </ui-select>
        <ui-select
          *ngIf="dlpNotificationForm?.value.frequency === notificationFrequencyEnum.MONTHLY"
          [options]="dayOptions"
          name="weekDay"
          control="weekDay"
          [group]="dlpNotificationForm"
          [label]="'settings.notification.frequency.label.month' | t"
          [required]="true"
        >
        </ui-select>
        <ui-switch-field-static
          *ngIf="dlpNotificationForm?.value.frequency === notificationFrequencyEnum.X_DAYS"
          [isEditing]="isEditing"
          [label]="'settings.shares.notification.days.between' | t"
          name="weekDay"
          control="weekDay"
          [locale]="i18n.currentLocale"
          [value]="7"
          [required]="true"
          [group]="dlpNotificationForm"
          maxlength="3"
        >
        </ui-switch-field-static>
      </ui-field-group>
      <mat-radio-group
        formControlName="weekDay"
        *ngIf="dlpNotificationForm?.value.frequency === notificationFrequencyEnum.WEEKLY"
      >
        <mat-radio-button
          *ngFor="let i of generateRange(0, 6); let index = index"
          (click)="selectDayOfTheWeek(index)"
          [value]="index"
          labelPosition="before"
        >
          <div>
            {{ ('common.weekdays.' + index) | t }}
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </form>
</ng-template>
