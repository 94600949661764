import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SecretStoreContainer } from './containers/secret-store.container';

const routes: Routes = [
  {
    path: '',
    component: SecretStoreContainer,
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class SecretStoreRoutingModule {}
