<div class="container">
  <div class="container--inner">
    <div class="container--inner--image-wrapper">
      <img
        class="container--inner--image-wrapper--image"
        [src]="'assets/favicons/' + data.logo + '.svg'"
      />
      <h2 class="container--inner--image-wrapper--title">
        {{ data.title | t }}
      </h2>
    </div>

    <div class="container--inner--description">
      <p class="container--inner--description--text container--inner--description--active-number">
        {{ 'detection.datasource.page.card.active.number' | t: { activatedNumber: data.activatedNumber } }}
      </p>
      <p class="container--inner--description--text">
        {{'detection.datasource.page.card.activationAt'
            | t: { activatedAt: date.activatedAt }
        }}
      </p>
      <p class="container--inner--description--text">
        {{
          'detection.datasource.page.card.updatedAt'
            | t: { updatedAt: date.updatedAt }
        }}
      </p>
    </div>
  </div>

  <div class="container--footer">
    <ui-status-badge [state]="data.state" [locale]="i18n.currentLocale"></ui-status-badge>
  </div>
</div>
