import { Component, Input, OnInit } from "@angular/core";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { PlaybooksApiService } from "projects/@common/services/api/respond/playbooks/playbooks.api";
import { IPlaybookDetail } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";

@Component({
  selector: 'app-playbook-section',
  templateUrl: './playbook-section.component.html',
  styleUrls: [ './playbook-section.component.scss' ],
})
export class PlaybookSectionComponent implements OnInit {
  @Input()
    incident: Incident;

  @Input()
    organizationId: string;

  public playbook: IPlaybookDetail;

  constructor(private readonly playbookApi: PlaybooksApiService) { }

  ngOnInit(): void {
    this.playbookApi.getPlaybook(this.organizationId, this.incident.playbookId)
      .then((value) => this.playbook = value);
  }
}
