import { Component, Input } from '@angular/core';

@Component({
  selector: 'labels-protection-information',
  templateUrl: './labels-protection-information.component.html',
  styleUrls: [ './labels-protection-information.component.scss' ],
})
export class LabelsProtectionInformationComponent {
  @Input() public hasProtection;
  constructor() {}
}
