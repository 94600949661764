import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { UrlUtils } from 'projects/@common/utils/utils';
import { Observable } from 'rxjs';
import { EcoUrlService } from '../../core/url.service';
import { ConnectorHealthStatus, ConnectorInstanceBuildStatus, ConnectorInstanceByteRate, ConnectorInstanceLagHistory, IConnectorInstance } from '../models/connectorInstance.model';
import { ConnectorInstanceError, ConnectorInstanceHealthIndicatorType, ConnectorInstanceVolumeByteRateRequest, CreateConnectorInstanceRequest, DeleteConnectorInstanceRequest, ListConnectorInstanceErrorsRequestParams, ListConnectorInstanceIndicatorsRequestParams, MuteConnectorInstanceIndicatorRequest, UpdateConnectorInstanceMonitoringConfigRequest, UpdateConnectorInstanceRequest } from './connectorInstances-api.definition';

export enum AlertPriorityEnum {
  P1 = "P1",
  P2 = "P2",
  P3 = "P3",
  P4 = "P4",
  P5 = "P5"
}


@Injectable({
  providedIn: 'root',
})
export class ConnectorInstanceApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.detect_connectors;

  constructor(
    readonly http: HttpClient, url: EcoUrlService,
    private readonly i18nService: I18nService
  ) {
    super(http, url);
  }

  public async createConnectorInstance(organizationId: string, connectorInstanceConfig: CreateConnectorInstanceRequest): Promise<void> {
    const url = `${this.host}/organizations/${organizationId}/instances`;
    return this.http.post<void>(url, connectorInstanceConfig).toPromise();
  }

  public listDataConnectorsInstance(organizationId: string, params: {
    fulltext: string,
    status?: ConnectorInstanceBuildStatus,
    health?: ConnectorHealthStatus
  }): Observable<IConnectorInstance[]> {
    const url = `${this.host}/organizations/${organizationId}/instances?${UrlUtils.jsonToQueryParams({ ...params, lang: this.i18nService.currentLocale })}`;
    return this.http.get<IConnectorInstance[]>(url);
  }

  public getConnectorInstance(organizationId: string, connectorInstanceId: string): Observable<IConnectorInstance> {
    const url = `${this.host}/organizations/${organizationId}/instances/${connectorInstanceId}`;
    return this.http.get<IConnectorInstance>(url);
  }

  public async deleteConnectorInstance(organizationId: string, connectorInstanceId: string, request: DeleteConnectorInstanceRequest): Promise<void> {
    const url = `${this.host}/organizations/${organizationId}/instances/${connectorInstanceId}?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.delete<void>(url).toPromise();
  }

  public async updateConnectorInstance(organizationId: string, connectorInstanceId: string, request: UpdateConnectorInstanceRequest): Promise<void> {
    const url = `${this.host}/organizations/${organizationId}/instances/${connectorInstanceId}`;
    return this.http.patch<void>(url, request).toPromise();
  }

  public async updateConnectorInstanceMonitoringConfig(organizationId: string, connectorInstanceId: string, request: UpdateConnectorInstanceMonitoringConfigRequest): Promise<void> {
    const url = `${this.host}/organizations/${organizationId}/instances/${connectorInstanceId}/monitoring`;
    return this.http.patch<void>(url, request).toPromise();
  }

  public async muteConnectorInstance(organizationId: string, request: MuteConnectorInstanceIndicatorRequest): Promise<void> {
    const url = `${this.host}/organizations/${organizationId}/instances/${request.connectorInstanceId}/indicators/mute`;
    const body = {
      from: request.from,
      to: request.to,
    };
    return this.http.post<void>(url, body).toPromise();
  }

  public async unmuteConnectorInstance(organizationId: string, connectorInstanceId: string): Promise<void> {
    const url = `${this.host}/organizations/${organizationId}/instances/${connectorInstanceId}/indicators/unmute`;
    return this.http.post<void>(url, null).toPromise();
  }

  public async listConnectorInstanceHealthIndicators(organizationId: string, connectorInstanceId: string, params: ListConnectorInstanceIndicatorsRequestParams) {
    const url = `${this.host}/organizations/${organizationId}/instances/${connectorInstanceId}/indicators?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<[]>(url).toPromise();
  }

  public async listConnectorInstaceHealthIndicatorErrors(organizationId: string, connectorInstanceId: string, params: ListConnectorInstanceErrorsRequestParams) {
    const url = `${this.host}/organizations/${organizationId}/instances/${connectorInstanceId}/errors?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<ConnectorInstanceError[]>(url).toPromise();
  }

  public async resetConnectorInstance(organizationId: string, connectorInstanceId: string, indicatorType: ConnectorInstanceHealthIndicatorType, reason?: string): Promise<void> {
    const url = `${this.host}/organizations/${organizationId}/instances/${connectorInstanceId}/indicators/${indicatorType}/reset`;
    return this.http.post<void>(url, { reason }).toPromise();
  }

  public async getConnectorInstanceVolumeByteRate(organizationId: string, connectorInstanceId: string, request: ConnectorInstanceVolumeByteRateRequest) {
    const url = `${this.host}/organizations/${organizationId}/instances/${connectorInstanceId}/volume/byteRate?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<ConnectorInstanceByteRate[]>(url).toPromise();
  }

  public async getConnectorInstanceLagHistory(organizationId: string, connectorInstanceId: string, request: ConnectorInstanceVolumeByteRateRequest) {
    const url = `${this.host}/organizations/${organizationId}/instances/${connectorInstanceId}/lag/history?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<ConnectorInstanceLagHistory[]>(url).toPromise();
  }
}
