import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupTemplateContainer } from './containers/group-template/group-template.container';
import { GroupTemplatesContainer } from './containers/group-templates/group-templates.container';


const routes: Routes = [
  {
    path: '',
    component: GroupTemplatesContainer,
  },
  {
    path: 'groupTemplate/:id',
    component: GroupTemplateContainer,
  },
  {
    path: 'groupTemplate',
    component: GroupTemplateContainer,
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class GroupTemplatesRoutingModule {
}
