import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PlaybookTaskRelation } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";
import { IParameterStore } from "projects/@common/services/api/tools/parameters/parameters.definitions";
import * as uuid from "uuid";

import { TaskOriginEnum } from "./playbook-tasks-drag-and-drop.component";
import { ExpendableTypeEnum } from "@ui-kit/components/ui-expendable-section/ui-expendable-section.component";
import { Store } from "@ngxs/store";
import { EcoSessionState } from "@common/modules/session/state/session.state";

interface ITaskActions {
  isEditable: boolean;
  isIgnorable: boolean;
  isHidable: boolean;
  isDeletable: boolean;
}

@Component({
  selector: 'task-ui-card',
  templateUrl: './playbook-detail-task-ui-card.component.html',
  styleUrls: [ './playbook-detail-task-ui-card.component.scss' ],
})
export class TaskUiCardComponent implements OnInit {
  @Input() public item: PlaybookTaskRelation;

  @Input() public region: any;

  @Input() public origin: any;

  @Input() public parametersValues: IParameterStore[];

  @Input() public lockedPosition = false;

  @Input() public isCollapsable = true;

  @Input() public isCollapsed = true;

  @Input() public isReadonly = false;

  @Input() public showTaskInstructions = false;

  @Output() toggleCollapseEvent = new EventEmitter<boolean>();

  @Output() deleteEvent = new EventEmitter<any>();

  @Output() ignoreEvent = new EventEmitter<any>();

  @Output() hideEvent = new EventEmitter<any>();

  @Output() editEvent = new EventEmitter<any>();

  constructor(protected store: Store){

  }

  public taskOriginEnum = TaskOriginEnum;

  public iteration: string = uuid.v4();

  UPDATED_DATE_LIMIT = 7;

  public isHeaderHovered = false;

  public taskActions: ITaskActions = {
    isEditable: false,
    isIgnorable: false,
    isHidable: false,
    isDeletable: false,
  };

  public tooltipMessage = '';

  public taskIconSrc = '';

  public expendableTypeEnum = ExpendableTypeEnum;

  public get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }

  public ngOnInit(): void {
    this.initIconState();
  }

  public getItemPipeline(pipeline: any): string{
    return JSON.stringify({pipeline: pipeline});
  }

  public edit(): void {
    this.editEvent.emit(this.item);
  }

  private initIconState(): void {
    this.taskActions.isDeletable = !this.isReadonly && !this.item.managed;
    this.taskActions.isHidable = !this.isReadonly;
    this.taskActions.isIgnorable = !this.isReadonly;
    switch (this.origin) {
      case TaskOriginEnum.Custom: {
        this.taskActions.isEditable = !this.isReadonly;
        this.taskIconSrc = 'assets/favicons/icon_playbook_task_custom.svg';
        break;
      }
      case TaskOriginEnum.Library: {
        this.taskActions.isEditable = false;
        if (this.item.updatedAt) {
          if (this.updatedDateDiffToString(this.item.updatedAt) <= this.UPDATED_DATE_LIMIT) {
            this.taskIconSrc = 'assets/favicons/icon_playbook_task_template_recently_updated.svg';
            break;
          }
        }
        this.taskIconSrc = 'assets/favicons/icon_playbook_task_library.svg';
        break;
      }
      default:
    }
  }

  public deleteTask(): void {
    if (!this.isReadonly) {
      this.deleteEvent.emit({
        region: this.region,
        item: this.item,
      });
    }
  }

  public ignoreTask(): void {
    if (!this.isReadonly) {
      this.ignoreEvent.emit({
        region: this.region,
        item: this.item,
      });
    }
  }

  public hideTask(): void {
    this.hideEvent.emit({
      region: this.region,
      item: this.item,
    });
  }

  public toggleCollapse(): void {
    if (this.isCollapsable) {
      this.isCollapsed = !this.isCollapsed;
      this.toggleCollapseEvent.emit(this.isCollapsed);
    }
  }

  private updatedDateDiffToString(date: number): number {
    const diff = Date.now() / 1000 - date;
    return Math.ceil(diff / (1000 * 3600 * 24));
  }
}
