import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";

import { IDescribeIncidentTask, IInProgressPeriod, IincidentTaskMessage, IncidentCustomTaskType, IncidentTaskStatus } from "../incidentTasks.definitions";
import { IncidentTaskAction } from "./incidentTaskAction";

export class IncidentTask implements IDescribeIncidentTask {
  public task: IncidentTaskAction;
  public availableStatuses: IncidentTaskStatus[];
  public canUpdateStatus: boolean;
  public canUpdateComment: boolean;
  public durationInSeconds: number;
  public actions: string[];
  public entities: string[];
  public observables: string[];
  public findings: IincidentTaskMessage[];

  public icon?: string;

  constructor(parameters: IDescribeIncidentTask) {
    Object.assign(this, parameters);
    this.task = new IncidentTaskAction(parameters?.task);
    this.icon = this.getIconName();
  }

  public updateAvailableStatuses(task: IncidentTask): void {
    this.availableStatuses = task.availableStatuses;
    this.task.status = task.taskStatus;
    this.durationInSeconds = task.durationInSeconds;
    this.task.inProgressPeriods = task.task.inProgressPeriods;
    this.task.comment = task.taskComment;
    this.actions = task.actions;
    this.entities = task.entities;
    this.observables = task.observables;
  }

  public getIconName(): string {
    if (this.task?.customTask?.type === IncidentCustomTaskType.USER) {
      return 'assets/favicons/icon_playbook_task_custom.svg';
    }
    return 'assets/favicons/icon_playbook_task_library.svg';
  }

  public get isHidden(): boolean {
    return this.task.isHidden;
  }

  public get resolutionPeriod(): IInProgressPeriod {
    return this.task.inProgressPeriods ? this.task?.inProgressPeriods[this.task?.inProgressPeriods?.length - 1] : null;
  }

  public get taskId(): string {
    return this.task.id;
  }

  public get taskPhase(): string {
    return this.task.phase;
  }

  public get taskStatus(): IncidentTaskStatus {
    return this.task.status;
  }

  public get taskName(): ITranslatedField {
    return this.task.name;
  }

  public get taskDescription(): ITranslatedField {
    return this.task.description;
  }

  public get taskInstruction(): ITranslatedField {
    return this.task.instruction;
  }

  public get taskComment(): string {
    return this.task.comment;
  }

  public get isInProgress(): boolean {
    return this.taskStatus === IncidentTaskStatus.IN_PROGRESS;
  }

  public get isOpen(): boolean {
    return this.taskStatus === IncidentTaskStatus.OPEN;
  }
}
