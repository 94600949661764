import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SharedFilesResourceTypeEnum } from '@common/services/api/sg/shares/shares.definitions';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { MobileService } from '@common/services/mobile.service';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';


@Component({
  selector: 'admin-top10-row',
  templateUrl: './admin-top10-row.component.html',
  styleUrls: ['./admin-top10-row.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminTop10RowComponent {
  @Input() showReminder = false;

  @Input() public resource: {
    groupType: SharedFilesResourceTypeEnum,
    name: string;
    id: string;
    owners: { o365UserId: string, name: string; }[];
    showReminderAction: boolean;
  };

  public locale: LanguageEnum;
  public reminderClicked = false;

  constructor(public mobileService: MobileService, private userApiService: UsersApiService) { }

  async notifyUser(resourceId: string): Promise<void> {
    this.reminderClicked = true;
    setTimeout(() => {
      this.reminderClicked = false;
    }, 1500);
    await this.userApiService.reminderSend(resourceId);
  }
}
