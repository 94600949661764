<div
  [formGroup]="labelForm"
  class="input-width"
  [ngClass]="iconClass"
>
  <ui-select
    [options]="impactLevelOptions"
    [required]="true"
    control="severityLevel"
    [group]="labelForm"
    name="label"
    [placeholder]="'settings.labels.card.labels.picker.associate' | t"
  >
  </ui-select>
</div>