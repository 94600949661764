import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from '../../core/url.service';
import { IRespondResponse } from '@common/definitions/IRespondResponse';
import { IDataConnectorAction, IDataConnectorStatuses, IDescribeSoarConnectorResponse, ISoarConnector, ISoarConnectorAction, ISoarConnectorPayload, IUpdateSoarConnectorResponse } from './connectors-alerting-api.definition';
import { Store } from '@ngxs/store';
import { EcoSessionState } from '@common/modules/session/state/session.state';
import { VarConnectorsAlertingApiService } from './var-connectors-alerting-api.service';
import { CustommerVarConnectorsAlertingApiService } from './custommer-connectors-alerting-api.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectorsAlertingApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  private varService: VarConnectorsAlertingApiService;
  private customerService: CustommerVarConnectorsAlertingApiService;

  constructor(
    protected store: Store,
    readonly http: HttpClient, url: EcoUrlService,
  ) {
    super(http, url);
    this.varService = new VarConnectorsAlertingApiService(http, url);
    this.customerService = new CustommerVarConnectorsAlertingApiService(http, url);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }

  public describeSoarConnectorActions(organizationId: string, connectorId: string, accountIds?: string[]): Promise<IRespondResponse<ISoarConnectorAction>> {
    if(this.isVarMode) {
      return this.varService.describeSoarConnectorActions(organizationId, connectorId, accountIds);
    }
    
    return this.customerService.describeSoarConnectorActions(organizationId, connectorId);
  }

  public describeDataConnectorActions(organizationId: string, connectorId: string): Promise<IRespondResponse<IDataConnectorAction>> {
    if(this.isVarMode) {
      return this.varService.describeDataConnectorActions(organizationId, connectorId);
    }
    
    return this.customerService.describeDataConnectorActions(organizationId, connectorId);
  }

  public describeSoarConnector(organizationId: string, connectorId: string): Promise<IDescribeSoarConnectorResponse> {
    if(this.isVarMode) {
      return this.varService.describeSoarConnector(organizationId, connectorId);
    }
    
    return this.customerService.describeSoarConnector(organizationId, connectorId);
  }

  public listSoarConnectors(organizationId: string): Promise<IRespondResponse<ISoarConnector>> {
    if(this.isVarMode) {
      return this.varService.listSoarConnectors(organizationId);
    }
    
    return this.customerService.listSoarConnectors(organizationId);
  }

  public listDataConnectorStatuses(organizationId: string): Promise<IDataConnectorStatuses[]> {
    if(this.isVarMode) {
      return this.varService.listDataConnectorStatuses(organizationId);
    }
    
    return this.customerService.listDataConnectorStatuses(organizationId);
  }

  public async createSoarConnectorInstance(organizationId: string, connectorInstanceConfig: ISoarConnectorPayload): Promise<IDescribeSoarConnectorResponse> {
    if(this.isVarMode) {
      return this.varService.createSoarConnectorInstance(organizationId, connectorInstanceConfig);
    }
  }

  public updateSoarConnectorPermissions(organizationId: string, connectorId: string): Promise<void> {
    if(this.isVarMode) {
      return this.varService.testSoarConnectorPermissions(organizationId, connectorId);
    }

    return this.customerService.testSoarConnectorPermissions(organizationId, connectorId);
  }

  public updateDataConnectorPermissions(organizationId: string, connectorId: string): Promise<void> {
    if(this.isVarMode) {
      return this.varService.testDataConnectorPermissions(organizationId, connectorId);
    }

    return this.customerService.testDataConnectorPermissions(organizationId, connectorId);
  }

  public async updateSoarConnectorInstance(organizationId: string, connectorId: string, connectorInstanceConfig: ISoarConnectorPayload): Promise<IUpdateSoarConnectorResponse> {
    if(this.isVarMode) {
      return this.varService.updateSoarConnectorInstance(organizationId, connectorId, connectorInstanceConfig);
    }
  }

  public async deleteSoarConnectorInstance(organizationId: string, soarConnectorId: string): Promise<void> {
    if(this.isVarMode) {
      return this.varService.deleteSoarConnectorInstance(organizationId, soarConnectorId);
    }
  }
}
