import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";
import { MitreAttackGenericData } from "projects/@common/services/api/detect/mitre-attack/mitre-attack-api.definition";

import { IPlaybookDetail, PlaybookTaskRelation } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";
import { DataConnectorTypes } from '@md.eco/connectors';;

export class Playbook implements IPlaybookDetail {
  public createdAt: number;
  public datasourceTypes: DataConnectorTypes[];
  public description: ITranslatedField;
  public extraDescription: string;
  public id: string;
  public name: ITranslatedField;
  public notes: string;
  public objective: ITranslatedField;
  public organizationId: string;
  public playbookTasks: PlaybookTaskRelation[];
  public suffix: string;
  public templateCatalogId: string;
  public templateId: string;
  public updatedAt: number;
  public version: number;
  public attacks?: MitreAttackGenericData[];

  constructor(playbook: IPlaybookDetail) {
    this.createdAt = playbook.createdAt;
    this.datasourceTypes = playbook.datasourceTypes;
    this.description = playbook.description;
    this.extraDescription = playbook.extraDescription;
    this.id = playbook.id;
    this.name = playbook.name;
    this.notes = playbook.notes;
    this.objective = playbook.objective;
    this.organizationId = playbook.organizationId;
    this.playbookTasks = playbook.playbookTasks;
    this.suffix = playbook.suffix;
    this.templateCatalogId = playbook.templateCatalogId;
    this.templateId = playbook.templateId;
    this.updatedAt = playbook.updatedAt;
    this.version = playbook.version;
    this.attacks = playbook.attacks;
  }

  public setCreatedAtNow(): void {
    this.createdAt = this.getTimestampNow();
  }

  public setUpdatedAtNow(): void {
    this.updatedAt = this.getTimestampNow();
  }

  public getVersionString(): string {
    return `${this.version}.0`;
  }

  private getTimestampNow(): number {
    return new Date().getTime();
  }
}
