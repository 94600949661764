import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Icon } from 'projects/@common/services/api/respond/datasources/datasources.definitions';

export interface IconObject extends Icon {
  isHovered: boolean;
}

@Component({
  selector: 'app-datasource-table-icons',
  templateUrl: './datasource-table-icons.component.html',
  styleUrls: [ './datasource-table-icons.component.scss' ],
})
export class DatasourceTableIconsComponent implements OnInit {
  @Input() public datasourceIcons: Icon[];

  @Input() public maxDisplay = 5;

  public iconsToDisplay: IconObject[] = [];

  public iconsHidden = 0;

  @ViewChild('tooltipMessage', { static: false }) tooltipElementRef: ElementRef;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.handleIcons();
  }

  public showTooltip(icon: IconObject): void {
    icon.isHovered = true;
    this.cdRef.detectChanges();
  }

  public hideTooltip(icon: IconObject): void {
    icon.isHovered = false;
    this.cdRef.detectChanges();
  }

  private handleIcons(): void {
    if (this.datasourceIcons) {
      this.iconsToDisplay = this.datasourceIcons
        .slice(0, this.maxDisplay)
        .map((icon) => ({ ...icon, isHovered: false }));
      this.iconsHidden = Math.max(this.datasourceIcons.length - this.maxDisplay, 0);
    }
  }
}
