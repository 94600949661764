<div class="report-filters-left">
  <app-date-interval
    [period]="period"
  ></app-date-interval>
</div>

<div class="report-filters-right">
  <ui-table-total-tool
    [locale]="i18n.currentLocale"
    [total]="total"
  ></ui-table-total-tool>

  <ui-table-search-tool
    *ngIf="showNameFilter"
    [placeholder]="'ui.table-search-tool.placeholder' | t"
    [query]="name"
    (query)="handleSearchTextChange($event)"
    (clear)="handleSearchTextChange(null)"
  ></ui-table-search-tool>

  <ui-table-date-tool
    (timeEmitter)="handlePeriodChange($event)"
    (resetDateEmitter)="handlePeriodChange(null)"
    [defaultCustomTimePeriod]="period"
    [excludePresets]="['last_180_days', 'last_365_days']"
    [locale]="i18n.currentLocale"
  >
  </ui-table-date-tool>

  <ui-table-filter-tool
    [tableFilterData]="reportTypeOptions"
    (select)="handleReportTypeChange($event)"
  ></ui-table-filter-tool>

  <ui-table-refresh-tool
    [loading]="isLoading"
    (click)="handleRefreshClicked()"
  ></ui-table-refresh-tool>
</div>