<div
  page
  [appPagePaths]="['mdRespond', 'actifs']"
  [hasTopSection]="true"
  [tooltipTitle]="'actifs.page.title.tooltip'"
  [searchMode]="searchMode"
  (organizationChanged)="organizationChanged($event)"
>
  <span
    *ngIf="showEmptyPageToSelectOrganization"
    class="no-org-transition"
  >
    {{ 'organizations_select.notSelected.message' | t }}
  </span>

  <actifs-filters
    *ngIf="!showEmptyPageToSelectOrganization"
    toolbar
    [total]="actifsService.total"
  ></actifs-filters>

  <actifs-table
    *ngIf="!showEmptyPageToSelectOrganization && !isAssetTypesLoading"
    [defaultColumnsDef]="viewParams.tableColumns"
    [assetTypes]="actifsService.assetTypes"
    [assetsData]="actifsService.assetsData"
    [canDescribe]="canDescribe"
    [canCreate]="canCreate"
    [canDelete]="canDelete"
    [isReadonly]="viewParams.isReadonly"
    (rowClickEvent)="openAssetDetailDrawer($event)"
    (deleteEvent)="openDeleteModal()"
    (createEvent)="openCreateDrawer()"
  ></actifs-table>
</div>
