import { ConnectorFamilies, DataConnectorTypes } from "../enums";
import { DataConnectorTypesCatalog } from "./connectors.catalog";

export interface IConnectorTypeFamily {
  id: string;
  translations: {
    en: {
      id: string;
      name: string;
    },
    fr: {
      id: string;
      name: string;
    }
  }
}

export const ConnectorFamiliesCatalog: { [id in ConnectorFamilies]: IConnectorTypeFamily } = {
  [ConnectorFamilies.AWS]: {
    id: "01H84X40AEDJ5CHPQ481YMP4HM",
    translations: {
      en: {
        id: "01H8FMZJ611A5HR6ZH3VF09D9R",
        name: "AWS",
      },
      fr: {
        id: "01H8FMZJ62Q7PERFZKA8F4JJAF",
        name: "AWS",
      },
    },
  },
  [ConnectorFamilies.MICROSOFT_AZURE]: {
    id: "01H84X40AEDJ5CHPQ481YMP4HN",
    translations: {
      en: {
        id: "01H8FMZJ64X4ABE619M76ZP9F6",
        name: "Azure",
      },
      fr: {
        id: "01H8FMZJ65VFV484RA8X27CB8A",
        name: "Azure",
      },
    },
  },
  [ConnectorFamilies.CYBERSECURITY_INFRA]: {
    id: "01H84X40AEDJ5CHPQ481YMP4HP",
    translations: {
      en: {
        id: "01H8FMZJ66QQ3M48R57WCSCH5T",
        name: "Cybersecurity Infrastructure",
      },
      fr: {
        id: "01H8FMZJ68H9W673FS2NGXTY05",
        name: "Infrastructure Cybersécurité",
      },
    },
  },
  [ConnectorFamilies.EDR]: {
    id: "01H84X40AEDJ5CHPQ481YMP4HQ",
    translations: {
      en: {
        id: "01H8FMZJ69556TZ60B1N85ABMR",
        name: "EDR",
      },
      fr: {
        id: "01H8FMZJ69YSFWN24REBNWN3F6",
        name: "EDR",
      },
    },
  },
  [ConnectorFamilies.GOOGLE]: {
    id:"01J2HPG83X83XR19493PHMQQWX",
    translations: {
      en: {
        id: "01J2HPG83X77W2D3N3X3T5KDNF",
        name: "Google",
      },
      fr: {
        id: "01J2HPG83X05HF7KADHKKEDM5H",
        name: "Google",
      },
    },
  },
  [ConnectorFamilies.INFORMATICA]: {
    id: "01H84X40AEDJ5CHPQ481YMP4HR",
    translations: {
      en: {
        id: "01H8FMZJ6AC6M3C1AM9H4F3NMJ",
        name: "Informatica",
      },
      fr: {
        id: "01H8FMZJ6FR5HSBKYAQSFEA8EP",
        name: "Informatica",
      },
    },
  },
  [ConnectorFamilies.LOCAL_INFRA]: {
    id: "01H84X40AEDJ5CHPQ481YMP4HS",
    translations: {
      en: {
        id: "01H8FMZJ6G80ZKXM04SDK9XARZ",
        name: "Local Infrastructure",
      },
      fr: {
        id: "01H8FMZJ6HVFSERKA42FTH0DQ5",
        name: "Infrastructure Locale",
      },
    },
  },
  [ConnectorFamilies.MICROSOFT_365]: {
    id: "01H84X40AEDJ5CHPQ481YMP4HT",
    translations: {
      en: {
        id: "01H8FMZJ6J7WN310SV0ZBRDK57",
        name: "Microsoft 365",
      },
      fr: {
        id: "01H8FMZJ6K7VWJCNRXPBCHRP0F",
        name: "Microsoft 365",
      },
    },
  },
};

type FamilyToConnectorTypes =  { [id in ConnectorFamilies]: DataConnectorTypes[] };

const tempFamilyToConnectorType: { [id in ConnectorFamilies]: DataConnectorTypes[] } = {} as FamilyToConnectorTypes;

for (const family of Object.values(ConnectorFamilies)) {
  tempFamilyToConnectorType[family] = [];
}

for (const connectorType of Object.keys(DataConnectorTypesCatalog)) {
  const connectorFamily: ConnectorFamilies = DataConnectorTypesCatalog[connectorType as DataConnectorTypes].family;
  tempFamilyToConnectorType[connectorFamily].push(connectorType as DataConnectorTypes);
}

export const FamilyToConnectorType = tempFamilyToConnectorType;

type ConnectorTypesToFamilyType = { [id in DataConnectorTypes]: ConnectorFamilies };

const tempConnectorTypeToFamily: ConnectorTypesToFamilyType = {} as ConnectorTypesToFamilyType;

for (const key of Object.keys(DataConnectorTypesCatalog)) {
  tempConnectorTypeToFamily[key as DataConnectorTypes] = DataConnectorTypesCatalog[key as DataConnectorTypes].family;
}

export const ConnectorTypesToFamily = tempConnectorTypeToFamily;
