import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthnRedirectComponent } from 'projects/@common/modules/auth/components/redirect/authn-redirect.component';
import { AuthnSignInComponent } from 'projects/@common/modules/auth/components/signin/sign-in.component';
import { AdminConsentRedirectComponent } from 'projects/@common/modules/auth/components/signup/adminconsent-redirect.component';
import { SignupComponent } from 'projects/@common/modules/auth/components/signup/sign-up.component';
import { SignupRedirectComponent } from 'projects/@common/modules/auth/components/signup/signup-redirect.component';
import { PrivateLayout } from 'projects/@common/modules/layout/private.layout';
import { AuthGuard } from 'projects/@common/modules/session/auth.guard';
import { IAMModule } from './iam/iam.module';
import { RolesModule } from './iam/roles/roles.module';
import { SharesModule } from './iam/shares/shares.module';
import { MdDetectionResponseModule } from './md-detection-response/md-detection-response.module';
import { SecretStoreModule } from './tools/secret-store/secret-store.module';

import { DevEnvironmentGuard } from 'projects/@common/modules/guards/dev-environment.guard';
import { ImagesBankComponent } from 'projects/@common/modules/layout/components/images-bank/images-bank.component';
import { OrgCspModule } from './csp/org.csp.module';
import { HomeModule } from "./home/home.module";
import { PermissionGrantConfirmationComponent } from './permission-grant-confirmation/permission-grant-confirmation.component';
import { OrgRespondModule } from "./respond/org.respond.module";
import { TestSignInComponent } from '@common/modules/auth/components/testSignin/test-sign-in.component';
import { environment } from '@common/environments/environment';
import { Stages } from '@common/definitions/stages';

const routes: Routes = [
  {
    path: 'IMAGES',
    component: ImagesBankComponent,
    canActivate: [DevEnvironmentGuard],
  },
  { path: 'signin', component: AuthnSignInComponent },
  { path: 'test', component: TestSignInComponent, canActivate: [() => environment.stage !== Stages.PROD]},
  { path: 'login/office', component: AuthnRedirectComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'signup/redirect', component: SignupRedirectComponent },
  { path: 'adminconsent/redirect', component: AdminConsentRedirectComponent },
  {
    path: '',
    component: PrivateLayout,
    canActivate: [AuthGuard],
    children: [
      { path: 'permission-confirmation/redirect', component: PermissionGrantConfirmationComponent },
      {
        path: '',
        loadChildren: () => HomeModule,
      },
      {
        path: 'csp',
        loadChildren: () => OrgCspModule,
      },
      {
        path: 'detection',
        loadChildren: () => MdDetectionResponseModule,
      },
      {
        path: 'respond',
        loadChildren: () => OrgRespondModule,
      },
      {
        path: 'roles',
        loadChildren: () => RolesModule,
      },
      {
        path: 'shares',
        loadChildren: () => SharesModule,
      },
      {
        path: 'iam',
        loadChildren: () => IAMModule,
      },
      {
        path: 'tools/secrets',
        loadChildren: () => SecretStoreModule,
      },
      {
        path: 'tools/:organizationEcoId/secrets',
        loadChildren: () => SecretStoreModule,
      },
      {
        path: "releases",
        loadChildren: () => import('../../../console-org/src/app/release-notes/release-note.module').then((m) => m.ReleaseNotesModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class OrgRoutingModule {
}
