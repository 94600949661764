<div class="card-content">
  <settings-edition-card [icon]="'icon_users_widget_green_nobackground_cropped.svg'" [title]="'settings.guests.expirationDuration.title' | t"
                         [canSave]="isValid" (saveEvent)="handleSave()" (cancelEvent)="cancel()"
                         (toggleEditingEvent)="isEditing = $event">
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="settings-content">
        <ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>
      </div>
    </ng-container>
  </settings-edition-card>
</div>

<ng-template #readonly>
  <div>
    <p class="description">
      {{ 'settings.guests.expirationDuration.description' | t }}
    </p>
    <p class="attribute"
       [innerHTML]="'settings.guests.expirationDuration.description.readonly' | t: {expirationPeriod: formGroup.get('expirationPeriod').value}"></p>
  </div>
</ng-template>

<ng-template #editing>
  <p class="edit-description">
    {{ 'settings.guests.expirationDuration.description' | t }}
  </p>
  <div class="buttonsAndStuff">
    <p class="description">{{ 'settings.guests.expirationDuration.label' | t }}</p>
    <div class="days" [formGroup]="formGroup">
      <input ui-input maxlength="3" uiNumberOnlyInput formControlName="expirationPeriod" />
      <ng-container *ngIf="formGroup.get('expirationPeriod').invalid">
        <div class="fielderror">{{getErrorMessage(formGroup.get('expirationPeriod'))}}</div>
      </ng-container>
    </div>
  </div>
</ng-template>
