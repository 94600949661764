import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { Observable } from "rxjs";

import { EcoUrlService } from "../../core/url.service";

export enum UploadFolderEnum {
  INCIDENTS = 'incidents'
}

export interface GetImagePresignedUrlRequestDto {
  filename: string;
  organizationId?: string;
  folder?: UploadFolderEnum,
  resourceId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImagesUploaderService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public async uploadToS3(file: Blob, filename: string, folder: UploadFolderEnum, resourceId: string, organizationId: string): Promise<string> {
    const request: GetImagePresignedUrlRequestDto = {
      filename: this.getFullFilename(filename),
      folder,
      resourceId,
      organizationId,
    };

    const response = await this.getPresignedUrl(request);

    await this.uploadImageToCloud(response.uploadPresignedUrl, file, this.getHeaders(file.type)).toPromise();

    return response.downloadPresignedUrl;
  }

  public uploadImageToCloud(url: string, data: any, headers: HttpHeaders): Observable<any> {
    return this.http.put<any>(url, data, { headers });
  }

  public getPresignedUrl(request: GetImagePresignedUrlRequestDto): any {
    const url = `${this.host}/organizations/${request.organizationId}/${request.folder}/${request.resourceId}/images/${request.filename}`;
    return this.http.get<any>(url).toPromise();
  }

  private getFullFilename(filename: string): string {
    return encodeURIComponent(`${Date.now()}-${filename}`);
  }

  private getHeaders(contentType: string): HttpHeaders {
    return new HttpHeaders({ 'Content-Type': contentType });
  }
}
