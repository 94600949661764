import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEscalationHistory, IListEscalationHistoryRequest } from 'projects/@common/services/api/respond/histories/histories.definition';

@Component({
  selector: 'communication-tab-history',
  templateUrl: './communication-tab-history.component.html',
  styleUrls: [ './communication-tab-history.component.scss' ],
})
export class CommunicationTabHistoryComponent {
  @Input() history: IEscalationHistory[];
  @Input() canLoadMore: boolean;
  @Input() isLoading = false;
  @Input() isVarMode: boolean;

  @Output() loadMoreEvent = new EventEmitter<IListEscalationHistoryRequest>();

  emitLoadMoreEvent() {
    this.loadMoreEvent.emit();
  }
}
