import { Component, Input, OnInit } from '@angular/core';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { Eco } from 'projects/@common/definitions/eco';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IAssetPropertyConfigRepresentation, IAssetRepresentation } from 'projects/@common/services/api/respond/actifs/actifs.definitions';
import { IAlert } from 'projects/@common/services/api/respond/alerts/alerts.definitions';
import { IFinding } from 'projects/@common/services/api/respond/finding/finding.definition';
import { IIncident } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { ObservablesApi } from 'projects/@common/services/api/respond/observables/observables.api';
import { IListObservablesRequest, IObservable, ObservableSourceEnum } from 'projects/@common/services/api/respond/observables/observables.definition';
import { ResponseUtils } from 'projects/@common/utils/response-utils';
import { clone, normalizeString, randomId } from 'projects/@common/utils/utils';
import { ObservablesTableColumnsEnum, getObservablesTableColumns } from '../../observables-table-columns.config';
import { ICollapsableBadge } from '@ui-kit/components/ui-collapsable-card/ui-collapsable-card.component';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';

export enum ObservablesPageEnum {
  ALERT_DETAIL_PAGE = 'ALERT_DETAIL_PAGE',
  ALERT_SIMILARITY_PAGE = 'ALERT_SIMILARITY_PAGE',
  INCIDENT_OBSERVABLES_PAGE = 'INCIDENT_OBSERVABLES_PAGE',
  INCIDENT_TASKS_PAGE = 'INCIDENT_TASKS_PAGE',
  INCIDENT_SIMILARITY_PAGE = 'INCIDENT_SIMILARITY_PAGE',
  INCIDENT_TOOLBOX = 'INCIDENT_TOOLBOX',
}
export interface IObservablesPageContext {
  pageEnum: ObservablesPageEnum;
  organizationId?: string;
  alert?: IAlert;
  incident?: IIncident;
  finding?: IFinding;
  observables?: IObservable[];
  viewParams: {
    showCreateButton?: boolean;
    showDeleteButton?: boolean;
    showViewModeSelect?: boolean;
    showTitle?: boolean;
    hideSearch?: boolean;
    isCollapsedGroupsByDefault?: boolean;
    useRedFlagFeature?: boolean;
  }
}

export enum ActifsListViewModeEnum {
  SIMPLE_LIST = 'SIMPLE_LIST',
  GROUPED_BY_ASSETS = 'GROUPED_BY_ASSETS'
}

export interface IObservableFilters {
  searchText?: string;
}

export interface IObservableTableGroup {
  id: string;
  observables: IObservable[];
  source: ObservableSourceEnum;
  name: string;
  columnsDef: Array<Object>;
  isExpanded: boolean;
  icon?: string;
  badge?: ICollapsableBadge;
}

@Component({
  selector: 'observable-container',
  templateUrl: './observable-container.component.html',
  styleUrls: [ './observable-container.component.scss' ],
})
export class ObservableContainerComponent implements OnInit {
  @Input() public pageContext: IObservablesPageContext;
  @Input() public assets: IAssetRepresentation[] = [];
  @Input() public assetTypes: IAssetPropertyConfigRepresentation[] = [];
  @Input() public defaultViewMode: ActifsListViewModeEnum = ActifsListViewModeEnum.GROUPED_BY_ASSETS;
  @Input() public useGrouping: boolean = true;
  @Input() public isReadonly: boolean = false;

  public observables: IObservable[];
  public tableGroups: IObservableTableGroup[] = [];

  public isLoadingObservables: boolean = false;
  public selectedRows: IObservable[] = [];
  public filters: IObservableFilters = { searchText: '' };
  public locale: Eco.Languages;
  public listViewModes: TableFilterData;
  public viewMode: ActifsListViewModeEnum;
  public defaultSort = ObservablesTableColumnsEnum.TYPE;
  public defaultSortDirection = 'asc';

  constructor(
    public readonly i18n: I18nService,
    private readonly staticTableDataFactory: StaticTableDataMapper,
    private readonly observablesApi: ObservablesApi,
    private readonly noticeService: NoticeService,
    private readonly tableFilterToolDataFactory: TableFilterToolDataFactory,
    private readonly translatedObjectPipe: TranslatedObjectPipe
  ) { }

  ngOnInit(): void {
    this.viewMode = this.defaultViewMode;
    this.listViewModes = this.tableFilterToolDataFactory.create(ActifsListViewModeEnum, 'observables.listViewModes.', this.viewMode, true);
    this.observables = clone(this.pageContext.observables);
    if (Array.isArray(this.observables)) {
      this.mapObservablesToTableGroups();
    } else {
      this.fetchObservable();
    }
  }

  public get totalDisplayed(): number {
    return this.tableGroups.reduce((acc, group) => acc + group.observables.length, 0);
  }

  public get shouldHideCreateButton(): boolean {
    return !this.pageContext.viewParams.showCreateButton;
  }

  public get shouldHideDeleteButton(): boolean {
    return !this.pageContext.viewParams.showCreateButton;
  }

  public get shouldHideViewModeSelect(): boolean {
    return !this.pageContext.viewParams.showViewModeSelect;
  }

  public get shouldHideTitle(): boolean {
    return !this.pageContext.viewParams.showTitle;
  }

  public get shouldHideSearch(): boolean {
    return this.pageContext.viewParams.hideSearch;
  }

  public get isIncidentTasksPage(): boolean {
    return this.pageContext.pageEnum === ObservablesPageEnum.INCIDENT_TASKS_PAGE;
  }

  public onClickCreate(): void {
    throw new Error('onClickCreate Not implemented');
  }

  public onClickDelete(): void {
    throw new Error('onClickDelete Not implemented');
  }

  public getObservablesCount(): number {
    return this.observables?.length || 0;
  }

  public trackById(index: number, item: IObservableTableGroup): string {
    return item.id;
  }

  public handleCheckboxEvent(isChecked: boolean, clickedRow: any): void {
    if (isChecked) {
      this.selectedRows.push(clickedRow);
    } else {
      this.selectedRows = this.selectedRows.filter((item) => item.id !== clickedRow.id);
    }
  }

  public handleSearchTextChange(searchText: string): void {
    this.filters.searchText = searchText;
    this.handleFilterSearch();
  }

  public handleViewModeChange(viewMode: ActifsListViewModeEnum): void {
    this.viewMode = viewMode;
    this.handleFilterSearch();
  }

  public handleFilterSearch(): void {
    this.mapObservablesToTableGroups();
    if (this.filters.searchText) {
      const normalizedSearchText = normalizeString(this.filters.searchText);
      this.tableGroups = this.tableGroups.map((group) => ({
        ...group,
        observables: group.observables.filter((observable) => {
          const searchString = normalizeString([ observable.type, observable.value, observable.displayValue || "" ].join(' '));
          return searchString.includes(normalizedSearchText);
        }),
      }));
    }
  }

  private fetchObservable(): void {
    this.isLoadingObservables = true;

    const request: IListObservablesRequest = {
      organizationId: this.pageContext?.organizationId,
      alertId: null,
      incidentId: null,
      findingId: null,
    };

    let contextualFetcher: Function;
    switch (this.pageContext.pageEnum) {
      case ObservablesPageEnum.ALERT_DETAIL_PAGE:
        request.alertId = this.pageContext.alert.id;
        contextualFetcher = () => this.observablesApi.listAlertObservables(request);
        break;
      default:
        request.incidentId = this.pageContext.incident.id;
        contextualFetcher = () => this.observablesApi.listIncidentObservables(request);
        break;
    }

    contextualFetcher().subscribe(
      (response: ResponseUtils<IObservable>) => this.handleListObservablesResponse(response),
      (error: any) => this.noticeService.error('common.error.retry'),
      () => this.isLoadingObservables = false
    );
  }

  private async handleListObservablesResponse(response: ResponseUtils<IObservable>) {
    this.observables = response.getItems();
    this.mapObservablesToTableGroups();
  }

  private mapObservablesToTableGroups(): void {
    switch (this.viewMode) {
      case ActifsListViewModeEnum.GROUPED_BY_ASSETS:
        this.tableGroups = this.getTableGroupsByAssets();
        break;
      default:
        this.tableGroups = this.getTableGroupsAsSimpleList();
        break;
    }
  }

  private getDefaultExpandedState(): boolean {
    return this.pageContext.viewParams.isCollapsedGroupsByDefault !== undefined ? !this.pageContext.viewParams.isCollapsedGroupsByDefault : true;
  }

  private getTableGroupsAsSimpleList(): IObservableTableGroup[] {
    return [ {
      id: randomId(),
      columnsDef: this.getTableColumns(),
      observables: this.observables,
      source: ObservableSourceEnum.FINDING,
      name: `${this.translatedObjectPipe.transform({ fr: "Tous", en: "All" })} (${this.observables.length})`,
      isExpanded: this.getDefaultExpandedState(),
      badge: null,
    } ];
  }

  private getTableGroupsByAssets(): IObservableTableGroup[] {
    const alertObservables = this.observables.filter((observable) => !observable.alertAssetId);
    const columns = this.getTableColumns();

    const alertObservablesTableGroup = {
      id: randomId(),
      columnsDef: columns,
      observables: alertObservables,
      source: ObservableSourceEnum.FINDING,
      name: this.getExpandTitle(alertObservables.length, alertObservables.filter((observable) => observable.similar).length),
      isExpanded: this.getDefaultExpandedState(),
      badge: {
        text: this.translatedObjectPipe.transform({ fr: "Observables", en: "Observables" }),
        color: 'blue',
      },
    };

    const assetObservablesTableGroups = [];
    const assetObservables = this.observables.filter((observable) => !!observable.alertAssetId && observable.source === ObservableSourceEnum.ENRICHMENT);
    this.assets.forEach((asset) => {
      const parentObservables = assetObservables.filter((observable) => asset.id === observable.assetId);
      const childObservables = assetObservables.filter((observable) => parentObservables.some((o) => o.alertAssetId === observable.parentId));
      const observables = [ ...parentObservables, ...childObservables ];
      assetObservablesTableGroups.push({
        id: randomId(),
        columnsDef: columns,
        observables: observables,
        source: ObservableSourceEnum.ENRICHMENT,
        name: `${asset.name} (${observables.length})`,
        isExpanded: this.getDefaultExpandedState(),
        badge: {
          color: 'orange',
          text: `${this.translatedObjectPipe.transform({ fr: "Actifs d'intérêt", en: "Critical assets" })} - ${this.translatedObjectPipe.transform(asset.typeName)}`,
        },
      });
    });

    const tableGroups: IObservableTableGroup[] = [
      alertObservablesTableGroup,
      ...assetObservablesTableGroups,
    ];

    return tableGroups;
  }

  private getTableColumns(): Array<object> {
    const columnsToInclude: ObservablesTableColumnsEnum[] = [
      ObservablesTableColumnsEnum.TYPE,
      ObservablesTableColumnsEnum.VALUE,
    ];

    switch (this.pageContext.pageEnum) {
      case ObservablesPageEnum.ALERT_DETAIL_PAGE:
        columnsToInclude.push(ObservablesTableColumnsEnum.DESCRIPTION, ObservablesTableColumnsEnum.ACTIONS);
        break;
      case ObservablesPageEnum.INCIDENT_OBSERVABLES_PAGE:
        columnsToInclude.push(ObservablesTableColumnsEnum.DESCRIPTION, ObservablesTableColumnsEnum.ALERT_IDS, ObservablesTableColumnsEnum.ACTIONS);
        break;
      case ObservablesPageEnum.ALERT_SIMILARITY_PAGE:
      case ObservablesPageEnum.INCIDENT_SIMILARITY_PAGE:
        columnsToInclude.unshift(ObservablesTableColumnsEnum.INDICATORS);
        columnsToInclude.push(ObservablesTableColumnsEnum.DESCRIPTION, ObservablesTableColumnsEnum.ACTIONS);
        break;
      case ObservablesPageEnum.INCIDENT_TOOLBOX:
      case ObservablesPageEnum.INCIDENT_TASKS_PAGE:
        columnsToInclude.unshift(ObservablesTableColumnsEnum.INDICATORS);
        columnsToInclude.push(ObservablesTableColumnsEnum.ACTIONS);
        break;
    }

    let columns = getObservablesTableColumns(columnsToInclude, this.pageContext);
    [ columns, this.observables ] = this.staticTableDataFactory.translate(columns, this.observables);
    return columns;
  }

  private getExpandTitle(totalCount: number, similarCount: number): string {
    const total = totalCount || 0;
    const similar = similarCount || 0;

    let name: ITranslatedField;
    switch (this.pageContext.pageEnum) {
      case ObservablesPageEnum.ALERT_DETAIL_PAGE:
        name = { fr: `Observables de l'alerte (${total})`, en: `Alert's observables (${total})` };
        break;
      case ObservablesPageEnum.INCIDENT_OBSERVABLES_PAGE:
      case ObservablesPageEnum.INCIDENT_TASKS_PAGE:
        name = { fr: `Observables des alertes (${total})`, en: `Alerts observables (${total})` };
        break;
      case ObservablesPageEnum.INCIDENT_SIMILARITY_PAGE:
      case ObservablesPageEnum.ALERT_SIMILARITY_PAGE:
        name = { fr: `Observables (${similar}/${total} en commun)`, en: `Observables (${similar}/${total} in common)` };
        break;
      case ObservablesPageEnum.INCIDENT_TOOLBOX:
        name = { fr: `Observables de l'action (${total})`, en: `Action's observables ${total})` };
        break;
      default:
        break;
    }
    return this.translatedObjectPipe.transform(name);
  }
}
