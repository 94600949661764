import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Eco } from 'projects/@common/definitions/eco';
import { EcoUrlService } from '../../core/url.service';
import { IamApi } from '../iam.api.definitions';
import { ApiEndpointPrefixEnum } from 'projects/@common/definitions/eco-api';
import { Store } from '@ngxs/store';
import { IProfileApi } from './profiles.api.definition';
import { CustomerProfilesApi } from './customer-profiles.api';
import { VarProfilesApi } from './var-profiles.api';

export interface ListProfileMembersRequest {
  organizationId?: string;
  profileId: string;
  params?: {
    from?: number,
    size?: number,
    idpRequired?: boolean
  }
}

export interface UpdateProfileMembersRequest {
  organizationId?: string;
  profileId: string;
  body: any;
}

export interface ListProfilesRequest {
  organizationId?: string;
  params?: {
    from?: number;
    size?: number;
    searchName?: string;
    nameLanguage?: string;
    order?: string;
  }
}

export interface ListProfileSecurityGroupsRequest {
  organizationId?: string;
  profileId: string;
  params?: {
    from?: number,
    size?: number;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProfilesApi implements IProfileApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.iam;

  private customerService: CustomerProfilesApi;
  private varService: VarProfilesApi;

  constructor(
    http: HttpClient,
    url: EcoUrlService,
    protected store: Store
  ) {
    this.customerService = new CustomerProfilesApi(http, url);
    this.varService = new VarProfilesApi(http, url);
  }
  public async listProfileMembers(request: ListProfileMembersRequest): Promise<Eco.List<IamApi.Profile.listMemberResponse>> {
    if (this.isVarMode) {
      return this.varService.listProfileMembers(request);
    }
    return this.customerService.listProfileMembers(request);
  }

  public async updateProfileMembers(request: UpdateProfileMembersRequest): Promise<void> {
    if (this.isVarMode) {
      return this.varService.updateProfileMembers(request);
    }
    return this.customerService.updateProfileMembers(request);
  }

  public async listProfiles(request: ListProfilesRequest): Promise<Eco.List<IamApi.Profile.listResponse>> {
    if (this.isVarMode) {
      return this.varService.listProfiles(request);
    }
    return this.customerService.listProfiles(request);
  }

  public async listProfileSecurityGroups(request: ListProfileSecurityGroupsRequest): Promise<Eco.List<{ groupId: string, name: string, description: string; }>> {
    if (this.isVarMode) {
      return this.varService.listProfileSecurityGroups(request);
    }
    return this.customerService.listProfileSecurityGroups(request);
  }

  private get isVarMode(): boolean {
    return false;
  }
}
