<label ui-label [required]="false">
  {{ 'common.filter.startDate' | t }}
</label>
<ui-date-picker
  [withClearButton]="true"
  [maxDate]="NOW"
  (onDateSelection)="onDateSelection('start', $event)"
></ui-date-picker>
<hours-picker (millisecondsEvent)="setStartHourTime($event)"></hours-picker>

<label ui-label [required]="false">
  {{ 'common.filter.endDate' | t }}
</label>
<ui-date-picker
  [withClearButton]="true"
  [maxDate]="NOW"
  (onDateSelection)="onDateSelection('end', $event)"
></ui-date-picker>
<hours-picker (millisecondsEvent)="setEndHourTime($event)"></hours-picker>

<div class="apply-button-container">
  <button
    class="apply-button"
    ui-button
    color="linkFlat"
    icon="plus"
    icon-size="small"
    (click)="apply()"
  >
    {{ 'user.detail.notOnboarded.enable.dialog.actionButton' | t }}
  </button>
</div>
