import { Component, Input, OnInit } from '@angular/core';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { HistoriesApi } from 'projects/@common/services/api/respond/histories/histories.api';
import { IUsecaseHistory } from 'projects/@common/services/api/respond/histories/histories.definition';
import { UsecaseHistoryRequest } from 'projects/@common/services/api/respond/histories/models/usecaseHistoryRequest';
import { IUsecase } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { ResponseUtils } from 'projects/@common/utils/response-utils';

@Component({
  selector: 'app-usecase-history',
  templateUrl: './usecase-history.component.html',
  styleUrls: [ './usecase-history.component.scss' ],
})
export class UsecaseHistoryComponent implements OnInit {
  public readonly tableColumnsDefinition;

  @Input() public usecase: IUsecase;
  public usecaseHistoryTableData: IUsecaseHistory[] = [];
  public isLoadingHistory: boolean = false;
  public usecaseHistoryLastResponse?: ResponseUtils<IUsecaseHistory>;

  constructor(
    public readonly i18n: I18nService,
    private readonly historiesApi: HistoriesApi,
    private readonly noticeService: NoticeService
  ) {
    this.tableColumnsDefinition = [
      {
        label: '',
        field: 'icon',
        type: UiStaticTableRowType.EXPENDABLE_ICON,
        showIcon: true,
        width: '26px',
        minWidth: '26px',
        isResizable: false,
        noSorting: true,
      },
      {
        label: 'date',
        field: 'createdAt',
        width: '200px',
        minWidth: '200px',
        type: UiStaticTableRowType.DATE_AND_HOUR_AND_AGO,
        isResizable: false,
        noSorting: true,
      },
      {
        label: this.i18n.translate('histories.user'),
        field: 'identityName',
        width: '200px',
        minWidth: '200px',
        type: UiStaticTableRowType.TEXT,
        isResizable: false,
        noSorting: true,
      },
      {
        label: this.i18n.translate('histories.message'),
        field: 'message',
        width: '100%',
        type: UiStaticTableRowType.TRANSLATED_FIELD,
        noSorting: true,
      },
    ];
  }

  ngOnInit(): void {
    this.loadHistoricalData();
  }

  public loadMoreHistoricalData(): void {
    if (!this.isLoadingHistory) {
      this.loadHistoricalData();
    }
  }

  private loadHistoricalData(): void {
    this.isLoadingHistory = true;
    const request = new UsecaseHistoryRequest({
      usecaseId: this.usecase.id,
      organizationId: this.usecase.organizationId,
      from: !this.usecaseHistoryLastResponse ? 0 : this.usecaseHistoryLastResponse.getNextItem(),
    });
    this.historiesApi.listUsecaseHistory(request).toPromise()
      .then((response) => {
        this.usecaseHistoryLastResponse = response;
        this.usecaseHistoryTableData = this.usecaseHistoryTableData.concat(response.getItems());
      })
      .catch(() => {
        this.noticeService.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR));
      })
      .finally(() => this.isLoadingHistory = false);
  }
}
