import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SpecificTaskForm } from './specific-task.form';

@Injectable({
  providedIn: 'root',
})
export class FormsFactory {
  constructor(private readonly formBuilder: UntypedFormBuilder) {
  }

  public getSpecificTaskForm(): SpecificTaskForm {
    return new SpecificTaskForm(this.formBuilder);
  }
}
