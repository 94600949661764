<ng-container *ngIf="isWithinTableColumn">
  <a
    (click)="askForLanguageAndOpenReport($event);"
    target="_blank"
    [tooltip]="'incidents.container.page.details.nav.printReport.button.text' | t"
  >
    <img src="assets/favicons/icon_download_report.svg">
  </a>
</ng-container>

<div
  *ngIf="!isWithinTableColumn"
>
  <button
    ui-button
    id="playbookGenerateReportButton"
    color="lightGreyFlat"
    icon="icon_download_report"
    [custom-icon]="true"
    [isSvg]="true"
    icon-size="small"
    icon-margin="0 1px 0 0"
    [disabled]="isDisabled"
    (click)="askForLanguageAndOpenReport($event);"
  >
    {{ 'playbookCatalog.report.generate' | t }}
  </button>
</div>