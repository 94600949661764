import { Component, Input, OnInit } from "@angular/core";
import { DisplayService } from "projects/@common/modules/display/display.service";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { Notice, NoticeLevels, NoticeService } from "projects/@common/modules/notice/notice.service";
import { IncidentsApi } from "projects/@common/services/api/respond/incidents/incidents.api";
import { IUpdateIncidentPriorityRequest } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";

import { IncidentDifferenceService } from "../../../../../../services/incident.difference.service";
import { TabNamesEnum } from "../../../../incidents-details.component";
import { IUpdatePriority } from "../../components/incident-priority-picker/incident-priority-picker.component";
import { IUsecaseDescriptionResponse } from "projects/@common/services/api/respond/usecase/usecase.definition";

@Component({
  selector: 'app-summary-section',
  templateUrl: './summary-section.component.html',
  styleUrls: [ './summary-section.component.scss' ],
})
export class SummarySectionComponent implements OnInit {
  @Input()
    incident: Incident;

  @Input()
    usecases: IUsecaseDescriptionResponse[];

  @Input()
    organizationId: string;

  @Input()
    isEditable: boolean;

  constructor(
    private readonly incidentsApi: IncidentsApi,
    private incidentDifferenceService: IncidentDifferenceService,
    public i18nService: I18nService,
    private readonly notice: NoticeService,
    private readonly displayService: DisplayService
  ) { }
  ngOnInit(): void {
  }

  public get shouldShowUnsavedWarning(): boolean {
    return this.incidentDifferenceService.shouldShowUnsavedWarnings;
  }

  public get hasEditPermission(): boolean {
    return this.displayService.meetsRequirements({ permissions: [ 'can_update_incident' ] });
  }

  public get canEditSeverity(): boolean {
    return this.isEditable && this.hasEditPermission && this.incident.isEditable;
  }

  public get canEditPriority(): boolean {
    return this.isEditable && this.hasEditPermission && this.incident.isEditable;
  }

  public get canEditConfidentialityBreach(): boolean {
    return this.isEditable && this.hasEditPermission && this.incident.isEditable;
  }

  public get canEditSummary(): boolean {
    return this.isEditable && this.hasEditPermission && this.incident.isEditable && !this.incident.isReopened;
  }

  public get canEditZohoLink(): boolean {
    return this.isEditable && this.hasEditPermission && this.incident.isEditable;
  }

  public saveSeverity(value: number): void {
    this.incident.severity = Number(value);
    this.save();
  }

  public savePriority(value: IUpdatePriority): void {
    const request: IUpdateIncidentPriorityRequest = {
      id: this.incident.id,
      priority: value.priority,
      comment: value.comment,
    };
    this.incidentsApi.updateIncidentPriority(this.organizationId, request).subscribe(
      (response) => {
        this.incident.reloadIncident({
          ...response.incident,
        });
        this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.success'), NoticeLevels.SUCCESS));
      },
      (error) => this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.error'), NoticeLevels.ERROR))
    );
  }

  public saveConfidentialityBreach(value: boolean): void {
    this.incident.breachOfConfidentiality = value;
    this.save();
  }

  public saveSummary(value: string): void {
    this.incident.summary = value?.length ? value.trim() : "";
    this.save();
  }

  public saveZohoTicket(zohoTicket: { url: string, id: string; }): void {
    this.incident.zohoTicket = zohoTicket.id;
    this.incident.zohoTicketUrl = zohoTicket.url;
    this.save();
  }

  public save(): void {
    this.incidentsApi.updateIncident(this.organizationId, this.incident.updateRequestDto).subscribe(
      (response) => {
        this.incident.reloadIncident({
          ...response.incident,
          tasks: response.tasks,
          resolveIncidentActions: response.resolveIncidentActions,
        });
        this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.success'), NoticeLevels.SUCCESS));
      },
      (error) => this.notice.notifyUser(new Notice(this.i18nService.translate('incidents.container.page.details.tab.detail.save.error'), NoticeLevels.ERROR))
    );
  }

  public handleSummaryEditingState(event: boolean): void {
    this.incidentDifferenceService.handleEditingStates({
      tabNameEnum: TabNamesEnum.DETAILS,
      itemId: "summary",
      hasUnsavedChange: event,
    });
  }

  public copyIncidentURL(): void {
    navigator.clipboard.writeText(this.incident.shortId);
    this.notice.notifyUser(new Notice(this.i18nService.translate('common.action.copied'), NoticeLevels.SNACK));
  }
}
