import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MobileService } from '@common/services/mobile.service';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { SharesResourceAvatar } from 'projects/@assist-ops/components/shares-resource-avatar/shares-resource-avatar.component';
import { Eco } from 'projects/@common/definitions/eco';
import { User } from 'projects/@common/definitions/user.definition';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IGetResourcesWithSharesResponse } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares.definitions';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { FileRiskEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'admin-top10-high-risk-shares',
  templateUrl: './admin-top10-high-risk-shares.component.html',
  styleUrls: ['./admin-top10-high-risk-shares.component.scss'],
})
export class AdminTop10HighRiskSharesComponent implements OnInit, OnChanges {
  @Input() isFromConsoleOrg = true;
  @Input() showReminder = false;
  @Input() selectedUsers: User[] = [];
  @Input() customEmptyMessageKey: string;

  public resources = [];

  public columnsDef: any[] = [
    {
      label: 'shares.list.columns.name.name',
      field: 'groupType',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: SharesResourceAvatar,
      paramName: 'resource',
      size: 'normal',
      rowspan: 2,
      noSorting: true,
      width: '60px',
      isResizable: false,
    },
    {
      label: '',
      field: 'name',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      isTruncated: true,
      width: '150px',
    },
    {
      label: 'shares.list.columns.owners.name',
      field: 'owners',
      type: UiStaticTableRowType.AVATARS,
      avatarSize: 'tiny',
      rowspan: 2,
      width: '120px',
      minWidth: '120px',
      noSorting: true,
      isResizable: false,
    },
  ];
  public isLoading = false;

  public locale: LanguageEnum;

  constructor(
    public mobileService: MobileService,
    private sharesApiService: SharesApiService,
    private readonly i18n: I18nService,
    private staticTableDataMapper: StaticTableDataMapper,
  ) { }

  ngOnInit(): void {
    this.locale = this.i18n.currentLocale as LanguageEnum;
    this.loadLibraries();
  }

  ngOnChanges() {
    this.loadLibraries();
  }

  private loadLibraries(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.resources = [];

      const params = {
        risks: [FileRiskEnum.HIGH],
        sort: 'sharedFiles.number',
        direction: UiTableDirection.Desc,
        size: 10,
      };
      const call = this.isFromConsoleOrg
        ? this.sharesApiService.getResourcesWithSharesOrg(params)
        : this.sharesApiService.getResourcesWithSharesReportingTo(
          params,
          this.selectedUsers.map((user) => user.o365UserId)
        );

      call
        .then((resources) => {
          this.resourcesMapper(resources);
          this.resources = this.resources.slice(0, 10);
        })
        .finally(() => {
          [this.columnsDef, this.resources] = this.staticTableDataMapper.translate(this.columnsDef, this.resources);
          this.isLoading = false;
        });
    }
  }

  private resourcesMapper(resources: Eco.IFetchResult<IGetResourcesWithSharesResponse>): void {
    const resourcesWithStats = resources.items.map((resource) => ({
      name: resource.resourceType === 'ONEDRIVE' ? `OneDrive - ${resource.resourceName}` : resource.resourceName,
      owners: resource.owners,
      groupType: resource.resourceType,
      id: resource?.resourceId,
      showReminderAction: resource?.showReminderAction
    }));
    this.resources = this.resources.concat(resourcesWithStats);
  }
}
