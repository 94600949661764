<detail-layout [backLink]="'../../'">
    <div class="connector-container" topCard>
        <div class="connector-details-section">
            <div class="connector-details-title">
                <img [src]="connectorMappingHelper.getConnectorIcon(connector.id)">
                <h2>{{ connector.translations.name }}</h2>
            </div>
            <p>{{ connector.translations.description }}</p>
            <div class="connector-informations" *ngIf="instance">
                <div class="connector-informations-items">
                    <div class="connector-informations-title-section">
                        <p>{{'detect.connector.instance.drawer.connectorType.fieldName' | t}}</p>
                    </div>
                    <p>{{ connector.type.toUpperCase() }}</p>
                </div>

                <div class="connector-informations-items" *ngIf="connector.billingMethod">
                    <div class="connector-informations-title-section">
                        <p>{{'detection.connector.instance.page.billing.title' | t}}</p>
                        <ui-tooltip [isSmall]="true" [data]="billingTooltipDescription"></ui-tooltip>
                    </div>
                    <p>{{ connector.billingMethod.name }}</p>
                </div>
    
                <div class="connector-informations-items">
                    <div class="connector-informations-title-section">
                        <p>{{'detection.connector.instance.page.category.title' | t}}</p>
                        <ui-tooltip [isSmall]="true" [data]="i18n.translate('detection.connector.instance.page.category.tooltip.description')"></ui-tooltip>
                    </div>
                    <p *ngIf="connector.category?.name">{{ connector.category.name }}</p>
                    <p *ngIf="!connector.category?.name"> - </p>
                </div>
    
                <div class="connector-informations-items">
                    <div class="connector-informations-title-section">
                        <p>{{'detection.connector.instance.page.function.title' | t}}</p>
                        <ui-tooltip [isSmall]="true" [data]="i18n.translate('detection.connector.instance.page.function.tooltip.description')"></ui-tooltip>
                    </div>
                    <p *ngIf="functionsToDisplay">{{ functionsToDisplay }}</p>
                    <p *ngIf="!functionsToDisplay"> - </p>
                </div>
    
                <div class="connector-informations-items" *ngIf="connector.vendor">
                    <div class="connector-informations-title-section">
                        <p>{{'detection.connector.instance.page.vendor.title' | t}}</p>
                        <ui-tooltip [isSmall]="true" [data]="i18n.translate('detection.connector.instance.page.vendor.tooltip.description')"></ui-tooltip>
                    </div>
                    <p>{{ connector.vendor.name }}</p>
                </div>
            </div>
        </div>
        <div class="connector-family-section" *ngIf="connector.family">
            <div class="tooltip">
                <img [src]="connectorMappingHelper.getConnectorFamilyIcon(connector.family.id)">
                <div class="tooltip-text" [innerHTML]="connector.family.name"></div>
            </div>
        </div>
    </div>

    <div bottomCard>
        <ng-container *ngIf="canDisplayDataConnectorsPermissions">
            <ui-tabs [color]="listTabsColor" [hasWhiteBackroundColor]="false">
                <ui-tab [tabTitle]="'detect.connector.instance.details.card.tab.overview' | t">
                    <ng-container [ngTemplateOutlet]="volumetry"></ng-container>
                </ui-tab>
                <ui-tab 
                    [tabTitle]="'detect.connector.instance.details.card.tab.permissions' | t"
                    [icon]="(connectorSoarPermissions?.hasActionsWithInvalidPermissions || connectorDataPermissions?.hasInvalidPermissions) ? 'icon_pause_warning' : ''"
                    [iconOpacity]="1">
                    <ng-container *ngIf="instance.type === connectorType.DATA">
                        <ng-container [ngTemplateOutlet]="permissionsDataConnector"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="instance.type === connectorType.SOAR">
                        <ng-container [ngTemplateOutlet]="permissionsSoarConnector"></ng-container>
                    </ng-container>
                </ui-tab>
            </ui-tabs>
        </ng-container>
        <ng-container *ngIf="!canDisplayDataConnectorsPermissions">
            <ng-container [ngTemplateOutlet]="volumetry"></ng-container>
        </ng-container>
    </div>
</detail-layout>

<ng-template #volumetry>
    <div class="connector-instance-card" *ngIf="instance">
        <h2>{{ 'detect.connector.instance.details.card.overview.title' | t }}</h2>
        <div class="connector-instance-data-items-container">
            <div class="connector-instance-data-items-top-left">
                <p class="connector-instance-data-items-label">{{ 'datasources.tab.info.createdAt' | t }}</p>
                <p class="connector-instance-data-items-value">{{ instance.createdAt | dt:{locale: i18n.currentLocale} }}</p>
            </div>
            <div class="connector-instance-data-items-top-right">
                <p class="connector-instance-data-items-label">{{ 'datasources.tab.info.updatedAt' | t }}</p>
                <p class="connector-instance-data-items-value">{{ instance.updatedAt | dt:{locale: i18n.currentLocale}  }}</p>
            </div>
            <div class="connector-instance-data-items-bottom-left">
                <p class="connector-instance-data-items-label">{{ 'sidebar.navigation.tools.secrets.title' | t }}</p>
                <div class="connector-instance-data-items-value-secret">
                    <div *ngFor="let secret of secretKeys">
                        <div class="connector-instance-data-items-value-secret-link" (click)="redirectToSecretsPage(secret)">
                            {{ secret }}
                            <img src="assets/favicons/icon_open_external.svg">
                        </div>
                    </div>
                    <ng-container *ngIf="instance.secretKeys && instance.secretKeys.length === 0">
                        {{ 'detection.connector.instance.page.info.secret.without.text' | t }}
                    </ng-container>
                </div>
            </div>

            <ng-container *ngIf="instance.type === connectorType.DATA">
                <div class="connector-instance-data-items-bottom-right">
                    <p class="connector-instance-data-items-label">{{ 'detection.playbook.list.label.usecases' | t }}</p>
                    <div class="connector-instance-data-items-value-link" (click)="redirectToUsecasesPage()">
                        {{ 'detection.connector.instance.page.info.usecase.link.text' | t }}
                        <img src="assets/favicons/icon_open_external.svg">
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="instance.type === connectorType.DATA">
            <div class="connector-instance-volumetry" *ngIf="instance && instance?.volumetryExcluded != 1">
                <app-connector-instance-volumetry-chart-section [organization]="organization" [connectorInstanceId]="instance.id"></app-connector-instance-volumetry-chart-section>
            </div>
    
            <app-connector-instance-graphs-volume-section *ngIf="instance" [connectorInstance]="instance"></app-connector-instance-graphs-volume-section>
    
            <div class="without-connector-instance" *ngIf="!instance">
                <h2>{{'detection.connector.instance.page.not.found.title' | t}}</h2>
                <p>{{'detection.connector.instance.page.not.found.description' | t}}</p>
                <a class="without-connector-instance-link" [href]="contactLink">{{'detection.connector.instance.page.not.found.link.text' | t}}</a>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #permissionsDataConnector>
    <div class="connector-instance-card">
        <connectors-data-permissions 
            #connectorDataPermissions
            [organizationId]="organization.id"
            [connectorId]="instance.id"
            [connectorName]="instance.translations.name"
        ></connectors-data-permissions>
    </div>
</ng-template>

<ng-template #permissionsSoarConnector>
    <div class="connector-instance-card">
        <connectors-soar-permissions 
            #connectorSoarPermissions
            [selectedOrganizationId]="organization.id"
            [connectorInstanceId]="instance.id"
            [connectorType]="instance.connectorId.includes(soarConnectorTypes.AWS) ? soarConnectorTypes.AWS : soarConnectorTypes.MICROSOFT_365"
            [metadata]="instance.connector.metadata"
        ></connectors-soar-permissions>
    </div>
</ng-template>
