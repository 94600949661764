import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { IWhitelistCondition, IWhitelistConditionField } from "projects/@common/services/api/respond/whitelists/whitelists.definitions";

export function getWhitelistConditionDescription(condition: IWhitelistCondition, conditionFields: IWhitelistConditionField[], i18n: I18nService): string {
  if (!condition.id) {
    return i18n.translate('whitelists.drawer.tabs.details.conditions.ecsFields.tooltip.pleaseselect');
  }
  const ecsFields: string[] = conditionFields.find((field) => field.id === condition.id)?.ecsFields;
  const str = ecsFields?.length > 0
    ? ecsFields.map((f) => `${f}`).join(', ')
    : i18n.translate('whitelists.drawer.tabs.details.conditions.ecsFields.tooltip.advanced');
  return i18n.translate('whitelists.drawer.tabs.details.conditions.ecsFields.tooltip', { ecsFields: str });
}
