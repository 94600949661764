import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";

import { EcoUrlService } from "../../core/url.service";
import { Finding } from "./model/finding";
import { GetAlertFindingsRequest } from "./model/findingFilters";
import { IFindingsApi } from "./finding.definition";
import { Store } from "@ngxs/store";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { CustomerFindingsApi } from "projects/console-org/src/app/respond/findings/api/customer-findings.api";
import { VarFindingsApi } from "projects/console-adm/src/app/respond/findings/api/var-findings.api";

@Injectable({
  providedIn: 'root',
})
export class FindingsApi implements IFindingsApi {
  private customerService: CustomerFindingsApi;
  private varService: VarFindingsApi;

  constructor(
    protected store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerFindingsApi(http, url);
    this.varService = new VarFindingsApi(http, url);
  }

  public listFindings(request: GetAlertFindingsRequest): Observable<ResponseUtils<Finding>> {
    if (this.isVarMode) {
      return this.varService.listFindings(request);
    }
    return this.customerService.listFindings(request);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
