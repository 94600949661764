<div
  detail
  [useStickyTop]="true"
>
  <app-incident-detail-navbar
    header
    [incident]="incident"
    [usecases]="usecases"
    [observables]="observables"
    [incidentActions]="incidentActions"
    [entities]="entities"
    [tasksMessages]="tasksMessages"
    [showHeaderOrgInfo]="viewParams.showHeaderOrgInfo"
    [isAdminConsole]="viewParams.isAdminConsole"
    (closeIncidentEvent)="handleCloseIncident()"
    (reopenIncidentEvent)="handleReopenIncident()"
    (sendNotificationEvent)="handleNotificationSending()"
  ></app-incident-detail-navbar>
  <div class="container">
    <respond-custom-tabs
      header
      *ngIf="incident && tabs"
      [tabs]="tabs"
      [translatePrefix]="'incidents.container.page.details.tabs.'"
      (tabsChangeEmitter)="handleTabChanged($event)"
    >
      <app-incident-tab-header
        [incident]="incident"
        [organizationId]="organizationId"
        [isEditable]="viewParams.isEditable"
        [showHeaderOrgInfo]="viewParams.showHeaderOrgInfo"
        tabHeader
      ></app-incident-tab-header>

      <app-details-tab
        [isEditable]="viewParams.isEditable"
        [isAdminConsole]="viewParams.isAdminConsole"
        [incident]="incident"
        [usecases]="usecases"
        [organizationId]="organizationId"
        [class.hidden]="currentTabSelected.id !== 1"
      ></app-details-tab>

      <app-task-tab
        #taskTabRef
        *ngIf="showTaskTab && !isLoadingIncident && !isLoadingObservables && !isLoadingUsecase && !isLoadingAssets"
        [incident]="incident"
        [observables]="observables"
        [entities]="entities"
        [incidentActions]="incidentActions"
        [isLoadingEntities]="isLoadingEntities"
        [assets]="assets"
        [assetTypes]="assetTypes"
        [usecaseMonitors]="usecaseMonitors"
        [showToolboxComponent]="showObservablesTab"
        [class.hidden]="currentTabSelected.id !== 2"
        [isAdminConsole]="viewParams.isAdminConsole"
        (stopInvestigation)="onStopInvestigation()"
        (startConversation)="handleNotificationSending($event)"
        (emitTaskMessages)="setTasksMessages($event)"
      ></app-task-tab>

      <app-observable-tab
        *ngIf="showObservablesTab && !isLoadingIncident && !isLoadingObservables && !isLoadingUsecase && !isLoadingAssets"
        [incident]="incident"
        [observables]="observables"
        [assets]="assets"
        [assetTypes]="assetTypes"
        [usecaseMonitors]="usecaseMonitors"
        [class.hidden]="currentTabSelected.id !== 3"
      ></app-observable-tab>

      <app-alerts-tab
        *ngIf="showAlertsTab"
        [incident]="incident"
        [class.hidden]="currentTabSelected.id !== 4"
        [isAdminConsole]="viewParams.isAdminConsole"
        (inidentFindings)="setIncidentFindings($event)"
      ></app-alerts-tab>

      <app-chat-tab
        *ngIf="!!incident.conversationId"
        [isFromAdmConsole]="viewParams.isAdminConsole"
        [incidentConversation]="incidentConversation"
        [incidentNotes]="incidentNotes"
        [incident]="incident"
        [class.hidden]="currentTabSelected.id !== 5"
        [currentTabSelected]="currentTabSelected"
      ></app-chat-tab>

      <incident-similarity-tab
        *ngIf="showSimilarityTab"
        [incident]="incident"
        [class.hidden]="currentTabSelected.id !== 6"
      ></incident-similarity-tab>

      <app-chronology-tab
        [incident]="incident"
        [class.hidden]="currentTabSelected.id !== 7"
      ></app-chronology-tab>

      <app-incident-history-tab
        *ngIf="showHistoryTab"
        [incident]="incident"
        [class.hidden]="currentTabSelected.id !== 8"
      ></app-incident-history-tab>
    </respond-custom-tabs>
  </div>
</div>