import { DataConnectorTypes, DataConnectorTypesCatalog, IConnectorTypesCatalog, SoarConnectorTypesCatalog } from '@md.eco/connectors';
import { merge } from 'lodash';
import { SoarConnectorTypesEnum } from '../api/respond/connectors/connectors-alerting-api.definition';

const ICON_BASE_PATH = 'assets/favicons';

interface IWebUiDataConnectorTypesCatalog {
  icon: string; // Set to null to apply a default value
};

const tempImplementationCatalog: { [id in DataConnectorTypes | SoarConnectorTypesEnum]: IWebUiDataConnectorTypesCatalog } = {
  [DataConnectorTypes.AWS_WAF]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_waf.svg`,
  },
  [DataConnectorTypes.AZURE_ACTIVITY_LOGS]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_azureActivityLogs.svg`,
  },
  [DataConnectorTypes.AZURE_SENTINEL]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_azuresentinel.svg`,
  },
  [DataConnectorTypes.BIT_DEFENDER]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_bitdefender.svg`,
  },
  [DataConnectorTypes.CISCO_FMC]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_ciscoios.svg`,
  },
  [DataConnectorTypes.CISCO_FTD]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_cisco.svg`,
  },
  [DataConnectorTypes.CISCO_UMBRELLA]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_ciscoumbrella.svg`,
  },
  [DataConnectorTypes.CLOUDTRAIL]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_cloudtrail.svg`,
  },
  [DataConnectorTypes.CORTEX_XDR]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_cortexxdr.svg`,
  },
  [DataConnectorTypes.DATABRICKS]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_databrick.svg`,
  },
  [DataConnectorTypes.FORTINET]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_fortinet.svg`,
  },
  [DataConnectorTypes.FORTIWEB]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_fortiweb.svg`,
  },
  [DataConnectorTypes.GUARDDUTY]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_guardduty.svg`,
  },
  [DataConnectorTypes.INFORMATICA]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_informatica.svg`,
  },
  [DataConnectorTypes.JUNIPER_SRX]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_junipersrx.svg`,
  },
  [DataConnectorTypes.JUNOS_OS]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_junos.svg`,
  },
  [DataConnectorTypes.AWS_LINUX]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_linux.svg`,
  },
  [DataConnectorTypes.LINUX]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_linux.svg`,
  },
  [DataConnectorTypes.MICROSOFT_365_DEFENDER]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_atpendpoint.svg`,
  },
  [DataConnectorTypes.OFFICE]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_office.svg`,
  },
  [DataConnectorTypes.OPENVAS]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_openvas.svg`,
  },
  [DataConnectorTypes.PALOALTO]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_paloalto.svg`,
  },
  [DataConnectorTypes.PULSE_SECURE_VPN]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_pulsevpn.svg`,
  },
  [DataConnectorTypes.SECURITY_HUB]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_securityhub.svg`,
  },
  [DataConnectorTypes.SENTINELONE_SINGULARITY]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_sentineloneSingularity.svg`,
  },
  [DataConnectorTypes.ENTRA]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_mssigninslogs.svg`,
  },
  [DataConnectorTypes.SNOWFLAKE]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_snowflake.svg`,
  },
  [DataConnectorTypes.V_CENTER]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_vcenter.svg`,
  },
  [DataConnectorTypes.WATCH_GUARD_FIREBOX]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_watchGuardFirebox.svg`,
  },
  [DataConnectorTypes.WINDOWS_EVENT_LOGS]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_windowsevent.svg`,
  },
  [DataConnectorTypes.OPA]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_opa.svg`,
  },
  [DataConnectorTypes.TIQ_AUTH]: {
    icon: null,
  },
  [DataConnectorTypes.FORTI_SIEM]: {
    icon: null,
  },
  [DataConnectorTypes.GENERIC_SYSLOG]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_genericSyslog.svg`,
  },
  [DataConnectorTypes.OPA_CONFIG]: {
    icon: null,
  },
  [DataConnectorTypes.LDAP_CONFIG]: {
    icon: null,
  },
  [DataConnectorTypes.TIQ_ACTIVITY_LOG]: {
    icon: null,
  },
  [DataConnectorTypes.GINGER_ACTIVITY_LOG]: {
    icon: null,
  },
  [DataConnectorTypes.KNOWBILITY_ACTIVITY_LOG]: {
    icon: null,
  },
  [DataConnectorTypes.TIQ_PERMIT_USER_LIST]: {
    icon: null,
  },
  [DataConnectorTypes.TIQ_EXTERNAL_USER_LIST]: {
    icon: null,
  },
  [DataConnectorTypes.GOOGLE_WORKSPACE_ALERT]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_googleWorkspace.svg`,
  },
  [SoarConnectorTypesEnum.MICROSOFT_365_RESPONDER]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_ms_responder.svg`,
  },
  [SoarConnectorTypesEnum.MICROSOFT_365_ANALYZER]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_ms_analyzer.svg`,
  },
  [SoarConnectorTypesEnum.AWS_RESPONDER]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_aws_responder.svg`,
  },
  [SoarConnectorTypesEnum.AWS_ANALYZER]: {
    icon: `${ICON_BASE_PATH}/logo_datasourceType_aws_analyzer.svg`,
  },
};

// Merging webui catalog with the default catalog.
for (const key of Object.keys(tempImplementationCatalog)) {
  tempImplementationCatalog[key] = merge(tempImplementationCatalog[key], !!DataConnectorTypesCatalog[key] ? DataConnectorTypesCatalog[key] : SoarConnectorTypesCatalog[key]);

  // If the icon is set to null in the catalog, we set it to the default value
  if (!tempImplementationCatalog[key as DataConnectorTypes].icon) {
    tempImplementationCatalog[key as DataConnectorTypes].icon = `${ICON_BASE_PATH}/logo_datasourceType_default.svg`;
  }
}

export type WebUiDataConnectorTypesCatalogTemplate = { [id in DataConnectorTypes | SoarConnectorTypesEnum]: IConnectorTypesCatalog & IWebUiDataConnectorTypesCatalog };

export const WebUiDataConnectorTypesCatalog: WebUiDataConnectorTypesCatalogTemplate = tempImplementationCatalog as WebUiDataConnectorTypesCatalogTemplate;
