<widget-container
  [title]="'connector.dashboard.my.daily.data.section.title' | t"
  [locale]="i18n.currentLocale"
  [withBottomMargin]="true"
  [withTimeRange]="true"
  [timeRanges]="TIME_RANGE_ENUM"
  [timeRangesDefault]="currentTimeRange"
  (selectedTimerange)="handleTimeRangeChange($event)">
  <div class="content-container" [style.display]="isLoading ? 'none': 'flex'">
    <div class="badge-container" *ngIf="chartData.length > 0">
      <capacity-card
        class="card-border"
        numberColor="#47c1bf"
        [capacityNumber]="activeOrganization?.totalSubscribedCapacity"
        [topDescription]="'detection.datasource.page.capacity.total' | t"
        [bottomDescription]="'detection.datasource.page.capacity.day' | t"
      ></capacity-card>
      <capacity-card
        class="card-border"
        numberColor="#47c1bf"
        [capacityNumber]="averageCapacityValue"
        [topDescription]="'detection.datasource.page.capacity.average' | t"
        [bottomDescription]="'detection.datasource.page.capacity.day.unit.parameter' | t: {valueType: averageCapacityType}"
      ></capacity-card>
    </div>

    <div class="chart-container">
      <graph-connnector-daily-ingested-data [chartData]="chartData" [hidden]="chartData.length === 0 || isLoading"></graph-connnector-daily-ingested-data>
      <div *ngIf="chartData.length === 0 && !isLoading" class="graph-without-data-container">
        {{ 'dashboard.admin.noData' | t }}
      </div>

      <div class="graph--footer" *ngIf="chartData.length > 0">
        <p>{{ 'respond.dashboard.connectors.daily.data.chart.description' | t}}</p>
        <a [routerLink]="routerLink" class="chart-link" *display="{ permissions: routingPermissions }">
          {{ 'respond.dashboard.connectors.daily.data.chart.link' | t }}
        </a>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isLoading">
    <ui-spinner size="medium" modifier="secondary"></ui-spinner>
  </ng-container>
</widget-container>