<div
  page
  [appPagePaths]="['mdRespond', 'whitelists']"
  [hasTopSection]="true"
  [tooltipTitle]="adminConsole ? 'whitelists.table.title.tooltip.admin': 'whitelists.table.title.tooltip.client'"
  [searchMode]="searchMode"
  (organizationChanged)="organizationChanged($event)"
>
  <span
    *ngIf="showEmptyPageToSelectOrganization"
    class="no-org-transition"
  >
    {{ 'organizations_select.notSelected.message' | t }}
  </span>

  <whitelists-filters
    *ngIf="!showEmptyPageToSelectOrganization"
    toolbar
  ></whitelists-filters>
  <whitelists-table
    *ngIf="!showEmptyPageToSelectOrganization"
    [defaultColumnsDef]="defaultTableColumns"
    [whitelistsData]="whitelistsData"
    [canDescribe]="canDescribe"
    [canCreate]="canCreate"
    [canDelete]="canDelete"
    [isReadonly]="isReadonly"
    (rowClickEvent)="openWhitelistDrawer($event)"
    (createEvent)="openWhitelistDrawer(null)"
    (deleteEvent)="openDeleteModal()"
  ></whitelists-table>

</div>