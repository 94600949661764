import { Component, Input, OnInit } from '@angular/core';
import { IWhitelistRepresentation } from 'projects/@common/services/api/respond/whitelists/whitelists.definitions';

@Component({
  selector: 'whitelist-created-by',
  templateUrl: './whitelist-created-by.component.html',
  styleUrls: [ './whitelist-created-by.component.scss' ],
})
export class WhitelistCreatedByComponent implements OnInit {
  @Input() whitelist: IWhitelistRepresentation;

  public createdByName: string;
  public showMondataLogo: boolean;

  constructor() { }

  ngOnInit(): void {
    this.createdByName = this.whitelist.createdByName;
    this.showMondataLogo = !this.whitelist.createdByOrganization;
  }
}
