import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import {
  OrganizationSensitiveFilesSettings,
  OrganizationSettings,
  OrganizationShareSettings
} from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';

@Component({
  selector: 'settings',
  templateUrl: './settings.container.html',
  styleUrls: [ './settings.container.scss' ],
})
export class SettingsContainer {
  public uiTabsColor = UiTabsColor;
  public currentSettings: OrganizationSettings & OrganizationShareSettings & OrganizationSensitiveFilesSettings;
  public isLoading = false;

  public featureFlagEnum = FeaturesEnum;
   public tabIndex = 0

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private route : ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.tabIndex = this.route.snapshot.queryParams.tabIndex ?? 0
    this.loadCurrentSettings();
  }

  public get hasNoneOfNecessaryFeature(): boolean {
    const flagsDecisions = this.store.selectSnapshot((state) => state.eco.featureFlags);
    return [ FeaturesEnum.SHARES_MANAGEMENT, FeaturesEnum.RESOURCE_MANAGEMENT ].every((id) => !flagsDecisions[id]);
  }

  private loadCurrentSettings() {
    this.isLoading = true;
    this.organizationsApiService
      .getOrganizationSettings()
      .then((response) => {
        this.currentSettings = response;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
