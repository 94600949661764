<img
  *ngIf="isSimilar"
  src="assets/favicons/observables/icon_similarity_match.svg"
  alt="similar"
  [title]="'respond.observables.similar.tooltip' | t"
>

<img
  *ngIf="useRedFlagFeature && isRedFlagged"
  [src]="'assets/favicons/observables/icon_flag_grey.svg'"
  alt="Red Flag"
  [title]="'respond.observables.redflag.tooltip' | t: {tasks: redFlagTooltipTaskNames}"
>