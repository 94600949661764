import { Component, Input } from '@angular/core';
import { OrganizationSettings } from '@common/services/api/sg/organizations/organizations.definitions';

@Component({
  selector: 'app-guest-settings',
  templateUrl: './guest-settings.component.html',
  styleUrls: ['./guest-settings.component.scss']
})
export class GuestSettingsComponent {
  @Input() public currentSettings: OrganizationSettings;
  @Input() public isLoading = false;

  constructor() { }
}
