<settings-edition-card
  [icon]="'Icon_timer_bold_cropped.svg'"
  [title]="'settings.shares.duration.tolerance.card.title' | t"
  [canSave]="isValid"
  (saveEvent)="save()"
  (cancelEvent)="cancel()"
  (toggleEditingEvent)="isEditing=$event"
>
  <div class="card-content">
    <ng-container *ngIf="!isEditing; then readOnly else editing"></ng-container>
  </div>
</settings-edition-card>

<ng-template #readOnly>
  <div class="form-content">
    <p [innerHTML]="'settings.shares.duration.tolerance.description' | t"></p>
    <div class="cool-line-container">
      <div class="cool-line-section">
        <p
          class="medium-duration"
          [innerHTML]="'settings.shares.duration.tolerance.days.acceptable' | t:{nbDays: durationMediumDayCountControl.value}"
        ></p>
        <div class="inner-cool-line green-color"></div>
      </div>

      <div class="cool-line-section">
        <div class="inner-cool-line yellow-color"></div>
      </div>

      <div class="cool-line-section">
        <p
          class="high-duration"
          [innerHTML]="'settings.shares.duration.tolerance.days.max' | t:{nbDays: durationLongDayCountControl.value}"
        ></p>
        <div class="inner-cool-line red-color"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editing>
  <div class="form-content">
    <p [innerHTML]="'settings.shares.duration.tolerance.description' | t"></p>
    <button
      *ngIf="!isDefaultValues"
      class="default-values"
      (click)="restoreDefaults()"
    >{{ 'settings.notification.frequency.default.values' | t }}</button>

    <div
      class="cool-line-container"
      [formGroup]="toleranceForm"
    >
      <div class="cool-line-section">
        <p
          class="medium-duration"
          [innerHtml]="'settings.shares.duration.tolerance.days.edit.acceptable' | t"
        ></p>
        <input
          class="left-input"
          ui-input
          [ngClass]="{
            'invalid': durationMediumDayCountControl.invalid
          }"
          maxlength="3"
          uiNumberOnlyInput
          formControlName="durationMediumDayCount"
        />
        <ng-container *ngIf="durationMediumDayCountControl.invalid">
          <div class="fielderror align-right">{{getErrorMessage(durationMediumDayCountControl)}}</div>
        </ng-container>
        <div class="inner-cool-line green-color"></div>
      </div>

      <div class="cool-line-section">
        <div class="inner-cool-line yellow-color"></div>
      </div>

      <div class="cool-line-section">
        <p
          class="high-duration"
          [innerHtml]="'settings.shares.duration.tolerance.days.edit.max' | t"
        ></p>
        <input
          class="right-input"
          ui-input
          [ngClass]="{
            'invalid': durationLongDayCountControl.invalid
          }"
          maxlength="3"
          uiNumberOnlyInput
          formControlName="durationLongDayCount"
        />
        <ng-container *ngIf="durationLongDayCountControl.invalid">
          <div class="fielderror">{{getErrorMessage(durationLongDayCountControl)}}</div>
        </ng-container>
        <div class="inner-cool-line red-color"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #spinner>
  <ui-spinner size="medium"></ui-spinner>
</ng-template>
