import { Component, Input, OnInit } from '@angular/core';
import { MitreAttackGenericData } from 'projects/@common/services/api/detect/mitre-attack/mitre-attack-api.definition';

@Component({
  selector: 'playbook-details-mitre-attack-card',
  templateUrl: './playbook-details-mitre-attack-card.component.html',
  styleUrls: [ './playbook-details-mitre-attack-card.component.scss' ],
})
export class PlaybookDetailsMitreAttackCardComponent implements OnInit {
  @Input() public attack: MitreAttackGenericData;
  public isTechnique: boolean;

  constructor() { }

  ngOnInit(): void {
    this.isTechnique = this.isTechniqueAttack();
  }

  public openMitreAttackWebsite() {
    const url = `https://attack.mitre.org/${this.isTechnique ? 'techniques' : 'tactics'}/${this.attack.attackId}/`;
    window.open(url, "_blank");
  }

  private isTechniqueAttack(): boolean {
    return this.attack.type === 'technique';
  }
}
