import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UiSelect } from '@ui-kit/components/ui-select/ui-select.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IncidentPriorityEnum } from 'projects/@common/services/api/respond/incidents/incidents.definitions';

export interface IUpdatePriority {
  priority: IncidentPriorityEnum;
  comment: string;
}

@Component({
  selector: 'incident-priority-picker',
  templateUrl: './incident-priority-picker.component.html',
  styleUrls: [ './incident-priority-picker.component.scss' ],
})
export class IncidentPriorityPickerComponent implements OnInit {
  @Input() priority: number;
  @Input() disabled: boolean;
  @Input() organizationEcoId: string;

  @Output() priorityChangeEvent = new EventEmitter<IUpdatePriority>();

  @ViewChild('priorityDropdown', { static: false }) priorityDropdown: UiSelect;

  public priorities: Array<{ value: number, displayValue: string }>;
  public formGroup: UntypedFormGroup;
  private _showDropdown: boolean = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly i18n: I18nService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  public get showDropdown(): boolean {
    return this._showDropdown;
  }

  public get currentPriorityValue(): IncidentPriorityEnum {
    return Number(this.formGroup.get('priorityInput').value);
  }

  public get currentCommentValue(): string {
    return this.formGroup.get('commentInput').value;
  }

  public get isFormValid(): boolean {
    return this.formGroup.valid;
  }

  public handlePriorityChange(priority: number): void {
    this.formGroup.patchValue({ priorityInput: priority });
    this.priorityDropdown.toggleOptions();
  }

  public handleConfirmation(choice: boolean): void {
    if (choice) {
      const value: IUpdatePriority = {
        priority: this.currentPriorityValue,
        comment: this.currentCommentValue,
      };
      this.priorityChangeEvent.emit(value);
    }
    this.formGroup.reset();
    this.closePicker();
  }

  public openPicker(): void {
    if (!this.disabled) {
      this.initPriorityChoices();
      this._showDropdown = true;
    }
  }

  public closePicker(): void {
    this._showDropdown = false;
  }

  private initPriorityChoices(): void {
    const defaultPriorities = [ 1, 2, 3, 4 ];
    const availablePriorities = defaultPriorities.filter((p) => p !== this.priority);
    this.priorities = availablePriorities.map((priority: number) => ({
      value: priority,
      displayValue: this.i18n.translate(`incidents.priority.${priority}.label`),
    }));
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      priorityInput: this.formBuilder.control(null, Validators.required),
      commentInput: this.formBuilder.control("", Validators.required),
    });
  }
}
