import { CircleBullet, DateAxis, Legend, LineSeries, ValueAxis, ValueAxisDataItem, XYChart, XYCursor } from '@amcharts/amcharts4/charts';
import { addLicense, color, create } from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { randomBytes } from 'crypto';
import { DashboardFileUnitHelper } from '../../../helpers/dashboardFileUnitHelper';

export interface GraphConnectorDailyData {
  timestamp: number;
  values: Map<string, number>;
}

@Component({
  selector: 'graph-connnector-daily-ingested-data',
  templateUrl: './graph-connnector-daily-ingested-data.component.html',
  styleUrls: [ './graph-connnector-daily-ingested-data.component.scss' ],
})
export class GraphConnnectorDailyIngestedDataComponent implements AfterViewInit, OnDestroy, OnChanges {
  private readonly COLORS = [ "#004C2A", "#006F3E", "#068D44", "#1AA969", "#5ECC99", "#9EDDC1", "#004C41", "#006F5F", "#068D6D", "#1AA994" ];

  @Input()
  public chartData: GraphConnectorDailyData[] = [];

  @Input()
  public lineSerieName: string;

  @Input()
  public totalSubscribedCapacity: number = 0;

  @Input()
  public chartId: string = randomBytes(20).toString('hex');

  @Input()
  public containerHeight: string = '25rem';

  private chart: XYChart;
  private dataChartTransformed;
  private chartRange: ValueAxisDataItem;

  constructor(private readonly dashboardFileUnitHelper: DashboardFileUnitHelper) {
    addLicense('CH259911983');
  }

  ngAfterViewInit(): void {
    if (!this.chart) {
      this.createChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData && !changes.chartData?.isFirstChange()) {
      if (this.chart) {
        this.chart.series.setAll([]);
        this.setChartSeriesData(this.chart);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  private createChart(): void {
    const chart = create(`${this.chartId}_CONNECTOR_DASHBOARD_DAILY_INGESTED_ID`, XYChart);
    chart.hiddenState.properties.opacity = 0;
    chart.svgContainer.htmlElement.style.height = this.containerHeight;
    chart.colors.list = this.COLORS.map((colorHexValue) => color(colorHexValue));
    this.createChartAbscissa(chart);
    const yAxis = this.createChartOrdinate(chart);
    this.setChartSeriesData(chart);
    this.createChartRange(yAxis);
    this.createChartLegend(chart);
    chart.cursor = new XYCursor();
    chart.cursor.behavior = 'none';
    chart.cursor.lineY.visible = false;
    chart.legend.itemContainers.template.events.on("hit", () => {
      yAxis.keepSelection = false;
      yAxis.invalidateData();
    });
    chart.events.on('beforedatavalidated', () => {
      yAxis.keepSelection = false;
    });
    chart.events.on('datavalidated', () => {
      yAxis.keepSelection = true;
    });
    chart.zoomOutButton.disabled = true;
    this.chart = chart;
  }

  private createChartAbscissa(chart: XYChart): void {
    const xAxis = chart.xAxes.push(new DateAxis());
    xAxis.timezoneOffset = 0;
    xAxis.startLocation = 0.5;
    xAxis.endLocation = 0.5;
    xAxis.baseInterval.timeUnit = 'day';
    xAxis.baseInterval.count = 1;
    xAxis.dateFormatter.dateFormat = "MM dd";
    xAxis.renderer.maxLabelPosition = 0.99;
  }

  private createChartOrdinate(chart: XYChart): ValueAxis {
    const yAxis = chart.yAxes.push(new ValueAxis());
    yAxis.numberFormatter.numberFormat = "#.0b";
    yAxis.keepSelection = true;
    yAxis.calculateTotals = true;
    yAxis.extraMax = 0.1;
    return yAxis;
  }

  private setChartSeriesData(chart: XYChart) {
    if (this.chartData.length > 0) {
      this.createChartSeries(chart, this.chartData);
      this.createTotalSeriesTooltip(chart);
      chart.colors.list = this.COLORS.map((colorHexValue) => color(colorHexValue));
      chart.invalidate();
    }
  }

  private createChartSeries(chart: XYChart, data: GraphConnectorDailyData[]): void {
    this.dataChartTransformed = data.map((item) => ({
      ...item.values,
      timestamp: item.timestamp,
      _chartTotalValue: 0,
    }));
    const uniqueKeys = new Set<string>();
    data.forEach((item) => {
      Object.keys(item.values).forEach((key) => uniqueKeys.add(key));
    });

    for (const dataValueY of uniqueKeys) {
      const lineSerie = chart.series.push(new LineSeries());
      lineSerie.name = dataValueY;
      lineSerie.data = this.dataChartTransformed;
      lineSerie.stacked = true;
      lineSerie.dataFields.dateX = "timestamp";
      lineSerie.dataFields.valueY = dataValueY;
      lineSerie.fillOpacity = 0.5;
      lineSerie.strokeWidth = 2;
      const bulletPoint = lineSerie.bullets.push(new CircleBullet());
      bulletPoint.showOnInit = false;
      bulletPoint.hide();
      bulletPoint.events.on("shown", (event) => {
        event.target.hide();
      });
    }
  }

  private createTotalSeriesTooltip(chart: XYChart): void {
    const totalSeries = chart.series.push(new LineSeries());
    totalSeries.data = this.dataChartTransformed;
    totalSeries.dataFields.valueY = "_chartTotalValue";
    totalSeries.dataFields.dateX = "timestamp";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.tooltipText = "[Bold]Total:[/] {valueY.total}";
    totalSeries.tooltip.label.fill = color('#000000');
    totalSeries.tooltip.autoTextColor = false;
    totalSeries.tooltip.getFillFromObject = false;
    totalSeries.tooltip.getStrokeFromObject = false;
  }

  private createChartRange(valueAxis: ValueAxis): void {
    if (this.totalSubscribedCapacity) {
      this.chartRange = valueAxis.axisRanges.create();
      this.chartRange.value = (this.totalSubscribedCapacity * 1e+9);
      this.chartRange.grid.stroke = color("#FF0000");
      this.chartRange.grid.strokeWidth = 1.5;
      this.chartRange.grid.strokeOpacity = 1;
      this.chartRange.grid.above = true;
      this.chartRange.label.text = this.dashboardFileUnitHelper.formatValueWithBinaryUnit(this.chartRange.value);
      this.chartRange.label.location = 1;
      this.chartRange.label.fontWeight = "bold";
      this.chartRange.label.fill = color("#FF0000");
      this.chartRange.label.inside = true;
      this.chartRange.label.valign = "top";
      this.chartRange.label.dy = -9;
      this.chartRange.label.isMeasured = false;
      this.chartRange.label.interactionsEnabled = false;
    }
  }

  private createChartLegend(chart: XYChart): void {
    chart.legend = new Legend();
    chart.legend.scrollable = true;
    chart.legend.useDefaultMarker = true;
    chart.legend.position = "bottom";
  }
}
