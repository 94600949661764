import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiKitModule } from "@ui-kit/uikit.module";
import { I18nModule } from "../modules/i18n/i18n.module";
import { AlertIncidentIndicatorsComponent } from "./alert-incident-indicators/alert-incident-indicators.component";
import { AlertingBadgeComponent } from "./alerting-badge/alerting-badge.component";
import { ComboboxInputComponent } from './combobox-input/combobox-input.component';
import { CopyValueComponent } from './copy-value/copy-value.component';
import { DateIntervalComponent } from "./date-interval/date-interval.component";
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { DynamicFormComponent, DynamicFormFieldComponent } from "./dynamic-form";
import { EditButtonComponent } from './edit-button/edit-button.component';
import { EditWithModalComponent } from "./edit-with-modal/edit-with-modal.component";
import { EscalationExpandCardComponent } from "./escalation-expand-card/escalation-expand-card.component";
import { ExternalLinkDialog } from "./external-links-dialog/external-link-dialog.component";
import { IncidentStatusComponent } from "./incident-status/incident-status.component";
import { IncidentAlertsComponent } from "./incident-alerts/incident-alerts.component";
import { IncidentTableZohoLinkComponent } from './incident-table-zoho-link/incident-table-zoho-link.component';
import { JsonSortedViewComponent } from './json-sorted-view/json-sorted-view.component';
import { JsonTableComponent } from './json-table/json-table.component';
import { OrganizationFilterAutocompleteComponent } from "./organization-filter-autocomplete/organization-filter-autocomplete.component";
import { OrganizationPreferredLanguageComponent } from "./organization-preferred-language/organization-preferred-language.component";
import { PriorityTableComponent } from "./priority-table/priority-table.component";
import { SecurityGroupsTableComponent } from './security-groups-table/security-groups-table.component';
import { SeverityBadgeComponent } from "./severity-badge/severity-badge.component";
import { SgExpendableCardComponent } from "./sg-expendable-card/sg-expendable-card.component";
import { TimezoneAutocompleteComponent } from './timezone-autocomplete/timezone-autocomplete.component';
import { UsecaseAffectedResourcesComponent } from "./usecase-affected-resources/usecase-affected-resources.component";
import { UsersTableComponent } from "./users-table/users-table.component";
import { WeeklyTimeScheduleComponent } from "./weekly-time-schedule/weekly-time-schedule.component";

const components = [
  AlertingBadgeComponent,
  SeverityBadgeComponent,
  OrganizationPreferredLanguageComponent,
  OrganizationFilterAutocompleteComponent,
  PriorityTableComponent,
  IncidentStatusComponent,
  IncidentAlertsComponent,
  DateIntervalComponent,
  AlertIncidentIndicatorsComponent,
  EditWithModalComponent,
  DynamicFormComponent,
  DynamicFormFieldComponent,
  UsersTableComponent,
  SecurityGroupsTableComponent,
  JsonTableComponent,
  IncidentTableZohoLinkComponent,
  JsonSortedViewComponent,
  UsecaseAffectedResourcesComponent,
  ComboboxInputComponent,
  WeeklyTimeScheduleComponent,
  SgExpendableCardComponent,
  EditButtonComponent,
  EscalationExpandCardComponent,
  TimezoneAutocompleteComponent,
  DeleteButtonComponent,
  CopyValueComponent,
  ExternalLinkDialog
];

@NgModule({
  declarations: [ ...components ],
  imports: [
    CommonModule,
    UiKitModule,
    I18nModule,
    FormsModule,

  ],
  exports: [ ...components ],
})
export class ComponentsModule { }
