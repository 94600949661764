import { Injectable } from "@angular/core";
import { IAlertingUsecaseTemplate } from "@common/services/api/respond/usecase/usecase.definition";
import { Eco } from 'projects/@common/definitions/eco';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { UsecaseCategory } from "projects/@respond/usecases/mappers/usecaseCategories";

type CatalogCategoryRow = {
  usecase: Omit<IAlertingUsecaseTemplate, "attacks"> & { attacks: { attack: { tactic: string, techniques: { technique: string }[] } }[] }
};

@Injectable({
  providedIn: 'root',
})
export class UsecaseCatalogTemplateMapper<T extends IAlertingUsecaseTemplate> {
  constructor(private i18n: I18nService) { }

  public async generateMapper(language: Eco.Languages, usecaseCategories: UsecaseCategory<T>[]): Promise<Record<string, any>> {
    const groupedUsecases = this.groupUsecasesByConnector(language, usecaseCategories);
    return {
      name: this.i18n.translateWithLanguage("usecaseCatalog.report.title", language),
      categories: groupedUsecases,
    };
  }

  private groupUsecasesByConnector(language: Eco.Languages, usecaseCategories: UsecaseCategory<T>[]) {
    return usecaseCategories
      .map((category) => ({
        category: {
          name: category.name[language],
          header: this.i18n.translateWithLanguage("usecaseCatalog.report.header", language, { category: category.name[language] }),
        },
        usecases: category.usecases.map((usecase) => {
          const { attack, ...filteredUsecase } = usecase as any;
          return {
            usecase: {
              ...filteredUsecase,
              attacks: attack?.map((attack) => ({
                attack: {
                  tactic: `${attack.attackId} - ${attack.name}`,
                  techniques: attack.techniques.map((technique) => ({ technique: `${technique.attackId} - ${technique.name}` })),
                },
              })) || [],
            },
          };
        }),
      }), [] as CatalogCategoryRow[]);
  }
}
