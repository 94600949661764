<ui-card>
  <div *ngIf="!userSelected && !isEditing" class="warning-container">
    <ui-warning
      [warningMessages]="warningMessages"
      [centerText]="false"
      [showIcon]="true"
    >
    </ui-warning>
  </div>
  <ng-container *display="{ permissions: ['can_update_archivers'] }">
    <button
      *ngIf="!isEditing"
      icon="icon_modify"
      [custom-icon]="true"
      [isSvg]="true"
      ui-button
      color="linkFlat"
      (click)="toggleEditMode()"
    >
      {{ "securityModel.archiving.popup." + (userSelected ? "editButton" : "configureButton") | t }}
    </button>
  </ng-container>
  <div class="container">
    <p class="field">{{ "securityModel.archiving.text.account" | t }}</p>
    <div
      *ngIf="isEditing"
      class="autocomplete-container"
    >
      <autocomplete
        [oneItemAtATime]="true"
        [canMultiSelect]="false"
        [placeholder]="'securityModel.archiving.search.placeholder' | t"
        [autocompleteType]="usersAutocomplete"
        [searchOnlyUserResources]="false"
        (onItemSelected)="addUser($event)"
        [searchFunction]="usersAutocompleteSearchFunction"
        name="userAutocomplete"
        [required]="true"
        [locale]="i18n.currentLocale"
        [preserveSearchQueryWithOneItemAtTime]="false"
      ></autocomplete>
    </div>
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium"></ui-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <ui-user-tag
        *ngIf="userSelected"
        [user]="userSelected"
        [readOnly]="!isEditing"
        (onDeleteUser)="removeUser()"
      ></ui-user-tag>
    </ng-container>
    <p *ngIf="!userSelected && !isEditing">-</p>
    <div
      *ngIf="isEditing"
      class="button-container"
    >
      <button
        ui-button
        color="standard"
        [disabled]="saveDisabled"
        (click)="save()"
      >
        {{ "common.action.save" | t }}
      </button>
      <button
        ui-button
        color="secondary"
        (click)="cancel()"
      >
        {{ "common.action.cancel" | t }}
      </button>
    </div>
  </div>
</ui-card>
