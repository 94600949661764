import { Injectable, Injector } from "@angular/core";
import { Eco } from "projects/@common/definitions/eco";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { HandleBarUtils } from "projects/@common/utils/handlebars-utils";

export enum ReportTypesEnum {
  incident = 'incident',
  playbookCatalog = 'playbookCatalog',
  usecaseCatalog = 'usecaseCatalog'
}

enum ElementTypeEnum {
  script = 'script',
  link = 'link'
}

interface IElements {
  type: ElementTypeEnum;
  src: string;
  reportType?: ReportTypesEnum;
}

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private elementsToAdd: IElements[] = [
    { type: ElementTypeEnum.script, src: '/assets/javascript/paged.polyfill.js' },
    { type: ElementTypeEnum.link, src: '/assets/css/interface.css' },
    { type: ElementTypeEnum.link, src: '/assets/css/pdf-incident.css', reportType: ReportTypesEnum.incident },
    { type: ElementTypeEnum.link, src: '/assets/css/pdf-playbookCatalog.css', reportType: ReportTypesEnum.playbookCatalog },
    { type: ElementTypeEnum.link, src: '/assets/css/pdf-usecaseCatalog.css', reportType: ReportTypesEnum.usecaseCatalog },
  ];

  constructor(
    injector: Injector,
    public i18nService: I18nService
  ) {
    HandleBarUtils.registerHelpers(injector);
  }

  public generateReport(reportType: ReportTypesEnum, data: any, reportWindow: Window, locale: Eco.Languages): void {
    HandleBarUtils.locale = locale;
    const content = this.loadFile(`assets/reports/templating/${reportType}.html`);
    const html = HandleBarUtils.mustacheReplace(content, data);

    reportWindow.document.write("<!DOCTYPE html>");
    reportWindow.document.write("<html><head><title></title></head><body></body></html>");
    reportWindow.document.body.innerHTML = html;
    reportWindow.document.title = data.name;

    this.elementsToAdd.forEach((x) => {
      if (x.reportType && x.reportType !== reportType) {
        return;
      }

      let element: HTMLElement;
      switch (x.type) {
        case ElementTypeEnum.script:
          element = this.generateScriptElement(x.src);
          break;
        case ElementTypeEnum.link:
          element = this.generateCssElement(x.src);
          break;
      }
      reportWindow.document.head.appendChild(element);
    });

    this.injectPrintButton(reportWindow);

    reportWindow.document.close();
  }

  private injectPrintButton(reportWindow: Window): void {
    reportWindow.onbeforeprint = (event) => {
      const printButton = reportWindow.document.getElementsByTagName('button')[0];
      printButton.style.display = 'none';
    }
    reportWindow.onafterprint = (event) => {
      const printButton = reportWindow.document.getElementsByTagName('button')[0];
      printButton.style.display = '';
    }
    reportWindow.onload = (event) => {
      const printButton = reportWindow.document.createElement('button');
      printButton.innerText = this.i18nService.translate('common.print');
      printButton.setAttribute('id', 'print-button');
      printButton.onclick = () => {
        printButton.style.display = 'none';
        reportWindow.print(); // blocks the thread
        printButton.style.display = '';
      };
      printButton.style.position = 'fixed';
      printButton.style.top = '30px';
      printButton.style.left = '30px';
      printButton.style.height = '2.375rem';
      printButton.style.minWidth = '4rem';
      printButton.style.width = '100px';
      printButton.style.backgroundColor = "#62cac8";
      printButton.style.color = "#313942";
      printButton.style.fontWeight = "600";
      printButton.style.border = "none";
      printButton.style.borderRadius = "4px";
      printButton.style.fontSize = ".875rem";
      printButton.style.zIndex = "9999";
      printButton.style.boxSizing = "border-box";
      printButton.style.lineHeight = printButton.style.height;
      printButton.style.cursor = "pointer";
      printButton.style.padding = "0 1rem";
      printButton.style.textAlign = "center";
      printButton.style.userSelect = "none";
      printButton.style.verticalAlign = "middle";
      printButton.style.outline = "0";
      printButton.style.textDecoration = "none";
      printButton.style.boxShadow = "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)";
      reportWindow.document.body.prepend(printButton);
    };
  }

  private loadFile(filePath: string): string {
    let result: any;
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open("GET", filePath, false);
    xmlhttp.send();

    if (xmlhttp.status === 200) {
      result = xmlhttp.responseText;
    }

    return result;
  }

  private generateScriptElement(src: string): HTMLElement {
    const element = document.createElement('script');
    element.setAttribute('src', src);
    element.setAttribute('type', 'text/javascript');

    return element;
  }

  private generateCssElement(src: string): HTMLElement {
    const element = document.createElement('link');
    element.setAttribute('href', src);
    element.setAttribute('rel', 'stylesheet');
    element.setAttribute('type', 'text/css');

    return element;
  }
}
