import { Component, Input } from '@angular/core';
import { IEntityForDisplay } from 'projects/@common/services/api/respond/entities/entities.definition';

@Component({
  selector: 'respond-entity',
  templateUrl: './respond-entity.component.html',
  styleUrls: [ './respond-entity.component.scss' ],
})
export class RespondEntityComponent {
  @Input() entity: IEntityForDisplay;

  public isEditing = false;

  constructor() { }
}
