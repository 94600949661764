import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GetOrganizationProfileResponse } from '@common/services/api/sg/md.eco-profile/md.eco-profile.definition';
import { UserProfile } from '../user-info-dashboard/user-info-dashboard.component';
import { IAdminCardInformations } from './admin-card/admin-card.component';
import _ from 'lodash';

@Component({
  selector: 'sg-admin-cards',
  templateUrl: './admin-cards.component.html',
  styleUrls: ['./admin-cards.component.scss'],
})
export class AdminCardsComponent implements OnChanges {
  @Input() userProfile: UserProfile & { commitmentLevel: number };
  @Input() isFromConsoleOrg = true;
  @Input() totals: any;

  public adminCards: IAdminCardInformations[];

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!_.isEqual(simpleChanges.userProfile?.previousValue, simpleChanges.userProfile?.currentValue)) {
      this.setupCards();
    }
  }

  private setupCards(): void {
    const actionsTotal =
      this.userProfile.userActions.total - (this.isFromConsoleOrg ? 0 : this.userProfile.userActions.revokeUsers);
    this.adminCards = [
      {
        number: actionsTotal,
        text: 'dashboard.admin.card.text.proactiveActions' + (this.totals ? '.outOf' : ''),
        total: this.totals?.userActions?.total
          ? {
              total: this.totals.userActions.total,
              percentage: Math.floor((actionsTotal / this.totals.userActions.total) * 100),
            }
          : null,
        image: 'icon_Thumbs_up_green.svg',
      },
      {
        number: this.isFromConsoleOrg
          ? this.userProfile.badges.organizationBadgesCount
          : this.userProfile.badges.userBadges.length,
        total: this.totals?.badges.userBadges.length
          ? {
              total: this.totals.badges.userBadges.length,
              percentage: Math.floor(
                (this.userProfile.badges.userBadges.length / this.totals.badges.userBadges.length) * 100
              ),
            }
          : null,
        text: 'dashboard.admin.card.text.badges' + (this.totals ? '.outOf' : ''),
        image: this.isFromConsoleOrg ? 'icon_pause_ok_no_fill.svg' : 'icon_medal.svg',
      },
    ];
    if (this.isFromConsoleOrg) {
      this.adminCards.push({
        number: this.userProfile.commitmentLevel,
        text: 'dashboard.admin.card.text.commitmentLevel',
        image: 'icon_medal.svg',
        tooltipText: 'dashboard.admin.card.text.commitmentLevel.tooltip',
      });
    }
  }
}
