export interface IResourceApprovers {
  groupId?: string;
  userId?: string;
}

/* Is used to determine what we can pick as user property
 * Is hardcoded but at least bases its self on the sdk
 * Since we cannot get properties of interface in typescript, interfaces are only for compilation
 * Access it using Object.keys(userObjectAllProperties)
 *
 * Some properties are not collections, therefore we can't do filter in our
 * queries. This is bad, so we don'y allow them
 */
export const UserObjectAllProperties = {
  accountEnabled: null,
  ageGroup: null,
  city: null,
  companyName: null,
  country: null,
  department: null,
  jobTitle: null,
  officeLocation: null,
  preferredLanguage: null,
  state: null,
  usageLocation: null,
  userType: null,
};
