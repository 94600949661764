<div class="graph-container">
  <widget-container
    class="graph-content"
    [title]="'dashboard.admin.sharedFiles.severity.distribution.title' | t"
    [withIcon]="true"
    iconName="icon_resources_widget"
    [withBottomMargin]="true"
    [locale]="i18nService.currentLocale"
    [withTitleTooltip]="true"
  >
    <div tooltip-content>
      <p>{{ 'dashboard.admin.sharedFiles.severity.distribution.tooltip' | t }} </p>
    </div>
    <ui-spinner
      *ngIf="isLoading"
      size="medium"
      modifier="secondary"
    ></ui-spinner>
    <div
      [class.hidden]="!data?.data?.length"
      id="severityGraph"
      style="width: 100%; height: max-content; min-height: 375px;"
    ></div>
    <div
      *ngIf="!data?.data?.length"
      class="admin-tendency-widget--no-history"
    >
      {{ 'dashboard.admin.noData' | t }}
    </div>
  </widget-container>
</div>