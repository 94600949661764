import { Component, Input, ViewChild } from '@angular/core';
import { AbstractDrawer } from '@common/modules/layout/components/drawer/AbstractDrawer';
import { Store } from '@ngxs/store';
import { UiTabs } from '@ui-kit/components/ui-tabs/ui-tabs.component';

@Component({
  selector: 'usecase-drawer',
  templateUrl: './usecase-catalog-drawer.container.html',
})
export class UsecaseCatalogDrawerContainer extends AbstractDrawer {
  @Input() public data: any;

  @ViewChild('tabs', { static: false }) tabs: UiTabs;

  constructor(private readonly store: Store) {
    super(store);
  }
}
