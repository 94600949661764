import { Component, Inject, Input, OnInit } from '@angular/core';
import { ConnectorsOrgService } from '@common/services/api/detect/connectorsOrg/connectorsOrg.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { ConnectorCalendarInterval, ConnectorInstanceVolumeStats } from 'projects/@common/services/api/detect/models/connectorInstance.model';
import { ConnectorMappingHelper } from 'projects/@common/services/api/detect/models/connectorMappingHelper';
import { IAutomationOrganization } from 'projects/@common/services/api/respond/automation-organization/automation-organization.definitions';
import { DataConnectorTypes } from '@md.eco/connectors';
import { DashboardFileUnitHelper } from '../../helpers/dashboardFileUnitHelper';
import { GenericDashboardTimeRangeEnum, getTimestampsFromDynamicRange } from '../../helpers/getTimestampsFromRange';
import { GraphConnectorDailyData } from '../graphs/graph-connnector-daily-ingested-data/graph-connnector-daily-ingested-data.component';

@Component({
  selector: 'connectors-daily-ingested-data',
  templateUrl: './connectors-daily-ingested-data.component.html',
  styleUrls: [ './connectors-daily-ingested-data.component.scss' ],
})
export class ConnectorsDailyIngestedDataComponent implements OnInit {
  public readonly TIME_RANGE_ENUM = GenericDashboardTimeRangeEnum;

  @Input()
    activeOrganization: IAutomationOrganization;
  public currentTimeRange: GenericDashboardTimeRangeEnum = GenericDashboardTimeRangeEnum.LAST_7_DAYS;
  public chartData: GraphConnectorDailyData[] = [];
  public averageCapacityValue: number = 0;
  public averageCapacityType: string = 'GB';
  public isLoading: boolean = false;
  public routerLink: string;
  public routingPermissions: string[];

  constructor(
    public readonly i18n: I18nService,
    private readonly connectorMappingHelper: ConnectorMappingHelper,
    private readonly dashboardFileUnitHelper: DashboardFileUnitHelper,
    private readonly connectorsOrgService: ConnectorsOrgService,
    private readonly notice: NoticeService,
    @Inject('CONSOLE_TYPE') private readonly consoleType: ConsoleType
  ) {}

  ngOnInit(): void {
    this.routerLink = this.consoleType === ConsoleType.ADM ? '/detect/connectors' : `/detection/connectors`;
    this.routingPermissions = this.consoleType === ConsoleType.ADM ? [ 'can_list_detect_connectors' ] : [ 'can_list_detect_connector_instances' ];
    this.fetchChartData();
    this.fetchVolumeStats();
  }

  public handleTimeRangeChange(event: GenericDashboardTimeRangeEnum): void {
    this.currentTimeRange = event;
    this.fetchChartData();
    this.fetchVolumeStats();
  }

  private fetchChartData(): void {
    this.isLoading = true;
    const { fromDate, toDate } = getTimestampsFromDynamicRange(this.currentTimeRange.toString(), { timeIsUtc: true });
    this.connectorsOrgService.getConnectorVolume(this.activeOrganization.id, {
      from: fromDate,
      to: toDate,
      period: ConnectorCalendarInterval.DAY,
    })
      .then((result) => {
        if (result['connectors']?.length > 0) {
          const chartData: GraphConnectorDailyData[] = [];
          result['connectors'].forEach((element, index) => {
            const val = { values: {} };
            for (const key of Object.keys(element)) {
              if (key === 'timestamp') {
                val[key] = result['connectors'][index][key];
              } else {
                const connectorType = this.connectorMappingHelper.getDatasourceKeyTypeFromConnector(key);
                const connectorName = Object.keys(DataConnectorTypes).find((x) => DataConnectorTypes[x] === connectorType);
                const translatedName = this.i18n.translate(`detection.datasource.inventory.detail.table.datasource.type.${connectorName || 'OTHERS'}.filter`);
                val['values'][translatedName] = result['connectors'][index][key];
              }
            }
            chartData.push(val as GraphConnectorDailyData);
          });
          this.chartData = chartData;
        }
      })
      .catch(() => this.notice.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)))
      .finally(() => this.isLoading = false);
  }


  private fetchVolumeStats(): void {
    const { fromDate, toDate } = getTimestampsFromDynamicRange(this.currentTimeRange.toString(), { timeIsUtc: true });
    this.connectorsOrgService.getConnectorVolumeStats(this.activeOrganization.id, {
      from: fromDate,
      to: toDate,
      period: ConnectorCalendarInterval.DAY,
    })
      .then((result: ConnectorInstanceVolumeStats) => {
        const data = this.dashboardFileUnitHelper.getFileUnitValues(result.average);
        this.averageCapacityValue = data.result;
        this.averageCapacityType = this.i18n.translate(data.typeToTranslate);
      })
      .catch(() => this.notice.notifyUser(new Notice(this.i18n.translate('common.error.retry'), NoticeLevels.ERROR)));
  }
}
