import { HttpClient } from "@angular/common/http";
import { environment } from "projects/@common/environments/environment";
import { DisplayRequirements } from '../modules/display/display.service';
import { EcoUrlService } from "../services/api/core/url.service";
import { Stages } from "./stages";

export enum ApiEndpointPrefixEnum {
  detect_connectors = '/detect/connectors',
  automation = '/respond/automation',
  alerting = '/respond/alerting',
  iam = '/iam',
  sg = '/sg/dlp',
  notification = '/notifications',
  requests = '/requests',
  tools = '/tools',
  releases = '/releases',

  // AI RESOLUTION
  backtest = '/respond/alerting'
}

export abstract class EcoApi {
  protected abstract prefix: ApiEndpointPrefixEnum;

  protected constructor(protected readonly http: HttpClient, private url: EcoUrlService) {
  }

  public get host(): string {
    return `${this.url.baseUrl}${this.prefix}`;
  }

  public getFlagParams(requirements: DisplayRequirements): { headers: { [name: string]: string | string[]; }; } {
    return {
      headers: requirements,
    };
  }
}
