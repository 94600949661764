import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RespondIncidentListComponent } from "projects/@respond/incident/components/respond-incident-list/respond-incident-list.component";

import { AppRegistrationUpdateRedirectContainer } from "./components/app-registration-update/containers/app-registration-update-redirect/app-registration-update-redirect.container";
import { AppRegistrationUpdateContainer } from "./components/app-registration-update/containers/app-registration-update/app-registration-update.container";

const routes: Routes = [
  {
    path: 'app-registration-update',
    component: AppRegistrationUpdateContainer,
  },
  {
    path: 'app-registration-update/redirect',
    component: AppRegistrationUpdateRedirectContainer,
  },
  {
    path: 'app-registration-update/test',
    component: RespondIncidentListComponent,
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class IAMRoutingModule { }
