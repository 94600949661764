import { Component, Input } from "@angular/core";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { IUsecaseDescriptionResponse } from "projects/@common/services/api/respond/usecase/usecase.definition";
import { ActifsService } from "projects/@respond/actifs/actifs.service";
import { WhitelistsService } from "projects/@respond/whitelists/whitelists.service";

@Component({
  selector: 'app-details-tab',
  templateUrl: './details-tab.component.html',
  styleUrls: [ './details-tab.component.scss' ],
})
export class DetailsTabComponent {
  @Input()
    incident: Incident;

  @Input()
    usecases: IUsecaseDescriptionResponse[];

  @Input()
    organizationId: string;

  @Input()
    isEditable: boolean;

  @Input()
    isAdminConsole: boolean;

  constructor(
    public readonly actifsService: ActifsService,
    public readonly whitelistsService: WhitelistsService
  ) { }
}
