<widget-container
  [title]="'respond.dashboard.incident.var.chart.ongoing.title' | t"
  [locale]="i18n.currentLocale"
  [withBottomMargin]="true">
  <div class="content-container" [style.display]="isLoading ? 'none': 'flex'">
    <!-- Graph -->
    <div class="chart-container">
      <div [attr.id]="'INCIDENTS_ONGOING_VAR_GRAPH_ID'" [hidden]="chartData.length === 0" [style.height]="calculateChartHeight()"></div>
      <div *ngIf="chartData.length === 0" class="graph-without-data-container">
        {{ 'dashboard.admin.noData' | t }}
      </div>
  
      <div class="graph--footer" *ngIf="chartData.length > 0">
        <p class="chart-description">{{ 'respond.dashboard.incident.var.chart.ongoing.description' | t}}</p>
        <a routerLink="/respond/incidents" class="chart-link" *display="{ permissions: ['can_list_incidents'] }">
          {{ 'respond.dashboard.incident.var.chart.link' | t }}
        </a>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isLoading">
    <ui-spinner size="medium" modifier="secondary"></ui-spinner>
  </ng-container>
</widget-container>