<table>
  <tr>
    <td class="column-title">{{ 'settings.labels.card.labels.table.impact' | t }}</td>
    <td class="column-title">{{ 'settings.labels.card.labels.details' | t }}</td>
  </tr>
  <ng-container *ngFor="let severityLevel of severityLevels">
    <tr>
      <td>{{ 'settings.labels.card.labels.picker.impact.' + severityLevel | t }}</td>
      <td>{{ 'settings.labels.card.labels.picker.impact.' + severityLevel + '.description' | t }}</td>
    </tr>
  </ng-container>
</table>
