import { RequestDirection } from "projects/@common/definitions/consoles.enum";

import { HistoryType, IGetIncidentHistoriesRequest, ListHistoryOrderBy } from "../histories.definition";

export class GetIncidentHistoriesRequest implements IGetIncidentHistoriesRequest {
  incidentId?: string;
  organizationId: string;
  type: HistoryType;
  from?: number = 0;
  size?: number = 15;
  orderBy?: ListHistoryOrderBy = ListHistoryOrderBy.createdAt;
  direction?: RequestDirection = RequestDirection.desc;
  actions?: Array<string>;
  fields?: Array<string>;

  constructor(jsonObj: IGetIncidentHistoriesRequest) {
    Object.assign(this, jsonObj);
  }

  public getQueryParams(): Partial<IGetIncidentHistoriesRequest> {
    return {
      from: this.from,
      size: this.size,
      orderBy: this.orderBy,
      direction: this.direction,
      actions: this.actions,
    };
  }
}
