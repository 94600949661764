<ng-container *ngIf="!isLoading">
  <application-segmentation
    [currentSettings]="currentSettings"
    [isLoading]="isLoading"
  ></application-segmentation>

  <app-device-compliance-notification
    [currentSettings]="currentSettings"
    [isLoading]="isLoading"
    *display="{flags: [featureFlagEnum.DEVICE_COMPLIANCE_NOTIFICATION]}"
  ></app-device-compliance-notification>

</ng-container>

<ng-container *ngIf="isLoading">
  <ui-spinner size="medium"></ui-spinner>
</ng-container>