import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Eco } from 'projects/@common/definitions/eco';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { AutomationOrganizationsService } from 'projects/@common/services/api/respond/automation-organization/automation-organizations.api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationListService {
  public currentOrganization: string;
  public defaultOrganization: string;
  public organizationList: Record<string, string> = {};
  public getOrganizationEcoIdByName: Record<string, string> = {};

  @Select(EcoSessionState.organization) public organization$: Observable<Eco.IOrganization>;

  constructor(private automationOrganizationsService: AutomationOrganizationsService) {
    this.organization$.subscribe((value) => this.currentOrganization = value.id);
  }

  public get currentOrganizationName(): string {
    return this.organizationList[this.defaultOrganizationId];
  }

  public get defaultOrganizationId(): string {
    return this.getOrganizationId(this.defaultOrganization);
  }

  public async getAvailableOrganizations(): Promise<Record<string, string>> {
    const formattedOrganizations = {};

    const organizations = await this.automationOrganizationsService.getOrganizations();
    organizations.items.forEach((organization) => {
      if (organization.id === this.currentOrganization) {
        this.defaultOrganization = organization.name;
      }
      this.getOrganizationEcoIdByName[organization.name] = organization.ecoId;
      formattedOrganizations[organization.id] = organization.name;
    });

    this.organizationList = formattedOrganizations;

    const sortedOrg = this.sortOrganization(this.organizationList);

    if (this.defaultOrganization === undefined) {
      this.defaultOrganization = Object.values(sortedOrg)[0];
    }

    return sortedOrg;
  }

  private sortOrganization(organizations: Record<any, any>): Record<string, string> {
    const sortable = [];
    for (const org in organizations) {
      if (org) {
        sortable.push([ org, organizations[org] ]);
      }
    }

    sortable.sort((a, b) => {
      if (a[1] < b[1]) {
        return -1;
      }
      if (a[1] > b[1]) {
        return 1;
      }
      return 0;
    });

    const objSorted = {};
    sortable.forEach((item) => {
      objSorted[item[0]] = item[1];
    });

    return objSorted;
  }

  public getOrganizationId(organizationName: string): string {
    for (const organization in this.organizationList) {
      if (this.organizationList[organization] === organizationName) {
        return organization;
      }
    }
  }
}
