import { Component, Input } from '@angular/core';
import { OrganizationSettings, OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';

@Component({
  selector: 'downloads-settings',
  templateUrl: './downloads-settings.component.html',
  styleUrls: [ './downloads-settings.component.scss' ],
})
export class DownloadsSettingsComponent {
  @Input() public currentSettings: OrganizationSettings & OrganizationShareSettings;
  @Input() public isLoading = false;

  constructor() { }
}
