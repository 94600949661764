<div class="connector-cards-collapsable-container">
    <ui-tabs
        #tabs
        [color]="tabColor"
    >
        <ui-tab
            [tabTitle]="'detection.connector.page.user.connectors.collapsable.title' | t"
        >
            <div class="cards-container">
                <ng-container *ngFor="let connector of userConnectors">
                    <app-connector-card *ngIf="userConnectors.length > 0"
                        [connector]="connector"
                        [organization]="organization"
                        [connectorInstances]="getUserConnectorInstance(connector.id)">
                    </app-connector-card>
                </ng-container>
            </div>
            <ng-container *ngIf="userConnectors.length <= 0">
                <div class="without-user-connectors">
                    <img class="small-size"
                        [src]="'assets/favicons/icon_playbook_alert_update.svg'"
                        alt="infoIcon"/>
                    {{'detection.connector.page.all.connectors.collapsable.empty.list.text' | t}}
                </div>
            </ng-container>
        </ui-tab>
        <ui-tab
            [tabTitle]="'detection.connector.page.all.connectors.collapsable.title' | t"
            *ngIf="false"
        >
            <div class="cards-container">
                <ng-container *ngFor="let connector of connectors">
                    <app-connector-card *ngIf="connectors.length > 0" 
                        [connector]="connector"
                        [organization]="organization"
                        [connectorInstances]="getUserConnectorInstance(connector.id)"
                        [displayBadgeOwned]="doesUserHaveThisConnector(connector.id)"
                        [displayBadgeAdd]="!doesUserHaveThisConnector(connector.id)">
                    </app-connector-card>
                </ng-container>
            </div>
            <ng-container *ngIf="connectors.length <= 0">
                <div class="without-user-connectors">
                    <img class="small-size"
                        [src]="'assets/favicons/icon_playbook_alert_update.svg'"
                        alt="infoIcon"/>
                    {{'detection.connector.page.all.connectors.collapsable.empty.list.text' | t}}
                </div>
            </ng-container>
        </ui-tab>
    </ui-tabs>
</div>
