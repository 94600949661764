<div
  page
  [appPagePaths]="['org', 'resourcesShares']"
  [useMobileHeader]="true"
  [returnIcon]="false"
  [contextualHelp]="documentationSection"
  scrollToBottom
  (onScrollToBottom)="onScroll()"
  [disableOrganizationSelector]="true"
>
    <ng-container description>
        {{'shares.libraries.pageDescription.user' | t}}
    </ng-container>
    <resources-shares-table #table [isFromConsoleOrg]="true"></resources-shares-table>
</div>
