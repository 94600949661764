import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Eco } from 'projects/@common/definitions/eco';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { Finding } from 'projects/@common/services/api/respond/finding/model/finding';
import { IncidentsApi } from 'projects/@common/services/api/respond/incidents/incidents.api';
import { IDescribeIncidentResponse, IUpdateIncidentStatusRequest, ResolveIncidentAction } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { IncidentTaskStatus } from 'projects/@common/services/api/respond/incidentTasks/incidentTasks.definitions';
import { IObservable } from 'projects/@common/services/api/respond/observables/observables.definition';
import { getHttpErrorMessage, pruneObject } from 'projects/@common/utils/utils';
import { DatePipe } from '@angular/common';
import { SeverityEnum } from '@ui-kit/components/ui-severity-chip/ui-severity-chip.component';
import { ModalService } from '@ui-kit/services/modal.service';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';

enum AsyncStateEnum { "LOADING", "SAVING", "READY" };

enum JustificationEnum {
  OBSERVABLE = 'observable',
  FINDING = 'finding',
  DETECTION = 'detection',
  USECASE = 'usecase'
}

interface ISeverityOption {
  value: number,
  displayValue: string,
}

type Severity = {
  currentValue: number;
  selectableOptions: ISeverityOption[];
}

interface IStatusOption {
  value?: ResolveIncidentAction;
  displayValue: string;
  description: string;
}

interface IObservableOption extends IStatusOption {
  value: any;
}

interface IFindingOption extends IStatusOption {
  value: any;
}

type ResolveStatus = {
  currentValue: ResolveIncidentAction;
  selectableOptions: IStatusOption[];
}

type CollaborationTime = {
  milliseconds: number;
  minutes: number;
}

@Component({
  selector: 'app-incident-close-modal',
  templateUrl: './incident-close-modal.component.html',
  styleUrls: ['./incident-close-modal.component.scss']
})
export class IncidentCloseModalComponent implements OnInit {
  @Output() public successEvent = new EventEmitter<IDescribeIncidentResponse>();

  public readonly incident: Incident;
  public readonly asyncStateEnum = AsyncStateEnum;
  public readonly locale: Eco.Languages;
  public readonly dateNow: Date;
  public readonly minDate: Date;
  public asyncState: AsyncStateEnum = AsyncStateEnum.READY;
  public form: FormGroup;
  public status: ResolveStatus;
  public severity: Severity;
  public collaborationTime: CollaborationTime;
  public manualClosedAt: number;
  public useManualClosedAtDate: boolean;
  public useCollaborationTime: boolean;
  public displayJustification: boolean = false;
  public justificationOptions = [];
  public observables: IObservableOption[] = [];
  public findings: IFindingOption[] = [];
  public keysToFormatAsDate = [
    '@timestamp',
    'event.created',
    'event.ingested',
    'event.start',
    'event.end',
  ];
  public justifications: FormArray;
  public autocompleteTypes = AutocompleteTypes;
  private summaryInitialValue: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: { incident: Incident, observables: IObservable[], findings: Finding[] },
    public readonly i18n: I18nService,
    private readonly modalService: ModalService,
    private readonly formBuilder: FormBuilder,
    private readonly incidentsApi: IncidentsApi,
    private readonly noticeService: NoticeService,
    private readonly displayService: DisplayService,
    private readonly datepipe: DatePipe,
  ) {
    this.incident = this.data.incident;
    this.locale = this.i18n.currentLocale as Eco.Languages;
    this.dateNow = new Date();
    this.minDate = new Date(this.incident.createdAt);
    this.summaryInitialValue = this.incident.summary;
  }

  ngOnInit(): void {
    this.initStatus();
    this.initSeverity();
    this.initCollaborationTime();
    this.initForm();
    this.initManualClosingDate();
    this.initJustificationOptions();
    this.initObservables();
    this.initFindings();
  }

  public get hasSocManagerPermission(): boolean {
    return this.displayService.meetsRequirements({ permissions: ["can_reopen_incident"] });
  }

  public get isBreachOfConfidentiality(): boolean {
    return this.incident.breachOfConfidentiality;
  }

  public get hasIncompleteTasks(): boolean {
    const validStatuses = [IncidentTaskStatus.CLOSED, IncidentTaskStatus.NOT_APPLICABLE];
    const tasks: any[] = this.incident.tasks;
    return tasks.some((incidentTask) => !validStatuses.includes(incidentTask.task.status));
  }

  public get comment(): string {
    return this.form.get("comment").value?.trim() || "";
  }

  public get summary(): string {
    return this.form.get("summary").value?.trim() || this.incident.summary;
  }

  public get isValidStatus(): boolean {
    return Boolean(this.status?.currentValue && this.status.selectableOptions.find(status => status.value === this.status.currentValue))
  }

  public get isValidJustifications(): boolean {
    return Boolean(!this.justifications.controls?.some((justification) => justification.invalid));
  }

  public get isValidSeverity(): boolean {
    return Boolean(this.severity?.currentValue && this.severity.selectableOptions.find(severity => severity.value === this.severity.currentValue));
  }

  public get isValidCollaborationTime(): boolean {
    return !this.useCollaborationTime || this.collaborationTime.minutes !== null;
  }

  public get formErrors(): string[] {
    const currentErrorList = [];
    if (!this.isValidStatus) {
      currentErrorList.push("Statut invalide");
    }
    if (!this.isValidSeverity) {
      currentErrorList.push("Sévérité invalide");
    }
    if (!this.isValidCollaborationTime) {
      currentErrorList.push("Temps de collaboration invalide");
    }
    if (!this.isValidJustifications) {
      currentErrorList.push("Justifications invalides ou incomplètes");
    }

    return currentErrorList;
  }

  public get shouldPreventClosingIncident(): boolean {
    return this.isBreachOfConfidentiality && (!this.hasSocManagerPermission || this.hasIncompleteTasks);
  }

  public get isSaveActionAvailable(): boolean {
    if (this.useManualClosedAtDate && !(this.manualClosedAt && this.comment)) {
      return false;
    }

    const validState = !this.formErrors?.length && this.asyncState === this.asyncStateEnum.READY;
    return validState && !this.shouldPreventClosingIncident;
  }

  public get isCancelActionAvailable(): boolean {
    return this.asyncState !== this.asyncStateEnum.SAVING;
  }

  public get lastClosedAtTimestamp(): number {
    return this.incident.lastManualClosedAt || this.incident.lastClosedAt || null;
  }

  public get closeIncidentJustificationLabel(): string {
    return `incidents.container.page.details.modal.closeIncident.${this.form.get('incidentStatus').value}.label`
  }

  public handleConfirm(): void {
    this.closeIncident();
  }

  public handleCancel(): void {
    this.modalService.closeDialog();
    this.incident.summary = this.summaryInitialValue;
  }

  public setStatusValue(event: ResolveIncidentAction) {
    this.status.currentValue = event;
    this.displayJustification = false;

    for (let i = 0; i < this.justifications.length; i++) {
      this.removeJustification(i);
    }

    this.justifications.clear();
    this.justifications.updateValueAndValidity();

    if(this.status.currentValue === ResolveIncidentAction.CLOSE_FALSE_POSITIVE){
      this.displayJustification = true;
      this.addJustification();
    }

    if(this.status.currentValue === ResolveIncidentAction.CLOSE_EXPECTED){
      this.displayJustification = true;
    }
  }

  public setSeverityValue(event: number) {
    const selectedSeverity = this.severity.selectableOptions.find(severity => severity.value === event);
    this.severity.currentValue = selectedSeverity.value;
  }

  public setCollaborationTime(event: number) {
    this.collaborationTime = {
      milliseconds: event,
      minutes: this.convertMillisecondsToMinutes(event)
    };
  }

  public convertMinutesToMilliseconds(minutes: number): number {
    const minuteDurationInMilliseconds = 60 * 1000;
    return minutes ? (minutes * minuteDurationInMilliseconds) : 0;
  }

  public convertMillisecondsToMinutes(milliseconds: number): number {
    const minuteDurationInMilliseconds = 60 * 1000;
    return milliseconds ? (milliseconds / minuteDurationInMilliseconds) : 0;
  }

  public copyIncidentURL(): void {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    this.noticeService.notifyUser(new Notice("incidents.container.page.details.modal.closeIncident.copyIncidentURL.success", NoticeLevels.SNACK));
  }

  public toggleManualCloseDate(): void {
    this.useManualClosedAtDate = !this.useManualClosedAtDate;
    this.manualClosedAt = null;
    this.form.get('comment').setValidators(this.useManualClosedAtDate ? Validators.required : null);
    this.form.get('comment').updateValueAndValidity();
  }

  public toggleCollaborationTime(): void {
    this.useCollaborationTime = !this.useCollaborationTime;

    if(this.useCollaborationTime){
      this.collaborationTime.minutes > 0 ? this.collaborationTime.minutes : 1;
    }else{
      this.collaborationTime.minutes = 0;
    }
  }

  public handleCloseDateChange(event: number): void {
    this.manualClosedAt = event || null;
  }

  public addJustification(): void{
    this.justifications.push(this.formBuilder.group({
      type: this.formBuilder.control(null, Validators.required),
      value: this.formBuilder.control(null),
    }))
  }

  public removeJustification(index: number): void{    
    const justificationToRemove = this.justifications.at(index).get('type').value;
    const justificationDetailToRemove = this.justifications.at(index).get('value').value;

    if(justificationDetailToRemove){
      this[`${justificationToRemove}s`].push(justificationDetailToRemove);
    };

    this.justifications.removeAt(index);
  }

  public setJustification(value: string, index: number){;
    const justificationDetail = this.justifications.at(index).get('value');

    if(justificationDetail.value){
      this[value === JustificationEnum.OBSERVABLE ? 'findings' : 'observables'].push(justificationDetail.value);
      justificationDetail.reset();
    }

    switch (value) {
      case JustificationEnum.OBSERVABLE:
      case JustificationEnum.FINDING:
        justificationDetail.setValidators(Validators.required);
        break;
      default:
        justificationDetail.clearValidators();
    }

    justificationDetail.updateValueAndValidity();
  }

  public setJustificationDetailList(value: string): any{
    if(value === JustificationEnum.OBSERVABLE){
      return this.observables;
    }

    if(value === JustificationEnum.FINDING){
      return this.findings;
    }

    return null;
  }

  public setJustificationDetail(event: any, index: number): void {
    this.justifications.at(index).get('value').setValue(event);
    this.observables = this.observables.filter((observable) => observable.value.id !== event.value.id);
    this.findings = this.findings.filter((finding) => finding.value.id !== event.value.id);
  }

  public resetJustificationDetail(event: any, index: number): void{
    const justificationToRemove = this.justifications.at(index).get('type').value;
    this[`${justificationToRemove}s`].push(event);
    this.justifications.at(index).get('value').reset();
  }

  private initStatus(): void {
    let availableOptions: IStatusOption[];
    if (this.isBreachOfConfidentiality) {
      availableOptions = [{
        value: ResolveIncidentAction.CLOSE,
        displayValue: this.i18n.translate(`incidents.container.page.details.modal.closeIncident.status.${ResolveIncidentAction.CLOSE}`),
        description: this.i18n.translate(`incidents.container.page.details.modal.closeIncident.status.${ResolveIncidentAction.CLOSE}.description`)
      }];
    } else {
      availableOptions = this.incident.resolveIncidentActions
        .filter(value => value !== ResolveIncidentAction.REOPEN)
        .map(value => ({
          value,
          displayValue: this.i18n.translate(`incidents.container.page.details.modal.closeIncident.status.${value}`),
          description: this.i18n.translate(`incidents.container.page.details.modal.closeIncident.status.${value}.description`),
        }))
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
    }

    const defaultSelected = (!this.shouldPreventClosingIncident && availableOptions.length === 1)
      ? availableOptions[0].value
      : null;

    this.status = {
      currentValue: defaultSelected,
      selectableOptions: availableOptions,
    }
  }

  private initObservables(): void {
    this.observables = this.data.observables.map((value) => ({displayValue: value.type, description: JSON.stringify(value.value), value}))
  }

  private initFindings(): void {
    let findingsObject = {};
    for (const finding of this.data.findings as Array<Finding>) {
      findingsObject = {...findingsObject, ...this.flattenObject(JSON.parse(finding.content))}
    }

    for (const key in findingsObject) {
      const index = this.findings.findIndex((value) => value.displayValue === key)
      if(!index || this.findings[index]?.description !== findingsObject[key]){
        this.findings.push({
          displayValue: key,
          description: findingsObject[key],
          value: key
        })
      }
    }
  }

  private flattenObject(obj: Object, prefix = '', result = {}): Object {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.flattenObject(obj[key], newKey, result);
        } else {
          let value = obj[key];
          if (this.keysToFormatAsDate.includes(newKey)) {
            value = this.formatWithDatePipe(value);
          }
          result[newKey] = value;
        }
      }
    }
    return result;
  }

  private formatWithDatePipe(value: string | number) {
    const date = new Date(value);

    const formatedDate = this.datepipe.transform(date, "longDate", null, this.i18n.currentLocale === 'fr' ? 'fr-ca' : 'en');
    const separator = this.i18n.currentLocale === 'fr' ? 'à' : 'at';
    const time = this.datepipe.transform(date, "HH:mm:ss.SSS");

    return `${formatedDate} ${separator} ${time}`;
  }

  private initSeverity(): void {
    const severities = [
      { value: 5, enumKey: SeverityEnum.OK, },
      { value: 4, enumKey: SeverityEnum.LOW, },
      { value: 3, enumKey: SeverityEnum.MODERATE, },
      { value: 2, enumKey: SeverityEnum.HIGH, },
      { value: 1, enumKey: SeverityEnum.DISASTER, },
    ];
    const availableOptions = severities.map(severity => ({
      value: severity.value,
      displayValue: this.i18n.translate(`common.severity.${severity.value}.label`),
    }))
    this.severity = {
      currentValue: this.incident.severity,
      selectableOptions: availableOptions,
    }
  }

  private initCollaborationTime(): void {
    const defaultMinutes = this.incident.collaborationTime;
    const defaultMilliseconds = typeof defaultMinutes === "number" ? this.convertMinutesToMilliseconds(defaultMinutes) : null;
    this.collaborationTime = {
      milliseconds: defaultMilliseconds,
      minutes: defaultMinutes
    };
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      incidentStatus: this.formBuilder.control(this.status.currentValue || "", Validators.required),
      summary: this.formBuilder.control(this.incident.summary, null),
      comment: this.formBuilder.control("", null),
      falsePositiveJustification: new FormArray([])
    });
    this.justifications = this.form.get('falsePositiveJustification') as FormArray;
  }

  private initManualClosingDate(): void {
    this.manualClosedAt = this.incident.lastManualClosedAt || null;
    this.useManualClosedAtDate = Boolean(this.manualClosedAt);
  }

  private initJustificationOptions(): void{
    Object.values(JustificationEnum).forEach((value) => this.justificationOptions.push({
      displayValue: this.i18n.translate(`incidents.container.page.details.modal.closeIncident.option.${value}`),
      value

      }
    ))
  }

  private closeIncident(): void {
    this.asyncState = AsyncStateEnum.SAVING;

    const request: IUpdateIncidentStatusRequest = pruneObject({
      id: this.incident.id,
      status: this.status.currentValue,
      severity: this.severity.currentValue,
      collaborationTime: this.collaborationTime.minutes,
      comment: this.comment || null,
      manualClosedAt: this.useManualClosedAtDate ? this.manualClosedAt : null,
      summary: this.summary || this.incident.summary,
      justifications: this.justifications.getRawValue().map(
        (justification) => ({
          type: justification.type,
          key: justification.value?.displayValue,
          value: justification.value?.description
        })
      )
    });
    this.incidentsApi.updateIncidentStatus(this.incident.organizationId, request).subscribe(
      (response: IDescribeIncidentResponse) => {
        this.successEvent.emit(response);
        this.noticeService.notifyUser(new Notice("incidents.container.page.details.modal.closeIncident.apiresult.SUCCESS", NoticeLevels.SUCCESS, { shortId: this.incident.shortId }));
        this.modalService.closeDialog();
      },
      (httpErrorResponse) => {
        const errorMessage = getHttpErrorMessage(httpErrorResponse);
        this.noticeService.notifyUser(new Notice("incidents.container.page.details.modal.closeIncident.apiresult.ERROR", NoticeLevels.ERROR, null, errorMessage));
        this.asyncState = AsyncStateEnum.READY;
      }
    );
  }
}
