export enum SoarConnectorTypes {
  MICROSOFT_365 = 'MICROSOFT_365',
  AWS = 'AWS'
}

export enum SoarConnectorTypesEnum {
  MICROSOFT_365_RESPONDER = 'MICROSOFT_365_RESPONDER',
  MICROSOFT_365_ANALYZER = 'MICROSOFT_365_ANALYZER',
  AWS_RESPONDER = 'AWS_RESPONDER',
  AWS_ANALYZER = 'AWS_ANALYZER',
}

