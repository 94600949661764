<div class="user-activity-dialog-container">
  <ui-title
    [bottomBorder]="false"
    [largeFont]="true"
    [name]="'user-activity-dialog.title' | t "
  ></ui-title>

  <p>
    {{ 'user-activity-dialog.text' | t }}
  </p>

  <span>
    {{ 'user-activity-dialog.time' | t: {time: ( timer | timeDurationUnits: { locale: i18n.currentLocale })} }}
  </span>
  <div class="activity-buttons-container">
    <button
      id="closeModalButton"
      ui-button
      color="standard"
      (click)="closeModal(true)"
    >
      {{ 'user-activity-dialog.continue' | t }}
    </button>

    <button
      ui-button
      (click)="logout()"
    >
    {{ 'user-activity-dialog.logout' | t }}
    </button>
  </div>
</div>