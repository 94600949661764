import { IAlertStats } from "../alerts.definitions";

export class AlertStats implements IAlertStats {
  public new: number;
  public activeAssigned: number;

  constructor(jsonObj: IAlertStats) {
    Object.assign(this, jsonObj);
  }
}
