<section class="task-top-section">
  <app-date-interval
    *ngIf="incidentTask?.resolutionPeriod"
    [type]="'BIG'"
    [setMissingDateEnd]="false"
    [withPrefix]="true"
    [period]="{dateFrom: incidentTask?.resolutionPeriod?.from, dateTo: incidentTask?.resolutionPeriod?.to}"
  ></app-date-interval>

  <app-edit-with-modal
    #editor
    [noMargin]="true"
    *ngIf="incidentTask?.task?.inProgressPeriods?.length > 0"
  >
    <app-duration-time
      class="history-prompt"
      [class.not-readonly]="!disabled"
      (click)="toggleDurationTimeHistoryPopup()"
      *ngIf="incidentTask.durationInSeconds >= 1"
      [secondes]="incidentTask.durationInSeconds"
    ></app-duration-time>
    <div
      content
      class="timestamp-container"
    >
      <p class="history-title">{{('incidents.component.tab.task.history.title' | t) | uppercase}}</p>
      <ng-container *ngFor="let period of periods">
        <p class="history-timestamp">{{ period }}</p>
      </ng-container>
    </div>
  </app-edit-with-modal>
</section>


<section class="task-description">
  <p>{{(incidentTask?.taskDescription | translatedObject) | parameters:parametersValues | async}}</p>
</section>


<section class="task-expandable-subsections">
  <!-- Task Instructions -->
  <ui-expendable-section
    *ngIf="!disabled"
    class="task-instructions main-expandable-section"
    #taskInstructions
    [readonly]="disabled"
    [type]="expendableTypeEnum.COLUMN"
    [borderBottomWhenOpen]="expandableSections[expandableSections.length - 1] === taskSectionsEnum.INSTRUCTIONS"
    [forceExpanded]="autoExpandTaskInstruction"
  >
    <header
      class="section-title"
      header
    >
      <div class="image-and-title">
        <div class="img-wrapper">
          <img
            class="icon-scale"
            src="assets/favicons/icon_suivi.svg"
          >
        </div>
        <span class="title">{{'incidents.component.tab.task.content.instruction' | t}}</span>
      </div>
    </header>
    <div class="section-content">
      <p>{{(incidentTask?.taskInstruction | translatedObject) | parameters:parametersValues | async}}</p>
    </div>
  </ui-expendable-section>

  <!-- Task HTML Comments + Findings (Evidences) -->

  <ui-expendable-section
    *ngIf="showComment"
    class="task-findings main-expandable-section"
    #TaskMessages
    [readonly]="disabled"
    [type]="expendableTypeEnum.COLUMN"
    [borderBottomWhenOpen]="expandableSections[expandableSections.length - 1] === taskSectionsEnum.FINDINGS"
    [forceExpanded]="autoExpandTaskNote || disabled"
  >
    <header
      class="section-title"
      header
    >
      <div class="image-and-title">
        <div class="img-wrapper">
          <img src="assets/favicons/icon_note_grey.svg">
        </div>
        <span class="title">{{'incidents.component.tab.task.content.note' | t}}</span>
      </div>
    </header>
    <div class="section-content">
      <div
        class="task-comment-editor-container"
        [class.isEditing]="isEditing"
        [class.disabled]="disabled"
        [tooltip]="!disabled && !isEditing ? ('common.action.edit'| t) : ''"
        (click)="openHtmlEditor()"
      >
        <span *ngIf="isInfoGroupEmpty && !isEditing">-</span>
        <html-editor
          #htmlEditor
          class="editor-element"
          controlName="comment"
          [htmlData]="htmlEditorValue"
          [initialValue]="initialValue"
          [editorEventHandlers]="editorEventHandlers"
          [group]="formGroup"
          [showUnsavedWarning]="shouldShowUnsavedWarning"
          [locale]="i18nService.currentLocale"
          [isEditing]="isEditing"
          [maxInput]="10000"
        ></html-editor>
        <img
          *ngIf="!isEditing && !disabled"
          alt="edit-icon"
          class="edit-icon"
          src="assets/favicons/icon_modify_disabled.svg"
        />
        <ui-confirmations-buttons
          *ngIf="isEditing"
          [marginTop]="false"
          (confirmationEvent)="handleConfirmation($event)"
        ></ui-confirmations-buttons>
      </div>

      <!-- Actions -->
      <ui-expendable-section
        class="task-findings-actions task-expandable-result"
        *ngFor="let taskAction of taskActions; let i = index"
        [type]="expendableTypeEnum.COLUMN"
        [forceExpanded]="currentRowsExpanded.includes(i) || (i == 0 && forceExpandFirstElement)"
        [transparentBackground]="true"
        (expandedEvent)="setCurrentRowExpanded(i)"
        (collapsedEvent)="removeCurrentRowExpanded(i)"
      >
        <header
          class="section-title"
          header
        >
          <div class="image-and-title">
            <div class="img-wrapper">
              <img
                [src]="atLeastOneRowFlagged(taskAction) ? 'assets/favicons/toolbox-incidents/icon_actions_red.svg' : 'assets/favicons/toolbox-incidents/icon_actions.svg'"
                class="icon-actions"
              >
            </div>
            <span class="title">{{taskAction.name}}</span>
          </div>
          <div class="date-timestamp">
            <span class="title">{{taskAction.updatedAt | date:"medium":"":i18nService.dateCurrentLocale }}</span>
            <delete-button
              *ngIf="isConsoleAdmin"
              (clickEvent)="removeActionFromTask(taskAction)"
              [stopPropagation]="true"
            ></delete-button>
          </div>
        </header>
        <div class="section-content">
          <ng-container *ngIf="taskAction.payload?.activities?.length && isConsoleAdmin; else rawHtmlTable">
            <p class="table-title">{{ taskAction.payload.actionDescription }}</p>
            <table>
              <thead>
                <tr>
                  <ng-container *ngIf="taskAction.payload?.activities">
                    <ng-container *ngFor="let key of taskAction.payload?.activities[0] | keyvalue">
                      <th *ngIf="key.key !== 'isFlagged'">
                        {{ key.key }}
                      </th>
                    </ng-container>
                    <ng-container>
                      <th *ngIf="!!taskAction.payload?.activities?.length">
                        <img
                          class="flag-img"
                          [src]="isAllRowsFlagged(taskAction) ? 'assets/favicons/observables/icon_flag_red.svg' : 'assets/favicons/observables/icon_flag_red_empty.svg'"
                          (click)="onAllRowsClick(taskAction, isAllRowsFlagged(taskAction))"
                        >
                      </th>
                    </ng-container>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let activity of taskAction.payload?.activities; let i = index">
                  <ng-container *ngFor="let key of activity | keyvalue">
                    <td *ngIf="key.key !== 'isFlagged'">
                      {{ key.value }}
                    </td>
                  </ng-container>
                  <td>
                    <img
                      class="flag-img"
                      [src]="activity.isFlagged ? 'assets/favicons/observables/icon_flag_red.svg' : 'assets/favicons/observables/icon_flag_red_empty.svg'"
                      (click)="onRowClick(activity, i, taskAction)"
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <ng-container *ngIf="atLeastOneRowFlagged(taskAction) || taskAction.justification">
              <p class="justification">{{ 'incidents.task.action.field.textArea' | t }} :</p>
              <div class="container-text-editor">
                <html-editor
                  class="editor"
                  [isEditing]="taskActionsJustificationEdit.includes(taskAction.id)"
                  [useNgModel]="true"
                  [(ngModel)]="taskAction.justification">
                </html-editor>
                <img
                  *ngIf="!taskActionsJustificationEdit.includes(taskAction.id)"
                  alt="edit-icon"
                  class="edit-icon"
                  src="assets/favicons/icon_modify_disabled.svg"
                  (click)="editActionToggle(taskAction.id)"
                />
              </div>
            </ng-container>
            <ui-confirmations-buttons
              *ngIf="taskActionsJustificationEdit.includes(taskAction.id)"
              [marginTop]="true"
              (confirmationEvent)="handleConfirmationJustification(taskAction, $event, i)"
            ></ui-confirmations-buttons>
          </ng-container>
          <ng-template #rawHtmlTable> <!-- Contenu affiché ici -->

            <div
              class="result-sanitized-html"
              [innerHTML]="taskAction.description"
            ></div>

            <!-- Entities -->
            <div class="incident-actions--form-result-entities-wrapper" *ngIf="taskAction.entities?.length">
              <respond-entity *ngFor="let entity of taskAction.entities;" [entity]="entity">
              </respond-entity>
            </div>

            <!-- Observables de l'action -->
            <div class="incident-actions--form-result-observables-wrapper"
              *ngIf="taskAction.observables?.length && taskAction.observablesConfig">
              <observable-container class="incident-actions--form-observables" [useGrouping]="false"
                [pageContext]="taskAction.observablesConfig">
              </observable-container>
            </div>

          </ng-template>
        </div>
      </ui-expendable-section>


      <!-- Observables -->
      <ui-expendable-section
        class="task-findings-observables task-expandable-result"
        *ngFor="let taskObservable of taskObservables;"
        [type]="expendableTypeEnum.COLUMN"
        [forceExpanded]="false"
        [transparentBackground]="true"
      >
        <header
          class="section-title"
          header
        >
          <div class="image-and-title">
            <div class="img-wrapper">
              <img
                src="assets/favicons/toolbox-incidents/icon_observables.svg"
                class="icon-observables"
              >
            </div>
            <span class="title">{{taskObservable.type + " - " + taskObservable.value}}</span>
          </div>
          <div class="date-timestamp">
            <span class="title">{{taskObservable.createdAt | date:"medium":"":i18nService.dateCurrentLocale }}</span>
            <delete-button
              *ngIf="isConsoleAdmin"
              (clickEvent)="removeObservableFromTask(taskObservable)"
              [stopPropagation]="true"
            ></delete-button>
          </div>
        </header>
        <div class="section-content">
          <json-sorted-view [jsonString]="taskObservable.__jsonViewString"></json-sorted-view>
        </div>
      </ui-expendable-section>

      <!-- Entities -->
      <ui-expendable-section
        class="task-findings-entities task-expandable-result"
        *ngFor="let taskEntity of taskEntities;"
        [type]="expendableTypeEnum.COLUMN"
        [forceExpanded]="false"
        [transparentBackground]="true"
      >
        <header
          class="section-title"
          header
        >
          <div class="image-and-title">
            <div class="img-wrapper">
              <img
                src="assets/favicons/toolbox-incidents/icon_entities.svg"
                class="icon-entities"
              >
            </div>
            <span class="title">{{(taskEntity.typeTranslationKey | t) + " - " + taskEntity.label}}</span>
          </div>
          <div class="date-timestamp">
            <span class="title">{{taskEntity.createdAt | date:"medium":"":i18nService.dateCurrentLocale }}</span>
            <delete-button
              *ngIf="isConsoleAdmin"
              (clickEvent)="removeEntityFromTask(taskEntity)"
              [stopPropagation]="true"
            ></delete-button>
          </div>
        </header>
        <div class="section-content">
          <respond-entity [entity]="taskEntity"></respond-entity>
        </div>
      </ui-expendable-section>

      <!-- Messages -->
      <ui-expendable-section
        class="task-findings-messages task-expandable-result"
        *ngFor="let taskMessage of taskMessages;"
        [type]="expendableTypeEnum.COLUMN"
        [forceExpanded]="false"
        [transparentBackground]="true"
      >
        <header
          class="section-title"
          header
        >
          <div class="image-and-title">
            <div class="img-wrapper">
              <img
                src="assets/favicons/icon_revision.svg"
                class="icon-messages"
              >
            </div>
            <span class="title">{{ taskMessage.messageSenderName }}</span>
          </div>
          <div class="date-timestamp">
            <span class="title">{{ taskMessage.messageSentAt | date:"medium":"":i18nService.dateCurrentLocale }}</span>
            <delete-button
              *ngIf="isConsoleAdmin"
              (clickEvent)="removeMessageFromTask(taskMessage)"
              [stopPropagation]="true"
            ></delete-button>
          </div>
        </header>
        <div class="section-content">
          <p [innerHTML]="taskMessage.messageContent"></p>
        </div>
      </ui-expendable-section>

      <!-- Escalation Schedule -->
      <ui-expendable-section
        class="task-findings-messages task-expandable-result"
        *ngIf="incident.escalationScheduleHtml && incidentTask.task.customTask?.type==='CLIENT_COMMUNICATION'"
        [type]="expendableTypeEnum.COLUMN"
        [forceExpanded]="false"
        [transparentBackground]="true"
      >
        <header
          class="section-title"
          header
        >
          <div class="image-and-title">
            <div class="img-wrapper">
              <img
                src="assets/favicons/communication/icon_communication_tab_settings_inactive.svg"
                class="icon-messages"
              >
            </div>
            <span class="title">{{ 'incidents.component.tab.task.escalation.history' | t }}</span>
          </div>
        </header>
        <div class="section-content">
          <p [innerHTML]="incident.escalationScheduleHtml"></p>
        </div>
      </ui-expendable-section>
    </div>
  </ui-expendable-section>

  <!-- Task History -->
  <ui-expendable-section
    class="task-history main-expandable-section"
    *ngIf="isAdminConsole"
    #taskHistory
    [disabled]="false"
    [type]="expendableTypeEnum.COLUMN"
    [borderBottomWhenOpen]="expandableSections[expandableSections.length - 1] === taskSectionsEnum.HISTORY"
    [forceExpanded]="false"
    (expandedEvent)="handleHistoryExpanded()"
  >
    <header
      class="section-title"
      header
    >
      <div class="image-and-title">
        <div class="img-wrapper">
          <img
            src="assets/favicons/icon_history_inactive.svg"
            class="icon-scale"
            style="margin-left: 5px;"
          >
        </div>
        <span class="title">{{'common.history' | t}}</span>
      </div>
      <div>
        <button
          *ngIf="!!historyResponse && !taskHistory.isCollapsed"
          ui-button
          custom-icon="true"
          isSvg="true"
          icon="icon_refresh_grey"
          modifier="on-highlight"
          color="transparentFlat"
          style="border-radius: 50%; padding: 6px; width: 36px; height: 36px"
          [loading]="isLoadingHistory"
          (click)="handleHistoryManualRefresh(); $event.preventDefault(); $event.stopPropagation();"
        ></button>
      </div>
    </header>
    <div class="section-content">
      <history-table
        [history]="history"
        [isLoading]="isLoadingHistory"
        [canLoadMore]="historyResponse?.canLoadMore()"
        (loadMoreEvent)="handleLoadMoreHistory()"
      ></history-table>
    </div>
  </ui-expendable-section>
</section>


<!-- Task buttons at the bottom of the card -->
<section
  class="task-quick-actions"
  *ngIf="!disabled"
>
  <button
    *ngIf="showStartButton && !isCustomButtons()"
    ui-button
    class="task-quick-actions--start"
    color="standard"
    (click)="onClickStartFirstTask()"
  >{{"incidents.component.tab.task.quickActions.start" | t}}</button>

  <button
    *ngIf="showCompleteButton"
    ui-button
    class="task-quick-actions--complete"
    color="standard"
    (click)="onClickCompleteFinalTask()"
  >{{"incidents.component.tab.task.quickActions.complete" | t}}</button>

  <button
    *ngIf="showStopButton"
    ui-button
    class="task-quick-actions--complete"
    color="standard"
    (click)="onClickStopInvestigationTask()"
  >{{"incidents.component.tab.task.quickActions.stop" | t}}</button>

  <button
    *ngIf="showNotifyWithEscalationButton"
    ui-button
    class="task-quick-actions--complete"
    color="standard"
    (click)="onClickNotify(true)"
  >{{"incidents.component.tab.task.quickActions.notifyWithEscalation" | t}}</button>

  <button
    *ngIf="showNotifyWithoutEscalationButton"
    ui-button
    class="task-quick-actions--complete"
    color="standard"
    (click)="onClickNotify(false)"
  >{{"incidents.component.tab.task.quickActions.notifyWithoutEscalation" | t}}</button>

  <button
    *ngIf="showCustomNextButton"
    ui-button
    class="task-quick-actions--complete"
    color="standard"
    (click)="onClickCustomNextTask()"
  >{{"incidents.component.tab.task.quickActions.next" | t}}</button>

  <button
    *ngIf="showNextButton"
    ui-button
    class="task-quick-actions--next"
    color="standard"
    (click)="onClickNextTask()"
  >{{"incidents.component.tab.task.quickActions.next" | t}}</button>

</section>
