import { DatasourceDetailsMapper } from "projects/@common/services/api/respond/datasources/datasource.mapper";
import { IUsecaseDescriptionResponse } from "projects/@common/services/api/respond/usecase/usecase.definition";
import { DataConnectorTypes } from '@md.eco/connectors';


export interface IIncidentConnector {
  datasourceType: string;
  usecaseShortId: string;
  iconRelativePath: string;
  iconFullPath: string;
  connectorName: string;
}


function getConnectors(incidentUsecases: IUsecaseDescriptionResponse[], datasourceMapper: DatasourceDetailsMapper): IIncidentConnector[] {
  return incidentUsecases
    .map((uc: IUsecaseDescriptionResponse) => {
      const type: DataConnectorTypes = uc.usecase.datasourceType as DataConnectorTypes;
      const datasource = datasourceMapper.getDatasourceIcon([ type ])?.[0];
      return {
        datasourceType: type,
        usecaseShortId: uc?.template?.usecaseId || "-",
        connectorName: datasource ? datasource.name : null,
        iconRelativePath: datasource ? `/${datasource.icon}` : null,
        iconFullPath: datasource ? `${window.origin}/${datasource.icon}` : null,
      };
    })
    .reduce((acc: IIncidentConnector[], current: IIncidentConnector) => {
      const isDuplicate = acc.some((item) => item.connectorName === current.connectorName && item.usecaseShortId === current.usecaseShortId);
      if (!isDuplicate) {
        acc.push(current);
      }
      return acc;
    }, [])
    .sort((a: IIncidentConnector, b: IIncidentConnector) => `${a.connectorName + a.usecaseShortId}`.localeCompare(`${b.connectorName + b.usecaseShortId}`));
}


export { getConnectors };
