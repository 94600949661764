import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { JsonUtils } from '@common/utils/json-utils';

export interface JsonTextareaConfig {
  controlName: string;
  formGroup: UntypedFormGroup;
  label: string;
  required: boolean;
  placeholder: string;
}

export enum TextareaTypeEnum {
  JSON = 'json',
  ARRAY = 'array'
}

@Component({
  selector: 'json-textarea',
  templateUrl: './json-textarea.component.html',
})
export class JsonTextareaComponent implements OnInit {
  @Input() controlName: string;

  @Input() formGroup: UntypedFormGroup;

  @Input() label: string;

  @Input() description: string;

  @Input() required: boolean;

  @Input() placeholder: string;

  @Input() readonly: false;

  @Input() prettifyButton = true;

  @Input() type: TextareaTypeEnum = TextareaTypeEnum.JSON;

  ngOnInit(): void {
    this.placeholder =
      this.type === TextareaTypeEnum.JSON
        ? 'ex: { "key1" : value1, "key2" : value2, "key3" : value3, "key4" : value4 }'
        : 'ex: [value1, value2, value3, value4, value5]';
  }

  public get isInvalid(): boolean {
    const control = this.formGroup.get(this.controlName);
    return control.invalid && control.touched;
  }

  public validateAndPrettify(formControlName: string): void {
    const control = this.formGroup.get(formControlName) as AbstractControl;
    try {
      JSON.parse(control.value);
      if (control.valid) {
        control.setValue(JsonUtils.prettifyJson(control.value));
      }
    } catch (error) {
      console.log('Invalid json format');
    }
  }
}
