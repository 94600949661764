import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Eco } from "projects/@common/definitions/eco";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { MobileService } from "projects/@common/services/mobile.service";
import { Observable, Subscription } from "rxjs";

import { AppState } from "../../stores/app.state";
import { ConsoleType } from "../../private.layout";
import { DisplayService } from "@common/modules/display/display.service";

@Component({
  selector: 'nav[topbar]',
  templateUrl: './topbar.component.html',
  styleUrls: [ './topbar.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'topbar',
    role: 'navigation',
  },
})
export class Topbar implements OnChanges, OnDestroy, OnInit {
  @Select(AppState.isTopbarDisabled) public isTopbarDisabled$: Observable<boolean>;

  @Select(AppState.isExpanded) public isSideBarExpanded$: Observable<boolean>;

  @Select(EcoSessionState.organization) public organization$: Observable<Eco.IOrganization>;

  @Input('title') public title: string;

  @Output('toggle-menu') public menuToggled: EventEmitter<void> = new EventEmitter();

  public hasSearchBar = false;

  public isSearchBarVisible = false;

  public topbarTitle: string;

  public isConsoleOrg: boolean = false;

  public canListMdrReleaseNotes = false;

  public canListProactiveReleaseNotes = false;

  private activePageSubscription: Subscription;

  constructor (
    public mobileService: MobileService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private readonly displayService: DisplayService,
    @Inject('CONSOLE_TYPE') private readonly consoleType: ConsoleType
  ) { }

  ngOnInit() {
    this.isConsoleOrg = this.consoleType === ConsoleType.ORG;

    this.canListMdrReleaseNotes = this.displayService.meetsRequirements({ permissions: [ 'can_list_releases_notes_mdr' ] });
    this.canListProactiveReleaseNotes = this.displayService.meetsRequirements({ permissions: [ 'can_list_releases_notes_proactive' ] });

    // this.currentUser = {
    //   ...this.iamSessionService.currentUser,
    // };
    // this.currentOrganization = {
    //   ...this.iamSessionService.organization
    // }
  }

  ngOnDestroy() {
    // this.activePageSubscription?.unsubscribe();
  }

  ngOnChanges() {
    // this.activePageSubscription = this.store.select(AppState.activePage).subscribe(activePage => {
    //   this.topbarTitle = `${activePage}.title`;

    //   if (this.mobileService.isMobile()) {
    //     setTimeout(() => {
    //       switch (activePage) {
    //         case AppPages.RESOURCE_PAGE:
    //         case AppPages.RECENT_FILES_PAGE:
    //         case AppPages.SHARED_WITH_ME_FILES_PAGE:
    //         case AppPages.LIBRARIES_PAGE:
    //         case AppPages.SHARED_FILES_PAGE:
    //         case AppPages.RESOURCES_WITH_SHARES_PAGE:
    //         case AppPages.MY_SHARES:
    //           this.hasSearchBar = true;
    //           break;
    //         default:
    //           this.hasSearchBar = false;
    //       }
    //       this.cdr.detectChanges();
    //     }, 1);
    //   }
    // });
  }

  public handleToggle(): void {
    this.menuToggled.emit();
  }

  openSearchBar() {
    // this.isSearchBarVisible = true;
  }
}
