<div class="flex-box-container">
  <a
    [routerLink]="backLink"
    [queryParamsHandling]="queryParamsHandling"
    class="router-link"
  >
    <button
      ui-button
      color="listActionFlat"
      icon="icon_back"
      [custom-icon]="true"
      [isSvg]="true"
      icon-size="medium"
    >
      {{ 'common.action.return' | t }}
    </button>
  </a>

  <app-incident-report-button
    *ngIf="incident"
    [incidentInfo]="incident"
    [observables]="observables"
    [incidentActions]="incidentActions"
    [entities]="entities"
    [isWithinTableColumn]="false"
    [tasksMessages]="tasksMessages"
  ></app-incident-report-button>

  <button
    *ngIf="showCloseIncidentButton"
    ui-button
    id="incidentsNavbarCloseIncidentButton"
    color="lightGreyFlat"
    icon="ico_fermer_incident"
    [custom-icon]="true"
    [isSvg]="true"
    icon-size="small"
    icon-margin="0 1px 0 0"
    (click)="emitCloseIncidentEvent()"
  >
    {{ 'incidents.container.page.details.nav.closeIncident.button.text' | t }}
  </button>

  <button
    *ngIf="showReopenIncidentButton"
    ui-button
    id="incidentsNavbarReopenIncidentButton"
    color="lightGreyFlat"
    icon="icon_reset_left_arrow"
    [custom-icon]="true"
    [isSvg]="true"
    icon-size="small"
    icon-margin="0 1px 0 0"
    (click)="emitReopenIncidentEvent()"
  >
    {{ 'incidents.container.page.details.nav.reopenIncident.button.text' | t }}
  </button>

  <button
    *ngIf="sendNotificationButton"
    ui-button
    id="incidentsNavbarSendNotificationButton"
    color="lightGreyFlat"
    icon="icon_conversation"
    [custom-icon]="true"
    [isSvg]="true"
    icon-size="small"
    icon-margin="0 1px 0 0"
    (click)="emitSendNotificationEvent()"
  >
    <span [ngStyle]="{'margin-right': '2px'}"></span>
    {{ 'incidents.container.page.details.nav.sendNotification.button.text' | t }}
  </button>
</div>

<div class="flex-box-container">

  <div
    *ngIf="showHeaderOrgInfo"
    class="name-container organization"
  >
    <p>{{incident?.organizationName}}</p>
    <organization-preferred-language
      *ngIf="incident?.organizationEcoId"
      class="alert-ignore-modal--preferredLanguage"
      [organizationEcoId]="incident.organizationEcoId"
    ></organization-preferred-language>
  </div>

  <short-id
    [shortId]="incident?.shortId"
    [label]="null"
  ></short-id>

  <incident-status-badge [incidentStatus]="incident?.status"></incident-status-badge>

  <incident-connectors
    *ngIf="usecases?.length"
    [incidentUsecases]="usecases"
  ></incident-connectors>

</div>