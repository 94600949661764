<ng-container *ngIf="!isEditing; else editingTemplate">
  <div class="method-row background-gray">
    <div class="left-column">
      <ng-container *ngTemplateOutlet="methodIconTemplate; context: {iconSrc: iconSrc}"></ng-container>
      <p class="method-type">
        {{("respond.communication.tab.contacts.communicationMethods.type." + communicationMethod.type) | t}}
      </p>
    </div>

    <div class="right-column">
      <p class="method-information">{{readonlyValue}}</p>
    </div>
  </div>
</ng-container>


<ng-template #editingTemplate>
  <div class="method-row background-gray editable">
    <div class="left-column">
      <button
        ui-button
        class="method-icon"
        [style.visibility]="showDeleteButton ? 'visible' : 'hidden'"
        color="drawerCloseFlat"
        icon="deleteActive"
        [tooltip]="'common.action.delete'| t"
        (click)="handleDeleteClicked();"
      >
      </button>
      <ng-container *ngTemplateOutlet="methodIconTemplate; context: {iconSrc: iconSrc}"></ng-container>
      <div class="method-type">
        <ui-select
          ngDefaultControl
          [name]="'methodType'"
          [label]="'respond.communication.tab.contacts.communicationMethods.method' | t"
          [placeholder]="'respond.communication.tab.contacts.communicationMethods.method.placeholder' | t"
          [required]="true"
          [options]="methodsSelectOptions"
          [(ngModel)]="communicationMethod.type"
          (ngModelChange)="handleOptionSelected($event)"
        >
        </ui-select>
      </div>
    </div>

    <div class="right-column">
      <!-- dynamic inputs for selected communication method type -->
      <ng-container [ngSwitch]="communicationMethod.type">
        <div
          *ngSwitchCase="methodsEnum.EMAIL"
          class="email-address-input-container"
        >
          <ng-container *ngTemplateOutlet="emailTemplate; context: {comMethod: communicationMethod}"></ng-container>
        </div>
        <div
          *ngSwitchCase="methodsEnum.OTHER"
          class="note-input-container"
        >
          <ng-container *ngTemplateOutlet="otherTemplate; context: {comMethod: communicationMethod}"></ng-container>
        </div>
        <div
          *ngSwitchCase="methodsEnum.SMS"
          class="phone-number-input-container"
        >
          <ng-container *ngTemplateOutlet="phoneTemplate; context: {comMethod: communicationMethod}"></ng-container>
        </div>
        <div
          *ngSwitchCase="methodsEnum.PHONE"
          class="field-group-same-row"
        >
          <div class="phone-number-input-container">
            <ng-container *ngTemplateOutlet="phoneTemplate; context: {comMethod: communicationMethod}"></ng-container>
          </div>
          <div class="phone-extension-input-container">
            <ng-container *ngTemplateOutlet="phoneExtensionTemplate; context: {comMethod: communicationMethod}"></ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>


<ng-template
  #methodIconTemplate
  let-iconSrc="iconSrc"
>
  <img
    class="method-icon"
    [class.hidden]="!iconSrc"
    [src]="iconSrc"
    alt="icon"
  >
</ng-template>


<ng-template
  #emailTemplate
  let-communicationMethod="comMethod"
>
  <label
    ui-label
    [required]="true"
  >{{'respond.communication.tab.contacts.communicationMethods.emailAddress' | t}}</label>
  <input
    #emailInput="ngModel"
    ui-input
    required
    email
    type="text"
    [placeholder]="'respond.communication.tab.contacts.communicationMethods.emailAddress.placeholder' | t"
    [(ngModel)]="communicationMethod.properties.email"
  >
  <div
    *ngIf="emailInput.invalid && emailInput.touched"
    class="input-error-container"
  >
    <p *ngIf="emailInput.errors.required">
      {{'respond.communication.tab.contacts.communicationMethods.emailAddress.required' | t}}
    </p>
    <p *ngIf="emailInput.errors.email">
      {{'respond.communication.tab.contacts.communicationMethods.emailAddress.invalid' | t}}
    </p>
  </div>
</ng-template>


<ng-template
  #otherTemplate
  let-communicationMethod="comMethod"
>
  <label
    ui-label
    [required]="true"
  >{{'respond.communication.tab.contacts.communicationMethods.note' | t}}</label>
  <input
    #noteInput="ngModel"
    ui-input
    type="text"
    [placeholder]="'respond.communication.tab.contacts.communicationMethods.note.placeholder' | t"
    [(ngModel)]="communicationMethod.properties.note"
  >
</ng-template>

<ng-template
  #phoneTemplate
  let-communicationMethod="comMethod"
>
  <div class="flex-box center-horizontal gap-025">
    <label
      ui-label
      [required]="true"
    >{{'respond.communication.tab.contacts.communicationMethods.phoneNumber' | t}}</label>
    <ui-tooltip [isSmall]="true">{{'respond.communication.tab.contacts.communicationMethods.phoneNumber.tooltip'|t}}</ui-tooltip>
  </div>
  <input
    #phoneInput="ngModel"
    ui-input
    required
    phoneNumberValidator
    phoneNumberMask
    type="text"
    [placeholder]="'+x xxx xxx xxxx'"
    [(ngModel)]="communicationMethod.properties.phoneNumber"
  >
  <div
    *ngIf="phoneInput.invalid && phoneInput.touched"
    class="input-error-container"
  >
    <p *ngIf="phoneInput.errors.required">
      {{'respond.communication.tab.contacts.communicationMethods.phoneNumber.required' | t}}
    </p>
    <p *ngIf="phoneInput.errors.invalidPhoneNumber">
      {{'respond.communication.tab.contacts.communicationMethods.phoneNumber.invalid' | t}}
    </p>
  </div>
</ng-template>


<ng-template
  #phoneExtensionTemplate
  let-communicationMethod="comMethod"
>
  <label ui-label>{{'respond.communication.tab.contacts.communicationMethods.phoneExtension' | t}}</label>
  <input
    #phoneExtensionInput="ngModel"
    ui-input
    uiNumberOnlyInput
    type="text"
    [(ngModel)]="communicationMethod.properties.ext"
  >
</ng-template>