import { IncidentTaskPhases } from "../../incidents/incidents.definitions";
import { IncidentTask } from "./incidentTask";

export class IncidentTaskPhase {
  public phase: IncidentTaskPhases;
  public name: string;
  public tasks: IncidentTask[];
  public hasUnsavedChange: boolean;

  constructor(phaseName: string) {
    this.name = phaseName;
    this.tasks = [];
  }

  public get numberOfTask(): number {
    return this.tasks.length;
  }

  public addTask(task: IncidentTask | any): void {
    this.tasks.push(task);
  }

  public setTask(task: IncidentTask): void {
    const taskIndex = this.findTaskIndex(task.taskId);
    if (taskIndex === -1) {
      this.tasks.push(task);
    } else {
      this.tasks[taskIndex].updateAvailableStatuses(task);
    }
  }

  public removeTask(task: IncidentTask): void {
    const taskIndex = this.findTaskIndex(task.taskId);
    if (taskIndex > -1) {
      this.tasks.splice(taskIndex, 1);
    }
  }

  public get numberOfTaskApplicable(): number {
    return (this.tasks.filter((task) => task.task.status !== 'NOT_APPLICABLE')).length;
  }

  public filterRemovedTasks(incidentTasks: IncidentTask[]): void {
    // Filter out tasks that are no longer in the incident
    const removedTasks = this.tasks.filter((task) => !incidentTasks.find((t) => t.taskPhase === task.taskPhase && t.taskId === task.taskId));
    removedTasks.forEach((task) => this.removeTask(task));
  }

  private findTaskIndex(taskId: string): number {
    return this.tasks.findIndex((task) => task.taskId === taskId);
  }
}
