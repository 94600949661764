<label
  *ngIf="showLabel"
  ui-label
>
  {{'incidents.container.page.details.tab.detail.summary.confidentiality' | t}}&nbsp;
  <tooltip-confidentiality></tooltip-confidentiality>
</label>

<app-edit-with-modal
  #editor
  [noMargin]="true"
>
  <div
    class="input-wrapper"
    [ngClass]="{
      'disabled':disabled, 
      'editable':editable, 
      'style-like-input-field':styleLikeInputField
    }"
    [tooltip]="!editable ? null : ('common.action.edit'| t)"
    (click)="open()"
  >
    <div class="input-box flex-box center-horizontal">
      <ui-icon
        *ngIf="value"
        [name]="'confidentiality_breach_grey'"
      ></ui-icon>
      <span>
        {{value ? ("common.boolean.true"|t) : "-"}}
      </span>
    </div>
    <img
      *ngIf="editable"
      alt="edit-icon"
      class="edit-icon"
      src="assets/favicons/icon_modify_disabled.svg"
    />
  </div>

  <div
    content
    class="form-wrapper flex-box center-horizontal"
  >
    <ui-checkbox
      [checked]="!!value"
      [isEditable]="editable"
      (onCheck)="select($event)"
    >
      <ui-icon [name]="'confidentiality_breach_grey'"></ui-icon>
      <label ui-label>{{"incidents.container.page.details.tab.detail.summary.confidentiality.checkboxlabel"|t}}</label>
    </ui-checkbox>
  </div>
</app-edit-with-modal>