import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { UrlUtils } from "projects/@common/utils/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EcoUrlService } from "../../core/url.service";
import { AlertSimilarityItem, IAlert, IAlertFilters, IAlertIgnoreRequest, IAlertStats, ISplitAlertRequest, ISplitAlertResponse, ListAlertSimilarItemsRequest } from "./alerts.definitions";
import { Alert } from "./models/alert";
import { AlertStats } from "./models/alertStats";
import { Store } from "@ngxs/store";
import { EcoSessionState } from "@common/modules/session/state/session.state";

@Injectable({
  providedIn: 'root',
})
export class AlertsApi extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService, private store: Store) {
    super(http, url);
  }

  public listAlerts(filters: IAlertFilters): Observable<ResponseUtils<Alert>> {
    const url = `${this.host}/alerts?${UrlUtils.jsonToQueryParams(filters)}`;
    return this.http.get<IRespondResponse<Alert>>(url)
      .pipe(map((response) => new ResponseUtils<Alert>(response)));
  }

  public getAlertStats(): Observable<AlertStats> {
    const url = `${this.host}/alerts/stats`;
    return this.http.get<IAlertStats>(url)
      .pipe(map((response) => new AlertStats(response)));
  }

  public getAlert(request: { organizationId: string, alertId: string; }): Observable<Alert> {
    const url = `${this.host}/organizations/${request.organizationId}/alerts/${request.alertId}`;
    return this.http.get<IAlert>(url)
      .pipe(map((response) => new Alert(response)));
  }

  public splitAlert(organizationId: string, alertId: string, request: ISplitAlertRequest): Observable<ISplitAlertResponse> {
    const url = `${this.host}/organizations/${organizationId}/alerts/${alertId}/split`;
    return this.http.post<{ completed: true; }>(url, request);
  }

  public updateAlerts(request: IAlertIgnoreRequest): Observable<{ completed: true; }> {
    const url = `${this.host}/alerts/statuses`;
    return this.http.post<{ completed: true; }>(url, request);
  }

  public exportAlerts(filters: IAlertFilters): Observable<ResponseUtils<Alert & { observables: any[]; findings: any[] }>> {
    filters.from = 0;
    filters.size = 500;
    const params = UrlUtils.jsonToQueryParams(filters);
    const url = `${this.host}/alerts/export?${params}`;
    return this.http.get<IRespondResponse<Alert & { observables: any[]; findings: any[] }>>(url)
      .pipe(map((response) => new ResponseUtils<Alert & { observables: any[]; findings: any[] }>(response)));
  }

  public listSimilarItems(organizationId: string, request: ListAlertSimilarItemsRequest): Observable<ResponseUtils<AlertSimilarityItem>> {
    const url = `${this.host}/organizations/${organizationId}/alerts/${request.alertId}/similarities?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<AlertSimilarityItem>>(url)
      .pipe(map((response) => new ResponseUtils<AlertSimilarityItem>(response)));
  }

  public createTestAlert(request: any): Observable<any> {
    if (this.isVarMode) {
      const url = `${this.host}/alerts/test`;
      return this.http.post<any>(url, request);
    }
    const url = `${this.host}/me/alerts/test`;
    return this.http.post<any>(url, request);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
