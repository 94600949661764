import { Component, Input } from '@angular/core';

@Component({
  selector: 'usecase-inventory-top-section',
  templateUrl: './usecase-inventory-top-section.component.html',
  styleUrls: [ './usecase-inventory-top-section.component.scss' ],
})
export class UsecaseInventoryTopSectionComponent {
  @Input('usecaseTotalQuantity')
    usecaseTotalQuantity = 0;

  @Input('usecaseTotalSubscribed')
    usecaseTotalSubscribed = 0;

  @Input('loading')
    loading = false;
}
