import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTimePeriod } from '@ui-kit/components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { PeriodicReportTypeEnum } from 'projects/@common/services/api/respond/periodic-reports/periodic-reports.definitions';

export interface IReportFilters {
  name: string;
  dateFrom: number;
  dateTo: number;
  type?: PeriodicReportTypeEnum;
}

@Component({
  selector: 'respond-reports-filters',
  templateUrl: './respond-reports-filters.component.html',
  styleUrls: [ './respond-reports-filters.component.scss' ],
})
export class RespondReportsFiltersComponent implements OnInit {
  @Input() total: boolean;
  @Input() isLoading: boolean;
  @Input() defaultFilters: IReportFilters;

  @Output() filterEvent = new EventEmitter<IReportFilters>();

  public name: string;
  public period: DateTimePeriod;
  public type: PeriodicReportTypeEnum;
  public reportTypeOptions: TableFilterData;

  public showNameFilter = false;

  constructor(
    public readonly i18n: I18nService,
    private filterDataFactory: TableFilterToolDataFactory
  ) { }

  ngOnInit(): void {
    this.name = this.defaultFilters.name;
    this.period = {
      dateFrom: this.fallbackToNull(this.defaultFilters.dateFrom),
      dateTo: this.fallbackToNull(this.defaultFilters.dateTo),
    };
    this.type = this.defaultFilters.type;
    this.reportTypeOptions = this.filterDataFactory.create(PeriodicReportTypeEnum, 'respond.reports.', this.type);
  }

  public handleSearchTextChange(text: string): void {
    this.name = text;
    this.emitFiltersChange();
  }

  public handlePeriodChange(period: DateTimePeriod): void {
    this.period = this.fallbackToNull(period);
    this.emitFiltersChange();
  }

  public handleReportTypeChange(type: PeriodicReportTypeEnum): void {
    this.type = type;
    this.emitFiltersChange();
  }

  public handleRefreshClicked(): void {
    this.emitFiltersChange();
  }

  public emitFiltersChange(): void {
    const filters: IReportFilters = {
      name: this.name,
      dateFrom: this.fallbackToNull(this.period?.dateFrom),
      dateTo: this.fallbackToNull(this.period?.dateTo),
      type: this.type,
    };
    this.filterEvent.emit(filters);
  }

  private fallbackToNull(value: any): any {
    return value || null;
  }
}
