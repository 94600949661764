import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QueryBuilderClassNames, QueryBuilderConfig, RuleSet } from 'angular2-query-builder';
import { UntypedFormControl } from '@angular/forms';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import * as isoCountries from 'i18n-iso-countries';
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";
import { Eco } from 'projects/@common/definitions/eco';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';

isoCountries.registerLocale(fr);
isoCountries.registerLocale(en);

@Component({
  selector: 'query-builder-component',
  templateUrl: './query-builder.component.html',
  styleUrls: [ './query-builder.component.scss' ],
})
export class QueryBuilderComponent implements OnInit {
  @Input() public conditions?: RuleSet;
  @Input() public conditionFields: any;
  @Input() public isDisabled: boolean;
  @Output() public conditionsChange: EventEmitter<RuleSet> = new EventEmitter();

  public queryCtrl: UntypedFormControl;
  public warnings = [];

  public countries: { value: string; displayValue: string }[];
  public customAutocompleteType = AutocompleteTypes.CUSTOM;

  config: QueryBuilderConfig = null;

  classNames: QueryBuilderClassNames = {
    invalidRuleSet: 'empty-message',
    emptyWarning: 'empty-message',
  };

  constructor(private readonly i18n: I18nService) {}

  ngOnInit(): void {
    const sortedConditionFields = Object.keys(this.conditionFields).sort().reduce(
      (obj, key) => {
        obj[key] = this.conditionFields[key];
        return obj;
      },
      {}
    );

    this.config = {
      fields: sortedConditionFields,
    };

    const locale = this.i18n.currentLocale as Eco.Languages;
    const countries = isoCountries.getNames(locale, { select: "alias" });
    this.countries = Object.entries(countries)
      .map(([ key, value ]) => ({ value: key, displayValue: value }))
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue));

    this.computeWarnings();
  }

  isAddButtonAvailable(ruleset: RuleSet, button: string) {
    if (button === 'addRule') {
      return ruleset.condition !== 'not' || !ruleset.rules?.length;
    }
    if (button === 'addRuleSet') {
      return ruleset.condition !== 'not' || !ruleset.rules?.length;
    }
    return true;
  }

  getCountryDisplayValue(value: string): string {
    return this.countries?.find((option) => option.value === value)?.displayValue || '-';
  }

  getSelectedCountry(rule: any): any[] {
    if (rule.value) {
      return this.countries.filter((item) => item.value === rule.value);
    }
    return [];
  }

  getOperatorOptions(options: string[], value: string): { value: string; displayValue: string }[] {
    return options.map((option) => ({ value: option, displayValue: option }));
  }

  getFieldDisplayValue(options: { name: string, value: string }[], value: string): string {
    return options?.find((option) => option.value === value)?.name || '-';
  }

  getOptionDisplayValue(options: { name: string, value: string }[], value: string): string {
    return options?.find((option) => option.value === value)?.name || '-';
  }

  getBooleanDisplayValue(value: boolean): string {
    return value === true ? "True" : value === false ? "False" : '-';
  }

  getSelectedField(rule: any, fields: { name: string, value: string }[]): any[] {
    const field = fields.find((item) => item.value === rule.field);
    return field ? [ { value: field.value, displayValue: field.name } ] : [];
  }

  getOptions(fields: { name: string, value: string }[]): { value: string; displayValue: string }[] {
    return fields.map((option) => ({ value: option.value, displayValue: option.name }));
  }

  getBooleanOptions(): { value: string; displayValue: string }[] {
    return [ { value: 'true', displayValue: 'True' }, { value: 'false', displayValue: 'False' } ];
  }

  onBooleanChange(rule: any, $event: string) {
    rule.value = $event === 'true';
  }

  setNumber(rule: any, value: string) {
    rule.value = +value;
  }

  setFieldValue($event: any, rule: any, onChange: any) {
    if ($event) {
      delete rule['invalid'];
      rule.field = $event.value;
      onChange($event.value, rule);
    } else {
      rule['invalid'] = true;
    }
    this.computeWarnings();
  }

  get data(): RuleSet {
    return this.conditions;
  }

  getIsValid(): boolean {
    return this.warnings.length === 0;
  }

  computeWarnings() {
    this.warnings = [];

    this.validateRuleset(this.conditions, this.warnings);

    this.warnings = [ ...new Set(this.warnings) ];
  }

  private validateRuleset(condition: RuleSet, warnings: any[]) {
    for (const rule of condition.rules) {
      if (rule['condition']) {
        this.validateRuleset(rule as RuleSet, warnings);
      }
    }
  }
}
