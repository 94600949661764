import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPlaybookTaskTemplateRepresentation } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';

@Component({
  selector: 'playbook-task-library-card',
  templateUrl: './playbook-task-library-card.component.html',
  styleUrls: [ './playbook-task-library-card.component.scss' ],
})
export class DetectionPlaybooksLibraryTaskCardComponent implements OnInit {
  private static readonly UPDATED_DATE_LIMIT = 7;

  @Input() public playbooksTable: IPlaybookTaskTemplateRepresentation;

  @Input() public isCollapsable = true;

  @Input() public isCollapsed = true;

  @Input() public isTasklibrary = true;

  @Input() public icon = true;

  @Output() public onToggleCollapse = new EventEmitter<boolean>();

  public taskIconSrc = '';

  public tags = [];

  public updateDate = '';

  public toggleCollapse() {
    if (this.isCollapsable) {
      this.isCollapsed = !this.isCollapsed;
      this.onToggleCollapse.emit(this.isCollapsed);
    }
  }

  ngOnInit(): void {
    if (this.isCollapsed) {
      this.isCollapsed = this.isCollapsable;
    }
    if (this.isTasklibrary) {
      this.tags = this.playbooksTable.recommendedPhases;
    }

    const updatedAtDiff = this.updatedDateDiffToString(this.playbooksTable?.updatedAt);
    if (updatedAtDiff <= DetectionPlaybooksLibraryTaskCardComponent.UPDATED_DATE_LIMIT) {
      this.taskIconSrc = 'assets/favicons/icon_playbook_task_template_recently_updated.svg';
      this.updateDate = updatedAtDiff.toString();
    } else {
      this.taskIconSrc = `assets/favicons/icon_playbook_task_${this.playbooksTable.templateId ? 'library' : 'custom'}.svg`;
    }
  }

  private updatedDateDiffToString(date: number): number {
    const diff = Date.now() / 1000 - date;
    return Math.ceil(diff / (1000 * 3600 * 24));
  }
}
