<ng-container *ngIf="!isLoading">
  <app-guest-expiration
    [currentSettings]="currentSettings"
    [isLoading]="isLoading"
  ></app-guest-expiration>

  <app-guest-grace-period
    [currentSettings]="currentSettings"
    [isLoading]="isLoading"
  ></app-guest-grace-period>
</ng-container>

<ng-container *ngIf="isLoading">
  <ui-spinner size="medium"></ui-spinner>
</ng-container>
