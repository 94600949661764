import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UiLabelInputWithModifyComponent } from '@ui-kit/components/ui-label-input-with-modify/ui-label-input-with-modify.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { PlaybooksApiService } from 'projects/@common/services/api/respond/playbooks/playbooks.api';
import { IPlaybookDetail, IPlaybookTemplate } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import {
  PlaybookInventoryDetailForm,
  PlaybookPatchValuesEnum
} from '../../containers/respond-playbook-detail/playbook-details.form';
import { PlaybookTabService, PlaybookTabsEnum } from './playbook-tab.service';

@Component({
  selector: 'playbook-detail-tab-header',
  templateUrl: './playbook-detail-tab-header.component.html',
  styleUrls: [ './playbook-detail-tab-header.component.scss' ],
})
export class PlaybookDetailTabHeaderComponent implements OnInit {
  @Input()
  public playbook?: IPlaybookDetail;

  @Input()
  public template?: IPlaybookTemplate;

  @Input()
  public playbookDetailForm: PlaybookInventoryDetailForm;

  @Input()
  public organizationName: string;

  @Input()
  public tabsToHide: Array<PlaybookTabsEnum>;

  @Input()
  public showHeaderOrgInfo: boolean;

  @Input()
  public isReadonly: boolean;

  public playbookValuesEnum = PlaybookPatchValuesEnum;

  @ViewChild('uiLabelEdit') public uiLabelEdit: UiLabelInputWithModifyComponent;

  constructor(
    private readonly playbookApiService: PlaybooksApiService,
    public readonly playbookTabService: PlaybookTabService,
    private readonly noticeService: NoticeService,
    public readonly i18nService: I18nService
  ) {
  }
  ngOnInit(): void {
    if (!this.playbook && this.template) {
      this.tabsToHide.push(PlaybookTabsEnum.TASK);
      this.tabsToHide = [ ...new Set(this.tabsToHide) ];
    }
    this.playbookTabService.setTabsToHide(this.tabsToHide);
  }

  public onTitleClick(): void {
    this.uiLabelEdit.openEdit();
  }

  public async savePlaybookValue(patchValueEnum: PlaybookPatchValuesEnum, value: string): Promise<void> {
    if (this.isReadonly) {
      return;
    }
    if (this.playbook) {
      const modifiedPlaybook = {
        ...this.playbook,
        [patchValueEnum]: value,
      };
      this.playbookApiService.updatePlaybook(this.playbook.organizationId, modifiedPlaybook)
        .then(() => {
          this.playbook[patchValueEnum] = value;
          this.noticeService.success('detection.playbook.detail.updated.success');
        })
        .catch((err) => this.noticeService.error(err));
    }
  }

  public hasTemplate(): boolean {
    return this.template != null || this.playbook.templateId !== null;
  }
}
