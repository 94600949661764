import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DatasourceConfigurationParameterTypes } from 'projects/@common/services/api/respond/datasources/datasources.definitions';
import { AlertingUsecaseListItem, IAction, IAggregationRule, IMonitor, IMonitorParameters, IUpdateMonitorDetails, IUpdateUsecaseDetails } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { CustomValidators } from 'projects/@common/utils/validators';

export class UsecaseUpdateForm {
  public usecaseFormGroup: UntypedFormGroup;

  public monitorFormGroup: UntypedFormGroup;

  constructor (protected readonly formBuilder: UntypedFormBuilder) {
    this.createUsecaseForm();
    this.createMonitorForm();
  }

  public get actionsFormArray(): UntypedFormArray {
    return this.usecaseFormGroup.get('pipeline') as UntypedFormArray;
  }

  public get blacklistedComment() {
    return this.usecaseFormGroup.get('blacklistedComment').value;
  }

  public get alertingModeComment() {
    return this.usecaseFormGroup.get('silencedComment').value;
  }

  public get alertingMode() {
    return this.usecaseFormGroup.get('silenced').value;
  }

  public get monitorParametersFormArray(): UntypedFormArray {
    return this.monitorFormGroup.get('parameters') as UntypedFormArray;
  }

  public get actionsControls(): Array<UntypedFormControl> {
    return this.actionsFormArray.controls as UntypedFormControl[];
  }

  public get isUsecaseFormValid(): boolean {
    return this.usecaseFormGroup.valid;
  }

  public get isMonitorFormValid(): boolean {
    return this.monitorFormGroup.valid;
  }

  public setAlertingMode(silenced: boolean): void {
    this.usecaseFormGroup.get('silenced').setValue(silenced);
  }

  public setAlertingModeComment(comment: string): void {
    this.usecaseFormGroup.get('silencedComment').setValue(comment);
  }

  public clearAlertingModeComment(): void {
    this.usecaseFormGroup.get('silencedComment').setValue(null);
  }

  public setBlacklistedComment(comment: string): void {
    this.usecaseFormGroup.get('blacklistedComment').setValue(comment);
  }

  public clearBlacklistedComment(): void {
    this.usecaseFormGroup.get('blacklistedComment').setValue(null);
  }

  public toggleAlertingMode(): void {
    this.setAlertingMode(!this.alertingMode);
  }

  public removeUsecaseAction(index: number): void {
    this.actionsFormArray.removeAt(index);
  }

  public setUsecaseId(usecaseId: string): void {
    this.usecaseFormGroup.get('id').setValue(usecaseId);
    this.monitorFormGroup.get('id').setValue(usecaseId);
  }

  public setPlaybookId(playbookId: string): void {
    if (!this.usecaseFormGroup.contains('playbookId')) {
      this.usecaseFormGroup.addControl('playbookId', this.formBuilder.control(''));
    }
    this.usecaseFormGroup.get('playbookId').setValue(playbookId);
  }

  public setUsecasePriority(priority: any): void {
    if (!this.usecaseFormGroup.contains('priority')) {
      this.usecaseFormGroup.addControl('priority', this.formBuilder.control(''));
    }
    this.usecaseFormGroup.get('priority').setValue(priority);
  }

  public setUsecaseAggregationRule(rule: IAggregationRule) {
    this.usecaseFormGroup.get('aggregationRule').setValue(JSON.stringify(rule));
  }

  public get usecaseJson(): IUpdateUsecaseDetails {
    if (typeof (this.usecaseFormGroup.get('priority').value) === 'string') {
      this.setUsecasePriority(-1);
    };

    const bodyToReturn: AlertingUsecaseListItem = this.usecaseFormGroup.getRawValue();
    bodyToReturn.pipeline = bodyToReturn.pipeline.map((action) => this.setActionToSave(action, !!action.name));
    bodyToReturn.silenced = !!bodyToReturn.silenced;

    if (bodyToReturn.aggregationRule) {
      bodyToReturn.aggregationRule = JSON.parse(bodyToReturn.aggregationRule);
    } else {
      delete bodyToReturn.aggregationRule;
    }

    return bodyToReturn;
  }

  public get monitorsJson(): IUpdateMonitorDetails {
    const bodyToReturn = this.monitorFormGroup.getRawValue();

    bodyToReturn.parameters.forEach((parameter) => (parameter.parameters = parameter.parameters.map((monitor) => ({
      name: monitor.name,
      type: monitor.type,
      value: this.setValue(monitor.type, monitor.value),
    }))));

    return bodyToReturn;
  }

  public getActionControl(action: IAction): UntypedFormGroup {
    const configAsString = action.config ? JSON.stringify(action.config) : '';
    return this.formBuilder.group({
      name: action.name,
      config: [ configAsString, CustomValidators.isValidJson ],
      action: action.action,
      inputs: action.inputs,
      outputs: action.outputs,
      precondition: action.precondition,
      postcondition: action.postcondition,
    });
  }

  public getMonitorParametersControl(param: IMonitorParameters): UntypedFormGroup {
    const group = this.formBuilder.group({
      name: param.name,
      type: param.type,
      value: [ param.default ? param.default : null ],
    });

    if (!param.default) {
      group.get('value').setValidators([ Validators.required, this.getProperValidator(param.type) ]);
    } else {
      group.get('value').setValidators(this.getProperValidator(param.type));
    }

    group.get('value').updateValueAndValidity();

    return group;
  }

  private getProperValidator(type: string): any {
    switch (type) {
      case DatasourceConfigurationParameterTypes.NUMBER:
        return CustomValidators.numberValidator;
      case DatasourceConfigurationParameterTypes.STRING_LIST:
        return CustomValidators.isArrayOfString;
      case DatasourceConfigurationParameterTypes.OBJECT:
        return CustomValidators.isValidJson;
      default:
        return CustomValidators.defaultValidator;
    }
  }

  public getMonitorControl(monitor: IMonitor): UntypedFormGroup {
    const parametersForm = [];
    monitor?.parameters?.forEach((param) => parametersForm.push(this.getMonitorParametersControl(param)));

    return this.formBuilder.group({
      monitor: monitor.name,
      blacklisted: monitor.blacklisted,
      silenced: monitor.silenced,
      parameters: this.formBuilder.array(parametersForm),
    });
  }

  public createMonitorForm(): void {
    this.monitorFormGroup = this.formBuilder.group({
      id: null,
      parameters: this.formBuilder.array([]),
    });
  }

  public patchMonitorParameters(monitor: IMonitor, index: number): void {
    monitor.parameters.forEach((param) => {
      let value: any;
      if (param.type === 'string') {
        value = param.value;
      } else {
        value = JSON.stringify(param.value);
      }
      const paramIndex = (this.monitorParametersFormArray.at(index).get('parameters').value as any[]).findIndex((paramsForm) => paramsForm.name === param.name);

      (this.monitorParametersFormArray.at(index).get('parameters') as UntypedFormArray).at(paramIndex).setValue({
        name: param.name,
        type: param.type,
        value,
      });
    });
  }

  private setActionToSave(action: IAction, isLegacyAction: boolean): IAction {
    const legacyAction = {};
    const newAction = {};

    if (isLegacyAction) {
      legacyAction['name'] = action.name,
      legacyAction['config'] = action.config ? JSON.parse(action.config) : {};

      return legacyAction;
    }

    newAction['action'] = action.action,
    action.inputs ? newAction['inputs'] = JSON.parse(action.inputs) : null,
    action.outputs ? newAction['outputs'] = JSON.parse(action.outputs) : null,
    action.precondition ? newAction['precondition'] = JSON.parse(action.precondition) : null,
    action.postcondition ? newAction['postcondition'] = JSON.parse(action.postcondition) : null;

    return newAction;
  }

  private createUsecaseForm(): void {
    this.usecaseFormGroup = this.formBuilder.group({
      id: null,
      priority: null,
      silenced: false,
      pipeline: this.formBuilder.array([]),
      aggregationRule: [ null, CustomValidators.isValidJson ],
      affectedResources: [ null, [ CustomValidators.affectedResources ] ],
      silencedComment: this.formBuilder.control(null),
      blacklistedComment: this.formBuilder.control(null),
    });
  }

  private setValue(type: string, value: any): any {
    let valueToReturn;

    if (type === 'object') {
      valueToReturn = this.isJsonString(value) ? JSON.parse(value) : {};
    }
    if (type === 'string') {
      valueToReturn = String(value);
    }
    if (type === 'number') {
      valueToReturn = Number(value);
    }
    if (type === 'boolean') {
      valueToReturn = Boolean(value);
    }

    return valueToReturn;
  }

  private isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
