import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { UiTab } from '@ui-kit/components/ui-tabs/ui-tab.component';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { RequestDirection } from 'projects/@common/definitions/consoles.enum';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SearchMode } from 'projects/@common/modules/layout/components/page/page.component';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ConnectorsOrgService } from 'projects/@common/services/api/detect/connectorsOrg/connectorsOrg.service';
import { ConnectorMappingHelper } from 'projects/@common/services/api/detect/models/connectorMappingHelper';
import { ConnectorsApiOrganizationService } from 'projects/@common/services/api/detect/organizations/connectors-api-organizations';
import { DescribeOrganizationResponse } from 'projects/@common/services/api/detect/organizations/definitions';
import { CommunicationApi } from 'projects/@common/services/api/respond/communication/communication.api';
import { BusinessHours, EscalationDelayByPriority, EscalationListCommunicationMethodRequest, ICommunicationContact, IConnectorEscalation, ICreateContactRequest, ICreateEscalationListRequest, IEscalationLevelForDisplay, IEscalationList, IEscalationParameters, IEsclationListContact, IListContactsRequest, IListEscalationListsRequest, IListEscalationParametersRequest, IUpdateBusinessHoursRequest, IUpdateContactRequest, IUpdateEscalationDelaysRequest, IUpdateEscalationListRequest, PriorityEnum } from 'projects/@common/services/api/respond/communication/communication.definitions';
import { DataConnectorTypes, DataConnectorTypesCatalog } from '@md.eco/connectors';
import { HistoriesApi } from 'projects/@common/services/api/respond/histories/histories.api';
import { IEscalationHistory, IListEscalationHistoryRequest, ListHistoryOrderBy } from 'projects/@common/services/api/respond/histories/histories.definition';
import { PlaybooksApiService } from 'projects/@common/services/api/respond/playbooks/playbooks.api';
import { IPlaybookDetail } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { ResponseUtils } from 'projects/@common/utils/response-utils';
import { pruneObject } from 'projects/@common/utils/utils';
import { PrioritiesFilterEnum } from '@common/services/api/respond/usecase/usecase.definition';
import { IntegerRange } from '@microsoft/microsoft-graph-types';


enum TabsEnum {
  lists = "lists",
  contacts = "contacts",
  settings = "settings",
  history = "history"
}

@Component({
  selector: 'respond-communication-page',
  templateUrl: './respond-communication-page.container.html',
  styleUrls: [ './respond-communication-page.container.scss' ],
})
export class RespondCommunicationPageContainer implements OnInit {
  private activeOrganization: DescribeOrganizationResponse;
  public organizationSearchMode = SearchMode.RESPOND;
  public isVarMode: boolean;

  public uiTabsColor: UiTabsColor = UiTabsColor.lightTabs;
  public tabsEnum = TabsEnum;
  public selectedTab: TabsEnum = TabsEnum.lists;

  public escalationLists: IEscalationList[];
  public totoLists: IEscalationList[];
  public isLoadingEscalationLists = false;

  public contacts: ICommunicationContact[];
  public isLoadingContacts = true;

  public parameters: IEscalationParameters;
  public isLoadingParameters = true;

  public playbooks: IPlaybookDetail[];
  public isLoadingPlaybooks = true;

  public connectors: IConnectorEscalation[];
  public isLoadingConnectors = true;

  public historyResponse: ResponseUtils<IEscalationHistory>;
  public history: IEscalationHistory[];
  public isLoadingHistory = false;

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    private readonly communicationApi: CommunicationApi,
    private readonly playbooksApi: PlaybooksApiService,
    private readonly connectorOrgService: ConnectorsOrgService,
    private readonly historyApi: HistoriesApi,
    private readonly noticeService: NoticeService,
    private readonly i18n: I18nService,
    public readonly connectorMappingHelper: ConnectorMappingHelper
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  ngOnInit(): void {
    this.initDefaultValues();
    this.initDefaultSelectedTabFromURL();
    if (!this.showEmptyPageToSelectOrganization) {
      this.fetchAll();
    }
  }

  public get showEmptyPageToSelectOrganization(): boolean {
    return this.isVarMode && !this.activeOrganization;
  }

  public get organizationId(): string {
    return this.activeOrganization?.organization_respond_id;
  }

  public get showHistoryTab(): boolean {
    return !!this.isVarMode;
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    if (org) {
      this.activeOrganization = await this.connectorsApiOrganizationService.describeOrganization(org.id);
      this.fetchAll();
    } else {
      this.activeOrganization = null;
      this.contacts = [];
    }
  }

  public handleSaveContact(contact: ICommunicationContact): void {
    if (!contact.id) {
      this.createContact(contact);
    } else {
      this.updateContact(contact);
    }
  }

  public handleDeleteContact(contact: ICommunicationContact): void {
    this.deleteContact(contact);
  }

  public handleSaveEscalationList(escalationList: IEscalationList): void {
    if (!escalationList.id) {
      this.createEscalationList(escalationList);
    } else {
      this.updateEscalationList(escalationList);
    }
  }

  public handleDeleteEscalationList(escalationList: IEscalationList): void {
    this.deleteEscalationList(escalationList);
  }

  public handleSaveParameters(parameters: IEscalationParameters): void {
    if (parameters.businessHours) {
      this.updateParametersBusinessHours(parameters.businessHours);
    } else if (parameters.escalationDelays) {
      this.updateParametersEscalationDelays(parameters.escalationDelays.delays);
    }
  }

  public handleTabClicked(tab: UiTab): void {
    const tabName = tab.tabName as TabsEnum;
    this.setTabUrl(tabName);
    if (tabName === TabsEnum.history) {
      this.fetchHistory();
    }
  }

  private setSelectedTab(tabName: TabsEnum): void {
    this.selectedTab = tabName;
  }

  private setTabUrl(tabName: TabsEnum): void {
    // update the URL to support the org selector ecoId part of the URL
    const ecoIdRegex = /[A-Z0-9]{26}/;
    const urlParts = window.location.pathname.split('/').filter((part) => !ecoIdRegex.test(part));
    if (this.activeOrganization) {
      // insert the organizationEcoId (ex: respond/{organizationEcoId}/...)
      urlParts.splice(2, 0, this.activeOrganization.organization_eco_id);
    }
    const newPath = urlParts.join('/');
    const queryParams = `?tab=${tabName}`;
    const url = newPath + queryParams;
    this.location.replaceState(url);
  }

  private initDefaultValues(): void {
    this.contacts = [];
    this.escalationLists = [];
    this.totoLists = [];
    this.parameters = {
      businessHours: {
        fromHour: 0,
        fromMinute: 0,
        toHour: 0,
        toMinute: 0,
        timezone: "America/Toronto",
      },
      escalationDelays: {
        useOffHours: false,
        delays: [
          {
            priority: PriorityEnum.P1,
            businessHours: {},
            offHours: {},
          },
          {
            priority: PriorityEnum.P2,
            businessHours: {},
            offHours: {},
          },
          {
            priority: PriorityEnum.P3,
            businessHours: {},
            offHours: {},
          },
          {
            priority: PriorityEnum.P4,
            businessHours: {},
            offHours: {},
          },
        ],
      },
    };
  }

  private initDefaultSelectedTabFromURL(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams.tab) {
      const tabName = queryParams.tab as TabsEnum;
      this.setSelectedTab(tabName);
    }
  }

  private fetchEscalationLists(): void {
    this.isLoadingEscalationLists = true;
    const request: IListEscalationListsRequest = {
      organizationId: this.organizationId,
    };
    this.communicationApi.listEscalationLists(request).subscribe(
      (response: ResponseUtils<IEscalationList>) => {
        this.escalationLists = response.getItems();
        this.isLoadingEscalationLists = false;
      },
      (error) => {
        this.noticeService.error("respond.communication.fetch.escalationList.error");
      }
    );
  }

  private fetchContacts(): void {
    this.isLoadingContacts = true;
    const request: IListContactsRequest = {
      organizationId: this.organizationId,
    };
    this.communicationApi.listContacts(request).subscribe(
      (response: ResponseUtils<ICommunicationContact>) => {
        this.contacts = response.getItems();
        this.isLoadingContacts = false;
      },
      (error) => {
        this.noticeService.error("respond.communication.fetch.contacts.error");
      }
    );
  }

  private fetchParameters(): void {
    this.isLoadingParameters = true;
    const request: IListEscalationParametersRequest = { organizationId: this.organizationId };
    this.communicationApi.listEscalationParameters(request).subscribe(
      (response) => {
        this.parameters = response;
        this.isLoadingParameters = false;
      },
      (error) => {
        this.noticeService.error("respond.communication.fetch.settings.error");
      }
    );
  }

  private fetchPlaybooks(): void {
    this.isLoadingPlaybooks = true;
    this.playbooksApi.getPlaybooks(this.organizationId)
      .then((response) => {
        this.playbooks = response.items.map((item) => item.playbook);
        this.isLoadingPlaybooks = false;
      })
      .catch((error) => {
        this.noticeService.error("respond.communication.fetch.playbooks.error");
      });
  }

  private fetchHistory(isLoadMore: boolean = false): void {
    if (!this.showHistoryTab) {
      return;
    }
    this.isLoadingHistory = true;
    const shouldUseLoadMore = isLoadMore && this.historyResponse?.canLoadMore();

    const request: IListEscalationHistoryRequest = {
      size: shouldUseLoadMore ? 50 : 10,
      orderBy: ListHistoryOrderBy.createdAt,
      direction: RequestDirection.desc,
      organizationId: this.organizationId,
      from: shouldUseLoadMore ? this.historyResponse.getNextItem() : 0,
    };

    this.historyApi.listEscalationHistory(request).subscribe(
      (response) => {
        this.historyResponse = response;
        this.history = shouldUseLoadMore ? [ ...this.history, ...response.getItems() ] : response.getItems();
        this.isLoadingHistory = false;
      },
      (error) => {
        this.noticeService.error("respond.communication.fetch.history.error");
      }
    );
  }

  private async fetchConnectors(): Promise<void> {
    this.isLoadingConnectors = true;
    this.connectors = [];
    try {
      const orgConnectorsResponse = await this.connectorOrgService.getConnectors(this.organizationId, { lang: this.i18n.currentLocale });
      orgConnectorsResponse.connectors.forEach((connector) => {
        if (!this.connectors.find((c) => c.connectorId === connector.id)) {
          let connectorType = null;
          for (const connectorTypeKey of Object.keys(DataConnectorTypesCatalog)) {
            if (DataConnectorTypesCatalog[connectorTypeKey]?.id === connector.id) {
              connectorType = connectorTypeKey;
            }
          }
          this.connectors.push({
            connectorId: connector.id,
            displayName: connector.translations.name,
            datasourceType: connectorType as DataConnectorTypes,
            icon: this.connectorMappingHelper.getConnectorIcon(connector.id),
          });
        }
      });
      this.connectors.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.isLoadingConnectors = false;
    } catch (error) {
      console.log(error);
      this.noticeService.error("respond.communication.fetch.connectors.error");
    }
  }

  private fetchAll(): void {
    this.fetchEscalationLists();
    this.fetchContacts();
    this.fetchParameters();
    this.fetchPlaybooks();
    this.fetchConnectors();
    this.fetchHistory();
  }

  private createContact(contact: ICommunicationContact): void {
    const request: ICreateContactRequest = {
      organizationId: this.organizationId,
      name: contact.name,
      position: contact.position,
      communicationMethods: contact.communicationMethods,
    };

    this.communicationApi.createContact(request).subscribe(
      (response: ICommunicationContact) => {
        this.contacts = this.contacts.map((contact) => !contact.id ? response : contact);
        this.noticeService.success("respond.communication.create.contact.success");
      },
      (error) => {
        this.noticeService.error("respond.communication.create.contact.error");
      }
    );
  }

  private updateContact(contact: ICommunicationContact): void {
    const request: IUpdateContactRequest = {
      id: contact.id,
      organizationId: this.organizationId,
      name: contact.name,
      position: contact.position,
      communicationMethods: contact.communicationMethods,
    };

    this.communicationApi.updateContact(request).subscribe(
      (response: ICommunicationContact) => {
        this.contacts = this.contacts.map((contact) => contact.id === response.id ? response : contact);
        this.noticeService.success("respond.communication.update.contact.success");
      },
      (error) => {
        this.noticeService.error("respond.communication.update.contact.error");
      }
    );
  }

  private deleteContact(contact: ICommunicationContact): void {
    if (!contact.id) {
      this.contacts = this.contacts.filter((c) => c !== contact);
      return;
    }

    const request = {
      organizationId: this.organizationId,
      contactId: contact.id,
    };

    this.communicationApi.deleteContact(request).subscribe(
      (response: ICommunicationContact) => {
        this.contacts = this.contacts.filter((c) => c.id !== contact.id);
        this.noticeService.success("respond.communication.delete.contact.success");
      },
      (error) => {
        this.noticeService.error("respond.communication.delete.contact.error");
      }
    );
  }

  private createEscalationList(escalationList: IEscalationList): void {
    const request: ICreateEscalationListRequest = {
      organizationId: this.organizationId,
      name: escalationList.name,
      type: escalationList.type,
      ownerContactId: escalationList.ownerContactId,
      datasourceTypes: escalationList.datasourceTypes,
      playbookIds: escalationList.playbookIds,
      communicationMethods: this.convertEscalationLevelsToSaveRequest(escalationList),
    };

    this.communicationApi.createEscalationList(request).subscribe(
      (response: IEscalationList) => {
        this.escalationLists = this.escalationLists.map((list) => !list.id ? response : list);
        this.noticeService.success("respond.communication.create.escalationList.success");
      },
      (error) => {
        this.noticeService.error("respond.communication.create.escalationList.error");
      }
    );
  }

  private updateEscalationList(escalationList: IEscalationList): void {
    const request: IUpdateEscalationListRequest = {
      id: escalationList.id,
      organizationId: this.organizationId,
      name: escalationList.name,
      type: escalationList.type,
      ownerContactId: escalationList.ownerContactId,
      datasourceTypes: escalationList.datasourceTypes,
      playbookIds: escalationList.playbookIds,
      communicationMethods: this.convertEscalationLevelsToSaveRequest(escalationList),
    };

    this.communicationApi.updateEscalationList(request).subscribe(
      (response: IEscalationList) => {
        console.log(response);
        this.escalationLists = this.escalationLists.map((list) => list.id === response.id ? response : list);
        this.noticeService.success("respond.communication.update.escalationList.success");
      },
      (error) => {
        this.noticeService.error("respond.communication.update.escalationList.error");
      }
    );
  }

  private deleteEscalationList(escalationList: IEscalationList): void {
    if (!escalationList.id) {
      this.escalationLists = this.escalationLists.filter((list) => list !== escalationList);
      return;
    }

    const request = {
      organizationId: this.organizationId,
      escalationContactListId: escalationList.id,
    };

    this.communicationApi.deleteEscalationList(request).subscribe(
      (response: IEscalationList) => {
        this.escalationLists = this.escalationLists.filter((list) => list.id !== escalationList.id);
        this.noticeService.success("respond.communication.delete.escalationList.success");
      },
      (error) => {
        this.noticeService.error("respond.communication.delete.escalationList.error");
      }
    );
  }

  private updateParametersBusinessHours(businessHours: BusinessHours): void {
    const request: IUpdateBusinessHoursRequest = {
      id: businessHours.id,
      organizationId: this.organizationId,
      fromHour: businessHours.fromHour,
      fromMinute: businessHours.fromMinute,
      toHour: businessHours.toHour,
      toMinute: businessHours.toMinute,
      timezone: businessHours.timezone,
    };

    this.communicationApi.updateEscalationBusinessHours(request).subscribe(
      (response) => {
        this.parameters = { ...this.parameters, businessHours: response };
        this.noticeService.success("respond.communication.update.settings.success");
      },
      (error) => {
        this.noticeService.error("respond.communication.update.settings.error");
      }
    );
  }

  private updateParametersEscalationDelays(delays: EscalationDelayByPriority[]): void {
    const request: IUpdateEscalationDelaysRequest = {
      id: this.parameters.escalationDelays.id,
      organizationId: this.organizationId,
      useOffHours: !!this.parameters.escalationDelays.useOffHours,
      delays: pruneObject(delays),
    };

    this.communicationApi.updateEscalationDelays(request).subscribe(
      (response) => {
        this.parameters = { ...this.parameters, escalationDelays: response };
        this.noticeService.success("respond.communication.update.settings.success");
      },
      (error) => {
        this.noticeService.error("respond.communication.update.settings.error");
      }
    );
  }

  private convertEscalationLevelsToSaveRequest(escalationList: IEscalationList): EscalationListCommunicationMethodRequest[] {
    let communicationMethods: EscalationListCommunicationMethodRequest[] = [];

    escalationList.escalationLevels?.forEach((escalationLevel: IEscalationLevelForDisplay) => {
      escalationLevel.contacts.forEach((contact: IEsclationListContact) => {
        let contactCommunicationMethodIds: string[] = [];
        let priorities: number[] = [];
        let withPriorities: boolean = false;

        contact.selectedCommunicationMethods.forEach((method) => {
          contactCommunicationMethodIds.push(method.id);

          if (contact.withPriorities !== undefined && contact.withPriorities && contact.priorities !== undefined){
            withPriorities = contact.withPriorities;
            priorities = contact.priorities;
          }
        });

        communicationMethods.push({
          level: escalationLevel.level,
          contactCommunicationMethodIds,
          priorities,
          withPriorities
        });
      });
    });
    return communicationMethods;
  }
}
