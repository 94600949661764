<div class="home-container">
    <h2 class="title"> {{ 'dashboard.welcome.title' | t }}</h2>
    <h3>
        {{'dashboard.welcome.userInfo' | t:{userName:userName || "", organizationName:organizationName || ""} }}
    </h3>
    <p class="paragraph">
        {{'dashboard.welcome.instructions' | t}}
        <br>
        <br>
        <span>{{'dashboard.welcome.instructionsNoTabs' | t}}</span>
    </p>
</div> 

