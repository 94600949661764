import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrganizationsApiService } from '@common/services/api/sg/organizations/organizationsApi.service';
import { Select, Store } from '@ngxs/store';
import { FontSizeTypeEnum } from '@ui-kit/components/ui-checkbox/interface/font-size-type.enum';
import { HideDialog } from '@ui-kit/components/ui-dialog/stores/ui-dialog.store';
import { DialogActions } from '@ui-kit/components/ui-dialog/ui-dialog.component';
import { Eco } from 'projects/@common/definitions/eco';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { CookInedibleSnack } from 'projects/@common/modules/notice/implementations/snackbar/state/snackbar.state';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ResourceSharesApiService } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares-api.service';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { IFilesTypesNumbers, ISharedFilesRevokeConditions } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { DashboardProfileService } from 'projects/@common/services/dashboard-profile.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { Observable } from 'rxjs';
import { RevokeTypeEnum } from '../../revoke-shares-dialog/revoke-shares-dialog.component';

@Component({
  selector: 'batch-revoke',
  templateUrl: './batch-revoke.component.html',
  styleUrls: ['./batch-revoke.component.scss'],
})
export class BatchRevokeComponent implements OnInit, OnChanges {
  @Input() public revokeHighRiskDialog = false;
  @Input() public showBatchRevokeDialog = false;
  @Input() public resourceId: string;
  @Input() public userId: string;
  @Input() public isFromQuickActions: boolean = false;
  @Input() public actionType: RevokeTypeEnum;
  @Select(EcoSessionState.organization) public organization$: Observable<Eco.IOrganization>;
  @Output() public showBatchRevokeDialogChange: EventEmitter<boolean> = new EventEmitter();
  @Output() public revokeHighRiskDialogChange: EventEmitter<boolean> = new EventEmitter();
  public isLoadingTypesNumbers = true;
  public isRevoking = false;
  public batchRevokeForm: UntypedFormGroup;
  public sharesNumbersPerType: IFilesTypesNumbers = {};
  public organizationName: string;
  public yesterdayDate = new Date();
  public showBatchRevokeConfirmationDialog = false;
  public dialogActions = DialogActions;
  public fontSize = FontSizeTypeEnum.SMALL;
  public includeSilenced = false;
  public silencingOptions: string[];

  constructor(
    public mobileService: MobileService,
    public i18n: I18nService,
    private store: Store,
    private fb: UntypedFormBuilder,
    private sharesService: SharesApiService,
    private resourceSharesService: ResourceSharesApiService,
    private noticeService: NoticeService,
    private organizationsApiService: OrganizationsApiService,
    private dashboardProfileService: DashboardProfileService
  ) {
  }

  public get formControls(): string[] {
    return [
      'riskHigh',
      'riskModerate',
      'publicShares',
      'organizationalShares',
      'groupShares',
      'guestShares',
      'memberShares',
      'sharedSince',
      'inactiveSince',
    ];
  }

  private static atLeastOneIsActivated(formGroup: UntypedFormGroup) {
    const controls = formGroup.controls;

    const atLeastOneIsActivated = Object.keys(formGroup.controls)
      .filter((controlKey) => !controlKey.includes('Date'))
      .find((controlKey) => controls[controlKey].value === true);

    if (!atLeastOneIsActivated) {
      return { invalidAtLeastOneIsActivated: true };
    }

    return null;
  }

  public ngOnInit() {
    this.createBatchRevokeForm();
    this.organization$.subscribe((organization) => this.organizationName = organization.name);
    this.organizationsApiService.getOrganizationSettings().then((response) => this.silencingOptions = response.silencingOptions);
    if (this.actionType === RevokeTypeEnum.EXPRESS_REVOKE) {
      this.batchRevokeForm.patchValue({ riskHigh: true, riskModerate: true });
      this.showBatchRevokeDialog = false;
      this.showBatchRevokeConfirmationDialog = true;
    }
  }

  public ngOnChanges() {
    if (this.showBatchRevokeDialog || this.revokeHighRiskDialog) {
      this.isLoadingTypesNumbers = true;
      this.getData({ includeSilenced: this.includeSilenced });
    }
  }


  public onRevokeFilesForm(event: DialogActions) {
    if (event === DialogActions.ACTION) {
      this.showBatchRevokeDialog = false;
      this.showBatchRevokeConfirmationDialog = true;
      if (this.batchRevokeForm.value.sharedSince || this.batchRevokeForm.value.inactiveSince) {
        this.isLoadingTypesNumbers = true;

        const inactiveSince = this.batchRevokeForm.value.inactiveSince
          ? (new Date(this.batchRevokeForm.value.inactiveSinceDate) as any).getTime()
          : null;
        const sharedSince = this.batchRevokeForm.value.sharedSince
          ? (new Date(this.batchRevokeForm.value.sharedSinceDate) as any).getTime()
          : null;

        this.getData({
          sharedSince,
          inactiveSince,
          includeSilenced: this.includeSilenced,
        });
      }
    } else {
      this.createBatchRevokeForm();
      this.closeRevokeDialog();
    }
  }

  public onRevokeFilesConfirmation(event: DialogActions) {
    if (event === DialogActions.ACTION) {
      this.revokeFiles();
    } else if (this.revokeHighRiskDialog) {
      this.closeRevokeDialog();
    } else {
      this.showBatchRevokeDialog = true;
      this.showBatchRevokeConfirmationDialog = false;
      this.includeSilenced = false;
      this.isLoadingTypesNumbers = true;
      this.getData({ includeSilenced: this.includeSilenced });
    }
  }

  public revokeFiles() {
    this.isRevoking = true;

    const batchRevokeValues: ISharedFilesRevokeConditions = this.batchRevokeForm.value;

    batchRevokeValues.inactiveSince = batchRevokeValues.inactiveSince
      ? (new Date(this.batchRevokeForm.value.inactiveSinceDate) as any).getTime()
      : null;

    batchRevokeValues.sharedSince = batchRevokeValues.sharedSince
      ? (new Date(this.batchRevokeForm.value.sharedSinceDate) as any).getTime()
      : null;

    batchRevokeValues.includeSilenced = this.includeSilenced;

    delete batchRevokeValues['inactiveSinceDate'];
    delete batchRevokeValues['sharedSinceDate'];

    if (!this.userId && !this.resourceId) {
      this.sharesService
        .revokeMySharesInBatch(batchRevokeValues, this.actionType)
        .then(() => {
          this.closeRevokeDialog();
          this.noticeService.notifyUser(new Notice('shares.dialog.batchRevoke.confirmation.success.message', NoticeLevels.SUCCESS));

          if (this.isFromQuickActions) {
            this.dashboardProfileService.getDashboardUserProfile();
          }
        })
        .catch(() => {
          this.isRevoking = false;
          this.store.dispatch(new CookInedibleSnack('shares.dialog.batchRevoke.confirmation.error.message'));
        });
    } else {
      this.sharesService
        .revokeResourceSharedFilesByConditions(this.resourceId, batchRevokeValues, this.actionType)
        .then(() => {
          this.closeRevokeDialog();
          this.noticeService.notifyUser(new Notice('shares.dialog.batchRevoke.confirmation.success.message', NoticeLevels.SUCCESS));

          if (this.isFromQuickActions) {
            this.dashboardProfileService.getDashboardUserProfile();
          }
        })
        .catch(() => {
          this.isRevoking = false;
          this.store.dispatch(new CookInedibleSnack('shares.dialog.batchRevoke.confirmation.error.message'));
        });
    }
  }

  public convertDateToTimestamp(calendarDate: number): number {
    return new Date(calendarDate).getTime();
  }

  private getData(params?: { sharedSince?: number, inactiveSince?: number; includeSilenced?: boolean; }) {
    if (!this.userId && !this.resourceId) {
      this.sharesService.getMyFilesSharesTypesNumbers(params)
        .then((sharesNumbersPerType) => this.init(sharesNumbersPerType))
        .finally(() => this.isLoadingTypesNumbers = false);
    } else {
      this.resourceSharesService.getResourceFilesSharesTypesNumbers(this.resourceId, params)
        .then((sharesNumbersPerType) => this.init(sharesNumbersPerType))
        .finally(() => this.isLoadingTypesNumbers = false);
    }
  }

  private init(sharesNumbersPerType: IFilesTypesNumbers) {
    this.sharesNumbersPerType = sharesNumbersPerType;
    if (this.revokeHighRiskDialog) {
      this.batchRevokeForm.patchValue({ riskHigh: true });
      this.onRevokeFilesForm(DialogActions.ACTION);
    }
  }

  public get willRevokeSomeShares(): boolean {
    return this.formControls.some((controlName) => this.batchRevokeForm?.get(controlName).value && this.sharesNumbersPerType[controlName] > 0);
  }

  private createBatchRevokeForm() {
    this.batchRevokeForm = this.fb.group({
      riskHigh: this.fb.control(false),
      riskModerate: this.fb.control(false),
      publicShares: this.fb.control(false),
      organizationalShares: this.fb.control(false),
      groupShares: this.fb.control(false),
      guestShares: this.fb.control(false),
      memberShares: this.fb.control(false),
      sharedSince: this.fb.control(false),
      sharedSinceDate: this.fb.control(null),
      inactiveSince: this.fb.control(false),
      inactiveSinceDate: this.fb.control(null),
    });
    this.batchRevokeForm.get('sharedSinceDate').disable();
    this.batchRevokeForm.get('inactiveSinceDate').disable();

    this.batchRevokeForm.setValidators(BatchRevokeComponent.atLeastOneIsActivated);
    this.batchRevokeForm.updateValueAndValidity();

    this.batchRevokeForm.get('sharedSince').valueChanges.subscribe((value) => {
      if (value) {
        this.batchRevokeForm.get('sharedSinceDate').setValidators([Validators.required]);
        this.batchRevokeForm.get('sharedSinceDate').enable();
      } else {
        this.batchRevokeForm.get('sharedSinceDate').setValidators([]);
        this.batchRevokeForm.get('sharedSinceDate').disable();
      }
      this.batchRevokeForm.get('sharedSinceDate').updateValueAndValidity();
      this.batchRevokeForm.updateValueAndValidity();
    });

    this.batchRevokeForm.get('inactiveSince').valueChanges.subscribe((value) => {
      const control = this.batchRevokeForm.get('inactiveSinceDate');
      if (value) {
        control.setValidators([Validators.required]);
        control.enable();
      } else {
        control.setValidators([]);
        control.disable();
      }
      control.updateValueAndValidity();
      this.batchRevokeForm.updateValueAndValidity();
    });
  }

  private closeRevokeDialog() {
    this.isRevoking = false;
    this.isLoadingTypesNumbers = false;
    this.showBatchRevokeDialog = false;
    this.showBatchRevokeConfirmationDialog = false;
    this.revokeHighRiskDialog = false;
    this.showBatchRevokeDialogChange.emit(this.showBatchRevokeDialog);
    this.revokeHighRiskDialogChange.emit(this.revokeHighRiskDialog);
    this.store.dispatch(new HideDialog());
  }

  public handleIncludeSilencedShares(check: boolean): void {
    this.includeSilenced = check;
    this.isLoadingTypesNumbers = true;

    const inactiveSince = this.batchRevokeForm.value.inactiveSince
      ? (new Date(this.batchRevokeForm.value.inactiveSinceDate) as any).getTime()
      : null;

    const sharedSince = this.batchRevokeForm.value.sharedSince
      ? (new Date(this.batchRevokeForm.value.sharedSinceDate) as any).getTime()
      : null;

    this.getData({
      includeSilenced: check,
      sharedSince,
      inactiveSince,
    });
  }
}
