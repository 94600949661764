import { Component, Input, OnInit } from '@angular/core';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { IUsecase } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { Whitelist } from 'projects/@common/services/api/respond/whitelists/models/Whitelist';
import { WhitelistsApi } from 'projects/@common/services/api/respond/whitelists/whitelists.api';
import { WhitelistsTableColumnEnum } from 'projects/@respond/whitelists/components/whitelists-table/whitelists-table.columns-config';
import { IWhitelistDrawerData, RespondWhitelistDrawerContainer } from 'projects/@respond/whitelists/containers/respond-whitelist-drawer/respond-whitelist-drawer.container';

@Component({
  selector: 'app-usecase-whitelist',
  templateUrl: './usecase-whitelist.component.html',
  styleUrls: [ './usecase-whitelist.component.scss' ],
})
export class UsecaseWhitelistComponent implements OnInit {
  public readonly usecaseWhitelistsTableColumnsDefinition;

  @Input() usecase: IUsecase;
  public usecaseWhitelists: Whitelist[] = [];
  public isLoading: boolean = false;

  constructor(
    public readonly i18n: I18nService,
    private readonly whitelistsApi: WhitelistsApi,
    private readonly drawerService: DrawerService
  ) {
    this.usecaseWhitelistsTableColumnsDefinition = [
      {
        label: 'whitelists.table.column.name',
        field: WhitelistsTableColumnEnum.name,
        type: UiStaticTableRowType.TEXT,
        noSorting: false,
        isResizable: true,
      },
      {
        label: 'whitelists.table.column.startsAt',
        field: WhitelistsTableColumnEnum.startsAt,
        type: UiStaticTableRowType.DATE_AND_HOUR,
        noSorting: false,
        isResizable: true,
      },
      {
        label: 'whitelists.table.column.endsAt',
        field: WhitelistsTableColumnEnum.endsAt,
        type: UiStaticTableRowType.DATE_AND_HOUR,
        noSorting: false,
        isResizable: true,
      },
    ];
  }

  ngOnInit(): void {
    const request = {
      usecaseId: this.usecase.id,
      organizationId: this.usecase.organizationId,
    };
    this.isLoading = true;
    this.whitelistsApi.listWhitelists(request).toPromise()
      .then((response) => {
        this.usecaseWhitelists = response.getItems().map(((representation) => new Whitelist(representation)));
      })
      .finally(() => this.isLoading = false);
  }

  public openWhitelistDetailsDrawer(whitelist: Whitelist): void {
    const drawerInputData: IWhitelistDrawerData = {
      title: whitelist ? whitelist.name : this.i18n.translate(`whitelists.drawer.title.create`),
      whitelistId: whitelist ? whitelist.id : null,
      organizationId: whitelist.organizationId,
      isReadonly: false,
    };
    this.drawerService.showDrawer(RespondWhitelistDrawerContainer, {
      component: DrawerPageEnum.whitelistDrawer,
      data: drawerInputData,
    });
  }
}
