<app-incident-details-base-layout>
  <div
    class="section-container"
    *ngIf="alertsResponse; else loadingSpinner"
  >
    <div class="alertstopbar">
      <ui-toggle
        class="findings-show-json-view-toggle"
        [labelEnabled]="'alert.finding.toggleJsonView' | t"
        [labelDisabled]="'alert.finding.toggleJsonView' | t"
        [toggled]="!!showJsonView"
        [disabledWhenUntoggled]="false"
        (onClick)="toggleJsonView()"
      ></ui-toggle>
    </div>

    <ui-static-table
      sort="createdAt"
      direction="desc"
      [dataSource]="alertsTableData"
      [columnsDef]="columnsDef"
      [loading]="isLoadingAlert"
      [locale]="i18n.currentLocale"
      [expendedTemplate]="expandedTableRowTemplate"
      [emitClickEventOnExpandedRow]="true"
      [addPaddingOnFirst]="false"
    >
    </ui-static-table>
    <div
      class="button-container"
      *ngIf="canLoadMore()"
    >
      <ui-table-refresh-tool
        class="refresher"
        [loading]="isLoadingHistory"
        [text]="'common.showMore' | t"
        rightSide
        (click)="loadMoreAlerts()"
      ></ui-table-refresh-tool>
    </div>
  </div>
</app-incident-details-base-layout>

<ng-template
  #expandedTableRowTemplate
  let-alert="row"
  let-isExpanded="isSelect"
>
  <div
    *ngIf="isExpanded"
    class="alert-row-expanded-container"
  >
    <ui-field-group
      [layout]="'inline'"
      [hasMarginBottom]="true"
    >
      <short-id
        class="width-100"
        [label]="'alert.detail.Id.label' | t"
        [shortId]="alert.shortId"
        [linkURL]="showAlertLink ? alert.linkURL : null"
        [linkQueryParams]="alert.linkQueryParams"
        [linkOpenExternal]="true"
      ></short-id>
      <ui-static
        [label]="'common.organization' | t"
        [value]="alert.organizationName"
      ></ui-static>
    </ui-field-group>

    <ui-field-group
      [layout]="'inline'"
      [hasMarginBottom]="true"
    >
      <short-id
        class="width-100"
        [label]="'alert.detail.usecaseShortId.label' | t"
        [shortId]="alert.templateId || '-'"
      ></short-id>
      <ui-static
        [label]="'alert.detail.connector.label' | t"
        [value]="alert.connector || '-'"
      >
      </ui-static>
    </ui-field-group>

    <section
      *ngIf="alert?.whitelisted"
      class="border-box-container"
    >
      <ui-collapsable-card
        [cardTitle]="'alert.whitelisted.label' | t"
        [badges]="[]"
        [headerIcon]=""
        [isBigHeaderImage]="false"
        [isCollapsed]="true"
      >
        <pre
          *ngIf="showJsonView"
          class="json-content"
        >{{stringifyAlertWhitelist(alert) | jsonPrettier:4}}</pre>

        <json-table
          *ngIf="!showJsonView"
          [jsonString]="stringifyAlertWhitelist(alert)"
        ></json-table>
      </ui-collapsable-card>
    </section>

    <section class="border-box-container">
      <ng-container *ngIf="alert?.findings !== null; else loadingSpinner">
        <ui-collapsable-card
          *ngFor="let finding of alert?.findings; trackBy: findingRowsTrackById"
          style="margin-bottom: 3px; display: block;"
          [cardTitle]="'alert.finding.label' | t:{monitor:finding.monitorName}"
          [badges]="[ finding.createdAtBadge ]"
          [headerIcon]=""
          [isBigHeaderImage]="false"
          [isCollapsed]="true"
          [tooltipText]="finding.monitorDescription | translatedObject"
          [tooltipPositionBottom]="true"
          (onToggleCollapse)="finding.isCollapsed = $event"
        >
          <ng-container *ngIf="!finding.isCollapsed">
            <ng-template
              [ngTemplateOutlet]="jsonViewTemplate"
              [ngTemplateOutletContext]="{ $implicit: finding }"
            ></ng-template>
          </ng-container>
        </ui-collapsable-card>
      </ng-container>
    </section>
  </div>
</ng-template>

<ng-template
  #jsonViewTemplate
  let-finding
>
  <json-sorted-view
    *ngIf="showJsonView;"
    [jsonString]="finding.content"
  ></json-sorted-view>
  <json-table
    *ngIf="!showJsonView"
    [jsonString]="finding.content"
    [formatDate]="true"
  ></json-table>
</ng-template>

<ng-template #loadingSpinner>
  <ui-spinner size="medium"></ui-spinner>
</ng-template>