import { RuleSet } from "angular2-query-builder";
import { IFilterRepresentation, IFilterUsecases } from "../filters.definitions";
import { FilterPeriod } from "projects/@respond/filters/filter-periods.service";


export class Filter implements IFilterRepresentation {
  id: string;
  organizationId: string;
  organizationName: string;
  organizationEcoId: string;
  usecases: IFilterUsecases[];
  name: string;
  description: string;
  createdById: string;
  createdByName: string;
  createdByOrganization: boolean;
  createdAt: number;
  conditions?: RuleSet;
  periods?: FilterPeriod[];
  monitors: string[];
  hidden: boolean;

  constructor(parameters?: IFilterRepresentation) {
    if (!parameters) {
      // eslint-disable-next-line no-param-reassign
      parameters = {
        id: null,
        usecases: [],
        monitors: [],
        organizationId: null,
        organizationName: null,
        organizationEcoId: null,
        name: null,
        description: null,
        createdById: null,
        createdByName: null,
        createdByOrganization: null,
        createdAt: null,
        conditions: null,
        periods: null,
        hidden: false,
      };
    }

    Object.assign(this, parameters);
  }

  public get usecaseIds(): string[] {
    return this.usecases.map((usecase) => usecase.id);
  }

  public get now(): number {
    return Date.now();
  }
}
