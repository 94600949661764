import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiEndpointPrefixEnum, EcoApi } from "@common/definitions/eco-api";
import { IRespondResponse } from "@common/definitions/IRespondResponse";
import { IEcoAlertFiltersApi } from "@common/services/api/respond/filters/ecoAlertFilter.definition";
import { ResponseUtils } from "@common/utils/response-utils";
import { UrlUtils } from "@common/utils/utils";
import { FieldMap } from "angular2-query-builder";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { ICreateFilterRequest, IDeleteFiltersRequest, IDeleteFiltersResponse, IDescribeFilterRequest, IDescribeFilterResponse, IFilterBaseRepresentation, IListFilterFieldsRequest, IListFiltersRequest, IUpdateFilterRequest } from "projects/@common/services/api/respond/filters/filters.definitions";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class VarEcoAlertFiltersApi extends EcoApi implements IEcoAlertFiltersApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  getFilterConditionFields(request?: IListFilterFieldsRequest): Promise<FieldMap> {
    const url = `${this.host}/organizations/${request.organizationId}/ecoAlertFilters/fields`;
    return this.http.get<FieldMap>(url).toPromise();
  }

  public describeFilter(request: IDescribeFilterRequest): Observable<IDescribeFilterResponse> {
    const url = `${this.host}/organizations/${request.organizationId}/ecoAlertFilters/${request.id}`;
    return this.http.get<IDescribeFilterResponse>(url);
  }

  public listFilters(organizationId: string, request: IListFiltersRequest): Observable<ResponseUtils<IFilterBaseRepresentation>> {
    const url = `${this.host}/organizations/${organizationId}/ecoAlertFilters?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IFilterBaseRepresentation>>(url)
      .pipe(map((response) => new ResponseUtils<IFilterBaseRepresentation>(response)));
  }

  public listOrganizationsFilters(request?: IListFiltersRequest): Observable<ResponseUtils<IFilterBaseRepresentation>> {
    const url = `${this.host}/ecoAlertFilters?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IFilterBaseRepresentation>>(url)
      .pipe(map((response) => new ResponseUtils<IFilterBaseRepresentation>(response)));
  }

  public createFilter(request: ICreateFilterRequest): Observable<IDescribeFilterResponse> {
    const url = `${this.host}/organizations/${request.organizationId}/ecoAlertFilters`;
    return this.http.post<IDescribeFilterResponse>(url, request);
  }

  public updateFilter(request: IUpdateFilterRequest): Observable<IDescribeFilterResponse> {
    const url = `${this.host}/organizations/${request.organizationId}/ecoAlertFilters/${request.id}`;
    return this.http.put<IDescribeFilterResponse>(url, request);
  }

  public deleteFilter(request: IDeleteFiltersRequest): Promise<IDeleteFiltersResponse> {
    const url = `${this.host}/organizations/${request.organizationId}/ecoAlertFilters/${request.id}`;
    return this.http.delete<IDeleteFiltersResponse>(url).toPromise();
  }
}
