<div
class="ui-table-filter-tool--trigger multi-select-trigger"
(click)="toggle()">
<button
  ui-button
  class="ui-table-filter-tool--button filter-button"
  color="linkBordedRegular"
  modifier="gray"
  custom-icon="true"
  isSvg="true"
  [ngStyle]="{'min-width': !mobileService.isMobile() ? '150px' : null}"
  [wide]="!mobileService.isMobile()"
  icon="icon_filter"
  >
  {{ !mobileService.isMobile() ? selectedOptionsTranslated  : defaultOptionTranslated }}
</button>
</div>

<div
  class="multi-select-options"
  [ngClass]="{ 'multi-select-options--left-orientation': leftOrientation }"
  *ngIf="showOptions"
  uiAccessibleChoiceListDirective
  [choices]="multiSelectData.options"
  (choiceSelectedEmitter)="checkOption($event.choice)"
>
  <!-- Filter content -->
  <div>

    <!-- Input text for filtering -->
    <div class="ui-table-search-tool--input-group">
      <img *ngIf="withIcon" class="ui-table-search-tool--icon" src="{{ 'assets/favicons/icon_search.svg' }}"/>
      <input
        class="ui-table-search-tool--input"
        type="text"
        placeholder="{{ placeholder }}"
        [(ngModel)]="filterText"
        (input)="filterOptions()"
      />
      <button class="ui-table-search-tool--clear"
              type="button"
              (click)="handleFilterTextClear()"
              *ngIf="filterText.length">
        <ui-icon
          class="ui-table-search-tool--clear-icon"
          name="close"
          modifier="OnHighlight"
        ></ui-icon>
      </button>
    </div>

    <!-- No results message -->
    <div *ngIf="!optionsForDisplay.length" class="multi-select-options--option no-results">
      {{ 'common.nomatch' | t : { locale: locale } }}
    </div>

    <!-- Filtered checkboxes list -->
    <div
      *ngFor="let option of optionsForDisplay; let first = first; let last = last"
      class="multi-select-options--option accessible-choice"

      [ngClass]="{
        'multi-select-options--option--first': first,
        'multi-select-options--option--last': last
      }"
      (click)="checkOption(option)">
      <div class="multi-select-options--option--checkbox">
        <ui-checkbox
          [isEditable]="true"
          [checked]="option.isSelected"></ui-checkbox>
      </div>

      {{ option.translated }}

    </div>

  </div>
  <!-- / Filter content -->

</div>

<ng-template #mobile>
  <div
    *ngFor="let option of optionsForDisplay"
    class="multi-select-options--mobile"
    (click)="checkOption(option)">
    <ui-checkbox
      [checked]="option.isSelected"
      [isEditable]="true"></ui-checkbox>

    {{ option.translated }}
  </div>
</ng-template>


