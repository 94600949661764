import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";

import { PlaybookTaskPhases } from "../../playbooks/playbooks.definitions";
import { CustomTaskAction, IIncidentTaskRepresentation, IInProgressPeriod, IncidentCustomTaskType, IncidentTaskStatus } from "../incidentTasks.definitions";

export class IncidentTaskAction implements IIncidentTaskRepresentation {
  id?: string;
  incidentId?: string;
  taskId: string;
  taskVersion: number;
  hidden: boolean;
  order: number;
  phase: PlaybookTaskPhases;
  status: IncidentTaskStatus;
  previousStatus: IncidentTaskStatus;
  comment: string;
  inProgressPeriods?: IInProgressPeriod[];
  playbookTask?: {
    name: ITranslatedField;
    description: ITranslatedField;
    instruction: ITranslatedField;
  };
  customTask?: {
    type: IncidentCustomTaskType;
    customActions: CustomTaskAction[];
  };

  constructor(parameters: IIncidentTaskRepresentation) {
    Object.assign(this, parameters);
  }

  public get isHidden(): boolean {
    return this.hidden;
  }

  public get name(): ITranslatedField {
    return this.playbookTask.name;
  }

  public set name(name: ITranslatedField) {
    this.playbookTask.name = name;
  }

  public get description(): ITranslatedField {
    return this.playbookTask.description;
  }

  public set description(description: ITranslatedField) {
    this.playbookTask.description = description;
  }

  public get instruction(): ITranslatedField {
    return this.playbookTask.instruction;
  }
  
  public set instruction(instruction: ITranslatedField) {
    this.playbookTask.instruction = instruction;
  }
}
