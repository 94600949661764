import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DatasourceFamilyDetailService } from '../services/datasource-family-detail.service';

@Injectable({
  providedIn: 'root',
})
export class DetectionGuardGuard implements CanActivate {
  constructor(private readonly datasourceFamilyDetailService: DatasourceFamilyDetailService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.datasourceFamilyDetailService.canNavigateToDetail()) {
      return true;
    }
    this.router.navigate([ 'detection/datasources' ]);
    return false;
  }
}
