import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DisplayService } from '@common/modules/display/display.service';
import { Snackbar } from '@common/modules/notice/implementations/snackbar/component/snackbar.component';
import { Login } from '@common/modules/session/state/session.state';
import { NotificationsApiService } from '@common/services/api/notifications/notifications-api.service';
import { Store } from '@ngxs/store';
import { AdmRespondSidebar } from 'projects/console-adm/src/configuration/adm.respond.sidebar.service';
import { OrgRespondSidebar } from 'projects/console-org/src/configuration/org.respond.sidebar.service';

@Component({
  templateUrl: './test-sign-in.component.html',
  styleUrls: ['./test-sign-in.component.scss'],
})
export class TestSignInComponent implements OnInit {
  @ViewChild('payload', { static: true }) private button: ElementRef;

  constructor(private router: Router, private displayService: DisplayService, private store: Store) {
  }

  public ngOnInit() {
    // Prepare some special tweaks for testing...
    Snackbar._hidesnack = true;
    OrgRespondSidebar._showCreateAlert = true; // Show the create alert option in the left menu
    AdmRespondSidebar._showCreateAlert = true; // Show the create alert option in the left menu
    
    // This service wont work in test environement
    NotificationsApiService.prototype.getUserIdentity = async (): Promise<any> => {
      return {
        confirmed: true
      };
    };
  }

  public async load() {
    const action = new Login(JSON.parse(this.button.nativeElement.value));
    await this.store.dispatch(action).toPromise();
    await this.displayService.loadPermissions();
    console.log(`Login completed, navigating to the application...`);
    return this.router.navigate(['/']);
  }
}
