<ng-container [ngSwitch]="columnDef.type">
  <div
    *ngSwitchCase="uiStaticTableRowType.TEXT"
    [ngClass]="columnDef.isTruncated ? 'truncate-text' : 'break-words'"
    [ngStyle]="{ 'max-width': '100%' }"
    [class.bold]="bold"
    [title]="columnDef.isTruncated ? row[columnDef.field] : ''"
  >
    {{ row[columnDef.field] != null ? row[columnDef.field] : '' }}
  </div>
  <div
    *ngSwitchCase="uiStaticTableRowType.TRANSLATED_TEXT"
    [ngClass]="columnDef.isTruncated ? 'truncate-text' : 'break-words'"
    [ngStyle]="{ 'max-width': '100%' }"
    [class.bold]="bold"
    [title]="
      columnDef.isTruncated
        ? row[columnDef.field + 'Translated']
        : ''
    "
  >
    {{
      row[columnDef.field] != null
        ? row[columnDef.field + 'Translated']
        : ''
    }}
  </div>
  <div
    *ngSwitchCase="uiStaticTableRowType.DEEP_1"
    [ngClass]="columnDef.isTruncated ? 'truncate-text' : 'break-words'"
    [ngStyle]="{ 'max-width': '100%' }"
    [class.bold]="bold"
    [title]="
      columnDef.isTruncated && row[columnDef.fields[0]]
        ? row[columnDef.fields[0]][columnDef.fields[1]]
        : ''
    "
  >
    {{
      row[columnDef.fields[0]] != null
        ? row[columnDef.fields[0]][columnDef.fields[1]]
        : ''
    }}
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.TOOLTIP">
    <ui-tooltip class="toolTipText--neutral-background"
                *ngIf="row[columnDef.field]"
                [isSmall]="columnDef.isSmall"
                [data]="columnDef.tooltipText"
                [icon]="columnDef.icon"
    ></ui-tooltip>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.TEXT_WITH_TOOLTIP">
    <div class="text-with-tooltip">
      {{ row[columnDef.field] }}
      <ui-tooltip
        *ngIf="columnDef.showTooltip"
        [isSmall]="columnDef.isSmall"
        [data]="columnDef.getRowTooltip(row)"
        [icon]="columnDef.icon"
        [width]="columnDef.tooltipWidth"
      ></ui-tooltip>
    </div>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.DEEP_1_WITH_TOOLTIP">
    <div
      class="text-with-tooltip"
      *ngIf="row[(columnDef?.fields)[0]][(columnDef?.fields)[1]]"
    >
      {{ row[(columnDef?.fields)[0]][(columnDef?.fields)[1]] }}
      <ui-tooltip
        *ngIf="row[columnDef.tooltip]"
        [isSmall]="columnDef.isSmall"
        [icon]="columnDef.icon"
      >{{ row[columnDef.tooltip] }}
      </ui-tooltip
      >
    </div>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.CONCATENATED_VALUES">
    {{
      row[columnDef.field] +
      (columnDef.customSpace ? columnDef.customSpace : ' ') +
      row[columnDef.field2]
    }}
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.ENUM" [class.bold]="bold">
    {{ row[columnDef.field + 'Translated'] }}
  </div>
  <div
    #functionTypeRowElement
    *ngSwitchCase="uiStaticTableRowType.FUNCTION"
    [ngClass]="columnDef.isTruncated ? 'truncate-text' : 'break-words'"
    [title]="columnDef.isTruncated ? dynamicTruncatedTooltip(functionTypeRowElement, columnDef.func(row[columnDef.field])) : ''"
  >
    {{ columnDef.func(row[columnDef.field]) }}
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.PIPED_ROW">
    {{ row | dynamicPipe: columnDef.pipe:columnDef.pipeParams }}
  </div>
  <div
    *ngSwitchCase="uiStaticTableRowType.PIPED_FIELD"
    [ngStyle]="{ color: columnDef.color }"
  >
    {{
      (row[columnDef.field]
        | dynamicPipe: columnDef.pipe:columnDef.pipeParams) ||
      ((columnDef.defaultContent | t: { locale: locale }) || '')
    }}
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.EXPENDABLE_ICON">
    <div class="expendable-arrow-icon">
      <ui-icon
        [ngClass]="__isIconShowing ? 'showIcon' : 'hide'"
        class="'open-icon'"
        modifier="on-header"
        [name]="row.__selected ? 'chevron-down' : 'chevron-right'"
        size="medium"
      ></ui-icon>
    </div>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.RESOURCE_AVATAR" class="icon-column">
    <ui-avatar-resource
      [resourceType]="row[columnDef.resourceType] || columnDef.resourceType"
      [groupType]="row[columnDef.groupType]"
      [size]="columnDef?.size ? columnDef.size : 'small'"
    ></ui-avatar-resource>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.AVATAR">
    <ui-avatar
      [user]="row[columnDef.field]"
      [size]="columnDef.avatarSize || (smallRow ? 'tiny' : 'small')"
    ></ui-avatar>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.AVATARS">
    <ui-avatars
      [users]="row[columnDef.field]"
      [size]="columnDef.avatarSize || (smallRow ? 'tiny' : 'small')"
    ></ui-avatars>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.ENUM_WITH_ICON">
    <ui-static
      [label]="''"
      [value]="row[columnDef.field + 'Translated']"
      [iconName]="columnDef.iconName(row)"
      [iconModifier]="columnDef.iconColor ? columnDef.iconColor(row) : ''"
      [iconSide]="columnDef.iconSide"
      [isCustomIcon]="columnDef.customIcon"
      [isSvg]="columnDef.isSvg"
      [iconSize]="columnDef.iconSize"
    ></ui-static>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.DYNAMIC_COMPONENT_HOST" [class.display-none]="columnDef.showWhenHover === true && hoveredRow !== row">
    <dynamic-component [dynamicComponent]="dynamicComponentParam"></dynamic-component>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.DYNAMIC_COMPONENT_DEEP_1">
    <dynamic-component
      [dynamicComponent]="dynamicComponentParam"
    ></dynamic-component>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.CHECKBOX" (click)="$event.stopPropagation()">
    <ng-container *ngIf="!row.isPending">
      <div class="checkbox" [class.row-checkbox-is-selected]="row[columnDef.selected]"
           *ngIf="!columnDef.specialCheckbox">
        <ui-checkbox
          *ngIf="(checkboxVisible && !checkboxDisabled && hoveredRow === row) || row[columnDef.selected]"
          [isEditable]="true"
          [checked]="row[columnDef.selected]"
          (onCheck)="checkboxEvent($event, row, columnDef.selected)"
        ></ui-checkbox>
        <div
          *ngIf="(checkboxVisible && checkboxDisabled && hoveredRow === row)"
          class="checkbox-custom-disabled"
          [tooltip]="columnDef.checkboxDisabledTooltip |t:{locale:locale}:''"
        ></div>
      </div>
      <div
        *ngIf="columnDef.specialCheckbox"
        (click)="
        columnDef.useDefaultCheckboxEvent
          ? checkboxEvent($event, row, columnDef.selected)
          : specialCheckboxEvent(row, columnDef.selected)
      "
        [title]="
        !row[columnDef.selected] && !canSelectItems
          ? (columnDef.unselectableReason)
          : ''
      "
        [ngClass]="{
        'unselectable-checkbox': !row[columnDef.selected] && !canSelectItems
      }"
      >
        <img
          class="icon-image-column"
          [src]="
          (row[columnDef.selected]
            ? columnDef.icon + '_selected'
            : columnDef.icon) + '.svg'
        "
        />
      </div>
    </ng-container>
    <ng-container *ngIf="row.isPending">
      <img class="progress-icon" [src]="'assets/favicons/icon_in_progress_grey.svg'"/>
    </ng-container>
  </div>
  <div
    *ngSwitchCase="uiStaticTableRowType.ICON_WITH_LINK"
    class="flex-box center-horizontal"
  >
    <img
      class="icon-image"
      [src]="columnDef.getIcon(row, row[columnDef.field])"
      alt=""
    />
    <div [ngClass]="columnDef.isTruncated ? 'truncate-text' : 'break-words'">{{ row[columnDef.field] }}</div>
    <img
      *ngIf="hoveredRow === row && (row[columnDef.webUrl] || row.webUrl)"
      class="icon-link"
      src="assets/favicons/icon_external_list.svg"
      (click)="
        openUrl(row[columnDef.webUrl] || row.webUrl); $event.stopPropagation()
      "
    />
  </div>
  <div
    *ngSwitchCase="uiStaticTableRowType.TEXT_WITH_LINK"
    class="flex-box center-horizontal"
    [ngClass]="columnDef.isTruncated ? 'truncate-text' : 'break-words'"
    [class.bold]="bold"
    [ngStyle]="{ 'max-width': '100%' }"
  >
    {{ row[columnDef.field] }}
    <img
      *ngIf="
        hoveredRow === row &&
        row[columnDef.webUrl] &&
        row[columnDef.linkConditionAttribute] === columnDef.linkCondition
      "
      class="icon-link"
      src="assets/favicons/icon_external_list.svg"
      (click)="openUrl(row[columnDef.webUrl]); $event.stopPropagation()"
    />
  </div>
  <div
    *ngSwitchCase="uiStaticTableRowType.INDICATOR_ARROW_WITH_TEXT"
    class="indicator-arrow-with-text"
  >
    <div
      *ngIf="row[columnDef.text] !== undefined"
      class="indicator-arrow-with-text--text"
      [style]="'width: ' + columnDef.textWidth + ';'"
    >
      {{ row[columnDef.text] }}
    </div>
    <div class="indicator-arrow-with-text--indicator">
      {{ row[columnDef.text] !== undefined ? '[' : '' }}
      <ui-indicator-arrow
        [value]="row[columnDef.field]"
        [includePlusSign]="false"
        [includeMinusSign]="false"
      >
      </ui-indicator-arrow>
      {{ row[columnDef.text] !== undefined ? ']' : '' }}
    </div>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.ICON" class="icon-row" [ngClass]="columnDef.textAlign">
    <img
      class="icon-image-column"
      [src]="columnDef.getIcon(row)"
      [ngClass]="{
        'table-selected-icon': columnDef.onClick,
        'icon-image-column--big': columnDef.bigIcon,
        'icon-image-column--small': columnDef.smallIcon,
        'icon-image-column--auto': columnDef.autoSizeIcon
      }"
      [tooltip]="columnDef.iconDescription?.(row, locale)"
      (click)="
        columnDef.onClick ? columnDef.onClick(row) : null;
        $event.stopPropagation()
      "
    />
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.DATE" [class.bold]="bold">
    {{ row[columnDef.field] | dt : getDateParams(columnDef, false) }}
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.DATE_AND_HOUR" [class.bold]="bold">
    <p class="no-margin">{{ row[columnDef.field] | dt : getDateParams(columnDef, true) }}</p>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.DATE_AND_HOUR_AND_AGO" [class.bold]="bold">
    <p class="no-margin">
      {{ row[columnDef.field] | dt : columnDef.noSeconds ? getDateParams(columnDef, false) : getDateParams(columnDef, true) }}
    </p>
    <p class="no-margin info" [ngStyle]="columnDef.getColumnCustomCss ? columnDef.getColumnCustomCss(row) : null">
      {{ row[columnDef.field] | dateAgo:{ locale: locale, timeOnly: columnDef.timeOnly || false } }}
    </p>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.DATE_AND_HOUR_AND_UNTIL" [class.bold]="bold">
    <p class="no-margin">
      {{ row[columnDef.field] | dt : columnDef.noSeconds ? getDateParams(columnDef, false) : getDateParams(columnDef, true) }}
    </p>
    <p class="no-margin info" [ngStyle]="columnDef.getColumnCustomCss ? columnDef.getColumnCustomCss(row) : null">
      {{ row[columnDef.field] | dateUntil }}
    </p>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.BOOLEAN">
    {{ columnDef | resourceTranslation : resourceTranslationParams }}
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.LIST">
    <div *ngFor="let element of row[columnDef.field]; let last = last">
      {{ element }}{{ !last ? ',' : '' }}
    </div>
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.TRANSLATED_FIELD" [class.bold]="bold">
    {{ row[columnDef.field] | translatedObjectLocale :locale }}
  </div>
  <div *ngSwitchCase="uiStaticTableRowType.TRANSLATED_FIELD_LIST">
    <div *ngFor="let element of row[columnDef.field]; let last = last">
      {{ element | translatedObjectLocale :locale }}{{ !last ? ',' : '' }}
    </div>
  </div>

</ng-container>
