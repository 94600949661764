import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from '../../core/url.service';
import { IRespondResponse } from '@common/definitions/IRespondResponse';
import { IDataConnectorAction, IDataConnectorStatuses, IDescribeSoarConnectorResponse, ISoarConnector, ISoarConnectorAction, ISoarConnectorPayload, IUpdateSoarConnectorResponse } from './connectors-alerting-api.definition';

@Injectable({
  providedIn: 'root',
})
export class VarConnectorsAlertingApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  constructor(
    readonly http: HttpClient, url: EcoUrlService,
  ) {
    super(http, url);
  }

  public describeSoarConnectorActions(organizationId: string, connectorId: string, accountIds?: string[]): Promise<IRespondResponse<ISoarConnectorAction>> {
    const ids = accountIds?.length ? 'accountIds=' + accountIds?.join(',') : '';
    const url = `${this.host}/organizations/${organizationId}/connectors/soar/${connectorId}/actions/actives?${ids}`;
    return this.http.get<IRespondResponse<ISoarConnectorAction>>(url).toPromise();
  }

  public describeDataConnectorActions(organizationId: string, connectorId: string): Promise<IRespondResponse<IDataConnectorAction>> {
    const url = `${this.host}/organizations/${organizationId}/connectors/data/${connectorId}/actions/actives`;
    return this.http.get<IRespondResponse<IDataConnectorAction>>(url).toPromise();
  }

  public describeSoarConnector(organizationId: string, connectorId: string): Promise<IDescribeSoarConnectorResponse> {
    const url = `${this.host}/organizations/${organizationId}/connectors/soar/${connectorId}`;
    return this.http.get<IDescribeSoarConnectorResponse>(url).toPromise();
  }

  public listSoarConnectors(organizationId: string): Promise<IRespondResponse<ISoarConnector>> {
    const url = `${this.host}/organizations/${organizationId}/connectors/soar`;
    return this.http.get<IRespondResponse<ISoarConnector>>(url).toPromise();
  }
  
  public listDataConnectorStatuses(organizationId: string): Promise<IDataConnectorStatuses[]> {
    const url = `${this.host}/organizations/${organizationId}/connectors/data/statuses`;
    return this.http.get<IDataConnectorStatuses[]>(url).toPromise();
  }

  public async createSoarConnectorInstance(organizationId: string, connectorInstanceConfig: ISoarConnectorPayload): Promise<IDescribeSoarConnectorResponse> {
    const url = `${this.host}/organizations/${organizationId}/connectors/soar`;
    return this.http.post<IDescribeSoarConnectorResponse>(url, connectorInstanceConfig).toPromise();
  }

  public testSoarConnectorPermissions(organizationId: string, connectorId: string): Promise<void> {
    const url = `${this.host}/organizations/${organizationId}/connectors/soar/${connectorId}/permissions/update`;
    return this.http.get<void>(url, {}).toPromise();
  }

  public testDataConnectorPermissions(organizationId: string, connectorId: string): Promise<void> {
    const url = `${this.host}/organizations/${organizationId}/connectors/data/${connectorId}/permissions/update`;
    return this.http.get<void>(url, {}).toPromise();
  }

  public async updateSoarConnectorInstance(organizationId: string, connectorId: string, connectorInstanceConfig: ISoarConnectorPayload): Promise<IUpdateSoarConnectorResponse> {
    const url = `${this.host}/organizations/${organizationId}/connectors/soar/${connectorId}`;
    return this.http.put<IUpdateSoarConnectorResponse>(url, connectorInstanceConfig).toPromise();
  }

  public async deleteSoarConnectorInstance(organizationId: string, soarConnectorId: string): Promise<void> {
    const url = `${this.host}/organizations/${organizationId}/connectors/soar/${soarConnectorId}`;
    return this.http.delete<void>(url).toPromise();
  }
}
