import { Component, Input, OnInit } from '@angular/core';
import { IFilterBaseRepresentation } from 'projects/@common/services/api/respond/filters/filters.definitions';

@Component({
  selector: 'filter-created-by',
  templateUrl: './filter-created-by.component.html',
  styleUrls: [ './filter-created-by.component.scss' ],
})
export class FilterCreatedByComponent implements OnInit {
  @Input() filter: IFilterBaseRepresentation;

  public createdByName: string;
  public showMondataLogo: boolean;

  constructor() { }

  ngOnInit(): void {
    this.createdByName = this.filter.createdByName;
    this.showMondataLogo = !this.filter.createdByOrganization;
  }
}
