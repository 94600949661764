<ui-static-table
  [loading]="isLoading"
  [dataSource]="history"
  [addPaddingOnFirst]="false"
  [columnsDef]="historyColumnsDef"
  [expendedTemplate]="rowTemplate"
  [locale]="locale"
>
</ui-static-table>

<div
  class="load-more-button-container"
  *ngIf="canLoadMore"
>
  <button
    ui-button
    [loading]="isLoading"
    custom-icon="true"
    isSvg="true"
    icon="icon_refresh"
    modifier="on-highlight"
    color="linkBordedRegular"
    (click)="loadMoreHistory()"
  >{{'common.showMore' | t}}</button>
</div>


<ng-template
  #rowTemplate
  let-historyRow="row"
  let-isExpanded="isSelect"
>
  <app-history-detail
    *ngIf="isExpanded"
    [history]="historyRow"
  ></app-history-detail>
</ng-template>