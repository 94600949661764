import { Component, Input, OnInit } from "@angular/core";
import { DisplayService } from "projects/@common/modules/display/display.service";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { DrawerPageEnum } from "projects/@common/modules/layout/components/drawer/drawerPage.enum";
import { DrawerService } from "projects/@common/modules/layout/components/drawer/services/drawer.service";
import { SearchMode } from "projects/@common/modules/layout/components/page/page.component";
import { IAssetPropertyConfigRepresentation, IAssetRepresentation } from "projects/@common/services/api/respond/actifs/actifs.definitions";
import { ActifDrawerComponent } from "projects/@respond/actifs/components/actif-drawer/actif-drawer.component";
import { Store } from '@ngxs/store';
import { ActifsService } from "../../actifs.service";
import { ActifsDeleteModalComponent } from "../../components/actifs-delete-modal/actifs-delete-modal.component";
import { ConnectorsApiOrganizationService } from "projects/@common/services/api/detect/organizations/connectors-api-organizations";
import { DescribeOrganizationResponse } from "projects/@common/services/api/detect/organizations/definitions";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { ModalService } from "@ui-kit/services/modal.service";

export interface IActifsListViewParams {
  isReadonly: boolean;
  tableColumns: Array<Object>;
}

@Component({
  selector: 'respond-actifs-list',
  templateUrl: './respond-actifs-list.container.html',
  styleUrls: [ './respond-actifs-list.container.scss' ],
})
export class RespondActifsListContainer implements OnInit {
  @Input() viewParams: IActifsListViewParams;

  public activeOrganization: DescribeOrganizationResponse;

  public canList: boolean = false;
  public canCreate: boolean = false;
  public canDelete: boolean = false;
  public canUpdate: boolean = false;
  public canDescribe: boolean = false;

  public assetTypes: IAssetPropertyConfigRepresentation[];

  public searchMode = SearchMode.RESPOND;

  private isVarMode: boolean = false;

  constructor(
    private readonly store: Store,
    private readonly actifsService: ActifsService,
    private readonly i18n: I18nService,
    private readonly displayService: DisplayService,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    private readonly modalService: ModalService,
    private drawerService: DrawerService
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  ngOnInit(): void {
    this.actifsService.setDefaultSettings();
    this.canList = this.displayService.meetsRequirements({ permissions: [ 'can_list_assets' ] });
    this.canCreate = this.displayService.meetsRequirements({ permissions: [ 'can_create_asset' ] });
    this.canDelete = this.displayService.meetsRequirements({ permissions: [ 'can_delete_asset' ] });
    this.canUpdate = this.displayService.meetsRequirements({ permissions: [ 'can_update_asset' ] });
    this.canDescribe = this.displayService.meetsRequirements({ permissions: [ 'can_describe_asset' ] });
    if (this.canList && !this.showEmptyPageToSelectOrganization) {
      // immediate fetch for consoles that do not have an organization selector
      this.fetchAssetsData();
    }
  }

  public get isAssetTypesLoading(): boolean {
    return this.actifsService.isAssetTypesLoading;
  }

  public get showEmptyPageToSelectOrganization(): boolean {
    return this.isVarMode && !this.activeOrganization;
  }

  public fetchAssetsData() {
    this.actifsService.fetchAssetTypes();
    this.actifsService.fetchAssets();
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    this.drawerService.hideDrawer();
    if (org) {
      this.activeOrganization = await this.connectorsApiOrganizationService.describeOrganization(org.id);
      this.actifsService.setOrganizationId(this.activeOrganization.organization_respond_id);
      this.fetchAssetsData();
    } else {
      this.activeOrganization = null;
    }
  }

  public getOrganizationId(asset?: IAssetRepresentation): string {
    if (asset) {
      return asset.organizationId; // existing whitelist
    }
    if (this.activeOrganization) {
      return this.activeOrganization.organization_respond_id; // using organization selector
    }
    return this.store.selectSnapshot(EcoSessionState.organization).id; // logged in user's organization
  }

  public async openAssetDetailDrawer(asset: IAssetRepresentation) {
    this.drawerService.replaceCurrentDrawer(ActifDrawerComponent, {
      component: DrawerPageEnum.actifDrawer,
      data: {
        title: this.i18n.translate('actifs.drawer.update.title'),
        organizationId: this.getOrganizationId(asset),
        assetId: asset.id,
        types: this.actifsService.assetTypes,
        isReadonly: this.viewParams.isReadonly,
      },
    });
  }

  public openCreateDrawer() {
    this.drawerService.replaceCurrentDrawer(ActifDrawerComponent, {
      component: DrawerPageEnum.actifDrawer,
      data: {
        organizationId: this.getOrganizationId(),
        title: this.i18n.translate('actifs.drawer.create.title'),
        types: this.actifsService.assetTypes,
      },
    });
  }

  public openDeleteModal() {
    this.modalService.openDialog(ActifsDeleteModalComponent, { actifs: this.actifsService.selectedRows });
  }
}
