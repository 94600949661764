import { Injectable } from "@angular/core";
import { Eco } from 'projects/@common/definitions/eco';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { TranslatedObjectPipe } from 'projects/@common/modules/i18n/translatedObject.pipe';
import { MitreAttackGenericData, MitreAttackTechnique } from "projects/@common/services/api/detect/mitre-attack/mitre-attack-api.definition";
import { PlaybooksApiService } from "projects/@common/services/api/respond/playbooks/playbooks.api";
import { IPlaybookListResponse, IPlaybookTemplate } from 'projects/@common/services/api/respond/playbooks/playbooks.definitions';
import { UsecasesApiService } from "projects/@common/services/api/respond/usecase/usecase.api";
import { IDatasourceType } from 'projects/@common/services/api/respond/usecase/usecase.definition';

@Injectable({
  providedIn: 'root',
})
export class PlaybooksCatalogTemplateMapper {
  constructor(
    private playbookService: PlaybooksApiService,
    private usecaseApi: UsecasesApiService,
    private i18nService: I18nService,
    private readonly translatedObjectPipe: TranslatedObjectPipe
  ) { }

  public async generateMapper(language: Eco.Languages): Promise<Record<string, any>> {
    const [ playbookTemplates, connectors ] = await Promise.all([
      this.fetchPlaybookTemplates(),
      this.fetchConnectors(),
    ]);

    const groupedPlaybooks = this.groupPlaybooksByConnector(playbookTemplates, connectors, language);
    return {
      name: this.i18nService.translateWithLanguage("playbookCatalog.report.title", language),
      connectors: groupedPlaybooks,
    };
  }

  private async fetchPlaybookTemplates(): Promise<IPlaybookTemplate[]> {
    const results = await this.playbookService.getPlaybookTemplates({ displayAttacks: true });
    return results.items;
  }

  private async fetchConnectors(): Promise<{ [type: string]: IDatasourceType }> {
    const results = await this.usecaseApi.getDatasourcesDataTypes();
    return results.items.reduce((grouped, connector) => {
      grouped[connector.dataType] = connector.name;
      return grouped;
    }, {});
  }

  private groupPlaybooksByConnector(playbookTemplates: IPlaybookTemplate[], connectors: { [type: string]: IDatasourceType; }, language: Eco.Languages) {
    const playbooks: { [type: string]: IPlaybookListResponse[] } = playbookTemplates.reduce((grouped, playbookTemplate) => {
      for (const type of playbookTemplate.datasourceTypes) {
        grouped[type] = grouped[type] || [];
        grouped[type].push({
          playbook: {
            ...playbookTemplate,
            attacks: playbookTemplate.attacks?.map((attack: MitreAttackTechnique | MitreAttackGenericData) => ({
              attack: {
                tactic: "tacticRef" in attack ? `${attack.tacticRef.attackId} - ${attack.tacticRef.name}` : `${attack.attackId} - ${attack.name}`,
                technique: "tacticRef" in attack ? `${attack.attackId} - ${attack.name}` : null,
              },
            })) || [],
          },
        });
      }
      return grouped;
    }, {});

    return Object.keys(playbooks).map((connectorType) => ({
      connector: {
        header: this.i18nService.translateWithLanguage("playbookCatalog.report.header", language, { connector: this.translatedObjectPipe.transform(connectors[connectorType], language) }),
        translations: connectors[connectorType],
      },
      playbooks: playbooks[connectorType],
    }));
  }
}
