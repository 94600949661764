<div class="container">
  <div class="left-spane">
    <div class="playbook-helper">
      <ui-card>
        <div>
          <h3>
            {{ 'detection.playbook.creation.step.property.title' | t }}
          </h3>
          <div>
            <p>
              {{ 'detection.playbook.creation.step.property.description' | t }}
            </p>
          </div>
        </div>
      </ui-card
      >
    </div>
    <div class="content-card">
      <ui-card>
        <div>
          <div>
            <div>
              <h4>
                {{ 'detection.playbook.creation.step.property.drop.title' | t }}
              </h4>
              <div class="choosen-playbook-container">
                <div
                  *ngIf="dragDropInitialized"
                  cdkDropList
                  id="playbookTemplate"
                  [cdkDropListData]="dragDropRegions['playbookTemplate'].items"
                  [cdkDropListConnectedTo]="formDropRegions"
                  (cdkDropListDropped)="drop($event)"
                  [cdkDropListSortingDisabled]="false"
                  [ngClass]="
                    isPlaybookTemplateEmpty()
                      ? 'playbook-empty'
                      : 'playbook-full'
                  "
                >
                  <div class="playbook-empty-text" *ngIf="!hasEntered">
                    {{
                    'detection.playbook.creation.step.property.drop.placeholder'
                      | t
                    }}
                  </div>
                  <div
                    class="choosen-playbook"
                    *ngFor="
                      let item of dragDropRegions['playbookTemplate'].items
                    "
                    cdkDrag
                    [cdkDragData]="item"
                    [cdkDragDisabled]="true"
                  >
                    <div class="choosen-playbook-primary">
                      {{ item.itemContext.name | translatedObject }}
                    </div>
                    <div class="choosen-playbook-secondary">
                      <div>{{ item.itemContext.playbookTemplateId }}</div>
                      <div
                        class="choosen-playbook-secondary-count"
                        *ngIf="playbookTemplateTaskCount === 1"
                      >
                        {{ playbookTemplateTaskCount }}
                        {{
                        'detection.playbook.creation.step.task.single'
                          | t
                          | lowercase
                        }}
                      </div>
                      <div
                        class="choosen-playbook-secondary-count"
                        *ngIf="playbookTemplateTaskCount > 1"
                      >
                        {{ playbookTemplateTaskCount }}
                        {{
                        'detection.playbook.creation.step.task'
                          | t
                          | lowercase
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="choosen-playbook-delete"
                  *ngIf="!isPlaybookTemplateEmpty()"
                >
                  <button
                    ui-button
                    color="listActionFlat"
                    [custom-icon]="true"
                    [isSvg]="true"
                    icon="icon_delete"
                    icon-size="medium"
                    (click)="removeCurrentTemplate()"
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <form ui-form [formGroup]="playbookCreationForm.formGroup">
                <ui-field
                  *ngIf="!isPlaybookTemplateEmpty()"
                  class="form--playbook-name-full"
                  name="playbookName"
                  [label]="'detection.playbook.creation.step.property.input.playbook.name.title' | t"
                  control="playbookName"
                  [required]="true"
                  [group]="playbookCreationForm.formGroup"
                  readonly="true"
                  disabled="true">
                </ui-field>
                <ui-field
                  *ngIf="!isPlaybookTemplateEmpty()"
                  class="form--email"
                  name="playbookNameSuffix"
                  [label]="'detection.playbook.creation.step.property.input.playbook.name.suffix' | t"
                  control="playbookNameSuffix"
                  [required]="true"
                  [group]="playbookCreationForm.formGroup">
                </ui-field>
                <ui-field
                  *ngIf="canCreatePlaybookWithoutTemplate && isPlaybookTemplateEmpty()"
                  class="form--email"
                  name="playbookName"
                  [label]="'detection.playbook.creation.step.property.input.playbook.name.title' | t"
                  control="playbookName"
                  [required]="true"
                  [group]="playbookCreationForm.formGroup"
                >
                </ui-field>
                <ui-text-field
                  *ngIf="canCreatePlaybookWithoutTemplate && isPlaybookTemplateEmpty()"
                  class="form--email"
                  name="description"
                  [label]="'detection.playbook.creation.step.property.input.description.title' | t"
                  control="description"
                  [required]="true"
                  [group]="playbookCreationForm.formGroup"
                >
                </ui-text-field>

                <ui-text-field
                  *ngIf="!isPlaybookTemplateEmpty()"
                  class="form--description"
                  name="description"
                  [label]="'detection.playbook.creation.step.property.input.description.title.playbook' | t"
                  control="description"
                  [required]="true"
                  [group]="playbookCreationForm.formGroup"
                  readonly="true"
                  disabled="true"
                >
                </ui-text-field>

                <ui-text-field
                  *ngIf="!isPlaybookTemplateEmpty()"
                  class="form--email"
                  name="descriptionAdditional"
                  [label]="'detection.playbook.creation.step.property.input.description.additional.title' | t"
                  control="descriptionAdditional"
                  [required]="false"
                  [group]="playbookCreationForm.formGroup"
                >
                </ui-text-field>
              </form>
            </div>
          </div>
        </div>
      </ui-card>
    </div>
    <ui-card
      [withShadow]="false"
      [withPadding]="true"
      [isHoverable]="false"
      [hasLargeBorder]="false"
    >
      <div class="footer--button">
        <button ui-button color="secondary" (click)="handleCancel()">
          {{ 'common.action.cancel' | t }}
        </button>
        <button
          ui-button
          matStepperNext
          color="secondary"
          [disabled]="!canCreatePlaybookWithoutTemplate && isPlaybookTemplateEmpty() || !playbookCreationForm.isValid"
        >
          {{ 'common.action.next' | t }}
        </button>
      </div>
    </ui-card>
  </div>
  <div class="library-card" (click)="toggleSplitPane()"
       [ngClass]="splitPaneOpened ? 'split-pane-opened' : 'split-pane-closed'">
    <div class="library">
      <div class="library--header">
        <div class="library--header-left"
             [ngStyle]="splitPaneOpened ? {'flex-direction' : 'row', 'width': '100%'} : null">
          <img src="assets/favicons/icon_playbooks_library.svg"/>
          <div class="lib"
               [ngStyle]="splitPaneOpened ? {'align-items' : 'center', 'width': '100%', 'justify-content': 'space-between'} : {'margin-top': '10px'}">
            <span>{{ 'detection.playbook.create.library.task' | t }}</span>
            <ui-icon
              class="library--header--chevron"
              modifier="on-header"
              [name]="splitPaneOpened ? 'chevron-down' : 'chevron-right'"
              size="medium"
            ></ui-icon>
          </div>
        </div>
      </div>
      <div class="library-filters collapses-on-pane-closed" (click)="$event.stopPropagation()">
        <ui-table-search-tool
          [placeholder]="'ui.table-search-tool.placeholder' | t"
          [query]="filtering.query"
          (clear)="filteringOnChange('')"
          (query)="filteringOnChange($event)"
        ></ui-table-search-tool>
        <div class="library-filters--multiselect-datasources">
          <ui-multi-select
            [multiSelectData]="datasourceTypeFilterSelect"
            (onOptionSelect)="handleDataSourceFilter($event)"
            (click)="$event.stopPropagation()"
          ></ui-multi-select>
        </div>
      </div>
      <div
        (click)="$event.stopPropagation()"
        *ngIf="dragDropInitialized"
        cdkDropList
        id="MDPlaybookTemplates"
        [cdkDropListData]="dragDropRegions['MDPlaybookTemplates'].items"
        [cdkDropListConnectedTo]="formDropRegions"
        [cdkDropListSortingDisabled]="true"
        [cdkDropListEnterPredicate]="noReturnPredicate"
        (cdkDropListDropped)="libraryClearPickedItem()"
        class="playbookTemplate-library collapses-on-pane-closed">
        <ng-container
          *ngFor="let item of dragDropRegions['MDPlaybookTemplates'].items"
          class="library-items">
          <div
            cdkDrag
            (cdkDragStarted)="librarySetPickedItem($event)"
            [cdkDragData]="item"
            [cdkDragDisabled]="!isDraggable"
          >
            <div class="drag-placeholder" *cdkDragPlaceholder></div>
            <playbook-library-card
              [isDraggable]="isDraggable"
              class="template-card"
              [playbookTemplate]="item.itemContext">
            </playbook-library-card>
          </div>
          <div class="item-placeholder" *ngIf="pickedItem === item">
            <playbook-library-card
              [playbookTemplate]="item.itemContext"
            ></playbook-library-card>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!dragDropInitialized">{{ 'detection.playbook.creation.step.property.loading' | t }}</div>
    </div>
  </div>
</div>
