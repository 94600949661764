import { Component, Input, OnInit } from '@angular/core';
import { IObservable } from '@common/services/api/respond/observables/observables.definition';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';

@Component({
  selector: 'incident-similarity-tab',
  templateUrl: './incident-similarity-tab.component.html',
  styleUrls: [ './incident-similarity-tab.component.scss' ],
})
export class IncidentSimilarityTabComponent implements OnInit {
  @Input() 
  public incident: Incident;
  
  @Input()
  public observables: IObservable[];

  constructor() { }

  ngOnInit(): void { }
}
