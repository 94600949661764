import { Component, Input, OnInit } from '@angular/core';
import { ITranslatedField } from '@common/definitions/ITranslatedField';
import { Eco } from '@common/definitions/eco';
import { TableFilterToolDataFactory } from '@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { DrawerPageEnum } from '@common/modules/layout/components/drawer/drawerPage.enum';
import { UsecasesApiService } from '@common/services/api/respond/usecase/usecase.api';
import { IAlertingUsecaseTemplate, ISystemListUsecaseTemplateItem, IUsecase } from '@common/services/api/respond/usecase/usecase.definition';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { IMultiSelectData } from '@ui-kit/components/ui-multi-select/ui-multi-select.component';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { UsecasesCatalogContainerActions } from '../../actions/usecasesCatalog.actions';
import { UsecaseCatalogDrawerContainer } from '../../containers/drawers/usecase-catalog-drawer/usecase-catalog-drawer.container';
import { UsecaseCategories, UsecaseCategoryType, UsecaseNonConnectorCategoryEnum } from '../../mappers/usecaseCategories';


export interface RichAlertingUsecaseTemplate extends IAlertingUsecaseTemplate {
  activated: boolean;
  connectorTypes: ITranslatedField[];
};

export enum UsecaseDisplayFilterEnum {
  ALL = 'ALL_USECASES',
  PER_CONNECTOR = 'PER_CONNECTOR'
}

export enum UsecaseActiveFilterEnum {
  ALL = 'ALL_USECASES',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

@Component({
  selector: 'usecase-catalog',
  templateUrl: './usecase-catalog.component.html',
  styleUrls: [ './usecase-catalog.component.scss' ],
})
export class UsecaseCatalogComponent implements OnInit {
  @Input() usecaseCatalogAction: UsecasesCatalogContainerActions;

  // Filters
  public usecaseDisplayFilterEnum = UsecaseDisplayFilterEnum;
  public usecaseActiveFilterEnum = UsecaseActiveFilterEnum;
  public usecaseDatasourceFilterEnum = UsecaseNonConnectorCategoryEnum;
  public displayFilter: UsecaseDisplayFilterEnum = UsecaseDisplayFilterEnum.PER_CONNECTOR;
  public activeFilter: UsecaseActiveFilterEnum = UsecaseActiveFilterEnum.ALL;
  public connectorFilters: UsecaseCategoryType[] = [ UsecaseNonConnectorCategoryEnum.ALL_CONNECTORS ];
  public usecaseDisplayTableFilter: TableFilterData;
  public usecaseCategoryFilterMultiSelectData: MultiSelectData = new MultiSelectData();
  public usecaseActiveTableFilter: TableFilterData;

  public isLoading: boolean = true;
  public total: number = 0;
  public sort = 'name_fr';
  public direction = UiTableDirection.Asc;
  public selectedCategoryKeys: UsecaseCategoryType[] = [];
  public categories: UsecaseCategories<RichAlertingUsecaseTemplate>;

  public readonly columnsDef = [
    {
      label: this.i18n.translate('usecases.details.activated'),
      field: 'activated',
      type: UiStaticTableRowType.ICON,
      getIcon: this.getIsActivatedIcon,
      width: '90px',
      noSorting: true,
    },
    {
      label: this.i18n.translate('usecases.details.id'),
      field: 'usecaseId',
      type: UiStaticTableRowType.TEXT,
      width: '150px',
      noSorting: true,
    },
    {
      label: this.i18n.translate('usecases.details.name'),
      field: 'name',
      type: UiStaticTableRowType.TRANSLATED_FIELD,
      noSorting: true,
    },
    {
      label: this.i18n.translate('usecases.details.priority'),
      field: 'priority',
      type: UiStaticTableRowType.TEXT,
      width: '90px',
      noSorting: true,
    },
    {
      label: this.i18n.translate('usecases.details.relatedDatasource'),
      field: 'connectorTypes',
      type: UiStaticTableRowType.TRANSLATED_FIELD_LIST,
      width: '250px',
      noSorting: true,
    },
  ];

  constructor(
    private tableFilterToolDataFactory: TableFilterToolDataFactory,
    private usecasesApiService: UsecasesApiService,
    private drawerService: DrawerService,
    public i18n: I18nService
  ) {
    this.categories = new UsecaseCategories(i18n);
  }

  public ngOnInit() {
    this.isLoading = true;
    this.usecaseDisplayTableFilter = this.tableFilterToolDataFactory.create(this.usecaseDisplayFilterEnum, 'usecases.table.', this.displayFilter, true);
    this.usecaseActiveTableFilter = this.tableFilterToolDataFactory.create(this.usecaseActiveFilterEnum, 'usecases.table.', this.activeFilter, true);

    const dataTypesPromise = this.usecasesApiService.getDatasourcesDataTypes().then((connectorTypes) => {
      this.categories.setCategories(connectorTypes.items);
      this.setUsecaseCategoryFilterMultiSelectData();
    });
    this.usecaseCatalogAction.usecasesCatalog.subscribe(async (_observer) => {
      if (this.usecaseCatalogAction.hasMoreToLoad) {
        return this.usecaseCatalogAction.fetchUsecasesCatalog(true);
      }
      await dataTypesPromise;
      this.categories.setUsecases(this.usecaseCatalogAction.usecasesCatalog.getValue().map((usecase) => this.formatUsecase(usecase)));
      this.filterCategoryKeys();
      this.isLoading = false;
    });
  }

  public get filteredCategories() {
    return this.selectedCategoryKeys
      .map((categoryKey) => ({
        ...this.categories.get(categoryKey),
        usecases: this.getCategoryUsecases(categoryKey),
      }))
      .filter((category) => category.usecases.length > 0);
  }

  public getCategoryUsecases(category: UsecaseCategoryType) {
    const usecases = this.categories.get(category)?.usecases || [];

    if (this.activeFilter === UsecaseActiveFilterEnum.ALL) {
      return usecases;
    }
    return usecases.filter((usecase) => this.activeFilter === UsecaseActiveFilterEnum.ACTIVE ? usecase.activated : !usecase.activated);
  }

  public getIsActivatedIcon(usecase: IUsecase): string {
    if (usecase.activated) {
      return 'assets/favicons/icon_success.svg';
    }
    return '';
  }

  public async handleRefresh() {
    this.isLoading = true;
    await this.usecaseCatalogAction.fetchUsecasesCatalog();
  }

  public handleDisplayFilter(filter: UsecaseDisplayFilterEnum) {
    this.displayFilter = filter;
    this.filterCategoryKeys();
  }

  public handleDataFilter(filter: UsecaseActiveFilterEnum) {
    this.activeFilter = filter;
    this.filterCategoryKeys();
  }

  public handleDataSourceFilter(filters: UsecaseCategoryType[]) {
    this.connectorFilters = filters;
    this.filterCategoryKeys();
  }

  private formatUsecase(usecaseResponse: ISystemListUsecaseTemplateItem): RichAlertingUsecaseTemplate {
    return {
      ...usecaseResponse.template,
      activated: usecaseResponse.instances > 0,
      connectorTypes: usecaseResponse.template.datasourceTypes.map((connectorType) => this.categories.get(connectorType)?.name || Object.values(Eco.Languages)
        .reduce((prev, lang) => {
          prev[lang] = connectorType;
          return prev;
        }, {})),
    };
  }

  public handleRowClick(item) {
    this.drawerService.replaceCurrentDrawer(UsecaseCatalogDrawerContainer, {
      component: DrawerPageEnum.usecaseDrawer,
      data: { usecase: item, title: item.name[this.i18n.currentLocale] },
    });
  }

  private setUsecaseCategoryFilterMultiSelectData() {
    const options: IMultiSelectData[] = this.categories.keys.map((categoryKey) => {
      const category = this.categories.get(categoryKey);
      return {
        option: category.type,
        translated: category.name[this.i18n.currentLocale],
        isDefaultValue: category.type === UsecaseNonConnectorCategoryEnum.ALL_CONNECTORS,
        isSelected: category.type === UsecaseNonConnectorCategoryEnum.ALL_CONNECTORS,
      } as IMultiSelectData;
    });

    this.usecaseCategoryFilterMultiSelectData = new MultiSelectData(options);
  }

  private filterCategoryKeys() {
    this.selectedCategoryKeys = this.categories.keys
      .filter((category) => {
        if (this.displayFilter === UsecaseDisplayFilterEnum.ALL) {
          return category === UsecaseNonConnectorCategoryEnum.ALL_CONNECTORS;
        }

        if (this.connectorFilters.length === 0 || (this.connectorFilters.length === 1 && this.connectorFilters[0] === UsecaseNonConnectorCategoryEnum.ALL_CONNECTORS)) {
          return category !== UsecaseNonConnectorCategoryEnum.ALL_CONNECTORS;
        }

        return this.connectorFilters.includes(category);
      });

    this.total = this.selectedCategoryKeys
      .map((category) => this.getCategoryUsecases(category).length)
      .reduce((prev, curr) => prev + curr, 0);
  }
}
