import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationFrequency } from 'projects/@common/definitions/notificationFrequency.enum';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';

@Component({
  selector: 'revision-frequency',
  templateUrl: './revision-frequency.component.html',
  styleUrls: [ './revision-frequency.component.scss' ],
})
export class RevisionFrequencyComponent implements OnInit {
  @Input() public currentSettings: OrganizationSettings;
  @Input() public isLoading = false;

  public isEditing = false;
  public notificationFrequencyEnum = NotificationFrequency;
  public revisionFrequencyForm: UntypedFormGroup;
  public frequencyOptions = (() => {
    const options = [];
    for (let i = 1; i < 13; i++) {
      options.push({
        value: i,
        displayValue: this.i18n.translate('settings.resources.revision.frequency.option', { monthNumber: i }),
      });
    }
    return options;
  })();

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private formBuilder: UntypedFormBuilder,
    private i18n: I18nService,
    private noticeService: NoticeService
  ) {}

  ngOnInit() {
    this.initForm(this.currentSettings);
  }

  private initForm(currentSettings?: OrganizationSettings) {
    this.revisionFrequencyForm = this.formBuilder.group({
      frequency: this.formBuilder.control(currentSettings?.defaultRevisionFrequency || 6),
    });
  }

  public cancel() {
    this.initForm(this.currentSettings);
  }

  public save(): void {
    this.organizationsApiService
      .updateOrganizationSettings({
        newSettings: { defaultRevisionFrequency: this.revisionFrequencyForm?.value.frequency },
      })
      .then((response) => {
        this.currentSettings = response;
        this.initForm(response);
        this.noticeService.notifyUser({
          message: 'settings.notification.revision.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.notification.revision.snack.error',
          level: NoticeLevels.ERROR,
        });
      });
  }

  public get isValid() {
    return this.revisionFrequencyForm.valid;
  }

  public restoreDefaults() {
    this.revisionFrequencyForm.patchValue({
      frequency: 6,
    });
  }
}
