import { Component, Input } from "@angular/core";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { NoticeService } from "projects/@common/modules/notice/notice.service";
import { PlaybooksApiService } from "projects/@common/services/api/respond/playbooks/playbooks.api";
import { IPlaybookDetail } from "projects/@common/services/api/respond/playbooks/playbooks.definitions";

import { PlaybookInventoryDetailForm, PlaybookPatchValuesEnum } from "../../containers/respond-playbook-detail/playbook-details.form";

@Component({
  selector: 'app-playbook-detail-notes',
  templateUrl: './playbook-detail-notes.component.html',
  styleUrls: [ './playbook-detail-notes.component.scss' ],
})
export class PlaybookDetailNotesComponent {
  @Input()
  public playbook: IPlaybookDetail;

  @Input()
  public playbookDetailForm: PlaybookInventoryDetailForm;

  @Input()
  public isReadonly: boolean = true;

  public playbookValuesEnum = PlaybookPatchValuesEnum;

  constructor(
    private readonly playbooksApiService: PlaybooksApiService,
    private readonly noticeService: NoticeService,
    public readonly i18nService: I18nService
  ) {
  }

  public async savePlaybookValue(patchValueEnum: PlaybookPatchValuesEnum, value: string): Promise<void> {
    if (this.isReadonly) {
      return;
    }
    const modifiedPlaybook = {
      ...this.playbook,
      [patchValueEnum]: value,
    };
    this.playbooksApiService.updatePlaybook(this.playbook.organizationId, modifiedPlaybook)
      .then(() => {
        this.playbook[patchValueEnum] = value;
        this.noticeService.success('detection.playbook.detail.updated.success');
      })
      .catch((err) => this.noticeService.error(err));
  }

  public isNotesEmpty(): boolean {
    return !this.playbook.notes || Object.keys(this.playbook.notes).length === 0;
  }
}
