import { Component, Input } from '@angular/core';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { IWarningMessage, WarningColors } from '@ui-kit/components/ui-warning/ui-warning.component';
import { IConnector, IConnectorAction, ISoarConnectorAction, PermissionTypes, SoarConnectorActionStatus, SoarConnectorStatus, SoarConnectorTypes } from '@common/services/api/respond/connectors/connectors-alerting-api.definition';
import { NoticeService } from '@common/modules/notice/notice.service';
import { ConnectorsAlertingApiService } from '@common/services/api/respond/connectors/connectors-alerting-api.service';
import { ConnectorTypesEnum } from '@common/services/api/detect/connectorsOrg/model/ConnectorOrg';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';

export type SoarConnectorDatasource = IConnector & {actions: IConnectorAction[], displayName: string, health: string, connectorType?: ConnectorTypesEnum};

type IDatasource = {
  name: string;
  application: string;
  access: string;
  validPermissions: string;
  effectivePermission: string;
  accountId: string;
  status: SoarConnectorActionStatus,
};

type ISoarConnectorActionWithDataSource = (ISoarConnectorAction & {dataSource?: IDatasource[]});

@Component({
  selector: 'connectors-soar-permissions',
  templateUrl: './connectors-soar-permissions.component.html',
  styleUrls: ['./connectors-soar-permissions.component.scss']
})
export class ConnectorsSoarPermissionsComponent {
  @Input() public selectedOrganizationId: string;
  @Input() public connectorInstanceId: string;
  @Input() public connectorType: SoarConnectorTypes;
  @Input() public metadata: any;
  @Input() public isFromConsoleAdm: boolean = false;

  public warningMessages: IWarningMessage[] = [{
      messageKey: this.i18nService.translate('detect.connector.instance.drawer.tab.permissions.invalidPermissions'),
    },
  ];

  public isTestingPermission = false;
  public isLoading = false;
  public soarConnectorActions: ISoarConnectorActionWithDataSource[] = [];
  public warningColors = WarningColors;
  public hasActionsWithInvalidPermissions = false;
  public actionsGroupedByAccount: {[key: string]: ISoarConnectorActionWithDataSource[]} = {};
  public SoarConnectorTypes = SoarConnectorTypes;
  public status: SoarConnectorStatus;

  public columnsDef = [
    {
      label: '',
      field: 'icon',
      type: UiStaticTableRowType.EXPENDABLE_ICON,
      showIcon: true,
      isResizable: false,
      width: '45px',
      maxWidth: '45px',
    },
    {
      label: this.i18nService.translate('connector.table.field.name'),
      field: 'name',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      width: '50%'
    },
    {
      label: this.i18nService.translate('connector.table.field.application'),
      field: 'application',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      width: '180px',
      maxWidth: '180px',
      minWidth: '180px',
    },
    {
      label: this.i18nService.translate('connector.table.field.accessRole'),
      field: 'access',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      width: '100px',
      maxWidth: '100px',
      minWidth: '100px',
    },
    {
      label: this.i18nService.translate('connector.table.field.result'),
      field: 'result',
      type: UiStaticTableRowType.ICON,
      isResizable: false,
      width: '80px',
      maxWidth: '80px',
      minWidth: '80px',
      textAlign: 'center',
      getIcon: (data: IDatasource) => this.getResultTestIcon(data),
      iconDescription: (data: IDatasource) => this.getTooltip(data)
    },
  ];

  private soarConnectorPermissionsDef: {[key:string]: {type: PermissionTypes}};

  constructor(
    private readonly i18nService: I18nService,
    private readonly connectorsApi: ConnectorsAlertingApiService,
    private readonly noticeService: NoticeService,
    private readonly dtPipe: DTPipe,
  ) { }

  ngOnInit(): void {
    this.fetchConnectorInfos();
  }

  public async testPermissions(){
    this.isTestingPermission = true;
    try {
      await this.connectorsApi.updateSoarConnectorPermissions(this.selectedOrganizationId, this.connectorInstanceId);
      await this.fetchConnectorInfos();
    } catch {
      this.noticeService.error('common.error.retry');
    } finally {
      this.isTestingPermission = false;
    }
  }

  public getActionIcon(action: ISoarConnectorActionWithDataSource): {name: string; tooltip: string; text: string} {
    const message = this.i18nService.translate('detect.connector.instance.drawer.tab.permissions.validatedAt');
    const invalidPermissions = action.status === SoarConnectorActionStatus.MISSING_PERMISSION;

    return {
      tooltip: invalidPermissions ? this.i18nService.translate('detect.connector.instance.drawer.tab.permissions.invalidPermissions') : '',
      name: invalidPermissions ? 'assets/favicons/icon_problem.svg' : 'assets/favicons/icon_activated.svg',
      text: message + this.dtPipe.transform(new Date(action.updatedAt), { withTime: true, locale: this.i18nService.currentLocale })
    }
  }

  private setDatasource(): void{
    for(const action of this.soarConnectorActions) {
      action.dataSource = action.action.permissions
        .map((permission: string | string[]) => Array.isArray(permission) ? permission : [permission])
        .map((permissions) => ({
          name: permissions[0],
          application: action.action.applications.join(", "),
          access: this.i18nService.translate(`detect.connector.instance.drawer.tab.permissions.access.${this.soarConnectorPermissionsDef[permissions[0]].type}`),
          validPermissions: permissions.join(`, `),
          effectivePermission: permissions.filter((permission) => action.enabledByPermissions.includes(permission)).shift(),
          accountId: action.accountId,
          status: action.status
        }));
    };

    this.hasActionsWithInvalidPermissions = this.soarConnectorActions.some((item) => item.status === SoarConnectorActionStatus.MISSING_PERMISSION);

    if(this.connectorType === SoarConnectorTypes.AWS){
      this.groupActionsByAccount();
    }
  }

  private getResultTestIcon(row: IDatasource): string {
    return row.status === SoarConnectorActionStatus.FULLY_ENABLED ? 
      'assets/favicons/icon_activated.svg' : 
      'assets/favicons/icon_problem.svg';
  }

  public getTooltip(row: IDatasource): string {
    return row.status === SoarConnectorActionStatus.FULLY_ENABLED ? 
      this.i18nService.translate(`detect.connector.instance.drawer.tab.permissions.status.GRANTED`) :
      this.i18nService.translate(`detect.connector.instance.drawer.tab.permissions.status.MISSING`);
  }

  private async fetchConnectorInfos() {
    this.isLoading = true;
    try {
      const connectorActions = await this.connectorsApi.describeSoarConnectorActions(this.selectedOrganizationId, this.connectorInstanceId);
      const connectorPermissionsDef = await this.connectorsApi.describeSoarConnector(this.selectedOrganizationId, this.connectorInstanceId);

      this.soarConnectorActions = connectorActions.items.sort((c1, c2) => {
        const name1 = c1.action.nameI18n[this.i18nService.currentLocale] as string;
        const name2 = c2.action.nameI18n[this.i18nService.currentLocale] as string;
        return name1.localeCompare(name2);
      });

      this.soarConnectorPermissionsDef = connectorPermissionsDef.permissionDefinition;
      this.status = connectorPermissionsDef.soarConnector.status;
      this.setDatasource();
    } catch {
      this.noticeService.error('common.error.retry');
    } finally {
      this.isLoading = false;
    }
  }

  private groupActionsByAccount(): void{
    const accountIds = [...new Set(this.soarConnectorActions.map((item) => item.accountId))];
    accountIds.forEach((accountId: string) => {
      this.actionsGroupedByAccount[this.getAccountNameFromAccountId(accountId)] = this.soarConnectorActions.filter((action) => action.accountId === accountId);
    })
  }

  private getAccountNameFromAccountId(accountId: string): string {
    return this.metadata?.accounts?.find((account: {id: string; name: string}) => account.id === accountId)?.name;
  }
}
