<label
  ui-label
  [required]="actionParameter.isRequired"
>{{actionParameter.label}}</label>

<div class="incident-actions--inputs-group">
  <div
    class="incident-actions--input-container"
    *ngFor="let item of arrayItems; trackBy: trackByFn;"
  >
    <combobox-input
      [defaultValue]="item.value"
      [disabled]="!isExecuteActionAvailable"
      [dropdownOptions]="dropdownOptions"
      [dropdownOptionsToIgnore]="dropdownOptionsToIgnore"
      (valueChangeEvent)="handleInputChange($event, item)"
    ></combobox-input>

    <button
      *ngIf="actionParameter.isArrayParam && arrayItems.length > 1"
      ui-button
      class="incident-actions--input-button-delete"
      color="drawerCloseFlat"
      icon="delete"
      [disabled]="!isExecuteActionAvailable"
      [tooltip]="'common.action.delete'| t"
      (click)="deleteValue(item)"
    ></button>
  </div>
</div>

<button
  ui-button
  *ngIf="actionParameter.isArrayParam"
  class="incident-actions--add-button"
  color="drawerCloseFlat"
  [tooltip]="'incident-actions.actionForm.input.add'| t:{propName:actionParameter.key}"
  (click)="addValue()"
>
  <img src="assets/favicons/icon_add_circle.svg">
  {{'common.action.add' | t}}
</button>