import { Component, Input, OnInit } from '@angular/core';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';

@Component({
  selector: 'app-device-compliance-notification',
  templateUrl: './device-compliance-notification.component.html',
  styleUrls: [ './device-compliance-notification.component.scss' ],
})
export class DeviceComplianceNotificationComponent implements OnInit {
  @Input() public currentSettings: OrganizationSettings;
  @Input() public isLoading = false;

  public isEditing = false;
  public isActivated = false;
  public initialValue: boolean;

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private noticeService: NoticeService
  ) { }

  ngOnInit(): void {
    this.initialValue = this.currentSettings.notifyNonCompliantUsers;
    this.isActivated = !!this.initialValue;
  }

  public cancel(): void {
    this.isActivated = !!this.initialValue;
  }

  public handleSave() {
    this.save();
  }

  public toggleActivation(): void {
    this.isActivated = !this.isActivated;
  }

  public save(): void {
    this.initialValue = this.isActivated;
    this.organizationsApiService
      .updateOrganizationSettings({
        newSettings: {
          notifyNonCompliantUsers: this.isActivated,
        },
      })
      .then((response) => {
        this.isActivated = response.notifyNonCompliantUsers;
        this.initialValue = response.notifyNonCompliantUsers;
        this.noticeService.notifyUser({
          message: 'settings.devices.compliance.notification.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.devices.compliance.notification.snack.error',
          level: NoticeLevels.ERROR,
        });
      });
  }
}
