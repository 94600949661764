<div
  page
  [appPagePaths]="['mdRespond', 'filters']"
  [hasTopSection]="true"
  [tooltipTitle]="isVarMode ? 'filters.table.title.tooltip.admin': 'filters.table.title.tooltip.client'"
  (organizationChanged)="organizationChanged($event)"
>
  <span
    *ngIf="showEmptyPageToSelectOrganization"
    class="no-org-transition"
  >
    {{ 'organizations_select.notSelected.message' | t }}
  </span>

  <filters-filters
    *ngIf="!showEmptyPageToSelectOrganization"
    toolbar
  ></filters-filters>
  <filters-table
    *ngIf="!showEmptyPageToSelectOrganization"
    [defaultColumnsDef]="viewParams.tableColumns"   
    [filtersData]="filtersData"
    [canDescribe]="canDescribe"
    [canCreate]="canCreate"
    [canDelete]="canDelete"
    [isReadonly]="viewParams.isReadonly"
    (rowClickEvent)="openFilterDrawer($event)"
    (createEvent)="openFilterDrawer(null)"
    (deleteEvent)="openDeleteModal()"
  ></filters-table>

</div>