<app-incident-details-base-layout [title]="'incidents.container.page.details.tab.detail.playbook.title' | t">
  <ui-field-group>
    <ui-static
      [label]="'incidents.container.page.details.tab.detail.playbook.name' | t"
      [value]="(playbook?.name | translatedObject) + (playbook?.suffix ? ' - ' + playbook.suffix : '')"
    ></ui-static>
  </ui-field-group>

  <ui-field-group>
    <ui-static
      [label]="'incidents.container.page.details.tab.detail.playbook.id' | t"
      [value]="playbook?.templateCatalogId"
    ></ui-static>
  </ui-field-group>

  <ui-field-group>
    <ui-static
      [label]="'incidents.container.page.details.tab.detail.playbook.description' | t"
      [value]="playbook?.description | translatedObject"
    ></ui-static>
  </ui-field-group>
</app-incident-details-base-layout>