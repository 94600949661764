import { Component, Input } from '@angular/core';
import { ComponentsModule } from '@common/components/components.module';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { NoticeService } from '@common/modules/notice/notice.service';
import { ConnectorTypesEnum } from '@common/services/api/detect/connectorsOrg/model/ConnectorOrg';
import { IConnectorInstance } from '@common/services/api/detect/models/connectorInstance.model';
import { DataConnectorGrantedStatus, IDataConnectorAction, UnknownReason } from '@common/services/api/respond/connectors/connectors-alerting-api.definition';
import { ConnectorsAlertingApiService } from '@common/services/api/respond/connectors/connectors-alerting-api.service';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { IWarningMessage, WarningColors } from '@ui-kit/components/ui-warning/ui-warning.component';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';

export type DataConnectorDatasource = IConnectorInstance & { displayName: string, connectorType?: ConnectorTypesEnum };

type IDatasource = {
  name: string;
  application: string;
  access: string;
  status: DataConnectorGrantedStatus;
  unknownReason: UnknownReason;
}

@Component({
  selector: 'connectors-data-permissions',
  templateUrl: './connectors-data-permissions.component.html',
  styleUrls: ['./connectors-data-permissions.component.scss']
})
export class ConnectorsDataPermissionsComponent {
  @Input() public organizationId: string;
  @Input() public connectorId: string;
  @Input() public connectorName: string;
  @Input() public isFromConsoleAdm: boolean = false

  public dataSource: IDatasource[];
  public dataConnectorActions: IDataConnectorAction[];
  public isTestingPermission = false;
  public isLoading = false;
  public hasInvalidPermissions = false;
  public warningColors = WarningColors;

  public warningMessages: IWarningMessage[] = [{
      messageKey: this.i18nService.translate('detect.connector.instance.drawer.tab.permissions.invalidPermissions'),
    },
  ];

  public columnsDef = [
    {
      label: this.i18nService.translate('connector.table.field.name'),
      field: 'name',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      width: '50%'
    },
    {
      label: this.i18nService.translate('connector.table.field.application'),
      field: 'application',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      width: '180px',
      maxWidth: '180px',
      minWidth: '180px',
    },
    {
      label: this.i18nService.translate('connector.table.field.accessRole'),
      field: 'access',
      type: UiStaticTableRowType.TEXT,
      isResizable: false,
      width: '100px',
      maxWidth: '100px',
      minWidth: '100px',
    },
    {
      label: this.i18nService.translate('connector.table.field.result'),
      field: 'status',
      type: UiStaticTableRowType.ICON,
      getIcon: (data: IDatasource) => this.getResultTestIcon(data),
      isResizable: false,
      width: '80px',
      maxWidth: '80px',
      minWidth: '80px',
      textAlign: 'center',
      iconDescription: (data: IDatasource) => this.getTooltip(data),
    },
  ];
  
  constructor(
    private readonly i18nService: I18nService,
    private readonly connectorsApi: ConnectorsAlertingApiService,
    private readonly noticeService: NoticeService,
    private readonly dtPipe: DTPipe,
  ) { }

  ngOnInit(): void {
    this.fetchConnectorActions();
  }

  public async testPermissions() {
    this.isTestingPermission = true;
    try {
      await this.connectorsApi.updateDataConnectorPermissions(this.organizationId, this.connectorId);
      await this.fetchConnectorActions();
    } catch {
      this.noticeService.error('common.error.retry')
    } finally {
      this.isTestingPermission = false;
    }
  }

  public getActionIcon(): {name: string; tooltip: string; text: string} {
    const message = this.i18nService.translate('detect.connector.instance.drawer.tab.permissions.validatedAt');

    return {
      tooltip: this.hasInvalidPermissions ? this.i18nService.translate('detect.connector.instance.drawer.tab.permissions.invalidPermissions') : '',
      name: this.hasInvalidPermissions ? 'assets/favicons/icon_problem.svg' : 'assets/favicons/icon_activated.svg',
      text: message + this.dtPipe.transform(new Date(this.dataConnectorActions[0].updatedAt), { withTime: true, locale: this.i18nService.currentLocale })
    }
  }

  private setDatasource(): void{
    this.dataSource = this.dataConnectorActions.map((action) => ({
      name: action.name,
      application: this.connectorName,
      access: this.i18nService.translate(`detect.connector.instance.drawer.tab.permissions.access.${action.permission.type}`),
      status: action.status,
      unknownReason: action.unknownReason
    }));

    this.hasInvalidPermissions = this.dataSource.some((item) => item.status !== DataConnectorGrantedStatus.GRANTED);
  }

  private async fetchConnectorActions() {
    this.isLoading = true;
    try {
      const response = await this.connectorsApi.describeDataConnectorActions(this.organizationId, this.connectorId)
      this.dataConnectorActions = response.items;
      this.setDatasource();
    } catch {      
      this.noticeService.error('common.error.retry');
    } finally {
      this.isLoading = false;
    }
  }

  private getResultTestIcon(row: IDatasource): string {
    switch (row.status) {
      case DataConnectorGrantedStatus.MISSING:
        return 'assets/favicons/icon_problem.svg';
      case DataConnectorGrantedStatus.GRANTED:
        return 'assets/favicons/icon_activated.svg';
      case DataConnectorGrantedStatus.UNKNOWN:
        return 'assets/favicons/icon_contextual_help_small.svg';
    };
  }

  private getTooltip(row: IDatasource): string {
    const out = this.i18nService.translate(`detect.connector.instance.drawer.tab.permissions.status.${row.status}`);
    if (row.unknownReason) {
      return `${out}. ` + this.i18nService.translate(`detect.connector.instance.drawer.tab.permissions.reason.${row.unknownReason}`);
    }
    return out;
  }
}
