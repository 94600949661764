<app-incident-status
  *ngIf="isIncident"
  [data]="statusData"
  [context]="'table'"
  [wrapper]="false"
  [withIcon]="false"
></app-incident-status>

<app-alert-status
  *ngIf="isAlert"
  [alert]="statusData"
></app-alert-status>