import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { UiMultiSelect } from '@ui-kit/components/ui-multi-select/ui-multi-select.component';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { UsecasesApiService } from 'projects/@common/services/api/respond/usecase/usecase.api';
import { IDatasourceType } from 'projects/@common/services/api/respond/usecase/usecase.definition';
import { mapArrayToObject } from 'projects/@common/utils/utils';

@Component({
  selector: 'connectors-multiselect',
  templateUrl: './connectors-multiselect.component.html',
  styleUrls: [ './connectors-multiselect.component.scss' ],
})
export class ConnectorsMultiselectComponent implements OnInit {
  @Input() public shouldFetchConnectorsList = false;
  @Input() public injectedConnectorsList: IDatasourceType[];
  @Input() public defaultSelectedConnectors: string[];

  @Output() public connectorSelectedEvent = new EventEmitter<string[]>();

  @ViewChild(UiMultiSelect) multiselectComponent!: UiMultiSelect;

  public connectorMultiselectOptions: MultiSelectData;
  public connectorsList: IDatasourceType[];

  constructor(
    private readonly i18n: I18nService,
    private readonly multiSelectFactory: MultiSelectDataFactory,
    private readonly usecasesApi: UsecasesApiService,
    private readonly noticeService: NoticeService
  ) { }

  ngOnInit(): void {
    this.connectorMultiselectOptions = new MultiSelectData();
    if (this.shouldFetchConnectorsList) {
      this.fetchConnectors();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.injectedConnectorsList?.currentValue) {
      this.connectorsList = changes.injectedConnectorsList.currentValue;
      this.initConnectorsMultiSelectOptions();
    }
  }

  public applyConnectors(values: string[]): void {
    this.connectorSelectedEvent.emit(values);
  }

  public clearConnectors(): void {
    this.multiselectComponent.clearSelection();
  }

  private fetchConnectors(): void {
    this.connectorsList = [];
    this.usecasesApi.getDatasourcesDataTypes()
      .then((response) => {
        this.connectorsList = response.items;
        this.initConnectorsMultiSelectOptions();
      })
      .catch(() => this.noticeService.error('common.error.retry'));
  }

  private initConnectorsMultiSelectOptions(): void {
    const options = mapArrayToObject(this.connectorsList, 'name', 'dataType');
    this.connectorMultiselectOptions = this.multiSelectFactory.create(options, this.defaultSelectedConnectors || [], "", true);
  }
}
