<img
  *ngIf="isSameOrganization"
  class="icon-pos-1 img20px"
  [class.fixedGridDisplay]="fixedGridDisplay"
  src="assets/favicons/similarity/icon_same_organization.svg"
  alt="Organization"
  [tooltip]="'respond.similarity.same.organization' | t"
>

<img
  *ngIf="isSameUsecase"
  class="icon-pos-2 img24px "
  [class.fixedGridDisplay]="fixedGridDisplay"
  src="assets/favicons/similarity/icon_same_usecase.svg"
  alt="Usecase"
  [tooltip]="'respond.similarity.same.usecase' | t"
>

<img
  *ngIf="isSamePlaybook"
  class="icon-pos-3 img24px"
  [class.fixedGridDisplay]="fixedGridDisplay"
  src="assets/favicons/similarity/icon_same_playbook.svg"
  alt="Playbook"
  [tooltip]="'respond.similarity.same.playbook' | t"
>