import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionType } from '@ngxs/store';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { ISensitiveFileDrawerData } from 'projects/@assist-ops/components/drawer/sensitive-file/sensitive-file-drawer/sensitive-file-drawer.container';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { UrlUtils } from 'projects/@common/utils/utils';
import { ISensitiveDataList } from 'projects/console-sg/src/app/pages/sensitive-files/sensitive-files-list/sensitive-files-service';
import { Eco } from '../../../../definitions/eco';
import { EcoUrlService } from '../../core/url.service';
import { DlpType } from '../organizations/organizations.definitions';
import { ListSensitiveFilesRequestParams } from './sensitive-files.definitions';

@Injectable({
  providedIn: 'root',
})
export class SensitiveFilesApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(
    readonly http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url);
  }

  public async action(id: string, body: { type: ActionType; justification: string }): Promise<void> {
    return this.http
      .post<any>(
        `${this.host}/sensitiveFiles/action?${UrlUtils.jsonToQueryParams({ sensitiveFileId: encodeURIComponent(id) })}`,
        body,
        this.getFlagParams({ flags: [FeaturesEnum.DLP_MANAGEMENT] })
      )
      .toPromise();
  }

  public async listSensitiveFiles(
    params: ListSensitiveFilesRequestParams
  ): Promise<Eco.IFetchResult<ISensitiveDataList>> {
    return this.http
      .get<any>(
        `${this.host}/sensitiveFiles?${UrlUtils.jsonToQueryParams(params)}`,
        this.getFlagParams({ flags: [FeaturesEnum.DLP_MANAGEMENT] })
      )
      .toPromise();
  }

  public async describeSensitiveFile(
    sensitiveFileId: string,
    language?: LanguageEnum
  ): Promise<ISensitiveFileDrawerData> {
    return this.http
      .get<ISensitiveFileDrawerData>(
        `${this.host}/sensitiveFiles/specific?${UrlUtils.jsonToQueryParams({ language, sensitiveFileId: encodeURIComponent(sensitiveFileId) })}`,
        this.getFlagParams({ flags: [FeaturesEnum.DLP_MANAGEMENT] })
      )
      .toPromise();
  }

  public async onboardOrganizationInDlp(
    organizationEcoId: string,
    params: { type: DlpType; secretId: string }
  ): Promise<any> {
    return this.http
      .post(
        `${this.host}/sensitiveFiles/${organizationEcoId}/onboard`,
        params,
        this.getFlagParams({ flags: [FeaturesEnum.DLP_MANAGEMENT] })
      )
      .toPromise();
  }

  public async getPurviewActivationState(redirectUrl: string): Promise<{ consentUrl: string }> {
    return this.http
      .get<{ consentUrl: string; }>(`${this.host}/sensitiveFiles/purview/state?${UrlUtils.jsonToQueryParams({ redirectUrl })}`)
      .toPromise();
  }
}
