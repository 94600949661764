<div class="page-container">
  <div class="confirmation-card">
    <div class="center-img">
      <img [src]="'assets/favicons/air-done.svg'" />
    </div>
    <div class="confirmation-title">
      <h2>{{'permission.grant.confirmation.title' | t}}</h2>
    </div>
    <p>{{'permission.grant.confirmation.description' | t}}</p>
  </div>
  <button class="button-spacing" ui-button color="secondary" (click)="close()">
    {{'common.action.close' | t}}
  </button>
</div>
