import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subscription } from "rxjs";


export interface ITabsInfo {
  name: string;
  id: number;
  count?: number;
  icon?: string;
  iconTooltip?: string;
  isBold?: boolean;
}

@Component({
  selector: 'respond-custom-tabs',
  templateUrl: './custom-tabs.component.html',
  styleUrls: [ './custom-tabs.component.scss' ],
})
export class CustomTabsComponent implements OnInit, OnDestroy {
  @Input() tabs: ITabsInfo[];

  @Input() translatePrefix: string;

  @Output() tabsChangeEmitter: EventEmitter<ITabsInfo> = new EventEmitter();

  public defaultTab: ITabsInfo;

  private _currentTabSelected: ITabsInfo;

  private _queryParamSubscription: Subscription = null;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.defaultTab = this.tabs[0];
    this.currentTabSelected = this.defaultTab;
    this._queryParamSubscription = this.subscribeQueryParamsTab();
  }

  ngOnDestroy(): void {
    if (this._queryParamSubscription) {
      this._queryParamSubscription.unsubscribe();
    }
  }

  public set currentTabSelected(tab: ITabsInfo) {
    this._currentTabSelected = tab;
  }

  public get currentTabSelected(): ITabsInfo {
    return this._currentTabSelected;
  }

  public handleTabChange(tab: ITabsInfo): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tab.name.toLowerCase() },
      queryParamsHandling: 'merge',
    });
  }

  private findTabByName(tabName: string): ITabsInfo {
    return this.tabs.find((tab) => tab.name.toLowerCase() === tabName?.toLowerCase());
  }

  private subscribeQueryParamsTab(): Subscription {
    return this.route.queryParams.subscribe((params: Params) => {
      const urlQueriedTab = this.findTabByName(params.tab);
      if (urlQueriedTab) {
        this.currentTabSelected = urlQueriedTab;
        this.tabsChangeEmitter.emit(this.currentTabSelected);
      } else {
        // resets tab to default tab if queried tab does not exist
        this.handleTabChange(this.defaultTab);
      }
    });
  }
}
