import { Component, Inject, Input, OnInit } from '@angular/core';
import { ConsoleType } from '@common/modules/layout/private.layout';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';
import { FiltersService } from '../../filters.service';

export interface IFilterDrawerData {
  filterId: string;
  organizationId: string;
  title: string;
  isReadonly: boolean;
}

export enum FilterDrawerTabsEnum {
  DETAILS = 'DETAILS',
  HISTORY = 'HISTORY'
}

@Component({
  selector: 'respond-filter-drawer',
  templateUrl: './respond-filter-drawer.container.html',
  styleUrls: [ './respond-filter-drawer.container.scss' ],
})
export class RespondFilterDrawerContainer implements OnInit {
  @Input()
  public data: IFilterDrawerData;

  public selectedTab = FilterDrawerTabsEnum.DETAILS;
  public isConsoleAdmin: boolean = true;

  constructor(
    private readonly filtersService: FiltersService,
    private readonly drawerService: DrawerService,
    @Inject('CONSOLE_TYPE') private readonly consoleType: ConsoleType
  ) { }

  ngOnInit(): void {
    this.isConsoleAdmin = this.consoleType === ConsoleType.ADM;
    if (this.data.filterId) {
      this.loadFilter();
      if (this.isConsoleAdmin) {
        this.loadHistory();
      }
    } else {
      this.clearFilter();
    }
  }

  public get filter(): Filter {
    return this.filtersService.filter;
  }

  public get isLoading(): boolean {
    return this.filtersService.isLoadingFilter;
  }

  public get organizationId(): string {
    return this.data.organizationId;
  }

  public get isEditing(): boolean {
    return this.drawerService.isEditing;
  }

  public loadFilter(): void {
    this.filtersService.fetchFilter({ id: this.data.filterId, organizationId: this.data.organizationId });
    if (this.data.isReadonly && !this.filtersService.filter?.id) {
      this.drawerService.hideDrawer();
    }
  }

  public clearFilter(): void {
    this.filtersService.filter = null;
  }

  public loadHistory(): void {
    this.filtersService.fetchHistory(this.data.filterId, this.data.organizationId);
  }
}
