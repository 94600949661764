import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EcoUrlService } from '../../core/url.service';
import { Eco } from '../../../../definitions/eco';
import { ISecretIntegrations, ISecret, ISecretApi } from './secrets.definitions';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { CustomerSecretsApiService } from './customer-secrets.api';
import { VarSecretsApiService } from './var-secrets.api';
import { Store } from '@ngxs/store';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';

@Injectable({
  providedIn: 'root',
})
export class SecretsApiService extends EcoApi implements ISecretApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.tools;

  private readonly integrationHost: string;

  private customerService: CustomerSecretsApiService;
  private varService: VarSecretsApiService;

  constructor(
    protected readonly store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url);
    this.integrationHost = `${url.baseUrl}/tools/integrations`;
    this.customerService = new CustomerSecretsApiService(http, url);
    this.varService = new VarSecretsApiService(http, url);
  }
  public listSecrets(organizationEcoId: string, params: { from?: number; size?: number; order?: string; searchName?: string; searchType?: string; }) {
    if (this.isVarMode) {
      return this.varService.listSecrets(organizationEcoId, params);
    }
    return this.customerService.listSecrets(organizationEcoId, params);
  }

  public describeSecret(organizationEcoId: string, secretId: string): Promise<ISecret> {
    if (this.isVarMode) {
      return this.varService.describeSecret(organizationEcoId, secretId);
    }
    return this.customerService.describeSecret(organizationEcoId, secretId);
  }

  public updateSecret(organizationEcoId: string, secret: any): Promise<ISecret> {
    if (this.isVarMode) {
      return this.varService.updateSecret(organizationEcoId, secret);
    }
    return this.customerService.updateSecret(organizationEcoId, secret);
  }

  public deleteSecret(organizationEcoId: string, secretId: string): Promise<any> {
    if (this.isVarMode) {
      return this.varService.deleteSecret(organizationEcoId, secretId);
    }
    return this.customerService.deleteSecret(organizationEcoId, secretId);
  }

  public createSecret(organizationEcoId: string, secret: any): Promise<ISecret> {
    if (this.isVarMode) {
      return this.varService.createSecret(organizationEcoId, secret);
    }
    return this.customerService.createSecret(organizationEcoId, secret);
  }

  public async listSecretIntegrations(): Promise<ISecretIntegrations[]> {
    const url = `${this.integrationHost}/secrets`;
    const response = await this.http.get<Eco.List<ISecretIntegrations>>(url).toPromise();

    return response.items;
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
