<escalation-expand-card
  [title]="'respond.communication.tab.settings.openhours.title' | t"
  [description]="'respond.communication.tab.settings.openhours.description' | t"
  [titleIconPath]="'assets/favicons/communication/icon_communication_calendar_business_hours.svg'"
  [iconSize]="'20px'"
  [isExpanded]="true"
  [preventCollapse]="isEditing"
  [isConfirmButtonEnabled]="isEditing && isValidTimeRange && isValidTimezone"
  [templateReadonly]="readonlyTemplate"
  [templateEditing]="editingTemplate"
  [showActionButtons]="hasEditPermission"
  (toggleEditEvent)="toggleEdit($event)"
  (saveEvent)="save()"
  (cancelEvent)="cancel()"
></escalation-expand-card>

<ng-template #readonlyTemplate>
  <div class="flex-box center-horizontal gap-025">
    <label ui-label>
      {{'common.from' | t}}
    </label>
    <strong>{{formattedFrom}}</strong>
    <label ui-label>
      {{'common.to' | t}}
    </label>
    <strong>{{formattedTo}}</strong>
    <label ui-label>{{"(" + businessHours.timezone + ")"}}</label>
  </div>
</ng-template>

<ng-template #editingTemplate>
  <div class="flex-box center-horizontal gap-050">
    <label
      ui-label
      for="timestampFrom"
    >
      {{'common.from' | t}}
    </label>
    <ui-time-picker
      #timestampFrom
      [defaultHour]="businessHours.fromHour"
      [defaultMinute]="businessHours.fromMinute"
      [shouldOutputAsHourMinuteObject]="true"
      (timeChangedEvent)="handleTimeChanged($event, 'from')"
    ></ui-time-picker>

    <label
      ui-label
      for="timestampTo"
    >
      {{'common.to' | t}}
    </label>
    <ui-time-picker
      #timestampTo
      [defaultHour]="businessHours.toHour"
      [defaultMinute]="businessHours.toMinute"
      [shouldOutputAsHourMinuteObject]="true"
      (timeChangedEvent)="handleTimeChanged($event, 'to')"
    ></ui-time-picker>

    <timezone-autocomplete
      [defaultTimezoneValue]="businessHours.timezone"
      (timezoneChange)="handleTimezoneSelection($event)"
    ></timezone-autocomplete>
  </div>
</ng-template>