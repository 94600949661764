<label
  *ngIf="!!label"
  ui-label
  class="short-id-label"
>{{label}}</label>

<ng-container *ngIf="shortId; else emptyTemplate">
  <div
    *ngIf="!linkURL; else shortIdWithLinkTemplate"
    class="short-id-value-no-link"
    [tooltip]="'common.action.copy'|t"
    (click)="copyShortIdToClipboard()"
  >
    <img
      class="short-id-value-icon"
      src="assets/favicons/icon_copy.svg"
      alt="icon copy"
    >
    <span class="short-id-value-text">{{shortId}}</span>
  </div>
</ng-container>

<ng-template #shortIdWithLinkTemplate>
  <div class="short-id-value-with-link">
    <img
      class="short-id-value-icon"
      src="assets/favicons/icon_copy.svg"
      alt="icon copy"
      [tooltip]="'common.action.copy'|t"
      (click)="copyShortIdToClipboard()"
    >
    <a
      class="short-id-value-link-url"
      [routerLink]="linkURL"
      [queryParams]="linkQueryParams"
      [target]="linkOpenExternal ? '_blank' : '_self'"
      [tooltip]="linkTooltip"
      [tooltipDelay]="100"
    >
      <span class="short-id-value-text">{{shortId}}</span>
    </a>
  </div>
</ng-template>

<ng-template #emptyTemplate>
  <div class="short-id-value-empty">
    <span class="short-id-value-text">-</span>
  </div>
</ng-template>