<div class="card">
  <div class="header-title-section" [ngClass]="{'is-readonly': isReadonly}">
    <ng-container *ngIf="!hasTemplate()">
      <ui-label-input-with-modify
        [value]="playbook?.suffix"
        [hasLabel]="false"
        [locale]="i18nService.currentLocale"
        [inputStyle]="{
          'font-size': '24px',
          'line-height': '40px',
          height: '40px'
        }"
        [disabled]="isReadonly"
        (onValueChange)="savePlaybookValue(playbookValuesEnum.SUFFIX, $event)"
      >
      </ui-label-input-with-modify>
    </ng-container>
    <ng-container *ngIf="hasTemplate()">
      <div
        class="header-title-section--name-title"
      >
        <div>
          <p class="header-title-section--template-title" 
            *ngIf="playbook?.templateCatalogId || template?.playbookTemplateId">
            {{playbook?.templateCatalogId || template?.playbookTemplateId}}
          </p>
        </div>
        <div class="header-title-section--name-container">
          <h1 *ngIf="playbook?.name || template.name"
              (click)="onTitleClick()"
              (mouseenter)="uiLabelEdit.isHovering = true"
              (mouseleave)="uiLabelEdit.isHovering = false"
              class="header-title-section--name-title--name">
            {{ playbook?.name || template.name | translatedObject }}
          </h1>
          <ui-label-input-with-modify
            #uiLabelEdit
            [value]="playbook?.suffix"
            [placeholder]="'detection.playbook.detail.title.suffix.placeholder' | t"
            [hasLabel]="false"
            [locale]="i18nService.currentLocale"
            [inputStyle]="{
              'font-size': '24px',
              'line-height': '40px',
              height: '40px'
            }"
            [disabled]="isReadonly"
            (onValueChange)="savePlaybookValue(playbookValuesEnum.SUFFIX, $event)"
          >
          </ui-label-input-with-modify>
        </div>
      </div>
    </ng-container>

    <p *ngIf="showHeaderOrgInfo" class="header-title-section--subtitle">
      {{ organizationName }}
    </p>
  </div>

  <div class="card--tabs-section">
    <ng-container *ngFor="let tab of playbookTabService.tabsToDisplay">
      <div
        class="card--tabs-section--tab-button"
        (click)="playbookTabService.setCurrentTab(tab)"
        [ngClass]="
          tab === playbookTabService.currentTabSelected
            ? 'card--tabs-section--tab-button--active'
            : 'card--tabs-section--tab-button'
        "
      >
        <p>{{ 'detection.playbook.detail.' + tab + '.tab' | t }}</p>
      </div>
    </ng-container>
  </div>
</div>
