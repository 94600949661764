import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";

import { ApiEndpointPrefixEnum } from "../../../../definitions/eco-api";
import { EcoUrlService } from "../../core/url.service";
import { IActifsApi, IAssetPropertyConfigRepresentation, IAssetRepresentation, ICreateAssetRequest, ICreateAssetResponse, IDeleteAssetsRequest, IDeleteAssetsResponse, IDescribeAssetResponse, IDescribeAssetsRequest, IListAssetsRequest, IUpdateAssetRequest, IUpdateAssetResponse } from "./actifs.definitions";
import { CustomerActifsApi } from "./customer-actifs.api";
import { VarActifsApi } from "./var-actifs.api";
import { Store } from "@ngxs/store";
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";

@Injectable({
  providedIn: 'root',
})
export class ActifsApi implements IActifsApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  private customerService: CustomerActifsApi;
  private varService: VarActifsApi;

  constructor(
    http: HttpClient,
    url: EcoUrlService,
    protected store: Store
  ) {
    this.customerService = new CustomerActifsApi(http, url);
    this.varService =  new VarActifsApi(http, url);
  }

  public listAssets(request: IListAssetsRequest): Observable<ResponseUtils<IAssetRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listAssets(request);
    }
    return this.customerService.listAssets(request);
  }
  public listAlertAssets(organizationId?: string, alertId?: string): Observable<ResponseUtils<IAssetRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listAlertAssets(organizationId, alertId);
    }
    return this.customerService.listAlertAssets(organizationId, alertId);
  }
  public listIncidentAssets(organizationId?: string, incidentId?: string): Observable<ResponseUtils<IAssetRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listIncidentAssets(organizationId, incidentId);
    }
    return this.customerService.listIncidentAssets(organizationId, incidentId);
  }

  public describeAsset(request: IDescribeAssetsRequest): Observable<IDescribeAssetResponse> {
    if (this.isVarMode) {
      return this.varService.describeAsset(request);
    }
    return this.customerService.describeAsset(request);
  }

  public listAssetTypes(organizationId?: string): Observable<ResponseUtils<IAssetPropertyConfigRepresentation>> {
    if (this.isVarMode) {
      return this.varService.listAssetTypes(organizationId);
    }
    return this.customerService.listAssetTypes(organizationId);
  }

  public createAsset(request: ICreateAssetRequest): Observable<ICreateAssetResponse> {
    if (this.isVarMode) {
      return this.varService.createAsset(request);
    }
    return this.customerService.createAsset(request);
  }

  public updateAsset(request: IUpdateAssetRequest): Observable<IUpdateAssetResponse> {
    if (this.isVarMode) {
      return this.varService.updateAsset(request);
    }
    return this.customerService.updateAsset(request);
  }

  public async deleteAsset(request: IDeleteAssetsRequest): Promise<IDeleteAssetsResponse> {
    if (this.isVarMode) {
      return this.varService.deleteAsset(request);
    }
    return this.customerService.deleteAsset(request);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
