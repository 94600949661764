import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Eco } from "projects/@common/definitions/eco";
import { IncidentHistoryTabFiltersService } from './incident-history-tab-filters.service';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'incident-history-tab-filters',
  templateUrl: './incident-history-tab-filters.component.html',
  styleUrls: ['./incident-history-tab-filters.component.scss'],
  providers: [ IncidentHistoryTabFiltersService ]
})
export class IncidentHistoryTabFiltersComponent implements OnInit, OnDestroy {

  @Input() locale: Eco.Languages;

  @Input() total: number;

  @Input() organizationId: string;

  @Input() isNotificationActionSwitchToggled: boolean;

  constructor(
    public incidentHistoryTabFiltersService: IncidentHistoryTabFiltersService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.incidentHistoryTabFiltersService.initializeHistoryFilters();
  }

  ngOnDestroy(): void { }

  public get indicatorsValues(): MultiSelectData {
    return this.incidentHistoryTabFiltersService.indicatorsValues;
  }

  public applyIndicatorsFilter(value: string[]): void {
    this.incidentHistoryTabFiltersService.applyIndicatorsFilter(value);
  }

  public applyNotificationActionsFilter(value: boolean): void {
    this.incidentHistoryTabFiltersService.applyNotificationActionsFilter(value);
  }

  public clearFilters(): void {
    this.incidentHistoryTabFiltersService.clearAllFilters();
  }

  public hasActiveFilters(): void {
    this.incidentHistoryTabFiltersService.hasActiveFilters;
  }

}