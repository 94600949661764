import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Eco } from 'projects/@common/definitions/eco';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { FiltersViewModeEnum, ListFiltersOrderBy } from 'projects/@common/services/api/respond/filters/filters.definitions';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';
import { clone } from 'projects/@common/utils/utils';
import { FiltersService } from '../../filters.service';

interface ITableGroup {
  filters: Filter[];
  name: string;
  columnsDef: any[];
  isExpanded: boolean;
  icon?: string;
}

@Component({
  selector: 'filters-table',
  templateUrl: './filters-table.component.html',
  styleUrls: [ './filters-table.component.scss' ],
})
export class FiltersTableComponent implements OnInit {
  @Input() public defaultColumnsDef: any[] = [];
  @Input() public filtersData: Filter[] = [];
  @Input() public canCreate: boolean = false;
  @Input() public canDescribe: boolean = false;
  @Input() public canDelete: boolean = false;
  @Input() public isReadonly: boolean = false;
  @Input() public disabledGrouping: boolean = false;

  @Output() public rowClickEvent = new EventEmitter<Filter>();
  @Output() public createEvent = new EventEmitter<void>();
  @Output() public deleteEvent = new EventEmitter<void>();

  public tableGroups: ITableGroup[] = [];

  constructor(
    private readonly filtersService: FiltersService,
    private readonly i18n: I18nService,
    private readonly staticTableDataFactory: StaticTableDataMapper,
    private readonly drawerService: DrawerService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filtersData) {
      this.mapFiltersDataToTableGroups(this.filtersData);
    }
  }

  public get viewMode(): FiltersViewModeEnum {
    return this.filtersService.listViewMode;
  }

  public get isSimpleList(): boolean {
    return this.viewMode === FiltersViewModeEnum.SIMPLE_LIST;
  }

  public get locale(): Eco.Languages {
    return this.i18n.currentLocale as Eco.Languages;
  }

  public get isLoading(): boolean {
    return this.filtersService.isLoading;
  }

  public get direction(): string {
    return "asc";
  }

  public get sort(): ListFiltersOrderBy {
    return ListFiltersOrderBy.name;
  }

  public get selectedCount(): number {
    return this.filtersService.selectedRows?.length || null;
  }

  public get shouldHideCreateButton(): boolean {
    return this.isReadonly || !this.canCreate;
  }

  public get shouldHideDeleteButton(): boolean {
    return this.isReadonly || !this.canDelete || !this.filtersService.selectedRows?.length;
  }

  public onClickRow(row: Filter): void {
    this.rowClickEvent.emit(row);
  }

  public onClickCreate(): void {
    this.createEvent.emit();
  }

  public onClickDelete(): void {
    this.deleteEvent.emit();
  }

  public handleCheckboxEvent(isChecked: boolean, rowItem: any): void {
    if (isChecked) {
      this.filtersService.selectedRows.push(rowItem);
    } else {
      this.filtersService.selectedRows = this.filtersService.selectedRows.filter((item) => item.id !== rowItem.id);
    }
    this.drawerService.hideDrawer();
  }

  private mapFiltersDataToTableGroups(filtersData: Filter[]): void {
    if (this.viewMode === FiltersViewModeEnum.SIMPLE_LIST) {
      this.tableGroups = this.getTableGroupsAsSimpleList(filtersData);
    }
    // else if (this.viewMode === FiltersViewModeEnum.GROUPED_BY_USECASE) {
    //   this.tableGroups = this.getTableGroupsByUsecase(filtersData);
    // }
  }

  private getTableGroupsAsSimpleList(filtersData: Filter[]): ITableGroup[] {
    const [ columns, translatedTableData ] = this.staticTableDataFactory.translate(this.defaultColumnsDef, clone(filtersData));
    return [ {
      columnsDef: columns,
      filters: translatedTableData,
      name: this.i18n.translate('common.all'),
      isExpanded: true,
    } ];
  }
}
