<escalation-expand-card
  [title]="'respond.communication.tab.settings.escalation.title' | t"
  [description]="'respond.communication.tab.settings.escalation.description' | t"
  [titleIconPath]="'assets/favicons/communication/icon_communication_tab_settings.svg'"
  [preventCollapse]="isEditing"
  [isExpanded]="true"
  [isConfirmButtonEnabled]="isEditing && isValid"
  [templateReadonly]="readonlyTemplate"
  [templateEditing]="editingTemplate"
  [showActionButtons]="hasEditPermission"
  (toggleEditEvent)="toggleEdit($event)"
  (saveEvent)="save()"
  (cancelEvent)="cancel()"
></escalation-expand-card>

<ng-template #readonlyTemplate>
  <div class="flex-box column gap-100">
    <table class="escalation-table">
      <ng-container *ngTemplateOutlet="tableHeader; context: {isEditing: false}"></ng-container>
      <tbody>
        <tr
          *ngFor="let delay of delays; let isLast = last; trackBy:trackByFunc;"
          [class.border-bottom]="!isLast"
        >
          <td class="priority priority"><alerting-badge [type]="delay.priority"></alerting-badge></td>

          <ng-container *ngIf="!delay.businessHours.nextBusinessDay; else nextBusinessDayTemplate">
            <td class="reminder border-left">
              <escalation-time-picker
                [type]="'reminder'"
                [timeChoice]="delay.businessHours.reminder"
                [isReadonly]="true"
              ></escalation-time-picker>
            </td>
            <td class="delay">
              <escalation-time-picker
                [type]="'delay'"
                [timeChoice]="delay.businessHours.delay"
                [isReadonly]="true"
              ></escalation-time-picker>
            </td>
          </ng-container>

          <ng-container *ngIf="delay.offHours; else readonlySameOffHours">
            <ng-container *ngIf="!delay.offHours?.nextBusinessDay; else nextBusinessDayTemplate">
              <td class="reminder border-left">
                <escalation-time-picker
                  [type]="'reminder'"
                  [timeChoice]="delay.offHours.reminder"
                  [isReadonly]="true"
                ></escalation-time-picker>
              </td>
              <td class="delay">
                <escalation-time-picker
                  [type]="'delay'"
                  [timeChoice]="delay.offHours.delay"
                  [isReadonly]="true"
                ></escalation-time-picker>
              </td>
            </ng-container>
          </ng-container>
          <ng-template #readonlySameOffHours>
            <ng-container *ngTemplateOutlet="idemTemplate; context: {isEditing: false}"></ng-container>
          </ng-template>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #editingTemplate>
  <div class="flex-box column gap-100">
    <ui-toggle
      [labelEnabled]="'respond.communication.tab.settings.escalation.toggleDifferent' | t"
      [labelDisabled]="'respond.communication.tab.settings.escalation.toggleDifferent' | t"
      [toggled]="useOffHours"
      [disabledWhenUntoggled]="false"
      [boldLabel]="false"
      [ignoreLabelClicks]="true"
      (onClick)="toggleBusinessSchedule($event)"
    ></ui-toggle>

    <table class="escalation-table">
      <ng-container *ngTemplateOutlet="tableHeader; context: {isEditing: true}"></ng-container>
      <tbody>
        <tr
          *ngFor="let delay of delays; let i = index; let isLast = last; trackBy:trackByFunc;"
          [class.border-bottom]="!isLast"
        >
          <td class="priority priority"><alerting-badge [type]="delay.priority"></alerting-badge></td>

          <ng-container *ngIf="!delay.businessHours.nextBusinessDay; else nextBusinessDayTemplate">
            <td class="reminder border-left">
              <escalation-time-picker
                [type]="'reminder'"
                [timeChoice]="delay.businessHours.reminder"
                [isReadonly]="false"
                (timeChoiceChange)="delay.businessHours.reminder = $event"
              ></escalation-time-picker>
            </td>
            <td class="delay">
              <escalation-time-picker
                [type]="'delay'"
                [timeChoice]="delay.businessHours.delay"
                [isReadonly]="false"
                (timeChoiceChange)="delay.businessHours.delay = $event"
              ></escalation-time-picker>
            </td>
          </ng-container>
          <td class="checkbox">
            <mat-checkbox
              [checked]="delay.businessHours.nextBusinessDay"
              [disabled]="isDisabledCheckBox(i, 'businessHours')"
              (change)="setNextBusinessDaysState(delay, $event.checked, 'businessHours')"
            ></mat-checkbox>
          </td>

          <ng-container *ngIf="useOffHours; else editingSameOffHours">
            <ng-container *ngIf="!delay.offHours?.nextBusinessDay; else nextBusinessDayTemplate">
              <td class="reminder border-left">
                <escalation-time-picker
                  [type]="'reminder'"
                  [timeChoice]="delay.offHours.reminder"
                  [isReadonly]="false"
                  (timeChoiceChange)="delay.offHours.reminder = $event"
                ></escalation-time-picker>
              </td>
              <td class="delay">
                <escalation-time-picker
                  [type]="'delay'"
                  [timeChoice]="delay.offHours.delay"
                  [isReadonly]="false"
                  (timeChoiceChange)="delay.offHours.delay = $event"
                ></escalation-time-picker>
              </td>
            </ng-container>
            <td class="checkbox">
              <mat-checkbox
                [checked]="delay.offHours?.nextBusinessDay"
                [disabled]="isDisabledCheckBox(i, 'offHours')"
                (change)="setNextBusinessDaysState(delay, $event.checked, 'offHours')"
              ></mat-checkbox>
            </td>
          </ng-container>
          <ng-template #editingSameOffHours>
            <ng-container *ngTemplateOutlet="idemTemplate; context: {isEditing: true}"></ng-container>
          </ng-template>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>


<ng-template
  #tableHeader
  let-isEditing="isEditing"
>
  <thead>
    <tr class="border-bottom first-header-row">
      <th
        colspan="1"
        class="priority"
      ></th>
      <th
        [attr.colspan]="isEditing ? 3 : 2"
        class="border-left bold"
      >
        {{'respond.communication.tab.settings.escalation.table.businessHours' | t}}
      </th>
      <th
        [attr.colspan]="isEditing ? 3 : 2"
        class="border-left bold"
      >
        {{'respond.communication.tab.settings.escalation.table.offHours' | t}}
      </th>
    </tr>

    <tr class="border-bottom second-header-row">
      <th
        colspan="1"
        class="priority"
      >
        {{'respond.communication.tab.settings.escalation.table.priority' | t}}
      </th>
      <th
        colspan="1"
        class=" border-left"
      >
        {{'respond.communication.tab.settings.escalation.table.reminder' | t}}
      </th>
      <th colspan="1">
        {{'respond.communication.tab.settings.escalation.table.delay' | t}}
      </th>
      <th
        *ngIf="isEditing"
        colspan="1"
        class="flex-box center-horizontal center-vertical"
      >
        <span>{{'respond.communication.tab.settings.escalation.table.pjo.title' | t}}</span>
        <img
          [src]="'assets/favicons/icon_info_small.svg'"
          [tooltip]="'respond.communication.tab.settings.escalation.table.pjo.tooltip' | t"
          alt="infoIcon"
        />
      </th>
      <th
        colspan="1"
        class=" border-left"
      >
        {{'respond.communication.tab.settings.escalation.table.reminder' | t}}
      </th>
      <th colspan="1">
        {{'respond.communication.tab.settings.escalation.table.delay' | t}}
      </th>
      <th
        *ngIf="isEditing"
        colspan="1"
        class="flex-box center-horizontal center-vertical"
      >
        <span>{{'respond.communication.tab.settings.escalation.table.pjo.title' | t}}</span>
        <img
          [src]="'assets/favicons/icon_info_small.svg'"
          [tooltip]="'respond.communication.tab.settings.escalation.table.pjo.tooltip' | t"
          alt="infoIcon"
        />
      </th>
    </tr>
  </thead>
</ng-template>

<ng-template
  #idemTemplate
  let-isEditing="isEditing"
>
  <td
    class="idem border-left"
    [attr.colspan]="isEditing ? 3 : 2"
  >{{"respond.communication.tab.settings.escalation.table.same" | t}}</td>
</ng-template>

<ng-template #nextBusinessDayTemplate>
  <td class="reminder border-left">
    {{"respond.communication.tab.settings.escalation.table.pjo.time" | t:{ time: nextBusinessDayValues.reminder } }}
  </td>
  <td class="delay">
    {{"respond.communication.tab.settings.escalation.table.pjo.time" | t:{ time: nextBusinessDayValues.delay } }}
  </td>
</ng-template>