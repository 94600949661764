<div class="section-container">
  <ui-card>
    <ui-static-table [loading]="isLoadingHistory" [dataSource]="usecaseHistoryTableData" [addPaddingOnFirst]="false"
      [columnsDef]="tableColumnsDefinition" [expendedTemplate]="history" [locale]="i18n.currentLocale">
    </ui-static-table>
  </ui-card>
  <div class="button-container" *ngIf="usecaseHistoryLastResponse && usecaseHistoryLastResponse.canLoadMore()">
    <ui-table-refresh-tool class="refresher" [loading]="isLoadingHistory" [text]="'common.showMore' | t" rightSide
      (click)="loadMoreHistoricalData()"></ui-table-refresh-tool>
  </div>
</div>

<ng-template #history let-usecaseHistory="row" let-isExpanded="isSelect">
  <app-history-detail *ngIf="isExpanded" [history]="usecaseHistory"></app-history-detail>
</ng-template>