import { Component, Input, OnInit } from "@angular/core";
import { DTPipe } from "@ui-kit/pipes/dt.pipe";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { DatasourceInventoryInfo } from "projects/@common/services/api/respond/datasources/datasources.definitions";

@Component({
  selector: 'datasource-card',
  templateUrl: './datasource-card.component.html',
  styleUrls: [ './datasource-card.component.scss' ],
  providers: [ DTPipe ],
})
export class DatasourceCardComponent implements OnInit {
  @Input()
    data: DatasourceInventoryInfo;

  date: { activatedAt: string; updatedAt: string };

  constructor(private readonly dt: DTPipe, public i18n: I18nService) {
  }

  ngOnInit(): void {
    this.date = {
      activatedAt: this.dt.transform(this.data.activatedAt, { withTime: false, locale: this.i18n.currentLocale }),
      updatedAt: this.dt.transform(this.data.updatedAt, { withTime: false, locale: this.i18n.currentLocale }),
    };
  }
}

