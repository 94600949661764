import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AutocompleteCustomValue } from "@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component";
import { ModalService } from "@ui-kit/services/modal.service";

import { SpecificTaskForm } from "../../forms/specific-task.form";
import { ITextareaSpecificTaskParams } from "./components/specific-task-textarea/specific-task-textarea.component";
import { LanguageEnum } from "@ui-kit/interfaces/ILanguage";

export interface ISpecificTaskData {
  specificTaskForm: SpecificTaskForm;
  organizationId: string;
  paramsList: AutocompleteCustomValue[];
  title?: string;
  save?: string;
  cantIgnore?: boolean;
  requiredLanguage?: LanguageEnum;
  value?: any;
  callback: any;
}

@Component({
  selector: 'specific-task',
  templateUrl: './specific-task.component.html',
  styleUrls: [ './specific-task.component.scss' ],
})
export class SpecificTaskComponent implements OnInit {
  public specificTaskForm: SpecificTaskForm;
  public organizationId: string;
  public taskInputArray: ITextareaSpecificTaskParams[] = [];
  public requiredLanguage: LanguageEnum;
  public paramsList: AutocompleteCustomValue[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ISpecificTaskData,
    public modalService: ModalService
  ) {
    this.specificTaskForm = data.specificTaskForm;
    this.organizationId = data.organizationId;
    this.paramsList = data.paramsList;
    this.requiredLanguage = data.requiredLanguage;
  }

  ngOnInit(): void {
    this.setupInputInformation();
    if (this.data.value) {
      this.specificTaskForm.taskForm.patchValue(this.data.value);
    }
  }

  public save(): void {
    if (this.data.callback) {
      this.data.callback({ ...this.specificTaskForm.values, id: this.data.value?.id });
    }
    this.specificTaskForm.resetForm();
    this.modalService.closeDialog();
  }

  private setupInputInformation(): void {
    this.taskInputArray = [
      {
        label: 'detection.playbook.task.specific.task-name',
        formGroup: this.specificTaskForm.nameFormGroup,
        maximumChar: 150,
        textareaStyleParams: {
          height: 70,
          resizable: false,
        },
        placeholder: 'detection.playbook.task.specific.task-name.placeholder',
        isRequired: true,
      },
      {
        label: 'detection.playbook.task.specific.task-description',
        formGroup: this.specificTaskForm.descriptionFormGroup,
        textareaStyleParams: {
          height: 140,
          resizable: true,
        },
        placeholder: 'detection.playbook.task.specific.task-description.placeholder',
        isRequired: true,
        iconName: 'icon_autres_demandes',
      },
      {
        label: 'detection.playbook.task.specific.task-instruction',
        formGroup: this.specificTaskForm.instructionsFormGroup,
        textareaStyleParams: {
          height: 140,
          resizable: true,
        },
        placeholder: 'detection.playbook.task.specific.task-instruction.placeholder',
        isRequired: true,
        iconName: 'icon_grey_checkbox_task',
      },
    ];
  }
}
