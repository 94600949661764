import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RuleSet } from 'angular2-query-builder';
import * as isoCountries from 'i18n-iso-countries';
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';


isoCountries.registerLocale(fr);
isoCountries.registerLocale(en);

@Component({
  selector: 'filter-conditions',
  templateUrl: './filter-conditions.component.html',
})
export class FilterConditionsComponent implements OnInit {
  @Input() public filter: Filter;
  @Input() public conditionFields: any;
  @Input() public isDisabled: boolean;
  @Output() public filterConditionsChange: EventEmitter<RuleSet> = new EventEmitter();

  ngOnInit(): void {
    if (!this.filter.conditions || JSON.stringify(this.filter.conditions) === JSON.stringify({})) {
      this.filter.conditions = { condition: 'and', rules: [] };
    }
  }
}
