<div class="detail-layout">
  <div class="detail-layout--back-wrapper" *ngIf="backLink">
    <a [routerLink]="backLink">
      <button
        ui-button
        color="listActionFlat"
        class="margin--small--bottom"
        icon="icon_back"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="medium"
      >
        {{ 'common.action.return' | t }}
      </button>
    </a>
  </div>

  <div class="creation-layout" *ngIf="isLoaded; else spinner">
    <div class="creation-layout--title-wrapper">
      <h1 *ngIf="playbookTemplateName;else withoutPlaybookTemplateName" class="creation-layout--title-wrapper--title">
        {{ 'detection.playbook.creation.title.additional' | t: {playbookTemplateName: playbookTemplateName | translatedObject} }}
      </h1>
      <ng-template #withoutPlaybookTemplateName>
        <h1 class="creation-layout--title-wrapper--title">
          {{ 'detection.playbook.creation.title' | t }}
        </h1>
      </ng-template>
    </div>
    <p class="org-name">{{ organizationName }}</p>
    <div class="creation-layout--stepper">
      <mat-horizontal-stepper [linear]="true" #stepper class="stepper">
        <mat-step
          [stepControl]="playbookCreationForm.formGroup"
          state="properties"
        >
          <ng-template matStepLabel>
            <h4>{{ 'detection.playbook.creation.step.property.name' | t }}</h4>
          </ng-template>
          <div class="properties--content">
            <playbook-creation-properties
              [playbookCreationForm]="playbookCreationForm"
              [playbookTemplate]="playbookTemplate"
              [playbook]="playbook"
              (playbookPickedEvent)="handlePlaybookPickedEvent($event)"
            ></playbook-creation-properties>
          </div>
        </mat-step>
        <div>
          <mat-step state="tasks">
            <ng-template matStepLabel>
              <h4>{{ 'detection.playbook.creation.step.task' | t }}</h4>
            </ng-template>
            <div class="task-container">
              <div class="task-content">
                <div>
                  <playbook-tasks-drag-and-drop [playbook]="playbook"
                                                [organizationId]="organizationEcoId"
                                                [mdTasks]="mdTasks">

                    <div ngProjectAs="header" class="playbook-helper">
                      <ui-card>
                        <div>
                          <h3>{{ 'detection.playbook.creation.step.task.title' | t }}</h3>
                          <div>
                            <p>
                              {{ 'detection.playbook.creation.step.task.description' | t }}
                            </p>
                          </div>
                        </div>
                      </ui-card>
                    </div>

                    <div ngProjectAs="footer" class="footer">
                      <ui-card>
                        <div class="footer--button-container">
                          <div class="footer--button-left">
                            <button ui-button color="secondary" (click)="handleCancel()">
                              {{ 'common.action.cancel' | t }}
                            </button>
                          </div>
                          <div class="footer--button-right">
                            <div>
                              <button ui-button color="secondary" matStepperPrevious>
                                {{ 'common.action.previous' | t }}
                              </button>
                            </div>
                            <div>
                              <button ui-button color="standard" (click)="handleCreateEvent()">
                                {{ 'common.action.save' | t }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </ui-card>
                    </div>
                  </playbook-tasks-drag-and-drop>
                </div>
              </div>
            </div>
          </mat-step>
        </div>

        <ng-template matStepperIcon="properties">
          <div></div>
        </ng-template>

        <ng-template matStepperIcon="tasks">
          <div></div>
        </ng-template>

        <ng-template matStepperIcon="edit">
          <div></div>
        </ng-template>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>

<ng-template #spinner>
  <ui-spinner></ui-spinner>
</ng-template>
