import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-incident-details-base-layout',
  templateUrl: './incident-details-base-layout.component.html',
  styleUrls: [ './incident-details-base-layout.component.scss' ],
})
export class IncidentDetailsBaseLayoutComponent implements OnInit {
  @Input() title: string;
  @Input() uiTooltip: string;

  constructor() { }

  ngOnInit(): void {
  }
}
