import { Component, Input, OnInit } from '@angular/core';
import { PeriodicReportsApi } from 'projects/@common/services/api/respond/periodic-reports/periodic-reports.api';

@Component({
  selector: 'respond-reports-print-button',
  templateUrl: './respond-reports-print-button.component.html',
  styleUrls: [ './respond-reports-print-button.component.scss' ],
})
export class RespondReportsPrintButtonComponent implements OnInit {
  @Input() report: any;

  public isDisabled = false;

  constructor(private reportsApi: PeriodicReportsApi) { }

  ngOnInit(): void {

  }

  public printReport(): void {
    this.reportsApi.getReportDownloadUrl(this.report.id, this.report.organizationId).subscribe((res) => {
      const link = document.createElement('a');
      link.setAttribute("target", "_blank");
      link.href = res.url;
      link.download = 'report.pdf';
      link.click();
      link.remove();
    });
  }
}
