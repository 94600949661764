import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalService } from '@ui-kit/services/modal.service';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ConnectorsApiOrganizationService } from 'projects/@common/services/api/detect/organizations/connectors-api-organizations';
import { DescribeOrganizationResponse } from 'projects/@common/services/api/detect/organizations/definitions';
import { IFilter, IFilterBaseRepresentation, IFiltersListViewParams } from 'projects/@common/services/api/respond/filters/filters.definitions';
import { Filter } from 'projects/@common/services/api/respond/filters/models/Filter';
import { FiltersDeleteModalComponent, IFiltersDeleteModalData } from '../../components/filters-delete-modal/filters-delete-modal.component';
import { FiltersService } from '../../filters.service';
import { IFilterDrawerData, RespondFilterDrawerContainer } from '../respond-filter-drawer/respond-filter-drawer.container';

@Component({
  selector: 'respond-filters',
  templateUrl: './respond-filters.container.html',
  styleUrls: [ './respond-filters.container.scss' ],
})
export class RespondFiltersContainer implements OnInit {
  @Input() public viewParams: IFiltersListViewParams;

  public activeOrganization: DescribeOrganizationResponse;

  public canList: boolean = false;
  public canCreate: boolean = false;
  public canDelete: boolean = false;
  public canUpdate: boolean = false;
  public canDescribe: boolean = false;

  public isVarMode: boolean = false;

  constructor(
    private readonly filtersService: FiltersService,
    private readonly i18n: I18nService,
    private readonly displayService: DisplayService,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    private readonly modalService: ModalService,
    private readonly store: Store,
    private drawerService: DrawerService
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  ngOnInit(): void {
    this.filtersService.setDefaultSettings();
    if (this.viewParams.visibleOnly) {
      this.filtersService.visibleFiltersOnly = this.viewParams.visibleOnly;
    }
    if (this.viewParams.hiddenOnly) {
      this.filtersService.hiddenFiltersOnly = this.viewParams.hiddenOnly;
    }
    this.canList = this.displayService.meetsRequirements({ permissions: [ 'can_list_filters' ] });
    this.canCreate = this.displayService.meetsRequirements({ permissions: [ 'can_create_filter' ] });
    this.canDelete = this.displayService.meetsRequirements({ permissions: [ 'can_delete_filter' ] });
    this.canUpdate = this.displayService.meetsRequirements({ permissions: [ 'can_update_filter' ] });
    this.canDescribe = this.displayService.meetsRequirements({ permissions: [ 'can_describe_filter' ] });
    if (this.canList && !this.showEmptyPageToSelectOrganization) {
      // immediate fetch for consoles that do not have an organization selector
      this.fetchFilters();
    }
  }

  public get filtersData(): Array<IFilterBaseRepresentation> {
    return this.filtersService.filtersData;
  }

  public get showEmptyPageToSelectOrganization(): boolean {
    return this.isVarMode && !this.activeOrganization;
  }

  public getOrganizationId(filter?: IFilterBaseRepresentation): string {
    if (filter) {
      return filter.organizationId; // existing filter
    }
    if (this.activeOrganization) {
      return this.activeOrganization.organization_respond_id; // using organization selector
    }
    return this.store.selectSnapshot(EcoSessionState.organization).id; // logged in user's organization
  }

  public fetchFilters(): void {
    this.filtersService.fetchFilters();
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    this.drawerService.hideDrawer();
    if (org) {
      this.activeOrganization = await this.connectorsApiOrganizationService.describeOrganization(org.id);
      this.filtersService.setOrganizationId(this.activeOrganization.organization_respond_id);
      this.fetchFilters();
    } else {
      this.activeOrganization = null;
    }
  }

  public openFilterDrawer(filter: IFilter | null): void {
    const drawerInputData: IFilterDrawerData = {
      title: filter ? filter.name : this.i18n.translate(`filters.drawer.title.create`),
      filterId: filter ? filter.id : null,
      organizationId: this.getOrganizationId(filter),
      isReadonly: !this.canUpdate || this.viewParams.isReadonly,
      filterType: filter?.filterType,
    };
    this.drawerService.replaceCurrentDrawer(RespondFilterDrawerContainer, {
      component: DrawerPageEnum.filterDrawer,
      data: drawerInputData,
    });
  }

  public openDeleteModal(): void {
    const dialogData: IFiltersDeleteModalData = {
      filters: this.filtersService.selectedRows.map((selectedRow) => new Filter(selectedRow.filterType, selectedRow)),
    };
    this.modalService.openDialog(FiltersDeleteModalComponent, dialogData);
  }
}
