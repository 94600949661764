<widget-container
  class="large-widget"
  [title]="
    'dashboard.admin.sharedFiles.tendency.title.' + translationSuffix | t
  "
  [subtitle]="
    'dashboard.admin.sharedFiles.tendency.subtitle.' + translationSuffix | t
  "
  [withBottomMargin]="true"
  [withTimeRange]="true"
  [timeRanges]="TIME_RANGE_ENUM"
  [timeRangesDefault]="currentTimeRange"
  (selectedTimerange)="handleTimeRange($event)"
  [locale]="locale"
>
  <div class="admin-tendency-widget--chart">
    <ui-spinner
      *ngIf="isLoading"
      size="medium"
      modifier="secondary"
    ></ui-spinner>

    <dynamic-chart
      *ngIf="data?.length && !isLoading"
      [data]="data"
      [type]="CHART_TYPE"
      [key]="KEY"
      [series]="[SHARES_LINE, REGRESSION_LINE]"
      [legend]="legend"
      [name]="
        isHighRiskOnly
          ? 'high_risk_shared_files_tendency'
          : 'total_shared_files_tendency'
      "
      [colors]="colors"
      [importantLine]="SHARES_LINE"
      [axisFormattingSettings]="axisFormattingSettings"
      chartWidth="350px"
    ></dynamic-chart>

    <div
      *ngIf="!data?.length && !isLoading"
      class="admin-tendency-widget--no-history"
    >
      {{ 'dashboard.admin.noData' | t }}
    </div>
  </div>
</widget-container>
