<div class="inventory-layout">
  <div class="inventory-layout--top-section">
    <ng-content select="[topSection]"></ng-content>
  </div>

  <div
    class="inventory-layout--title-wrapper"
    [class.inventory-layout--title-wrapper--spaced]="actionSpaced"
  >
  <div class="inventory-layout--title-wrapper--title">
    <h1 class="inventory-layout--title-wrapper--title--text">{{ title }}</h1>
    <div *ngIf="previewMode" class="inventory-layout--title-wrapper--title--beta">
      {{ 'common.previewMode.' + previewMode | t }}
    </div>
  </div>
    <div class="inventory-layout--title-wrapper--actions-bar">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>

  <div class="inventory-layout--content">
    <ng-content></ng-content>
  </div>
</div>
