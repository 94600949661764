import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UiButtonColor } from '@ui-kit/components/ui-button/ui-button.component';
import { DynamicFormField } from 'projects/@common/components/dynamic-form';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SetDrawerIsEditing } from 'projects/@common/modules/layout/components/drawer/stores/drawer.store';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { ActifsApi } from 'projects/@common/services/api/respond/actifs/actifs.api';
import { ICreateAssetPropertyRequest, ICreateAssetRequest, IDescribeAssetResponse, IListAssetType, IUpdateAssetRequest, PropertyType } from 'projects/@common/services/api/respond/actifs/actifs.definitions';
import { IAssetDynamicFormField } from 'projects/@common/services/api/respond/actifs/models/IActif';
import { FormErrorUtils } from 'projects/@common/utils/formErrors-utils';
import { clone, getHttpErrorMessage } from 'projects/@common/utils/utils';
import { CustomValidators } from 'projects/@common/utils/validators';
import { ActifsService } from '../../actifs.service';
import { AssetDynamicFieldHandler } from './assetDynamicFieldHandler';
import { ValueAxis } from '@amcharts/amcharts4/charts';

@Component({
  selector: 'actif-dynamic-component',
  templateUrl: './actif-dynamic-component.component.html',
  styleUrls: [ './actif-dynamic-component.component.scss' ],
})
export class ActifDynamicComponentComponent implements OnInit {
  @Input()
    assetTypes: IListAssetType[];

  @Input()
    currentAsset: IDescribeAssetResponse;

  @Input()
    isReadonly: boolean;

  @Input()
    organizationId: string;

  public isEditing = false;
  public isTemplateAvailable = false;
  public temporaryForm: UntypedFormGroup;

  public form: UntypedFormGroup;

  public assetDynamicFieldHandler: AssetDynamicFieldHandler;
  public errorUtils: FormErrorUtils;

  public fieldValues: { key: string, form: AbstractControl, fieldValue: IAssetDynamicFormField; }[] = [];
  public temporaryfieldValues: { key: string, form: AbstractControl, fieldValue: IAssetDynamicFormField; }[] = [];

  public typeSelectOptions: { value: string; displayValue: string; }[] = [];
  public dynamicFormFields: IAssetDynamicFormField[];

  public uiButtonColor = UiButtonColor.ListActionFlatGray;

  constructor(
    public readonly i8nService: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly actifsService: ActifsService,
    private readonly store: Store,
    private readonly actifsApi: ActifsApi,
    private readonly noticeService: NoticeService
  ) {
    this.assetDynamicFieldHandler = new AssetDynamicFieldHandler(formBuilder, i8nService);
    this.errorUtils = new FormErrorUtils(i8nService);
  }

  ngOnInit(): void {
    this.mapAssetTypes();
    this.createForm();
    this.initTemplate();
  }

  public get propertiesGroup(): UntypedFormGroup {
    return (this.form.get('properties') as UntypedFormGroup);
  }

  public get isValid(): boolean {
    return this.form.valid;
  }

  public get nameControl(): AbstractControl {
    return this.form.get('name');
  }

  public setEditing(): void {
    this.temporaryForm = clone(this.form);
    this.temporaryfieldValues = clone(this.fieldValues);
    this.isEditing = true;
    this.store.dispatch(new SetDrawerIsEditing({ isEditing: this.isEditing, tabName: 'ActifDrawerComponent' }));
  }

  public cancel(): void {
    this.resetOldForm();
    this.closeEditing();
  }

  private resetOldForm(): void {
    this.form = this.temporaryForm;
    this.fieldValues = this.temporaryfieldValues;
    this.mapFieldValues();
  }

  public addTemplate(type: string): void {
    this.clearCurrentTemplate();
    this.createAssetLinkValues(type);
    this.getCurrentTemplateFields(type);

    this.form.addControl('properties', this.assetDynamicFieldHandler.createForm(this.currentAsset));

    if (this.currentAsset) {
      this.form.patchValue({ name: this.currentAsset.asset.name, note: this.currentAsset.asset.note, type: this.currentAsset.asset.type });
    }

    this.mapFieldValues();
    this.isTemplateAvailable = true;
  }

  public getFormField(id: string, index: number): DynamicFormField {
    const newFields = { ...this.dynamicFormFields?.find((value) => value.id === id)?.dynamicForm };
    newFields.fieldName = index.toString();
    if (index > 0) {
      newFields.label = null;
      newFields.tooltip = null;
    }

    return newFields;
  }

  public showDelete(form: UntypedFormArray, field: { key: string, form: AbstractControl, fieldValue: IAssetDynamicFormField; }, i: number): boolean {
    if (field.fieldValue?.dynamicForm?.values?.length > 0 && !field.fieldValue?.dynamicForm.required && form.at(i).value) {
      return true;
    }

    return field.fieldValue.multiple && form.length > 1;
  }

  public add(id: string): void {
    (this.propertiesGroup.get(id) as UntypedFormArray).push(this.assetDynamicFieldHandler.getNewFieldControl(id));
  }

  public remove(id: string, index: number, field: { key: string, form: AbstractControl, fieldValue: IAssetDynamicFormField; }): void {
    const formArray = (this.propertiesGroup.get(id) as UntypedFormArray);
    const dynamicForm = field.fieldValue?.dynamicForm;

    if (formArray.at(index).value && !dynamicForm.required && formArray.length === 1 && dynamicForm?.values?.length > 0) {
      formArray.at(index).setValue(null);
    } else {
      formArray.removeAt(index);
    }
  }

  public getReadonlyValue(formField: DynamicFormField, value: string): string {
    return formField?.values?.length
      ? formField?.values?.find((option) => option.value === value)?.displayValue || '-'
      : value;
  }

  public handleSave(): void {
    const values = this.form.getRawValue();
    const properties: ICreateAssetPropertyRequest[] = [];

    for (const value in (values.properties as Object)) {
      values.properties[value] = values.properties[value]?.filter((value: string) => value);
      if (values.properties[value].length) {
        properties.push({ propertyId: value, values: values.properties[value] });
      }
    }

    this.currentAsset
      ? this.update(this.getUpdateDto(values, properties))
      : this.save(this.getCreateDto(values, properties));
  }

  public isTooltipValid(tooltip: ITranslatedField): boolean {
    return !!tooltip?.en || !!tooltip?.fr;
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      name: [ '', Validators.required ],
      note: [ '' ],
      type: [ null ],
    });
  }

  private mapFieldValues(): void {
    this.fieldValues = [];
    for (const key in this.propertiesGroup.controls) {
      this.fieldValues.push({
        key,
        form: this.propertiesGroup.get(key),
        fieldValue: this.dynamicFormFields.find((field) => field.id === key),
      });
    }

    this.fieldValues
      .filter((fieldValue) => !!fieldValue?.fieldValue?.dynamicForm?.values)
      .forEach((fieldValue) => {
        fieldValue.fieldValue.dynamicForm.values = fieldValue.fieldValue.dynamicForm?.values.map((value) => ({
          value: value.value,
          displayValue: value.label
        })) || null;
    });
  }

  private getCurrentTemplateFields(type: string): void {
    this.dynamicFormFields = this.assetDynamicFieldHandler.createDynamicForm(this.assetTypes.find((asset) => asset.type === type));
  }

  private createAssetLinkValues(type: string): void {
    const assetTemplate: IListAssetType = this.assetTypes.find((asset) => asset.type === type);
    const assetConfig = assetTemplate.config.properties.find((config) => config.type === PropertyType.ASSET);
    if (assetConfig) {
      assetConfig.options = this.actifsService.assetsData
        .filter((data) => data.type === assetConfig.linkedAsset.type && (this.currentAsset?.asset.id !== data.id))
        .map((data) => ({ key: data.id, value: { en: data.name, fr: data.name } }));
    }
  }

  private initTemplate(): void {
    if (this.currentAsset) {
      this.addTemplate(this.currentAsset.asset.type);
    } else {
      this.isEditing = true;
    }
  }

  private closeEditing(): void {
    this.isEditing = false;
    this.store.dispatch(new SetDrawerIsEditing({ isEditing: this.isEditing, tabName: 'ActifDrawerComponent' }));
  }

  private clearCurrentTemplate(): void {
    this.fieldValues = [];
    this.isTemplateAvailable = false;

    if (this.propertiesGroup) {
      this.form.removeControl('properties');
    }
  }

  private mapAssetTypes(): void {
    this.assetTypes.forEach((asset) => {
      this.typeSelectOptions.push({ value: asset.type, displayValue: asset.config.name[this.i8nService.currentLocale] });
    });
  }

  private update(request: IUpdateAssetRequest): void {
    this.actifsApi.updateAsset(request).subscribe(
      (response) => {
        this.noticeService.notifyUser(new Notice('actifs.modal.update.success', NoticeLevels.SUCCESS));
        this.actifsService.fetchAssets();
        this.currentAsset = response;
        this.initTemplate();
        this.closeEditing();
      },
      (error) => {
        this.noticeService.notifyUser(new Notice('actifs.modal.update.error', NoticeLevels.ERROR, getHttpErrorMessage(error)));
      }
    );
  }

  private save(request: ICreateAssetRequest): void {
    this.actifsApi.createAsset(request).subscribe(
      (response) => {
        this.noticeService.notifyUser(new Notice('actifs.modal.create.success', NoticeLevels.SUCCESS));
        this.actifsService.fetchAssets();
        this.currentAsset = response;
        this.initTemplate();
        this.closeEditing();
      },
      (error) => {
        const errorMessage = error.error?.code ? `actifs.drawer.error.${error.error.code}` : 'actifs.modal.create.error';
        this.noticeService.notifyUser(new Notice(errorMessage, NoticeLevels.ERROR));

        if (error?.error?.code === 'DUPLICATE_ASSET_NAME') {
          this.nameControl.setValidators([ CustomValidators.validateIsADifferentValue(this.nameControl.value) ]);
          this.nameControl.updateValueAndValidity();
        }
      }
    );
  }

  private getCreateDto(values: any, properties: ICreateAssetPropertyRequest[]): ICreateAssetRequest {
    return {
      name: values.name,
      note: values.note,
      type: values.type,
      organizationId: this.organizationId,
      properties,
    };
  }

  private getUpdateDto(values: any, properties: ICreateAssetPropertyRequest[]): IUpdateAssetRequest {
    return {
      id: this.currentAsset.asset.id,
      organizationId: this.currentAsset.asset.organizationId,
      name: values.name,
      note: values.note,
      properties,
    };
  }
}
