import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { IPeriodicReportRepresentation, PeriodicReportTypeEnum } from 'projects/@common/services/api/respond/periodic-reports/periodic-reports.definitions';

@Component({
  selector: 'respond-reports-period-code',
  templateUrl:  './respond-reports-period-code.component.html',
  styleUrls: [ './respond-reports-period-code.component.scss' ],
})
export class RespondReportsPeriodCodeComponent implements OnInit {
  @Input() public report: IPeriodicReportRepresentation;

  public periodCodeLabel: string;

  constructor(private i18n: I18nService) {}

  ngOnInit(): void {
    // The back-end could return a custom report period type.
    // In that case, the periodCode will be null
    switch (this.report.type) {
      case PeriodicReportTypeEnum.MONTHLY:
        this.periodCodeLabel = 'M';
        break;
      case PeriodicReportTypeEnum.WEEKLY:
        this.periodCodeLabel = this.i18n.currentLocale === 'en' ? 'W' : 'S';
        break;
      default:
        break;
    }
  }
}
