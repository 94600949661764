<div class="drawer-detail--scrollable visible">
  <div class="drawer-detail--content">
    <div class="margin--small--top margin--small--bottom">
      <ui-field-group>
        <ui-static
          label="{{'org_usecase.table.column.id' | t}}"
          [value]="currentUsecase.id"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          label="{{'org_usecase.table.column.catalog_id' | t}}"
          [value]="currentUsecase.templateId + ' ('+ usecaseTemplate.usecaseId +')'"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          label="Description"
          [value]="description"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          label="{{'org_usecase.description_drawer.tab.details.playbook' | t}}"
          [value]="playbookNameWithSuffix"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          label="{{'org_usecase.table.column.default_priority' | t}}"
          [value]="usecaseTemplate.priority || '-'"
        ></ui-static>

        <ui-static
        label="{{'org_usecase.table.column.modified_priority' | t}}"
        [value]="currentUsecase.priority || '-'"
      ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          label="{{'org_usecase.table.column.activated_at' | t}}"
          [value]="currentUsecase.createdAt | dt:{withTime:true,withSeconds:true,locale:i18n.currentLocale}"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          label="{{'org_usecase.table.column.updated_at' | t}}"
          [value]="currentUsecase.updatedAt | dt:{withTime:true,withSeconds:true,locale:i18n.currentLocale}"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          label="{{'org_usecase.table.column.blacklisted' | t}}"
          [value]="'org_usecase.table.column.blacklisted.' + (currentUsecase.hasBlacklistedMonitor)  | t "
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          label="{{'org_usecase.description_drawer.tab.details.hasSilencedMonitors' | t}}"
          [value]="'common.boolean.' + (currentUsecase.hasSilencedMonitor)  | t "
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          [label]="'org_usecase.table.column.datasourceType' | t"
          [value]="currentUsecase.datasourceType"
        ></ui-static>
      </ui-field-group>
    </div>
  </div>
</div>