import { Component, OnInit } from '@angular/core';
import { DisplayService } from '@common/modules/display/display.service';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { Store } from '@ngxs/store';
import { FieldMap } from 'angular2-query-builder';
import { SearchMode } from 'projects/@common/modules/layout/components/page/page.component';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ConnectorsApiOrganizationService } from 'projects/@common/services/api/detect/organizations/connectors-api-organizations';
import { DescribeOrganizationResponse } from 'projects/@common/services/api/detect/organizations/definitions';
import { QueriesApi } from 'projects/@common/services/api/respond/queries/queries.api';
import { IBasicQueryParameter, ICreateCustomQueryRequest, IDeleteCustomQueryRequest, IDescribeQueryRequest, IDescribeQueryResponse, IExecuteDefaultQueryRequest, IExecuteOrganizationQueryRequest, IExecuteQueryResponse, IGetBasicQueryRequest, IListQueriesRequest, IQueryItem, IUpdateCustomQueryRequest, QueryTypeEnum } from 'projects/@common/services/api/respond/queries/queries.definitions';
import { clone, pruneObject } from 'projects/@common/utils/utils';

@Component({
  selector: 'respond-queries-page',
  templateUrl: './respond-queries-page.container.html',
  styleUrls: [ './respond-queries-page.container.scss' ],
})
export class RespondQueriesPageComponent implements OnInit {
  public isVarMode: boolean;
  public activeOrganization: DescribeOrganizationResponse;
  public organizationSearchMode = SearchMode.RESPOND;

  public isLoadingQueriesList: boolean;
  public queriesList: IQueryItem[];

  public isLoadingQueryDetails: boolean;
  public queryDetails: IDescribeQueryResponse;

  public isExecutingQuery: boolean;
  public queryResult: IExecuteQueryResponse;

  public isLoadingAggregationFields: boolean;
  public aggregationFields: FieldMap;

  constructor(
    private readonly store: Store,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    private readonly noticeService: NoticeService,
    private readonly queriesApi: QueriesApi,
    private readonly displayService: DisplayService,
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }
  ngOnInit(): void {
    if (!this.showEmptyPageToSelectOrganization) {
      this.fetchAll();
    }
  }

  public get showEmptyPageToSelectOrganization(): boolean {
    return this.isVarMode && !this.activeOrganization;
  }

  public get organizationId(): string {
    if (this.isVarMode) {
      return this.activeOrganization?.organization_respond_id; // using organization selector
    }
    return this.store.selectSnapshot(EcoSessionState.organization).id; // logged in user's organization
  }

  public async organizationChanged(org: { id: string; }): Promise<void> {
    if (org) {
      this.activeOrganization = await this.connectorsApiOrganizationService.describeOrganization(org.id);
      this.fetchAll();
    } else {
      this.activeOrganization = null;
    }
  }

  public handleQuerySelectionEvent(queryItem: IQueryItem): void {
    this.fetchQueryDetails(queryItem.id, queryItem.type);
  }

  public handleExecuteDefaultQueryEvent(request: IExecuteDefaultQueryRequest): void {
    this.executeDefaultQuery(request);
  }

  public handleExecuteOrganizationQueryEvent(request: IExecuteOrganizationQueryRequest): void {
    this.executeOrganizationQuery(request);
  }

  public handleConvertQueryToAdvancedModeEvent(basicQueryParameters: IBasicQueryParameter[]): void {
    this.convertQueryToAdvancedMode(basicQueryParameters);
  }

  public handleCreateAdvancedQueryEvent(createRequest: ICreateCustomQueryRequest): void {
    this.createAdvancedQuery(createRequest);
  }

  public handleUpdateAdvancedQueryEvent(updateRequest: IUpdateCustomQueryRequest): void {
    this.updateAdvancedQuery(updateRequest);
  }

  public handleDeleteAdvancedQueryEvent(deleteRequest: IDeleteCustomQueryRequest): void {
    this.deleteQuery(deleteRequest);
  }

  private fetchAll(): void {
    this.queriesList = [];
    this.queryDetails = null;
    this.queryResult = null;
    this.aggregationFields = null;
    this.fetchQueriesList();
    this.fetchAdvancedQueryAggregationFields();
  }

  private fetchQueriesList(): void {
    this.isLoadingQueriesList = true;
    this.queriesList = [];
    const request: IListQueriesRequest = {
      organizationId: this.organizationId,
    };
    this.queriesApi.listQueries(request).subscribe(
      (response) => {
        this.queriesList = response.getItems();

        // Filter out custom queries if the user is not a VAR and dont have the Feature flag
        if (!this.isVarMode && !this.displayService.meetsRequirements({flags: [FeaturesEnum.ADVANCED_QUERY_BUILDER]})) {
          this.queriesList = this.queriesList.filter((item) => item.type === QueryTypeEnum.DEFAULT);
        }
        this.isLoadingQueriesList = false;
      },
      (error) => {
        this.noticeService.error("respond.queries.fetch.error");
      }
    );
  }

  private fetchQueryDetails(queryId: string, queryType: QueryTypeEnum): void {
    this.isLoadingQueryDetails = true;
    this.queryDetails = null;
    this.queryResult = null;
    const request: IDescribeQueryRequest = {
      organizationId: this.organizationId,
      customQueryId: queryId,
      type: queryType,
    };
    this.queriesApi.describeQuery(request).subscribe(
      (response) => {
        this.queryDetails = response;
        this.isLoadingQueryDetails = false;
      },
      (error) => {
        this.noticeService.error("respond.queries.fetch.error");
      }
    );
  }

  private executeDefaultQuery(request: IExecuteDefaultQueryRequest): void {
    this.isExecutingQuery = true;
    this.queryResult = null;
    if (!request.organizationId) {
      request.organizationId = this.organizationId;
    }
    this.queriesApi.executeDefaultQueryUsecase(pruneObject(request)).subscribe(
      (response) => {
        this.queryResult = response;
        this.isExecutingQuery = false;
      },
      (error) => {
        this.noticeService.error("respond.queries.fetch.error");
        this.isExecutingQuery = false;
      }
    );
  }

  private executeOrganizationQuery(request: IExecuteOrganizationQueryRequest): void {
    this.isExecutingQuery = true;
    this.queryResult = null;
    if (!request.organizationId) {
      request.organizationId = this.organizationId;
    }
    this.queriesApi.executeOrganizationQueryUsecase(pruneObject(request)).subscribe(
      (response) => {
        this.queryResult = response;
        this.isExecutingQuery = false;
      },
      (error) => {
        this.noticeService.error("respond.queries.fetch.error");
        this.isExecutingQuery = false;
      }
    );
  }

  private convertQueryToAdvancedMode(basicQueryParameters: IBasicQueryParameter[]): void {
    this.isLoadingQueryDetails = true;
    const request: IGetBasicQueryRequest = {
      customQueryId: this.queryDetails.query.id,
      organizationId: this.organizationId,
      parameters: basicQueryParameters.reduce((obj, item) => {
        obj[item.key] = item.value;
        return obj;
      }, {}),
    };
    this.queriesApi.getBasicQueryUsecase(request).subscribe(
      (response) => {
        this.queryDetails = clone(this.queryDetails); // clone() to trigger ngOnChanges in children
        this.queryDetails.query.conditions = response;
        this.queryDetails.query.type = QueryTypeEnum.ORGANIZATION;
        this.queryDetails.query.id = null;
        this.queryDetails.query.organizationId = null;
        this.isLoadingQueryDetails = false;
      },
      (error) => {
        this.noticeService.error("respond.queries.convert.error");
      }
    );
  }

  private fetchAdvancedQueryAggregationFields(): void {
    this.isLoadingAggregationFields = true;
    this.queriesApi.getAdvancedQueryAggregationFields({ organizationId: this.organizationId }).subscribe(
      (response) => {
        this.aggregationFields = response;
        this.isLoadingAggregationFields = false;
      },
      (error) => {
        this.noticeService.error("respond.queries.fetchConditions.error");
      }
    );
  }

  private createAdvancedQuery(createRequest: ICreateCustomQueryRequest): void {
    createRequest.organizationId = this.organizationId;
    this.queriesApi.createCustomQuery(createRequest).subscribe(
      (response) => {
        this.queryDetails = response;
        this.fetchQueriesList();
        this.noticeService.success("respond.queries.create.success");
      },
      (error) => {
        this.noticeService.error("respond.queries.create.error");
      }
    );
  }

  private updateAdvancedQuery(updateRequest: IUpdateCustomQueryRequest): void {
    this.queriesApi.updateCustomQuery(updateRequest).subscribe(
      (response) => {
        this.queryDetails = response;
        this.fetchQueriesList();
        this.noticeService.success("respond.queries.update.success");
      },
      (error) => {
        this.noticeService.error("respond.queries.update.error");
      }
    );
  }

  private deleteQuery(deleteRequest: IDeleteCustomQueryRequest): void {
    this.queriesApi.deleteCustomQuery(deleteRequest).subscribe(
      (response) => {
        this.queryDetails = null;
        this.queryResult = null;
        this.fetchQueriesList();
        this.noticeService.success("respond.queries.delete.success");
      },
      (error) => {
        this.noticeService.error("respond.queries.delete.error");
      }
    );
  }
}
