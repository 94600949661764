import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationShareSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';

@Component({
  selector: 'downloads-frequency',
  templateUrl: './downloads-frequency.component.html',
  styleUrls: [ './downloads-frequency.component.scss' ],
})
export class DownloadsFrequencyComponent implements OnInit {
  @Input() public currentSettings: OrganizationShareSettings;
  @Input() public isLoading = false;

  public isEditing = false;
  public isActivated = false;
  public downloadsFrequencyForm: UntypedFormGroup;
  public frequencyOptions = (() => {
    const options = [];
    for (let i = 1; i < 9; i++) {
      options.push({
        value: i,
        displayValue: this.i18n.translate('settings.downloads.card.label.option', { weekNumber: i }),
      });
    }
    return options;
  })();

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private formBuilder: UntypedFormBuilder,
    private i18n: I18nService,
    private noticeService: NoticeService
  ) {}

  ngOnInit() {
    this.initForm(this.currentSettings);
  }

  private initForm(currentSettings?: OrganizationShareSettings) {
    this.isActivated = !!currentSettings?.downloadedFileNotificationFrequency;
    this.downloadsFrequencyForm = this.formBuilder.group({
      frequency: this.formBuilder.control(currentSettings?.downloadedFileNotificationFrequency || 1),
    });
  }

  public cancel() {
    this.initForm(this.currentSettings);
  }

  public save(): void {
    this.organizationsApiService
      .updateOrganizationSettings({
        newSettings: {
          downloadedFileNotificationFrequency: this.isActivated ? this.downloadsFrequencyForm?.value.frequency : null,
        },
      })
      .then((response) => {
        this.currentSettings = response;
        this.initForm(response);
        this.noticeService.notifyUser({
          message: 'settings.downloads.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.downloads.snack.error',
          level: NoticeLevels.ERROR,
        });
      });
  }

  public get isValid() {
    return this.downloadsFrequencyForm.valid;
  }

  public restoreDefaults() {
    this.downloadsFrequencyForm.patchValue({
      frequency: 1,
    });
  }

  public toggleSilencingActivation() {
    this.isActivated = !this.isActivated;
  }
}
