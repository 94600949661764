import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { Eco } from "projects/@common/definitions/eco";
import { IPropertyConfig } from "projects/@common/services/api/respond/actifs/actifs.definitions";
import { ActifsRelationsCellComponent } from "../../components/actifs-table/components/actifs-relations-cell/actifs-relations-cell.component";
import { ActifsTypeCellComponent } from "../../components/actifs-table/components/actifs-type-cell/actifs-type-cell.component";

export enum ActifsTableColumnEnum {
  selected = 'selected',
  name = 'name',
  type = 'type',
  note = 'note',
  relations = 'relations',
  updatedAt = 'updatedAt'
}

export function getActifsTableColumns(hiddenColumns: ActifsTableColumnEnum[] = []): Array<Object> {
  const defaultTableColumns = [
    {
      label: '',
      field: ActifsTableColumnEnum.selected,
      selected: "__Selected",
      type: UiStaticTableRowType.CHECKBOX,
      noSorting: true,
      isResizable: true,
      width: '50px',
    },
    {
      label: 'actifs.list.table.column.name',
      field: ActifsTableColumnEnum.name,
      type: UiStaticTableRowType.TEXT,
      noSorting: false,
      isResizable: true,
      width: '20%',
      minWidth: "150px",
    },
    {
      label: 'actifs.list.table.column.type',
      field: ActifsTableColumnEnum.type,
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: ActifsTypeCellComponent,
      paramName: "asset",
      noSorting: false,
      isResizable: true,
      width: '20%',
      minWidth: "102px",
    },
    {
      label: 'actifs.list.table.column.note',
      field: ActifsTableColumnEnum.note,
      type: UiStaticTableRowType.FUNCTION,
      func: (note: string) => note ? note : '-',
      isTruncated: true,
      noSorting: true,
      isResizable: true,
      minWidth: '200px',
      width: '35%',
    },
    {
      label: 'actifs.list.table.column.relations',
      field: ActifsTableColumnEnum.relations,
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: ActifsRelationsCellComponent,
      paramName: "asset",
      noSorting: true,
      isResizable: true,
      minWidth: "102px",
    },
    {
      label: 'actifs.list.table.column.updatedAt',
      field: ActifsTableColumnEnum.updatedAt,
      type: UiStaticTableRowType.DATE,
      noSorting: false,
      isResizable: true,
      minWidth: '90px',
    },
  ];
  return defaultTableColumns.filter((column) => !hiddenColumns?.includes(column.field));
}

function injectColumnsBeforeLast(columns: Array<Object>, columnsToInject: Array<Object>): void {
  columns.splice(columns.length - 1, 0, ...columnsToInject);
}

export function addDynamicColumns(columns: Array<Object>, assetPropertyConfigs: IPropertyConfig, locale: Eco.Languages): void {
  // Mutates the input param columns in place, by inserting the dynamic columns before the last column.
  // This is done to keep the last column (updatedAt) on the right side of the table.
  // The use case is to add columns received from the backend data, which are not known at compile time.
  const dynamicColumnsToInsert = assetPropertyConfigs.properties
    .filter((config) => !!config.showInList)
    .map((config) => ({
      label: config.name[locale],
      field: config.propertyId,
      isTruncated: true,
      noSorting: false,
      isResizable: true,
      minWidth: '95px',
      type: UiStaticTableRowType.FUNCTION,
      func: (data: any) => {
        if (data?.[locale]) {
          return data[locale];
        }
        return "-";
      },
    }));
  injectColumnsBeforeLast(columns, dynamicColumnsToInsert);
}
