<div class="split-pane-right self-contained-scrollbar" (onScrollToBottom)="fetchMoreTaskTemplates()" scrollToBottom>
  <div class="split-right-content">
    <div class="top-container">
      <h4 class="section-bold-title">{{'incident-toolbox.tabs.tasks.subtitle' | t}}</h4>
      <div class="library-filters">
        <ui-table-search-tool
          [query]="name"
          (clear)="onNameChange('')"
          (query)="onNameChange($event)"
          (click)="$event.stopPropagation()"
          [placeholder]="'ui.table-search-tool.placeholder' | t"
        ></ui-table-search-tool>
        <div class="multiselect-container">
          <ui-multi-select
            [multiSelectData]="phaseFiltersSelect"
            [disabled]="false"
            (onOptionSelect)="onPhaseChange($event)"
            (click)="$event.stopPropagation()"
            class="phases"
          ></ui-multi-select>
          <ui-table-filter-tool
            [tableFilterData]="taskTypeFilterSelect"
            (click)="$event.stopPropagation()"
            (select)="onTypeChange($event)"
            class="types"
          ></ui-table-filter-tool>
        </div>
      </div>
    </div>
    <div class="list-container">
      <div
        cdkDropList
        class="library-items"
        [cdkDropListEnterPredicate]="notAllowedPredicate"
        [cdkDropListSortingDisabled]="true">
        <div *ngFor="let item of taskTemplates">
          <div  
            cdkDrag
            class="task-card" 
            [cdkDragData]="item"
            (cdkDragStarted)="pickedItem = $event.source.data"
            (cdkDragDropped)="pickedItem = null"
            >
            <div class="drag-placeholder" *cdkDragPlaceholder></div>
            <playbook-task-library-card [playbooksTable]="item"></playbook-task-library-card>
          </div>
          <div class="item-placeholder" *ngIf="pickedItem?.id === item.id">
            <playbook-task-library-card class="task-card" [playbooksTable]="item"></playbook-task-library-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
