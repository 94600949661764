import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IExecuteToolboxActionsResponse } from '@common/services/api/respond/actions-toolbox/actions-toolbox.definition';
import { UiTab } from '@ui-kit/components/ui-tabs/ui-tab.component';
import { UiTabs, UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { IAssetPropertyConfigRepresentation, IAssetRepresentation } from 'projects/@common/services/api/respond/actifs/actifs.definitions';
import { IEntityRepresentation } from 'projects/@common/services/api/respond/entities/entities.definition';
import { IIncidentActionRepresentation, IncidentStatus } from 'projects/@common/services/api/respond/incidents/incidents.definitions';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';
import { IObservable } from 'projects/@common/services/api/respond/observables/observables.definition';
import { EntityPageEnum, IEntityPageContext } from 'projects/@respond/components/respond-entity-with-collapse/respond-entity-with-collapse.component';
import { IObservablesPageContext, ObservablesPageEnum } from 'projects/@respond/observables/components/observable-container/observable-container.component';
import { Subscription } from 'rxjs';
import { IncidentEventsService } from '../../services/incident.events.service';
import { DisplayService } from '@common/modules/display/display.service';

enum TabsEnum {
  observables = "observables",
  entities = "entities",
  actions = "actions",
  tasks = "tasks"
}

@Component({
  selector: 'incident-toolbox',
  templateUrl: './incident-toolbox.component.html',
  styleUrls: [ './incident-toolbox.component.scss' ],
})
export class IncidentToolboxComponent implements OnInit, AfterViewInit {
  @Input() public incident: Incident;
  @Input() public observables: IObservable[];
  @Input() public incidentActions: IIncidentActionRepresentation[];

  @Input() public isLoadingEntities: boolean;
  @Input() public entities: IEntityRepresentation[];

  @Input() public assets: IAssetRepresentation[];
  @Input() public assetTypes: IAssetPropertyConfigRepresentation[];

  @Output() public onExpandToggle = new EventEmitter<boolean>();

  @ViewChild('toolboxTabs') toolboxTabs: UiTabs;

  public uiTabsColor: UiTabsColor = UiTabsColor.lightTabs;
  public tabsEnum = TabsEnum;
  public selectedTab: TabsEnum;

  public isToolboxExpanded: boolean = true;
  public title: string;
  public configObservables: IObservablesPageContext;
  public configEntities: IEntityPageContext;

  private actionListedSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly incidentEventsService: IncidentEventsService,
    private readonly displayService: DisplayService,
  ) { }

  ngOnInit(): void {
    this.initObservablesConfig();
    this.initEntitiesConfig();

    this.actionListedSubscription = this.incidentEventsService.actionsListedSubject$.subscribe((result: IIncidentActionRepresentation[]) => {
      this.toolboxTabs.selectTabName(TabsEnum.actions);
    });
  }

  ngAfterViewInit(): void {
    this.initDefaultSelectedTabFromURL();
  }

  ngOnDestroy(): void {
    this.actionListedSubscription?.unsubscribe();
  }

  public get isReadonlyMode(): boolean {
    return !this.incident.isEditable;
  }

  public toggleExpanded(): void {
    this.isToolboxExpanded = !this.isToolboxExpanded;
    this.onExpandToggle.emit(this.isToolboxExpanded);
  }

  public handleTabClicked(tab: UiTab): void {
    const tabName = tab.tabName as TabsEnum;
    this.setSelectedTab(tabName);
    this.setTabUrl(tabName);
    if (this.toolboxTabs.getSelectedTabName() as TabsEnum !== tabName) {
      this.toolboxTabs.selectTabName(tabName);
    }
  }

  public setSelectedTab(tabName: TabsEnum): void {
    this.selectedTab = tabName;
  }

  public isTaskTabVisible(): boolean {
    return [IncidentStatus.OPEN, IncidentStatus.FINALIZING].includes(this.incident.status) && 
      this.displayService.meetsRequirements({ permissions: [ 'can_list_toolbox_actions' ] });
  }

  private setTabUrl(tabName: TabsEnum): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { toolbox: tabName },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private initDefaultSelectedTabFromURL(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams.toolbox) {
      const tabName = queryParams.toolbox as TabsEnum;
      this.setSelectedTab(tabName);
      this.toolboxTabs?.selectTabName(tabName);
    } else {
      this.setSelectedTab(TabsEnum.observables);
    }
  }

  private initObservablesConfig() {
    this.configObservables = {
      pageEnum: ObservablesPageEnum.INCIDENT_TASKS_PAGE,
      organizationId: this.incident.organizationId,
      incident: this.incident,
      observables: this.observables,
      viewParams: {
        showCreateButton: false,
        showDeleteButton: false,
        showTitle: false,
        showViewModeSelect: false,
        useRedFlagFeature: true,
      },
    };
  }

  private initEntitiesConfig() {
    this.configEntities = {
      pageEnum: EntityPageEnum.INCIDENTS,
      organizationId: this.incident.organizationId,
      incident: this.incident,
    };
  }
}
