<div
  page
  [appPagePaths]="['mdRespond', 'incidents']"
  scrollToBottom
  (onScrollToBottom)="handleLoadMore()"
  [hasTopSection]="true"
  [searchMode]="searchMode"
  (organizationChanged)="handleOrganizationChanged($event)"
  [allSelectorCustomValue]="allSelectorCustomValue"
  [disableOrganizationSelector]="isAdmConsole"
>

  <div
    topSection
    class="left-side-container"
  >
    <capacity-card
      logo="icon_assigned_alerts"
      [topDescription]="'incidents.container.page.badges.open.top' | t"
      [bottomDescription]="'incidents.container.page.badges.open.bottom' | t"
      [capacityNumber]="incidentsStats ? incidentsStats.open : '-'"
      numberColor="#DE3618"
      [loading]="!incidentsStats"
    >
    </capacity-card>
  </div>

  <respond-incident-table-filters
    #incidentTableFilters
    toolbar
    (filteringChangeEvent)="handleDebouncedRefresh()"
    (refreshEvent)="handleDebouncedRefresh(true)"
    [isLoading]="isIncidentsLoading"
    [locale]="locale"
    [incidentResponse]="incidentResponse"
    [organizations]="organizationsList"
    [owners]="ownersList"
    [visibleFilters]="viewParams.visibleFilters"
  ></respond-incident-table-filters>

  <respond-incident-table
    (sortChangeEvent)="handleSortingChange($event)"
    (exportCsvEvent)="exportCsv()"
    [isLoading]="isIncidentsLoading"
    [isExporting]="isExporting"
    [locale]="locale"
    [incidentsColumnDefinition]="viewParams.tableColumns"
    [incidentTableData]="incidentsTableData"
    [canCreateIncident]="viewParams.canCreateIncident"
    [canDescribeIncident]="viewParams.canDescribeIncident"
  ></respond-incident-table>
</div>