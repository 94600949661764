import { HttpClient } from "@angular/common/http";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { ICreateWhitelistRequest, IDeleteWhitelistsRequest, IDeleteWhitelistsResponse, IDescribeWhitelistRequest, IDescribeWhitelistResponse, IGetWhitelistFieldsRequest, IListWhitelistsRequest, IUpdateWhitelistRequest, IWhitelistConditionField, IWhitelistRepresentation, IWhitelistsApi } from "projects/@common/services/api/respond/whitelists/whitelists.definitions";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { UrlUtils } from "projects/@common/utils/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class VarWhitelistsApi  extends EcoApi implements IWhitelistsApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listWhitelists(request: IListWhitelistsRequest): Observable<ResponseUtils<IWhitelistRepresentation>> {
    const url = `${this.host}/organizations/${request.organizationId}/whitelists?${UrlUtils.jsonToQueryParams(request)}`;
    return this.http.get<IRespondResponse<IWhitelistRepresentation>>(url)
      .pipe(map((response) => new ResponseUtils<IWhitelistRepresentation>(response)));
  }

  public getWhitelistConditionFields(request?: IGetWhitelistFieldsRequest): Promise<IWhitelistConditionField[]> {
    const url = `${this.host}/organizations/${request.organizationId}/whitelists/fields`;
    return this.http.get<IWhitelistConditionField[]>(url).toPromise();
  }

  public describeWhitelist(request: IDescribeWhitelistRequest): Observable<IDescribeWhitelistResponse> {
    const url = `${this.host}/organizations/${request.organizationId}/whitelists/${request.id}`;
    return this.http.get<IDescribeWhitelistResponse>(url);
  }

  public createWhitelist(request: ICreateWhitelistRequest): Observable<IDescribeWhitelistResponse> {
    const url = `${this.host}/organizations/${request.organizationId}/whitelists`;
    return this.http.post<IDescribeWhitelistResponse>(url, request);
  }

  public updateWhitelist(request: IUpdateWhitelistRequest): Observable<IDescribeWhitelistResponse> {
    const url = `${this.host}/organizations/${request.organizationId}/whitelists/${request.id}`;
    return this.http.put<IDescribeWhitelistResponse>(url, request);
  }

  public deleteWhitelist(request: IDeleteWhitelistsRequest): Promise<IDeleteWhitelistsResponse> {
    const url = `${this.host}/organizations/${request.organizationId}/whitelists/${request.id}`;
    return this.http.delete<IDeleteWhitelistsResponse>(url).toPromise();
  }
}
