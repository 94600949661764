<detail-layout backLink="../../">
  <detail-header
    [isLoading]="isLoading"
    [data]="datasourceDetails"
    topCard
  ></detail-header>
  <detail-body [datasources]="listData" [loading]="isLoading" [refreshing]="detailActions.isLoading" bottomCard>
    <ng-container actions>
      <ui-table-total-tool [total]="detailActions.total" [locale]="i18nService.currentLocale"></ui-table-total-tool>
      <ui-table-search-tool
        [placeholder]="'ui.table-search-tool.placeholder'|t"
        [query]="detailActions.requestsQuery"
        (clear)="detailActions.handleSearchClear()"
        (query)="detailActions.handleSearchQuery($event)"
      ></ui-table-search-tool>
      <ui-table-filter-tool
        *ngIf="tableFilterData"
        [tableFilterData]="tableFilterData"
        (select)="detailActions.handleModeFilter($event)"
      ></ui-table-filter-tool>

      <ui-multi-select
        *ngIf="multiSelectData"
        [multiSelectData]="multiSelectData"
        (onOptionSelect)="detailActions.handlePrioritiesFilter($event)"
      ></ui-multi-select>

      <ui-table-refresh-tool
        [loading]="detailActions.isLoading"
        (click)="detailActions.handleRefresh()"
      ></ui-table-refresh-tool>
    </ng-container>
  </detail-body>
</detail-layout>
