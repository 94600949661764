import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Eco } from "projects/@common/definitions/eco";
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { UrlUtils } from 'projects/@common/utils/utils';
import { EcoUrlService } from '../../core/url.service';

export type Group = {
  id?: string;
  idpIdentifier?: string;
  idpId?: string;
  name?: string;
  description?: string;
  email?: string;
  organizationId: string;
  createdAt?: number
  updatedAt?: number
};

@Injectable({
  providedIn: 'root',
})
export class GroupsApi extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.iam;
  private readonly groupEndpoint = '/groups';

  constructor(
    http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url as any);
  }

  public async listGroupProfileAssociations(profileId: string): Promise<Eco.List<Group>> {
    const queryParams = { profileId, size: 100 };
    const url = `${this.host}${this.groupEndpoint}/profileAssociations?${UrlUtils.jsonToQueryParams(queryParams)}`;
    const res = await this.http.get<any>(url).toPromise();
    return res;
  }

  public async setGroupProfileAssociations(groupIds: string[], profileId: string): Promise<Group> {
    const url = `${this.host}${this.groupEndpoint}/profileAssociations`;
    await this.http.put(url, {
      groupIds,
      profileId,
    }).toPromise();
    return;
  }

  public async listGroups(params?: {
    filter?: string,
    search?: string,
    size?: number,
    from?: number | string,
  }): Promise<Eco.List<{ id: string, name: string, description: string }>> {
    const queryParams = params ? `?${UrlUtils.jsonToQueryParams(params)}` : '';
    const url = `${this.host}${this.groupEndpoint}${params ? queryParams : ''}`;
    const res = await this.http.get<any>(url).toPromise();
    return res;
  }
}
