import { Component, Input, OnInit } from '@angular/core';
import { IAssetRepresentation } from 'projects/@common/services/api/respond/actifs/actifs.definitions';
import { ActifTypesIconName } from 'projects/@respond/actifs/containers/respond-actifs-list/actifs-list-icons.enum';

@Component({
  selector: 'actifs-type-cell',
  templateUrl: './actifs-type-cell.component.html',
})
export class ActifsTypeCellComponent implements OnInit {
  @Input() public asset: IAssetRepresentation;

  public iconPath: string;
  public typeName: string;

  constructor() { }

  ngOnInit(): void {
    this.iconPath = this.getIconPath(this.asset.type);
    this.typeName = this.asset.typeName;
  }

  public getIconPath(type: string) {
    return `favicons/actifs/${ActifTypesIconName[type] || ActifTypesIconName._UNKNOWN}_24px`;
  }
}
