<label>{{"incidents.container.page.details.tab.chronology.collaborationTime" | t}}</label>

<div class="time-container">
  <ui-toggle
    [toggled]="isToggled"
    [disabledWhenUntoggled]="false"
    [switchRightSide]="false"
    [boldLabel]="false"
    [ignoreLabelClicks]="true"
    [disabled]="isDisabled"
    (onClick)="toggleCollaborationTime($event)"
  ></ui-toggle>
</div>
