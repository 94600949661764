import { IListFindingsRequest, ListFindingsDirection, ListFindingsOrderBy } from "../finding.definition";


export class GetAlertFindingsRequest implements IListFindingsRequest {
  public from = 0;
  public size = 50;
  public alertId: string;
  public organizationId: string;
  public orderBy?: ListFindingsOrderBy = ListFindingsOrderBy.createdAt;
  public direction?: ListFindingsDirection = ListFindingsDirection.desc;

  constructor(findingObject?: IListFindingsRequest) {
    Object.assign(this, findingObject);
  }

  public getQueryParams(): Partial<IListFindingsRequest> {
    return {
      from: this.from,
      size: this.size,
      orderBy: this.orderBy,
      direction: this.direction,
    };
  }
}
