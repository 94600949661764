import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UiTableDirection } from "@ui-kit/components/ui-table/ui-table.component";
import { ModalService } from "@ui-kit/services/modal.service";
import { Eco } from "projects/@common/definitions/eco";
import { StaticTableDataMapper } from "projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service";
import { IIncident } from "projects/@common/services/api/respond/incidents/incidents.definitions";
import { GetIncidentsSortElements } from "projects/@common/services/api/respond/incidents/models/getIncidentsRequest";
import { Incident } from "projects/@common/services/api/respond/incidents/models/incident";
import { clone } from "projects/@common/utils/utils";
import { CreateIncidentModalComponent } from "../create-incident-modal/create-incident-modal.component";
import { IncidentsTableColumnsEnum } from "../respond-incident-list/configuration/incident-table-columns.config";

@Component({
  selector: 'respond-incident-table',
  templateUrl: './respond-incident-table.component.html',
  styleUrls: [ './respond-incident-table.component.scss' ],
})
export class RespondIncidentTableComponent implements OnInit {
  @Input()
    incidentTableData: Incident[] = [];

  @Input()
    isLoading: boolean;

  @Input()
    isExporting: boolean;

  @Input()
    incidentsColumnDefinition: Array<Object> = [];

  @Input()
    canCreateIncident = false;

  @Input()
    canDescribeIncident = false;

  @Input()
    locale: Eco.Languages;

  @Output()
  public sortChangeEvent = new EventEmitter<GetIncidentsSortElements>();

  @Output()
  public exportCsvEvent = new EventEmitter<void>();

  public sortDirection = UiTableDirection.Desc;
  public sort: string = IncidentsTableColumnsEnum.UPDATED_AT;

  private isHoldingCtrlKey: boolean = false;

  constructor(
    private readonly staticTableDataFactory: StaticTableDataMapper,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly modalService: ModalService
  ) { }

  ngOnInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams.orderBy && queryParams.direction) {
      this.sortDirection = queryParams.direction.toLowerCase() as UiTableDirection;
      this.sort = queryParams.orderBy === "organization" ? IncidentsTableColumnsEnum.ORGANIZATION : queryParams.orderBy;
    }
    this.refreshTable();
  }

  @HostListener('window:keydown', [ '$event' ])
  keyDown(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.isHoldingCtrlKey = true;
    }
  }

  @HostListener('window:keyup', [ '$event' ])
  keyUp(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.isHoldingCtrlKey = false;
    }
  }

  public refreshTable(): void {
    const incidentsClone = clone(this.incidentTableData);
    [ this.incidentsColumnDefinition, this.incidentTableData ] = this.staticTableDataFactory.translate(this.incidentsColumnDefinition, incidentsClone);
  }

  public handleSorting(event: any): void {
    this.sort = event.sortColumn;
    this.sortDirection = event.sortDirection;

    const sortElement: GetIncidentsSortElements = {
      orderBy: event.sortColumn,
      direction: event.sortDirection,
    };

    this.sortChangeEvent.emit(sortElement);
  }

  public handleRowClick(incident: IIncident): void {
    if (this.canDescribeIncident) {
      const url = `respond/${incident.organizationEcoId}/incidents/${incident.id}/details`;
      if (this.isHoldingCtrlKey) {
        const queryParams = window.location.search;
        window.open(url + queryParams, '_blank');
      } else {
        this.router.navigate([ url ], { skipLocationChange: false, queryParamsHandling: "merge" });
      }
    }
  }

  public createIncident(): void {
    this.modalService.openDialog(CreateIncidentModalComponent, null, false);
  }

  public emitExportCsvEvent(): void {
    this.exportCsvEvent.emit();
  }
}
