
<div *ngIf="!isLoading" class="container">
  <ui-title
    class="title"
    [name]="'incidents.container.page.details.nav.sendNotification.button.text' | t"
  >
  </ui-title>

  <incident-escalation-table class="incident-escalation-table"
    [incident]="data.incident"
    [computedEscalationList]="computedEscalationlist"
    [escalationParameters]="escalationParameters"
  ></incident-escalation-table>

  <html-editor
    #editor
    class="editor"
    controlName="message"
    [isEditing]="true"
    [handleImage]="false"
    [group]="formGroup"
    [htmlData]="messageValue"
  ></html-editor>

  <div class="buttons">
    <button
      ui-button
      color="standard"
      id="start-conversation"
      [disabled]="!messageValue"
      (click)="handleConfirm()"
    >
      <ui-spinner
        *ngIf="false"
        size="small"
      ></ui-spinner>
      {{"incidents.container.page.details.modal.sendNotification.confirmButton" | t}}
    </button>

    <button
      ui-button
      [disabled]="false"
      (click)="handleCancel()"
    >
      {{'common.action.cancel' | t}}
    </button>
  </div>
</div>

<div *ngIf="isLoading" class="container">
  <ui-spinner
    size="medium"
    modifier="secondary"
  ></ui-spinner>
</div>
  
