import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Eco } from '../../../../definitions/eco';
import { UrlUtils } from '../../../../utils/utils';
import { EcoUrlService } from '../../core/url.service';
import { ICreateParameterRequest, IDeleteParameterRequest, IDescribeParameterRequest, IListParametersRequest, IParameterStore, IUpdateParameterRequest } from './parameters.definitions';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root',
})
export class ParametersApiService {
  private readonly prefix = '/tools/parameters';
  private readonly adminPrefix = '/tools/admin/organizations/ECOID/parameters';
  private readonly host: string;
  private readonly adminHost: string;

  constructor(private http: HttpClient, private url: EcoUrlService) {
    this.host = url.baseUrl + this.prefix;
    this.adminHost = url.baseUrl + this.adminPrefix;
  }

  public async listParameters(params: IListParametersRequest, admin: boolean): Promise<Eco.List<IParameterStore>> {
    const query = UrlUtils.jsonToQueryParams({
      from: params.from,
      size: params.size,
      order: params.order,
      searchName: params.searchName,
    });
    const url = admin ? `${this.adminHost.replace(/ECOID/gi, params.ecoId)}?${query}` : `${this.host}?${query}`;
    const response = await this.http.get<Eco.List<IParameterStore>>(url).toPromise();
    response.items.map((parameter) => this.decodeContent(parameter));
    return response;
  }

  public async describeParameter(params: IDescribeParameterRequest, admin: boolean): Promise<IParameterStore> {
    const query = UrlUtils.jsonToQueryParams(params);
    const url = admin ? `${this.adminHost.replace(/ECOID/gi, params.ecoId)}/parameter?${query}` : `${this.host}/parameter?${query}`;
    return this.decodeContent(await this.http.get<IParameterStore>(url).toPromise());
  }

  public async describeParameters(params: IDescribeParameterRequest[], admin: boolean): Promise<Eco.List<IParameterStore>> {
    const url = admin ? `${this.adminHost.replace(/ECOID/gi, params[0].ecoId)}` : this.host;
    const response = await this.http.post<Eco.List<IParameterStore>>(url, params).toPromise();
    response.items.map((parameter) => this.decodeContent(parameter));
    return response;
  }

  public updateParameter(params: IUpdateParameterRequest): Promise<IParameterStore> {
    const url = `${this.adminHost.replace(/ECOID/gi, params.ecoId)}/parameter`;
    return this.http.put<IParameterStore>(url, this.encodeContent(params)).toPromise();
  }

  public deleteParameter(params: IDeleteParameterRequest): Promise<any> {
    const query = UrlUtils.jsonToQueryParams({ name: params.name });
    const url = `${this.adminHost.replace(/ECOID/gi, params.ecoId)}/parameter?${query}`;
    return this.http.delete<any>(url).toPromise();
  }

  public createParameter(params: ICreateParameterRequest): Promise<IParameterStore> {
    const url = `${this.adminHost.replace(/ECOID/gi, params.ecoId)}/parameter`;
    return this.http.post<IParameterStore>(url, this.encodeContent(params)).toPromise();
  }

  private encodeContent(parameter): Promise<IParameterStore> {
    parameter.value.content = Buffer.from(parameter.value.content, 'utf8').toString('base64');
    return parameter;
  }

  private decodeContent(parameter): Promise<IParameterStore> {
    parameter.value.content = Buffer.from(parameter.value.content, 'base64').toString('utf8');
    return parameter;
  }
}
