import { RequestDirection } from "projects/@common/definitions/consoles.enum";
import { IAssetHistory, ListHistoryOrderBy } from "../histories.definition";

export class AssetHistoryRequest implements IAssetHistory {
  assetId: string;
  organizationId: string;
  from?: number = 0;
  size?: number = 15;
  orderBy?: ListHistoryOrderBy = ListHistoryOrderBy.createdAt;
  direction?: RequestDirection = RequestDirection.desc;

  constructor(jsonObj: IAssetHistory) {
    Object.assign(this, jsonObj);
  }

  public getQueryParams(): Partial<IAssetHistory> {
    return {
      from: this.from,
      size: this.size,
      orderBy: this.orderBy,
      direction: this.direction,
    };
  }
}
