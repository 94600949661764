import { Injectable } from '@angular/core';
import { TranslatedObjectPipe } from '@common/modules/i18n/translatedObject.pipe';
import { IEntityForDisplay } from '@common/services/api/respond/entities/entities.definition';
import { IEntityPageContext } from '../respond-entity-with-collapse/respond-entity-with-collapse.component';

@Injectable({
  providedIn: 'root',
})
export class EntityCellService {

  constructor(
    private readonly translatedObjectPipe: TranslatedObjectPipe
  ) { }

  getFlaggedIncidentTasks(pageContext: IEntityPageContext, entity: IEntityForDisplay): string {
    if (pageContext.incident) {
      const flaggedIncidentTasks = pageContext.incident.tasks
        .filter((incidentTask) => incidentTask.entities.includes(entity.id))
        .map((incidentTask) => this.translatedObjectPipe.transform(incidentTask.task.playbookTask.name)).join(', ');
      return flaggedIncidentTasks;
    }

    return '';
  }

  getRedFlaggedStatus(pageContext: IEntityPageContext, entity: IEntityForDisplay): boolean {
    return this.getFlaggedIncidentTasks(pageContext, entity).length > 0;
  }
}
