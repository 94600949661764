<ui-card [class.no-padding]="!!disabledGrouping">
  <div class="top-action-buttons-container">
    <button
      *ngIf="!shouldHideCreateButton"
      ui-button
      color="linkFlat"
      [disabled]="isLoading"
      [custom-icon]="true"
      [isSvg]="true"
      icon="icon_create"
      icon-size="medium"
      (click)="onClickCreate()"
    >
      {{ 'common.action.create' | t }}</button>
    <button
      *ngIf="!shouldHideDeleteButton"
      ui-button
      color="linkFlat"
      [disabled]="isLoading"
      [custom-icon]="true"
      [isSvg]="true"
      icon="icon_delete"
      icon-size="medium"
      (click)="onClickDelete()"
    >
      {{ ('common.action.delete' | t) + " (" + selectedCount +")" }}
    </button>
  </div>

  <ng-container *ngIf="!disabledGrouping">
    <ui-collapsable-card
      *ngFor="let tableGroup of tableGroups"
      class="table-group"
      [cardTitle]="tableGroup.name"
      [headerIcon]="tableGroup.icon"
      [isBigHeaderImage]="false"
      [numberItems]="tableGroup.whitelists.length"
      [isCollapsed]="!tableGroup.isExpanded"
    >
      <ui-static-table
        [dataSource]="tableGroup.whitelists"
        [columnsDef]="tableGroup.columnsDef"
        [loading]="isLoading"
        [isCustomSorting]="false"
        [sort]="sort"
        [direction]="direction"
        [locale]="locale"
        [canSelectOnlyOne]="false"
        [isSelectingOnlyOneAtATime]="false"
        [addPaddingOnFirst]="false"
        [hoverable]="true"
        (on-row-click)="onClickRow($event)"
        (checkbox-event)="handleCheckboxEvent($event.event, $event.item)"
      >
      </ui-static-table>
    </ui-collapsable-card>
  </ng-container>

  <ng-container *ngIf="disabledGrouping">
    <ui-static-table
      [dataSource]="tableGroups[0].whitelists"
      [columnsDef]="tableGroups[0].columnsDef"
      [loading]="isLoading"
      [isCustomSorting]="false"
      [sort]="sort"
      [direction]="direction"
      [locale]="locale"
      [canSelectOnlyOne]="false"
      [isSelectingOnlyOneAtATime]="false"
      [addPaddingOnFirst]="false"
      [hoverable]="true"
      (on-row-click)="onClickRow($event)"
      (checkbox-event)="handleCheckboxEvent($event.event, $event.item)"
    >
    </ui-static-table>
  </ng-container>

  <ui-spinner
    *ngIf="isLoading && !tableGroups.length"
    size="medium"
  ></ui-spinner>

</ui-card>