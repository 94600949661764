import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { I18nModule } from "@common/modules/i18n/i18n.module";
import { PipesModule } from "@common/modules/pipes/pipes.module";
import { FeatureSharedModule } from "@common/modules/shared/feature.shared.module";
import { UiKitModule } from "@ui-kit/uikit.module";
import { ComponentsModule } from "projects/@common/components/components.module";
import { CustomTabsComponent } from "projects/@common/components/custom-tabs/custom-tabs.component";
import { ActifsModule } from "../actifs/actifs.module";
import { RespondComponentsModule } from "../components/respond.components.module";
import { FilterModule } from "../filters/filters.module";
import { HistoriesModule } from "../histories/histories.module";
import { ObservablesModule } from "../observables/observables.module";
import { WhitelistsModule } from "../whitelists/whitelists.module";
import { CreateIncidentModalComponent } from "./components/create-incident-modal/create-incident-modal.component";
import { IncidentActionInputComponent } from './components/incident-actions/incident-action-input/incident-action-input.component';
import { IncidentActionsComponent } from './components/incident-actions/incident-actions.component';
import { IncidentDetailsBaseLayoutComponent } from "./components/incident-details-base-layout/incident-details-base-layout.component";
import { IncidentEntitiesComponent } from './components/incident-entities/incident-entities.component';
import { IncidentReportButtonComponent } from "./components/incident-report-button/incident-report-button.component";
import { IncidentStatusBadgeComponent } from './components/incident-status-badge/incident-status-badge.component';
import { IncidentToolboxComponent } from './components/incident-toolbox/incident-toolbox.component';
import { AlertsTabComponent } from "./components/incidents-details/components/alerts-tab/alerts-tab.component";
import { ChatConversationsComponent } from "./components/incidents-details/components/chat-tab/chat-conversations/chat-conversations.component";
import { ChatNotesComponent } from "./components/incidents-details/components/chat-tab/chat-notes/chat-notes.component";
import { ChatTabContainer } from './components/incidents-details/components/chat-tab/chat-tab.container';
import { ChronologyTabComponent } from "./components/incidents-details/components/chronology-tab/chronology-tab.component";
import { CollaborationTimeComponent } from "./components/incidents-details/components/chronology-tab/components/collaboration-time/collaboration-time.component";
import { ChronologyTimestampComponent } from "./components/incidents-details/components/chronology-tab/components/timestamp/chronology-timestamp.component";
import { ConfidentialityBreachComponent } from './components/incidents-details/components/details-tab/components/confidentiality-breach/confidentiality-breach.component';
import { EscaladeListComponent } from './components/incidents-details/components/details-tab/components/escalade-list/escalade-list.component';
import { IncidentConnectorsComponent } from './components/incidents-details/components/details-tab/components/incident-connectors/incident-connectors.component';
import { IncidentPriorityPickerComponent } from './components/incidents-details/components/details-tab/components/incident-priority-picker/incident-priority-picker.component';
import { SeverityPickerComponent } from "./components/incidents-details/components/details-tab/components/severity-picker/severity-picker.component";
import { ZohoLinkEditorComponent } from "./components/incidents-details/components/details-tab/components/zoho-link-editor/zoho-link-editor.component";
import { DetailsTabComponent } from "./components/incidents-details/components/details-tab/details-tab.component";
import { ActifsSectionComponent } from './components/incidents-details/components/details-tab/sections/actifs-section/actifs-section.component';
import { AdditionalInfoSectionComponent } from "./components/incidents-details/components/details-tab/sections/additional-info-section/additional-info-section.component";
import { AffectedResourcesSectionComponent } from './components/incidents-details/components/details-tab/sections/affected-resources-section/affected-resources-section.component';
import { AnalystAdderComponent } from './components/incidents-details/components/details-tab/sections/metadata-section/analyst-adder/analyst-adder.component';
import { MetadataSectionComponent } from "./components/incidents-details/components/details-tab/sections/metadata-section/metadata-section.component";
import { PlaybookSectionComponent } from "./components/incidents-details/components/details-tab/sections/playbook-section/playbook-section.component";
import { SummarySectionComponent } from "./components/incidents-details/components/details-tab/sections/summary-section/summary-section.component";
import { UsecaseSectionComponent } from "./components/incidents-details/components/details-tab/sections/usecase-section/usecase-section.component";
import { WhitelistSectionComponent } from "./components/incidents-details/components/details-tab/sections/whitelist-section/whitelist-section.component";
import { HistoryTabIncidentComponent } from "./components/incidents-details/components/history-tab/history-tab.component";
import { IncidentCloseModalComponent } from "./components/incidents-details/components/incident-close-modal/incident-close-modal.component";
import { IncidentsNavbarComponent } from "./components/incidents-details/components/incident-detail-navbar/incident-detail-navbar.component";
import { IncidentEscalationTableComponent } from './components/incidents-details/components/incident-escalation-table/incident-escalation-table.component';
import { IncidentNotificationModalComponent } from './components/incidents-details/components/incident-notification-modal/incident-notification-modal.component';
import { IncidentReopenModalComponent } from "./components/incidents-details/components/incident-reopen-modal/incident-reopen-modal.component";
import { IncidentSimilarityTabComponent } from './components/incidents-details/components/incident-similarity-tab/incident-similarity-tab.component';
import { IncidentTabHeaderComponent } from "./components/incidents-details/components/incident-tab-header/incident-tab-header.component";
import { ObservableTabComponent } from "./components/incidents-details/components/observable-tab/observable-tab.component";
import { DurationTimeComponent } from "./components/incidents-details/components/task-tab/components/duration-time/duration-time.component";
import { IncidentPhaseMenuComponent } from './components/incidents-details/components/task-tab/components/incident-phase-menu/incident-phase-menu.component';
import { TaskContainerContentComponent } from "./components/incidents-details/components/task-tab/components/task-container-content/task-container-content.component";
import { TaskExpensableContainerComponent } from "./components/incidents-details/components/task-tab/components/task-expensable-container/task-expensable-container.component";
import { UiSelectWithIconComponent } from "./components/incidents-details/components/task-tab/components/ui-select-with-icon/ui-select-with-icon.component";
import { TaskTabComponent } from "./components/incidents-details/components/task-tab/task-tab.component";
import { IncidentsDetailsComponent } from "./components/incidents-details/incidents-details.component";
import { RespondIncidentListComponent } from "./components/respond-incident-list/respond-incident-list.component";
import { RespondIncidentTableFiltersComponent } from "./components/respond-incident-table-filters/respond-incident-table-filters.component";
import { RespondIncidentTableComponent } from "./components/respond-incident-table/respond-incident-table.component";
import { TooltipConfidentialityComponent } from './components/tooltip-confidentiality/tooltip-confidentiality.component';
import { MatRadioModule } from "@angular/material/radio";
import { IncidentTasksComponent } from "./components/incident-tasks/incident-tasks.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PlaybooksModule } from "../playbooks/playbooks.module";
import { DirectivesModule } from "@common/modules/directives/directives.module";
import { IncidentEntityActionsModalComponent } from './components/incident-entity-actions-modal/incident-entity-actions-modal.component';
import { ChatMessageMenuComponent } from './components/incidents-details/components/chat-tab/chat-message-menu/chat-message-menu.component';
import { IncidentHistoryTabFiltersComponent } from "./components/incidents-details/components/history-tab/components/incident-history-tab-filters/incident-history-tab-filters.component";
import { IncidentHistoryService } from './components/incidents-details/components/history-tab/history-tab.service';
import { IncidentHistoryTabFiltersService } from './components/incidents-details/components/history-tab/components/incident-history-tab-filters/incident-history-tab-filters.service';
import { PreparationSectionComponent } from "./components/incidents-details/components/task-tab/components/identification-section/preparation-section.component";
import { PermissionBadgeComponent } from "./components/incidents-details/components/task-tab/components/permission-badge/permission-badge.component";
import { RespondConnectorsModule } from "../connectors/respond-connectors.module";

const components = [
  RespondIncidentListComponent,
  RespondIncidentTableComponent,
  RespondIncidentTableFiltersComponent,
  IncidentsDetailsComponent,
  TaskTabComponent,
  PreparationSectionComponent,
  PermissionBadgeComponent,
  UiSelectWithIconComponent,
  TaskExpensableContainerComponent,
  TaskContainerContentComponent,
  DurationTimeComponent,
  ObservableTabComponent,
  IncidentTabHeaderComponent,
  IncidentReopenModalComponent,
  IncidentsNavbarComponent,
  IncidentCloseModalComponent,
  HistoryTabIncidentComponent,
  DetailsTabComponent,
  UsecaseSectionComponent,
  SummarySectionComponent,
  PlaybookSectionComponent,
  MetadataSectionComponent,
  AdditionalInfoSectionComponent,
  ZohoLinkEditorComponent,
  SeverityPickerComponent,
  ChronologyTabComponent,
  ChronologyTimestampComponent,
  CollaborationTimeComponent,
  AlertsTabComponent,
  IncidentDetailsBaseLayoutComponent,
  CustomTabsComponent,
  CreateIncidentModalComponent,
  IncidentReportButtonComponent,
  ConfidentialityBreachComponent,
  TooltipConfidentialityComponent,
  ActifsSectionComponent,
  IncidentStatusBadgeComponent,
  IncidentPriorityPickerComponent,
  WhitelistSectionComponent,
  IncidentPhaseMenuComponent,
  AnalystAdderComponent,
  AffectedResourcesSectionComponent,
  IncidentToolboxComponent,
  IncidentActionsComponent,
  IncidentTasksComponent,
  IncidentConnectorsComponent,
  IncidentSimilarityTabComponent,
  IncidentActionInputComponent,
  EscaladeListComponent,
  IncidentNotificationModalComponent,
  IncidentEscalationTableComponent,
  IncidentEntitiesComponent,
  ChatTabContainer,
  IncidentEscalationTableComponent,
  ChatConversationsComponent,
  ChatNotesComponent,
  IncidentEntityActionsModalComponent,
  IncidentHistoryTabFiltersComponent
];

@NgModule({
  declarations: [ ...components, ChatMessageMenuComponent ],
  exports: [ ...components ],
  imports: [
    MatRadioModule,
    CommonModule,
    UiKitModule,
    PipesModule,
    RouterModule,
    I18nModule,
    ComponentsModule,
    FeatureSharedModule,
    HistoriesModule,
    ObservablesModule,
    ActifsModule,
    WhitelistsModule,
    FilterModule,
    RespondComponentsModule,
    DragDropModule,
    PlaybooksModule,
    DirectivesModule,
    RespondConnectorsModule
  ],
  providers: [IncidentHistoryService, IncidentHistoryTabFiltersService ]
})
export class IncidentModule { }
