import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { EcoUrlService } from "../../core/url.service";
import { IListObservablesRequest, IListSimilarObservablesRequest, IObservable } from "./observables.definition";

@Injectable({
  providedIn: 'root',
})
export class ObservablesApi extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public listAlertObservables(request: IListObservablesRequest): Observable<ResponseUtils<IObservable>> {
    const url = `${this.host}/organizations/${request.organizationId}/alerts/${request.alertId}/observables`;
    return this.http.get<IRespondResponse<IObservable>>(url).pipe(map((response) => new ResponseUtils<IObservable>(response)));
  }

  public listIncidentObservables(request: IListObservablesRequest): Observable<ResponseUtils<IObservable>> {
    const url = `${this.host}/organizations/${request.organizationId}/incidents/${request.incidentId}/observables`;
    return this.http.get<IRespondResponse<IObservable>>(url).pipe(map((response) => new ResponseUtils<IObservable>(response)));
  }

  public listAlertObservablesSimilarity(request: IListSimilarObservablesRequest): Observable<ResponseUtils<IObservable>> {
    const url = `${this.host}/organizations/${request.similarItemOrganizationId}/${request.similarItemType}/${request.similarItemId}/similarities/alerts/${request.alertId}/observables`;
    return this.http.get<IRespondResponse<IObservable>>(url).pipe(map((response) => new ResponseUtils<IObservable>(response)));
  }

  public listIncidentObservablesSimilarity(request: IListSimilarObservablesRequest): Observable<ResponseUtils<IObservable>> {
    const url = `${this.host}/organizations/${request.similarItemOrganizationId}/${request.similarItemType}/${request.similarItemId}/similarities/incidents/${request.incidentId}/observables`;
    return this.http.get<IRespondResponse<IObservable>>(url).pipe(map((response) => new ResponseUtils<IObservable>(response)));
  }
}
