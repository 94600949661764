import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'edit-card-content',
  templateUrl: './edit-card-content.component.html',
  styleUrls: [ './edit-card-content.component.scss' ],
})
export class EditCardContentComponent {
  @Input() public canSave: boolean;
  @Input() public confirmationSave: boolean = false;
  @Input() public canEdit: boolean = true;

  @Output() toggleEditingEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() saveEvent: EventEmitter<Function> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter();

  public isEditing = false;

  constructor() { }

  public toggleEdit() {
    this.isEditing = !this.isEditing;
    this.toggleEditingEvent.emit(this.isEditing);
  }

  public cancel() {
    this.toggleEdit();
    this.cancelEvent.emit();
  }

  public save() {
    if (this.confirmationSave) {
      this.saveEvent.emit((decision: boolean) => {
        if (decision) this.toggleEdit();
      });
    } else {
      this.saveEvent.emit();
      this.toggleEdit();
    }
  }

  public get icon() {
    return this.canSave ? 'assets/favicons/icon_conforme.svg' : 'assets/favicons/icon_small_check.svg';
  }
}
