import { Injectable } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';

export enum AverageCapacityUnitEnum {
  GIGABYTE = 'GB',
  MEGABYTE = 'MB',
  KILOBYTE = 'KB',
  BYTE = 'B'
}

@Injectable({
  providedIn: 'root',
})
export class DashboardFileUnitHelper {
  constructor(private readonly i18n: I18nService) {}

  public formatValueWithBinaryUnit(value: number): string {
    const { result, typeToTranslate } = this.getFileUnitValues(value);
    return `${result} ${this.i18n.translate(typeToTranslate)}`;
  }

  public getFileUnitValues(value: number, nbFloatingPoint: number = 2, minimumPossibleUnit: AverageCapacityUnitEnum = AverageCapacityUnitEnum.BYTE) : { result: number, typeToTranslate: string } {
    if (value >= Math.pow(1024, 3) || minimumPossibleUnit === AverageCapacityUnitEnum.GIGABYTE) {
      return { result: parseFloat((value / Math.pow(1024, 3)).toFixed(nbFloatingPoint)), typeToTranslate: 'file.size.gigabytes' };
    } else if (value >= Math.pow(1024, 2) || minimumPossibleUnit === AverageCapacityUnitEnum.MEGABYTE) {
      return { result: parseFloat((value / Math.pow(1024, 2)).toFixed(nbFloatingPoint)), typeToTranslate: 'file.size.megabytes' };
    } else if (value >= 1024 || minimumPossibleUnit === AverageCapacityUnitEnum.KILOBYTE) {
      return { result: parseFloat((value / 1024).toFixed(nbFloatingPoint)), typeToTranslate: 'file.size.kilobytes' };
    }
    return { result: value, typeToTranslate: 'file.size.bytes.fullname' };
  }
}
