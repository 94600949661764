import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Autocomplete, AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { JustificationConfirmationModalComponent } from '@ui-kit/components/justification-confirmation-modal/justification-confirmation-modal.component';
import { WarningColors } from '@ui-kit/components/ui-warning/ui-warning.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { ModalService } from '@ui-kit/services/modal.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationSettings } from 'projects/@common/services/api/sg/organizations/organizations.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { UserObjectAllProperties } from 'projects/@common/services/api/sg/user/user.definition';

@Component({
  selector: 'application-segmentation',
  templateUrl: './application-segmentation.component.html',
  styleUrls: [ './application-segmentation.component.scss' ],
})
export class ApplicationSegmentationComponent implements OnInit {
  @ViewChild('segmentationAutocomplete') private autocomplete: Autocomplete;

  @Input() public currentSettings: OrganizationSettings;
  @Input() public isLoading = false;

  public isEditing = false;
  public isActivated = false;
  public warningColors = WarningColors;
  public warningMessage = [ { messageKey: this.i18n.translate('settings.general.segmentation.deactivated.warning') } ];

  public initialValue: string;

  public selectedAttribute: string;

  public customAutocompleteType = AutocompleteTypes.CUSTOM;
  public customValues = Object.keys(UserObjectAllProperties)
    .map((userProperty) => ({
      value: userProperty,
      displayValue: userProperty,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));

  constructor(
    private organizationsApiService: OrganizationsApiService,
    private i18n: I18nService,
    private noticeService: NoticeService,
    private readonly modalService: ModalService
  ) { }

  ngOnInit() {
    this.initialValue = this.currentSettings?.segmentationAttribute;
    this.selectedAttribute = this.initialValue;
    this.isActivated = !!this.initialValue;
  }

  public cancel(): void {
    this.selectedAttribute = this.initialValue;
    this.isActivated = !!this.initialValue;
  }

  public get isChangingAttribute(): boolean {
    return (!this.isActivated && !!this.initialValue) || (this.initialValue !== this.selectedAttribute) && !!this.initialValue;
  }

  public handleSave(closeEditEvent: Function) {
    if (this.isChangingAttribute) {
      this.openConfirmation(closeEditEvent);
    } else {
      this.save();
      closeEditEvent(true);
    }
  }

  public save(): void {
    this.initialValue = this.selectedAttribute;
    this.organizationsApiService
      .updateOrganizationSettings({
        newSettings: {
          segmentationAttribute: this.isActivated ? this.selectedAttribute : null,
        },
      })
      .then((response) => {
        this.isActivated = !!response.segmentationAttribute;
        this.initialValue = response.segmentationAttribute;
        this.selectedAttribute = this.initialValue;
        this.noticeService.notifyUser({
          message: 'settings.notification.general.segmentation.snack.success',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'settings.notification.general.segmentation.snack.error',
          level: NoticeLevels.ERROR,
        });
      });
  }

  public get isValid(): boolean {
    return (!!this.selectedAttribute && this.selectedAttribute !== this.initialValue) || !this.isActivated;
  }

  public toggleSilencingActivation(): void {
    this.isActivated = !this.isActivated;
  }

  public onItemSelected(event: any): void {
    if (event) {
      this.selectedAttribute = event.value;
      this.autocomplete.searchQuery = '';
    }
  }

  public get showSelectedAttribute(): boolean {
    return this.isActivated && this.initialValue === this.selectedAttribute;
  }

  private openConfirmation(closeEditEvent: Function): any {
    return this.modalService.openDialog(JustificationConfirmationModalComponent, {
      title: this.i18n.translate('settings.general.segmentation.deactivated.confirm.label'),
      text: this.i18n.translate('settings.general.segmentation.deactivated.warning'),
      justificationConfirmationLabel: this.i18n.translate('settings.general.segmentation.deactivated.confirm'),
      justificationConfirmationPlaceholder: this.i18n.translate('common.action.confirm'),
      needsJustification: false,
      confirmationText: this.i18n.translate('yes'),
      confirmationFieldText: {
        fr: this.i18n.translate('common.action.confirm', { locale: LanguageEnum.FRENCH }),
        en: this.i18n.translate('common.action.confirm', { locale: LanguageEnum.ENGLISH }),
      },
      cancelText: this.i18n.translate('no'),
      currentLang: this.i18n.currentLocale,
      callback: (closeValue: { value: boolean; }) => {
        if (closeValue.value) {
          this.save();
          closeEditEvent(closeValue.value);
        };
      },
    });
  }
}
