<ui-static-table
  [loading]="loading"
  [expendedTemplate]="template"
  [dataSource]="datasources"
  [columnsDef]="columnsDef"
  [isSelectingOnlyOneAtATime]="false"
  [addPaddingOnFirst]="false"
  [hoverable]="true"
  [locale]="locale"
>
</ui-static-table>

<ng-template #template let-datasource="row">
  <div class="template-container">
    <p class="template-container--description">
      {{ 'detection.datasource.inventory.detail.usecase.description' | t }}
    </p>
    <p class="template-container--text">
      {{ datasource.description | translatedObject }}
    </p>

    <p class="template-container--description spacer-top">
      {{
        'detection.datasource.inventory.detail.usecase.description.datasources'
          | t
      }}
    </p>
    <p class="template-container--text">{{ datasource.datasources }}</p>
    <p class="template-container--description spacer-top">
      {{
        'detection.datasource.inventory.detail.usecase.description.catalog_id'
          | t
      }}
    </p>
    <p class="template-container--text">{{ datasource.catalogId }}</p>
  </div>
</ng-template>
